import React from "react";
import CONFIG from "../config";
import { toTitleCase } from "./strings";
import { getCode } from "iso-3166-1-alpha-2";
import { Icon } from "@avaya/neo-react";
export const createCommonFilterObject = (data) => {
    const values = JSON.parse(JSON.stringify(data));
    let filterSortObject = {};
    Object.keys(values)?.forEach(key => {
        filterSortObject = {
            ...filterSortObject,
            filter: {
                ...filterSortObject?.filter,
                ...((values[key]?.length > 0 || (typeof values[key] === "boolean")) && {
                    [key]: {
                        operator: "=",
                        oprVal: Array.isArray(values[key]) ? values[key]?.length > 0 ? values[key]?.join('\\\\') : undefined : values[key]
                    },
                })
            }
        }
    });
    return filterSortObject;
}

export const createGraphData = (inputData = []) => {
    return inputData?.length > 0 ? inputData?.map((quarterData) => ({
        name: quarterData?.entityName,
        data: quarterData?.data?.map(entry => entry?.count)
    })) : []
}

export const createTableColumns = (inputObject, reportKey) => {
    const singleData = inputObject?.length ? inputObject[0] : [];
    const columns = singleData?.data?.map((item, mainIndex) => {
        const children = item?.data?.map((eachItem, subIndex) => ({
            title: eachItem?.userType === CONFIG.userTypes[0]?.value ? eachItem?.userType.charAt(0).toUpperCase() + eachItem?.userType.slice(1) : eachItem?.userType.toUpperCase(),
            render: (record) => {
                if (reportKey === CONFIG.standardReportKeys.demoScheduleTrend)
                    return (<div>{record?.data[mainIndex]?.data[subIndex]?.count} {parseInt(record?.data[mainIndex]?.data[subIndex]?.count) !== 0 && <Icon size="md" className={`fill-icon-${parseInt(record?.data[mainIndex]?.data[subIndex]?.count) > 0 ? "increase" : "decrease"}`} icon={parseInt(record?.data[mainIndex]?.data[subIndex]?.count) > 0 ? "arrow-up" : "arrow-down"} />}</div>)
                else return (<div>{record?.data[mainIndex]?.data[subIndex]?.count}</div>)
            },
            key: `count${eachItem?.userType}`,
            align: 'left'
        }))
        return {
            title: item?.entityName,
            key: `quarter${item?.entityName}`,
            children,
        }
    });
    return columns;
}

export const createTableColumnsUserDistribution = (inputData, values) => {
    const record = inputData?.length ? inputData[0] : [];
    const columns = ((values?.type === "countries" || values?.type === "regions") && (values?.countries?.length > 0 || values?.regions?.length > 0)) ? [
        { title: record?.entityType, render: (record) => record?.entityName, key: record?.entityType, align: 'left' },
        ...((values?.users === undefined || values?.users?.includes("avaya") || values?.users?.length === 0) ? [{ title: "Avaya Users", align: 'left', render: (record) => record?.avayaUsersCount, key: `${record?.avayaUsersCount}-avaya-users` }] : []),
        ...((values?.users === undefined || values?.users?.includes("bp") || values?.users?.length === 0) ? [{ title: "BP Users", align: 'left', render: (record) => record?.bpUsersCount, key: `${record?.bpUsersCount}-bp-users` }] : []),
        ...((!!values?.isTotalRequired) ? [{ title: "Total", align: 'left', render: (record) => record?.totalCount, key: `${record?.totalCount}-total-users` }] : [])
    ] : [
        { title: toTitleCase(record?.entityType), render: (record) => record?.entityName, key: record?.entityName, align: 'left' },
        { title: "Number of Countries", render: (record) => record?.totalCountriesCount, key: record?.totalCountriesCount, align: 'left' },
        { title: "Number of Users", render: (record) => record?.totalUsersCount, key: record?.totalUsersCount, align: 'left' },
    ];
    return columns || [];
}

export const createUserDistributionData = (data = [], values) => {
    const userTypes = !values?.users ? ["avaya", "bp"] : values?.users?.length > 0 ? values?.users : ["avaya", "bp"];
    const sortedData = data.sort((a, b) => a.entityName.localeCompare(b.entityName));
    const finalData = [];
    const userCounts = [];
    if (sortedData?.length > 0) {
        sortedData.forEach((item) => {
            if (userTypes.includes("avaya") && userTypes.includes("bp")) userCounts.push(item.avayaUsersCount, item.bpUsersCount);
            else if (userTypes.includes("avaya")) userCounts.push(item.avayaUsersCount);
            else if (userTypes.includes("bp")) userCounts.push(item.bpUsersCount);
        })
    }

    if (!values?.users || values?.users?.length === 0 || values?.users?.length === 2) {
        finalData?.push({ name: "Avaya Users", data: userCounts.filter((number, index) => index % 2 === 0) })
        finalData?.push({ name: "BP Users", data: userCounts.filter((number, index) => index % 2 !== 0) })
    }
    else {
        finalData?.push({ name: `${values?.users?.includes("avaya") ? "Avaya Users" : "BP Users"}`, data: userCounts })
    }
    return finalData;
}

const generateRandomDelta = () => Math.floor(Math.random() * 5) + 3;

const adjustDuplicates = (regions) => {
    const countMap = new Map();
    regions.forEach(region => {
        const { totalCount } = region;
        if (!countMap.has(totalCount)) {
            countMap.set(totalCount, []);
        }
        countMap.get(totalCount).push(region);
    });
    regions.forEach(region => {
        let { totalCount } = region;
        let delta = 0;
        if (countMap.get(totalCount).length > 1) {
            while (countMap.has(totalCount + delta)) {
                delta = generateRandomDelta();
            }
            countMap.set(totalCount + delta, [region]);
            region.adjustedCount = totalCount + delta;
            region.delta = delta;
        }
        else {
            region.adjustedCount = totalCount;
            region.delta = delta;
        }
    });

    return regions;
};

export const clearMapContainer = () => {
    const mapContainer = document.querySelector('.map-container');
    if (mapContainer) {
        const paths = mapContainer.querySelectorAll('path');
        paths.forEach(function (path) {
            path.setAttribute('class', '');
            path.setAttribute('style', '');
        });
    }
}

export const createDataForGlobeMap = (data, form, countries) => {
    const values = form.getFieldsValue();
    clearMapContainer();
    let layers, colors, finalData;
    if (values?.type === "regions") {
        const transformedData = countries.reduce((acc, country) => {
            const regionName = country.region.name;
            if (!acc[regionName]) {
                acc[regionName] = [];
            }
            getCode(country?.name) && acc[regionName].push(getCode(country?.name));
            return acc;
        }, {});

        const adjustedData = adjustDuplicates(data);
        finalData = adjustedData?.flatMap(regionData =>
            transformedData[regionData.entityName]?.map(countryCode => ({
                country: countryCode,
                region: regionData.entityName,
                values: regionData,
                amount: regionData.adjustedCount,
                value: regionData.adjustedCount,
            })) || []
        )?.filter(item => !!item?.country);
        layers = adjustedData?.map(item => `${item?.adjustedCount - 1}-${item?.adjustedCount + 1}`)
        return { layers: layers, colors: CONFIG.regionColors, data: finalData }
    }
    else if (values?.type === "countries") {
        const countryData = data?.map((item) => ({
            country: getCode(item?.entityName),
            amount: item?.totalCount,
            values: item,
        }))?.filter(item => !!item?.country);
        layers = data?.map(item => `${item?.totalCount - 1}-${item?.totalCount + 1}`);
        return { layers: layers, colors: CONFIG.regionColors, data: countryData }
    }
    return { layers, colors, finalData }
}