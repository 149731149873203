import {
    FETCH_ALL_CAPABILITY,
    TOGGLE_IS_FETCHING_CAPABILITY,
    CURRENT_CAPABILITY_FILTER,
    REFRESH_CAPABILITY,
    CLEAR_CAPABILITY,
    TOGGLE_IS_FETCHING_CAPABILITY_FORM,
    FETCH_CAPABILITY_DETAILS,
    CLEAR_CAPABILITY_FORM,
    UPDATE_DEMO_CAPABILITY_ROLE_TEMPLATE,
    SAVE_DEMO_CAPABILITY_ROLE_TEMPLATES,
    CANCEL_DEMO_CAPABILITY_ROLE_TEMPLATES,
    FETCH_CAPABILITY_REQUEST_DETAILS,
    TOGGLE_IS_FETCHING_CAPABILITY_REQUEST_DETAILS,
    UPDATE_CAPABILITY_DETAILS_COMMENTS,
    CLEAR_COMMENT_DETAILS,
    UPDATE_CAPABILITY_GENERAL_INSTRUCTION,
    UPDATE_CAPABILITY_QUESTION_INSTRUCTION,
    CLEAR_CAPABILITY_GENERAL_INSTRUCTION,
    CLEAR_CAPABILITY_QUESTION_INSTRUCTION,
    CAPABILITY_QUESTION_INSTRUCTIONS,
    DELETE_CAPABILITY_QUESTION_INSTRUCTION,
    FETCH_LITE_CAPABILITY
} from "../actions/types";
import CONFIG from '../config';
const demoCapabilityState = {
    isFetching: false,
    demoCapabilityData: [],
    currentPageNumber: undefined
};
export const demoCapabilityReducer = (state = demoCapabilityState, action) => {
    switch (action.type) {
        case FETCH_ALL_CAPABILITY:
            return { ...state, demoCapabilityData: action.payload ? action.payload?.dataSet : [] || [] };
        case TOGGLE_IS_FETCHING_CAPABILITY:
            return { ...state, isFetching: action.payload };
        case CURRENT_CAPABILITY_FILTER:
            return { ...state, currentFilter: action.payload[0], isEdit: true, status: action.payload[1], currentPageNumber: action.payload[2] };
        case REFRESH_CAPABILITY:
            return { ...state, data: [], count: undefined };
        case CLEAR_CAPABILITY:
            return demoCapabilityState
        case FETCH_LITE_CAPABILITY:
            return { ...state, demoCapabilityData: action.payload ? action.payload : [] || [] }
        default:
            return state;
    }
};
const capabilityFormState = {
    loading: false,
    allRTAdminAccess: 0,
    capabilityGeneralInstruction: undefined
}
export const capabilityFormReducer = (state = capabilityFormState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_CAPABILITY_FORM:
            return { ...state, loading: action.payload };
        case FETCH_CAPABILITY_DETAILS:
            return {
                ...state,
                formData: action.payload || [],
                assignedRoleTemplates: action.payload?.roleTemplates,
                capabilityObj: action.payload
            }
        case UPDATE_DEMO_CAPABILITY_ROLE_TEMPLATE:
            return {
                ...state,
                formData: state.formData
                    ? {
                        ...state.formData,
                        roleTemplates: action.payload.updatedRoleTemplates
                    }
                    : { roleTemplates: action.payload.updatedRoleTemplates }
                ,
                allRTAdminAccess: !action.payload.selectedRoleTemplate && action.payload.access
                    ? action.payload.accessType
                    : CONFIG.roleTemplateAccess.none
            }
        case SAVE_DEMO_CAPABILITY_ROLE_TEMPLATES:
            return { ...state, assignedRoleTemplates: state.formData?.roleTemplates };

        case CANCEL_DEMO_CAPABILITY_ROLE_TEMPLATES:
            return {
                ...state,
                allRTAdminAccess: 0,
                formData: state.formData
                    ? { ...state.formData, roleTemplates: state.assignedRoleTemplates || [] }
                    : { roleTemplates: state.assignedRoleTemplates || [] }
            };
        case CLEAR_CAPABILITY_FORM:
            return capabilityFormState;
        default: return state
    }
}

const capabilityGeneralInstructionState = {
    isContentLoaded: false,
    html: undefined
};

export const capabilityGeneralInstructionReducer = (state = capabilityGeneralInstructionState, action) => {
    switch (action.type) {
        case UPDATE_CAPABILITY_GENERAL_INSTRUCTION:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_CAPABILITY_GENERAL_INSTRUCTION:
            return capabilityGeneralInstructionState;
        default:
            return state
    }
}


export const capabilityQuestionInstructionReducer = (state = {}, action) => {
    switch (action.type) {
        case CAPABILITY_QUESTION_INSTRUCTIONS:
            return { ...state, ...action.payload }
        case UPDATE_CAPABILITY_QUESTION_INSTRUCTION:
            return { ...state, [action.payload.key]: { isContentLoaded: true, html: action.payload.htmlData } };
        case DELETE_CAPABILITY_QUESTION_INSTRUCTION:
            return { ...state, ...action.payload };
        case CLEAR_CAPABILITY_QUESTION_INSTRUCTION:
            return {};
        default:
            return state
    }
}

const capabilityRequestDetailsState = {
    loading: false,
    isContentLoaded: false
}

export const capabilityRequestDetailsReducer = (state = capabilityRequestDetailsState, action) => {
    switch (action.type) {
        case FETCH_CAPABILITY_REQUEST_DETAILS:
            return { ...state, requestDetails: action.payload || [] }
        case TOGGLE_IS_FETCHING_CAPABILITY_REQUEST_DETAILS:
            return { ...state, loading: action.payload }
        default: return state;
    }
}

const capabilityCommentState = {
    isContentLoaded: false,
    html: undefined
}

export const capabilityCommentDetailsReducer = (state = capabilityCommentState, action) => {
    switch (action.type) {
        case UPDATE_CAPABILITY_DETAILS_COMMENTS:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_COMMENT_DETAILS:
            return capabilityCommentState;
        default: return state;
    }
}