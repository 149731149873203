import { batch } from 'react-redux';

import { aepSchedule } from '../apis';
import {
    FETCH_MY_DEMO_SCHEDULES,
    TOGGLE_IS_FETCHING_MY_DEMO_SCHEDULES,
    CLEAR_MY_SCHEDULES_RESPONSE,
    RETAIN_MY_SCHEDULE_FILTER,
    CLEAR_MY_DEMO_SCHEDULES
} from './types';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';

export const fetchMySchedules = ({ pageNumber = 1, pageSize = CONFIG.pageSize, filterSort }) => async dispatch => {
    const params = {
        pageNumber,
        pageSize,
        filterSort
    };
    const response = await aepSchedule.get('/scheduleMaintenanceService/myDemos/schedules', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: FETCH_MY_DEMO_SCHEDULES,
                payload: [response.data, response.status] || []
            });
        })
    else {
        dispatch({
            type: CLEAR_MY_SCHEDULES_RESPONSE,
            payload: response.status
        });
        pushMessage(CONFIG.messageType.error, 'Unable to fetch my demo schedules');
    }
    dispatch(toggleIsFetchingMySchedules(false));
};

export const toggleIsFetchingMySchedules = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_MY_DEMO_SCHEDULES,
        payload: flag
    };
};

export const fetchMySchedulesForExportPrint = ({ filterSort }) => async dispatch => {
    const params = {
        filterSort
    };
    const response = await aepSchedule.get('/scheduleMaintenanceService/myDemos/schedules', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        return response?.data?.dataSet || [];
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch my demo schedules for export/print');
    }
};

export const retainMyScheduleFilter = (filterSort, status, pageNumber, pageSize) => {
    return {
        type: RETAIN_MY_SCHEDULE_FILTER,
        payload: [filterSort, status, pageNumber, pageSize]
    }
}

export const clearMyDemoSchedules = () => {
    return { type: CLEAR_MY_DEMO_SCHEDULES };
}