import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd5';
import CONFIG from '../../config';

class ReportOutputTableNeo extends React.Component {
    render() {
        const { generalReportData, currentReportData, filterSort } = this.props;
        const tableData = (currentReportData?.reportKey === CONFIG.standardReportKeys.userDistribution ? generalReportData : generalReportData?.tableData) || [];
        const firstColumn = currentReportData?.reportKey === CONFIG.standardReportKeys.userDistribution ? [] : [{
            title: currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage
                ? "Region"
                : filterSort?.filter?.type?.oprVal === "demos" ? "Demos" : "Solution Types"
            , dataIndex: "entityName", key: "entityName"
        }]
        const actualColumns = this.props.tableColumns?.length > 0 ? tableData && tableData.length > 0 ? [...firstColumn, ...this.props.tableColumns] : [] : [];
        return (
            <div style={{ padding: "2%" }}>
                <Table
                    columns={actualColumns}
                    dataSource={(actualColumns?.length > 0 ? tableData : []) || []}
                    className={`antd-to-neo-table antd-to-neo-table-report`}
                    pagination={false}
                    bordered={true}
                    size="middle"
                />
            </div>
        )
    }
}

const mapStateToProps = ({ currentReport, userReports }, props) => {
    const acutalReport = props?.isRunByUser ? userReports : currentReport?.reportDetails;
    return {
        data: props?.isRunByUser ? acutalReport.runReportData : currentReport?.runReportData,
        generalReportData: props?.isRunByUser ? (acutalReport.runGeneralReportData || []) : (currentReport?.runGeneralReportData || []),
        currentReport: acutalReport,
        isRunByUser: props?.isRunByUser
    };
}

export default connect(mapStateToProps, {

})(ReportOutputTableNeo);