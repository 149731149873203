import React from "react";
import { Form, Row, Popconfirm, Spin, message, Dropdown, Tooltip, Tabs } from "antd";
import { connect, batch } from "react-redux";
import { exportMenu } from "../../utils/strings";

import {
  toggleBackButton,
  selectTitle,
  clearResources,
  exportTable,
  fetchDataCenters,
  fetchDemos,
  fetchResourceDetails,
  restoreResource,
  refreshResources,
  toggleHasResourcesUpdated,
  currentResourceFilter,
  updateActiveTab,
  clearResourceData,
  handleOnClickAction,
  toggleSaveReportVisible,
  toggleIsFetchingResourcesFlag,
  fetchLiteResources
} from "../../actions";
import { checkResourceDependencies } from "../../actions/resources";
import { deleteResource } from '../../actions/resources';
import CONFIG from '../../config.js';
import openConfirm from '../common/Confirm';
import pushMessage from '../common/PushMessage';
import { isTabAlreadyOpen } from "../common/TabLayout";
import throttle from 'lodash/throttle';
import SaveReport from "../common/SaveReport";
import { LoadingOutlined } from "@ant-design/icons";
import { Table as NeoTable, Icon as NeoIcon, Button as NeoButton } from "neo-latest";
import { checkIfFilterSortIsEmpty } from "../../utils/formChanged.js";
import FilterDrawer from "../common/FilterDrawer";
import { otherFields } from "../../utils/neoTableFields";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;

const dataIndexMap = {
  name: 'name',
  dataCenter: 'dataCenter.title',
  demo: 'demoResources.demo.title',
  isActive: 'isActive',
  viewDeletedItems: 'viewDeletedItems'
};
class ManageResources extends React.Component {

  constructor(props) {
    super(props);
    this.handleClear = throttle(this.handleClear, 1000);
  }

  state = {
    status: "true",
    resourcesData: [],
    loading: false,
    isReturnedFromEdit: false,
    isReturnedFromDelete: false,
    deletedRecordIndex: undefined,
    showFilter: false,
    currentPage: 0,
    currentPath: null
  }

  filterSort = {};
  filterSave = {};
  tableRef = React.createRef()
  flag = 0
  isViewDeletedItemsChecked = undefined;

  clearCurrentResources = async () => {
    await this.props.clearResources();
    this.setState({ currentPage: 0 });
  }

  handleSelectChange = async (value) => {
    await this.clearCurrentResources()
    this.setState({ status: value }, () => { this.handleFetchResources() })
  }

  handleFetchResources = async () => {
    this.props.toggleIsFetchingResourcesFlag(true);
    this.props.fetchLiteResources({ status: this.state.status, filterSort: this.filterSort });
  }


  handleFilter = async (filters, isSave) => {
    let { name, isActive, dataCenter, demo, viewDeletedItems } = filters;
    this.isViewDeletedItemsChecked = viewDeletedItems;
    const isStatusEmpty = (!isActive || (isActive && isActive.length === 0));
    const isdataCenterEmpty = (!dataCenter || (dataCenter && dataCenter.length === 0));
    const isDemoEmpty = !demo || (demo && demo.length === 0);
    name = name?.trim();
    const isFilter = isSave ? "save" : "filter";
    if (!name && isStatusEmpty && isdataCenterEmpty && isDemoEmpty && !viewDeletedItems) {
      pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
      return;
    }
    if (viewDeletedItems || this.isViewDeletedItemsChecked) this.setState({ status: "false" });
    if (isSave) {
      this.filterSave = {
        ...this.filterSave,
        filter: {
          ...(name && {
            [dataIndexMap.name]: {
              operator: "=",
              oprVal: name || undefined
            }
          }),
          ...(isActive && isActive.length === 1 && {
            [dataIndexMap.isActive]: {
              operator: "=",
              oprVal: isActive && isActive.length === 1 ? JSON.parse(isActive[0]) : undefined
            }
          }),
          ...(!isdataCenterEmpty && {
            [dataIndexMap.dataCenter]: {
              operator: "=",
              oprVal: !isdataCenterEmpty ? dataCenter.join(CONFIG.delimiters.saveFilter) : undefined
            }
          }),
          ...(!isDemoEmpty && {
            [dataIndexMap.demo]: {
              operator: "=",
              oprVal: !isDemoEmpty ? demo.join(CONFIG.delimiters.saveFilter) : undefined
            }
          }),
          ...(viewDeletedItems && {
            isSoftDelete: {
              operator: "=",
              oprVal: viewDeletedItems
            }
          })
        },
        filterTable: {
          id: 5,
          tableName: "Resource"
        },
      }
      this.props.toggleSaveReportVisible(true)
      this.setState({ saveReport: true })
    }
    else {
      await this.clearCurrentResources()
      this.filterSort = {
        ...this.filterSort,
        filter: {
          [dataIndexMap.name]: name || undefined,
          [dataIndexMap.isActive]: isActive && isActive.length === 1 ? JSON.parse(isActive[0]) : undefined,
          [dataIndexMap.dataCenter]: !isdataCenterEmpty ? dataCenter.join(CONFIG.delimiters.selectFilter) : undefined,
          [dataIndexMap.demo]: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
          [dataIndexMap.viewDeletedItems]: viewDeletedItems,
          dataCenter: !isdataCenterEmpty ? dataCenter.join(CONFIG.delimiters.selectFilter) : undefined,
          demo: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
        }
      }

      this.handleFetchResources();
    }
  }

  handleClear = async (refresh) => {
    if (refresh) {
      this.props.clearResourceData();
      this.setState({ currentPage: 0 });
      this.props.toggleHasResourcesUpdated(false);
      this.fetchResourcesOnMount();
    }
    else {
      await this.clearCurrentResources();
      this.filterSort = { ...this.filterSort, filter: undefined };
      this.filterSave = {};
      this.handleFetchResources();
    }
  }

  handleSave = (filters) => {
    this.handleFilter(filters, true);
  }

  handleExport = async (fileType) => {
    const { timeZoneName } = this.props;
    pushMessage(CONFIG.messageType.loading, "Exporting", 0);
    const resources = await this.props.fetchLiteResources({ filterSort: this.filterSort, status: this.state.status })


    if (resources?.length > 0 && resources !== undefined) {
      exportTable(CONFIG.exportTable.resources.name, resources, timeZoneName, CONFIG.exportTable.resources.fileName + `.${fileType}`, fileType)
        .then((response) => {
          message.destroy()
          const isExport = response.status === CONFIG.HTTP_STATUS.OK
          pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
        })
        .catch(() => {
          message.destroy()
          pushMessage(CONFIG.messageType.error, "Unable to export table")
        })
    }
    else {
      message.destroy()
      pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
    }
  }

  handleDeleteConfirmation = async () => {
    const hardDelete = this.filterSort?.filter?.viewDeletedItems;
    const response = await deleteResource(this.resourceId, hardDelete);
    if (response) {
      await this.clearCurrentResources()
      this.setState({ currentPage: this.props.currentPageNumber });
      this.fetchResourcesOnMount();
    }
  }

  handleRestore = async (resourceId, index) => {
    const response = await this.props.restoreResource(resourceId);
    if (response) {
      this.clearCurrentResources()
      this.setState({ currentPage: this.props.currentPageNumber });
      this.fetchResourcesOnMount();
    }
  }

  handleDelete = (resourceId, index) => {
    this.resourceId = resourceId;
    this.recordIndex = index
    const hardDelete = this.filterSort?.filter?.viewDeletedItems;
    if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
    else {
      checkResourceDependencies(resourceId, hardDelete)
        .then(dependencyList => this.renderDeleteConfirmation(dependencyList))
        .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
    }
  }


  renderDeleteConfirmation = (dependencyList) => {
    const { scheduleCount, maintenanceCount } = dependencyList;
    const hardDelete = this.filterSort?.filter?.viewDeletedItems;
    const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"

    const showDependencyList = !hardDelete && (scheduleCount !== 0 || maintenanceCount !== 0);
    const content = <>
      {hardDelete ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Resource")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Resource")}</h4>}
      {showDependencyList &&
        <>
          <h4>Deletion of selected resource would affect</h4>
          {scheduleCount} * Future Scheduled Demos <br />
          {maintenanceCount} * Future Scheduled Maintenances <br />
        </>
      }
    </>
    openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, showDependencyList ? CONFIG.dependencyModalTitle : CONFIG.deleteModalTitle?.replace('<placeholder>', 'resource'));
  }

  async componentWillUnmount() {
    if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
      await this.props.currentResourceFilter(this.filterSort, this.state.status, this.state.currentPage)
      await this.props.clearResourceData();
    }
    else {
      await this.props.clearResources();
    }
  }

  fetchResourcesOnMount = async () => {
    const { isEdit } = this.props;
    this.setState({ loading: true })
    this.props.toggleIsFetchingResourcesFlag(true);
    await this.props.fetchLiteResources({ filterSort: this.filterSort, status: this.state.status });

    this.setState(({ resourcesData }) => ({
      loading: false,
      resourcesData: this.props.resources ? resourcesData?.concat(this.props.resources) : [],
      isReturnedFromEdit: isEdit ? true : false,
    }))
  }

  async componentDidMount() {
    const { currentStatus, isEdit, resourceFilter, currentPageNumber } = this.props;
    this.setState({ currentPath: window.location.pathname });

    if (isEdit) {
      this.setState({ currentPage: currentPageNumber });
      this.filterSort = resourceFilter;
      if (Object.keys(JSON.parse(JSON.stringify(resourceFilter)))?.length > 0) {
        const filterObject = resourceFilter?.filter ? JSON.parse(JSON.stringify(resourceFilter?.filter)) : {}
        Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
      }
      await this.setState({ status: currentStatus });
    }
    else {
      this.filterSort = {}
    }
    this.fetchResourcesOnMount()
    batch(() => {
      this.props.fetchDataCenters();
      this.props.fetchDemos({ getAll: true, demoType: CONFIG.demoTypes.standardDemo })
    });
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps?.activeKey !== this.props.activeKey && nextProps?.activeKey !== "/manage/demo-resources") return false;
    else return true;
  }

  renderFilterPanel = () => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
      >
        {
          <div style={{ display: "flex", flex: "1", zIndex: "1", position: "relative" }}>
            <NeoButton
              onClick={() => {
                this.props.handleOnClickAction({
                  component: CONFIG.createComponentRoute.resources,
                  tabTitle: "Create",
                });
              }}
            >
              Create Resource
            </NeoButton>
          </div>
        }
        <div
          style={{
            display: "flex",
            width: "100%",
            flex: "1",
            flexDirection: "row-reverse",
            gap: "24px",
          }}
        >
          <div>
            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
              <NeoButton
                className="export-download-button"
                icon="chevron-down"
                variant="secondary"
              >
                Download
              </NeoButton>
            </Dropdown>
          </div>
          <div>
            <NeoIcon
              size="md"
              className="clickable"
              onClick={() =>
                this.setState((prevState) => ({
                  showFilter: !prevState.showFilter,
                }))
              }
              title="Filter"
              icon={
                this.state.showFilter ||
                  !checkIfFilterSortIsEmpty({ filter: this.filterSort })
                  ? "filter-filled"
                  : "filter"
              }
              style={{ fontSize: "23px", color: "#0b67bd" }}
            />
          </div>
          <div>
            <NeoIcon
              size="md"
              className="clickable"
              title="Refresh"
              icon="refresh"
              onClick={() => this.handleClear(true)}
              style={{ fontSize: "23px", color: "#0b67bd" }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderTabContents = () => {
    return (
      <React.Fragment>
        <div>{this.renderFilterPanel()}</div>
        <div style={{ minHeight: "250px" }}>{this.renderTable()}</div>
      </React.Fragment>
    );
  };

  renderTable = () => {
    const { userRoleTemplate, resources, isFetching } = this.props;
    const deleteView = this.filterSort?.filter?.viewDeletedItems;
    this.isViewDeletedItemsChecked = deleteView;
    const neoColumns = [
      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.name}
            </span>
          );
        },
        Header: "Title",
        sortType: "string",
        accessor: "name",
      },

      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.dataCenter?.title}
            </span>
          );
        },
        Header: "Data Center",
        sortType: "string",
        accessor: "dataCenter.title",
      },

      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {a?.cell?.row?.original?.demoResources?.map(demoResource => { return demoResource.demo.title }).join(", ")}
            </span>
          );
        },
        Header: "Associated Demos",
        accessor: "demoResources.demo.title",
        sortType: (rowA, rowB) => {
          const TitlesA = rowA.original.demoResources?.map(demoResource => demoResource.demo.title).join(", ");
          const TitlesB = rowB.original.demoResources?.map(demoResource => demoResource.demo.title).join(", ");
          if (TitlesA > TitlesB) return 1;
          if (TitlesA < TitlesB) return -1;
          return 0;
        }
      },

      {
        Cell: (a) => {
          return (
            <span style={{ textAlign: "center" }}>
              {
                <NeoIcon
                  style={
                    a?.cell?.row?.original?.isActive
                      ? { color: "#088A08" }
                      : { color: "#DA291C" }
                  }
                  icon={
                    a?.cell?.row?.original?.isActive ? "available" : "missed"
                  }
                />
              }
            </span>
          );
        },
        sortType: "basic",
        Header: "Status",
        accessor: "isActive",
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Cell: (a) => {
          const record = a.cell.row.original;
          const index = a.cell.row.original;
          const isEditDisabled = userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin;
          return isEditDisabled ? null : (
            <React.Fragment>
              <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
                {!deleteView &&
                  <>
                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                      <button
                        onClick={() => {
                          this.props.handleOnClickAction({
                            component: CONFIG.editComponentRoute.resources,
                            tabTitle: record?.name,
                            recordId: record?.id
                          })
                        }}
                        disabled={isEditDisabled}
                        className={!isEditDisabled ? "link" : "link-disabled"}>
                        <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                      </button>
                    </Tooltip>
                  </>
                }

                {!deleteView &&
                  <>
                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                      < button
                        onClick={async () => {
                          await this.props.handleOnClickAction({
                            component: CONFIG.cloneComponentRoute.resources,
                            tabTitle: record?.name,
                            recordId: record?.id,
                            isClone: true
                          }
                          )
                        }}
                        disabled={isEditDisabled}
                        className={!isEditDisabled ? "link" : "link-disabled"}>
                        <span class="neo-icon-copy" title="Clone" style={{ fontSize: "20px" }}></span>
                      </button>
                    </Tooltip>
                  </>
                }

                <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                  {
                    <>
                      <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDelete(record.id, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                      {deleteView && <>
                        <Popconfirm disabled={isEditDisabled} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id, index + 1)}>
                          <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                        </Popconfirm>
                      </>
                      }
                    </>
                  }
                </Tooltip>

              </div>
            </React.Fragment>
          );
        },
        Header: "  ",
        disableFilters: true,
        disableSortBy: true,
      },
    ];

    return isFetching ? (
      <Spin
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        spinning={isFetching}
        indicator={antIcon}
      />
    ) : (
      <NeoTable
        columns={neoColumns}
        data={resources || []}
        className="table-actions"
        allowColumnFilter={false}
        itemsPerPageOptions={[10, 20, 50, 100]}
        showRowSeparator
        initialStatePageIndex={this.state.currentPage}
        handlePageChange={(newPageIndex) => {
          if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
        }}
        {...otherFields}
      />
    );
  };

  handleCloseFilterDrawer = () => {
    this.setState((prevState) => ({
      showFilter: !prevState.showFilter,
    }));
  };

  render() {
    const { demos, timeZone } = this.props;
    const formFields = [
      {
        label: "Title",
        type: CONFIG.formFieldTypes.INPUT,
        key: "name",
        value: this.filterSort?.filter?.name,
        colSpan: 24,
      },
      {
        label: "Data Center",
        type: CONFIG.formFieldTypes.SELECT,
        key: "dataCenter",
        data: this.props.dataCenters || [],
        dataKey: "title",
        valueKey: "title",
        value: this.filterSort?.filter?.dataCenter,
        colSpan: 24,
      },
      {
        label: "Demo",
        type: CONFIG.formFieldTypes.SELECT,
        key: "demo",
        mode: "tags",
        valueKey: "text",
        data: demos?.map(demo => ({ text: demo.title })) || [],
        value: this.filterSort?.filter?.demo,
        colSpan: 24,
      }
    ]

    return (
      <React.Fragment>
        <div
          style={{
            color: "#242424",
            fontSize: "19px",
            fontWeight: "400",
            lineHeight: "28px",
            marginTop: "28px",
          }}
        >
          Manage Resources
        </div>
        <div style={{ marginTop: "16px" }}>
          <Tabs
            disabled={this.isViewDeletedItemsChecked}
            activeKey={this.state.status}
            className="demo-status-tabs"
            defaultActiveKey={this.state.status}
            onChange={this.handleSelectChange}
          >
            <TabPane
              disabled={this.isViewDeletedItemsChecked}
              tab="Active"
              key="true"
            >
              {this.renderTabContents()}
            </TabPane>
            <TabPane
              tab="Inactive"
              key="false"
            >
              {this.renderTabContents()}
            </TabPane>
            <TabPane
              disabled={this.isViewDeletedItemsChecked}
              tab="All"
              key="all"
            >
              {this.renderTabContents()}
            </TabPane>
          </Tabs>
        </div>
        <div className="approvals-pagination-row">
          <div></div>
          <Row>
            {this.state.showFilter && (
              <FilterDrawer
                visible={this.state.showFilter}
                width="520"
                handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                placement="right"
                maskClosable={true}
                panelHeader="Filters and Export"
                formFields={formFields}
                handleClear={this.handleClear}
                handleFilter={this.handleFilter}
                handleSave={this.handleSave}
                timeZone={timeZone}
                hideSoftDeleteCheckbox={false}
                hideSave={false}
                label='View Deleted Resources'
                viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                hideApplyToAll={true}
              />
            )}
          </Row>
        </div>
        {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ resources, user, dataCenters, demos, tabsLayout }) => {
  return {
    dataCenters: dataCenters.data,
    demos: demos.allDemos,
    resources: resources.data,
    isFetching: resources.isFetching,
    resourcesCount: resources.count,
    currentPage: resources.currentPage,
    timeZoneName: user.profile?.timezone,
    userProfile: user.profile,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    resourceFilter: resources.currentFilter,
    currentStatus: resources.status,
    isEdit: resources.isEdit,
    currentPageNumber: resources.currentPageNumber,
    resourceIndex: resources.resourceIndex,
    openedTabs: tabsLayout.panes,
    activeKey: tabsLayout.activeKey
  };
};

const EditableFormTable = Form.create()(ManageResources);

export default connect(
  mapStateToProps,
  {
    fetchDemos,
    toggleBackButton,
    selectTitle,
    clearResources,
    currentResourceFilter,
    fetchDataCenters,
    fetchResourceDetails,
    restoreResource,
    refreshResources,
    toggleHasResourcesUpdated,
    updateActiveTab,
    clearResourceData,
    toggleIsFetchingResourcesFlag,
    handleOnClickAction,
    toggleSaveReportVisible,
    fetchLiteResources
  }
)(EditableFormTable);
