import {
    ADD_NEW_TAB,
    UPDATE_ACTIVE_TAB,
    DELETE_TAB,
    CLOSE_ALL_TABS,
    CLOSE_OTHER_TABS,
    CLOSE_CURRENT_OPEN_TAB,
    TOGGLE_IS_LOCAL_USERS_EDIT_TAB_OPENED,
    RESTORE_TABS_ON_REFRESH,
    UPDATE_TABS_LOCAL_STORAGE,
    HAS_SAVE_CHANGES,
    REPLACE_TAB_DETAILS,
    TOGGLE_HAS_UNSAVED_CHANGES_FLAG,
    CLEAR_HAS_UNSAVED_FLAG,
    CLEAR_TAB_DATA,
    CLOSE_ACTIVE_TAB
} from '../actions/types';

const tabsLayoutState = {
    panes: [],
    activeKey: '',
    fromLocalStorage: false,
    showNewTabWindow: false,
    previousTabs: [],
    hasSave: false,
}

export const tabsLayoutReducer = (state = tabsLayoutState, action) => {
    switch (action.type) {
        case ADD_NEW_TAB:
            const index = state.panes.findIndex(x => x.key === state.activeKey);
            const newPanes = [
                ...state.panes.slice(0, index + 1),
                action.payload,
                ...state.panes.slice(index + 1)
            ];
            return { ...state, previousTabs: state.panes, panes: newPanes, activeKey: action.payload.key };
        case REPLACE_TAB_DETAILS:
            const updatedTabs = state.panes.map((item) => item.id === action.payload[1]?.id ? action.payload[1] : item);
            return { ...state, previousTabs: state.panes, panes: updatedTabs, activeKey: action.payload[1]?.key };
        case UPDATE_TABS_LOCAL_STORAGE:
            return { ...state, panes: state.panes?.map(el => el.key === action.payload ? { ...el, fromLocalStorage: false } : el) };
        case CLEAR_HAS_UNSAVED_FLAG:
            return { ...state, panes: state.panes?.map(el => { return { ...el, hasUnsavedChanges: false } }) };
        case RESTORE_TABS_ON_REFRESH:
            return { ...state, previousTabs: state.panes, panes: action.payload, activeKey: action.payload?.[0]?.key };
        case UPDATE_ACTIVE_TAB:
            return { ...state, previousTabs: state.panes, activeKey: action.payload }
        case CLOSE_ALL_TABS:
            return { ...state, previousTabs: state.panes, panes: [], activeKey: "/getStarted", showNewTabWindow: true }
        case CLOSE_OTHER_TABS:
            return { ...state, previousTabs: state.panes, panes: action.payload }
        case CLOSE_CURRENT_OPEN_TAB:
            let currentTabIndex = state.panes.indexOf(state.panes.filter(pane => pane.key === state.activeKey)?.[0])
            let otherTabs = state.panes.filter(pane => pane.key !== state.activeKey);
            return {
                ...state,
                previousTabs: state.panes,
                activeKey: currentTabIndex > 0
                    ? state.panes?.[currentTabIndex - 1]?.key
                    : state.panes?.[1]?.key,
                panes: otherTabs,
            }
        case CLOSE_ACTIVE_TAB:
            const filteredTabs = state.panes.filter(pane => pane.key !== state.activeKey);
            return {
                ...state,
                previousTabs: state.panes,
                panes: filteredTabs
            }
        case HAS_SAVE_CHANGES:
            return { ...state, hasSave: action.payload }
        case TOGGLE_HAS_UNSAVED_CHANGES_FLAG:
            return { ...state, panes: state.panes?.map(el => el.key === action.payload.key ? { ...el, hasUnsavedChanges: action.payload.hasUnsavedChanges } : el) };
        case DELETE_TAB:
            var deletedTabIndex = state.panes.indexOf(state.panes.filter(pane => pane.key === action.payload[0])?.[0])
            return {
                ...state,
                previousTabs: state.panes,
                panes: state.panes.filter(pane => pane.key !== action.payload[0]),
                activeKey: action.payload[1]
                    ? deletedTabIndex > 0
                        ? state.panes?.[deletedTabIndex - 1]?.key
                        : state.panes?.[1]?.key
                    : state.activeKey,
            }
        case CLEAR_TAB_DATA:
            return { panes: [], activeKey: '', previousTabs: [] }
        default:
            return state;
    }
}

const componentTabsState = {
    isLocalUserTabOpened: false,
    isLocalUserEditTabOpened: false
}

export const componentTabsReducer = (state = componentTabsState, action) => {
    switch (action.type) {
        case TOGGLE_IS_LOCAL_USERS_EDIT_TAB_OPENED:
            return { ...state, isLocalUserEditTabOpened: action.payload };
        default:
            return state;
    }
}