import { batch } from 'react-redux';
import { decrypt } from '../utils/aesDecrypt';
import { aepNetwork } from '../apis';
import {
    FETCH_NETWORKS,
    FETCH_IP_ADDRESSES,
    TOGGLE_IS_FETCHING_IP_ADDRESSES,
    TOGGLE_IS_IP_ADDRESS_FORM_VISIBLE,
    UPDATE_IP_ADDRESS_FORM,
    FETCH_NETWORK_DETAILS,
    CLEAR_NETWORKS,
    UPDATE_NETWORK_DETAILS,
    TOGGLE_IS_FETCHING_NETWORKS,
    UPDATE_NETWORK_ROLE_TEMPLATE,
    CLEAR_NETWORK_DATA,
    FETCH_DELETED_NETWORKS,
    UPDATE_NETWORK_LOGIN_PASSWORD,
    UPDATE_NETWORK_CURRENT_PAGE,
    TOGGLE_IS_IP_ADDRESS_UPDATED,
    FETCH_DELETED_NETWORKS_BY_DC_ID,
    TOGGLE_IS_FETCHING_DELETED_NETWORK,
    TOGGLE_IS_COLLAPSED_STATUS,
    CURRENT_NETWORKS_FILTER,
    UPDATE_SELECTED_NETWORKS_DATACENTER,
    CREATE_NETWORK_DATA
} from './types';
import pushMessage from '../components/common/PushMessage';
import { getUpdatedRoleTemplateAssociations, saveNetworkRoleTemplates } from './roleTemplates';
import CONFIG from '../config';
import { download } from '../utils/file';

export const fetchNetworks = (viewDeletedItems = false) => async dispatch => {

    const response = await aepNetwork.get('/networkManagementService/networks/dataCenter', { params: { viewDeletedItems } });
    const responseData = response?.data

    // Decrypt

    try {
        responseData && responseData.forEach((item) => {
            item.networkAddress =
                item.networkAddress && decrypt(item.networkAddress)
            item.gateway = item.gateway && decrypt(item.gateway)
            if (item.ipaddresses?.length > 0) {
                item.ipaddresses.forEach((ipAddress) => {
                    ipAddress.hostAddress = ipAddress.hostAddress && decrypt(ipAddress.hostAddress)
                    ipAddress.accessLink = ipAddress.accessLink !== null && decrypt(ipAddress.accessLink)
                });
            }
        });

        batch(() => {
            dispatch({
                type: viewDeletedItems ? FETCH_DELETED_NETWORKS : FETCH_NETWORKS,
                payload: responseData || []
            });
            dispatch(toggleIsFetchingNetworks(false))
        });
    } catch (e) {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch network details');;
    }
}

export const fetchDeletedNetworksByDcId = (dataCenterId, filterSort) => async dispatch => {
    const params = {
        filterSort
    }
    const response = await aepNetwork.get(`/networkManagementService/dataCenter/network/${dataCenterId}`, { params });
    const responseData = response?.data?.dataSet
    // Decrypt
    try {
        responseData && responseData.forEach((item) => {
            item.networkAddress = item.networkAddress && decrypt(item.networkAddress)
            item.gateway = item.gateway && decrypt(item.gateway)
        });

        batch(() => {
            dispatch({
                type: FETCH_DELETED_NETWORKS_BY_DC_ID,
                payload: responseData || []
            });
            dispatch(toggleIsFetchingDeletedNetworks(false))
        });
    } catch (e) {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch network details');;
    }
}

export const fetchNetworkDetails = (network) => async dispatch => {
    const response = await aepNetwork.get(`/networkManagementService/networks/${network.id}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const responseData = response?.data
        try {
            responseData.networkAddress = responseData.networkAddress && decrypt(responseData.networkAddress)
            responseData.gateway = responseData.gateway && decrypt(responseData.gateway)
            if (responseData.ipaddresses?.length > 0) {
                responseData.ipaddresses.forEach((ipAddress) => {
                    ipAddress.hostAddress = ipAddress.hostAddress && decrypt(ipAddress.hostAddress)
                    ipAddress.accessLink = ipAddress.accessLink !== null && decrypt(ipAddress.accessLink)
                });
            }

            batch(() => {
                dispatch({
                    type: FETCH_NETWORK_DETAILS,
                    payload: { networkDetails: responseData, selectedNetwork: network }
                });
                dispatch(toggleIsFetchingNetworks(false))
            });
        } catch (e) {
            pushMessage(CONFIG.messageType.error, 'Unable to fetch network details');;
        }
        return responseData;
    }

    else if (response.status !== CONFIG.HTTP_STATUS.NO_CONTENT) pushMessage(CONFIG.messageType.error, 'Unable to fetch network details');
}

export const exportNetworks = async ({ network, assigned, filterSort, viewDeletedItems = false, fileName, fileFormat, timeZone }) => {
    const response = await aepNetwork({
        method: 'get',
        url: `/networkManagementService/export/ipAddresses/${network.id}/${fileFormat}`,
        responseType: "blob",
        params: {
            filterSort,
            viewDeletedItems,
            subnetMask: network.subnetMask,
            hostAddress: network.networkAddress,
            assigned: assigned,
            timeZone
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(fileName, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}

const getRowSpanForIPAddress = (ipAddress, allIPAddresses) => allIPAddresses.filter(obj => obj.hostAddress === ipAddress.hostAddress).length;

const getIPAddressesTableData = (ipAddresses, pageNumber, sort, pageSize) => {
    let parsedIpAddresses = []; // Contains unique set of IP Addresses
    let updatedIPAddresses = []; // Table data
    const startIndex = (pageNumber - 1) * (pageSize ? pageSize : CONFIG.lazyLoadPageSize);
    const endIndex = ipAddresses?.length

    //skip the grouping of ipAddress in case if we are sorting on columns.
    if (!sort) {
        const slicedIPAddresses = ipAddresses.slice(startIndex, ipAddresses.length); // To calculate row span from current page onwards
        // Creating IP Addresses table data
        for (let index = startIndex; index < endIndex; index++) {
            let rowSpan = 0;
            let ipAddress = ipAddresses[index];
            if (!parsedIpAddresses.includes(ipAddress.hostAddress)) {
                rowSpan = getRowSpanForIPAddress(ipAddress, slicedIPAddresses);
                parsedIpAddresses.push(ipAddress.hostAddress);
            }
            updatedIPAddresses.push({ ...ipAddress, rowSpan });
        }
        return updatedIPAddresses;
    } else return ipAddresses.slice(startIndex, endIndex);
}

export const fetchIPAddresses = ({ network, assigned, pageNumber = 1, filterSort, viewDeletedItems = false, pageSize }) => async dispatch => {
    dispatch(toggleIsFetchingIPAddresses(true));
    let updatedIPAddresses
    const params = {
        filterSort,
        viewDeletedItems,
        subnetMask: network.subnetMask,
        hostAddress: network.networkAddress,
        assigned: assigned
    }
    const response = await aepNetwork.get(`/networkManagementService/network/ipaddresses/${network.id}`, { params });
    const responseData = response?.data

    try {
        // Decrypt the hostAddress
        responseData && responseData.forEach((item) => {
            item.hostAddress = item.hostAddress && decrypt(item.hostAddress)
            item.accessLink = item.accessLink !== null && decrypt(item.accessLink)

        });
        if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
            updatedIPAddresses = getIPAddressesTableData(responseData || [], pageNumber, filterSort?.sort, pageSize);
            if (pageNumber !== 1 && updatedIPAddresses.length === 0) {
                updatedIPAddresses = getIPAddressesTableData(responseData || [], pageNumber - 1, filterSort?.sort);
            }

            dispatch({
                type: FETCH_IP_ADDRESSES,
                payload: { updatedIPAddresses, ipAddressCount: responseData.length }
            });
        }
        else if (response.status !== CONFIG.HTTP_STATUS.NO_CONTENT) pushMessage(CONFIG.messageType.error, 'Unable to fetch IP Addreses');
        dispatch(toggleIsFetchingIPAddresses(false));
        return [updatedIPAddresses || [], responseData?.length]
    } catch (e) {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch network details');;
    }
}

export const toggleIsFetchingIPAddresses = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_IP_ADDRESSES,
        payload: flag
    };
}


export const toggleIsFetchingDeletedNetworks = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DELETED_NETWORK,
        payload: flag
    };
}

export const toggleIsFetchingNetworks = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_NETWORKS,
        payload: flag
    }
}

export const toggleIsIPAddressFormVisible = (flag) => {
    return {
        type: TOGGLE_IS_IP_ADDRESS_FORM_VISIBLE,
        payload: flag
    };
}

export const toggleIsIPAddressUpdated = (flag, operation) => {
    return {
        type: TOGGLE_IS_IP_ADDRESS_UPDATED,
        payload: [flag, operation]
    };
}

export const updateIPAddressForm = (ipAddress) => dispatch => {
    batch(() => {
        dispatch({
            type: UPDATE_IP_ADDRESS_FORM,
            payload: ipAddress
        });
        dispatch(toggleIsIPAddressFormVisible(true));
    });
}

export const putNetworkDetails = ({ networkObj, description, gateway, updateRoleTemplates = false }) => async (dispatch, getState) => {
    const networkId = networkObj.id;
    const networks = getState().networks;
    const roleTemplates = networks.current ? networks.current.roleTemplates : [];
    const successMessage = updateRoleTemplates ? "Role templates updated successfully" : "Network details updated successfully";
    const errorMessage = updateRoleTemplates ? "Unable to update role templates" : "Unable to update network details";

    if (!updateRoleTemplates) networkObj = { ...networkObj, description, gateway };
    const requestData = updateRoleTemplates ? { ...networkObj, roleTemplates } : { ...networkObj }
    const response = await aepNetwork.put("/networkManagementService/networks/" + networkId, requestData);

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        if (updateRoleTemplates) {
            dispatch(saveNetworkRoleTemplates())
            dispatch(fetchNetworkDetails(networkObj))
        }
        else dispatch(updateNetworkDetails(networkObj))
        pushMessage(CONFIG.messageType.success, successMessage);
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
}

export const updateNetworkDetails = (networkObj) => {
    return {
        type: UPDATE_NETWORK_DETAILS,
        payload: networkObj
    };
}

export const updateNetworkCurrentPage = (currentPageNumber, pageSize, ipAddressId, ipAddressIndex) => {
    return {
        type: UPDATE_NETWORK_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, ipAddressId, ipAddressIndex]
    }
};

export const getEncryptedPassword = (ipAddress) => async (dispatch) => {
    const payload = {
        loginPassword: ipAddress?.loginPassword
    }
    const response = await aepNetwork({
        method: 'POST',
        url: "/networkManagementService/ipaddress/encryptLoginPassword",
        data: payload
    });
    return response.data || ""
}

export const postUpdatedIPAddress = (ipAddress, pageNumber) => async (dispatch, getState) => {
    dispatch(toggleIsFetchingIPAddresses(true));
    const encryptedPassword = await dispatch(getEncryptedPassword(ipAddress));
    const networkDetails = getState().networks.current;
    const isAdd = ipAddress.id === undefined || ipAddress.id === 0;
    const ipNetworkId = !isAdd ? ipAddress.id : networkDetails.id;
    const data = { ...ipAddress, loginPassword: encryptedPassword }
    if (networkDetails) {
        const response = await aepNetwork({
            method: !isAdd ? 'put' : 'post',
            url: `/networkManagementService/ipaddresses/${ipNetworkId}`,
            data
        });
        const successMessage = !isAdd ? 'IP address updated successfully' : 'IP address added successfully';
        const errorMessage = !isAdd ? 'Unable to update IP address' : 'Unable to add IP address';

        if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
            pushMessage(CONFIG.messageType.success, successMessage);
            dispatch(toggleIsIPAddressUpdated(true, !isAdd ? 'put' : 'post'));
        }
        else pushMessage(CONFIG.messageType.error, errorMessage);
        dispatch(toggleIsFetchingIPAddresses(false));
        dispatch(toggleIsIPAddressFormVisible(false));
    }
}

export const deleteIPAddress = (ipAddress, network, pageNumber, assigned, hardDelete = false) => async dispatch => {
    const response = await aepNetwork.delete(`/networkManagementService/ipAddresses/${ipAddress.id}`, { params: { hardDelete } });

    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, 'IP address deleted successfully')
        : pushMessage(CONFIG.messageType.error, 'Unable to delete IP Address');

    dispatch(toggleIsFetchingIPAddresses(true));
    dispatch(fetchIPAddresses({ network, assigned, pageNumber, viewDeletedItems: hardDelete }));
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const deleteNetworkAddress = (dataCenter, network, hardDelete = false, filterSort) => async dispatch => {
    const response = await aepNetwork.delete(`/networkManagementService/networks/${network.id}`, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, 'Network deleted successfully')
        : pushMessage(CONFIG.messageType.error, 'Unable to delete Network');
    hardDelete && dispatch(fetchDeletedNetworksByDcId(dataCenter?.id, filterSort));
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}


export const restoreNetworkAddress = (dataCenter, network, hardDelete = false, filterSort) => async dispatch => {
    const response = await aepNetwork.put(`/networkManagementService/restore/network/${network?.id}`);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, 'Network restored successfully')
        : pushMessage(CONFIG.messageType.error, 'Unable to restore Network');
    dispatch(fetchDeletedNetworksByDcId(dataCenter?.id, filterSort));
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const restoreIPAddress = (ipAddress, network, pageNumber, assigned, hardDelete = false) => async dispatch => {
    const response = await aepNetwork.put("/networkManagementService/restore/ipAddresses/" + ipAddress);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "IP Address restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore IP Address");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const getNetworkLoginPassword = (ipAddressId, isEdit) => async dispatch => {
    const params = {
        ipAddressId: ipAddressId,
    }
    const response = await aepNetwork.get('/networkManagementService/network/ipaddress/password', { params });
    if (response.status !== CONFIG.HTTP_STATUS.OK) pushMessage(CONFIG.messageType.error, 'Unable to fetch network Login Password');
    if (isEdit && response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: UPDATE_NETWORK_LOGIN_PASSWORD,
            payload: response.data
        });
    }
    return response.status === CONFIG.HTTP_STATUS.OK ? response.data : undefined;
}

export const postNewNetwork = (networkDetails) => async (dispatch, getState) => {
    const networks = getState().networks;
    const roleTemplates = networks.current ? networks.current.roleTemplates : networks?.createData?.roleTemplates || [];
    const requestData = {
        ...networkDetails,
        dataCenter: JSON.parse(networkDetails.dataCenter),
        subnetMask: parseInt(networkDetails.subnetMask),
        ipaddresses: [],
        roleTemplates
    }
    const response = await aepNetwork.post('/networkManagementService/networks', requestData);
    if (response.status === CONFIG.HTTP_STATUS.CREATED) pushMessage(CONFIG.messageType.success, 'Network created successfully');
    else pushMessage(CONFIG.messageType.error, 'Unable to add Network');
    dispatch(clearNetworkData())
}

export const updateNetworkRoleTemplate = (access, accessType, roleType, selectedRoleTemplate) => (dispatch, getState) => {
    const allRoleTemplates = getState().roleTemplates.all;
    const assignedRoleTemplates = getState().networks.current ? getState().networks.current.roleTemplates : [];
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_NETWORK_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access }
    });
}

export const clearNetworks = () => {
    return {
        type: CLEAR_NETWORKS
    }
}

export const clearNetworkData = () => {
    return {
        type: CLEAR_NETWORK_DATA
    }
}

export const toggleIsCollapsedStatusNetwork = (data) => {
    return {
        type: TOGGLE_IS_COLLAPSED_STATUS,
        payload: data
    }
}

export const currentNetworksFilter = (selectedDataCenter, selectedNetwork) => {
    return {
        type: CURRENT_NETWORKS_FILTER,
        payload: { selectedDataCenter, selectedNetwork }
    }
}

export const updateSelectedNetworksDataCenter = (dataCenter) => {
    return {
        type: UPDATE_SELECTED_NETWORKS_DATACENTER,
        payload: dataCenter
    }
};

export const createNetworkData = (selectedDataCenter, values) => {
    return {
        type: CREATE_NETWORK_DATA,
        payload: { selectedDataCenter, values }
    }
}