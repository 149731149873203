import {
    FETCH_ALL_TIPS,
    UPDATE_TIP_DETAILS,
    UPDATE_TIP_INTRO,
    UPDATE_TIP_BODY,
    UPDATE_TIPS_CURRENT_PAGE,
    CLEAR_TIP_DETAILS,
    CLEAR_TIP_BODY,
    CLEAR_TIP_INTRO,
    TOGGLE_IS_FETCHING_TIPS,
    UPDATE_TIP_ROLE_TEMPLATE,
    CLEAR_TIPS,
    FETCH_USER_TIP,
    LAST_TIP_FILTER,
    CLEAR_TIPS_RESPONSE,
    CLEAR_TIPS_TABLE_DATA,
    SAVE_TIPS_ROLE_TEMPLATES,
    CANCEL_TIPS_ROLE_TEMPLATES
} from '../actions/types';
import CONFIG from '../config';

const tipsState = {
    currentPage: 1,
    isFetching: false,
    isReordered: false,
    currentTipId: undefined,
    data: []
};

export const tipsReducer = (state = tipsState, action) => {
    switch (action.type) {
        case FETCH_ALL_TIPS:
            return { ...state, data: [...state.data, ...(action.payload[0].dataSet || [])], count: action.payload[0].count || 0 };
        case FETCH_USER_TIP:
            return { ...state, userTip: action.payload }
        case UPDATE_TIPS_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentTipId: action.payload[2] || undefined, tipIndex: action.payload[3] || undefined };
        case TOGGLE_IS_FETCHING_TIPS:
            return { ...state, isFetching: action.payload };
        case LAST_TIP_FILTER:
            return { ...state, currentFilter: action.payload[0] || undefined, status: action.payload[1], isEdit: true };
        case CLEAR_TIPS:
            return { ...tipsState.currentFilter, ...tipsState }
        case CLEAR_TIPS_RESPONSE:
            return { ...state, response: action.payload };
        case CLEAR_TIPS_TABLE_DATA:
            return { ...state, data: [] }
        default:
            return state;
    }
}

const currentTipState = {
    allRTAdminAccess: undefined,
    allRTUserAccess: undefined,
    isLoading: false
}

export const currentTipReducer = (state = currentTipState, action) => {
    switch (action.type) {
        case UPDATE_TIP_DETAILS:
            return { ...state, ...action.payload, assignedRoleTemplates: action.payload.roleTemplates || [], tipObj: action.tipObj };
        case UPDATE_TIP_ROLE_TEMPLATE:
            return {
                ...state,
                roleTemplates: action.payload.updatedRoleTemplates,
                [action.payload.allAccessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                    ? action.payload.accessType
                    : CONFIG.roleTemplateAccess.none
            }
        case CLEAR_TIP_DETAILS:
            return currentTipState
        case SAVE_TIPS_ROLE_TEMPLATES:
            return { ...state, assignedRoleTemplates: state.roleTemplates };
        case CANCEL_TIPS_ROLE_TEMPLATES:
            return { ...state, roleTemplates: state.assignedRoleTemplates || [], allRTAdminAccess: undefined, allRTUserAccess: undefined };
        default:
            return state
    }
}

const tipIntroState = {
    isContentLoaded: false,
    html: undefined
};

export const tipIntroReducer = (state = tipIntroState, action) => {
    switch (action.type) {
        case UPDATE_TIP_INTRO:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_TIP_INTRO:
            return tipIntroState;
        default:
            return state
    }
}

const tipBodyState = {
    isContentLoaded: false,
    html: undefined
};

export const tipBodyReducer = (state = tipBodyState, action) => {
    switch (action.type) {
        case UPDATE_TIP_BODY:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_TIP_BODY:
            return tipBodyState;
        default:
            return state
    }
}