import React from 'react'
import { Row, Col, Divider, Form, Radio, Checkbox, Alert, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Form as NeoForm, TextArea, Icon } from "@avaya/neo-react";
import { checkScheduleAvailability, clearScheduleFormErrors, updateCustomerInfo } from "../../../actions";
import {
    getInitialValue,
    renderAdditionalFields,
    // createScheduleObject,
    // checkDeletedPurpose,
    getInitialValueForSchedules,
    isAttributeChanged,
    renderNotes
} from "../../common/Scheduler"
import CONFIG from '../../../config';
import ScheduleTimeDetails from "./ScheduleTimeDetails";
import { Select } from "antd5"
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class NeoLabDemoDetailsPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            warningText: '',
            selectedPurpose: props?.currentSchedule ? JSON.stringify(props?.currentSchedule.demoPurpose) : [],
            isFetchingOppNo: false
        }
        this.testingGroup = []
    }

    async componentDidMount() {
        const { currentSchedule, isReschedule } = this.props;
        (!currentSchedule || isReschedule) && this.handleCheckTestingPurpose();
        if (isReschedule) {
            this.setState({ scheduleParticipants: [] })
        }
    }

    handleCheckTestingPurpose = async () => {
        this.testingGroup = this.props.userPurposeTypesLite.filter((purposeType) => { return (purposeType.purpose === "Testing" || purposeType.id === 5) })
        if (this.testingGroup?.length > 0) {
            await this.setState({
                selectedPurpose: this.testingGroup[0]
            });
        }
    }

    renderPurposeTypesOptions = () => {
        const { userPurposeTypesLite } = this.props;
        return userPurposeTypesLite?.length > 0 ? userPurposeTypesLite.map((purposeType) => {
            return <Option key={purposeType.id} value={JSON.stringify(purposeType)}>{purposeType.purpose}</Option>
        }) : []
    }

    handlePurposeSelectChange = async (value) => {
        const purpose = JSON.parse(value);
        const { form } = this.props;

        form.setFieldsValue({
            purposeName: undefined,
            vertical: undefined
        });
        this.setState({
            selectedPurpose: purpose,
            showMessage: false,
        });
        await this.props.handleCurrentScheduleChange(false);
        this.isPurposeDeleted = false
    }

    renderPurpose = () => {
        const { getFieldDecorator } = this.props.form;
        const { currentSchedule, isReschedule } = this.props
        const initialPurpose = currentSchedule && !isReschedule ? this.isPurposeDeleted ? undefined : JSON.stringify(currentSchedule.demoPurpose) : this.state?.selectedPurpose ? JSON.stringify(this.state?.selectedPurpose) : this.testingGroup?.length > 0 ? JSON.stringify(this.testingGroup[0]) : undefined
        return <>
            {(this.isPurposeDeleted && this.state.showMessage) && <Alert
                message="The selected purpose may be inactive or deleted. Please select a different purpose"
                type="warning"
                showIcon
            />}
            <Form.Item label="Purpose">
                {
                    getFieldDecorator("demoPurpose", {
                        rules: [{ required: true, message: 'Please select a purpose' }],
                        initialValue: initialPurpose
                    })(
                        <Select
                            showSearch
                            bordered={false}
                            suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                            className={currentSchedule && currentSchedule?.isCustomerDemoPurpose ? 'organiser-select select-disabled' : 'organiser-select'}
                            placeholder="Select a purpose"
                            size='large'
                            onChange={this.handlePurposeSelectChange}
                            disabled={currentSchedule && currentSchedule?.isCustomerDemoPurpose}
                        >
                            {this.renderPurposeTypesOptions()}
                        </Select>
                    )
                }
            </Form.Item>
        </>
    }

    renderAdditonalQuestions = (eachQuestion, index) => {
        const { getFieldDecorator } = this.props.form
        const { title, id, answers, answerType, isMandatory } = eachQuestion?.question
        const { answerText } = eachQuestion
        return (
            <>
                <span style={{ color: "#242424", fontSize: "14px", fontWeight: "400" }}>
                    {title} {isMandatory && <span style={{ color: "#b51418", fontSize: "12px" }}>*</span>}
                </span>
                <Form.Item>
                    {answerType === CONFIG.demoAnswerTypes.Checkbox
                        ?
                        (getFieldDecorator(`${id}`, {
                            rules: [{ required: isMandatory, message: "Atleast one checkbox must be selected" }],
                            initialValue: answerText?.split('//') || []
                        })(<Checkbox.Group>{answers?.map(opt =>
                            (<Checkbox style={{ color: "#242424" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Checkbox>))}
                        </Checkbox.Group>))
                        : answerType === CONFIG.demoAnswerTypes.RadioButton
                            ? (getFieldDecorator(`${id}`, {
                                rules: [{ required: isMandatory, message: "Please select an option" }],
                                initialValue: answerText || undefined
                            })(
                                <Radio.Group>
                                    {answers?.map(opt =>
                                        (<Radio style={{ color: "#242424" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Radio>))}
                                </Radio.Group>))
                            : (getFieldDecorator(`${id}`, {
                                rules: [{ required: isMandatory, message: "This field cannot be blank" }],
                                initialValue: answerText || undefined
                            })(<TextArea style={{ width: '700px' }} label="  "
                            />))
                    }
                </Form.Item>
            </>)
    }

    handleOpportunityNumberChange = async (customerInfo) => {
        await this.props.updateCustomerInfo(customerInfo);
        await this.props.form.setFieldsValue({ [`Customer Name`]: customerInfo && customerInfo?.status === "Success" ? customerInfo?.opportunity?.AccountName : "" })

    }

    handleNoOpportunityCheckbox = async (value) => {
        if (value) {
            await this.props.updateCustomerInfo({});
            await this.props.form.setFieldsValue({ [`Customer Name`]: "" })
            await this.props.form.setFieldsValue({ [`Opportunity Number`]: "" })
        }
    }

    isFetchingOpportunity = async (flag) => {
        await this.setState({ isFetchingOppNo: flag });
    }

    render() {
        const { demo, form } = this.props;
        const { timezone, selectedResource, startTime, endTime, currentSchedule, isReschedule, customerInfo } = this.props;
        const { additionalDemoQuestions } = this.props.demo;
        const selectedPurpose = form.getFieldValue('demoPurpose') ? JSON.parse(form.getFieldValue('demoPurpose')) : this.state.selectedPurpose
        this.additionalAttributes = getInitialValue(selectedPurpose, this.props.form)
        this.data = currentSchedule && getInitialValueForSchedules(currentSchedule, this.props.form);
        this.data = this.props.isCurrentSchedule ? isAttributeChanged(this.data, selectedPurpose, this.props.form) : this.data
        this.additionalData = this.props.isCurrentSchedule ? this.data : this.additionalAttributes;
        const completeData = this.additionalData;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");
        const selectedParameters = isEditSchedule ? currentSchedule?.parameters?.split(CONFIG.delimiters.selectFilter) : [];
        const params = isEditSchedule ? currentSchedule?.demo?.parameters?.split(CONFIG.delimiters.selectFilter) : demo?.parameters?.split(CONFIG.delimiters.selectFilter)
        const preRequisiteQuestionObj = currentSchedule ? currentSchedule?.demo?.additionalDemoQuestions : additionalDemoQuestions
        const { getFieldDecorator } = this.props.form;
        // const completeData = getInitialValue(selectedPurpose ? JSON.parse(this.props.form.getFieldValue("demoPurpose")) : null, this.props.form);
        const notes = isReschedule ? undefined : currentSchedule ? currentSchedule?.notes : undefined;

        return (
            <Spin indicator={antIcon} spinning={this.state.isFetchingOppNo}>
                <div style={{ marginTop: "16px" }}>
                    <div className='schedule-details'>
                        < ScheduleTimeDetails showResource={true} demo={demo} startTime={startTime} endTime={endTime} timezone={timezone} selectedResource={selectedResource} />
                    </div>
                    <NeoForm className='neo-scheduler-form'>
                        <Row gutter={24} style={{ marginTop: "10px", display: 'flex', flexWrap: "wrap" }}>
                            <Col span={6}>
                                <div>
                                    {this.renderPurpose()}
                                </div>
                            </Col>
                            {(completeData?.length > 0 && this.props.form.getFieldValue("demoPurpose")) && completeData.map((eachItem) => {
                                return renderAdditionalFields(eachItem, completeData?.length, this.props, this.props.verticals, this.props.isCurrentSchedule, isReschedule, true, customerInfo, this.handleOpportunityNumberChange, this.isFetchingOpportunity, this?.props?.userSettings, this.handleNoOpportunityCheckbox)
                            }
                            )}
                        </Row>
                        <Divider className="neo-std-divider" />
                        <Row>
                            {renderNotes(notes, this.props.form)}
                        </Row>
                    </NeoForm>
                    <Divider className="neo-std-divider" />
                    {
                        preRequisiteQuestionObj?.length > 0 && <div>
                            <NeoForm>
                                <div style={{ margin: "10px" }}>
                                    {preRequisiteQuestionObj?.map((ques, index) => { return this.renderAdditonalQuestions(ques, index + 1) })}
                                </div>
                                <Divider className="neo-std-divider" />
                            </NeoForm>
                        </div>
                    }
                    {(isEditSchedule
                        ? (currentSchedule?.demo?.parameters !== "" && currentSchedule?.demo?.parameters !== null && currentSchedule?.demo?.parameters !== undefined)
                        : (demo?.parameters !== "" && demo?.parameters !== null && demo?.parameters !== undefined))
                        && <Row style={{ marginLeft: "1px" }} gutter={20}>
                            <div style={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#242424" }}>
                                Please select desired capabilities
                            </div>
                            <Form.Item
                            >
                                {getFieldDecorator("parameters", {
                                    initialValue: !isReschedule ? isEditSchedule ? selectedParameters : "" : ""
                                })
                                    (params && params?.length > 0 && <Checkbox.Group>{params.map((parameter, index) =>
                                        (<Checkbox key={index} label={parameter} value={parameter}>{parameter}</Checkbox>))}
                                    </Checkbox.Group>)}
                            </Form.Item>
                            <Divider className="approvals-divider" />
                        </Row>}
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = ({ newSchedule, scheduler, user }) => {
    return {
        userPurposeTypesLite: newSchedule.userPurposeTypesLite,
        verticals: newSchedule.verticals,
        timezone: scheduler.selectedTimezone,
        isAddParticipantsEnabled: user.isAddParticipantsEnabled,
        selectedResource: scheduler.selectedResource,
        user: user.profile,
        currentSchedule: newSchedule?.currentSchedule,
        customerInfo: newSchedule?.customerInfo,
        userSettings: user?.configurations
    };
};

export default connect(
    mapStateToProps, { checkScheduleAvailability, clearScheduleFormErrors, updateCustomerInfo })(NeoLabDemoDetailsPage);