import {
    FETCH_ALL_NEWS,
    UPDATE_NEWS_DETAILS,
    UPDATE_NEWS_INTRO,
    UPDATE_NEWS_BODY,
    UPDATE_NEWS_CURRENT_PAGE,
    CLEAR_NEWS_DETAILS,
    CLEAR_NEWS_BODY,
    CLEAR_NEWS_INTRO,
    TOGGLE_IS_FETCHING_NEWS,
    UPDATE_NEWS_ROLE_TEMPLATE,
    FETCH_USER_NEWS,
    FETCH_USER_INACTIVE_NEWS,
    CLEAR_NEWS,
    CLEAR_INACTIVE_NEWS,
    SAVE_NEWS_ROLE_TEMPLATES,
    CANCEL_NEWS_ROLE_TEMPLATES,
    REORDER_NEWS,
    TOGGLE_IS_NEWS_REORDERED,
    FETCH_ACTIVE_NEWS,
    FETCH_INACTIVE_NEWS,
    UPDATE_USER_NEWS_DETAILS,
    LAST_NEWS_FILTER,
    CLEAR_NEWS_RESPONSE,
    REFRESH_NEWS,
    UPDATE_NEWS_FORM_DETAILS_TAB_DATA,
    UPDATE_INTRO_TAB_DATA,
    UPDATE_BODY_TAB_DATA,
    CLEAR_NEWS_FORM_DATA,
    CLEAR_NEWS_DATA,
    UPDATE_NEWS_MEDIA_PATH,
    UPDATE_NEWS_COVER_IMAGE_PATH,
    DELETE_NEWS_MEDIA,
    DELETE_COVER_IMAGE_MEDIA,
    FETCH_DRAFT_NEWS,
    FETCH_ALL_USER_NEWS
} from '../actions/types';
import CONFIG from '../config';

const userNewsState = {
    userNewsData: [],
    userInactiveNews: [],
    currentUserNews: [],
    userAllNews: []
};


export const userNewsReducer = (state = userNewsState, action) => {
    switch (action.type) {
        case FETCH_USER_NEWS:
            return { ...state, userNewsData: action.payload };
        case FETCH_USER_INACTIVE_NEWS:
            return { ...state, userInactiveNews: action.payload };
        case UPDATE_USER_NEWS_DETAILS:
            return { ...state, currentUserNews: action.payload }
        case CLEAR_INACTIVE_NEWS:
            return { ...newsState, userInactiveNews: [], userNewsData: [], currentUserNews: null }
        case FETCH_ALL_USER_NEWS:
            const updatedUserAllNews = state?.userAllNews ? state?.userAllNews?.concat(action.payload.dataSet || []) : action.payload.dataSet;
            return { ...state, userAllNews: updatedUserAllNews, count: action.payload.count };
        default:
            return state;
    }
}
const newsState = {
    currentPage: 1,
    isFetching: false,
    isReordered: false,
    currentPageNumber: undefined,
    currentNewsId: undefined,
    activeFilterSort: {},
    inactiveFilterSort: {},
    currentFilter: {},
    data: []
};

export const newsReducer = (state = newsState, action) => {
    switch (action.type) {
        case FETCH_ALL_NEWS:
            return { ...state, data: action.payload[0].dataSet || [], count: action.payload[0].count };
        case FETCH_ACTIVE_NEWS:
            return { ...state, activeNews: action.payload };
        case FETCH_DRAFT_NEWS:
            return { ...state, draftNews: action.payload };
        case FETCH_INACTIVE_NEWS:
            return { ...state, inActiveNews: action.payload[0].dataSet || [], inActiveNewsCount: action.payload[0].count, response: action.payload[1] };
        case REORDER_NEWS:
            return { ...state, activeNews: action.payload };
        case UPDATE_NEWS_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentNewsId: action.payload[2] || undefined, newsIndex: action.payload[3] || undefined };
        case TOGGLE_IS_FETCHING_NEWS:
            return { ...state, isFetching: action.payload };
        case TOGGLE_IS_NEWS_REORDERED:
            return { ...state, isReordered: action.payload };
        case LAST_NEWS_FILTER:
            return { ...state, activeFilterSort: action.payload[0], inactiveFilterSort: action.payload[1], draftFilterSort: action.payload[2], currentFilter: action.payload[3], status: action.payload[4], isEdit: true }
        case CLEAR_NEWS:
            return { ...newsState, data: state.data };
        case CLEAR_NEWS_RESPONSE:
            return { ...state, response: action.payload };
        case REFRESH_NEWS:
            return { ...state, data: [], count: undefined };
        case CLEAR_NEWS_DATA:
            return { ...state, data: [], inActiveNews: [], activeNews: [], count: undefined, inActiveNewsCount: undefined }
        default:
            return state;
    }
}

const currentNewsState = {
    allRTAdminAccess: undefined,
    allRTUserAccess: undefined,
    newsData: [],
    newsDetailsTabData: [],
    newsIntroTabData: [],
    newsBodyTabData: []
}

export const currentNewsReducer = (state = currentNewsState, action) => {
    switch (action.type) {
        case UPDATE_NEWS_DETAILS:
            return { ...state, newsData: state.newsData.filter(obj => ((parseInt(obj.id) !== parseInt(action.payload?.id)) || (parseInt(obj.id) === parseInt(action.payload?.id) && obj.isClone !== action.payload?.isClone))).concat(action.payload) };

        case UPDATE_NEWS_FORM_DETAILS_TAB_DATA:
            return {
                ...state, newsDetailsTabData: state.newsDetailsTabData.filter(obj => ((parseInt(obj.id) !== parseInt(action.payload.id)) || (parseInt(obj.id) === parseInt(action.payload.id) && obj.isClone !== action.payload.isClone))).concat(action.payload)
            }

        case UPDATE_INTRO_TAB_DATA:
            return {
                ...state,
                newsDetailsTabData: state.newsDetailsTabData?.map(el => (parseInt(el?.id) === parseInt(action.payload?.id) && el.isClone === action.payload?.isClone) ? { ...el, introHtml: action.payload?.editorContent } : el)
            }

        case UPDATE_BODY_TAB_DATA:
            return {
                ...state,
                newsDetailsTabData: state.newsDetailsTabData?.map(el => (parseInt(el?.id) === parseInt(action.payload?.id) && el.isClone === action.payload?.isClone) ? { ...el, bodyHtml: action.payload?.editorContent } : el)
            }

        case UPDATE_NEWS_ROLE_TEMPLATE:
            const updatedData = state.newsDetailsTabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.newsId)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el,
                    details: {
                        ...el.details,
                        roleTemplates: action.payload.updatedRoleTemplates,
                        [action.payload.allAccessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                            ? action.payload.accessType
                            : CONFIG.roleTemplateAccess.none
                    }
                }
                : el)
            return {
                ...state, newsDetailsTabData: updatedData,
            }

        case UPDATE_NEWS_MEDIA_PATH:
            const updatedMediaData = state.newsDetailsTabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.newsId)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el,
                    details: {
                        ...el.details,
                        mediaPath: action.payload.path
                    }
                }
                : el)
            return {
                ...state, newsDetailsTabData: updatedMediaData,
            }


        case UPDATE_NEWS_COVER_IMAGE_PATH:
            const updatedCoverMediaData = state.newsDetailsTabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.newsId)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el,
                    details: {
                        ...el.details,
                        coverImagePath: action.payload.path
                    }
                }
                : el)
            return {
                ...state, newsDetailsTabData: updatedCoverMediaData,
            }

        case DELETE_NEWS_MEDIA:
            const deletedMediaData = state.newsDetailsTabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.newsId)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el,
                    details: {
                        ...el.details,
                        mediaPath: null
                    }
                }
                : el)
            return {
                ...state, newsDetailsTabData: deletedMediaData,
            }

        case DELETE_COVER_IMAGE_MEDIA:
            const deletedCoverImageData = state.newsDetailsTabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.newsId)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el,
                    details: {
                        ...el.details,
                        coverImagePath: null
                    }
                }
                : el)
            return {
                ...state, newsDetailsTabData: deletedCoverImageData,
            }

        case CLEAR_NEWS_DETAILS:
            return { ...currentNewsState, userNews: state.userNews }

        case SAVE_NEWS_ROLE_TEMPLATES:
            const updatedRTSaveData = state.newsDetailsTabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.id)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el, details: {
                        ...el.details,
                        assignedRoleTemplates: el.details?.roleTemplates
                    }
                } : el)
            return { ...state, newsDetailsTabData: updatedRTSaveData };


        case CANCEL_NEWS_ROLE_TEMPLATES:
            const updatedRTCancelData = state.newsDetailsTabData?.map(el =>
                ((parseInt(el.id) === parseInt(action.payload.id)) && (el.isClone === action.payload.isClone))
                    ? {
                        ...el, details: {
                            ...el.details,
                            roleTemplates: el.details.assignedRoleTemplates,
                            allRTUserAccess: undefined,
                            allRTAdminAccess: undefined
                        }
                    } : el)
            return { ...state, newsDetailsTabData: updatedRTCancelData };

        case CLEAR_NEWS_FORM_DATA:
            const updatedNewsData = state.newsData?.filter(el => ((parseInt(el.id) !== parseInt(action.payload.id)) || ((parseInt(el.id) === parseInt(action.payload.id)) && (el?.isClone !== action.payload.isClone))))
            const updatedTabNewsData = state.newsDetailsTabData?.filter(el => ((parseInt(el.id) !== parseInt(action.payload.id)) || ((parseInt(el.id) === parseInt(action.payload.id)) && (el?.isClone !== action.payload.isClone))));
            const updatedNewsIntroTabData = state.newsIntroTabData?.filter(el => ((parseInt(el.id) !== parseInt(action.payload.id)) || ((parseInt(el.id) === parseInt(action.payload.id)) && (el?.isClone !== action.payload.isClone))))
            const updatedNewsBodyTabData = state.newsBodyTabData?.filter(el => ((parseInt(el.id) !== parseInt(action.payload.id)) || ((parseInt(el.id) === parseInt(action.payload.id)) && (el?.isClone !== action.payload.isClone))))
            return { ...state, newsData: updatedNewsData, newsDetailsTabData: updatedTabNewsData, newsIntroTabData: updatedNewsIntroTabData, newsBodyTabData: updatedNewsBodyTabData }

        default:
            return state
    }
}

const newsIntroState = {
    isContentLoaded: false,
    html: undefined
};

export const newsIntroReducer = (state = newsIntroState, action) => {
    switch (action.type) {
        case UPDATE_NEWS_INTRO:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_NEWS_INTRO:
            return newsIntroState;
        default:
            return state
    }
}

const newsBodyState = {
    isContentLoaded: false,
    html: undefined
};

export const newsBodyReducer = (state = newsBodyState, action) => {
    switch (action.type) {
        case UPDATE_NEWS_BODY:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_NEWS_BODY:
            return newsBodyState;
        default:
            return state
    }
}
