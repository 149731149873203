import React from 'react';
import { connect } from 'react-redux';
import { Form, Spin, Checkbox, Radio } from "antd"
import { Icon } from "@avaya/neo-react";
import {
    toggleBackButton,
    clearDemoScheduler,
    getUserDemoById,
    getUserScheduleById,
    clearSolutionDemos,
    updateSelectedResource,
    updateOverridingResource
} from '../../../actions';
import { LoadingOutlined } from '@ant-design/icons';
// import moment from 'moment-timezone';
import ScheduleTimeDetails from "./ScheduleTimeDetails";
class ResourceDetails extends React.Component {

    state = {
        hasChanged: true,
        hasTimeChanged: true,
        demo: undefined,
        loading: true
    }

    onRadioChange = async (event) => {
        await this.props.updateSelectedResource(JSON.parse(event.target.value));
        await this.props.handleCurrentScheduleChange(false);
    }

    handleOverride = async (id) => {
        await this.props.updateOverridingResource(id);
        await this.props.handleFormDisableChange(false);
    }

    renderSingleResourceOptions = (resources) => {
        const { selectedResource, form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <div>
                <Form.Item>
                    {
                        getFieldDecorator("resources", {
                            rules: [{ required: true, message: "Please select a resource to schedule." }],
                            initialValue: selectedResource ? JSON.stringify({ id: selectedResource?.id, name: selectedResource?.name }) : null
                        })(
                            <Radio.Group
                                onChange={this.onRadioChange}
                                className='two-column-radio-group'
                                value={selectedResource ? JSON.stringify({ id: selectedResource?.id, name: selectedResource?.name }) : null}
                            >
                                {resources?.map((resource,) => (
                                    <Radio
                                        disabled={(resource?.isOverride || !!resource?.isUnderMaintenance)}
                                        className='two-column-radio' value={JSON.stringify({ id: resource?.id, name: resource?.name })}>{resource.name}
                                        {resource?.isOverride && <span onClick={() => this.handleOverride(resource?.id)} className='float-right resource-override'>
                                            Override<span title="Temporary Access will be provided to the selected user" style={{ marginLeft: "5px" }}>
                                                <Icon icon='info' />
                                            </span>
                                        </span>}
                                        {
                                            !!resource?.isUnderMaintenance && <span style={{ color: "#da291c", fontSize: "14px" }} title="Currently unavailable" className='float-right'>
                                                <Icon icon="warning" />
                                            </span>
                                        }
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )
                    }
                </Form.Item>
            </div>
        );
    }

    onCheckboxChange = async (value) => {
        const selectedvalues = value?.length > 0 ? value?.map(item => JSON.parse(item)) : []
        await this.props.updateSelectedResource(selectedvalues);
        await this.props.handleCurrentScheduleChange(false);
    }

    renderMultipleResourceOptions = (resources) => {
        const { selectedResource, form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <div>
                <Form.Item>
                    {
                        getFieldDecorator("resources", {
                            rules: [{ required: true, message: "Please select a resource to schedule." }],
                            initialValue: selectedResource?.length > 0 ? selectedResource?.map(item => JSON.stringify({ id: item?.id, name: item?.name })) : null
                        })(
                            <Checkbox.Group
                                onChange={this.onCheckboxChange}
                                className='two-column-radio-group'
                                value={selectedResource?.length > 0 ? selectedResource?.map(item => JSON.stringify({ id: item?.id, name: item?.name })) : null}
                            >
                                {resources?.map((resource) => (
                                    <Checkbox
                                        disabled={(resource?.isOverride || !!resource?.isUnderMaintenance)}
                                        key={resource.id} className='two-column-radio' value={JSON.stringify({ id: resource?.id, name: resource?.name })}>
                                        {resource.name}
                                        {resource?.isOverride && <span onClick={() => this.handleOverride(resource?.id)} className='float-right resource-override'>
                                            Override<span title="Temporary Access will be provided to the selected user" style={{ marginLeft: "5px" }}>
                                                <Icon icon='info' />
                                            </span>
                                        </span>}
                                        {
                                            !!resource?.isUnderMaintenance && <span style={{ color: "#da291c", fontSize: "14px" }} title="Currently unavailable" className='float-right'>
                                                <Icon icon="warning" />
                                            </span>
                                        }
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        )
                    }
                </Form.Item>
            </div>
        );
    }

    render() {
        const { demo, timezone, user, form, startTime, currentSchedule, endTime, resources, isFetchingScheduleData, isMultipleScheduleBooking, allDataCentersNotAvailable, scheduleExists } = this.props;
        const resourcesAvailable = resources && resources?.length > 0;
        const { isBatchEdit } = this.props.match?.params;
        const assigneValue = form.getFieldValue("assignee");
        let assignedUser = !!assigneValue ? JSON.parse(form.getFieldValue("assignee")) : null;
        assignedUser = assignedUser || user;
        return (<>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={isFetchingScheduleData}>
                {resourcesAvailable ? <div className='schedule-details'>
                    < ScheduleTimeDetails showResource={false} demo={demo} startTime={startTime} endTime={endTime} timezone={timezone} />
                    {/* <div className='reosurce-list'> */}
                    <div className='resource-content'>
                        <div className='resource-heading' style={{ color: "#242424" }}><span>Resources</span></div>
                        <div >
                            {(currentSchedule?.id && isBatchEdit) ?
                                this.renderMultipleResourceOptions(resources)
                                : (currentSchedule?.id && !isBatchEdit) ? this.renderSingleResourceOptions(resources)
                                    : (isMultipleScheduleBooking && assignedUser?.isMultipleScheduleBooking) ?
                                        this.renderMultipleResourceOptions(resources)
                                        : this.renderSingleResourceOptions(resources)}
                        </div>
                    </div>
                    {/* </div> */}
                </div> : <div className='no-resource-div'>
                    <Icon icon='change-password' className='no-resource-icon' />
                    <span className='no-resource-text'>{
                        allDataCentersNotAvailable ?
                            "The demo is currently unavailable."
                            : scheduleExists ?
                                "You already have an instance scheduled for this demo on the same time. Please select a different date or time to schedule the demo."
                                : "There are no resources available for the date and time selected for this demo. Please select a different date or time to schedule the demo."
                    }
                    </span>
                </div>}
            </Spin>
        </>
        );
    }
}

const mapStateToProps = ({ scheduler, user, newSchedule }) => {
    return {
        timezone: scheduler.selectedTimezone,
        startTime: scheduler.startTime,
        endTime: scheduler.endTime,
        resourceType: scheduler.resourceType,
        isMultipleDays: user.isMultipleDays,
        userTimeZone: user.profile ? user.profile.timezone : undefined,
        user: user.profile,
        isMultipleScheduleBooking: user.profile?.isMultipleScheduleBooking || false,
        resources: scheduler.resources,
        selectedResource: scheduler.selectedResource,
        currentSchedule: newSchedule.currentSchedule,
        isFetchingScheduleData: scheduler?.isFetchingScheduleData,
        allDataCentersNotAvailable: scheduler?.allDataCentersNotAvailable,
        scheduleExists: scheduler.scheduleExists
    };
}

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        clearDemoScheduler,
        getUserDemoById,
        getUserScheduleById,
        clearSolutionDemos,
        updateSelectedResource,
        updateOverridingResource
    }
)(ResourceDetails);