import React from 'react';
import { Input, Button, Select } from 'antd';
import CONFIG from '../../config';

const { Option } = Select;

const SearchFilter = ({ dataIndex, searchString, setSearchString, confirm, clearFilters, handleClear, handleSearch, type, data }) => {
    const getInputElement = () => {
        switch (type) {
            case CONFIG.formFieldTypes.SELECT:
                return <Select
                    mode="tags"
                    value={searchString}
                    placeholder={`Select ${dataIndex || ''}`}
                    className="bottom-spacing-3 display-block"
                    onChange={value => setSearchString(value ? value : [])}
                    onPressEnter={() => handleSearch(searchString, confirm)}
                >
                    {
                        data.map((dataObj, index) =>
                            <Option key={index} value={dataObj["text"]} title={dataObj["text"]}>{dataObj["text"]}</Option>
                        )
                    }
                </Select>

            default:
                return <Input
                    placeholder={`Search ${dataIndex || ''}`}
                    value={searchString}
                    onChange={e => setSearchString(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(searchString, confirm)}
                    className="bottom-spacing-3 display-block"
                />
        }
    }

    return (
        <div className="search-div">
            {getInputElement()}
            <Button
                type="primary"
                onClick={() => handleSearch(searchString, confirm)}
                icon="search"
                size="small"
                className="filter-btn right-spacing-3"
            >
                Search
            </Button>
            <Button onClick={() => handleClear(clearFilters)} size="small" className="filter-btn">
                Reset
            </Button>
        </div>
    )
}

export default SearchFilter;