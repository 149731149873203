import { batch } from 'react-redux';

import {
    FETCH_ACTIVE_DEMO_RESOURCES,
    UPDATE_SELECTED_RESOURCE_TYPE,
    UPDATE_SCHEDULER_TIMEZONE,
    UPDATE_SCHEDULER_START_END_TIME,
    UPDATE_SCHEDULER_NEW_EVENT,
    TOGGLE_SCHEDULER_MODAL_IS_VISIBLE,
    FETCH_DEMO_PURPOSE_TYPES,
    CLEAR_DEMO_SCHEDULER,
    TOGGLE_IS_FETCHING_SCHEDULER_DATA,
    ADD_DEMO_SCHEDULES,
    UPDATE_CURRENT_DEMO_SCHEDULE,
    REFRESH_SCHEDULER,
    FETCH_USER_DEMO_PURPOSE_TYPES,
    FETCH_DEMO_PURPOSE_TYPES_LITE,
    FETCH_DEMO_VERTICAL_SOLUTIONS_LITE,
    CHECK_SCHEDULE_AVAILABILITY,
    CLEAR_SCHEDULE_FORM_ERRORS,
    UPDATE_NEW_EVENTS,
    CLEAR_NEW_EVENTS,
    ADD_NEW_SCHEDULE_EVENT,
    DELETE_NEW_SCHEDULE_EVENT,
    DATE_CHANGED_SCHEDULER,
    FETCH_AVAILABLE_DEMO_RESOURCES,
    UPDATE_SELECTED_RESOURCE,
    CLEAR_SELECTED_RESOURCE,
    CLEAR_ALL_NEW_EVENTS,
    UPDATE_OVERRIDING_RESOURCE,
    FETCH_CUSTOMER_INFO
} from './types';
import { fetchSolutions } from './solutions';
import { fetchUserSchedules } from './dashboard';
import { closeCurrentOpenTab } from "./tabsLayout"
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import openInfo from '../components/common/Info';
import { aepDemo, aepSchedule, aepLDAP, aepUser, aepEmail, aepSFDC } from '../apis';

/*
    demoId: To fetch active resources for a demo
    startTime, endTime: To fetch schedules for a demo from startTime to endTime after fetching resources
    dashboardSchedule: To fetch resources for a scheduled demo that the user wants to update from dashboard
*/

export const getDateRanges = async (schedule, timeZone, demoId, createdBy) => {
    const data = {
        startDate: schedule.startValue.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
        endDate: schedule.endValue.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
        endSeriesDate: schedule.seriesEndDate.tz(timeZone, true).endOf('day').set('second', 0).set('millisecond', 0).toISOString(),
        dayOfWeek: schedule.freqType === "sw" ? schedule.dayOfWeek : null,
        monthDay: (schedule.freqType === "sm" && schedule.radioToggle === 1) ? schedule.monthDay : null,
        dayName: (schedule.freqType === "sm" && schedule.radioToggle === 2) ? schedule.dayName : null,
        value: (schedule.freqType === "sm" && schedule.radioToggle === 2) ? schedule.value : null,
        demoId,
        createdBy
    }
    const response = await aepSchedule.post('/scheduleMaintenanceService/schedules/dateRanges', data, { params: { timeZone } });
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
    else if (response.status === CONFIG.HTTP_STATUS.NO_CONTENT) return []
    return []
}

export const fetchActiveDemoResources = (demoId) => async (dispatch, getState) => {
    const { profile: { region } } = getState().user;
    const params = {
        regionId: region.id
    }
    const response = await aepDemo.get(`/demoSolutionService/active/${demoId}/resources`, { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ACTIVE_DEMO_RESOURCES,
            payload: response.data || {}
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch resources');
}

export const updateSelectedResourceType = (resourceTypeKey) => ({
    type: UPDATE_SELECTED_RESOURCE_TYPE,
    key: resourceTypeKey
})

/*
    demoId: To fetch schedules for a demo
    startTime, endTime: To fetch schedules for a demo from startTime to endTIme
*/
export const fetchSchedulesOverDuration = (demoId, startTime, endTime) => async (dispatch) => {
    dispatch(toggleIsFetchingSchedulerData(true));

    const response = await aepSchedule.get('/scheduleMaintenanceService/schedulesAndMaintenance', {
        params: {
            demoId,
            startTime,
            endTime
        }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: ADD_DEMO_SCHEDULES,
            payload: response.data || []
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch schedules');

    dispatch(toggleIsFetchingSchedulerData(false));
    return response.data || []
}

export const updateSchedulerTimezone = (timezone, demoId) => (dispatch, getState) => {

    dispatch({
        type: UPDATE_SCHEDULER_TIMEZONE,
        payload: timezone
    });
}

export const updateSchedulerStartEndTime = (startTime, endTime) => {
    return {
        type: UPDATE_SCHEDULER_START_END_TIME,
        payload: { startTime, endTime }
    };
};

/*
    To store the new event that the user has created in the scheduler by dragging in Day view
    This event object is required by the DemoScheduleBasicDetailsForm component to populate 
*/
export const updateSchedulerNewEvent = (event) => {
    return {
        type: UPDATE_SCHEDULER_NEW_EVENT,
        payload: event
    }
}

export const updateNewEvents = (events) => {
    return {
        type: UPDATE_NEW_EVENTS,
        payload: events
    }
}

export const clearAllNewEvents = () => {
    return { type: CLEAR_NEW_EVENTS }
}

export const addNewEventfromScheduler = (event) => {
    return {
        type: ADD_NEW_SCHEDULE_EVENT,
        payload: event
    }
}

export const deleteNewScheduleEvent = (event) => {
    return {
        type: DELETE_NEW_SCHEDULE_EVENT,
        payload: event
    }
}

export const toggleDateChanged = (flag) => {
    return {
        type: DATE_CHANGED_SCHEDULER,
        payload: flag
    }
}

export const toggleSchedulerModalIsVisible = (flag) => {
    return {
        type: TOGGLE_SCHEDULER_MODAL_IS_VISIBLE,
        payload: flag
    };
};

export const fetchDemoPurposeTypes = (isUser, userObj, shouldfetchSolutions) => async (dispatch, getState) => {
    userObj = userObj || getState().user.profile
    const contextApi = isUser ? "/scheduleMaintenanceService/user/demoPurposes/" : "/scheduleMaintenanceService/demoPurposes"
    const response = isUser ? await aepSchedule.post(contextApi, userObj) : await aepSchedule.get(contextApi);
    (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        ? batch(() => {
            dispatch({
                type: isUser ? FETCH_USER_DEMO_PURPOSE_TYPES : FETCH_DEMO_PURPOSE_TYPES,
                payload: response.data || []
            });
            shouldfetchSolutions && dispatch(fetchSolutions(CONFIG.verticalId, true, false, true));
        })
        : pushMessage(CONFIG.messageType.error, 'Unable to fetch purpose types');
};

export const fecthDemoPurposeTypesLite = (userId) => async (dispatch) => {
    const contextApi = userId ? `/scheduleMaintenanceService/lite/user/demoPurposes?userId=${userId}` : "/scheduleMaintenanceService/lite/user/demoPurposes"
    const response = await aepSchedule.get(contextApi);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_DEMO_PURPOSE_TYPES_LITE,
            payload: response?.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch purpose types');
}

export const fetchVerticalSolutionsLite = () => async (dispatch) => {
    const response = await aepDemo.get("/demoSolutionService/lite/user/vertical/solutions");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_DEMO_VERTICAL_SOLUTIONS_LITE,
            payload: response?.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch vertical solutions');
}

export const createLDAPUser = async (scheduleId) => {
    const response = await aepLDAP.post(`/ldapService/users?scheduleId=${scheduleId}`);
    if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.unauthorized)
    }
    else if (response.status !== CONFIG.HTTP_STATUS.OK) throw Error('Unable to create LDAP User');
}

export const createLDAPUserForBatch = async (batchNumber) => {
    const response = await aepLDAP.post(`/ldapService/v1/users/batchSchedules?batchNumber=${batchNumber}`);
    if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.unauthorized)
    }
    else if (response.status !== CONFIG.HTTP_STATUS.OK) throw Error('Unable to create LDAP User');
}

export const addUserToGroup = async (scheduleId) => {
    // eslint-disable-next-line 
    const response = await aepLDAP.post('ldapService/user/add/' + scheduleId)
    if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.unauthorized)
    }
}

export const addUserToGroupForBatch = async (batchNumber) => {
    // eslint-disable-next-line 
    const response = await aepLDAP.post('/ldapService/v1/user/add/batchNumber/' + batchNumber)
    if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.unauthorized)
    }
}

export const removeUserFromGroup = (scheduleId) => {
    aepLDAP.post('/ldapService/user/remove/' + scheduleId)
}

export const removeUserFromGroupForBatch = (batchNumber) => {
    aepLDAP.post('/ldapService/v1/user/remove/batchNumber/' + batchNumber)
}

export const postNewDemoSchedule = (schedule, shouldRemoveUserFromGroup, demoType, isPeriodic, approvalRequired, isMultipleScheduleBooking, isBatchEdit) => async (dispatch) => {
    const successMessage = schedule.id ? 'Schedule updated successfully' : `Schedule is ${demoType === CONFIG.demoTypes.labPocDemo ? approvalRequired ? 'requested' : 'created' : 'created'} successfully`;
    const errorMessage = schedule.id ? 'Unable to update schedule' : `Unable to ${demoType === CONFIG.demoTypes.labPocDemo ? approvalRequired ? 'request' : 'create' : 'create'}  schedule`;
    const contextPath = (((isMultipleScheduleBooking && schedule?.createdBy?.isMultipleScheduleBooking) && !schedule?.id && !schedule?.isReschedule) || (isBatchEdit && schedule?.id)) ? "/scheduleMaintenanceService/v1/batch/schedules" : isPeriodic ? "/scheduleMaintenanceService/periodic/schedules" : "/scheduleMaintenanceService/schedules";
    const response = await aepSchedule({
        method: schedule.id ? 'put' : 'post',
        url: schedule.id ? `${contextPath}/${(((isMultipleScheduleBooking && schedule?.createdBy?.isMultipleScheduleBooking) && !schedule?.id && !schedule?.isReschedule) || (isBatchEdit && schedule?.id)) ? schedule?.batchNumber : schedule.id}` : contextPath,
        data: schedule
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        const scheduleId = schedule.id || response.data?.scheduleId
        const batchNumber = schedule?.batchNumber || response.data?.batchNumber
        if (shouldRemoveUserFromGroup && (scheduleId > 0 && !isBatchEdit)) removeUserFromGroup(scheduleId);
        if (shouldRemoveUserFromGroup && ((batchNumber > 0 && isBatchEdit) || (batchNumber > 0 && scheduleId === 0))) removeUserFromGroupForBatch(batchNumber);
        pushMessage(CONFIG.messageType.success, successMessage);
        dispatch(toggleSchedulerModalIsVisible(false));
        dispatch(updateCurrentDemoSchedule(undefined))
        dispatch(closeCurrentOpenTab())
        // history.replace('/dashboard');

        return [scheduleId, batchNumber];
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        const conflictResponse = response?.data?.errorMessage
        const conflictMessage = `Conflict! ${conflictResponse}. `
        openInfo(CONFIG.warningMessages.scedulerConflict.title, conflictMessage, null, "OK")
        dispatch(toggleSchedulerModalIsVisible(false));
        return false
    }
    else if (response.status === CONFIG.HTTP_STATUS.NOT_ACCEPTABLE) {
        openInfo(CONFIG.warningMessages.scedulerInvalidDate.title, response?.data?.errorMessage, null, "OK");
        dispatch(toggleSchedulerModalIsVisible(false));
        return false
    }
    else {
        pushMessage(CONFIG.messageType.error, errorMessage)
        dispatch(toggleSchedulerModalIsVisible(false));
        return false;
    }
}

export const clearDemoScheduler = () => {
    return {
        type: CLEAR_DEMO_SCHEDULER
    };
};

export const clearAllEvents = () => {
    return {
        type: CLEAR_ALL_NEW_EVENTS
    }
}

export const toggleIsFetchingSchedulerData = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_SCHEDULER_DATA,
        payload: flag
    };
};

export const optOutSchedule = (schedule, demoType) => async (dispatch, getState) => {
    const { fetchUsersSchedulesParams } = getState().dashboard;
    const successMessage = 'The schedule was successfully deleted.';
    const errorMessage = 'Could not delete the schedule. Try again later.';
    const contextPath = "/scheduleMaintenanceService/schedules";
    const response = await aepSchedule({
        method: 'put',
        url: `${contextPath}/${schedule.id}?isOptOut=true`,
        data: schedule
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, successMessage);
        if (fetchUsersSchedulesParams) {
            dispatch(fetchUserSchedules((fetchUsersSchedulesParams)));
            if (fetchUsersSchedulesParams.viewType === CONFIG.dashboard.schedulesViewType.TABLE) {
                fetchUsersSchedulesParams.isExport = true
            }
            dispatch(fetchUserSchedules(fetchUsersSchedulesParams))
            dispatch(fetchUserSchedules(({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD })))
        }
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
}

/*
    event: The schedule that user wants to update from either Scheduler or Dashboard
*/
export const updateCurrentDemoSchedule = (event, fromRescheduleEmail) => (dispatch, getState) => {
    if (!fromRescheduleEmail) {
        batch(() => {
            dispatch({
                type: UPDATE_CURRENT_DEMO_SCHEDULE,
                payload: event
            });
            dispatch(toggleSchedulerModalIsVisible(true));
        });
    }
    else if (fromRescheduleEmail) {
        batch(() => {
            dispatch({
                type: UPDATE_CURRENT_DEMO_SCHEDULE,
                payload: event
            });
            dispatch(toggleSchedulerModalIsVisible(true));
        });
    }
};


/* Since Schedule can be deleted from multiple views, In order to preserve the state of the view,
a new reducer - fetchUsersSchedulesParams is introduced. It contains params needed to restore the view once
the record is deleted. -- This approach can be revisited in the future.*/
export const deleteDemoSchedule = (scheduleId, hardDelete = false, demoType) => async (dispatch, getState) => {
    const { fetchUsersSchedulesParams } = getState().dashboard;
    const deletingEntity = demoType === "builds" ? "Demo build" : "Schedule"
    demoType === CONFIG.demoTypes.standardDemo && removeUserFromGroup(scheduleId)
    const response = await aepSchedule.delete(`/scheduleMaintenanceService/schedules/${scheduleId}`,
        { params: { hardDelete } })
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `${deletingEntity} deleted successfully`);
        if (fetchUsersSchedulesParams) {
            dispatch(fetchUserSchedules((fetchUsersSchedulesParams)));
            if (fetchUsersSchedulesParams.viewType === CONFIG.dashboard.schedulesViewType.TABLE) {
                fetchUsersSchedulesParams.isExport = true
            }
            dispatch(fetchUserSchedules(fetchUsersSchedulesParams))
            dispatch(fetchUserSchedules(({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD })))
        }
    }
    else pushMessage(CONFIG.messageType.error, `Unable to delete ${deletingEntity} `)
    return response?.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const deleteBatchDemoSchedule = (batchNumber) => async (dispatch, getState) => {
    removeUserFromGroupForBatch(batchNumber);
    const response = await aepSchedule.delete(`/scheduleMaintenanceService/v1/delete/schedules/batch/${batchNumber}`)
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Batch Schedules deleted successfully`);
    } else {
        pushMessage(CONFIG.messageType.error, `Unable to delete batch Schedules `)
    }
}

export const optOutBatchDemoSchedule = async (batchNumber) => {
    const response = await aepSchedule.put(`/scheduleMaintenanceService/v1/optOut/schedules/batch/${batchNumber}`)
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Schedule deleted successfully`);
    } else {
        pushMessage(CONFIG.messageType.error, `Unable to delete Schedule`)
    }
}

export const refreshScheduler = (flag) => ({
    type: REFRESH_SCHEDULER,
    payload: flag
})

export const fetchUserNamesBySearchString = async (searchString) => {
    const contextPath = '/userTemplateService/userslist/search'
    const response = await aepUser.get(contextPath, {
        params: {
            searchString,
            sslHandleIncluded: true
        }
    })
    return response.data ? response.data.dataSet : [];
}

export const fetchScheduleParticipantsList = async (searchString, demoId, resourceId, isFullName) => {
    const contextPath = `/demoSolutionService/participants/search?resourceIds=${resourceId}`
    const response = await aepDemo.get(contextPath, {
        params: {
            searchString,
            demoId,
            fullName: isFullName
        }
    })

    let participants = [];
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        participants = [...response?.data?.eapUsers, ...response?.data?.extUsers]
    }
    return response?.data ? participants : [];
}


export const getUserDemoAccess = async (demoId, userObj, scheduleId) => {
    const contextPath = '/demoSolutionService/access/demo/' + demoId
    const params = scheduleId ? { scheduleId: scheduleId } : {};
    const response = await aepDemo.post(contextPath, userObj, { params })
    return response.data ? [response.data.userAccess === CONFIG.demoAccess.fullAccess ? true : false, response.data.demoRequestStatus, response.data.isDeleteEnabled] : [false, 0, false]
}

export const getUserResourceAccess = async (resourceIdsList, userObj) => {
    // eslint-disable-next-line
    const contextPath = '/demoSolutionService/access/resources' + `?resourceIds=${resourceIdsList}`
    const response = await aepDemo.post(contextPath, userObj)
    return response.data
}

export const resendCredentialsForParticipants = async (userId) => {
    const response = await aepEmail.post(`/emailPushNotificationService/externalUser/${userId}/credentials`);
    if (response.status === CONFIG.HTTP_STATUS.OK) pushMessage(CONFIG.messageType.success, 'Email sent successfully.');
    else if (response.status === CONFIG.HTTP_STATUS.NOT_FOUND) pushMessage(CONFIG.messageType.error, 'Could not send email. User no longer exists.');
    else pushMessage(CONFIG.messageType.error, 'Failed to send email.');
}

export const checkScheduleAvailability = (schedules, timeZone) => async (dispatch) => {
    const params = {
        timeZone
    };
    const urlPath = `/scheduleMaintenanceService/checkAvailability/schedules`
    const response = await aepSchedule({
        url: urlPath,
        method: 'POST',
        data: schedules,
        params
    });

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const maintenance = response.data.maintenance
        const conflictingSchedules = response.data.conflictingSchedules
        const possibleSchedules = response.data.possibleSchedules;
        const dateRangeWithConditions = response.data.dateRangeWithConditions;
        const checkAvailability = true

        dispatch({
            type: CHECK_SCHEDULE_AVAILABILITY,
            payload: {
                maintenance,
                conflictingSchedules,
                possibleSchedules,
                dateRangeWithConditions,
                checkAvailability
            }
        });
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, 'Overlapping times. Change the date range and try again.')
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to check availability');
};

export const clearScheduleFormErrors = () => {
    return {
        type: CLEAR_SCHEDULE_FORM_ERRORS
    };
};

export const fetchAvailableDemoResources = ({ demoId, startTime, endTime, organizerId, scheduleId, batchNumber }) => async (dispatch, getState) => {
    const { profile: { region } } = getState().user;
    const params = {
        regionId: region.id,
        startTime: startTime,
        endTime: endTime,
        organizerId: scheduleId ? undefined : organizerId || undefined,
        scheduleId: scheduleId || undefined,
        batchNumber: batchNumber || undefined
    }
    const response = await aepDemo.get(`/demoSolutionService/v1/demo/${demoId}/availableResources`, { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_AVAILABLE_DEMO_RESOURCES,
            payload: response.data || {}
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch resources');
    dispatch(toggleIsFetchingSchedulerData(false));
}

export const updateSelectedResource = (resource) => ({
    type: UPDATE_SELECTED_RESOURCE,
    payload: resource
})

export const updateOverridingResource = (resourceId) => {
    return {
        type: UPDATE_OVERRIDING_RESOURCE,
        payload: resourceId
    }
}

export const clearSelectedResource = () => {
    return {
        type: CLEAR_SELECTED_RESOURCE,
    };
};

export const fetchTimeslotForReschedule = async (resourceId, demoId) => {
    const params = { demoId: demoId }
    const response = await aepDemo.get(`/demoSolutionService/v1/resource/${resourceId}/availableTime`, { params });

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        return response?.data;
    } else return;

};

export const fetchTimeslotForLabDemos = async ({ startTime, demoId, scheduleId, organizerId }) => {
    const params = { startTime: startTime, scheduleId: scheduleId || 0, organizerId: organizerId }
    const response = await aepDemo.get(`/demoSolutionService/v1/cloudDemo/${demoId}/availableTime`, { params });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        return response?.data;
    } else return {};
};

export const getAllDemoPurposesLite = (showTestingPurpose = true) => async () => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/v1/lite/demoPurposes", { params: { showTestingPurpose } });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) return response?.data || [];
    else pushMessage(CONFIG.messageType.error, "Unable to fetch demo purposes");
}

export const getSchedulesForDuration = (demoId, startTime, endTime, demoType) => async () => {
    const response = await aepDemo.get(`/demoSolutionService/v1/demo/${demoId}/schedulingAssistant`, { params: { startTime, endTime, demoType } });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) return response?.data || [];
    else pushMessage(CONFIG.messageType.error, "Unable to fetch schedules");
}

export const getScheduleDetailsForSFDC = (scheduleId, token) => async () => {
    const response = await aepSFDC.get(`/sfdcScheduleService/v1/sfdcOpportunity/schedule/${scheduleId}`,
        { params: { token } });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        return response || null;
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch schedule details");
    return response;
}

export const validateOpportunityNumberForExternalUser = (OppNo, scheduleId) => async () => {
    const response = await aepSFDC.get(`/sfdcScheduleService/v1/sfdcOpportunity/${OppNo}`,
        { params: { scheduleId } });
    if (response.status === CONFIG.HTTP_STATUS.OK) return response?.data || []
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch customer name');
    return null;
}


export const fetchOpportunityDetails = async (opportunityNumber) => {
    const response = await aepSchedule.get(`/sfdcScheduleService/v1/sfdcOpportunity/${opportunityNumber}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        return response?.data || []
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch customer name');
    return [];
}


export const updateScheduleForExternalUser = (scheduleId, data, token) => async () => {
    const response = await aepSFDC.patch(`/sfdcScheduleService/v1/sfdcOpportunity`,
        data,
        { params: { scheduleId, token } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Opportunity Number Updated Successfully');
        return true
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update Opportunity Number');
}

export const updateAccountOwnerEmail = (scheduleId, email) => async () => {
    const data = { accountOwnerEmail: email }
    const response = await aepSFDC.patch(`/sfdcScheduleService/v1/sfdcOpportunity/accountOwner`,
        data,
        { params: { scheduleId } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Account owner email updated successfully');
        return true
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update Account owner email');
}


export const updateCustomerInfo = (customerInfo) => (dispatch, getState) => {
    dispatch({
        type: FETCH_CUSTOMER_INFO,
        payload: customerInfo || []
    });
}
