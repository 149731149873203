import React, { Component } from 'react'
import { Comment, Tooltip, Avatar, Form, Input, Button, Rate, Result } from 'antd';
import { getAvatarColor } from '../../utils/avatarColorPicker'
import CONFIG from '../../config';
import { getEZDemoReviewByAuditId, updateEZDemoFeedback, handleOnClickAction } from '../../actions'
import { connect } from 'react-redux';
import moment from 'moment-timezone';

const { TextArea } = Input;

class EZDemoRatingNotification extends Component {
    state = { printPreview: false };

    editor = () => {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className='antd-button-fix-2'>
                <Form.Item >
                    {getFieldDecorator("adminReply",
                        {
                            rules: [
                                { required: true, message: "Reply cannot be blank." },
                            ]
                        })
                        (<TextArea rows={3} />)
                    }
                </Form.Item>
            </div>
        )
    }

    handleSubmitReply = async (isEdit, isDelete = false) => {
        const { ezDemoReview, onCancel } = this.props
        // API call to submit Super Admin's reply
        this.props.form.validateFields(async (err, values) => {
            if (err) return;
            const payload = {
                id: parseInt(ezDemoReview?.ezDemoAuditRating?.id),
                isAdminReply: true,
                replyComment: isDelete ? null : values?.adminReply,
            }
            await this.props.updateEZDemoFeedback(ezDemoReview?.ezDemoAuditRating?.id, payload, isEdit, isDelete)
            this.setState({
                replyVisible: false,
            })
            onCancel();
        });
    }


    componentDidMount() {
        const { ezDemoAuditId } = this.props
        this.props.getEZDemoReviewByAuditId(ezDemoAuditId)
    }

    renderLowRatingNotificationContent = () => {
        const { ezDemoReview, onCancel, timeZone, notificationType,handleDropdownClose } = this.props
        const { ezDemo, createdBy, ezDemoAuditRating } = ezDemoReview || []
        const { ratingTime, rating, comment, replyComment, replyBy, replyTime } = ezDemoAuditRating || []
        return (
            <>
                <h2><b>{notificationType === CONFIG.ezDemoRatingNotificationTypes[0] ? "Low Rating received!" : "Admin replied to your Review!"}</b></h2>
                <h3>EZ Demo : {ezDemo?.title}</h3>
                {ezDemoAuditRating === null
                    ?
                    <Result
                        status="warning"
                        title="The item is no longer available!"
                    />
                    : <Comment
                        author={<span className="demo-reviews-author-header">
                            {createdBy?.fullName}
                            <Rate style={{ color: '#F38D00'}}
                                className="review-Rating"
                                disabled allowHalf
                                value={rating} />
                            {<Tooltip title={moment.tz(ratingTime, timeZone).format(CONFIG.dateFormats.userDateTime)}><span>{moment.tz(ratingTime, timeZone).fromNow()}</span></Tooltip>}
                        </span>
                        }
                        actions={
                            replyComment === null && [
                                !this.state?.replyVisible && <span onClick={() => this.setState({ replyVisible: true })} key="comment-basic-reply-to">Reply</span>,
                                this.state?.replyVisible && <Button className="primary-action-button-filled " onClick={() => this.handleSubmitReply(true)} key="comment-basic-reply-to">Add Comment</Button>,
                                this.state?.replyVisible && <Button className="left-spacing cancel-button brandLogo" onClick={() => this.setState({ replyVisible: false })} key="comment-basic-reply-to">Cancel</Button>
                            ]
                        }
                        avatar={<Avatar style={{ backgroundColor: getAvatarColor(createdBy?.firstName?.charAt(0)) }}>{createdBy?.firstName ? createdBy?.firstName?.charAt(0) : ''}</Avatar>}
                        content={
                            <>
                                <p>{comment}{this.state?.replyVisible && this.editor()}</p>

                                {/* Admin's reply */}
                                {replyComment !== null && <Comment
                                    author={<span className="demo-reviews-author-header">
                                        {replyBy?.fullName}&emsp;
                                        {<Tooltip title={moment.tz(replyTime, timeZone).format(CONFIG.dateFormats.userDateTime)}><span>{moment.tz(replyTime, timeZone).fromNow()}</span></Tooltip>}
                                    </span>
                                    }
                                    avatar={<Avatar style={{ backgroundColor: getAvatarColor(replyBy?.firstName?.charAt(0)) }}>{replyBy?.firstName ? replyBy?.firstName?.charAt(0) : ''}</Avatar>}
                                    content={<p>{replyComment}</p>}
                                />}
                            </>
                        }
                    />}
                    {notificationType === CONFIG.ezDemoRatingNotificationTypes[0] ?
                    <h3>To manage EZ Demo reviews, click
                        <span
                            className='link'
                            onClick={() => {
                                this.props.handleOnClickAction({
                                    component: CONFIG.cloneComponentRoute.ezDemosRating,
                                    tabTitle: ezDemoReview?.ezDemo?.title,
                                    recordId: ezDemoReview?.ezDemo?.id
                                }
                                );
                                onCancel();
                                handleDropdownClose();
                            }}

                        > here</span>.</h3>
                    : null
                }
                <div style={{display:'flex', justifyContent:'flex-end'}}><Button className="primary-action-button-filled float-right" onClick={onCancel}> Close </Button></div>
            </>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderLowRatingNotificationContent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ demoSolutionAccess, ezDemoFeedback, user }) => {
    return {
        demoAccessInformation: demoSolutionAccess.demoAccessInformation,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        ezDemoReview: ezDemoFeedback.ezDemoByAuditId,
        timeZone: user.profile.timezone,
    };
};
const EZDemoRatingNotificationForm = Form.create({ name: "ezDemosForm" })(EZDemoRatingNotification)

export default connect(
    mapStateToProps, {
    getEZDemoReviewByAuditId,
    updateEZDemoFeedback,
    handleOnClickAction
}
)(EZDemoRatingNotificationForm);