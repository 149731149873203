import React from 'react';
import CONFIG from '../../config';
import { Form } from "antd";
import { TextInput, Switch, TextArea } from 'neo-latest';
import moment from 'moment-timezone';

function SFDCStatsDetails(props) {
    const { record, timezone, setArchieved, setNotes, readOnly } = props;
    return (
        <div>
            <div style={{ fontSize: "12px", fontWeight: "400", color: "#323232", lineHeight: "16px", marginBottom: "4px" }}>
                Demo Title
            </div>
            <div style={{ padding: "8px 0", fontSize: "14px", fontWeight: "400", color: "#000", lineHeight: "20px" }}>
                {(record?.demo?.title || "-")}
            </div>
            <div style={{ marginTop: "16px", display: "flex" }}>
                <TextInput label="Resource" value={(record?.resource?.name || "-")} readOnly />
            </div>
            <div style={{ marginTop: "16px", display: "flex" }}>
                <div style={{ display: "flex", flex: "1" }}>
                    <TextInput label="Demo Purpose" value={(record?.demoPurpose || "-")} readOnly />
                </div>
                <div style={{ display: "flex", flex: "1" }}>
                    <TextInput label={"Handle"} value={((record?.sslHandle) || "-")} readOnly />
                </div>
            </div>
            <div style={{ marginTop: "16px", display: "flex" }}>
                <div style={{ display: "flex", flex: "1" }}>
                    <TextInput label="Start Time" value={record?.startTime ? moment.tz(record?.startTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                </div>
            </div>
            <div style={{ marginTop: "16px", display: "flex" }}>
                <div style={{ display: "flex", flex: "1" }}>
                    <TextInput label="End Time" value={record?.endTime ? moment.tz(record?.endTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                </div>
            </div>
            <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
                <div style={{ display: "flex", flex: "1" }}>
                    <TextInput label="Created By" value={((record?.createdBy?.fullName) || "-")} readOnly />
                </div>
                <div style={{ display: "flex", flex: "1", minWidth: '200px' }}>
                    <TextInput label="Account Owner" value={record?.opportunity?.accountOwnerEmail || "-"} readOnly />
                </div>
            </div>
            <div style={{ marginTop: "16px", }}>
                {readOnly ? <TextInput label=" Archive Status" value={((record?.isArchived ? "Yes" : "No") || "-")} readOnly />
                    : <><div style={{ fontSize: "12px", fontWeight: "400", color: "#323232", lineHeight: "16px", marginBottom: "4px" }}>
                        Archive Status
                    </div>
                        <div style={{ marginTop: "12px", }}>
                            <Switch defaultChecked={record?.isArchived} onChange={(event) => {
                                setArchieved(event.target.checked);
                            }}>
                                Archive
                            </Switch>
                        </div>
                    </>}
            </div>
            <div style={{ marginTop: "16px", }}>
                {readOnly ? <TextInput label="Notes" value={((record?.archiveNotes) || "-")} readOnly /> : <TextArea defaultValue={record?.archiveNotes} onChange={(event) => {
                    setNotes(event.target.value);
                }} label="Notes" />}
            </div>
        </div>
    )
}

const SFDCStatsDetailsForm = Form.create()(SFDCStatsDetails);

export default SFDCStatsDetailsForm;