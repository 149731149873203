import React from 'react';
import { Form } from 'antd';
import { Select } from 'antd5';
import moment from 'moment-timezone';

const { Option } = Select;

const renderTimeZoneOptions = () => {
    const timezones = moment.tz.names();
    return timezones.map((timezone, index) => {
        return <Option key={index} value={timezone}>{timezone}</Option>;
    });
}

const TimeZone = (props) => {
    let getFieldDecorator;
    if (props.form) getFieldDecorator = props.form.getFieldDecorator;
    const selectProps = !props.form ? {
        defaultValue: props.defaultValue
    } : {};
    const timezoneSelect = <Select {...selectProps} className={!props.fullWidth ? "medium-select" : ""} placeholder="Select a timezone" showSearch onChange={props.handleChange}>{renderTimeZoneOptions()}</Select>
    return props.form
        ? (
            <Form.Item className='font' >
                {getFieldDecorator('timezone', {
                    rules: [{ required: true, message: 'Please select a Timezone' }],
                    initialValue: props.defaultValue
                })(timezoneSelect)}
            </Form.Item>
        )
        : timezoneSelect
}

export default TimeZone;