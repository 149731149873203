import React from 'react'
import { Form, Divider } from 'antd';
import { updateTabLimit, putTestingMode, updateTestingEmails, putSFDCBypass } from "../actions";
import { fetchEmailsBySearchString } from '../actions/reports';
import { connect } from 'react-redux';
import CONFIG from '../config';
import { Icon, Switch, Button } from 'neo-latest';
import { Alert } from "antd5";
import { Select, Spin, Tooltip } from "antd"
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

class AdminSettings extends React.Component {

    state = {
        matchedUserEmails: [],
        isEditDeveloperList: false,
        loading: false
    }

    getLimitDropdown = () => {
        return (
            Array.from({ length: 16 }, (_, i) => i + 5).map(i => (
                <Option key={i} value={i}>{i}</Option>
            ))
        )
    }

    handleTabLimitSelect = (userType, limit) => {
        if (!this.props.userSettings?.isFirstTimeLogin) this.props.updateTabLimit(userType, limit);
    }

    handleTestModeToggle = async (event) => {
        const flag = event.target.checked
        const hasChanged = await this.props.putTestingMode(flag)
        hasChanged && setTimeout(function () {
            window.location.reload();
        }, 3000);
    }

    handleSFDCBypass = async (event) => {
        const flag = event.target.checked
        const hasChanged = await this.props.putSFDCBypass(flag);
        hasChanged && setTimeout(function () {
            window.location.reload();
        }, 3000);
    }

    fetchUserEmails = searchString => {
        fetchEmailsBySearchString({ searchString })
            .then((result) => { result.length !== 0 ? this.setState({ matchedUserEmails: [...new Set(result.map(item => item.email))] }) : this.setState({ matchedUserEmails: [] }) });
    };

    renderUserEmails = () => {
        if (this.state.matchedUserEmails) {
            return this.state.matchedUserEmails?.map(email => {
                return (
                    <Option key={email} value={email}>
                        {email}
                    </Option>
                );
            });
        }
        return;
    }

    handleCancelDeveloperList = () => {
        this.props.form.resetFields(["testingRecipients"]);
        this.setState({ isEditDeveloperList: false })
    }

    handleSaveDeveloperList = async () => {
        this.setState({ loading: true })
        await this.props.updateTestingEmails(this.props.form.getFieldValue('testingRecipients'));
        this.setState({ isEditDeveloperList: false, loading: false })
    }

    render() {
        const { form, userSettings } = this.props;
        const { getFieldDecorator } = form || {};
        return (
            <div style={{ marginBottom: "40px" }}>
                <div style={{ display: "flex", flex: "1", color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px" }}>
                    Admin Settings
                </div>
                <Divider />
                <div style={{ marginTop: "16px", }}>
                    <div style={{ fontSize: "16px", fontWeight: "600", color: "#242424", lineHeight: "24px" }}>
                        Maximum Tabs Allowed
                    </div>
                </div>
                <Form>
                    <div style={{ marginTop: "16px", display: "flex", gap: "32px" }}>
                        <div style={{ flex: "1" }}>
                            <Form.Item colon={false} label={<span style={{ fontSize: "12px", fontWeight: "400", color: "#323232" }}>Super Admin</span>}>
                                {getFieldDecorator("superAdminTabLimit", {
                                    initialValue: this.props.userSettings?.superAdminTabLimit
                                })(
                                    <Select
                                        onSelect={(e) => this.handleTabLimitSelect(CONFIG.roleTypes.superAdmin, e)}
                                        className="organiser-select"
                                        size='large'
                                        suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                    >
                                        {this.getLimitDropdown()}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                        <div style={{ flex: "1" }}>
                            <Form.Item colon={false} label={<span style={{ fontSize: "12px", fontWeight: "400", color: "#323232" }}>Admin</span>}>
                                {getFieldDecorator("adminTabLimit", {
                                    initialValue: this.props.userSettings?.adminTabLimit
                                })(
                                    <Select
                                        onSelect={(e) => this.handleTabLimitSelect(CONFIG.roleTypes.admin, e)}
                                        className="organiser-select"
                                        size='large'
                                        suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}>
                                        {this.getLimitDropdown()}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                        <div style={{ flex: "1" }}>
                            <Form.Item colon={false} label={<span style={{ fontSize: "12px", fontWeight: "400", color: "#323232" }}>Users</span>}>
                                {getFieldDecorator("userTabLimit", {
                                    initialValue: this.props.userSettings?.userTabLimit
                                })(
                                    <Select
                                        onSelect={(e) => this.handleTabLimitSelect(CONFIG.roleTypes.user + "," + CONFIG.roleTypes.default, e)}
                                        className="organiser-select"
                                        size='large'
                                        suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                    >
                                        {this.getLimitDropdown(20)}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                        <div style={{ flex: "2" }}></div>
                    </div>
                    <Divider />
                </Form>
                <div style={{ marginTop: "16px", }}>
                    <div style={{ fontSize: "16px", fontWeight: "600", color: "#242424", lineHeight: "24px" }}>
                        SFDC Opportunity Bypass &nbsp;
                        <Tooltip title="By enabling bypass, users can schedule demos without entering opportunities."><Icon style={{ color: "#1b77af" }} icon="info" /></Tooltip>
                    </div>
                    <div style={{ width: "200px", marginTop: "16px" }}>
                        <span>
                            <Switch
                                defaultChecked={userSettings?.isSfdcByPass}
                                onChange={(e) => this.handleSFDCBypass(e)}
                            >
                                Opportunity Bypass
                            </Switch>
                        </span>
                    </div>
                    <Divider />
                </div>
                <div>
                    <Spin spinning={this.state.loading} indicator={antIcon}>
                        <div style={{ marginTop: "16px", }}>
                            <div style={{ fontSize: "16px", fontWeight: "600", color: "#242424", lineHeight: "24px" }}>
                                Developer Options
                            </div>
                        </div>
                        <div>
                            <Alert
                                className="vertical-spacing-2"
                                message="Warning"
                                description={CONFIG.warningMessages.developerOptions}
                                type="warning"
                                showIcon
                            />
                        </div>
                        <div>
                            <div style={{ width: "150px" }}>
                                <span>
                                    <Switch
                                        defaultChecked={this.props.isTestingMode}
                                        onChange={(e) => this.handleTestModeToggle(e)}
                                    >
                                        Testing Mode
                                    </Switch>
                                </span>
                            </div>
                            <div style={{ marginTop: "16px", display: "flex" }}>
                                <div>
                                    <Form.Item colon={false} label={<span style={{ fontSize: "12px", fontWeight: "400", color: "#323232" }}>Test Email Recipients</span>}>
                                        {getFieldDecorator("testingRecipients", {
                                            initialValue: this.props.testingRecipients || []
                                        })(
                                            <Select
                                                disabled={!this.state.isEditDeveloperList}
                                                mode="tags"
                                                className="organiser-select"
                                                size='large'
                                                style={{
                                                    width: '350px',
                                                }}
                                                suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                                onSearch={this.fetchUserEmails}
                                            >
                                                {this.renderUserEmails()}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </div>
                                <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "3px", marginLeft: "32px" }}>
                                    {
                                        this.state.isEditDeveloperList ? [
                                            <Button onClick={this.handleCancelDeveloperList} className="left-spacing-3" variant="secondary">Cancel</Button>,
                                            <Button onClick={this.handleSaveDeveloperList} className="left-spacing-3" status="default">Save</Button>,
                                        ] : <Button onClick={() => this.setState({ isEditDeveloperList: true })} className="left-spacing-3" status="default" >Edit</Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
                <Divider />
            </div>
        )
    }
}

const AdminSettingsForm = Form.create()(AdminSettings);

const mapStateToProps = ({ user }) => {
    return {
        timeZoneName: user.profile?.timezone,
        developerMode: user.profile?.isDeveloper,
        isTestingMode: user.configurations?.isTestingEnvironment,
        userSettings: user?.configurations,
        testingRecipients: user.userSettings?.testingRecipients,
    };
};

export default connect(mapStateToProps, {
    updateTabLimit, putTestingMode, updateTestingEmails, putSFDCBypass
})(AdminSettingsForm);