import {
    TOGGLE_IS_FETCHING_PRODUCTS,
    GET_ALL_PRODUCTS,
    CLEAR_ALL_PRODUCTS,
    UPDATE_PRODUCT_EDITING_KEY,
    GET_ALL_PRODUCT,
    REMOVE_EMPTY_PRODUCT,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    LAST_PRODUCT_FILTERS,
    REFRESH_PRODUCTS
} from "../actions/types";

const productState = {
    data: [],
    isFetching: false,
    editingKey: ""
}

export const productReducer = (state = productState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_PRODUCTS:
            return { ...state, isFetching: action.payload };
        case CLEAR_ALL_PRODUCTS:
            return { state: productState, productData: state.productData, editingKey: "", data: [] };
        case GET_ALL_PRODUCTS:
            return { ...state, data: action?.payload || [], count: action.payload?.length || 0, isTabOpen: false };
        case GET_ALL_PRODUCT:
            return { ...state, productData: action.payload?.dataSet || [] }
        case UPDATE_PRODUCT_EDITING_KEY:
            return { ...state, editingKey: action.payload }
        case ADD_PRODUCT:
            return state.data
                ? { ...state, data: [action.payload, ...state.data], editingKey: action.payload.id }
                : { ...state, data: [action.payload], editingKey: action.payload.id }
        case REMOVE_EMPTY_PRODUCT:
            return { ...state, data: state.data.filter(product => product.title !== "") }
        case UPDATE_PRODUCT:
            return {
                ...state,
                data: state.data.map(product =>
                    product.id === action.payload.id
                        ? action.payload
                        : product
                )
            };
        case REFRESH_PRODUCTS:
            return { ...state, data: [], count: undefined }
        case LAST_PRODUCT_FILTERS:
            return { ...state, filterSort: action.payload[0], status: action.payload[1], pageNumber: action.payload[2], pageSize: action.payload[3], isTabOpen: true }
        default: return state
    }
}
