import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Modal, Button } from 'antd';

import {
    saveNetworkRoleTemplates,
    cancelNetworkRoleTemplates,
    updateNetworkRoleTemplate,
    putNetworkDetails
} from '../../actions';
import FormActionButtons from '../common/FormActionButtons';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import CONFIG from '../../config';

class NetworkRoleTemplates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    getAssignedRoleTemplates = (isSuperAdminUser) => {
        const { networkDetails, selectedRoleTemplates } = this.props;
        let assignedRoleTemplates = [];
        const rts = selectedRoleTemplates ?? networkDetails?.roleTemplates;
        if ((rts?.length !== 0 && rts !== undefined) || (networkDetails !== undefined && rts?.length > 0)) {
            rts.forEach(obj => {
                const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            })
        }
        return assignedRoleTemplates;
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleRoleTemplatesCancel = e => {
        this.props.cancelNetworkRoleTemplates();
        this.setState({
            visible: false
        });
    };

    handleRoleTemplatesAssign = () => {
        const { isEditing, saveNetworkRoleTemplates, putNetworkDetails, selectedNetwork } = this.props;
        isEditing ? putNetworkDetails({ networkObj: selectedNetwork, updateRoleTemplates: true }) : saveNetworkRoleTemplates();
        this.setState({ visible: false });
    }

    renderAssignButton = () => {
        return <Button className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
    }

    render() {
        const { roleTemplates, form, user, networkDetails, allRTAdminAccess, selectedRoleTemplates } = this.props;
        const { getFieldDecorator } = form;
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
        return (
            <Form.Item label="Assign Role Template">
                {getFieldDecorator("roleTemplates", {
                    initialValue: assignedRoleTemplates.length !== 0
                        ? assignedRoleTemplates
                        : "None"
                })(<Input className="role-template-input" disabled addonAfter={this.renderAssignButton()} />)}
                {<Modal
                    title="Role Template"
                    visible={this.state.visible}
                    onCancel={this.handleRoleTemplatesCancel}
                    footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                >
                    <RoleTemplatesTable
                        dataKey="roleTemplate"
                        data={roleTemplates.all}
                        pagination={false}
                        assignedData={(networkDetails?.roleTemplates?.length ? networkDetails?.roleTemplates : selectedRoleTemplates || [])}
                        allAdminAccess={allRTAdminAccess}
                        hideColumns={['user']}
                        updateRoleTemplateData={this.props.updateNetworkRoleTemplate}
                    />
                </Modal>
                }
            </Form.Item>

        )
    }
}



const mapStateToProps = ({ user, roleTemplates, networks }) => {
    return {
        user: user.profile,
        roleTemplates,
        allRTAdminAccess: networks.allRTAdminAccess,
        networkDetails: networks.current,
        selectedNetwork: networks.selectedNetwork,
        selectedRoleTemplates: networks?.createData?.roleTemplates
    };
};

export default connect(
    mapStateToProps,
    {
        saveNetworkRoleTemplates,
        cancelNetworkRoleTemplates,
        updateNetworkRoleTemplate,
        putNetworkDetails
    }
)(NetworkRoleTemplates);