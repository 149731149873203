import React from "react";
// import { message } from "antd";
import { notification, message } from "antd5";
import CONFIG from '../../config';

const pushMessage = (type, msg, delay) => {
    type !== CONFIG.messageType.loading && message.destroy();
    let content = <span className="font" style={{ color: "dark-gray" }}>{msg}</span>
    switch (type) {
        case CONFIG.messageType.error:
            notification.error({
                className: "action-notification",
                message: 'Something went wrong!',
                description: msg,
                placement: "bottomRight",
                duration: delay
            });
            break;
        case CONFIG.messageType.success:
            notification.success({
                className: "action-notification",
                message: null,
                description: msg,
                placement: "bottomRight",
                duration: delay
            })
            break;
        case CONFIG.messageType.warning:
            notification.warning({
                message: 'Warning',
                className: "action-notification",
                description: msg,
                placement: "bottomRight",
                duration: delay
            })
            break;
        case CONFIG.messageType.info:
            notification.info({
                message: 'Note',
                className: "action-notification",
                description: msg,
                placement: "bottomRight",
                duration: delay
            })
            break;
        case CONFIG.messageType.loading:
            return message.open({
                type: "loading",
                content: content,
                duration: delay,
            });
        default:
            return
    }
};

export default pushMessage;