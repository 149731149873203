import { Collapse, Col, Row, Form, Checkbox, Radio, Divider } from 'antd'
import React from 'react';
import { Dropdown } from 'antd5'
import { connect } from 'react-redux';
import CONFIG from '../../../config';
import moment from 'moment-timezone';
import { Icon } from "@avaya/neo-react"
import { getUserInformationCard } from "../../../utils/getUserInformationCard";
import CapabilityDetailsModal from '../../CapabilityRequests/CapabilityDetailsModal';
import {
    openLinkInNewTab,
    putCapabilityRequest
} from '../../../actions'
const { Panel } = Collapse;

class CapabilityRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            showMore: true,
            showCommentModal: false,
            clickedRecord: undefined
        };
    }

    toggleCollapse = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }));
        setTimeout(() => {
            this.setState(prevState => ({
                showMore: !prevState.showMore,
            }));
        }, 100);
    };


    renderRowInformation = (title, data) => {
        return (
            data && <div className=" font-12px">
                <span className="bold">{title}: </span>{data}
            </div>
        )
    }

    renderQAResponse = (title, data) => {
        return (
            data && <div className=" font-12px">
                <div style={{ marginLeft: "5px" }}>• {title} </div>
                <div style={{ marginLeft: "10px" }}>{data}</div>
            </div>
        )
    }

    handleApproveOrDeclineConfirmation = async (action) => {

        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            else {
                let userComment = this.props.form.getFieldValue('comment')
                userComment = userComment?.trim();

                const data = {
                    ...this.state.clickedRecord,
                    id: this.state.clickedRecord?.id,
                    notes: userComment,
                    status: action === CONFIG.capabilityDetailsRequestStatus.Revoked ? CONFIG.capabilityDetailsRequestStatus.Declined : action,
                }

                const typeOfAction = action.substring(0, action?.length - 1).toLowerCase();
                await this.props.putCapabilityRequest(this.state.clickedRecord?.id, data, typeOfAction, true)
                this.setState({ showCommentModal: false })
                this.props.fetchAdminApprovals();
            }
        })
    }

    handleCancel = async () => {
        await this.setState({ showCommentModal: false })
        setTimeout(() => this.setState({
            clickedRecord: undefined,
            modalButtonText: undefined,
            typeOfApprove: undefined
        }), 100)
        this.props.form.resetFields();
    }

    handleActionButton = (action, approvalObj) => {

        let modalTitle, content;
        modalTitle = action === CONFIG.myRequestsStatus.Approved ? CONFIG.warningMessages.requestApproveTitle : action === CONFIG.capabilityDetailsRequestStatus.Declined ? CONFIG.warningMessages.requestDeclineTitle : CONFIG.warningMessages.requestRevokeTitle;
        content =
            <>
                <div>
                    <div style={{ fontWeight: "500", color: "black", fontFamily: 'Noto Sans', }}>
                        {action === CONFIG.myRequestsStatus.Approved ?
                            CONFIG.warningMessages.requestApprove?.replace('<placeHolder>', approvalObj?.capability?.title) :
                            action === CONFIG.myRequestsStatus.Declined ? CONFIG.warningMessages.demoDecline?.replace('<placeHolder>', approvalObj?.capability?.title) :
                                CONFIG.warningMessages.requestRevoke?.replace('<placeHolder>', approvalObj?.capability?.title)}
                    </div>
                </div>
            </>
        this.setState({
            showCommentModal: true,
            clickedRecord: approvalObj,
            typeOfApprove: action,
            content,
            modalTitle,
        })
    }

    renderScheduleInformation = () => {
        const { collapsed, showMore } = this.state;
        const { demoApprovalsData, userObj, isPending } = this.props
        const { timezone } = userObj || []
        const eachCard = demoApprovalsData;

        const isCompleted = eachCard.requestStatus?.toLowerCase()?.includes("approved");
        // Compute each Object

        let demoRequestBasicDetails = {
            "Requested by": getUserInformationCard(eachCard?.createdBy, eachCard?.createdBy?.fullName),
            "Requested on": moment.tz(eachCard?.createdTime, timezone).format(CONFIG.dateFormats.userDateTimeWithTZ2),
            "Assigned to": eachCard?.assignedUser && getUserInformationCard(eachCard?.assignedUser, eachCard?.assignedUser?.fullName)
        }

        let completedApprovalsObject = {
            [isCompleted ? "Completed By" : "Declined By"]: getUserInformationCard(eachCard?.resolvedBy, eachCard?.resolvedBy?.fullName),
            [isCompleted ? "Completed On" : "Declined On"]: moment.tz(eachCard?.resolvedTime, timezone).format(CONFIG.dateFormats.userDateTimeWithTZ2)
        }

        let demoRequestUserResponse = {}

        eachCard?.userAnswers?.forEach(item => {
            demoRequestUserResponse[item.question.title] = item.answerText;
        });
        const items = [
            {
                key: 'details',
                openLinkInNewTab,
                label: (<span className="font-12px full-width-options" onClick={() => this.props.openLinkInNewTab("Capability Request", `/manage/capability-request/details/${eachCard?.id}`)}><Icon icon="forward-skip" style={{ fontSize: "19px", paddingRight: "8px" }} />Go to Request</span>),
            },
            isPending ? {
                key: 'edit',
                label: (<span className="font-12px full-width-options" onClick={() => this.handleActionButton("Approved", eachCard)}><Icon icon="check" style={{ fontSize: "19px", paddingRight: "8px" }} />Approve</span>)
            } : undefined,
            isPending ? {
                key: 'delete',
                label: (<span className="font-12px full-width-options" onClick={() => this.handleActionButton("Declined", eachCard)}><Icon icon="close" style={{ fontSize: "19px", paddingRight: "8px" }} />Decline</span>)
            } : undefined
        ];

        const renderAdditionalQuestions = (questions) => {
            return (
                questions?.map(item => {
                    const { question, answerText, questionOptions } = item;
                    const { title, id, answerType } = question
                    const answers = answerType !== CONFIG.demoAnswerTypes.Textbox && questionOptions?.split("//")

                    let userSubmittedAnswers = answerType === CONFIG.demoAnswerTypes.Checkbox ? answerText?.split("//") : answerText;
                    return (
                        <React.Fragment key={id}>
                            <Form.Item className='admin-dashboard-request-user-response' label={<span className="font-12px">○ {title}</span>}>
                                <div style={{ marginLeft: "25px" }}>{answerType === CONFIG.demoAnswerTypes.Checkbox
                                    ?
                                    (<Checkbox.Group value={userSubmittedAnswers} className="font-12px"
                                        options={answers}
                                    >

                                        {/* {answers?.map(opt => (<Checkbox style={{ color: "black" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Checkbox>))} */}
                                    </Checkbox.Group>)
                                    : answerType === CONFIG.demoAnswerTypes.RadioButton
                                        ? (<Radio.Group value={userSubmittedAnswers} className="font-12px"
                                            options={answers}

                                        >

                                        </Radio.Group>)
                                        : (<div className="font-12" style={{ width: '700px' }}>{userSubmittedAnswers}</div>)
                                }</div>
                            </Form.Item>
                        </React.Fragment>
                    )

                })
            )
        }

        const eachCardJSX = (<div >
            <Collapse
                bordered={false}
                className='admin-dashoard-requests-collapse'
                activeKey={collapsed ? [] : ['1']}
                expandIconPosition="right"
            >
                <Panel
                    header={
                        <div style={{ paddingBottom: "1%" }}>
                            <Row>
                                <Col span={17}>
                                    <Row> <span className='dashboard-requests-sub-heading'>Capability Request</span></Row>
                                    <span className='medium-text dashboard-schedules-demo-title '>{demoApprovalsData?.demo?.title}</span>
                                    <Row className='baseline-flex'>
                                        <Col span={1}>
                                            <Icon icon="screenshare-on" style={{ fontSize: "18px" }} />
                                        </Col>
                                        <Col style={{ paddingLeft: "7px" }} span={23}>
                                            {Object.entries(demoRequestBasicDetails).map(([key, value]) => (
                                                <div>
                                                    {this.renderRowInformation(key, value)}
                                                </div>
                                            ))}
                                            {
                                                !isPending ?
                                                    Object.entries(completedApprovalsObject).map(([key, value]) => (
                                                        <div>
                                                            {this.renderRowInformation(key, value)}
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    {(showMore && isPending) && (
                                        <div className="font-12px vertical-spacing-3 view-more-link" onClick={this.toggleCollapse}>
                                            {showMore ? 'View More' : 'View Less'}
                                        </div>
                                    )}
                                </Col>
                                <Col className="dashboard-daily-testing-row" span={7}>
                                    <Dropdown
                                        trigger="click"
                                        menu={{ items }}
                                        placement="bottomLeft"
                                        arrow
                                    >
                                        <Icon icon="ellipses-vertical" className='pointer' style={{ fontSize: "19px", color: "#1B77AF" }} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    }
                    key="1"
                >
                    {isPending ? <div >
                        <Row className='vertical-spacing-3 baseline-flex'>
                            <Col span={1}>
                                <Icon icon="calendar" style={{ fontSize: "18px" }} />
                            </Col>
                            <Col span={23}>
                                <div className=" font-12px">
                                    <div className="bold">Request Details: </div>
                                    {renderAdditionalQuestions(eachCard?.capabilityAnswers)}
                                </div>
                            </Col>
                        </Row>
                        <div className="vertical-spacing-3 view-more-link font-12px" onClick={this.toggleCollapse}>
                            {showMore ? 'View More' : 'View Less'}
                        </div>
                    </div> : null}
                </Panel>
            </Collapse>
            <Divider style={{ margin: 0, marginBottom: "5px" }} />
        </div >);

        return eachCardJSX;
    }


    render() {

        const { form } = this.props.form;
        const { showCommentModal } = this.state;
        return (
            <div>
                {this.renderScheduleInformation()}
                {
                    showCommentModal && <CapabilityDetailsModal
                        showModal={showCommentModal}
                        setShowModal={() => this.setState({ showCommentModal: false })}
                        data={{
                            action: this.state.typeOfApprove,
                            content: this.state.content,
                            modalTitle: this.state.modalTitle
                        }}
                        form={form}
                        handleApproveOrDeclineConfirmation={this.handleApproveOrDeclineConfirmation}
                    />
                }
            </div>
        )
    }
}

const CapabilityRequestsRef = Form.create()(CapabilityRequests);

export default connect(
    null,
    {
        openLinkInNewTab,
        putCapabilityRequest
    }
)(CapabilityRequestsRef);