import {
    FETCH_TRENDING_DEMOS,
    REFRESH_TRENDING_DEMOS,
    TOGGLE_IS_FETCHING_TRENDING_DEMOS,
    FETCH_MOST_SCHEDULED_DEMOS,
    CLEAR_FETCH_MOST_SCHEDULED_DEMOS
} from "./types";
import pushMessage from "../components/common/PushMessage";
import CONFIG from "../config";
import { aepSchedule, aepDemo } from '../apis';
import moment from 'moment-timezone';


export const fetchTrendingDemos = ({ graphType, startDate, endDate, timeline = CONFIG.trendingDemosTimeline[1], isExport = false }) => async (dispatch, getState) => {
    dispatch(toggleIsFetchingTrendingDemos(true))
    const timeZone = getState().user.profile.timezone;
    const utcOffset = moment().tz(timeZone).utcOffset()
    const dataKey = graphType === CONFIG.graphTypes.pieChart ? 'pieChartData' : 'graphData'
    const otherKey = graphType === CONFIG.graphTypes.pieChart ? 'graphData' : 'pieChartData'
    const contextPath = graphType === CONFIG.graphTypes.pieChart ? '/scheduleMaintenanceService/trendingDemos' : `/scheduleMaintenanceService/${CONFIG.graphViews[timeline]}`
    const response = await aepSchedule({
        method: 'GET',
        url: contextPath,
        params: { startDate, endDate, interval: utcOffset }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_TRENDING_DEMOS, payload: response.data || [], dataKey, otherKey });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch trending demos');
    dispatch(refreshTrendingDemos(false))
    dispatch(toggleIsFetchingTrendingDemos(false))

    if (isExport) {
        return response.data;
    }
}

export const refreshTrendingDemos = (flag) => {
    return {
        type: REFRESH_TRENDING_DEMOS,
        payload: flag
    }
}

export const toggleIsFetchingTrendingDemos = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_TRENDING_DEMOS,
        payload: flag
    }
}

export const fetchMostScheduledDemos = () => async (dispatch) => {
    const response = await aepDemo({
        method: 'GET',
        url: 'demoSolutionService/dashboard/mostPopularDemos',
    });

    dispatch({
        type: FETCH_MOST_SCHEDULED_DEMOS,
        payload: response.data || {}
    })
    dispatch(toggleIsFetchingTrendingDemos(false))
}

export const clearMostUsedDemos = () => {
    return {
        type: CLEAR_FETCH_MOST_SCHEDULED_DEMOS
    }
}
