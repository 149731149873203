import React from 'react';
import { Carousel, Modal, Divider, Tag, Row, Col } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import HTML from '../common/HTML';
import CONFIG from '../../config';
import { Icon } from "@avaya/neo-react"
import { closeNotificationBanner } from '../../actions';
class DashboardNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRender: false
        }
    }

    /**
     * React-slick display arrow only when notification count>1
     * arrow-style and left/right arrow is been added explicitly when notification
     * count is one. 
     */
    renderNotificationListItem = (notification) => {
        return (
            <div key={notification.id} className='notification-carousel-item-banner'>
                <div key={notification.id}>
                    <h3>
                        <div style={{paddingTop: '4px'}}>
                            <span className="border-notification notification-banner-title" id='user-notification-title'>{notification?.title}</span>
                            <Tag color="#1B77AF" className="left-spacing clickable" onClick={() => this.renderDescription(notification)} id='notification-read-more' >Read More</Tag>
                        </div>
                    </h3>
                </div>
            </div>
        );
    }

    renderDescription = (notification) => {
        this.setState({ isRender: true })
        this.notificationTitle = notification.title;
        this.notificationDescription = notification.description;
        this.publishedDate = notification.validityStartTime;
    }

    renderNotificationsList = () => {
        const { dashboardNotifications } = this.props;
        if (dashboardNotifications) {
            let duplicateNotifications = [];
            if (dashboardNotifications.length === 1) {
                for (let index = 0; index <= 1; index++) duplicateNotifications.push(dashboardNotifications[0])
                return this.calculateNotifications(duplicateNotifications);
            }
            else {
                return this.calculateNotifications(dashboardNotifications);
            }
        }
        else return [];
    }

    componentDidMount() {
        //document.getElementById('container').scrollIntoView();

    }

    calculateNotifications = (items) => {
        let notificationPanels = items.map((notification, index) => {
            return this.renderNotificationListItem(notification);
        });
        return notificationPanels;
    }

    handleCancel = () => {
        this.setState({ isRender: false })
    }

    handleCloseNotificationBanner = () => {
        this.props.closeNotificationBanner();
    }

    modalCloseButton = () => {
        return (
            <Icon onClick={this.handleCancel} icon="close" id='user-notification-modal-close'/>
        );
      };

    render() {
        const isArrowVisible = this.props.viewport.isDesktopView || false;
        const isDotsVisible = this.props.dashboardNotifications?.length === 1 ? false : true;
        return (
            this.props.showNotificationBanner
            && this.props.dashboardNotifications?.length > 0
            && <div className={isDotsVisible ? "notification-banner-dashboard" : "notification-banner-dashboard-nodots"} id='notification-banner'>
                <Row>
                    <Col span={23}><Carousel
                        autoplay={isDotsVisible && true}
                        autoplaySpeed={5000}
                        dots={false}
                        arrows={isDotsVisible && isArrowVisible}
                    >
                        {this.renderNotificationsList()}

                    </Carousel>
                    </Col>
                    <Col className='notification-banner-dashboard-close' span={1} style={{paddingTop: '4px'}}>
                        <span onClick={this.handleCloseNotificationBanner} ><Icon title="Dismiss Notifications" className="clickable" icon="close" size="md" id='dismiss-notification'/></span>
                    </Col>
                </Row>
                {this.state.isRender &&
                    <Modal
                        className='notification-banner-modal'
                        title={""}
                        visible={this.state.isRender}
                        onCancel={this.handleCancel}
                        footer={null}
                        closeIcon={this.modalCloseButton()}
                    >
                        <div className="notification-banner-content" id='user-notification-modal'>
                            <span className='bold' id='user-notification-modal-title'>{this.notificationTitle}</span>
                            <p id='user-notification-modal-published-time'>{`Published On : ${moment.tz(this.publishedDate, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)}`}</p>
                            <Divider />
                            <div id='user-notification-modal-description'><HTML htmlContent={this.notificationDescription} /></div>
                        </div>
                    </Modal>
                }
            </div >
        );
    }
}

const mapStateToProps = ({ userDashboardNotifications, viewport, user }) => {
    return {
        dashboardNotifications: userDashboardNotifications.dashboardNotifications,
        showNotificationBanner: userDashboardNotifications.showNotificationBanner,
        viewport,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
    };
}

export default connect(
    mapStateToProps,
    {
        closeNotificationBanner
    }
)(DashboardNotifications);