import React from 'react';
import { Row, Col, Alert, Form, DatePicker, TimePicker, Spin, Tooltip } from 'antd';
import { TextInput, Icon, Button } from "@avaya/neo-react"
import { connect, batch } from 'react-redux';
import { Select } from 'antd5';
import CONFIG from '../../../config';
import moment from 'moment-timezone';
import openInfo from '../../common/Info';
import debounce from 'lodash/debounce';
import {
    updateSelectedResourceType,
    refreshScheduler,
    updateSchedulerTimezone,
    updateSchedulerStartEndTime,
    fetchAvailableDemoResources,
    fecthDemoPurposeTypesLite,
    closeCurrentOpenTab,
    postNewDemoSchedule,
    clearAllNewEvents,
    updateSelectedResource,
    fetchScheduleById,
    toggleIsFetchingSchedulerData,
    clearSelectedResource,
    optOutSchedule,
    deleteDashboardSchedule,
    deleteBatchDemoSchedule,
    toggleIsFetchingDashboardSchedules,
    fetchDashboardActiveSchedules,
    clearDashboardSchedules
} from '../../../actions';
import pushMessage from '../../common/PushMessage';
import { fetchUserNamesBySearchString, getUserDemoAccess, getUserResourceAccess, createLDAPUser, addUserToGroup, fetchTimeslotForReschedule, addUserToGroupForBatch, createLDAPUserForBatch } from '../../../actions/scheduler'
import NeoStandardDemoDetailsPage from './NeoStandardDemoDetailsPage';
import { createScheduleObject, getInitialValue, checkDeletedPurpose } from "../../common/Scheduler"
import ResourceDetails from './ResourceDetails';
import StandardDemoConfirmationModal from './StandardDemoConfirmationModal';
import { LoadingOutlined } from '@ant-design/icons';
import ScheduleDeleteConfirmation from '../../common/ScheduleDeleteConfirmation';
import SchedulingAssistant from "./SchedulingAssistant"
import SchedulerHelpButton from '../../common/SchedulerHelpButton';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

class NeoStandardDemoScheduleForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            endTime: props?.currentSchedule ? moment.tz(props?.currentSchedule.endTime, props.timezone) : moment().tz(props.timezone).add(30 - (moment().tz(props.timezone).minutes() % 30), 'minutes').add(30, 'minutes'),
            endDate: props?.currentSchedule ? moment.tz(props?.currentSchedule.endTime, props.timezone) : moment().tz(props.timezone),
            startTime: props?.currentSchedule ? moment.tz(props?.currentSchedule.startTime, props.timezone) : moment().tz(props.timezone).add(30 - (moment().tz(props.timezone).minutes() % 30), 'minutes'),
            startDate: props?.currentSchedule ? moment.tz(props?.currentSchedule.startTime, props.timezone) : moment().tz(props.timezone),
            scheduleParticipants: props?.currentSchedule?.participants ? [...props.currentSchedule?.participants?.eapUsers, ...props.currentSchedule?.participants?.extUsers] : [],
            matchedUserNames: [],
            isLoadingUser: false,
            isStepOne: true,
            demoRequestStatus: 2,
            demoAccess: true,
            resourceAccess: true,
            isActiveUser: true,
            isFormDisabled: false,
            isModalVisible: false,
            isLoadingPage: false,
            selectedUser: props?.currentSchedule ? props?.currentSchedule?.createdBy : props?.user,
            selectedPurpose: [],
            isCurrentSchedule: false,
            freqType: "1",
            deleteModalOpen: false,
            showPulse: true,
            scheduleAssistantModal: false
        }
        this.fetchUserNames = debounce(this.fetchUserNames, 800);
    }

    handleCurrentScheduleChange = async (flag) => {
        await this.setState({ isCurrentSchedule: flag })
    }

    async componentDidMount() {
        const { timezone, userTimeZone, startTime, endTime, resourceType, demo, currentSchedule, isReschedule } = this.props;
        const { isBatchEdit, batchId } = this.props?.match?.params;
        if (currentSchedule !== undefined && currentSchedule !== null) {
            this.setState({
                selectedPurpose: currentSchedule?.demoPurpose,
                isCurrentSchedule: true
            })
            await this.props.fecthDemoPurposeTypesLite(currentSchedule?.createdBy?.id);
            await this.props.updateSelectedResource(isBatchEdit ? currentSchedule?.scheduledResources : currentSchedule?.resource);
            // const isActivePurpose = this.props.userPurposeTypes?.find((purpose) => purpose.id === currentSchedule?.demoPurpose?.id);
            // this.isPurposeDeleted = currentSchedule ? (isActivePurpose === undefined || checkDeletedPurpose(currentSchedule?.demoPurpose)) : false;
        }
        !currentSchedule && this.handleCheckTestingPurpose();
        let schedulerStartTime;
        let schedulerEndTime;

        if (isReschedule) {
            this.handleCheckTestingPurpose();
            const availableStartTime = await fetchTimeslotForReschedule(currentSchedule?.resource?.id, demo?.id);
            schedulerStartTime = availableStartTime ? moment(availableStartTime).tz(timezone).toISOString() : moment().tz(timezone).add(30 - (moment().tz(timezone).minutes() % 30), 'minutes').toISOString();
            schedulerEndTime = availableStartTime ? moment(availableStartTime).tz(timezone).add(30, 'minutes').toISOString() : moment().tz(timezone).add(30 - (moment().tz(timezone).minutes() % 30), 'minutes').add(30, 'minutes').toISOString();
            this.setState({
                endTime: moment(schedulerEndTime).tz(timezone),
                endDate: moment(schedulerEndTime).startOf('day').tz(timezone),
                startTime: moment(schedulerStartTime).tz(timezone),
                startDate: moment(schedulerStartTime).startOf('day').tz(timezone),
                selectedPurpose: undefined,
                isCurrentSchedule: false,
                scheduleParticipants: []
            })
        } else {
            if (currentSchedule) {
                schedulerStartTime = moment.tz(currentSchedule.startTime, timezone).toISOString();
                schedulerEndTime = moment.tz(currentSchedule.endTime, timezone).toISOString()
            }
            else {
                schedulerStartTime = currentSchedule ? startTime : moment.tz(this.state.startTime, timezone).toISOString();
                schedulerEndTime = currentSchedule ? endTime : moment.tz(this.state.endTime, timezone).toISOString();
            }
        }

        const isActivePurpose = this.props.userPurposeTypes?.find((purpose) => purpose.id === currentSchedule?.demoPurpose?.id);
        this.isPurposeDeleted = currentSchedule ? (isActivePurpose === undefined || checkDeletedPurpose(currentSchedule?.demoPurpose)) : false;

        await this.props.updateSelectedResourceType(resourceType || CONFIG.resourceTypes.recommended.key);
        await this.props.updateSchedulerTimezone(timezone || userTimeZone);

        await this.props.updateSchedulerStartEndTime(schedulerStartTime, schedulerEndTime);
        // demoId, startTime, endTime,  organizerId, scheduleId
        await this.props.toggleIsFetchingSchedulerData(true);
        await this.props.fetchAvailableDemoResources({ demoId: demo.id, startTime: schedulerStartTime, endTime: schedulerEndTime, organizerId: (this?.state?.selectedUser?.id !== this?.props?.user?.id) ? this?.state?.selectedUser?.id : undefined, scheduleId: currentSchedule ? currentSchedule.id : undefined, batchNumber: (batchId && isBatchEdit) ? batchId : undefined });
        await this.props.toggleIsFetchingSchedulerData(false);
        setTimeout(() => {
            this.setState({
                showPulse: false
            })
        }, 6700)
    }

    handleStartDateChange = async (date) => {
        if (!!date) {
            const { form } = this.props;
            form.validateFields(['startDate', 'startTime', 'endDate', 'endTime'], async (err) => {
                if (err) {
                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                    return
                }
                else {
                    await this.setState({ startDate: date }, async () => {
                        await this.handleDateTimeChange(true);
                        await this.handleStartTimeCheck(false);
                    })
                }
            })
        }
    };

    handleEndDateChange = async (date) => {
        if (!!date) {
            const { form } = this.props;
            form.validateFields(['startDate', 'startTime', 'endDate', 'endTime'], async (err) => {
                if (err) {
                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                    return
                }
                else {
                    await this.setState({ endDate: date }, () => {
                        this.handleDateTimeChange();
                    })
                }
            })
        }
    };

    handleStartTimeChange = async (time) => {
        if (!!time) {
            const { form } = this.props;
            form.validateFields(['startDate', 'startTime', 'endDate', 'endTime'], async (err) => {
                if (err && !time) {
                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                    return
                }
                else {
                    await this.setState({ startTime: time }, () => {
                        this.handleDateTimeChange(true);
                    })
                }
            })
        }
    };

    handleStartTimeCheck = async (open) => {
        if (!open) {
            const { startDate, startTime } = this.state;
            const { timezone, isReschedule, currentSchedule, form } = this.props;
            const newstartDateTime = moment.tz(
                `${startDate.format('YYYY-MM-DD')} ${startTime.format('HH:mm:ss')}`,
                timezone
            );
            let startDateTimeUTC = newstartDateTime.utc().toISOString();
            const currentTimeUTC = moment().toISOString();
            const isEndTimeExtend = !isReschedule ? currentSchedule?.id && moment.tz(currentSchedule?.startTime, timezone).isBefore(moment.tz(currentTimeUTC, timezone)) ? true : false : false;
            if (moment(startDateTimeUTC).isBefore(moment(currentTimeUTC)) && !isEndTimeExtend) {
                startDateTimeUTC = moment().add(30 - (moment().tz(timezone).minutes() % 30), 'minutes').toISOString();
                await this.setState({
                    startDate: moment(startDateTimeUTC).tz(timezone),
                    startTime: moment(startDateTimeUTC).tz(timezone)
                })
                form.setFieldsValue({
                    startDate: moment(startDateTimeUTC).tz(timezone),
                    startTime: moment(startDateTimeUTC).tz(timezone)
                })
                this.handleDateTimeChange();
            }
        }
    }

    handleEndTimeChange = async (time) => {
        if (!!time) {
            const { form } = this.props;
            form.validateFields(['startDate', 'startTime', 'endDate', 'endTime'], async (err) => {
                if (err && !time) {
                    pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                    return
                }
                else {
                    await this.setState({ endTime: time }, () => {
                        this.handleDateTimeChange();
                    })
                }
            })
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps?.timezone !== this.props.timezone) {
            this.props.updateSchedulerTimezone(this.props.timezone);
            this.props.form.setFieldsValue({ timezone: this.props.timezone });
            const currentStartDateTime = moment.tz(this.state.startTime, this.props.timezone);
            // Add 30 minutes to the current start date and time
            const newStartDateTime = currentStartDateTime;
            const newEndDateTime = moment.tz(this.state.endTime, this.props.timezone);
            // Update the form fields with the converted values
            this.setState({
                startDate: moment(newStartDateTime),
                startTime: moment(newStartDateTime),
                endDate: moment(newEndDateTime),
                endTime: moment(newEndDateTime),
            }, () => {
                this.props.form.setFieldsValue({
                    startDate: moment(newStartDateTime),
                    startTime: moment(newStartDateTime),
                    endDate: moment(newEndDateTime),
                    endTime: moment(newEndDateTime),
                    resources: undefined
                });
                this.props.updateSchedulerStartEndTime(moment(newStartDateTime).utc().toISOString(), moment(newEndDateTime).utc().toISOString());
            })
        }
    }

    handleDateTimeChange = async (isStartDateTimeChanged) => {
        // Convert the start and end dates to moment objects
        const { startDate, startTime, endDate, endTime } = this.state
        const { timezone, demo, currentSchedule, form } = this.props;
        const { isBatchEdit, batchId } = this.props?.match?.params;

        const newstartDateTime = moment.tz(
            `${startDate.format('YYYY-MM-DD')} ${startTime.format('HH:mm:ss')}`,
            timezone
        );

        const newEndDateTime = moment.tz(
            `${endDate.format('YYYY-MM-DD')} ${endTime.format('HH:mm:ss')}`,
            timezone
        );

        // Now startDateTime is a moment object representing the combined date and time in UTC
        let startDateTimeUTC = newstartDateTime.utc().toISOString();
        let endDateTimeUTC = newEndDateTime.utc().toISOString();
        const duration = moment.duration(moment(endDateTimeUTC).diff(moment(startDateTimeUTC)));
        const isValidDuration = this.props.isMultipleDays ? true : duration.asHours() <= CONFIG.demoScheduler.scheduleDurationLimit;
        const isDurationWithinLimit = this.props.isMultipleDays ? true : demo?.schTimeLimit ? duration.asMinutes() <= demo?.schTimeLimit : true;
        if (moment(endDateTimeUTC).isBefore(moment(startDateTimeUTC)) || !isValidDuration || !isDurationWithinLimit) {



            endDateTimeUTC = moment(startDateTimeUTC).add(30, 'minutes').toISOString();
            await this.setState({
                endDate: moment(endDateTimeUTC).tz(timezone),
                endTime: moment(endDateTimeUTC).tz(timezone)
            })
            form.setFieldsValue({
                endDate: moment(endDateTimeUTC).tz(timezone),
                endTime: moment(endDateTimeUTC).tz(timezone)
            })

            if (!isStartDateTimeChanged) {
                !isValidDuration && pushMessage(CONFIG.messageType.warning, 'Duration cannot exceed 24 hours');
                !isDurationWithinLimit && pushMessage(CONFIG.messageType.warning, 'Duration cannot exceed demo time limit');
            }
        }
        await this.setState({ isCurrentSchedule: false })
        await this.props.updateSchedulerStartEndTime(startDateTimeUTC, endDateTimeUTC);
        await this.props.toggleIsFetchingSchedulerData(true);
        this.props.form.setFieldsValue({
            resources: undefined
        });
        await this.props.fetchAvailableDemoResources({ demoId: demo.id, startTime: startDateTimeUTC, endTime: endDateTimeUTC, organizerId: (this?.state?.selectedUser?.id !== this?.props?.user?.id) ? this?.state?.selectedUser?.id : undefined, scheduleId: currentSchedule ? currentSchedule.id : undefined, batchNumber: (batchId && isBatchEdit) ? batchId : undefined });
        await this.handleSelectedResourceChange();
        form.setFieldsValue({ resourceType: this.props.resourceType })
        await this.props.toggleIsFetchingSchedulerData(false);
    }

    handleResourceTypeChange = (resourceTypeKey) => {
        batch(async () => {
            await this.props.updateSelectedResourceType(resourceTypeKey);
            await this.props.toggleIsFetchingSchedulerData(true);
            this.props.form.setFieldsValue({
                resources: undefined
            });
            this.handleSelectedResourceChange();
            await this.props.toggleIsFetchingSchedulerData(false);
            await this.props.refreshScheduler(true);
        })
    }

    renderTimeZoneOptions = () => {
        const timezones = moment.tz.names();
        return timezones.map((timezone, index) => {
            return <Option key={index} value={timezone}>{timezone}</Option>;
        });
    }

    handleTimezoneChange = (value) => {
        const { form } = this.props;
        let { demo, currentSchedule } = this.props;
        this.props.timeZoneChanged(false);
        const { isBatchEdit, batchId } = this.props?.match?.params;

        const currentStartDateTime = currentSchedule ? moment.tz(this.state.startTime, value) : moment.tz(value);
        // Add 30 minutes to the current start date and time
        const newStartDateTime = currentSchedule ? currentStartDateTime : currentStartDateTime.clone().add(30 - (moment().tz(value).minutes() % 30), 'minutes');
        const newEndDateTime = currentSchedule ? moment.tz(this.state.endTime, value) : newStartDateTime.clone().add(30, 'minutes');

        // Update the form fields with the converted values
        this.setState({
            startDate: moment(newStartDateTime),
            startTime: moment(newStartDateTime),
            endDate: moment(newEndDateTime),
            endTime: moment(newEndDateTime),
        }, () => {
            form.setFieldsValue({
                startDate: moment(newStartDateTime),
                startTime: moment(newStartDateTime),
                endDate: moment(newEndDateTime),
                endTime: moment(newEndDateTime),
                resources: undefined
            });
        })

        batch(async () => {
            this.props.updateSchedulerTimezone(value);
            // this.props.updateSchedulerStartEndTime(moment(newStartDateTime).toISOString(), moment(newEndDateTime).toISOString());
            this.props.updateSchedulerStartEndTime(moment(newStartDateTime).utc().toISOString(), moment(newEndDateTime).utc().toISOString());

            await this.props.toggleIsFetchingSchedulerData(true);
            await this.props.fetchAvailableDemoResources({ demoId: demo.id, startTime: moment(newStartDateTime).toISOString(), endTime: moment(newEndDateTime).toISOString(), organizerId: (this?.state?.selectedUser?.id !== this?.props?.user?.id) ? this?.state?.selectedUser?.id : undefined, scheduleId: currentSchedule ? currentSchedule.id : undefined, batchNumber: (batchId && isBatchEdit) ? batchId : undefined });
            await this.handleSelectedResourceChange();
            await this.props.toggleIsFetchingSchedulerData(false);
            form.setFieldsValue({ resourceType: this.props.resourceType })
            // this.props.fetchSchedulesOverDuration(demo.id, startTime, endTime);
            await this.setState({ isCurrentSchedule: false })
            this.props.refreshScheduler(true);
            setTimeout(() => {
                this.props.timeZoneChanged(true);
            }, 1000);
        })
    }

    handleSelectedResourceChange = async () => {
        const { resources, selectedResource, form } = this.props;
        const isSelectedResourceArray = Array.isArray(selectedResource);
        let filteredList;
        if (isSelectedResourceArray && selectedResource?.length > 0) {
            filteredList = selectedResource.filter((selectedResourceObj) =>
                resources.some((resource) => resource.id === selectedResourceObj.id)
            );

            if (filteredList?.length === 0) {
                await this.props.clearSelectedResource();
            } else {
                await this.props.updateSelectedResource(filteredList);
                await this.handleCurrentScheduleChange(false);
                form.setFieldsValue({ resources: filteredList?.length > 0 ? filteredList?.map(item => JSON.stringify({ id: item?.id, name: item?.name })) : null });
            }
        } else {
            const isResourcePresent = resources?.some((resource) => resource?.id === selectedResource?.id);
            if (!isResourcePresent) await this.props.clearSelectedResource();
            form.setFieldsValue({ resources: JSON.stringify({ id: selectedResource?.id, name: selectedResource?.name }) });
        }
    }

    handleAssigneeChange = async (userObj) => {
        const user = JSON.parse(userObj);
        const { startTime, endTime, demo, selectedResource, currentSchedule } = this.props;
        const { isBatchEdit, batchId } = this.props?.match?.params;
        const { form } = this.props;
        await this.props.fecthDemoPurposeTypesLite(user?.id);
        await this.setState({ selectedUser: user })
        if (user.isActive) {
            await getUserDemoAccess(demo.id, user).then(result => {
                const demoAccess = result[0];
                const demoRequestStatus = result[1];
                const { isPreRegistrationRequired } = demo || false;


                if (demoAccess) {
                    this.setState({ isFormDisabled: false, demoAccess: true, isActiveUser: true })
                    if (user?.id !== this.props.user?.id && isPreRegistrationRequired) {
                        if (demoRequestStatus < CONFIG.demoRequestAccessStatus.approved) {
                            this.setState({ isFormDisabled: true, demoRequestStatus: demoRequestStatus })
                            return;
                        }
                        else this.setState({ isFormDisabled: false, demoRequestStatus: demoRequestStatus })
                    }
                    if (selectedResource) {
                        let resourceIdList = []
                        Array.isArray(selectedResource) ? selectedResource.forEach(item => resourceIdList.push(item?.id)) : resourceIdList.push(selectedResource?.id)
                        const resourceIds = resourceIdList
                        getUserResourceAccess(resourceIds, user).then(result => {
                            if (new Set(result).size === new Set(resourceIds).size) this.setState({ isFormDisabled: false, resourceAccess: true })
                            else this.setState({ isFormDisabled: true, resourceAccess: false })
                        })
                    }
                }
                else this.setState({ isFormDisabled: true, demoAccess: false, isActiveUser: true })
            })
        }
        else {
            this.setState({ isFormDisabled: true, isActiveUser: false, demoAccess: true })
        }
        //check the user access for the demo if is active.
        await this.setState({ isCurrentSchedule: false })
        await this.props.toggleIsFetchingSchedulerData(true);
        this.props.form.setFieldsValue({
            resources: undefined
        });
        await this.props.clearSelectedResource();
        await this.props.fetchAvailableDemoResources({ demoId: demo.id, startTime: startTime, endTime: endTime, organizerId: (this?.state?.selectedUser?.id !== this?.props?.user?.id) ? this?.state?.selectedUser?.id : undefined, scheduleId: currentSchedule ? currentSchedule.id : undefined, batchNumber: (batchId && isBatchEdit) ? batchId : undefined });
        await this.props.toggleIsFetchingSchedulerData(false);
        form.setFieldsValue({ resourceType: this.props.resourceType })
    }

    renderUserNamesOptions = () => {
        return this.state.matchedUserNames?.map((item, index) => {
            return <Option key={index} value={JSON.stringify(item)} title={item.fullName}>
                {`${item.fullName} (${item.sslHandle})`}
            </Option>
        })
    }

    fetchUserNames = searchString => {
        this.setState({ matchedUserNames: [], isLoadingUser: true });
        fetchUserNamesBySearchString(searchString)
            .then((result) => {
                this.setState({ isLoadingUser: false });
                if (result?.length !== 0) {
                    this.setState({ matchedUserNames: result })
                }
            });
    };

    disabledStartDate = (start) => {
        const { timezone } = this.props;
        const now = start && start < (moment.tz(moment(), timezone).endOf('day').subtract(1, 'day'))
        return now
    };

    disabledEndDate = end => {
        const { timezone } = this.props;
        const now = end && end < (moment.tz(moment(this.state.startDate), timezone).endOf('day').subtract(1, 'day'))
        return now
    };

    calculateDisabledHoursAndMinutes = () => {
        const { timezone } = this.props;
        const disabledHours = [];
        const disabledMinutes = [];
        const now = moment.tz(moment(), timezone);
        const startDate = moment.tz(this.props.startTime, timezone) ?? now;

        if (startDate.format(CONFIG.dateFormats.user) === now.format(CONFIG.dateFormats.user)) {
            for (let i = 0; i < now.hour(); i++) {
                disabledHours.push(i);
            }
            if (startDate.hour() === now.hour()) {
                for (let i = 0; i <= now.minute(); i++) {
                    disabledMinutes.push(i);
                }
            }
        }

        return [
            disabledHours,
            disabledMinutes,
        ];
    }

    calculateDisabledHoursAndMinutesForEndTime = () => {
        const { timezone } = this.props;
        const disabledHours = [];
        const disabledMinutes = [];
        const startDateTime = moment(this.props.startTime).tz(timezone);
        const endDate = moment(this.props.endTime).tz(timezone) ?? startDateTime;
        if (endDate.format(CONFIG.dateFormats.user) === startDateTime.format(CONFIG.dateFormats.user)) {
            for (let i = 0; i < startDateTime.hour(); i++) {
                disabledHours.push(i);
            }
            if (startDateTime.hour() === endDate.hour()) {
                for (let i = 0; i <= startDateTime.minute(); i++) {
                    disabledMinutes.push(i);
                }
            }
        }

        return [
            disabledHours,
            disabledMinutes,
        ];
    }

    componentWillUnmount() {
        const { form } = this.props;
        form.resetFields();
    }

    handleSchedule = () => {
        const { form, demo, selectedResource, timezone, startTime, endTime, user, isReschedule, currentSchedule, isMultipleScheduleBooking, customerInfo } = this.props;
        const { additionalDemoQuestions } = demo || false;
        const { isBatchEdit } = this.props?.match?.params;
        const { scheduleParticipants } = this.state;
        this.handleModalClose();
        form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoadingPage: true })
                const currentStartTime = moment(startTime).tz(timezone).utc().toISOString();
                const currentEndTime = moment(endTime).tz(timezone).utc().toISOString();
                const startBuffer = currentSchedule ? currentSchedule.startTimeBuffer + CONFIG.schedulerJobFrequency : ((demo.startTimeBuffer || 0) + CONFIG.schedulerJobFrequency)
                const scheduleStartTime = moment(startTime).tz(timezone).subtract(startBuffer || 0, 'm')

                // const startBuffer = event.scheduleId ? event.startTimeBuffer + CONFIG.schedulerJobFrequency : ((demo.startTimeBuffer || 0) + CONFIG.schedulerJobFrequency)
                const currentTime = moment.tz(moment(), timezone);

                const shouldAddUserToGroup = scheduleStartTime < currentTime
                const shouldRemoveUserFromGroup = currentSchedule && scheduleStartTime > currentTime;
                const selectedPurpose = this.props.form.getFieldValue("demoPurpose");
                const data = getInitialValue(selectedPurpose ? JSON.parse(this.props.form.getFieldValue("demoPurpose")) : null, this.props.form);
                const purposeAttributesSchedule = createScheduleObject(data, false);

                let userAnswers = {}
                let participants;
                const preRequisiteQuestionObj = additionalDemoQuestions;
                if (preRequisiteQuestionObj?.length > 0) {
                    const formValues = Object.entries(values).map(([questionId, answerText]) => ({ questionId, answerText }))?.filter((el) => {
                        return preRequisiteQuestionObj?.some((f) => {
                            return f?.question?.id === parseInt(el?.questionId);
                        });
                    });

                    const userAnswersObj = formValues.map(fv => {
                        fv.questionOptions = preRequisiteQuestionObj?.filter(dq => dq.question?.id === parseInt(fv.questionId))?.[0]?.question?.answers?.map(a => a.title).join('//') || null;
                        return fv;
                    })


                    userAnswers = userAnswersObj?.map(obj => ({
                        question: { id: parseInt(obj.questionId) },
                        questionOptions: obj.questionOptions,
                        answerText: Array.isArray(obj?.answerText) ? obj?.answerText.join('//') : obj?.answerText
                    }))
                }


                if (scheduleParticipants?.length > 0) {
                    participants = {
                        "eapUsers": (currentSchedule?.id && !isReschedule) ? scheduleParticipants?.filter(sp => sp?.isEapUser === true) : scheduleParticipants?.filter(sp => sp?.isEapUser === true && sp?.isCreator === false),
                        "extUsers": scheduleParticipants?.filter(sp => ((sp?.isEapUser === false) || (sp?.newlyAdded === true)))
                    }
                }

                const createdBy = isReschedule ? user : currentSchedule ? currentSchedule.createdBy : values.assignee ? JSON.parse(values.assignee) : user;

                let opportunity;
                if (values?.doNotHaveOpportunity && values.doNotHaveOpportunity === true) {
                    opportunity = {
                        id: currentSchedule && currentSchedule?.opportunity ? isReschedule || isBatchEdit ? undefined : currentSchedule?.opportunity?.id : undefined,
                        accountOwnerEmail: values?.accountOwnerEmail ? values?.accountOwnerEmail : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.accountOwnerEmail : undefined,
                        customerName: this.props.form.getFieldValue(`Customer Name`)?.trim()
                    }
                } else {
                    opportunity = {
                        id: currentSchedule && currentSchedule?.opportunity ? isReschedule || isBatchEdit ? undefined : currentSchedule?.opportunity?.id : undefined,
                        opportunityNumber: customerInfo ? customerInfo?.opportunity?.otnNumber : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.opportunityNumber : undefined,
                        sfdcOpportunityId: customerInfo ? customerInfo?.opportunity?.id : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.sfdcOpportunityId : undefined,
                        validatedBy: customerInfo && customerInfo?.opportunity ? user?.email : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.validatedBy : undefined,
                        accountOwnerEmail: values?.accountOwnerEmail ? values?.accountOwnerEmail : isReschedule ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.accountOwnerEmail : undefined,
                        sfdcTaskId: isReschedule || isBatchEdit ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.sfdcTaskId : undefined,
                        sfdcTaskStatus: isReschedule || isBatchEdit ? undefined : currentSchedule && currentSchedule?.opportunity ? currentSchedule?.opportunity?.sfdcTaskStatus : undefined,
                        customerName: customerInfo && customerInfo?.opportunity ? customerInfo?.opportunity?.AccountName : this.props.form.getFieldValue(`Customer Name`)?.trim()
                    }
                }

                const requireOpportunity = values.demoPurpose ? JSON.parse(values.demoPurpose)?.demoPurposeAttributes?.some(attribute =>
                    attribute.hasOwnProperty('type') && attribute.type !== null && attribute.type !== '' && attribute.type !== undefined
                ) : false;

                const schedule = {
                    createdBy: createdBy,
                    demoPurpose: JSON.parse(values.demoPurpose),
                    endTimeBuffer: demo.endTimeBuffer,
                    id: isReschedule ? undefined : currentSchedule ? currentSchedule?.id : undefined,
                    purposeAttributesSchedule: purposeAttributesSchedule,
                    [(isBatchEdit && currentSchedule?.id) ? "scheduledResources"
                        : ((isMultipleScheduleBooking && createdBy?.isMultipleScheduleBooking) && !isBatchEdit && !currentSchedule?.id) ? "scheduledResources" : "resource"]: selectedResource,
                    startTime: currentStartTime,
                    endTime: currentEndTime,
                    startTimeBuffer: demo.startTimeBuffer,
                    demo,
                    userAnswers: Object.keys(userAnswers)?.length === 0 ? null : userAnswers,
                    participants: participants || null,
                    isOneTime: demo?.isOneTime,
                    isPreRegistration: demo?.isPreRegistration,
                    batchNumber: isReschedule ? undefined : currentSchedule?.batchNumber,
                    isReschedule,
                    opportunity: requireOpportunity ? opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined : undefined,
                    notes: values?.notes
                };

                await this.props.postNewDemoSchedule(schedule, shouldRemoveUserFromGroup, null, false, false, isMultipleScheduleBooking, isBatchEdit)
                    .then(async (response) => {
                        const scheduleId = response?.[0];
                        const batchNumber = response?.[1];
                        this.props.clearAllNewEvents();
                        this.props.refreshScheduler(true);
                        if (scheduleId > 0 && !isBatchEdit) {
                            if (scheduleId !== undefined) await createLDAPUser(scheduleId);
                            if (shouldAddUserToGroup) await addUserToGroup(scheduleId);
                        }
                        if ((batchNumber > 0 && isBatchEdit) || (batchNumber > 0 && scheduleId === 0)) {
                            if (batchNumber !== undefined) await createLDAPUserForBatch(batchNumber);
                            if (shouldAddUserToGroup) await addUserToGroupForBatch(batchNumber);
                        }
                    })
                    .catch((err) => openInfo(CONFIG.warningMessages.ldapFailure.title, CONFIG.warningMessages.ldapFailure.content, null, "OK"));
                this.setState({ isLoadingPage: false });

            }
            else {
                pushMessage(CONFIG.messageType.warning, 'Please verify the fields');
                this.handleCheckTestingPurpose();
            }
        });
    }

    handleCheckTestingPurpose = async () => {
        this.testingGroup = this.props.userPurposeTypes.filter((purposeType) => { return (purposeType.purpose === "Testing" || purposeType.id === 5) })
        if (this.testingGroup?.length > 0) {
            await this.setState({
                selectedPurpose: this.testingGroup[0]
            });
        }
    }

    renderDemoErrorAlert = () => {
        const { demoAccess, isActiveUser } = this.state;
        const message = !demoAccess ? "The selected user does not have access to the demo" : !isActiveUser ? "The selected user is currently inactive" : null;
        return (<Alert
            message={message}
            type="error"
            showIcon
        />)
    }

    renderDemoRequestErrorAlert = () => {
        const { demoRequestStatus, isActiveUser } = this.state;
        const message = demoRequestStatus === 0
            ? "This demo needs to be requested by the selected user before scheduling."
            : demoRequestStatus === 1
                ? "Request for this demo by the selected user is yet to be approved."
                : !isActiveUser ? "The selected user is currently inactive" : null;
        return (<Alert
            message={message}
            type="error"
            showIcon
        />)
    }

    handleModalClose = async () => {
        await this.setState({ isModalVisible: false });
    }

    handleParticipantsChange = (participants) => {
        this.setState({
            scheduleParticipants: participants,
            // isCurrentSchedule: false
        })
    }

    handleFreqChange = (value) => {
        this.setState({ freqType: value });
    }

    handleStepOne = () => {
        const { form } = this.props;
        form.validateFields(['startDate', 'startTime', 'endDate', 'endTime'], async (err) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return
            }
            else {
                this.setState({ isStepOne: false })
            }
        })
    }

    handleRemove = async () => {
        const { user, currentSchedule } = this.props;
        const record = currentSchedule;
        const eapUsers = record?.participants?.eapUsers?.filter((eachUser) => eachUser?.id !== user?.id);
        const schedule = {
            createdBy: record?.createdBy,
            demo: record?.demo,
            demoPurpose: record?.demoPurpose,
            endTime: record?.endTime,
            batchNumber: record?.batchNumber,
            endTimeBuffer: record?.endTimeBuffer,
            id: record?.id,
            isOneTime: record?.isOneTime,
            isPreRegistration: record?.isPreRegistration,
            purposeAttributesSchedule: record?.purposeAttributesSchedule,
            resource: record?.resource,
            startTime: record?.startTime,
            startTimeBuffer: record?.startTimeBuffer,
            userAnswers: record?.userAnswers,
            participants: {
                ...record.participants,
                eapUsers
            } || null
        }

        await this.props.optOutSchedule(schedule, record?.demo?.demoType);
        this.setState({ deleteModalOpen: false });
        this.props.closeCurrentOpenTab()
    }


    handleDeleteSchedule = async (isDeleteForEveryone) => {
        const { currentSchedule, user } = this.props;
        const { isBatchEdit } = this.props?.match?.params;
        const isLoggedInUserCreator = currentSchedule?.createdBy?.id === user?.id;
        const hasParticipants = currentSchedule?.participants !== null
        const record = this.props.currentSchedule;
        if (isBatchEdit) {
            this.props.toggleIsFetchingDashboardSchedules(true)
            await this.props.deleteBatchDemoSchedule(record?.batchNumber).then(async () => {
                await this.props.clearDashboardSchedules();
                await this.props.fetchDashboardActiveSchedules();
                this.props.toggleIsFetchingDashboardSchedules(false)
                this.props.closeCurrentOpenTab()
            })
            this.setState({ deleteModalOpen: false });
        }
        else {
            if (!hasParticipants) {
                this.props.toggleIsFetchingDashboardSchedules(true)
                await this.props.deleteDashboardSchedule(record?.id, record?.demo?.demoType).then(() => {
                    this.props.closeCurrentOpenTab()
                })
                this.setState({ deleteModalOpen: false });
            }
            else {
                if (!isDeleteForEveryone && !isLoggedInUserCreator) {
                    this.handleRemove();
                }
                else {
                    this.props.toggleIsFetchingDashboardSchedules(true)
                    await this.props.deleteDashboardSchedule(record?.id, record?.demo?.demoType).then(() => {
                        this.props.closeCurrentOpenTab()
                    })
                    this.setState({ deleteModalOpen: false });
                }
            }
        }
    }

    handleFormDisableChange = async (isFormDisabled) => {
        await this.setState({ isFormDisabled: isFormDisabled, resourceAccess: !isFormDisabled })
    }

    handleOpenConfirmationSchedule = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return;
            }
            else this.setState({ isModalVisible: true })
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { demo, isMultipleDays, resourceType, timezone, userTimeZone, user, selectedResource, startTime, endTime, currentSchedule, isReschedule } = this.props;
        const { scheduleParticipants } = this.state;
        const resourceValue = (selectedResource && (Array.isArray(selectedResource) ? selectedResource.length > 0 : Object.keys(selectedResource).length > 0))
        const isFormCompelete = resourceValue && endTime && startTime
        const isAdmin = (user.roleTemplate.templateType === CONFIG.roleTypes.admin || user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin);
        const { isBatchEdit } = this.props?.match?.params;
        const showOrganiser = isAdmin && !currentSchedule && (scheduleParticipants && scheduleParticipants?.filter(item => item?.id !== user?.id)?.length === 0);
        return (
            <Spin indicator={antIcon} spinning={this.state.isLoadingPage}>
                <div className='neo-scheduler-form'>
                    <Row>
                        <div>
                            <span className='large-text schedule-demo-title'><span style={{ color: "#1B77AF" }}>Solution Demos</span> / Schedule <span style={{ fontWeight: '500' }}>{demo.title}</span> Demo</span>
                        </div>
                        {(this.state.freqType === "1" || (this.state.freqType !== "1" && this.state.isStepOne)) && <div style={{ display: "flex", marginTop: "12px" }}>
                            <div style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}>
                                <Button animation={this.state.showPulse ? "pulse" : "none"} variant="primary" icon="calendar" onClick={() => this.setState({ scheduleAssistantModal: true })} >Calendar View</Button>
                            </div>
                            <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                {(currentSchedule && !isReschedule) && <Button variant="tertiary" id="schedule-delete-button" icon="trash" onClick={() => { this.setState({ deleteModalOpen: true }) }} className="float-right left-spacing-3">Delete Schedule</Button>}
                                <Button className="float-right left-spacing-3" variant="secondary" onClick={() => { this.state.isStepOne ? this.props.closeCurrentOpenTab() : this.setState({ isStepOne: true }) }}>{this.state.isStepOne ? "Discard & Close" : "Back"}</Button>
                                <Button id="schedule-confirm-button" onClick={() => { this.state.isStepOne ? this.handleStepOne() : this.handleOpenConfirmationSchedule() }} className="float-right left-spacing-3" disabled={this.state.isFormDisabled || !isFormCompelete}> {this.state.isStepOne ? "Next" : (currentSchedule && !isReschedule) ? "Update" : "Create"}</Button>
                            </div></div>}
                    </Row>
                    <Row style={{ marginBottom: '5px', marginTop: '5px' }}>
                        <>
                            {(!this.state.demoAccess || !this.state.isActiveUser) && this.renderDemoErrorAlert()}
                            {this.state.demoRequestStatus < 2 && this.renderDemoRequestErrorAlert()}
                        </>
                    </Row>

                    <div style={!this.state.isStepOne ? { display: "none" } : {}}>
                        <Form>
                            <Row gutter={16}>
                                <Col lg={5} sm={12} xs={24}>
                                    <Form.Item label="Time zone">
                                        {getFieldDecorator("timezone", {
                                            rules: [{
                                                required: true,
                                                message: "Please select a TimeZone"
                                            }],
                                            initialValue: timezone ?? userTimeZone
                                        })(
                                            <Select
                                                id="schedule-form-timezone"
                                                showSearch
                                                bordered={false}
                                                suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                                className='organiser-select'
                                                size='large'
                                                onChange={this.handleTimezoneChange}
                                            >
                                                {this.renderTimeZoneOptions()}
                                            </Select>)}
                                    </Form.Item>
                                </Col>
                                <Col lg={5} sm={12} xs={24}>
                                    <Form.Item label="Resources">
                                        {getFieldDecorator("resourceType", {
                                            rules: [{
                                                required: true,
                                                message: "Please select a resource type"
                                            }], initialValue: resourceType || CONFIG.resourceTypes["recommended"].key
                                        })(
                                            <Select
                                                showSearch
                                                bordered={false}
                                                suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                                className='organiser-select'
                                                size='large'
                                                onChange={this.handleResourceTypeChange} >
                                                {
                                                    Object.keys(CONFIG.resourceTypes).map((typeKey, index) => (
                                                        <Option key={index} value={CONFIG.resourceTypes[typeKey].key} >{CONFIG.resourceTypes[typeKey].title}</Option>
                                                    ))
                                                }
                                            </Select>)}
                                    </Form.Item>
                                </Col>
                                {showOrganiser && <Col lg={5} sm={12} xs={24}>

                                    <Form.Item label="Organiser ">
                                        {
                                            getFieldDecorator('assignee', {
                                                rules: [{ required: true, message: 'Please select an Organiser' }],
                                                initialValue: isReschedule
                                                    ? JSON.stringify(user) : currentSchedule ? currentSchedule.createdBy.fullName : this.state.selectedUser ? JSON.stringify(this.state.selectedUser) : JSON.stringify(user)
                                            })(
                                                <Select
                                                    showSearch
                                                    bordered={false}
                                                    placeholder={`Type name to search`}
                                                    suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                                    onSearch={this.fetchUserNames}
                                                    notFoundContent="No Match found!"
                                                    className='organiser-select'
                                                    size='large'
                                                    onChange={this.handleAssigneeChange}
                                                    disabled={currentSchedule || (scheduleParticipants?.length > 0 ? scheduleParticipants?.filter(item => item?.id !== user?.id)?.length > 0 : false)}
                                                    loading={this.state.isLoadingUser}
                                                >
                                                    {<Option value={JSON.stringify(user)} style={{ color: "#242424", fontWeight: "400" }} title="Myself"> Myself</Option>}
                                                    {this.renderUserNamesOptions()}
                                                </Select>
                                            )
                                        }
                                    </Form.Item>
                                </Col>}
                            </Row>

                            <Row gutter={16}>
                                <div className='scheduler-range-picker'>
                                    <Col lg={5} sm={12} xs={24}>
                                        <Form.Item label="Start Date ">
                                            {
                                                getFieldDecorator("startDate", {
                                                    rules: [{ required: true, message: 'Please select the start date' }],
                                                    initialValue: this.state.startDate
                                                })(
                                                    <DatePicker
                                                        id="schedule-form-date-picker"
                                                        placeholder="Start Date"
                                                        style={{ display: "grid" }}
                                                        format={CONFIG.dateFormats.schedulerDate}
                                                        onChange={this.handleStartDateChange}
                                                        allowClear={false}
                                                        disabledDate={this.disabledStartDate}
                                                    />
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5} sm={12} xs={24}>
                                        <Form.Item label="Start Time ">
                                            {
                                                getFieldDecorator("startTime", {
                                                    // id: "startTime",
                                                    rules: [{ required: true, message: 'Please select the start time' }],
                                                    initialValue: this.state.startTime,
                                                })(
                                                    <TimePicker
                                                        style={{ display: "grid" }}
                                                        placeholder="Start Time"
                                                        use12Hours
                                                        format="h:mm a"
                                                        onChange={this.handleStartTimeChange}
                                                        allowClear={false}
                                                        // minuteStep={30}
                                                        // hourStep={1}
                                                        size='large'
                                                        onOpenChange={async () => await this.handleStartTimeCheck()}
                                                        disabledHours={() => this.calculateDisabledHoursAndMinutes()[0]}
                                                        disabledMinutes={() => this.calculateDisabledHoursAndMinutes()[1]}
                                                    />
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5} sm={12} xs={24}>
                                        <Form.Item label="End Date ">
                                            {
                                                getFieldDecorator("endDate", {
                                                    rules: [{ required: true, message: 'Please select the end date' }],
                                                    initialValue: this.state.endDate
                                                })(
                                                    <DatePicker
                                                        style={{ display: "grid" }}
                                                        placeholder="End Date"
                                                        format={CONFIG.dateFormats.schedulerDate}
                                                        allowClear={false}
                                                        disabledDate={this.disabledEndDate}
                                                        onChange={this.handleEndDateChange}
                                                    />
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col lg={5} sm={12} xs={24}>
                                        <Form.Item label="End Time "
                                        >
                                            {
                                                getFieldDecorator("endTime", {
                                                    rules: [{ required: true, message: 'Please select the end time' }],
                                                    initialValue: this.state.endTime
                                                })(
                                                    <TimePicker
                                                        placeholder="End Time"
                                                        style={{ display: "grid" }}
                                                        use12Hours
                                                        format="h:mm a"
                                                        onChange={this.handleEndTimeChange}
                                                        // minuteStep={30}
                                                        allowClear={false}
                                                        // hourStep={1}
                                                        size='large'
                                                        disabledHours={() => this.calculateDisabledHoursAndMinutesForEndTime()[0]}
                                                        disabledMinutes={() => this.calculateDisabledHoursAndMinutesForEndTime()[1]}
                                                    />
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ marginTop: "8px" }} lg={2} sm={12} xs={24}>
                                        <Form.Item label={
                                            <>
                                                Start Buffer {" "}
                                                <Tooltip title={CONFIG.bufferTimeInfo.startBufferTime} placement="topLeft" overlayStyle={{ minWidth: "500px", minHeight: "30px" }}>
                                                    <Icon className="text-color-primary" icon="info-outline" />
                                                </Tooltip>
                                            </>
                                        }>
                                            {getFieldDecorator("StartBuffer", {
                                                rules: [{
                                                }], initialValue: (demo?.startTimeBuffer || " 0") + " mins"
                                            })(
                                                <TextInput readOnly aria-label="Start Buffer" defaultValue={(demo?.startTimeBuffer || " 0") + " mins"} value={(demo?.startTimeBuffer || " 0") + " mins"} />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ marginTop: "8px" }} lg={2} sm={12} xs={24}>
                                        <Form.Item label={
                                            <>
                                                End Buffer {" "}
                                                <Tooltip title={CONFIG.bufferTimeInfo.endBufferTime} placement="topLeft" overlayStyle={{ minWidth: "500px", minHeight: "30px" }}>
                                                    <Icon className="text-color-primary" icon="info-outline" />
                                                </Tooltip>
                                            </>
                                        }>
                                            {getFieldDecorator("EndBuffer", {
                                                rules: [{
                                                }], initialValue: (demo?.endTimeBuffer || " 0") + " mins"
                                            })(
                                                <TextInput readOnly aria-label="End Buffer" defaultValue={(demo?.endTimeBuffer || " 0") + " mins"} value={(demo?.endTimeBuffer || " 0") + " mins"} />)}
                                        </Form.Item>
                                    </Col>
                                </div>
                            </Row>
                            {(!isMultipleDays && demo?.schTimeLimit && demo?.schTimeLimit > 0) && <Row gutter={16} style={{ marginBottom: '16px' }}>
                                <Col style={{ marginTop: "8px" }} lg={5} sm={12} xs={24}>
                                    <span ><Icon icon="warning" /> Demo time limit: </span>{isMultipleDays ? "No Limit" : (demo?.schTimeLimit / 60 || " 0") + " hours"}

                                </Col>
                            </Row>}
                            {demo?.notes &&
                                <Row style={{ marginBottom: '16px' }}>
                                    <Col>
                                        <Row style={{ marginBottom: '5px' }}>
                                            <span style={{ fontSize: "12px" }}>Notes:</span>
                                        </Row>
                                        <Row>
                                            <span style={{ paddingBottom: '10px' }}>{demo.notes}</span>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </Form>
                        <ResourceDetails
                            form={this.props.form}
                            demo={demo}
                            handleCurrentScheduleChange={this.handleCurrentScheduleChange}
                            isCurrentSchedule={this.state.isCurrentSchedule}
                            match={this.props.match}
                            isFetchingScheduleData={this.props.isFetchingScheduleData}
                            startTime={this.state.startTime}
                            endTime={this.state.endTime}
                            handleFormDisableChange={this.handleFormDisableChange}
                            isReschedule={isReschedule} /> </div >
                </div >
                <div style={this.state.isStepOne ? { display: "none" } : {}}>
                    <NeoStandardDemoDetailsPage
                        handleParticipantsChange={this.handleParticipantsChange}
                        form={this.props.form}
                        demo={demo}
                        handleCurrentScheduleChange={this.handleCurrentScheduleChange}
                        isCurrentSchedule={this.state.isCurrentSchedule}
                        isReschedule={isReschedule}
                        handleStepChange={(value) => this.setState({ isStepOne: value })}
                        handleFreqChange={this.handleFreqChange}
                        formDisabled={this.state.isFormDisabled}
                        match={this.props.match}
                    />
                </div>
                {
                    this.state.isModalVisible &&
                    <StandardDemoConfirmationModal
                        handleSchedule={this.handleSchedule}
                        selectedResource={selectedResource}
                        demo={demo}
                        form={this.props.form}
                        match={this.props.match}
                        handleModalClose={this.handleModalClose}
                        isModalVisible={this.state.isModalVisible}
                        isReschedule={isReschedule} />
                }
                {
                    this.state.deleteModalOpen && <ScheduleDeleteConfirmation
                        schedule={currentSchedule}
                        handleModalClose={() => { this.setState({ deleteModalOpen: false }) }}
                        isBatchDelete={isBatchEdit ? true : false}
                        isLoggedInUserCreator={currentSchedule?.createdBy?.id === user?.id}
                        hasParticipants={currentSchedule?.participants !== null}
                        handleDelete={this.handleDeleteSchedule}
                        isModalVisible={this.state.deleteModalOpen}
                    />
                }
                {
                    this.state.scheduleAssistantModal && <SchedulingAssistant
                        demo={demo}
                        startTime={this.state.startTime}
                        timezone={this.props.timezone}
                        visible={this.state.scheduleAssistantModal}
                        handleCloseSchedulingAssistant={() => this.setState({ scheduleAssistantModal: false })}
                        className="std"
                    />
                }
                <SchedulerHelpButton />
            </Spin >
        );
    }
}

const NeoStandardDemoScheduleFormRef = Form.create()(NeoStandardDemoScheduleForm);

const mapStateToProps = ({ scheduler, user, newSchedule }) => {
    return {
        timezone: scheduler.selectedTimezone,
        startTime: scheduler.startTime,
        endTime: scheduler.endTime,
        resourceType: scheduler.resourceType,
        isMultipleDays: user.isMultipleDays,
        userTimeZone: user.profile ? user.profile.timezone : undefined,
        user: user.profile,
        selectedResource: scheduler.selectedResource,
        currentSchedule: newSchedule.currentSchedule,
        userPurposeTypes: newSchedule.userPurposeTypes ? newSchedule.userPurposeTypes : [],
        isMultipleScheduleBooking: user.profile?.isMultipleScheduleBooking || false,
        isFetchingScheduleData: scheduler?.isFetchingScheduleData,
        resources: scheduler.resources,
        customerInfo: newSchedule?.customerInfo
    };
}

export default connect(
    mapStateToProps, {
    updateSelectedResourceType,
    refreshScheduler,
    updateSchedulerTimezone,
    updateSchedulerStartEndTime,
    fetchAvailableDemoResources,
    fecthDemoPurposeTypesLite,
    closeCurrentOpenTab,
    postNewDemoSchedule,
    clearAllNewEvents,
    updateSelectedResource,
    fetchScheduleById,
    toggleIsFetchingSchedulerData,
    clearSelectedResource,
    deleteDashboardSchedule,
    optOutSchedule,
    deleteBatchDemoSchedule,
    toggleIsFetchingDashboardSchedules,
    fetchDashboardActiveSchedules,
    clearDashboardSchedules
}

)(NeoStandardDemoScheduleFormRef);