import React, { Component } from 'react'
import { Spin, Table, Typography } from "antd";
const { Text } = Typography

export default class ActiveMaintenanceTable extends Component {
    componentDidMount() {
        //document.getElementById('container').scrollIntoView();

        var tableContent = document.querySelector('.active .ant-table-body')
        tableContent && tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight
            let currentScroll = Math.ceil(event.target.scrollTop)
            // (currentScroll === maxScroll && (maxScroll !== 0 && currentScroll !== 0))
            if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (maxScroll !== 0 && currentScroll !== 0) && (currentScroll >= maxScroll && (maxScroll !== 0 && currentScroll !== 0))) {
                this.props.getActiveMaintenanceSchedules()
            }
        })
    }
    render() {
        const { loading, dataSource, tableLoading, count, columns } = this.props;
        const fetchedRecords = dataSource[dataSource.length - 1]?.index || 0;
        return (
            <Spin spinning={loading}>
                <Table
                    size='middle'
                    className="responsive-container active"
                    rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                    scrollToFirstRowOnChange={true}
                    rowKey={(record) => record.id}
                    bordered
                    scroll={{ y: 550, x: 2000 }}
                    dataSource={dataSource}
                    columns={columns}
                    loading={tableLoading}
                    onChange={(...rest) => this.props.handleSortChange(true, ...rest)}
                    pagination={false}
                    footer={() => <>{fetchedRecords !== 0 && <div style={{ textAlign: 'center' }}>
                        <Text strong>Fetched {fetchedRecords} out of {count} active Schedule Maintenances</Text>
                    </div>}
                    </>}
                />
            </Spin>
        )
    }
}
