import React from 'react';
import { connect, batch } from 'react-redux';
import { DatePicker, Form, Input, Row, Col, Button, Spin, Modal, Switch, Alert } from 'antd';
import moment from 'moment-timezone';
import HTML from '../common/HTML'

import {
    toggleBackButton,
    updateNotificationDescription,
    toggleIsFetchingNotificationsFlag,
    fetchNotification,
    postNotification,
    updateNotificationRoleTemplate,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    saveNotificationRoleTemplates,
    cancelNotificationRoleTemplates,
    clearNotificationForm,
    currentNotificationFilter,
    updateNotificationsCurrentPage,
    fetchAccessForEAPOffline,
    updateNotificationTabDetails,
    updateTabsLocalStorage,
    resetNotificationForm,
    deleteTab,
    toggleHasUnsavedChangesFlag,
    closeCurrentOpenTab,
    clearFormData,
    postDraftNotification,
    deleteNotification,
    handleOnClickAction
} from '../../actions';
import TextEditor from '../common/TextEditor';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import FormActionButtons from '../common/FormActionButtons';
import CONFIG from '../../config';
import history from '../../history';
import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';
import EmailUpdates from '../common/EmailUpdates';
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
import { getStartOfDateValue, range, areDatesEqual, areHoursEqual, getDateTime } from '../../utils/datepicker';
class NotificationsForm extends React.Component {

    constructor(props) {
        super(props);
        this.isEditMode = this.props.match.params.notificationId !== undefined;
        this.draftMode = window.location.pathname?.includes("/notifications/draft");

        this.state = {
            visible: false,
            isOpen: false,
            startValue: null,
            endValue: null,
            eapOfflineCheckBox: false,
            isClone: false,
        }

        this.selectedTime = {
            start: null,
            end: null
        }

        this.timeCleared = false
    }

    saveSelectedTime = () => {
        const { form } = this.props;
        const startTime = form.getFieldValue('publishTime')?.format(CONFIG.dateFormats.crossBrowserDefault);
        const endTime = form.getFieldValue('expiryTime')?.format(CONFIG.dateFormats.crossBrowserDefault);

        this.selectedTime = {
            start: startTime ? moment(startTime) : undefined,
            end: endTime ? moment(endTime) : undefined
        };
    }

    resetTime = (isReset) => {
        const { form } = this.props;
        const startTime = (form.getFieldValue('publishTime') !== undefined && form.getFieldValue('publishTime') !== null) ? form.getFieldValue('publishTime').format(CONFIG.dateFormats.crossBrowserDefault) : form.getFieldValue('expiryTime').format(CONFIG.dateFormats.crossBrowserDefault);
        const endTime = (form.getFieldValue('expiryTime') !== undefined && form.getFieldValue('expiryTime') !== null) ? form.getFieldValue('expiryTime').format(CONFIG.dateFormats.crossBrowserDefault) : form.getFieldValue('publishTime').format(CONFIG.dateFormats.crossBrowserDefault);

        const newStart = isReset ? moment(startTime).startOf('day') : this.selectedTime.start;
        const newEnd = isReset ? moment(endTime).endOf('day') : this.selectedTime.end;

        form.setFieldsValue({ publishTime: newStart })
        form.setFieldsValue({ expiryTime: newEnd })
    }

    handleAllDayChange = (isAllDay) => {
        if (isAllDay) {
            this.saveSelectedTime();
            this.resetTime(true);
        }
        else this.resetTime(false);
    }

    handleEAPOfflineCheckBoxChange = (value) => {
        this.setState({ eapOfflineCheckBox: value });

    }

    handleCreate = () => {
        const { form } = this.props;
        const { notificationId } = this.props.match.params;
        const draftMode = window.location.pathname?.includes("/notifications/draft");
        const isCloneNotification = window.location.pathname?.includes("/notifications/clone");
        form.validateFields((err, values) => {
            if (err) return;
            if (values.publishTime === values.expiryTime) pushMessage(CONFIG.messageType.error, "End time cannot be same as Start time")
            else {
                this.props.toggleIsFetchingNotificationsFlag(true);
                this.props.postNotification(values, (notificationId || "-1"), isCloneNotification, draftMode)
            }
        });
    }


    handleSaveDraft = (isDraftEdit) => {
        const currentNotificationData = this.getCurrentNotificationData();
        const isCloneNotification = currentNotificationData ? currentNotificationData?.isClone : window.location.pathname?.includes("/notifications/clone");
        const isDraftCreatedOutOfParent = (isDraftEdit === false && currentNotificationData?.id > 0)
        const { bodyHtml, details } = currentNotificationData || [];
        const currentData = this.props.form.getFieldsValue();
        const title = currentData?.title?.trim();
        const validityStartTime = currentData?.validityStartTime ? moment.tz(currentData?.validityStartTime, this.props.timeZoneName) : undefined;
        const validityEndTime = currentData?.validityEndTime ? moment.tz(currentData?.validityEndTime, this.props.timeZoneName) : undefined;
        const notifyUsers = currentData?.notifyUsers;
        const isOverride = currentData?.isOverride;
        const eapOffline = currentData?.eapOffline;
        const roleTemplates = Array.isArray(currentData?.roletemplates) && currentData?.roletemplates?.length > 0
        if (isOverride === undefined && notifyUsers === undefined && bodyHtml === undefined
            && eapOffline === undefined && validityStartTime === undefined && validityEndTime === undefined
            && title === "" && roleTemplates === false) {
            pushMessage(CONFIG.messageType.warning, "Draft cannot be empty!");
        }
        else {
            this.props.postDraftNotification({
                notificationId: (this.props.match.params.notificationId || "-1"),
                notification: currentData,
                isDraftEdit,
                parentNotificationObj: isDraftCreatedOutOfParent ? { id: details?.id } : null,
                // parentNotificationObj: isDraftCreatedOutOfParent ? { id: details?.id } : details?.parentNotification ? details?.parentNotification : null,
                isCloneNotification
            })
        }
    }

    getCurrentNotificationData = () => {
        const { notificationDetailsTabData } = this.props;
        const isClone = this.state.isClone;
        const { notificationId } = this.props.match.params;
        const id = notificationId !== undefined ? notificationId : "-1";
        return notificationDetailsTabData?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isClone)));
    }

    handleCloseTab = async () => {
        this.props.closeCurrentOpenTab();
    }

    async componentDidMount() {
        document.getElementById('tab-pane').scrollIntoView();
        const { notificationId } = this.props.match.params;
        const { previousTabs } = this.props;
        const { userRoleTemplate } = this.props;
        // const pageSize = notificationsIndex ? ((Math.ceil(notificationsIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize) : CONFIG.lazyLoadPageSize;
        // const currentPageNumber = ((Math.ceil(notificationsIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;

        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const currentPath = window.location.pathname;
        const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;

        const isCloneNotification = window.location.pathname?.includes("/notifications/clone");
        await this.setState({ isClone: isCloneNotification })

        if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
            if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                batch(async () => {
                    await this.props.fetchAccessForEAPOffline()
                    fetchAllRoleTemplates()
                        .then(() => {
                            if (notificationId) {
                                // this.props.currentNotificationFilter(filterSort, status);
                                this.props.toggleIsFetchingNotificationsFlag(true);
                                this.props.fetchNotification(notificationId, isCloneNotification)
                                    .then(() => { this.setState({ eapOfflineCheckBox: this.props.eapOffline }) })
                            }
                        });
                    this.props.updateTabsLocalStorage(currentPath);
                    this.props.updateNotificationTabDetails({
                        notificationId: this.props.match.params?.notificationId !== undefined
                            ? this.props.match.params?.notificationId
                            : "-1",
                        notificationDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                        isClone: this.state.isClone
                    });
                });
            }
        } else {
            // Clearing the old form data - could be uncleared CREATE form / UPDATE form
            await this.props.clearFormData(CONFIG.createComponentRoute.notifications.title, (this.props.match.params.notificationId || '-1'), window.location.pathname?.includes("clone"));
            batch(async () => {
                this.props.toggleBackButton(false);
                await this.props.fetchAccessForEAPOffline()
                // this.props.updateNotificationsCurrentPage(currentPageNumber, pageSize, notificationId, notificationsIndex)

                fetchAllRoleTemplates()
                    .then(() => {
                        if (notificationId) {
                            // this.props.currentNotificationFilter(filterSort, status);
                            this.props.toggleIsFetchingNotificationsFlag(true);
                            this.props.fetchNotification(notificationId, isCloneNotification)
                                .then(() => { this.setState({ eapOfflineCheckBox: this.props.eapOffline }) })
                        } else {
                            // this.props.updateTabsLocalStorage(currentPath);
                            this.props.updateNotificationTabDetails({
                                notificationId: this.props.match.params?.notificationId !== undefined
                                    ? this.props.match.params?.notificationId
                                    : "-1",
                                notificationDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                                isClone: this.state.isClone
                            });
                        }
                    });
            });
        }
        // this.setState({ isClone: isCloneNotification })
        this.descriptionKey = Math.random().toString();

    }

    disabledStartDate = (start) => {
        const { timeZoneName, form } = this.props;
        // const currentNotificationData = this.getCurrentNotificationData();
        // const { details } = currentNotificationData || [];
        const endTime = form.getFieldValue('expiryTime');
        const end = this.state.endValue;
        const startValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(start) });
        const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timeZoneName });
        const endValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(end) });
        const expiryValue = endTime ? endTime.valueOf() : undefined;
        const startTimeCondition = startValue < currentValue;
        const expiryTimeCondition = endTime ? startValue > expiryValue : false;

        if (!start || !end) return endTime ? (startTimeCondition || expiryTimeCondition) : startTimeCondition;

        return startValue > endValue || startTimeCondition;
    };

    disabledEndDate = end => {
        const { timeZoneName, form } = this.props;
        // const currentNotificationData = this.getCurrentNotificationData();
        // const { details } = currentNotificationData || [];
        const startTime = form.getFieldValue('publishTime');
        const start = this.state.startValue;
        const endValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(end) });
        const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timeZoneName });
        const startValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(start) });
        const publishValue = startTime ? startTime.valueOf() : undefined;

        const endTimeCondition = endValue < currentValue;
        const publishTimeValue = startTime ? endValue < publishValue : false;

        if (!end || !start) return startTime ? (publishTimeValue || endTimeCondition) : endTimeCondition

        return endValue < startValue;
    };

    disabledPublishTime = (current) => {
        const { endValue } = this.state;
        if (endValue && areDatesEqual(endValue, current)) {
            return {
                disabledHours: () => range(endValue.get('hours') + 1, 24),
                disabledMinutes: () => areHoursEqual(endValue, current) ? range(endValue.get('minute'), 60) : []
            };
        }
    }

    disabledExpiryTime = (current) => {
        const { startValue } = this.state;
        if (startValue && areDatesEqual(startValue, current)) {
            return {
                disabledHours: () => range(0, startValue.get('hours')),
                disabledMinutes: () => areHoursEqual(startValue, current) ? range(0, startValue.get('minute')) : []
            };
        }
    }

    handlePublishOpenChange = (isOpen) => {
        const { form } = this.props;
        const { endValue } = this.state;
        if (!isOpen) {
            const startValue = form.getFieldValue('publishTime');
            if (endValue && startValue && endValue.valueOf() < startValue.valueOf()) form.setFieldsValue({ publishTime: undefined });
        }
    }

    handleExpiryOpenChange = (isOpen) => {
        const { form } = this.props;
        const { startValue } = this.state;
        if (!isOpen) {
            const endValue = form.getFieldValue('expiryTime');
            if (endValue && startValue && endValue.valueOf() < startValue.valueOf()) form.setFieldsValue({ expiryTime: undefined });
        }
    }

    handleChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };

    handleStartChange = value => {
        this.handleChange('startValue', value);
    };

    handleEndChange = value => {
        this.handleChange('endValue', value);
    };

    handleExpiryOk = () => {
        const { form } = this.props
        if (!form.getFieldValue('publishTime')) {
            form.setFields({
                publishTime: { errors: [Error("Please select start date")] },
                expiryTime: { value: "" }
            })
            this.setState({
                endValue: null
            });
        }
    }

    renderAssignButton = (hasDraft) => {
        return <Button disabled={hasDraft} className="primary-action-button-bordered right-border-radius" onClick={this.showModal} id='notification-roletemplate-assign'>Assign</Button>;
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleRoleTemplatesCancel = e => {
        const { notificationId } = this.props.match.params;
        const id = notificationId !== undefined ? notificationId : "-1";
        this.props.cancelNotificationRoleTemplates(id, this.state.isClone);
        this.setState({
            visible: false
        });
    };

    handleRoleTemplatesAssign = () => {
        const { notificationId } = this.props.match.params;
        const id = notificationId !== undefined ? notificationId : "-1";
        this.props.saveNotificationRoleTemplates(id, this.state.isClone);
        this.setState({ visible: false });
    }

    renderWarningMessage = () => {
        return <Alert message={CONFIG.systemMessage.ckEditorSourceModeWarning} type="warning" showIcon />;
    }

    getAssignedRoleTemplates = (isSuperAdminUser) => {
        let assignedRoleTemplates = [];
        const currentNotificationData = this.getCurrentNotificationData();
        const { details } = currentNotificationData || {};
        const { roleTemplates } = details || [];
        if (roleTemplates) {
            roleTemplates.forEach(obj => {
                const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            })
        }
        return assignedRoleTemplates;
    }

    componentWillUnmount() {
        // Checking if the tab is still in the openTab list. If yes, then update store with form data.
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        const currentNotification = this.getCurrentNotificationData();
        const { bodyHtml, originalDetails } = currentNotification || [];
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
            this.props.updateNotificationTabDetails({
                notificationId: this.props.match.params?.notificationId !== undefined
                    ? this.props.match.params?.notificationId
                    : "-1",
                notificationDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined, bodyHtml: bodyHtml, originalDetails: originalDetails },
                isClone: this.state.isClone
            });
        }
    }

    handleReset = async () => {
        await this.props.deleteTab(this.props.activeKey, true)
        history.replace(this.props.activeKey)
        if (this.props.openedTabs?.length === 0) {
            history.replace('/getStarted');
            // this.props.selectTitle(this.getTitle());
        }
    }

    createCurrentObj = (newData, newBodyHtml) => {
        const currentData = this.props.form.getFieldsValue();
        return {
            validityStartTime: currentData.publishTime ? moment.tz(currentData.publishTime, this.props.timeZoneName) : undefined,
            validityEndTime: currentData.expiryTime ? moment.tz(currentData.expiryTime, this.props.timeZoneName) : undefined,
            title: currentData.title,
            roleTemplates: newData?.roleTemplates?.filter(rT => (rT.adminAccess !== 0 || rT.userAccess !== 0)),
            bodyHtml: newBodyHtml,
            notifyUsers: currentData.notifyUsers,
            isOverride: currentData.isOverride,
            eapOffline: currentData.eapOffline
        }
    }

    hasNotificationChanged = (oldData, newData) => {
        const oldRts = oldData?.roleTemplates?.filter(rT => ((rT?.adminAccess && rT.adminAccess !== 0) || (rT?.userAccess && rT.userAccess !== 0)));
        let hasChanged = false;
        const { hasAccessToEAPOffline } = this.props;
        const isValidStartTime = !(oldData?.validityStartTime === undefined && newData?.validityStartTime === undefined)
        const isValidEndTime = !(oldData?.validityEndTime === undefined && newData?.validityEndTime === undefined)

        if (
            oldData?.title !== newData?.title ||
            (!isValidStartTime ? false : !oldData?.validityStartTime?.isSame(newData?.validityStartTime)) ||
            (!isValidEndTime ? false : !oldData?.validityEndTime?.isSame(newData?.validityEndTime)) ||
            oldData?.notifyUsers !== newData?.notifyUsers ||
            oldData?.isOverride !== newData?.isOverride ||
            (hasAccessToEAPOffline ? oldData?.eapOffline !== newData?.eapOffline : false) ||
            oldData?.bodyHtml?.trim() !== newData?.bodyHtml?.trim() ||
            JSON.stringify(oldRts) !== JSON.stringify(newData?.roleTemplates)
        ) {
            hasChanged = true;
        }

        return hasChanged;
    }

    componentDidUpdate() {
        const { notificationId } = this.props.match.params;
        const isCloneNotification = window.location.pathname?.includes("/notifications/clone");
        const currentNotificationData = this.getCurrentNotificationData();
        const { originalDetails, details, bodyHtml } = currentNotificationData || [];
        const currentData = this.createCurrentObj(details, bodyHtml);
        this.hasChanged = (notificationId && !isCloneNotification) ? this.hasNotificationChanged(originalDetails, currentData) : true;
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    }

    handleDelete = (details) => {
        const okButtonText = "Confirm Deletion"
        const content = <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Notification Draft")}</h4>
        const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'Notification Draft');
        openConfirm(okButtonText, () => this.handleDeleteConfirmation(details), null, content, modalTitle);
    }

    handleDeleteConfirmation = async (details) => {
        await this.props.deleteNotification(details?.draftNotification?.id, true, true);
        await this.props.closeCurrentOpenTab();
        await this.props.handleOnClickAction({
            component: CONFIG.editComponentRoute.notifications,
            tabTitle: details?.title,
            recordId: details?.id,
        })
    }

    renderDraftWarningMessage = (details) => {
        const { user } = this.props;

        const draftCreator = details?.draftNotification?.createdBy?.fullName
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const selfDraftCreator = user?.id === details?.draftNotification?.createdBy?.id;

        return (
            <>
                <Alert
                    message={selfDraftCreator ? "Continue from where you left!" : "Can't edit this page"}
                    description={
                        <p>{selfDraftCreator ? `You have some unsaved changes on this page. ` : isSuperAdminUser ? `This page cannot be edited because another user - ${draftCreator} has unsaved changes.` : `This page cannot be edited because another admin ${draftCreator} has unsaved changes. Please ask ${draftCreator} to publish the page, or delete the draft.`}<br /><br />
                            {(selfDraftCreator || isSuperAdminUser) && <span>How do you like to proceed?</span>}<br />
                            {(selfDraftCreator || isSuperAdminUser) && <ul className='padding-left-30px'>
                                <li>Continue <span className='link' onClick={async () => {
                                    this.props.closeCurrentOpenTab();
                                    await this.props.handleOnClickAction({
                                        component: CONFIG.draftEditComponentRoute.notification,
                                        tabTitle: details?.draftNotification?.title,
                                        recordId: details?.draftNotification?.id,
                                    })
                                }}
                                >editing </span>existing draft.</li>
                                <li>
                                    <span className='link' onClick={async () => this.handleDelete(details)}
                                    >Delete </span>existing draft to edit this page.<br />
                                </li>
                            </ul>}
                        </p>
                    }
                    type="warning"
                    showIcon
                />
                <br />
            </>
        )
    }

    renderFormButtonsUnsavedChanges = () => {
        return (
            <Row className="right-align vertical-spacing">
                <Button onClick={() => this.handleCloseTab()} className={`cancel-button button-width`}>Close</Button>
            </Row>
        )
    }

    renderFormButtons = (isCloneNotification, draftMode) => {
        return (
            <Row className="vertical-spacing right-align">
                <Col xl={20} sm={24} xs={24} >
                     <Button onClick={this.handleCloseTab} className={`cancel-button`} id='notification-discard-changes'>
                        {'Discard Changes'}
                    </Button>
                    <Button disabled={draftMode ? false : !this.hasChanged}
                            className={`left-spacing primary-action-button-filled`}
                            onClick={this.handleCreate} id='notification-action-confirm'>
                        {draftMode ? "Create Notification" : this.isEditMode && !isCloneNotification ? "Update" : "Create"}
                    </Button>
                    <Button
                        disabled={!this.hasChanged}
                        id='notification-form-save-draft'
                        className="left-spacing primary-action-button-filled button-width"
                        onClick={() => this.handleSaveDraft(draftMode)}>
                        {draftMode ? "Update Draft" : "Save Changes as Draft"}
                    </Button>
                </Col>
            </Row>
        )
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const currentNotificationData = this.getCurrentNotificationData();
        const { bodyHtml, details, isContentLoaded, fetchFromAPI } = currentNotificationData || [];
        const isCloneNotification = currentNotificationData ? currentNotificationData?.isClone : window.location.pathname?.includes("/notifications/clone");
        const { roleTemplates, isFetching, userRoleTemplate, timeZoneName, hasAccessToEAPOffline } = this.props;
        const currentDateTime = getDateTime({ convertTimeZone: true, timeZone: timeZoneName });
        const startEndTime = details?.validityStartTime || details?.validityEndTime ? [details?.validityStartTime, details?.validityEndTime] : this.props.form.getFieldValue('publishTime') || this.props.form.getFieldValue('expiryTime');
        const showSwitch = !this.timeCleared ? startEndTime : false;
        const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
        const draftMode = window.location.pathname?.includes("/notifications/draft");
        const { hasDraft } = isCloneNotification ? false : details || false;
        const showAssignRoleTemplates = (this.isEditMode && details?.eapOffline) ? false : this.state.eapOfflineCheckBox ? false : true

        return (
            <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                <div className="content-container">
                    <Form>
                        {hasDraft ? this.renderFormButtonsUnsavedChanges() : this.renderFormButtons(isCloneNotification, draftMode)}
                        {hasDraft && this.renderDraftWarningMessage(details)}
                        <Row gutter={48}>
                            <Col xl={10} sm={20} xs={24}>
                                <Form.Item label="Title">
                                    {
                                        getFieldDecorator("title", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please input the title of the notification"
                                                }
                                            ],
                                            initialValue: currentNotificationData ? (isCloneNotification && fetchFromAPI) ? details?.title + '_clone' : details?.title : ""
                                        })(<Input disabled={hasDraft} />)
                                    }
                                </Form.Item>
                            </Col>
                            {showAssignRoleTemplates && <Col xl={10} sm={20} xs={24}>
                                <Form.Item label="Assign Role Template">
                                    {getFieldDecorator("roletemplate", {
                                        initialValue: assignedRoleTemplates.length !== 0
                                            ? assignedRoleTemplates
                                            : "None"
                                    })(<Input disabled addonAfter={this.renderAssignButton(hasDraft)} />)}
                                    {<Modal
                                        title="Role Template"
                                        visible={this.state.visible}
                                        onCancel={this.handleRoleTemplatesCancel}
                                        footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                                    >
                                        <RoleTemplatesTable
                                            hideColumns={isSuperAdminUser ? [] : ['admin']}
                                            dataKey="roleTemplate"
                                            data={roleTemplates.all}
                                            pagination={false}
                                            assignedData={details?.roleTemplates}
                                            allUserAccess={details?.allRoleTemplatesUserAccess}
                                            allAdminAccess={details?.allRoleTemplatesAdminAccess}
                                            updateRoleTemplateData={this.props.updateNotificationRoleTemplate}
                                            isClone={this.state.isClone}
                                            recordId={this.isEditMode ? this.props.match.params.notificationId : "-1"}
                                        />
                                    </Modal>
                                    }
                                </Form.Item>
                            </Col>}
                        </Row>
                        {!hasDraft && <Col><EmailUpdates
                            form={this.props.form}
                            showIsOverride={true}
                            colSpan={6}
                            initialValue={[details?.notifyUsers, details?.isOverride]}
                            eapOfflineObject={[hasAccessToEAPOffline ? true : false, details?.eapOffline, this.handleEAPOfflineCheckBoxChange, !showAssignRoleTemplates, this.isEditMode]}
                        />
                        </Col>}
                        <Row>
                            <Col xl={20} sm={24} xs={24}>
                                <div className="bottom-spacing">
                                    <h3>Description</h3>
                                    {!hasDraft && this.renderWarningMessage()}
                                    {!hasDraft && <br />}
                                    {hasDraft ?
                                        <div className="ckeditor-readonly"><HTML htmlContent={bodyHtml} /></div>
                                        : <TextEditor
                                            updateEditorContent={this.props.updateNotificationDescription}
                                            storeKey="notificationsDescription"
                                            editorKey={this.descriptionKey}
                                            componentKey={CONFIG.editorType.notificationDescription}
                                            isEdit={this.isEditMode}
                                            recordId={this.isEditMode ? this.props.match.params.notificationId : "-1"}
                                            contentData={bodyHtml}
                                            isClone={this.state.isClone}
                                            isContentLoaded={isContentLoaded} />
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col xl={8} sm={20} xs={24} >
                                <Form.Item label="Publish on">
                                    {getFieldDecorator("publishTime", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please select publish date"
                                            }
                                        ],
                                        initialValue: currentNotificationData ? details?.validityStartTime ? details?.validityStartTime : undefined : undefined
                                    })(<DatePicker
                                        className="full-width"
                                        disabled={hasDraft}
                                        disabledDate={this.disabledStartDate}
                                        disabledTime={this.disabledPublishTime}
                                        showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                        format={CONFIG.dateFormats.default}
                                        placeholder="Publish Date"
                                        onChange={this.handleStartChange}
                                        onOpenChange={this.handlePublishOpenChange}
                                        showToday={false}
                                        defaultPickerValue={currentDateTime}
                                    />)}
                                </Form.Item>
                            </Col>
                            <Col xl={8} sm={20} xs={24} >
                                <Form.Item label="Expire on">
                                    {getFieldDecorator("expiryTime", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please select expiry date"
                                            }
                                        ],
                                        initialValue: currentNotificationData ? details?.validityEndTime ? details?.validityEndTime : undefined : undefined
                                    })(<DatePicker
                                        className="full-width"
                                        disabled={hasDraft}
                                        disabledTime={this.disabledExpiryTime}
                                        disabledDate={this.disabledEndDate}
                                        onOpenChange={this.handleExpiryOpenChange}
                                        showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                        format={CONFIG.dateFormats.default}
                                        placeholder="Expiry Date"
                                        onChange={this.handleEndChange}
                                        showToday={false}
                                        defaultPickerValue={currentDateTime}
                                    />)}
                                </Form.Item>
                            </Col>
                            {
                                showSwitch &&
                                (
                                    <Col xl={4} sm={7} xs={24}>
                                        <Form.Item label="All Day" className="top-spacing-5">
                                            {getFieldDecorator("isAllDay", {
                                                valuePropName: "checked", initialValue: currentNotificationData ? details?.isAllDay : undefined
                                            })(
                                                <Switch
                                                    checkedChildren="Yes"
                                                    disabled={hasDraft}
                                                    unCheckedChildren="No"
                                                    onClick={this.handleAllDayChange}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                )
                            }
                        </Row>
                        {hasDraft ? this.renderFormButtonsUnsavedChanges() : this.renderFormButtons(isCloneNotification, draftMode)}
                    </Form>
                </div >
            </Spin >
        );
    }
}

const mapStateToProps = ({ notificationsForm, roleTemplates, user, reports, notificationsDescription, tabsLayout, notificationFormDetails }) => {
    const { isFetching } = notificationsForm;
    return {
        isFetching,
        roleTemplates,
        notificationRoleTemplates: notificationsForm.roleTemplates,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        hasAccessToEAPOffline: reports.hasAccessToEAPOffline,
        user: user.profile,
        notificationsDescription,
        activeKey: tabsLayout.activeKey,
        openedTabs: tabsLayout.panes,
        previousTabs: tabsLayout?.previousTabs,
        notificationDetailsTabData: notificationFormDetails.NotificationDetailsTabData,
        notificationDescription: notificationFormDetails.htmlBody
    };
}

const NotificationsFormRef = Form.create()(NotificationsForm);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        updateNotificationDescription,
        toggleIsFetchingNotificationsFlag,
        fetchNotification,
        postNotification,
        updateNotificationRoleTemplate,
        fetchRoleTemplates,
        fetchAdminRoleTemplates,
        saveNotificationRoleTemplates,
        cancelNotificationRoleTemplates,
        clearNotificationForm,
        currentNotificationFilter,
        updateNotificationsCurrentPage,
        fetchAccessForEAPOffline,
        updateNotificationTabDetails,
        updateTabsLocalStorage,
        resetNotificationForm,
        deleteTab,
        toggleHasUnsavedChangesFlag,
        closeCurrentOpenTab,
        clearFormData,
        postDraftNotification,
        deleteNotification,
        handleOnClickAction
    }
)(NotificationsFormRef);