import React from 'react'
import { Form, Input, Checkbox, Radio, Modal, Row } from 'antd';
import CONFIG from '../../config';
import { connect } from "react-redux";
import { Button } from "@avaya/neo-react"
import {
    selectTitle,
    toggleBackButton,
    postPreRegistrationForm,
    putPreRegistrationForm
}
    from '../../actions';
const { TextArea } = Input;

class DemoRegistrationModal extends React.Component {

    handleSubmit = () => {
        const { form, demo, isEdit, rawData } = this.props;
        const { preRegistrationDemoQuestions } = demo || {};
        const requestId = !!isEdit ? rawData?.id : false;
        form.validateFieldsAndScroll(async (err, values) => {
            if (err) return;
            else {
                const formValues = Object.entries(values).map(([questionId, answerText]) => ({ questionId, answerText }));
                const userAnswersObj = formValues.map(fv => {
                    fv.questionOptions = preRegistrationDemoQuestions.filter(dq => dq.question?.id === parseInt(fv.questionId))?.[0]?.question?.answers?.map(a => a.title).join('//') || null;
                    return fv;
                })

                requestId
                    ? await this.props.putPreRegistrationForm(userAnswersObj, requestId).then(() => this.handleAfterSubmit())
                    : await this.props.postPreRegistrationForm(userAnswersObj, demo?.id).then(() => this.handleAfterSubmit());

            }
        });
    }

    handleAfterSubmit = () => {
        this.props.handleDemoRequestSubmit();
    }

    renderRegistrationQuestions = (questions) => {
        const { getFieldDecorator } = this.props.form;
        const { rawData } = this.props || {};
        const { question, answerText } = questions;
        const { title, id, answers, answerType, isMandatory } = question;
        const readOnly = rawData?.viewOnly || false;
        const userSubmittedAnswers = answerType === CONFIG.demoAnswerTypes.Checkbox ? answerText?.split("//") : answerText;
        return (
            <div style={{ margin: "10px 0px" }}>
                <Form.Item style={readOnly ? { pointerEvents: "none" } : ""} label={<span>{title}</span>}>
                    {answerType === CONFIG.demoAnswerTypes.Checkbox
                        ? (getFieldDecorator(`${id}`, {
                            rules: [{ required: isMandatory, message: "Atleast one checkbox must be selected" }],
                            initialValue: userSubmittedAnswers
                        })
                            (<Checkbox.Group className='font'>
                                {answers?.map(opt => (<Checkbox style={{ color: "black" }} key={opt?.title} label={opt?.title} value={opt?.title} >{opt?.title}</Checkbox>))}
                            </Checkbox.Group>))
                        : answerType === CONFIG.demoAnswerTypes.RadioButton
                            ? (getFieldDecorator(`${id}`, {
                                rules: [{ required: isMandatory, message: "Please select an option" }],
                                initialValue: userSubmittedAnswers
                            })(
                                <Radio.Group className='font'>
                                    {answers?.map(opt => (<Radio style={{ color: "black" }} className='demo-reg-radio' key={opt?.title} label={opt?.title} value={opt?.title} >{opt?.title}</Radio>))}
                                </Radio.Group>))
                            : (getFieldDecorator(`${id}`, {
                                rules: [{ required: isMandatory, message: "This field cannot be blank" }],
                                initialValue: userSubmittedAnswers
                            })(<TextArea className='font' style={{ width: '576px' }} rows={2} />))
                    }
                </Form.Item>
            </div>
        )
    }

    renderFooterButtons = () => {
        const { isEdit, handleCancel, rawData } = this.props;
        const readOnly = rawData?.viewOnly || false;
        return (<Row>
            {!!!readOnly && <Button aria-label="submit" className="float-right" onClick={this.handleSubmit} > {!!isEdit ? "Update Request" : "Request Demo"}</Button >}
            <Button variant='secondary' aria-label="back" className="float-right right-spacing" onClick={handleCancel}>Back</Button>
        </Row >)
    }

    render() {
        const { visible, handleCancel, demo, isEdit, rawData } = this.props;
        const { preRegistrationDemoQuestions } = demo || []
        const readOnly = rawData?.viewOnly || false;
        return (
            <Modal
                visible={visible}
                title={<span style={{ fontSize: "17px" }}>{readOnly ? "Demo Request Details of " : "Request Demo for "}{demo?.title}</span>}
                className='extra-wide-modal'
                okText={isEdit ? "Update Request" : "Request Demo"}
                footer={this.renderFooterButtons()}
                onCancel={handleCancel}
            >
                <div>
                    {readOnly && <div style={{ color: "red", fontSize: "12px", float: 'right' }}>* Readonly view </div>}
                    <Form>
                        {preRegistrationDemoQuestions?.map(ques => { return this.renderRegistrationQuestions(ques) })}
                    </Form>
                </div >
            </Modal>
        );
    }
}
const mapStateToProps = ({ user, demoSchedules }) => {
    return {
        timeZoneName: user.profile ? user.profile.timezone : '',
        isFetchingFeedback: demoSchedules.isFetchingFeedback,
    };
};

const DemoRegistrationModalRef = Form.create()(DemoRegistrationModal);

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        postPreRegistrationForm,
        putPreRegistrationForm
    }
)(DemoRegistrationModalRef);