import {
    CLEAR_CAPABILITY_REQUEST_DATA,
    TOGGLE_IS_FETCHING_CAPABILITY_REQUESTS,
    FETCH_ALL_CAPABILITY_REQUESTS,
    UPDATE_CAPABILITY_REQUEST_EDITING_KEY,
    CURRENT_CAPABILITY_REQUEST_FILTER,
    FETCH_CAPABILITY,
} from "./types";

import { aepDemo } from "../apis";
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';

export const fetchCapabilityRequestsLite = ({ status, filterSort }) => async (dispatch) => {
    dispatch(toggleIsFetchingCapabilityRequestTable(true));
    const response = await aepDemo.get("/demoSolutionService/v1/lite/capabilityRequests",
        {
            params: {
                filterSort: {
                    ...filterSort,
                    filter: {
                        ...filterSort?.filter,
                        status
                    }
                }
            }
        })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ALL_CAPABILITY_REQUESTS,
            payload: response.data ? response.data : []
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Capability Requests');
    dispatch(toggleIsFetchingCapabilityRequestTable(false));
    return response.data ? response.data : [];
}

export const clearCapabilityRequestData = () => {
    return {
        type: CLEAR_CAPABILITY_REQUEST_DATA
    }
}

export const toggleIsFetchingCapabilityRequestTable = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_CAPABILITY_REQUESTS,
        payload: flag
    }
}

export const updateCapabilityRequestEditingKey = (key = "") => {
    return {
        type: UPDATE_CAPABILITY_REQUEST_EDITING_KEY,
        payload: key
    };
};

export const currentCapabilityRequestFilter = (filterSort, status, currentPage) => {
    return {
        type: CURRENT_CAPABILITY_REQUEST_FILTER,
        payload: [filterSort, status, currentPage]
    }
};



export const fetchCapability = (filterSort, isActive) => async dispatch => {
    const response = await aepDemo.get("/demoSolutionService/v1/lite/capabilities",
        {
            params: {
                filterSort: {
                    ...filterSort,
                    filter: {
                        isActive
                    }
                }
            }
        });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_CAPABILITY,
            payload: response.data ? response.data : []
        })
    }
};

export const fetchCapabilityRequestDetailsById = (requestId) => async () => {
    const response = await aepDemo.get(`/demoSolutionService/v1/lite/capabilityRequest/${requestId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) return response?.data || {};
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Capability Requests Details');
}


