import {
    ADD_NEW_TAB,
    UPDATE_ACTIVE_TAB,
    DELETE_TAB,
    CLOSE_ALL_TABS,
    CLOSE_OTHER_TABS,
    RESTORE_TABS_ON_REFRESH,
    UPDATE_TABS_LOCAL_STORAGE,
    HAS_SAVE_CHANGES,
    TOGGLE_HAS_UNSAVED_CHANGES_FLAG,
    CLEAR_HAS_UNSAVED_FLAG,
    REPLACE_TAB_DETAILS,
    CLOSE_CURRENT_OPEN_TAB,
    CLEAR_TAB_DATA,
    CLOSE_ACTIVE_TAB
} from './types';
import { batch } from 'react-redux';
import history from "../history";
import { isTabAlreadyOpen, getClearComponentType, getClearFormType } from "../components/common/TabLayout";
import CONFIG from "../config"
import openInfo from '../components/common/Info';

export const addNewTab = (title, path) => async (dispatch, getState) => {
    const openedTabs = getState().tabsLayout.panes;
    const tabLimit = getState().user.profile?.tabLimit || 8;
    if (openedTabs?.length < tabLimit) {
        dispatch({
            type: ADD_NEW_TAB,
            payload: { title: title, key: path, hasUnsavedChanges: false }
        })
        return true;
    }
    else {
        openInfo("Warning", CONFIG.warningMessages.maxLimitWarning, null, "OK")
        return false;
    }
};


export const openLinkInNewTab = (title, path, propsObj) => async (dispatch, getState) => {
    const openedTabs = getState().tabsLayout.panes;
    const tabLimit = getState().user.profile.tabLimit;
    let response = true;
    if (!isTabAlreadyOpen({ openedTabs: openedTabs, currentComponentPath: path })) {
        if (openedTabs?.length < tabLimit) {
            await dispatch({
                type: ADD_NEW_TAB,
                payload: { title: title, key: path }
            })
        }
        else {
            response = false;
            openInfo("Warning", CONFIG.warningMessages.maxLimitWarning, null, "OK")
        }
    }
    else await dispatch(updateActiveTab(path))
    response && history.replace({pathname:path, propsObj: propsObj})
};

export const handleOnClickAction = ({ component, tabTitle, recordId, isClone = false, recordName }) => async (dispatch, getState) => {
    let response = true;
    if (recordId) {
        const componentKey = recordName ? component.key + recordName + "/" : component?.key
        if (!isTabAlreadyOpen({ openedTabs: getState().tabsLayout.panes, currentComponentPath: componentKey + recordId })) {
            response = await dispatch(addNewTab(isClone ? ("Clone " + component.title) : (component.title + tabTitle), componentKey + recordId))
        }
        else await dispatch(updateActiveTab(componentKey + recordId))
        response && history.replace(componentKey + recordId)
    }
    else {
        if (!isTabAlreadyOpen({ openedTabs: getState().tabsLayout.panes, currentComponentPath: component?.key })) {
            response = await dispatch(addNewTab(tabTitle + " " + component.title, component.key))
        }
        else await dispatch(updateActiveTab(component.key))
        response && history.replace(component?.key)
    }
}

export const updateActiveTab = (key) => {
    return {
        type: UPDATE_ACTIVE_TAB,
        payload: key
    }
}

export const restoreTabsOnPageRefresh = (tabs) => {
    return {
        type: RESTORE_TABS_ON_REFRESH,
        payload: tabs?.map(x => ({
            ...x,
            fromLocalStorage: true
        }))
    }
};


export const closeCurrentOpenTab = () => async (dispatch, getState) => {
    dispatch({ type: CLOSE_CURRENT_OPEN_TAB });
    const activeKey = getState().tabsLayout.activeKey;
    history.replace(activeKey)
}


export const closeActiveTab = () => async (dispatch, getState) => {
    dispatch({ type: CLOSE_ACTIVE_TAB })
}


export const reopenExistingTab = async (path) => {
    history.replace("/")
    history.replace(path)
}


export const toggleHasUnsavedChangesFlag = (key, flag) => {
    return {
        type: TOGGLE_HAS_UNSAVED_CHANGES_FLAG,
        payload: { key, hasUnsavedChanges: flag }
    }
}

export const redirectToDashboard = () => (dispatch, getState)  => {
    dispatch(openLinkInNewTab(getState().user.isAdmin? "Admin Dashboard":"User Dashboard", '/dashboard'))
}

export const deleteTab = (key, isCurrentTabClosed) => {
    return {
        type: DELETE_TAB,
        payload: [key, isCurrentTabClosed]
    }
}

export const updateTabsLocalStorage = (key) => {
    return {
        type: UPDATE_TABS_LOCAL_STORAGE,
        payload: key
    }
}

export const clearHasUnsavedChanges = () => {
    return {
        type: CLEAR_HAS_UNSAVED_FLAG,
    }
}

export const replaceTabDetails = (existingTitle, title, path) => {
    return {
        type: REPLACE_TAB_DETAILS,
        payload: [existingTitle, { title: title, key: path }]
    }
}

export const closeAllTabs = () => async (dispatch, getState) => {
    const { tabsLayout } = getState()
    batch(() => {
        dispatch({
            type: CLOSE_ALL_TABS,
        });
        tabsLayout.panes.forEach(eachTab => dispatch(clearComponentOnTabClose(eachTab?.key)))
    })
}

export const closeOtherTabs = () => async (dispatch, getState) => {
    const { tabsLayout } = getState()
    var retainedTab = tabsLayout.panes.filter(pane => pane.key === tabsLayout.activeKey);
    var closedTabs = tabsLayout.panes.filter(pane => pane.key !== tabsLayout.activeKey);
    batch(() => {
        dispatch({
            type: CLOSE_OTHER_TABS,
            payload: retainedTab
        });
        closedTabs.forEach(eachTab => dispatch(clearComponentOnTabClose(eachTab?.key)))
    });

}

export const hasSaveChanges = (flag) => {
    return {
        type: HAS_SAVE_CHANGES,
        payload: flag
    }
}

export const clearTabStoreData = () => {
    return {
        type: CLEAR_TAB_DATA,
    }
}

export const clearComponentOnTabClose = (key) => async (dispatch) => {
    const type = getClearComponentType(key);
    type && dispatch({ type: type });
}


export const clearFormData = (formType, id, isClone) => async (dispatch) => {
    const type = getClearFormType(formType);
    type && dispatch({
        type: type,
        payload: { id, isClone }
    });
}
