import React from "react";
import { connect, batch } from "react-redux";
import { Dropdown, Form, message, Spin, Tabs, Tooltip } from 'antd';
import moment from 'moment-timezone';
import { exportMenu } from "../../utils/strings";
import CONFIG from '../../config';
import { fetchNamesBySearchString } from '../../actions/userRole'

import {
    exportTable,
    selectTitle,
    toggleBackButton,
    handleOnClickAction,
    toggleHasUnsavedChangesFlag,
    toggleSaveReportVisible,
    clearCapabilityRequestData,
    toggleIsFetchingCapabilityRequestTable,
    fetchCapabilityRequestsLite,
    updateCapabilityRequestEditingKey,
    currentCapabilityRequestFilter,
    fetchUsersForDemoDataCenter,
    putCapabilityRequest,
    getAllDemosLite,
    fetchCapability,
} from '../../actions';
import { Icon as NeoIcon, Button as NeoButton, Table as NeoTable, Chip, TextInput, Form as NeoForm, Select, SelectOption } from "neo-latest"
import { isTabAlreadyOpen } from "../common/TabLayout";
import pushMessage from '../common/PushMessage';
import SaveReport from "../common/SaveReport";
import { otherFields } from '../../utils/neoTableFields';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import { LoadingOutlined } from '@ant-design/icons';
import FilterDrawer from "../common/FilterDrawer";
import { BasicModal } from "@avaya/neo-react";
export const ManageCapabilityRequestsContext = React.createContext();
const { TabPane } = Tabs;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const dataIndexMap = {
    demo: "demo.title",
    capability: "capability.title",
    status: "status",
    requestedBy: "requestedBy.fullName",
    startRequestedTime: "startRequestedTime",
    endRequestedTime: "endRequestedTime",
    requestedDate: "requestedDate",
    assigned: "assignedUser.fullName",
    resolvedBy: "resolvedBy.fullName",
    startResolvedTime: "startResolvedTime",
    endResolvedTime: "endResolvedTime",
    resolvedDate: "resolvedDate",
    notes: "notes",
    type: "type",
    description: 'description',
};

class CapabilityRequests extends React.Component {

    tableRef = React.createRef();

    constructor() {
        super()
        this.state = {
            capabilityRequestStatus: CONFIG.capabilityRequestStatus.assigned.value,
            matchedUsersNames: [],
            showFilter: false,
            showEdit: false,
            record: null,
            currentPage: 0,
        }
        this.filterSort = {};
        this.filterSave = {};
    }

    isEditing = record => record.id === this.props.editingKey;

    clearCurrentRequestData = async () => {
        await this.props.clearCapabilityRequestData();
    };

    async componentDidMount() {
        const { isEdit, filterSort, status, currentPage } = this.props;
        this.setState({ currentPath: window.location.pathname })
        if (isEdit) {
            this.filterSort = filterSort;
            if (Object.keys(JSON.parse(JSON.stringify(filterSort)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(filterSort?.filter));
                Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
            }
            await this.setState({ capabilityRequestStatus: status, currentPage: currentPage })
        }
        else {
            this.filterSort = {};
        }
        batch(async () => {
            await this.props.toggleIsFetchingCapabilityRequestTable(true);
            await this.props.getAllDemosLite();
            await this.props.fetchCapability({}, true);
            await this.props.fetchCapabilityRequestsLite({ filterSort: this.filterSort, status: this.state.capabilityRequestStatus });
        })
    };

    async componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            await this.props.clearCapabilityRequestData();
            await this.props.currentCapabilityRequestFilter(this.filterSort, this.state.capabilityRequestStatus, this.state.currentPage)
        }
        else this.props.clearCapabilityRequestData();

        this.cleanup();
        this.props.updateCapabilityRequestEditingKey("");
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
    }

    handleRequestStatusChange = async (value) => {
        await this.clearCurrentRequestData()
        this.setState({ capabilityRequestStatus: value }, async () => await this.props.fetchCapabilityRequestsLite({ filterSort: this.filterSort, status: value }))
    };

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const demos = await this.props.fetchCapabilityRequestsLite({ filterSort: this.filterSort, status: this.state.capabilityRequestStatus });
        if (demos?.length > 0 && demos !== undefined) {
            exportTable(CONFIG.exportTable.capabilityRequest?.name, demos, timeZoneName, CONFIG.exportTable.capabilityRequest.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleClear = async (isRefresh) => {
        await this.clearCurrentRequestData()
        this.props.updateCapabilityRequestEditingKey("");
        if (!isRefresh) {
            this.filterSort = {};
            this.filterSave = {};
        }
        this.setState({ matchedUsersNames: [] })
        this.props.toggleIsFetchingCapabilityRequestTable(true);
        await this.props.fetchCapabilityRequestsLite({ filterSort: this.filterSort, status: this.state.capabilityRequestStatus });
    }

    handleFilter = async (filters, isSave) => {
        const { timeZoneName } = this.props;
        let { capability, demo, requestedBy, assignedUser, resolvedBy, requestedTime, resolvedTime } = filters;
        requestedBy = requestedBy?.trim();
        assignedUser = assignedUser?.trim();
        resolvedBy = resolvedBy?.trim();
        const isFilter = isSave ? "save" : "filter";
        const isDemoEmpty = !demo || (demo && demo?.length === 0);
        const isCapabilityEmpty = !capability || (capability && capability?.length === 0);

        const isrequestedTimeEmpty = !requestedTime || (requestedTime && requestedTime.length === 0);
        const isresolvedTimeEmpty = !resolvedTime || (resolvedTime && resolvedTime.length === 0);

        if (isCapabilityEmpty && isDemoEmpty && !requestedBy && !assignedUser && !resolvedBy && isrequestedTimeEmpty && isresolvedTimeEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
            return;
        }
        if (isSave) {
            this.filterSave = {
                ...this.filterSave,
                filter: {
                    ...(!isCapabilityEmpty && {
                        [dataIndexMap.capability]: {
                            operator: "=",
                            oprVal: capability.join(CONFIG.delimiters.saveFilter) || undefined
                        }
                    }),
                    ...(!isDemoEmpty && {
                        [dataIndexMap.demo]: {
                            operator: "=",
                            oprVal: demo.join(CONFIG.delimiters.saveFilter) || undefined
                        }
                    }),
                    ...(resolvedBy && {
                        [dataIndexMap.resolvedBy]: {
                            operator: "=",
                            oprVal: resolvedBy || undefined
                        }
                    }),
                    ...(assignedUser && {
                        [dataIndexMap.assigned]: {
                            operator: "=",
                            oprVal: assignedUser || undefined
                        }
                    }),
                    ...(requestedBy && {
                        [dataIndexMap.requestedBy]: {
                            operator: "=",
                            oprVal: requestedBy || undefined
                        }
                    }),
                    ...(!isrequestedTimeEmpty && {
                        requestedTime: {
                            operator: "IN",
                            oprVal: !isrequestedTimeEmpty ? requestedTime[0].tz(timeZoneName, true).startOf('day').toISOString() + '@' + requestedTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                        }
                    }),
                    ...(!isresolvedTimeEmpty && {
                        resolvedTime: {
                            operator: "IN",
                            oprVal: !isresolvedTimeEmpty ? resolvedTime[0].tz(timeZoneName, true).startOf('day').toISOString() + '@' + resolvedTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                        }
                    }),
                },
                filterTable: {
                    id: 20,
                    tableName: "Capability Request"
                },
            }
            this.props.toggleSaveReportVisible(true)
            this.setState({ saveReport: true })
        }
        else {
            await this.clearCurrentRequestData();
            this.filterSort = {
                filter: {
                    [dataIndexMap.capability]: !isCapabilityEmpty ? capability.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.demo]: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.startRequestedTime]: !isrequestedTimeEmpty ? this.getUTCTime(requestedTime[0]) : undefined,
                    [dataIndexMap.endRequestedTime]: !isrequestedTimeEmpty ? this.getUTCTime(requestedTime[1]) : undefined,
                    [dataIndexMap.startResolvedTime]: !isresolvedTimeEmpty ? this.getUTCTime(resolvedTime[0]) : undefined,
                    [dataIndexMap.endResolvedTime]: !isresolvedTimeEmpty ? this.getUTCTime(resolvedTime[1]) : undefined,
                    [dataIndexMap.requestedBy]: requestedBy || undefined,
                    [dataIndexMap.resolvedBy]: resolvedBy || undefined,
                    [dataIndexMap.assigned]: assignedUser || undefined,
                }
            }
            this.setState({ matchedUsersNames: [] })
            this.props.toggleIsFetchingCapabilityRequestTable(true);
            await this.props.fetchCapabilityRequestsLite({ filterSort: this.filterSort, status: this.state.capabilityRequestStatus });
        }
    }

    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString() || undefined
    }

    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleSaveFilters = (filters) => {
        this.handleFilter(filters, true)
    };

    handleSave = (record, form) => {
        form.validateFields(async (error, values) => {
            if (error) {
                return;
            }
            else {
                let data = {
                    ...record,
                    assignedUser: JSON.parse(values.assignedUser),
                }
                await this.props.putCapabilityRequest(record?.id, data, "Update");
                this.handleClear(true);
                this.setState({ showEdit: false, record: null })
            }
        });
    };

    handeEditRecord = async (record) => {
        this.props.toggleIsFetchingCapabilityRequestTable(true);
        await this.props.fetchUsersForDemoDataCenter(record).then(() => this.props.toggleIsFetchingCapabilityRequestTable(false))
        this.setState({
            showEdit: true,
            record
        })
    }

    handleChangeSortName = () => {
        setTimeout(() => {
            const myElement = document.querySelector('.neo-dropdown__content');
            if (myElement) {
                myElement.classList.add('sorting-classes');
                const parentItem = document.querySelector(".sorting-classes");
                const children = parentItem.children;
                children[0].textContent = 'Clear Sort';
                children[1].textContent = 'Older to Newer';
                children[2].textContent = 'Newer to Older';
            }
        }, 10)
    }

    componentDidUpdate() {
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    }

    handleDatesSortForNeoTable = (event) => {
        const targetElement = event.target;
        const sortingDateChild = targetElement.querySelector('#sorting-date');
        const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
            (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
        if (sortingDateChild || arrowChild) {
            setTimeout(() => {
                const myElement = document.querySelector('.neo-dropdown__content');
                if (myElement) {
                    myElement.classList.add('sorting-classes');
                    const parentItem = document.querySelector(".sorting-classes");
                    const children = parentItem.children;
                    children[0].textContent = 'Clear Sort';
                    children[1].textContent = 'Older to Newer';
                    children[2].textContent = 'Newer to Older';
                }
            }, 10)
        }
    }

    cleanup = () => {
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    };

    renderTable = () => {
        const { timeZoneName, capabilityRequests, isFetchingCapabilityRequests } = this.props;
        const { capabilityRequestStatus } = this.state;
        const isUnresolved = ((capabilityRequestStatus === CONFIG.capabilityRequestStatus.pending.status) || (capabilityRequestStatus === CONFIG.capabilityRequestStatus.assigned.status)) ? true : false;
        const isExpiredTable = capabilityRequestStatus === CONFIG.capabilityRequestStatus.expired.status ? true : false;
        const isCompletedTable = capabilityRequestStatus === (CONFIG.capabilityRequestStatus.completed.status);
        const isDeclinedTable = capabilityRequestStatus === (CONFIG.capabilityRequestStatus.declined.status);
        const isAllTable = capabilityRequestStatus === (CONFIG.capabilityRequestStatus.all.status);
        const titleColumns = [
            {
                Cell: (a) => {
                    const record = a?.cell?.row?.original;
                    return <span onClick={() => {
                        this.props.handleOnClickAction({
                            component: CONFIG.editComponentRoute.capabilityRequestDetails,
                            tabTitle: record?.capability?.title,
                            recordId: record?.id
                        })
                    }} style={{ textAlign: "center", color: "#1B77AF", cursor: "pointer" }}>{a?.cell?.row?.original?.capability?.title}</span>
                },
                Header: "Capability Title",
                sortType: "string",
                accessor: "capability.title",
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.demo?.title}</span>
                },
                Header: "Demo Title",
                sortType: "string",
                accessor: "demo.title",
            },
        ];

        const statusMgmt = [{
            Header: "Status",
            sortType: "basic",
            accessor: "status",
            Cell: (a) => {
                const record = a?.cell?.row?.original;
                return <span style={{ textAlign: "center" }}>
                    <Chip variant={CONFIG.myRequestsChips[record?.status]} style={{ textAlign: "center" }}>{record?.status?.charAt(0)?.toUpperCase() + record?.status?.slice(1)}</Chip>
                </span>
            },
        }];

        const neoColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.requestedBy?.fullName}</span>
                },
                Header: "Requested By",
                sortType: "alphanumeric",
                accessor: "requestedBy.fullName",
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.requestedTime ? moment.tz(a?.cell?.row?.original?.requestedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                },
                Header: <span onClick={this.handleChangeSortName} id='sorting-date'>Requested Date</span>,
                accessor: "requestedTime",
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.requestedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.requestedTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                }
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.assignedUser?.fullName}</span>
                },
                Header: "Assigned User",
                sortType: "alphanumeric",
                accessor: "assignedUser.fullName",
            },
        ];

        const resolvedColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.resolvedBy?.fullName}</span>
                },
                Header: isDeclinedTable ? "Declined By" : isCompletedTable ? "Completed By" : "Completed / Declined By",
                sortType: "alphanumeric",
                accessor: "resolvedBy.fullName",
            },
        ];

        const actions = [
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    const isEditEnabled = (record?.status === CONFIG.capabilityRequestStatus.pending.status) || (record?.status === CONFIG.capabilityRequestStatus.assigned.status)
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", gap: "24px" }}>
                                {isEditEnabled && <div>
                                    <Tooltip title="Assign User"><NeoIcon size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="user-return"
                                        onClick={() => this.handeEditRecord(record, false)}
                                    /></Tooltip>
                                </div>}
                                <div>
                                    <Tooltip title="View Details"><NeoIcon size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="list-bullet"
                                        onClick={() => {
                                            this.props.handleOnClickAction({
                                                component: CONFIG.editComponentRoute.capabilityRequestDetails,
                                                tabTitle: record?.capability?.title,
                                                recordId: record?.id
                                            })
                                        }}
                                    /></Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true
            }
        ];

        return (
            isFetchingCapabilityRequests ? null : <NeoTable
                // columns={neoColumns}
                columns={isUnresolved ? [...titleColumns, ...neoColumns, ...actions] : isAllTable ? [...titleColumns, ...statusMgmt, ...[neoColumns[0], neoColumns[2]], ...resolvedColumns, ...actions] : isExpiredTable ? [...titleColumns, ...neoColumns, ...actions] : [...titleColumns, ...neoColumns, ...resolvedColumns, ...actions]}
                data={capabilityRequests || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                initialStatePageIndex={this.state.currentPage}
                handlePageChange={(newPageIndex, newPageSize) => {
                    if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
                }}
                showRowSeparator
                {...otherFields}
            />
        )
    }

    renderFilterPanel = () => {
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <div style={{ display: "flex", width: "100%", flex: "1", flexDirection: "row-reverse", gap: "24px" }}>
                    <div >
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoButton className="export-download-button" icon="chevron-down" variant="secondary">Download</NeoButton>
                        </Dropdown>
                    </div>
                    <div >
                        <NeoIcon size="md" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty(this.filterSort))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                    <div >
                        <NeoIcon size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                </div>
            </div>
        )
    }

    renderTabContents = () => {
        return (
            <React.Fragment>
                <div>
                    {this.renderFilterPanel()}
                </div>
                <div style={{ minHeight: "250px", marginBottom: "16px" }}>
                    {this.renderTable()}
                </div>
            </React.Fragment>
        )
    }

    renderUsers = () => {
        const users = this.props.assignedUserDropdown;
        if (users && users?.length > 0) {
            return users?.map(user => {
                return (
                    <SelectOption key={user.id} value={JSON.stringify({ id: user?.id, fullName: user?.fullName })}>
                        {user.fullName}
                    </SelectOption>
                );
            });
        }
        return;
    };

    render() {
        const { demos, capabilities, isFetchingCapabilityRequests } = this.props;
        const formFields = [
            {
                label: "Capability Title",
                type: CONFIG.formFieldTypes.SELECT,
                key: "capability",
                mode: "tags",
                data: (capabilities) ? capabilities.map(capability => ({ text: capability?.title })) : [],
                valueKey: "text",
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.capability]
            },
            {
                label: "Demo Title",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: (demos) ? demos.map(demo => ({ text: demo?.title })) : [],
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.demo]
            },
            {
                label: "Requested By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "requestedBy",
                data: this.state.matchedUsersNames,
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.requestedBy]
            },
            {
                label: "Assigned User",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "assignedUser",
                data: this.state.matchedUsersNames,
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.assigned]
            },
            {
                label: "Completed / Declined By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "resolvedBy",
                data: this.state.matchedUsersNames,
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.resolvedBy]
            },
            {
                label: "Requested Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "requestedTime",
                value: [this.filterSort.filter?.startRequestedTime, this.filterSort.filter?.endRequestedTime],
                colSpan: 24
            },
            {
                label: "Completed / Declined Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "resolvedTime",
                value: [this.filterSort.filter?.startResolvedTime, this.filterSort.filter?.endResolvedTime],
                colSpan: 24,
            },
        ];
        const drawerContainer = document.getElementById('#component-content');
        const { getFieldDecorator } = this.props.form;
        return (
            <React.Fragment>
                <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                    Capability Requests
                </div>
                <Spin spinning={isFetchingCapabilityRequests} indicator={antIcon}>
                    <div style={{ marginTop: "16px" }}>
                        <Tabs activeKey={this.state.capabilityRequestStatus} className='demo-status-tabs' defaultActiveKey={this.state.capabilityRequestStatus} onChange={this.handleRequestStatusChange}>
                            <TabPane tab="Assigned" key={CONFIG.capabilityRequestStatus.assigned.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Pending" key={CONFIG.capabilityRequestStatus.pending.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Approved" key={CONFIG.capabilityRequestStatus.completed.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Declined" key={CONFIG.capabilityRequestStatus.declined.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Expired" key={CONFIG.capabilityRequestStatus.expired.value}>
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="All" key={CONFIG.capabilityRequestStatus.all.value}>
                                {this.renderTabContents()}
                            </TabPane>
                        </Tabs>
                    </div>
                    <div>
                        {this.state.showFilter && (
                            <FilterDrawer
                                visible={this.state.showFilter}
                                width='520'
                                handleCloseFilterDrawer={() => this.setState(prevState => ({
                                    showFilter: false,
                                    record: null
                                }))}
                                placement="right"
                                drawerContainer={drawerContainer}
                                style={{ zIndex: 9999, }}
                                maskClosable={false}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                timeZone={this.props.timeZoneName}
                                hideSoftDeleteCheckbox={true}
                                hideSave={false}
                                handleSave={this.handleSaveFilters}
                                handleFilter={this.handleFilter}
                                getSearchData={this.fetchUsersName}
                            />)}
                        {
                            (this.state.showEdit) &&
                            <BasicModal
                                open={(this.state.showEdit)}
                                title="Assign the User"
                                className="neo-modal conflicting-modal min-height-modal"
                                onClose={() => this.setState({ showEdit: false, record: null })}
                                actions={[
                                    <NeoButton onClick={() => this.handleSave(this.state.record, this.props.form)} variant="primary">Save</NeoButton>
                                ]}
                            >
                                <div>
                                    <Form>
                                        <NeoForm>
                                            <div style={{ marginTop: "16px" }}>
                                                <TextInput label="Requested Capability" value={(this.state.record?.capability?.title || "-")} readOnly />
                                            </div>
                                            <div style={{ marginTop: "16px", display: "flex" }}>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <TextInput label="Currently Assigned user" value={(this.state.record?.assignedUser?.fullName || "-")} readOnly />
                                                </div>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <Form.Item style={{ width: "100%" }}>
                                                        {
                                                            getFieldDecorator("assignedUser", {
                                                                rules: [
                                                                    {
                                                                        type: 'string',
                                                                        required: true,
                                                                        message: `Please select a user!`
                                                                    }
                                                                ],
                                                                initialValue: JSON.stringify({ id: this.state.record?.assignedUser?.id, fullName: this.state.record?.assignedUser?.fullName })
                                                            })(<Select required className="remove-padding-style" label="Assigned User">{this.renderUsers()}</Select>)
                                                        }
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "16px", display: "flex" }}>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <TextInput label="Requested By" value={(this.state.record?.requestedBy?.fullName || "-")} readOnly />
                                                </div>
                                                <div style={{ display: "flex", flex: "1" }}>
                                                    <TextInput label="Requested Date" value={(this.state.record?.requestedTime ? moment.tz(this.state.record?.requestedTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-")} readOnly />
                                                </div>
                                            </div>
                                        </NeoForm>
                                    </Form>
                                </div>
                            </BasicModal>
                        }
                        {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
                    </div>
                </Spin>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ user, demos, tabsLayout, capabilityRequest, allCapability, dataCenterStatus }) => {
    return {
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        timeZoneName: user.profile?.timezone,
        demos: demos.allDemos,
        capabilities: allCapability?.allCapabilities,
        assignedUserDropdown: dataCenterStatus.users,
        editingKey: capabilityRequest.editingKey,
        isFetchingUser: capabilityRequest.isFetchingUser,
        status: capabilityRequest.status,
        isEdit: capabilityRequest.isEdit,
        currentPage: capabilityRequest.currentPage,
        filterSort: capabilityRequest.filters,
        capabilityRequests: capabilityRequest.capabilityRequests,
        isFetchingCapabilityRequests: capabilityRequest.isFetchingCapabilityRequests,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
    };
};

const EditableFormCapabilityRequestsTable = Form.create()(CapabilityRequests);

export default connect(
    mapStateToProps,
    {
        exportTable,
        selectTitle,
        toggleBackButton,
        handleOnClickAction,
        toggleHasUnsavedChangesFlag,
        toggleSaveReportVisible,
        clearCapabilityRequestData,
        toggleIsFetchingCapabilityRequestTable,
        fetchCapabilityRequestsLite,
        updateCapabilityRequestEditingKey,
        currentCapabilityRequestFilter,
        fetchUsersForDemoDataCenter,
        putCapabilityRequest,
        getAllDemosLite,
        fetchCapability,
    }
)(EditableFormCapabilityRequestsTable); 