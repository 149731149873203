import React from 'react'
import { Row, Col, Divider, Form, Radio, Checkbox, Tooltip, Modal, Typography, Alert, DatePicker, Spin } from "antd";
import { connect } from 'react-redux';
import { Form as NeoForm, TextInput, TextArea, Table, Chip, Icon, Button, BasicModal } from "@avaya/neo-react";
import { checkScheduleAvailability, clearScheduleFormErrors, closeCurrentOpenTab, updateCustomerInfo } from "../../../actions";
import moment from 'moment-timezone';
import { Select as NeoSelect, SelectOption, } from "neo-latest"
import {
    getInitialValue,
    renderAdditionalFields,
    createScheduleObject,
    getInitialValueForSchedules,
    isAttributeChanged,
    renderNotes
} from "../../common/Scheduler"
import CONFIG from '../../../config';
import ScheduleTimeDetails from "./ScheduleTimeDetails";
import ScheduleOccurenceDetails from "./ScheduleOccurenceDetails";
import ScheduleOccurenceConfirmation from "./ScheduleOccurenceConfirmation"
import {
    fetchScheduleParticipantsList,
    postNewDemoSchedule,
    getDateRanges,
    fetchOpportunityDetails
} from '../../../actions/scheduler'
import pushMessage from '../../common/PushMessage';
import { range } from '../../../utils/datepicker';
import { toTitleCase, getDaySuffix } from "../../../utils/strings"
import { Select, Steps } from "antd5";
import { LoadingOutlined } from '@ant-design/icons';
import scrollIntoView from 'scroll-into-view';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;
const { Step } = Steps;
const { Text } = Typography;

const radioStyle = {
    display: 'block',
    lineHeight: '30px',
};

const weekValues = [
    {
        name: "FIRST",
        value: "1"
    },
    {
        name: "SECOND",
        value: "2"
    },
    {
        name: "THIRD",
        value: "3"
    },
    {
        name: "FOURTH",
        value: "4"
    },
    {
        name: "LAST",
        value: "5"
    }
];
class NeoStandardDemoDetailsPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            scheduleParticipants: props?.currentSchedule?.participants ? [...props.currentSchedule?.participants?.eapUsers, ...props.currentSchedule?.participants?.extUsers] : [],
            matchedParticipants: [],
            addNewUserVisible: false,
            warningText: '',
            selectedPurpose: props?.currentSchedule ? JSON.stringify(props?.currentSchedule.demoPurpose) : undefined,
            freqType: "1",
            seriesEndDate: null,
            dayOfWeek: [],
            monthDay: ["1"],
            dayName: "MONDAY",
            radioToggle: 1,
            value: "1",
            currentStep: 0,
            nextButtonLoading: false,
            scheduleDetails: [],
            userAnswers: [],
            preRequisiteQuestionObj: [],
            purposeDetails: [],
            schedulePurposeAttributes: [],
            isFormDisabled: false,
            confirmModal: false,
            activePanelKey: "1",
            isFetchingOppNo: false,
            customerInfoMap: new Map()
        }
        this.testingGroup = []
    }

    opportunityNumberPattern = /^000\d{7}$/; // Matches 10 digits starting with 000

    async componentDidMount() {
        const { currentSchedule, isReschedule } = this.props;
        (!currentSchedule || isReschedule) && this.handleCheckTestingPurpose();
        if (isReschedule) {
            this.setState({ scheduleParticipants: [] })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.formDisabled && !prevProps.formDisabled) && !prevState.isFormDisabled) {
            this.setState({
                isFormDisabled: true
            })
        }
    }

    handleCheckTestingPurpose = async () => {
        this.testingGroup = this.props.userPurposeTypesLite.filter((purposeType) => { return (purposeType.purpose === "Testing" || purposeType.id === 5) })
        if (this.testingGroup?.length > 0) {
            await this.setState({
                selectedPurpose: this.testingGroup[0]
            });
        }
    }

    renderPurposeTypesOptions = () => {
        const { userPurposeTypesLite } = this.props;
        return userPurposeTypesLite?.length > 0 ? userPurposeTypesLite.map((purposeType) => {
            return <Option key={purposeType.id} value={JSON.stringify(purposeType)}>{purposeType.purpose}</Option>
        }) : []
    }

    handlePurposeSelectChange = async (key, value) => {
        this.props.clearScheduleFormErrors();
        const purpose = JSON.parse(value);
        const { form, userPurposeTypesLite } = this.props;
        const attributes = userPurposeTypesLite?.find((eachItem) => purpose?.id === eachItem?.id)?.demoPurposeAttributes;
        if (key >= 0) {
            this.setState((prevState) => ({
                scheduleDetails: prevState.scheduleDetails.map(item => {
                    if (key === item.id) return {
                        ...item,
                        purpose,
                        attributes
                    }
                    else return item
                })
            }))
        }
        else {
            form.setFieldsValue({
                purposeName: undefined,
                vertical: undefined
            });
            this.setState({
                selectedPurpose: purpose,
                showMessage: false,
            });
            await this.props.handleCurrentScheduleChange(false);
            this.isPurposeDeleted = false
        }
    }

    handleOppNumberChange = async (opportunityNumber, uniqueId) => {
        const { customerInfoMap } = this.state;
        if (opportunityNumber.length > 10) {
            pushMessage(CONFIG.messageType.warning, 'Opportunity number must not exceed 10 digits');
        } else if (opportunityNumber.length > 2 && opportunityNumber.slice(0, 3) !== "000") {
            pushMessage(CONFIG.messageType.warning, 'Opportunity number must begin with 000');
        } else {
            if (this.opportunityNumberPattern.test(opportunityNumber)) {
                await this.isFetchingOpportunity(true);
                const customerInfo = await fetchOpportunityDetails(opportunityNumber);
                await this.setState({ customerInfoMap: customerInfoMap.set(uniqueId, customerInfo) });
                await this.isFetchingOpportunity(false);
            } else {
                await this.setState({ customerInfoMap: customerInfoMap.set(uniqueId, {}) });
            }
        }
    };

    handleTextBoxChange = async (value, key, attirbuteId, atrType, uniqueId) => {
        this.props.clearScheduleFormErrors();
        if (atrType === CONFIG?.purposeAttributeTypes.opportunityNumber) {
            await this.handleOppNumberChange(value, uniqueId);
        }
        const currentCustomer = this.state.customerInfoMap?.get(uniqueId);
        if (key >= 0) {
            this.setState((prevState) => ({
                scheduleDetails: prevState.scheduleDetails.map(item => {
                    if (key === item.id) {
                        const index = item.attributes?.findIndex((obj => obj.id === attirbuteId));
                        let attribute = item.attributes?.find(eachItem => eachItem.id === attirbuteId);
                        attribute = {
                            ...attribute,
                            value
                        }
                        let updatedAttributes = item.attributes?.filter(eachItem => eachItem.id !== attirbuteId)
                        updatedAttributes.splice(index, 0, attribute);
                        let customerNameAttribute = item.attributes?.find(eachItem => eachItem.type === CONFIG?.purposeAttributeTypes.customerName);
                        if (atrType === CONFIG?.purposeAttributeTypes.opportunityNumber) {
                            customerNameAttribute = {
                                ...customerNameAttribute,
                                value: currentCustomer && currentCustomer?.status === "Success" ? currentCustomer?.opportunity?.AccountName : undefined
                            }
                            const custNameIndex = updatedAttributes?.findIndex((obj => obj.type === CONFIG?.purposeAttributeTypes.customerName));
                            updatedAttributes = updatedAttributes?.filter(eachItem => eachItem.type !== CONFIG?.purposeAttributeTypes.customerName)
                            updatedAttributes.splice(custNameIndex, 0, customerNameAttribute);
                            this.props.form.setFieldsValue({ [`${customerNameAttribute?.id}-${customerNameAttribute?.attributeLabel}-${key}`]: currentCustomer && currentCustomer?.status === "Success" ? currentCustomer?.opportunity?.AccountName : "" })
                        }
                        let opportunity;
                        opportunity = {
                            ...item?.opportunity,
                            opportunityNumber: currentCustomer && currentCustomer?.status === "Success" ? currentCustomer?.opportunity?.otnNumber : undefined,
                            sfdcOpportunityId: currentCustomer && currentCustomer?.status === "Success" ? currentCustomer?.opportunity?.id : undefined,
                            validatedBy: currentCustomer && currentCustomer?.status === "Success" ? this.props.user?.email : undefined,
                            customerName: currentCustomer && currentCustomer?.opportunity ? currentCustomer?.opportunity?.AccountName : this.props.form.getFieldValue(`${customerNameAttribute?.id}-${customerNameAttribute?.attributeLabel}-${key}`)
                        }

                        return {
                            ...item,
                            attributes: updatedAttributes,
                            opportunity: opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined,
                            isOpportunityNumberValid: currentCustomer && currentCustomer?.status === "Success",
                            customerNameInfo: currentCustomer
                        }
                    }
                    else return item
                }),
            }))
        }
    }

    handleNotesChange = async (value, key) => {
        this.props.clearScheduleFormErrors();
        if (key >= 0) {
            this.setState((prevState) => ({
                scheduleDetails: prevState.scheduleDetails.map(item => {
                    if (key === item.id) {
                        return {
                            ...item,
                            notes: value
                        }
                    }
                    else return item
                }),
            }))
        }
    }

    handleNoOpportunityCheckboxForRecurrence = async (value, key) => {
        this.props.clearScheduleFormErrors();
        if (value) {
            if (key >= 0) {
                this.setState((prevState) => ({
                    scheduleDetails: prevState.scheduleDetails.map(item => {
                        if (key === item.id) {
                            let customerNameAttribute = item.attributes?.find(eachItem => eachItem.type === CONFIG?.purposeAttributeTypes.customerName);
                            let opportunityNumberAttribute = item.attributes?.find(eachItem => eachItem.type === CONFIG?.purposeAttributeTypes.opportunityNumber);

                            customerNameAttribute = {
                                ...customerNameAttribute,
                                value: undefined
                            }

                            opportunityNumberAttribute = {
                                ...opportunityNumberAttribute,
                                value: undefined
                            }

                            let updatedAttributes = item.attributes;
                            const custNameIndex = updatedAttributes?.findIndex((obj => obj.type === CONFIG?.purposeAttributeTypes.customerName));
                            const oppNoIndex = updatedAttributes?.findIndex((obj => obj.type === CONFIG?.purposeAttributeTypes.opportunityNumber));

                            updatedAttributes = updatedAttributes?.filter(eachItem => eachItem.type !== CONFIG?.purposeAttributeTypes.customerName)
                            updatedAttributes.splice(custNameIndex, 0, customerNameAttribute);

                            updatedAttributes = updatedAttributes?.filter(eachItem => eachItem.type !== CONFIG?.purposeAttributeTypes.opportunityNumber)
                            updatedAttributes.splice(oppNoIndex, 0, opportunityNumberAttribute);

                            let opportunity;
                            opportunity = {
                                ...item?.opportunity,
                                opportunityNumber: undefined,
                                sfdcOpportunityId: undefined,
                                validatedBy: undefined,
                                customerName: this.props.form.getFieldValue(`${customerNameAttribute?.id}-${customerNameAttribute?.attributeLabel}-${key}` || undefined)
                            }

                            this.props.form.setFieldsValue({ [`${customerNameAttribute?.id}-${customerNameAttribute?.attributeLabel}-${key}`]: "" })
                            this.props.form.setFieldsValue({ [`${opportunityNumberAttribute?.id}-${opportunityNumberAttribute?.attributeLabel}-${key}`]: "" })


                            return {
                                ...item,
                                attributes: updatedAttributes,
                                opportunity: opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined,
                                doNotHaveOpportunity: value
                            }
                        }
                        else return item
                    }),
                }))
            }
        }
    }

    // handleNoAccountOwnerEmailForReccurence = async (value, key) =>{
    //     if(value){
    //         if (key >= 0) {
    //             this.setState((prevState) => ({
    //                 scheduleDetails: prevState.scheduleDetails.map(item => {
    //                     if (key === item.id) {
    //                         this.props.form.setFieldsValue({ [`${"accountOwnerEmail"}-${key}`]: "" })
    //                         return {
    //                             ...item,
    //                             opportunity: {
    //                                 ...item?.opportunity,
    //                                 accountOwnerEmail: undefined
    //                             }
    //                         }
    //                     }
    //                     else return item
    //                 }),
    //             }))
    //         }
    //     }
    // }

    handleAccountOwnerEmailChange = async (value, key) => {
        this.props.clearScheduleFormErrors();
        if (key >= 0) {
            this.setState((prevState) => ({
                scheduleDetails: prevState.scheduleDetails.map(item => {
                    if (key === item.id) {
                        return {
                            ...item,
                            opportunity: {
                                ...item?.opportunity,
                                accountOwnerEmail: value
                            }
                        }
                    }
                    else return item
                }),
            }))
        }
    }

    renderPurpose = () => {
        const { getFieldDecorator } = this.props.form;
        const { currentSchedule, isReschedule } = this.props;
        const initialPurpose = (currentSchedule && !isReschedule) ? this.isPurposeDeleted ? undefined : JSON.stringify(currentSchedule.demoPurpose) : this.state?.selectedPurpose ? JSON.stringify(this.state?.selectedPurpose) : this.testingGroup?.length > 0 ? JSON.stringify(this.testingGroup[0]) : undefined
        return <>
            {(this.isPurposeDeleted && this.state.showMessage) && <Alert
                message="The selected purpose may be inactive or deleted. Please select a different purpose"
                type="warning"
                showIcon
            />}
            <Form.Item label={(currentSchedule && !isReschedule) && currentSchedule?.isCustomerDemoPurpose ? <span>Purpose <span><Tooltip title={`${JSON.parse(initialPurpose)?.purpose} purpose type cannot be changed upon schedule update.`}><Icon style={{ color: "black", fontSize: "14px" }} icon="info" /></Tooltip></span></span> : "Purpose"}>
                {
                    getFieldDecorator("demoPurpose", {
                        rules: [{ required: true, message: 'Please select a purpose' }],
                        initialValue: initialPurpose
                    })(
                        <Select
                            showSearch
                            bordered={false}
                            suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                            className={(currentSchedule && !isReschedule) && currentSchedule?.isCustomerDemoPurpose ? 'organiser-select select-disabled' : 'organiser-select'}
                            placeholder="Select a purpose"
                            size='large'
                            onChange={(...rest) => this.handlePurposeSelectChange(-1, ...rest)}
                            disabled={(currentSchedule && !isReschedule) && currentSchedule?.isCustomerDemoPurpose}
                        >
                            {this.renderPurposeTypesOptions()}
                        </Select>
                    )
                }
            </Form.Item>
        </>
    }

    renderAdditonalQuestions = (eachQuestion, index) => {
        const { getFieldDecorator } = this.props.form
        const { title, id, answers, answerType, isMandatory } = eachQuestion?.question
        const { answerText } = eachQuestion
        return (
            <>
                <span style={{ color: "#242424", fontSize: "14px", fontWeight: "400" }}>
                    {index}. {title} {isMandatory && <span style={{ color: "#b51418", fontSize: "12px" }}>*</span>}
                </span>
                <Form.Item>
                    {answerType === CONFIG.demoAnswerTypes.Checkbox
                        ?
                        (getFieldDecorator(`${id}`, {
                            rules: [{ required: isMandatory, message: "Atleast one checkbox must be selected" }],
                            initialValue: answerText?.split('//') || []
                        })(<Checkbox.Group>{answers?.map(opt =>
                            (<Checkbox style={{ color: "#242424" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Checkbox>))}
                        </Checkbox.Group>))
                        : answerType === CONFIG.demoAnswerTypes.RadioButton
                            ? (getFieldDecorator(`${id}`, {
                                rules: [{ required: isMandatory, message: "Please select an option" }],
                                initialValue: answerText || undefined
                            })(
                                <Radio.Group>
                                    {answers?.map(opt =>
                                        (<Radio style={{ color: "#242424" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Radio>))}
                                </Radio.Group>))
                            : (getFieldDecorator(`${id}`, {
                                rules: [{ required: isMandatory, message: "This field cannot be blank" }],
                                initialValue: answerText || undefined
                            })(<TextArea style={{ width: '700px' }} label="  "
                            />))
                    }
                </Form.Item>
            </>)
    }

    fetchParticipants = async (key, searchString) => {
        const { demo, user, selectedResource } = this.props;
        this.setState({ showMinimumCharacterMessage: searchString?.length < 3 });
        const selectedResourceId = key >= 0 ? this.state.scheduleDetails?.[key]?.resource?.id : Array.isArray(selectedResource) ? selectedResource?.map(item => item?.id) : selectedResource?.id || null;
        selectedResourceId !== null && searchString?.length > 2 && this.setState({ matchedParticipants: [], fetchingParticipants: true });
        !searchString && this.setState({ fetchingParticipants: false });
        searchString && searchString?.length > 2 && selectedResourceId !== null && await fetchScheduleParticipantsList(searchString, demo?.id, selectedResourceId, true)
            .then((result) => {
                this.setState({ fetchingParticipants: false });
                if (result?.length !== 0) {
                    const usersWithAccess = result?.filter(item => ((item?.demoUserAccess === 2 && item?.resourceUserAccess === 1) || (item?.isEapUser === false)) && (item?.id !== user?.id))
                    this.setState({ matchedParticipants: usersWithAccess })
                }
            });
    };

    renderParticipantsDropdown = (user) => {
        return (
            <div>
                {`${user.firstName + " " + user.lastName} (${user.email})`}
                &nbsp;<span>{!user?.isEapUser && <Chip variant="info">External</Chip>}</span>
            </div>
        )
    }

    renderParticipantsOptions = (key) => {
        const { matchedParticipants, scheduleParticipants, scheduleDetails } = this.state;
        let filterdData = [];
        // Filtering Users based on
        // -which are already added.
        // -Who is creator.

        const scheduleParticipantsData = key >= 0 ? scheduleDetails[key]?.scheduleParticipants : scheduleParticipants
        filterdData = matchedParticipants?.filter(el => {
            return !scheduleParticipantsData?.find(element => {
                return (element.email === el?.email || this.props.user.id === el?.id);
            });
        });

        return (filterdData?.map((item, index) => {
            return <Option key={index} value={JSON.stringify(item)} title={item.firstName + " " + item.lastName}>
                {this.renderParticipantsDropdown(item)}
            </Option>
        }))
    }

    handleParticipantSelect = (key, selectedParticipant) => {
        this.props.clearScheduleFormErrors();
        const { form, user } = this.props;
        if (key >= 0) {
            if (this.state.scheduleDetails?.[key]?.scheduleParticipants?.length >= 4) {
                pushMessage(CONFIG.messageType.warning, "You can only add maximum of 4 participants")
            }
            else {
                if (this.state.scheduleDetails?.[key]?.scheduleParticipants?.length === 0)
                    this.setState(prevState => ({
                        scheduleDetails: prevState.scheduleDetails?.map(item => {
                            if (item?.id === key)
                                return {
                                    ...item,
                                    scheduleParticipants: [...item.scheduleParticipants, { ...user, isCreator: true, isEapUser: true }]
                                }
                            else return item;
                        })
                    }))

                this.setState(prevState => ({
                    scheduleDetails: prevState.scheduleDetails?.map(item => {
                        if (item?.id === key) return {
                            ...item,
                            scheduleParticipants: [...item.scheduleParticipants, { ...JSON.parse(selectedParticipant), disableSendMail: true }]
                        }
                        else return item;
                    })
                }))
            }
            form.setFieldsValue({ [`scheduleDetails[${key}].scheduleParticipants`]: [] })
        }
        else {
            if (this.state.scheduleParticipants?.length >= 4)
                pushMessage(CONFIG.messageType.warning, "You can only add maximum of 4 participants")
            else {
                if (this.state.scheduleParticipants?.length === 0) {
                    this.setState(prevState => ({
                        scheduleParticipants: [...prevState.scheduleParticipants, { ...user, isCreator: true, isEapUser: true }]
                    }), () => this.props.handleParticipantsChange(this.state.scheduleParticipants))
                }
                this.setState(prevState => ({
                    scheduleParticipants: [...prevState.scheduleParticipants, { ...JSON.parse(selectedParticipant), disableSendMail: true }]
                }), () => this.props.handleParticipantsChange(this.state.scheduleParticipants))
            }
            form.setFieldsValue({ scheduleParticipants: [] })
        }
    }

    handleDeleteParticipant = (record) => {
        this.props.clearScheduleFormErrors();
        if (this.key >= 0) this.setState((prevState) => ({
            scheduleDetails: prevState.scheduleDetails?.map(item => {
                if (item.id === this.key) {
                    return {
                        ...item,
                        scheduleParticipants: item.scheduleParticipants?.filter(el => el.email !== record?.email)
                    }
                }
                else return item
            })
        }))
        else {
            this.setState({ scheduleParticipants: this.state.scheduleParticipants?.filter(el => el.email !== record?.email) }, () => {
                this.props.handleParticipantsChange(this.state.scheduleParticipants);
            });
        }
    }

    addItem = () => {
        this.props.clearScheduleFormErrors();
        if (this.key >= 0 ? this.state.scheduleDetails?.[this.key]?.scheduleParticipants?.length >= 4 : this.state.scheduleParticipants?.length >= 4)
            pushMessage(CONFIG.messageType.warning, "You can only add maximum of 4 participants")
        else {
            this.setState({
                addNewUserVisible: true,
            });
        }
    }

    renderFooterButtonsNewParticipant = () => {
        return [
            <Button key="cancel" variant="secondary" onClick={() => this.handleCloseUserModal()}> Cancel </Button>,
            <Button key="ok" variant="primary" onClick={() => this.handleNewUserSave()}>Save</Button>
        ];
    }

    handleCloseUserModal = () => {
        this.setState({
            addNewUserVisible: false,
            isEdit: false,
            editRecord: [],
            warningText: ''
        })
    }

    handeEditUser = (record) => {
        this.setState({
            addNewUserVisible: true,
            isEdit: true,
            editRecord: record
        });
    }

    handleNewUserSave = () => {
        this.props.clearScheduleFormErrors();
        const { demo, form, user, selectedResource } = this.props;
        form.validateFields(['firstName', 'lastName', 'email'], async (err) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return
            }
            else {
                const values = form.getFieldsValue(['firstName', 'lastName', 'email']);
                const { isEdit, editRecord } = this.state
                const newUserObject = {
                    "firstName": values?.firstName,
                    "lastName": values?.lastName,
                    "email": values?.email,
                    "newlyAdded": true
                }

                fetchScheduleParticipantsList(values?.email, demo?.id, selectedResource?.id, false)
                    .then((result) => {
                        const hasEmailChanged = editRecord?.email !== values?.email
                        if (result?.length !== 0 && hasEmailChanged) {
                            if (result[0]?.isEapUser && result[0]?.demoUserAccess !== 2) {
                                this.setState({ warningText: "Sorry! The User you are trying to create already exists, but does not have access to this demo." })
                                return
                            }
                            else if (result[0]?.isEapUser && result[0]?.resourceUserAccess !== 1) {
                                this.setState({ warningText: "Sorry! The User you are trying to create already exists, but does not have access to this resource." })
                                return
                            }
                            else {
                                this.setState({ warningText: "Sorry! The User you are trying to create already exists. You can add this user from the selection dropdown." })
                                return
                            }
                        }

                        else {
                            if (this.key >= 0) {
                                if (isEdit) {
                                    this.setState((prevState) => ({
                                        scheduleDetails: prevState.scheduleDetails?.map(item => {
                                            if (item.id === this.key)
                                                return {
                                                    ...item,
                                                    scheduleParticipants: item.scheduleParticipants?.filter(el => el.email !== editRecord?.email)
                                                }
                                            else return item;
                                        })
                                    }))
                                }

                                if (this.state.scheduleDetails?.[this.key]?.scheduleParticipants?.length === 0)
                                    this.setState((prevState) => ({
                                        scheduleDetails: prevState.scheduleDetails?.map(item => {
                                            if (item?.id === this.key)
                                                return {
                                                    ...item,
                                                    scheduleParticipants: [...item.scheduleParticipants, { ...user, isCreator: true, isEapUser: true }]
                                                }
                                            else return item;
                                        })
                                    }))

                                this.setState((prevState) => ({
                                    scheduleDetails: prevState.scheduleDetails?.map(item => {
                                        if (item?.id === this.key)
                                            return {
                                                ...item,
                                                scheduleParticipants: [...item.scheduleParticipants, newUserObject]
                                            }
                                        else return item;
                                    })
                                }))
                                this.handleCloseUserModal();
                            }

                            else {
                                if (isEdit) this.setState((prevState) => ({ scheduleParticipants: prevState.scheduleParticipants?.filter(el => el.email !== editRecord?.email) }), () => this.props.handleParticipantsChange(this.state.scheduleParticipants))

                                if (this.state.scheduleParticipants?.length === 0)
                                    this.setState(prevState => ({
                                        scheduleParticipants: [...prevState.scheduleParticipants, { ...user, isCreator: true, isEapUser: true }]
                                    }), () => this.props.handleParticipantsChange(this.state.scheduleParticipants))

                                this.setState(prevState => ({
                                    scheduleParticipants: [...prevState.scheduleParticipants, newUserObject]
                                }), () => this.props.handleParticipantsChange(this.state.scheduleParticipants))

                                this.handleCloseUserModal();
                            }
                        }
                    });
            }
        })
    }

    renderAddParticipantModal = () => {
        const { form } = this.props;
        const { isEdit, editRecord } = this.state;
        const { getFieldDecorator } = form;
        return this.state.addNewUserVisible && <Modal
            title="Add New Participant"
            visible={this.state.addNewUserVisible}
            footer={this.renderFooterButtonsNewParticipant()}
            onCancel={this.handleCloseUserModal}
        >
            {<NeoForm>
                <Row gutter={12}>
                    <Col xl={12} sm={20} xs={24}>
                        <Form.Item >
                            {getFieldDecorator("firstName", {
                                rules: [
                                    { required: true, message: "Please input first name" },
                                ],
                                initialValue: isEdit ? editRecord?.firstName : ""
                            })(<TextInput clearable={false} required={true} label="First Name" />)
                            }
                        </Form.Item>
                    </Col>
                    <Col xl={12} sm={20} xs={24}>
                        <Form.Item>
                            {getFieldDecorator("lastName", {
                                rules: [
                                    { required: true, message: "Please input last name" },
                                ],
                                initialValue: isEdit ? editRecord?.lastName : ""
                            }
                            )(<TextInput clearable={false} required={true} label="Last Name" />)
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                    <Form.Item >
                        {getFieldDecorator("email", {
                            rules: [{
                                required: true,
                                type: "email",
                                message: "Please input the e-mail id"
                            }],
                            initialValue: isEdit ? editRecord?.email : ""
                        })(<TextInput clearable={false} type="email" required={true} label="Email" />)
                        }
                    </Form.Item>
                </Row>
                <Row>
                    <Text type="danger"> {this.state.warningText}</Text>
                </Row>
            </NeoForm>}
        </Modal>
    }

    handleFreqTypeChange = (value) => {
        this.setState({ freqType: value });
        this.props.handleFreqChange(value);
    }

    disabledEndDate = end => {
        const { timezone } = this.props;
        const now = end && end < (moment.tz(moment(), timezone).endOf('day').subtract(1, 'day'))
        return now
    };

    seriesEndDate = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return <Form.Item label={<span style={{ fontSize: "12px", color: "#323232" }}>Series End Date</span>}>
            {getFieldDecorator("seriesEndDate", {
                rules: [
                    {
                        required: true,
                        message: "Please select series end date"
                    }
                ],
                initialValue: this.state.seriesEndDate
            })(<DatePicker
                disabledDate={this.disabledEndDate}
                size="large"
                className='antd-neo-date-picker'
                format={CONFIG.dateFormats.calendarDate}
                placeholder="End Time"
                showToday={false}
                allowClear={false}
                style={{ display: "grid", marginTop: "1px" }}
                onChange={(value) => {
                    this.setState({ seriesEndDate: value })
                }}
            />)}
        </Form.Item>
    }

    renderDaysOfWeekOptions = () => {
        return ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'].map(item => {
            return <SelectOption value={item} >{item}</SelectOption>
        })
    }

    renderMonthDayOptions = () => {
        return [...range(1, 32)].map(item => {
            return <SelectOption value={`${item}`} >{item}</SelectOption>
        })
    }

    renderValueOptions = () => {
        return weekValues.map(item => {
            return <SelectOption value={item.value} >{item.name}</SelectOption>
        })
    }

    radioToggle = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return <Form.Item>
            {
                getFieldDecorator('radioToggle',
                    {
                        initialValue: this.state.radioToggle
                    }

                )(
                    <Radio.Group style={{ width: "100%" }} onChange={(e) => {
                        this.setState({ radioToggle: e.target.value })
                    }}
                    >
                        <div className='radio-div-occurence'>
                            <Radio value={1} style={radioStyle}>
                                <span style={{ color: "#323232" }}>On day</span>
                                <Form.Item style={{ display: "inline-block", width: "30%", marginLeft: "36px" }}>
                                    {
                                        getFieldDecorator('monthDay',
                                            {
                                                initialValue: this.state.monthDay,
                                                rules: [{ required: this.state.radioToggle === 2 ? false : true, message: 'Select a value' }],
                                            }

                                        )(
                                            <NeoSelect
                                                disabled={this.state.radioToggle === 2}
                                                placeholder='Select a value'
                                                multiple
                                                label=" "
                                                value={this.state.monthDay}
                                                onChange={(value) => {
                                                    this.setState({ monthDay: value })
                                                }
                                                }
                                            >
                                                {
                                                    this.renderMonthDayOptions()
                                                }
                                            </NeoSelect>)}
                                </Form.Item>
                            </Radio>
                        </div>
                        <br />
                        <div className='radio-div-occurence'>
                            <Radio value={2} style={radioStyle}>
                                <span style={{ color: "#323232" }}>On the</span>
                                <br />
                                <div style={{ marginLeft: "70px", marginTop: "-28px", marginBottom: "-28px" }}>
                                    <Form.Item style={{ display: "inline-block", marginLeft: "36px", width: "31.5%", }}>
                                        {getFieldDecorator('value',
                                            {
                                                initialValue: this.state.value,
                                                rules: [{ required: this.state.radioToggle === 1 ? false : true, message: 'Select a value' }],
                                            }

                                        )(<NeoSelect
                                            disabled={this.state.radioToggle === 1}
                                            label=" "
                                            placeholder='Select a value'
                                            // style={{ width: 140 }}
                                            value={this.state.value}
                                            onChange={(value) => {
                                                this.setState({ value })
                                            }}
                                        >
                                            {
                                                this.renderValueOptions()
                                            }
                                        </NeoSelect>)

                                        }
                                    </Form.Item>
                                    <Form.Item style={{ display: "inline-block", marginLeft: "36px", width: "31.5%", }}>
                                        {getFieldDecorator('dayName',
                                            {
                                                initialValue: this.state.dayName,
                                                rules: [{ required: this.state.radioToggle === 1 ? false : true, message: 'Select a value' }],
                                            }
                                        )
                                            (<NeoSelect
                                                disabled={this.state.radioToggle === 1}
                                                // multiple
                                                label=" "
                                                placeholder='Select a value'
                                                // style={{ width: 140, marginLeft: 20 }}
                                                value={this.state.dayName}
                                                onChange={value => {
                                                    this.setState({ dayName: value })
                                                }}
                                            >
                                                {
                                                    this.renderDaysOfWeekOptions()
                                                }
                                            </NeoSelect>
                                            )}
                                    </Form.Item>
                                </div>
                            </Radio>
                        </div>
                    </Radio.Group>
                )
            }
        </Form.Item>
    }

    dayOfWeek = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return <Form.Item >
            {
                getFieldDecorator('dayOfWeek',
                    {
                        rules: [{ required: true, message: 'Select a day of week' }],
                        initialValue: this.state.dayOfWeek
                    }
                )(
                    <NeoSelect
                        placeholder='Select a value'
                        multiple
                        label="Occurs every"
                        className="occurs-astrick"
                        onChange={value => {
                            this.setState({ dayOfWeek: value })
                        }}
                    >
                        {
                            this.renderDaysOfWeekOptions()
                        }
                    </NeoSelect>
                )
            }
        </Form.Item>
    }

    scheduleRecurrence = () => {
        const { demo, form } = this.props;
        const { getFieldDecorator } = form;
        const scheduleId = this.props?.match?.params?.scheduleId || null;
        const isScheduleRecurrenceDisabled = (demo?.isPreRegistrationRequired || scheduleId)

        return <Form.Item colon={false} label={<span style={{ fontSize: "12px", color: "#323232" }}>Schedule Recurrence</span>}>
            {getFieldDecorator("freqType", {
                initialValue: this.state.freqType,
                rules: [{ required: true, message: 'Please select a type' }]
            })(<Select
                showSearch
                bordered={false}
                suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                className='organiser-select'
                placeholder="Select the occurence"
                size='large'
                disabled={isScheduleRecurrenceDisabled}
                onChange={this.handleFreqTypeChange}
            >
                <Option key="1" value='1' >Does not repeat</Option>
                <Option key="2" value='sw' >Weekly</Option>
                <Option key="3" value="sm" >Monthly</Option>
                <Option key="4" value="r" >Custom</Option>
            </Select>)}
        </Form.Item>
    }

    handleAvailabilityCheck = () => {
        const { form, user, demo, timezone } = this.props
        const { scheduleDetails } = this.state;
        const selectedUser = form.getFieldValue("assignee") ? JSON.parse(form.getFieldValue("assignee")) : null
        let userAnswers = {}
        let schedules = []
        form.validateFields(async (err) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Certain form fields require your attention. Please review.");
                return
            }
            else {
                const preRequisiteQuestionObj = demo?.additionalDemoQuestions || [];
                if (preRequisiteQuestionObj?.length > 0) {
                    const formValues = Object.entries(this.state.formValues).map(([questionId, answerText]) => ({ questionId, answerText }))?.filter((el) => {
                        return preRequisiteQuestionObj?.some((f) => {
                            return f?.question?.id === parseInt(el?.questionId);
                        });
                    });

                    const userAnswersObj = formValues.map(fv => {
                        fv.questionOptions = preRequisiteQuestionObj?.filter(dq => dq.question?.id === parseInt(fv.questionId))?.[0]?.question?.answers?.map(a => a.title).join('//') || null;
                        return fv;
                    })

                    userAnswers = userAnswersObj?.map(obj => ({
                        question: { id: parseInt(obj.questionId) },
                        questionOptions: obj.questionOptions,
                        answerText: Array.isArray(obj?.answerText) ? obj?.answerText.join('//') : obj?.answerText
                    }))
                }
                scheduleDetails.forEach(item => {
                    const purposeAttributesSchedule = createScheduleObject(item.attributes)
                    let participants;
                    if (item.scheduleParticipants?.length > 0) {
                        participants = {
                            "eapUsers": item.scheduleParticipants?.filter(sp => sp?.isEapUser === true && sp?.isCreator === false),
                            "extUsers": item.scheduleParticipants?.filter(sp => ((sp?.isEapUser === false) || (sp?.newlyAdded === true)))
                        }
                    }

                    const requireOpportunity = item.purpose ? item.purpose?.demoPurposeAttributes?.some(attribute =>
                        attribute.hasOwnProperty('type') && attribute.type !== null && attribute.type !== '' && attribute.type !== undefined
                    ) : false;

                    schedules.push(
                        {
                            tempId: item.id + 1,
                            createdBy: selectedUser ? selectedUser : user,
                            demoPurpose: item.purpose,
                            startTime: item.startTime.tz(timezone, true).toISOString(),
                            endTime: item.endTime.tz(timezone, true).toISOString(),
                            resource: item.resource,
                            purposeAttributesSchedule,
                            userAnswers: Object.keys(userAnswers)?.length === 0 ? null : userAnswers,
                            participants: participants || null,
                            demo,
                            isOneTime: demo?.isOneTime,
                            isPreRegistration: demo?.isPreRegistration,
                            uniqueId: item?.uniqueId,
                            opportunity: requireOpportunity ? item?.opportunity : undefined
                        }
                    )
                })
                this.setState({ nextButtonLoading: true })
                this.props.checkScheduleAvailability(schedules, timezone).then(() => this.setState({ nextButtonLoading: false })
                )
            }
        });

    }

    handleNext = () => {
        const { form, timezone, demo, selectedResource, startTime, endTime, user, customerInfo } = this.props;
        const { currentStep, freqType } = this.state;

        let userAnswers = {}
        if (currentStep === 0) {
            this.key = 0;
            const data = {
                ...this.state,
                startValue: moment.tz(startTime, timezone),
                endValue: moment.tz(endTime, timezone)
            }
            form.validateFields(async (err, values) => {
                if (err) {
                    pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
                    return
                }

                const preRequisiteQuestionObj = demo?.additionalDemoQuestions || [];
                if (preRequisiteQuestionObj?.length > 0) {
                    const formValues = Object.entries(values).map(([questionId, answerText]) => ({ questionId, answerText }))?.filter((el) => {
                        return preRequisiteQuestionObj?.some((f) => {
                            return f?.question?.id === parseInt(el?.questionId);
                        });
                    });

                    const userAnswersObj = formValues.map(fv => {
                        const obj = preRequisiteQuestionObj?.filter(dq => dq.question?.id === parseInt(fv.questionId))?.[0]?.question;
                        fv.questionOptions = obj?.answers?.map(a => a.title).join('//') || null;
                        fv.isMandatory = obj?.isMandatory;
                        fv.title = obj?.title;
                        fv.answerType = obj?.answerType;
                        fv.answers = obj?.answers;
                        return fv;
                    })


                    userAnswers = userAnswersObj?.map(obj => ({
                        question: { id: parseInt(obj.questionId), isMandatory: obj.isMandatory, title: obj.title, answerType: obj.answerType, answers: obj.answers },
                        questionOptions: obj.questionOptions,
                        answerText: Array.isArray(obj?.answerText) ? obj?.answerText.join('//') : obj?.answerText,
                    }))
                }
                this.setState({ nextButtonLoading: true });
                const createdBy = values?.assignee ? JSON.parse(values.assignee) : user;

                let dateRanges = freqType === "r" ? [] : await getDateRanges(data, this.props.timezone, demo?.id, createdBy)
                let scheduleDetails = [];
                let purposeDetails = [];

                let opportunity;
                opportunity = {
                    opportunityNumber: customerInfo ? customerInfo?.opportunity?.otnNumber : undefined,
                    sfdcOpportunityId: customerInfo ? customerInfo?.opportunity?.id : undefined,
                    validatedBy: customerInfo && customerInfo?.opportunity ? user?.email : undefined,
                    accountOwnerEmail: values?.accountOwnerEmail,
                    customerName: customerInfo && customerInfo?.opportunity ? customerInfo?.opportunity?.AccountName : this.props.form.getFieldValue(`Customer Name`)
                }

                if (dateRanges.length !== 0) {
                    dateRanges.forEach((item, index) => {
                        if (Array.isArray(selectedResource)) {
                            selectedResource.forEach((resource, resourceIndex) => {
                                scheduleDetails.push({
                                    id: resourceIndex + index * selectedResource.length, // Adjust the id based on your logic
                                    uniqueId: Math.floor(Math.random() * 10000),
                                    startTime: moment.tz(item.startTime, timezone),
                                    endTime: moment.tz(item.endTime, timezone),
                                    resource: resource,
                                    ...(selectedResource.length > 1 && { groupId: index }),
                                    purpose: this.state.selectedPurpose,
                                    resourceAccess: true,
                                    attributes: this.additionalAttributes,
                                    scheduleParticipants: this.state.scheduleParticipants,
                                    availableResources: demo?.demoResources || [],
                                    opportunity: opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined,
                                    notes: values?.notes,
                                    doNotHaveOpportunity: values?.doNotHaveOpportunity
                                });
                                purposeDetails.push({
                                    id: resourceIndex + index * selectedResource.length, // Adjust the id based on your logic
                                    purpose: this.state.selectedPurpose
                                });
                            });
                        }
                        else {
                            scheduleDetails.push({
                                id: index,
                                uniqueId: Math.floor(Math.random() * 10000),
                                startTime: moment.tz(item.startTime, timezone),
                                endTime: moment.tz(item.endTime, timezone),
                                resource: selectedResource,
                                purpose: this.state.selectedPurpose,
                                resourceAccess: true,
                                attributes: this.additionalAttributes,
                                scheduleParticipants: this.state.scheduleParticipants,
                                availableResources: demo?.demoResources || [],
                                opportunity: opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined,
                                notes: values?.notes,
                                doNotHaveOpportunity: values?.doNotHaveOpportunity
                            })
                            purposeDetails.push({
                                id: index,
                                purpose: this.state.selectedPurpose
                            })
                        }
                    })
                }
                else {
                    if (Array.isArray(selectedResource)) {
                        selectedResource.forEach((resource, resourceIndex) => {
                            scheduleDetails.push({
                                id: resourceIndex, // Adjust the id based on your logic
                                uniqueId: Math.floor(Math.random() * 10000),
                                startTime: moment.tz(startTime, timezone),
                                endTime: moment.tz(endTime, timezone),
                                resource: resource,
                                purpose: this.state.selectedPurpose,
                                resourceAccess: true,
                                attributes: this.additionalAttributes,
                                scheduleParticipants: this.state.scheduleParticipants,
                                availableResources: demo?.demoResources || [],
                                opportunity: opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined,
                                notes: values?.notes,
                                doNotHaveOpportunity: values?.doNotHaveOpportunity
                            });
                            purposeDetails.push({
                                id: resourceIndex, // Adjust the id based on your logic
                                purpose: this.state.selectedPurpose
                            });
                        });
                    }
                    else {
                        scheduleDetails.push({
                            id: 0,
                            uniqueId: Math.floor(Math.random() * 10000),
                            startTime: moment.tz(startTime, timezone),
                            endTime: moment.tz(endTime, timezone),
                            resource: selectedResource,
                            purpose: this.state.selectedPurpose,
                            resourceAccess: true,
                            attributes: this.additionalAttributes,
                            scheduleParticipants: this.state.scheduleParticipants,
                            availableResources: demo?.demoResources || [],
                            opportunity: opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined,
                            notes: values?.notes,
                            doNotHaveOpportunity: values?.doNotHaveOpportunity
                        })
                        purposeDetails.push({
                            id: 0,
                            purpose: this.state.selectedPurpose
                        })
                    }
                }

                this.setState((prevState) => ({
                    currentStep: prevState.currentStep + 1,
                    nextButtonLoading: false,
                    dateRanges,
                    additionalAttributes: this.additionalAttributes,
                    userAnswers,
                    scheduleDetails,
                    purposeDetails,
                    schedulePurposeAttributes: this.additionalAttributes,
                    formValues: values,
                    activePanelKey: scheduleDetails[0]?.uniqueId
                }), () => this.handleAvailabilityCheck());
                this.schedulePurposeAttributes = this.additionalAttributes;
            })
        }
        else this.setState((prevState) => ({
            currentStep: prevState.currentStep + 1,
        }), () => this.handleAvailabilityCheck())
        this.setState({ matchedParticipants: [] })
    }

    handlePrev = () => {
        const { currentStep } = this.state;
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
                return
            }
            else {
                if (currentStep === 1) this.props.clearScheduleFormErrors();
                this.setState((prevState) => ({ currentStep: prevState.currentStep - 1, matchedParticipants: [] }));
            }
        })
    }

    handleCancel = () => {
        this.props.closeCurrentOpenTab()
    }

    renderPageButtons = () => {
        const { currentSchedule, checkAvailability, loading } = this.props;
        const { currentStep, freqType, isFormDisabled } = this.state;

        if (!currentSchedule && freqType !== "1") {
            if (currentStep === 0) {
                return [
                    <div style={{ marginBottom: "20px" }} className='float-right'>
                        <Button key="cancel" variant="secondary" onClick={() => this.props.handleStepChange(true)} > Back </Button>
                        <Button key="next" className="float-right left-spacing-3" variant="primary" onClick={this.handleNext} loading={this.state.nextButtonLoading} disabled={isFormDisabled} > Next </Button>
                    </div>
                ]
            }
            else if (currentStep === 1) {
                if (!checkAvailability) return [
                    <div style={{ marginBottom: "20px" }} className='float-right'>
                        <Button variant="tertiary" key="cancel" className="cancel-button" onClick={this.handleCancel} > Discard & Close </Button>
                        <Button variant="secondary" key="prev" className="left-spacing-3" onClick={this.handlePrev} > Prev </Button>
                        <Button key="check-availability" className="float-right left-spacing-3" onClick={this.handleAvailabilityCheck} loading={this.state.nextButtonLoading} disabled={isFormDisabled}> Check Availability </Button>
                    </div>
                ]
                return [
                    <div style={{ marginBottom: "20px" }} className='float-right'>
                        <Button key="cancel" variant="tertiary" onClick={this.handleCancel} > Discard & Close </Button>
                        <Button key="prev" variant="secondary" className="left-spacing-3" onClick={this.handlePrev} > Prev </Button>
                        <Button key="next" className="float-right left-spacing-3" onClick={this.handleNext} disabled={isFormDisabled} > Next </Button>
                    </div>
                ]
            }
            else {
                return (
                    <div style={{ marginBottom: "20px" }} className='float-right'>
                        <Button key="cancel" variant="tertiary" onClick={this.handleCancel}> Discard & Close </Button>
                        <Button key="prev" variant="secondary" className="left-spacing-3" onClick={this.handlePrev} > Prev </Button>
                        <Button key="ok" className="float-right left-spacing-3" disabled={this.props.possibleSchedules?.length === 0 || loading} onClick={() => { this.setState({ confirmModal: true }) }}> Create </Button>
                    </div>
                )
            }
        }
    }

    handlePanelChange = (key) => {
        this.key = key;
        this.setState({ matchedParticipants: [] })
    }

    handleResourceChange = async (key, event) => {
        this.props.clearScheduleFormErrors();
        if (key >= 0) {
            this.setState((prevState) => ({
                isFormDisabled: false,
                scheduleDetails: prevState.scheduleDetails.map(item => {
                    if (key === item.id) return {
                        ...item,
                        resourceAccess: true,
                        scheduleParticipants: [],
                        resource: JSON.parse(event.target.value)
                    }
                    else return item
                })
            }))
        }
    }

    renderParticipants = (key, label, span) => {
        label = label || "scheduleParticipants"
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return <Row gutter={24}>
            <Col style={{ marginBottom: "10px" }} span={span ?? 6}>
                <Form.Item colon={false} label={<span style={{ fontSize: "12px", color: "#323232" }}>Participants (Optional)</span>}>
                    {
                        getFieldDecorator(label)(
                            <Select
                                showSearch
                                bordered={false}
                                placeholder="Select/Add a Participant"
                                suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                notFoundContent={<span>No Match found!</span>}
                                className='organiser-select'
                                size="large"
                                onSearch={(...rest) => this.fetchParticipants(key, ...rest)}
                                onSelect={(...rest) => this.handleParticipantSelect(key, ...rest)}
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div
                                            style={{ padding: '4px 8px', cursor: 'pointer', color: "#242424" }}
                                            onMouseDown={e => e.preventDefault()}
                                            onClick={(...rest) => this.addItem(key, ...rest)}
                                        >
                                            <Icon style={{ fontSize: "14px" }} icon="user-add" /> Add New User
                                        </div>
                                    </div>
                                )}
                            >
                                {this.renderParticipantsOptions(key)}
                            </Select>
                        )
                    }
                </Form.Item>
            </Col>
        </Row>
    }

    deleteScheduleInstance = (index) => {
        this.props.clearScheduleFormErrors();
        this.setState((prevState) => ({ scheduleDetails: prevState.scheduleDetails.filter(item => item.id !== index) }),
            () => { this.setState(prevState => ({ scheduleDetails: prevState.scheduleDetails.map((item, index) => { return { ...item, id: index } }) })) })
    }

    handleTimeChange = (key) => {
        const { scheduleDetails } = this.state;
        const initialDateValue = [scheduleDetails[key].startTime, scheduleDetails[key].endTime]
        return initialDateValue
    }

    handleValidateDateAndTime = async (key, selectedDate) => {
        const { form, timezone } = this.props;
        const initialDateValue = this.handleTimeChange(key)
        const startDate = moment(selectedDate).tz(timezone, true).utc()
        if (startDate < moment().utc()) {
            if (key !== null || key !== undefined) {
                await form.setFieldsValue({ [`scheduleDetails[${key}].startTime`]: initialDateValue[0], [`scheduleDetails[${key}].endTime`]: initialDateValue[1] })
            }
            pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.scedulerInvalidDate.content);
            return false
        }
        else return true
    }

    displayWarningMessage = async (key) => {
        const { form } = this.props;
        const initialDateValue = this.handleTimeChange(key)
        if (key >= 0) form.setFieldsValue({ [`scheduleDetails[${key}].startTime`]: initialDateValue[0], [`scheduleDetails[${key}].endTime`]: initialDateValue[0] })
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.scedulerInvalidDate.content);
    }

    handleStartChange = (key, value) => {
        this.props.clearScheduleFormErrors();
        const { form } = this.props;
        const endValue = form.getFieldValue(`scheduleDetails[${key}].endTime`)
        if ((endValue === null || endValue === undefined) || value < endValue) {
            const isValid = this.handleValidateDateAndTime(key, value);
            if (isValid) {
                this.setState((prevState) => ({
                    scheduleDetails: prevState.scheduleDetails.map(item => {
                        if (item.id === key) return {
                            ...item,
                            startTime: value
                        }
                        else return item
                    })
                }))
            }
        }
        else {
            this.displayWarningMessage(key)
        }
    }

    handlePublishOpenChange = async (key, isOpen) => {
        const { isReschedule } = this.props;
        const { form } = this.props;
        if (!isOpen && !isReschedule) {
            const initialDateValue = this.handleTimeChange(key)
            const startValue = form.getFieldValue(`scheduleDetails[${key}].startTime`);
            const endValue = form.getFieldValue(`scheduleDetails[${key}].endTime`);
            if ((endValue && startValue) && (endValue.valueOf() < startValue.valueOf())) {
                if (key >= 0) form.setFieldsValue({ [`scheduleDetails[${key}].startTime`]: initialDateValue[0] })
            }
        }
    }

    handleExpiryOpenChange = (key, isOpen) => {
        const { isReschedule } = this.props;
        const { form } = this.props;
        if (!isOpen && !isReschedule) {
            const initialDateValue = this.handleTimeChange(key)
            const startValue = form.getFieldValue(`scheduleDetails[${key}].startTime`);
            const endValue = form.getFieldValue(`scheduleDetails[${key}].endTime`);
            if ((endValue && startValue) && (endValue.valueOf() < startValue.valueOf())) {
                if (key >= 0) form.setFieldsValue({ [`scheduleDetails[${key}].endTime`]: initialDateValue[1] })
            }
        }
    }

    handleEndChange = async (key, value) => {
        this.props.clearScheduleFormErrors();
        const { form } = this.props;
        const startValue = form.getFieldValue(`scheduleDetails[${key}].startTime`);
        if (startValue < value) {
            const isValid = this.handleValidateDateAndTime(key, value);
            if (isValid) {
                this.setState((prevState) => ({
                    scheduleDetails: prevState.scheduleDetails.map(item => {
                        if (item.id === key) return {
                            ...item,
                            endTime: value
                        }
                        else return item
                    })
                }))
            }
        }
        else {
            this.displayWarningMessage(key)
        }
    };

    addNewSchedule = () => {
        let { scheduleDetails, purposeDetails } = this.state;
        const { selectedResource, demo, customerInfo, form } = this.props;

        let opportunity;
        opportunity = {
            opportunityNumber: customerInfo ? customerInfo?.opportunity?.otnNumber : undefined,
            sfdcOpportunityId: customerInfo ? customerInfo?.opportunity?.id : undefined,
            validatedBy: customerInfo && customerInfo?.opportunity ? this.props.user?.email : undefined,
            accountOwnerEmail: form.getFieldValue("accountOwnerEmail"),
            customerName: customerInfo && customerInfo?.opportunity ? customerInfo?.opportunity?.AccountName : this.props.form.getFieldValue(`Customer Name`)
        }

        this.props.clearScheduleFormErrors();
        scheduleDetails.push({
            id: scheduleDetails.length,
            uniqueId: Math.floor(Math.random() * 10000),
            startTime: null,
            endTime: null,
            resource: Array.isArray(selectedResource) ? selectedResource[0] : selectedResource,
            purpose: this.state.selectedPurpose,
            resourceAccess: true,
            attributes: this.additionalAttributes,
            scheduleParticipants: this.state.scheduleParticipants,
            availableResources: demo?.demoResources || [],
            opportunity: opportunity && !Object.values(opportunity).every(value => value === undefined) ? opportunity : undefined,
            doNotHaveOpportunity: form.getFieldValue("doNotHaveOpportunity")
        })
        purposeDetails.push({
            id: scheduleDetails.length,
            purpose: this.state.selectedPurpose
        })
        this.setState({ scheduleDetails, purposeDetails })
    }

    renderDetails = () => {
        const { demo, timezone, startTime } = this.props;
        const { dayOfWeek, value, dayName, monthDay } = this.state;
        return (
            <>
                <div >
                    <div style={{ fontSize: "12px", color: "#242424", lineHeight: "16px" }}>Demo Name: </div>
                    <div style={{ fontSize: '14px', color: "#242424", fontWeight: '600', lineHeight: "20px" }}>{demo.title}</div>
                </div>
                <div style={{ marginTop: "8px" }}>
                    <div style={{ fontSize: "12px", color: "#242424", lineHeight: "16px", marginTop: "5px" }}>Recurrence: </div>
                    <div style={{ fontSize: '14px', color: "#242424", fontWeight: '600', lineHeight: "20px" }}>
                        {this.state.freqType === "sw" ? "Weekly, Every " : this.state.freqType === "sm" ? "Monthly, Every " : "Custom"}
                        <span>
                            {this.state.freqType === "sw" ?
                                (dayOfWeek?.length > 0 ? dayOfWeek.map((str, index) => (
                                    <span key={index}>
                                        {toTitleCase(str)}
                                        {index < dayOfWeek.length - 2 ? ', ' : index === dayOfWeek.length - 2 ? ' and ' : ''}
                                    </span>
                                )) : null)
                                : this.state.freqType === "sm" ? this.state.radioToggle === 1 ?
                                    (monthDay?.length > 0 ? monthDay.map((str, index) => (
                                        <span key={index}>
                                            <span>{parseInt(str)}{getDaySuffix(parseInt(str))}</span>
                                            {index < monthDay.length - 2 ? ', ' : index === monthDay.length - 2 ? ' and ' : ''}
                                        </span>
                                    )) : null) :
                                    <span>{toTitleCase(weekValues[parseInt(value) - 1]?.name)} of {toTitleCase(dayName)}</span> : null
                            }
                        </span>
                    </div>
                </div>
                {this.state.freqType !== "r" && <div style={{ marginTop: "8px" }}>
                    <div style={{ fontSize: "12px", color: "#242424", lineHeight: "16px", marginTop: "5px" }}>Series Ending: </div>
                    <div style={{ fontSize: '14px', color: "#242424", fontWeight: '600', lineHeight: "20px" }}>
                        {moment(this.state.seriesEndDate).tz(timezone).format(CONFIG.dateFormats.user)}
                    </div>
                </div>}
                <Divider className="neo-std-divider" />
                <div className='reccurence-confirmation-warning'>
                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ margin: "0 16px" }}>
                            <Icon
                                icon="warning"
                                size="md"
                                style={{ color: "#8e4900" }}
                            />
                        </span>
                        <span>
                            Are you sure you want to create {this.props.possibleSchedules?.length} {this.state.freqType !== "r" ? "reccuring schedules" : "custom schedules?"}
                            {this.state.freqType !== "r" && <span> between {moment(startTime).tz(timezone).format(CONFIG.dateFormats.user)} and {moment(this.state.seriesEndDate).tz(timezone).format(CONFIG.dateFormats.user)}?</span>}
                        </span>
                    </div>
                </div>
            </>
        );
    }

    handleCreateSchedule = async () => {
        const { form } = this.props;
        this.setState({ nextButtonLoading: true, confirmModal: false })
        await this.props.postNewDemoSchedule(this.props.possibleSchedules, null, null, true).then(() => {
            form.resetFields();
            this.setState({
                selectedPurpose: undefined,
                isCurrentSchedule: false,
                nextButtonLoading: false
            });
        })
    }

    showConfirmationModal = () => {
        const modalTitle = <span className='modal-title'>Create Schedule?</span>
        return (
            <>
                <BasicModal open={this.state.confirmModal}
                    onClose={() => { this.setState({ confirmModal: false, currentStep: 1 }); this.props.clearScheduleFormErrors(); }}
                    closeButtonLabel={"Edit"}
                    title={modalTitle}
                    className="neo-modal"
                    actions={[
                        <Button key="Confirm"
                            onClick={this.handleCreateSchedule}
                        >
                            Create
                        </Button>]}>
                    {this.renderDetails()}
                </BasicModal>
            </>
        );
    }

    handleActivePanelChange = (key, goBack) => {
        if (goBack) {
            this.setState((prevState) => ({ currentStep: prevState.currentStep - 1, matchedParticipants: [] }), () => {
                this.setState({
                    activePanelKey: key
                },
                    () => {
                        const element = document.getElementById(`collapse-panel-${key}`);
                        if (element) {
                            scrollIntoView(element, {
                                align: { top: 0, left: 0 },
                                behavior: "smooth"
                            })
                        }
                    });
            });
        }
        else {
            this.setState({
                activePanelKey: key
            })
        }
    }

    handleOpportunityNumberChange = async (customerInfo, label, atrType) => {
        await this.props.updateCustomerInfo(customerInfo);

        await this.props.form.setFieldsValue({ [`Customer Name`]: customerInfo && customerInfo?.status === "Success" ? customerInfo?.opportunity?.AccountName : "" })

    }

    isFetchingOpportunity = async (flag) => {
        await this.setState({ isFetchingOppNo: flag });
    }

    handleNoOpportunityCheckbox = async (value) => {
        if (value) {
            await this.props.updateCustomerInfo({});
            await this.props.form.setFieldsValue({ [`Customer Name`]: "" })
            await this.props.form.setFieldsValue({ [`Opportunity Number`]: "" })
        }
    }

    render() {
        const { demo, form, isMultipleScheduleBooking } = this.props;
        const { timezone, selectedResource, startTime, endTime, currentSchedule, isPeriodicEnabled, isAddParticipantsEnabled, isReschedule, user, customerInfo } = this.props;
        const selectedPurpose = form.getFieldValue('demoPurpose') ? JSON.parse(form.getFieldValue('demoPurpose')) : this.state.selectedPurpose
        const { additionalDemoQuestions } = this.props.demo;
        this.additionalAttributes = getInitialValue(selectedPurpose, this.props.form)
        this.data = currentSchedule && getInitialValueForSchedules(currentSchedule, this.props.form);
        this.data = this.props.isCurrentSchedule ? isAttributeChanged(this.data, selectedPurpose, this.props.form) : this.data
        this.additionalData = this.props.isCurrentSchedule ? this.data : this.additionalAttributes;
        const completeData = this.additionalData;
        const preRequisiteQuestionObj = currentSchedule ? currentSchedule?.demo?.additionalDemoQuestions : additionalDemoQuestions
        const createdBy = isReschedule ? user : currentSchedule ? currentSchedule.createdBy : form.getFieldValue("assignee") ? JSON.parse(form.getFieldValue("assignee")) : user;
        const notes = isReschedule ? undefined : currentSchedule ? currentSchedule?.notes : undefined;
        let neoColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.firstName + " " + a?.cell?.row?.original?.lastName}</span>
                },
                Header: "Name",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}><Chip variant={a.cell.row.original?.isCreator ? "info" : "default"}>{a.cell.row.original?.isCreator ? "Creator" : "Participant"}</Chip></span>
                },
                Header: "Role",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}><Chip variant={a.cell.row.original?.isEapUser ? "info" : "default"}>{a.cell.row.original?.isEapUser ? "EAP" : "External"}</Chip></span>
                },
                Header: "User Type",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    const { user } = this.props;
                    const record = a.cell.row.original;
                    const isEditDisabled = (record?.isEapUser === true)
                    const showOptOut = (record?.id === user?.id)
                    return (
                        <>
                            {!isEditDisabled && <span style={{ marginRight: "15px" }}>
                                <Tooltip title="Edit"><Icon size="md" style={{ cursor: "pointer" }} icon="edit" onClick={() => this.handeEditUser(record)}
                                /></Tooltip>
                            </span>}
                            {
                                showOptOut ? ""
                                    : <><span style={isEditDisabled ? { marginLeft: "35px" } : {}}></span><Tooltip title="Delete"><Icon size="md" style={{ cursor: "pointer" }} icon="trash" onClick={() => !record?.isCreator && this.handleDeleteParticipant(record)} className={record?.isCreator ? "link-disabled comments-action-button-disabled" : ""} /></Tooltip></>
                            }
                        </>
                    )
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true
            },
        ];

        return (
            <Spin indicator={antIcon} spinning={this.state.nextButtonLoading || this.state.isFetchingOppNo}>
                <div style={{ marginTop: "16px" }}>
                    {this.state.freqType !== "1" && <div style={{}}>
                        <div >
                            {this.renderPageButtons()}
                        </div>
                    </div>}
                    {this.state.freqType !== "1" && <div style={{ marginBottom: "20px", marginLeft: "30px", marginRight: "30px" }}>
                        <Steps size='small' current={this.state.currentStep}>
                            <Step title="Basic Details" />
                            <Step title="Schedule Details" />
                            <Step title="Confirmation" />
                        </Steps>
                    </div>}
                    {<div style={this.state.currentStep === 0 ? {} : { display: "none" }}>
                        <div className='schedule-demo-details'>
                            < ScheduleTimeDetails isMultipleScheduleBooking={isMultipleScheduleBooking} showResource={true} demo={demo} startTime={startTime} endTime={endTime} timezone={timezone} selectedResource={selectedResource} />
                        </div>
                        <NeoForm className='neo-scheduler-form'>
                            <Row gutter={24} style={{ marginTop: "10px", display: 'flex', flexWrap: "wrap" }}>
                                <Col span={6}>
                                    <div>
                                        {this.renderPurpose()}
                                    </div>
                                </Col>
                                {(completeData?.length > 0 && this.props.form.getFieldValue("demoPurpose")) && completeData.map((eachItem) => {
                                    return renderAdditionalFields(eachItem, completeData?.length, this.props, this.props.verticals, this.props.isCurrentSchedule, this.props.isReschedule, true, customerInfo, this.handleOpportunityNumberChange, this.isFetchingOpportunity, this?.props?.userSettings, this.handleNoOpportunityCheckbox)
                                }
                                )}
                            </Row>
                            <Divider className="neo-std-divider" />
                            <Row>
                                {renderNotes(notes, this.props.form)}
                            </Row>
                        </NeoForm>
                        <Divider className="neo-std-divider" />
                        <div>
                            {isPeriodicEnabled && <NeoForm className='neo-scheduler-form'><Row gutter={24} style={{ marginTop: "10px" }}>
                                <Col span={6}>
                                    {
                                        this.scheduleRecurrence()
                                    }
                                </Col >
                                {
                                    (this.state.freqType === "sm" || this.state.freqType === "sw") && <Col span={6} >{this.seriesEndDate()}</Col>
                                }
                                {
                                    this.state.freqType === "sw" && <>

                                        <Col span={6} style={{ marginTop: "3px" }}>{this.dayOfWeek()}</Col>
                                    </>
                                }
                            </Row >
                                {
                                    this.state.freqType === "sm" && <div style={{ marginTop: "10px" }}>{this.radioToggle()}</div>
                                }
                                <Divider className="neo-std-divider" />
                            </NeoForm >}
                        </div>
                        <div>
                            {(isAddParticipantsEnabled && user?.id === createdBy?.id) && this.renderParticipants()}
                            {this.state.scheduleParticipants?.length > 0 && <div style={{ marginTop: "10px" }}>
                                <Table
                                    showRowSeparator
                                    rowHeight="medium"
                                    columns={isAddParticipantsEnabled ? neoColumns : neoColumns.slice(0, 4)}
                                    data={this.state.scheduleParticipants}
                                    className="schedule-table"
                                />
                                <Divider className="neo-std-divider" />
                            </div>}
                        </div>
                        {
                            preRequisiteQuestionObj?.length > 0 && <div>
                                <NeoForm>
                                    <div style={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#242424" }}>
                                        Additional Questions
                                    </div>
                                    <div style={{ margin: "10px" }}>
                                        {preRequisiteQuestionObj?.map((ques, index) => { return this.renderAdditonalQuestions(ques, index + 1) })}
                                    </div>
                                    <Divider className="neo-std-divider" />
                                </NeoForm>
                            </div>
                        }
                        {this.renderAddParticipantModal()}
                    </div>
                    }
                    {this.state.currentStep === 1 && <div>
                        <ScheduleOccurenceDetails
                            form={this.props.form}
                            checkAvailability={this.props.checkAvailability}
                            scheduleDetails={this.state.scheduleDetails}
                            onPanelChange={this.handlePanelChange}
                            handlePurposeSelectChange={this.handlePurposeSelectChange}
                            handleTextBoxChange={this.handleTextBoxChange}
                            handleNotesChange={this.handleNotesChange}
                            handleAccountOwnerEmailChange={this.handleAccountOwnerEmailChange}
                            handleNoOpportunityCheckboxForRecurrence={this.handleNoOpportunityCheckboxForRecurrence}
                            // handleNoAccountOwnerEmailForReccurence={this.handleNoAccountOwnerEmailForReccurence}
                            demo={this.props.demo}
                            handleResourceChange={this.handleResourceChange}
                            neoColumns={neoColumns}
                            renderParticipants={this.renderParticipants}
                            deleteScheduleInstance={this.deleteScheduleInstance}
                            handleStartChange={this.handleStartChange}
                            handlePublishOpenChange={this.handlePublishOpenChange}
                            handleExpiryOpenChange={this.handleExpiryOpenChange}
                            handleEndChange={this.handleEndChange}
                            addNewSchedule={this.addNewSchedule}
                            activePanelKey={this.state.activePanelKey}
                            handleActivePanelChange={this.handleActivePanelChange}
                        /></div>}
                    {this.state.currentStep === 2 && <div>
                        <ScheduleOccurenceConfirmation
                            timezone={this.props.timezone}
                            possibleSchedules={this.props.possibleSchedules}
                            handleActivePanelChange={this.handleActivePanelChange}
                        />
                    </div>}
                </div >
                {this.state.confirmModal && this.showConfirmationModal()}
            </Spin>
        )
    }
}

const mapStateToProps = ({ newSchedule, scheduler, user }) => {
    return {
        userPurposeTypesLite: newSchedule.userPurposeTypesLite,
        verticals: newSchedule.verticals,
        timezone: scheduler.selectedTimezone,
        startTime: scheduler.startTime,
        endTime: scheduler.endTime,
        isAddParticipantsEnabled: user.isAddParticipantsEnabled,
        selectedResource: scheduler.selectedResource,
        isMultipleScheduleBooking: user.profile?.isMultipleScheduleBooking || false,
        user: user.profile,
        currentSchedule: newSchedule?.currentSchedule,
        isPeriodicEnabled: user.isPeriodicEnabled,
        maintenance: newSchedule.maintenance,
        conflictingSchedules: newSchedule.conflictingSchedules,
        possibleSchedules: newSchedule.possibleSchedules,
        dateRangeWithConditions: newSchedule.dateRangeWithConditions,
        checkAvailability: newSchedule.checkAvailability,
        customerInfo: newSchedule?.customerInfo,
        userSettings: user?.configurations
    };
};

export default connect(
    mapStateToProps, { checkScheduleAvailability, clearScheduleFormErrors, closeCurrentOpenTab, postNewDemoSchedule, updateCustomerInfo })(NeoStandardDemoDetailsPage);