import React from 'react';
import { connect, batch } from 'react-redux';
import { Table, Row, Form, Divider, Popconfirm, Button, Icon, Collapse, Checkbox, Col, message, Menu, Dropdown } from 'antd';
import StatusIcon from '../common/StatusIcon';
import { isTabAlreadyOpen } from "../common/TabLayout";
import {
    fetchProviders,
    updateProviderEditingKey,
    postNewProvider,
    addProvider,
    removeEmptyProvider,
    deleteProvider,
    restoreProvider,
    exportTable,
    toggleIsCollapsedStatusDID,
    currentDidsFilter
} from '../../actions';
import { checkProviderDependencies } from '../../actions/dids'
import { ProviderTableCell } from './ProviderTableCell';
import SearchFilter from '../common/SearchFilter';
import StatusFilter from '../common/StatusFilter';
import CONFIG from '../../config';
import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';

export const ManageProvidersContext = React.createContext();

const { Panel } = Collapse;

class ManageProviders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortedInfo: null,
            status: true,
            currentPath: null
        }
    }

    filterSort = {}

    viewDeletedItems = false;
    componentDidMount() {
        const { isEdit, status } = this.props;
        this.setState({ currentPath: window.location.pathname })
        if (isEdit) {
            this.setState({ status: status });
        }
        batch(() => {
            this.props.fetchProviders();
        });

    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentDidsFilter(this.props.isCollapsed?.length > 0 ? this.state.status : true);
        }
        this.props.updateProviderEditingKey();
    }

    isEditing = record => record.id === this.props.editingKey;

    handleCancel = record => {
        this.props.removeEmptyProvider(record);
    };

    handleSave = (providerId, form) => {
        const { selectedDataCenter } = this.props;
        const shouldFetchDIDs = providerId > 0 && selectedDataCenter
        form.validateFields((error, values) => {
            if (error) {
                return;
            }
            const provider = {
                id: providerId,
                title: values.title,
                active: values.active
            }
            this.props.postNewProvider(providerId, provider)
                .then(() => shouldFetchDIDs && this.props.fetchDidOnProviderChange())
        });
    }

    handleAdd = () => {
        const providerId = -1 * (Date.now());
        this.props.addProvider(providerId);
    };

    handleDeleteConfirmation = () => {
        const { selectedDataCenter } = this.props;
        const shouldFetchDIDs = selectedDataCenter && !this.viewDeletedItems
        this.props.deleteProvider(this.providerId, this.viewDeletedItems)
            .then(() => { this.props.fetchProviders(this.viewDeletedItems) })
            .then(() => shouldFetchDIDs && this.props.fetchDidOnProviderChange())
    }


    handleRestoreConfirmation = () => {
        const { selectedDataCenter } = this.props;
        const shouldFetchDIDs = selectedDataCenter && !this.viewDeletedItems
        this.props.restoreProvider(this.providerId)
            .then(() => { this.props.fetchProviders(this.viewDeletedItems) })
            .then(() => shouldFetchDIDs && this.props.fetchDidOnProviderChange())
    }


    renderDeleteRestoreConfirmation = (dependencyList, isRestore) => {
        const { didsCount } = dependencyList;
        const hardDelete = this.viewDeletedItems;
        const okButtonText = isRestore ? "Confirm Restore" : hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
        const text = isRestore ? "Restoration" : "Deletion"

        const showDependencyList = didsCount !== 0;
        const modalTitle = isRestore ? CONFIG.restoreModalTitle.replace('<placeholder>', 'provider') : showDependencyList ? CONFIG.dependencyModalTitle : CONFIG.deleteModalTitle?.replace('<placeholder>', 'provider')
        const content = <>
            {hardDelete && !isRestore ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Provider")}</h4> : !isRestore ? <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Provider")}</h4> : ""}
            {showDependencyList &&
                <>
                    <h4>{text} of selected provider would affect</h4>
                    {didsCount} * DIDs
                </>
            }
        </>
        openConfirm(okButtonText, isRestore ? this.handleRestoreConfirmation : this.handleDeleteConfirmation, null, content, modalTitle);
    }


    handleDeleteRestore = (providerId, isRestore) => {
        this.providerId = providerId;
        const hardDelete = this.viewDeletedItems;

        checkProviderDependencies(providerId, hardDelete)
            .then(dependencyList => this.renderDeleteRestoreConfirmation(dependencyList, isRestore))
            .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
    }


    handleChange = (_, __, sorter) => {
        this.setState({
            sortedInfo: sorter
        });
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    handleFilter = (title, confirm) => {
        title = title?.trim();
        if (!title) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else {
            this.title = title;
            let filteredData = this.getFilteredData(title, this.state.status)
            this.setState({ filteredData })
            confirm();
        }
    }

    handleClear = (clearFilters) => {
        this.title = undefined;
        this.setState({ filteredData: undefined });
        clearFilters();
    }

    handleChangeCheckbox = (e) => {
        this.viewDeletedItems = e.target.checked;
        this.props.fetchProviders(this.viewDeletedItems);
        if (this.viewDeletedItems) this.setState({ status: false })
    }


    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        const exportData = this.getFilteredData(this.title, this.state.status)
        if (exportData?.length > 0 && exportData !== undefined) {
            pushMessage(CONFIG.messageType.loading, "Exporting", 0);
            exportTable(CONFIG.exportTable.providers.name, exportData, timeZoneName, CONFIG.exportTable.providers.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    getFilteredData = (title, status) => {
        const { providers, softDeletedProviders } = this.props;

        let data = this.viewDeletedItems ? softDeletedProviders : providers

        let filteredData = title ?
            data.filter(provider => provider.title.toLowerCase().includes(title.toLowerCase()))
            : data;

        filteredData = status !== undefined && !this.viewDeletedItems
            ? filteredData?.filter(provider => provider.active === status || provider.id < 0)
            : filteredData;

        this.filterSort = {
            title,
            status
        }

        return filteredData
    }

    handleStatusChange = (value) => {

        let filteredData = this.getFilteredData(this.title, value)

        this.setState({ status: value, filteredData })
    }

    render() {
        const { templateType, providerAccess } = this.props;
        let { sortedInfo } = this.state;
        const deleteView = this.viewDeletedItems;
        const editingKey = this.props.editingKey;
        const isEditDisabled = templateType !== CONFIG.roleTypes.superAdmin && providerAccess === CONFIG.roleTemplateAccess.readOnly;
        const isDisabled = deleteView || editingKey !== ""
        const tableData = this.getFilteredData(this.title, this.state.status)

        const components = {
            body: {
                cell: ProviderTableCell
            }
        };
        sortedInfo = sortedInfo || {};

        const tableColumns = [
            {
                title: "Provider Name",
                dataIndex: 'title',
                key: 'title',
                width: '20%',
                align: 'left',
                editable: true,
                sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
                sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
                filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "title")
            },
            {
                title: "Status",
                dataIndex: 'active',
                key: 'active',
                editable: true,
                sortOrder: sortedInfo.columnKey === 'active' && sortedInfo.order,
                width: '15%',
                render: (active) => <StatusIcon status={active} />,
                align: 'center'
            },
            {
                title: "Actions",
                align: "center",
                width: "20%",
                dataIndex: "operation",
                render: (text, record) => {
                    const editable = this.isEditing(record);
                    return editable
                        ? (
                            <span>
                                <ManageProvidersContext.Consumer>
                                    {form => (
                                        <span className="link" onClick={() => this.handleSave(record.id, form)}>
                                            <span class="neo-icon-save" title="Save" style={{ fontSize: "20px" }}></span>
                                        </span>
                                    )}
                                </ManageProvidersContext.Consumer>
                                <Divider type="vertical" />
                                <Popconfirm title="Confirm Cancel?" okText="Yes" cancelText="No" onConfirm={() => this.handleCancel()}>
                                    <span className="link"><span class="neo-icon-close" title="Cancel" style={{ fontSize: "20px" }}></span></span>
                                </Popconfirm>
                            </span>
                        )
                        : (
                            <div>
                                {
                                    !deleteView ?
                                        <>
                                            <button
                                                disabled={isEditDisabled || isDisabled}
                                                onClick={() => this.props.updateProviderEditingKey(record.id)}
                                                className={isDisabled ? "link-disabled" : "link"}
                                            >
                                                <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                            </button>
                                            <Divider type="vertical" />
                                            <button disabled={isEditDisabled || isDisabled} className={!(isEditDisabled || isDisabled) ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id)}>
                                                <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id)}>
                                                <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                            </button>
                                            <Divider type="vertical" />
                                            <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id, true)}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                        </>
                                }
                            </div >
                        );
                }
            }
        ];

        const columns = tableColumns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                })
            };
        });

        const handleMenuClick = (e) => {
            this.handleExport(e.key)
        }

        const menu = (
            <Menu onClick={handleMenuClick}>
                <Menu.Item key={CONFIG.exportFileType.xlsx.ext}>
                    {CONFIG.exportFileType.xlsx.name}
                </Menu.Item>
                <Menu.Item key={CONFIG.exportFileType.csv.ext}>
                    {CONFIG.exportFileType.csv.name}
                </Menu.Item>
            </Menu>
        );

        return (
            <>
                <Collapse
                    style={{ backgroundColor: 'white' }}
                    className="provider-collapse"
                    bordered={false}
                    onChange={(event) => {
                        this.props.toggleIsCollapsedStatusDID(event)
                    }}
                    activeKey={this.props.isCollapsed}
                    expandIconPosition='right'
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                >
                    <Panel
                        header={<span className="large-text">Providers</span>} key="1">
                        {!isEditDisabled && <Col xl={6} xs={24}>
                            <Button className="primary-action-button-filled" disabled={isDisabled} onClick={this.handleAdd}>New<Icon type="plus-circle" /></Button>
                        </Col>}
                        <Col xxl={18} xl={18} lg={24} s={18} xs={1} className="right-align">
                            <Dropdown overlay={menu}>
                                <Button className="primary-action-button-filled left-spacing-3">
                                    Export <Icon type="down" />
                                </Button>
                            </Dropdown>
                        </Col>

                        <br />
                        <Row className="vertical-spacing">
                            <Col xl={6} sm={6} xs={24}>
                                <StatusFilter disabled={this.viewDeletedItems} onChange={this.handleStatusChange} value={this.state.status} />
                            </Col>
                        </Row>
                        <Checkbox
                            onChange={this.handleChangeCheckbox}
                            disabled={editingKey !== ""}>
                            View Deleted Providers
                        </Checkbox>
                        <br />
                        <br />
                        <Row>
                            <h3>{this.viewDeletedItems ? 'List of Deleted Providers' : this.state.status ? 'List of Active Providers' : this.state.status === false ? 'List of Inactive Providers' : 'List of All Providers'}</h3>

                        </Row>
                        <ManageProvidersContext.Provider value={this.props.form}>
                            <Table
                                size='middle'
                                rowKey={(record) => record.id}
                                className="responsive-container"
                                components={components}
                                bordered
                                dataSource={tableData}
                                columns={columns}
                                onChange={this.handleChange}
                                pagination={false}
                            />
                        </ManageProvidersContext.Provider>
                    </Panel>
                </Collapse>
            </>
        );
    }
}

const mapStateToProps = ({ providers, user, tabsLayout }) => {
    return {
        providers: providers.data,
        editingKey: providers.editingKey,
        softDeletedProviders: providers.deletedData,
        templateType: user.profile.roleTemplate.templateType,
        providerAccess: user.profile.didsAccess,
        isCollapsed: providers.isCollapsed,
        openedTabs: tabsLayout.panes,
        isEdit: providers.isEdit,
        currentFilter: providers.currentFilter,
        status: providers.status
    }
}

const Providers = Form.create()(ManageProviders);

export default connect(
    mapStateToProps,
    {
        fetchProviders,
        updateProviderEditingKey,
        postNewProvider,
        addProvider,
        removeEmptyProvider,
        deleteProvider,
        restoreProvider,
        exportTable,
        toggleIsCollapsedStatusDID,
        currentDidsFilter
    }
)(Providers);
