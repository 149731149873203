import { aepDemo } from "../apis";
import {
    FETCH_ACTIVE_RESOURCES,
    ADD_DEMO_RESOURCES,
    ADD_NEW_DEMO_RESOURCE,
    DELETE_DEMO_RESOURCE,
    EDIT_DEMO_RESOURCE
} from './types';

export const fetchActiveResources = () => async dispatch => {
    const response = await aepDemo.get("/demoSolutionService/active/resources");
    dispatch({
        type: FETCH_ACTIVE_RESOURCES,
        payload: response.data
    });
};

export const addDemoTabResources = (demoId, resources) => {
    return {
        type: ADD_DEMO_RESOURCES,
        payload: resources
    }
}

export const addNewDemoResource = (resource, resourceUserGroup) => {
    return {
        type: ADD_NEW_DEMO_RESOURCE,
        payload: { resource, resourceUserGroup }
    };
};

export const deleteDemoResource = resourceId => {
    return {
        type: DELETE_DEMO_RESOURCE,
        payload: resourceId
    };
};

export const editDemoResource = resource => {
    return {
        type: EDIT_DEMO_RESOURCE,
        payload: resource
    }
}
