import React from 'react';
import { Form, Row, Col, Spin, Checkbox, Radio, Popover } from 'antd';
import { Form as NeoForm, Icon, Button, Switch } from "@avaya/neo-react";
import "neo-latest/avaya-neo-react.css"
import { Select, SelectOption } from "neo-latest";
import CONFIG from '../../config';
import { generateFinanicalYearQuarters } from '../../utils/datepicker';
import { connect } from 'react-redux';
import pushMessage from '../../components/common/PushMessage';
import CustomNeoSelect from '../common/CustomNeoSelect';
import {
    getAllDemosLite,
    getAllSolutionTypeLite,
    getAllDemoPurposesLite,
    fetchAllCountries
} from "../../actions";
import { LoadingOutlined } from '@ant-design/icons';
import { toTitleCase } from '../../utils/strings';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class RefineReportNeo extends React.Component {

    state = {
        loading: true,
        solutionsList: [],
        demosList: [],
        demoPurposes: [],
        countries: []
    }

    handleYearChange = (value) => {
        const yearValue = value?.map(Number);
        const { setFieldsValue, getFieldValue } = this.props.form;
        const financialYears = getFieldValue("quarters")?.filter(item => item !== '') || [];
        if (yearValue && yearValue?.length > 2) {
            pushMessage(CONFIG.messageType.warning, "Selecting more than 2 years are not allowed");
            value.pop();
            setFieldsValue({ year: value })
            this.forceUpdate()
        }
        const filteredFinancialYears = (financialYears?.length > 0 && yearValue?.length > 0) ? financialYears?.filter(fy => yearValue?.includes(parseInt(fy.match(/\d+/)[0]))) : [];
        setFieldsValue({ quarters: filteredFinancialYears })
    }

    getInitialValueForReport = (key) => {
        const { isRunByUser, currentReport } = this.props;
        const currentReportData = isRunByUser === "fromUser" ? currentReport : this.getCurrentReportData();
        const filterSort = currentReportData?.filterSortObject?.filter || {};
        const value = filterSort ? (filterSort[key]?.oprVal?.length > 0 || (typeof filterSort[key]?.oprVal === "boolean")) ? filterSort[key]?.oprVal : undefined : undefined;
        return (key === "type" || key === "isTotalRequired") ? key === "isTotalRequired" ? (value || false) : value : value?.split("\\\\");
    }

    getCurrentReportData = (isAll = false) => {
        const { reportTabDetails, isCloneReport, isGeneralReport } = this.props;
        const { reportId } = this.props.params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        if (isAll) return reportTabDetails?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))
        return reportTabDetails?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))?.details;
    }

    async componentDidMount() {
        const { isRunByUser, currentReport } = this.props;
        const { setFieldsValue } = this.props.form;
        const currentReportData = isRunByUser === "fromUser" ? currentReport : this.getCurrentReportData();
        const demoPurposes = currentReportData?.reportKey === CONFIG.standardReportKeys.userDistribution ? [] : await this.props.getAllDemoPurposesLite(false);
        await this.setState({ demoPurposes, loading: true });
        switch (true) {
            case currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage:
                ["year", "quarters", "users", "regions", "demoPurposes"].forEach((item) => {
                    const value = this.getInitialValueForReport(item);
                    setFieldsValue({ item: value });
                })
                this.forceUpdate();
                break;

            case currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsageSolutionDemo:
            case currentReportData?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend:
                const demosList = await this.props.getAllDemosLite();
                const solutionsList = await this.props.getAllSolutionTypeLite();
                ["year", "quarters", "users", "type", "demoPurposes", "reportType", "isTotalRequired"].forEach(async (item) => {
                    const value = this.getInitialValueForReport(item);
                    await setFieldsValue({ item: value });
                });
                const typeData = this.getInitialValueForReport("type");
                this.setState({
                    demosList, solutionsList
                })
                this.setState({ type: typeData }, () => {
                    setFieldsValue({ [typeData]: this.getInitialValueForReport(typeData) })
                });
                this.forceUpdate();
                break;
            case currentReportData?.reportKey === CONFIG.standardReportKeys.userDistribution:
                const countries = await this.props.fetchAllCountries();
                ["users", "type"].forEach(async (item) => {
                    const value = this.getInitialValueForReport(item);
                    await setFieldsValue({ item: value });
                });
                this.setState({ countries });
                setFieldsValue({ [this.getInitialValueForReport("type")]: this.getInitialValueForReport(this.getInitialValueForReport("type")) })
                this.forceUpdate();
                break;

            default: break;
        }
        setTimeout(async () => {
            await this.setState({ loading: false });
        }, 2500)
    }

    handleTypeofDemoChange = (event) => {
        const { setFieldsValue } = this.props.form;
        setFieldsValue({ [event.target.value]: [] });
    }

    renderOptions = () => {
        const type = this.props.form.getFieldValue("type")
        return (type === "demos" ? this.state.demosList : this.state.solutionsList)?.map((item) => {
            return <SelectOption key={item?.id} value={item?.title}>{item?.title}</SelectOption>
        })
    }

    renderDemoPurposeOptions = () => {
        return this.state.demoPurposes?.map((item) => {
            return <SelectOption key={item?.id} value={item?.purpose}>{item?.purpose}</SelectOption>
        })
    }

    clearSelection = () => {
        const { setFieldsValue, getFieldValue } = this.props.form;
        setFieldsValue({ [getFieldValue("type")]: [], type: undefined, users: [] });
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { isRunByUser, currentReport } = this.props;
        const { regions } = this.props;
        const currentReportData = isRunByUser === "fromUser" ? currentReport : this.getCurrentReportData();
        const sortedRegions = regions?.sort((a, b) => a?.name?.localeCompare(b?.name));
        const quarterOptions = generateFinanicalYearQuarters(getFieldValue("year") ?? currentReportData?.filterSortObject?.filter?.year?.oprVal?.split("\\\\"));
        const initialValueForQuarters = this.getInitialValueForReport("quarters");
        const type = getFieldValue("type")
        const infoContent = (currentReportData?.reportKey !== CONFIG.standardReportKeys.userDistribution ? <div style={{ color: "#242424" }}>
            <p>Q1: October to December, Q2: January to March, Q3: April to June, Q4: July to September.</p>
            <p>The displayed data excludes the test user's schedules.</p>
        </div> : <div style={{ color: "#242424" }}>
            <p>If no selections are made, default report will be generated.</p>
        </div>);
        return (
            <div style={{ marginTop: "24px" }}>
                <Spin indicator={antIcon} spinning={this.state.loading} wrapperClassName="spin-overlay">
                    <div className='refine-report-border'>
                        <div style={{ fontSize: "16px", fontWeight: "400", color: "#242424" }}>
                            Refine Report
                            <div className='float-right'>
                                <Popover content={infoContent}
                                    title={<span style={{ fontFamily: "Noto-sans", fontSize: "14px", textAlign: "center", color: '#242424' }}>Information</span>}
                                    placement="topLeft" >
                                    <Icon style={{ fontSize: "20px" }} className="text-color-primary" icon="info-outline" />
                                </Popover>
                            </div>
                        </div>
                        <div className='refine-report-filter'>
                            <Form>
                                {currentReportData?.reportKey !== CONFIG.standardReportKeys.userDistribution ? <NeoForm>
                                    <Row gutter={12}>
                                        <Col xl={currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? 6 : 8} sm={6} xs={24} >
                                            <Form.Item>
                                                {getFieldDecorator("year", {
                                                    initialValue: this.getInitialValueForReport("year"),
                                                    rules: [
                                                        { required: true, message: 'Please select the year', type: 'array' },
                                                    ],
                                                })
                                                    (<Select onChange={this.handleYearChange} multiple label="Year" required>
                                                        {CONFIG.yearArray?.map((item, index) => <SelectOption key={index} value={`${item}`} >{`${item}`}</SelectOption>)}
                                                    </Select>)
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col xl={currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? 6 : 8} sm={6} xs={24} >
                                            <Form.Item>
                                                {getFieldDecorator("quarters", {
                                                    initialValue: initialValueForQuarters,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please select the quarter'
                                                        }
                                                    ],
                                                })
                                                    (
                                                        <Select multiple label="Quarters" required>
                                                            {quarterOptions?.map((item, index) => <SelectOption value={item?.value} key={index}>{item?.value}</SelectOption>)}
                                                        </Select>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        {currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage && <Col xl={currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? 6 : 8} sm={6} xs={24} >
                                            <Form.Item>
                                                {getFieldDecorator("regions", {
                                                    initialValue: this.getInitialValueForReport("regions"),
                                                })
                                                    (
                                                        <Select searchable={false} multiple label="Regions">
                                                            {sortedRegions?.map((item, index) => <SelectOption key={index} value={item?.name}>{item?.name}</SelectOption>)}
                                                        </Select>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>}
                                        <Col xl={currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? 6 : 8} sm={6} xs={24} >
                                            <Form.Item>
                                                {getFieldDecorator("users", {
                                                    initialValue: this.getInitialValueForReport("users"),
                                                })
                                                    (
                                                        <Select searchable={false} multiple label="User Type">
                                                            {CONFIG.userTypes?.map((item, index) => <SelectOption value={item?.value} key={index}>{item?.key}</SelectOption>)}
                                                        </Select>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: "16px" }} gutter={12}>
                                        {(currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsageSolutionDemo || currentReportData?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend) && <>
                                            <Col xl={8} sm={6} xs={24} >
                                                <Form.Item>
                                                    {getFieldDecorator("type", {
                                                        initialValue: this.getInitialValueForReport("type"),
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Please select the type'
                                                            }
                                                        ],
                                                    })
                                                        (
                                                            <Radio.Group onChange={this.handleTypeofDemoChange} >
                                                                <Radio style={{ color: "#242424" }} value="demos">Demos </Radio>
                                                                <Radio style={{ color: "#242424" }} value="solutionTypes">Solution Types </Radio>
                                                            </Radio.Group>
                                                        )
                                                    }
                                                </Form.Item>
                                            </Col>
                                            <Col xl={8} sm={6} xs={24} >
                                                <Form.Item>
                                                    {getFieldDecorator(`${getFieldValue("type")}`, {
                                                        initialValue: this.getInitialValueForReport(`${getFieldValue("type")}`),
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Please select the items'
                                                            }
                                                        ],
                                                    })
                                                        (
                                                            <CustomNeoSelect value={getFieldValue(`${getFieldValue("type")}`)}
                                                                allData={(type === "demos" ? this.state.demosList : this.state.solutionsList)?.map(item => item?.title)}
                                                                form={this.props.form}
                                                                formKey={`${getFieldValue("type")}`}
                                                                searchable={true}
                                                                disabled={!type}
                                                                label={type === "demos" ? "Demos" : "Solution Types"}
                                                                multiple={true} required={true} >
                                                                {this.renderOptions()}
                                                            </CustomNeoSelect>
                                                        )
                                                    }
                                                </Form.Item>
                                            </Col>
                                        </>}
                                        <Col xl={currentReportData?.reportKey === CONFIG.standardReportKeys.demoUsage ? 6 : 8} sm={6} xs={24} >
                                            <Form.Item>
                                                {getFieldDecorator("demoPurposes", {
                                                    initialValue: this.getInitialValueForReport("demoPurposes")
                                                })
                                                    (
                                                        <Select searchable={true} multiple label="Demo Purpose" >
                                                            {this.renderDemoPurposeOptions()}
                                                        </Select>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col xl={8} sm={6} xs={24} >
                                            {currentReportData?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend && <Form.Item>
                                                {getFieldDecorator("reportType", {
                                                    initialValue: this.getInitialValueForReport("reportType"),
                                                })
                                                    (
                                                        <Checkbox.Group className='font'>
                                                            <Checkbox className='remove-margin-checkbox' style={{ color: "black" }} key={"count"} label={"count"} value={"count"}> Display in Count</Checkbox>
                                                            <Checkbox style={{ color: "black" }} key={"percentage"} label={"percentage"} value={"percentage"}>Display in Percentage</Checkbox>
                                                        </Checkbox.Group>
                                                    )
                                                }
                                            </Form.Item>}
                                        </Col>
                                    </Row>
                                </NeoForm>
                                    :
                                    <NeoForm>
                                        <Row gutter={24}>
                                            <Col xl={8} sm={6} xs={24} >
                                                <Form.Item>
                                                    {getFieldDecorator("users", {
                                                        initialValue: this.getInitialValueForReport("users"),
                                                    })
                                                        (
                                                            <Select searchable={false} multiple label="User Type">
                                                                {CONFIG.userTypes?.map((item, index) => <SelectOption value={item?.value} key={index}>{item?.key}</SelectOption>)}
                                                            </Select>
                                                        )
                                                    }
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ display: "flex", justifyContent: "center", marginTop: "18px" }} xl={6} sm={6} xs={24} >
                                                <Form.Item>
                                                    {getFieldDecorator("type", {
                                                        initialValue: this.getInitialValueForReport("type"),
                                                    })
                                                        (
                                                            <Radio.Group onChange={this.handleTypeofDemoChange} inline={false} >
                                                                <Radio style={{ color: "#242424" }} value="regions">Regions </Radio>
                                                                <Radio style={{ color: "#242424" }} value="countries">Countries</Radio>
                                                            </Radio.Group>
                                                        )
                                                    }
                                                </Form.Item>
                                            </Col>
                                            <Col xl={8} sm={6} xs={24} >
                                                <Form.Item>
                                                    {getFieldDecorator(`${getFieldValue("type")}`, {
                                                        initialValue: this.getInitialValueForReport(`${getFieldValue("type")}`),
                                                    })
                                                        (
                                                            <CustomNeoSelect value={getFieldValue(`${getFieldValue("type")}`)}
                                                                allData={(getFieldValue("type") === "regions" ? sortedRegions : this.state.countries)?.map(item => item?.name)}
                                                                form={this.props.form}
                                                                formKey={`${getFieldValue("type")}`}
                                                                searchable={true}
                                                                disabled={!!!getFieldValue("type")}
                                                                label={getFieldValue("type") ? toTitleCase(getFieldValue("type")) : "Regions"}
                                                                multiple={true}
                                                                required={false} >
                                                                {(getFieldValue("type") === "regions" ? sortedRegions : this.state.countries)?.map((item, index) => <SelectOption key={index} value={item?.name}>{item?.name}</SelectOption>)}
                                                            </CustomNeoSelect>
                                                        )
                                                    }
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ height: "57px", display: 'flex', alignItems: "center", justifyContent: 'center' }}
                                                xl={2} sm={6} xs={24}>
                                                <Button icon="clean" style={{ marginTop: "21px" }}
                                                    onClick={(event) => { event.preventDefault(); this.clearSelection() }} size="compact" variant='secondary'>Clear</Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "16px" }} >
                                            <Col>
                                                <Form.Item>
                                                    {getFieldDecorator("isTotalRequired", {
                                                        initialValue: this.getInitialValueForReport("isTotalRequired") || false,
                                                    })
                                                        (
                                                            <Switch value={this.props.form.getFieldValue("isTotalRequired") || false} defaultChecked={this.props.form.getFieldValue("isTotalRequired") || false} className="status-switch-neo" >
                                                                Display Total Users
                                                            </Switch>
                                                        )
                                                    }
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </NeoForm>
                                }
                            </Form>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const RefineReportNeoRef = Form.create()(RefineReportNeo);

const mapStateToProps = ({ user, currentReport }) => {
    return {
        timeZoneName: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        user: user.profile,
        regions: user.regions,
        reportTabDetails: currentReport.reportDetails,
    };
}

export default connect(mapStateToProps, {
    getAllDemosLite,
    getAllSolutionTypeLite,
    getAllDemoPurposesLite,
    fetchAllCountries
})(RefineReportNeoRef);