import React, { useEffect, useReducer } from 'react';
import { Button as NeoButton, } from "neo-latest"
import {
    Eventcalendar, momentTimezone,
    CalendarNext,
    CalendarPrev,
    CalendarToday,
} from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { getSchedulesForDuration } from "../../../actions";
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import CONFIG from '../../../config';
import { getSchedulerView, computeTime } from "../../../utils/scheduler"
import { Modal, Spin, Select, DatePicker, Icon } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { MonthPicker } = DatePicker;
const { Option } = Select;
momentTimezone.moment = moment;
function SchedulingAssistant(props) {
    const { demo, startTime, visible, timezone, className } = props;
    const dispatch = useDispatch();
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            isVisible: visible ?? false,
            loading: true,
            resources: [],
            events: [],
            view: {
                timeline: {
                    type: 'day',
                    timeCellStep: 30,
                    timeLabelStep: 60,
                }
            },
            viewType: 'day',
            calendarDate: props.className === "cloud" ? props.startTime : moment(moment(moment()).tz(timezone).format("YYYY-MM-DD HH:mm:ss")).toDate(),
        }
    )
    const handleCloseModal = () => {
        setState({ isVisible: false })
        props.handleCloseSchedulingAssistant();
    }

    useEffect(() => {
        const fetchData = async () => {
            const start = moment(startTime).tz(timezone, true).startOf('day').toISOString();
            const end = moment(startTime).tz(timezone, true).endOf('day').toISOString();
            const data = await dispatch(getSchedulesForDuration(demo?.id, start, end, demo?.demoType));
            getEventsAndResources(data);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEventsAndResources = (data) => {
        const demoType = demo?.demoType;
        const resources = demoType === CONFIG.demoTypes.standardDemo ? data?.map((item) => {
            return {
                id: item?.id, isActive: item?.isResourceActive, color: '#01adff',
                name: <span style={{ fontWeight: '400', fontFamily: 'Noto Sans', fontSize: "14px" }} title={item?.name}> {item?.name + " "}
                    {!item?.isResourceActive && <span title="Not available"> <Icon type="close-circle" className="error-text" /></span>}
                </span>
            }
        }) : [];
        const events = demoType === CONFIG.demoTypes.standardDemo ? prepareDataForCalendar(data) : data?.map((schedule) => {
            return {
                title: !schedule.isMaintenance ? schedule.createdBy.fullName : `${CONFIG.demoScheduler.eventText.maintenance} ( ${schedule?.title} )`,
                start: moment(moment(schedule.startTime).format("YYYY-MM-DD HH:mm:ss")).toISOString(),
                end: moment(moment(schedule.endTime).format("YYYY-MM-DD HH:mm:ss")).toISOString(),
                color: schedule.isMaintenance ? CONFIG.colors.maintenance : CONFIG.colors.userDemo,
                allDay: false,
            }
        });
        setState({
            resources: resources, events: events, loading: false
        })
    }

    const prepareDataForCalendar = (data) => {
        const result = [];
        if (data?.length > 0) {
            data?.forEach(resource => {
                resource?.schedulesAndMaintenances?.forEach(schedule => {
                    result.push({
                        title: !schedule.isMaintenance ? schedule.createdBy.fullName : `${CONFIG.demoScheduler.eventText.maintenance} ( ${schedule?.title} )`,
                        start: moment(moment(schedule.startTime).format("YYYY-MM-DD HH:mm:ss")).toISOString(),
                        end: moment(moment(schedule.endTime).format("YYYY-MM-DD HH:mm:ss")).toISOString(),
                        color: schedule.isMaintenance ? CONFIG.colors.maintenance : CONFIG.colors.userDemo,
                        resource: resource?.id,
                        allDay: false,
                    });
                })
            })
        }
        return result;
    }

    const changeView = (value) => {
        const calView = getSchedulerView(value);
        const [start, end] = computeTime(value, null, state.calendarDate, timezone);
        setState({ view: calView, viewType: value, calendarDate: moment(start).toDate(), loading: true })
        fetchDataForEvents(start, end);
    }

    const renderViewOptions = () => {
        return CONFIG.schedulerViews?.map((view) => {
            return <Option key={view.value} value={view.value}>{view.text}</Option>
        })
    }

    const handleDateChange = async (value) => {
        if (value === null) return;
        const { viewType } = state;
        const [start, end] = computeTime(viewType, value, state.calendarDate, timezone)
        setState({
            calendarDate: moment(value).toDate(),
            loading: true
        })
        fetchDataForEvents(start, end)
    }

    const onSelectedDateChange = async (event, inst) => {
        const { viewType } = state;
        const [start, end] = computeTime(viewType, moment(event?.date), state.calendarDate, timezone)
        setState({
            calendarDate: moment(event?.date).toDate(),
        })
        fetchDataForEvents(start, end)
    }

    const fetchDataForEvents = async (start, end) => {
        setState({ loading: true })
        const data = await dispatch(getSchedulesForDuration(demo?.id, start, end, demo?.demoType));
        getEventsAndResources(data);
    }

    const customWithNavButtons = () => {
        return (<div className="mobiscroll-header-dropdown">
            {state.viewType !== "month" ? <div>
                <DatePicker
                    onChange={handleDateChange}
                    allowClear={false}
                    value={moment(state.calendarDate)}
                    format="MM-DD-YYYY"
                />
            </div>
                :
                <MonthPicker
                    onChange={handleDateChange}
                    value={moment(state.calendarDate)}
                    allowClear={false}
                    format="MMMM-YYYY"
                    className="cal-header-nav" />
            }

            <div className="md-event-listing-picker ">
                <span style={{ fontSize: "14px" }}>View &nbsp;</span>
                <Select value={state.viewType} style={{ width: 120 }} onChange={changeView}>
                    {renderViewOptions()}
                </Select>
            </div>
            <div style={{ float: "right" }} className="md-custom-range-view-controls">
                <span style={{ width: "50px" }}><CalendarPrev className="calendar-prev" /></span>
                <span className='calendar-today' ><CalendarToday /></span>
                <CalendarNext className='calendar-next' />
            </div>
        </div >)
    }

    return (
        <Modal
            visible={state.isVisible}
            onCancel={() => handleCloseModal()}
            title={"Calendar View"}
            bodyStyle={className === "std" ? { maxHeight: `${document.body.clientHeight - 120}px`, overflowY: "auto" } : {}}
            className={`${className}-calendar-modal`}
            footer={<NeoButton onClick={handleCloseModal}>Close</NeoButton>}
        >
            <Spin spinning={state.loading} indicator={antIcon}>
                <Eventcalendar
                    dataTimezone="utc"
                    displayTimezone={timezone}
                    timezonePlugin={momentTimezone}
                    selectedDate={state.calendarDate}
                    dateFormatLong="MMMM D, YYYY"
                    theme="ios"
                    themeVariant="light"
                    clickToCreate={false}
                    dragToCreate={false}
                    dragToMove={false}
                    dragToResize={false}
                    view={state.view}
                    data={state.events}
                    resources={state.resources}
                    dragTimeStep={15}
                    onSelectedDateChange={onSelectedDateChange}
                    renderHeader={customWithNavButtons}
                    cssClass="md-event-listing md-resource-details"
                />
            </Spin>
        </Modal>
    )
}

export default SchedulingAssistant;