import React from 'react';
import { TextInput, Form as NeoForm, Button, Switch, TextArea } from "@avaya/neo-react";
import { Col, Form, Row } from "antd";
import { connect } from 'react-redux';
import CONFIG from '../../config';
import {
    saveReportRoleTemplates,
    cancelReportRoleTemplates,
    toggleIsFetchingReportForm,
    fetchReportColumns,
    toggleIsFetchingReportFlag,
    clearReportOnEntityChange
} from '../../actions';
import ReportRoleTemplates from '../ManageReports/ReportRoleTemplates';

class ReportBasicDetailsNeo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
    }

    getCurrentReportData = (isAll = false) => {
        const { reportTabDetails, isCloneReport, isGeneralReport } = this.props;
        const { reportId } = this.props.params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        if (isAll) return reportTabDetails?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))
        return reportTabDetails?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))?.details;
    }

    getAssignedRoleTemplates = (isSuperAdminUser) => {
        const currentReportData = this.getCurrentReportData();
        const { roleTemplates } = currentReportData || [];
        let assignedRoleTemplates = [];
        if (roleTemplates) {
            roleTemplates.forEach(obj => {
                const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            })
        }
        return assignedRoleTemplates;
    }

    handleRoleTemplatesCancel = e => {
        const { isGeneralReport, isCloneReport, params } = this.props;
        const { reportId } = params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        this.props.cancelReportRoleTemplates(id, isCloneReport, isGeneralReport);
        this.setState({
            visible: false,
        });
    };

    handleRoleTemplatesAssign = () => {
        const { isGeneralReport, isCloneReport, params } = this.props;
        const { reportId } = params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        this.props.saveReportRoleTemplates(id, isCloneReport, isGeneralReport);
        this.setState({ visible: false });
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { roleTemplates, user, isGeneralReport, isCloneReport, params } = this.props;
        const { fetchFromAPI } = this.getCurrentReportData(true) || false;
        const { reportId } = params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
        const currentReportData = this.getCurrentReportData();
        const switchValue = getFieldValue("isActive");
        return (
            <Form id="basicDetails-neo" style={{ marginTop: "8px" }}>
                <NeoForm>
                    <Row gutter={16}>
                        <Col xl={8} sm={10} xs={24}>
                            <Form.Item>
                                {getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'please input the name of the report'
                                        }
                                    ],
                                    initialValue: (isCloneReport && fetchFromAPI) ? currentReportData?.name + '_clone' : currentReportData?.name
                                })(<TextInput
                                    label="Report Name"
                                    required={true}
                                    clearable={false}
                                />)}
                            </Form.Item>
                        </Col>
                        <Col xl={7} sm={8} xs={24} style={{ paddingRight: "16px" }}>
                            <Form.Item>
                                {getFieldDecorator("roletemplate", {
                                    initialValue: assignedRoleTemplates?.length !== 0
                                        ? assignedRoleTemplates
                                        : "None"
                                })(<TextInput clearable={false} disabled={isGeneralReport}
                                    label="Assign Role Template"
                                />)}
                                <ReportRoleTemplates
                                    visible={this.state.visible}
                                    handleCancel={this.handleRoleTemplatesCancel}
                                    handleAssign={this.handleRoleTemplatesAssign}
                                    assignedRoleTemplates={currentReportData?.roleTemplates}
                                    allRoleTemplates={roleTemplates?.all}
                                    isClone={isCloneReport}
                                    isGeneralReport={isGeneralReport}
                                    allRoleTemplatesAdminAccess={currentReportData?.allRTAdminAccess}
                                    allRoleTemplatesUserAccess={currentReportData?.allRTUserAccess}
                                    recordId={id}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={2} sm={2} xs={24} style={{ marginTop: "20px", paddingLeft: "0px" }}>
                            <Button
                                variant="primary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.showModal()
                                }}
                            >
                                Assign
                            </Button>
                        </Col>
                        <Col xl={5} sm={2} xs={24}>
                            <TextInput
                                label="Report Type"
                                clearable={false}
                                disabled={true}
                                value={CONFIG.standardReportTypes[currentReportData?.reportKey]}
                                title={CONFIG.standardReportTypes[currentReportData?.reportKey]}
                            />
                        </Col>
                        <Col xl={2} sm={3} xs={24}>
                            <Form.Item colon={false} label={<span style={{ fontSize: "12px", color: "#323232" }}>Status</span>}>
                                {getFieldDecorator("isActive", {
                                    valuePropName: "checked",
                                    initialValue: currentReportData?.isActive
                                })(<Switch className="status-switch-neo" >{switchValue ? "Active" : "Inactive"}</Switch>)}

                            </Form.Item>
                        </Col>
                    </Row>
                    <div>
                        <Row style={{ marginLeft: "0px" }} gutter={48}>
                            <Form.Item className='neo-text-area' >
                                {getFieldDecorator("description", {
                                    initialValue: currentReportData?.description
                                })
                                    (<TextArea rows={3} label="Report Description" />)
                                }
                            </Form.Item>
                        </Row>
                    </div>
                </NeoForm>
            </Form>
        )
    }
}

const ReportBasicDetailsNeoRef = Form.create()(ReportBasicDetailsNeo);

const mapStateToProps = ({ roleTemplates, user, currentReport }) => {
    return {
        timeZoneName: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        user: user.profile,
        reportColumns: currentReport?.reportColumns,
        entityTypes: currentReport?.entityTypes,
        reportFormFetching: currentReport.isFetchingReportForm,
        roleTemplates,
        reportTabDetails: currentReport.reportDetails,
    };
}

export default connect(mapStateToProps,
    {
        saveReportRoleTemplates,
        cancelReportRoleTemplates,
        toggleIsFetchingReportForm,
        fetchReportColumns,
        toggleIsFetchingReportFlag,
        clearReportOnEntityChange
    })(ReportBasicDetailsNeoRef);