import React from 'react';
import { connect, batch } from 'react-redux';
import { Divider, Select, Row, Col, Tooltip, message, Checkbox, Table, Collapse, Radio, Dropdown } from 'antd'
import moment from 'moment-timezone';
import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';
import MaintenanceFormContainer from './MaintenanceFormContainer';
import { Icon as NeoIcon } from "@avaya/neo-react";
import {
  fetchDemos,
  fetchMaintenanceSchedule,
  toggleIsFetchingMaintenanceSchedule,
  deleteMaintenanceSchedule,
  updateFilterSort,
  clearMaintenanceSchedules,
  exportTable,
  fetchResources,
  fetchDataCenters,
  currentMaintenanceFilter,
  handleOnClickAction,
  toggleSaveReportVisible
} from '../../actions';
import CONFIG from '../../config';
import FilterPanel from '../common/FilterPanel';
import StatusFilter from '../common/StatusFilter';
import InactiveMaintenanceTable from "./InactiveMaintenanceTable"
import ActiveMaintenanceTable from "./ActiveMaintenanceTable"
import { getAllMaintenanceSchedules } from '../../actions/maintenanceSchedule';
import { fetchNamesBySearchString } from '../../actions/userRole'
import { getMaintenanceSchedulesByGroupID } from '../../actions/maintenanceSchedule'
import ScheduleMaintenanceCalendar from './ScheduleMaintenanceCalendar';
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
import SaveReport from "../common/SaveReport";

const { Option } = Select;
const { Panel } = Collapse;

const dataIndexMap = {
  demo: "maintenanceRelations.demo.title",
  demoDC: "maintenanceRelations.dataCenter.title",
  resource: "maintenanceRelations.resource.name",
  description: "description",
  startTime: "startTime",
  endTime: "endTime",
  applyToAllCheck: 'includeStartAndEndDate',
  viewDeletedItems: 'viewDeletedItems',
  title: "title",
  createdBy: 'createdBy.fullName',
  updatedBy: 'updatedBy.fullName',
}


class ScheduleMaintenanceTable extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      activeMaintenanceData: [],
      inactiveMaintenanceData: [],
      activeLoading: false,
      inactiveLoading: false,
      objectActiveMaintenanceCount: undefined,
      objectInactiveMaintenanceCount: undefined,
      previouslySelectedStatus: true,
      matchedUsersNames: [],
      deleteAll: false,
      viewType: CONFIG.viewType.TABLE,
      showFilter: false,
      isCalendarDeleted: this.generateUniqueRandomNumber(),
      calendarDate: moment(moment(moment()).format("YYYY-MM-DD HH:mm:ss")).toDate(),
      calendarViewType: "month"
    }
  }

  filter = undefined;
  filterSave = {};
  activeFilterSort = {};
  inactiveFilterSort = {};
  status = true;

  activeMaintenanceCurrentPage = 1
  inactiveMaintenanceCurrentPage = 1

  tableRef = React.createRef();

  generateUniqueRandomNumber = () => {
    const uniqueId = Date.now();
    const randomNumber = Math.floor(Math.random() * 1000);
    return `${uniqueId}-${randomNumber}`;
  }

  clearCurrentActiveMaintenance = async () => {
    this.activeMaintenanceCurrentPage = 1
    await this.setState(
      {
        activeMaintenanceData: [],
        matchedUsersNames: []
      }
    )
  }

  clearCurrentInactiveMaintenance = async () => {
    this.inactiveMaintenanceCurrentPage = 1
    await this.setState(
      {
        inactiveMaintenanceData: [],
        matchedUsersNames: []
      }
    )
  }


  componentWillUnmount() {
    if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {

      // This will ensure that we do not increment the pageNumber on Tab Switch.
      const nextActivePageNumber = this.activeMaintenanceCurrentPage > 2 ? this.activeMaintenanceCurrentPage - 1 : this.activeMaintenanceCurrentPage
      const nextInactivePageNumber = this.inactiveMaintenanceCurrentPage > 2 ? this.inactiveMaintenanceCurrentPage - 1 : this.inactiveMaintenanceCurrentPage
      this.clearCurrentActiveMaintenance();
      this.clearCurrentInactiveMaintenance();
      this.props.currentMaintenanceFilter(this.filter, this.activeFilterSort, this.inactiveFilterSort, this.status, nextActivePageNumber, nextInactivePageNumber, (nextActivePageNumber * 12), (nextInactivePageNumber * 12), this.state.viewType, this.state.calendarDate, this.state.calendarViewType);
    } else {
      this.props.clearMaintenanceSchedules();
    }
  }

  fetchUsersName = searchString => {
    this.setState({ matchedUsersNames: [] });
    fetchNamesBySearchString({ searchString })
      .then((result) => {
        if (result?.length !== 0) {
          delete result.id
          this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
        }
        this.setState({ hasEnteredString: false })
      });
  };

  componentDidUpdate() {
    if (this.state.previouslySelectedStatus !== this.status) {
      this.setState({ previouslySelectedStatus: this.status })
    }
  }

  removeActiveDuplicateRecordsIfAny = () => {
    let activeMaintenanceData = this.state.activeMaintenanceData
    activeMaintenanceData = this.props.activeSchedules ? activeMaintenanceData.concat(this.props.activeSchedules) : []
    return activeMaintenanceData?.filter((value, index) => activeMaintenanceData.indexOf(value) === index)
  }


  handleFetchActiveMaintenances = async () => {
    const countOfActiveRecordsFetched = this.state.activeMaintenanceData?.length
    const totalActiveMaintenances = this.props.activeCount
    if ((totalActiveMaintenances === undefined && countOfActiveRecordsFetched === undefined) || !(totalActiveMaintenances <= countOfActiveRecordsFetched)) {
      this.setState({ loading: true });
      await this.props.fetchMaintenanceSchedule({ pageNumber: this.activeMaintenanceCurrentPage++, status: true, filterSort: this.activeFilterSort })
        .then(() => {
          setTimeout(() => {
            const uniqueData = this.removeActiveDuplicateRecordsIfAny()
            this.setState(({ activeMaintenanceData }) => ({
              activeMaintenanceData: uniqueData || [],
              objectActiveMaintenanceCount: this.props.activeCount,
            }))
          }, 200)
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1000);
        }).then(() => {
          var scrollContainer = document.querySelector('.active .ant-table-body')
          if (scrollContainer !== null)
            scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
        })
    }
  }

  removeInactiveDuplicateRecordsIfAny = () => {
    let inactiveMaintenanceData = this.state.inactiveMaintenanceData
    inactiveMaintenanceData = this.props.inactiveSchedules ? inactiveMaintenanceData.concat(this.props.inactiveSchedules) : []
    return inactiveMaintenanceData?.filter((value, index) => inactiveMaintenanceData.indexOf(value) === index)
  }

  handleFetchInactiveMaintenances = async () => {
    const countOfInactiveRecordsFetched = this.state.inactiveMaintenanceData?.length
    const totalInactiveMaintenances = this.props.inactiveCount
    if ((totalInactiveMaintenances === undefined && countOfInactiveRecordsFetched === undefined) || !(totalInactiveMaintenances <= countOfInactiveRecordsFetched)) {
      this.setState({ loading: true });
      await this.props.fetchMaintenanceSchedule({ pageNumber: this.inactiveMaintenanceCurrentPage++, status: false, filterSort: this.inactiveFilterSort })
        .then(() => {
          setTimeout(() => {
            const uniqueData = this.removeInactiveDuplicateRecordsIfAny()
            this.setState(({ inactiveMaintenanceData }) => ({
              inactiveMaintenanceData: uniqueData || [],
              objectInactiveMaintenanceCount: this.props.inactiveCount,
            }))
          }, 200)
          setTimeout(() => {
            this.setState({ loading: false })
          }, 1000);
        }).then(() => {
          var scrollContainer = document.querySelector('.inactive .ant-table-body')
          if (scrollContainer !== null)
            scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
        })
    }
  }

  async componentDidMount() {
    const { isEdit, status, filter, activeFilterSort, inactiveFilterSort, currentActivePage, currentInactivePage, viewType } = this.props;
    //document.getElementById('container').scrollIntoView();
    document.getElementById('tab-pane').scrollIntoView();
    this.setState({ currentPath: window.location.pathname });
    if (isEdit) {
      this.filter = filter
      this.status = status
      this.activeMaintenanceCurrentPage = currentActivePage || 1
      this.inactiveMaintenanceCurrentPage = currentInactivePage || 1
      this.activeFilterSort = activeFilterSort || {}
      this.inactiveFilterSort = inactiveFilterSort || {}
      this.setState({ viewType })
    }
    this.setState({ loading: true });
    this.getMaintenanceSchedules()
    batch(() => {
      this.props.fetchDemos({ getAll: true, access: 2 });
      this.props.fetchResources({ pageNumber: 1, access: 2, status: true, getAll: true });
      this.props.fetchDataCenters();
      this.props.updateFilterSort(this.activeFilterSort, this.inactiveFilterSort, this.status);
    });
    this.setState({
      objectActiveMaintenanceCount: this.props.activeCount,
      objectInactiveMaintenanceCount: this.props.inactiveCount
    });

  };

  renderDemosOptions = () => {
    if (this.props.demos) {
      return this.props.demos.map((demo, index) => {
        return <Option key={index} value={JSON.stringify(demo)}>{demo.title}</Option>;
      });
    }
  }

  /**
   * isFetchOnDelete is passed to refresh only the active Maintenance table, and not inactive,
   * since inactive table does not have delete, and need not to be fetched again.
   */

  getMaintenanceSchedules = async (status, isFetchOnDelete) => {
    const { activePageSize, inactivePageSize, isEdit } = this.props;
    this.activeFilterSort = { ...this.activeFilterSort, filter: this.filter }
    this.inactiveFilterSort = { ...this.inactiveFilterSort, filter: this.filter }
    status = status || this.status;
    batch(() => {
      this.props.updateFilterSort(this.activefilterSort, this.inactiveFilterSort, status);
    })

    if (status === undefined) {
      await this.props.fetchMaintenanceSchedule({ pageNumber: isEdit ? 1 : this.activeMaintenanceCurrentPage++, pageSize: isEdit ? activePageSize : CONFIG.lazyLoadPageSize, status: true, filterSort: this.activeFilterSort })
      setTimeout(() => {
        this.setState(({ activeMaintenanceData }) => ({
          activeLoading: false,
          loading: false,
          activeMaintenanceData: this.props.activeSchedules ? activeMaintenanceData?.concat(this.props.activeSchedules) : [],
          objectActiveMaintenanceCount: this.props.activeCount,
        }))
      }, 200)
      if (!isFetchOnDelete) {
        await this.props.fetchMaintenanceSchedule({ pageNumber: isEdit ? 1 : this.inactiveMaintenanceCurrentPage++, pageSize: isEdit ? inactivePageSize : CONFIG.lazyLoadPageSize, status: false, filterSort: this.inactiveFilterSort })
        setTimeout(() => {
          this.setState(({ inactiveMaintenanceData }) => ({
            inactiveLoading: false,
            loading: false,
            inactiveMaintenanceData: this.props.inactiveSchedules ? inactiveMaintenanceData?.concat(this.props.inactiveSchedules) : [],
            objectInactiveMaintenanceCount: this.props.inactiveCount,
          }))
        }, 200)

      }
    }
    else {
      await this.props.fetchMaintenanceSchedule({ pageNumber: status ? isEdit ? 1 : this.activeMaintenanceCurrentPage++ : isEdit ? 1 : this.inactiveMaintenanceCurrentPage++, status: status, filterSort: status ? this.activeFilterSort : this.inactiveFilterSort })
      setTimeout(() => {
        this.setState(({ activeMaintenanceData, inactiveMaintenanceData }) => ({
          activeLoading: status && false,
          loading: false,
          activeMaintenanceData: status && this.props.activeSchedules ? activeMaintenanceData?.concat(this.props.activeSchedules) : [],
          objectActiveMaintenanceCount: status && this.props.activeCount,
          inactiveLoading: !status && false,
          inactiveMaintenanceData: !status && this.props.inactiveSchedules ? inactiveMaintenanceData?.concat(this.props.inactiveSchedules) : [],
          objectInactiveMaintenanceCount: !status && this.props.inactiveCount,
        }))
      }, 200)
    }
  }

  handleSelectChange = async (value) => {
    this.clearCurrentActiveMaintenance();
    this.clearCurrentInactiveMaintenance();
    this.status = value;
    if (value) this.inactiveFilterSort = { ...this.inactiveFilterSort, sort: undefined }
    else if (value === false) this.activeFilterSort = { ...this.activeFilterSort, sort: undefined }
    this.getMaintenanceSchedules();
  }

  handleDeleteConfirmation = () => {
    const hardDelete = this.filter?.viewDeletedItems;
    batch(() => {
      this.props.deleteMaintenanceSchedule(this.maintenanceId, hardDelete, this.state.deleteAll, this.groupId).then(() => {
        this.clearCurrentActiveMaintenance();
        this.getMaintenanceSchedules(this.status, true)
        this.setState({ deleteAll: false })
        this.fromCalendar && this.setState({ isCalendarDeleted: this.generateUniqueRandomNumber() });
        this.fromCalendar = false;
      });
    })
  }

  maintenanceGroupColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      align: "left",
      key: 'title',
      width: "40%"
    },
    {
      title: 'Start Date',
      dataIndex: 'startTime',
      width: "30%",
      key: 'startDate',
      align: 'center',
      render: (startTime) => moment.tz(startTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)
    },
    {
      title: 'End Date',
      dataIndex: 'endTime',
      key: 'endDate',
      width: "30%",
      align: 'center',
      render: (endTime) => moment.tz(endTime, this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)
    }
  ];

  renderMaintenanceGroupList = (groupSchedules) => {
    return (
      <Table
        record={(record) => record.id}
        className="responsive-container vertical-spacing"
        dataSource={groupSchedules}
        size="small"
        pagination={false}
        columns={this.maintenanceGroupColumns}
      />)
  }

  handleDelete = async (record, fromCalendar = false) => {
    this.maintenanceId = record.id;
    this.groupId = record.groupId;
    this.fromCalendar = fromCalendar;
    const hardDelete = this.filter?.viewDeletedItems;
    if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
    else {
      const groupSchedules = record.groupId > 0 ? await getMaintenanceSchedulesByGroupID(record.groupId, hardDelete) : null
      const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
      const content = hardDelete
        ? <>
          <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Scheduled Maintenance")}</h4>
          <br />
          {record.groupId > 0 && groupSchedules?.length > 1 &&
            <h4>The selected maintenance window belongs to a series. Would you like to delete the entire series?<br /><br />
              <Collapse bordered={true} >
                <Panel header={<h3>List of maintenance schedules belonging to this series</h3>} key="1">
                  <div>{this.renderMaintenanceGroupList(groupSchedules)}</div>
                </Panel>
              </Collapse>
              <br />
              <Checkbox onChange={(e) => this.setState({ deleteAll: e.target.checked })}>Delete All</Checkbox>
            </h4>
          }
        </>
        : record.groupId > 0 && groupSchedules?.length > 1
          ? <h4>The selected maintenance window belongs to a series. Would you like to delete the entire series?<br /><br />
            <Collapse bordered={true} >
              <Panel header={<h3>List of maintenance schedules belonging to this series</h3>} key="1">
                <div>{this.renderMaintenanceGroupList(groupSchedules)}</div>
              </Panel>
            </Collapse>
            <br />
            <Checkbox onChange={(e) => this.setState({ deleteAll: e.target.checked })}>Delete All</Checkbox></h4>
          : ""

      const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'scheduled maintenance')
      openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle, groupSchedules !== null && groupSchedules.length > 1 ? 'demo-resource-dependancyList-modal' : null);

    }
  };

  handleClear = (isRefresh) => {
    if (this.status) this.clearCurrentActiveMaintenance()
    else if (this.status === false) this.clearCurrentInactiveMaintenance()
    else {
      this.clearCurrentActiveMaintenance()
      this.clearCurrentInactiveMaintenance()
    }
    if (!isRefresh) {
      this.filter = undefined;
      this.activeFilterSort = { ...this.activeFilterSort, filter: undefined };
      this.inactiveFilterSort = { ...this.inactiveFilterSort, filter: undefined };
      this.filterSave = {};
    }
    this.getMaintenanceSchedules();
  }

  getUTCTime = (time) => {
    const { timezone } = this.props.userProfile;
    return time.tz(timezone, true).toISOString();
  }

  handleFilter = (filters, isSave) => {
    let { demo, description, startEndTime, applyToAllCheck, viewDeletedItems, demoDC, resource, title, createdBy, updatedBy } = filters;

    description = description?.trim();
    title = title?.trim();
    createdBy = createdBy?.trim();
    updatedBy = updatedBy?.trim();
    const isDemoEmpty = !demo || (demo && demo.length === 0);
    const isDemoDCEmpty = !demoDC || (demoDC && demoDC.length === 0);
    const isResourceEmpty = !resource || (resource && resource.length === 0);
    const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
    const isFilter = isSave ? "save" : "filter";
    const formEmpty = isDemoEmpty && isDemoDCEmpty && isResourceEmpty && !title && !description && !createdBy && !updatedBy && isStartEndTimeEmpty && !viewDeletedItems;

    if (formEmpty) {
      pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
      return;
    }
    else if (!formEmpty && isSave && isStartEndTimeEmpty) {
      pushMessage(CONFIG.messageType.warning, `Please select start and end time to save`)
      return;
    }
    if (isSave) {
      this.filterSave = {
        ...this.filterSave,
        filter: {
          ...(!isStartEndTimeEmpty && {
            [dataIndexMap.startTime]: {
              operator: ">=",
              oprVal: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0].startOf('day')) : undefined
            }
          }),
          ...(!isStartEndTimeEmpty && {
            [dataIndexMap.endTime]: {
              operator: "<",
              oprVal: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1].endOf('day')) : undefined
            }
          }),
          ...(!isDemoEmpty && {
            [dataIndexMap.demo]: {
              operator: "=",
              oprVal: !isDemoEmpty ? demo.join(CONFIG.delimiters.saveFilter) : undefined
            }
          }),
          ...(!isDemoDCEmpty && {
            [dataIndexMap.demoDC]: {
              operator: "=",
              oprVal: !isDemoDCEmpty ? demoDC.join(CONFIG.delimiters.saveFilter) : undefined
            }
          }),
          ...(!isResourceEmpty && {
            [dataIndexMap.resource]: {
              operator: "=",
              oprVal: !isResourceEmpty ? resource.join(CONFIG.delimiters.saveFilter) : undefined
            }
          }),
          ...(description && {
            [dataIndexMap.description]: {
              operator: "=",
              oprVal: description || undefined
            }
          }),
          ...(title && {
            [dataIndexMap.title]: {
              operator: "=",
              oprVal: title || undefined
            }
          }),
          ...(viewDeletedItems && {
            isSoftDelete: {
              operator: "=",
              oprVal: viewDeletedItems
            }
          })
        },
        ...(createdBy && {
          [dataIndexMap.createdBy]: {
            operator: "=",
            oprVal: createdBy || undefined
          }
        }),
        ...(updatedBy && {
          [dataIndexMap.updatedBy]: {
            operator: "=",
            oprVal: updatedBy || undefined
          }
        }),
        filterTable: {
          id: 3,
          tableName: "Scheduled Maintenance"
        },
      }
      this.props.toggleSaveReportVisible(true)
      this.setState({ saveReport: true })
    }

    else {
      if (this.status) this.clearCurrentActiveMaintenance()
      else if (this.status === false) this.clearCurrentInactiveMaintenance()
      else {
        this.clearCurrentActiveMaintenance()
        this.clearCurrentInactiveMaintenance()
      }

      if (viewDeletedItems) this.status = undefined;

      this.filter = {
        [dataIndexMap.demo]: !isDemoEmpty ? demo.join('\\') : undefined,
        selectedDemo: !isDemoEmpty ? demo.join('\\') : undefined,
        selectedDC: !isDemoDCEmpty ? demoDC.join('\\') : undefined,
        selectedResource: !isResourceEmpty ? resource.join('\\') : undefined,
        [dataIndexMap.demoDC]: !isDemoDCEmpty ? demoDC.join('\\') : undefined,
        [dataIndexMap.resource]: !isResourceEmpty ? resource.join('\\') : undefined,
        [dataIndexMap.description]: description || undefined,
        [dataIndexMap.startTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0]) : undefined,
        [dataIndexMap.endTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1]) : undefined,
        [dataIndexMap.applyToAllCheck]: applyToAllCheck || undefined,
        [dataIndexMap.viewDeletedItems]: viewDeletedItems,
        [dataIndexMap.title]: title || undefined,
        [dataIndexMap.createdBy]: createdBy || undefined,
        [dataIndexMap.updatedBy]: updatedBy || undefined,
      }
      this.getMaintenanceSchedules();
      this.tableRef.current !== null && this.tableRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  handleSave = (filters) => {
    this.handleFilter(filters, true)
  }

  handleExport = async (fileType) => {
    const { timezone } = this.props.userProfile;
    pushMessage(CONFIG.messageType.loading, "Exporting", 0);
    const filterSort = this.status ? this.activeFilterSort : this.inactiveFilterSort;
    const schedules = await getAllMaintenanceSchedules(filterSort, this.status)
    if (schedules?.length > 0 && schedules !== undefined) {
      exportTable(CONFIG.exportTable.maintenanceSchedules.name, schedules, timezone, CONFIG.exportTable.maintenanceSchedules.fileName + `.${fileType}`, fileType)
        .then((response) => {
          message.destroy()
          const isExport = response.status === CONFIG.HTTP_STATUS.OK
          pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
        })
        .catch(() => {
          message.destroy()
          pushMessage(CONFIG.messageType.error, "Unable to export table")
        })
    }
    else {
      message.destroy()
      pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
    }
  }

  handleSortChange = async (status, pagination, _, sorter) => {
    status ? await this.clearCurrentActiveMaintenance() : await this.clearCurrentInactiveMaintenance();
    const type = status ? "activeFilterSort" : "inactiveFilterSort";
    this[type] = {
      ...this[type],
      sort: sorter.columnKey
        ? {
          [sorter.columnKey]: CONFIG.sortMap[sorter.order]
        }
        : undefined
    }
    status ? this.handleFetchActiveMaintenances() : this.handleFetchInactiveMaintenances()
  }

  getDemoTitleString = (demos, delimiter = ' ', defaultString = '') => {
    return demos?.map(demo => demo.title)?.join(delimiter) || defaultString;
  }

  getRowSpanForMaintenance = (item) => {
    return item?.maintenanceRelations?.length;
  }

  getDataSets = (data) => {
    let updatedDataSet = [];
    let parsedDataSet = [];
    data.forEach((item) => {
      if (item?.maintenanceRelations?.length > 0) {
        let relationsForMaintenance = {}
        return item?.maintenanceRelations?.forEach((relation) => {
          let rowSpan = 0;
          if (parsedDataSet.indexOf(item.id) === -1) {
            rowSpan = this.getRowSpanForMaintenance(item);
            parsedDataSet.push(item.id)
          }
          relationsForMaintenance = { ...relationsForMaintenance, relation };
          updatedDataSet.push({ ...item, relationsForMaintenance, rowSpan });
        })
      }
    })
    return updatedDataSet;
  }

  handleViewTypeChange = async (e) => {
    // await this.clearCurrentSchedules()
    // this.selectedMonthFirstDay = moment(moment()).tz(this.props.timeZoneName).startOf('month').startOf('day').toISOString();
    // this.selectedMonthLastDay = moment(moment()).tz(this.props.timeZoneName).endOf('month').endOf('day').toISOString();
    this.setState({ viewType: e.target.value })
    // , () => { this.handleFetchSchedules() })
  }

  updateCalendarProps = (calendarDate, calendarViewType) => {
    this.setState({
      calendarDate,
      calendarViewType
    })
  }

  render() {
    const activeDataSet = this.getDataSets(this.state.activeMaintenanceData);
    const inactiveDataSet = this.getDataSets(this.state.inactiveMaintenanceData);
    const actionsColumn = {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: 125,
      render: (text, record, index) => {
        const deleteView = this?.filter?.viewDeletedItems;
        const isEditEnabled = record.maintenanceRelations?.some(item => item?.demo?.adminAccess === CONFIG.roleTemplateAccess.fullAccess)
        const isDeleteEnabled = record.maintenanceRelations?.every(item => item?.demo?.adminAccess === CONFIG.roleTemplateAccess.fullAccess)
        return {
          children:
            <span>
              {!deleteView &&
                <>
                  <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                    <button disabled={!isEditEnabled}
                      onClick={async () => {
                        await this.props.handleOnClickAction({
                          component: CONFIG.editComponentRoute.scheduleMaintenance,
                          tabTitle: record?.title,
                          recordId: record?.id
                        }
                        )
                      }}
                      className={isEditEnabled ? "link" : "link-disabled"}>
                      <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span></button>
                  </Tooltip>

                  <Divider type="vertical" />
                </>
              }
              <button title={!isDeleteEnabled ? CONFIG.warningMessages.noAccess : undefined} disabled={!isDeleteEnabled} className={isDeleteEnabled ? "link" : "link-disabled"} onClick={() => this.handleDelete(record)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
              {!deleteView &&
                <>
                  <Divider type="vertical" />
                  <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                    <button disabled={!isEditEnabled}
                      onClick={async () => {
                        await this.props.handleOnClickAction({
                          component: CONFIG.cloneComponentRoute.scheduleMaintenance,
                          tabTitle: record?.title,
                          recordId: record?.id,
                          isClone: true
                        }
                        )
                      }}
                      className={isEditEnabled ? "link" : "link-disabled"}>
                      <span class="neo-icon-copy" title="Clone" style={{ fontSize: "20px" }}></span></button>
                  </Tooltip>
                </>
              }
            </span >,
          props: { rowSpan: record?.rowSpan }
        }
      }
    }

    const cloneAction = {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: 75,
      render: (text, record, index) => {
        const isEditEnabled = record.maintenanceRelations?.some(item => item?.demo?.adminAccess === CONFIG.roleTemplateAccess.fullAccess)
        return {
          children:
            <span>
              {
                <>
                  <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                    <button disabled={!isEditEnabled}
                      onClick={async () => {
                        await this.props.handleOnClickAction({
                          component: CONFIG.cloneComponentRoute.scheduleMaintenance,
                          tabTitle: record?.title,
                          recordId: record?.id,
                          isClone: true
                        }
                        )
                      }}
                      className={isEditEnabled ? "link" : "link-disabled"}>
                      <span class="neo-icon-copy" title="Clone" style={{ fontSize: "20px" }}></span></button>
                  </Tooltip>
                </>

              }
            </span >,
          props: { rowSpan: record?.rowSpan }
        }
      }
    }

    const numbers =
    {
      title: 'No.',
      key: 'index',
      align: 'center',
      width: 75,
      render: (text, record, index) => {
        return {
          children: record.index,
          props: { rowSpan: record?.rowSpan }
        }
      }
    };

    const commonColumns = [
      {

        title: <span className={this.filter?.[dataIndexMap.title] ? "filtered-column" : ''}>Maintenance Title</span>,
        dataIndex: 'title',
        key: dataIndexMap.title,
        width: 300,
        sorter: true,
        align: "center",
        className: "vertical-align",
        render: (title, { rowSpan }) => {
          return {
            children: <span>{title}</span>,
            props: { rowSpan }
          }
        }
      },
      {
        title: <span className={this.filter?.[dataIndexMap.demo] ? "filtered-column" : ''}>Demo(s)</span>,
        dataIndex: 'relationsForMaintenance',
        key: dataIndexMap.demo,
        width: 250,
        sorter: true,
        render: (maintenanceRelations) => {
          return {
            children: <span> {maintenanceRelations?.relation?.demo?.title || "-"} </span>,
          }
        }
      },
      {
        title: <span className={this.filter?.[dataIndexMap.demoDC] ? "filtered-column" : ''}>Data Center(s)</span>,
        dataIndex: 'relationsForMaintenance',
        key: dataIndexMap.demoDC,
        width: 250,
        sorter: true,
        render: (maintenanceRelations) => {
          return {
            children: <span> {maintenanceRelations?.relation?.dataCenter?.title || "ALL"} </span>,
          }
        }
      },
      {
        title: <span className={this.filter?.[dataIndexMap.resource] ? "filtered-column" : ''}>Resource(s)</span>,
        dataIndex: 'relationsForMaintenance',
        key: dataIndexMap.resource,
        width: 200,
        sorter: true,
        render: (maintenanceRelations) => {
          return {
            children: <span> {maintenanceRelations?.relation?.resource?.name || "ALL"} </span>,
            // props: { rowSpan }
          }
        }
      },
      {
        title: <span className={this.filter?.[dataIndexMap.description] ? "filtered-column" : ''}>Description</span>,
        dataIndex: 'description',
        key: dataIndexMap.description,
        sorter: true,
        align: "center",
        className: "vertical-align",
        width: 300,
        render: (description, { rowSpan }) => {
          return {
            children: description,
            props: { rowSpan }
          }
        }
      },
      {
        title: <span className={this.filter?.[dataIndexMap.startTime] ? "filtered-column" : ''}>Start Date/Time</span>,
        dataIndex: 'startTime',
        key: 'startTime',
        align: 'center',
        className: "vertical-align",
        width: 200,
        sorter: true,
        render: (startTime, { rowSpan }) => {
          const { userProfile } = this.props;
          const timeZoneName = userProfile ? userProfile.timezone : null;
          startTime = moment.tz(startTime, timeZoneName);
          return {
            children: startTime.format(CONFIG.dateFormats.userDateTime),
            props: { rowSpan }
          }
        }
      },
      {
        title: <span className={this.filter?.[dataIndexMap.endTime] ? "filtered-column" : ''}>End Date/Time</span>,
        dataIndex: 'endTime',
        key: 'endTime',
        align: 'center',
        className: "vertical-align",
        width: 200,
        sorter: true,
        render: (endTime, { rowSpan }) => {
          const { userProfile } = this.props;
          const timeZoneName = userProfile ? userProfile.timezone : null;
          endTime = moment.tz(endTime, timeZoneName);
          return {
            children: endTime.format(CONFIG.dateFormats.userDateTime),
            props: { rowSpan }
          }
        }
      },
      {
        title: <span className={this.filterSort?.filter?.[dataIndexMap.createdBy] ? "filtered-column" : ''}>Created By</span>,
        key: "createdBy.fullName",
        dataIndex: "createdBy",
        sorter: true,
        width: 200,
        render: (createdBy) => createdBy ? createdBy?.fullName : "-",
      },
      {
        title: <span className={this.filterSort?.filter?.[dataIndexMap.updatedBy] ? "filtered-column" : ''}>Updated By</span>,
        key: "updatedBy.fullName",
        dataIndex: "updatedBy",
        sorter: true,
        width: 200,
        render: (updatedBy) => updatedBy ? updatedBy?.fullName : "-",
      },
    ];

    const { demos, userProfile, resources, dataCenters } = this.props;
    const timeZoneName = userProfile ? userProfile.timezone : null;
    const { loading } = this.state
    const deleteView = this?.filter?.viewDeletedItems;

    const formFields = [
      {
        label: "Title",
        type: CONFIG.formFieldTypes.INPUT,
        key: "title",
        colSpan: 12,
        value: this.filter?.title
      },
      {
        label: "Demo Name",
        type: CONFIG.formFieldTypes.SELECT,
        key: "demo",
        mode: "tags",
        valueKey: "text",
        data: (demos) ? demos.map(demo => ({ text: demo.title })) : [],
        colSpan: 12,
        value: this.filter?.selectedDemo
      },
      {
        label: "Data Center",
        type: CONFIG.formFieldTypes.SELECT,
        key: "demoDC",
        mode: "tags",
        valueKey: "text",
        data: (dataCenters) ? dataCenters.filter(record => record?.active).map(dataCenter => ({ text: dataCenter?.title })) : [],
        colSpan: 12,
        value: this.filter?.selectedDC
      },
      {
        label: "Resource",
        type: CONFIG.formFieldTypes.SELECT,
        key: "resource",
        mode: "tags",
        valueKey: "text",
        data: (resources) ? resources.map(resource => ({ text: resource.name })) : [],
        colSpan: 12,
        value: this.filter?.selectedResource
      },
      {
        label: "Created By",
        type: CONFIG.formFieldTypes.SEARCH_SELECT,
        key: "createdBy",
        data: this.state.matchedUsersNames,
        colSpan: 12,
      },
      {
        label: "Updated By",
        type: CONFIG.formFieldTypes.SEARCH_SELECT,
        key: "updatedBy",
        data: this.state.matchedUsersNames,
        colSpan: 12,
      },
      {
        label: "Description",
        type: CONFIG.formFieldTypes.INPUT,
        key: "description",
        colSpan: 12,
        value: this.filter?.description
      },
      {
        label: "Start / End Date",
        type: CONFIG.formFieldTypes.RANGE_PICKER,
        key: "startEndTime",
        colSpan: 12,
        value: [this.filter?.startTime, this.filter?.endTime, this.filter?.includeStartAndEndDate],
        disabled: this.state.viewType === CONFIG.viewType.CALENDAR ? true : false
      }
    ]
    const { showFilter } = this.state
    return (
      <React.Fragment>
        <Row className="vertical-spacing">
          <Col span={4}>
            <StatusFilter dataIndex="maintenance" disabled={this.activeFilterSort.filter?.viewDeletedItems || this.inactiveFilterSort.filter?.viewDeletedItems} value={this.status} onChange={this.handleSelectChange} />
          </Col>
          <Col xl={2} sm={6} xs={24} className="left-spacing">
            <MaintenanceFormContainer />
          </Col>

          <Col xl={1} sm={2} xs={2} className="float-right">
            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
              <NeoIcon title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
            </Dropdown>
          </Col>
          <Col xl={1} sm={2} xs={2} className="float-right">
            <NeoIcon className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
          </Col>
          <Col xl={1} sm={2} xs={2} className="float-right">
            <NeoIcon className="clickable" onClick={() => this.setState(prevState => ({
              showFilter: !prevState.showFilter
            }))}
              title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
          </Col>
        </Row>
        <div className="content-container">
          <Row className="vertical-spacing">
            <Radio.Group value={this.state.viewType} onChange={this.handleViewTypeChange}  >
              <Radio.Button value={CONFIG.viewType.TABLE}>Table</Radio.Button>
              <Radio.Button value={CONFIG.viewType.CALENDAR}>Calendar</Radio.Button>
            </Radio.Group>
          </Row>
          <Row>
            {this.state.showFilter && <FilterPanel
              label='View Deleted Scheduled Maintenance'
              formFields={formFields}
              handleClear={this.handleClear}
              handleFilter={this.handleFilter}
              panelHeader="Filters and Export"
              getSearchData={this.fetchUsersName}
              handleSave={this.handleSave}
              timeZone={timeZoneName}
              viewDeletedItemsValue={this.status ? this.activeFilterSort?.filter?.viewDeletedItems : this.inactiveFilterSort?.filter?.viewDeletedItems}
            />}
          </Row>
          {this.state.viewType === CONFIG.viewType.TABLE ? <>
            {
              (this.status || this.status === undefined) &&
              <React.Fragment>
                <h3> {this.filter?.viewDeletedItems ? `Deleted Active Maintenance Schedules` : `Active Maintenance Schedules`}</h3>
                <div ref={this.status || this.status === undefined ? this.tableRef : undefined} />
                <ActiveMaintenanceTable handleSortChange={this.handleSortChange} getActiveMaintenanceSchedules={this.handleFetchActiveMaintenances} loading={loading} columns={[numbers, ...commonColumns, actionsColumn]} tableLoading={this.state.loading} dataSource={activeDataSet} count={this.state.objectActiveMaintenanceCount} />
              </React.Fragment>
            }
            {
              !this.status &&
              <React.Fragment>
                <h3>{this.filter?.viewDeletedItems ? `Deleted Inactive Maintenance Schedules` : `Inactive Maintenance Schedules`}</h3>
                <div ref={(this.status === false || this.status === undefined) ? this.tableRef : undefined} />
                <InactiveMaintenanceTable handleSortChange={this.handleSortChange} getInactiveMaintenanceSchedules={this.handleFetchInactiveMaintenances} loading={loading} dataSource={inactiveDataSet} columns={[numbers, ...commonColumns, !deleteView ? cloneAction : {}]} tableLoading={this.state.loading} count={this.state.objectInactiveMaintenanceCount} />
              </React.Fragment>
            }
          </>
            : <ScheduleMaintenanceCalendar updateCalendarProps={this.updateCalendarProps} isCalendarDeleted={this.state.isCalendarDeleted} handleDelete={this.handleDelete} deleteView={deleteView} status={this.status} filterSort={this.filter} />
          }
        </div>
        {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ maintenanceSchedule, user, demos, resources, dataCenters, tabsLayout }) => {
  return {
    userProfile: user.profile,
    demos: demos.allDemos,
    activeSchedules: maintenanceSchedule.active,
    inactiveSchedules: maintenanceSchedule.inactive,
    isFetching: maintenanceSchedule.isFetching,
    currentActivePage: maintenanceSchedule.currentActivePage,
    currentInactivePage: maintenanceSchedule.currentInactivePage,
    activeCount: maintenanceSchedule.activeCount,
    inactiveCount: maintenanceSchedule.inactiveCount,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    timeZoneName: user.profile ? user.profile.timezone : undefined,
    isEdit: maintenanceSchedule.isEdit,
    filter: maintenanceSchedule.filter,
    status: maintenanceSchedule.status,
    viewType: maintenanceSchedule.viewType,
    tableIndex: maintenanceSchedule.tableIndex,
    resources: resources.data,
    dataCenters: dataCenters.data,
    activeFilterSort: maintenanceSchedule.activeFilterSort,
    inactiveFilterSort: maintenanceSchedule.inactiveFilterSort,
    activePageSize: maintenanceSchedule.activePageSize,
    inactivePageSize: maintenanceSchedule.inactivePageSize,
    openedTabs: tabsLayout.panes

  };
};

export default connect(
  mapStateToProps,
  {
    fetchDemos,
    fetchMaintenanceSchedule,
    toggleIsFetchingMaintenanceSchedule,
    deleteMaintenanceSchedule,
    updateFilterSort,
    clearMaintenanceSchedules,
    fetchResources,
    fetchDataCenters,
    currentMaintenanceFilter,
    handleOnClickAction,
    toggleSaveReportVisible
  }
)(ScheduleMaintenanceTable);
