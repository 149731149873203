import React from 'react';
import { Select, SelectOption } from "neo-latest";
import CONFIG from '../../config';
export default class CustomNeoSelect extends React.Component {

    state = { hasChanged: Math.random(0, 100) * Date.now() }

    handleOnChange = (value) => {
        const { formKey, allData, form } = this.props;
        const { setFieldsValue } = form;
        if (value?.length > 0 && value?.includes(CONFIG.selectAll)) {
            setTimeout(() => {
                setFieldsValue({ [formKey]: allData })
            }, 1);
        }
        else {
            setFieldsValue({ [formKey]: value })
        }
        this.setState({ hasChanged: Math.random(0, 100) * Date.now() })
        this.forceUpdate();
    }

    render() {
        const { label, required, searchable, multiple, children, value, disabled } = this.props;
        return (
            <Select disabled={disabled} onChange={this.handleOnChange} value={value} multiple={multiple} label={label} required={required} searchable={searchable}>
                <SelectOption value={CONFIG.selectAll}>Select All</SelectOption>
                {children}
            </Select>
        )
    }
}
