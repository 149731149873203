import React, { Component } from 'react'
import { Modal, Rate, Form, Spin } from 'antd'
import { postEZDemoFeedback, clearUserEZDemos, getEZDemoReviewByAuditId } from '../../actions';
import { connect } from 'react-redux';
import CONFIG from '../../config';
import { Button, TextArea } from "@avaya/neo-react"
import { LoadingOutlined } from '@ant-design/icons';

class EZDemoFeedbackModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFeedbackModal: props.redirectFromEmail ? false : props.showFeedbackModal,
            currentViewedEZDemo: props.currentViewedEZDemo,
            showErrorPage: false,
            isLoading: false
        };
    }

    handleCancel = () => {
        this.setState({ showFeedbackModal: false });
        this.props.handleCancel();
        this.clearEZDemosLocalStorage();
    }

    clearEZDemosLocalStorage = () => {
        localStorage.removeItem('redirectToEZDemoModal')
        localStorage.removeItem('ezDemoFeedbackId')
        localStorage.removeItem('ezDemoTitle')
        this.setState({ showErrorPage: false })
    }

    handleFeedbackSumit = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const { handlePostFeedbackSubmitOnDemoCard, handleCancel, clearUserEZDemos, fetchOnMount } = this.props;
                let response;
                if (!this.state.isLoading) {
                    await this.setState({ isLoading: true })
                    response = await this.props.postEZDemoFeedback(this.props.feedbackAuditId, values);
                    await this.setState({ isLoading: false })
                }
                this.setState({ showFeedbackModal: false, currentViewedEZDemo: null })
                handleCancel();
                if (handlePostFeedbackSubmitOnDemoCard && !this.state.isLoading) {
                    handlePostFeedbackSubmitOnDemoCard(response);
                }
                else {
                    this.props.form.resetFields();
                    await clearUserEZDemos()
                    fetchOnMount();
                }
            }
        });
        this.clearEZDemosLocalStorage();
    }

    renderFeedbackForm = () => {
        const { getFieldDecorator } = this.props.form;
        return (<>
            <span className='feedback-modal-title'>We'd love your feedback!</span>
            <Form>
                <Form.Item label="How do you rate your recent EZ Demo Experience?">
                    {getFieldDecorator("overallRating", {
                        rules: [
                            { required: true, message: "Please provide a rating." },
                        ],
                        initialValue: undefined,
                    })(<Rate style={{ fontSize: 36 }} tooltips={CONFIG.ratingTooltip} />)
                    }
                </Form.Item>
                <Form.Item
                    className='feedback-note-form-item'
                    label="Do you have any additional comment?">
                    {getFieldDecorator("comment", {
                        initialValue: undefined,
                        rules: [
                            { required: false },
                        ]
                    }
                    )(<TextArea rows={2} />)
                    }
                </Form.Item>
            </Form>
        </>)
    }

    componentWillUnmount() {
        this.clearEZDemosLocalStorage();
    }

    setModalVisbility = ({ showErrorPage, showFeedbackModal }) => {
        this.setState({ showErrorPage, showFeedbackModal })
    }

    async componentDidMount() {
        const redirectFromEmail = localStorage.getItem('redirectToEZDemoModal');
        if (redirectFromEmail) {
            await this.props.getEZDemoReviewByAuditId(this.props.feedbackAuditId);
            if (this.props.ezDemoReview && this.props.ezDemoReview?.ezDemoAuditRating !== null) this.setModalVisbility({ showErrorPage: true, showFeedbackModal: false })
            else this.setModalVisbility({ showErrorPage: false, showFeedbackModal: true })
        }
        else this.setModalVisbility({ showErrorPage: false, showFeedbackModal: true })
    }

    render() {
        return (
            <React.Fragment>
                {/* // Modal shown when user has already rated for the specific ezDemoAudit */}
                <Modal
                    title="Page Not Found"
                    visible={this.state.showErrorPage}
                    onCancel={this.clearEZDemosLocalStorage}
                    cancelText="Close"
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelButtonProps={{ style: { "background": "red", color: "white" } }}
                >
                    Looks like you've already submitted your feedback or you followed a broken link.
                </Modal>
                <Modal
                    className='unsubscribe-modal'
                    visible={this.state.showFeedbackModal}
                    onCancel={this.handleCancel}
                    title={`EZ Demo Feedback - ${this.props.title}`}
                    footer={[
                        <Button aria-label='cancel' key="back" variant='secondary' onClick={this.handleCancel} disabled={this.state.isLoading}>Dismiss</Button>,
                        <Button aria-label='submit' key="submit" onClick={() => this.handleFeedbackSumit()} disabled={this.state.isLoading}>Submit Feedback</Button>,
                    ]}
                >
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.isLoading}>
                        {this.renderFeedbackForm()}
                    </Spin>
                </Modal>
            </React.Fragment>)
    }
}

const mapStateToProps = ({ user, ezDemoFeedback }) => {
    return {
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        ezDemoReview: ezDemoFeedback.ezDemoByAuditId,
    }
}

const EZDemoFeedbackModalForm = Form.create({ name: "ezDemosForm" })(EZDemoFeedbackModal)

export default connect(mapStateToProps, {
    postEZDemoFeedback,
    clearUserEZDemos,
    getEZDemoReviewByAuditId
})(EZDemoFeedbackModalForm);