import moment from 'moment-timezone';

export const getStartOfDateValue = ({ changeTimeZone, convertTimeZone, timeZone, date }) => {
    if (changeTimeZone) return date?.tz(timeZone, true).startOf('day').valueOf();
    else if (convertTimeZone) return moment.tz(moment(), timeZone).startOf('day').valueOf()
}

export const getDateTime = ({ changeTimeZone, convertTimeZone, timeZone, date }) => {
    if (changeTimeZone) return date?.tz(timeZone, true);
    else if (convertTimeZone) return moment.tz(moment(), timeZone);
}

export const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export const areDatesEqual = (date1, date2) => {
    const d1 = moment(date1).startOf('day').valueOf();
    const d2 = moment(date2).startOf('day').valueOf();
    return d1 === d2;
}

export const areHoursEqual = (time1, time2) => { return time1?.get('hours') === time2?.get('hours') }

// Range Picker

export const disabledDate = (timezone, start) => {

    const startValue = start ? getStartOfDateValue({ changeTimeZone: true, timeZone: timezone, date: moment(start) }) : undefined;
    const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timezone })

    return start && startValue < currentValue;
}

export const disabledTime = (timezone, startEndDate, type) => {

    const startDate = getStartOfDateValue({ changeTimeZone: true, timeZone: timezone, date: moment(startEndDate?.[0]) });
    const currentDate = getStartOfDateValue({ convertTimeZone: true, timeZone: timezone });
    const currentTime = getDateTime({ convertTimeZone: true, timeZone: timezone })

    if (type === 'start' && areDatesEqual(startDate, currentDate)) {
        return {
            disabledHours: () => range(0, currentTime?.get('hours')),
        }
    }
};

export const generateFinanicalYearQuarters = (selectedYears = []) => {
    selectedYears = selectedYears?.filter(item => item !== '');
    return selectedYears?.length > 0 ? selectedYears?.flatMap(year => ['Q1', 'Q2', 'Q3', 'Q4'].map(quarter => { return { key: `FY${year}-${quarter}`, value: `FY${year}-${quarter}` } })) : [];
}

