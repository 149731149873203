import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

export const decrypt = (encryptedData) => {
    const key = process.env.REACT_APP_NETWORK_AES_ENCRYPT_KEY
    const encryptedBase64Key = Buffer.from(key).toString('base64')
    const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, parsedBase64Key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    const decrypedText = decryptedData.toString(CryptoJS.enc.Utf8);
    return decrypedText
}

export const encrypt = (plainText) => {
    const key = process.env.REACT_APP_NETWORK_AES_ENCRYPT_KEY;
    const encryptedBase64Key = Buffer.from(key).toString('base64')
    const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    const encryptedData = CryptoJS.AES.encrypt(plainText, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encryptedData;
}