import { aepDemo } from "../apis";
import {
    DELETE_DEMO_COLLATERAL,
    FETCH_DEMO_COLLATERAL_TYPES,
    ADD_DEMO_COLLATERALS,
    REORDER_DEMO_COLLATERALS,
    ADD_NEW_DEMO_COLLATERAL,
    UPDATE_DEMO_COLLATERAL,
    CLEAR_NEW_DEMO_COLLATERALS,
    TOGGLE_IS_UPLOADING_FILE,
    FETCH_DELETED_COLLATERALS,
    DELETE_COLLATERALS
} from './types';
import CONFIG from "../config";
import pushMessage from "../components/common/PushMessage";

export const deleteDemoCollateral = collateralId => {
    return {
        type: DELETE_DEMO_COLLATERAL,
        payload: collateralId
    };
};

export const restoreDemoCollateral = (collateralId) => async () => {
    const response = await aepDemo.put("/demoSolutionService/restore/collaterals/" + collateralId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Collateral restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore collateral");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false

}

export const fetchDemoCollateralTypes = () => async dispatch => {
    const response = await aepDemo.get("/demoSolutionService/collaterals/types");
    dispatch({
        type: FETCH_DEMO_COLLATERAL_TYPES,
        payload: response.data
    });
};

export const addDemoTabCollaterals = (demoId, demoCollaterals) => {
    return {
        type: ADD_DEMO_COLLATERALS,
        payload: demoCollaterals
    }
}

export const reorderDemoCollaterals = newCollateralData => {
    return {
        type: REORDER_DEMO_COLLATERALS,
        payload: newCollateralData
    };
};

export const addNewDemoCollateral = collateral => {
    return {
        type: ADD_NEW_DEMO_COLLATERAL,
        payload: collateral
    };
};

export const updateDemoCollateral = (newCollateralValues, collateralId) => {
    const updatedCollateral = { ...newCollateralValues, id: collateralId };
    return {
        type: UPDATE_DEMO_COLLATERAL,
        payload: updatedCollateral
    };
};

export const clearNewDemoCollaterals = () => {
    return {
        type: CLEAR_NEW_DEMO_COLLATERALS
    }
}

const toggleIsUploadingFile = (flag) => ({
    type: TOGGLE_IS_UPLOADING_FILE,
    payload: flag
})

export const uploadDemoCollateral = (file, formValues, collateral, isFile) => async (dispatch) => {
    dispatch(toggleIsUploadingFile(true))
    const editMode = collateral.id !== undefined;
    const formData = new FormData();
    let collateralObj = {
        ...formValues,
        file: undefined,
        dataCenter: JSON.parse(formValues.dataCenter),
        collateralType: JSON.parse(formValues.collateralType),
        isLinkType: !isFile,
        link: !isFile ? formValues?.url : undefined,
    };
    const collateralJSONString = JSON.stringify(collateralObj);
    const collateralBlob = new Blob([collateralJSONString], {
        type: 'application/json'
    });
    if (isFile && (file.size !== 0 || !editMode)) formData.append('mediaFile', file);
    formData.append('collateral', collateralBlob)
    const contextPath = '/demoSolutionService/demo/collateral';

    const response = await aepDemo({
        method: editMode ? 'put' : 'post',
        url: editMode ? `${contextPath}/${collateral.id}` : contextPath,
        data: formData
    });

    const errorMessage = editMode ? "Unable to update collateral" : "Unable to add collateral";

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        const collateralDetails = response.data.toString();
        const collateralID = parseInt(collateralDetails.split(';')[0]);
        const collateralLink = collateralDetails.split(';')[1];
        collateralObj = { ...collateralObj, id: collateralID, link: isFile? collateralLink : formValues?.url };
        editMode ? dispatch(updateDemoCollateral(collateralObj, collateral.id)) : dispatch(addNewDemoCollateral(collateralObj));
    }
    else if (response.status === CONFIG.HTTP_STATUS.BAD_REQUEST) pushMessage(CONFIG.messageType.error, "Invalid file size")
    else if (response.status === CONFIG.HTTP_STATUS.UNSUPPORTED_MEDIA_TYPE) {
        pushMessage(CONFIG.messageType.error, "Uploaded file type is not supported.");
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsUploadingFile(false))
}

export const addOrUpdateDemoCollateral = ( formValues, collateral) => async (dispatch) => {
    
    const editMode = collateral.id !== undefined;
    
    let collateralObj = {
        ...formValues,
        file: undefined,
        dataCenter: JSON.parse(formValues.dataCenter),
        collateralType: JSON.parse(formValues.collateralType),
        link: formValues?.url,
        isLinkType: true
    };
    editMode ? dispatch(updateDemoCollateral(collateralObj, collateral.id)) : dispatch(addNewDemoCollateral(collateralObj));
}

export const deleteUnassociatedCollaterals = (collaterals) => async () => {
    await aepDemo.delete('/demoSolutionService/demo/collateral', { data: collaterals });
}

export const fetchDeletedCollaterals = (demoId, viewDeletedItems) => async (dispatch) => {
    const response = await aepDemo.get(`/demoSolutionService/demos/${demoId}/collaterals?`, { params: { viewDeletedItems } });
    if (response.status === CONFIG.HTTP_STATUS.OK || CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_DELETED_COLLATERALS,
            payload: response.data
        })
    }
    else {
        pushMessage("Unable to fetch deleted collaterals");
    }
}

export const deleteCollaterals = (collateralId) => async (dispatch) => {

    const response = await aepDemo.delete(`/demoSolutionService/collateral/${collateralId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Demo collateral deleted successfully")
        dispatch({
            type: DELETE_COLLATERALS,
            payload: collateralId
        })
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to delete demo collateral");
    }
}