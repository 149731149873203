import { TOGGLE_BACK_BUTTON, SELECT_TITLE, TOGGLE_MENU_BUTTON, TOGGLE_CLEAR_PATH, POST_SEARCH_RESULT } from '../actions/types';

export const headerReducer = (state = {}, action) => {
    switch (action.type) {
        case TOGGLE_BACK_BUTTON:
            return { ...state, showBackButton: action.payload.flag, previousPath: action.payload.path };
        case SELECT_TITLE:
            return { ...state, title: action.payload };
        case TOGGLE_MENU_BUTTON:
            return { ...state, showMenu: action.payload }
        case TOGGLE_CLEAR_PATH:
            return { ...state, previousPath: null }
        case POST_SEARCH_RESULT:
            return { ...state, searchResult: action.payload }
        default:
            return state;
    }
};