import React from 'react'
import { Divider, Card, Row, Col, Spin, Avatar } from 'antd';
import { connect } from "react-redux";
import moment from 'moment-timezone';
import { analytics } from '../firebase';
import { selectTitle, fetchUserNews, clearInactiveNews, updateCurrentUserNews, toggleBackButton, clearNews, clearCurrentNews, fetchNews, fetchAllUserNews } from "../actions";
import CONFIG from '../config';
import HTML from './common/HTML'
import Highlighter from 'react-highlight-words';
import { Menu } from 'antd5'
import { Icon as NeoIcon, Chip, Button } from "@avaya/neo-react"
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class News extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeNews: false,
            height: `${document.body.clientHeight - 230}px`,
            loading: true,
            infoLoading: true
        }
        props.clearInactiveNews();
    }
    pageNumber = 1;

    handleResize = (e) => {
        this.setState({ height: document.body.clientHeight > 250 ? `${document.body.clientHeight - 230}px` : `250px` });
    }

    async componentDidMount() {
        const { universalSearchString } = this.props.location
        const newsId = this.props.match.params?.newsId || this.props.location?.state?.selectedNews?.id
        newsId && await this.props.fetchNews(newsId, true, universalSearchString);
        await this.props.fetchAllUserNews({ pageNumber: 1, newsId })
        this.setState({ loading: false, infoLoading: false })
        if (localStorage.getItem('news'))
            localStorage.removeItem('news');
        window.addEventListener("resize", this.handleResize);
    }

    async componentWillUnmount() {
        await this.props.clearInactiveNews();
        window.removeEventListener("resize", this.handleResize);
    }

    handleLoadMore = async () => {
        const { currentUserNews } = this.props;
        const newsId = currentUserNews ? currentUserNews?.id : undefined;
        await this.setState({ loading: true });
        this.pageNumber = this.pageNumber + 1;
        await this.props.fetchAllUserNews({ pageNumber: this.pageNumber, newsId });
        await this.setState({ loading: false });
    }

    handleTabClick = async (key) => {
        const { user, userAllNews } = this.props;
        await this.setState({ infoLoading: true });
        const data = userAllNews;
        const currentNews = data?.find(news => news.id === parseInt(key))
        const newsObj = { ...currentNews, ...user, userName: user?.firstName + " " + user?.lastName }
        await this.props.updateCurrentUserNews(currentNews);
        setTimeout(async () => {
            await this.setState({ infoLoading: false });
        }, 1500)
        /* Firebase Analytics Log Event */
        const { news: {
            name: eventName,
            parameters: eventParams
        } } = CONFIG.firebaseEvents;
        let parameters = {};
        Object.keys(eventParams).forEach(paramKey => {
            parameters[paramKey] = newsObj[eventParams[paramKey]]
        });
        analytics().logEvent(eventName, parameters);
    }

    renderTitle = (title) => {
        const { universalSearchString } = this.props.location;
        return (
            <Highlighter
                className='demo-menu-title margin-bottom-16'
                style={{ color: "black" }}
                searchWords={[universalSearchString]}
                autoEscape={true}
                textToHighlight={title}
                id='user-news-title'
            />
        )
    }

    renderTabContent = (news) => {
        const { timeZoneName } = this.props;
        return (
            <div>
                {news !== undefined && <Card style={{ overflowY: "auto", height: this.state.height, background: "#fafafa" }}>
                    {news?.coverImagePath
                        ? <img className="bottom-spacing-2 news-img" id="user-news-thumbnail" src={news?.coverImagePath} alt="News Media" />
                        : ""
                    }
                    <h2 >{this.renderTitle(news?.title)}</h2>
                    {news?.link && <a className="news-article-link-text no-underline" href={news?.link || ""} rel="noopener noreferrer" target="_blank" id='user-news-link'>{news?.linkText}</a>}
                    <span id='user-news-published-time' className="solution-name-title" style={{ color: "#5E5E5E" }}>{`Published ${moment.tz(news?.publishTime, timeZoneName).format(CONFIG.dateFormats.user)}`}</span>
                    <Divider style={{ margin: "10px 0" }} />
                    <div>
                        <HTML htmlContent={news?.intro}></HTML>
                        <br />
                        <HTML htmlContent={news?.body}></HTML>
                    </div>
                </Card>}
            </div>
        );
    }

    renderDesktopNewsMenu = (data) => {
        const { universalSearchString } = this.props.location;
        const { currentUserNews, timeZoneName, count } = this.props;

        return (
            <div className='font'>
                {((data && data?.length > 0) || ((universalSearchString || this.props.match.params?.newsId) && currentUserNews)) ? <Row>
                    <Col xl={universalSearchString ? 0 : 7}>
                        <div className='user-news-heading'><span>News</span></div>
                        {!universalSearchString && !this.props.match.params?.newsId &&
                            <div >
                                <div style={{ overflowY: "auto", height: this.state.height }}>
                                    <Menu
                                        className='news-menu'
                                        selectedKeys={currentUserNews !== undefined ? currentUserNews?.id?.toString() : ''} >
                                        {data?.map(news => {
                                            const avatar = news?.mediaPath !== null && news?.hasThumbnail === true && <Col style={{ marginTop: "5px" }} span={4}><Avatar size="large" src={news?.mediaPath} shape='square' id='user-news-image' /></Col>
                                            return (
                                                <Menu.Item
                                                    style={{ whiteSpace: 'normal', paddingLeft: "10px", borderBottom: "1px solid #C9C9C9", borderRadius: "0px", paddingRight: "0px" }}
                                                    id={news?.id?.toString()}
                                                    key={news?.id}
                                                    className='menu-display'
                                                    onClick={(key) => this.handleTabClick(key.key)}
                                                >
                                                    <Row>
                                                        {avatar}
                                                        <Col span={(news?.mediaPath !== null && news?.hasThumbnail === true) ? 20 : 24}>
                                                            <div title={news?.title} style={{ color: "black", marginTop: '8px' }} className='news-menu-title-2 margin-bottom-8'>
                                                                {news?.title}
                                                                {news?.isNew && <span className='float-right'>
                                                                    <Chip variant="info" style={{ fontWeight: "400", fontSize: "12px", fontFamily: 'Noto Sans', marginRight: "2px" }} >New</Chip>
                                                                </span>}
                                                            </div>
                                                            <div style={{ color: "#5E5E5E", marginBottom: "15px" }} className='news-time-info  margin-bottom-8'>
                                                                {`Published ${moment.tz(news?.publishTime, timeZoneName).format(CONFIG.dateFormats.user)}`}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Menu.Item>
                                            )
                                        })}
                                    </Menu>
                                </div>
                                {count && count > CONFIG.cardPageSize && count > data?.length &&
                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}><Button onClick={() => this.handleLoadMore()}>Load More News</Button></div>}
                            </div>
                        }

                    </Col>
                    <Col xl={(universalSearchString || this.props.match.params?.newsId) ? 24 : 17}>
                        {this.state.infoLoading ? <Spin style={{ width: "100%", justifyContent: "center", alignItems: "center", height: this.state.height, display: "flex" }} indicator={antIcon} spinning={this.state.infoLoading} />
                            : this.renderTabContent(currentUserNews)
                        }
                    </Col>
                </Row> : <div className='vertical-spacing-2'>
                    <div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />
                        No News Articles
                    </div></div>}
            </div>
        );
    }

    render() {
        const { userAllNews } = this.props;
        const { loading } = this.state;
        const data = userAllNews;
        return (
            <Spin indicator={antIcon} spinning={loading}>
                {
                    this.renderDesktopNewsMenu(data)
                }
            </Spin>
        )
    }
}

const mapStateToProps = ({ viewport, user, userNews }) => {
    return {
        timeZoneName: user.profile ? user.profile.timezone : '',
        currentUserNews: userNews.currentUserNews,
        viewport,
        user: user.profile,
        userAllNews: userNews?.userAllNews,
        count: userNews?.count
    };
};

export default connect(mapStateToProps, { selectTitle, fetchUserNews, clearInactiveNews, updateCurrentUserNews, toggleBackButton, clearNews, clearCurrentNews, fetchNews, fetchAllUserNews })(News);
