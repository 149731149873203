import React from 'react';
import { connect } from 'react-redux';
import { Spin } from "antd"

import {
    toggleBackButton,
    fecthDemoPurposeTypesLite,
    fetchVerticalSolutionsLite,
    clearDemoScheduler,
    getUserDemoById,
    getUserScheduleById,
    getSchedulesByBatch,
    clearSolutionDemos,
    toggleHasUnsavedChangesFlag,
    clearAllEvents,
    updateCurrentDemoSchedule,
    updateSchedulerTimezone,
    getUserDemoByIdLite,
    getUserScheduleByIdLite
} from '../../../actions';
import NeoStandardDemoScheduleForm from './NeoStandardDemoScheduleForm';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class NeoScheduleStandardDemo extends React.Component {

    state = {
        hasChanged: true,
        hasTimeChanged: true,
        demo: undefined,
        loading: true,
        schedule: null
    }

    async componentDidMount() {
        const { fromManageSide, isBatchEdit, batchId } = this.props.match.params;
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, true);
        setTimeout(async () => {
            const isValidView = window.location.pathname.includes("/scheduler/standard")
            if (isValidView) {
                await this.props.fecthDemoPurposeTypesLite();
                await this.props.fetchVerticalSolutionsLite();
                const isCreate = window.location.pathname.includes("new");
                if (isCreate) {
                    await getUserDemoByIdLite(this.props.match.params.demoId)
                        .then((response) => {
                            this.setState({ demo: response, loading: false })
                        })
                }
                else {
                    if (isBatchEdit && batchId) {
                        await getSchedulesByBatch(this.props.match.params.scheduleId, this.props.match.params.batchId)
                            .then(async (response) => {
                                await this.props.updateCurrentDemoSchedule(response, false);
                                this.setState({ demo: response?.demo, loading: false, schedule: response })
                            })
                    }
                    else {
                        await getUserScheduleByIdLite(this.props.match.params.scheduleId, fromManageSide === 'true' ? true : false)
                            .then(async (response) => {
                                await this.props.updateCurrentDemoSchedule(response, false);
                                this.setState({ demo: response?.demo, loading: false, schedule: response })
                            })
                    }
                }
            }
        }, 100)
    }

    componentWillUnmount() {
        this.props.clearDemoScheduler();
        this.props.clearSolutionDemos();
        this.props.updateSchedulerTimezone(this.props.userTimeZone);
        this.props.clearAllEvents();
        this.setState({
            demo: undefined,
            loading: true,
            schedule: null
        })
    }

    handleTimeZoneChange = (flag) => {
        this.setState({
            hasChanged: flag
        })
    }

    handleTimeChange = (flag) => {
        setTimeout(() => {
            this.setState({
                hasTimeChanged: flag
            })
        }, 0)
    }

    render() {
        const pathValues = this.props.location.pathValues;
        const { isReschedule } = this.props.match.params;
        const demo = this.state.demo;
        return (
            <div className="font">
                <Spin indicator={antIcon} spinning={!this.state.hasTimeChanged}>
                    {this.state.demo?.id ? <div>
                        <NeoStandardDemoScheduleForm
                            demo={demo}
                            timeZoneChanged={this.handleTimeZoneChange}
                            handleTimeChange={this.handleTimeChange}
                            pathValues={pathValues}
                            isReschedule={isReschedule === 'true' ? true : false}
                            match={this.props.match}
                        />
                    </div> : <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spin indicator={antIcon} spinning={this.state.loading} />
                    </div>}
                </Spin>

            </div>
        );
    }
}

const mapStateToProps = ({ newSchedule, tabsLayout, user }) => {
    return {
        newEvents: newSchedule.newEvents,
        activeKey: tabsLayout.activeKey,
        userTimeZone: user.profile ? user.profile.timezone : undefined,
    };
};

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        fecthDemoPurposeTypesLite,
        clearDemoScheduler,
        getUserDemoById,
        getUserScheduleById,
        clearSolutionDemos,
        fetchVerticalSolutionsLite,
        toggleHasUnsavedChangesFlag,
        updateCurrentDemoSchedule,
        clearAllEvents,
        getSchedulesByBatch,
        updateSchedulerTimezone,
        getUserDemoByIdLite,
        getUserScheduleByIdLite
    }
)(NeoScheduleStandardDemo);