import React from 'react';
import { Col, Row, Form, Checkbox } from 'antd';
import CONFIG from '../../config';
const EmailUpdates = ({ form, showIsOverride, colSpan, eapOfflineObject, initialValue }) => {
    const [showMaintenanceDowntimeToggle, MaintenanceDowntimeValue, handleEAPOfflineCheckBoxChange, EAPCheckboxEnabled, isEditMode] = eapOfflineObject || []
    const { getFieldDecorator } = form;
    return (
        <React.Fragment>
            <Row className="new-domain-form-item">
                <Col span={24}><span className="large-text text-color-black">Notification and Email Updates:</span></Col>
            </Row>
            <Form>
                <Row gutter={24}>
                    {showMaintenanceDowntimeToggle && <Col className="gutter-row user-details" span={colSpan}>
                        <Form.Item>
                            {getFieldDecorator("eapOffline", {
                                valuePropName: "checked", initialValue: MaintenanceDowntimeValue
                            })
                                (<Checkbox disabled={isEditMode} onChange={e => handleEAPOfflineCheckBoxChange(e.target.checked)} className="medium-text text-color-black email-updates">{CONFIG.emailUpdates.maintenanceDowntime}</Checkbox>)}
                        </Form.Item>
                    </Col>}
                    {!EAPCheckboxEnabled && <Col className="gutter-row user-details" span={colSpan}>
                        <Form.Item disabled={showMaintenanceDowntimeToggle}>
                            {getFieldDecorator("notifyUsers", {
                                valuePropName: "checked", initialValue: initialValue ? initialValue[0] : false
                            })
                                (<Checkbox className="medium-text text-color-black email-updates">{CONFIG.emailUpdates.notifyUsers}</Checkbox>)}
                        </Form.Item>
                    </Col>}
                    {!EAPCheckboxEnabled && showIsOverride && <Col className="gutter-row user-details" span={12}>
                        <Form.Item disabled={showMaintenanceDowntimeToggle}>
                            {getFieldDecorator("isOverride", { valuePropName: "checked", initialValue: initialValue ? initialValue[1] : false })
                                (<Checkbox className="medium-text text-color-black email-updates">{CONFIG.emailUpdates.isOverride}</Checkbox>)}
                        </Form.Item>
                    </Col>}
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default EmailUpdates;