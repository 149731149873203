import CONFIG from '../config';

export const download = (fileName, data) => {
    let url = window.URL.createObjectURL(new Blob([data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

export const getFileName = (link) => {
    const splitLink = link ? link.split(CONFIG.delimiters.filePath) : [];
    let fileName = splitLink.length !== 0 ? splitLink[splitLink.length - 1] : "File Not Found";
    const delimiterFirstIndex = fileName.indexOf('_');
    fileName = splitLink.length !== 0 ? fileName.substring(delimiterFirstIndex + 1, fileName.length) : fileName;
    return fileName;
}