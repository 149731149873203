import {
    FETCH_DIDS,
    UPDATE_SELECTED_DIDS_DATACENTER,
    UPDATE_DIDS_CURRENT_PAGE,
    TOGGLE_IS_FETCHING_DIDS,
    UPDATE_DID_EDITING_KEY,
    CLEAR_DIDS,
    FETCH_NETWORK_ENVIRONMENTS,
    UPDATE_DID_UPLOAD_CRITERIA,
    UPDATE_DIDS_SELECTED_ROW_KEYS,
    TOGGLE_IS_UPLOADING_DOWNLOADING_FLAG,
    CLEAR_DIDS_TABLE_DATA,
    ADD_DID,
    REMOVE_EMPTY_DIDS,
    FETCH_PROVIDERS,
    UPDATE_PROVIDER_EDITING_KEY,
    ADD_PROVIDER,
    REMOVE_EMPTY_PROVIDER,
    CLEAR_DIDS_RESPONSE,
    TOGGLE_IS_COLLAPSED_STATUS,
    CURRENT_DIDS_FILTER
} from '../actions/types';
import CONFIG from '../config';

const didsState = {
    isFetching: false,
    currentPage: 1,
    editingKey: "",
    filter: undefined,
    selectedRowKeys: [],
    uploadType: CONFIG.didUploadType.full,
    isUploadingOrDownloading: false
};

export const didsReducer = (state = didsState, action) => {
    switch (action.type) {
        case FETCH_DIDS:
            return { ...state, data: action.payload.dataSet, count: action.payload.count, response: action.payload.response };
        case UPDATE_SELECTED_DIDS_DATACENTER:
            return { ...state, dataCenter: action.payload };
        case UPDATE_DIDS_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case TOGGLE_IS_FETCHING_DIDS:
            return { ...state, isFetching: action.payload };
        case UPDATE_DID_EDITING_KEY:
            return { ...state, editingKey: action.payload };
        case FETCH_NETWORK_ENVIRONMENTS:
            return { ...state, networkEnvironments: action.payload }
        case CLEAR_DIDS:
            return didsState
        case CLEAR_DIDS_TABLE_DATA:
            return { ...state, data: [], count: undefined }
        case UPDATE_DID_UPLOAD_CRITERIA:
            return { ...state, uploadType: action.payload };
        case UPDATE_DIDS_SELECTED_ROW_KEYS:
            return { ...state, selectedRowKeys: action.payload };
        case TOGGLE_IS_UPLOADING_DOWNLOADING_FLAG:
            return { ...state, isUploadingOrDownloading: action.payload };

        case ADD_DID:
            return state.data
                ? { ...state, data: [action.payload, ...state.data], editingKey: action.payload.id }
                : { ...state, data: [action.payload], editingKey: action.payload.id };
        case REMOVE_EMPTY_DIDS:
            return { ...state, data: state.data.filter(did => did.did !== "") }
        case CLEAR_DIDS_RESPONSE:
            return { ...state, response: action.payload };
        default:
            return state;
    }
};

const providersState = {
    isFetching: false,
    editingKey: "",
    isEdit: false
};

export const providersReducer = (state = providersState, action) => {
    switch (action.type) {
        case FETCH_PROVIDERS:
            return { ...state, [action.payload.key]: action.payload.data };
        case UPDATE_PROVIDER_EDITING_KEY:
            return { ...state, editingKey: action.payload };
        case ADD_PROVIDER:
            return state.data
                ? { ...state, data: [action.payload, ...state.data], editingKey: action.payload.id }
                : { ...state, data: [action.payload], editingKey: action.payload.id };
        case REMOVE_EMPTY_PROVIDER:
            return { ...state, data: state.data.filter(provider => provider.title !== "") };
        case TOGGLE_IS_COLLAPSED_STATUS:
            return {
                ...state,
                isCollapsed: action.payload
            };
        case CURRENT_DIDS_FILTER:
            return {
                ...state,
                status: action.payload,
                isEdit: true
            };
        default:
            return state;
    }
};