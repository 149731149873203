import React, { useEffect } from 'react'
import { Table, Typography } from "antd";
import { DIDTableCell } from './DIDTableCell';
const { Text } = Typography

const components = {
    body: {
        cell: DIDTableCell
    }
};

const DIDTableContent = ({ dataSource, columns, loading, handleChange, rowSelection, didCount, handleFetchDids, flag, handleFlagChange }) => {
    useEffect(() => {
        //document.getElementById('container').scrollIntoView();

        var tableContent = document.querySelector('.ant-table-body');
        tableContent && tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight
            let currentScroll = event.target.scrollTop
            if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (maxScroll !== 0 && currentScroll !== 0)) {
                handleFlagChange();
            }
        })
    }, [handleFlagChange]);

    useEffect(() => {
        flag === 1 && handleFetchDids();
    }, [flag, handleFetchDids])

    return (
        <div>
            <Table
                size='middle'
                rowSelection={rowSelection}
                rowKey={(record) => record.id}
                className="responsive-container"
                components={components}
                bordered
                scrollToFirstRowOnChange={true}
                scroll={{ y: 550, scrollToFirstRowOnChange: false, x: true | 600 }}
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                onChange={handleChange}
                pagination={false}
                footer={() => <>{dataSource?.length !== 0 && didCount !== undefined && <div style={{ textAlign: 'center' }}>
                    <Text strong>Fetched {dataSource?.length} out of {didCount} DIDs</Text>
                </div>}
                </>}
            />
        </div>
    )
}
export default DIDTableContent;