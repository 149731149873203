import React, { Component } from 'react'
import {
    selectTitle,
    toggleBackButton,
    fetchUserStandardReports,
    toggleIsFetchingUserReports,
    exportTable,
    fetchReport,
    clearSaveReports,
    resetReport,
    saveReport,
    printReport,
    postSaveAsReport,
    clearUserReports,
    fetchTrendingDemos,
    getReportById,
    closeCurrentOpenTab,
    exportCommonStandardReport
} from '../actions';
import pushMessage from './common/PushMessage';
import { Form, Spin, Row, Col, Divider, Collapse, message, Table, Modal, Input, DatePicker } from 'antd';
import { Dropdown } from 'antd5';
import { batch, connect } from 'react-redux';
import CONFIG from "../config"
import RefineReport from './common/RefineReport';
import openConfirm from './common/Confirm';
import moment from 'moment-timezone';
import { getReportExportData } from "../actions/reports";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { getTrendingTableData, getTrendingColumns } from "./common/ReportExport";
import StatusIcon from './common/StatusIcon';
import TrendingDemos from "./Dashboard/TrendingDemos";
import { Button, Icon as NeoIcon } from "@avaya/neo-react"
import { LoadingOutlined } from '@ant-design/icons';
import ReportOutputDetails from './common/ReportOutputDetails';
import RefineReportNeo from './ManageReportsNeo/RefineReportNeo';
import ReportOutputGraph from './ManageReportsNeo/ReportOutputGraph';
import { createTableColumns, createCommonFilterObject, createTableColumnsUserDistribution } from "../utils/reports";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const ViewReport = Form.create({ name: "view_report" })(
    class ViewReport extends Component {
        constructor(props) {
            super(props);
            this.state = {
                isPrint: false,
                modalVisible: false,
                modalLoading: false
            }
        }
        filterSort = {}
        printData = undefined
        report = {}
        componentDidMount() {
            // const { activeKey } = this.props;
            document.getElementById("tab-pane").scrollIntoView();
            batch(async () => {
                this.props.toggleBackButton(false);
                const { reportId, isMyReport } = this.props.match.params;
                this.report = await this.props.getReportById(reportId);
                this.props.selectTitle((isMyReport !== undefined && JSON.parse(isMyReport)) ? "My Reports" : "Standard Reports");
                const userRun = "fromUser";
                this.props.toggleIsFetchingUserReports(true)
                await this.props.fetchReport(reportId, userRun, this.report?.default, null, null, this.report)
            })
        }


        async componentWillUnmount() {
            await this.props.clearUserReports();
            this.props.toggleBackButton(false);
        }

        getOperatorValue = (columnType, value, operator) => {
            const { timeZoneName } = this.props;
            switch (columnType) {
                case CONFIG.formFieldTypes.MULTISELECT:
                case CONFIG.formFieldTypes.PACKAGE:
                case CONFIG.formFieldTypes.AUTOCOMPLETE:
                    return value.join('\\\\')
                case CONFIG.formFieldTypes.DATE:
                    if (Array.isArray(value)) {
                        const startTime = value[0].tz(timeZoneName, true).startOf('day').toISOString();
                        const endTime = value[1].tz(timeZoneName, true).endOf('day').toISOString();
                        return startTime + '@' + endTime
                    }
                    return value.tz(timeZoneName, true).startOf('day').toISOString()
                case CONFIG.formFieldTypes.NUMBER:
                    return value?.toString();
                case CONFIG.formFieldTypes.MONTH:
                    if (operator === "<" || operator === ">=") return value.startOf('month').startOf('day').toISOString();
                    else if (operator === "<=" || operator === ">") return value.endOf('month').endOf('day').toISOString();
                    else return value.startOf('month').startOf('day').toISOString() + "@" + value.endOf('month').endOf('day').toISOString();
                case CONFIG.formFieldTypes.DURATION:
                    let minutes = value.Days ? value.Days * 24 * 60 : 0
                    minutes += value.Hours ? value.Hours * 60 : 0
                    minutes += value.Minutes ? value.Minutes : 0
                    return minutes.toString()
                default:
                    return value
            }
        }

        checkEqualFilterSort = (oldFilter, currentFilter) => {
            const keys1 = Object.keys(oldFilter);
            const keys2 = Object.keys(currentFilter);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (const key of keys1) {
                const val1 = oldFilter[key];
                const val2 = currentFilter[key];
                const areObjects = this.isObject(val1) && this.isObject(val2);
                if (
                    (areObjects && !this.checkEqualFilterSort(val1, val2)) ||
                    (!areObjects && val1 !== val2)
                ) {
                    return false;
                }
            }
            return true;
        }

        isObject = (object) => {
            return object != null && typeof object === 'object';
        }

        handleSave = () => {
            this.props.form.validateFields(async (err, values) => {
                if (err === null) {
                    const { timeZoneName } = this.props;
                    const report = this.report;
                    this.createFilterSortObject(report, timeZoneName);
                    let currentFilterSort = JSON.parse(report?.filterSortObject);
                    const isEqual = this.checkEqualFilterSort(currentFilterSort, this.filterSort)
                    if (isEqual) {
                        pushMessage(CONFIG.messageType.info, "No changes made");
                        return;
                    }
                    else {
                        delete report.filterSortObject;
                        let currentReportFilter = {
                            ...report,
                            filterSortObject: JSON.stringify(this.filterSort)
                        }
                        await this.props.saveReport(currentReportFilter);
                        this.props.closeCurrentOpenTab();
                    }
                }
            });
        }

        handleDelete = async () => {
            const report = this.report;
            await this.props.resetReport(report.id);
            this.props.closeCurrentOpenTab()
        }

        handleTablePrint = async (handlePrint) => {
            this.props.form.validateFields(async (err) => {
                if (err === null) {
                    const report = this.report;
                    const { timeZoneName } = this.props;
                    this.createFilterSortObject(report, timeZoneName);
                    const data = await this.props.printReport(report?.id, this.filterSort, report?.filterTable?.tableName?.replaceAll(" ", ""), "fromUser");
                    this.printData = data?.dataSet;
                    this.setState({ isPrint: true }, () => { handlePrint() })
                    document.title = report.name;
                }
            });
        }

        createFilterSortObject = (report, timeZoneName) => {
            const { currentReport } = this.props;
            const readOnlyAccess = currentReport?.filerAccess === CONFIG.roleTemplateAccess.readOnly
            const filterSortObject = report?.filterSortObject ? JSON.parse(report.filterSortObject) : {}
            const isDemoUsagePerQuarter = report?.default && (report?.reportKey === CONFIG.standardReportKeys.demoUsage) ? true : false;
            const isDemoUsagePerQuarterGrouped = report?.default && (report?.reportKey === CONFIG.standardReportKeys.demoUsageSolutionDemo) ? true : false;
            const isDemoScheduleTrend = report?.default && (report?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend) ? true : false;
            this.props.form.validateFields((err, values) => {
                this.filterSort = { ...this.filterSort, filter: {} }
                if (!err) {
                    if (readOnlyAccess) this.filterSort = { ...filterSortObject }
                    if (!report.default) {
                        if (values?.startEndTime?.length > 0) {
                            if (report.filterTable?.id === 16 || report.filterTable?.tableName === "Daily Test Report") {
                                this.filterSort = {
                                    ...this.filterSort,
                                    filter: {
                                        ...this.filterSort?.filter,
                                        createdTime: {
                                            operator: 'IN',
                                            oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() + "@" + values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                                        },
                                    }
                                }
                            }
                            else {
                                this.filterSort = {
                                    ...this.filterSort,
                                    filter: {
                                        ...this.filterSort?.filter,
                                        startTime: {
                                            operator: '>=',
                                            oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() : undefined
                                        },
                                        endTime: {
                                            operator: '<',
                                            oprVal: values.startEndTime ? values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                                        },
                                    }
                                }
                            }
                        }
                        values.keys.forEach(k => {
                            this.filterSort = {
                                ...this.filterSort,
                                filter: {
                                    ...this.filterSort?.filter,
                                    [JSON.parse(values.names[(k * 3) + 1]).columnKey]: {
                                        operator: values.names[(k * 3) + 2],
                                        oprVal: this.getOperatorValue(JSON.parse(values.names[(k * 3) + 1])?.columnType, values.names[(k * 3) + 3], values.names[(k * 3) + 2])
                                    },
                                }
                            }
                        })
                    }
                    else {
                        if (!isDemoUsagePerQuarter && !isDemoUsagePerQuarterGrouped && !isDemoScheduleTrend) {
                            this.filterSort = {
                                ...this.filterSort,
                                filter: {
                                    ...this.filterSort?.filter,
                                    ...(values.startEndTime && {
                                        startTime: {
                                            operator: '>=',
                                            oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() : undefined
                                        }
                                    }),
                                    ...(values.startEndTime && {
                                        endTime: {
                                            operator: '<',
                                            oprVal: values.startEndTime ? values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                                        }
                                    })
                                }
                            }
                        } else {
                            if (readOnlyAccess) this.filterSort = { ...filterSortObject }
                            else this.filterSort = createCommonFilterObject(isDemoUsagePerQuarter ? { quarters: values.quarters, regions: values.regions, users: values.users, year: values.year, demoPurposes: values.demoPurposes } : { quarters: values.quarters, type: values.type, users: values.users, year: values.year, [values.type]: values?.[values.type], demoPurposes: values.demoPurposes, reportType: values?.reportType, isTotalRequired: values?.isTotalRequired }) || {};
                        }
                    }
                }
            });
        }

        handleExport = async (report) => {
            this.props.form.validateFields(async (err, values) => {
                if (err === null) {
                    const { timeZoneName } = this.props;
                    const userRunReport = "fromUser";
                    let reportName = report?.name;
                    reportName = reportName + ".xlsx";
                    const isDefault = report?.default;
                    let reportData;
                    pushMessage(CONFIG.messageType.loading, "Exporting", 0);
                    this.createFilterSortObject(report, timeZoneName);
                    if (!isDefault) {
                        reportData = await getReportExportData({ reportId: report?.id, userRunReport, filterSort: this.filterSort });
                    }
                    (!isDefault ?
                        exportTable(CONFIG.exportTable.report.name, reportData.dataSet, timeZoneName, reportName, "xlsx", reportData.exportViewColumns)
                        : this.props.exportCommonStandardReport({ name: reportName, filterSort: this.filterSort, userRunReport, reportId: report?.id, reportKey: report?.reportKey })
                    ).then((response) => {
                        message.destroy()
                        const isExport = response.status === CONFIG.HTTP_STATUS.OK
                        pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Report exported successfully" : "There is no data to export. Please verify the filters")
                    })
                        .catch(() => {
                            message.destroy()
                            pushMessage(CONFIG.messageType.error, "Unable to export report")
                        })
                }
            });
        }

        closePrint = () => {
            this.setState({ isPrint: false })
            this.printData = undefined
        }

        getColumns = (columnsData, isGeneralReport) => {
            const { timeZoneName, generalReportData } = this.props

            if (isGeneralReport) {
                const columns = generalReportData?.columns?.map((item) => {
                    return {
                        key: item,
                        dataIndex: item,
                        title: item,
                        align: 'center'
                    };
                })
                return columns ? [{ key: "type", dataIndex: "type", title: " ", align: 'left' }, ...columns] : [];
            }
            else
                return (columnsData?.map(column => {
                    const columnKeySplit = column.columnKey.split(".")
                    const shouldAlignCenter = column?.columnType === CONFIG.formFieldTypes.DATE || column?.columnType === CONFIG.formFieldTypes.MONTH || column?.columnType === CONFIG.formFieldTypes.NUMBER || column?.columnType === CONFIG.formFieldTypes.BOOLEAN
                    return {
                        ...column,
                        dataIndex: column.columnKey,
                        key: column.columnKey,
                        title: column.columnName,
                        align: shouldAlignCenter ? "center" : "left",
                        width: '10%',
                        sorter: true,
                        render: (value, item) =>
                            columnKeySplit.length === 3 && Array.isArray(item[columnKeySplit?.[0]])
                                ? item[columnKeySplit?.[0]]?.length > 0 ? item[columnKeySplit?.[0]]?.reduce((acc, item) => { acc.push(item[columnKeySplit?.[1]]?.[columnKeySplit?.[2]]); return acc }, [])?.join(", ") : "-"
                                : columnKeySplit.length === 2 && Array.isArray(item[columnKeySplit?.[0]])
                                    ? item[columnKeySplit?.[0]]?.reduce((acc, item) => { acc.push(item[columnKeySplit?.[1]]); return acc }, [])?.join(", ")
                                    : column?.columnType === CONFIG.formFieldTypes.DATE
                                        ? value ? moment.tz(value, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"
                                        : column?.columnType === CONFIG.formFieldTypes.BOOLEAN
                                            ? <StatusIcon status={value} />
                                            : (column?.columnType === CONFIG.formFieldTypes.SINGLESELECT && column?.filterTable?.id === 11)
                                                ? CONFIG.demoStatus.types[value]
                                                : value ? value : "-"
                    }
                }))
        }

        getTableData = () => {
            const { generalReportData } = this.props
            const demoType = generalReportData?.data?.length > 0 && generalReportData?.data[0].hasOwnProperty('demoCount') ? "demoCount" : "demoHours";
            const countType = demoType === "demoCount" ? "scheduleCount" : "hoursScheduled"
            const rows = generalReportData?.data?.map((item, index) => {
                return {
                    id: index, type: item.subject, ...item[demoType]?.reduce((acc, item) => {
                        acc[item.month] = parseFloat(item[countType]);
                        return acc
                    }, {})
                }
            })
            return rows || [];
        }

        handleModalOpen = () => {
            this.props.form.validateFields((err, values) => {
                if (err === null) {
                    this.setState({ modalVisible: true })
                }
            });
        }

        handleModalClose = () => {
            this.setState({ modalVisible: false })
        }

        handleOk = async () => {
            const report = this.report;
            const { timeZoneName } = this.props;
            let reportName;
            this.props.form.validateFields((err, values) => {
                reportName = values?.reportName;
            });
            reportName = reportName?.trim();
            if (reportName?.length > 0 && !this.state.modalLoading) {
                this.createFilterSortObject(report, timeZoneName);
                await this.setState({ modalLoading: true })
                await this.props.postSaveAsReport(reportName, this.filterSort, report);
                await this.setState({ modalLoading: false })
                this.handleModalClose();
            }
            else {
                pushMessage(CONFIG.messageType.warning, "Please Enter the report name")
            }
        }

        myReportButtons = () => {
            const { isMyReport } = this.props.match.params;
            if (JSON.parse(isMyReport) === false) {
                return (
                    <span>
                        <Button onClick={this.handleModalOpen} className=" left-spacing-5" variant="secondary" >Save As</Button>
                    </span>
                )
            }
            else {
                return (
                    <>
                        <span>
                            <Button onClick={this.handleSave} className=" left-spacing-5" variant="secondary" >Save</Button>
                        </span>
                    </>
                )
            }
        }

        renderReportAdditionalButtons = (report) => {
            const { isMyReport } = this.props.match.params;

            const items = [
                {
                    key: 'close',
                    label: (
                        <span className='full-width-options'
                            onClick={() => this.props.closeCurrentOpenTab()}
                        >
                            <NeoIcon icon="end" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} />
                            <span style={{ fontSize: '13px' }}>Close</span>
                        </span>
                    )
                },
                {
                    key: 'print',
                    label: (
                        <span className='full-width-options'>
                            <ReactToPrint
                                content={() => this.componentRef}
                                documentTitle={report?.name}
                                onAfterPrint={this.closePrint}
                                pageStyle={CONFIG.dashboard.schedulePrintStyle}>
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (<span onClick={() => this.handleTablePrint(handlePrint)}>
                                        <NeoIcon icon="print" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} />
                                        <span style={{ fontSize: '13px' }}>Print</span>
                                    </span>
                                    )}
                                </PrintContextConsumer>
                            </ReactToPrint>
                        </span>
                    )
                },
                JSON.parse(isMyReport) === true && {
                    key: 'delete',
                    label: (
                        <span className='full-width-options'
                            onClick={this.renderActivationConfirm}
                        >
                            <NeoIcon icon="trash" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} />
                            <span style={{ fontSize: '13px' }}>Delete</span>
                        </span>
                    )
                }
            ];

            return (

                <span >
                    <Dropdown
                        // overlayClassName='my-schedules-table-actions-menu'
                        trigger="click"
                        menu={{
                            items,
                        }}
                        placement="bottomRight"
                        arrow
                    >
                        <NeoIcon icon="ellipses-vertical" className='pointer' style={{ fontSize: "24px", color: "#1B77AF" }} />
                    </Dropdown>
                </span>
            )
        }

        renderActivationConfirm = () => {
            openConfirm('Yes', this.handleDelete, null, "This report will be permanently deleted. You cannot undo this!");
        }

        handleTrendingDemosRunReport = (graphType) => {
            const trendingStartTime = this.props?.form?.getFieldsValue()?.startEndTime[0].toISOString();
            const trendingEndTime = this.props?.form?.getFieldsValue()?.startEndTime[1].toISOString();
            const timeLine = this.getTimeLine(trendingStartTime, trendingEndTime);
            this.props.fetchTrendingDemos({ graphType, startDate: trendingStartTime, timeline: timeLine, endDate: trendingEndTime })
        }

        getTimeLine = (startTime, endTime) => {
            let timeLine;
            const dateDiff = moment.tz(endTime, this.props.timeZoneName).diff(moment.tz(startTime, this.props.timeZoneName), 'days');
            if (dateDiff <= 7) {
                timeLine = CONFIG.trendingDemosTimeline[0]
            } else if (dateDiff > 7 && dateDiff <= 31) {
                timeLine = CONFIG.trendingDemosTimeline[1]
            } else {
                timeLine = CONFIG.trendingDemosTimeline[2]
            }
            return timeLine;
        }

        render() {
            const report = this.report;
            const { isMyReport } = this.props.match.params;
            const isTrendingDemo = report?.default && (report?.reportKey === CONFIG.standardReportKeys.trendingDemos) ? true : false;
            const isDemoUsagePerQuarter = report?.default && (report?.reportKey === CONFIG.standardReportKeys.demoUsage) ? true : false;
            const isDemoUsagePerQuarterGrouped = report?.default && (report?.reportKey === CONFIG.standardReportKeys.demoUsageSolutionDemo) ? true : false;
            const isDemoScheduleTrend = report?.default && (report?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend) ? true : false;
            const isUserDistribution = report?.default && (report?.reportKey === CONFIG.standardReportKeys.userDistribution) ? true : false;
            const { getFieldDecorator } = this.props.form;
            const reportName = <h3 className="center-align">{report?.name}</h3>
            const { columns, isFetching, generalReportData, currentReport, timeZoneName, tableData, columnData } = this.props;
            const defaultData = isTrendingDemo ? getTrendingTableData(tableData) : this.getTableData();
            const showRangePicker = CONFIG.reportDatePickerEntities.includes(report?.filterTable?.tableName);
            // const showRangePicker = report?.filterTable?.tableName === "Scheduled Demo" || report?.filterTable?.tableName === "Scheduled Maintenance" || report?.filterTable?.tableName === "Daily Test Report" || report?.filterTable?.tableName === "Daily Testing Audit";
            let shouldShowRefineReport = report?.filterSortObject && JSON.parse(report?.filterSortObject);
            shouldShowRefineReport = report?.filterSortObject && Object.keys(shouldShowRefineReport?.filter).length;
            this.tableColumns = isTrendingDemo ? getTrendingColumns(columnData) : this.getColumns(columns, report?.default) || [];
            const generalReportColumns = isUserDistribution ? createTableColumnsUserDistribution(generalReportData || [], this.props.form.getFieldsValue()) : createTableColumns(generalReportData ? generalReportData?.tableData : [], report?.reportKey);
            const fullAccess = currentReport?.filerAccess === CONFIG.roleTemplateAccess.fullAccess
            const shouldHideRunQuery = !(shouldShowRefineReport > 0 && currentReport?.filterSortObject) || (!showRangePicker && !fullAccess)
            const trendingStartTime = currentReport?.filterSortObject?.filter?.startTime?.oprVal && moment.tz(currentReport?.filterSortObject?.filter?.startTime.oprVal, this.props.timeZoneName).toISOString();
            const trendingEndTime = currentReport?.filterSortObject?.filter?.endTime?.oprVal && moment.tz(currentReport?.filterSortObject?.filter?.endTime.oprVal, this.props.timeZoneName).toISOString();
            this.ranges = {
                'Last Year': [moment.tz(timeZoneName).startOf('year').subtract(12, 'months'), moment.tz(this.props.timeZoneName).subtract(12, 'months').endOf('year')],
                'Last 6 Months': [moment.tz(timeZoneName).startOf('month').subtract(6, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
                'Last 3 Months': [moment.tz(timeZoneName).startOf('month').subtract(3, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
                'Last Month': [moment.tz(timeZoneName).startOf('month').subtract(1, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
                'Last Week': [moment.tz(timeZoneName).startOf('week').subtract(1, 'weeks').add(1, 'days'), moment.tz(this.props.timeZoneName).subtract(1, 'weeks').endOf('week').add(1, 'days')],
                'This Year': [moment.tz(timeZoneName).startOf('year'), moment.tz(this.props.timeZoneName).endOf('year')],
                'This Month': [moment.tz(timeZoneName).startOf('month'), moment.tz(this.props.timeZoneName).endOf('month')],
                'Tomorrow': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(1, 'days')],
                'Next 7 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(7, 'days')],
                'Next 14 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(14, 'days')],
                'Next 30 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(30, 'days')],
            }
            const rangePickerValue = report?.filterTable?.tableName === "Daily Test Report" ? currentReport?.filterSortObject?.filter?.createdTime ? currentReport?.filterSortObject?.filter?.createdTime?.oprVal?.split("@") : [] : []
            const firstColumn = isUserDistribution ? [] : [{
                title: isDemoUsagePerQuarter
                    ? "Region"
                    : this.filterSort?.filter?.type?.oprVal === "demos" ? "Demos" : "Solution Types"
                , dataIndex: "entityName", key: "entityName"
            }]
            const generalTableData = (isUserDistribution ? generalReportData : generalReportData?.tableData) || [];
            const actualColumns = generalReportColumns?.length > 0 ? generalTableData && generalTableData.length > 0 ? [...firstColumn, ...generalReportColumns] : [] : [];

            return (
                <Spin indicator={antIcon} spinning={report?.default ? generalReportData === undefined : isFetching} wrapperClassName="spin-overlay">
                    <div className="content-container">
                        <div className="vertical-spacing-3" >
                            <div className='reports-header-div'>
                                <div>
                                    <div className='view-reports-title right-spacing-5' >
                                        <span className='view-reports-title'><span style={{ color: "#1B77AF" }}>{JSON.parse(isMyReport) === true ? "My Reports " : "Standard Reports "}</span> / <span style={{ color: "#242424" }}>{report?.name}</span></span>
                                    </div>
                                </div>
                                <div>
                                    {this.renderReportAdditionalButtons(report)}
                                    {shouldShowRefineReport > 0 && currentReport?.filterSortObject && this.myReportButtons()}
                                    <span>
                                        <Button onClick={() => this.handleExport(report)} className="primary-action-button-filled left-spacing-5" variant="primary">Export</Button>
                                    </span>

                                </div>
                            </div>
                            <h4>{report?.description}</h4>

                        </div >
                        {shouldShowRefineReport > 0 && currentReport?.filterSortObject && showRangePicker && <Row>
                            <Col xl={8} sm={8} xs={24}>
                                <Form.Item label={report?.filterTable?.tableName === "Daily Test Report" ? "Create Time" : "Start/End Time"}>
                                    {getFieldDecorator("startEndTime", {
                                        initialValue: report?.filterTable?.tableName === "Daily Test Report"
                                            ? [moment.tz(rangePickerValue[0], this.props.timeZoneName), moment.tz(rangePickerValue[1], this.props.timeZoneName)]
                                            : currentReport?.filterSortObject?.filter?.startTime ? [moment.tz(currentReport?.filterSortObject?.filter?.startTime.oprVal, this.props.timeZoneName), moment.tz(currentReport?.filterSortObject?.filter?.endTime.oprVal, this.props.timeZoneName)] : undefined,
                                        rules: [
                                            {

                                                type: 'array',
                                                required: true,
                                                whitespace: true,
                                                message: "Please select a value",
                                            },
                                        ],
                                    })(<RangePicker
                                        ranges={this.ranges}
                                        allowClear={false}
                                    />)
                                    }
                                </Form.Item>
                            </Col>

                        </Row>}

                        <div >
                            {shouldShowRefineReport > 0
                                && currentReport?.filterSortObject
                                && (report?.default || fullAccess)
                                && !(!report?.default && fullAccess && shouldShowRefineReport === 2 && currentReport?.filterSortObject?.filter?.startTime && currentReport?.filterSortObject?.filter?.endTime)
                                && !isDemoUsagePerQuarter && !isDemoUsagePerQuarterGrouped && !isDemoScheduleTrend && !isUserDistribution && <Collapse expandIconPosition='right' defaultActiveKey="1" >
                                    <Panel header={<span className="large-text" >Refine Report</span>} key="1" forceRender={true}>
                                        <RefineReport isEditMode={true} isGeneralReport={report?.default} form={this.props.form} userRun="fromUser" fullAccess={fullAccess} />
                                    </Panel>
                                </Collapse>}
                            {shouldShowRefineReport > 0
                                && currentReport?.filterSortObject
                                && report?.default
                                && fullAccess
                                && (isDemoUsagePerQuarter || isDemoUsagePerQuarterGrouped || isDemoScheduleTrend || isUserDistribution) &&
                                <RefineReportNeo
                                    params={this.props.match.params}
                                    isGeneralReport={report?.default}
                                    form={this.props.form}
                                    isCloneReport={false}
                                    currentReport={currentReport}
                                    isRunByUser={"fromUser"}
                                />
                            }
                            <Divider />
                            {currentReport?.viewColumnsJson && !isTrendingDemo && !isDemoUsagePerQuarter && !isDemoUsagePerQuarterGrouped && !isDemoScheduleTrend && !isUserDistribution && <ReportOutputDetails form={this.props.form} reportObject={report} userRun="fromUser" isGeneralReport={report?.default ? true : false} shouldHideRunQuery={shouldHideRunQuery} />}
                            {isTrendingDemo && trendingStartTime && trendingEndTime && <TrendingDemos shouldHideRunQuery={shouldHideRunQuery}
                                userRun="fromUser"
                                startTime={trendingStartTime}
                                endTime={trendingEndTime}
                                handleTrendingDemosRunReport={this.handleTrendingDemosRunReport}
                                filterSort={this.filterSort}
                                timeLine={this.getTimeLine(trendingStartTime, trendingEndTime)} />}
                            {(isDemoUsagePerQuarter || isDemoUsagePerQuarterGrouped || isDemoScheduleTrend || isUserDistribution) && currentReport &&
                                <ReportOutputGraph
                                    form={this.props.form}
                                    params={this.props.match.params}
                                    isCloneReport={false}
                                    isGeneralReport={report?.default}
                                    currentReport={currentReport}
                                    isRunByUser={"fromUser"}
                                    shouldHideRunQuery={shouldHideRunQuery}
                                />
                            }
                        </div >
                        {
                            this.state.isPrint && !isDemoUsagePerQuarter && !isDemoUsagePerQuarterGrouped && !isDemoScheduleTrend && !isUserDistribution &&
                            <Table
                                size='middle'
                                ref={el => (this.componentRef = el)}
                                title={() => reportName}
                                className="responsive-container ant-table-wrapper"
                                rowKey={(report) => report?.id}
                                bordered
                                columns={this.tableColumns}
                                dataSource={report?.default ? defaultData : this.printData}
                                pagination={false}
                            />
                        }
                        {
                            this.state.isPrint && (isDemoUsagePerQuarter || isDemoUsagePerQuarterGrouped || isDemoScheduleTrend || isUserDistribution) &&
                            <Table
                                ref={el => (this.componentRef = el)}
                                title={() => reportName}
                                columns={actualColumns}
                                dataSource={(isUserDistribution ? generalReportData : generalReportData?.tableData) || []}
                                bordered
                                pagination={false}
                                size="middle"
                            />
                        }
                        {this.state.modalVisible &&
                            <Modal
                                title="Save As"
                                visible={this.state.modalVisible}
                                onCancel={this.handleModalClose}
                                onOk={this.handleOk}
                                footer={[
                                    <Button key="back" onClick={this.handleModalClose}>
                                        Cancel
                                    </Button>,
                                    <Button disabled={this.state.modalLoading} key="submit" className="primary-action-button-filled" type="primary" onClick={this.handleOk}>
                                        Save
                                    </Button>,
                                ]}
                            >
                                <Spin indicator={antIcon} spinning={this.state.modalLoading}>
                                    <Form.Item label="Report Name">
                                        {getFieldDecorator("reportName", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please input the report name"
                                                }
                                            ],
                                        })(<Input />)}
                                    </Form.Item>
                                </Spin>
                            </Modal>
                        }
                    </div >
                </Spin >
            )
        }
    }
)
const mapStateToProps = ({ user, userReports, tabsLayout, trendingDemos }) => {
    const actualReport = userReports?.details;
    return {
        isFetching: userReports.isFetching,
        reportColumns: actualReport?.reportColumns,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        columns: actualReport?.reportColumns,
        data: userReports.runReportData,
        generalReportData: userReports.runGeneralReportData,
        currentReport: actualReport,
        activeKey: tabsLayout.activeKey,
        tableData: trendingDemos?.graphData?.data || [],
        columnData: trendingDemos?.graphData?.columns || []
    }
}

export default connect(mapStateToProps, {
    selectTitle,
    toggleBackButton,
    fetchUserStandardReports,
    toggleIsFetchingUserReports,
    exportTable,
    fetchReport,
    clearSaveReports,
    resetReport,
    saveReport,
    printReport,
    postSaveAsReport,
    clearUserReports,
    fetchTrendingDemos,
    getReportById,
    closeCurrentOpenTab,
    exportCommonStandardReport
})
    (ViewReport);
