import React from 'react';
import { Button } from '@avaya/neo-react';

function FormActionButtonsNeo(props) {
    return (
        <React.Fragment>
            <Button size={props.size || "default"} variant="secondary" onClick={props.handleCancel} >
                {props.cancelText ? props.cancelText : 'Cancel'}
            </Button>
            <Button size={props.size || "default"} variant="primary" disabled={props.isDisabled} className={`left-spacing`} onClick={props.handleSubmit}>
                {props.okText ? props.okText : 'Create'}
            </Button>
        </React.Fragment>
    )
}

export default FormActionButtonsNeo