import React from "react";
import { Tabs, Form, Row, Tooltip, message, Dropdown, Spin } from "antd";
import {
    toggleIsProductFetching,
    getAllProductsLite,
    clearProducts,
    exportTable,
    toggleBackButton,
    selectTitle,
    updateProductEditingKey,
    getAllProduct,
    addProduct,
    removeEmptyProduct,
    updateProduct,
    postProductRecord,
    refreshProduct,
    currentProductFilters,
    toggleHasUnsavedChangesFlag
} from "../../actions";
import { connect, batch } from "react-redux";
import moment from 'moment-timezone';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";
import { Button as NeoButton, Table as NeoTable } from "neo-latest"
import { isTabAlreadyOpen } from "../common/TabLayout";
import { LoadingOutlined } from '@ant-design/icons';
import { otherFields } from "../../utils/neoTableFields";
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import FilterDrawer from "../common/FilterDrawer";
import ProductDetails from "./ProductDetails";
import EditDrawer from "../common/EditDrawer";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;
export const ManageProductTableRef = React.createContext();
const dataIndexMap = {
    title: 'title',
};

class ManageProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: "true",
            showFilter: false,
            showEdit: false,
            isReadOnlyEdit: false,
            record: null,
            height: `${document.body.clientHeight - 175}px`,
            drawerHeight: `${document.body.clientHeight - 100}px`,
            formLoading: false,
            currentPage: 0,
            currentPath: null,
        }

        this.filterSort = {}
        this.flag = 0;
        this.tableRef = React.createRef();
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentProductFilters(this.filterSort, this.state.status, this.state.currentPage, (this.state.currentPage * 12));
            this.props.refreshProduct();
            this.props.updateProductEditingKey("");
        } else {
            this.props.clearProducts();
            this.props.updateProductEditingKey("");
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        }
        this.cleanup();
    }

    async componentDidMount() {
        const { isTabOpen, lastPageNumber, lastStatus, lastFilterSort } = this.props;
        this.setState({ currentPath: window.location.pathname });
        if (isTabOpen) {
            this.filterSort = lastFilterSort;
            await this.setState({ status: lastStatus, currentPage: lastPageNumber })
            const currentFilterSize = lastFilterSort !== undefined ? Object.keys(lastFilterSort)?.length : 0;
            if (currentFilterSize > 0) {
                this.setState({ showFilter: true })
            }
        }
        batch(() => {
            this.props.toggleIsProductFetching(true);
            this.props.getAllProduct();
        });
        this.props.toggleIsProductFetching(true);
        await this.props.getAllProductsLite({ filterSort: this.filterSort, isActive: this.state.status === "true" ? true : this.state.status === "false" ? false : undefined });
    }

    componentDidUpdate() {
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);

        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );

        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    }

    handleDatesSortForNeoTable = (event) => {
        const targetElement = event.target;
        const sortingDateChild = targetElement.querySelector('#sorting-date');
        const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
            (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
        if (sortingDateChild || arrowChild) {
            setTimeout(() => {
                const myElement = document.querySelector('.neo-dropdown__content');
                if (myElement) {
                    myElement.classList.add('sorting-classes');
                    const parentItem = document.querySelector(".sorting-classes");
                    const children = parentItem.children;
                    children[0].textContent = 'Clear Sort';
                    children[1].textContent = 'Older to Newer';
                    children[2].textContent = 'Newer to Older';
                }
            }, 10)
        }
    }

    cleanup = () => {
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    };

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const exportData = await this.props.getAllProductsLite({ filterSort: this.filterSort, isActive: this.state.status === "true" ? true : this.state.status === "false" ? false : undefined, isExport: true })

        if (exportData?.length > 0 && exportData !== undefined) {
            exportTable(CONFIG.exportTable.products.name, exportData, timeZoneName, CONFIG.exportTable.products.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleStatusChange = async (value) => {
        await this.props.clearProducts();
        this.setState({ status: value })
        this.props.toggleIsProductFetching(true);
        await this.props.getAllProductsLite({ filterSort: this.filterSort, isActive: value === "true" ? true : value === "false" ? false : undefined })
    }

    handleFilter = async (filters) => {
        const { title } = filters;
        const isProductEmpty = !title || (title && title.length === 0);
        if (isProductEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to filter`);
            return;
        }
        await this.props.clearProducts();
        this.filterSort = {
            ...this.filterSort,
            filter: {
                [dataIndexMap.title]: !isProductEmpty ? title.join(CONFIG.delimiters.selectFilter) : undefined,
            }
        }
        this.props.toggleIsProductFetching(true);
        await this.props.getAllProductsLite({ filterSort: this.filterSort, isActive: this.state.status === "true" ? true : this.state.status === "false" ? false : undefined })
    }

    handleClear = async (isRefresh) => {
        if (!isRefresh) {
            this.filterSort = {};
            await this.props.clearProducts();
        } else {
            await this.props.refreshProduct();
        }
        this.props.toggleIsProductFetching(true);
        await this.props.getAllProductsLite({ filterSort: this.filterSort, isActive: this.state.status === "true" ? true : this.state.status === "false" ? false : undefined })
    }

    handleSave = () => {
        const productId = this.state.record?.id || -1;
        this.props.form.validateFields(async (error, values) => {
            if (error) {
                return;
            }
            const product = {
                id: productId < 0 ? undefined : productId,
                title: values.title,
                isActive: values.isActive,
            }
            this.setState({
                formLoading: true, showEdit: false,
                isReadOnlyEdit: false,
            })
            this.props.toggleIsProductFetching(true);
            await this.props.postProductRecord(productId, product)
                .then(async () => {
                    this.setState({ currentPage: 0 })
                    await this.props.clearProducts();
                    this.props.toggleIsProductFetching(true);
                    this.props.getAllProduct();
                    await this.props.getAllProductsLite({ filterSort: this.filterSort, isActive: this.state.status === "true" ? true : this.state.status === "false" ? false : undefined })
                })
        });
        this.setState({
            formLoading: false,
            record: null
        })
    }

    handleEditClick = (record, isReadOnly) => {
        this.setState({
            showEdit: true,
            isReadOnlyEdit: isReadOnly,
            record
        })
    }

    renderFilterPanel = () => {
        const { templateType } = this.props;
        const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                {isSuperAdmin && <div style={{ display: "flex", flex: "1" }}>
                    <NeoButton onClick={() => this.handleEditClick(null, true)}>Create Product</NeoButton>
                </div>}
                <div style={{ display: "flex", width: "100%", flex: "1", flexDirection: "row-reverse", gap: "24px" }}>
                    <div >
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoButton className="export-download-button" icon="chevron-down" variant="secondary">Download</NeoButton>
                        </Dropdown>
                    </div>
                    <div >
                        <NeoIcon size="md" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty(this.filterSort))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                    <div >
                        <NeoIcon size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                </div>
            </div>
        )
    }

    handleChangeSortName = () => {
        setTimeout(() => {
            const myElement = document.querySelector('.neo-dropdown__content');
            if (myElement) {
                myElement.classList.add('sorting-classes');
                const parentItem = document.querySelector(".sorting-classes");
                const children = parentItem.children;
                children[0].textContent = 'Clear Sort';
                children[1].textContent = 'Older to Newer';
                children[2].textContent = 'Newer to Older';
            }
        }, 10)
    }

    renderTable = () => {
        const { timeZoneName, data, isFetching } = this.props;
        const neoColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center", }}>{a?.cell?.row?.original?.title}</span>
                },
                Header: "Title",
                sortType: "string",
                accessor: "title",
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.createdTime ? moment.tz(a?.cell?.row?.original?.createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                },
                Header: <span onClick={this.handleChangeSortName} id='sorting-date'>Created Date</span>,
                accessor: "createdTime",
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.createdTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.createdTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                }
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{<NeoIcon style={a?.cell?.row?.original?.isActive ? { color: "#088A08" } : { color: "#DA291C" }} icon={a?.cell?.row?.original?.isActive ? "available" : "missed"} />}</span>
                },
                Header: "Status",
                sortType: "basic",
                accessor: "isActive",
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", gap: "24px" }}>
                                <div>
                                    <Tooltip title="Edit"><NeoIcon size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="edit" onClick={() => this.handleEditClick(record, false)}
                                    /></Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true
            }
        ]
        return (
            isFetching ? null : <NeoTable
                columns={neoColumns}
                data={data || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                showRowSeparator
                initialStatePageIndex={this.state.currentPage}
                handlePageChange={(newPageIndex, newPageSize) => {
                    if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
                }}
                {...otherFields}
            />
        )
    }


    renderTabContents = () => {
        return (
            <React.Fragment>
                <div>
                    {this.renderFilterPanel()}
                </div>
                <div style={{ minHeight: "250px" }}>
                    {this.renderTable()}
                </div>
            </React.Fragment>
        )
    }


    render() {
        const { allDemos } = this.props;
        this.hasChanged = this.props.editingKey !== "" ? true : false

        const formFields = [
            {
                label: "Product Title",
                type: CONFIG.formFieldTypes.SELECT,
                key: "title",
                valueKey: "text",
                data: (allDemos) ? allDemos.map(demo => ({ text: demo.title })) : [],
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.title]
            },

        ]
        const drawerContainer = document.getElementById('#component-content');
        return (
            <div>
                <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                    Manage Products
                </div>
                <Spin spinning={this.props.isFetching} indicator={antIcon}>
                    <div style={{ marginTop: "16px" }}>
                        <Tabs disabled={this.isViewDeletedItemsChecked} activeKey={this.state.status} className='demo-status-tabs' defaultActiveKey={this.state.status} onChange={this.handleStatusChange}>
                            <TabPane disabled={this.isViewDeletedItemsChecked} tab="Active" key="true">
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Inactive" key="false">
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane disabled={this.isViewDeletedItemsChecked} tab="All" key="all">
                                {this.renderTabContents()}
                            </TabPane>
                        </Tabs>
                    </div>
                    <Row>
                        {this.state.showFilter && (
                            <FilterDrawer
                                visible={this.state.showFilter}
                                width='520'
                                handleCloseFilterDrawer={() => this.setState(prevState => ({
                                    showFilter: false,
                                    record: null
                                }))}
                                placement="right"
                                drawerContainer={drawerContainer}
                                style={{ zIndex: 9999, }}
                                maskClosable={false}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                timeZone={this.props.timeZoneName}
                                hideSoftDeleteCheckbox={true}
                                hideSave={true}
                                handleSave={null}
                                handleFilter={this.handleFilter}
                                getSearchData={null}
                            />)}
                    </Row>
                    {
                        this.state.showEdit && <EditDrawer
                            visible={this.state.showEdit}
                            width='520'
                            handleCloseFilterDrawer={() => this.setState({ showEdit: false })}
                            placement="right"
                            drawerContainer={drawerContainer}
                            style={{ zIndex: 9999, }}
                            maskClosable={false}
                            timeZone={this.props.timeZoneName}
                            readOnly={false}
                            form={this.props.form}
                            handleEditClick={this.handleEditClick}
                            handleSave={this.handleSave}
                            record={this.state.record}
                            showEdit={true}
                            okButtonText={(!this.state.isReadOnlyEdit) ? "Save" : "Create Product"}
                            cancelButtonText="Close"
                            filterSort={this.filterSort}
                            title={`${(!this.state.isReadOnlyEdit) ? "Edit" : "Create"} Product`}
                        >
                            <Spin spinning={this.state.formLoading} indicator={antIcon}>
                                <ProductDetails
                                    form={this.props.form}
                                    record={this.state.record}
                                    regions={this.props.regions}
                                    readOnly={this.state.isReadOnlyEdit}
                                />
                            </Spin>
                        </EditDrawer>
                    }
                    <br />
                </Spin>
            </div >
        );
    }
}

const mapStateToProps = ({ user, product, tabsLayout }) => {
    return {
        data: product.data,
        isFetching: product.isFetching,
        editingKey: product.editingKey,
        templateType: user.profile.roleTemplate.templateType,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        count: product.count,
        allDemos: product.productData,
        openedTabs: tabsLayout.panes,
        lastFilterSort: product.filterSort,
        lastStatus: product.status,
        lastPageNumber: product.pageNumber,
        pageSize: product.pageSize,
        isTabOpen: product.isTabOpen,
        activeKey: tabsLayout.activeKey
    };
};

const ManageProductsTable = Form.create()(ManageProducts);

export default connect(
    mapStateToProps,
    {
        toggleIsProductFetching,
        getAllProductsLite,
        clearProducts,
        toggleBackButton,
        selectTitle,
        exportTable,
        updateProductEditingKey,
        getAllProduct,
        addProduct,
        removeEmptyProduct,
        updateProduct,
        postProductRecord,
        refreshProduct,
        currentProductFilters,
        toggleHasUnsavedChangesFlag
    }
)(ManageProductsTable);

