import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDashboardApprovalsCount } from "../../actions";
import { Alert, Badge } from 'antd5';

function PendingApprovals() {
    const dispatch = useDispatch();
    const [data, setData] = useState(0);

    useEffect(() => {
        (async () => {
            const data = await dispatch(fetchDashboardApprovalsCount())
            setData(data)
        })();
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            {
                (!!data && data > 0) ? <Alert
                    message="Approval Request"
                    type="warning"
                    action={
                        <Badge count={data} />
                    }

                /> : null
            }
        </div>
    )
}

export default PendingApprovals