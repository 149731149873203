export const otherFields = {
    translations: {
        toolbar: {
            clear: "Clear Filter",
            close: "Close",
            create: "Create",
            delete: "Delete",
            edit: "Edit",
            noDataAvailable: "No Data Available",
            refresh: "Refresh",
            rowsPerPage: "Rows per page",
            searchInputPlaceholder: "Search all"
        },
        header: {
            clearSort: "Clear Sort",
        },
        body: {
            noDataAvailable: "No Data Available"
        },
        pagination: {
            backIconButtonText: "Back",
            nextIconButtonText: "Next",
            itemsPerPageLabel: "Show ",
            tooltipForCurrentPage: "Current Page",
            tooltipForShownPagesSelect: "Items"
        }
    }
}