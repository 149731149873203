import React from 'react';
import { connect, batch } from 'react-redux';
import { Table, Row, Col, Typography, Divider, Dropdown, Popconfirm, Tag, Icon, Popover, Tooltip, message } from 'antd';
import moment from 'moment-timezone';
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
import {
    fetchDemos,
    fetchBuildsSchedules,
    getAllBuilds,
    toggleIsFetchingDemoSchedules,
    selectTitle,
    toggleBackButton,
    clearAutoBuildsSchedules,
    fetchScheduleComments,
    addScheduleComment,
    updateScheduleComment,
    deleteScheduleComment,
    updateScheduleEndDate,
    tearDownSchedule,
    reinitiateDemoBuild,
    closeDemoSchedule,
    updateManageSchedulesCurrentPage,
    deleteDemoSchedule,
    exportTable,
    toggleHasUnsavedChangesFlag,
    currentBuildsFilter,
    updateBuildsCurrentPage
} from '../../actions';
import CONFIG from '../../config';
import pushMessage from '../common/PushMessage';
import FilterPanel from '../common/FilterPanel';
import StatusFilter from '../common/StatusFilter';
import TearDownForm from './TearDownForm';
import { fetchNamesBySearchString } from '../../actions/userRole';
import openConfirm from '../common/Confirm';
import throttle from 'lodash/throttle';


const dataIndexMap = {
    demo: "demo.title",
    startTime: "startTime",
    endTime: "endTime",
    applyToAllCheck: 'includeStartAndEndDate',
    demoType: 'demo.demoType',
    createdBy: "createdBy.fullName",
    buildStatus: 'buildStatus',
    demoBuildId: 'demoBuildId',
    referenceNo: 'referenceNo',
}

const { Text } = Typography

const statusDataSet = [
    {
        text: "Active Builds",
        value: "In-Progress,Available,Expired,Canceled (In-Progress),Canceled (Available),Canceled (Failed)"
    },
    {
        text: "Failed Builds",
        value: "Failed,Failed (In-Progress)"
    },
    {
        text: "Torn Down + Closed Builds",
        value: "Torn Down (Manual),Closed,Torn Down (Auto),Torn Down (Canceled)"
    }

]

class ManageTearDown extends React.Component {

    constructor(props) {
        super(props);
        this.handleClear = throttle(this.handleClear, 1000);
    }

    state = {
        matchedUserNames: [],
        status: "In-Progress,Available,Expired,Canceled (In-Progress),Canceled (Available),Canceled (Failed)",
        schedulesData: [],
        loading: false,
        isReturnedFromDelete: false,
        submitting: false,
        comment: "",
        endDateTime: undefined,
        selectedSchedule: undefined,
        isEditing: false,
        editingKey: "",
        showFilter: false
    };

    scheduleId = undefined;
    currentPage = this.props.currentPageNumber !== undefined ? this.props.currentPageNumber : 1
    filterSort = {};
    filterSave = {};
    flag = 0;
    tableRef = React.createRef();

    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString();
    }

    clearCurrentSchedules = async () => {
        this.currentPage = 1
        this.setState({ schedulesData: [] })
    }

    handleFilter = async (filters) => {

        let { demo, startEndTime, applyToAllCheck, demoType, createdBy, buildStatus, referenceNo, demoBuildId } = filters;
        const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isDemoTypeEmpty = !demoType || (demoType && demoType.length === 0);
        const isBuildStatusEmpty = !buildStatus || (buildStatus && buildStatus.length === 0);

        referenceNo = referenceNo?.trim();
        createdBy = createdBy?.trim();
        demoBuildId = demoBuildId?.trim();

        const formEmpty = isBuildStatusEmpty && isStartEndTimeEmpty && isDemoEmpty && isDemoTypeEmpty && !createdBy && !referenceNo && !demoBuildId;

        if (formEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to filter`)
            return;
        }
        this.setState({ matchedUserNames: [] })
        await this.clearCurrentSchedules()

        this.filterSort = {
            ...this.filterSort,
            filter: {
                [dataIndexMap.demo]: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.demoType]: demoType && demoType.length === 1 ? demoType[0] : undefined,
                [dataIndexMap.startTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0]) : undefined,
                [dataIndexMap.endTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1]) : undefined,
                [dataIndexMap.applyToAllCheck]: applyToAllCheck || undefined,
                [dataIndexMap.createdBy]: createdBy || undefined,
                [dataIndexMap.buildStatus]: !isBuildStatusEmpty ? buildStatus.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.referenceNo]: referenceNo || undefined,
                [dataIndexMap.demoBuildId]: demoBuildId || undefined
            }
        }

        this.currentPage = 1;

        batch(() => {
            this.handleFetchSchedules()

        })

    }

    fetchUsersName = searchString => {
        this.setState({ matchedUserNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUserNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleClear = async () => {
        await this.clearCurrentSchedules()
        this.filterSort = { ...this.filterSort, filter: undefined };
        this.setState({ matchedUserNames: [] })
        this.currentPage = 1;
        this.handleFetchSchedules();
    }

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const automaticBuilds = await getAllBuilds({ filterSort: this.filterSort, buildStatus: this.state.status });
        if (automaticBuilds?.length > 0 && automaticBuilds !== undefined) {
            exportTable(CONFIG.exportTable.automaticBuilds.name, automaticBuilds, timeZoneName, CONFIG.exportTable.automaticBuilds.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleSortChange = async (_, __, sorter) => {
        await this.clearCurrentSchedules()
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.handleFetchSchedules()
    }

    removeDuplicateRecordsIfAny = () => {
        let schedulesData = this.state.schedulesData
        schedulesData = this.props.schedules ? schedulesData.concat(this.props.schedules) : []
        return schedulesData?.filter((value, index) => schedulesData.indexOf(value) === index)
    }

    handleFetchSchedules = async () => {
        const { count } = this.props
        const countOfRecordsFetched = this.state.schedulesData?.length
        if ((count === undefined && countOfRecordsFetched === undefined) || !(count <= countOfRecordsFetched)) {
            this.setState({ loading: true });
            await this.props.fetchBuildsSchedules({ pageNumber: this.currentPage++, filterSort: this.filterSort, buildStatus: this.state.status })
                .then(() => {
                    this.setState(({ schedulesData }) => ({
                        loading: false,
                        schedulesData: this.removeDuplicateRecordsIfAny(),
                    }))
                    this.flag = 0;
                })
                .then(() => {
                    var scrollContainer = document.querySelector('.ant-table-body')
                    scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
                })
        }
    }

    fetchSchedulesOnMount = async () => {
        const { pageSize, isEdit } = this.props;
        this.setState({ loading: true })
        await this.props.fetchBuildsSchedules({
            pageNumber: isEdit || this.state.isReturnedFromDelete ? 1 : this.currentPage++,
            pageSize: isEdit || this.state.isReturnedFromDelete ? pageSize : 12,
            filterSort: this.filterSort,
            buildStatus: this.state.status,
        })

        this.setState(({ schedulesData }) => ({
            loading: false,
            schedulesData: this.removeDuplicateRecordsIfAny(),
        }))
    }

    componentDidUpdate = () => {
        const { openedTabs, activeKey } = this.props;
        const formChanged = this.state.editingKey !== "" && this.state.editingKey !== undefined;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
        else if (hasUnsavedChanges && !formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
    }

    async componentDidMount() {
        const { currentStatus, isEdit, currentFilter, currentPageNumber } = this.props;
        this.setState({ loading: true })
        this.setState({ currentPath: window.location.pathname })
        var tableContent = document.querySelector('.ant-table-body')
        tableContent && tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight
            let currentScroll = event.target.scrollTop
            if ((event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) && (maxScroll !== 0 && currentScroll !== 0)) {
                this.flag += 1
                this.flag === 1 && this.handleFetchSchedules()
            }
        })
        if (isEdit) {
            this.currentPage = currentPageNumber + 1;
            this.filterSort = currentFilter;
            if (this.filterSort?.filter !== undefined) this.setState({ showFilter: true })
            await this.setState({ status: currentStatus })
        }
        else {
            this.filterSort = {}
        }
        batch(() => {
            this.fetchSchedulesOnMount();
            this.props.selectTitle('Manage Automatic Demo Builds');
            // this.props.toggleBackButton(false);
            this.props.toggleIsFetchingDemoSchedules(true);
            this.props.fetchDemos({ getAll: true, demoType: CONFIG.demoTypes.labPocDemo });
        });
    }

    async componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            // const filterSort = {
            //     ...this.filterSort,
            //     sorter: this.state.sortedInfo ?? undefined
            // }
            // this.props.currentScheduleFilter(filterSort, this.state.status, false)

            await this.props.currentBuildsFilter(this.filterSort, this.state.status)
            // This will ensure that we do not increment the pageNumber on Tab Switch.
            const nextPageNumber = this.currentPage > 2 ? this.currentPage - 1 : this.currentPage
            await this.props.updateBuildsCurrentPage(nextPageNumber, (nextPageNumber * 12), 0, 0)
        }
        else {
            this.props.clearAutoBuildsSchedules();
        }
    }

    handleSelectChange = async (value) => {
        await this.clearCurrentSchedules()
        this.setState({ status: value }, () => { this.handleFetchSchedules() })
    }

    handleViewDetails = (record) => {
        this.props.fetchScheduleComments(record.id);
        this.setState({
            visible: true,
            selectedSchedule: record,
            endDateTime: moment.tz(record.endTime, this.props.timeZoneName)
        })
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            comment: ''
        })
    }

    handleSubmit = (isUpdate, commentId) => {
        const { comment, selectedSchedule } = this.state;

        if (!comment?.trim()) {
            pushMessage(CONFIG.messageType.info, "The comment field cannot be empty.")
            return;
        }

        this.setState({
            submitting: true,
        });

        if (!isUpdate) {
            this.props.addScheduleComment(comment, selectedSchedule.id)
                .then(() => this.setState({ submitting: false, comment: "" }))
        }

        else {
            this.props.updateScheduleComment(comment, selectedSchedule.id, commentId)
                .then(() => this.setState({ submitting: false, comment: "", editingKey: "" }))
        }


    };

    handleChange = e => {
        this.setState({
            comment: e.target.value,
        });
    };

    handleEndDateSubmit = () => {
        let { endDateTime, selectedSchedule } = this.state;
        const { timeZoneName } = this.props;
        const { startTime } = selectedSchedule;
        const startValue = moment.tz(startTime, timeZoneName)
        const currentValue = moment.tz(moment(), timeZoneName)

        if (!endDateTime) return;

        if (endDateTime && (endDateTime.valueOf() < startValue.valueOf() || endDateTime.valueOf() < currentValue.valueOf())) {
            pushMessage(CONFIG.messageType.warning, 'End time cannot be less than start time / current time');
            return;
        }

        const endTime = endDateTime.tz(timeZoneName, true).toISOString();
        selectedSchedule = {
            ...selectedSchedule,
            endTime: endTime
        }

        let schedulesData = this.state.schedulesData.map(item =>
            item.id === selectedSchedule.id
                ? { ...item, endTime }
                : item
        )

        this.props.updateScheduleEndDate(endTime, selectedSchedule.id)
            .then(() => this.setState({ selectedSchedule, isEditing: false, schedulesData }))
    };

    handleEndDateChange = value => {
        this.setState({
            endDateTime: value,
        });
    };

    setIsEditing = (flag) => {
        this.setState({ isEditing: flag })
    }


    handleFetchSchedulesOnDelete = async () => {
        this.clearCurrentSchedules()
        const pageSize = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
        const currentPageNumber = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
        await this.props.updateBuildsCurrentPage(currentPageNumber, pageSize, this.scheduleId, this.recordIndex)
        await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: this.recordIndex })
        this.currentPage = this.props.currentPageNumber + 1
        this.fetchSchedulesOnMount();
    }

    handleTearDown = async (record, index) => {
        this.scheduleId = record.id;
        this.recordIndex = index;
        // eslint-disable-next-line
        const response = await this.props.tearDownSchedule(this.scheduleId);
        this.handleFetchSchedulesOnDelete()
    }

    handleReInitiate = async (record, index) => {
        this.scheduleId = record.id;
        this.recordIndex = index;
        const response = await this.props.reinitiateDemoBuild(this.scheduleId);
        if (response) {
            this.handleFetchSchedulesOnDelete()
        }
    }

    handleScheduleClose = async (record, index) => {
        this.scheduleId = record.id;
        this.recordIndex = index;
        const response = await this.props.closeDemoSchedule(this.scheduleId);
        if (response) {
            this.handleFetchSchedulesOnDelete()
        }
    }

    handleDeleteConfirmation = async () => {
        const hardDelete = false;
        const response = await this.props.deleteDemoSchedule(this.scheduleId, hardDelete, "builds");
        if (response) {
            this.handleFetchSchedulesOnDelete()
        }
    }

    renderHardDeleteConfirmation = (record, index) => {
        this.scheduleId = record.id;
        this.recordIndex = index;
        openConfirm('Confirm Permanent Deletion', this.handleDeleteConfirmation, null, CONFIG.warningMessages.hardDelete.replace('<placeHolder>', 'Demo Build'), CONFIG.deleteModalTitle?.replace('<placeholder>', 'demo build'));
    }

    handleRefresh = async () => {
        await this.clearCurrentSchedules()
        this.handleFetchSchedules()
    }

    handleCommentEdit = (commentId, initialValue) => {
        this.setState({ editingKey: commentId, comment: initialValue })
    }

    handleCommentDelete = (commentId, scheduleId) => {
        this.props.deleteScheduleComment(commentId, scheduleId)
    }

    handleCommentCancel = () => {
        this.setState({ editingKey: "", comment: "" })
    }

    render() {
        const { timeZoneName, demos, count, comments } = this.props;
        const { status, comment, submitting } = this.state;
        const tableLabel = status === "Failed,Failed (In-Progress)" ? "Failed Builds" : status === 'In-Progress,Available,Expired,Canceled (In-Progress),Canceled (Available),Canceled (Failed)' ? 'Active Builds' : 'Torn Down + Closed Builds'
        const content = CONFIG.filterDataSet.automaticBuildStatus.map(item => {
            return (
                <> <span><Tag color={item.color}>{item.text}</Tag> - {item.desc}</span><br /><br /></>
            )
        })
        const columns = [
            {
                title: 'No.',
                key: 'index',
                align: 'center',
                width: 75,
                render: (text, record, index) => index + 1,
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.referenceNo] ? "filtered-column" : ''} >Request No.</span>,
                key: "referenceNo",
                dataIndex: "referenceNo",
                width: 200,
                align: "center",
                sorter: true,
                render: (referenceNo) => referenceNo !== null ? referenceNo : ""
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.startTime] ? "filtered-column" : ''}>Start Date/Time</span>,
                dataIndex: 'startTime',
                key: 'startTime',
                align: 'center',
                width: 200,
                sorter: true,
                render: (startTime) => moment.tz(startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.endTime] ? "filtered-column" : ''}>End Date/Time</span>,
                dataIndex: 'endTime',
                key: 'endTime',
                align: 'center',
                width: 300,
                sorter: true,
                render: (endTime, record) => <span> {moment.tz(endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}
                    {record.endTimeBeforeExtend && <>
                        &nbsp;&nbsp;
                        <Tooltip title={<div>The schedule was extended by {moment.tz(record.endTimeBeforeExtend, timeZoneName).to(moment.tz(endTime, timeZoneName), true)} from {moment.tz(record.endTimeBeforeExtend, timeZoneName).format(CONFIG.dateFormats.userDateTime)} to {moment.tz(endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</div>}>
                            <Tag color={CONFIG.demoBuildStatusTagColors["Extended"]}>Extended</Tag>
                        </Tooltip>
                    </>
                    }
                </span>
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.demoType] ? "filtered-column" : ''}>Demo Type</span>,
                dataIndex: 'demo',
                key: 'demo.demoType',
                width: 200,
                sorter: true,
                render: (demo) => CONFIG.demoTypeOptions.find(demoType => demoType.value === demo.demoType)?.name
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.demo] ? "filtered-column" : ''}>Demo Name</span>,
                dataIndex: 'demo',
                key: 'demo.title',
                width: 200,
                sorter: true,
                render: (demo) => demo.title
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.createdBy] ? "filtered-column" : ''}>Created By</span>,
                key: "createdBy.fullName",
                width: 200,
                sorter: true,
                render: (schedule) => schedule.createdBy !== null ? schedule.createdBy.fullName : ''
            },
            {

                title: <span className={this.filterSort.filter?.[dataIndexMap.buildStatus] ? "filtered-column" : ''}>Build Status <Popover
                    id="buildStatusInfo"
                    placement="right"
                    content={content}
                    trigger="hover">
                    &nbsp;<span className="small-text"> <Icon type="info-circle" className="text-color-primary" width={1} /></span>
                </Popover> </span>,
                key: 'buildStatus',
                width: 200,
                sorter: true,
                align: "center",
                render: (record) => <Tag color={CONFIG.demoBuildStatusTagColors[record.buildStatus]}>{record.buildStatus}</Tag>
            },
            {
                title: <span>Build Id</span>,
                key: "demoBuildId",
                width: 150,
                sorter: true,
                align: "center",
                render: (schedule) => schedule.demoBuildId !== null ? schedule.demoBuildId : "-"
            },
        ]


        const actions = [{
            title: 'Actions',
            key: 'actions',
            width: 150,
            render: (text, record, index) => {
                const { status } = this.state;
                const { timeZoneName } = this.props;
                const isCloseDisabled = record.buildStatus !== "Failed" ? (record.externalApiResponse?.[1]?.responseCode === null || record.externalApiResponse?.[1]?.responseCode === undefined) : false;
                const isTearDownDisabled = record.buildStatus === "Canceled (Failed)" && record.demoBuildId === null
                const isReInitiateDisabled = moment.tz(moment(), timeZoneName) > moment.tz(record.startTimeWithoutBuffer, timeZoneName) || record.isSoftDelete
                const hideTearDown = record.buildStatus === "Failed" || status === "Torn Down (Manual),Closed,Torn Down (Auto),Torn Down (Canceled)"
                const hideClose = status === "Torn Down (Manual),Closed,Torn Down (Auto),Torn Down (Canceled)"
                const showReInitiate = record.buildStatus === "Failed";

                return (
                    <>
                        <button className="link" onClick={() => this.handleViewDetails(record)}><span class="neo-icon-list-bullet" title="View Details" style={{ fontSize: "20px" }}></span></button>
                        {!hideTearDown &&
                            <>
                                <Divider type="vertical" />
                                <Popconfirm disabled={isTearDownDisabled} title="Sure to tear down?" onConfirm={() => this.handleTearDown(record, index + 1)}>
                                    <button disabled={isTearDownDisabled} className={!isTearDownDisabled ? "link" : "link-disabled"}><span class="neo-icon-archive" title="Tear Down" style={{ fontSize: "20px" }}></span></button>
                                </Popconfirm>
                            </>
                        }
                        {showReInitiate &&
                            <>
                                <Divider type="vertical" />
                                <Popconfirm disabled={isReInitiateDisabled} title="Sure to re-initiate?" onConfirm={() => this.handleReInitiate(record, index + 1)}>
                                    <button disabled={isReInitiateDisabled} className={!isReInitiateDisabled ? "link" : "link-disabled"}><span class="neo-icon-queue-send-to" title="Re-Initiate" style={{ fontSize: "20px" }}></span></button>
                                </Popconfirm>
                            </>
                        }
                        {!hideClose &&
                            <>
                                <Divider type="vertical" />
                                <Popconfirm disabled={isCloseDisabled} title="Sure to close?" onConfirm={() => this.handleScheduleClose(record, index + 1)}>
                                    <button disabled={isCloseDisabled} className={!isCloseDisabled ? "link" : "link-disabled"}><span class="neo-icon-close" title="Close" style={{ fontSize: "20px" }}></span></button>
                                </Popconfirm>
                            </>
                        }
                    </>
                )
            },
            align: 'center'
        }
        ];

        const deleteAction = [{
            title: 'Actions',
            key: 'actions',
            width: 50,
            render: (text, record, index) => {
                return (
                    <button className="link" onClick={() => this.renderHardDeleteConfirmation(record, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>                    </button>
                )
            },
            align: 'center'
        }]

        const formFields = [
            {
                label: "Demo",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: (demos) ? demos.map(demo => ({ text: demo.title })) : [],
                colSpan: 12,
                value: this.filterSort.filter?.[dataIndexMap.demo]
            },
            {
                label: "Start / End Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "startEndTime",
                colSpan: 12,
                value: [this.filterSort?.filter?.[dataIndexMap.startTime], this.filterSort?.filter?.[dataIndexMap.endTime], this.filterSort?.filter?.[dataIndexMap.applyToAllCheck]],
            },
            {
                label: "Created By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "createdBy",
                data: this.state.matchedUserNames,
                value: this.filterSort?.filter?.createdBy
            },
            {
                label: "Demo Type",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demoType",
                data: CONFIG.filterDataSet.demoTypes,
                value: this.filterSort.filter?.[dataIndexMap.demoType],
            },
            {
                label: "Build Status",
                type: CONFIG.formFieldTypes.SELECT,
                key: "buildStatus",
                data: CONFIG.filterDataSet.automaticBuildStatus,
                value: this.filterSort.filter?.[dataIndexMap.buildStatus]
            },
            {
                label: "Request No.",
                type: CONFIG.formFieldTypes.INPUT,
                key: "referenceNo",
                value: this.filterSort.filter?.[dataIndexMap.referenceNo]
            },
            {
                label: "Build Id",
                type: CONFIG.formFieldTypes.INPUT,
                key: "demoBuildId",
                colSpan: 8,
                value: this.filterSort.filter?.[dataIndexMap.demoBuildId]
            },
        ]
        const { showFilter } = this.state;
        return (
            <>
                <div className="component-status-bar positioning">
                    <Row >
                        <Col xl={5} sm={5} xs={24}>
                            <StatusFilter value={this.state.status} dataSet={statusDataSet} onChange={this.handleSelectChange} />
                        </Col>

                        <Col xl={1} sm={2} xs={2} className="float-right">
                            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                                <NeoIcon title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                            </Dropdown>
                        </Col>

                        <Col xl={1} sm={2} xs={2} className="float-right">
                            <NeoIcon className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Col>

                        <Col xl={1} sm={2} xs={2} className="float-right">
                            <NeoIcon className="clickable" onClick={() => this.setState(prevState => ({
                                showFilter: !prevState.showFilter
                            }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Col>
                    </Row>
                    <Row>
                        {this.state.showFilter &&
                            <FilterPanel
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                getSearchData={this.fetchUsersName}
                                timeZone={timeZoneName}
                                hideSoftDeleteCheckbox={true}
                            />}
                    </Row>
                </div>
                <div className="content-container responsive-container">
                    <Row>
                        <div style={{ marginTop: this.state.showFilter ? '370px' : '50px' }} ref={this.tableRef}>
                            <h3>{tableLabel}</h3>
                            <Table
                                size='middle'
                                rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                scrollToFirstRowOnChange={true}
                                rowKey={(record) => record.id}
                                className="responsive-container"
                                bordered
                                scroll={{ y: 550, scrollToFirstRowOnChange: false, x: 1000 }}
                                loading={this.state.loading}
                                dataSource={this.state.schedulesData}
                                columns={this.state.status === "failedBuilds" ? [...columns, ...deleteAction] : [...columns, ...actions]}
                                onChange={this.handleSortChange}
                                pagination={false}
                                footer={() => <>{this.state.schedulesData?.length !== 0 && <div style={{ textAlign: 'center' }}>
                                    <Text strong>Fetched {this.state.schedulesData?.length} out of {count} Demo Builds</Text>
                                </div>}
                                </>}
                            />
                        </div>
                    </Row>
                </div>

                <TearDownForm
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    selectedSchedule={this.state.selectedSchedule}
                    timeZoneName={timeZoneName}
                    comments={comments}
                    comment={comment}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    submitting={submitting}
                    handleEndDateChange={this.handleEndDateChange}
                    handleEndDateSubmit={this.handleEndDateSubmit}
                    endDateTime={this.state.endDateTime}
                    setIsEditing={this.setIsEditing}
                    isEditing={this.state.isEditing}
                    user={this.props.user}
                    handleCommentEdit={this.handleCommentEdit}
                    handleCommentDelete={this.handleCommentDelete}
                    editingKey={this.state.editingKey}
                    handleCommentCancel={this.handleCommentCancel}
                />
            </>
        );
    }
}
const mapStateToProps = ({ demoSchedulesAutomatic, user, demos, tabsLayout }) => {
    return {
        schedules: demoSchedulesAutomatic.tearDownData,
        count: demoSchedulesAutomatic.tearDownCount,
        isFetching: demoSchedulesAutomatic.isFetching,
        user: user.profile,
        timeZoneName: user.profile?.timezone,
        demos: demos.allDemos,
        comments: demoSchedulesAutomatic.comments,
        currentPageNumber: demoSchedulesAutomatic.currentPageNumber,
        pageSize: demoSchedulesAutomatic.pageSize,
        scheduleIndex: demoSchedulesAutomatic.demoIndex,
        currentscheduleId: demoSchedulesAutomatic.currentscheduleId,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        demoSchedulesAutomatic,
        currentStatus: demoSchedulesAutomatic?.status,
        isEdit: demoSchedulesAutomatic?.isEdit,
        currentFilter: demoSchedulesAutomatic?.currentFilter,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchBuildsSchedules,
        toggleIsFetchingDemoSchedules,
        selectTitle,
        toggleBackButton,
        clearAutoBuildsSchedules,
        fetchDemos,
        fetchScheduleComments,
        addScheduleComment,
        updateScheduleEndDate,
        tearDownSchedule,
        reinitiateDemoBuild,
        closeDemoSchedule,
        updateManageSchedulesCurrentPage,
        deleteDemoSchedule,
        updateScheduleComment,
        deleteScheduleComment,
        getAllBuilds,
        toggleHasUnsavedChangesFlag,
        exportTable,
        currentBuildsFilter,
        updateBuildsCurrentPage
    }
)(ManageTearDown);
