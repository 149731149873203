import React, { useEffect, useState } from 'react'
import { fetchDemoPurposeTypes, toggleBackButton, postNewDemoSchedule, clearPathValue, fetchAllCountries, redirectToDashboard } from '../../../actions';
import { fetchUserNamesBySearchString, getUserDemoAccess } from '../../../actions/scheduler'
import TimeZone from '../../common/TimeZone';
import { Row, Col, Input, Select, Form, DatePicker, Spin, Icon, Tooltip, Checkbox, Divider, Alert, Radio } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import CONFIG from '../../../config';
import { Button } from "@avaya/neo-react"
import moment from 'moment-timezone';
import history from '../../../history';
import pushMessage from '../../common/PushMessage';
import debounce from 'lodash/debounce';
import LabDemoSchedulerMobiscroll from './LabDemoSchedulerMobiscroll';
import { createScheduleObject, getInitialValue, renderAdditionalFields, getInitialValueForSchedules, isAttributeChanged, checkDeletedPurpose } from "../../common/Scheduler";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

function LabDemoSchedule(props) {

    // Selectors
    const dispatch = useDispatch();
    const user = useSelector(({ user }) => user);
    const localUsersForm = useSelector(({ localUsersForm }) => localUsersForm);
    const newSchedule = useSelector(({ newSchedule }) => newSchedule)
    const solutions = useSelector(({ solutions }) => solutions)

    //Props
    const { getFieldDecorator } = props.form;
    const { demo, fromDashboard, fromManageSide, isReSchedule, schedule, pathValues } = props;
    const { isEdit } = props.location || false;
    const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");

    const path = (pathValues?.pathname && pathValues?.pathname !== null && pathValues?.pathname !== undefined) ? pathValues?.pathname : '/dashboard';
    const { profile } = user;
    const { countries } = localUsersForm;
    const { userPurposeTypes } = newSchedule;
    const { additionalDemoQuestions, isManagerEmail, isManagerMandatory, notes, regionalPrimeTitle, managerTitle, isRegionalPrime } = (isEditSchedule ? schedule?.demo : demo) || []

    //useState
    const [purpose, setPurpose] = useState(isEditSchedule ? schedule?.demoPurpose?.purpose : 'practice'); //@Kalpitha's code
    const [startValue, setStartValue] = useState(null)
    const [loading, setLoading] = useState(true);
    const [isCurrentSchedule, setCurrentSchedule] = useState(false);
    const [endValue, setEndValue] = useState(!isReSchedule ? isEditSchedule ? moment.tz(schedule?.endTime, profile?.timezone) : null : null)
    const [timeZone, setTimeZone] = useState(profile?.timezone)
    const [testingGroup, setTestingGroup] = useState(null);
    const [showMessage, setMessage] = useState(true);
    const [matchedUserNames, setMatchedUserNames] = useState([]);
    const [managers, setManagers] = useState([]);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [isActiveUser, setActiveUser] = useState(true);
    const [demoAccess, setDemoAccess] = useState(true);
    const [isLoadingUser, setLoadingUser] = useState(false);
    const [isTimeZoneChanged, setTimeZoneChange] = useState(true);

    // Demo Purpose Params
    let additionalAttributes = getInitialValue(purpose, props.form)
    let fieldData = (isEditSchedule && schedule) && getInitialValueForSchedules(schedule, props.form);
    fieldData = isCurrentSchedule ? isAttributeChanged(fieldData, purpose, props.form) : fieldData
    const data = solutions?.data || [];
    const selectedParameters = isEditSchedule ? schedule?.parameters?.split(CONFIG.delimiters.selectFilter) : [];
    const params = isEditSchedule ? schedule?.demo?.parameters?.split(CONFIG.delimiters.selectFilter) : demo?.parameters?.split(CONFIG.delimiters.selectFilter)
    const additionalData = isCurrentSchedule ? fieldData : additionalAttributes;
    const isPurposeDeleted = isCurrentSchedule ? checkDeletedPurpose(schedule?.demoPurpose) : false;
    // debouncing the user names 
    const fetchUserNameString = debounce((searchString) => fetchUserNames(searchString), 800);

    useEffect(() => {
        setTimeout(() => {
            const isValidView = window.location.pathname.includes("/scheduler/lab");
            if (isValidView) {
                (async () => {
                    dispatch(toggleBackButton(false, (isEditSchedule && fromManageSide !== true) ? '/dashboard' : path));
                    await dispatch(fetchAllCountries())
                    document.getElementById("tab-pane").scrollIntoView({ behavior: "smooth" });
                    await setManagers(isEditSchedule ? (schedule?.demo?.regionalPrimes || []) : (demo?.regionalPrimes || []))
                    await dispatch(fetchDemoPurposeTypes(true)).then(() => {
                        setLoading(false);
                    });
                    if (isEditSchedule) {
                        if (isReSchedule) {
                            setPurpose(null)
                            setCurrentSchedule(false)
                        }
                        else {
                            setPurpose(schedule?.demoPurpose)
                            setCurrentSchedule(true)
                        }
                    }

                }
                )();
            }
        }, 100)
        return (() => {
            dispatch(clearPathValue());
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTimeout(() => {
            const isValidView = window.location.pathname.includes("/scheduler/lab");
            if (isValidView) {
                let testingGroup = userPurposeTypes && userPurposeTypes?.filter((purposeType) => { return (purposeType.purpose === "Testing" || purposeType.id === 5) })
                setTestingGroup(testingGroup);
                (testingGroup !== undefined && testingGroup[0] !== undefined) && setPurpose(testingGroup[0])
                if (isEditSchedule || isEdit) {
                    setPurpose(schedule?.demoPurpose)
                    setCurrentSchedule(true);
                }
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPurposeTypes, isEditSchedule, isEdit])

    const renderPurposeTypesOptions = () => {
        return userPurposeTypes ? userPurposeTypes.length > 0 && userPurposeTypes.map((purposeType, index) => {
            return <Option key={index} value={JSON.stringify(purposeType)}>{purposeType.purpose}</Option>
        }) : []
    }

    const disabledDate = (current) => {
        return current && current < moment.tz(moment(), timeZone).endOf("day").subtract(1, "day");
    }

    const handleDateChange = (date) => {
        const { form } = props;
        const bufferTime = demo?.startTimeBuffer / 60;
        const start = moment(date).tz(timeZone, true).subtract(bufferTime, "hours");
        const startBufferTime = moment.tz(moment(), timeZone).add(7, "minutes");
        if (start < startBufferTime) {
            pushMessage(CONFIG.messageType.warning, "Selected time conflicts with start buffer time.")
            setEndValue(null);
            form.setFieldsValue({ endTime: undefined })
            return;
        }

        setStartValue(date);
        let end = moment(date).tz(timeZone).add(isEditSchedule ? schedule?.demo?.scheduleRange : demo?.scheduleRange, "day");
        setEndValue(end);
        form.setFieldsValue({ endTime: end })
    }


    const renderScheduleButtons = () => {
        // const state = props.location.state;
        const demoObj = props.demo;
        const approvalRequired = !isReSchedule ? isEditSchedule ? schedule?.demo?.approvalRequired : demoObj.approvalRequired : demoObj.approvalRequired;

        return (
            <Row>
                <Button variant="primary" className="float-left" style={{ marginBottom: "15px" }} onClick={() => history.replace(path)}> Go Back </Button>
                <Button className="primary-action-button-filled float-right" onClick={() => handleSave(approvalRequired)} disabled={isFormDisabled}>
                    {demoObj.demoType === CONFIG.demoTypes.restfulApiDemo
                        ? ((isCurrentSchedule && !isReSchedule) ? 'Update' : 'Schedule')
                        : ((isCurrentSchedule && !isReSchedule) ? `Update ${approvalRequired ? "Request" : "Schedule"}` : approvalRequired ? 'Request Approval' : "Schedule")}
                </Button>
            </Row>
        );
    }

    const handleTimezoneChange = (value) => {
        setTimeZone(value);
        setLoading(true);
        setTimeZoneChange(false);
        const { form } = props;
        form.setFieldsValue({ startTime: null, endTime: null })
        setStartValue(null);
        setEndValue(null);
        setTimeout(() => {
            setTimeZoneChange(true);
            setLoading(false);
        }, 1000);
    }

    const handleSave = (approvalRequired) => {
        const { form } = props;
        let userAnswers = {}
        form.validateFields(async (err, values) => {
            handleDateChange(values.startTime)
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return
            }
            else {
                setLoading(true);
                const demoPurpose = JSON.parse(values.demoPurpose)
                const country = values.country !== undefined ? JSON.parse(values.country) : null
                const demoObj = props.demo
                const purposeAttributesSchedule = createScheduleObject(additionalAttributes)
                const demoParameters = values?.parameters && values.parameters !== "" ? values.parameters.join(CONFIG.delimiters.selectFilter) : ""
                if (additionalDemoQuestions?.length > 0) {
                    const formValues = Object.entries(values).map(([questionId, answerText]) => ({ questionId, answerText }))?.filter((el) => {
                        return additionalDemoQuestions?.some((f) => {
                            return f?.question?.id === parseInt(el?.questionId);
                        });
                    });

                    const userAnswersObj = formValues.map(fv => {
                        fv.questionOptions = additionalDemoQuestions?.filter(dq => dq.question?.id === parseInt(fv.questionId))?.[0]?.question?.answers?.map(a => a.title).join('//') || null;
                        return fv;
                    })

                    userAnswers = userAnswersObj?.map(obj => ({
                        question: { id: parseInt(obj.questionId) },
                        questionOptions: obj.questionOptions,
                        answerText: Array.isArray(obj?.answerText) ? obj?.answerText.join('//') : obj?.answerText
                    }))
                }

                const scheduleObj = {
                    id: (!isReSchedule ? isEditSchedule ? schedule?.id : undefined : undefined) || undefined,
                    demoPurpose: demoPurpose,
                    country,
                    purposeAttributesSchedule,
                    startTime: values.startTime,
                    endTime: values.endTime,
                    resource: null,
                    startTimeBuffer: demoObj?.startTimeBuffer || 0,
                    endTimeBuffer: 0,
                    demo: demoObj,
                    approvalRequired: !isReSchedule ? isEditSchedule ? schedule?.approvalRequired : demoObj.approvalRequired : demoObj.approvalRequired,
                    userAnswers: Object.keys(userAnswers)?.length === 0 ? null : userAnswers,
                    scheduleApproval: !isReSchedule ? (isEditSchedule ? schedule?.scheduleApproval : demo?.scheduleApproval) : undefined,
                    parameters: demoParameters,
                    managerEmail: values.managersEmail ? values.managersEmail.trim() : undefined,
                    regionalPrimeEmail: values?.regionalPrimeEmail ? values?.regionalPrimeEmail?.trim() : undefined,
                    createdBy: isEditSchedule ? schedule?.createdBy : (values.assignee ? JSON.parse(values.assignee) : profile)
                };

                const scheduleId = await dispatch(postNewDemoSchedule(scheduleObj, false, props.demo?.demoType, false, approvalRequired))
                if (scheduleId !== undefined) {
                    // await dispatch(closeCurrentOpenTab());
                    await dispatch(redirectToDashboard());
                    setTimeZone(profile?.timezone);
                    setTestingGroup(null);
                    setStartValue(null);
                    setEndValue(null);
                    form.resetFields();
                }
                setLoading(false);
            }
        })
    }

    const handlePurposeSelectChange = (value) => {
        let selectedPurpose = JSON.parse(value);
        setPurpose(selectedPurpose);
        setMessage(false)
        !isEditSchedule && setCurrentSchedule(false);
    }

    const renderCountryOptions = () => {
        return countries?.map(country => {
            return (<Option key={country.id} value={JSON.stringify({ id: country.id, name: country.name })}>{country.name}</Option>
            );
        });
    }

    const fetchUserNames = searchString => {
        setMatchedUserNames([])
        setLoadingUser(true);
        fetchUserNamesBySearchString(searchString)
            .then((result) => {
                setLoadingUser(false);
                if (result?.length !== 0) {
                    setMatchedUserNames(result)
                }
            });
        // setLoadingUser(false);
    };

    const renderUserNamesOptions = () => {
        return matchedUserNames?.map((item, index) => {
            return <Option key={index} value={JSON.stringify(item)} title={item.fullName}>
                {`${item.fullName} (${item.sslHandle})`}
            </Option>
        })
    }

    const renderAdditonalQuestions = (eachQuestion) => {
        const { getFieldDecorator } = props.form
        const { title, id, answers, answerType, isMandatory } = eachQuestion?.question
        const { answerText } = eachQuestion
        return (
            <>
                <Form.Item label={title}>
                    {answerType === CONFIG.demoAnswerTypes.Checkbox
                        ?
                        (getFieldDecorator(`${id}`, {
                            rules: [{ required: isMandatory, message: "Atleast one checkbox must be selected" }],
                            initialValue: !isReSchedule && (answerText?.split('//') || [])
                        })(<Checkbox.Group>{answers?.map(opt =>
                            (<Checkbox key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Checkbox>))}
                        </Checkbox.Group>))
                        : answerType === CONFIG.demoAnswerTypes.RadioButton
                            ? (getFieldDecorator(`${id}`, {
                                rules: [{ required: isMandatory, message: "Please select an option" }],
                                initialValue: !isReSchedule && (answerText || undefined)
                            })(
                                <Radio.Group>
                                    {answers?.map(opt =>
                                        (<Radio key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Radio>))}
                                </Radio.Group>))
                            : (getFieldDecorator(`${id}`, {
                                rules: [{ required: isMandatory, message: "This field cannot be blank" }],
                                initialValue: !isReSchedule && (answerText || undefined)
                            })(<TextArea style={{ width: '700px' }} rows={2} />))
                    }
                </Form.Item>
            </>)
    }

    const isAdminUser = user.profile.roleTemplate.templateType === CONFIG.roleTypes.admin || user.profile.roleTemplate.templateType === CONFIG.roleTypes.superAdmin

    const handleAssigneeChange = (userObj) => {
        const user = JSON.parse(userObj);
        props.form.setFieldsValue({ demoPurpose: undefined })
        dispatch(fetchDemoPurposeTypes(true, user))
        if (user.isActive) {
            getUserDemoAccess(demo?.id, user).then(result => {
                if (result) {
                    setIsFormDisabled(false);
                    setDemoAccess(true);
                    setActiveUser(true);
                }
                else {
                    setIsFormDisabled(true);
                    setDemoAccess(false);
                    setActiveUser(true);
                }
            })
        }
        else {
            setIsFormDisabled(true);
            setActiveUser(false);
            setDemoAccess(true);
        }
    }

    const renderDemoErrorAlert = () => {
        const message = !demoAccess ? "The selected user does not have access to the demo" : !isActiveUser ? "The selected user is currently inactive" : null;
        return (<Alert
            message={message}
            type="error"
            showIcon
        />)
    }

    const renderManagers = () => {
        let keys = Object.keys(managers);
        keys.sort((a, b) => {
            if (profile?.region?.name === a?.replace('List', "").toUpperCase()) return -1;
            if (profile?.region?.name !== a?.replace('List', "").toUpperCase()) return 1;
            return 0;
        })
        if (keys.length > 0) {
            //eslint-disable-next-line
            return keys?.map(key => {
                if (managers[key]?.length > 0) {
                    return <OptGroup
                        label={<span style={{ fontWeight: "600", color: "black", fontFamily: 'Noto Sans', }}>{key?.replace('List', "").toUpperCase()} {profile?.region?.name === key?.replace('List', "").toUpperCase() ? "(Recommended)" : null}</span>}
                    >
                        {
                            managers[key]?.map((manager, index) => {
                                return (
                                    <Option key={index} value={manager} title={manager}>
                                        {manager}
                                    </Option>
                                )
                            })
                        }
                    </OptGroup>
                }
            })
        }
        else return []
    }

    const isValidView = window.location.pathname.includes("/scheduler/lab");
    if (!isValidView) return null;
    else return (
        <div style={{ margin: "10px" }}>
            <Spin indicator={antIcon} spinning={loading}>
                {(!demoAccess || !isActiveUser) && renderDemoErrorAlert()}
                {renderScheduleButtons()}
                {notes && <Row className='vertical-spacing-3'>
                    <Alert
                        className='scheduler-alert'
                        message={notes}
                        type="info"
                        showIcon
                    />
                </Row>}
                <Row gutter={20}>
                    {isAdminUser &&
                        <Col xl={6} sm={12} xs={24} >
                            <Form.Item label="Assignee">
                                {
                                    getFieldDecorator('assignee', {
                                        rules: [{ required: true, message: 'Please select an assignee' }],
                                        initialValue: isEditSchedule ? schedule?.createdBy?.fullName : JSON.stringify(profile)
                                    })(
                                        <Select
                                            showSearch
                                            placeholder={`Type name to search`}
                                            onSearch={fetchUserNameString}
                                            notFoundContent="No Match found!"
                                            onChange={handleAssigneeChange}
                                            disabled={isEditSchedule}
                                            loading={isLoadingUser}
                                        >
                                            {<Option value={JSON.stringify(profile)} title="Myself"> Myself</Option>}
                                            {renderUserNamesOptions()}
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    }
                    <Col xl={isAdminUser ? 6 : 8} sm={12} xs={24} >
                        <Form.Item
                            label="Selected Demo"
                        >
                            {getFieldDecorator("selectedDemo", {
                                initialValue: props.demo?.title || '',
                            },
                            )
                                (
                                    <Input disabled title={props.demo?.title} />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col xl={isAdminUser ? 6 : 8} sm={12} xs={24} >
                        <Form.Item label="Time Zone" name="timeZone">
                            <TimeZone handleChange={handleTimezoneChange} defaultValue={timeZone} fullWidth />
                        </Form.Item>
                    </Col>
                    <Col xl={isAdminUser ? 6 : 8} sm={12} xs={24} >
                        <Form.Item label="Purpose">
                            {getFieldDecorator('demoPurpose', {
                                rules: [{
                                    required: true,
                                    message: 'Please input demo purpose'
                                }],
                                initialValue: !isReSchedule ? isEditSchedule ? isPurposeDeleted ? undefined : JSON.stringify(schedule?.demoPurpose) : testingGroup?.length > 0 ? JSON.stringify(testingGroup[0]) : undefined : testingGroup?.length > 0 ? JSON.stringify(testingGroup[0]) : undefined
                            })
                                (< Select showSearch placeholder="Select a purpose" onChange={handlePurposeSelectChange}>
                                    {renderPurposeTypesOptions()}
                                </Select>)
                            }
                        </Form.Item>
                        {(isPurposeDeleted && showMessage) && <Alert
                            message="The selected purpose may be inactive or deleted. Please select a different purpose"
                            type="warning"
                            showIcon
                        />}
                    </Col>
                </Row>
                <Row gutter={48}>

                    {(!isPurposeDeleted && additionalData?.length > 0) && additionalData.map((eachItem) => {
                        return renderAdditionalFields(eachItem, additionalData?.length, props, data, isCurrentSchedule, isReSchedule)
                    }
                    )}
                </Row>
                <br />
                <Row gutter={20}>
                    <Col xl={6} sm={12} xs={24} >
                        <Form.Item label="Start Time">
                            {getFieldDecorator("startTime", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please select start time"
                                    }
                                ],
                                initialValue: !isReSchedule ? isEditSchedule ? moment.tz(schedule?.startTime, profile?.timezone) : undefined : undefined
                            })(<DatePicker
                                className="full-width"
                                disabledDate={disabledDate}
                                onChange={handleDateChange}
                                value={startValue}
                                showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                format={CONFIG.dateFormats.default}
                                placeholder="Start Time"
                                showToday={false}
                            />)}
                        </Form.Item>
                    </Col>
                    <Col xl={6} sm={12} xs={24} >
                        <Form.Item label="Expiry Time">
                            {getFieldDecorator("endTime", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please select valid start time"
                                    }
                                ],
                                initialValue: !isReSchedule ? endValue || (schedule?.endTime && moment.tz(schedule?.endTime, profile?.timezone)) || undefined : undefined
                            })
                                (<DatePicker
                                    className="full-width"
                                    value={endValue}
                                    placeholder="Expiry Time"
                                    showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                    format={CONFIG.dateFormats.default}
                                    disabled={true}
                                />)
                            }
                        </Form.Item>
                    </Col>
                    <Col xl={4} sm={12} xs={24} >
                        <Form.Item label={<span>Demo Duration <Tooltip title={CONFIG.systemMessage.demoDuration}><Icon className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                            {getFieldDecorator("defaultDuration", {
                                initialValue: isEditSchedule ? `${schedule?.demo?.scheduleRange} Day(s)` : `${demo?.scheduleRange} Day(s)`
                            })(
                                <Input disabled={true} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xl={4} sm={12} xs={24} >
                        <Form.Item label={<span>Buffer Time <Tooltip title={CONFIG.systemMessage.buildDuration}><Icon className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                            {getFieldDecorator("bufferTime", {
                                initialValue: `${demo?.startTimeBuffer / 60} Hour(s)`
                            })(
                                <Input disabled={true} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xl={4} sm={12} xs={24} >
                        <Form.Item
                            label="Max Simultaneous Demos"
                        > {getFieldDecorator("maxSchudule", {
                            initialValue: isEditSchedule ? schedule?.demo?.maxSchedule : demo?.maxSchedule
                        })
                            (<Input disabled />)
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <br />
                <Row gutter={20}>
                    {props.demo?.demoType === CONFIG.demoTypes.labPocDemo && <>
                        {isManagerEmail && <Col xl={6} sm={12} xs={24}>
                            <Form.Item
                                label={managerTitle}
                            >
                                {getFieldDecorator("managersEmail", {
                                    rules: [
                                        {
                                            required: isManagerMandatory,
                                            type: "email",
                                            message: "Please enter a valid email-id"
                                        }
                                    ],
                                    initialValue: !isReSchedule ? schedule?.managerEmail : undefined
                                })
                                    (<Input />)
                                }
                            </Form.Item>
                        </Col>}
                        {isRegionalPrime && <Col xl={6} sm={12} xs={24} >
                            <Form.Item
                                label={regionalPrimeTitle}
                            >
                                {getFieldDecorator("regionalPrimeEmail", {
                                    rules: [
                                        {
                                            required: true,
                                            type: "email",
                                            message: "Please enter a valid email-id"
                                        }
                                    ],
                                    initialValue: !isReSchedule ? schedule?.regionalPrimeEmail : undefined
                                })
                                    (
                                        <Select
                                            showSearch={true}
                                            placeholder="Please select regional prime"
                                            dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                                            allowClear
                                            notFoundContent={null}
                                        >
                                            {renderManagers()}
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>}
                    </>}
                    <Col xl={6} sm={12} xs={24} >
                        <Form.Item
                            label="Country"
                        > {getFieldDecorator("country", {
                            rules: [
                                {
                                    required: true,
                                    message: "Please enter a country"
                                }
                            ],
                            initialValue: !isReSchedule ? schedule?.country ? JSON.stringify(schedule?.country) : undefined : undefined,
                        })
                            (<Select showSearch={true} placeholder="Select a country" >
                                {renderCountryOptions()}
                            </Select>)
                            }
                        </Form.Item>
                    </Col>

                </Row>
                {(isEditSchedule
                    ? (schedule?.demo?.parameters !== "" && schedule?.demo?.parameters !== null && schedule?.demo?.parameters !== undefined)
                    : (demo?.parameters !== "" && demo?.parameters !== null && demo?.parameters !== undefined))
                    && <Row style={{ marginLeft: "1px" }} gutter={20}>
                        <Form.Item
                            label="Please select desired capabilities"
                        >
                            {getFieldDecorator("parameters", {
                                initialValue: !isReSchedule ? isEditSchedule ? selectedParameters : "" : ""
                            })
                                (params && params?.length > 0 && <Checkbox.Group>{params.map((parameter, index) =>
                                    (<Checkbox key={index} label={parameter} value={parameter}>{parameter}</Checkbox>))}
                                </Checkbox.Group>)}
                        </Form.Item>
                        <Divider className="approvals-divider" />
                    </Row>}
                {additionalDemoQuestions?.map(ques => { return renderAdditonalQuestions(ques) })}
                <Divider className="approvals-divider" />
                {isTimeZoneChanged && <div>
                    <div><span style={{ fontWeight: 'bold', color: 'red', fontFamily: 'Noto Sans', }}>Availability View Only</span></div> <br />
                    <LabDemoSchedulerMobiscroll
                        demo={demo}
                        schedule={isEditSchedule ? schedule : null}
                        maxSchedule={isEditSchedule ? schedule?.demo?.maxSchedule : demo?.maxSchedule}
                        timeZone={timeZone}
                        fromDashboard={fromDashboard}
                        pathValues={pathValues}
                    />
                </div>}
                <Divider />
                {renderScheduleButtons()}
            </Spin>
        </div >
    )
}

const LabDemoScheduleForm = Form.create({ name: 'register' })(LabDemoSchedule);
export default LabDemoScheduleForm;