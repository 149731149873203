import React from 'react';
import { Icon, Row, Tooltip, Popover, Spin, Typography, Modal, message, Table as AntTable, Col } from 'antd';
import { connect, batch } from 'react-redux';
import { Dropdown } from 'antd5';
import moment from 'moment-timezone';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { getDemoCardWarnings } from '../../utils/dashboardCardMessage';
import CONFIG from '../../config';
import { renderStatusChip, getApprovalStatus } from '../../utils/dashboard';
import { Button, Pagination, Icon as NeoIcon, Tooltip as NeoTooltip } from "@avaya/neo-react"
import DemoStatusTagNeo from '../common/DemoStatusTagNeo'
import { exportMenu } from "../../utils/strings";
import pushMessage from '../common/PushMessage';
import NeoTable from '../common/NeoTable';
import { LoadingOutlined } from '@ant-design/icons';
import FilterDrawer from '../common/FilterDrawer';
import DemoRegistrationModal from '../common/DemoRegistrationModal';
import ScheduledDemoViewDetails from '../ManageScheduledDemo/ScheduledDemoViewDetails';
import { isTabAlreadyOpen } from "../common/TabLayout";
import {
    fetchMySchedules,
    toggleIsFetchingMySchedules,
    openLinkInNewTab,
    toggleIsReschedule,
    toggleIsResourceReschedule,
    updateSchedulerStartEndTime,
    fetchActiveDemoResources,
    fetchSchedulesOverDuration,
    updateCurrentDemoSchedule,
    updateUserSchedulesParams,
    exportTable,
    toggleBackButton,
    fetchAllExistingDemos,
    fetchAllResources,
    fetchMySchedulesForExportPrint,
    optOutSchedule,
    deleteDemoSchedule,
    fetchDataCenters,
    retainMyScheduleFilter,
    clearMyDemoSchedules,
    deleteBatchDemoSchedule,
    optOutBatchDemoSchedule
} from '../../actions';
import ScheduleDeleteConfirmation from '../common/ScheduleDeleteConfirmation';
import SFDCOppDrawer from '../common/SFDCOppDrawer';
const { Text } = Typography;

const dataIndexMap = {
    demo: 'demo.title',
    resource: 'resource.name',
    startTime: 'startTime',
    endTime: 'endTime',
};

class MySchedules extends React.Component {

    constructor(props) {
        super(props);
        this.printStartDate = undefined
        this.printEndDate = undefined
        this.state = {
            loading: true,
            TableData: [],
            isActiveSchedules: true,
            printPreview: false,
            startDate: undefined,
            endDate: undefined,
            height: `${document.body.clientHeight - (document.body.clientHeight * .2)}px`,
            viewScheduleDetailsRecordId: undefined,
            viewScheduleDetails: false,
            showFilter: false,
            registrationModalVisible: false,
            demoRequestData: null,
            deleteModalVisible: false,
            isBatchDelete: false,
            isLoggedInUserCreator: false,
            hasParticipants: false,
            oppUpdateModal: false,
            scheduleDetails: null,
        }
    }
    currentPage = 1;
    pageSize = CONFIG.pageSize
    filterSort = {};
    printData = {};

    async componentDidMount() {
        const { isEdit, currentFilter, pageNumber, pageSize, status, roleTemplate } = this.props;
        const shouldFetchDemos = roleTemplate && (roleTemplate.templateType === CONFIG.roleTypes.superAdmin || roleTemplate.templateType === CONFIG.roleTypes.admin)
        this.setState({ currentPath: window.location.pathname })
        if (isEdit) {
            await this.setState({
                isActiveSchedules: status,
                startDate: currentFilter?.filter?.startTime || undefined,
                endDate: currentFilter?.filter?.endTime || undefined
            });
            this.printStartDate = currentFilter?.filter?.startTime || undefined;
            this.printEndDate = currentFilter?.filter?.endTime || undefined;
            this.currentPage = pageNumber || 1;
            this.pageSize = pageSize || CONFIG.pageSize;
            this.filterSort = { ...currentFilter, filter: { ...currentFilter.filter, isActive: status } }
            if (currentFilter?.filter !== undefined && (currentFilter?.filter?.[dataIndexMap.demo] || currentFilter?.filter?.startTime || currentFilter?.filter?.endTime || currentFilter?.filter?.[dataIndexMap.resource])) this.setState({ showFilter: true })

        } else {
            this.filterSort = { filter: { isActive: true } }
            this.setState({ isActiveSchedules: true });
        }

        this.props.toggleBackButton(false, { pathname: '/my-schedules' });
        this.props.toggleIsFetchingMySchedules(true);
        batch(async () => {
            await this.props.fetchMySchedules({ pageNumber: this.currentPage, filterSort: this.filterSort })
            shouldFetchDemos && await this.props.fetchAllExistingDemos({ getAll: true });
            shouldFetchDemos && await this.props.fetchAllResources();
            await this.props.fetchDataCenters();
        });
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.retainMyScheduleFilter(this.filterSort, this.state.isActiveSchedules, this.currentPage, this.pageSize)
        }
        else {
            this.props.clearMyDemoSchedules();
        }
        window.removeEventListener("resize", this.handleResize);
    }

    handleSelectSchedule = (selectedDemo) => {
        this.props.openLinkInNewTab(selectedDemo.demo?.title, `/solutions/demo/${selectedDemo.demo?.id}`)
    }

    handleResize = (e) => {
        this.setState({ height: document.body.clientHeight > 250 ? `${document.body.clientHeight - (document.body.clientHeight * 0.2)}px` : `250px` });
    }


    handleDemoScheduleUpdate = async (scheduleId, schedule, isReschedule, isEdit, fromRescheduleEmail, isBatchEdit) => {
        const { demo } = schedule;
        const { isDemoAlreadyRequested, showRequestButton, showScheduleButton, demoType } = demo;
        const { timeZoneName } = this.props;

        const schedulerStartTime = this.state.isActiveSchedules
            ? moment(schedule?.startTime).tz(timeZoneName).startOf('day').toISOString()
            : moment().tz(timeZoneName).startOf('day').toISOString();
        const schedulerEndTime = this.state.isActiveSchedules
            ? moment(schedule?.startTime).tz(timeZoneName).endOf('day').toISOString()
            : moment().tz(timeZoneName).endOf('day').toISOString();

        // const startTime = moment(schedule?.startTime).tz(timeZoneName)?.startOf('day').toISOString();

        // const endTime = moment(schedule?.startTime).tz(timeZoneName)?.endOf('day').toISOString();
        this.props.updateSchedulerStartEndTime(schedulerStartTime, schedulerEndTime);

        //  !isReschedule is to check if the user is editing the schedule.
        if (showScheduleButton || isEdit) {
            await this.props.fetchActiveDemoResources(demo?.id)
                // .then(async () => await this.props.fetchSchedulesOverDuration(demo?.id, startTime, endTime))
                .then(() => this.props.updateCurrentDemoSchedule({ ...schedule, id: scheduleId }, fromRescheduleEmail))
                .then(() => this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`,
                    (isBatchEdit && demoType === CONFIG.demoTypes.standardDemo) ? `/scheduler/standard/${scheduleId}/edit/isReschedule=${isReschedule}/isBatchEdit=${isBatchEdit}/batchId=${schedule?.batchNumber}`
                        : `/scheduler/${demoType === CONFIG.demoTypes.standardDemo ? "standard" : "lab"}/${scheduleId}/edit/isReschedule=${isReschedule}/fromManageSide=${false}`))
            // .then(() => history.replace({
            //     pathname: demoType === CONFIG.demoTypes.standardDemo ? `/scheduler/standard/${scheduleId}/edit` : `/scheduler/lab/${scheduleId}/edit`,
            //     fromDashboard: false,
            //     isReschedule: isReschedule,
            //     pathValues: { pathname: '/my-schedules' }
            // }))
        }
        else if (showRequestButton) {
            this.handleDemoRequestEdit(schedule)
        }
        else if (isDemoAlreadyRequested) return null
    }

    renderDemoTitle = (schedule) => {
        const tooltipMessages = getDemoCardWarnings(schedule)
        const DemoStatusTooltipMessage = tooltipMessages[0];
        // const hasParticipants = false;
        const hasParticipants = this.state.isActiveSchedules && schedule?.participants !== null;
        const scheduleCreator = hasParticipants && [...schedule?.participants?.eapUsers, ...schedule?.participants?.extUsers]?.filter(par => par?.isCreator === true)?.[0];
        const scheduleParticipants = hasParticipants && [...schedule?.participants?.eapUsers, ...schedule?.participants?.extUsers]?.filter(par => par?.isCreator !== true);
        return (<>
            {
                <span>
                    <span
                        className={schedule.demo?.isActive ? "hover-link" : undefined}
                        onClick={() => schedule.demo?.isActive && this.handleSelectSchedule(schedule)}
                        style={{ color: '#1B77AF' }}
                    >{schedule.demo.title}</span>
                    <span>
                        {hasParticipants &&
                            <span>
                                <Popover
                                    placement="right"
                                    className="float-right"
                                    content={<React.Fragment>
                                        <p><Text underline>Creator</Text><br />{`${scheduleCreator?.fullName} (${scheduleCreator?.email})`}</p>
                                        <p><Text underline>Participants</Text><br />{scheduleParticipants?.map(par => (<div>{par?.fullName} ( {par?.email} )<br /></div>))}</p>
                                    </React.Fragment>}
                                    trigger="hover"
                                >
                                    &nbsp;<span class="neo-icon-user-group" style={{ fontSize: "20px", paddingRight: "10px" }}></span>
                                </Popover>
                            </span>}
                    </span>
                </span>}
            {schedule.demo?.demoType === CONFIG.demoTypes.standardDemo && DemoStatusTooltipMessage?.length > 0 && < Tooltip
                title={DemoStatusTooltipMessage.length > 1 ? <>{DemoStatusTooltipMessage?.map((message, index) => (<div key={index}>&#8226;&nbsp;{message}</div>))}</> : DemoStatusTooltipMessage[0]}>
                &nbsp;< Icon type="warning" theme="twoTone" twoToneColor="#ff9000" />
            </Tooltip>}
        </>)
    }

    handleUpdate = async (schedule, type, isBatchEdit) => {
        if (type !== CONFIG.demoTypes.standardDemo && !schedule?.isMaintenance) {
            this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${false}`)
            return
        }

        if (type === CONFIG.demoTypes.standardDemo && !schedule?.isMaintenance) {
            this.handleDemoScheduleUpdate(schedule.id, schedule, true, false, false, isBatchEdit);
        }
        else {
            await this.props.handleOnClickAction({
                component: CONFIG.editComponentRoute.scheduleMaintenance,
                tabTitle: schedule?.title,
                recordId: schedule?.id
            }
            )
        }
    }

    showStatusTag = (schedule) => {
        const { allDataCenters } = this.props;
        this.activeDataCenters = allDataCenters?.filter(datacenter => datacenter.active === true) || [];
        const tooltipMessages = getDemoCardWarnings(schedule)
        const showEditOrRescheduleButton = tooltipMessages[2];
        const DemoStatusTooltipMessage = tooltipMessages[0]
        const demoDCStatuses = this.activeDataCenters?.map(dataCenter => schedule.demo?.demoDCStatuses?.find(demoDCStatus => demoDCStatus.dataCenter.id === dataCenter.id) || { dataCenter, status: 2 });
        const demoResourceDCStatus = demoDCStatuses.find(demoDCStatus => demoDCStatus?.dataCenter?.id === schedule.resource?.dataCenter?.id)
        const scheduledDC = demoDCStatuses?.find((dataCenters) => {
            return dataCenters?.dataCenter?.id === schedule?.resource?.dataCenter?.id
        })
        let count = 0, dataCenter;
        demoDCStatuses.forEach((item) => {
            if (item.status === CONFIG.demoStatus.dataCenterTypes.green || item.status === CONFIG.demoStatus.dataCenterTypes.yellow) {
                count += 1;
                dataCenter = item;
            }
        })
        const showCheckAvailbility = (count > 1 || (count === 1 && (dataCenter?.dataCenter?.id !== scheduledDC?.dataCenter?.id)))
        const showReschedule = (count === 1 && (dataCenter?.dataCenter?.id === scheduledDC?.dataCenter?.id))
        return schedule.demo?.demoType === CONFIG.demoTypes.standardDemo ? <DemoStatusTagNeo
            demoResourceDCStatus={demoResourceDCStatus}
            timezone={this.props.timeZoneName}
            scheduleId={schedule.id}
            handleReschedule={this.handleDemoScheduleUpdate}
            handleUpdate={this.handleUpdate}
            disabledReschedule={showEditOrRescheduleButton}
            toggleIsReschedule={this.props.toggleIsReschedule}
            toggleIsResourceReschedule={this.props.toggleIsResourceReschedule}
            scheduleObject={schedule}
            isActiveSchedules={this.state.isActiveSchedules}
            errorMessage={DemoStatusTooltipMessage}
            roleTemplate={this.props.roleTemplate}
            showCheckAvailbility={showCheckAvailbility}
            showReschedule={showReschedule}
        />
            : (schedule.demo?.demoType === CONFIG.demoTypes.labPocDemo || schedule.demo?.demoType === CONFIG.demoTypes.restfulApiDemo) ? renderStatusChip(schedule, false) : "-"
    }

    handleReschedule = (schedule, type) => {
        const demo = schedule?.demo;
        const { isDemoAlreadyRequested, showRequestButton, showScheduleButton } = demo;
        if (type !== CONFIG.demoTypes.standardDemo) {
            if (showScheduleButton)
                this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${true}/fromManageSide=${false}`)
            else if (showRequestButton) {
                this.handleDemoRequestEdit(schedule)
            }
            else if (isDemoAlreadyRequested) return null
            return
        }

        this.props.toggleIsReschedule(true)
        if (type === CONFIG.demoTypes.standardDemo) {
            this.handleDemoScheduleUpdate(schedule.id, schedule, true);
        }
    }

    handleEdit = async (schedule, type, isBatchEdit) => {
        if (type !== CONFIG.demoTypes.standardDemo) {
            this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${false}`)
            return
        }

        if (type === CONFIG.demoTypes.standardDemo) {
            this.handleDemoScheduleUpdate(schedule.id, schedule, false, true, false, isBatchEdit);
        }
    }

    handleScheduleDelete = (hasParticipants, schedule) => {
        const record = hasParticipants ? this.state.recordToDelete : schedule
        const demoType = record?.demo?.demoType
        this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE });
        this.handleDemoScheduleCancel(record.id, demoType)
        this.handleCloseDeleteSharedSchedule();
    }

    handleDelete = (isDeleteForEveryone) => {
        const { recordToDelete, hasParticipants, isBatchDelete, isLoggedInUserCreator } = this.state;
        const record = recordToDelete;
        if (!isBatchDelete) {
            if (!hasParticipants) {
                const demoType = record?.demo?.demoType
                this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE });
                this.handleDemoScheduleCancel(record.id, demoType);
                this.handleDeleteModalClose();
            } else {
                if (!isDeleteForEveryone && !isLoggedInUserCreator) {
                    this.handleRemove();
                } else {
                    const demoType = record?.demo?.demoType
                    this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE });
                    this.handleDemoScheduleCancel(record.id, demoType);
                    this.handleDeleteModalClose();
                }
            }
        } else {
            this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE });
            this.handleDemoBatchScheduleCancel(record?.batchNumber);
            this.handleDeleteModalClose();
        }
    }

    handleCloseDeleteSharedSchedule = () => {
        this.setState({
            recordToDelete: undefined,
            deleteOptionsVisible: false,
        })
    }

    handleDeleteSharedSchedule = (record) => {
        this.setState({
            recordToDelete: record,
            deleteOptionsVisible: true
        })
    }

    renderScheduleDetails = () => {
        return (
            <Modal
                className='extra-wide-modal'
                visible={this.state.viewScheduleDetails}
                onCancel={() => this.setState({ viewScheduleDetailsRecordId: undefined, viewScheduleDetails: false })}
                footer={[
                    <Button id="view-details-close" className="primary-action-button-filled" key="back" onClick={() => this.setState({ viewScheduleDetailsRecordId: undefined, viewScheduleDetails: false })}>
                        Close
                    </Button>]}

            >
                <ScheduledDemoViewDetails
                    fromDashboard={true}
                    scheduleId={this.state.viewScheduleDetailsRecordId}
                />
            </Modal>
        )
    }

    renderDeleteOptions = () => {
        return (
            <Modal
                width={'25%'}
                title="Confirm Delete?"
                visible={this.state.deleteOptionsVisible}
                footer={null}
                onCancel={() => this.setState({ deleteOptionsVisible: false })}
            >
                <div className="delete-option-modal">

                    <div className='schedule-delete-button'><Button
                        aria-label="Delete for me"
                        className="delete-option-button primary-action-button-filled"
                        onClick={this.handleRemove} > Delete for me
                    </Button><br />
                        <Button
                            aria-label="Delete for Everyone"
                            className="delete-option-button primary-action-button-filled"
                            onClick={() => this.handleScheduleDelete(true)} > Delete for Everyone
                        </Button>
                    </div><br />
                    <Button
                        aria-label="Cancel"
                        className="delete-cancel-button cancel-button"
                        onClick={() => this.setState({ deleteOptionsVisible: false })} > Cancel
                    </Button>
                </div>
            </Modal>)
    }

    handleRemove = () => {
        const record = this.state.recordToDelete
        const { user } = this.props;
        const eapUsers = record?.participants?.eapUsers?.filter((eachUser) => eachUser?.id !== user?.id);
        const schedule = {
            createdBy: record?.createdBy,
            demo: record?.demo,
            demoPurpose: record?.demoPurpose,
            endTime: record?.endTime,
            endTimeBuffer: record?.endTimeBuffer,
            id: record?.id,
            batchNumber: record?.batchNumber,
            isOneTime: record?.isOneTime,
            isPreRegistration: record?.isPreRegistration,
            purposeAttributesSchedule: record?.purposeAttributesSchedule,
            resource: record?.resource,
            startTime: record?.startTime,
            startTimeBuffer: record?.startTimeBuffer,
            userAnswers: record?.userAnswers,
            participants: {
                ...record.participants,
                eapUsers
            } || null
        }

        this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE });
        this.handleOptout(schedule, record?.demoType);
        this.handleCloseDeleteSharedSchedule();
        this.handleDeleteModalClose();
    }

    handleOptout = async (record, demoType) => {
        this.props.toggleIsFetchingMySchedules(true);
        await this.props.optOutSchedule(record, demoType);
        await this.props.fetchMySchedules({ pageNumber: this.currentPage, pageSize: this.pageSize, filterSort: this.filterSort });
        this.props.toggleIsFetchingMySchedules(false);
    }

    handleBatchOptout = async (batchNumber) => {
        this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE });
        this.props.toggleIsFetchingMySchedules(true);
        await this.props.optOutBatchDemoSchedule(batchNumber);
        await this.props.fetchMySchedules({ pageNumber: this.currentPage, pageSize: this.pageSize, filterSort: this.filterSort });
        this.props.toggleIsFetchingMySchedules(false);
        this.handleDeleteModalClose();
    }

    handleDemoScheduleCancel = async (scheduleId, demoType, fromEmail) => {
        fromEmail && await this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE })
        await this.props.deleteDemoSchedule(scheduleId, false, demoType);
        this.props.toggleIsFetchingMySchedules(true);
        await this.props.fetchMySchedules({ pageNumber: this.currentPage, pageSize: this.pageSize, filterSort: this.filterSort });
        this.props.toggleIsFetchingMySchedules(false);
        Modal.destroyAll();
    }

    handleDemoBatchScheduleCancel = async (batchNumber) => {
        // fromEmail && await this.props.updateUserSchedulesParams({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.TABLE })
        await this.props.deleteBatchDemoSchedule(batchNumber);
        this.props.toggleIsFetchingMySchedules(true);
        await this.props.fetchMySchedules({ pageNumber: this.currentPage, pageSize: this.pageSize, filterSort: this.filterSort });
        this.props.toggleIsFetchingMySchedules(false);
        Modal.destroyAll();
    }

    renderActions = (record) => {
        const { timeZoneName, user, isMultipleScheduleBooking } = this.props;
        const bufferStart = this.state.isActiveSchedules && record?.demo?.startTimeBuffer;
        const demoType = record.demo?.demoType;
        const bufferStartTime = bufferStart > 0 ? moment.tz(record?.startTime, timeZoneName).subtract(bufferStart, 'm') : undefined;
        const isBufferTimeOver = record?.demo?.demoType !== CONFIG.demoTypes.standardDemo ? !(moment().tz(timeZoneName) < bufferStartTime) : false
        const isDemoSchedule = true;
        const showEditOrRescheduleButton = isDemoSchedule ? getDemoCardWarnings(record)[2] : true
        const approvedSchedule = record.status === CONFIG.approvalStatusTypes.approvedRequests?.value ? true : false
        const isExpiredSchedule = demoType === CONFIG.demoTypes.labPocDemo ? approvedSchedule ? false : true : false
        const isScheduleRated = record?.rating !== 0
        const isRatingLinkExpired = (record?.isOutOfRatingWindow || isExpiredSchedule)
        const disableEditButtonAfterApproval = record?.demo?.demoType === CONFIG.demoTypes.labPocDemo && (record?.demo?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value) || getApprovalStatus(record?.scheduleApproval))
        const isDeclinedOrExpired = record?.demo?.demoType === CONFIG.demoTypes.labPocDemo
            ? (record?.status === CONFIG.approvalStatusTypes.declinedRequests.value || record.status === CONFIG.approvalStatusTypes.expiredRequests.value)
            : false

        const { isDemoAlreadyRequested, showRequestButton, showScheduleButton } = record?.demo || [];
        const hasParticipants = this.state.isActiveSchedules && record?.participants !== null;
        const isLoggedInUserCreator = hasParticipants && [...record?.participants?.eapUsers, ...record?.participants?.extUsers]?.filter(par => par?.isCreator === true)?.[0]?.id === user?.id
        const isBatchSchedule = record?.batchNumber > 0 && record?.scheduledResources !== null;
        const isEditDisabled = isDemoSchedule && (record?.demoBuildId || !showEditOrRescheduleButton || disableEditButtonAfterApproval || record.demoBuildId || (isBufferTimeOver && (disableEditButtonAfterApproval || demoType === CONFIG.demoTypes.restfulApiDemo)))
        const batchNumber = record?.batchNumber;
        const isCreator = record?.createdBy?.id === user?.id;
        const activeViewItems = [
            {
                key: 'details',
                label: (
                    <span onClick={() => this.setState({
                        viewScheduleDetails: true,
                        viewScheduleDetailsRecordId: record?.id
                    })}><NeoIcon icon="alignment-left" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>View Details</span></span>

                ),
            },
            {
                key: 'edit',
                disabled: isEditDisabled,
                label: (batchNumber > 0 && isCreator && isMultipleScheduleBooking) ? <span><NeoIcon icon="edit" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Edit</span></span> : (
                    <span className='full-width-options'
                        disabled={isEditDisabled}
                        onClick={() => !isEditDisabled && this.handleEdit(record, record?.demo?.demoType, false)}
                    >
                        <span className='edit-dropdown'
                            title={isDemoSchedule && demoType !== CONFIG.demoTypes.standardDemo ? (isBufferTimeOver && ((disableEditButtonAfterApproval && !isDeclinedOrExpired) || demoType === CONFIG.demoTypes.restfulApiDemo)) ? "Schedule started" : disableEditButtonAfterApproval ? "Approval Process Started" : null : null}
                        ><NeoIcon icon="edit" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Edit</span></span>
                    </span>
                ),
                children: (batchNumber > 0 && isCreator && isMultipleScheduleBooking) ? [
                    {
                        key: 'occurence',
                        label: <span className='full-width-options'
                            disabled={isEditDisabled}
                            onClick={() => !isEditDisabled && this.handleEdit(record, record?.demo?.demoType, false)}>
                            Occurence
                        </span>,
                    },
                    {
                        key: 'series',
                        label: <span className='full-width-options'
                            disabled={isEditDisabled}
                            onClick={() => !isEditDisabled && this.handleEdit(record, record?.demo?.demoType, true)}>
                            Series
                        </span>,
                    },
                ] : null,
            },

            {
                key: 'delete',
                label: ((isBatchSchedule && isMultipleScheduleBooking && (!hasParticipants || isLoggedInUserCreator)) ?
                    <span className='full-width-options'
                    ><NeoIcon icon="backspace" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span>Delete</span></span>
                    :
                    <span className='full-width-options'
                        onClick={async () => await this.setState({ recordToDelete: record, isBatchDelete: false, deleteModalVisible: true, isLoggedInUserCreator: isLoggedInUserCreator, hasParticipants: hasParticipants })}
                    ><NeoIcon icon="backspace" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span>Delete</span></span>),
                children: (isBatchSchedule && isMultipleScheduleBooking && (!hasParticipants || isLoggedInUserCreator)) ? [
                    {
                        key: 'occurence',
                        label: <span
                            onClick={async () => await this.setState({ recordToDelete: record, isBatchDelete: false, deleteModalVisible: true, isLoggedInUserCreator: isLoggedInUserCreator, hasParticipants: hasParticipants })}
                        ><span>Occurence</span></span>,
                    },
                    {
                        key: 'series',
                        label: <span
                            onClick={async () => await this.setState({ recordToDelete: record, isBatchDelete: true, deleteModalVisible: true, isLoggedInUserCreator: isLoggedInUserCreator, hasParticipants: hasParticipants })}
                        ><span>Series</span></span>,
                    },
                ] : null,
            }
        ]

        const activeViewDeclineOrExpiredItems = [
            {
                key: 'details',
                label: (
                    <span onClick={() => this.setState({
                        viewScheduleDetails: true,
                        viewScheduleDetailsRecordId: record?.id
                    })}><NeoIcon icon="alignment-left" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>View Details</span></span>

                ),
            },
            {
                key: 'reschedule',
                disabled: !showEditOrRescheduleButton || isDemoAlreadyRequested,
                label: (
                    <span
                        onClick={(showEditOrRescheduleButton && !isDemoAlreadyRequested) && (() => this.handleReschedule(record, record?.demo?.demoType))}>
                        {showScheduleButton ? <span> <NeoIcon icon="refresh" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Reschedule</span></span> : showRequestButton
                            ? <span ><NeoIcon icon="posts" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Request</span></span>
                            : <span><NeoIcon icon="posts" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Requested</span></span>}
                    </span>
                ),
            },
            {
                key: 'delete',
                label: (
                    <span
                        onClick={async () => await this.setState({ recordToDelete: record, isBatchDelete: false, deleteModalVisible: true, isLoggedInUserCreator: isLoggedInUserCreator, hasParticipants: hasParticipants })}
                    ><NeoIcon icon="backspace" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Delete</span></span>
                ),
            }
        ]

        const previousViewItems = [
            {
                key: 'details',
                label: (
                    <span onClick={() => this.setState({
                        viewScheduleDetails: true,
                        viewScheduleDetailsRecordId: record?.id
                    })}><NeoIcon icon="alignment-left" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>View Details</span>
                    </span>

                ),
            },
            {
                key: 'reschedule',
                disabled: !showEditOrRescheduleButton || isDemoAlreadyRequested,
                label: (
                    <span
                        onClick={(showEditOrRescheduleButton && !isDemoAlreadyRequested) && (() => this.handleReschedule(record, record?.demo?.demoType))}>
                        {showScheduleButton ? <span> <NeoIcon icon="refresh" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Reschedule</span></span> : showRequestButton
                            ? <span ><NeoIcon icon="posts" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Request</span></span>
                            : <span><NeoIcon icon="posts" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Requested</span></span>}
                    </span>
                ),
            },
            {
                key: 'rating',
                disabled: isRatingLinkExpired,
                label: (
                    <span
                        onClick={() => !isRatingLinkExpired && this.props.openLinkInNewTab("Demo feedback - " + record?.demo?.title, `/demo-feedback/${record?.id}/${isScheduleRated}`)}>
                        {!isScheduleRated ? <span title={isRatingLinkExpired ? CONFIG.warningMessages.ratingExpired : undefined}> <NeoIcon icon="star-half-filled" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Rating</span></span> :
                            <span title={isRatingLinkExpired ? CONFIG.warningMessages.ratingExpired : undefined}> <NeoIcon icon="star-half-filled" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}> {`You Rated : ${record?.rating}`} <Icon type="star" theme="filled" style={{ color: "#fadb14" }} /></span></span>}
                    </span>
                ),
            }
        ];

        const oppNumberAction = {
            key: 'oppNo',
            label: (
                <span onClick={() => this.setState({
                    oppUpdateModal: true,
                    scheduleDetails: record
                })}><NeoIcon icon="awfos" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Update SFDC Details</span>
                </span>
            ),
        };

        const previousActions = record?.isCustomerDemoPurpose ? [...previousViewItems.slice(0, 2), oppNumberAction, ...previousViewItems.slice(2)] : previousViewItems;
        const items = this.state.isActiveSchedules ? isDeclinedOrExpired ? activeViewDeclineOrExpiredItems : activeViewItems : previousActions;
        return (

            <span >
                <Dropdown
                    overlayClassName='my-schedules-table-actions-menu'
                    trigger="click"
                    menu={{
                        items,
                    }}
                    placement="bottomLeft"
                    arrow
                >
                    <NeoIcon icon="ellipses-horizontal" className='pointer' style={{ fontSize: "19px", color: "#1B77AF" }} />
                </Dropdown>
            </span>
        )
    }

    renderResourceWithStatus = (schedule) => {
        const tooltipMessages = getDemoCardWarnings(schedule);
        const resourceTooltipMessage = tooltipMessages[1];
        if (schedule?.demo?.demoType === CONFIG.demoTypes.standardDemo) {
            return (
                <span>{schedule.resource?.name}
                    {resourceTooltipMessage?.length > 0 && < NeoTooltip
                        label={resourceTooltipMessage.length > 1 ? <>{resourceTooltipMessage?.map((message, index) => (<div key={index}>&#8226;&nbsp;{message}</div>))}</> : resourceTooltipMessage[0]}>
                        &nbsp;< Icon type="warning" theme="twoTone" twoToneColor="#ff9000" />
                    </NeoTooltip>}
                </span>
            )
        } else { return "-" }
    }

    createTableData = () => {
        const { schedules } = this.props;
        let data = [];
        for (let i = 0; i < schedules?.length; i++) {
            let tempSch = {
                id: schedules[i].id,
                demo: this.renderDemoTitle(schedules[i]),
                startTime: moment(schedules[i].startTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime),
                updatedTime: moment(schedules[i].demo.updatedTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime),
                resource: this.renderResourceWithStatus(schedules[i]),
                status: this.showStatusTag(schedules[i]),
                actions: this.renderActions(schedules[i])
            }
            data.push(tempSch);
        }
        return data;
    }

    handleClear = async (isRefresh) => {
        await this.props.clearMyDemoSchedules()
        if (!isRefresh) {
            this.filterSort = { filter: { isActive: this.state.isActiveSchedules } }
        }
        this.handleFetchMySchedules();
    }

    handleViewChange = (isActive) => {
        this.setState({ isActiveSchedules: isActive });
        this.filterSort = { filter: { ...this.filterSort?.filter, isActive: isActive } }
        this.currentPage = 1;
        this.props.toggleIsFetchingMySchedules(true);
        this.props.fetchMySchedules({ pageNumber: this.currentPage, filterSort: this.filterSort });
    }

    handlePageChange = (e) => {
        this.currentPage = e;
    }

    handleExport = async (fileType) => {
        const endPoint = CONFIG.exportTable.dashboardSchedules.demo;
        const { timeZoneName } = this.props;
        const exportData = await this.props.fetchMySchedulesForExportPrint({ filterSort: this.filterSort });
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportTable(endPoint, exportData, timeZoneName, this.setTablePrintHeading()[1] + `.${fileType}`, fileType)
            .then(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.success, "Table exported successfully")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString() || undefined
    }


    handleFilter = async (filters) => {
        let { demo, resource, startEndTime } = filters;
        const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isResourceEmpty = !resource || (resource && resource.length === 0);
        this.filterSort = {
            ...this.filterSort,
            filter: {
                [dataIndexMap.demo]: !isDemoEmpty ? typeof demo === 'string' ? demo : demo.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.resource]: !isResourceEmpty ? typeof resource === 'string' ? resource : resource.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.startTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0].startOf('day')) : undefined,
                [dataIndexMap.endTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1].endOf('day')) : undefined,
                isActive: this.state.isActiveSchedules
            }
        }
        if (!isStartEndTimeEmpty) {
            this.setState({
                startDate: this.filterSort?.filter?.startTime || undefined,
                endDate: this.filterSort?.filter?.endTime || undefined
            });
            this.printStartDate = this.filterSort?.filter?.startTime || undefined;
            this.printEndDate = this.filterSort?.filter?.endTime || undefined;
        }
        this.currentPage = 1;
        this.handleFetchMySchedules()

    }

    setTablePrintHeading = () => {
        const { timeZoneName } = this.props
        const printStartDate = this.printStartDate && moment.tz(this.printStartDate, timeZoneName).format(CONFIG.dateFormats.calendarDate)
        const printEndDate = this.printEndDate && moment.tz(this.printEndDate, timeZoneName).format(CONFIG.dateFormats.calendarDate)

        let printPageHeading;
        let fileName;
        if (this.state.isActiveSchedules) {
            printPageHeading = <h4 className="center-align" >List of Active Scheduled Demos</h4>
            fileName = `List of Active Scheduled Demos`
        }
        else if ((printStartDate === undefined || printEndDate === undefined)) {
            printPageHeading = <h4 className="center-align">List of all Previous Scheduled Demos till date.</h4>
            fileName = `List of all Previous Scheduled Demos till date`
        }
        else {
            printPageHeading = <h4 className="center-align">List of Previous Scheduled Demos from {printStartDate} to {printEndDate}</h4>
            fileName = `List of Scheduled Demos from ${printStartDate} to ${printEndDate}`
        }
        return [printPageHeading, fileName]
    }

    handleTablePrint = async (handlePrint) => {
        this.printData = await this.props.fetchMySchedulesForExportPrint({ filterSort: this.filterSort });
        this.setState({ printPreview: true }, () => { handlePrint() })
    }

    closePrint = () => {
        this.setState({ printPreview: false })
    }

    handleFetchMySchedules = () => {
        this.props.toggleIsFetchingMySchedules(true);
        this.props.fetchMySchedules({ pageNumber: this.currentPage, pageSize: this.pageSize, filterSort: this.filterSort });
    }

    handlePagination = async (_, pageNumber) => {
        this.currentPage = pageNumber
        this.handleFetchMySchedules();
    }

    handleTableSortChange = async (pagination, _, sorter) => {
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }

        this.handleFetchMySchedules();
    }

    onItemsPerPageChange = async (_, pageSize) => {
        this.pageSize = pageSize
        this.currentPage = 1
        this.handleFetchMySchedules();
    }

    handleDemoRequestEdit = (record) => {
        this.setState({
            demoRequestData: { demo: record?.demo, viewOnly: false },
            registrationModalVisible: true
        })
    }

    handleDemoRequestModalClose = () => {
        this.setState({ demoRequestData: null, registrationModalVisible: false })
    }

    handleDemoRequestSubmit = () => {
        this.handleDemoRequestModalClose();
        this.handleClear(true)
    }

    handleDeleteModalClose = async () => {
        await this.setState({
            deleteModalVisible: false,
            recordToDelete: undefined,
            isBatchDelete: false,
            hasParticipants: false,
            isLoggedInUserCreator: false
        })
    }

    handleCloseFilterDrawer = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }))
    }

    render() {
        const { scheduleCount, schedules, roleTemplate } = this.props;
        const { registrationModalVisible, demoRequestData } = this.state;
        const shouldFetchDemos = roleTemplate && (roleTemplate.templateType === CONFIG.roleTypes.superAdmin || roleTemplate.templateType === CONFIG.roleTypes.admin)
        const columns = [
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.demo] ? "filtered-column" : ''}>Demo</span>,
                key: dataIndexMap.demo,
                align: 'left',
                width: "30%",
                render: (record) => this.renderDemoTitle(record),
                sorter: true,
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.startTime] ? "filtered-column" : ''}>Start Date/Time</span>,
                key: 'startTime',
                align: 'left',
                width: "18%",
                sorter: true,
                render: (record) => {
                    let bufferStartTime;
                    const bufferStart = record?.startTimeBuffer || 0;
                    bufferStartTime = bufferStart > 0 ? moment.tz(record.startTime, this.props.timeZoneName).subtract(bufferStart, 'm') : moment.tz(record.startTime, this.props.timeZoneName);
                    return (
                        <span>
                            {(bufferStart > 0) ? <span>
                                {moment(record?.startTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)}{" "}
                                <NeoTooltip
                                    position={'right'}
                                    label={<>
                                        <div>Buffer Start Time  - {bufferStartTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                    </>}>{<NeoIcon icon="info" style={{ color: '#147ecf' }} />}
                                </NeoTooltip></span> :
                                <span>{moment(record?.startTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)} </span>}
                        </span>
                    )
                }
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.endTime] ? "filtered-column" : ''}>End Date/Time</span>,
                key: 'endTime',
                align: 'left',
                width: "18%",
                sorter: true,
                render: (record) => {
                    let bufferEndTime;
                    const bufferEnd = record?.endTimeBuffer || 0;
                    bufferEndTime = bufferEnd > 0 ? moment.tz(record.endTime, this.props.timeZoneName).add(bufferEnd, 'm') : moment.tz(record.endTime, this.props.timeZoneName);
                    const demoType = record?.demo?.demoType;
                    return (
                        <span>
                            {(bufferEnd > 0 && demoType === CONFIG.demoTypes.standardDemo) ? <span>
                                {moment(record?.endTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)}{" "}
                                <NeoTooltip
                                    position={'right'}
                                    label={<>
                                        <div>Buffer End Time  - {bufferEndTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                    </>}>{<NeoIcon icon="info" style={{ color: '#147ecf' }} />}
                                </NeoTooltip></span> :
                                <span>{moment(record?.endTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)} </span>}
                        </span>
                    )
                }
            },
            {
                title: <span className={this.filterSort.filter?.[dataIndexMap.resource] ? "filtered-column" : ''}>Resource</span>,
                key: dataIndexMap.resource,
                width: "20%",
                align: 'left',
                sorter: true,
                render: (record) => this.renderResourceWithStatus(record)
            },
            {
                title: "Status",
                key: 'status',
                width: "20%",
                align: 'left',
                render: (record) => this.showStatusTag(record)
            },
            {
                title: "Batch",
                key: 'status',
                width: "20%",
                align: 'left',
                render: (record) => record?.batchNumber ? <Tooltip title={CONFIG.batchInfo}><NeoIcon style={{ color: "#5e5e5e" }} icon="queue-internal-call" size='md' /> </Tooltip> : "-"
            },
            {
                title: "",
                key: 'action',
                width: "5%",
                align: 'left',
                render: (record) => this.renderActions(record)
            },
        ];

        this.printColumn = [
            {
                title: 'Rating',
                align: 'center',
                width: '10%',
                render: (record) => record?.rating !== 0 ? <>{record?.rating} <Icon type="star" theme="filled" style={{ color: "#fadb14" }} /></> : "Not Rated"
            }
        ]

        const formFields = [

            {
                label: "Demo",
                type: shouldFetchDemos ? CONFIG.formFieldTypes.SELECT : CONFIG.formFieldTypes.INPUT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: (this.props.demos) ? this.props.demos.map(demo => ({ text: demo.title })) : [],
                colSpan: '24',
                value: this.filterSort.filter?.[dataIndexMap.demo]
            },
            {
                label: "Start / End Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "startEndTime",
                colSpan: '24',
                value: [this.filterSort?.filter?.[dataIndexMap.startTime], this.filterSort?.filter?.[dataIndexMap.endTime]],
            },
            {
                label: "Resource",
                type: shouldFetchDemos ? CONFIG.formFieldTypes.SELECT : CONFIG.formFieldTypes.INPUT,
                key: "resource",
                mode: "tags",
                valueKey: "text",
                data: (this.props.resources) ? this.props.resources.map(resource => ({ text: resource.name })) : [],
                value: this.filterSort.filter?.[dataIndexMap.resource],
                colSpan: '24',
            }
        ]

        return (
            <div className="approvals-pagination-row" style={{ maxHeight: this.state.height }}>
                <span id="my-schedule-demo-section-heading" className='dashboard-schedules-category-heading' style={{ fontSize: '19px' }}>My Scheduled Demos</span>
                <div className='items-on-either-side margin-top-1'>
                    <div className='width-30percent'>
                        <Button aria-label="Active" className={this.state.isActiveSchedules && "neo-button-selected"} variant="secondary" onClick={() => this.handleViewChange(true)} >Active</Button>
                        <Button aria-label="Previous" className={!this.state.isActiveSchedules ? "neo-button-selected left-spacing-3" : "left-spacing-3"} variant="secondary" onClick={() => this.handleViewChange(false)}>Previous</Button>
                    </div>
                    <div>
                        <ReactToPrint
                            content={() => this.componentRef}
                            documentTitle={this.setTablePrintHeading()[1]}
                            onAfterPrint={this.closePrint}
                            pageStyle={CONFIG.dashboard.schedulePrintStyle}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <NeoIcon className="clickable" title="print" icon="print" onClick={() => { this.handleTablePrint(handlePrint) }} style={{ fontSize: "23px", color: '#0b67bd' }} />
                                )}
                            </PrintContextConsumer>
                        </ReactToPrint>
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoIcon title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd', 'margin-left': '1em' }} />
                        </Dropdown>
                        <NeoIcon className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={this.state.showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd', 'margin-left': '1em' }} />
                    </div>

                </div>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.props.isFetching}>
                    <div>
                        {this.state.showFilter && <div>
                            <FilterDrawer
                                visible={this.state.showFilter}
                                width="520"
                                handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                                placement="right"
                                style={{ position: 'absolute', zIndex: 9999, }}
                                maskClosable={false}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                timeZone={this?.props?.timeZoneName}
                                hideSoftDeleteCheckbox={true}
                                hideApplyToAll={true}
                            />
                        </div>}
                        <NeoTable
                            cn={!this.state.showFilter && "top-spacing-2"}
                            columns={columns}
                            dataSource={schedules}
                            size='small'
                            onChange={this.handleTableSortChange}
                            noDataText='No Demos Scheduled'
                        />
                        <Row
                            className="approval-pagination"

                        >
                            {<Pagination
                                alwaysShowPagination={true}
                                itemsPerPageLabel="Items per page: "
                                currentPageIndex={this.currentPage || 1}
                                id="templated-pagination"
                                itemCount={scheduleCount || 0}
                                itemsPerPage={this.pageSize || 1}
                                itemsPerPageOptions={[5, 10, 15, 20]}
                                onItemsPerPageChange={(e, pageIndex) => this.onItemsPerPageChange(e, pageIndex)}
                                onPageChange={(e, pageIndex) => this.handlePagination(e, pageIndex)}
                            />}
                        </Row>
                        <Row>
                            <Col hidden={true}>
                                {this.state.printPreview && <AntTable
                                    ref={el => (this.componentRef = el)}
                                    title={() => this.setTablePrintHeading()[0]}
                                    className="responsive-container ant-table-wrapper"
                                    rowKey={(record) => record.id}
                                    columns={[...columns?.slice(0, -1), ...this.printColumn]}
                                    dataSource={this.printData}
                                    bordered
                                    pagination={false}
                                />}
                            </Col>
                        </Row>
                        {this.renderDeleteOptions()}
                        {this.renderScheduleDetails()}
                    </div>
                    {
                        registrationModalVisible &&
                        <DemoRegistrationModal
                            visible={registrationModalVisible}
                            handleDemoRequestSubmit={this.handleDemoRequestSubmit}
                            demo={demoRequestData?.demo}
                            isEdit={false}
                            rawData={demoRequestData}
                            handleCancel={this.handleDemoRequestModalClose}
                        />
                    }
                    {
                        this.state.deleteModalVisible && <ScheduleDeleteConfirmation
                            schedule={this.state.recordToDelete}
                            handleModalClose={this.handleDeleteModalClose}
                            isBatchDelete={this.state.isBatchDelete}
                            isActiveSchedules={this.state.isActiveSchedules}
                            isLoggedInUserCreator={this.state.isLoggedInUserCreator}
                            hasParticipants={this.state.hasParticipants}
                            handleDelete={this.handleDelete}
                            isModalVisible={this.state.deleteModalVisible}
                        />
                    }
                    {
                        this.state.oppUpdateModal && <SFDCOppDrawer
                            visible={this.state.oppUpdateModal}
                            timezone={this.props.timeZoneName}
                            schedule={this.state.scheduleDetails}
                            handleFetchSchedules={() => {
                                this.currentPage = 1;
                                this.handleFetchMySchedules()
                            }}
                            handleCloseSFDCDrawer={() => { this.setState({ oppUpdateModal: false, scheduleDetails: null }) }}
                        />
                    }
                </Spin>
            </div >
        );
    }
}

const mapStateToProps = ({ mySchedules, user, dataCenters, filterPanel, tabsLayout }) => {
    return {
        schedules: mySchedules?.data || [],
        demos: filterPanel.allDemos,
        resources: filterPanel.allResources,
        scheduleCount: mySchedules?.count,
        isFetching: mySchedules?.isFetching,
        response: mySchedules?.response,
        user: user.profile,
        timeZoneName: user.profile?.timezone,
        allDataCenters: dataCenters.data,
        roleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isRatingEnabled: user.isRatingEnabled,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        isEdit: mySchedules?.isEdit,
        currentFilter: mySchedules?.currentFilter,
        pageNumber: mySchedules?.pageNumber,
        pageSize: mySchedules?.pageSize,
        status: mySchedules?.status,
        isMultipleScheduleBooking: user.profile?.isMultipleScheduleBooking || false,
    };
}

export default connect(
    mapStateToProps,
    {
        fetchMySchedules,
        toggleIsFetchingMySchedules,
        openLinkInNewTab,
        toggleIsReschedule,
        toggleIsResourceReschedule,
        updateSchedulerStartEndTime,
        fetchActiveDemoResources,
        fetchSchedulesOverDuration,
        updateCurrentDemoSchedule,
        updateUserSchedulesParams,
        exportTable,
        toggleBackButton,
        fetchMySchedulesForExportPrint,
        fetchAllExistingDemos,
        fetchAllResources,
        optOutSchedule,
        deleteDemoSchedule,
        fetchDataCenters,
        retainMyScheduleFilter,
        clearMyDemoSchedules,
        deleteBatchDemoSchedule,
        optOutBatchDemoSchedule
    }
)(MySchedules);