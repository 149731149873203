import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Icon, Popover, Spin, Typography, Modal } from 'antd';
import moment from 'moment-timezone';
import { Button } from '@avaya/neo-react'
import { Icon as NeoIcon, Tooltip as NeoTooltip, Chip } from "@avaya/neo-react";
import ROUTE from "../../components/ContentRouteConfig";
import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd5'
import {
    toggleIsActiveSchedules,
    toggleIsFetchingPreviousSchedules,
    toggleIsReschedule,
    updateUserSchedulesParams,
    toggleIsFetchingMaintenanceSchedule,
    fetchSchedulesOverDuration,
    toggleIsResourceReschedule,
    openLinkInNewTab,
    handleOnClickAction,
    deleteDashboardSchedule,
    toggleIsFetchingDashboardSchedules,
    optOutSchedule,
    fetchDashboardActiveSchedules,
    updateSchedulerStartEndTime,
    updateCurrentDemoSchedule,
    fetchActiveDemoResources,
    fetchDashboardPreviousSchedules,
    clearPreviousSchedules,
    retainMyScheduleFilter
} from '../../actions';
import CONFIG from '../../config';
// import NoContent from '../common/NoContent';
import DemoStatusTag from '../common/DemoStatusTag';
import SFDCOppDrawer from '../common/SFDCOppDrawer';
import { getDemoCardWarnings } from '../../utils/dashboardCardMessage';
import { renderStatusTag } from '../../utils/dashboard';
import ScheduledDemoViewDetails from '../ManageScheduledDemo/ScheduledDemoViewDetails';
import DemoRegistrationModal from '../common/DemoRegistrationModal';
const { Text } = Typography;


class DashboardPreviousSchedulesCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewScheduleDetailsRecordId: undefined,
            viewScheduleDetails: false,
            registrationModalVisible: false,
            demoRequestData: null,
            oppUpdateModal: false,
            scheduleDetails: null
        }
    }

    handleDemoScheduleUpdate = async (scheduleId, schedule, isReschedule, isEdit, fromRescheduleEmail, isBatchEdit) => {
        const { demo } = schedule;
        const { isDemoAlreadyRequested, showRequestButton, showScheduleButton, demoType } = demo;
        const { timeZoneName } = this.props;

        const schedulerStartTime = this.state.isActiveSchedules
            ? moment(schedule?.startTime).tz(timeZoneName).startOf('day').toISOString()
            : moment().tz(timeZoneName).startOf('day').toISOString();
        const schedulerEndTime = this.state.isActiveSchedules
            ? moment(schedule?.startTime).tz(timeZoneName).endOf('day').toISOString()
            : moment().tz(timeZoneName).endOf('day').toISOString();

        // const startTime = moment(schedule?.startTime).tz(timeZoneName)?.startOf('day').toISOString();

        // const endTime = moment(schedule?.startTime).tz(timeZoneName)?.endOf('day').toISOString();
        this.props.updateSchedulerStartEndTime(schedulerStartTime, schedulerEndTime);

        //  !isReschedule is to check if the user is editing the schedule.
        if (showScheduleButton || isEdit) {
            await this.props.fetchActiveDemoResources(demo?.id)
                // .then(async () => await this.props.fetchSchedulesOverDuration(demo?.id, startTime, endTime))
                .then(() => this.props.updateCurrentDemoSchedule({ ...schedule, id: scheduleId }, fromRescheduleEmail))
                .then(() => this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/${demoType === CONFIG.demoTypes.standardDemo ? "standard" : "lab"}/${scheduleId}/edit/isReschedule=${isReschedule}/fromManageSide=${false}`))
        }
        else if (showRequestButton) {
            this.handleDemoRequestEdit(schedule)
        }
        else if (isDemoAlreadyRequested) return null
    }

    handleReschedule = (schedule, type) => {
        const demo = schedule?.demo;
        const { isDemoAlreadyRequested, showRequestButton, showScheduleButton } = demo;
        if (type !== CONFIG.demoTypes.standardDemo) {
            if (showScheduleButton)
                this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${true}/fromManageSide=${false}`)
            else if (showRequestButton) {
                this.handleDemoRequestEdit(schedule)
            }
            else if (isDemoAlreadyRequested) return null
            return
        }

        this.props.toggleIsReschedule(true)
        if (type === CONFIG.demoTypes.standardDemo) {
            this.handleDemoScheduleUpdate(schedule.id, schedule, true);
        }

    }

    handleDemoRequestEdit = (record) => {
        this.setState({
            demoRequestData: { demo: record?.demo, viewOnly: false },
            registrationModalVisible: true
        })
    }

    handleDemoRequestModalClose = () => {
        this.setState({ demoRequestData: null, registrationModalVisible: false })
    }

    handleDemoRequestSubmit = async () => {
        this.handleDemoRequestModalClose();
        this.handleClear(true)
        await this.props.toggleIsFetchingPreviousSchedules(true);
        await this.props.fetchDashboardPreviousSchedules();
        await this.props.toggleIsFetchingPreviousSchedules(false);
    }

    handleClear = async () => {
        await this.props.clearMyDemoSchedules()
    }

    renderScheduleDetails = () => {
        return (
            <Modal
                id="view-details-modal"
                className='extra-wide-modal'
                visible={this.state.viewScheduleDetails}
                onCancel={() => this.setState({ viewScheduleDetailsRecordId: undefined, viewScheduleDetails: false })}
                footer={[
                    <Button id="view-details-close" className="primary-action-button-filled view-details-close" key="back" onClick={() => this.setState({ viewScheduleDetailsRecordId: undefined, viewScheduleDetails: false })}>
                        Close
                    </Button>]}

            >
                <ScheduledDemoViewDetails
                    fromDashboard={true}
                    scheduleId={this.state.viewScheduleDetailsRecordId}
                />
            </Modal>
        )
    }



    handleSelectSchedule = (selectedDemo) => {
        this.props.openLinkInNewTab(selectedDemo.demo?.title, `/solutions/demo/${selectedDemo.demo?.id}`)
    }

    componentDidMount() {
        this.props.toggleIsActiveSchedules(true);
        this.props.toggleIsFetchingPreviousSchedules(false);
    }


    isAdminModified = (schedule) => {
        const {
            // createdBy, updatedBy, adminCreatedBy, 
            oldStartTime, oldEndTime, oldResource, resource, startTime, endTime, isAdminUpdatedSchedule, isAdminCreatedSchedule } = schedule;
        const { timeZoneName } = this.props;
        // const participants = schedule?.participants !== null && schedule?.participants?.eapUsers
        const modifiedContent = (
            <div className="maintenance-card-demo-list">
                {<React.Fragment>
                    {oldResource && <div>&#8226; Resource changed from <Text strong>{oldResource?.name}</Text> to <Text strong>{resource?.name}</Text></div>}
                    <div>&#8226; Schedule time changed from <Text strong>
                        {moment.tz(oldStartTime ? oldStartTime : startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)} - {moment.tz(oldEndTime ? oldEndTime : endTime, timeZoneName).format(CONFIG.dateFormats.userTime)}
                    </Text> to <Text strong>
                            {moment.tz(startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)} - {moment.tz(endTime, timeZoneName).format(CONFIG.dateFormats.userTime)}
                        </Text>
                    </div>
                </React.Fragment>}
            </div>
        );

        if (isAdminCreatedSchedule)
            return <span className='admin-schedule-tag'><Chip variant="info">Scheduled by Admin</Chip><br /></span>
        else if (isAdminUpdatedSchedule)
            return <span className='admin-schedule-tag'>
                <Popover
                    placement="right"
                    content={modifiedContent}
                    trigger="hover">
                    <Chip variant="info">Updated by Admin </Chip>
                </Popover>
                <br /></span>
    }

    handleUpdate = async (schedule, type, isBatchEdit) => {
        if (type !== CONFIG.demoTypes.standardDemo && !schedule?.isMaintenance) {
            this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${false}`)
            return
        }

        if (type === CONFIG.demoTypes.standardDemo && !schedule?.isMaintenance) {
            this.handleDemoScheduleUpdate(schedule.id, schedule, true, false, false, isBatchEdit);
        }
        else {
            await this.props.handleOnClickAction({
                component: CONFIG.editComponentRoute.scheduleMaintenance,
                tabTitle: schedule?.title,
                recordId: schedule?.id
            }
            )
        }
    }

    renderDemoCards = () => {
        const { timeZoneName, allDataCenters, dashboardPreviousSchedules, dashboardActiveScheduleCount } = this.props;
        let scheduleCards = [];

        // JSX for Schedule Demo Card
        const schedulesCard = dashboardPreviousSchedules

        if (timeZoneName && schedulesCard) {
            // Calculate the desired length based on totalCount
            let numberOfPreviousSchToBeDisplayed = CONFIG.previousSchedulesCardSize - (dashboardActiveScheduleCount > 0 ? dashboardActiveScheduleCount - 1 : dashboardActiveScheduleCount);

            // Make sure the desired length is at least 1
            if (numberOfPreviousSchToBeDisplayed < 1) {
                numberOfPreviousSchToBeDisplayed = 0;
            }
            for (let i = 0; i < Math.min(schedulesCard?.length, numberOfPreviousSchToBeDisplayed); i++) {
                let card;
                let tooltipMessages, showEditOrRescheduleButton, showCheckAvailbility, showReschedule, demoResourceDCStatus, hasParticipants, scheduleCreator, isDemoActive, DemoStatusTooltipMessage, resourceTooltipMessage, bufferStartTime, bufferEndTime;


                const demoType = schedulesCard[i]?.demo?.demoType;
                const isDemoSchedule = !schedulesCard[i]?.isMaintenance;
                const startDateTime = moment.tz(schedulesCard[i].startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime3);
                const endDateTime = moment.tz(schedulesCard[i].endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime);
                const adminModifiedStatus = this.isAdminModified(schedulesCard[i]);

                if (isDemoSchedule) {
                    isDemoActive = schedulesCard[i].demo?.isActive
                    const bufferStart = schedulesCard[i]?.startTimeBuffer || 0;
                    const bufferEnd = schedulesCard[i]?.endTimeBuffer || 0;
                    bufferStartTime = bufferStart > 0 ? moment.tz(schedulesCard[i].startTime, timeZoneName).subtract(bufferStart, 'm') : moment.tz(schedulesCard[i].startTime, timeZoneName);
                    bufferEndTime = bufferEnd > 0 ? moment.tz(schedulesCard[i].endTime, timeZoneName).add(bufferEnd, 'm') : moment.tz(schedulesCard[i].endTime, timeZoneName);
                    tooltipMessages = getDemoCardWarnings(schedulesCard[i])
                    DemoStatusTooltipMessage = tooltipMessages[0]
                    resourceTooltipMessage = tooltipMessages[1]
                    showEditOrRescheduleButton = isDemoSchedule ? tooltipMessages?.[2] : true
                    const { isDemoAlreadyRequested, showRequestButton, showScheduleButton } = schedulesCard[i]?.demo || [];
                    if (demoType === CONFIG.demoTypes.standardDemo) {
                        const activeDataCenters = allDataCenters?.filter(datacenter => datacenter.active === true) || [];
                        const demoDCStatuses = activeDataCenters?.map(dataCenter => schedulesCard[i].demo?.demoDCStatuses?.find(demoDCStatus => demoDCStatus.dataCenter.id === dataCenter.id) || { dataCenter, status: 2 });
                        const scheduledDC = demoDCStatuses?.find((dataCenters) => {
                            return dataCenters?.dataCenter?.id === schedulesCard[i]?.resource?.dataCenter?.id
                        })
                        let count = 0, dataCenter;
                        demoDCStatuses.forEach((item) => {
                            if (item.status === CONFIG.demoStatus.dataCenterTypes.green || item.status === CONFIG.demoStatus.dataCenterTypes.yellow) {
                                count += 1;
                                dataCenter = item;
                            }
                        })
                        showCheckAvailbility = (count > 1 || (count === 1 && (dataCenter?.dataCenter?.id !== scheduledDC?.dataCenter?.id)))
                        showReschedule = (count === 1 && (dataCenter?.dataCenter?.id === scheduledDC?.dataCenter?.id))
                        demoResourceDCStatus = demoDCStatuses.find(demoDCStatus => demoDCStatus?.dataCenter?.id === schedulesCard[i].resource?.dataCenter?.id)
                    }
                    const items = [
                        {
                            key: 'details',
                            label: (
                                <span id='previous-schedule-view-details' className='full-width-options'
                                    onClick={() => this.setState({
                                        viewScheduleDetails: true,
                                        viewScheduleDetailsRecordId: schedulesCard?.[i]?.id
                                    })}
                                ><NeoIcon icon="alignment-left" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>View Details</span>
                                </span>
                            ),
                        },
                        {
                            key: 'reschedule',
                            label: (
                                <span id='previous-schedule-reschedule' className='full-width-options'
                                    disabled={!showEditOrRescheduleButton || isDemoAlreadyRequested}
                                    onClick={(showEditOrRescheduleButton && !isDemoAlreadyRequested) && (() => this.handleReschedule(schedulesCard[i], demoType))}
                                >
                                    {showScheduleButton ? <span> <NeoIcon icon="refresh" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Reschedule</span></span> : showRequestButton
                                        ? <span ><NeoIcon icon="posts" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Request</span></span>
                                        : <span><NeoIcon icon="posts" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} /><span style={{ fontSize: '13px' }}>Requested</span></span>}
                                </span>

                            ),
                            disabled: !showEditOrRescheduleButton || isDemoAlreadyRequested
                        },
                    ];

                    if (schedulesCard[i]?.isCustomerDemoPurpose) {
                        items.splice(1, 0, {
                            key: 'oppNo',
                            label: (
                                <span id='previous-schedule-opp-number' className='full-width-options'
                                    onClick={() => this.setState({
                                        oppUpdateModal: true,
                                        scheduleDetails: schedulesCard?.[i]
                                    })}
                                >
                                    <NeoIcon icon="awfos" className='pointer' style={{ fontSize: "19px", paddingRight: "8px" }} />
                                    <span style={{ fontSize: '13px' }}>Update SFDC Details</span>
                                </span>
                            ),
                        });
                    }

                    card = (
                        <>
                            <Row id={`dashboard-previous-schedule-card-${i + 1}`} className='dashboard-most-used-demo-card' style={{ marginLeft: "2%" }}>
                                <Col className='dashboard-schedule-card' style={{ borderColor: "#868383" }}>
                                    <Row>
                                        {hasParticipants && <span> <Popover
                                            placement="right"
                                            content={<React.Fragment>
                                                <p><Text underline>Creator</Text><br />{`${scheduleCreator?.fullName} (${scheduleCreator?.email})`}</p>
                                            </React.Fragment>}
                                            trigger="hover"
                                        >
                                            <NeoIcon icon="user-conference" size='md' style={{ paddingRight: "5px" }} />
                                        </Popover></span>}
                                        {adminModifiedStatus}
                                        <span>{
                                            <span
                                                id={`demo-title-${i + 1}`}
                                                className={isDemoActive ? "hover-link medium-text bold dashboard-schedules-demo-title" : undefined}
                                                onClick={() => isDemoActive && this.handleSelectSchedule(schedulesCard[i])}>
                                                {schedulesCard[i].demo?.title}
                                            </span>
                                            // Maintenance Card
                                        }
                                            {isDemoSchedule && DemoStatusTooltipMessage?.length > 0 && < NeoTooltip
                                                label={(this.props.roleTemplate?.templateType === CONFIG.roleTypes.superAdmin && DemoStatusTooltipMessage[0]?.includes("access")) ? CONFIG.warningMessages.solutionInactive : DemoStatusTooltipMessage.length > 1 ? <>{DemoStatusTooltipMessage?.map((message, index) => (<div key={index}>&#8226;&nbsp;{message}</div>))}</> : DemoStatusTooltipMessage[0]}>
                                                &nbsp;< Icon type="warning" theme="twoTone" twoToneColor="#ff9000" />
                                            </NeoTooltip>}
                                            <>
                                                <span className="dashboard-schedule-more-options float-right">
                                                    <Dropdown
                                                        overlayClassName='my-schedules-table-actions-menu'
                                                        trigger="click"
                                                        menu={{ items }}
                                                        placement="bottomLeft"
                                                        arrow
                                                    >
                                                        <NeoIcon icon="ellipses-vertical" id={`previous-schedule-actions-${i + 1}`} className='pointer' style={{ fontSize: "19px", color: "#1B77AF" }} />
                                                    </Dropdown>

                                                </span>
                                                <span id='demo-satus-tag' className='dashboard-card-status-tag float-right'>
                                                    {isDemoSchedule ? demoType === CONFIG.demoTypes.standardDemo
                                                        ?
                                                        < DemoStatusTag
                                                            demoResourceDCStatus={demoResourceDCStatus}
                                                            timezone={this.props.timeZoneName}
                                                            scheduleId={schedulesCard[i].id}
                                                            scheduleObject={schedulesCard[i]}
                                                            handleReschedule={this.handleDemoScheduleUpdate}
                                                            handleUpdate={this.handleUpdate}
                                                            disabledReschedule={showEditOrRescheduleButton}
                                                            toggleIsReschedule={this.props.toggleIsReschedule}
                                                            toggleIsResourceReschedule={this.props.toggleIsResourceReschedule}
                                                            errorMessage={DemoStatusTooltipMessage}
                                                            roleTemplate={this.props.roleTemplate}
                                                            showCheckAvailbility={showCheckAvailbility}
                                                            showReschedule={showReschedule}
                                                            itemId={i + 1}
                                                            section={"previous"}
                                                        />
                                                        : (demoType === CONFIG.demoTypes.labPocDemo || demoType === CONFIG.demoTypes.restfulApiDemo) &&
                                                        renderStatusTag(schedulesCard[i], false, i + 1)
                                                        : undefined
                                                    }</span >
                                            </>
                                        </span>
                                    </Row>
                                    <div className='dashboard-schedule-card-details'>
                                        <span>{<div className="demo-card-timings">
                                            <Row className="top-spacing-6">
                                                <Col span={24} className='bold bottom-spacing-2 font-14px'>
                                                    <span id='start-end-date-time'>
                                                        {(bufferStart > 0 || bufferEnd > 0) ? <NeoTooltip
                                                            label={<>
                                                                <div>Buffer Start Time  - {bufferStartTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                                                {demoType === CONFIG.demoTypes.standardDemo && <div>Buffer End Time  - {bufferEndTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                                                }</>}>{startDateTime} - {endDateTime}
                                                        </NeoTooltip> :
                                                            <span id='start-end-date-time'>{startDateTime} - {endDateTime} </span>}
                                                    </span>
                                                </Col>
                                            </Row>
                                            {
                                                isDemoSchedule && demoType === CONFIG.demoTypes.standardDemo && <span className="demoApprovedStatus">
                                                    <span className="bold">Resource: </span>
                                                    <span id={`resource-name-${i + 1}`}>{schedulesCard[i].resource?.name}
                                                        {resourceTooltipMessage?.length > 0 && < NeoTooltip
                                                            label={resourceTooltipMessage.length > 1 ? <>{resourceTooltipMessage?.map((message, index) => (<div key={index}>&#8226;&nbsp;{message}</div>))}</> : resourceTooltipMessage[0]}>
                                                            &nbsp;< Icon type="warning" theme="twoTone" twoToneColor="#ff9000" />
                                                        </NeoTooltip>}
                                                    </span><br />
                                                </span>
                                            }
                                        </div >}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    );
                }
                scheduleCards.push(card);
            }
        }

        return scheduleCards;
    }

    render() {
        const { registrationModalVisible, demoRequestData } = this.state;
        const { isFetchingPreviousSchedules, dashboardPreviousSchedules } = this.props;
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        return (
            <div>
                <Spin indicator={antIcon} spinning={isFetchingPreviousSchedules} >
                    {dashboardPreviousSchedules?.length > 0 && (this.props.dashboardActiveScheduleCount <= CONFIG.previousSchedulesCardSize) ?
                        <>
                            <Row className='dashboard-category-heading-with-extra left-spacing'>
                                <span id='dashboard-previous-schedules' className='dashboard-schedules-category-heading left-spacing'><NeoIcon icon="history" style={{ fontSize: "20px", paddingRight: "10px" }} />
                                    Previous Schedules</span>
                                <span id='view-all-previous-schedules' onClick={() => {
                                    this.props.openLinkInNewTab('Schedules', ROUTE.mySchedules);
                                    this.props.retainMyScheduleFilter({ filter: { isActive: false } }, false)
                                }
                                } className='dashboard-category-extra'>View all Previous Schedules</span>
                            </Row>
                            {this.renderDemoCards()}
                        </>
                        :
                        undefined}
                </Spin>
                {this.renderScheduleDetails()}
                {
                    registrationModalVisible &&
                    <DemoRegistrationModal
                        visible={registrationModalVisible}
                        handleDemoRequestSubmit={this.handleDemoRequestSubmit}
                        demo={demoRequestData?.demo}
                        isEdit={false}
                        rawData={demoRequestData}
                        handleCancel={this.handleDemoRequestModalClose}
                    />
                }
                {
                    this.state.oppUpdateModal && <SFDCOppDrawer
                        visible={this.state.oppUpdateModal}
                        timezone={this.props.timeZoneName}
                        schedule={this.state.scheduleDetails}
                        handleFetchSchedules={async () => {
                            await this.props.toggleIsFetchingPreviousSchedules(true);
                            await this.props.fetchDashboardPreviousSchedules();
                            await this.props.toggleIsFetchingPreviousSchedules(false);
                        }}
                        handleCloseSFDCDrawer={() => { this.setState({ oppUpdateModal: false, scheduleDetails: null }) }}
                    />
                }
            </div>
        );
    }
}


const mapStateToProps = ({ dashboard, user, dataCenters, mostScheduledDemos, trendingDemos, adminDashboard }) => {
    return {
        dashboardPreviousSchedules: dashboard.dashboardPreviousSchedules,
        dashboardActiveScheduleCount: dashboard.dashboardActiveScheduleCount,
        todaySchedules: dashboard.dashboardTodaySchedules,
        upcomingMaintenances: adminDashboard.upcomingMaintenances,
        mostScheduledDemos: mostScheduledDemos.mostScheduledDemos,
        fetchingDashboardSchedules: dashboard.fetchingDashboardSchedules,
        previousSchedules: dashboard.previousSchedulesCard,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        isRatingEnabled: user.isRatingEnabled,
        isViewCommentEnabled: user.isViewCommentEnabled,
        roleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isLoading: dashboard.isLoading,
        isActiveSchedules: dashboard.isActiveSchedules,
        allDataCenters: dataCenters.data,
        isMostUsedDemosLoading: trendingDemos?.isLoading,
        isFetchingPreviousSchedules: dashboard.isFetchingPreviousSchedules,
        user: user.profile,
        isAdmin: user.isAdmin,
    };
}

export default connect(mapStateToProps,
    {
        toggleIsActiveSchedules,
        toggleIsFetchingPreviousSchedules,
        toggleIsReschedule,
        toggleIsResourceReschedule,
        updateUserSchedulesParams,
        toggleIsFetchingMaintenanceSchedule,
        fetchSchedulesOverDuration,
        openLinkInNewTab,
        handleOnClickAction,
        deleteDashboardSchedule,
        toggleIsFetchingDashboardSchedules,
        optOutSchedule,
        fetchDashboardActiveSchedules,
        updateSchedulerStartEndTime,
        updateCurrentDemoSchedule,
        fetchActiveDemoResources,
        fetchDashboardPreviousSchedules,
        clearPreviousSchedules,
        retainMyScheduleFilter
    }
)(DashboardPreviousSchedulesCard);