import { batch } from 'react-redux';

import {
    UPDATE_DASHBOARD_SCHEDULES_VIEW,
    TOGGLE_IS_FETCHING_USER_SCHEDULES,
    TOGGLE_IS_ACTIVE_SCHEDULES,
    TOGGLE_IS_DASHBOARD_LOADING,
    CLEAR_PREVIOUS_SCHEDULES,
    TOGGLE_IS_RESCHEDULE,
    TOGGLE_IS_RESOURCE_RESCHEDULE,
    TOGGLE_IS_FETCHING_PREVIOUS_SCHEDULES,
    UPDATE_SCHEDULES_CURRENT_PAGE,
    FETCH_SCHEDULES_PRINT_EXPORT,
    CLEAR_DASHBOARD_SCHEDULES,
    FETCH_ACTIVE_USER_SCHEDULES_CARD,
    FETCH_PREVIOUS_USER_SCHEDULES_CARD,
    FETCH_USER_SCHEDULES_CALENDAR,
    FETCH_USER_DEMO_SCHEDULES_TABLE,
    TOGGLE_IS_TABLE_CLOSE,
    FETCH_USER_MAINTENANCE_SCHEDULES_TABLE,
    UPDATE_USERS_SCHEDULES_PARAMS,
    TOGGLE_IS_FETCHING_DASHBOARD_SCHEDULES,
    GET_ALL_ACTIVE_REQUESTS,
    DASHBOARD_ACTIVE_SCHEDULES,
    DASHBOARD_PREVIOUS_SCHEDULES,
    GET_ALL_INACTIVE_REQUESTS,
    DASHBOARD_POPULAR_EZ_DEMOS,
    CLEAR_DASHBOARD_EZ_DEMOS
} from './types';
import { aepSchedule, aepDemo } from '../apis';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { removeUserFromGroup } from './scheduler';
import { refreshUserDashboardView } from './dashboardSections';
import {toggleIsFetchingUserEZDemos} from './ezDemos'
import {fetchMostScheduledDemos, toggleIsFetchingTrendingDemos} from './trendingDemos'

export const updateDashboardSchedulesView = (view) => {
    return {
        type: UPDATE_DASHBOARD_SCHEDULES_VIEW,
        payload: view
    };
};

/*
+--------------+----------------------------------------------------+-----------------------------------+------------+
|   viewType   |                       Params                       |           Functionality           | Pagination |
+--------------+----------------------------------------------------+-----------------------------------+------------+
| cardView     | isActive=true                                      | Active Schedules and Maintenance  | No         |
|              | isActive=false                                     | All Previous Schedules            | Yes        |
+--------------+----------------------------------------------------+-----------------------------------+------------+
| calendarView | startDate,endDate                                  | Includes both Schedules and Main. | No         |
+--------------+----------------------------------------------------+-----------------------------------+------------+
| tableView    | isScheduleDemo=true , startDate, endDate,isActive  | Fetch schedules.                  | Yes        |
| tableView    | isScheduleDemo=false , startDate, endDate,isActive | Fetch Maintenance.                | Yes        |
+--------------+----------------------------------------------------+-----------------------------------+------------+
*/

export const fetchUserSchedules = ({ isActive, viewType, isScheduleDemo, pageNumber = 1, pageSize, startDate, endDate, isExport, filterSort }) => async dispatch => {


    let params = {}
    let dispatchType
    const fromDashboard = viewType === CONFIG.dashboard.schedulesViewType.CARD ? true : false
    if (viewType === CONFIG.dashboard.schedulesViewType.CARD) {
        if (isActive) {
            params = { viewType, isActive, isScheduleDemo }
            dispatchType = FETCH_ACTIVE_USER_SCHEDULES_CARD
        }
        else {
            params = { viewType, isActive, pageNumber, pageSize };
            dispatchType = FETCH_PREVIOUS_USER_SCHEDULES_CARD
        }
    }

    else if (viewType === CONFIG.dashboard.schedulesViewType.CALENDAR) {
        params = { viewType, startDate, endDate }
        dispatchType = FETCH_USER_SCHEDULES_CALENDAR
    }

    else if (viewType === CONFIG.dashboard.schedulesViewType.TABLE) {
        if (isExport) {
            params = { viewType, isActive, isScheduleDemo, startDate, endDate, filterSort }
            dispatchType = FETCH_SCHEDULES_PRINT_EXPORT
        }
        else {
            params = { viewType, isActive, isScheduleDemo, startDate, endDate, filterSort }
            isScheduleDemo ? dispatchType = FETCH_USER_DEMO_SCHEDULES_TABLE : dispatchType = FETCH_USER_MAINTENANCE_SCHEDULES_TABLE
        }
    }

    let response = await aepSchedule.get('/scheduleMaintenanceService/user/schedules', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {

        batch(() => {
            dispatch(fetchDemoAccessInfo(response, dispatchType, fromDashboard, isActive))
        });
    }
    else {
        viewType === CONFIG.dashboard.schedulesViewType.CARD && pushMessage(CONFIG.messageType.error, "Unable to fetch schedules for Card View");
        viewType === CONFIG.dashboard.schedulesViewType.CALENDAR && pushMessage(CONFIG.messageType.error, "Unable to fetch Calendar schedules  ");
        viewType === CONFIG.dashboard.schedulesViewType.TABLE && pushMessage(CONFIG.messageType.error, "Unable to fetch schedules for Table View");
    }
}

export const fetchDashboardActiveSchedules = () => async (dispatch) => {
    const response = await aepSchedule.get('/scheduleMaintenanceService/dashboard/schedules');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: DASHBOARD_ACTIVE_SCHEDULES,
                payload: response.data
            });
        });
    }
}

export const fetchDashboardPreviousSchedules = () => async (dispatch) => {

    const params = {
        pageNumber: 1,
        pageSize: 5,
        filterSort: { filter: { isActive: false } }
    };
    const response = await aepSchedule.get('/scheduleMaintenanceService/myDemos/schedules', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: DASHBOARD_PREVIOUS_SCHEDULES,
                payload: response.data?.dataSet || []
            });
        });
    }
}


export const fetchPopularEzDemos = () => async (dispatch) => {
    const response = await aepDemo.get('/demoSolutionService/dashboard/popularEzDemos');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: DASHBOARD_POPULAR_EZ_DEMOS,
                payload: response.data || []
            });
        });
    }
}

export const clearDashboardEZDemos = () => {
    return {
        type: CLEAR_DASHBOARD_EZ_DEMOS
    }
}

export const fetchUserDemoRequests = ({ isActive, pageNumber = 1, pageSize }) => async (dispatch) => {
    const params = { isActive, pageNumber, pageSize }
    const contextPath = "demoSolutionService/user/requests"
    const response = await aepDemo(contextPath, { params })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        const demoIds = response?.data?.dataSet?.length > 0 ? response?.data?.dataSet?.map(item => item?.demo?.id) : [];
        const uniqDemoIds = [...new Set(demoIds)];
        const demoIdsResponse = uniqDemoIds?.length > 0 && await aepDemo.get(`/demoSolutionService/access/demos?demoIds=${uniqDemoIds}`);
        for (let i = 0; i < response?.data?.dataSet?.length; i++)
            response.data.dataSet[i].userHasAccessToDemo = demoIdsResponse.data?.includes(response?.data?.dataSet[i]?.demo?.id) ? true : false;
        dispatch({
            type: isActive ? GET_ALL_ACTIVE_REQUESTS : GET_ALL_INACTIVE_REQUESTS,
            payload: response.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, `Unable to ${isActive ? "active" : "previous"} fetch demo requests`)
}

export const fetchDemoAccessInfo = (responseData, dispatchType, fromDashboard, isActive) => async dispatch => {

    // Sending array of demo IDs of Scheduled Demos, ( Not Maintenence) to check if the user
    // has access to the Demos.

    var demoIds = []
    for (var i = 0; i < responseData.data.dataSet.length; i++) {
        // Checking if the schedule type is maintenance or Demo schedule. Pushing only if its a Demo schedule.
        // Since schedule demo object has only one Demo --. Checking demo.id

        if (!responseData.data.dataSet[i]?.isMaintenance) {
            demoIds.push(responseData.data.dataSet[i]?.demo?.id)
        }
    }
    const uniqDemoIds = [...new Set(demoIds)];
    const demoIdsResponse = uniqDemoIds?.length > 0 && await aepDemo.get(`/demoSolutionService/access/demos?demoIds=${uniqDemoIds}`)
    for (i = 0; i < responseData.data.dataSet.length; i++)
        responseData.data.dataSet[i].userHasAccessToDemo = demoIdsResponse.data?.includes(responseData.data.dataSet[i]?.demo?.id) ? true : false
    dispatch({
        type: dispatchType,
        payload: responseData.data || []
    });
    fromDashboard && !isActive && dispatch(toggleIsFetchingDashboardSchedules(false));
    dispatch(toggleIsFetchingUserSchedules(false, "isLoading"));
    dispatch(toggleIsFetchingUserSchedules(false));

}

export const checkResourceAvailability = async (ScheduleId) => {
    let response = await aepSchedule.get('/scheduleMaintenanceService/schedule/checkavailability/' + ScheduleId);
    return response.data || []
}

export const updateSchedulesCurrentPage = ({ pageNumber = 1 }) => (dispatch) => {
    batch(() => {
        dispatch({
            type: UPDATE_SCHEDULES_CURRENT_PAGE,
            payload: pageNumber
        });
    });
}

export const clearPreviousSchedules = () => {
    return {
        type: CLEAR_PREVIOUS_SCHEDULES
    }
}

export const clearDashboardSchedules = () => {
    return {
        type: CLEAR_DASHBOARD_SCHEDULES
    }
}

export const toggleIsFetchingUserSchedules = (flag, type = "isFetchingSchedules") => {
    return {
        type: TOGGLE_IS_FETCHING_USER_SCHEDULES,
        payload: { flag, type }
    }
}


export const toggleIsActiveSchedules = (flag) => {
    return {
        type: TOGGLE_IS_ACTIVE_SCHEDULES,
        payload: flag
    }
}

export const isDashboardLoading = (flag) => {
    return {
        type: TOGGLE_IS_DASHBOARD_LOADING,
        payload: flag
    }
}

export const toggleIsFetchingDashboardSchedules = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DASHBOARD_SCHEDULES,
        payload: flag
    }
}

export const updateUserSchedulesParams = ({ isActive, viewType, isScheduleDemo, pageNumber, pageSize, startDate, endDate }) => {
    const fetchUsersSchedulesParams = {
        isActive, viewType, isScheduleDemo, pageNumber, pageSize, startDate, endDate
    }
    return {
        type: UPDATE_USERS_SCHEDULES_PARAMS,
        payload: fetchUsersSchedulesParams
    }
}
export const toggleIsReschedule = (flag) => {
    return {
        type: TOGGLE_IS_RESCHEDULE,
        payload: flag
    }
}

export const toggleIsResourceReschedule = (flag) => {
    return {
        type: TOGGLE_IS_RESOURCE_RESCHEDULE,
        payload: flag
    }
}

export const toggleIsFetchingPreviousSchedules = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_PREVIOUS_SCHEDULES,
        payload: flag
    }
}

export const clearTableView = () => {
    return {
        type: TOGGLE_IS_TABLE_CLOSE
    }
}

export const deleteUserRequest = (requestId) => async (dispatch) => {
    const contextPath = `/demoSolutionService/demo/request/${requestId}?hardDelete=false`;
    const response = await aepDemo.delete(contextPath);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        pushMessage(CONFIG.messageType.success, "Request deleted successfully");
        return true;
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to delete request");
        return false;
    }
}

export const deleteDashboardSchedule = (scheduleId, demoType) => async (dispatch, getState) => {
    demoType === CONFIG.demoTypes.standardDemo && removeUserFromGroup(scheduleId)
    const response = await aepSchedule.delete(`/scheduleMaintenanceService/schedules/${scheduleId}`)
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Schedule deleted successfully`);
        batch(async () => {
            await dispatch(clearDashboardSchedules())
            await dispatch(fetchDashboardActiveSchedules())
            
            await dispatch(toggleIsFetchingTrendingDemos(true))
            await dispatch(fetchMostScheduledDemos())

            await dispatch(toggleIsFetchingUserEZDemos(true))
            await dispatch(fetchPopularEzDemos())
            await dispatch(toggleIsFetchingUserEZDemos(false))

            dispatch(refreshUserDashboardView())
        })
    }
    else pushMessage(CONFIG.messageType.error, `Unable to delete schedule`)
    dispatch(toggleIsFetchingDashboardSchedules(false))
    return response?.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const fetchDashboardApprovalsCount = () => async () => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/dashboard/myApprovals/count");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        return response?.data || 0
    }
    else pushMessage(CONFIG.messageType.error, `Unable to fetch approvals`)
}

