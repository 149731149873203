import React from 'react';
import { Menu } from "antd"
import CONFIG from "../config";

export const toTitleCase = (string) => {
    if (string?.includes(" ")) {
        // Sentence case: Convert each word to title case
        const words = string?.toLowerCase().split(" ");
        const titleCaseWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return titleCaseWords.join(" ");
    } else {
        // Single word case: Convert the word to title case
        return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
    }
}

export const camelToTitleCase = (string) => {
    const result1 = string.replace(/([A-Z])/g, " $1");
    const result2 = result1.charAt(0).toUpperCase() + result1.slice(1);
    return result2
}

export const upperCaseForSupport = (string) => {
    return string?.replace("-", "_")?.toUpperCase()
}

export const lowerCaseForSupport = (string) => {
    return string?.replace("_", "-")?.toLowerCase()
}

export const exportMenu = (exportFunction) => {
    return (
        <Menu className='export-menu' style={{ color: "#242424" }} onClick={(e) => exportFunction(e.key)}>
            <Menu.Item key={CONFIG.exportFileType.xlsx.ext} style={{ color: "#242424" }}>
                {CONFIG.exportFileType.xlsx.name}
            </Menu.Item>
            <Menu.Item key={CONFIG.exportFileType.csv.ext} style={{ color: "#242424" }}>
                {CONFIG.exportFileType.csv.name}
            </Menu.Item>
        </Menu>
    )
}

export const validateURL = (rule, value, callback) => {
    let url;
    try {
        url = new URL(value);
    } catch (_) {
        callback('Please enter a Valid URL');
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const getRandomDarkColor = () => {
    const red = Math.floor(Math.random() * 128) + 128;
    const green = Math.floor(Math.random() * 128) + 128;
    const blue = Math.floor(Math.random() * 128) + 128;
    const color = `rgba(${red}, ${green}, ${blue}, 0.5)`;
    return color;
}

export const roundToNearestHalf = (number) => {
    const roundedNumber = Number(number.toFixed(1)); // Round to 1 decimal place
    const decimalPart = roundedNumber - Math.floor(roundedNumber); // Get the decimal part

    let roundedDecimal;
    if (decimalPart >= 0.5 && decimalPart < 0.9) {
        roundedDecimal = 0.5;
    } else {
        roundedDecimal = 0;
    }

    const finalNumber = Math.floor(roundedNumber) + roundedDecimal;
    return finalNumber;
}

export const convertStringToEllipsis = (string, maxCharacter) => {
    return (string?.length > maxCharacter ? string.substring(0, maxCharacter) + "..." : string)
}

export const downloadTextFile = (content, filename) => {
    // Create a Blob containing the text content
    const blob = new Blob([content], { type: 'text/plain' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element
    const a = document.createElement('a');

    // Set the anchor's attributes
    a.href = url;
    a.download = filename;

    // Programmatically click the anchor element to trigger the download
    a.click();

    // Cleanup: revoke the URL object after the download is triggered
    URL.revokeObjectURL(url);
};

export const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i];
        const matchFound = arr2.some(obj2 => obj2.id === obj1.id);
        if (!matchFound) {
            return false;
        }
    }
    return true;
}

export const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}

export const formatTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);

    if (hours === 0 && minutes === 0) {
        return '0 hrs';
    } else if (hours === 0) {
        return `${minutes} min${minutes > 1 ? 's' : ''}`;
    } else if (minutes === 0) {
        return `${hours} hr${hours > 1 ? 's' : ''}`;
    } else {
        return `${hours} hr${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
    }
}