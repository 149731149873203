import React, { Component } from 'react'
import { selectTitle, toggleBackButton, getAllUserEZDemos, clearUserEZDemos, getAllEZDemosForMenu, toggleIsFetchingUserEZDemos, currentUserEZDemo, audit, postEZDemoFeedback } from '../actions';
import { Spin, Row, Col, Tooltip, Form, Rate, Divider, Drawer, Select } from 'antd';
import { batch, connect } from 'react-redux';
import CONFIG from "../config"
import moment from 'moment-timezone';
import { analytics } from '../firebase';
import EZDemoReviews from '../components/ManageEZDemos/EZDemoReviews'
import EZDemoFeedbackModal from './common/EZDemoFeedbackModal';
import { Menu } from "antd5"
import { roundToNearestHalf } from "../utils/strings"
import { LoadingOutlined } from '@ant-design/icons';
import { Icon as NeoIcon, Button } from "@avaya/neo-react";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;
class EZDemosNeo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFeedbackModal: false,
            solutionTypeEzDemos: [],
            reviewsVisible: false,
            currentViewedEZDemo: null,
            ezDemoId: null,
            currentItem: null,
            readMore: {},
            allSectionEZDemos: [],
            solutionSectionEZDemos: [],
            height: `${document.body.clientHeight - 150}px`,
        };
    }

    componentDidMount() {
        batch(async () => {
            this.props.selectTitle("EZ Demos");
            this.props.toggleIsFetchingUserEZDemos(true);
            this.fetchOnMount()
        });
        window.addEventListener("resize", this.handleResize);
    }

    handleResize = (e) => {
        this.setState({ height: document.body.clientHeight > 250 ? `${document.body.clientHeight - 150}px` : `250px` });
    }

    fetchOnMount = async () => {
        const { salesVideoId } = this.props.match.params;
        !salesVideoId && await this.props.getAllUserEZDemos();
        await this.props.getAllEZDemosForMenu(salesVideoId);
        if (salesVideoId) {
            this.props.toggleIsFetchingUserEZDemos(true);
            setTimeout(() => {
                let allEZDemos = this.props.allEZDemos;
                if (allEZDemos) {
                    const searchedEZDemos = allEZDemos?.ezDemos?.filter(ezDemo => ezDemo?.id.toString() === salesVideoId.toString());
                    this.setState({
                        allSectionEZDemos: this.props.allEZDemos?.ezDemos,
                        solutionSectionEZDemos: searchedEZDemos
                    })
                }
                this.props.toggleIsFetchingUserEZDemos(false);
            }, 100)
        }
        else {
            setTimeout(() => {
                this.setState({ allSectionEZDemos: this.props.allEZDemos?.ezDemos, solutionSectionEZDemos: this.props.ezDemos[0]?.ezDemos })
            }, 100)
        }
    }

    componentWillUnmount() {
        this.props.currentUserEZDemo(null);
        window.removeEventListener("resize", this.handleResize);
    }

    handleLinkClick = async (value, isDemo, isAll) => {
        const { currentEZDemo } = this.props;
        let item = value;
        item = { ...item, fileName: item?.isFile ? item.link?.match(CONFIG.regEx.getFileName)?.[0] : item?.link };

        const auditId = await this.props.audit(item?.id, value?.demo?.id, !isAll ? currentEZDemo?.id : value?.solutionType?.id)

        setTimeout(() => {
            this.setState({
                showFeedbackModal: true,
                feedbackAuditId: auditId,
                currentViewedEZDemo: item,
            })
        }, 3000)

        /* Firebase Analytics Log Event */
        const { demoGuide: {
            name: eventName,
            parameters: eventParams
        } } = CONFIG.firebaseEvents;
        let parameters = {};
        Object.keys(eventParams).forEach(paramKey => {
            parameters[paramKey] = item[eventParams[paramKey]]
        });
        analytics().logEvent(eventName, parameters);

        window.open(item.link, '_blank');
    }

    handleTabClick = (key) => {
        const { ezDemos, form } = this.props;
        const currentEZDemo = ezDemos?.find(ezDemo => ezDemo.id === parseInt(key));
        document.getElementById('tab-pane').scrollIntoView();
        this.props.currentUserEZDemo(currentEZDemo);
        form.resetFields(["demos"]);
        this.setState({ solutionSectionEZDemos: currentEZDemo?.ezDemos, allSectionEZDemos: this.props.allEZDemos?.ezDemos })
    }

    renderEZDemoOptions = () => {
        const { currentEZDemo } = this.props;
        const { ezDemos } = currentEZDemo || [];
        if (ezDemos?.length > 0) {
            const uniqueEZDemos = ezDemos.filter((obj, index) => {
                return index === ezDemos.findIndex(o => (obj?.id === o?.id));
            });
            return uniqueEZDemos?.map((item) => {
                return <Option key={JSON.stringify({ item })} value={JSON.stringify({ item })}>
                    <div>
                        {item?.title}
                    </div>
                </Option>
            });
        }
        else return [];
    }

    handleAllDemoChange = async (value, isAll) => {
        const { currentEZDemo } = this.props;
        const selectedValues = value?.map(item => JSON.parse(item)?.item);
        const getMappedDemos = () => {
            const filteredArray = isAll ? currentEZDemo?.ezDemos?.filter((item) => selectedValues.some(subItem => subItem.id === item.id))
                : currentEZDemo?.ezDemos?.filter((item) => selectedValues.some(subItem => subItem.id === item.id));

            return filteredArray;
        }
        const selectedDemo = value?.length > 0 ? getMappedDemos() : currentEZDemo?.ezDemos;
        this.setState((prevState) => ({ allSectionEZDemos: isAll ? selectedDemo : prevState.allSectionEZDemos, solutionSectionEZDemos: isAll ? prevState.solutionSectionEZDemos : selectedDemo }))
    }

    renderContent = () => {
        const { ezDemos, currentEZDemo, form, allEZDemos } = this.props;
        const { getFieldDecorator } = form;
        const isAll = currentEZDemo?.id === "all"
        const { salesVideoId } = this.props.match.params;
        const data = !salesVideoId ? [...(ezDemos?.length ? ezDemos : [])] : [];
        return (
            <div>
                <Row>
                    <Col span={18}>

                    </Col>
                    <Col span={6}>
                        {!salesVideoId &&
                            <div style={{ marginBottom: "16px", marginTop: "16px" }}>
                                <Form.Item>
                                    {getFieldDecorator("demos")(
                                        <Select
                                            mode="multiple"
                                            placeholder="Search a EZ Demo"
                                            onChange={(value) => this.handleAllDemoChange(value, isAll)}
                                        >
                                            {this.renderEZDemoOptions()}
                                        </Select>)}
                                </Form.Item>
                            </div>}
                    </Col>
                </Row>
                <Row gutter={24}>
                    {!salesVideoId && <Col xl={6}>
                        <div style={{ overflowY: "auto", height: this.state.height }}>
                            <Menu className='ez-demos-menu' selectedKeys={currentEZDemo !== undefined ? [currentEZDemo?.id?.toString()] : ['']} >
                                <Menu.Item
                                    style={{ whiteSpace: 'normal', height: 'auto', borderBottom: "1px solid #DDDDDD", borderRadius: "0px", paddingLeft: "10px", }}
                                    id={"all"}
                                    key={"all"}
                                    className='ez-demos-menu-item'
                                    onClick={() => {
                                        document.getElementById('tab-pane').scrollIntoView();
                                        this.props.currentUserEZDemo(allEZDemos);
                                        form.resetFields(["demos"]);
                                    }}
                                >
                                    All
                                </Menu.Item>
                                {data?.map(item => (
                                    <Menu.Item
                                        className='ez-demos-menu-item'
                                        style={{ whiteSpace: 'normal', height: 'auto', borderBottom: "1px solid #DDDDDD", borderRadius: "0px", paddingLeft: "10px", }}
                                        id={item?.id?.toString()}
                                        key={item?.id?.toString()}
                                        onClick={(key) => this.handleTabClick(key.key)}
                                    >
                                        <span><Tooltip title={item?.name}>{item?.name}</Tooltip></span>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        </div>
                    </Col>}
                    <Col style={{
                        overflowX: "hidden",
                        overflowY: "auto", height: this.state.height
                    }} xl={!salesVideoId ? 18 : 24}>
                        {this.renderTabContent()}
                    </Col>
                </Row>
            </div>
        );
    }

    toggleReadMore = (eachItem) => {
        const id = `${eachItem?.id}+${eachItem?.isDemoType ? eachItem?.demo?.id : eachItem?.solutionType?.id}`;
        let modifiedArray = this.state.readMore
        if (this.state.readMore[id] === undefined) { // when user has not clicked for any time
            modifiedArray[id] = true
        }
        else { //when user already clicked
            let value = modifiedArray[id]
            modifiedArray[id] = !value;
        }
        this.setState({
            readMore: modifiedArray
        })
    }

    expandReadMore = (desc, eachItem) => {
        const description = desc;
        return (
            <p className='ez-demo-description'>
                {this.state.readMore[`${eachItem?.id}+${eachItem?.isDemoType ? eachItem?.demo?.id : eachItem?.solutionType?.id}`] ? description : description?.slice(0, 400)}
                <span
                    style={{ fontSize: "12px", cursor: "pointer" }}
                    onClick={() => this.toggleReadMore(eachItem)}
                    className="read-or-hide link" >
                    {description?.length > 400 ? this.state.readMore[`${eachItem?.id}+${eachItem?.isDemoType ? eachItem?.demo?.id : eachItem?.solutionType?.id}`] ? " show less" : "...read more" : null}
                </span >
            </p>
        );
    }

    renderTabContent = () => {
        const { timeZoneName, currentEZDemo, isEzDemoRatingEnabled, isViewEzDemoCommentEnabled, } = this.props;
        const { salesVideoId } = this.props.match.params;
        const isAll = currentEZDemo?.id === "all";
        const { allSectionEZDemos, solutionSectionEZDemos } = this.state;
        const data = salesVideoId ? solutionSectionEZDemos : isAll ? allSectionEZDemos : solutionSectionEZDemos;
        return (
            <div>
                <span>
                    {
                        data?.map(ezDemo => {
                            const isUpdated = ezDemo?.updatedTime !== null;
                            const tag = `${isUpdated ? "Updated" : "Created"}`
                            return (
                                <div key={`${ezDemo?.id}-${ezDemo?.demo ? ezDemo?.demo?.id : ezDemo?.solutionType?.id}`} style={{ marginBottom: "10px" }}>
                                    <div className='ez-demo-date' style={{ marginTop: "5px" }}>
                                        <span style={{ fontWeight: "600", fontSize: "12px" }}>{tag}: </span>{moment.tz((ezDemo?.updatedTime ?? ezDemo?.createdTime), timeZoneName).format(CONFIG.dateFormats.userDateTime)}
                                    </div>
                                    <div>
                                        <span className='ez-demo-title margin-bottom-8'>{ezDemo?.title}</span>
                                        <span className='float-right' style={{ margin: "10px 0px" }}>
                                            <Button status="default" aria-label="start demo" onClick={() => this.handleLinkClick(ezDemo, ezDemo?.isDemoType, isAll)} >
                                                Start Demo
                                            </Button>
                                        </span>
                                        {/* <span className='float-right'>
                                            <Tag className={`${isUpdated ? "updated-tag" : "new-tag"}`}><span style={{ color: "black" }}>{tag}</span> </Tag>
                                        </span> */}

                                        {isEzDemoRatingEnabled && (ezDemo?.ratingCount > 0) && <Row style={{ fontSize: "12px" }}>
                                            {(ezDemo?.ratingCount > 0) && <Rate style={{ color: "#F38D00" }} disabled allowHalf value={roundToNearestHalf(ezDemo?.rating)} />}
                                            {(ezDemo?.ratingCount > 0 && isViewEzDemoCommentEnabled) && <span><Divider type="vertical" />
                                                <span
                                                    style={{ fontSize: "12px" }}
                                                    className="ant-rate-text link"
                                                    onClick={() => isViewEzDemoCommentEnabled ? this.setState({ reviewsVisible: true, currentItem: ezDemo, ezDemoId: ezDemo?.id }) : undefined}
                                                >
                                                    {ezDemo?.ratingCount} user ratings</span>
                                            </span>
                                            }
                                        </Row>}
                                    </div>
                                    <div>
                                        <span style={{ fontSize: "12px", fontWeight: "600" }}>{ezDemo?.isDemoType ? "Demo: " : "Solution: "}</span><span style={{ fontSize: "12px" }}> <span>{ezDemo?.isDemoType ? ezDemo?.demo?.title : (isAll || salesVideoId) ? ezDemo?.solutionType?.name : currentEZDemo?.name}</span></span>
                                    </div>
                                    <div>
                                        {this.expandReadMore(ezDemo?.description, ezDemo)}
                                    </div>
                                    <Divider className="ez-demo-divider" />
                                </div>)
                        })
                    }
                </span >
            </div >
        );
    }

    render() {
        const { ezDemos, allEZDemos, loading } = this.props;
        const data = (allEZDemos || ezDemos);
        const { currentViewedEZDemo, currentItem, ezDemoId } = this.state;
        const empty = <div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />{CONFIG.comingSoon}</div>;
        return (
            <Spin indicator={antIcon} spinning={loading}>
                <div style={{ padding: "0px 20px 20px 10px", marginTop: "-30px" }} className="font" >
                    <br />
                    {data !== undefined && data.length !== 0 ?
                        this.renderContent() :
                        empty}

                    {this.state.showFeedbackModal && <EZDemoFeedbackModal
                        showFeedbackModal={this.state.showFeedbackModal}
                        feedbackAuditId={this.state.feedbackAuditId}
                        fetchOnMount={this.fetchOnMount}
                        title={currentViewedEZDemo?.title}
                        handleCancel={() => this.setState({ showFeedbackModal: false })}
                    />}
                    {this.state.reviewsVisible && <Drawer
                        width="50%"
                        placement="right"
                        className="ez-demo-review-drawer"
                        title={"EZ Demo Reviews - " + currentItem?.title}
                        headerStyle={{ padding: '20px' }}
                        visible={this.state.reviewsVisible}
                        onClose={() => this.setState({ reviewsVisible: false, currentItem: null, ezDemoId: null })}
                        closable={true}
                    > <EZDemoReviews
                            ezDemoId={ezDemoId}
                            updateUserReviews={this.fetchOnMount}
                        />
                    </Drawer>}
                </div>
            </Spin >
        )
    }
}
const mapStateToProps = ({ ezDemos, user }) => {
    return {
        ezDemos: ezDemos.ezDemos,
        loading: ezDemos.loading,
        currentEZDemo: ezDemos.currentEZDemo,
        allEZDemos: ezDemos.allEZDemos,
        isEzDemoRatingEnabled: user.isEzDemoRatingEnabled,
        isViewEzDemoCommentEnabled: user.isViewEzDemoCommentEnabled,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
    }
}

const EZDemosForm = Form.create({ name: "ezDemosForm" })(EZDemosNeo)

export default connect(mapStateToProps, { selectTitle, toggleBackButton, clearUserEZDemos, getAllUserEZDemos, postEZDemoFeedback, getAllEZDemosForMenu, toggleIsFetchingUserEZDemos, currentUserEZDemo, audit })(EZDemosForm);