import React, { Component } from 'react'
import { connect } from 'react-redux'
import RoleTemplatesTable from '../common/RoleTemplatesTable';

import CONFIG from '../../config';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import { updateRoleTemplateEZDemos, toggleIsFetchingEZDemos, getAllEZDemos } from '../../actions';

class RoleTemplateEZDemos extends Component {
    state = {
        selectAllDisabled: false
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    columnFilters = {
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "title"),
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        onFilter: (value, record) =>
            record.title
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    }

    handleFilter = (title, confirm) => {
        title = title?.trim();
        if (!title && !this.state.selectAllDisabled) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else confirm()
    }

    handleClear = (clearFilters) => {
        clearFilters();
    }

    handleChange = (_, filters) => {
        const selectAllDisabled = Object.values(filters).filter(value => value.length !== 0)?.length ? true : false;
        this.setState({ selectAllDisabled });
    }

    componentDidMount() {
        this.props.toggleIsFetchingEZDemos(true);
        this.props.getAllEZDemos({ filterSort: {} });
    }

    render() {
        const { ezDemos, assignedEZDemos, allEZDemosAdminAccess, isFetching, updateRoleTemplateEZDemos, allEZDemosUserAccess, templateType } = this.props;
        const { selectAllDisabled } = this.state;

        return (
            <RoleTemplatesTable
                dataKey="ezDemo"
                hideColumns={templateType === CONFIG.roleTypes.admin ? [] : ['admin']}
                marginTop={true}
                bordered={true}
                data={ezDemos || []}
                selectAllDisabled={selectAllDisabled}
                columnFilters={this.columnFilters}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1,
                }}
                isFetching={isFetching}
                assignedData={assignedEZDemos}
                allAdminAccess={allEZDemosAdminAccess}
                allUserAccess={allEZDemosUserAccess}
                updateRoleTemplateData={updateRoleTemplateEZDemos}
            />
        );

    }

}
const mapStateToProps = ({ manageEZDemos, roleTemplate }) => {
    return {
        ezDemos: manageEZDemos.data,
        assignedEZDemos: roleTemplate.ezDemos,
        allEZDemosAdminAccess: roleTemplate.allEZDemosAdminAccess,
        allEZDemosUserAccess: roleTemplate.allEZDemosUserAccess,
        isFetching: manageEZDemos.loading,
        templateType: roleTemplate.templateType
    }
}

export default connect(mapStateToProps, { updateRoleTemplateEZDemos, toggleIsFetchingEZDemos, getAllEZDemos })(RoleTemplateEZDemos);
