import { aepEmail } from "../apis";
import { batch } from 'react-redux';
import {
    FETCH_DYNAMIC_EMAIL_TEMP_VERS,
    CLEAR_DYNAMIC_EMAIL_TEMP_VERS,
    TOGGLE_IS_FETCHING_EMAIL_TEMPLATE_VERSIONS,
    CLEAR_DYNAMIC_EMAIL_TEMPLATE,
    CLEAR_DYNAMIC_EMAIL_PREVIEW,
    UPDATE_EDITOR_CONTENT_DYNAMIC_FLOW,
    UPDATE_EMAIL_PREVIEW_EDITOR_CONTENT,
    TOGGLE_IS_PREVIEW_SUCCESSFUL,
    TOGGLE_CK_EDITOR_CONTENT_CHANGED_FLAG
}
    from './types';
import pushMessage from '../components/common/PushMessage';
import CONFIG from '../config';


export const fetchEmailTemplateVersionsByType = (demoId, type) => async (dispatch, getState) => {
    const response = await aepEmail.get(`emailPushNotificationService/emailTemplate/versions`, { params: { demoId: demoId, type } });

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const emailTemplateVersions = response.data;
        batch(() => {
            dispatch({
                type: FETCH_DYNAMIC_EMAIL_TEMP_VERS,
                payload: emailTemplateVersions
            });
            dispatch(toggleIsFetchingEmailTemplateVersions(false));
        });
    }
    else {
        pushMessage(CONFIG.messageType.error, "Could not fetch email templates!");
    }
    return response.status === CONFIG.HTTP_STATUS.OK ? response.data : []
}

export const clearDynamicTemplateVersion = () => {
    return {
        type: CLEAR_DYNAMIC_EMAIL_TEMP_VERS
    }
}

export const fetchPreviewForEmailTemplate = (updatedRecord, emailTemplateObj, subject, emailType) => async (dispatch, getState) => {
    const state = getState();
    const templateObj = {
        eventType: emailTemplateObj?.type,
        eventId: updatedRecord?.id,
        subject: subject,
        body: state.dynamicEmailTemplate?.html,
        schedule: emailType === CONFIG.emailTemplateEventType.manualBuildAvailable ? updatedRecord : undefined,
        statusMgmt: emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser ? updatedRecord : undefined
    }

    dispatch(toggleIsFetchingEmailTemplateVersions(true))
    const contextPath = '/emailPushNotificationService/preview/email'
    const response = await aepEmail({
        url: `${contextPath}`,
        method: 'post',
        data: templateObj,
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: UPDATE_EMAIL_PREVIEW_EDITOR_CONTENT,
            payload: response.data?.body || []
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Failed to load preview!');
    dispatch({
        type: TOGGLE_IS_PREVIEW_SUCCESSFUL,
        payload: (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) ? true : false
    });
    dispatch(toggleIsFetchingEmailTemplateVersions(false));
}

export const postEmailTemplateVersion = (versionDetails, values, demoId, isPostVersion) => async (dispatch, getState) => {
    dispatch(toggleIsFetchingEmailTemplateVersions(true))
    const state = getState();
    const postContextPath = "/emailPushNotificationService/emailTemplate/versions";
    const putContextPath = "/emailPushNotificationService/emailTemplates";
    const data = {
        type: versionDetails?.type,
        subject: versionDetails?.subject,
        title: values?.title,
        body: state.dynamicEmailTemplate.html,
        demoId: demoId,
        description: values?.description,
        emailCategory: versionDetails?.emailCategory
    }
    const response = await aepEmail({
        method: isPostVersion ? 'post' : 'put',
        url: isPostVersion ? postContextPath : `${putContextPath}/${versionDetails?.id}`,
        data: data,
        params: { isVersionEdit: isPostVersion ? false : true }
    });


    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, `Template ${isPostVersion ? 'created' : 'updated'} successfully`)
        return isPostVersion && response.data
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.duplicate_title.content)
        return "FAILED"
    }
    else {
        pushMessage(CONFIG.messageType.error, `Failed to save template!`);
        return "FAILED"
    }

}

export const deleteEmailTemplateVersion = (versionId, type, demoId, hardDelete = false) => async (dispatch) => {
    dispatch(toggleIsFetchingEmailTemplateVersions(true))
    const response = await aepEmail.delete("/emailPushNotificationService/emailTemplates/" + versionId, { params: { hardDelete } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Template deleted successfully")
        dispatch(fetchEmailTemplateVersionsByType(demoId, type))
    }
    else pushMessage(CONFIG.messageType.error, "Unable to delete the template!");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
};

export const updateEmailTemplateDefaultVersion = (versionId, type, demoId, isVersion = false) => async (dispatch) => {
    dispatch(toggleIsFetchingEmailTemplateVersions(true))
    const response = await aepEmail.patch(`/emailPushNotificationService/emailTemplate/default`, {
        id: versionId,
        type,
        demoId,
        isVersion
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Recommended Template updated successfully")
        dispatch(fetchEmailTemplateVersionsByType(demoId, type));
        return true;
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to update recommended template!");
        return false;
    }
};


export const uploadAttachment = (elementId, file, eventType) => async () => {
    const formData = new FormData();

    // Append data to the FormData
    formData.append('file', file)
    formData.append('elementId', new Blob([elementId], { type: 'application/json' }))
    formData.append('eventType', new Blob([eventType], { type: 'application/json' }))

    const response = await aepEmail({
        method: 'post',
        url: "emailPushNotificationService/attachment/uploadFile/",
        data: formData
    })
    if ((response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED))
        return response?.data
    else if (response.status === CONFIG.HTTP_STATUS.UNSUPPORTED_MEDIA_TYPE) {
        pushMessage(CONFIG.messageType.error, "Uploaded file type is not supported. Could not send email!");
        return undefined
    }
    else {
        pushMessage(CONFIG.messageType.error, "Error occured while uploading the attachment. Could not send email!");
        return undefined
    }
}

export const sendEmailToUser = (emailDetails, toRecipient, elementId, file, emailType, isDemoRequestAdmin) => async (dispatch, getState) => {
    const state = getState();
    const filePath = file ? await dispatch(uploadAttachment(elementId, file, emailType)) : undefined
    if ((file && filePath !== undefined) || (!file)) {
        const emailPayload = {
            subject: emailDetails?.subject,
            body: isDemoRequestAdmin ? state.demoRequestEmailPreview.html : state.dynamicEmailPreview.html,
            recipients: [toRecipient],
            cc: emailDetails?.cc,
            bcc: emailDetails?.bcc,
            filePath: filePath,
        }

        const contextPath = `/emailPushNotificationService/user/email`
        const response = await aepEmail({
            method: 'post',
            url: contextPath,
            data: emailPayload
        });

        const pushMessageString = emailType === CONFIG.emailTemplateEventType.manualBuildAvailable
            ? "Build Status Updated & "
            : emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser
                ? "Demo Request Updated & "
                : ""

        if (response.status === CONFIG.HTTP_STATUS.OK) pushMessage(CONFIG.messageType.success, `${pushMessageString} Email sent successfully.`);
        else if (response.status === CONFIG.HTTP_STATUS.NOT_FOUND) pushMessage(CONFIG.messageType.error, 'Could not send email. User no longer exists.');
        else pushMessage(CONFIG.messageType.error, 'Failed to send email!');
        return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
    }
}



export const updateEditorContentForDynamicFlow = (emailTemplate) => async dispatch => {
    dispatch({
        type: UPDATE_EDITOR_CONTENT_DYNAMIC_FLOW,
        payload: emailTemplate
    })
}

export const updateEmailPreviewEditorContent = (emailTemplate) => async dispatch => {
    dispatch({
        type: UPDATE_EMAIL_PREVIEW_EDITOR_CONTENT,
        payload: emailTemplate
    })
}

export const clearDynamicEmailTemplate = () => {
    return {
        type: CLEAR_DYNAMIC_EMAIL_TEMPLATE
    }
}

export const clearDynamicEmailPreview = () => {
    return {
        type: CLEAR_DYNAMIC_EMAIL_PREVIEW
    }
}

export const toggleIsFetchingEmailTemplateVersions = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_EMAIL_TEMPLATE_VERSIONS,
        payload: flag
    };
};

export const toggleCkEditorContentChangedFlag = (flag) => {
    return {
        type: TOGGLE_CK_EDITOR_CONTENT_CHANGED_FLAG,
        payload: flag
    };
};

export const getAllDemosByEventType = async (eventType) => {
    const response = await aepEmail.get("/emailPushNotificationService/emailTemplate/demos", {
        params: { eventType }
    });
    return response.data || [];
}