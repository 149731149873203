import { aepDemo } from '../apis';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { batch } from 'react-redux';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';

import {
    TOGGLE_IS_FETCHING_USER_EZ_DEMOS,
    FETCH_USER_EZ_DEMOS,
    CURRENT_USER_EZ_DEMO,
    FETCH_ALL_EZ_DEMOS,
    FETCH_ACTIVE_EZ_DEMOS,
    TOGGLE_IS_FETCHING_EZ_DEMOS,
    CLEAR_EZ_DEMOS,
    DELETE_EZ_DEMOS_DEMO,
    ADD_EZ_DEMOS_DEMO,
    CLEAR_EZ_DEMO_FORM,
    FETCH_ALL_SOLUTION_DEMOS,
    FETCH_EZ_DEMO_BY_ID,
    UPDATE_EZ_DEMOS_ROLE_TEMPLATE,
    UPDATE_EZ_DEMO_CURRENT_PAGE,
    LAST_EZ_DEMO_FILTER,
    CLEAR_EZ_DEMOS_TABLE_DATA,
    FETCH_ALL_USER_EZ_DEMOS,
    FETCH_ALL_USERS_REVIEWS_EZ_DEMOS,
    TOGGLE_IS_FETCHING_EZ_DEMOS_FEEDBACK,
    FETCH_EZ_DEMO_RATING_SPLIT_UP,
    CLEAR_EZ_DEMO_REVIEWS,
    CLEAR_USER_EZ_DEMOS,
    RE_ORDER_EZ_DEMOS_TABLE,
    TOGGLE_IS_EZ_DEMOS_REORDERED
} from "./types";
import { closeCurrentOpenTab } from './tabsLayout';

export const toggleIsFetchingUserEZDemos = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_USER_EZ_DEMOS,
        payload: flag
    }
}

export const getAllUserEZDemos = () => async (dispatch) => {
    dispatch(toggleIsFetchingUserEZDemos(true));
    const response = await aepDemo.get("/demoSolutionService/user/solutionType/ezDemo");
    if (response.status === CONFIG.HTTP_STATUS.OK || CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_USER_EZ_DEMOS,
            payload: response.data || []
        })
    }
    else pushMessage("Unable to fetch EZ Demos");
    dispatch(toggleIsFetchingUserEZDemos(false))
}

export const clearUserEZDemos = () => {
    return {
        type: CLEAR_USER_EZ_DEMOS
    }
}

export const getAllEZDemosForMenu = (searchEZDemoId) => async (dispatch) => {
    dispatch(toggleIsFetchingUserEZDemos(true));
    const response = await aepDemo.get("/demoSolutionService/user/allEzDemo");
    if (response.status === CONFIG.HTTP_STATUS.OK || CONFIG.HTTP_STATUS.NO_CONTENT) {
        const currentEZDemo = response?.data ? { ezDemos: response.data, id: "all" } : null;
        if (currentEZDemo && !searchEZDemoId) dispatch(currentUserEZDemo(currentEZDemo))
        dispatch({
            type: FETCH_ALL_USER_EZ_DEMOS,
            payload: currentEZDemo || []
        })
    }
    else pushMessage("Unable to fetch EZ Demos");
    dispatch(toggleIsFetchingUserEZDemos(false))
}

export const currentUserEZDemo = (ezDemo) => {
    return {
        type: CURRENT_USER_EZ_DEMO,
        payload: ezDemo
    }
}

export const toggleIsFetchingEZDemos = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_EZ_DEMOS,
        payload: flag
    }
}

export const clearEZDemos = () => {
    return { type: CLEAR_EZ_DEMOS }
}

export const fetchAllEZDemosForExport = ({ filterSort, access, status }) => async () => {
    const response = await aepDemo.get("/demoSolutionService/ezDemos", {
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
                }
            },
            access
        }
    });
    return response?.data?.dataSet || []
}

export const getAllActiveEZDemos = (filterSort) => async (dispatch) => {
    dispatch(toggleIsFetchingEZDemos(true));
    const response = await aepDemo.get("/demoSolutionService/ezDemos", {
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: true
                }
            }
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ACTIVE_EZ_DEMOS, payload: response?.data?.dataSet || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to active fetch EZ Demos');
    dispatch(toggleIsFetchingEZDemos(false));
}

export const reorderEZDemos = (ezDemos) => dispatch => {
    batch(() => {
        dispatch({
            type: RE_ORDER_EZ_DEMOS_TABLE,
            payload: [...ezDemos]
        });
        dispatch(toggleIsEZDemosReordered(true));
    });
};

export const putEZDemosOrder = (ezDemos) => async dispatch => {
    const response = await aepDemo.put('/demoSolutionService/ezDemos/updateSortOrder', ezDemos);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "EZ Demos order updated successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to update EZ Demos order");
    dispatch(toggleIsEZDemosReordered(false));
};

export const toggleIsEZDemosReordered = (flag) => {
    return {
        type: TOGGLE_IS_EZ_DEMOS_REORDERED,
        payload: flag
    };
};

export const getAllEZDemos = ({ filterSort, access, status }) => async (dispatch) => {

    const response = await aepDemo.get("/demoSolutionService/ezDemos", {
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
                }
            },
            access
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ALL_EZ_DEMOS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch EZ Demos');
    dispatch(toggleIsFetchingEZDemos(false));
}

export const getEZDemoById = (ezDemoId) => async (dispatch, getState) => {
    const response = await aepDemo.get("/demoSolutionService/ezDemo/" + ezDemoId);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { user, roleTemplates } = getState();
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const ezDemo = response.data;
        const ezDemoData = {
            ...response.data,
            roleTemplates: isAdmin ? response.data.roleTemplates.filter(tipRoleTemplate => allRoleTemplates.some(roleTemplate => tipRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates
        }
        batch(() => {
            dispatch({
                type: FETCH_EZ_DEMO_BY_ID,
                payload: { ezDemo: ezDemoData, ezDemoObj: ezDemo }
            })
            const demos = ezDemo?.demos || [];
            const solutionTypes = ezDemo?.solutionTypes || [];
            if (solutionTypes?.length > 0) {
                solutionTypes.forEach((solution) => {
                    dispatch(addEZDemosDemo({
                        id: solution?.solutionType?.id, title: solution?.solutionType?.name, isActive: solution?.solutionType?.isActive, isDemo: false
                    }))
                })
            }
            if (demos?.length > 0) {
                demos.forEach((demo) => {
                    dispatch(addEZDemosDemo({
                        id: demo?.demo?.id, title: demo?.demo?.title, isActive: demo?.demo?.isActive, isDemo: true
                    }))
                })
            }

        });
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch EZ Demo');
    }
    dispatch(toggleIsFetchingEZDemos(false));
}

export const deleteEZDemosDemo = (demoId, isDemo) => {
    return {
        type: DELETE_EZ_DEMOS_DEMO,
        payload: { demoId, isDemo }
    };
};

export const addEZDemosDemo = (demo) => {
    return {
        type: ADD_EZ_DEMOS_DEMO,
        payload: demo
    };
};

export const clearEZDemoForm = () => {
    return { type: CLEAR_EZ_DEMO_FORM }
}

export const allSolutionDemos = (solutionDemos) => {
    return {
        type: FETCH_ALL_SOLUTION_DEMOS,
        payload: solutionDemos
    }
}

export const updateEZDemoRoleTemplate = (access, accessType, roleType, selectedRoleTemplate) => (dispatch, getState) => {
    const allRoleTemplates = getState().roleTemplates.all;
    const assignedRoleTemplates = getState().ezDemosForm.roleTemplates;
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allRTUserAccess' : 'allRTAdminAccess';
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_EZ_DEMOS_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access, allAccessKey }
    });
}

export const postEZDemo = (ezDemoId, values, demos, solutionTypes, file) => async (dispatch, getState) => {
    const state = getState();
    const isFile = values?.isFile?.includes?.("File")
    const ezDemoObj = {
        id: ezDemoId,
        roleTemplates: state.ezDemosForm.roleTemplates,
        title: values.title,
        description: values.description,
        body: state.tipBody.html,
        isActive: values.isActive,
        isFile,
        link: isFile === false ? values?.url : null,
        assignedRoleTemplates: undefined,
        demos,
        solutionTypes,
        isRatingReset: values?.isRatingReset
    };

    const formData = new FormData();
    const ezDemoJSONString = JSON.stringify(ezDemoObj);
    const ezDemoBlob = new Blob([ezDemoJSONString], {
        type: 'application/json'
    });
    if (isFile && (file.size !== 0 || ezDemoId === undefined)) formData.append('mediaFile', file);
    formData.append('ezDemoObj', ezDemoBlob);

    const contextPath = "/demoSolutionService/ezDemo";
    const response = await aepDemo({
        method: ezDemoId === undefined ? 'post' : 'put',
        url: ezDemoId === undefined ? contextPath : `${contextPath}/${ezDemoId}`,
        data: formData
    });
    const successMessage = ezDemoId ? 'EZ Demo updated successfully' : 'EZ Demo created successfully';
    const errorMessage = ezDemoId ? 'Unable to update EZ Demo' : 'Unable to create EZ Demo';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage)
        dispatch(closeCurrentOpenTab())
    }
    else if (response.status === CONFIG.HTTP_STATUS.BAD_REQUEST) pushMessage(CONFIG.messageType.error, "Invalid file size")
    else if (response.status === CONFIG.HTTP_STATUS.UNSUPPORTED_MEDIA_TYPE) {
        pushMessage(CONFIG.messageType.error, "Uploaded file type is not supported.");
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingEZDemos(false));
}

export const deleteEZDemoManage = (ezDemoId, hardDelete = false) => async () => {
    const response = await aepDemo.delete("/demoSolutionService/ezDemo/" + ezDemoId, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "EZ Demo deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete EZ Demo");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
};

export const updateEZDemosCurrentPage = (currentPageNumber, pageSize, ezDemoId, ezIndex) => {
    return {
        type: UPDATE_EZ_DEMO_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, ezDemoId, ezIndex]
    }
};

export const clearEZDemosTableData = () => {
    return {
        type: CLEAR_EZ_DEMOS_TABLE_DATA
    }
}

export const currentEZDemoFilter = (filters, status) => {
    return {
        type: LAST_EZ_DEMO_FILTER,
        payload: [filters, status]
    }
}

export const getAllReviewsForEZDemos = (ezDemoId, isManageSection) => async (dispatch) => {
    const params = {
        isManageSection: isManageSection,
    };
    const response = await aepDemo.get(`/demoSolutionService/ezDemo/feedbacks/${ezDemoId}`, { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: FETCH_ALL_USERS_REVIEWS_EZ_DEMOS,
                payload: response.data ? response.data?.dataSet : []
            });
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch EZ Demo reviews');
}

export const toggleIsFetchingEZDemoFeedback = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_EZ_DEMOS_FEEDBACK,
        payload: flag
    }
}

export const getSplitRatingForEZDemos = (ezDemoId) => async (dispatch) => {
    const response = await aepDemo.get(`/demoSolutionService/ezDemo/${ezDemoId}/ratingSplit`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: FETCH_EZ_DEMO_RATING_SPLIT_UP,
                payload: response.data || []
            });
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch EZ Demo Rating Details');
}

export const clearEZDemoReviews = () => {
    return {
        type: CLEAR_EZ_DEMO_REVIEWS
    }
}

export const updateEZDemoFeedback = (ezDemoAuditRatingId, values, isEdit, isDelete, isAdmin) => async () => {
    const contextPath = `/demoSolutionService/ezDemo/feedback/${ezDemoAuditRatingId}`
    const successMessage = isAdmin ? isDelete ? "deleted" : isEdit ? "updated" : "submitted" : "updated"
    const failureMessage = isAdmin ? isDelete ? "delete" : isEdit ? "update" : "submit" : "update"
    const response = await aepDemo({
        method: "PUT",
        url: contextPath,
        data: values
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, `${isAdmin ? "Reply" : "Feedback"} ${successMessage} successfully`)
    }
    else pushMessage(CONFIG.messageType.error, `Failed to ${failureMessage} ${isAdmin ? "reply" : "feedback"}`);
}

export const deleteEZDemoFeedback = (ezDemoAuditRatingId, isAdmin) => async () => {
    const response = await aepDemo.delete(`/demoSolutionService/ezDemo/feedback/${ezDemoAuditRatingId}?isAdminAction=${isAdmin}`);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "EZ Demo feedback deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete EZ Demo feedback");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}