import { batch } from "react-redux";

import { aepDemo } from "../apis";

import {
  FETCH_SOLUTION_TYPES,
  TOGGLE_IS_FETCHING_SOLUTION_TYPES,
  REORDER_SOLUTION_TYPES,
  TOGGLE_IS_SOLUTION_TYPES_REORDERED,
  DELETE_SOLUTION_TYPE,
  UPDATE_SOLUTION_TYPE_ROLE_TEMPLATE,
  GET_SELECTED_SOLUTION_TYPE,
  CLEAR_SOLUTION_TYPE_FORM,
  CLEAR_SOLUTION_TYPE,
  LAST_SOLUTIONS_TYPE_FILTER,
  REFRESH_SOLUTION_TYPES,
  TOGGLE_HAS_SOLUTION_TYPES_UPDATED
} from "./types";
import pushMessage from "../components/common/PushMessage";
import { getUpdatedRoleTemplateAssociations } from "./roleTemplates";
import CONFIG from "../config";
import { closeCurrentOpenTab } from './tabsLayout';

export const fetchSolutionTypes = (access, viewDeletedItems = false) => async (dispatch) => {
  const response = await aepDemo.get("/demoSolutionService/solutionTypes", { params: { access, viewDeletedItems } });
  if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
    const solutionTypes = response.data;
    dispatch({
      type: FETCH_SOLUTION_TYPES,
      payload: solutionTypes || [],
    });
  }
  else pushMessage(CONFIG.messageType.error, "Unable to fetch solution types");
  dispatch(toggleIsFetchingSolutionTypes(false));
};

export const reorderSolutionTypes = (reorderedSolutionTypes) => (dispatch) => {
  batch(() => {
    dispatch({
      type: REORDER_SOLUTION_TYPES,
      payload: [...reorderedSolutionTypes],
    });
    dispatch(toggleIsSolutionTypesReordered(true));
  });
};

export const putSolutionTypesOrder = (solutionTypes) => async (dispatch) => {
  const response = await aepDemo.put(
    `/demoSolutionService/solutionTypes`,
    solutionTypes
  );
  response.status === CONFIG.HTTP_STATUS.OK
    ? pushMessage(
      CONFIG.messageType.success,
      "Solution types order updated successfully"
    )
    : pushMessage(
      CONFIG.messageType.error,
      "Unable to update solution types order"
    );
  dispatch(toggleIsSolutionTypesReordered(false));
};

export const deleteSolutionType = (solutionTypeId, hardDelete = false) => async (dispatch) => {
  const response = await aepDemo.delete(
    `/demoSolutionService/solutionTypes/${solutionTypeId}`,
    { params: { hardDelete } }
  );
  if (response.status === CONFIG.HTTP_STATUS.OK) {
    dispatch({
      type: DELETE_SOLUTION_TYPE,
      payload: solutionTypeId,
    });
    pushMessage(
      CONFIG.messageType.success,
      "Solution type deleted successfully"
    );
  } else
    pushMessage(CONFIG.messageType.error, "Unable to delete solution type");
};

export const restoreSolutionType = (solutionTypeId) => async () => {
  const response = await aepDemo.put("/demoSolutionService/restore/solutionTypes/" + solutionTypeId);
  response.status === CONFIG.HTTP_STATUS.OK
    ? pushMessage(CONFIG.messageType.success, "Solution Type restored successfully")
    : pushMessage(CONFIG.messageType.error, "Unable to restore solution type");
}

export const toggleIsSolutionTypesReordered = (flag) => {
  return {
    type: TOGGLE_IS_SOLUTION_TYPES_REORDERED,
    payload: flag,
  };
};

export const toggleIsFetchingSolutionTypes = (flag) => ({
  type: TOGGLE_IS_FETCHING_SOLUTION_TYPES,
  payload: flag,
});

export const postSolutionType = (formValues) => async (dispatch, getState) => {
  const { solutionTypeForm } = getState();
  const { roleTemplates } = solutionTypeForm;
  const solutionTypeDetails = {
    name: formValues.name,
    isActive: formValues.isActive ? formValues.isActive : false,
    roleTemplates,
  };

  const response = await aepDemo.post(
    `/demoSolutionService/solutionTypes`,
    solutionTypeDetails
  );

  response.status === CONFIG.HTTP_STATUS.CREATED
    ? pushMessage(
      CONFIG.messageType.success,
      "Solution type created successfully"
    )
    : pushMessage(CONFIG.messageType.error, "Unable to create solution type");
  dispatch(toggleIsFetchingSolutionTypes(false))
  dispatch(closeCurrentOpenTab());
};

export const putSolutionType = (solutionTypeId, formValues) => async (
  dispatch,
  getState
) => {
  const { solutionTypeForm } = getState();
  const { roleTemplates } = solutionTypeForm;
  const solutionType = {
    name: formValues.name,
    isActive: formValues.isActive ? formValues.isActive : false,
    roleTemplates,
  };
  const response = await aepDemo.put(
    `/demoSolutionService/solutionTypes/${solutionTypeId}`,
    solutionType
  );
  response.status === CONFIG.HTTP_STATUS.OK
    ? pushMessage(
      CONFIG.messageType.success,
      "Solution type updated successfully"
    )
    : pushMessage(CONFIG.messageType.error, "Unable to update solution type");
  dispatch(toggleIsFetchingSolutionTypes(false))
  dispatch(closeCurrentOpenTab());
};

export const updateSolutionTypeRoleTemplate = (
  access,
  accessType,
  roleType,
  selectedRoleTemplate
) => async (dispatch, getState) => {
  const { solutionTypeForm, roleTemplates } = getState();
  const allRoleTemplates = roleTemplates.all;
  const accessKey =
    roleType === CONFIG.roleTypes.user
      ? "allRoleTemplatesUserAccess"
      : "allRoleTemplatesAdminAccess";
  let assignedRoleTemplates = solutionTypeForm.roleTemplates;
  let updatedRoleTemplates = getUpdatedRoleTemplateAssociations(
    access,
    roleType,
    accessType,
    selectedRoleTemplate,
    assignedRoleTemplates,
    "roleTemplate",
    allRoleTemplates,
    false
  );
  dispatch({
    type: UPDATE_SOLUTION_TYPE_ROLE_TEMPLATE,
    payload: {
      updatedRoleTemplates,
      selectedRoleTemplate,
      accessType,
      access,
      accessKey,
    },
  });
};

export const getSelectedSolutionType = (solutionId) => async (dispatch, getState) => {
  const response = await aepDemo(`demoSolutionService/solutionTypes/${solutionId}`);
  if (response.status === CONFIG.HTTP_STATUS.OK) {
    const { user, roleTemplates } = getState();
    const allRoleTemplates = roleTemplates.all;
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
    const solutionTypeDetails = {
      ...response.data,
      roleTemplates: isAdmin
        ? response.data.roleTemplates.filter((solutionRoleTemplate) => allRoleTemplates.some((roleTemplate) => solutionRoleTemplate.roleTemplate.id === roleTemplate.id))
        : response.data.roleTemplates,
    };
    batch(() => {
      dispatch({
        type: GET_SELECTED_SOLUTION_TYPE,
        payload: solutionTypeDetails,
      });
    });
  }
  else pushMessage(CONFIG.messageType.error, "Unable to fetch solution");
};

export const clearSolutionTypeForm = () => {
  return {
    type: CLEAR_SOLUTION_TYPE_FORM,
  };
};

export const clearSolutionType = () => {
  return {
    type: CLEAR_SOLUTION_TYPE,
  };
};

export const currentSolutionTypeFilter = (filters, status) => {
  return {
    type: LAST_SOLUTIONS_TYPE_FILTER,
    payload: [filters, status]
  }
}

export const checkSolutionTypeDependencies = async (solutionTypeId) => {
  const response = await aepDemo.get(`/demoSolutionService/solutionTypes/${solutionTypeId}/dependencies`)
  if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
  else throw Error("Unable to get solution type dependency list");
};

export const refreshSolutionTypes = () => {
  return {
    type: REFRESH_SOLUTION_TYPES
  }
}

export const toggleHasSolutionTypesUpdated = flag => {
  return {
    type: TOGGLE_HAS_SOLUTION_TYPES_UPDATED,
    payload: flag
  }
}

export const getAllSolutionTypeLite = () => async () => {
  const response = await aepDemo.get("/demoSolutionService/v1/lite/solutionTypes");
  if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) return response?.data || [];
  else pushMessage(CONFIG.messageType.error, "Unable to fetch solution type");
}