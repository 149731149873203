import {
  FETCH_SOLUTION_TYPES,
  TOGGLE_IS_FETCHING_SOLUTION_TYPES,
  TOGGLE_IS_SOLUTION_TYPES_REORDERED,
  REORDER_SOLUTION_TYPES,
  DELETE_SOLUTION_TYPE,
  SAVE_SOLUTION_TYPE_ROLE_TEMPLATES,
  UPDATE_SOLUTION_TYPE_ROLE_TEMPLATE,
  GET_SELECTED_SOLUTION_TYPE,
  CLEAR_SOLUTION_TYPE_FORM,
  CANCEL_SOLUTION_TYPE_ROLE_TEMPLATES,
  CLEAR_SOLUTION_TYPE,
  LAST_SOLUTIONS_TYPE_FILTER,
  REFRESH_SOLUTION_TYPES,
  TOGGLE_HAS_SOLUTION_TYPES_UPDATED,
} from "../actions/types";

import CONFIG from "../config";

const solutionTypesState = {
  isFetching: false,
  selectedType: undefined,
  isReordered: false,
};

const solutionTypeFormState = {
  allRoleTemplatesUserAccess: undefined,
  allRoleTemplatesAdminAccess: undefined,
};

export const solutionTypesReducer = (state = solutionTypesState, action) => {
  switch (action.type) {
    case FETCH_SOLUTION_TYPES:
      const allTypes = action.payload || [];
      const activeTypes = allTypes?.length > 0 ? allTypes?.filter(item => item?.isActive) : [];
      const inactiveTypes = allTypes?.length > 0 ? allTypes?.filter(item => !item?.isActive) : [];
      return { ...state, types: allTypes, activeTypes, inactiveTypes };
    case REORDER_SOLUTION_TYPES:
      return { ...state, activeTypes: action.payload };
    case DELETE_SOLUTION_TYPE:
      return {
        ...state,
        types: state.types.filter(
          (solutionType) => solutionType.id !== action.payload
        ),
      };
    case TOGGLE_IS_FETCHING_SOLUTION_TYPES:
      return { ...state, isFetching: action.payload };
    case TOGGLE_IS_SOLUTION_TYPES_REORDERED:
      return { ...state, isReordered: action.payload };
    case LAST_SOLUTIONS_TYPE_FILTER:
      return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true }
    case CLEAR_SOLUTION_TYPE:
      return solutionTypesState;
    default:
      return state;
  }
};

export const solutionTypeFormReducer = (
  state = solutionTypeFormState,
  action
) => {
  switch (action.type) {
    case SAVE_SOLUTION_TYPE_ROLE_TEMPLATES:
      return { ...state, assignedRoleTemplates: state.roleTemplates };
    case UPDATE_SOLUTION_TYPE_ROLE_TEMPLATE:
      return {
        ...state,
        roleTemplates: action.payload.updatedRoleTemplates,
        [action.payload.accessKey]:
          !action.payload.selectedRoleTemplate && action.payload.access
            ? action.payload.accessType
            : CONFIG.roleTemplateAccess.none,
      };
    case GET_SELECTED_SOLUTION_TYPE:
      return {
        ...state,
        ...action.payload,
        assignedRoleTemplates: action.payload.roleTemplates || [],
        solutionTypeObj: action.payload
      };
    case CLEAR_SOLUTION_TYPE_FORM:
      return solutionTypeFormState;
    case TOGGLE_IS_FETCHING_SOLUTION_TYPES:
      return { ...state, isFetching: action.payload };
    case CANCEL_SOLUTION_TYPE_ROLE_TEMPLATES:
      return {
        ...state,
        roleTemplates: state.assignedRoleTemplates || [],
        allRoleTemplatesAdminAccess: undefined,
        allRoleTemplatesUserAccess: undefined,
      };
    case REFRESH_SOLUTION_TYPES:
      return {
        ...state,
        data: [],
        count: undefined
      }
    case TOGGLE_HAS_SOLUTION_TYPES_UPDATED:
      return {
        ...state,
        hasUpdated: action.payload,
      };

    default:
      return state;
  }
};
