import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Switch } from 'antd';

import CONFIG from '../../config';
import { postNewDomain, updateRoleTemplateType, updateNetworkAdmin } from '../../actions';
// import pushMessage from '../common/PushMessage';

// const { Option } = Select;
const { TextArea } = Input;

class RoleTemplatesBasicDetails extends React.Component {


    isDomainDuplicate = (newDomain) => {
        const { allDomains } = this.props;
        const duplicateDomains = allDomains.filter(domain => domain.name === newDomain);
        return duplicateDomains.length !== 0;
    }

    // handleDomainSelect = (selectedDomain) => {
    //     const { form, allDomains } = this.props;
    //     let currentDomains = form.getFieldValue('domains');
    //     let domainObj = allDomains.find(domain => domain.name === selectedDomain);
    //     if (!this.isDomainDuplicate(selectedDomain)) {
    //         currentDomains.pop();
    //         form.setFieldsValue({ domains: currentDomains });
    //         this.props.postNewDomain(selectedDomain);
    //     }
    //     else if (domainObj && domainObj.roleTemplate !== null && domainObj.roleTemplate.id !== this.props.id) {
    //         currentDomains.pop();
    //         form.setFieldsValue({ domains: currentDomains });
    //         pushMessage(CONFIG.messageType.warning, `Domain exists in ${domainObj.roleTemplate.title}`);
    //     }
    // }

    handleAdminToggle = (value) => {
        const templateType = value ? CONFIG.roleTypes.admin : CONFIG.roleTypes.user;
        this.props.updateRoleTemplateType(templateType);
    }

    handleNetworkAdminToggle = (value) => {
        this.props.updateNetworkAdmin(value);
    }

    // renderDomainOptions = () => {
    //     const { allDomains } = this.props;
    //     return allDomains
    //         ? allDomains.map((domain, index) => <Option key={index} value={domain.name}>{domain.name}</Option>)
    //         : [];
    // }


    handleCommentsToggleChange = (flag) => {
        if (flag) this.props.form.setFieldsValue({ isRatingEnabled: true })
    }

    handleRatingToggleChange = (flag) => {
        if (!flag) this.props.form.setFieldsValue({ isViewCommentEnabled: false })
    }

    handleEZRatingToogleChange = (flag) => {
        if (!flag) this.props.form.setFieldsValue({ isViewEzDemoComment: false })
    }

    handleEZCommentToggleChange = (flag) => {
        if (flag) this.props.form.setFieldsValue({ isEzDemoRatingEnabled: true })
    }


    render() {
        const { title, templateType, userRoleTemplate, networksAccess, isRatingEnabled, isViewCommentEnabled, description, isEzDemoRatingEnabled, isViewEzDemoComment } = this.props;
        const { getFieldDecorator } = this.props.form;
        // const selectedDomains = domains
        //     ? domains.map(domain => domain.name)
        //     : [];
        return (
            <Form colon={false} >
                <Row gutter={48}>
                    <Col xl={12} xs={24}>
                        <Form.Item label="Title">
                            {getFieldDecorator("roleTemplateName", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input the title"
                                    }
                                ],
                                initialValue: title ? title : ""
                            })(<Input
                                disabled={title === "CCaaS SFDC Scheduling User" || title === "EA Admin"} />)}
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={48}>
                    {userRoleTemplate.templateType !== CONFIG.roleTypes.admin &&
                        <Col xl={4} lg={12} md={12} xs={24}>
                            <Form.Item label="Admin">
                                {getFieldDecorator("isAdmin", {
                                    valuePropName: "checked",
                                    initialValue: templateType
                                        ? templateType === CONFIG.roleTypes.admin || templateType === CONFIG.roleTypes.superAdmin
                                        : false
                                })(
                                    <Switch
                                        disabled={templateType === CONFIG.roleTypes.superAdmin || templateType === CONFIG.roleTypes.default}
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={this.handleAdminToggle}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    }
                    {userRoleTemplate.templateType !== CONFIG.roleTypes.admin && (templateType === CONFIG.roleTypes.admin || templateType === CONFIG.roleTypes.superAdmin) &&
                        <Col xl={4} lg={12} md={12} xs={24}>
                            <Form.Item label="Network Admin">
                                {getFieldDecorator("networksAccess", {
                                    valuePropName: "checked",
                                    initialValue: networksAccess ? true : false
                                })(
                                    <Switch
                                        disabled={templateType === CONFIG.roleTypes.superAdmin || templateType === CONFIG.roleTypes.default}
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={this.handleNetworkAdminToggle}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    }
                    <Col xl={4} lg={12} md={12} xs={24}>
                        <Form.Item label="View Demo Ratings">
                            {getFieldDecorator("isRatingEnabled", {
                                valuePropName: "checked",
                                initialValue: isRatingEnabled
                            })(
                                <Switch
                                    disabled={templateType === CONFIG.roleTypes.superAdmin}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={(flag) => this.handleRatingToggleChange(flag)}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xl={4} lg={12} md={12} xs={24}>
                        <Form.Item label="View Demo Reviews">
                            {getFieldDecorator("isViewCommentEnabled", {
                                valuePropName: "checked",
                                initialValue: isViewCommentEnabled
                            })(
                                <Switch
                                    disabled={templateType === CONFIG.roleTypes.superAdmin}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={(flag) => this.handleCommentsToggleChange(flag)}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={48}>
                    <Col xl={4} lg={12} md={12} xs={24}>
                        <Form.Item label="View EZ Demo Ratings">
                            {getFieldDecorator("isEzDemoRatingEnabled", {
                                valuePropName: "checked",
                                initialValue: isEzDemoRatingEnabled
                            })(
                                <Switch
                                    disabled={templateType === CONFIG.roleTypes.superAdmin}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={(flag) => this.handleEZRatingToogleChange(flag)}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xl={4} lg={12} md={12} xs={24}>
                        <Form.Item label="View EZ Demo Reviews">
                            {getFieldDecorator("isViewEzDemoComment", {
                                valuePropName: "checked",
                                initialValue: isViewEzDemoComment
                            })(
                                <Switch
                                    disabled={templateType === CONFIG.roleTypes.superAdmin}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={(flag) => this.handleEZCommentToggleChange(flag)}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item label="Description">
                        {getFieldDecorator("description", {
                            initialValue: description ? description : ""
                        })(<TextArea rows={4} />)}
                    </Form.Item>
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = ({ roleTemplate, user }) => {
    const { id, title, domains, allDomains, templateType, networksAccess, isRatingEnabled, isViewCommentEnabled, description, isEzDemoRatingEnabled, isViewEzDemoComment } = roleTemplate
    return {
        id,
        title,
        domains,
        allDomains,
        templateType,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        networksAccess,
        isRatingEnabled,
        isViewCommentEnabled,
        description,
        isEzDemoRatingEnabled,
        isViewEzDemoComment
    };
};


const RoleTemplatesBasicDetailsForm = Form.create()(RoleTemplatesBasicDetails);

export default connect(
    mapStateToProps,
    { postNewDomain, updateRoleTemplateType, updateNetworkAdmin }
)(RoleTemplatesBasicDetailsForm);