import {
    TOGGLE_IS_FETCHING_DAILY_REPORTS,
    GET_ALL_DAILY_REPORTS,
    GET_EACH_DAILY_REPORT,
    CLEAR_DAILY_REPORT_FORM,
    CLEAR_DAILY_REPORT,
    GET_COL_ATTRIBUTE_MAPPING,
    GET_PRODUCT_ATTRIBUTES,
    FETCH_UNTESTED_PRODUCT,
    REFRESH_DAILY_REPORT,
    LAST_DAILY_REPORT_FILTER
} from "./types";

import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { aepReport } from '../apis';

export const toggleIsFetchingDailyReports = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DAILY_REPORTS,
        payload: flag
    }
}

export const getAllDailyReport = (startTime, endTime) => async (dispatch) => {
    const contextPath = `/reportSearchService/dailyTestReport`;
    const response = await aepReport.get(contextPath, {
        params: {
            startTime,
            endTime
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: GET_ALL_DAILY_REPORTS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Reports');
    dispatch(toggleIsFetchingDailyReports(false));
}

export const clearReportForm = () => {
    return { type: CLEAR_DAILY_REPORT_FORM }
}

export const clearDailyReport = () => {
    return {
        type: CLEAR_DAILY_REPORT,
    }
}

export const deleteDailyReport = (id) => async (dispatch) => {
    dispatch(toggleIsFetchingDailyReports(true));
    const contextPath = `/reportSearchService/dailyTestReport/${id}`
    const response = await aepReport.delete(contextPath);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Report deleted successfully`);
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to delete Report");
    }
    dispatch(toggleIsFetchingDailyReports(false));
}

export const getColAttributeMapping = () => async (dispatch) => {
    const contextPath = `/reportSearchService/mappingColumnNames`;
    const response = await aepReport.get(contextPath);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: GET_COL_ATTRIBUTE_MAPPING, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Reports');
    dispatch(toggleIsFetchingDailyReports(false));
}

export const getProductAttributes = (id) => async (dispatch) => {
    const contextPath = `/reportSearchService/productAttributes/${id}`;
    const response = await aepReport.get(contextPath);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: GET_PRODUCT_ATTRIBUTES, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Product Attributes');
    dispatch(toggleIsFetchingDailyReports(false));
}

export const postDailyReport = (id = 0, data) => async (dispatch) => {
    const response = await aepReport({
        url: id > 0 ? `reportSearchService/dailyTestReport/${id}` : `reportSearchService/dailyTestReport`,
        method: id > 0 ? "PUT" : "POST",
        data
    });
    if (response.status === CONFIG.HTTP_STATUS.CREATED || response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Report ${id === 0 ? "created" : "updated"} successfully`)
        return true;
    } else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, `Report For this Product Already Exist`);
    } else if (response.status === CONFIG.HTTP_STATUS.NOT_ACCEPTABLE) {
        pushMessage(CONFIG.messageType.warning, `Report can not be created, product does not have attributes`);
    }
    else {
        pushMessage(CONFIG.messageType.error, `Unable to ${id === 0 ? "create" : "update"} Report`);
        return false;
    }
}

export const getNotTestedProducts = (startTime, endTime) => async dispatch => {
    const response = await aepReport.get("/reportSearchService/notTestedProduct", {
        params: {
            startTime,
            endTime
        }
    })

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_UNTESTED_PRODUCT,
            payload: response?.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, `Unable to fetch not tested product`);
}

export const getDailyReportById = (Id) => async (dispatch) => {
    const contextPath = `/reportSearchService/dailyTestReport/${Id}`;
    const response = await aepReport.get(contextPath);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: GET_EACH_DAILY_REPORT, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Report');
    dispatch(toggleIsFetchingDailyReports(false));
}

export const refreshDailyReport = () => {
    return {
        type: REFRESH_DAILY_REPORT,
    }
}

export const currentDailyReportFilter = (date, sortInfo, timeZone) => {
    return {
        type: LAST_DAILY_REPORT_FILTER,
        payload: [date, sortInfo, timeZone]
    }
}