export const getDataSetForExport = (records) => {
    if (records?.data !== null) {
        const demoType = records?.data?.length > 0 && records?.data[0].hasOwnProperty('demoCount') ? "demoCount" : "demoHours"
        const countType = demoType === "demoCount" ? "scheduleCount" : "hoursScheduled";
        const dataSet = records?.data?.map((item) => {
            return {
                "subject": item.subject,
                [demoType]: {
                    ...item[demoType]?.reduce((acc, item) => {
                        acc[item.month] = {
                            [countType]: item[countType]
                        }
                        return acc
                    }, {})
                }

            }
        })
        return dataSet !== undefined ? dataSet : [];
    }
    else return null
}

export const getDataSetForTrendingExport = (records) => {
    if (records?.data !== null) {
        const rows = records?.data?.map((item, index) => {
            return {
                "subject": item?.demoName,
                "demoCount": {
                    ...item["count"]?.reduce((acc, item) => {
                        acc[item.column] = {
                            "scheduleCount": item["scheduleCount"]
                        }
                        return acc
                    }, {})
                }
            }
        })
        return rows || [];
    }
    else return null
}


export const getColumnsForExport = (records) => {
    const demoType = records?.data?.length > 0 && records?.data[0].hasOwnProperty('demoCount') ? "demoCount" : "demoHours";
    const countType = demoType === "demoCount" ? "scheduleCount" : "hoursScheduled";
    const hardCodingSubject = {
        "id": 1,
        "columnName": " ",
        "columnKey": "subject"
    }
    let dataSet = records?.columns?.map((item, key) => {
        return {
            "id": key + 2,
            "columnName": item,
            "columnKey": `${demoType}.${item}.${countType}`
        }
    })
    return dataSet && [hardCodingSubject, ...dataSet]
}

export const getColumnsForTrendingExport = (records) => {
    const hardCodingSubject = {
        "id": 1,
        "columnName": " ",
        "columnKey": "subject"
    }
    let dataSet = records?.columns?.map((item, key) => {
        return {
            "id": key + 2,
            "columnName": item,
            "columnKey": `${"demoCount"}.${item}.${"scheduleCount"}`
        }
    })
    return dataSet && [hardCodingSubject, ...dataSet]
}

export const getTrendingTableData = (tableData) => {
    const rows = tableData?.map((item, index) => {
        return {
            id: index, type: item?.demoName, ...item["count"]?.reduce((acc, item) => {
                acc[item.column] = item["scheduleCount"];
                return acc
            }, {})
        }
    })
    return rows || [];
}

export const getTrendingColumns = (columnData) => {
    const columns = columnData?.map((item) => {
        return {
            key: item,
            dataIndex: item,
            title: item,
            align: 'center',
            width: 125
        };
    })
    return columns ? [{ key: "type", dataIndex: "type", title: " ", align: 'left', width: 400 }, ...columns] : [];

}
