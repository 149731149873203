import React from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux'

import { selectTitle, toggleBackButton } from "../actions";
import aboutUsImage_4 from "../assets/images/aboutUsImage_4.jpg"

class AboutUs extends React.Component {

    componentDidMount() {
        this.props.selectTitle("About Us");
        this.props.toggleBackButton(false);
    }
    render() {
        return (
            <React.Fragment>
                <div className="center-align">
                    <h2 className="header">About Us</h2>
                </div>
                <Card>
                    <img className="img-responsive" alt="Carousel" src={aboutUsImage_4} />
                    <br />
                    <br />
                    <p className="paragraph">Experience Avaya provides a suite of solution demos built upon a flexible platform, all geared toward delivering world-class demo experiences to our Customers. Our demo catalog consists of both solution demos, like Avaya IX™ Digital, as well as vertically oriented demos for Healthcare, Hospitality, Government, Education, Financial Services, Public Safety, and Sports & Entertainment. We are globally focused with presence in Asia, Europe, and the United States, and operate with a fundamental tenet: demos should be available anywhere at any time and should support a flexible experience to help Customers envision how Avaya solutions can help drive desired outcomes.To begin your experience, please login, or click one of the links below. </p>
                </Card>
            </React.Fragment>
        );
    }
}

export default connect(
    null,
    { selectTitle, toggleBackButton }
)(AboutUs);
