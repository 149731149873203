import React from 'react';
import { connect } from 'react-redux';

import {
    fetchSolutionTypes,
    toggleIsFetchingSolutionTypes,
    updateRoleTemplateSolutionTypes
} from '../../actions';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';

class RoleTemplatesSolutionTypes extends React.Component {

    state = {
        selectAllDisabled: false
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    columnFilters = {
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "title"),
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        onFilter: (value, record) =>
            record.name
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    }

    handleFilter = (name, confirm) => {
        name = name?.trim();
        if (!name && !this.state.selectAllDisabled) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else {
            this.setState({ selectAllDisabled: !!name })
            confirm()
        }
    }

    handleClear = (clearFilters) => {
        this.setState({ selectAllDisabled: false })
        clearFilters();
    }

    componentDidMount() {
        const { userRoleTemplate } = this.props;
        const access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
        this.props.toggleIsFetchingSolutionTypes(true);
        this.props.fetchSolutionTypes(access);
    }

    render() {
        const { assignedSolutionTypes, isFetching, allSolutionTypesUserAccess, allSolutionTypesAdminAccess, templateType, updateRoleTemplateSolutionTypes, solutionTypes } = this.props;
        const { selectAllDisabled } = this.state
        return (
            <RoleTemplatesTable
                dataKey="solutionType"
                dataIndex="name"
                hideColumns={templateType === CONFIG.roleTypes.admin ? [] : ['admin']}
                marginTop={true}
                bordered={true}
                data={solutionTypes}
                selectAllDisabled={selectAllDisabled}
                columnFilters={this.columnFilters}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1
                }}
                isFetching={isFetching}
                assignedData={assignedSolutionTypes}
                allAdminAccess={allSolutionTypesAdminAccess}
                allUserAccess={allSolutionTypesUserAccess}
                updateRoleTemplateData={updateRoleTemplateSolutionTypes}
            />
        );
    }
}

const mapStateToProps = ({ roleTemplate, user, solutionTypes }) => {
    return {
        solutionTypes: solutionTypes.types,
        isFetching: solutionTypes.isFetching,
        assignedSolutionTypes: roleTemplate.solutionTypes,
        allSolutionTypesUserAccess: roleTemplate.allSolutionTypesUserAccess,
        allSolutionTypesAdminAccess: roleTemplate.allSolutionTypesAdminAccess,
        templateType: roleTemplate.templateType,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined
    }
}

export default connect(
    mapStateToProps,
    {
        fetchSolutionTypes,
        toggleIsFetchingSolutionTypes,
        updateRoleTemplateSolutionTypes
    }
)(RoleTemplatesSolutionTypes);