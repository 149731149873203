import React from 'react';
import { connect } from 'react-redux';
import { } from '../../../actions';
import CONFIG from "../../../config";
import { Divider } from 'antd';
import moment from 'moment-timezone';
import { Button as NeoButton, BasicModal } from "@avaya/neo-react"
import { getInitialValue } from '../../common/Scheduler';
class LabDemoConfirmationModal extends React.Component {

    renderDetails = () => {
        const { demo, startTime, endTime, timezone, managerEmail, regionalPrimeEmail, form } = this.props
        const formValues = form.getFieldsValue();
        const selectedPurpose = JSON.parse(formValues?.demoPurpose);
        const additionalAttributes = getInitialValue(selectedPurpose, form)
        return (
            <div className='confirmation-container'>
                <div className='confirmation-banner' style={{ gap: "8px" }}>
                    <div>
                        <div className='date-banner-left'>{moment(startTime).tz(timezone).format(CONFIG.dateFormats.schedulerDate)}</div>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4168 6.22258C19.7081 5.92809 20.1829 5.92549 20.4774 6.21678L29.6774 15.3168C29.8193 15.4572 29.8995 15.6483 29.9 15.8479C29.9005 16.0476 29.8215 16.2392 29.6803 16.3803L20.4803 25.5803C20.1874 25.8732 19.7126 25.8732 19.4197 25.5803C19.1268 25.2874 19.1268 24.8126 19.4197 24.5197L27.3393 16.6H3.75C3.33579 16.6 3 16.2642 3 15.85C3 15.4358 3.33579 15.1 3.75 15.1H27.3253L19.4226 7.28322C19.1281 6.99193 19.1255 6.51707 19.4168 6.22258Z" fill="black" />
                        </svg>
                    </div>
                    <div>
                        <div className='date-banner-left'>{moment(endTime).tz(timezone).format(CONFIG.dateFormats.schedulerDate)}</div>
                    </div>
                </div>
                <Divider style={{ paddingTop: "1px", color: '#c9c9c9', margin: "0px" }} />
                <div >
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Demo Name:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {demo.title}
                    </div>
                </div>
                <div >
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Expiry Date:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {moment(endTime).tz(timezone).format(CONFIG.dateFormats.schedulerDate)}
                    </div>
                </div>
                <div >
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Buffer Time:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {`${demo?.startTimeBuffer / 60} Hour(s)`}
                    </div>
                </div>
                <div >
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Demo Duration:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {`${demo?.scheduleRange} Day(s)`}
                    </div>
                </div>

                {managerEmail && <div >
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Manager approval:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {managerEmail}
                    </div>
                </div>}

                {regionalPrimeEmail && <div >
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Regional Prime approval:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {regionalPrimeEmail}
                    </div>
                </div>}
                <div>
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Purpose:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {selectedPurpose ? selectedPurpose?.purpose : "-"}
                    </div>
                </div>
                {
                    additionalAttributes?.length > 0 ?
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {additionalAttributes.map((item) => item?.isVisible && (
                                    <div key={item.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: '8px' }}>
                                        <div style={{ fontSize: '12px', fontWeight: '400', lineHeight: '16px', color: '#242424' }}>
                                            {item.attributeLabel}:
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '600', lineHeight: '20px', color: '#242424' }}>
                                            {(item.value && item?.value?.length > 0) ? item?.value : "N/A"}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }

    render() {
        const { isReschedule, currentSchedule, approvalRequired, demo } = this.props;
        const modalTitle = <span className='modal-title'>{`${currentSchedule ? isReschedule ? "Create" : "Update" : "Create"} Schedule`}</span>
        const okButtonText = demo.demoType === CONFIG.demoTypes.restfulApiDemo
            ? ((currentSchedule && !isReschedule) ? 'Update' : 'Schedule')
            : ((currentSchedule && !isReschedule) ? `Update ${approvalRequired ? "Request" : "Schedule"}` : approvalRequired ? 'Request Approval' : "Schedule")
        return (
            <>
                <BasicModal open={this.props.isModalVisible}
                    onClose={() => this.props.handleModalClose()}
                    closeButtonLabel={"Cancel"}
                    title={modalTitle}
                    className="neo-modal confirmation-modal-neo"
                    actions={[
                        <NeoButton key="Confirm"
                            id="schedule-confirm"
                            onClick={() => this.props.handleSchedule(approvalRequired)}
                        >
                            {okButtonText}
                        </NeoButton>]}>
                    {this.renderDetails()}
                </BasicModal>
            </>
        );
    }
}

const mapStateToProps = ({ scheduler, user, newSchedule }) => {
    return {
        timezone: scheduler.selectedTimezone,
        currentSchedule: newSchedule.currentSchedule,
    };
}

export default connect(
    mapStateToProps,
    {

    }
)(LabDemoConfirmationModal);