import { Modal } from 'antd';
import CONFIG from '../../config';
const { confirm } = Modal;

const openConfirm = (okText, onOk, onCancel, content, title, className, cancelText) => {
    confirm({
        title: title ? title : CONFIG.warningMessages.title,
        content,
        okText,
        onOk,
        onCancel,
        cancelText,
        open: true,
        className,
        cancelButtonProps: { id: 'pop-up-cancel' },
        okButtonProps: { id: 'pop-up-confirm' },
    });
};

export default openConfirm;