import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table, Divider, Popconfirm, Spin, Form, Typography } from 'antd';
import { isTabAlreadyOpen } from "../common/TabLayout";
import {
    toggleIsFetchingDeletedNetworks,
    clearNetworkData,
    fetchDeletedNetworksByDcId,
    deleteNetworkAddress,
    restoreNetworkAddress,
    currentNetworksFilter
} from '../../actions';
import DataCenters from '../common/DataCenters';
import IPAddressForm from './IPAddressForm';
import CONFIG from '../../config';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';
import { camelToTitleCase } from '../../utils/strings'
import scrollIntoView from 'scroll-into-view';


const { Text } = Typography;

const dataIndexMap = {
    networkAddress: {
        filter: 'networkAddress',
        sort: 'getNetworkAddress'
    },
    gateway: {
        filter: 'gateway',
        sort: 'getGateway'
    },
    subnetMask: {
        filter: 'subnetMask',
        sort: 'getSubnetMask'
    },

    description: {
        filter: 'description',
        sort: 'getDescription'
    }
};

const DeletedNetwork = Form.create({ name: 'my_profile' })(
    class DeletedNetwork extends React.Component {


        state = {
            isEditing: false,
            selectedDataCenter: undefined,

            previouslySelectedNetwork: undefined,
            showTable: false,
            isReturnedFromDelete: false,
            isReturnedFromEdit: false,
            editedRecordIndex: undefined,
            deletedRecordIndex: undefined
        }

        currentPage = 1;
        filterSort = {}
        selectedNetwork = undefined
        editIndex = undefined
        deleteIndex = undefined
        flag = 0;


        handleFilter = (dataIndex, confirm) => {
            dataIndex = dataIndex?.trim();
            if (!dataIndex) {
                pushMessage(CONFIG.messageType.warning, "Please enter a search string")
                return;
            }
            else confirm()
        };

        handleClear = clearFilters => {
            clearFilters();
        };

        getFilterDropDown = (filterProps, dataIndex) => {
            const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
            return (
                <SearchFilter
                    dataIndex={dataIndex}
                    searchString={selectedKeys[0]}
                    setSearchString={setSelectedKeys}
                    confirm={confirm}
                    handleClear={this.handleClear}
                    handleSearch={this.handleFilter}
                    clearFilters={clearFilters}
                />
            )
        }

        getColumnSearchProps = dataIndex => (
            {
                filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, camelToTitleCase(dataIndex)),
                sorter: (a, b) => a[dataIndex] === null ? -1 : b[dataIndex] === null ? 1 : a[dataIndex]?.toLowerCase().localeCompare(b[dataIndex]?.toLowerCase())
            }
        )

        handleRestore = async (network, index) => {
            this.props.toggleIsFetchingDeletedNetworks(true);
            this.network = network;
            this.deleteIndex = index
            const response = await this.props.restoreNetworkAddress(this.state.selectedDataCenter, this.network, false, this.filterSort);
            if (response) {
                this.scrollToView = true
                await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: this.deleteIndex })
            }
        }

        handleDelete = (network, index) => {
            this.network = network;
            this.deleteIndex = index
            const okButtonText = "Confirm Permanent Deletion"
            const content = <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Network")}</h4>
            const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'Network')
            openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
        }


        handleDeleteConfirmation = async () => {
            this.props.toggleIsFetchingDeletedNetworks(true);
            const response = await this.props.deleteNetworkAddress(this.state.selectedDataCenter, this.network, true, this.filterSort);
            if (response) {
                this.scrollToView = true
                await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: this.deleteIndex })
            }
        }

        handleDataCenterChange = async (selectedDataCenter) => {
            selectedDataCenter = JSON.parse(selectedDataCenter)
            this.setState({ showTable: true })
            this.filterSort = {};
            this.setState({ selectedDataCenter })
            this.props.clearNetworkData();
            this.props.fetchDeletedNetworksByDcId(selectedDataCenter?.id)
        }

        handleSortChange = async (pagination, filters, sorter) => {
            let { networkAddress, gateway, subnetMask, description } = filters;
            this.filterSort = {
                filter: {
                    [dataIndexMap.networkAddress.filter]: networkAddress?.[0]?.trim(),
                    [dataIndexMap.gateway.filter]: gateway?.[0]?.trim(),
                    [dataIndexMap.subnetMask.filter]: subnetMask?.[0]?.trim(),
                    [dataIndexMap.description.filter]: description?.[0]?.trim(),

                },
                sort: sorter.columnKey
                    ? {
                        [dataIndexMap[sorter.columnKey].sort]: CONFIG.sortMap[sorter.order],
                    }
                    : undefined
            }
            this.handleFetchNetworks();
            this.setState({ showTable: true })
        }

        handleFetchNetworks = async () => {
            await this.props.fetchDeletedNetworksByDcId(this.state.selectedDataCenter?.id, this.filterSort)
        }


        async componentDidUpdate() {
            const networkToScrollIntoView = this.state.isReturnedFromDelete && Math.abs(this.state.deletedRecordIndex - 1);
            (this.state.isReturnedFromDelete)
                && networkToScrollIntoView
                && this.scrollToView
                && scrollIntoView(document.querySelector(`.scroll-row-${networkToScrollIntoView}`), {
                    align: { top: 0, left: 0 },
                    behavior: "smooth"
                },
                    await this.setState({ isReturnedFromDelete: false }),
                    this.deleteIndex = undefined,
                    this.scrollToView = false
                )
        }

        componentWillUnmount() {
            if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
                this.props.currentNetworksFilter(this.state.selectedDataCenter)
            }
            else {
                this.props.clearNetworkData();
            }
        }

        componentDidMount() {
            //document.getElementById('container').scrollIntoView();
            const { currentSelectedDataCenter, isEdit } = this.props;
            this.setState({ loading: true })
            this.setState({ currentPath: window.location.pathname })
            if (isEdit) {
                this.setState({ selectedDataCenter: currentSelectedDataCenter })
                this.setState({ showTable: true })
                this.filterSort = {};
                // this.props.clearNetworkData();
                this.props.fetchDeletedNetworksByDcId(currentSelectedDataCenter?.id)
                // this.handleNetworkChange(currentSelectedNetwork, true)
            }

        }



        render() {
            const { isFetching } = this.props;
            // const selectedNetworkDropdown = isEdit ? currentSelectedNetwork : selectedNetwork;
            this.columns = [
                {
                    title: 'No.',
                    key: 'index',
                    align: 'center',
                    width: '9%',
                    render: (text, record, index) => index + 1,
                },
                {
                    title: "Network Address",
                    dataIndex: "networkAddress",
                    width: '20%',
                    ...this.getColumnSearchProps('networkAddress'),
                },
                {
                    title: "Gateway",
                    dataIndex: "gateway",
                    width: '20%',
                    ...this.getColumnSearchProps('gateway'),
                },
                {
                    title: "Subnet Mask",
                    dataIndex: "subnetMask",
                    width: '15%',
                    ...this.getColumnSearchProps('subnetMask'),
                },
                {
                    title: "Description",
                    dataIndex: "description",
                    width: '25%',
                    ...this.getColumnSearchProps('description'),
                },

                {
                    title: 'Actions',
                    key: 'actions',
                    width: '25%',
                    render: (text, record, index) => {
                        return (
                            <span>
                                <button disabled={record.id === 0} className={record.id === 0 ? 'link-disabled' : 'link'} onClick={() => this.handleDelete(record, index + 1)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                <Divider type="vertical" />
                                <Popconfirm disabled={record.id === 0} title="Confirm restore?" onConfirm={() => this.handleRestore(record, index + 1)}>
                                    <button disabled={record.id === 0} className={record.id === 0 ? "link-disabled" : "link"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                </Popconfirm>
                            </span >
                        )
                    },
                    align: 'center'
                }
            ];

            const { showTable } = this.state;
            const { deletedNetworksById } = this.props;
            // const { selectedDataCenter } = this.state;
            return (
                <Form>
                    <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                        <Row gutter={24}>
                            <IPAddressForm currentPage={this.currentPage} />
                            <Col xl={6} sm={12} xs={24} className="vertical-spacing">
                                <DataCenters fromDids={true} form={this.props.form} dataCenter={this.state.selectedDataCenter} handleChange={this.handleDataCenterChange} />
                            </Col>
                        </Row>

                        {showTable &&
                            <Table
                                size='middle'
                                rowClassName={(record, index) => (`scroll-row-${index + 1}`)}
                                className="responsive-container network-table"
                                rowKey={(record) => record.id}
                                loading={isFetching}
                                columns={this.columns}
                                dataSource={deletedNetworksById}
                                bordered
                                onChange={this.handleSortChange}
                                pagination={false}
                                scroll={{ x: 900 }}
                                footer={() => <>{deletedNetworksById?.length !== 0 && <div style={{ textAlign: 'center' }}>
                                    <Text strong>Fetched {deletedNetworksById?.length} out of {deletedNetworksById?.length} Network Addresses</Text>
                                </div>}
                                </>}
                            />
                        }
                    </Spin>
                </Form >
            )
        }
    }
);

const mapStateToProps = ({ networks, tabsLayout }) => {
    return {
        networks: networks.data,
        isFetching: networks.isFetchingDeletedNetworks,
        deletedNetworks: networks.deletedNetworks,
        deletedNetworksById: networks.deletedNetworksById,
        openedTabs: tabsLayout.panes,
        isEdit: networks.isEdit,
        currentSelectedDataCenter: networks.currentSelectedDataCenter,
    };
};

export default connect(
    mapStateToProps,
    {
        toggleIsFetchingDeletedNetworks,
        clearNetworkData,
        fetchDeletedNetworksByDcId,
        deleteNetworkAddress,
        restoreNetworkAddress,
        currentNetworksFilter
    }
)(DeletedNetwork);