import { batch } from 'react-redux';

import { aepNetwork } from "../apis";
import CONFIG from "../config"
import pushMessage from "../components/common/PushMessage"
import {
    ADD_DATA_CENTER,
    FETCH_DATA_CENTERS,
    UPDATE_DATA_CENTER,
    REMOVE_EMPTY_DATA_CENTER,
    TOGGLE_IS_FETCHING_DATA_CENTERS,
    UPDATE_DATA_CENTER_EDITING_KEY,
    CLEAR_DATA_CENTERS,
    TOGGLE_SHOULD_UPDATE_FILTERED_DATA_FLAG,
    REFRESH_DATA_CENTERS,
    TOGGLE_HAS_DATA_CENTERS_UPDATED,
    LAST_DATA_CENTERS_FILTER
} from './types';

export const addDataCenter = dataCenterId => {
    const dataCenter = {
        id: dataCenterId,
        title: ""
    };

    return {
        type: ADD_DATA_CENTER,
        payload: dataCenter
    };
};

export const fetchDataCenters = (viewDeletedItems = false) => async dispatch => {
    const response = await aepNetwork.get("/networkManagementService/dataCenters", { params: { viewDeletedItems } });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_DATA_CENTERS, payload: response.data || [] });
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch data centers');
    }
    dispatch(toggleIsFetchingDataCentersFlag(false));
};

export const updateDataCenter = (dataCenter) => {
    return {
        type: UPDATE_DATA_CENTER,
        payload: dataCenter
    };
};

export const toggleShouldUpdateFilteredDataFlag = (flag) => {
    return {
        type: TOGGLE_SHOULD_UPDATE_FILTERED_DATA_FLAG,
        payload: flag
    };
}

export const removeEmptyDataCenter = () => async dispatch => {
    batch(() => {
        dispatch({
            type: REMOVE_EMPTY_DATA_CENTER
        });
        dispatch(updateDataCenterEditingKey())
    });
};

export const toggleIsFetchingDataCentersFlag = flag => {
    return {
        type: TOGGLE_IS_FETCHING_DATA_CENTERS,
        payload: flag
    }
}

export const postNewDataCenter = (dataCenter) => async dispatch => {
    const response = await aepNetwork({
        url: '/networkManagementService/dataCenters',
        method: 'post',
        data: dataCenter,
    });
    batch(async () => {
        await dispatch(updateDataCenterEditingKey())
        await dispatch(toggleIsFetchingDataCentersFlag(true))
        await dispatch(fetchDataCenters())
    })
    if (response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, 'Data center created successfully')
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to add data center');

};

export const putDataCenter = (dataCenter) => async dispatch => {

    const response = await aepNetwork({
        url: '/networkManagementService/dataCenters/' + dataCenter.id,
        method: 'put',
        data: dataCenter,
    });

    batch(async () => {
        if (response.status === CONFIG.HTTP_STATUS.OK) {
            pushMessage(CONFIG.messageType.success, 'Data center updated successfully')
            await dispatch(clearDataCenters());
            await dispatch(toggleIsFetchingDataCentersFlag(true))
            await dispatch(fetchDataCenters());
        }
        else pushMessage(CONFIG.messageType.error, 'Unable to update data center');
        dispatch(updateDataCenterEditingKey());
    })
};

export const updateDataCenterEditingKey = (key = "") => {
    return {
        type: UPDATE_DATA_CENTER_EDITING_KEY,
        payload: key
    };
};

export const clearDataCenters = () => {
    return { type: CLEAR_DATA_CENTERS };
}

export const deleteDataCenter = (dataCentersId, hardDelete = false) => async dispatch => {
    const response = await aepNetwork.delete("/networkManagementService/dataCenters/" + dataCentersId, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Data center deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete data center");
    batch(() => {
        hardDelete && dispatch(toggleShouldUpdateFilteredDataFlag(true));
        dispatch(fetchDataCenters(hardDelete));
    })
    return response.status;
};

export const restoreDataCenter = (dataCenterId) => async dispatch => {
    const response = await aepNetwork.put("/networkManagementService/restore/dataCenters/" + dataCenterId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Data Center restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore data center");
    batch(() => {
        dispatch(toggleShouldUpdateFilteredDataFlag(true));
        dispatch(fetchDataCenters(true));
    })
}

export const checkDataCenterDependencies = async (dataCenterId, viewDeletedItems = false) => {
    const response = await aepNetwork.get(`/networkManagementService/dataCenters/${dataCenterId}/dependencies`, { params: { viewDeletedItems } })
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
    else throw Error("Unable to get data center dependency list");
};

export const refreshDataCenters = () => {
    return {
        type: REFRESH_DATA_CENTERS
    }
}

export const toggleHasDataCentersUpdated = flag => {
    return {
        type: TOGGLE_HAS_DATA_CENTERS_UPDATED,
        payload: flag
    }
}

export const currentDataCentersFilter = (filters, status, currentPage) => {
    return {
        type: LAST_DATA_CENTERS_FILTER,
        payload: { filters, status, currentPage }
    }
}