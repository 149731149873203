import {
    ADD_SCHEDULER_EVENTS,
    FETCH_ACTIVE_DEMO_RESOURCES,
    UPDATE_SCHEDULER_TIMEZONE,
    UPDATE_SCHEDULER_START_END_TIME,
    UPDATE_SCHEDULER_NEW_EVENT,
    TOGGLE_SCHEDULER_MODAL_IS_VISIBLE,
    FETCH_DEMO_PURPOSE_TYPES,
    FETCH_USER_DEMO_PURPOSE_TYPES,
    FETCH_DEMO_PURPOSE_TYPES_LITE,
    FETCH_DEMO_VERTICAL_SOLUTIONS_LITE,
    CLEAR_DEMO_SCHEDULER,
    TOGGLE_IS_FETCHING_SCHEDULER_DATA,
    ADD_DEMO_SCHEDULES,
    UPDATE_CURRENT_DEMO_SCHEDULE,
    UPDATE_SELECTED_RESOURCE_TYPE,
    REFRESH_SCHEDULER,
    CHECK_SCHEDULE_AVAILABILITY,
    CLEAR_SCHEDULE_FORM_ERRORS,
    UPDATE_NEW_EVENTS,
    CLEAR_NEW_EVENTS,
    CLEAR_ALL_NEW_EVENTS,
    ADD_NEW_SCHEDULE_EVENT,
    DELETE_NEW_SCHEDULE_EVENT,
    DATE_CHANGED_SCHEDULER,
    FETCH_AVAILABLE_DEMO_RESOURCES,
    UPDATE_SELECTED_RESOURCE,
    CLEAR_SELECTED_RESOURCE,
    UPDATE_OVERRIDING_RESOURCE,
    FETCH_CUSTOMER_INFO
} from '../actions/types';
import CONFIG from '../config';

const schedulerState = {
    isFetchingScheduleData: false,
    shouldRefreshScheduler: false,
    shouldUpdateResourceType: true
};

export const schedulerReducer = (state = schedulerState, action) => {
    switch (action.type) {
        case FETCH_ACTIVE_DEMO_RESOURCES:
            const defaultKey = action.payload[CONFIG.resourceTypes.recommended.key].length
                ? CONFIG.resourceTypes.recommended.key
                : CONFIG.resourceTypes.all.key;
            return {
                ...state,
                resources: action.payload[defaultKey] || [],
                resourceType: defaultKey,
                ...action.payload
            };
        case UPDATE_SELECTED_RESOURCE_TYPE:
            let resourceIds = state[CONFIG.resourceTypes.recommended.key]?.reduce((acc, item) => { acc.push(item.id); return acc }, [])
            return { ...state, resources: action.key === CONFIG.resourceTypes.recommended.key ? state[action.key] : [...(state[CONFIG.resourceTypes.recommended.key]?.map(item => { return { ...item, isUserResource: true } }) || []), ...state[CONFIG.resourceTypes.all.key].filter(item => !resourceIds.includes(item.id))], resourceType: action.key, shouldUpdateResourceType: false }
        case ADD_SCHEDULER_EVENTS:
            return { ...state, events: action.payload };
        case ADD_DEMO_SCHEDULES:
            return { ...state, schedules: action.payload || [] };
        case UPDATE_SCHEDULER_TIMEZONE:
            return { ...state, selectedTimezone: action.payload };
        case UPDATE_SCHEDULER_START_END_TIME:
            return { ...state, startTime: action.payload.startTime, endTime: action.payload.endTime };
        case TOGGLE_IS_FETCHING_SCHEDULER_DATA:
            return { ...state, isFetchingScheduleData: action.payload };
        case REFRESH_SCHEDULER:
            return { ...state, shouldRefreshScheduler: action.payload };
        case CLEAR_DEMO_SCHEDULER:
            return { ...schedulerState, selectedTimezone: state.selectedTimezone };
        case FETCH_AVAILABLE_DEMO_RESOURCES:
            const resourceTypeKey = action.payload[CONFIG.resourceTypes.recommended.key]?.length > 0
                ? CONFIG.resourceTypes.recommended.key
                : CONFIG.resourceTypes.all.key;

            return {
                ...state,
                resources: action.payload[resourceTypeKey] || [],
                resourceType: resourceTypeKey,
                allDataCentersNotAvailable: action.payload["allDataCentersNotAvailable"] || false,
                ...action.payload
            };
        case UPDATE_SELECTED_RESOURCE:
            return { ...state, selectedResource: action.payload };
        case UPDATE_OVERRIDING_RESOURCE:
            const updatedResources = state.resources?.length > 0 ? state.resources?.map(item => item?.id === action.payload ? { ...item, isOverride: false } : item) : []
            return {
                ...state,
                resources: updatedResources
            }
        case CLEAR_SELECTED_RESOURCE:
            return { ...state, selectedResource: undefined };
        default:
            return state;
    };
};

const newScheduleState = {
    isModalVisible: false,
    checkAvailability: false,
    maintenance: [],
    conflictingSchedules: [],
    possibleSchedules: [],
    dateRangeWithConditions: [],
    newEvents: [],
    hasDateChanged: false,
}

export const newScheduleReducer = (state = newScheduleState, action) => {
    switch (action.type) {
        case UPDATE_SCHEDULER_NEW_EVENT:
            return { ...state, event: action.payload };
        case TOGGLE_SCHEDULER_MODAL_IS_VISIBLE:
            return {
                ...state, isModalVisible: action.payload,
                // currentSchedule: action.payload === false ? undefined : state.currentSchedule
            };
        case FETCH_DEMO_PURPOSE_TYPES:
            return { ...state, demoPurposeTypes: action.payload };
        case FETCH_CUSTOMER_INFO:
            return { ...state, customerInfo: action.payload };
        case FETCH_USER_DEMO_PURPOSE_TYPES:
            return { ...state, userPurposeTypes: action.payload };
        case FETCH_DEMO_PURPOSE_TYPES_LITE:
            return { ...state, userPurposeTypesLite: action.payload || [] }
        case FETCH_DEMO_VERTICAL_SOLUTIONS_LITE:
            return { ...state, verticals: action.payload || [] }
        case UPDATE_CURRENT_DEMO_SCHEDULE:
            return { ...state, currentSchedule: action.payload };
        case UPDATE_NEW_EVENTS:
            return { ...state, newEvents: [...state.newEvents, ...action.payload] };
        case ADD_NEW_SCHEDULE_EVENT:
            return { ...state, newEvents: [...state.newEvents, action.payload] };
        case DELETE_NEW_SCHEDULE_EVENT:
            return { ...state, newEvents: state.newEvents.filter((item) => item?.scheduleId !== action.payload?.original?.scheduleId) };
        case CLEAR_NEW_EVENTS:
            return { ...state, newEvents: [] };
        case CHECK_SCHEDULE_AVAILABILITY:
            return { ...state, ...action.payload }
        case DATE_CHANGED_SCHEDULER:
            return { ...state, hasDateChanged: action.payload }
        case CLEAR_SCHEDULE_FORM_ERRORS:
            return { ...state, checkAvailability: false, maintenance: [], conflictingSchedules: [], possibleSchedules: [], dateRangeWithConditions: [] }
        case CLEAR_ALL_NEW_EVENTS:
            return newScheduleState
        default:
            return state;
    }
};
