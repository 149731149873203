import {
    FETCH_MY_REQUESTS,
    TOGGLE_IS_FETCHING_MY_REQUESTS,
    CLEAR_MY_REQUESTS,
    LAST_MY_REQUESTS_FILTER
} from '../actions/types';

const myRequestsState = {
    data: [],
    isFetching: false,
}

export const myRequestsReducer = (state = myRequestsState, action) => {
    switch (action.type) {
        case FETCH_MY_REQUESTS:
            return { ...state, data: action.payload?.dataSet || [], count: action.payload?.count };
        case CLEAR_MY_REQUESTS:
            return myRequestsState;
        case LAST_MY_REQUESTS_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], pageNumber: action.payload[2], pageSize: action.payload[3], isEdit: true };
        case TOGGLE_IS_FETCHING_MY_REQUESTS:
            return { ...state, isFetching: action.payload };
        default:
            return state;
    }
}   