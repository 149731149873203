import React from 'react';
import { connect, batch } from 'react-redux';
import { Form, Input, Row, Col, Button, Spin, Modal, Switch, Tooltip, InputNumber, Icon, Table, Popconfirm, Select } from 'antd';
import {
    toggleBackButton,
    fetchResourceDetails,
    clearResourceForm,
    postResource,
    updateResourceRoleTemplate,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    saveResourceRoleTemplates,
    cancelResourceRoleTemplates,
    fetchDataCenters,
    currentResourceFilter,
    toggleIsFetchingResourcesFlag,
    fetchDemos,
    addResourceDemo,
    deleteResourceDemo,
    getLDAPUserGroups,
    updateResourceFormTabData,
    deleteTab,
    updateTabsLocalStorage,
    hasSaveChanges,
    toggleHasUnsavedChangesFlag,
    resetCreateResource
} from '../../actions';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import FormActionButtons from '../common/FormActionButtons';
import CONFIG from '../../config';
import history from '../../history';
import DataCenters from '../common/DataCenters';
import { checkResourceDependencies } from "../../actions/resources";
import openInfo from '../common/Info';
import pushMessage from '../common/PushMessage';
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
import { checkRoleTemplateChanges } from "../../utils/status";

const { Option } = Select;

let id = 1;


class ResourceForm extends React.Component {

    constructor(props) {
        super(props);
        this.isEditMode = this.props.match.params?.resourceId !== undefined;
        this.state = {
            visible: false,
            modalOpen: false,
            isTimeLimitEnabled: false,
            isClone: false,
        }
    }

    handleSave = () => {
        const { form } = this.props;
        const { resourceId } = this.props.match.params;
        const isCloneResource = window.location.pathname.split('/').slice(-2)[0] === "clone"
        form.validateFields((err, values) => {
            if (!err) {
                this.props.toggleIsFetchingResourcesFlag(true)
                this.props.postResource(values, (resourceId || "-1"), isCloneResource)
            } else {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return;
            }
        });
    }

    componentWillUnmount() {
        const resourceId = this.props.match.params?.resourceId || "-1";
        const currentUserData = this.props.tabData?.find(item => (parseInt(item?.id) === parseInt(resourceId) && item.isClone === this.state.isClone)) || []
        const { data, originalData } = currentUserData || [];
        // Checking if the tab is still in the openTab list. If yes, then update store with form data.
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
            this.props.match.params?.resourceId === undefined && this.props.updateResourceFormTabData({ resourceId: this.props.match.params?.resourceId !== undefined ? this.props.match.params?.resourceId : "-1", resourceDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined, demoResources: data?.demoResources, originalData: originalData }, isClone: this.state.isClone });
            if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
                this.props.updateResourceFormTabData({
                    resourceId: this.props.match.params?.resourceId !== undefined
                        ? this.props.match.params?.resourceId
                        : "-1",
                    resourceDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined, demoResources: data?.demoResources, originalData: originalData },
                    isClone: this.state.isClone
                })
            }
        }
        else {
            this.props.resetCreateResource(resourceId, this.state.isClone);
        }
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
    }

    componentDidUpdate() {
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, true);
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
    }

    componentDidMount() {
        const isCloneResource = window.location.pathname.split('/').slice(-2)[0] === "clone";
        this.setState({ isClone: isCloneResource })
        const { resourceId } = this.props.match.params;
        const currentPath = window.location.pathname
        const { previousTabs } = this.props;
        const { userRoleTemplate } = this.props;
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;
        const access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
        this.props.fetchDemos({ access, getAll: true, demoType: CONFIG.demoTypes.standardDemo })
        this.props.getLDAPUserGroups()
        this.props.fetchDataCenters();
        fetchAllRoleTemplates();
        // Checking if the current exact tab is the openTabs list. 
        if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
            if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                batch(() => {
                    // Checking if the tab is a edit form.
                    if (resourceId) {
                        this.props.toggleIsFetchingResourcesFlag(true);
                        if (resourceId) {
                            this.props.fetchResourceDetails(resourceId, isCloneResource)
                                .then((resourceDetails) => {
                                    this.setState({ isTimeLimitEnabled: (resourceDetails?.schTimeLimit !== 0 || undefined) })
                                })
                        }
                    }
                    this.props.updateTabsLocalStorage(currentPath);
                    this.props.updateResourceFormTabData({
                        resourceId: this.props.match.params?.resourceId !== undefined
                            ? this.props.match.params?.resourceId
                            : "-1",
                        resourceDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined, demoResources: [] },
                        isClone: isCloneResource
                    })
                })
            } else {
                const currentUserData = this.props.tabData?.find(item => (parseInt(item?.id) === parseInt(resourceId) && item.isClone === this.state.isClone))?.data || []
                this.setState({ isTimeLimitEnabled: (currentUserData?.schTimeLimit !== 0 || undefined) })
            }
        }

        else {
            // Current exact tab is not in the openTabs list. Hence fetching from API
            batch(() => {
                if (resourceId) {
                    this.props.toggleIsFetchingResourcesFlag(true);
                    if (resourceId) {
                        this.props.fetchResourceDetails(resourceId, isCloneResource)
                            .then((resourceDetails) => {
                                this.setState({ isTimeLimitEnabled: (resourceDetails?.schTimeLimit !== 0 || undefined) })
                            })
                    }
                }
                else {
                    this.props.updateResourceFormTabData({
                        resourceId: this.props.match.params?.resourceId !== undefined
                            ? this.props.match.params?.resourceId
                            : "-1",
                        resourceDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined, demoResources: [] },
                        isClone: isCloneResource
                    })
                }
            })
        }
    }

    renderAssignButton = () => {
        return <Button className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleRoleTemplatesCancel = () => {
        this.props.cancelResourceRoleTemplates(this.props.match.params?.resourceId || "-1", this.state.isClone);
        this.setState({ visible: false });
    }

    handleRoleTemplatesAssign = () => {
        this.props.saveResourceRoleTemplates(this.props.match.params?.resourceId || "-1", this.state.isClone);
        this.setState({ visible: false });
    }

    handleDataCenterChange = (selectedDataCenter) => {
        const resourceId = this.props.match.params?.resourceId || "-1";
        const currentUserData = this.props.tabData?.find(item => parseInt(item?.id) === parseInt(resourceId))?.data || []
        if (currentUserData?.dataCenter && currentUserData.dataCenter?.title !== JSON.parse(selectedDataCenter).title) {
            checkResourceDependencies(resourceId, false)
                .then(dependencyList => this.renderinActiveConfirmation(dependencyList, true))
                .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
        }
        const { form } = this.props;
        selectedDataCenter = JSON.parse(selectedDataCenter);
        const resourceActive = form.getFieldValue("isActive") || currentUserData?.isActive;

        this.dataCenter = selectedDataCenter;

        if (selectedDataCenter?.active === false && resourceActive) form.setFieldsValue({ isActive: false })
    }

    getAssignedRoleTemplates = (roleTemplates, isSuperAdminUser) => {
        let assignedRoleTemplates = [];
        if (roleTemplates) {
            roleTemplates.forEach(obj => {
                const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            })
        }
        return assignedRoleTemplates;
    }

    renderAssociatedDemos = (demoResources) => {
        const isCloneResource = window.location.pathname.split('/').slice(-2)[0] === "clone"
        return (
            demoResources && demoResources.length !== 0 && !isCloneResource
                ? demoResources.map(demoResource => { return demoResource.demo.title }).join(", ")
                : 'None'
        )
    }

    handleActiveChange = (flag) => {
        const resourceId = this.props.match.params?.resourceId || "-1";
        const currentUserData = this.props.tabData?.find(item => parseInt(item?.id) === parseInt(resourceId))?.data || []
        const { isActive } = currentUserData;
        if (isActive && !flag) {
            checkResourceDependencies(resourceId, false)
                .then(dependencyList => this.renderinActiveConfirmation(dependencyList))
                .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
        }
    }

    renderinActiveConfirmation = (dependencyList, fromDataCenter) => {
        const { maintenanceCount } = dependencyList;
        const okButtonText = "Dependency Affected";
        const text = fromDataCenter ? <h4>Changing Data Center of selected resource would affect</h4> : <h4>Inactivation of selected resource would affect</h4>
        const content = maintenanceCount !== 0 ? (
            <React.Fragment>
                {text}
                {maintenanceCount} * Future Scheduled Maintenances <br />
            </React.Fragment>) : null
        maintenanceCount !== 0 && openInfo(okButtonText, content, null, "OK", true);
    }

    remove = k => {
        let { form } = this.props;
        let keys = form.getFieldValue('keys');
        if (keys.length === 1) {
            return;
        }
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    add = () => {
        let { form } = this.props;
        let keys = form.getFieldValue('keys');
        let nextKeys = keys.concat(id++);
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    renderDemoOptions = (demoResources) => {
        const { allDemos } = this.props;
        const filteredDemos = allDemos?.length > 0 && allDemos?.filter(oldArr =>
            demoResources?.every(demo => demo?.demo?.id !== oldArr?.id));
        if (filteredDemos) {
            return filteredDemos.map((demo, index) => <Option key={index} value={JSON.stringify({ id: demo?.id, title: demo?.title, isActive: demo?.isActive })} title={demo.title}>{demo.title}</Option>);
        }
        else {
            return [];
        }
    }

    handleAddDemo = () => {
        const resourceId = this.props.match.params?.resourceId || "-1";
        const { form } = this.props;
        const isCloneResource = window.location.pathname.split('/').slice(-2)[0] === "clone"
        const newDemo = form.getFieldsValue().demo;
        newDemo.forEach(async (demo) => {
            await this.props.addResourceDemo(JSON.parse(demo), resourceId, isCloneResource)
        })
        form.setFieldsValue({ demo: undefined });
    }


    renderUserGroups = userGroups => {
        if (userGroups) {
            return userGroups.map(userGroup => {
                return (
                    <Option key={userGroup.id} value={JSON.stringify(userGroup)}>
                        <div>
                            {userGroup.name}
                            {
                                <span className="float-right">
                                    <Tooltip title={userGroup.active ? "Active" : "Inactive"} key={userGroup.id}>
                                        {
                                            userGroup.active
                                                ? <Icon type="check" className="small-text success-text" />
                                                : <Icon type="warning" className="small-text warning-text" />
                                        }
                                    </Tooltip>
                                </span>
                            }
                        </div>
                    </Option>
                );
            });
        }
        return;
    };

    renderFormItems = () => {
        const resourceId = this.props.match.params?.resourceId || "-1";
        const currentUserData = this.props.tabData?.find(item => parseInt(item?.id) === parseInt(resourceId))?.data || []
        const currentUserOriginalData = this.props.tabData?.find(item => parseInt(item?.id) === parseInt(resourceId))?.originalData || []
        const { form, userGroups } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const isCloneResource = window.location.pathname.split('/').slice(-2)[0] === "clone"
        const { name } = currentUserOriginalData
        const keys = getFieldValue('keys');

        const formItems = keys?.map((k, index) => (
            <Row gutter={24}>
                <Col xl={10} sm={10} xs={24}>
                    <Form.Item
                        label={index === 0 ? 'Title' : ''}
                        required={false}
                        key={k}
                    >
                        {getFieldDecorator(`names[${(k * 2) + 1}]`, {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "Please input resource title.",
                                },
                            ],
                            initialValue: isCloneResource ? currentUserData?.names?.[(k * 2) + 1] ? currentUserData?.names?.[(k * 2) + 1] : name + '_clone_' + (k + 1) : name ? name : ""
                        })(<Input />)}
                    </Form.Item>
                </Col>
                <Col xl={10} sm={10} xs={24}>
                    <Form.Item
                        label={index === 0 ? 'User Group' : ''}
                        required={false}
                    >
                        {
                            getFieldDecorator(`names[${(k * 2) + 2}]`, {
                                initialValue: currentUserData?.names?.[(k * 2) + 2] ? currentUserData?.names?.[(k * 2) + 2] : CONFIG.demoldapGroupNone
                            })(
                                <Select showSearch={true} placeholder="User Group" style={{ width: '60%', marginRight: 12 }}>
                                    <Option key="none" value={CONFIG.demoldapGroupNone}>None</Option>
                                    {this.renderUserGroups(userGroups)}
                                </Select >
                            )
                        }
                        {keys.length > 1 ? (
                            <Icon
                                className="dynamic-delete-button"
                                type="minus-circle-o"
                                onClick={() => this.remove(k)}
                            />
                        ) : null}
                    </Form.Item>
                </Col>
            </Row>
        ));

        return formItems
    }

    renderUserGroupSelector = () => {
        const { form, userGroups } = this.props;
        const resourceId = this.props.match.params?.resourceId || "-1";
        const currentUserData = this.props.tabData?.find(item => (parseInt(item?.id) === parseInt(resourceId) && item.isClone === this.state.isClone))?.data || []
        const { ldapGroupAtom } = currentUserData || []
        const { getFieldDecorator } = form;
        return (
            <Col xl={10} sm={10} xs={24}>
                <Form.Item
                    label={'User Group'}
                    required={false}
                >
                    {
                        getFieldDecorator("ldapGroupAtom", {
                            initialValue: ldapGroupAtom ? ldapGroupAtom : CONFIG.demoldapGroupNone
                        })(
                            <Select showSearch={true} placeholder="User Group" style={{ width: '60%', marginRight: 12 }}>
                                <Option key="none" value={CONFIG.demoldapGroupNone}>None</Option>
                                {this.renderUserGroups(userGroups)}
                            </Select >
                        )
                    }
                </Form.Item>
            </Col>
        )
    }


    getCurrentData = (resDetails) => {
        const resourceId = this.props.match.params?.resourceId || "-1";
        const currentUserData = this.props.tabData?.find(item => parseInt(item?.id) === parseInt(resourceId))?.data || []
        return {
            ...resDetails,
            dataCenter: resDetails?.dataCenter !== undefined ? JSON.parse(resDetails?.dataCenter) : undefined,
            roleTemplates: currentUserData?.roleTemplates,
            schTimeLimit: resDetails?.schTimeLimit ? resDetails?.schTimeLimit * 60 : 0
        };
    }

    checkResourceFormChanged = (newData) => {
        const isSuperAdminUser = this.props.userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const resourceId = this.props.match.params?.resourceId || "-1";
        const currentUserData = this.props.tabData?.find(item => (parseInt(item?.id) === parseInt(resourceId) && item.isClone === this.state.isClone)) || []
        const { data, originalData } = currentUserData || [];
        let hasChanged = false;
        hasChanged = newData?.name?.trim() !== originalData?.name?.trim()
            || newData?.isActive !== originalData?.isActive
            || newData?.schTimeLimit !== originalData?.schTimeLimit
            || newData?.isTimeLimitEnabled !== originalData?.isTimeLimitEnabled
            || newData?.dataCenter?.id !== originalData?.dataCenter?.id
            || checkRoleTemplateChanges(originalData?.roleTemplates, newData?.roleTemplates, isSuperAdminUser)
            || newData?.ldapGroupAtom !== originalData?.ldapGroupAtom

        if (originalData?.demoResources?.length !== data?.demoResources?.length) {
            hasChanged = true;
        } else {
            const isPresent = data?.demoResources?.map(newDr => originalData?.demoResources?.some(oldDr => oldDr?.demo.id === newDr?.demo.id))
            if (isPresent?.includes(false)) {
                hasChanged = true;
            }
        }
        if (hasChanged) return hasChanged;
        return hasChanged
    }

    handleClose = async () => {
        await this.props.deleteTab(this.props.activeKey, true)
        history.replace(this.props.activeKey)
        if (this.props.openedTabs?.length === 0) {
            history.replace('/getStarted');
            // this.props.selectTitle(this.getTitle());
        }
    }

    render() {
        const { allRoleTemplates, userRoleTemplate, form, allDemos, tabData, isFetching } = this.props;
        const resourceId = this.props.match.params?.resourceId || "-1";
        //const currentUserData = tabData?.find(item => parseInt(item?.id) === parseInt(resourceId))?.data || []
        const { getFieldDecorator } = form;
        const isCloneResource = window.location.pathname.split('/').slice(-2)[0] === "clone"
        const currentUserData = tabData?.find(item => (parseInt(item?.id) === parseInt(resourceId) && item.isClone === this.state.isClone))?.data || []
        const { name, dataCenter, roleTemplates, demoResources, isActive, schTimeLimit, isTimeLimitEnabled, allRoleTemplatesUserAccess, allRoleTemplatesAdminAccess, keys } = currentUserData || [];
        const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const assignedRoleTemplates = this.getAssignedRoleTemplates(roleTemplates, isSuperAdminUser);
        const selectedDataCenter = this.dataCenter || dataCenter;
        const isActiveDisabledText = !dataCenter && !this.dataCenter ? "Data center not selected" : "Data center is inactive"
        getFieldDecorator('keys', { initialValue: keys?.length > 1 ? keys : [0] });
        const currentFormData = this.getCurrentData(this.props.form.getFieldsValue());
        this.hasChanged = resourceId !== "-1" ? this.checkResourceFormChanged(currentFormData) : true;

        const columns = [
            {
                title: 'Demo',
                key: 'title',
                width: '20%',
                align: 'left',
                render: (record) => record.demo.title
            },
            {
                title: 'Action',
                key: 'action',
                width: '15%',
                render: (record) => {
                    const hasFullAccess = allDemos && allDemos.find(demo => (demo.id === record.demo?.id) || (demo.id === record.id)) !== undefined;
                    const isDisabled = userRoleTemplate && userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin && !hasFullAccess
                    return (
                        <div>
                            <Popconfirm disabled={isDisabled} title="Confirm Delete?" okText="Yes" cancelText="No" onConfirm={() => this.props.deleteResourceDemo(resourceId, record.demo.id, this.state.isClone)}>
                                <Tooltip title={isDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                    <button disabled={isDisabled} className={!isDisabled ? "link" : "link-disabled"}>Delete</button>
                                </Tooltip>
                            </Popconfirm>
                        </div>
                    )
                },
                align: 'center'
            },
        ];

        return (
            <div className="content-container">
                <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                    <Form>
                        <Row gutter={48}>
                            {!isCloneResource ?
                                <>
                                    <Col xl={10} lg={12} sm={20} xs={24}>
                                        <Form.Item label="Title">
                                            {
                                                getFieldDecorator("name", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please enter a title"
                                                        }
                                                    ],
                                                    initialValue: name ? name : ""
                                                })(<Input />)
                                            }
                                        </Form.Item>
                                    </Col>
                                    {this.renderUserGroupSelector()}
                                </>
                                : <Col span={24}>
                                    <>
                                        {this.renderFormItems()}
                                        <Row gutter={24}>
                                            <Col xl={6} lg={12} sm={20} xs={24}>
                                                <Form.Item >
                                                    <Button type="dashed" onClick={this.add} style={{ width: 'auto' }}>
                                                        <Icon type="plus" /> Add Resource
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col xl={isCloneResource ? '14' : '10'} lg={12} sm={20} xs={24}>
                                <Form.Item label="Assign Role Template">
                                    {getFieldDecorator("roleTemplates", {
                                        initialValue: assignedRoleTemplates.length !== 0
                                            ? assignedRoleTemplates
                                            : "None"
                                    })(<Input disabled addonAfter={this.renderAssignButton()} />)}
                                    {
                                        <Modal
                                            title="Role Template"
                                            visible={this.state.visible}
                                            onCancel={this.handleRoleTemplatesCancel}
                                            footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                                        >
                                            <RoleTemplatesTable
                                                hideColumns={isSuperAdminUser ? [] : ['admin']}
                                                dataKey="roleTemplate"
                                                data={allRoleTemplates}
                                                pagination={false}
                                                assignedData={roleTemplates}
                                                allUserAccess={allRoleTemplatesUserAccess}
                                                allAdminAccess={allRoleTemplatesAdminAccess}
                                                updateRoleTemplateData={this.props.updateResourceRoleTemplate}
                                                recordId={resourceId || "-1"}
                                                isClone={this.state.isClone}
                                            />
                                        </Modal>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col xl={6} lg={12} sm={12} xs={12}>
                                <DataCenters form={form} dataCenter={dataCenter} handleChange={this.handleDataCenterChange} />
                            </Col>
                            <Col xl={5} lg={12} sm={12} xs={12} className="center-align">
                                <Form.Item label={<span>Enable time limit <Tooltip title={CONFIG.systemMessage.resourceTimeLimit}><Icon className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                                    {getFieldDecorator("isTimeLimitEnabled", {
                                        valuePropName: "checked",
                                        initialValue: isTimeLimitEnabled
                                    })(
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            onChange={(flag) => this.setState({ isTimeLimitEnabled: flag })}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {this.state.isTimeLimitEnabled && <Col xl={5} lg={12} sm={12} xs={12} >
                                <Form.Item label={<span>Time limit <Tooltip title={CONFIG.systemMessage.resourceTimeLimit}><Icon className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                                    {getFieldDecorator("schTimeLimit", {
                                        rules: [{ required: true, message: "This field cannot be empty" }],
                                        initialValue: schTimeLimit ? (schTimeLimit / 60) : 1
                                    })(
                                        <InputNumber
                                            min={CONFIG.schTimeLimit.min}
                                            max={CONFIG.schTimeLimit.max}
                                            step={CONFIG.schTimeLimit.step}
                                            formatter={value => `${value} hour(s)`}
                                            parser={value => value.replace(/[^0-9]/g, '')}
                                        />
                                    )}
                                </Form.Item>
                            </Col>}
                            <Col xl={5} lg={6} sm={6} xs={24} >
                                <Form.Item label="Status">
                                    <Tooltip title={selectedDataCenter?.active ? undefined : isActiveDisabledText} key={selectedDataCenter?.id}>
                                        {getFieldDecorator("isActive", {
                                            valuePropName: "checked",
                                            initialValue: isActive
                                        })(
                                            <Switch
                                                disabled={!selectedDataCenter?.active}
                                                checkedChildren="Active"
                                                unCheckedChildren="Inactive"
                                                onChange={this.handleActiveChange}
                                            />
                                        )}
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            <>
                                <Row gutter={24}>
                                    <Col xl={10} sm={10} xs={24}>
                                        <Form.Item>
                                            {
                                                getFieldDecorator('demo')(
                                                    <Select
                                                        showSearch={true}
                                                        mode="multiple"
                                                        placeholder="Select a demo">
                                                        {this.renderDemoOptions(demoResources)}
                                                    </Select>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} sm={4} xs={24}>
                                        <Button className="primary-action-button-bordered horizontal-spacing antd-button-fix" onClick={this.handleAddDemo} disabled={(!form.getFieldValue('demo') || form.getFieldValue('demo')?.length === 0)}>
                                            Add
                                            <Icon type="plus-circle" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Table
                                    size='middle'
                                    className="vertical-spacing-2 medium-content responsive-container"
                                    columns={columns}
                                    dataSource={demoResources}
                                    bordered
                                    pagination={false}
                                    rowKey={(record) => record.id}
                                />
                            </>
                        }
                        <Row className="vertical-spacing right-align">
                            <Col xl={24} sm={24} xs={24} >
                                <FormActionButtons isDisabled={!this.hasChanged} handleSubmit={this.handleSave} cancelText={"Discard & Close"} handleCancel={this.handleClose} okText={this.isEditMode && !isCloneResource ? 'Update' : 'Create'} />
                            </Col>
                        </Row>
                    </Form>
                </Spin >
            </div >
        );
    }
}

const mapStateToProps = ({ resourceForm, roleTemplates, user, viewport, dataCenters, demos, tabsLayout, openTabDemoDetails }) => {
    const { demoResources } = openTabDemoDetails?.currentTabData
    return {
        demoResources: demoResources || [],
        isFetching: resourceForm.isFetching,
        tabData: resourceForm.tabData,
        f: resourceForm.demoResourcesTabData,
        originalData: resourceForm.data,
        dataCenters: dataCenters.data,
        allRoleTemplates: roleTemplates.all,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isMobileView: viewport.isMobileView,
        allDemos: demos.allDemos,
        userGroups: demos.userGroups,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        previousTabs: tabsLayout?.previousTabs
    }
}


const ResourceFormRef = Form.create()(ResourceForm);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        toggleIsFetchingResourcesFlag,
        fetchResourceDetails,
        clearResourceForm,
        postResource,
        updateResourceRoleTemplate,
        fetchRoleTemplates,
        fetchAdminRoleTemplates,
        saveResourceRoleTemplates,
        cancelResourceRoleTemplates,
        fetchDataCenters,
        currentResourceFilter,
        fetchDemos,
        addResourceDemo,
        deleteResourceDemo,
        getLDAPUserGroups,
        updateResourceFormTabData,
        updateTabsLocalStorage,
        deleteTab,
        hasSaveChanges,
        toggleHasUnsavedChangesFlag,
        resetCreateResource
    }
)(ResourceFormRef)