import React from 'react';
import { TextInput, Switch, Form as NeoForm } from 'neo-latest';
import { Form } from 'antd';

function ProductDetails(props) {
    const { record, readOnly, form } = props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
        <div>
            <NeoForm>
                <Form style={{ display: "flex", gap: "16px", flexDirection: "column" }}>
                    <Form.Item>
                        {
                            getFieldDecorator("title", {
                                rules: [
                                    {
                                        required: true,
                                        message: `Please Input Title!`
                                    }
                                ],
                                initialValue: readOnly ? undefined : record?.title
                            })(<TextInput label="Title" required />)
                        }
                    </Form.Item>
                    <Form.Item colon={false} label={<span style={{ fontSize: "12px", color: "#323232" }}>Status</span>}>
                        {
                            getFieldDecorator("isActive", {
                                initialValue: readOnly ? undefined : record?.isActive,
                                valuePropName: "checked",
                            })(<Switch className="status-switch-neo" >{getFieldValue("isActive") ? "Active" : "Inactive"}</Switch>)
                        }
                    </Form.Item>
                </Form>
            </NeoForm>
        </div>
    )
}

export default ProductDetails