import React from "react";
import { Layout, Icon, Dropdown, Avatar, Row, Col, Form, Modal, Spin, Drawer, Divider, Select } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cookie from 'react-cookies';
import axios from "axios";
import { Tag } from 'antd5'
import { toggleMenuButton, postSearchResult, addNewTab, logoutUser, clearTabStoreData, openLinkInNewTab } from "../actions";
import { encrypt } from "../utils/aesDecrypt";
import history from '../history';
import { removeAllCookies } from '../utils/cookies';
import CONFIG from '../config';
import NotificationDropdown from './NotificationDropdown';
import { fetchSearchResults } from '../actions/header';
import HTML from './common/HTML';
import Highlighter from 'react-highlight-words'
import moment from 'moment-timezone';
import LocalUserLoginForm from './LoginForm';
import MyProfile from "./MyProfile";
import { Button, Menu, MenuItem } from "neo-latest"
import file_search_empty from "../assets/images/file_search_empty.png";
import DashboardNotifications from '../components/Dashboard/DashboardNotifications';
import { LoadingOutlined } from '@ant-design/icons';

const AntHeader = Layout.Header;
const { Option } = Select;
const { CheckableTag } = Tag;

class Header extends React.Component {

  goBackCount = 0;
  previousPathPushed = false;
  searchValue = ""
  cancelToken = undefined;
  state = {
    isClosed: true,
    modalTitle: <span style={{ lineHeight: "28px" }}>Sign in</span>,
    searchString: "",
    selectedTags: ["Demo", "Solution"],
    searchData: {},
    updatedSearchData: [],
    selectedSearchResult: undefined,
    loading: false,
    myProfileVisible: false
  }

  getPath = (item, key, searchString) => {
    switch (key) {
      case "Demo":
        return CONFIG.searchRoutes[key].replace("activeTab", item.match) + item.id
      case "Report":
        return item.parentReport !== null ? CONFIG.searchRoutes['MyReports'] + item.id : CONFIG.searchRoutes['StandardReports'] + item.id
      case "Support":
        return CONFIG.searchRoutes[key].replace("guideType", item.guideType) + item.id
      case "Solution":
        return CONFIG.searchRoutes[key].replace("solutionTypeId", item.solutionType?.id) + item.id
      default: return CONFIG.searchRoutes[key] + item.id
    }
  }

  handleSearch = async (searchString) => {

    //Check if there are any previous pending requests
    if (typeof this.cancelToken != typeof undefined) {
      this.cancelToken.cancel("Operation canceled due to new request.");
    }
    //Save the cancel token for the current request
    this.cancelToken = await axios.CancelToken.source();
    this.searchValue = searchString && searchString
    let { selectedTags } = this.state;
    selectedTags = selectedTags.length !== 0 ? selectedTags?.join(",") : undefined;
    this.setState({ loading: true })
    let searchData = searchString?.length > 2 ? await fetchSearchResults({ searchText: searchString, searchFilter: selectedTags, cancelToken: this.cancelToken }) : [];
    searchData = { ...searchData }
    const keys = Object.keys(searchData);
    let updatedSearchData = []
    keys.forEach(key => {
      return key !== "count" && searchData?.[key]?.map(item => (
        updatedSearchData.push({ ...item, type: key, path: this.getPath(item, key, searchString), tag: key, chipColor: CONFIG.searchTagsColor[key] })
      ))
    })
    await this.props.postSearchResult(updatedSearchData);
    this.setState({ updatedSearchData, searchString, searchData, count: searchData.count, loading: false });
  }

  handleSelect = async (value) => {
    const { previousPath, form } = this.props;
    const { setFieldsValue } = form;
    const { pathname, pageValue, title } = this.props.location;
    const item = JSON.parse(value)
    const path = pageValue ? { pathname, pageValue, title } : pathname;
    if (item.type === "Notification") this.setState({ isClosed: false, modalTitle: item.type, selectedSearchResult: item })
    else {
      const response = await this.props.openLinkInNewTab(`Search Results - ${this.searchValue}`, item.path)
      response && history.replace({ pathname: item.path, previousPath: previousPath || path, universalSearchString: this.state.searchString });
    }
    await this.props.postSearchResult([]);
    this.setState({ updatedSearchData: [], searchString: "", searchData: {}, count: 0, });
    this.searchValue = "";
    setFieldsValue({ search: undefined })
  }

  handleLogout = () => {
    const userDetails = this.props.userprofile;
    const isLocalUser = userDetails !== undefined ? userDetails.backendUser : false;
    if (isLocalUser) {
      removeAllCookies();
      localStorage.removeItem('header');
      window.location.href = CONFIG.myProfile.localUserLoginPageURL;
    } else {
      removeAllCookies();
      window.location.href = process.env.REACT_APP_SAML_LOGOUT;
      this.props.logoutUser().then(() => window.location.href = process.env.REACT_APP_SAML_LOGOUT)
    }
    this.props.clearTabStoreData();
    localStorage.removeItem('openTabs');
    localStorage.removeItem('openTabsActiveKey');
  }

  /**
    If search was used, previousPath will be set and on click of back, it should navigate to previousPath

    Since we "push" to previousPath, if there is a back button after pushing to previousPath, on click it 
    should go back 3 times to work as a normal back

    But if a user searches N times in the header search bar, it should go back (3 + N) times
  */

  handleBackNavigation = () => {
    const { form, previousPath } = this.props;
    if (previousPath) {
      history.replace(previousPath)
      this.previousPathPushed = true;
    }
    else {
      this.goBackCount > 0
        ? history.go(-this.goBackCount)
        : history.goBack();

      this.goBackCount = 0;
    }
    form.resetFields();
  }

  componentDidUpdate() {
    const { showBackButton } = this.props;
    if (showBackButton && this.previousPathPushed) {
      this.goBackCount += 3;
    }
    this.previousPathPushed = false;
  }

  handleCancel = () => {
    this.setState({ isClosed: true, selectedSearchResult: undefined });
    this.setModalTitle('Sign in')
  }

  renderLoginButton = () => {
    const plainText = moment().utc().add(process.env.REACT_APP_LOGIN_AES_SALT, 'm').format(CONFIG.dateFormats.scheduler);
    const id = encrypt(plainText);
    const encodedUrl = encodeURIComponent(id);
    const url = `${process.env.REACT_APP_SAML_LOGIN}?id=${encodedUrl}`
    return <Button aria-label="login-home" id="login-button" className=" primary-action-button-filled login-button-home" style={{ whiteSpace: "nowrap" }} onClick={() => { this.props.location.pathname === '/home/non-sso-users' ? this.setState({ isClosed: false }) : window.open(url, "_self") }}>Sign in</Button>
  }

  renderProfileMenu = () => {
    const { userprofile, isAdmin } = this.props
    return (<>
      <div>
        <div className="flex-body clickable right-spacing-1" id="my-profile-drawer" onClick={() => this.setState({ myProfileVisible: true })}>
          <div className="flex-row">
            <div style={{ marginTop: "10px", maxWidth: "50px", }}>
              <Avatar
                style={{ color: '#FFFFF', backgroundColor: '#003A51', verticalAlign: 'middle' }}
                title="My Profile"
                src={this.props.userProfilePicture || undefined}
              >
                {!(this.props.userProfilePicture) && <span style={{ fontWeight: "400", fontFamily: 'Noto Sans', }}>{`${userprofile?.firstName?.charAt(0)}${userprofile?.lastName?.charAt(0)}`}</span>}
              </Avatar>
            </div>
          </div>
          <div className="flex-column" style={{ marginTop: "20px", marginRight: "10px" }} >
            <div title={`${userprofile?.firstName} ${userprofile?.lastName}`} className="header-avatar-title">
              {userprofile?.firstName}
            </div>
            <div className="header-avatar-persona">
              {isAdmin ? "Administrator" : "User"}
            </div>
          </div>
        </div>


      </div>
    </>
    );
  }

  renderBackButton = () => {
    const { showBackButton, isFirstTimeLogin } = this.props;
    if (showBackButton && !isFirstTimeLogin) {
      return (
        <span >
          <Icon
            type="arrow-left"
            className="right-spacing arrow-color"
            onClick={this.handleBackNavigation}
          />
        </span>
      );
    }
  };

  renderMenuButton = () => {
    return (
      <Icon type="menu"
        className="float-right menu-color top-spacing-2"
        onClick={() => this.props.toggleMenuButton(true)}
      />
    )
  }

  renderDropdown = () => {
    const { userprofile } = this.props
    const firstName = userprofile ? userprofile.firstName : cookie.load('firstName')
    return (
      <Dropdown overlay={this.renderProfileMenu()}>
        <Avatar className="float-right avatar-fill-color top-spacing-3" size="medium">
          {firstName ? firstName.charAt(0) : ''}
        </Avatar>
      </Dropdown>
    )
  }

  loginComplete = () => {
    this.setState({ isClosed: true });
    this.setModalTitle('Sign in')
  }

  renderTitle = (title) => {
    const { searchString } = this.state;
    return (
      <Highlighter
        searchWords={[searchString]}
        autoEscape={true}
        textToHighlight={title}
      />
    )
  }

  renderModal = () => {
    const { selectedSearchResult, modalTitle, isClosed, searchString } = this.state;
    const searchTextRegEx = CONFIG.regEx.searchText.source.replace('searchText', searchString);
    const intro = selectedSearchResult?.intro && selectedSearchResult?.intro.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`);
    const description = selectedSearchResult?.description && selectedSearchResult?.description.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`)
    const body = selectedSearchResult?.body && selectedSearchResult?.body.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`)

    return (
      <Modal
        title={modalTitle}
        wrapClassName="centered-modal" // Assign a custom CSS class for centering the title
        visible={!isClosed}
        onCancel={this.handleCancel}
        footer={null}
        className={selectedSearchResult ? "" : "login-modal"}
        width={selectedSearchResult ? '60%' : this.props.viewport.isMobileView ? '100%' : '30%'}
      >
        {!selectedSearchResult && <LocalUserLoginForm history={this.props.history} loginComplete={this.loginComplete} setModalTitle={this.setModalTitle} />}
        {selectedSearchResult &&
          <div>
            <h2>{this.renderTitle(selectedSearchResult?.title)}</h2>
            <HTML htmlContent={intro || description} />
            <HTML htmlContent={body} />
          </div>
        }
      </Modal>

    )
  }

  setModalTitle = (title) => {
    this.setState({
      modalTitle: title
    })
  }

  renderLogo = () => {
    return (
      <div className='brand-logo' style={{ height: "58px", display: "flex", alignItems: "center", marginLeft: "20px" }}>
        <svg width="100" height="28" viewBox="0 0 100 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M61.9861 0.111084L69.1111 16.3055L76.8472 0.111084H80.9028L66.9861 27.8889H63.0972L67.0972 20.3055L57.9167 0.111084H61.9861ZM89.9167 0.111084L100 20.3055H95.9305L88.5 4.70831L83.8333 14.5694H90.6528L91.7639 17.2361H82.5417L81.0833 20.3055H77.0139L87.0972 0.111084H89.9167ZM12.9028 0.111084L22.9861 20.3055H18.9306L11.5 4.70831L6.81944 14.5694H13.6389L14.75 17.2361H5.54167L4.06944 20.3055H0L10.0833 0.111084H12.9028ZM51.2639 0.111084L61.3472 20.3055H57.2778L49.8611 4.70831L45.1806 14.5694H52.0139L53.1111 17.2361H43.9028L42.4306 20.3055H38.3611L48.4444 0.111084H51.2639ZM23.25 0.111084L30.6806 16L38.1111 0.111084H42.1806L32.0972 20.3055H29.2778L19.1944 0.111084H23.25Z" fill="#DA291C" />
        </svg>
        {
          <p className="logo-text" style={{ verticalAlign: "middle", marginLeft: "24px" }}>
            <span className="size-14px vertical-center-align">{CONFIG.brandName}</span>
          </p>
        }
      </div>);
  }

  handleCloseMyProfileDrawer = () => {
    this.setState({ myProfileVisible: false })
  }

  handleTagSelect = async (tag, checked) => {
    if (checked) {
      if (tag === "All")
        await this.setState({ selectedTags: ["All", "Demo", "Solution", "News", "Notification", "Support", "EZ Demo", "Report"] })
      else
        await this.setState(prevState => ({ selectedTags: [...prevState.selectedTags, tag] }))
    }
    else {

      if (tag === "All")
        await this.setState({ selectedTags: [] })
      else
        await this.setState(prevState => ({ selectedTags: prevState.selectedTags.filter(eachTag => eachTag !== tag) }));
    }

    this.handleSearch(this.searchValue)
  }

  handleSearchClose = (open) => {
    if (!open) {
      this.setState({
        selectedTags: ["Demo", "Solution"],
      })
    }
  }

  renderSupportButton = () => {
    return (
      <div style={{ marginTop: "5px" }}>
        <Menu menuRootElement={<Button variant="primary" className="header-support-icon-button" size="sm" icon="chevron-down" dir="rtl">
          Support
        </Button>}>
          <MenuItem style={{ textAlign: "left" }} onClick={() => window.open(process.env.REACT_APP_ONE_CARE_URL, "_blank")}>OneCare Portal</MenuItem>
          <MenuItem style={{ textAlign: "left" }} onClick={() => window.open(process.env.REACT_APP_SUBMIT_USE_CASE_URL, "_blank")}>Submit Use Case</MenuItem>
        </Menu>
      </div>
    )
  }

  render() {
    const { form, isLoginComplete, isFirstTimeLogin, searchResult, showNotificationBanner, dashboardNotifications } = this.props;
    const shoulDisplayLogin = !isLoginComplete && cookie.load('ssoComplete') !== 'true';
    const isFirstTimeSSOLoginPage = isLoginComplete && isFirstTimeLogin
    const { getFieldDecorator } = form;
    const empty = <div className='search-no-results font'>
      <img className="search-image" src={file_search_empty} alt="No Results" />
      <div>{this.searchValue?.length > 0 ? <span>We couldn't find any results!<br /><span className="dashboard-card-details">You can try modifying the search/filter criteria to broaden your search</span></span> : "Type something to search"}
      </div>
    </div>
    const tagsData = ["All", "Demo", "Solution", "News", "Notification", "Support", "EZ Demo", "Report"];
    const displayNotificationBanner = dashboardNotifications?.length > 0 && showNotificationBanner
    return (
      <AntHeader style={{ borderBottom: "1px solid #c9c9c9" }} className={(displayNotificationBanner && isLoginComplete && !isFirstTimeLogin) ? "header-group-with-notification-banner font" : "header-group font"} >
        {isLoginComplete && !isFirstTimeLogin && <div><DashboardNotifications /></div>}
        <Row>
          <Col span={7}>
            {!this.props.viewport.isMobileView && this.renderLogo()}
          </Col>
          <Col style={{ paddingRight: "16px" }} xl={isLoginComplete ? 11 : 11} lg={12} md={12} xs={!shoulDisplayLogin ? 14 : 8} className="top-spacing-3">
            <Form.Item>
              {
                !isFirstTimeLogin && this.props.panes?.length > 0 && isLoginComplete && this.props.userprofile && getFieldDecorator('search')(
                  <Select
                    id="universal-search"
                    className='searchForm float-right header-search font'
                    filterOption={false}
                    placeholder={<span><span className="neo-gray-color">
                      <Icon type="search" />
                    </span> <i style={{ fontSize: "14px" }}>Search</i></span>}
                    showSearch={true}
                    defaultActiveFirstOption={false}
                    onSearch={this.handleSearch}
                    onSelect={this.handleSelect}
                    onDropdownVisibleChange={this.handleSearchClose}
                    optionLabelProp="label"
                    allowClear={true}
                    showArrow={false}
                    autoClearSearchValue={false}
                    dropdownRender={
                      menu => (
                        <div className={searchResult?.length > 0 ? "heightSelect" : undefined} onMouseDown={e => e.preventDefault()}>
                          <div className="medium-text vertical-spacing bold left-spacing">
                            <span className="font" style={{ marginRight: 8, color: "black" }}>
                              Categories:
                            </span>
                            {tagsData.map((tag) => (
                              <CheckableTag
                                className="font"
                                key={tag}
                                checked={this.state.selectedTags.includes(tag)}
                                onChange={(checked) => this.handleTagSelect(tag, checked)}
                              >
                                {tag}
                              </CheckableTag>
                            ))}
                            {/* > */}
                            <Divider className="no-top-margin-divider" />
                          </div>
                          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading}>
                            {searchResult && searchResult?.length > 0 ? menu : empty}
                          </Spin>
                        </div>
                      )
                    }
                  >
                    {
                      searchResult?.map((item, index) => (
                        <Option className="font" style={index === 0 ? { margin: "10px", color: "black", marginTop: '0px', } : { margin: '10px', color: "black" }} title={item.title || item.name} key={(item.id + item.match + index + Math.random())} value={JSON.stringify(item)} label={item.title || item.name}>
                          <Tag color={item?.chipColor}>{item?.tag}</Tag>
                          &nbsp;&nbsp;<span className="bold">{item.title || item.name}</span>
                          {item.match && <span className=" font-12 text-color-grey">&nbsp;&bull;&nbsp;<i className="font font-12">{CONFIG.demoSections[item?.match]}</i></span>}
                        </Option>
                      ))
                    }
                  </Select>
                )
              }
            </Form.Item>
          </Col>
          {!shoulDisplayLogin && !isFirstTimeLogin &&
            <Col xl={3} lg={3} md={3} xs={3} style={{ display: "flex", justifyContent: "space-evenly", gap: "16px" }} className="center-align top-spacing-6 ">
              <NotificationDropdown />
              {this.renderSupportButton()}
            </Col>
          }
          <Col xl={isLoginComplete ? 3 : 6} lg={3} md={3} xs={3} style={isLoginComplete ? { display: "flex", paddingLeft: "16px" } : { display: "flex", justifyContent: "flex-end" }}>
            {!shoulDisplayLogin ? this.props.viewport.isMobileView ? this.renderMenuButton() : this.renderProfileMenu() : <span className="float-right">{this.renderLoginButton()}</span>}
            {shoulDisplayLogin ? this.props.viewport.isMobileView ? this.renderMenuButton() : undefined : undefined}
          </Col>
        </Row>
        {!this.state.isClosed && this.renderModal()}
        {
          !isFirstTimeSSOLoginPage && <Drawer
            placement="right"
            width='65%'
            closable={true}
            onClose={this.handleCloseMyProfileDrawer}
            visible={this.state.myProfileVisible}
          >
            <MyProfile handleLogout={this.handleLogout} closeMyProfile={this.handleCloseMyProfileDrawer} />
          </Drawer>
          // : <MyProfile />
        }
      </AntHeader >

    );
  }
}

const mapStateToProps = ({ header, viewport, user, tabsLayout, userDashboardNotifications }) => {
  return {
    showBackButton: header.showBackButton,
    dashboardNotifications: userDashboardNotifications.dashboardNotifications,
    showNotificationBanner: userDashboardNotifications.showNotificationBanner,
    isFirstTimeLogin: user.isFirstTimeLogin,
    previousPath: header.previousPath,
    selectedTitle: header.title,
    viewport: viewport,
    userProfilePicture: user.userProfilePicture,
    user,
    isAdmin: user.isAdmin,
    userprofile: user.profile,
    isLoginComplete: user.isLoginComplete,
    searchResult: header.searchResult,
    panes: tabsLayout.panes
  };
};

const HeaderForm = Form.create()(Header);

export default connect(mapStateToProps, { addNewTab, toggleMenuButton, postSearchResult, logoutUser, clearTabStoreData, openLinkInNewTab })(withRouter(HeaderForm));
