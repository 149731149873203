import React, { Component } from 'react'
import { Row, Table, Spin, Divider } from "antd"
import { Icon } from '@avaya/neo-react'
import ROUTE from "../../components/ContentRouteConfig";
import CONFIG from '../../config';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import {
    fetchAdminDashboardRequestStats,
    openLinkInNewTab
} from '../../actions'

export class RequestStatistics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showCount: false
        }
    }

    getPercentage = (pending, resolved) => {

        if (pending === 0 && resolved === 0) {
            return 100;
        } else {
            const percentage = resolved / (pending + resolved) * 100;
            return Math.round((percentage + Number.EPSILON) * 100) / 100
        }

    }

    reDirectToManageRequest = (requestType) => {
        if (requestType === CONFIG?.requestType?.demoRequest) this.props.openLinkInNewTab(requestType, ROUTE.demoRequests);
        else if (requestType === CONFIG?.requestType?.capabilityRequest) this.props.openLinkInNewTab(requestType, ROUTE.capabilityRequest);
        else this.props.openLinkInNewTab("Approvals", '/schedule-approvals');
    }

    render() {
        const { requestStats, fetchingRequestStats } = this.props;
        const columns = [
            {
                title: '',
                key: 'requestType',
                align: 'center',
                dataIndex: "requestType",
                render: (requestType) => <span className='hover-link' style={{ fontWeight: 600 }} onClick={() => this.reDirectToManageRequest(requestType)}>{requestType}</span>,
            },
            {
                title: <span style={{ fontWeight: 600, fontSize: '13px' }}>Pending</span>,
                key: 'pendingRequestCount',
                align: 'center',
                dataIndex: "pendingRequestCount"
            },
            {
                title: <span style={{ fontWeight: 600, fontSize: '13px' }}>Completed</span>,
                key: 'resolvedRequestCount',
                align: 'center',
                dataIndex: "resolvedRequestCount"
            },
        ]

        return (
            <>
                <div >
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={fetchingRequestStats}>
                        <Row className='dashboard-category-heading-with-extra' style={{ paddingBottom: '10px' }} >
                            <span className='dashboard-schedules-category-heading'><Icon icon="analytics" style={{ fontSize: "20px", paddingRight: "10px" }} />Pending Requests Statistics</span>
                            <span className='dashboard-category-extra' onClick={() => this.setState(prevState => ({
                                showCount: !prevState.showCount
                            }))} >{!this.state.showCount ? "Go To Report" : "Show Progress"}</span>
                        </Row>
                        {!this.state.showCount ? <Row gutter={[8, 8]}>
                            <div
                                className='stats-card-spacing'
                            >

                                {requestStats.map((requestStatObj, index) => {
                                    const countColor = CONFIG.statscolor[index]
                                    const icon = CONFIG.statsIcon[index]
                                    return (
                                        <React.Fragment key={index}>
                                            <div style={{ gap: "16px" }} className={`request-stats-card ${requestStatObj?.pendingRequestCount > 0 && "link request-stats-card-link"}`} onClick={() => requestStatObj?.pendingRequestCount > 0 && this.reDirectToManageRequest(requestStatObj?.requestType)}>
                                                <div className="stats-card-row1">{requestStatObj?.requestType}</div>
                                                <div
                                                    className="stats-card-row2"
                                                >
                                                    {<div><span className='floafloat-left'><Icon icon={icon} /></span><span style={{ color: `${countColor}` }} className='float-right'>{requestStatObj?.pendingRequestCount}</span></div>}
                                                </div>
                                            </div>
                                        </React.Fragment>

                                    )
                                })
                                }
                            </div>

                        </Row> : <Row>
                            <Table
                                columns={columns}
                                dataSource={requestStats}
                                pagination={false}
                                className='request-statistics-table'
                                size='small'
                                style={{ paddingLeft: '10px' }}
                            />
                            <div style={{ marginLeft: "2px" }}>
                                <Divider style={{ marginBottom: "0px" }} />
                            </div>
                        </Row>}
                    </Spin>
                    <div style={{ marginLeft: "2px" }}>
                        <Divider style={{ marginBottom: "0px" }} />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ adminDashboard }) => {
    return {
        requestStats: adminDashboard?.requestStats,
        fetchingRequestStats: adminDashboard?.fetchingRequestStats
    };
}

export default connect(
    mapStateToProps,
    {
        fetchAdminDashboardRequestStats,
        openLinkInNewTab
    }
)(RequestStatistics);