import { batch } from 'react-redux';
import moment from 'moment-timezone';

import { aepSchedule, aepDemo } from '../apis';
import {
    TOGGLE_MAINTENANCE_AVAILABILITY,
    CHECK_MAINTENANCE_AVAILABILITY,
    SET_MAINTENANCE_RESOURCE_ID,
    CLEAR_MAINTENANCE_FORM,
    EDIT_MAINTENANCE_SCHEDULE,
    TOGGLE_MAINTENANCE_FORM_MODAL,
    UPDATE_MAINTENANCE_CURRENT_PAGE,
    FETCH_MAINTENANCE_SCHEDULE,
    TOGGLE_IS_FETCHING_MAINTENANCE_SCHEDULE,
    CLEAR_MAINTENANCE_FORM_ERRORS,
    CLEAR_MAINTENANCE_SCHEDULES,
    UPDATE_FILTERSORT,
    LAST_MAINTENANCE_FILTER,
    CLEAR_UPDATED_MAINTENANCE_DETAILS,
    FETCH_SHARED_DEMO_DETAILS,
    CLEAR_SHARED_DEMOS,
    SET_MAINTENANCE_ERROR_TYPE,
    CHANGE_MAINTENANCE_TYPE,
    UPDATE_SHARED_DEMO_LIST
} from './types';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { fetchUserSchedules } from './dashboard';

export const getDemoResources = async (demoId, dataCenterIds) => {
    const response = await aepDemo.get(`/demoSolutionService/demos/${demoId}/dataCenters/resources?dataCenterIds=${dataCenterIds}`)
    return response.data || []
}

export const getDemoDataCenters = async (demoId) => {
    const response = await aepDemo.get(`/demoSolutionService/demos/${demoId}/dataCenters`);
    return response.data || []
}

export const getMaintenanceSchedulesByGroupID = async (groupId, hardDelete) => {
    const urlPath = hardDelete ? `/scheduleMaintenanceService/softlyDeleted/maintenance/${groupId}` : `/scheduleMaintenanceService/${groupId}/maintenance`
    const response = await aepSchedule.get(urlPath);
    return response.data || []
}

export const toggleisMaintenanceAvailabilityVisible = (flag) => {
    return {
        type: TOGGLE_MAINTENANCE_AVAILABILITY,
        payload: flag
    };
};

export const updateSharedDemoList = (demo) => {
    return {
        type: UPDATE_SHARED_DEMO_LIST,
        payload: demo
    }
}

export const checkMaintenanceAvailability = (schedule, maintenanceType, maintenanceId, groupId, dateRangeList = []) => async (dispatch, getState) => {

    const timeZone = schedule.timeZone

    const dateRangeArray = (schedule.freqType === "r" || schedule.freqType === "1" || (maintenanceId && schedule.freqType === "sw" && !schedule.applyToAll) || (maintenanceId && schedule.freqType === "sm" && !schedule.applyToAll)) ? schedule.dateRangeList.map(item => {
        return {
            startTime: item.startTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
            endTime: item.endTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString()
        }
    }) : null

    const params = {
        maintId: schedule.applyToAll ? null : maintenanceId,
        maintenanceType,
        groupId: schedule.applyToAll ? groupId : null,
        timeZone
    };

    dateRangeList = dateRangeList && dateRangeArray && schedule.applyToAll ? [...dateRangeList, ...dateRangeArray] : dateRangeArray

    schedule = {
        maintenanceRelationList: schedule.maintenanceRelations,
        dateRangeList,
        dateRangeData: ((schedule.freqType === "sw" || schedule.freqType === "sm") && (maintenanceId ? schedule.applyToAll : true)) ? {
            startDate: schedule.dateRangeList[0].startTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
            endDate: schedule.dateRangeList[0].endTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
            endSeriesDate: schedule.seriesEndDate.tz(timeZone, true).endOf('day').set('second', 0).set('millisecond', 0).toISOString(),
            dayOfWeek: schedule.freqType === "sw" ? schedule.dayOfWeek : null,
            monthDay: (schedule.freqType === "sm" && schedule.radioToggle === 1) ? schedule.monthDay : null,
            dayName: (schedule.freqType === "sm" && schedule.radioToggle === 2) ? schedule.dayName : null,
            value: (schedule.freqType === "sm" && schedule.radioToggle === 2) ? schedule.value : null,
        } : null
    }

    const urlPath = `/scheduleMaintenanceService/checkAvailability/demo-dc-resources`
    const response = await aepSchedule({
        url: urlPath,
        method: 'POST',
        params,
        data: schedule,
    });

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const schedules = response.data.schedules || []
        const isMaintenanceAvailable = (response.data.maintenance.length === 0 && response.data.resources.length === 0 && (response.data.schedules?.length === 0 || response.data.schedules === null));
        const maintenanceUnavailableCount = response.data.maintenance.length;
        const maintenanceSchedules = response.data.maintenance;
        const scheduleConflictsCount = schedules?.length + response.data.resources?.length
        const resourceSchedules = response.data.resources;
        const labSchedules = response.data.schedules !== null ? response.data.schedules : [];
        const unavailableResources = resourceSchedules?.map(resource => {
            return {
                id: resource.id,
                name: resource.name
            };
        });

        let overrideMaintenance = true;
        if (response.data.maintenance.length !== 0) {
            response.data.maintenance.forEach(item => {
                if (item.isCreateDisabled) overrideMaintenance = false
            })
        }

        const unavailableLabDemos = labSchedules.map(schedule => {
            return {
                scheduleId: schedule.id,
                id: schedule?.demo.id,
                name: schedule?.demo.title
            };
        });

        dispatch({
            type: CHECK_MAINTENANCE_AVAILABILITY,
            payload: {
                isMaintenanceAvailable,
                maintenanceUnavailableCount,
                maintenanceSchedules,
                resourceSchedules,
                unavailableResources,
                labSchedules,
                unavailableLabDemos,
                scheduleConflictsCount,
                overrideMaintenance
            }
        });
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        const errorMessage = maintenanceType === "r" ? 'Overlapping dates. Change the date range and try again.' : 'Overlapping occurences. Change how often it occurs and try again.'
        pushMessage(CONFIG.messageType.warning, errorMessage);
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to check availability');
};

export const setMaintenanceErrorResource = (resourceId = undefined) => {
    return {
        type: SET_MAINTENANCE_RESOURCE_ID,
        payload: resourceId
    };
};

export const setMaintenanceErrorType = (type = undefined) => {
    return {
        type: SET_MAINTENANCE_ERROR_TYPE,
        payload: type
    };
};

export const clearMaintenanceForm = () => {
    return {
        type: CLEAR_MAINTENANCE_FORM
    }
};

export const changeMaintenanceType = (maintenanceType) => {
    return {
        type: CHANGE_MAINTENANCE_TYPE,
        payload: maintenanceType
    }
}

export const editMaintenanceSchedule = (scheduleId) => async (dispatch, getState) => {
    const profile = getState().user.profile;
    const timeZone = profile ? profile.timezone : undefined;
    const response = await aepSchedule.get('/scheduleMaintenanceService/maintenances/' + scheduleId);
    const schedule = { ...response.data, startTime: moment.tz(response.data?.startTime, timeZone), endTime: moment.tz(response.data?.endTime, timeZone) };

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        batch(() => {
            dispatch({
                type: EDIT_MAINTENANCE_SCHEDULE,
                payload: schedule
            });
        })
        dispatch(toggleIsFetchingMaintenanceSchedule(false));
    }

    else pushMessage(CONFIG.messageType.error, 'Unable to fetch maintenance');

}


export const postMaintenanceSchedule = schedule => async (dispatch, getState) => {
    const timeZone = schedule.timeZone

    const data = {
        maintenance: {
            timeZone: timeZone,
            description: schedule.description,
            isValid: true,
            notifyUsers: schedule.notifyUsers,
            maintenanceRelations: schedule.maintenanceRelations,
            title: schedule.title,
            maintenanceType: schedule.maintenanceType,
            freqType: (schedule.freqType === "1" || schedule.freqType === "r") ? schedule.freqType : "s",
            startTime: schedule.freqType === "1" ? schedule.dateRangeList[0].startTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString() : null,
            endTime: schedule.freqType === "1" ? schedule.dateRangeList[0].endTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString() : null,
            emailTemplateId: schedule?.emailTemplateId
        },
        dateRangeList: schedule.freqType === "r" ? schedule.dateRangeList?.map(item => {
            return {
                startTime: item.startTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
                endTime: item.endTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString()
            }
        }) : null,
        dateRangeData: (schedule.freqType === "sw" || schedule.freqType === "sm") ? {
            startDate: schedule.dateRangeList[0].startTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
            endDate: schedule.dateRangeList[0].endTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
            endSeriesDate: schedule.seriesEndDate.tz(timeZone, true).endOf('day').set('second', 0).set('millisecond', 0).toISOString(),
            dayOfWeek: schedule.freqType === "sw" ? schedule.dayOfWeek : null,
            monthDay: (schedule.freqType === "sm" && schedule.radioToggle === 1) ? schedule.monthDay : null,
            dayName: (schedule.freqType === "sm" && schedule.radioToggle === 2) ? schedule.dayName : null,
            value: (schedule.freqType === "sm" && schedule.radioToggle === 2) ? schedule.value : null,
        } : null
    };

    const response = await aepSchedule.post("/scheduleMaintenanceService/maintenance", data);
    if (response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, "Maintenance scheduled successfully")

    }
    else pushMessage(CONFIG.messageType.error, "Unable to schedule maintenance");
    batch(() => {
        dispatch(clearMaintenanceForm());
        dispatch(clearMaintenanceSchedules());
        dispatch(toggleIsFetchingMaintenanceSchedule(true));
    });
};

export const putMaintenanceSchedule = (schedule, maintenanceId, fromDashboard) => async (dispatch, getState) => {
    const timeZone = schedule.timeZone
    const data = {
        timeZone: timeZone,
        startTime: schedule.dateRangeList[0].startTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
        endTime: schedule.dateRangeList[0].endTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
        description: schedule.description,
        title: schedule.title,
        maintenanceType: schedule.maintenanceType,
        isValid: true,
        notifyUsers: schedule.notifyUsers,
        maintenanceRelations: schedule.maintenanceRelations?.map(item => { return { ...item, maintenance: { id: maintenanceId } } }),
        emailTemplateId: schedule?.emailTemplateId
    };
    const response = await aepSchedule.put(`/scheduleMaintenanceService/maintenance/${maintenanceId}`, data);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Maintenance updated successfully")
    }
    else pushMessage(CONFIG.messageType.error, "Unable to update maintenance");
    batch(() => {
        dispatch(clearMaintenanceForm());
        if (fromDashboard) {
            dispatch(fetchUserSchedules(({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD })));
            dispatch(toggleMaintenanceFormModal(false));
        }
    });

};

export const putBulkMaintenanceSchedule = (schedule, maintenanceId, groupId, fromDashboard, oldDateRangeList = []) => async (dispatch, getState) => {
    const state = getState();
    const timeZone = schedule.timeZone
    const maintenanceForm = state.maintenanceForm

    const dateRangeList = schedule.freqType === "r" ? schedule.dateRangeList?.map(item => {
        return {
            startTime: item.startTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
            endTime: item.endTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString()
        }
    }) : null

    const data = {
        maintenance: {
            timeZone,
            description: schedule.description,
            title: schedule.title,
            maintenanceType: schedule.maintenanceType,
            isValid: true,
            notifyUsers: schedule.notifyUsers,
            createdBy: maintenanceForm.createdBy,
            createdTime: maintenanceForm.createdTime,
            maintenanceRelations: schedule.maintenanceRelations?.map(item => { return { ...item, maintenance: { id: maintenanceId } } }),
            freqType: (schedule.freqType === "1" || schedule.freqType === "r") ? schedule.freqType : "s",
            id: maintenanceId,
            groupId,
            emailTemplateId: schedule?.emailTemplateId
        },
        dateRangeList: schedule.freqType === "r" ? oldDateRangeList ? [...dateRangeList, ...oldDateRangeList] : dateRangeList : null,
        dateRangeData: (schedule.freqType === "sw" || schedule.freqType === "sm") ? {
            startDate: schedule.dateRangeList[0].startTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
            endDate: schedule.dateRangeList[0].endTime.tz(timeZone, true).set('second', 0).set('millisecond', 0).toISOString(),
            endSeriesDate: schedule.seriesEndDate.tz(timeZone, true).endOf('day').set('second', 0).set('millisecond', 0).toISOString(),
            dayOfWeek: schedule.freqType === "sw" ? schedule.dayOfWeek : null,
            monthDay: (schedule.freqType === "sm" && schedule.radioToggle === 1) ? schedule.monthDay : null,
            dayName: (schedule.freqType === "sm" && schedule.radioToggle === 2) ? schedule.dayName : null,
            value: (schedule.freqType === "sm" && schedule.radioToggle === 2) ? schedule.value : null,
        } : null
    };

    const response = await aepSchedule.put(`/scheduleMaintenanceService/maintenance/updateMaintenanceByGroupId/${groupId}`, data);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Maintenance updated successfully")
    }
    else pushMessage(CONFIG.messageType.error, "Unable to update maintenance");
    batch(() => {
        dispatch(clearMaintenanceForm());
        if (fromDashboard) {
            dispatch(fetchUserSchedules(({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD })));
            dispatch(toggleMaintenanceFormModal(false));
        }
    });
}

export const clearUpdatedMaintenanceDetails = () => {
    return {
        type: CLEAR_UPDATED_MAINTENANCE_DETAILS,
    }
}



export const toggleMaintenanceFormModal = (flag) => {
    return {
        type: TOGGLE_MAINTENANCE_FORM_MODAL,
        payload: flag
    };
};

export const toggleIsFetchingMaintenanceSchedule = flag => {
    return {
        type: TOGGLE_IS_FETCHING_MAINTENANCE_SCHEDULE,
        payload: flag
    }
};

export const clearMaintenanceSchedules = () => {
    return {
        type: CLEAR_MAINTENANCE_SCHEDULES
    }
}

export const clearSharedDemoDetails = () => {
    return {
        type: CLEAR_SHARED_DEMOS
    }
}


export const fetchSharedDemoDetails = (data, maintenanceType) => async dispatch => {
    const response = await aepDemo.post(`/demoSolutionService/demos/related/demos?maintenanceType=${maintenanceType}`, data.maintenanceRelations)

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: FETCH_SHARED_DEMO_DETAILS,
                payload: response?.data || []
            });
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch demos with shared resources');
    dispatch(toggleIsFetchingMaintenanceSchedule(false));
}

export const fetchMaintenanceSchedule = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, status }) => async (dispatch, getState) => {
    let response = await aepSchedule.get("/scheduleMaintenanceService/maintenance", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            active: status,
            filterSort: filterSort,
        }
    });
    const data = response.data?.dataSet;
    let count = pageNumber === 1 ? 0 : (pageSize * (pageNumber - 1));
    const modifiedResponse = data?.map((eachItem) => {
        count += 1;
        return { ...eachItem, index: count }
    })

    if (response.status === CONFIG.HTTP_STATUS.OK || pageNumber === 1) {
        const payload = {
            [status ? "active" : "inactive"]: modifiedResponse || [],
            [status ? "activeCount" : "inactiveCount"]: response.data?.count || 0
        };

        batch(() => {
            dispatch({ type: FETCH_MAINTENANCE_SCHEDULE, payload: payload });
            dispatch({
                type: UPDATE_MAINTENANCE_CURRENT_PAGE,
                payload: { [status ? "currentActivePage" : "currentInactivePage"]: pageNumber }
            });
            dispatch(toggleIsFetchingMaintenanceSchedule(false));
        });
    }

    else if ((response.status === CONFIG.HTTP_STATUS.NO_CONTENT) && pageNumber !== 1) {
        dispatch(fetchMaintenanceSchedule({ filterSort: filterSort, status: status, pageNumber: pageNumber - 1 }));
        return;
    }

    else {
        dispatch(toggleIsFetchingMaintenanceSchedule(false));
        if (status)
            throw Error("Unable to fetch Scheduled Maintenance");
        else
            pushMessage(CONFIG.messageType.error, "Unable to fetch Scheduled Maintenance");
    }
    return data || [];
};

export const deleteMaintenanceSchedule = (demoId, hardDelete = false, deleteAll, groupId) => async () => {
    const response = deleteAll ? await aepSchedule.delete("/scheduleMaintenanceService/deleteMaintenanceByGroupId/" + groupId, { params: { hardDelete, allMaintenances: true } }) : await aepSchedule.delete("/scheduleMaintenanceService/maintenance/" + demoId, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, deleteAll ? "Maintenances deleted successfully" : "Maintenance deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete maintenance");
};

export const clearMaintenanceFormErrors = () => {
    return {
        type: CLEAR_MAINTENANCE_FORM_ERRORS
    };
};

export const getAllMaintenanceSchedules = async (filterSort, active) => {
    const response = await aepSchedule.get('/scheduleMaintenanceService/maintenance', { params: { filterSort, active } });
    return response.data?.dataSet || []
};

export const updateFilterSort = (activeFilterSort, inactiveFilterSort, status) => {
    return {
        type: UPDATE_FILTERSORT,
        payload: { activeFilterSort, inactiveFilterSort, status }
    };
};

export const currentMaintenanceFilter = (filter, activeFilterSort, inactiveFilterSort, status, activeCurrentPageNumber, InactiveCurrentPageNumber, activePageSize, inactivePageSize, viewType, calendarDate, calendarViewType) => {
    return {
        type: LAST_MAINTENANCE_FILTER,
        payload: [filter, activeFilterSort, inactiveFilterSort, status, activeCurrentPageNumber, InactiveCurrentPageNumber, activePageSize, inactivePageSize, viewType, calendarDate, calendarViewType]
    };
}