import {
    FETCH_LOCAL_USERS,
    FETCH_COUNTRIES,
    FETCH_LOCAL_USER,
    FETCH_ALL_COMPANIES,
    TOGGLE_IS_FETCHING_LOCAL_USERS,
    CLEAR_LOCAL_USERS,
    CLEAR_LOCAL_USERS_DETAILS,
    UPDATE_COMPANIES,
    LAST_LOCAL_USERS_FILTER,
    TOGGLE_HAS_LOCAL_USERS_UPDATED,
    REFRESH_LOCAL_USERS,
    UPDATE_LOCAL_USER_FORM_TAB_DATA,
} from '../actions/types';

const localUsersState = {
    isFetching: false,
    currentPage: 1,
    hasUpdated: false,
    isReordered: false,
    currentPageNumber: undefined,
    currentLocalUserId: undefined,
    data: []
};

export const localUsersReducer = (state = localUsersState, action) => {
    switch (action.type) {
        case FETCH_LOCAL_USERS:
            return { ...state, data: action.payload || [], count: action.payload.length || 0 };
        case LAST_LOCAL_USERS_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true, currentPageNumber: action.payload[2], pageSize: action.payload[3] }
        case TOGGLE_IS_FETCHING_LOCAL_USERS:
            return { ...state, isFetching: action.payload };
        case TOGGLE_HAS_LOCAL_USERS_UPDATED:
            return { ...state, hasUpdated: action.payload };
        case CLEAR_LOCAL_USERS:
            return localUsersState;
        case REFRESH_LOCAL_USERS:
            return { ...state, data: [], count: undefined }
        default:
            return state;
    };
};

const localUsersFormState = {
    isFetching: false,
    localUserDetails: [],
    tabData: [],
};

export const localUsersFormReducer = (state = localUsersFormState, action) => {
    switch (action.type) {
        case FETCH_COUNTRIES:
            return { ...state, countries: action.payload }
        case FETCH_ALL_COMPANIES:
            return { ...state, allCompanies: action.payload }
        case UPDATE_LOCAL_USER_FORM_TAB_DATA:
            return { ...state, tabData: state.tabData.filter(obj => obj.id !== action.payload.id).concat(action.payload) }
        case UPDATE_COMPANIES:
            return { ...state, allCompanies: state.allCompanies ? [action.payload, ...state.allCompanies] : [action.payload] };
        case FETCH_LOCAL_USER:
            return {
                ...state, localUserDetails: action.payload || [],
                localUserObj:action.payload
                // ...state, localUserDetails: [...state.localUserDetails, (action.payload || [])]
            }
        case CLEAR_LOCAL_USERS_DETAILS:
            return localUsersFormState;
        default:
            return state;
    };
};