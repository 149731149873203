import CONFIG from '../config';

export const getDemoCardWarnings = (schedules) => {
    // Validating Demo Status


    // +-------------------+-----------------------------+-------+--------------------------------------+
    // |       Check       |          Paramter           | value |           Tooltip Message            |
    // +-------------------+-----------------------------+-------+--------------------------------------+
    // | isDemoSoftDeleted | schedules->demo->softDelete | true  | This demo is no longer available.    |
    // | isDemoActive      | schedules->demo->isActive   | false | This demo is currently inactive.     |
    // | isDemoExpired     | schedules->demo->isExpired  | true  | This demo has currently expired      |
    // | hasAccessToDemo   | hasAccessToDemo             | false | You do not have access to this demo. |
    // +-------------------+-----------------------------+-------+--------------------------------------+



    const isDemoSoftDeleted = schedules?.demo?.softDelete
    const isDemoActive = schedules?.demo?.isActive
    const isDemoExpired = schedules?.demo?.expired
    const hasAccessToDemo = schedules?.hasAccessToDemo
    const isVDIRequired = schedules?.demo?.vdiRequired;
    const DemoStatusTooltipMessage = [];

    if (isDemoSoftDeleted) DemoStatusTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.DEMO_DELETED)
    else if (!hasAccessToDemo) DemoStatusTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.DEMO_NO_ACCESS)
    else {
        !isDemoActive && !isDemoExpired && DemoStatusTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.DEMO_INACTIVE)
        isDemoExpired && DemoStatusTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.DEMO_EXPIRED)
    }

    // To Show Edit button : 
    //   isDemoActive ->True, 
    //   hasAccessToDemo--> True, 
    //   isDemoExpired-->False, 
    //   isDemoSoftDeleted-->False

    const showEditOrRescheduleButton = isDemoActive && hasAccessToDemo && !isDemoExpired && !isDemoSoftDeleted;

    // Validating Resource Status
    const isResouceActive = schedules?.resource?.isActive && schedules?.resourceAssociationActive
    const hasAccessToResource = schedules?.userHasAccessToResource;
    const isResourceOverride = !hasAccessToResource && schedules?.adminCreatedBy !== null;
    const isResourceAssociatedWithDemo = schedules?.resourceAssociatedWithDemo
    const isResouceLdapNull = isVDIRequired && schedules?.ldapGroupNotAssigned
    const resourceTooltipMessage = [];
    if (isResourceOverride) {
        //resourceTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.RESOURCE_OVERRIDE);
        isResouceLdapNull && resourceTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.LDAP_NOT_ASSIGNED)
        !isResouceActive && resourceTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.RESOURCE_INACTIVE)
    }
    else if ((!hasAccessToResource || !isResourceAssociatedWithDemo) && !isResourceOverride) resourceTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.RESOURCE_NO_ACCESS)
    else {
        !isResouceActive && resourceTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.RESOURCE_INACTIVE)
        isResouceLdapNull && resourceTooltipMessage.push(CONFIG.dashboard.schedulesCardMessage.LDAP_NOT_ASSIGNED)
    }

    return [DemoStatusTooltipMessage, resourceTooltipMessage, showEditOrRescheduleButton];
}