import {
  FETCH_SOLUTIONS,
  TOGGLE_IS_FETCHING_SOLUTIONS,
  UPDATE_SELECTED_SOLUTION_TYPE,
  REORDER_SOLUTIONS,
  TOGGLE_IS_SOLUTIONS_REORDERED,
  UPDATE_SELECTED_SOLUTION,
  DELETE_SOLUTION,
  REORDER_SOLUTION_DEMOS,
  DELETE_SOLUTION_DEMO,
  ADD_SOLUTION_DEMO,
  CLEAR_SOLUTION_FORM,
  SELECT_DEMO_SOLUTION,
  FETCH_SOLUTION_DEMOS,
  UPDATE_SOLUTION_DEMO_SEARCH_STRING,
  CLEAR_SOLUTION_DEMOS,
  CLEAR_SOLUTIONS,
  UPDATE_SOLUTION_ROLE_TEMPLATE,
  SAVE_SOLUTIONS_ROLE_TEMPLATES,
  CANCEL_SOLUTIONS_ROLE_TEMPLATES,
  FETCH_USER_SALES_VIDEOS,
  TOGGLE_IS_FETCH_SALES_VIDEOS,
  LAST_SOLUTIONS_FILTER,
  REFRESH_DEMO_RATINGS,
  TOGGLE_DEMO_REQUEST_DELETE,
  UPDATE_DEMO_ACCESS_INFORMATION,
  REFRESH_SOLUTIONS,
  TOGGLE_HAS_SOLUTIONS_UPDATED,
  UPDATE_ID_STRING_DEMO,
  GET_SOLUTION_TYPE_DATA,
} from "../actions/types";
import CONFIG from "../config";

const solutionsState = {
  isFetching: false,
  selectedType: undefined,
  isReordered: false,
  isFetchingSalesVideos: false,

};

export const solutionsReducer = (state = solutionsState, action) => {
  switch (action.type) {
    case FETCH_SOLUTIONS:
      const allSolutions = action.payload || [];
      const activeSolutions = allSolutions?.length > 0 ? allSolutions?.filter(item => item?.isActive) : [];
      const inactiveSolutions = allSolutions?.length > 0 ? allSolutions?.filter(item => !item?.isActive) : [];
      return { ...state, data: allSolutions, activeSolutions, inactiveSolutions };
    case REORDER_SOLUTIONS:
      return { ...state, activeSolutions: action.payload };
    case FETCH_USER_SALES_VIDEOS:
      return { ...state, salesVideos: action.payload }
    case TOGGLE_IS_FETCHING_SOLUTIONS:
      return { ...state, isFetching: action.payload };
    case TOGGLE_IS_FETCH_SALES_VIDEOS:
      return { ...state, isFetchingSalesVideos: action.payload }
    case UPDATE_SELECTED_SOLUTION_TYPE:
      return { ...state, selectedType: action.payload };
    case TOGGLE_IS_SOLUTIONS_REORDERED:
      return { ...state, isReordered: action.payload };
    case LAST_SOLUTIONS_FILTER:
      return {
        ...state,
        currentFilter: action.payload.filters,
        status: action.payload.status,
        selectedSolutionType: action.payload.solutionType,
        isEdit: true,
        // currentFilter: action.payload[0],
        // status: action.payload[1],
        // isEdit: true, selectedType:
        //   action.payload[2]
      };
    case DELETE_SOLUTION:
      return {
        ...state,
        data: state.data.filter((solution) => solution.id !== action.payload),
      };
    case CLEAR_SOLUTIONS:
      return solutionsState;

    case REFRESH_SOLUTIONS:
      return {
        ...state,
        data: [],
        count: undefined
      };

    case TOGGLE_HAS_SOLUTIONS_UPDATED:
      return {
        ...state,
        hasUpdated: action.payload,
      };

    default:
      return state;
  }
};

const solutionFormState = {
  isFetching: false,
  allRoleTemplatesUserAccess: undefined,
  allRoleTemplatesAdminAccess: undefined,
  demos: []
};

export const solutionFormReducer = (state = solutionFormState, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_SOLUTION:
      return {
        ...state,
        ...action.payload,
        assignedRoleTemplates: action.payload.roleTemplates || [],
        solutionObj: {
          ...action.payload,
          demos: action.payload?.demos?.length ? action.payload?.demos.map(item => item) : []
        },
      };
    case REORDER_SOLUTION_DEMOS:
      return { ...state, demos: action.payload };
    case DELETE_SOLUTION_DEMO:
      return {
        ...state,
        demos: state.demos.filter(
          (demoObject) => demoObject.id !== action.payload
        ),
      };
    case TOGGLE_IS_FETCHING_SOLUTIONS:
      return { ...state, isFetching: action.payload };
    case ADD_SOLUTION_DEMO:
      return state.demos
        ? { ...state, demos: [...state.demos, action.payload] }
        : { ...state, demos: [action.payload] };
    case UPDATE_SOLUTION_ROLE_TEMPLATE:
      return {
        ...state,
        roleTemplates: action.payload.updatedRoleTemplates,
        [action.payload.accessKey]:
          !action.payload.selectedRoleTemplate && action.payload.access
            ? action.payload.accessType
            : CONFIG.roleTemplateAccess.none,
      };

    case CLEAR_SOLUTION_FORM:
      return solutionFormState;
    case SAVE_SOLUTIONS_ROLE_TEMPLATES:
      return { ...state, assignedRoleTemplates: state.roleTemplates };
    case CANCEL_SOLUTIONS_ROLE_TEMPLATES:
      return {
        ...state,
        roleTemplates: state.assignedRoleTemplates || [],
        allRoleTemplatesAdminAccess: undefined,
        allRoleTemplatesUserAccess: undefined,
      };
    default:
      return state;
  }
};

const demoSolutionState = {
  searchString: "",
  isRequestDeleted: false,
};

export const demoSolutionsReducer = (state = demoSolutionState, action) => {
  switch (action.type) {
    case SELECT_DEMO_SOLUTION:
      return { ...state, selectedSolution: action.payload };
    case FETCH_SOLUTION_DEMOS:
      return { ...state, demos: action.payload };
    case TOGGLE_DEMO_REQUEST_DELETE:
      return { ...state, isRequestDeleted: action.payload }
    case UPDATE_SOLUTION_DEMO_SEARCH_STRING:
      return { ...state, searchString: action.payload };
    case CLEAR_SOLUTION_DEMOS:
      return { ...state, demos: [], dataString: state.dataString };
    case UPDATE_ID_STRING_DEMO: {
      return { ...state, dataString: action.payload }
    }
    case REFRESH_DEMO_RATINGS:
      return {
        ...state, demos: state.demos?.map(demo => demo.id.toString() === action.payload?.[1]
          ? { ...demo, rating: parseInt(action.payload?.[2]), ratingCount: parseInt(action.payload?.[3]), commentCount: parseInt(action.payload?.[4]) }
          : demo)
      }
    default:
      return state;
  }

};

const demoSolutionAccessState = {
  demoAccessInformation: []
}

export const demoSolutionAccessReducer = (state = demoSolutionAccessState, action) => {
  switch (action.type) {
    case UPDATE_DEMO_ACCESS_INFORMATION:
      return { ...state, demoAccessInformation: action.payload };
    default:
      return state;
  }
}

const solutionTypeData = {
  solutionTypes: []
}

export const solutionTypeDataReducer = (state = solutionTypeData, action) => {
  switch (action.type) {
    case GET_SOLUTION_TYPE_DATA:
      return { ...state, solutionTypes: action.payload || [] };
    default: return state;
  }
}