import {
    UPDATE_NOTIFICATION_DETAILS,
    UPDATE_NOTIFICATION_DESCRIPTION,
    CLEAR_NOTIFICATION_DETAILS,
    CLEAR_NOTIFICATION_DESCRIPTION,
    FETCH_NOTIFICATIONS,
    TOGGLE_IS_FETCHING_NOTIFICATIONS,
    UPDATE_PUSH_NOTIFICATION,
    UPDATE_NOTIFICATION_ROLE_TEMPLATE,
    CLEAR_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS_CURRENT_PAGE,
    SAVE_NOTIFICATIONS_ROLE_TEMPLATES,
    CANCEL_NOTIFICATIONS_ROLE_TEMPLATES,
    FETCH_USER_NOTIFICATIONS,
    FETCH_ACTIVE_NOTIFICATIONS,
    REORDER_NOTIFICATIONS,
    TOGGLE_IS_NOTIFICATIONS_REORDERED,
    FETCH_INACTIVE_NOTIFICATIONS,
    FETCH_REAL_TIME_PUSH_NOTIFICATION,
    SAVE_REAL_TIME_PUSH_NOTIFICATION,
    TOGGLE_IS_FETCHING_BELL_NOTIFICATIONS,
    FETCH_USER_BELL_NOTIFICATIONS,
    UPDATE_USER_NOTIFICATION_COUNT,
    CLEAR_USER_BELL_NOTIFICATIONS,
    LAST_NOTIFICATION_FILTER,
    CLEAR_NOTIFICATIONS_RESPONSE,
    REFRESH_NOTIFICATIONS,
    TOGGLE_HAS_NOTIFICATIONS_UPDATED,
    CLEAR_NOTIFICATION_CONTENT,
    UPDATE_NOTIFICATION_TAB_DETAILS,
    CLEAR_NOTIFICATION_DATA,
    FETCH_DRAFT_NOTIFICATIONS,
    FETCH_USER_DASHBOARD_NOTIFICATIONS,
    CLEAR_USER_DASHBOARD_NOTIFICATIONS,
    CLOSE_NOTIFICATION_BANNER
} from '../actions/types';
import CONFIG from '../config';

const notificationsState = {
    isFetching: false,
    currentPage: 1,
    isReordered: false,
    currentPageNumber: undefined,
    currentNotificationId: undefined,
    inactiveNotifications: []
};

export const notificationsReducer = (state = notificationsState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS:
            return { ...state, data: action.payload[0].dataSet || action.payload, count: action.payload[0].count };
        case FETCH_ACTIVE_NOTIFICATIONS:
            return { ...state, activeNotifications: action.payload }
        case FETCH_INACTIVE_NOTIFICATIONS:
            return { ...state, inactiveNotifications: [...state.inactiveNotifications, ...(action.payload[0].dataSet || [])] || [], inactiveNotificationsCount: action.payload[0].count, response: action.payload[1] };
        case FETCH_DRAFT_NOTIFICATIONS:
            return { ...state, draftNotifications: action.payload };
        case FETCH_USER_NOTIFICATIONS:
            return { ...state, userNotifications: action.payload.dataSet || action.payload }
        case TOGGLE_IS_FETCHING_NOTIFICATIONS:
            return { ...state, isFetching: action.payload };
        case REORDER_NOTIFICATIONS:
            return { ...state, activeNotifications: action.payload };
        case TOGGLE_IS_NOTIFICATIONS_REORDERED:
            return { ...state, isReordered: action.payload };
        case LAST_NOTIFICATION_FILTER:
            return { ...state, activeFilterSort: action.payload[0], inactiveFilterSort: action.payload[1], draftFilterSort: action.payload[2], currentFilter: action.payload[3], status: action.payload[4], isEdit: true }
        case UPDATE_PUSH_NOTIFICATION:
            return {
                ...state, [action.payload.key]: state[action.payload.key].map(notification =>
                    notification.id === action.payload.id
                        ? { ...notification, pushNotification: action.payload.access }
                        : notification
                )
            }
        case CLEAR_NOTIFICATIONS_RESPONSE:
            return { ...state, response: action.payload }
        case CLEAR_NOTIFICATION_DATA:
            return { ...state, data: [], activeNotifications: [], inactiveNotifications: [], count: undefined, inactiveNotificationsCount: undefined }
        case CLEAR_NOTIFICATIONS:
            return notificationsState
        case UPDATE_NOTIFICATIONS_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentNotificationsId: action.payload[2] || undefined, notificationsIndex: action.payload[3] || undefined };
        case REFRESH_NOTIFICATIONS:
            return {
                ...state,
                data: [],
                count: undefined
            }
        case TOGGLE_HAS_NOTIFICATIONS_UPDATED:
            return {
                ...state,
                hasUpdated: action.payload,
            };
        default:
            return state;
    };
};

const notificationsFormState = {
    isFetching: false,
    allRoleTemplatesUserAccess: undefined,
    allRoleTemplatesAdminAccess: undefined
};

export const notificationsFormReducer = (state = notificationsFormState, action) => {
    switch (action.type) {
        case UPDATE_NOTIFICATION_DETAILS:
            return { ...state, ...action.payload, notificationObj: action.notification, assignedRoleTemplates: action.payload.roleTemplates || [] };
        case TOGGLE_IS_FETCHING_NOTIFICATIONS:
            return { ...state, isFetching: action.payload };
        case UPDATE_NOTIFICATION_ROLE_TEMPLATE:
            return {
                ...state,
                roleTemplates: action.payload.updatedRoleTemplates,
                [action.payload.accessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                    ? action.payload.accessType
                    : CONFIG.roleTemplateAccess.none
            }
        case CLEAR_NOTIFICATION_DETAILS:
            return notificationsFormState;
        case SAVE_NOTIFICATIONS_ROLE_TEMPLATES:
            return { ...state, assignedRoleTemplates: state.roleTemplates };
        case CANCEL_NOTIFICATIONS_ROLE_TEMPLATES:
            return { ...state, roleTemplates: state.assignedRoleTemplates || [], allRoleTemplatesAdminAccess: undefined, allRoleTemplatesUserAccess: undefined };
        default:
            return state;
    };
};

const notificationsDescriptionState = {
    isContentLoaded: false,
    html: undefined
};

export const notificationsDescriptionReducer = (state = notificationsDescriptionState, action) => {
    switch (action.type) {
        case UPDATE_NOTIFICATION_DESCRIPTION:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_NOTIFICATION_DESCRIPTION:
            return notificationsDescriptionState;
        default:
            return state;
    };
};

const realTimePushNotificationState = {
    messageId: []
}

export const realTimePushNotificationReducer = (state = realTimePushNotificationState, action) => {
    switch (action.type) {
        case FETCH_REAL_TIME_PUSH_NOTIFICATION:
            return { ...state, messageId: [...state.messageId] }
        case SAVE_REAL_TIME_PUSH_NOTIFICATION:
            return { ...state, messageId: state.messageId ? [...state.messageId, action.payload] : [action.payload] }
        default:
            return state;
    }
}

const userBellNotificationsState = {
    isLoading: false,
    data: [],
    maintainanceMessages: []
}

export const userBellNotificationsReducer = (state = userBellNotificationsState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_BELL_NOTIFICATIONS:
            return { ...state, isLoading: action.payload }
        case FETCH_USER_BELL_NOTIFICATIONS:
            return { ...state, data: [...state.data, ...action.payload?.messages], maintainanceMessages: action.payload?.maintainanceMessages, unreadMessageCount: action.payload?.unreadMessageCount, count: action.payload?.count }
        case UPDATE_USER_NOTIFICATION_COUNT:
            return { ...state, unreadMessageCount: 0 }
        case CLEAR_USER_BELL_NOTIFICATIONS:
            return userBellNotificationsState;
        default:
            return state;
    }
}

const userDashboardNotificationsState = {
    dashboardNotifications: [],
    showNotificationBanner: true
}

export const userDashboardNotificationsReducer = (state = userDashboardNotificationsState, action) => {
    switch (action.type) {
        case FETCH_USER_DASHBOARD_NOTIFICATIONS:
            return {
                ...state,
                dashboardNotifications: action.payload[0]?.dataSet || action.payload, dashboardNotificationsCount: action.payload[0]?.count,
                showNotificationBanner: true
            };
        case CLOSE_NOTIFICATION_BANNER:
            return { ...state, showNotificationBanner: false };
        case CLEAR_USER_DASHBOARD_NOTIFICATIONS:
            return userDashboardNotificationsState;
        default:
            return state;
    }
}

const NotificationFormState = {
    NotificationDetailsTabData: [],
    allRoleTemplatesUserAccess: undefined,
    allRoleTemplatesAdminAccess: undefined,
    htmlBody: []
}

export const notificationFormDetailsReducers = (state = NotificationFormState, action) => {
    switch (action.type) {
        case UPDATE_NOTIFICATION_DESCRIPTION:
            return {
                ...state,
                NotificationDetailsTabData: state.NotificationDetailsTabData?.map(el => (parseInt(el?.id) === parseInt(action.payload?.id) && el.isClone === action.payload?.isClone) ? { ...el, bodyHtml: action.payload?.editorContent } : el)
            };
        case UPDATE_NOTIFICATION_TAB_DETAILS:
            return {
                ...state,
                NotificationDetailsTabData: state.NotificationDetailsTabData.filter(obj => ((parseInt(obj.id) !== parseInt(action.payload.id)) || (parseInt(obj.id) === parseInt(action.payload.id) && obj.isClone !== action.payload.isClone))).concat(action.payload)
            }
        case CLEAR_NOTIFICATION_CONTENT:
            const updatedTabNotificationData = state.NotificationDetailsTabData?.filter(el => ((parseInt(el.id) !== parseInt(action.payload.id)) || ((parseInt(el.id) === parseInt(action.payload.id)) && (el?.isClone !== action.payload.isClone))));
            return { ...state, NotificationDetailsTabData: updatedTabNotificationData };
        case UPDATE_NOTIFICATION_ROLE_TEMPLATE:
            const updatedData = state.NotificationDetailsTabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.notificationId)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el,
                    details: {
                        ...el.details,
                        roleTemplates: action.payload.updatedRoleTemplates,
                        [action.payload.accessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                            ? action.payload.accessType
                            : CONFIG.roleTemplateAccess.none
                    }
                }
                : el)
            return {
                ...state, NotificationDetailsTabData: updatedData,
            }
        case SAVE_NOTIFICATIONS_ROLE_TEMPLATES:
            const updatedRTSaveData = state.NotificationDetailsTabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.id)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el, details: {
                        ...el.details,
                        assignedRoleTemplates: el.details.roleTemplates
                    }
                } : el)
            return { ...state, NotificationDetailsTabData: updatedRTSaveData };

        case CANCEL_NOTIFICATIONS_ROLE_TEMPLATES:
            const updatedRTCancelData = state.NotificationDetailsTabData?.map(el =>
                ((parseInt(el.id) === parseInt(action.payload.id)) && (el.isClone === action.payload.isClone))
                    ? {
                        ...el, details: {
                            ...el.details,
                            roleTemplates: el.details.assignedRoleTemplates,
                            allRoleTemplatesUserAccess: undefined,
                            allRoleTemplatesAdminAccess: undefined
                        }
                    } : el)
            return { ...state, NotificationDetailsTabData: updatedRTCancelData };
        default:
            return state
    }
}