export const getAvatarColor = (alphabet) => {
    if (['A', 'F', 'K', 'P', 'U', 'Z'].includes(alphabet?.toUpperCase()))
        return '#E32636'
    else if (['B', 'G', 'L', 'Q', 'V'].includes(alphabet?.toUpperCase()))
        return '#0095B6'
    else if (['C', 'H', 'M', 'R', 'W'].includes(alphabet?.toUpperCase()))
        return '#900020'
    else if (['D', 'I', 'N', 'S', 'X'].includes(alphabet?.toUpperCase()))
        return '#218559'
    else if (['E', 'J', 'O', 'T', 'Y'].includes(alphabet?.toUpperCase()))
        return '#191970'
    else return '#e22e5d'
}