import {
    TOGGLE_IS_FETCHING_DAILY_ATTRIBUTES,
    GET_ALL_DAILY_ATTRIBUTES,
    DELETE_DEMO_ATTRIBUTE,
    ADD_NEW_DEMO_ATTRIBUTE,
    CLEAR_ATTRIBUTE_FORM,
    GET_EACH_DAILY_ATTRIBUTE,
    CLEAR_DAILY_ATTRIBUTE,
    GET_ALL_PRODUCTS_FOR_ATTRIBUTES,
    LAST_ATTRIBUTE_FILTERS
} from '../actions/types';

const dailyAttributeState = {
    loading: false,
    demo: []
}

export const dailyAttributeReducer = (state = dailyAttributeState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_DAILY_ATTRIBUTES:
            return { ...state, loading: action.payload }
        case GET_ALL_DAILY_ATTRIBUTES:
            return { ...state, data: action.payload }
        case GET_ALL_PRODUCTS_FOR_ATTRIBUTES:
            return { ...state, allProducts: action.payload?.dataSet || [] }
        case ADD_NEW_DEMO_ATTRIBUTE:
            return { ...state, demo: [...state.demo, { demo: action.payload.demo }] };
        case DELETE_DEMO_ATTRIBUTE:
            return { ...state, demo: state.demo.filter(eachDemo => eachDemo.demo.id !== action.payload) };
        case CLEAR_ATTRIBUTE_FORM:
            return { ...state, demo: [], attribute: null }
        case GET_EACH_DAILY_ATTRIBUTE:
            return { ...state, attribute: action.payload }
        case CLEAR_DAILY_ATTRIBUTE:
            return dailyAttributeState
        case LAST_ATTRIBUTE_FILTERS:
            return {...state, filter: action.payload[0],sortInfo: action.payload[1], isViewDeleted: action.payload[2], searchString: action.payload[3] ,isTabOpen: true}
        default: return state
    }
}