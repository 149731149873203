import {
    FETCH_ALL_USERS,
    TOGGLE_IS_FETCHING_USERS,
    UPDATE_USER_EDITING_KEY,
    UPDATE_USER,
    UPDATE_USERS_CURRENT_PAGE,
    CLEAR_USERS,
    REFRESH_SSO_USERS,
    UPDATE_SSO_USER_FILTERS
} from '../actions/types';

const userState = {
    isFetching: false,
    currentPage: 1,
    editingKey: "",
    data: [],
};

export const userRoleReducer = (state = userState, action) => {
    switch (action.type) {
        case FETCH_ALL_USERS:
            return { ...state, data: action.payload || [], count: action.payload?.length };
        case UPDATE_USER:
            return {
                ...state,
                data: state.data.map(user =>
                    user.id === action.userId
                        ? {
                            ...user,
                            roleTemplates: action.payload.roleTemplates?.map(roleTemplate => JSON.parse(roleTemplate)),
                        }
                        : user
                )
            };
        case UPDATE_USERS_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case TOGGLE_IS_FETCHING_USERS:
            return { ...state, isFetching: action.payload };
        case UPDATE_USER_EDITING_KEY:
            return { ...state, editingKey: action.payload };
        case UPDATE_SSO_USER_FILTERS:
            return { ...state, filterSort: action.payload.filterSort, pageNumber: action.payload.pageNumber, isEdit: true, pageSize: action.payload.pageSize }
        case CLEAR_USERS:
            return userState;
        case REFRESH_SSO_USERS:
            return { ...state, data: [], count: undefined }
        default:
            return state;
    }
};
