import React from 'react';
import { connect, batch } from 'react-redux';
import { Table, Checkbox } from 'antd';

import { updateRoleTemplateDemos, fetchDemos, toggleIsFetchingDemosFlag } from '../../actions';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';

const dataIndexMap = {
    title: 'title'
};

class RoleTemplatesDemos extends React.Component {

    async componentDidMount() {
        const { userRoleTemplate } = this.props;
        this.access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
        this.props.toggleIsFetchingDemosFlag(true);
        await this.props.fetchDemos({ pageSize: CONFIG.shortPageSize + 1, access: this.access });
    }

    getDemoAccess = (demoId, roleType) => {
        const { assignedDemos } = this.props;
        const demo = assignedDemos ? assignedDemos.find(assignedDemo => assignedDemo.demo.id === demoId) : undefined;
        return demo && roleType
            ? roleType === CONFIG.roleTypes.user
                ? demo.userAccess
                : demo.adminAccess
            : undefined;
    }

    renderCheckbox = (demo, roleType, allowedAccess) => {
        const demoAccess = this.getDemoAccess(demo.id, roleType);
        return (
            <Checkbox
                checked={demoAccess === allowedAccess}
                onClick={e => this.props.updateRoleTemplateDemos(e.target.checked, roleType, allowedAccess, demo)}
            />
        );
    };

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                handleClear={this.handleClearSearch}
                handleSearch={this.handleSearch}
            />
        )
    }

    handleSearch = (searchString, confirm) => {
        if (searchString?.trim()) {
            confirm();
        }
        else pushMessage(CONFIG.messageType.warning, 'Please enter search text');
    }

    handleClearSearch = (clearFilters) => {
        clearFilters()
    }

    handleChange = (pagination, filter, sorter) => {

        const filterSort = {
            filter: filter[dataIndexMap.title]?.[0] ? { [dataIndexMap.title]: filter[dataIndexMap.title]?.[0] } : undefined,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }

        this.selectAllDisabled = filterSort.filter !== undefined;
        batch(() => {
            this.props.toggleIsFetchingDemosFlag(true);
            this.props.fetchDemos({ pageNumber: pagination.current, pageSize: CONFIG.shortPageSize + 1, filterSort, access: this.access })
        })

    }

    render() {
        const { demos, count, isFetchingDemos, templateType } = this.props;
        this.adminColumns = [
            {
                title: <span className="primary-text-color">Admin</span>,
                children: [
                    {
                        title: (
                            <div>
                                Read
                                <br />
                                <Checkbox
                                    checked={this.props.allDemosAdminAccess === CONFIG.roleTemplateAccess.readOnly}
                                    onChange={e => this.props.updateRoleTemplateDemos(e.target.checked, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.readOnly)}
                                    disabled={(demos && demos.length === 0) || this.selectAllDisabled}
                                />
                            </div>
                        ),
                        key: "adminReadAccess",
                        align: "center",
                        width: '10%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.readOnly)
                    },
                    {
                        title: (
                            <div>
                                Write
                                <br />
                                <Checkbox
                                    checked={this.props.allDemosAdminAccess === CONFIG.roleTemplateAccess.fullAccess}
                                    onChange={e => this.props.updateRoleTemplateDemos(e.target.checked, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.fullAccess)}
                                    disabled={(demos && demos.length === 0) || this.selectAllDisabled}
                                />
                            </div>
                        ),
                        key: "adminWriteAccess",
                        align: "center",
                        width: '10%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.fullAccess)
                    }
                ]
            }
        ]
        this.columns = [
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
                width: '60%',
                align: "left",
                sorter: true,
                filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "title")
            },
            {
                title: "User",
                children: [
                    {
                        title: (
                            <div>
                                Read-only
                                <br />
                                <Checkbox
                                    checked={this.props.allDemosUserAccess === CONFIG.roleTemplateAccess.readOnly}
                                    onChange={e => this.props.updateRoleTemplateDemos(e.target.checked, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.readOnly)}
                                    disabled={(demos && demos.length === 0) || this.selectAllDisabled}
                                />
                            </div>
                        ),
                        key: "userReadAccess",
                        align: "center",
                        width: '10%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.readOnly)
                    },
                    {
                        title: (
                            <div>
                                Full-access
                                <br />
                                <Checkbox
                                    checked={this.props.allDemosUserAccess === CONFIG.roleTemplateAccess.fullAccess}
                                    onChange={e => this.props.updateRoleTemplateDemos(e.target.checked, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.fullAccess)}
                                    disabled={(demos && demos.length === 0) || this.selectAllDisabled}
                                />
                            </div>
                        ),
                        key: "userFullAccess",
                        align: "center",
                        width: '10%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.fullAccess)
                    }
                ]
            }
        ];

        return (
            <Table
                size='middle'
                className="responsive-container top-spacing"
                bordered
                columns={templateType === CONFIG.roleTypes.admin ? [...this.columns, ...this.adminColumns] : this.columns}
                loading={isFetchingDemos}
                dataSource={demos || []}
                rowKey={(record) => record.id}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1,
                    total: count
                }}
            />
        );
    }
}

const mapStateToProps = ({ demos, roleTemplate, user }) => {
    return {
        demos: demos.data,
        isFetchingDemos: demos.isFetching,
        count: demos.count,
        assignedDemos: roleTemplate.demos,
        allDemosUserAccess: roleTemplate.allDemosUserAccess,
        allDemosAdminAccess: roleTemplate.allDemosAdminAccess,
        templateType: roleTemplate.templateType,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined
    };
};

export default connect(
    mapStateToProps,
    { updateRoleTemplateDemos, fetchDemos, toggleIsFetchingDemosFlag }
)(RoleTemplatesDemos);