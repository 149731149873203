import React from 'react';
import { connect, batch } from 'react-redux'
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import CONFIG from '../../config';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import {
    updateRoleTemplateDemoQuestions,
    toggleIsFetchingDemoQuestions,
    fetchAllDemoQuestions
}
    from '../../actions';

class RoleTemplatesDemoQuestions extends React.Component {

    state = {
        selectAllDisabled: false
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    columnFilters = {
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "demoQuestions"),
        sorter: true
    }

    filterSort = {}

    handleFilter = (title, confirm) => {
        title = title?.trim();
        if (!title && !this.filterSort.filter) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else confirm()
    }

    handleClear = (clearFilters) => {
        clearFilters();
    }

    handleChange = (pagination, filters, sorter) => {

        this.filterSort = {
            filter: filters.title?.[0] ? { title: filters.title[0] } : undefined,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        batch(() => {
            this.props.toggleIsFetchingDemoQuestions(true);
            this.props.fetchAllDemoQuestions({
                pageNumber: pagination.current,
                pageSize: (CONFIG.shortPageSize + 1),
                access: this.access,
                filterSort: this.filterSort
            });
        });
    }

    componentDidMount() {
        const { userRoleTemplate } = this.props;
        this.access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
        this.props.toggleIsFetchingDemoQuestions(true);
        this.props.fetchAllDemoQuestions({});
    }

    render() {
        const { demoQuestions, count, assignedDemoQuestion, isFetching, allDemoQuestionsAdminAccess, updateRoleTemplateDemoQuestions } = this.props;

        return (
            <RoleTemplatesTable
                dataKey="question"
                dataIndex="title"
                hideColumns={['user']}
                marginTop={true}
                bordered={true}
                data={demoQuestions}
                selectAllDisabled={this.filterSort.filter !== undefined}
                columnFilters={this.columnFilters}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1,
                    total: count
                }}
                isFetching={isFetching}
                assignedData={assignedDemoQuestion}
                allAdminAccess={allDemoQuestionsAdminAccess}
                updateRoleTemplateData={updateRoleTemplateDemoQuestions}
            />
        );

    }

}
const mapStateToProps = ({ demoQuestions, roleTemplate, user }) => {
    return {
        demoQuestions: demoQuestions?.questions,
        assignedDemoQuestion: roleTemplate.demoQuestions,
        roleTemplate,
        count: demoQuestions.questionCount,
        isFetching: demoQuestions?.isFetching,
        allDemoQuestionsAdminAccess: roleTemplate.allDemoQuestionsAdminAccess,
        templateType: roleTemplate.templateType,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined
    }
}

export default connect(mapStateToProps,
    {
        fetchAllDemoQuestions,
        updateRoleTemplateDemoQuestions,
        toggleIsFetchingDemoQuestions
    })
    (RoleTemplatesDemoQuestions);