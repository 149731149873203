import React from 'react';
import { Radio, Row, Col, Select, Badge, Breadcrumb, Spin, Table, message, Dropdown, Divider } from 'antd';
import { DualAxes, Pie, Line, Column } from '@ant-design/charts';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { fetchTrendingDemos, openLinkInNewTab, exportCommonStandardReport } from '../../actions';
import CONFIG from '../../config';
import NoContent from '../common/NoContent';
import pushMessage from '../common/PushMessage';
import { exportMenu } from "../../utils/strings";
import { Button } from "@avaya/neo-react";
import NeoTable from '../common/NeoTable';

const { Option } = Select;

class TrendingDemos extends React.Component {

    state = {
        graphType: CONFIG.graphTypes.graph,
        graphSubType: CONFIG.graphTypes.lineGraph,
        isDatePickerVisible: false
    }

    handleMonthPickerChange = (date) => {
        this.startDate = date?.startOf('month')?.startOf('day').toISOString();
        this.endDate = date?.endOf('month')?.endOf('day').toISOString();
        date && this.props.fetchTrendingDemos({ graphType: this.state.graphType, startDate: this.startDate, timeline: this.timeline, endDate: this.endDate })
    }

    handleGraphTypeChange = (e) => {
        const graphType = e.target.value;
        this.setState({ graphType: graphType });
        this.props.handleTrendingDemosRunReport(graphType);
    }

    renderTimelineOptions = () => {
        return CONFIG.trendingDemosTimeline.map(value => <Option key={value} value={value}>{value}</Option>)
    }

    getStartDate = (type) => {
        const { timeZoneName } = this.props;
        switch (type) {
            case CONFIG.trendingDemosTimeline[0]:
                this.endDate = moment.tz(moment(), timeZoneName).endOf('day').toISOString();
                return moment.tz(moment(), timeZoneName).subtract(6, 'days').startOf('day').toISOString();
            case CONFIG.trendingDemosTimeline[2]:
                this.endDate = moment.tz(moment(), timeZoneName).subtract(1, 'months').endOf('month').endOf('day').toISOString();
                return moment.tz(moment(), timeZoneName).subtract(3, 'months').startOf('month').startOf('day').toISOString();
            case CONFIG.trendingDemosTimeline[3]:
                this.endDate = moment.tz(moment(), timeZoneName).subtract(1, 'months').endOf('month').endOf('day').toISOString();
                return moment.tz(moment(), timeZoneName).subtract(6, 'months').startOf('month').startOf('day').toISOString();
            case CONFIG.trendingDemosTimeline[4]:
                this.endDate = moment.tz(moment(), timeZoneName).subtract(1, 'year').endOf('year').endOf('day').toISOString();
                return moment.tz(moment(), timeZoneName).subtract(1, 'year').startOf('year').startOf('day').toISOString();
            default:
                this.endDate = moment.tz(moment(), timeZoneName).endOf('day').toISOString();
                return moment.tz(moment(), timeZoneName).subtract(30, 'days').startOf('day').toISOString();
        }

    }

    componentDidMount() {
        const { graphType } = this.state;
        const { startTime, endTime, timeLine } = this.props;
        this.startDate = startTime;
        this.endDate = endTime;
        this.timeline = timeLine;
        this.props.fetchTrendingDemos({ graphType, startDate: this.startDate, timeline: this.timeline, endDate: this.endDate })
    }

    handleSelectChange = (value) => {
        const { graphType } = this.state;
        this.timeline = value;
        this.startDate = this.getStartDate(value);
        const customMonth = value === CONFIG.trendingDemosTimeline[5]
        this.setState({ isDatePickerVisible: customMonth ? true : false })
        if (!customMonth) this.props.fetchTrendingDemos({ graphType, startDate: this.startDate, timeline: value, endDate: this.endDate })
    }

    handleRunReport = () => {
        const { graphType } = this.state;
        this.props.handleTrendingDemosRunReport(graphType);
    }

    getBarGraphData = () => {
        const { graphData } = this.props;
        return graphData.map((demo, index) => {
            return {
                ...demo,
                time: demo.month || demo.week?.replace(" - ", "-") || demo.day?.replace(/-/gi, "/")
            }
        })
    }

    getLineGraphData = () => {
        const { graphData } = this.props;
        return graphData.map((demo, index) => {
            return {
                ...demo,
                demoTitle: demo.demoTitle + " ",
                count: demo.scheduleCount,
                time: demo.month || demo.week?.replace(" - ", "-") || demo.day?.replace(/-/gi, "/")
            }
        })
    }

    handleDemoSelect = (demo) => {
        this.props.openLinkInNewTab(demo?.demoTitle, `/solutions/demo/${demo?.demoId}`)
    }

    handleClick = (graphType) => {
        this.setState({ graphSubType: graphType })
    }

    getColumns = () => {
        const { columnData } = this.props
        const columns = columnData?.map((item) => {
            return {
                key: item,
                dataIndex: item,
                title: item,
                align: 'left',
                width: 125
            };
        })
        return columns ? [{ key: "type", dataIndex: "type", title: " ", align: 'left', width: 400 }, ...columns] : [];

    }

    getTableData = () => {
        const { tableData } = this.props
        const rows = tableData?.map((item, index) => {
            return {
                id: index, type: item?.demoName, ...item["count"]?.reduce((acc, item) => {
                    acc[item.column] = item["scheduleCount"];
                    return acc
                }, {})
            }
        })
        return rows || [];
    }

    handleExport = async (fileType) => {
        const report = this.props?.reportName;
        if (report === undefined || report === "") {
            pushMessage(CONFIG.messageType.error, "Please enter Report Name");
            return;
        }
        let reportName = report + `.${fileType}`;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        this.props.exportCommonStandardReport({ name: reportName, filterSort: this.props.filterSort, isRunByUser: this.props.userRun === "fromUser", reportId: null, reportKey: CONFIG.standardReportKeys.trendingDemos, fileFormat: fileType })
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    render() {
        const { graphType, graphSubType } = this.state;
        const { graphData, pieChartData, shouldHideRunQuery, userRun } = this.props;
        const empty = <NoContent description="No Demos" className="top-spacing-8" />
        const tableData = this.getTableData();
        const columnsData = this.getColumns();
        const barGraphData = this.getBarGraphData();
        const lineGraphData = this.getLineGraphData();

        const tooltipConfig = {
            follow: true,
            enterable: true,
            offset: 5,
            customContent: (value, items) => {
                items.reverse();
                const uniqueItems = [...new Map(items.map(item => [item['name'].trim(), item])).values()]
                uniqueItems.reverse();
                const container = <div style={{ padding: '20px' }}>
                    {uniqueItems.map((item, index) => (
                        <div key={index}>
                            <Badge color={item.color} className="vertical-spacing-3" text={<span className="hover-link" onClick={() => this.handleDemoSelect(item.data)}>
                                {`${item.name} - ${item.value}`}</span>} />
                        </div>
                    ))}
                </div>
                return container;
            }
        }

        const graphConfig = {
            data: [barGraphData, lineGraphData],
            xField: 'time',
            xAxis: {
                label: {
                    autoRotate: false,
                    offset: 10,
                    style: {
                        fontSize: 11,
                    }
                }
            },
            yField: ['scheduleCount', 'count'],
            autoFit: true,
            geometryOptions: [
                {
                    geometry: 'column',
                    isStack: true,
                    seriesField: 'demoTitle',
                    columnWidthRatio: 0.4,
                    color: ['#B5C6E7', '#2D64A7', '#004066', '#F6662A', '#E5350B']
                },
                {
                    geometry: 'line',
                    seriesField: 'demoTitle',
                    color: ['#B5C6E7', '#2D64A7', '#004066', '#F6662A', '#E5350B'],
                    lineStyle: () => {
                        return {
                            lineDash: [1, 4],
                            opacity: 1,
                        };
                    },
                }
            ],
            tooltip: { ...tooltipConfig },
            legend: {
                itemWidth: 170,
                position: 'right'
            }
        };

        const pieChartConfig = {
            appendPadding: 10,
            autoFit: true,
            data: pieChartData,
            angleField: 'scheduleCount',
            colorField: 'demoTitle',
            radius: 0.8,
            legend: {
                itemWidth: 170,
                position: 'right'
            },
            label: {
                type: 'inner',
                offset: '-30%',
                content: '{value}',
                style: {
                    fontSize: 14,
                    textAlign: 'center',
                },
            },
            color: ['#B5C6E7', '#2D64A7', '#004066', '#F6662A', '#E5350B'],
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            tooltip: { ...tooltipConfig },
        }

        var lineGraphConfig = {
            data: lineGraphData,
            autoFit: true,
            xField: 'time',
            xAxis: {
                label: {
                    autoRotate: false,
                    offset: 10,
                    style: {
                        fontSize: 11,
                    }
                }
            },
            yField: 'count',
            seriesField: 'demoTitle',
            color: ['#B5C6E7', '#2D64A7', '#004066', '#F6662A', '#E5350B'],
            legend: {
                itemWidth: 170,
                showTitle: true,
                position: 'right'
            },
            smooth: true,
            animation: {
                appear: {
                    animation: 'path-in',
                    duration: 4000,
                },
            },
            tooltip: { ...tooltipConfig },
        };

        var barGraphConfig = {
            data: barGraphData,
            autoFit: true,
            xField: 'time',
            xAxis: {
                label: {
                    autoRotate: false,
                    offset: 10,
                    style: {
                        fontSize: 11,
                    }
                }
            },
            yField: 'scheduleCount',
            color: ['#B5C6E7', '#2D64A7', '#004066', '#F6662A', '#E5350B'],
            seriesField: 'demoTitle',
            legend: {
                itemWidth: 170,
                position: 'right'
            },
            isStack: true,
            tooltip: { ...tooltipConfig },
        };

        return (
            <div className="font">
                <Row>
                    <Col xl={15} sm={24}>
                        <div className='large-text'>Output</div>
                    </Col>
                    <Col xl={9} sm={24} className="right-align"> 
                        {
                            userRun !== "fromUser" &&
                            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                                <Button className="left-spacing-3" variant='secondary' icon='chevron-down' dir="rtl">
                                    Export
                                </Button>
                            </Dropdown>
                        }
                         {!shouldHideRunQuery && <Button className="primary-action-button-filled left-spacing-3" onClick={() => this.handleRunReport()}>Run Query</Button>}
                    </Col>
                    <Col span={12}>
                        <Radio.Group value={this.state.graphType} onChange={this.handleGraphTypeChange}>
                            <Radio.Button value={CONFIG.graphTypes.graph}>Graph</Radio.Button>
                            <Radio.Button value={CONFIG.graphTypes.pieChart}>Pie Chart</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                {this.state.graphType === CONFIG.graphTypes.graph &&
                    <>
                        <br />
                        <Breadcrumb>
                            <Breadcrumb.Item><span className={graphSubType === CONFIG.graphTypes.lineGraph ? "link" : "hover-link"} onClick={() => this.handleClick("Line Graph")}>Trending</span></Breadcrumb.Item>
                            <Breadcrumb.Item><span className={graphSubType === CONFIG.graphTypes.barGraph ? "link" : "hover-link"} onClick={() => this.handleClick("Bar Graph")}>Demo Count</span></Breadcrumb.Item>
                            <Breadcrumb.Item><span className={graphSubType === CONFIG.graphTypes.dualGraph ? "link" : "hover-link"} onClick={() => this.handleClick("Dual Graph")}>Combination</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </>
                }
                <br />
                <Spin spinning={this.props.isLoading}>
                    {graphType === CONFIG.graphTypes.graph ? graphData.length === 0 ? empty : graphSubType === CONFIG.graphTypes.lineGraph ? <Line {...lineGraphConfig} height={300} /> : graphSubType === CONFIG.graphTypes.barGraph ? <Column {...barGraphConfig} /> : <DualAxes {...graphConfig} /> : pieChartData.length === 0 ? empty : <Pie {...pieChartConfig} />}
                </Spin>
                <Divider />
                {graphType === CONFIG.graphTypes.graph &&
                    (userRun === "fromUser" ? <NeoTable
                        size='middle'
                        cn="responsive-container"
                        rowKey={(record) => record.id}
                        columns={columnsData}
                        dataSource={tableData}
                        scroll={{ x: 800 | true, y: "none" | false }}
                        loading={this.props.isLoading}
                    /> : <Table
                        size='middle'
                        className="responsive-container"
                        rowKey={(record) => record.id}
                        bordered
                        columns={columnsData}
                        dataSource={tableData}
                        onChange={this.handleChange}
                        scroll={{ x: 800 | true, y: "none" | false }}
                        loading={this.props.isLoading}
                        pagination={false}
                    />)}

            </div>
        )
    }
};

const mapStateToProps = ({ user, trendingDemos }) => {
    return {
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        graphData: trendingDemos?.graphData?.graph || [],
        tableData: trendingDemos?.graphData?.data || [],
        columnData: trendingDemos?.graphData?.columns || [],
        pieChartData: trendingDemos.pieChartData || [],
        refresh: trendingDemos.refresh,
        isLoading: trendingDemos.isLoading
    };
};

export default connect(
    mapStateToProps,
    {
        fetchTrendingDemos,
        openLinkInNewTab,
        exportCommonStandardReport
    }
)(TrendingDemos);

