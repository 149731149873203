import { batch } from 'react-redux';
import { aepUser, initializeInterceptors } from '../apis';
import openInfo from '../components/common/Info';
import { toggleIsFirstTimeLogin, setIsLoginComplete, fetchUserTabLimits, showPortalTourModal } from './user';
import { updateSchedulerTimezone } from './scheduler';
import CONFIG from "../config";
import history from '../history';
import pushMessage from '../components/common/PushMessage';
import { encrypt } from "../utils/aesDecrypt";
import { closeAllTabs, addNewTab } from "./tabsLayout";

import {
    FETCH_USER,
} from './types';

export const login = (aepHeader = undefined, email = undefined, password = undefined) => {
    return async (dispatch, getState) => {
        let response;
        if (aepHeader) {
            response = await aepUser.get(`userTemplateService/validate/configDetails/`, {
                headers: {
                    'aep-header': aepHeader
                }
            });
        }
        else {
            const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
            response = await aepUser.post(`/backendLoginService/configDetails`, {
                email: isEmail ? email : undefined,
                sslHandle: isEmail ? undefined : email,
                password: encrypt(password).toString()
            });
        }
        const aepHeaderValue = response.headers['aep-header']
        if (response.status === CONFIG.HTTP_STATUS.OK) {
            initializeInterceptors(aepHeaderValue);
            batch(async () => {
                dispatch({
                    type: FETCH_USER,
                    payload: response.data
                });

                const state = getState();
                const isSuperAdminUser = state.user && state.user?.profile?.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
                isSuperAdminUser && dispatch(fetchUserTabLimits());
                dispatch(setIsLoginComplete(aepHeaderValue));

                if (!response.data.user.firstTimeLogin) {
                    dispatch(updateSchedulerTimezone(response.data.user.timezone));
                    dispatch(toggleIsFirstTimeLogin(false));
                    dispatch(showPortalTourModal(!(response.data.user.isPortalTourCompleted), false));
                }
                else if (response.data.user.firstTimeLogin) {
                    openInfo(CONFIG.systemMessage.firstTimeLoginTitle, CONFIG.systemMessage.firstTimeLoginContentnonSSO, null, "OK");
                    dispatch(toggleIsFirstTimeLogin(true));
                    await dispatch(closeAllTabs());
                    await dispatch(addNewTab('Welcome', "/my-profile"));
                    await history.replace({ pathname: '/my-profile' });
                }


            });
            localStorage.setItem('header', response.headers['aep-header']);
        }
        else if (response.status === CONFIG.HTTP_STATUS.NOT_ACCEPTABLE) {
            pushMessage(CONFIG.messageType.error, 'Invalid Email or Password');
        }
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    const contextPath = '/backendLoginService/user/createToken';

    const response = await aepUser({
        method: 'post',
        url: contextPath,
        params: { email }
    })
    if (response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        throw Error("The entered email is not registered with Avaya Experience Portal")
}

export const logoutUser = () => async (dispatch) => {
    const contextPath = '/auth/inValidateSession';
    await aepUser({
        method: 'get',
        url: contextPath,
    })
}