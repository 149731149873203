import React, { useState } from 'react'
import {  Row,  Divider, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Button, Chip } from "@avaya/neo-react";
import CONFIG from "../../config"
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {
    openLinkInNewTab
} from '../../actions';

const ViewReports = ({
    reports,
    timeZoneName,
    handleExport,
    handleModalOpen,
    reportId,
    fromMyReports
}) => {
    const dispatch = useDispatch();
    const [readMore, setReadMore] = useState({});
    if (reportId) reports = reports?.filter(report => report.id.toString() === reportId);
 
    const expandReadMore = (desc, eachItem ) => {
        const description = desc;
        return (
            <p style={{ fontSize: "12px", cursor: "pointer" }}>
                {readMore[`${eachItem?.id}`] ? description : description?.slice(0, 400)}
                <span
                    style={{ fontSize: "12px", color: "#6DB5EC", cursor: "pointer" }}
                    onClick={() => toggleReadMore(eachItem)}
                     >
                    {description?.length > 400 ? readMore[`${eachItem?.id}`] ? " show less" : "...read more" : null}
                </span >
            </p>
        );
    }

    const toggleReadMore = (eachItem) => {
        const id = `${eachItem?.id}`;
        let modifiedArray = readMore
        if (readMore[id] === undefined) { // when user has not clicked for any time
            modifiedArray[id] = true
        }
        else { //when user already clicked
            let value = modifiedArray[id]
            modifiedArray[id] = !value;
        }
        setReadMore({...modifiedArray});
    }

    return (
        <>
            <Row >
                <span className='dashboard-schedules-category-heading'>{fromMyReports ? "My Reports": "Standard Reports"}</span>
                <div className='reports-menu' >
                {reports.map((report) => {
                    const reportType = `Report Type: ${report?.filterTable?.tableName}`
                    return (
                        <div id="report-component-content" className='reports-items font'>
                <div style={{ width: "100%" }}>
                    <span className='reports-menu-title'>
                        {report.name}
                        {<Tooltip title={reportType}>
                            <Chip variant="info" className="left-spacing-3">{report?.filterTable?.tableName}</Chip>
                            
                        </Tooltip>}
                    </span>
            <Button  
            variant="primary" 
            className="float-right left-spacing-3"
            onClick = {() => dispatch(openLinkInNewTab(report.name, `/reports/view/${report?.id}/${fromMyReports}`))} >View Details </Button>
            <Button
                    variant="secondary"
                    className="float-right left-spacing-3"
                onClick={() => handleExport(report)}>
                Export
            </Button>
            {!fromMyReports && <Button
            variant="tertiary"
            className="float-right left-spacing-3"
            onClick={() => handleModalOpen(report)}>
            Clone
            </Button>}
                </div>
                <div style={{ marginBottom: "10px" }} >
                    <div>
                    <span className='demo-ratings'>{report?.updatedTime ? "Updated Time: ": "Created Time: "}</span><span style={{ fontSize: "12px" }}>{moment.tz(report?.updatedTime ? report?.updatedTime: report?.createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</span>
                    </div>
                    {report?.lastRunTime && <div>
                    <span className='demo-ratings'>Last run Time: </span><span style={{ fontSize: "12px" }}>{moment.tz(report?.lastRunTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</span>
                    </div>}
                </div>
                <div style={{ width: "100%" }}>
                    {/* <span style={{ fontSize: "12px", cursor: "pointer" }}>{report?.description}</span> */}
                    {expandReadMore(report?.description, report)}
                </div>
                <Divider className="ez-demo-divider" />			
            </div>
            )
                })}
                </div>
            </Row>
        </>
    )
}

export default connect(null, {
    openLinkInNewTab
})(ViewReports);