import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment-timezone';
import CONFIG from '../../config';

class MaintenanceAvailability extends React.Component {

    columns = [
        {
            title: 'Name',
            key: 'name',
            width: "15%",
            render: (record) => {
                const userProfile = record.updatedBy === null ? record.createdBy : record.updatedBy;
                return (
                    userProfile.firstName + " " + userProfile.lastName
                );
            }
        },
        {
            title: 'Email',
            key: 'email',
            width: "15%",
            render: (record) => {
                const userProfile = record.updatedBy === null ? record.createdBy : record.updatedBy;
                return userProfile.email
            }
        },
        {
            title: 'Start Date',
            dataIndex: 'startTime',
            width: "15%",
            key: 'startDate',
            align: 'center',
            render: (startTime) => moment.tz(startTime, this.props.timeZone).format(CONFIG.dateFormats.userDateTime)
        },
        {
            title: 'End Date',
            dataIndex: 'endTime',
            key: 'endDate',
            width: "15%",
            align: 'center',
            render: (endTime) => moment.tz(endTime, this.props.timeZone).format(CONFIG.dateFormats.userDateTime)
        }
    ];

    resourceScheduleColumns = [
        {
            title: 'Buffer Start Time',
            dataIndex: 'startTimeBuffer',
            key: 'startTimeBuffer',
            width: "15%",
            align: 'center',
            render: (startTimeBuffer, record) => moment.tz(record.startTime, this.props.timeZone).subtract(startTimeBuffer, "minutes").format(CONFIG.dateFormats.userTime)
        },
        {
            title: 'Buffer End Time',
            dataIndex: 'endTimeBuffer',
            key: 'endTimeBuffer',
            width: "15%",
            align: 'center',
            render: (endTimeBuffer, record) => moment.tz(record.endTime, this.props.timeZone).add(endTimeBuffer, "minutes").format(CONFIG.dateFormats.userTime)
        }
    ]

    maintenanceColumns = [
        {
            title: 'Maintenance Title',
            dataIndex: 'title',
            key: 'title',
            width: "15%",
            render: (record) => record ? record : ""
        }
    ]

    render() {
        const { selectedResource, resourceSchedules, maintenanceSchedules, selectedErrorType, labSchedules } = this.props;
        const tableData = selectedErrorType === CONFIG.maintenanceErrorTypes.resourceDemos
            ? resourceSchedules.find(resource => resource.id === selectedResource).schedules
            : selectedErrorType === CONFIG.maintenanceErrorTypes.maintenance ?
                maintenanceSchedules
                : [labSchedules.find(schedules => schedules.id === selectedResource)]
        const columns = selectedResource ? [...this.columns, ...this.resourceScheduleColumns] : [...this.maintenanceColumns, ...this.columns];
        return (
            <Table
                size='middle'
                className="responsive-container vertical-spacing"
                record={(record) => record.id}
                columns={columns}
                dataSource={tableData}
                scroll={{ y: 550, x: "none" }}
                bordered
                pagination={false}
            />
        );
    }
}

const mapStateToProps = ({ maintenanceForm, user }) => {
    return {
        maintenanceSchedules: maintenanceForm.maintenanceSchedules,
        resourceSchedules: maintenanceForm.resourceSchedules,
        labSchedules: maintenanceForm.labSchedules,
        selectedResource: maintenanceForm.selectedResource,
        selectedErrorType: maintenanceForm.selectedErrorType,
        timeZone: user.profile.timezone
    };
};

export default connect(
    mapStateToProps
)(MaintenanceAvailability);