import React from 'react';
import { Button, Form, Icon } from 'antd';
import { connect } from 'react-redux';
import CONFIG from '../../config.js';
import { handleOnClickAction } from "../../actions";

class MaintenanceFormContainer extends React.Component {
  render() {
    const { fromDashboard } = this.props;
    return (
      <Form className="maintenance-form">
        {
          fromDashboard !== true &&
          <Button className="primary-action-button-filled" onClick={async () => {
            await this.props.handleOnClickAction({
              component: CONFIG.createComponentRoute.scheduleMaintenance,
              tabTitle: "Create"
            })
          }}>New
            <Icon type="plus-circle" />
          </Button>
        }
      </Form>
    );
  }
}

const mapStateToProps = () => { return {}; };

const MaintenanceFormRef = Form.create()(MaintenanceFormContainer);

export default connect(
  mapStateToProps,
  {
    handleOnClickAction
  }
)(MaintenanceFormRef);