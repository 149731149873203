import {
    FETCH_MY_DEMO_SCHEDULES,
    TOGGLE_IS_FETCHING_MY_DEMO_SCHEDULES,
    CLEAR_MY_SCHEDULES_RESPONSE,
    RETAIN_MY_SCHEDULE_FILTER,
    CLEAR_MY_DEMO_SCHEDULES
} from '../actions/types';

const mySchedulesState = {
    data: [],
    isFetching: false,
}

export const myDemoSchedulesReducer = (state = mySchedulesState, action) => {
    switch (action.type) {
        case FETCH_MY_DEMO_SCHEDULES:
            return { ...state, data: action.payload?.[0].dataSet || [], count: action.payload[0].count, response: action.payload[1] };
        case CLEAR_MY_SCHEDULES_RESPONSE:
            return mySchedulesState;
        case RETAIN_MY_SCHEDULE_FILTER:
            return {
                ...state,
                currentFilter: action.payload[0] ?? state.currentFilter,
                status: action.payload[1] ?? state.status,
                pageNumber: action.payload[2] ?? state.pageNumber,
                pageSize: action.payload[3] ?? state.pageSize,
                isEdit: true
            };
        case TOGGLE_IS_FETCHING_MY_DEMO_SCHEDULES:
            return { ...state, isFetching: action.payload };
        case CLEAR_MY_DEMO_SCHEDULES:
            return mySchedulesState;
        default:
            return state;
    }
}   