import { batch } from 'react-redux';

import { aepDemo } from '../apis';
import {
    FETCH_SOLUTIONS,
    TOGGLE_IS_FETCHING_SOLUTIONS,
    UPDATE_SELECTED_SOLUTION_TYPE,
    REORDER_SOLUTIONS,
    TOGGLE_IS_SOLUTIONS_REORDERED,
    UPDATE_SELECTED_SOLUTION,
    DELETE_SOLUTION,
    REORDER_SOLUTION_DEMOS,
    DELETE_SOLUTION_DEMO,
    ADD_SOLUTION_DEMO,
    CLEAR_SOLUTION_FORM,
    SELECT_DEMO_SOLUTION,
    FETCH_SOLUTION_DEMOS,
    UPDATE_SOLUTION_DEMO_SEARCH_STRING,
    CLEAR_SOLUTION_DEMOS,
    CLEAR_SOLUTIONS,
    UPDATE_SOLUTION_ROLE_TEMPLATE,
    FETCH_USER_SALES_VIDEOS,
    TOGGLE_IS_FETCH_SALES_VIDEOS,
    LAST_SOLUTIONS_FILTER,
    REFRESH_DEMO_RATINGS,
    TOGGLE_DEMO_REQUEST_DELETE,
    UPDATE_DEMO_ACCESS_INFORMATION,
    REFRESH_SOLUTIONS,
    TOGGLE_HAS_SOLUTIONS_UPDATED,
    UPDATE_ID_STRING_DEMO,
    GET_SOLUTION_TYPE_DATA,
} from './types';
import pushMessage from '../components/common/PushMessage';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';
import CONFIG from '../config';
import { closeCurrentOpenTab } from './tabsLayout';

export const fetchSolutions = (solutionTypeId, shouldSetActiveFlag = false, viewDeletedItems = false, isScheduleDemo) => async dispatch => {
    const params = {
        activeFlag: shouldSetActiveFlag ? true : undefined,
        viewDeletedItems,
        isScheduleDemo
    }
    const contextPath = shouldSetActiveFlag ? `/demoSolutionService/solutionTypes/user/${solutionTypeId}/solutions` : `/demoSolutionService/solutionTypes/${solutionTypeId}/solutions`;
    const response = await aepDemo({
        url: contextPath,
        method: 'get',
        params
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: FETCH_SOLUTIONS,
                payload: response.data || []
            });
            if (shouldSetActiveFlag) dispatch(updateSelectedDemoSolution(CONFIG.ALL, solutionTypeId))
            else dispatch(toggleIsFetchingSolutions(false));
        });
    }
    else {
        dispatch(toggleIsFetchingSolutions(false));
        pushMessage('Unable to fetch solutions');
    }
};

export const refreshSolutions = () => {
    return {
        type: REFRESH_SOLUTIONS
    }
}

export const toggleHasSolutionsUpdated = flag => {
    return {
        type: TOGGLE_HAS_SOLUTIONS_UPDATED,
        payload: flag
    }
}


export const fetchAllSolutions = (access) => async dispatch => {
    dispatch(toggleIsFetchingSolutions(true));
    const response = await aepDemo.get('/demoSolutionService/solutions/all');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        const data = access ? response.data && response.data.filter(solution => solution.adminAccess === access) : response.data
        dispatch({
            type: FETCH_SOLUTIONS,
            payload: data || []
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch solutions');
    dispatch(toggleIsFetchingSolutions(false));
}

export const fetchSalesVideos = () => async dispatch => {
    const response = await aepDemo.get('/demoSolutionService/demos/salesVideos');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_USER_SALES_VIDEOS,
            payload: response.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch sales videos');
    dispatch(toggleIsFetchingSalesVideos(false))
}

export const toggleIsFetchingSalesVideos = (flag) => {
    return {
        type: TOGGLE_IS_FETCH_SALES_VIDEOS,
        payload: flag
    };
};

export const demoRequestDelete = (flag) => dispatch => {
    return { type: TOGGLE_DEMO_REQUEST_DELETE, payload: flag };

}


export const toggleIsFetchingSolutions = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_SOLUTIONS,
        payload: flag
    };
};

export const getUpdatedIdString = (data) => {
    return {
        type: UPDATE_ID_STRING_DEMO,
        payload: data
    }
}

export const updateSelectedSolutionType = (solutionType) => {
    return {
        type: UPDATE_SELECTED_SOLUTION_TYPE,
        payload: solutionType
    };
};

export const reorderSolutions = (reorderedSolutions) => dispatch => {
    batch(() => {
        dispatch({
            type: REORDER_SOLUTIONS,
            payload: [...reorderedSolutions]
        });
        dispatch(toggleIsSolutionsReordered(true));
    });
};

export const toggleIsSolutionsReordered = (flag) => {
    return {
        type: TOGGLE_IS_SOLUTIONS_REORDERED,
        payload: flag
    };
};

export const updateSelectedSolution = (solutionId) => async (dispatch, getState) => {
    const response = await aepDemo(`demoSolutionService/solutions/${solutionId}`)
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { user, roleTemplates } = getState();
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const solutionDetails = {
            ...response.data,
            roleTemplates: isAdmin ? response.data.roleTemplates.filter(solutionRoleTemplate => allRoleTemplates.some(roleTemplate => solutionRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates
        }
        batch(() => {
            dispatch({
                type: UPDATE_SELECTED_SOLUTION,
                payload: solutionDetails
            })
            dispatch(toggleIsFetchingSolutions(false));
        })
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch solution")
};

export const deleteSolution = (solutionId, hardDelete = false) => async dispatch => {
    const response = await aepDemo.delete(`demoSolutionService/solutions/${solutionId}`, { params: { hardDelete } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: DELETE_SOLUTION,
            payload: solutionId
        });
        pushMessage(CONFIG.messageType.success, "Solution deleted successfully");
    }
    else pushMessage(CONFIG.messageType.error, "Unable to delete solution");
};

export const restoreSolution = (solutionId) => async () => {
    const response = await aepDemo.put("/demoSolutionService/restore/solutions/" + solutionId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Solution restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore solution");
}

export const reorderSolutionDemos = (reorderedDemos) => {
    return {
        type: REORDER_SOLUTION_DEMOS,
        payload: [...reorderedDemos]
    };
};

export const deleteSolutionDemo = (demoId) => {
    return {
        type: DELETE_SOLUTION_DEMO,
        payload: demoId
    };
};

export const addSolutionDemo = (demo) => {
    return {
        type: ADD_SOLUTION_DEMO,
        payload: demo
    };
};

export const clearSolutionForm = () => {
    return {
        type: CLEAR_SOLUTION_FORM
    };
};

export const postSolution = (formValues) => async (dispatch, getState) => {
    const { solutionForm } = getState();
    const { roleTemplates, demos } = solutionForm;
    const solutionTypeId = JSON.parse(formValues.solutionTypes)?.id
    const solution = {
        title: formValues.title,
        solutionType: JSON.parse(formValues.solutionTypes),
        isActive: formValues.isActive ? formValues.isActive : false,
        demo: demos,
        roleTemplates
    };
    const response = await aepDemo.post(`/demoSolutionService/solutionTypes/${solutionTypeId}/solutions`, solution);
    response.status === CONFIG.HTTP_STATUS.CREATED
        ? pushMessage(CONFIG.messageType.success, "Solution created successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to create solution");
    dispatch(toggleIsFetchingSolutions(false))
    dispatch(closeCurrentOpenTab())
};

export const putSolution = (solutionId, formValues) => async (dispatch, getState) => {
    const { solutionForm } = getState();
    const { roleTemplates, demos } = solutionForm
    const solution = {
        title: formValues.title,
        isActive: formValues.isActive ? formValues.isActive : false,
        demo: demos,
        solutionType: JSON.parse(formValues.solutionTypes),
        roleTemplates
    };
    const response = await aepDemo.put(`/demoSolutionService/solutions/${solutionId}/solutions`, solution);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Solution updated successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to update solution");
    dispatch(toggleIsFetchingSolutions(false))
    dispatch(closeCurrentOpenTab())
};

export const putSolutionsOrder = (solutions, solutionTypeId) => async dispatch => {
    const response = await aepDemo.put(`/demoSolutionService/solutionTypes/${solutionTypeId}/solutions`, solutions);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Solutions order updated successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to update solutions order");
    dispatch(toggleIsSolutionsReordered(false));
};

export const fetchSolutionDemo = (demoId, searchString) => async dispatch => {

    const response = await aepDemo.get('demoSolutionService/solution/demo/' + demoId);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        let solutionDemo = response.data;
        let updatedSolutionDemo;
        if (searchString && solutionDemo.length !== 0) {
            const searchTextRegEx = CONFIG.regEx.searchText.source.replace('searchText', searchString);
            updatedSolutionDemo = [{
                ...solutionDemo[0],
                description: solutionDemo[0].description
                    ? solutionDemo[0].description.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`)
                    : solutionDemo[0].description, componentDetails: solutionDemo[0].componentDetails
                        ? solutionDemo[0].componentDetails.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`)
                        : solutionDemo[0].componentDetails, connectivityDetails: solutionDemo[0].connectivityDetails
                            ? solutionDemo[0].connectivityDetails.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`)
                            : solutionDemo[0].connectivityDetails
            }]
            updatedSolutionDemo = [{ ...updatedSolutionDemo[0] }]
        }
        batch(() => {
            dispatch({
                type: FETCH_SOLUTION_DEMOS,
                payload: updatedSolutionDemo || solutionDemo
            })
            dispatch(updateSolutionDemoSearchString())
        })

    }
    else {
        dispatch({
            type: FETCH_SOLUTION_DEMOS,
            payload: []
        });
        response.status === CONFIG.HTTP_STATUS.NO_CONTENT ? pushMessage(CONFIG.messageType.warning, "You no longer have access to view this demo") : pushMessage(CONFIG.messageType.error, "Unable to fetch demo");
    }
    dispatch(toggleIsFetchingSolutions(false));
}


export const fetchDemoAccessInformation = (demoId) => async dispatch => {
    const response = await aepDemo.get('demoSolutionService/solution/demo/' + demoId);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        let solutionDemo = response.data;
        batch(() => {
            dispatch({
                type: UPDATE_DEMO_ACCESS_INFORMATION,
                payload: solutionDemo?.[0]
            })
        })
    }
    else {
        dispatch({
            type: FETCH_SOLUTION_DEMOS,
            payload: []
        });
        response.status === CONFIG.HTTP_STATUS.NO_CONTENT ? pushMessage(CONFIG.messageType.warning, "You no longer have access to view this demo") : pushMessage(CONFIG.messageType.error, "Unable to fetch demo");
    }
    dispatch(toggleIsFetchingSolutions(false));
}

export const fetchSolutionDemos = ({ solutionId, solutionTypeId, searchString }) => async dispatch => {
    dispatch(toggleIsFetchingSolutions(true));
    dispatch(clearSolutionDemos());
    const contextPath = `demoSolutionService/solutions/demos`;
    const params = { solutionId, solutionTypeId, searchString };
    const response = await aepDemo.get(contextPath, {
        params
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        let solutionDemos = response.data;
        if (searchString && solutionDemos.length !== 0) {
            const searchTextRegEx = CONFIG.regEx.searchText.source.replace('searchText', searchString);
            solutionDemos = solutionDemos.map(solution => {
                return { ...solution, description: solution.description ? solution.description.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`) : undefined }
            });
        }

        batch(() => {
            dispatch({
                type: FETCH_SOLUTION_DEMOS,
                payload: solutionDemos
            })
            dispatch(updateSolutionDemoSearchString(searchString ? searchString : undefined))
        })
    }
    else {
        dispatch({
            type: FETCH_SOLUTION_DEMOS,
            payload: []
        });
        if (response.status !== CONFIG.HTTP_STATUS.NO_CONTENT) pushMessage(CONFIG.messageType.error, "Unable to fetch demos");
    }
    dispatch(toggleIsFetchingSolutions(false));
    return true;
};

export const updateSelectedDemoSolution = (solution, solutionTypeId) => async (dispatch) => {
    const solutionId = solution === CONFIG.ALL ? solution : solution.id;
    dispatch({
        type: SELECT_DEMO_SOLUTION,
        payload: solution
    });
    await dispatch(fetchSolutionDemos({ solutionId, solutionTypeId }));
};

export const updateSolutionRoleTemplate = (access, accessType, roleType, selectedRoleTemplate) => async (dispatch, getState) => {
    const { solutionForm, roleTemplates } = getState();
    const allRoleTemplates = roleTemplates.all;
    const accessKey = roleType === CONFIG.roleTypes.user ? 'allRoleTemplatesUserAccess' : 'allRoleTemplatesAdminAccess';
    let assignedRoleTemplates = solutionForm.roleTemplates
    let updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_SOLUTION_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access, accessKey }
    });
}

export const updateSolutionDemoSearchString = (searchString = "") => {
    return { type: UPDATE_SOLUTION_DEMO_SEARCH_STRING, payload: searchString }
}

export const clearSolutionDemos = () => {
    return { type: CLEAR_SOLUTION_DEMOS }
}

export const clearSolutions = () => {
    return { type: CLEAR_SOLUTIONS }
}

export const currentSolutionFilter = (filters, status, solutionType) => {
    return {
        type: LAST_SOLUTIONS_FILTER,
        payload: { filters, status, solutionType }
    }
}

export const checkSolutionDependencies = async (solutionId, viewDeletedItems = false) => {
    const response = await aepDemo.get(`/demoSolutionService/solutions/${solutionId}/dependencies`, { params: { viewDeletedItems } })
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
    else throw Error("Unable to get solution dependency list");
};

export const refreshDemoRatings = (message) => {
    return {
        type: REFRESH_DEMO_RATINGS,
        payload: message
    }
}

export const getAllSolutionTypes = (access, viewDeletedItems = false) => async (dispatch) => {
    const response = await aepDemo.get("/demoSolutionService/solutionTypes", { params: { access, viewDeletedItems } });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        const solutionTypes = response.data;
        dispatch({
            type: GET_SOLUTION_TYPE_DATA,
            payload: solutionTypes || [],
        });
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch solution types");
}