import { aepDemo } from "../apis";
import CONFIG from '../config';
import {
    TOGGLE_IS_FETCHING_CAPABILITY_FORM,
    FETCH_CAPABILITY_DETAILS,
    CLEAR_CAPABILITY_FORM,
    UPDATE_DEMO_CAPABILITY_ROLE_TEMPLATE,
    TOGGLE_IS_FETCHING_CAPABILITY,
    FETCH_ALL_CAPABILITY,
    CURRENT_CAPABILITY_FILTER,
    CLEAR_CAPABILITY,
    REFRESH_CAPABILITY,
    FETCH_CAPABILITY_REQUEST_DETAILS,
    TOGGLE_IS_FETCHING_CAPABILITY_REQUEST_DETAILS,
    UPDATE_CAPABILITY_DETAILS_COMMENTS,
    CLEAR_COMMENT_DETAILS,
    UPDATE_CAPABILITY_GENERAL_INSTRUCTION,
    UPDATE_CAPABILITY_QUESTION_INSTRUCTION,
    DELETE_CAPABILITY_QUESTION_INSTRUCTION,
    CAPABILITY_QUESTION_INSTRUCTIONS,
    CLEAR_CAPABILITY_QUESTION_INSTRUCTION,
    FETCH_LITE_CAPABILITY
} from "./types";
import pushMessage from '../components/common/PushMessage';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';
import { closeCurrentOpenTab } from './tabsLayout';
import { download } from '../utils/file';
export const toggleIsFetchingCapabilityForm = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_CAPABILITY_FORM,
        payload: flag
    }
};

export const fetchCapabilityById = (id) => async (dispatch, getState) => {
    const response = await aepDemo.get(`/demoSolutionService/capability/${id}`);
    if ([CONFIG.HTTP_STATUS.OK, CONFIG.HTTP_STATUS.NO_CONTENT].includes(response.status)) {
        dispatch({
            type: FETCH_CAPABILITY_DETAILS,
            payload: response?.data || []
        })
        dispatch(updateOverallInstruction(response.data.instruction));
        const arrayInstruction = response.data.questions.map((item) => { return { isContentLoaded: true, html: item?.question?.instruction } })
        dispatch(updateCapabilityQuestionInstruction(arrayInstruction));
        return response?.data
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch capability');
};

export const clearCapabilityForm = () => {
    return {
        type: CLEAR_CAPABILITY_FORM
    }
};

export const postDemoCapability = (values, capabilityId) => async (dispatch, getState) => {
    const contextPath = `/demoSolutionService/capability`;


    const data = {
        ...values,
        id: capabilityId,
    }

    const response = await aepDemo({
        method: capabilityId === undefined ? 'post' : 'put',
        url: capabilityId === undefined ? contextPath : `${contextPath}/${capabilityId}`,
        data: data,
    });
    const successMessage = capabilityId ? 'Demo capability updated successfully' : 'Demo capability created successfully';
    const errorMessage = response.status === CONFIG.HTTP_STATUS.CONFLICT ? response.data : capabilityId ? 'Unable to update demo capability' : 'Unable to create demo capability';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage);
        await dispatch(closeCurrentOpenTab())
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingCapabilityForm(false))
};
export const fetchAllCapability = (filterSort, isActive) => async (dispatch) => {
    dispatch(toggleIsFetchingCapability(true));
    const response = await aepDemo.get("/demoSolutionService/capabilities",
        {
            params: {
                filterSort: {
                    ...filterSort,
                    filter: {
                        ...filterSort?.filter,
                        isActive
                    }
                }
            }
        });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ALL_CAPABILITY, payload: response.data ? response.data : [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Capability');
    dispatch(toggleIsFetchingCapability(false));
    return response.data ? response.data.dataSet : [];
};

export const fetchLiteCapability = ({ filterSort, status }) => async (dispatch) => {
    dispatch(toggleIsFetchingCapability(true));
    const response = await aepDemo.get("/demoSolutionService/v1/lite/capabilities",
        {
            params: {
                filterSort: {
                    ...filterSort,
                    filter: {
                        ...filterSort?.filter,
                        isActive: status ? status === "true" ? true : status === "false" ? false : undefined : undefined,
                    }
                }
            }
        });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_LITE_CAPABILITY, payload: response.data ? response.data : [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Capability');
    dispatch(toggleIsFetchingCapability(false));
    return response.data || [];
};

export const toggleIsFetchingCapability = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_CAPABILITY,
        payload: flag
    };
};
export const refreshCapability = () => {
    return {
        type: REFRESH_CAPABILITY
    };
};
export const currentCapabilityFilter = (filterSort, active, currentPage) => {
    return {
        type: CURRENT_CAPABILITY_FILTER,
        payload: [filterSort, active, currentPage],
    };
};
export const clearCapability = () => {
    return {
        type: CLEAR_CAPABILITY
    }
};
export const deleteCapability = (capabilityId) => async (dispatch) => {
    const response = await aepDemo.delete("/demoSolutionService/capability/" + capabilityId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Capability deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete Capability");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
};
export const restoreCapability = (capabilityId) => async (dispatch) => {
    const response = await aepDemo.put("/demoSolutionService/restore/capabilities/" + capabilityId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Capability restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore Capability");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
};
export const updateCapabilityRoleTemplate = (access, accessType, roleType, selectedRoleTemplate) => (dispatch, getState) => {
    const allRoleTemplates = getState().roleTemplates.all;
    const assignedRoleTemplates = getState().capabilityForm.formData ? getState().capabilityForm.formData.roleTemplates : [];
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_DEMO_CAPABILITY_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access }
    });
};
export const postCapabilityRequest = (requestId, data) => async () => {
    const response = await aepDemo({
        url: requestId ? `/demoSolutionService/capabilityRequest/${requestId}` : "/demoSolutionService/capabilityRequest",
        method: requestId ? 'PUT' : "POST",
        data: data
    });
    (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED)
        ? pushMessage(CONFIG.messageType.success, `Demo capability ${requestId ? "updated" : "submitted"} successfully!. You can find this capability request under "My Requests"`)
        : pushMessage(CONFIG.messageType.error, `Failed to ${requestId ? "update" : "submit"} capability request!`);
    return (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) ? true : false;
}

export const deleteUserCapabilityRequest = (requestId) => async () => {
    const response = await aepDemo.delete("/demoSolutionService/capability/request/" + requestId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Capability request deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete capability request");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
}
export const toggleIsFetchingDemoCapabilityRequest = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_CAPABILITY_REQUEST_DETAILS,
        payload: flag
    }
}
export const fetchCapabilityRequestById = (capabilityId) => async (dispatch, getState) => {
    const response = await aepDemo.get(`/demoSolutionService/capabilityRequest/${capabilityId}`);
    if ([CONFIG.HTTP_STATUS.OK, CONFIG.HTTP_STATUS.NO_CONTENT].includes(response.status)) {
        dispatch({
            type: FETCH_CAPABILITY_REQUEST_DETAILS,
            payload: response?.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch capability request details');
    dispatch(toggleIsFetchingDemoCapabilityRequest(false));
}

export const putCapabilityRequest = (requestId, data, typeOfApprove, fromDashboard) => async (dispatch) => {
    const response = await aepDemo({
        url: `/demoSolutionService/capabilityRequest/${requestId}?isAdminAction=true`,
        method: 'PUT',
        data: data
    });
    if ([CONFIG.HTTP_STATUS.OK, CONFIG.HTTP_STATUS.NO_CONTENT].includes(response.status)) {
        pushMessage(CONFIG.messageType.success, `Capability request ${typeOfApprove}d successfully`)
    }
    else pushMessage(CONFIG.messageType.error, `Unable to ${typeOfApprove} capability request`);
    if (fromDashboard) return;
    else if (typeOfApprove !== "Update") dispatch(closeCurrentOpenTab())
}

export const updateCapabilityDetailsDescription = (payload) => {
    return {
        type: UPDATE_CAPABILITY_DETAILS_COMMENTS,
        payload
    }
}

export const clearCapabilityComments = () => {
    return { type: CLEAR_COMMENT_DETAILS }
}

export const uploadInstructionImages = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await aepDemo({
        method: 'put',
        url: "demoSolutionService/capability/uploadFile",
        data: formData
    })

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, 'File uploaded successfully');
        return response.data || []
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to upload file');
}


export const updateOverallInstructionFromForm = (dispatch) => payload => {
    dispatch({
        type: UPDATE_CAPABILITY_GENERAL_INSTRUCTION,
        payload: payload
    })
}

export const updateOverallInstruction = (payload) => { return { type: UPDATE_CAPABILITY_GENERAL_INSTRUCTION, payload } }



export const updateCapabilityQuestionInstructionFromForm = (dispatch) => (_, key, htmlData) => {
    dispatch({
        type: UPDATE_CAPABILITY_QUESTION_INSTRUCTION,
        payload: { key, htmlData }
    })
}


export const updateCapabilityQuestionInstruction = (payload) => { return { type: CAPABILITY_QUESTION_INSTRUCTIONS, payload } }

export const deleteCapabilityQuestionInstruction = (key) => async (dispatch, getState) => {
    const capabilityQuestionInstructions = getState().capabilityQuestionInstruction;
    delete capabilityQuestionInstructions[key];
    return {
        type: DELETE_CAPABILITY_QUESTION_INSTRUCTION,
        payload: capabilityQuestionInstructions
    }
}

export const exportCapabilityTable = async (endpoint, fileName, fileFormat, filterSort, timeZone, status) => {
    const response = await aepDemo({
        method: 'get',
        url: `/demoSolutionService/export/${endpoint}/${fileFormat}`,
        responseType: "blob",
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: status ? status === "true" ? true : status === "false" ? false : undefined : undefined,
                }
            },
            timeZone
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(fileName, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}

export const clearCapabilityQuestionsInstructions = () => {
    return {
        type: CLEAR_CAPABILITY_QUESTION_INSTRUCTION
    }
};