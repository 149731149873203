import React from 'react';
import { Form, DatePicker, Row, Select, Alert, Col, Icon, Switch, Input, Button, Modal, Spin, Table, Collapse, Steps, Tooltip, Radio } from 'antd';
import { connect, batch } from 'react-redux';
import moment from 'moment-timezone';
import {
    toggleisMaintenanceAvailabilityVisible,
    setMaintenanceErrorResource,
    clearMaintenanceFormErrors,
    checkMaintenanceAvailability,
    postMaintenanceSchedule,
    putMaintenanceSchedule,
    putBulkMaintenanceSchedule,
    toggleMaintenanceFormModal,
    fetchMaintenanceSchedule,
    toggleBackButton,
    clearMaintenanceForm,
    fetchSharedDemoDetails,
    clearSharedDemoDetails,
    toggleIsFetchingMaintenanceSchedule,
    currentMaintenanceFilter,
    setMaintenanceErrorType,
    changeMaintenanceType,
    fetchDataCenters,
    updateSharedDemoList,
    editMaintenanceSchedule,
    fetchDemos,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag
} from '../../actions';
import CONFIG from '../../config';
import { range, areDatesEqual, areHoursEqual, getDateTime, getStartOfDateValue } from '../../utils/datepicker';
import MaintenanceAvailability from './MaintenanceAvailability';
import { getDemoResources, getDemoDataCenters, getMaintenanceSchedulesByGroupID } from '../../actions/maintenanceSchedule'
import pushMessage from '../../components/common/PushMessage';
import ManageMaintenanceTemplateVersions from '../ManageEmailTemplates/ManageMaintenanceTemplateVersions';


const radioStyle = {
    display: 'block',
    height: '50px',
    lineHeight: '30px',
    marginBottom: '30px'
};

const { Option, OptGroup } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Step } = Steps;

class ScheduleMaintenanceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startValue: this.props.startTime,
            endValue: this.props.endTime,
            currentStep: 0,
            title: undefined,
            description: undefined,
            maintenanceType: CONFIG.maintenanceTypes.demoDCResource,
            demo: [],
            names: [],
            keys: [],
            notifyUsers: false,
            isLoading: [],
            isDCLoading: [],
            data: [],
            dcData: [],
            freqType: "1",
            names1: [],
            keys1: [],
            dayOfWeek: [],
            monthDay: [1],
            dayName: "MONDAY",
            value: "1",
            radioToggle: 1,
            applyToAll: false,
            groupMaintenanceSchedules: [],
            timeZone: this.props.timeZoneName,
            emailTemplateId: undefined
        }
    }
    addedDemoIds = []

    selectedTime = {
        startValue: null,
        endValue: null,
    }

    data = []
    dcData = []

    demoDCMap = {}

    shouldFetchSharedDemoList = false;

    columns = [
        {
            title: 'Demo',
            dataIndex: 'demo.title',
            align: "left",
            key: 'title',
            width: "40%"
        },
        {
            title: 'Data Center',
            align: "center",
            key: 'dataCenterTitle',
            width: "20%",
            render: (record) => record.dataCenter !== null ? record.dataCenter.title : "ALL"
        },
        {
            title: 'Resource',
            align: "center",
            key: 'resourceTitle',
            width: "20%",
            render: (record) => record.resource !== null ? record.resource.name : "ALL"
        },
        {
            title: 'Action',
            align: "center",
            width: "20%",
            dataIndex: "operation",
            render: (text, record, index) => {
                const { userRoleTemplate } = this.props;
                const isSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
                const noAccess = !isSuperAdmin && record.demo.adminAccess !== CONFIG.roleTemplateAccess.fullAccess
                return (
                    <React.Fragment>
                        <Tooltip title={(this.isFormDisabled || noAccess) ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                            <Button
                                disabled={(this.isFormDisabled || noAccess)}
                                className={(this.isFormDisabled || noAccess) ? "horizontal-spacing" : "primary-action-button-bordered horizontal-spacing"}
                                onClick={() => this.updateDemoDropdownList(record)}
                            >
                                Add
                                <Icon type="plus-circle" />
                            </Button>
                        </Tooltip>
                    </React.Fragment>
                );
            }
        },
    ];

    maintenanceGroupColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            align: "left",
            key: 'title',
            width: "40%"
        },
        {
            title: 'Start Date',
            dataIndex: 'startTime',
            width: "30%",
            key: 'startDate',
            align: 'center',
            render: (startTime) => moment.tz(startTime, this.state.timeZone).format(CONFIG.dateFormats.userDateTime)
        },
        {
            title: 'End Date',
            dataIndex: 'endTime',
            key: 'endDate',
            width: "30%",
            align: 'center',
            render: (endTime) => moment.tz(endTime, this.state.timeZone).format(CONFIG.dateFormats.userDateTime)
        }
    ];


    updateDemoDropdownList = async (record) => {
        const { form } = this.props;

        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(++this.id);
        form.setFieldsValue({
            keys: nextKeys,
        });
        this.props.clearMaintenanceFormErrors();
        this.props.updateSharedDemoList(record)
        let isDCLoadingKey = [];
        isDCLoadingKey[(this.id * 3) + 2] = true;
        this.dcData[(this.id * 3) + 2] = [];
        let names = form.getFieldValue('names')
        names[(this.id * 3) + 1] = JSON.stringify({ id: record.demo.id, title: record.demo.title })
        form.setFieldsValue({ names });

        this.setState({ isDCLoading: isDCLoadingKey, dcData: this.dcData });

        await getDemoDataCenters(record.demo.id).then(result => {
            this.dcData[(this.id * 3) + 2] = result;
            isDCLoadingKey[(this.id * 3) + 2] = false;
            this.setState({ dcData: this.dcData, isDCLoading: isDCLoadingKey })
        })

        names[(this.id * 3) + 2] = record.dataCenter !== null ? [JSON.stringify({ id: record.dataCenter.id, title: record.dataCenter.title })] : []
        form.setFieldsValue({ names });

        if (record.dataCenter) {
            let isLoadingKey = [];
            isLoadingKey[(this.id * 3) + 3] = true;
            this.data[(this.id * 3) + 3] = [];
            this.setState({ isLoading: isLoadingKey, data: this.data })

            await getDemoResources(record.demo.id, record.dataCenter.id)
                .then((result) => {
                    this.data[(this.id * 3) + 3] = result
                    isLoadingKey[(this.id * 3) + 3] = false;
                    this.setState({
                        data: this.data,
                        isLoadingKey: isLoadingKey
                    })
                })

            names[(this.id * 3) + 3] = record.resource !== null ? [JSON.stringify({ id: record.resource.id, title: record.resource.name })] : []
        }
        form.setFieldsValue({ names });
        this.setState({ names });

    }

    handleDemoSelect = (demoList) => {
        this.setState({ demo: demoList })
        this.props.clearMaintenanceFormErrors();
        if (demoList?.length > 0) {
            this.props.toggleIsFetchingMaintenanceSchedule(true)
            this.props.fetchSharedDemoDetails(this.createScheduleObject(demoList), this.state.maintenanceType)
        }
        else this.props.clearSharedDemoDetails()
    }

    saveSelectedTime = () => {
        const { form } = this.props;
        const names = form.getFieldValue('names1')
        this.selectedTime = names
    }

    resetTime = (isReset, k) => {
        const { form } = this.props;
        let names = form.getFieldValue('names1')
        const startTime = (names[(k * 3) + 1] !== undefined && names[(k * 3) + 1] !== null) ? names[(k * 3) + 1].format(CONFIG.dateFormats.crossBrowserDefault) : names[(k * 3) + 2].format(CONFIG.dateFormats.crossBrowserDefault);
        const endTime = (names[(k * 3) + 2] !== undefined && names[(k * 3) + 2] !== null) ? names[(k * 3) + 2].format(CONFIG.dateFormats.crossBrowserDefault) : names[(k * 3) + 1].format(CONFIG.dateFormats.crossBrowserDefault);
        names[(k * 3) + 1] = isReset ? moment(startTime).startOf('day') : this.selectedTime[(k * 3) + 1];
        names[(k * 3) + 2] = isReset ? moment(endTime).endOf('day') : this.selectedTime[(k * 3) + 2];
        form.setFieldsValue({ names1: names })
        this.setState({ names1: names })

    }

    handleSwitchChange = (k, isAllDay) => {
        if (isAllDay) {
            this.saveSelectedTime();
            this.resetTime(true, k);
        }
        else this.resetTime(false, k);
        this.clearCheckAvailabilityResult();
    }

    handleSeeDetails = (resourceId, isLabDemo) => {
        if (resourceId && !isLabDemo) {
            this.props.setMaintenanceErrorResource(resourceId);
            this.props.setMaintenanceErrorType(CONFIG.maintenanceErrorTypes.resourceDemos)
        }
        else if (isLabDemo) {
            this.props.setMaintenanceErrorResource(resourceId);
            this.props.setMaintenanceErrorType(CONFIG.maintenanceErrorTypes.labDemos)
        }
        else this.props.setMaintenanceErrorType(CONFIG.maintenanceErrorTypes.maintenance)
        this.props.toggleisMaintenanceAvailabilityVisible(true);
    }

    clearCheckAvailabilityResult = () => {
        const { isMaintenanceAvailable, clearMaintenanceFormErrors } = this.props;
        if (isMaintenanceAvailable !== undefined) clearMaintenanceFormErrors();
    }

    renderErrorRow = (message, index = Date.now(), resourceId, isLabDemo) => {
        return (
            <Row key={index}>
                <Col span={18} className="paragraph">
                    <span className="left-spacing">{message}</span>
                </Col>
                <Col span={6}>
                    <span className="link paragraph" onClick={() => this.handleSeeDetails(resourceId, isLabDemo)}>See Details</span>
                </Col>
            </Row>
        );
    }

    renderScheduleConflictErrorDescription = () => {
        const description = this.props.unavailableResources.map((resource, index) => {
            return this.renderErrorRow(resource.name, index, resource.id);
        });

        const labDemoDescription = this.props.unavailableLabDemos.map((schedule, index) => {
            return this.renderErrorRow(schedule.name, index, schedule.scheduleId, true);
        });

        description.unshift(labDemoDescription)
        return description;
    }

    renderSuccessAlert = () => {
        return <Alert message={CONFIG.maintenanceSuccessMessage} type="success" showIcon />
    }

    renderErrorAlert = () => {
        return (<>
            {this.props.maintenanceUnavailableCount !== 0 && <Alert
                message="Maintenance Window Conflict"
                description={this.renderMaintenanceWindowConflictError()}
                type="error"
                showIcon
            />}

            {this.props.scheduleConflictsCount !== 0 && <>
                <br />
                <Alert
                    message="Below Resources/Schedules will be affected"
                    description={this.renderScheduleConflictErrorDescription()}
                    type="warning"
                    showIcon
                />
            </>}
        </>)
    }

    renderWarningAlert = (demos) => {
        return (
            <Alert
                message={`You do not have access to ${demos}`}
                type="warning"
                showIcon
            />
        )
    }

    renderMaintenanceWindowConflictError = () => {
        const description = this.renderErrorRow(`${this.props.maintenanceUnavailableCount} ${CONFIG.maintenanceErrorMessage}`);
        return description;
    }

    renderDemoDependencyList = () => {
        return (
            <Table
                record={(record) => record.id}
                className="responsive-container vertical-spacing"
                dataSource={this.props.sharedDemos}
                size="small"
                pagination={false}
                columns={this.columns}
            />)
    }

    renderMaintenanceGroupList = () => {
        return (
            <Table
                record={(record) => record.id}
                className="responsive-container vertical-spacing"
                dataSource={this.state.groupMaintenanceSchedules}
                size="small"
                pagination={false}
                columns={this.maintenanceGroupColumns}
            />)
    }

    handleModalClose = () => {
        this.props.setMaintenanceErrorResource()
        this.props.toggleisMaintenanceAvailabilityVisible(false);
    }

    renderFooterButtons = () => {

        const { isMaintenanceAvailable, maintenanceId, overrideMaintenance } = this.props;
        const isMaintenanceClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
        const { currentStep, notifyUsers, emailTemplateId } = this.state;
        if (currentStep === 0) {
            return [
                <Button
                    key="cancel"
                    className="cancel-button"
                    handleCancel={() => this.props.closeCurrentOpenTab()}
                    onClick={this.handleCancel} > Discard & Close </Button>,
                <Button key="next" className="left-spacing primary-action-button-filled" onClick={this.handleNext} > Next </Button>
            ]
        }

        else if (currentStep === 1) {
            return (
                <>
                    <Button
                        key="cancel"
                        className="cancel-button"
                        handleCancel={() => this.props.closeCurrentOpenTab()}
                        onClick={this.handleCancel} > Discard & Close </Button>
                    <Button key="prev" className="left-spacing primary-action-button-filled" onClick={this.handlePrev} > Prev </Button>
                    {isMaintenanceAvailable === undefined && <Button key="check-availability" className="left-spacing primary-action-button-filled" onClick={this.handleAvailabilityCheck} disabled={this.isFormDisabled}> Check Availability </Button>}
                    {isMaintenanceAvailable !== undefined && <Button key="next" className="left-spacing primary-action-button-filled" disabled={!overrideMaintenance} onClick={this.handleNext} > Next </Button>}
                </>
            )
        }

        else if (currentStep === 2 && notifyUsers) {
            return (<>
                <Button
                    key="cancel"
                    className="cancel-button"
                    handleCancel={() => this.props.closeCurrentOpenTab()}
                    onClick={this.handleCancel} > Discard & Close </Button>
                <Button key="prev" className="left-spacing primary-action-button-filled" onClick={this.handlePrev} > Prev </Button>
                <Button key="next" className="left-spacing primary-action-button-filled" disabled={!emailTemplateId} onClick={this.handleNext} > Next </Button>
            </>
            )
        }
        else {
            return (
                <>
                    <Button key="cancel"
                        className="cancel-button"
                        isDisabled={!this.hasChanged}
                        handleCancel={() => this.props.closeCurrentOpenTab()}
                        onClick={this.handleCancel}> Discard & Close </Button>
                    <Button key="prev" className="left-spacing primary-action-button-filled" onClick={this.handlePrev} > Prev </Button>
                    <Button key="ok" className="left-spacing primary-action-button-filled" disabled={!overrideMaintenance || this.isFormDisabled} onClick={this.handleCreate}> {maintenanceId && !isMaintenanceClone ? 'Update' : 'Create'} </Button>
                </>
            )
        }

    }

    renderModalFooterButtons = () => {
        return [
            <Button key="cancel" className="cancel-button primary-action-button-filled" onClick={this.handleModalClose}> Close </Button>,
        ];

    }

    async componentDidMount() {
        const { recordId } = this.props.match.params;
        const isMaintenanceClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
        this.id = 0;
        this.id1 = 0
        document.getElementById("tab-pane").scrollIntoView();
        if (recordId) {
            this.props.toggleIsFetchingMaintenanceSchedule(true)
            await this.props.editMaintenanceSchedule(recordId)
                .then(async () => {
                    await this.props.fetchDemos({ getAll: true, access: 2 });
                    const { maintenanceRelations, maintenanceRelationsObj, startTime, endTime, title, description, maintenanceType, freqType, dayOfWeek, endSeriesDate, monthDay, dayName, value, groupId, timeZone } = this.props;
                    let names = []
                    this.id = maintenanceRelations?.length - 1
                    const keys = [...Array(maintenanceRelations?.length).keys()]
                    keys.forEach(async (k) => {
                        names[(k * 3) + 1] = JSON.stringify({ id: maintenanceRelations[k]?.id, title: maintenanceRelations[k]?.title })
                        names[(k * 3) + 2] = maintenanceRelations[k]?.dataCenters && maintenanceRelations[k]?.dataCenters !== null ? maintenanceRelations[k]?.dataCenters.map(item => JSON.stringify({ id: item.id, title: item.title })) : undefined
                        names[(k * 3) + 3] = maintenanceRelations[k]?.dataCenters && maintenanceRelations[k]?.dataCenters !== null ? maintenanceRelations[k]?.dataCenters.reduce((acc, item) => { if (item.resources && item.resources !== null) { item.resources.forEach(resource => acc.push(JSON.stringify({ id: resource.id, title: resource.name }))) } return acc }, []) : undefined;

                        let dataCenterIds = []
                        if (maintenanceRelations[k]?.dataCenters && maintenanceRelations[k]?.dataCenters !== null) {
                            maintenanceRelations[k].dataCenters.forEach(dataCenter => {
                                dataCenterIds.push(dataCenter.id)
                            })
                        }
                        getDemoResources(maintenanceRelations[k]?.id, dataCenterIds.join(','))
                            .then((result) => {
                                this.data[(k * 3) + 3] = result
                                this.setState({
                                    data: this.data,
                                })
                            })
                        getDemoDataCenters(maintenanceRelations[k]?.id)
                            .then((result) => {
                                this.dcData[(k * 3) + 2] = result;
                                this.setState({
                                    dcData: this.dcData
                                })
                            })
                    })

                    const groupMaintenanceSchedules = await getMaintenanceSchedulesByGroupID(groupId)

                    let names1 = [];

                    const timezone = timeZone !== null ? timeZone : this.props.timeZoneName

                    if (!isMaintenanceClone) {
                        names1[1] = moment.tz(startTime, timezone);
                        names1[2] = moment.tz(endTime, timezone);
                    }

                    this.setState({
                        title,
                        description,
                        maintenanceType,
                        groupMaintenanceSchedules,
                        names,
                        names1,
                        freqType: isMaintenanceClone ? "1" : freqType === "s" ? dayOfWeek !== null ? "sw" : "sm" : freqType,
                        dayOfWeek: dayOfWeek !== null ? dayOfWeek.split(",") : [],
                        seriesEndDate: freqType === "s" ? moment.tz(endSeriesDate, timezone) : null,
                        monthDay: monthDay !== null ? monthDay.split(",") : [],
                        dayName: dayName || "MONDAY",
                        value: value || "1",
                        radioToggle: dayName !== null ? 2 : 1,
                        timeZone: isMaintenanceClone ? this.props.timeZoneName : timezone
                    });

                    this.props.toggleIsFetchingMaintenanceSchedule(false);
                    this.props.fetchSharedDemoDetails({ maintenanceRelations: maintenanceRelationsObj }, this.state.maintenanceType);
                })
        }

        else this.props.toggleIsFetchingMaintenanceSchedule(false);
    }

    componentWillUnmount() {
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.props.clearMaintenanceForm();
    }

    hasMaintenanceChanged = (oldData, newData) => {
        let hasChanged = false;
        const { freqType, dayOfWeek, endSeriesDate, monthDay, dayName, value } = this.props;
        const oldDayOfWeek = dayOfWeek?.split(',');
        const oldmonthDay = monthDay?.split(',');
        const isMaintenanceClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
        const oldFreqType = isMaintenanceClone ? "1" : freqType === "s" ? dayOfWeek !== null ? "sw" : "sm" : freqType;
        if (this.state.currentStep === 0 && newData?.maintenanceRelations?.length === 0) {
            if (oldData.title !== newData.title || oldData.description !== newData.description) {
                hasChanged = true
            }
        } else {
            if (oldData.title !== newData.title ||
                oldData.description !== newData.description
            ) {
                hasChanged = true
            }
            if (oldData?.maintenanceRelations?.length !== newData?.maintenanceRelations?.length) {
                hasChanged = true
            } else {
                for (const oldMaintRelation of oldData?.maintenanceRelationsObj) {
                    const isDemoPresent = newData.maintenanceRelations.some(newMaintObj => newMaintObj.demo.id === oldMaintRelation.demo.id);
                    if (!isDemoPresent) {
                        hasChanged = true;
                    }
                    if (isDemoPresent && oldMaintRelation?.dataCenter !== null) {
                        const isDcPresent = newData.maintenanceRelations.some(newMaintObj => (newMaintObj.demo.id === oldMaintRelation.demo.id && newMaintObj?.dataCenter?.id === oldMaintRelation?.dataCenter?.id));
                        if (!isDcPresent) {
                            hasChanged = true;
                        }
                        if (isDcPresent && oldMaintRelation?.resource !== null) {
                            const isResourcePresent = newData.maintenanceRelations.some(newMaintObj => (newMaintObj?.resource?.id === oldMaintRelation?.resource?.id && newMaintObj?.dataCenter?.id === oldMaintRelation?.dataCenter?.id && newMaintObj.demo.id === oldMaintRelation.demo.id));
                            if (!isResourcePresent) {
                                hasChanged = true;
                            }
                        }
                        else if (isDcPresent && oldMaintRelation?.resource === null) {
                            const isResourceAdded = newData.maintenanceRelations.some(newMaintObj => (newMaintObj.demo.id === oldMaintRelation.demo.id && newMaintObj?.resource));
                            if (isResourceAdded) {
                                hasChanged = true;
                            }
                        }
                    }
                    else if (isDemoPresent && oldMaintRelation?.dataCenter === null) {
                        const isDcAdded = newData.maintenanceRelations.some(newMaintObj => (newMaintObj.demo.id === oldMaintRelation.demo.id && newMaintObj?.dataCenter));
                        if (isDcAdded) {
                            hasChanged = true;
                        }
                    }
                }
                if (oldFreqType !== newData.freqType) {
                    hasChanged = true
                } else if (oldFreqType === 1 || oldFreqType === "r") {
                    if (!oldData.startTime.isSame(newData.dateRangeList[0].startTime) || !oldData.endTime.isSame(newData.dateRangeList[0].endTime)) {
                        hasChanged = true
                    }
                } else if (oldFreqType === "sw") {
                    if (!oldData.startTime.isSame(newData.dateRangeList[0].startTime) ||
                        !oldData.endTime.isSame(newData.dateRangeList[0].endTime) ||
                        !moment(endSeriesDate).isSame(newData.seriesEndDate) ||
                        this.equalArrays(oldDayOfWeek, newData.dayOfWeek) === false) {
                        hasChanged = true
                    }
                } else if (oldFreqType === "sm") {
                    if (newData?.radioToggle === 1 && (!oldData.startTime.isSame(newData.dateRangeList[0].startTime) ||
                        !oldData.endTime.isSame(newData.dateRangeList[0].endTime) ||
                        !moment(endSeriesDate).isSame(newData.seriesEndDate) ||
                        this.equalArrays(oldmonthDay, newData?.monthDay) === false
                    )) {
                        hasChanged = true
                    }

                    if (newData?.radioToggle === 2 && (!oldData.startTime.isSame(newData.dateRangeList[0].startTime) ||
                        !oldData.endTime.isSame(newData.dateRangeList[0].endTime) ||
                        !moment(endSeriesDate).isSame(newData.seriesEndDate) || dayName === null ||
                        (dayName !== newData.dayName ||
                            value !== newData.value))) {
                        hasChanged = true;
                    }
                }
            }
        }
        return hasChanged
    }

    componentDidUpdate() {
        const { recordId } = this.props.match.params;
        const newObj = this.createScheduleObject();
        const oldObj = this.props.maintenanceForm;
        this.hasChanged = recordId ? this.hasMaintenanceChanged(oldObj, newObj) : true;
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    }

    equalArrays = (a, b) => {
        let isArraySame = true;
        if (a?.length !== b?.length) {
            isArraySame = false;
        } else
            for (const oldItem of a) {
                const isItemPresentInB = b.some(newItem => newItem === oldItem);
                isArraySame = isItemPresentInB
            }
        return isArraySame;
    }

    handleCancel = () => {
        batch(() => {
            this.props.form.resetFields();
            this.props.clearMaintenanceForm();
            this.props.closeCurrentOpenTab();
        })
    };

    createScheduleObject = () => {
        const { maintenanceType, notifyUsers, description, title, currentStep, freqType, seriesEndDate, dayOfWeek, monthDay, dayName, value, radioToggle, applyToAll, timeZone, emailTemplateId } = this.state;
        const { getFieldValue } = this.props.form;
        let maintenanceRelations = [];
        let dateRangeList = [];
        const names = currentStep !== 1 ? this.state.names : getFieldValue("names")
        const keys = currentStep !== 1 ? this.state.keys : getFieldValue("keys")
        const names1 = currentStep !== 1 ? this.state.names1 : getFieldValue("names1")
        const keys1 = currentStep !== 1 ? this.state.keys1 : getFieldValue("keys1")

        keys.forEach(k => {
            if (names?.[(k * 3) + 3] && names?.[(k * 3) + 3]?.length !== 0) {
                let dataCenters = names?.[(k * 3) + 2]
                let resourceDataCnters = []
                names[(k * 3) + 3].forEach(item => {
                    const dataCenter = {
                        id: this.state.data[(k * 3) + 3]?.find(resource => resource.id === JSON.parse(item).id)?.dataCenter.id,
                        title: this.state.data[(k * 3) + 3]?.find(resource => resource.id === JSON.parse(item).id)?.dataCenter.title
                    }
                    resourceDataCnters.push(JSON.stringify(dataCenter))
                    maintenanceRelations.push({
                        demo: {
                            id: JSON.parse(names[(k * 3) + 1]).id,
                            title: JSON.parse(names[(k * 3) + 1]).title,
                            demoType: this.props.demos?.find(demo => demo.id === JSON.parse(names[(k * 3) + 1]).id)?.demoType
                        },
                        dataCenter,
                        resource: {
                            id: JSON.parse(item).id,
                            name: JSON.parse(item).title
                        }
                    })
                })

                if (dataCenters.length !== resourceDataCnters) {
                    names[(k * 3) + 2].forEach(item => {
                        if (!resourceDataCnters.includes(item)) {
                            maintenanceRelations.push({
                                demo: {
                                    id: JSON.parse(names[(k * 3) + 1]).id,
                                    title: JSON.parse(names[(k * 3) + 1]).title,
                                    demoType: this.props.demos?.find(demo => demo.id === JSON.parse(names[(k * 3) + 1]).id)?.demoType
                                },
                                dataCenter: {
                                    id: JSON.parse(item).id,
                                    title: JSON.parse(item).title
                                },
                                resource: null
                            })
                        }
                    })
                }
            }
            else if (names?.[(k * 3) + 1] && names?.[(k * 3) + 2] && names?.[(k * 3) + 2]?.length !== 0) {
                names[(k * 3) + 2].forEach(item => {
                    maintenanceRelations.push({
                        demo: {
                            id: JSON.parse(names[(k * 3) + 1]).id,
                            title: JSON.parse(names[(k * 3) + 1]).title,
                            demoType: this.props.demos?.find(demo => demo.id === JSON.parse(names[(k * 3) + 1]).id)?.demoType
                        },
                        dataCenter: {
                            id: JSON.parse(item).id,
                            title: JSON.parse(item).title
                        },
                        resource: null
                    })
                })
            }
            else if (names?.[(k * 3) + 1]) {
                maintenanceRelations.push({
                    demo: {
                        id: JSON.parse(names[(k * 3) + 1]).id,
                        title: JSON.parse(names[(k * 3) + 1]).title,
                        demoType: this.props.demos?.find(demo => demo.id === JSON.parse(names[(k * 3) + 1]).id)?.demoType
                    }
                })
            }
        })
        if (keys1 && names1) {
            keys1.forEach(k => {
                dateRangeList.push({
                    startTime: names1[(k * 3) + 1],
                    endTime: names1[(k * 3) + 2]
                })
            })
        }

        return {
            maintenanceRelations,
            dateRangeList,
            description,
            notifyUsers,
            maintenanceType,
            title,
            freqType,
            seriesEndDate,
            dayOfWeek,
            monthDay,
            dayName,
            value,
            radioToggle,
            applyToAll,
            timeZone,
            emailTemplateId
        };
    }

    handleBack = () => {
        this.props.toggleisMaintenanceAvailabilityVisible(false);
    }

    handleAvailabilityCheck = () => {
        const { form, maintenanceId, groupId } = this.props;
        const isMaintenanceClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
        form.validateFields((err) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
                return
            }
            this.props.checkMaintenanceAvailability(this.createScheduleObject(), maintenanceId ? this.props.freqType : this.state.freqType, !isMaintenanceClone ? maintenanceId : undefined, groupId, this.props.dateRangeList);
        });
    }

    handleCreate = () => {
        const { form, maintenanceId, status, currentActivePage, activeFilterSort, inactiveFilterSort, currentInActivePage, groupId, dateRangeList } = this.props;
        const isMaintenanceClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
        const { freqType, applyToAll } = this.state;
        const fromDashboard = window.location.pathname === "/dashboard";
        form.validateFields(async (err) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
                return
            }
            else {
                this.props.toggleIsFetchingMaintenanceSchedule(true);
                maintenanceId && !isMaintenanceClone
                    ? ((groupId === 0 && (freqType === "r" || freqType === "sm" || freqType === "sw")) || (groupId > 0 && applyToAll))
                        ? await this.props.putBulkMaintenanceSchedule(this.createScheduleObject(), maintenanceId, groupId, fromDashboard, dateRangeList)
                            .then(async () => {
                                if (this.status === undefined) {
                                    batch(async () => {
                                        await this.props.fetchMaintenanceSchedule({ pageNumber: currentActivePage, filterSort: activeFilterSort, status: true });
                                        await this.props.fetchMaintenanceSchedule({ pageNumber: currentInActivePage, filterSort: inactiveFilterSort, status: false });
                                    })
                                } else if (this.status === true)
                                    await this.props.fetchMaintenanceSchedule({ pageNumber: currentActivePage, filterSort: activeFilterSort, status: status });
                                else
                                    await this.props.fetchMaintenanceSchedule({ pageNumber: currentInActivePage, filterSort: inactiveFilterSort, status: status });
                            })
                        : await this.props.putMaintenanceSchedule(this.createScheduleObject(), maintenanceId, fromDashboard)
                            .then(async () => {
                                if (this.status === undefined) {
                                    batch(async () => {
                                        await this.props.fetchMaintenanceSchedule({ pageNumber: currentActivePage, filterSort: activeFilterSort, status: true });
                                        await this.props.fetchMaintenanceSchedule({ pageNumber: currentInActivePage, filterSort: inactiveFilterSort, status: false });
                                    })
                                } else if (this.status === true)
                                    await this.props.fetchMaintenanceSchedule({ pageNumber: currentActivePage, filterSort: activeFilterSort, status: status });
                                else
                                    await this.props.fetchMaintenanceSchedule({ pageNumber: currentInActivePage, filterSort: inactiveFilterSort, status: status });
                            })
                    : await this.props.postMaintenanceSchedule(this.createScheduleObject())
                        .then(async () => {
                            if (this.status === undefined) {
                                batch(async () => {
                                    await this.props.fetchMaintenanceSchedule({ filterSort: activeFilterSort, status: true })
                                    await this.props.fetchMaintenanceSchedule({ filterSort: inactiveFilterSort, status: false })
                                })
                            } else
                                await this.props.fetchMaintenanceSchedule({ filterSort: this.status ? activeFilterSort : inactiveFilterSort, status: status })
                        });
                form.resetFields();
                this.props.closeCurrentOpenTab();
            }
        });
    }

    disabledStartDate = (k, start) => {
        const { getFieldValue } = this.props.form
        const { timeZone } = this.state;
        const names = getFieldValue('names1')
        const end = names[(k * 3) + 2]
        const startValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZone, date: moment(start) });
        const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timeZone });
        const endValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZone, date: moment(end) });
        const startTimeCondition = startValue < currentValue;

        if (!start || !end) return startTimeCondition;

        return startValue > endValue || startTimeCondition;
    };

    disabledEndDate = (k, isSeriesEndDate, end) => {
        const { timeZone } = this.state;
        const { getFieldValue } = this.props.form;
        const names = getFieldValue('names1');
        const start = isSeriesEndDate && names[(k * 3) + 2] ? names[(k * 3) + 2] : names[(k * 3) + 1]
        const endValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZone, date: moment(end) });
        const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timeZone });
        const startValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZone, date: moment(start) });

        const endTimeCondition = endValue < currentValue;

        if (!end || !start) return endTimeCondition

        return endValue < startValue;
    };

    disabledPublishTime = (k, current) => {
        const { form } = this.props;
        const { freqType } = this.state;
        const { getFieldValue } = this.props.form;

        const names = form.getFieldValue('names1');
        const endValue = (freqType === "1" || freqType === "r") ? names[(k * 3) + 2] : getFieldValue('endTime')
        if (endValue && areDatesEqual(endValue, current)) {
            return {
                disabledHours: () => range(endValue.get('hours') + 1, 24),
                disabledMinutes: () => areHoursEqual(endValue, current) ? range(endValue.get('minute'), 60) : []
            };
        }
    }

    disabledExpiryTime = (k, current) => {
        const { form } = this.props;
        const { freqType } = this.state;
        const { getFieldValue } = this.props.form;

        const names = form.getFieldValue('names1');
        const startValue = (freqType === "1" || freqType === "r") ? names[(k * 3) + 1] : getFieldValue('startTime')
        if (startValue && areDatesEqual(startValue, current)) {
            return {
                disabledHours: () => range(0, startValue.get('hours')),
                disabledMinutes: () => areHoursEqual(startValue, current) ? range(0, startValue.get('minute')) : []
            };
        }
    }

    handleStartOpenChange = (k, isOpen) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        let names = getFieldValue('names1');
        if (!isOpen) {
            const endValue = names[(k * 3) + 2];
            const startValue = names[(k * 3) + 1];
            names[(k * 3) + 1] = undefined;
            if (endValue && startValue && endValue.valueOf() < startValue.valueOf()) setFieldsValue({ names1: names });
        }
    }

    handleEndOpenChange = (k, isOpen) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        let names = getFieldValue('names1');
        if (!isOpen) {
            const endValue = names[(k * 3) + 2];
            const startValue = names[(k * 3) + 1];
            names[(k * 3) + 2] = undefined;
            if (endValue && startValue && endValue.valueOf() < startValue.valueOf()) setFieldsValue({ names1: names });
        }
    }

    handleDateChange = (k, value) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        let names = getFieldValue('names1');
        names[(k * 3) + 3] = false
        setFieldsValue({ names1: names })
        if (value) {
            this.toggleCheckAvailabilityOnDateChange()
        }
    };

    toggleCheckAvailabilityOnDateChange = () => {
        this.saveSelectedTime()
        this.props.clearMaintenanceFormErrors();
    }

    renderMaintenanceTypeOptions = () => {
        return CONFIG.maintenanceTypeOptions.map((item, index) => {
            return <Option key={index} value={item.value} title={item.name} label={item.name}>{item.name}</Option>
        })
    }

    clearFormDetails = () => {
        const { form } = this.props;
        this.shouldFetchSharedDemoList = true;
        form.setFieldsValue({
            startTime: undefined,
            endTime: undefined,
        });
        this.setState({
            startValue: undefined,
            endValue: undefined,
            demo: []
        });
        this.props.clearSharedDemoDetails();
    }

    renderFormBasicDetails = () => {
        const { maintenanceRelations, maintenanceId } = this.props;
        const { getFieldDecorator } = this.props.form;

        this.isFormDisabled = maintenanceId ? maintenanceRelations?.length !== maintenanceRelations?.filter(item => item.adminAccess === CONFIG.roleTemplateAccess.fullAccess)?.length : false

        return <Row  >
            {this.isFormDisabled && this.renderWarningAlert(maintenanceRelations?.filter(item => item.adminAccess !== CONFIG.roleTemplateAccess.fullAccess)?.map(item => item.title)?.toString())}
            <Col xl={13} xs={24}>
                <Form.Item label="Name of Maintenance window" >
                    {getFieldDecorator('title', {
                        rules: [{ required: true, message: 'Please enter title' }], initialValue: this.state.title || this.props.title
                    })(<Input disabled={this.isFormDisabled} onChange={(e) => {
                        this.props.clearMaintenanceFormErrors();
                        this.setState({ title: e.target.value })
                    }} />)}
                </Form.Item>
            </Col>
            <Col xl={13} xs={24}>
                <Form.Item label="Description" >
                    {getFieldDecorator("description",
                        { rules: [{ required: true, message: 'Please enter description' }], initialValue: this.state.description || this.props.description })
                        (<TextArea rows={6} disabled={this.isFormDisabled} onChange={(e) => {
                            this.props.clearMaintenanceFormErrors();
                            this.setState({ description: e.target.value })
                        }} />)
                    }
                </Form.Item>
            </Col>
            {/* Retaining code for Phase 4 */}
            {/* <Col xl={13} xs={24}>
                <Form.Item label={<span>Select type of Maintenance <Tooltip title={<><p className="text-color-white">If Demo in a specific Datacenter / Resource in a Demo is put into maintenance then the Demo will not be available for scheduling on the resources belonging to the selected data center or on the selected resource.</p>
                    <br />
                    <p className="text-color-white">If a Demo across all data centers is put into maintenance then the entire Demo will not be available for scheduling</p></>}><Icon className="text-color-primary" type="info-circle" width={1} /></Tooltip></span>}>
                    {
                        getFieldDecorator('maintenanceType',
                            {
                                rules: [{ required: true, message: 'Please select a value' }], initialValue: this.state.maintenanceType || this.props.maintenanceType
                            }

                        )(
                            <Select
                                disabled={this.isFormDisabled}
                                showSearch={true}
                                // eslint-disable-next-line
                                disabled={this.props.maintenanceId}
                                onChange={(maintenanceType) => {
                                    this.props.clearMaintenanceFormErrors();
                                    this.setState({ maintenanceType })
                                    this.clearFormDetails();
                                }}
                            >
                                {
                                    this.renderMaintenanceTypeOptions()
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            </Col> */}
        </Row>
    }

    renderDemoOptions = () => {
        const { userRoleTemplate, demos, form } = this.props;
        const names = form.getFieldValue('names');
        if (demos) {
            return demos.map((demo, index) => {
                if (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin || demo.adminAccess === CONFIG.roleTemplateAccess.fullAccess) {
                    return <Option key={index} disabled={names?.includes(JSON.stringify({ id: demo.id, title: demo.title }))} value={JSON.stringify({ id: demo.id, title: demo.title })} title={demo.title} label={demo.title}>
                        {demo.title}
                        {!(demo?.active || demo?.isActive) ?
                            < Tooltip
                                title="This demo is currently inactive or deleted">
                                &nbsp;< Icon type="warning" theme="twoTone" twoToneColor="#ff9000" />
                            </Tooltip>
                            : null}
                    </Option>
                }
                return null;
            });
        }
    }

    renderResourceOptions = (key, dcKey) => {
        const { form } = this.props;
        const names = form.getFieldValue('names');
        if (this.state.data && this.state.data[key]) {
            return names[dcKey].map((dataCenter, index) => {
                return <OptGroup label={JSON.parse(dataCenter).title}>
                    {this.state.data[key]?.filter(resource => resource.dataCenter.id === JSON.parse(dataCenter).id)?.map((resource, index) => {
                        return <Option key={index} value={JSON.stringify({ id: resource.id, title: resource.name })} title={resource.name} label={resource.name}>{resource.name}</Option>
                    })}
                </OptGroup>
            })
        }
    }

    renderDemoDataCenterOptions = (key) => {
        if (this.state.dcData && this.state.dcData[key]) {
            return this.state.dcData[key]?.map((dataCenter, index) => {
                return <Option key={index} value={JSON.stringify({ id: dataCenter.id, title: dataCenter.title })} title={dataCenter.title} label={dataCenter.title}>{dataCenter.title}</Option>
            });
        }
    }

    remove = (k, key) => {
        const { form } = this.props;
        const keys = form.getFieldValue(key);
        form.setFieldsValue({
            [key]: keys.filter(key => key !== k),
        });
        this.props.clearMaintenanceFormErrors();
        this.shouldFetchSharedDemoList = true;
    };

    add = (key) => {
        const { form } = this.props;
        const keys = form.getFieldValue(key);
        const id = key === "keys" ? ++this.id : ++this.id1
        const nextKeys = keys.concat(id);
        form.setFieldsValue({
            [key]: nextKeys,
        });
        this.props.clearMaintenanceFormErrors();
    };

    handleDemoColumnSelectChange = async (dataCenterKey, resourceKey, key, demo) => {
        const { setFieldsValue } = this.props.form;
        const demoId = JSON.parse(demo)?.id;
        setFieldsValue({ [dataCenterKey]: undefined, [resourceKey]: undefined })
        let isDCLoadingKey = [];
        isDCLoadingKey[key] = true;
        this.dcData[key] = [];
        this.setState({ isDCLoading: isDCLoadingKey, dcData: this.dcData });
        this.shouldFetchSharedDemoList = true;
        getDemoDataCenters(demoId).then(result => {
            this.dcData[key] = result;
            isDCLoadingKey[key] = false;
            this.setState({ dcData: this.dcData, isDCLoading: isDCLoadingKey })
        })
        this.props.clearMaintenanceFormErrors();
    }

    handleDCSelectChange = (demoKey, resourceKey, key, dataCenters) => {
        const { getFieldValue, setFieldsValue } = this.props.form;
        const demoId = JSON.parse(getFieldValue(demoKey)).id;
        let dataCenterIds = []
        dataCenters.forEach(dataCenter => {
            dataCenterIds.push(JSON.parse(dataCenter).id)
        })
        const names = getFieldValue('names')
        let resources = names[key]
        if (resources) resources = resources.filter(item => {
            const dataCenterId = this.state.data[key]?.find(resource => resource.id === JSON.parse(item).id)?.dataCenter.id
            const dataCenterTitle = this.state.data[key]?.find(resource => resource.id === JSON.parse(item).id)?.dataCenter.title
            return dataCenters.includes(JSON.stringify({ id: dataCenterId, title: dataCenterTitle }))
        })
        setFieldsValue({ [resourceKey]: resources })
        let isLoadingKey = [];
        isLoadingKey[key] = true;
        this.data[key] = [];
        this.setState({ isLoading: isLoadingKey, data: this.data })
        this.shouldFetchSharedDemoList = true;
        getDemoResources(demoId, dataCenterIds.join(",")).then(result => {
            this.data[key] = result;
            isLoadingKey[key] = false;
            this.setState({ data: this.data, isLoading: isLoadingKey })
        })
        this.props.clearMaintenanceFormErrors();
    }

    handleResourceSelectChange = () => {
        this.props.clearMaintenanceFormErrors();
        this.shouldFetchSharedDemoList = true;
    }

    isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    handleFreqTypeChange = (value) => {
        const { form } = this.props;
        this.props.clearMaintenanceFormErrors();
        this.setState({ freqType: value })
        form.setFieldsValue({
            'keys1': [0],
        });
    }

    renderDaysOfWeekOptions = () => {
        const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
        return daysOfWeek.map(item => {
            return <Option value={item} >{item}</Option>
        })
    }

    renderMonthDayOptions = () => {
        return [...range(1, 32)].map(item => {
            return <Option value={item} >{item}</Option>
        })
    }

    renderValueOptions = () => {
        const values = [
            {
                name: "FIRST",
                value: "1"
            },
            {
                name: "SECOND",
                value: "2"
            },
            {
                name: "THIRD",
                value: "3"
            },
            {
                name: "FOURTH",
                value: "4"
            },
            {
                name: "LAST",
                value: "5"
            }
        ]
        return values.map(item => {
            return <Option value={item.value} >{item.name}</Option>
        })
    }

    renderTimeZoneOptions = () => {
        const timezones = moment.tz.names();
        return timezones.map((timezone, index) => {
            return <Option key={index} value={timezone}>{timezone}</Option>;
        });
    }

    renderFormDetails = () => {
        const { availabilityVisible, sharedDemos, form, maintenanceRelations, maintenanceId, startTime, endTime } = this.props;
        const { getFieldValue } = form;
        let { maintenanceType, freqType, applyToAll, timeZone } = this.state;
        const { getFieldDecorator } = this.props.form;
        const currentDateTime = getDateTime({ convertTimeZone: true, timeZone: timeZone });
        const isMaintenanceClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
        const keys = getFieldValue('keys');
        const keys1 = getFieldValue('keys1');
        return (
            <>
                {this.isFormDisabled && this.renderWarningAlert(maintenanceRelations?.filter(item => item.adminAccess !== CONFIG.roleTemplateAccess.fullAccess)?.map(item => item.title)?.toString())}
                <br />
                {maintenanceType === CONFIG.maintenanceTypes.demo ?
                    <Row gutter={48}>
                        <Col xl={12} sm={10} xs={24}>
                            <Form.Item label="Demo" >
                                {
                                    getFieldDecorator('demo',
                                        {
                                            rules: [{ required: true, message: 'Select a demo' }],
                                            initialValue: this.state.demo.length !== 0 ? this.state.demo : this.props.maintenanceRelations?.map(item => this.isFormDisabled ? item.title : JSON.stringify({ id: item?.id, title: item?.title }))
                                        }

                                    )(
                                        <Select
                                            disabled={this.isFormDisabled}
                                            showSearch={true}
                                            placeholder={'Select a demo'}
                                            mode="multiple"
                                            onChange={this.handleDemoSelect}
                                        >
                                            {
                                                this.renderDemoOptions()
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    : keys.map((k, index) => (
                        <Row gutter={24}>
                            <Col xl={10} xs={24}>
                                <Form.Item
                                    key={k}
                                >
                                    {getFieldDecorator(`names[${(k * 3) + 1}]`, {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please select a demo",
                                            },
                                        ],
                                        initialValue: this.state.names.length !== 0 ? this.isFormDisabled ? JSON.parse(this.state.names[(k * 3) + 1]).title : this.state.names[(k * 3) + 1] : undefined
                                    })
                                        (<Select
                                            disabled={this.isFormDisabled}
                                            placeholder="Select a demo"
                                            showSearch={true}
                                            onChange={(...rest) => this.handleDemoColumnSelectChange(`names[${(k * 3) + 2}]`, `names[${(k * 3) + 3}]`, (k * 3) + 2, ...rest)}
                                        >
                                            {this.renderDemoOptions()}
                                        </Select>)
                                    }
                                </Form.Item>
                            </Col>
                            <Col xl={6} xs={24}>
                                <Form.Item
                                    key={k}
                                >
                                    {getFieldValue(`names[${(k * 3) + 1}]`) && getFieldDecorator(`names[${(k * 3) + 2}]`, {
                                        validateTrigger: ['onChange', 'onBlur'],
                                        rules: [
                                            {
                                                type: "array",
                                                whitespace: true,
                                                message: "Please select a data center (optional)",
                                            },
                                        ],
                                        initialValue: this.state.names.length !== 0 ? this.isFormDisabled ? this.state.names[(k * 3) + 2]?.map(item => JSON.parse(item).title) : this.state.names[(k * 3) + 2] : undefined

                                    })(<Select
                                        mode="multiple"
                                        disabled={this.isFormDisabled}
                                        loading={this.state.isDCLoading[(k * 3) + 2]}
                                        placeholder="Select a data center (optional)"
                                        showSearch={true}
                                        onChange={(...rest) => this.handleDCSelectChange(`names[${(k * 3) + 1}]`, `names[${(k * 3) + 3}]`, (k * 3) + 3, ...rest)}
                                    >
                                        {!this.isFormDisabled && this.renderDemoDataCenterOptions((k * 3) + 2, `names[${(k * 3) + 1}]`)}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            <Col xl={6} xs={24}>
                                <Form.Item
                                    key={k}
                                >
                                    {getFieldValue(`names[${(k * 3) + 2}]`) && getFieldDecorator(`names[${(k * 3) + 3}]`, {
                                        initialValue: this.state.names.length !== 0 ? this.isFormDisabled ? this.state.names[(k * 3) + 3]?.map(item => JSON.parse(item).title) : this.state.names[(k * 3) + 3] : undefined

                                    })(<Select
                                        loading={this.state.isLoading[(k * 3) + 3]}
                                        disabled={this.isFormDisabled}
                                        mode="multiple"
                                        showSearch={true}
                                        placeholder="Select a resource (optional)"
                                        onChange={this.handleResourceSelectChange}
                                    >
                                        {!this.isFormDisabled && this.renderResourceOptions((k * 3) + 3, (k * 3) + 2)}
                                    </Select>)}
                                </Form.Item>

                            </Col>
                            {index !== 0 && !this.isFormDisabled &&
                                <Col xl={1} xs={24} style={{ marginTop: 10 }}>
                                    {<Icon
                                        className="dynamic-delete-button"
                                        type="minus-circle-o"
                                        onClick={() => this.remove(k, 'keys')}
                                    />}
                                </Col>
                            }
                            {(keys.length - 1) === index && !this.isFormDisabled &&
                                <Col xl={1} xs={24} style={{ marginTop: 10 }}>
                                    {<Icon
                                        className="dynamic-delete-button"
                                        type="plus-circle"
                                        onClick={() => this.add('keys')}
                                    />}
                                </Col>
                            }
                        </Row>
                    ))
                }
                <Row gutter={48}>
                    <Col xl={5} sm={10} xs={24}>
                        <Form.Item label="Time Zone">
                            {getFieldDecorator("timezone", {
                                initialValue: this.state.timeZone
                            })(<Select
                                showSearch={true}
                                disabled={this.isFormDisabled}
                                onChange={(value) => this.setState({ timeZone: value })}
                            >
                                {this.renderTimeZoneOptions()}
                            </Select>)
                            }
                        </Form.Item>
                    </Col>
                    <Col span={5} sm={5} xs={24}>
                        <Form.Item label="Notify Scheduled Users">
                            {getFieldDecorator("notifyUsers", {
                                valuePropName: "checked",
                                initialValue: this.state.notifyUsers
                            })(
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    disabled={this.isFormDisabled}
                                    onChange={(value) => this.setState((prevState) => ({ notifyUsers: value, emailTemplateId: !value ? undefined : prevState?.emailTemplateId }))}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    {maintenanceId && this.props.freqType !== "1" && !isMaintenanceClone &&
                        <Col span={4} sm={4} xs={24}>
                            <Form.Item label="Apply to all">
                                {getFieldDecorator("applyToAll", {
                                    valuePropName: "checked",
                                    initialValue: this.state.applyToAll
                                })(
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        disabled={this.isFormDisabled}
                                        onChange={(value) => {
                                            this.props.clearMaintenanceFormErrors();
                                            let names = []
                                            if ((freqType === "sm" || freqType === "sw") && value) {
                                                names[1] = moment.tz(this.state.groupMaintenanceSchedules[0]?.startTime, this.state.timeZone);
                                                names[2] = moment.tz(this.state.groupMaintenanceSchedules[0]?.endTime, this.state.timeZone)
                                            }
                                            else if (freqType === "sm" || freqType === "sw") {
                                                names[1] = moment.tz(startTime, this.state.timeZone);
                                                names[2] = moment.tz(endTime, this.state.timeZone)
                                            }
                                            this.setState((prevState) => ({ applyToAll: value, names1: names.length !== 0 ? names : prevState.names1 }))
                                        }
                                        }
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    }
                </Row>
                {(!maintenanceId || isMaintenanceClone || (maintenanceId && applyToAll && (this.props.freqType === "s")) || (maintenanceId && (this.props.freqType === "r" || this.props.freqType === "1"))) &&
                    <Row gutter={48}>
                        <Col span={6} >
                            <Form.Item label="Maintenance Recurrence">
                                {getFieldDecorator("freqType", {
                                    initialValue: !isMaintenanceClone ? this.state.freqType : "1"
                                })(<Select onChange={this.handleFreqTypeChange} disabled={maintenanceId && !isMaintenanceClone && this.props.freqType === "r"}>
                                    <Option key="1" value='1' disabled={maintenanceId && !isMaintenanceClone && this.props.freqType === "s"}>Does not repeat</Option>
                                    <Option key="2" value='sw' >Weekly</Option>
                                    <Option key="3" value="sm" >Monthly</Option>
                                    <Option key="4" value="r" disabled={maintenanceId && !isMaintenanceClone && this.props.freqType === "s"}>Custom</Option>
                                </Select>)}
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {keys1.map((k, index) => (
                    <Row gutter={48}>
                        <Col xl={6} sm={12} xs={24} >
                            <Form.Item label="Start Time">
                                {getFieldDecorator(`names1[${(k * 3) + 1}]`, {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select start time"
                                        }
                                    ],
                                    initialValue: this.state.names1[(k * 3) + 1]
                                })(<DatePicker
                                    className="full-width"
                                    disabledDate={(...rest) => this.disabledStartDate(k, ...rest)}
                                    disabledTime={(...rest) => this.disabledPublishTime(k, ...rest)}
                                    showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                    format={CONFIG.dateFormats.default}
                                    placeholder="Start Time"
                                    onChange={(...rest) => this.handleDateChange(k, ...rest)}
                                    onOpenChange={(...rest) => this.handleStartOpenChange(k, ...rest)}
                                    showToday={false}
                                    defaultPickerValue={currentDateTime}
                                    disabled={this.isFormDisabled}
                                />)}
                            </Form.Item>
                        </Col>
                        <Col xl={6} sm={12} xs={24} >
                            <Form.Item label="End Time">
                                {getFieldDecorator(`names1[${(k * 3) + 2}]`, {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please select end time"
                                        }
                                    ],
                                    initialValue: this.state.names1[(k * 3) + 2]
                                })(<DatePicker
                                    className="full-width"
                                    disabledTime={(...rest) => this.disabledExpiryTime(k, ...rest)}
                                    disabledDate={(...rest) => this.disabledEndDate(k, null, ...rest)}
                                    onOpenChange={(...rest) => this.handleEndOpenChange(k, ...rest)}
                                    showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                    format={CONFIG.dateFormats.default}
                                    placeholder="End Time"
                                    onChange={(...rest) => this.handleDateChange(k, ...rest)}
                                    showToday={false}
                                    defaultPickerValue={currentDateTime}
                                    disabled={this.isFormDisabled}
                                />)}
                            </Form.Item>
                        </Col>
                        {(getFieldValue(`names1[${(k * 3) + 1}]`) || getFieldValue(`names1[${(k * 3) + 2}]`)) && <Col xl={4} sm={4} xs={24}>
                            <Form.Item label="All Day">
                                {getFieldDecorator(`names1[${(k * 3) + 3}]`, {
                                    valuePropName: "checked"
                                })(
                                    <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onClick={(...rest) => this.handleSwitchChange(k, ...rest)}
                                        disabled={this.isFormDisabled}
                                    />
                                )}
                            </Form.Item>
                        </Col>}
                        {index !== 0 && !this.isFormDisabled && freqType === "r" && ((maintenanceId && !isMaintenanceClone && this.props.freqType !== "1") ? applyToAll : true) &&
                            <Col xl={1} xs={24} style={{ marginTop: 50 }}>
                                {<Icon
                                    className="dynamic-delete-button"
                                    type="minus-circle-o"
                                    onClick={() => this.remove(k, 'keys1')}
                                />}
                            </Col>
                        }
                        {(keys1.length - 1) === index && !this.isFormDisabled && freqType === "r" && ((maintenanceId && !isMaintenanceClone && this.props.freqType !== "1") ? applyToAll : true) &&
                            <Col xl={1} xs={24} style={{ marginTop: 50 }}>
                                {<Icon
                                    className="dynamic-delete-button"
                                    type="plus-circle"
                                    onClick={() => this.add('keys1')}
                                />}
                            </Col>
                        }
                    </Row>
                ))
                }
                {
                    (((!maintenanceId || isMaintenanceClone) && freqType === "sw") || ((!maintenanceId || isMaintenanceClone) && freqType === "sm") || (maintenanceId && freqType === "sm" && applyToAll) || (maintenanceId && freqType === "sw" && applyToAll) || (maintenanceId && this.props.freqType === "1" && (freqType === "sm" || freqType === "sw"))) &&
                    <>
                        <Row gutter={48}>
                            <Col span={6}>
                                <Form.Item label="Series End Date">
                                    {getFieldDecorator('seriesEndDate', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please select series end date"
                                            }
                                        ],
                                        initialValue: isMaintenanceClone ? null : this.state.seriesEndDate
                                    })(<DatePicker
                                        className="full-width"
                                        disabledDate={(...rest) => this.disabledEndDate(null, true, ...rest)}
                                        format={CONFIG.dateFormats.calendarDate}
                                        onChange={(value) => {
                                            this.props.clearMaintenanceFormErrors();
                                            this.setState({ seriesEndDate: value })
                                        }}
                                        showToday={false}
                                        disabled={this.isFormDisabled}
                                    />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                }
                {(((!maintenanceId || isMaintenanceClone) && freqType === "sw") || (maintenanceId && freqType === "sw" && applyToAll) || (maintenanceId && this.props.freqType === "1" && freqType === "sw")) &&
                    <Row gutter={48}>
                        <Col xl={10} sm={10} xs={24}>
                            <Form.Item label="Occurs every" >
                                {
                                    getFieldDecorator('dayOfWeek',
                                        {
                                            rules: [{ required: true, message: 'Select a day of week' }],
                                            initialValue: isMaintenanceClone ? [] : this.state.dayOfWeek
                                        }

                                    )(
                                        <Select
                                            disabled={this.isFormDisabled}
                                            showSearch={true}
                                            placeholder='Select a value'
                                            mode="multiple"
                                            onChange={value => {
                                                this.props.clearMaintenanceFormErrors();
                                                this.setState({ dayOfWeek: value })
                                            }}
                                        >
                                            {
                                                this.renderDaysOfWeekOptions()
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {(((!maintenanceId || isMaintenanceClone) && freqType === "sm") || (maintenanceId && freqType === "sm" && applyToAll) || (maintenanceId && this.props.freqType === "1" && freqType === "sm")) &&
                    <Row gutter={48}>
                        <Col xl={10} sm={10} xs={24}>
                            <Form.Item >
                                {
                                    getFieldDecorator('radioToggle',
                                        {
                                            initialValue: isMaintenanceClone ? 1 : this.state.radioToggle
                                        }

                                    )(
                                        <Radio.Group onChange={(e) => {
                                            this.props.clearMaintenanceFormErrors();
                                            this.setState({ radioToggle: e.target.value })
                                        }}
                                        >
                                            <Radio value={1} style={radioStyle}>
                                                On day
                                                <Form.Item >
                                                    {
                                                        getFieldDecorator('monthDay',
                                                            {
                                                                initialValue: isMaintenanceClone ? ["1"] : this.state.monthDay,
                                                                rules: [{ required: this.state.radioToggle === 2 ? false : true, message: 'Select a value' }],
                                                            }

                                                        )(
                                                            <Select
                                                                disabled={this.isFormDisabled || this.state.radioToggle === 2}
                                                                showSearch={true}
                                                                placeholder='Select a value'
                                                                mode="multiple"
                                                                maxTagCount={4}
                                                                style={{ width: 300 }}
                                                                value={this.state.monthDay}
                                                                onChange={(value) => {
                                                                    this.props.clearMaintenanceFormErrors();
                                                                    this.setState({ monthDay: value })
                                                                }
                                                                }
                                                            >
                                                                {
                                                                    this.renderMonthDayOptions()
                                                                }
                                                            </Select>)}
                                                </Form.Item>
                                            </Radio>
                                            <Radio value={2} style={radioStyle}>
                                                On the
                                                <br />
                                                {getFieldDecorator('value',
                                                    {
                                                        initialValue: isMaintenanceClone ? "1" : this.state.value,
                                                    }

                                                )(<Select
                                                    disabled={this.isFormDisabled || this.state.radioToggle === 1}
                                                    showSearch={true}
                                                    placeholder='Select a value'
                                                    style={{ width: 140 }}
                                                    value={this.state.value}
                                                    onChange={(value) => {
                                                        this.props.clearMaintenanceFormErrors();
                                                        this.setState({ value })
                                                    }}
                                                >
                                                    {
                                                        this.renderValueOptions()
                                                    }
                                                </Select>)

                                                }
                                                {getFieldDecorator('dayName',
                                                    {
                                                        initialValue: isMaintenanceClone ? "MONDAY" : this.state.dayName,
                                                    }

                                                )

                                                    (<Select
                                                        disabled={this.isFormDisabled || this.state.radioToggle === 1}
                                                        showSearch={true}
                                                        placeholder='Select a value'
                                                        style={{ width: 140, marginLeft: 20 }}
                                                        value={this.state.dayName}
                                                        onChange={value => {
                                                            this.props.clearMaintenanceFormErrors();
                                                            this.setState({ dayName: value })
                                                        }}
                                                    >
                                                        {
                                                            this.renderDaysOfWeekOptions()
                                                        }
                                                    </Select>
                                                    )}
                                            </Radio>
                                        </Radio.Group>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {
                    (maintenanceId && this.props.freqType !== "1" && !isMaintenanceClone) &&
                    <Row gutter={24} className="vertical-spacing">
                        <Col xl={24} xs={24}>
                            <Collapse bordered={true} >
                                <Panel header={<h3>List of maintenance schedules belonging to this series</h3>} key="1">
                                    <div>{this.renderMaintenanceGroupList()}</div>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                }
                {
                    sharedDemos?.length > 0 && <Row gutter={24} className="vertical-spacing">
                        <Col xl={24} xs={24}>
                            <Collapse bordered={true} defaultActiveKey={['1']}>
                                <Panel header={<h3>List of Demos with shared resources</h3>} key="1">
                                    <div>{this.renderDemoDependencyList()}</div>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                }
                <Row>
                    {this.props.isMaintenanceAvailable !== undefined && this.props.isMaintenanceAvailable && this.renderSuccessAlert()}
                    {this.props.isMaintenanceAvailable !== undefined && !this.props.isMaintenanceAvailable && this.renderErrorAlert()}
                </Row>
                <Modal
                    className="maintenance-form-modal"
                    title="Maintenance Schedule Details"
                    visible={availabilityVisible}
                    footer={this.renderModalFooterButtons()}
                    onCancel={this.handleModalClose}
                >
                    {availabilityVisible && <MaintenanceAvailability />}
                </Modal>
            </>
        )
    }

    renderAffectedDemosList = () => {
        const maintenanceObj = this.createScheduleObject();

        return maintenanceObj.maintenanceRelations.map(item => {
            if (item.resource && item.resource !== null) {
                return <ul><li>{item.demo.title}({item.dataCenter.title})({item.resource.name})</li></ul>
            }
            else if (item.dataCenter) {
                return <ul><li>{item.demo.title}({item.dataCenter.title})</li></ul>
            }
            else return <ul><li>{item.demo.title}</li></ul>

        })

    }

    renderMaintenanceWindow = () => {
        const valueMap = {
            '1': 'first',
            '2': 'second',
            '3': 'third',
            '4': 'fourth',
            '5': 'last'
        }
        const { timeZone } = this.state;
        const { freqType, names1, keys1, dayOfWeek, seriesEndDate, monthDay, dayName, value, radioToggle } = this.state;

        if (freqType === "1") return <>{moment.tz(names1[1], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} - {moment.tz(names1[2], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)}</>
        else if (freqType === "r") {
            return keys1.map(k => {
                return <ul><li>{moment.tz(names1[(k * 3) + 1], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} - {moment.tz(names1[(k * 3) + 2], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)}</li></ul>
            })
        }
        else if (freqType === "sw") {
            return <>Occurs every {dayOfWeek.map(item => item[0] + item.slice(1).toLowerCase()).join(", ")} from {moment.tz(names1[1], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} to {moment.tz(names1[2], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} until {moment.tz(seriesEndDate, timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} </>
        }
        else {
            if (radioToggle === 1) return <>Occurs every month on day(s) {monthDay.join(", ")} from {moment.tz(names1[1], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} to {moment.tz(names1[2], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} until {moment.tz(seriesEndDate, timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} </>
            else return <>Occurs every month on the {valueMap[value].toLowerCase()} {dayName[0] + dayName.slice(1).toLowerCase()} from {moment.tz(names1[1], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} to {moment.tz(names1[2], timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} until {moment.tz(seriesEndDate, timeZone).format(CONFIG.dateFormats.userDateTimeWithTZ)} </>
        }

    }

    renderConfirmationPage = () => {
        return (
            <>
                <h2><b>Please confirm the Demo Details which will be added for Maintenance</b></h2>
                <br />
                <br />
                <p><b>Demos Affected :</b>{this.renderAffectedDemosList()}</p>
                <br />
                <p><b>Maintenance Window :</b> {this.renderMaintenanceWindow()}</p>
                <br />
                <p><b>Affected Scope :</b> Affected demos will not be available to users to schedule during this maintenance window</p>
            </>
        )
    }


    handleNext = async () => {
        const { form } = this.props;
        const { recordId } = this.props.match.params;
        const currentStep = this.state.currentStep + 1;
        await this.props.toggleIsFetchingMaintenanceSchedule(true);
        if (this.state.currentStep === 1 && !this.isFormDisabled) {
            let names = form.getFieldValue('names') || []
            let keys = form.getFieldValue('keys') || []
            let names1 = form.getFieldValue('names1') || []
            let keys1 = form.getFieldValue('keys1') || []
            this.setState({ names, keys, names1, keys1 })
        }
        form.validateFields((err) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
                return
            }
            this.setState({ currentStep });
        });

        if ((this.state.currentStep === 1) && recordId) {
            setTimeout(async () => {
                await this.props.toggleIsFetchingMaintenanceSchedule(false);
            }, 2500)
        }
        else await this.props.toggleIsFetchingMaintenanceSchedule(false);
    }

    handlePrev = () => {
        const { form } = this.props;
        const currentStep = this.state.currentStep - 1;
        if (this.state.currentStep === 1 && !this.isFormDisabled) {
            let names = form.getFieldValue('names') || []
            let keys = form.getFieldValue('keys') || []
            let names1 = form.getFieldValue('names1') || []
            let keys1 = form.getFieldValue('keys1') || []
            this.setState({ names, keys, names1, keys1 })
        }
        this.setState({ currentStep });
    }

    handleEmailTemplateSelect = async (templateId) => {
        await this.setState({ emailTemplateId: templateId });
    }

    render() {
        const { isFetching, form, maintenanceId, maintenanceRelations } = this.props;
        const isMaintenanceClone = window.location.pathname.split('/').slice(-2)[0] === "clone";
        const { getFieldDecorator } = form;
        let { currentStep, notifyUsers } = this.state;

        getFieldDecorator('keys', { initialValue: maintenanceId ? [...Array(maintenanceRelations.length).keys()] : this.state.keys.length !== 0 ? this.state.keys : [0] });
        getFieldDecorator('keys1', { initialValue: this.state.keys1.length !== 0 ? this.state.keys1 : [0] });

        if (this.shouldFetchSharedDemoList && !this.isFormDisabled) {
            this.shouldFetchSharedDemoList = false;
            this.props.fetchSharedDemoDetails(this.createScheduleObject(), this.state.maintenanceType)
        }

        return (
            <div className="content-container">
                <Form>
                    <Spin spinning={(currentStep !== 0 && isFetching) || (!maintenanceRelations && isFetching)} wrapperClassName="spin-overlay">
                        <Steps current={currentStep}>
                            <Step title="Add maintenance name/description" />
                            <Step title="Add maintenance details" />
                            {notifyUsers && <Step title="Select Email Version" />}
                            <Step title={maintenanceId && !isMaintenanceClone ? "Update maintenance" : "Create maintenance"} />
                        </Steps>
                        <div className="maintenance-steps-content">
                            {currentStep === 0 && this.renderFormBasicDetails()}
                            {currentStep === 1 && this.renderFormDetails()}
                            {notifyUsers && currentStep === 2 &&
                                <ManageMaintenanceTemplateVersions
                                    emailType={maintenanceId && !isMaintenanceClone ? CONFIG.emailTemplateEventType.maintenanceUpdate : CONFIG.emailTemplateEventType.maintenanceCreate}
                                    fromMaintenanceForm={true}
                                    emailTemplateId={this?.state?.emailTemplateId}
                                    handleEmailTemplateSelect={this.handleEmailTemplateSelect}
                                />}
                            {currentStep === (notifyUsers ? 3 : 2) && this.renderConfirmationPage()}
                            <Row className="right-align vertical-spacing">{this.renderFooterButtons()}</Row>
                        </div>

                    </Spin>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = ({ demos, maintenanceForm, maintenanceSchedule, user, resources, dataCenters, tabsLayout }) => {
    return {
        isMaintenanceAvailable: maintenanceForm.isMaintenanceAvailable,
        overrideMaintenance: maintenanceForm.overrideMaintenance,
        maintenanceUnavailableCount: maintenanceForm.maintenanceUnavailableCount,
        unavailableResources: maintenanceForm.unavailableResources,
        unavailableLabDemos: maintenanceForm.unavailableLabDemos,
        scheduleConflictsCount: maintenanceForm.scheduleConflictsCount,
        labSchedules: maintenanceForm.labSchedules,
        availabilityVisible: maintenanceForm.availabilityVisible,
        demos: demos.allDemos,
        demo: maintenanceForm.demo,
        maintenanceId: maintenanceForm.maintenanceId,
        groupId: maintenanceForm.groupId,
        maintenanceRelations: maintenanceForm.maintenanceRelations,
        maintenanceRelationsObj: maintenanceForm.maintenanceRelationsObj,
        startTime: maintenanceForm.startTime,
        endTime: maintenanceForm.endTime,
        description: maintenanceForm.description,
        title: maintenanceForm.title,
        maintenanceType: maintenanceForm.maintenanceType,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        notifyUsers: maintenanceForm.notifyUsers,
        sharedDemos: maintenanceForm.sharedDemos,
        isFetching: maintenanceSchedule.isFetching,
        maintenanceForm,
        resources: resources.data,
        allDataCenters: dataCenters.data,
        freqType: maintenanceForm.freqType,
        dateRangeList: maintenanceForm.dateRangeList,
        dayOfWeek: maintenanceForm.dayOfWeek,
        endSeriesDate: maintenanceForm.endSeriesDate,
        monthDay: maintenanceForm.monthDay,
        dayName: maintenanceForm.dayName,
        value: maintenanceForm.value,
        timeZone: maintenanceForm.timeZone,
        activeKey: tabsLayout.activeKey,
        openedTabs: tabsLayout.panes
    };
};

const ScheduleMaintenanceFormRef = Form.create()(ScheduleMaintenanceForm);

export default connect(
    mapStateToProps,
    {
        checkMaintenanceAvailability,
        postMaintenanceSchedule,
        putMaintenanceSchedule,
        putBulkMaintenanceSchedule,
        toggleMaintenanceFormModal,
        fetchMaintenanceSchedule,
        toggleBackButton,
        clearMaintenanceForm,
        toggleIsFetchingMaintenanceSchedule,
        toggleisMaintenanceAvailabilityVisible,
        setMaintenanceErrorResource,
        clearMaintenanceFormErrors,
        fetchSharedDemoDetails,
        clearSharedDemoDetails,
        currentMaintenanceFilter,
        setMaintenanceErrorType,
        changeMaintenanceType,
        fetchDataCenters,
        updateSharedDemoList,
        editMaintenanceSchedule,
        fetchDemos,
        closeCurrentOpenTab,
        toggleHasUnsavedChangesFlag
    }
)(ScheduleMaintenanceFormRef);