
import { batch } from 'react-redux';

import { aepNewsNotificationSupport } from '../apis';
import {
    FETCH_SUPPORT_GUIDES,
    FETCH_ALL_SUPPORT_GUIDES,
    TOGGLE_IS_FETCHING_SUPPORT_GUIDES,
    REORDER_SUPPORT_GUIDES,
    TOGGLE_IS_SUPPORT_GUIDES_REORDERED,
    DELETE_SUPPORT_GUIDE,
    CLEAR_SUPPORT,
    UPDATE_SUPPORT_ROLE_TEMPLATE,
    FETCH_SUPPORT_GUIDE,
    UPDATE_CURRENT_GUIDE,
    LAST_SUPPORT_FILTER,
    CLEAR_SUPPORT_FORM,
    FETCH_ALL_USER_SUPPORT_GUIDES,
    TOGGLE_IS_FETCHING_USER_SUPPORT,
    CLEAR_USER_SUPPORT_GUIDES
} from './types';
import pushMessage from '../components/common/PushMessage';
import CONFIG from '../config';
import history from '../history';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';
import { deleteTab } from './tabsLayout';

export const getAllSupport = async () => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/support/all");
    return response.data ? response.data : [];
}


export const fetchSupportGuides = () => async (dispatch, getState) => {
    const isLoginComplete = getState().user.isLoginComplete
    const params = !isLoginComplete ? { type: CONFIG.guideType.gettingStarted.value } : undefined
    const response = await aepNewsNotificationSupport.get("/supportService/support", { params });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        batch(() => {
            dispatch({ type: FETCH_SUPPORT_GUIDES, payload: response.data });
        });
    }
    else if (response.status !== CONFIG.HTTP_STATUS.NO_CONTENT) {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Support Guides');
    }
    dispatch(toggleIsFetchingSupportGuides(false));
}

export const fetchAllSupportGuides = (gType, viewDeletedItems = false) => async dispatch => {
    dispatch(toggleIsFetchingSupportGuides(true));
    const params = {
        viewDeletedItems,
        guideType: gType
    };
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/support/all", { params });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const mockResponse = response.data.map((guide) => { return { ...guide, roleTemplates: [] } });
        batch(() => {
            dispatch({ type: FETCH_ALL_SUPPORT_GUIDES, payload: mockResponse });
        });
    }
    else if (response.status !== CONFIG.HTTP_STATUS.NO_CONTENT) {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Support Guides');
    }
    dispatch(toggleIsFetchingSupportGuides(false));
};

export const toggleIsFetchingSupportGuides = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_SUPPORT_GUIDES,
        payload: flag
    };
};

export const getSupport = () => async dispatch => {
    // Fresh Desk Link
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/support/redirect");
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        window.open(`https://${response.data}`, '_blank')
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to Contact');
};

export const reorderSupportGuides = (reorderedGuides) => dispatch => {
    batch(() => {
        dispatch({
            type: REORDER_SUPPORT_GUIDES,
            payload: [...reorderedGuides]
        });
        dispatch(toggleIsSupportGuidesReordered(true));
    });
};

export const toggleIsSupportGuidesReordered = (flag) => {
    return {
        type: TOGGLE_IS_SUPPORT_GUIDES_REORDERED,
        payload: flag
    };
};

export const deleteSupportGuide = (guideId, hardDelete = false) => async dispatch => {
    const response = await aepNewsNotificationSupport.delete(`/newsNotificationSupportService/support/${guideId}`, { params: { hardDelete } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: DELETE_SUPPORT_GUIDE,
            payload: guideId
        });
        pushMessage(CONFIG.messageType.success, "Support guide deleted successfully");
    }
    else pushMessage(CONFIG.messageType.error, "Unable to delete support guide");
};

export const postSupportGuide = (file, formValues, guideId) => async (dispatch, getState) => {
    dispatch(toggleIsFetchingSupportGuides(true))
    const formData = new FormData();
    const support = getState().supportForm;
    const roleTemplates = support.currentGuide ? support.currentGuide.roleTemplates : [];
    let supportObj = {
        ...formValues,
        roleTemplates,
        file: undefined
    };
    const supportJSONString = JSON.stringify(supportObj);
    const supportBlob = new Blob([supportJSONString], {
        type: 'application/json'
    });
    if (file?.size !== 0) formData.append('mediaFile', file);
    formData.append('supportGuide', supportBlob)
    const contextPath = "/newsNotificationSupportService/support"
    const response = await aepNewsNotificationSupport({
        method: guideId === undefined ? 'post' : 'put',
        url: guideId === undefined ? contextPath : `${contextPath}/${guideId}`,
        data: formData
    });
    const successMessage = guideId ? 'Support guide updated successfully' : 'Support guide created successfully';
    const errorMessage = guideId ? 'Unable to update Support guide' : 'Unable to create Support guide';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage);
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(fetchAllSupportGuides(formValues.guideType));
    dispatch(deleteTab(guideId ? CONFIG.editComponentRoute.support.key + guideId : CONFIG.createComponentRoute.support.key, true))
    const activeKey = getState().tabsLayout.activeKey;
    history.replace(activeKey)
};

export const putSupportGuidesOrder = (guides) => async dispatch => {
    const response = await aepNewsNotificationSupport.put("newsNotificationSupportService/support", guides);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Support guides order updated successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to update support guides order");
    dispatch(toggleIsSupportGuidesReordered(false));
};

export const clearSupport = () => {
    return { type: CLEAR_SUPPORT }
}

export const updateSupportRoleTemplate = (access, accessType, roleType, selectedRoleTemplate) => (dispatch, getState) => {
    const allRoleTemplates = getState().roleTemplates.all;
    const assignedRoleTemplates = getState().supportForm.currentGuide ? getState().supportForm.currentGuide.roleTemplates : [];
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_SUPPORT_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access }
    });
}

export const updateSelectedGuideType = (selectedGuide) => (dispatch) => {
    dispatch({
        type: UPDATE_CURRENT_GUIDE,
        payload: selectedGuide
    })
}


export const fetchSupportGuide = (guideId) => async (dispatch) => {
    dispatch(toggleIsFetchingSupportGuides(true));
    const response = await aepNewsNotificationSupport.get(`newsNotificationSupportService/support/${guideId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: FETCH_SUPPORT_GUIDE,
            payload: response.data
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch guide');
    dispatch(toggleIsFetchingSupportGuides(false));
}

export const restoreSupportGuide = (guideId) => async () => {
    const response = await aepNewsNotificationSupport.put(`newsNotificationSupportService/restore/support/${guideId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Support guide restored successfully');
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to restore support guide');
}

export const currentSupportFilter = (filters, guideType) => {
    return {
        type: LAST_SUPPORT_FILTER,
        payload: [filters, guideType]
    }
}

export const clearSupportForm = () => {
    return {
        type: CLEAR_SUPPORT_FORM
    }
}

export const toggleIsFetchingUserSupport = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_USER_SUPPORT,
        payload: flag
    }
}

export const fetchUserSupportGuides = (type) => async (dispatch) => {
    dispatch(toggleIsFetchingUserSupport(true));
    const params = {
        type: type
    };
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/user/support", { params });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        batch(() => {
            dispatch({ type: FETCH_ALL_USER_SUPPORT_GUIDES, payload: response.data || [] });
        });
    }
    else if (response.status !== CONFIG.HTTP_STATUS.NO_CONTENT) {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Support Guides');
    }
    dispatch(toggleIsFetchingUserSupport(false));
}

export const clearUserSupport = () => {
    return { type: CLEAR_USER_SUPPORT_GUIDES }
}