export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER_TIME_ZONE = "UPDATE_USER_TIME_ZONE";
export const UPDATE_USER_EMAIL_SUBSCRIPTION = "UPDATE_USER_EMAIL_SUBSCRIPTION";
export const UPDATE_INVITE_OPT_OUT_SUBSCRIPTION = "UPDATE_INVITE_OPT_OUT_SUBSCRIPTION";
export const TOGGLE_IS_FIRST_TIME_LOGIN = "TOGGLE_IS_FIRST_TIME_LOGIN";
export const SET_IS_LOGIN_COMPLETE = "SET_IS_LOGIN_COMPLETE";
export const UPDATE_SA_USER_TAB_LIMIT = "UPDATE_SA_USER_TAB_LIMIT";
export const FETCH_USER_GROUPS = "FETCH_USER_GROUPS";
export const FETCH_TAB_LIMITS_FOR_USERS = "FETCH_TAB_LIMITS_FOR_USERS";
export const RESET_LDAP_PASSWORD = "RESET_LDAP_PASSWORD";
export const TOGGLE_IS_USER_LOADING = "TOGGLE_IS_USER_LOADING";
export const FETCH_ALL_COMPANIES = "FETCH_ALL_COMPANIES";
export const UPDATE_COMPANIES = "UPDATE_COMPANIES";
export const SET_HAS_PASSWORD_CHANGED = "SET_HAS_PASSWORD_CHANGED";
export const FETCH_DECRYPTED_LDAP_PASSWORD = "FETCH_DECRYPTED_LDAP_PASSWORD";
export const UPDATE_USER_PROFILE_PIC = "UPDATE_USER_PROFILE_PIC";
export const UPDATE_CONTENT_LOADER = "UPDATE_CONTENT_LOADER"
export const SHOW_PORTAL_TOUR_MODAL = "SHOW_PORTAL_TOUR_MODAL";
export const SET_PORTAL_TOUR_COMPLETE = "SET_PORTAL_TOUR_COMPLETE";
export const UPDATE_TESTING_RECIPIENTS = "UPDATE_TESTING_RECIPIENTS";

// Dashboard Schedules

export const DASHBOARD_ACTIVE_SCHEDULES = "DASHBOARD_ACTIVE_SCHEDULES"
export const DASHBOARD_PREVIOUS_SCHEDULES = "DASHBOARD_PREVIOUS_SCHEDULES"
export const FETCH_LOCAL_USERS = "FETCH_LOCAL_USERS";
export const REFRESH_SSO_USERS = "REFRESH_SSO_USERS";
export const UPDATE_SSO_USER_FILTERS = "UPDATE_SSO_USER_FILTERS";
export const UPDATE_LOCAL_USER = "UPDATE_LOCAL_USER";
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_LOCAL_USER = "FETCH_LOCAL_USER";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const UPDATE_LOCAL_USERS_CURRENT_PAGE = "UPDATE_LOCAL_USERS_CURRENT_PAGE";
export const TOGGLE_IS_FETCHING_LOCAL_USERS = "TOGGLE_IS_FETCHING_LOCAL_USERS";
export const UPDATE_LOCAL_USER_EDITING_KEY = "UPDATE_LOCAL_USER_EDITING_KEY";
export const CLEAR_LOCAL_USERS = "CLEAR_LOCAL_USERS";
export const CLEAR_LOCAL_USERS_DETAILS = "CLEAR_LOCAL_USERS_DETAILS";
export const LAST_LOCAL_USER_FILTER = "LAST_LOCAL_USER_FILTER";
export const TOGGLE_IS_LOCAL_USERS_EDIT_TAB_OPENED = "TOGGLE_IS_LOCAL_USERS_EDIT_TAB_OPENED";
export const REMOVE_LOCAL_USER_TAB = "REMOVE_LOCAL_USER_TAB";
export const ADD_LOCAL_USER_TAB = "ADD_LOCAL_USER_TAB";
export const TOGGLE_HAS_LOCAL_USERS_UPDATED = "TOGGLE_HAS_LOCAL_USERS_UPDATED";
export const REFRESH_LOCAL_USERS = "REFRESH_LOCAL_USERS";
export const UPDATE_LOCAL_USER_FORM_TAB_DATA = "UPDATE_LOCAL_USER_FORM_TAB_DATA";
export const DASHBOARD_POPULAR_EZ_DEMOS = "DASHBOARD_POPULAR_EZ_DEMOS";
export const CLEAR_DASHBOARD_EZ_DEMOS = "CLEAR_DASHBOARD_EZ_DEMOS";

// Dashboard Sections
export const SHOW_USER_DASHBOARD_TODAY_SCHEDULES_SECTION = "SHOW_USER_DASHBOARD_TODAY_SCHEDULES_SECTION";
export const SHOW_USER_DASHBOARD_UPCOMING_SCHEDULES_SECTION = "SHOW_USER_DASHBOARD_UPCOMING_SCHEDULES_SECTION";
export const SHOW_USER_DASHBOARD_PREVIOUS_SCHEDULES_SECTION = "SHOW_USER_DASHBOARD_PREVIOUS_SCHEDULES_SECTION";
export const SHOW_USER_DASHBOARD_EZ_DEMO_SECTION = "SHOW_USER_DASHBOARD_EZ_DEMO_SECTION";
export const SHOW_USER_DASHBOARD_NEWS_SECTION = "SHOW_USER_DASHBOARD_NEWS_SECTION";
export const SHOW_USER_DASHBOARD_MOST_SCHEDULED_DEMO_SECTION = "SHOW_USER_DASHBOARD_MOST_SCHEDULED_DEMO_SECTION"
export const CLEAR_USER_DASHBOARD_SECTION_VIEWS = "CLEAR_USER_DASHBOARD_SECTION_VIEWS"
export const SHOW_ADMIN_DASHBOARD_UPCOMING_SCHEDULES_SECTION = "SHOW_ADMIN_DASHBOARD_UPCOMING_SCHEDULES_SECTION"
export const SHOW_ADMIN_DASHBOARD_UPCOMING_MAINTENENCE_SECTION = "SHOW_ADMIN_DASHBOARD_UPCOMING_MAINTENENCE_SECTION"
export const CLEAR_ADMIN_DASHBOARD_SECTION_VIEWS = "CLEAR_ADMIN_DASHBOARD_SECTION_VIEWS"

// Admin Dashboard
export const ADMIN_DASHBOARD_UPCOMING_MAINTENANCE = "ADMIN_DASHBOARD_UPCOMING_MAINTENANCE";
export const ADMIN_DASHBOARD_PENDING_APPROVALS = "ADMIN_DASHBOARD_PENDING_APPROVALS";
export const ADMIN_DASHBOARD_COMPLETED_APPROVALS = "ADMIN_DASHBOARD_COMPLETED_APPROVALS"
export const ADMIN_DASHBOARD_TODAYS_DEMO_TEST = "ADMIN_DASHBOARD_TODAYS_DEMO_TEST";
export const TOGGLE_IS_FETCHING_ADMIN_REQUESTS = "TOGGLE_IS_FETCHING_ADMIN_REQUESTS";
export const CLEAR_ADMIN_DASHBOARD = "CLEAR_ADMIN_DASHBOARD";
export const ADMIN_DASHBOARD_REQUEST_STATS = "ADMIN_DASHBOARD_REQUEST_STATS";
export const CURRENT_APPROVALS_FILTER = "CURRENT_APPROVALS_FILTER";
export const CLEAR_APPROVALS_DATA = "CLEAR_APPROVALS_DATA";
export const FETCHING_REQUEST_STATS = "FETCHING_REQUEST_STATS";
export const TOGGLE_IS_FETCHING_ADMIN_DEMO_STATUS = "TOGGLE_IS_FETCHING_ADMIN_DEMO_STATUS";
export const ADMIN_DASHBOARD_DEMO_STATUS = "ADMIN_DASHBOARD_DEMO_STATUS";
export const ADMIN_DASHBOARD_SFDC_STATUS = "ADMIN_DASHBOARD_SFDC_STATUS";
export const TOGGLE_IS_FETCHING_SFDC_STATUS = "TOGGLE_IS_FETCHING_SFDC_STATUS";

export const TOGGLE_BACK_BUTTON = "TOGGLE_BACK_BUTTON";
export const TOGGLE_CLEAR_PATH = "TOGGLE_CLEAR_PATH";
export const POST_SEARCH_RESULT = "POST_SEARCH_RESULT"
export const SELECT_TITLE = "SELECT_TITLE";
export const TOGGLE_MENU_BUTTON = "TOGGLE_MENU_BUTTON";

export const FETCH_DEMOS = "FETCH_DEMOS";
export const FETCH_ALL_EXISTING_DEMOS = "FETCH_ALL_EXISTING_DEMOS";
export const FETCH_ALL_DEMOS = "FETCH_ALL_DEMOS";
export const TOGGLE_IS_FETCHING_DEMOS = "TOGGLE_IS_FETCHING_DEMOS";
export const CLEAR_DEMOS = "CLEAR_DEMOS";
export const UPDATE_DEMO_SAVE_WARNING = "UPDATE_DEMO_SAVE_WARNING";
export const UPDATE_DEMOS_CURRENT_PAGE = "UPDATE_DEMOS_CURRENT_PAGE";
export const CLEAR_CURRENT_DEMO = "CLEAR_CURRENT_DEMO";
export const FETCH_DEMO_REGIONS = "FETCH_DEMO_REGIONS";
export const UPDATE_DEMO_REGIONS = "UPDATE_DEMO_REGIONS";
export const FETCH_ALL_USERS_REVIEWS = "FETCH_ALL_USERS_REVIEWS";
export const FETCH_USER_REVIEWS = "FETCH_USER_REVIEWS";
export const CURRENT_ACTIVE_DEMO_PANEL = "CURRENT_ACTIVE_DEMO_PANEL"
export const FETCH_CURRENT_DEMO_BASIC_DETAILS = "FETCH_CURRENT_DEMO_BASIC_DETAILS";
export const FETCH_ALL_USERS_FOR_DEMO = "FETCH_ALL_USERS_FOR_DEMO"
export const CLEAR_DEMO_FORM_TAB_DATA = "CLEAR_DEMO_FORM_TAB_DATA"

export const DELETE_DEMO_COLLATERAL = "DELETE_DEMO_COLLATERAL";
export const FETCH_DEMO_COLLATERAL_TYPES = "FETCH_DEMO_COLLATERAL_TYPES";
export const ADD_DEMO_COLLATERALS = "ADD_DEMO_COLLATERALS";
export const REORDER_DEMO_COLLATERALS = "REORDER_DEMO_COLLATERALS";
export const ADD_NEW_DEMO_COLLATERAL = "ADD_NEW_DEMO_COLLATERAL";
export const UPDATE_DEMO_COLLATERAL = "UPDATE_COLLATERAL";
export const CLEAR_NEW_DEMO_COLLATERALS = "CLEAR_NEW_DEMO_COLLATERALS";
export const TOGGLE_IS_UPLOADING_FILE = "TOGGLE_IS_UPLOADING_FILE";
export const FETCH_DELETED_COLLATERALS = "FETCH_DELETED_COLLATERALS";
export const DELETE_COLLATERALS = "DELETE_COLLATERALS";

export const UPDATE_DEMO_DESCRIPTION = "UPDATE_DEMO_DESCRIPTION";
export const ADD_DEMO_COMPONENT_DETAILS = "ADD_DEMO_COMPONENT_DETAILS";
export const UPDATE_DEMO_COMPONENT_DETAILS = "UPDATE_DEMO_COMPONENT_DETAILS";
export const ADD_DEMO_ACCESS = "ADD_DEMO_ACCESS";
export const UPDATE_DEMO_ACCESS = "UPDATE_DEMO_ACCESS";
export const UPDATE_DEMO_STATUS = "UPDATE_DEMO_STATUS";
export const RESET_DEMO_STATUS = "RESET_DEMO_STATUS";

export const FETCH_ACTIVE_RESOURCES = "FETCH_ACTIVE_RESOURCES";
export const ADD_DEMO_RESOURCES = "ADD_DEMO_RESOURCES";
export const ADD_NEW_DEMO_RESOURCE = "ADD_NEW_DEMO_RESOURCE";
export const DELETE_DEMO_RESOURCE = "DELETE_DEMO_RESOURCE";
export const EDIT_DEMO_RESOURCE = "EDIT_DEMO_RESOURCE";
export const CLEAR_RESOURCE_DATA = "CLEAR_RESOURCE_DATA";
export const CLEAR_RESOURCES_RT_TABLE = "CLEAR_RESOURCES_RT_TABLE"

export const FETCH_ACTIVE_CAPABILITY = "FETCH_ACTIVE_CAPABILITY";
export const ADD_DEMO_CAPABILITY = "ADD_DEMO_CAPABILITY";
export const ADD_NEW_DEMO_CAPABILITY = "ADD_NEW_DEMO_CAPABILITY";
export const DELETE_DEMO_CAPABILITY = "DELETE_DEMO_CAPABILITY";
export const EDIT_DEMO_CAPABILITY = "EDIT_DEMO_CAPABILITY";
export const CLEAR_CAPABILITY_DATA = "CLEAR_CAPABILITY_DATA";


export const ADD_DEMO_TAB_DETAILS = "ADD_DEMO_TAB_DETAILS";
export const UPDATE_DEMO_BASIC_DETAILS = "UPDATE_DEMO_BASIC_DETAILS";
export const UPDATE_DEMO_ROLE_TEMPLATES = "UPDATE_DEMO_ROLE_TEMPLATES";
export const TOGGLE_ALL_DEMO_ROLE_TEMPLATES = "TOGGLE_ALL_DEMO_ROLE_TEMPLATES";

export const FETCH_RESOURCES = "FETCH_RESOURCES";
export const FETCH_RESOURCES_RT = "FETCH_RESOURCES_RT"
export const FETCH_ALL_RESOURCES = "FETCH_ALL_RESOURCES";
export const TOGGLE_IS_FETCHING_RESOURCES = "TOGGLE_IS_FETCHING_RESOURCES";
export const TOGGLE_IS_FETCHING_RESOURCES_RT = "TOGGLE_IS_FETCHING_RESOURCES_RT";
export const CLEAR_RESOURCES = "CLEAR_RESOURCES";
export const FETCH_RESOURCE_DETAILS = "FETCH_RESOURCE_DETAILS";
export const CLEAR_RESOURCE_FORM = "CLEAR_RESOURCE_FORM";
export const UPDATE_RESOURCE_ROLE_TEMPLATE = "UPDATE_RESOURCE_ROLE_TEMPLATE";
export const ADD_RESOURCE_DEMO = "ADD_RESOURCE_DEMO";
export const DELETE_RESOURCE_DEMO = "DELETE_RESOURCE_DEMO"
export const REFRESH_RESOURCES = "REFRESH_RESOURCES"
export const TOGGLE_HAS_RESOURCES_UPDATE = "TOGGLE_HAS_RESOURCES_UPDATE"
export const UPDATE_RESOURCE_FORM_TAB_DATA = "UPDATE_RESOURCE_FORM_TAB_DATA"
export const RESET_CREATE_RESOURCE = "RESET_CREATE_RESOURCE";

export const TOGGLE_MAINTENANCE_AVAILABILITY = "TOGGLE_MAINTENANCE_AVAILABILITY";
export const CHECK_MAINTENANCE_AVAILABILITY = "CHECK_MAINTENANCE_AVAILABILITY";
export const SET_MAINTENANCE_RESOURCE_ID = "SET_MAINTENANCE_RESOURCE_ID";
export const CLEAR_MAINTENANCE_FORM = "CLEAR_MAINTENANCE_FORM";
export const EDIT_MAINTENANCE_SCHEDULE = "EDIT_MAINTENANCE_SCHEDULE";
export const TOGGLE_MAINTENANCE_FORM_MODAL = "TOGGLE_MAINTENANCE_FORM_MODAL";
export const UPDATE_MAINTENANCE_CURRENT_PAGE = "UPDATE_MAINTENANCE_CURRENT_PAGE";
export const FETCH_MAINTENANCE_SCHEDULE = "FETCH_MAINTENANCE_SCHEDULE";
export const TOGGLE_IS_FETCHING_MAINTENANCE_SCHEDULE = "TOGGLE_IS_FETCHING_MAINTENANCE_SCHEDULE";
export const DELETE_MAINTENANCE_SCHEDULE = "DELETE_MAINTENANCE_SCHEDULE";
export const CLEAR_MAINTENANCE_FORM_ERRORS = "CLEAR_MAINTENANCE_FORM_ERRORS";
export const CLEAR_MAINTENANCE_SCHEDULES = "CLEAR_MAINTENANCE_SCHEDULES";
export const UPDATED_MAINTENANCE_SCHEDULE_AFTER_EDIT = "UPDATED_MAINTENANCE_SCHEDULE_AFTER_EDIT";
export const CLEAR_UPDATED_MAINTENANCE_DETAILS = "CLEAR_UPDATED_MAINTENANCE_DETAILS";
export const TOGGLE_IS_IP_ADDRESS_UPDATED = "TOGGLE_IS_IP_ADDRESS_UPDATED";
export const FETCH_SHARED_DEMO_DETAILS = "FETCH_SHARED_DEMO_DETAILS";
export const CLEAR_SHARED_DEMOS = "CLEAR_SHARED_DEMOS";
export const UPDATE_SHARED_DEMO_LIST = "UPDATE_SHARED_DEMO_LIST"
export const SET_MAINTENANCE_ERROR_TYPE = "SET_MAINTENANCE_ERROR_TYPE";
export const CHANGE_MAINTENANCE_TYPE = "CHANGE_MAINTENANCE_TYPE"

export const FETCH_ALL_NEWS = "FETCH_ALL_NEWS";
export const ADD_NEWS = "ADD_NEWS";
export const CLEAR_NEWS_FORM = "CLEAR_NEWS_FORM";
export const DELETE_NEWS = "DELETE_NEWS";
export const UPDATE_NEWS_DETAILS = "UPDATE_NEWS_DETAILS";
export const UPDATE_USER_NEWS_DETAILS = "UPDATE_USER_NEWS_DETAILS";
export const UPDATE_NEWS_INTRO = "UPDATE_NEWS_INTRO";
export const UPDATE_NEWS_BODY = "UPDATE_NEWS_BODY";
export const CLEAR_NEWS_DETAILS = "CLEAR_NEWS_DETAILS";
export const UPDATE_NEWS_ROLE_TEMPLATE = "UPDATE_NEWS_ROLE_TEMPLATE";
export const UPDATE_NEWS_CURRENT_PAGE = "UPDATE_NEWS_CURRENT_PAGE";
export const CLEAR_NEWS_INTRO = "CLEAR_NEWS_INTRO";
export const CLEAR_NEWS_BODY = "CLEAR_NEWS_BODY";
export const TOGGLE_IS_FETCHING_NEWS = "TOGGLE_IS_FETCHING_NEWS";
export const FETCH_USER_NEWS = "FETCH_USER_NEWS";
export const FETCH_USER_INACTIVE_NEWS = "FETCH_USER_INACTIVE_NEWS";
export const CLEAR_NEWS = "CLEAR_NEWS";
export const CLEAR_INACTIVE_NEWS = "CLEAR_INACTIVE_NEWS";
export const REORDER_NEWS = "REORDER_NEWS";
export const TOGGLE_IS_NEWS_REORDERED = "TOGGLE_IS_NEWS_REORDERED";
export const FETCH_ACTIVE_NEWS = "FETCH_ACTIVE_NEWS";
export const FETCH_ALL_USER_NEWS = "FETCH_ALL_USER_NEWS";
export const FETCH_INACTIVE_NEWS = "FETCH_INACTIVE_NEWS";
export const UPDATE_NEWS_FORM_DETAILS_TAB_DATA = "UPDATE_NEWS_FORM_DETAILS_TAB_DATA";
export const UPDATE_INTRO_TAB_DATA = "UPDATE_INTRO_TAB_DATA";
export const UPDATE_BODY_TAB_DATA = "UPDATE_BODY_TAB_DATA";
export const CLEAR_NEWS_FORM_DATA = "CLEAR_NEWS_FORM_DATA"
export const CLEAR_NEWS_DATA = "CLEAR_NEWS_DATA";
export const UPDATE_NEWS_MEDIA_PATH = "UPDATE_NEWS_MEDIA_PATH"
export const UPDATE_NEWS_COVER_IMAGE_PATH = "UPDATE_NEWS_COVER_IMAGE_PATH"
export const DELETE_NEWS_MEDIA = "DELETE_NEWS_MEDIA";
export const DELETE_COVER_IMAGE_MEDIA = "DELETE_COVER_IMAGE_MEDIA"
export const FETCH_ROLE_TEMPLATES = "FETCH_ROLE_TEMPLATES";
export const FETCH_ROLE_TEMPLATE = "FETCH_ROLE_TEMPLATE";
export const FETCH_DOMAINS = "FETCH_DOMAINS";
export const UPDATE_DOMAINS = "UPDATE_DOMAINS";
export const DELETE_ROLE_TEMPLATE = "DELETE_ROLE_TEMPLATE";
export const UPDATE_ROLE_TEMPLATE_DEMOS = "UPDATE_ROLE_TEMPLATE_DEMOS";
export const CLEAR_ROLE_TEMPLATE = "CLEAR_ROLE_TEMPLATE";
export const TOGGLE_ROLE_TEMPLATES_DEMOS_ALL = "TOGGLE_ROLE_TEMPLATES_DEMOS_ALL";
export const TOGGLE_ROLE_TEMPLATES_ENVIRONMENT_ALL = "TOGGLE_ROLE_TEMPLATES_ENVIRONMENT_ALL";
export const UPDATE_ROLE_TEMPLATE_ENVIRONMENT = "UPDATE_ROLE_TEMPLATE_ENVIRONMENT";
export const UPDATE_ROLE_TEMPLATE_NEWS = "UPDATE_ROLE_TEMPLATE_NEWS";
export const UPDATE_ROLE_TEMPLATE_REPORTS = "UPDATE_ROLE_TEMPLATE_REPORTS";
export const UPDATE_ROLE_TEMPLATE_TIPS = "UPDATE_ROLE_TEMPLATE_TIPS";
export const UPDATE_ROLE_TEMPLATE_NOTIFICATIONS = "UPDATE_ROLE_TEMPLATE_NOTIFICATIONS";
export const TOGGLE_ROLE_TEMPLATES_ALL_NEWS_ACCESS = "TOGGLE_ROLE_TEMPLATES_ALL_NEWS_ACCESS";
export const TOGGLE_ROLE_TEMPLATES_NOTIFICATIONS_ACCESS = "TOGGLE_ROLE_TEMPLATES_NOTIFICATIONS_ACCESS";
export const TOGGLE_IS_FETCHING_ROLE_TEMPLATES = "TOGGLE_IS_FETCHING_ROLE_TEMPLATES";
export const CLEAR_ROLE_TEMPLATES = "CLEAR_ROLE_TEMPLATES";
export const UPDATE_ROLE_TEMPLATE_TYPE = "UPDATE_ROLE_TEMPLATE_TYPE";
export const UPDATE_NETWORK_ADMIN = "UPDATE_NETWORK_ADMIN";
export const UPDATE_ROLE_TEMPLATE_RESOURCES = "UPDATE_ROLE_TEMPLATE_RESOURCES";
export const UPDATE_ROLE_TEMPLATE_SOLUTIONS = "UPDATE_ROLE_TEMPLATE_SOLUTIONS";
export const UPDATE_ROLE_TEMPLATE_SUPPORT = "UPDATE_ROLE_TEMPLATE_SUPPORT";
export const UPDATE_ROLE_TEMPLATE_NETWORKS = "UPDATE_ROLE_TEMPLATE_NETWORKS";
export const UPDATE_ROLE_TEMPLATE_SOLUTION_TYPES = "UPDATE_ROLE_TEMPLATE_SOLUTION_TYPES";
export const UPDATE_ADMIN_ROLE_TEMPLATES = "UPDATE_ADMIN_ROLE_TEMPLATES";
export const UPDATE_ROLE_TEMPLATE_CAPABILITY = "UPDATE_ROLE_TEMPLATE_CAPABILITY"
export const SAVE_ROLE_TEMPLATES = "SAVE_ROLE_TEMPLATES";
export const CANCEL_ROLE_TEMPLATES = "CANCEL_ROLE_TEMPLATES";
export const FETCH_DRAFT_NEWS = "FETCH_DRAFT_NEWS";

export const FETCH_SOLUTION_TYPES = "FETCH_SOLUTION_TYPES";
export const FETCH_SOLUTIONS = "FETCH_SOLUTIONS";
export const TOGGLE_IS_FETCHING_SOLUTIONS = "TOGGLE_IS_FETCHING_SOLUTIONS";
export const UPDATE_SELECTED_SOLUTION_TYPE = "UPDATE_SELECTED_SOLUTION_TYPE";
export const REORDER_SOLUTIONS = "REORDER_SOLUTIONS";
export const TOGGLE_IS_SOLUTIONS_REORDERED = "TOGGLE_IS_SOLUTIONS_REORDERED";
export const UPDATE_SELECTED_SOLUTION = "UPDATE_SELECTED_SOLUTION";
export const DELETE_SOLUTION = "DELETE_SOLUTION";
export const REORDER_SOLUTION_DEMOS = "REORDER_SOLUTION_DEMOS";
export const DELETE_SOLUTION_DEMO = "DELETE_SOLUTION_DEMO";
export const ADD_SOLUTION_DEMO = "ADD_SOLUTION_DEMO";
export const CLEAR_SOLUTION_FORM = "CLEAR_SOLUTION_FORM";
export const UPDATE_SOLUTION_DEMO_SEARCH_STRING = "UPDATE_SOLUTION_DEMO_SEARCH_STRING";
export const CLEAR_SOLUTIONS = "CLEAR_SOLUTIONS";
export const UPDATE_ID_STRING_DEMO = "UPDATE_ID_STRING_DEMO"
export const GET_SOLUTION_TYPE_DATA = "GET_SOLUTION_TYPE_DATA"

export const REFRESH_SOLUTIONS = "REFRESH_SOLUTIONS";
export const TOGGLE_HAS_SOLUTIONS_UPDATED = "TOGGLE_HAS_SOLUTIONS_UPDATED";

export const REFRESH_SOLUTION_TYPES = "REFRESH_SOLUTION_TYPES";
export const TOGGLE_HAS_SOLUTION_TYPES_UPDATED = "TOGGLE_HAS_SOLUTION_TYPES_UPDATED";



export const UPDATE_SOLUTION_ROLE_TEMPLATE = "UPDATE_SOLUTION_ROLE_TEMPLATE";
export const UPDATE_DEMO_ACCESS_INFORMATION = "UPDATE_DEMO_ACCESS_INFORMATION";

export const TOGGLE_IS_FETCHING_SOLUTION_TYPES = "TOGGLE_IS_FETCHING_SOLUTION_TYPES";
export const REORDER_SOLUTION_TYPES = "REORDER_SOLUTION_TYPES";
export const TOGGLE_IS_SOLUTION_TYPES_REORDERED = "TOGGLE_IS_SOLUTION_TYPES_REORDERED";
export const DELETE_SOLUTION_TYPE = "DELETE_SOLUTION_TYPE";
export const UPDATE_SOLUTION_TYPE_ROLE_TEMPLATE = "UPDATE_SOLUTION_TYPE_ROLE_TEMPLATE";
export const GET_SELECTED_SOLUTION_TYPE = "GET_SELECTED_SOLUTION_TYPE";
export const CLEAR_SOLUTION_TYPE_FORM = "CLEAR_SOLUTION_TYPE_FORM";

export const FETCH_DEMO_SCHEDULES = "FETCH_DEMO_SCHEDULES";
export const CLEAR_DEMO_SCHEDULES_TABLE = "CLEAR_DEMO_SCHEDULES_TABLE";
export const FETCH_DEMO_SCHEDULE = "FETCH_DEMO_SCHEDULE";
export const FETCH_MANAGE_SCHEDULE_DETAILS = "FETCH_MANAGE_SCHEDULE_DETAILS";
export const LAST_SCHEDULE_FILTER = "LAST_SCHEDULE_FILTER";
export const UPDATE_SCHEDULED_DEMO_CURRENT_PAGE = "UPDATE_SCHEDULED_DEMO_CURRENT_PAGE";
export const CLEAR_SCHEDULES_RESPONSE = "CLEAR_SCHEDULES_RESPONSE"
export const TOGGLE_IS_FETCHING_DEMO_SCHEDULES = "TOGGLE_IS_FETCHING_DEMO_SCHEDULES";
export const TOGGLE_IS_FETCHING_SCHEDULE = "TOGGLE_IS_FETCHING_SCHEDULE";
export const TOGGLE_IS_FETCHING_DEMO_REVIEWS = "TOGGLE_IS_FETCHING_DEMO_REVIEWS";
export const CLEAR_DEMO_SCHEDULES = "CLEAR_DEMO_SCHEDULES";
export const FETCH_DEMO_SCHEDULE_COMMENTS = "FETCH_DEMO_SCHEDULE_COMMENTS"
export const FETCH_TEAR_DOWN_SCHEDULES = "FETCH_TEAR_DOWN_SCHEDULES";
export const ADD_DEMO_SCHEDULE_COMMENT = "ADD_DEMO_SCHEDULE_COMMENT";
export const FETCH_SCHEDULE_FEEDBACK = "FETCH_SCHEDULE_FEEDBACK";
export const TOGGLE_IS_FETCHING_SCHEDULE_FEEDBACK = "TOGGLE_IS_FETCHING_SCHEDULE_FEEDBACK";
export const FETCH_DEMO_RATING_SPLIT_UP = "FETCH_DEMO_RATING_SPLIT_UP";
export const FETCH_SCHEDULE_FEEDBACK_ASSIGNED_QUESTIONS = "FETCH_SCHEDULE_FEEDBACK_ASSIGNED_QUESTIONS";

export const SELECT_DEMO_SOLUTION = "SELECT_DEMO_SOLUTION";
export const FETCH_SOLUTION_DEMOS = "FETCH_SOLUTION_DEMOS";
export const CLEAR_SOLUTION_DEMOS = "CLEAR_SOLUTION_DEMOS";
export const REFRESH_DEMO_RATINGS = "REFRESH_DEMO_RATINGS";
export const TOGGLE_DEMO_REQUEST_DELETE = "TOGGLE_DEMO_REQUEST_DELETE"

export const UPDATE_NOTIFICATION_DETAILS = "UPDATE_NOTIFICATION_DETAILS";
export const UPDATE_NOTIFICATION_DESCRIPTION = "UPDATE_NOTIFICATION_DESCRIPTION";
export const CLEAR_NOTIFICATION_DETAILS = "CLEAR_NOTIFICATION_DETAILS";
export const CLEAR_NOTIFICATION_DESCRIPTION = "CLEAR_NOTIFICATION_DESCRIPTION";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";
export const TOGGLE_IS_FETCHING_NOTIFICATIONS = "TOGGLE_IS_FETCHING_NOTIFICATIONS";
export const UPDATE_PUSH_NOTIFICATION = "UPDATE_PUSH_NOTIFICATION";
export const UPDATE_NOTIFICATION_ROLE_TEMPLATE = "UPDATE_NOTIFICATION_ROLE_TEMPLATE";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS_CURRENT_PAGE = "UPDATE_NOTIFICATIONS_CURRENT_PAGE";
export const FETCH_ACTIVE_NOTIFICATIONS = "FETCH_ACTIVE_NOTIFICATIONS";
export const REORDER_NOTIFICATIONS = "REORDER_NOTIFICATIONS";
export const TOGGLE_IS_NOTIFICATIONS_REORDERED = "TOGGLE_IS_NOTIFICATIONS_REORDERED";
export const FETCH_INACTIVE_NOTIFICATIONS = "FETCH_INACTIVE_NOTIFICATIONS";
export const FETCH_DRAFT_NOTIFICATIONS = "FETCH_DRAFT_NOTIFICATIONS";

export const REFRESH_NOTIFICATIONS = "REFRESH_NOTIFICATIONS";
export const TOGGLE_HAS_NOTIFICATIONS_UPDATED = "TOGGLE_HAS_NOTIFICATIONS_UPDATED";

export const ADD_SCHEDULER_EVENTS = "ADD_SCHEDULER_EVENTS";
export const FETCH_ACTIVE_DEMO_RESOURCES = "FETCH_ACTIVE_DEMO_RESOURCES";
export const UPDATE_SELECTED_RESOURCE_TYPE = "UPDATE_SELECTED_RESOURCE_TYPE";
export const UPDATE_SCHEDULER_TIMEZONE = "UPDATE_SCHEDULER_TIMEZONE";
export const UPDATE_SCHEDULER_START_END_TIME = "UPDATE_SCHEDULER_START_END_TIME";
export const UPDATE_SCHEDULER_NEW_EVENT = "UPDATE_SCHEDULER_NEW_EVENT";
export const UPDATE_NEW_EVENTS = "UPDATE_NEW_EVENTS"
export const CLEAR_NEW_EVENTS = "CLEAR_NEW_EVENTS"
export const CLEAR_ALL_NEW_EVENTS = "CLEAR_ALL_NEW_EVENTS"
export const ADD_NEW_SCHEDULE_EVENT = "ADD_NEW_SCHEDULE_EVENT";
export const DELETE_NEW_SCHEDULE_EVENT = "DELETE_NEW_SCHEDULE_EVENT";
export const DATE_CHANGED_SCHEDULER = "DATE_CHANGED_SCHEDULER"
export const TOGGLE_SCHEDULER_MODAL_IS_VISIBLE = "TOGGLE_SCHEDULER_MODAL_IS_VISIBLE";
export const FETCH_DEMO_PURPOSE_TYPES = "FETCH_DEMO_PURPOSE_TYPES";
export const CLEAR_DEMO_SCHEDULER = "CLEAR_DEMO_SCHEDULER";
export const TOGGLE_IS_FETCHING_SCHEDULER_DATA = "TOGGLE_IS_FETCHING_SCHEDULER_DATA";
export const ADD_DEMO_SCHEDULES = "ADD_DEMO_SCHEDULES";
export const UPDATE_CURRENT_DEMO_SCHEDULE = "UPDATE_CURRENT_DEMO_SCHEDULE";
export const CHECK_SCHEDULE_AVAILABILITY = "CHECK_SCHEDULE_AVAILABILITY";
export const CLEAR_SCHEDULE_FORM_ERRORS = "CLEAR_SCHEDULE_FORM_ERRORS";
export const REFRESH_SCHEDULER = "REFRESH_SCHEDULER";
export const FETCH_AVAILABLE_DEMO_RESOURCES = "FETCH_AVAILABLE_DEMO_RESOURCES";
export const UPDATE_SELECTED_RESOURCE = "UPDATE_SELECTED_RESOURCE";
export const CLEAR_SELECTED_RESOURCE = "CLEAR_SELECTED_RESOURCE";
export const UPDATE_OVERRIDING_RESOURCE = "UPDATE_OVERRIDING_RESOURCE";
export const FETCH_CUSTOMER_INFO = "FETCH_CUSTOMER_INFO";
export const FETCH_SUPPORT_GUIDES = "FETCH_SUPPORT";
export const FETCH_ALL_SUPPORT_GUIDES = "FETCH_ALL_SUPPORT";
export const TOGGLE_IS_FETCHING_SUPPORT_GUIDES = "TOGGLE_IS_FETCHING_SUPPORT";
export const REORDER_SUPPORT_GUIDES = "REORDER_SUPPORT";
export const TOGGLE_IS_SUPPORT_GUIDES_REORDERED = "TOGGLE_IS_SUPPORT_REORDERED";
export const DELETE_SUPPORT_GUIDE = "DELETE_SUPPORT";
export const CLEAR_SUPPORT = "CLEAR_SUPPORT";
export const UPDATE_SUPPORT_ROLE_TEMPLATE = "UPDATE_SUPPORT_ROLE_TEMPLATE";
export const FETCH_SUPPORT_GUIDE = "FETCH_SUPPORT_GUIDE";
export const UPDATE_CURRENT_GUIDE = "UPDATE_CURRENT_GUIDE";
export const FETCH_USER_SALES_VIDEOS = "FETCH_USER_SALES_VIDEOS";
export const TOGGLE_IS_FETCH_SALES_VIDEOS = 'TOGGLE_IS_FETCH_SALES_VIDEOS';
export const CLEAR_SUPPORT_FORM = "CLEAR_SUPPORT_FORM";

export const FETCH_ALL_USER_SUPPORT_GUIDES = "FETCH_ALL_USER_SUPPORT_GUIDES";
export const TOGGLE_IS_FETCHING_USER_SUPPORT = "TOGGLE_IS_FETCHING_USER_SUPPORT";
export const CLEAR_USER_SUPPORT_GUIDES = "CLEAR_USER_SUPPORT_GUIDES"

export const UPDATE_VIEW_PORT = "UPDATE_VIEW_PORT";

export const UPDATE_DASHBOARD_SCHEDULES_VIEW = "UPDATE_DASHBOARD_SCHEDULES_VIEW";
export const TOGGLE_IS_FETCHING_DASHBOARD_SCHEDULES = "TOGGLE_IS_FETCHING_DASHBOARD_SCHEDULES";
export const FETCH_USER_SCHEDULES = "FETCH_USER_SCHEDULES";
export const FETCH_USER_PREVIOUS_SCHEDULES = "FETCH_USER_PREVIOUS_SCHEDULES";
export const FETCH_USER_PREVIOUS_SCHEDULES_CARD = "FETCH_USER_PREVIOUS_SCHEDULES_CARD";
export const TOGGLE_IS_FETCHING_USER_SCHEDULES = "TOGGLE_IS_FETCHING_USER_SCHEDULES";
export const TOGGLE_IS_ACTIVE_SCHEDULES = "TOGGLE_IS_ACTIVE_SCHEDULES";
export const TOGGLE_IS_DASHBOARD_LOADING = "TOGGLE_IS_DASHBOARD_LOADING";
export const CLEAR_PREVIOUS_SCHEDULES = "CLEAR_PREVIOUS_SCHEDULES";
export const TOGGLE_IS_RESCHEDULE = "TOGGLE_IS_RESCHEDULE";
export const TOGGLE_IS_RESOURCE_RESCHEDULE = "TOGGLE_IS_RESOURCE_RESCHEDULE";
export const TOGGLE_IS_FETCHING_PREVIOUS_SCHEDULES = "TOGGLE_IS_FETCHING_PREVIOUS_SCHEDULES";
export const UPDATE_SCHEDULES_CURRENT_PAGE = "UPDATE_SCHEDULES_CURRENT_PAGE";
export const CLEAR_DASHBOARD_SCHEDULES = "CLEAR_DASHBOARD_SCHEDULES";
export const FETCH_ACTIVE_USER_SCHEDULES_CARD = "FETCH_ACTIVE_USER_SCHEDULES_CARD";
export const FETCH_PREVIOUS_USER_SCHEDULES_CARD = "FETCH_PREVIOUS_USER_SCHEDULES_CARD";
export const FETCH_USER_SCHEDULES_CALENDAR = "FETCH_USER_SCHEDULES_CALENDAR";
export const FETCH_USER_DEMO_SCHEDULES_TABLE = "FETCH_USER_DEMO_SCHEDULES_TABLE";
export const FETCH_USER_MAINTENANCE_SCHEDULES_TABLE = "FETCH_USER_MAINTENANCE_SCHEDULES_TABLE";
export const FETCH_SCHEDULES_PRINT_EXPORT = "FETCH_SCHEDULES_PRINT_EXPORT";
export const UPDATE_USERS_SCHEDULES_PARAMS = "UPDATE_USERS_SCHEDULES_PARAMS";
export const TOGGLE_IS_TABLE_CLOSE = "TOGGLE_IS_TABLE_CLOSE";
export const GET_ALL_ACTIVE_REQUESTS = "GET_ALL_ACTIVE_REQUESTS";
export const GET_ALL_INACTIVE_REQUESTS = "GET_ALL_INACTIVE_REQUESTS";

export const FETCH_ALL_USERS = "FETCH_ALL_USERS";
export const TOGGLE_IS_FETCHING_USERS = "TOGGLE_IS_FETCHING_USERS";
export const UPDATE_USER_EDITING_KEY = "UPDATE_USER_EDITING_KEY";
export const UPDATE_USER = "UPDATE_USER";
export const CLEAR_USERS = "CLEAR_USERS";
export const UPDATE_USERS_CURRENT_PAGE = "UPDATE_USERS_CURRENT_PAGE";

export const FETCH_DATA_CENTERS = "FETCH_DATA_CENTERS";
export const ADD_DATA_CENTER = "ADD_DATA_CENTER";
export const UPDATE_DATA_CENTER = "UPDATE_DATA_CENTER";
export const REMOVE_EMPTY_DATA_CENTER = "REMOVE_EMPTY_DATA_CENTER";
export const TOGGLE_IS_FETCHING_DATA_CENTERS = "TOGGLE_IS_FETCHING_DATA_CENTERS";
export const UPDATE_DATA_CENTER_EDITING_KEY = "UPDATE_DATA_CENTER_EDITING_KEY";
export const CLEAR_DATA_CENTERS = "CLEAR_DATA_CENTERS";
export const TOGGLE_SHOULD_UPDATE_FILTERED_DATA_FLAG = "TOGGLE_SHOULD_UPDATE_FILTERED_DATA_FLAG";
export const REFRESH_DATA_CENTERS = "REFRESH_DATA_CENTERS";
export const TOGGLE_HAS_DATA_CENTERS_UPDATED = "TOGGLE_HAS_DATA_CENTERS_UPDATED";
export const LAST_DATA_CENTERS_FILTER = "LAST_DATA_CENTERS_FILTER";

export const FETCH_DIDS = "FETCH_DIDS";
export const UPDATE_SELECTED_DIDS_DATACENTER = "UPDATE_SELECTED_DIDS_DATACENTER";
export const UPDATE_DIDS_CURRENT_PAGE = "UPDATE_DIDS_CURRENT_PAGE";
export const TOGGLE_IS_FETCHING_DIDS = "TOGGLE_IS_FETCHING_DIDS";
export const UPDATE_DID_EDITING_KEY = "UPDATE_DID_EDITING_KEY";
export const CLEAR_DIDS = "CLEAR_DIDS";
export const CLEAR_DIDS_TABLE_DATA = "CLEAR_DIDS_TABLE_DATA";
export const UPDATE_SELECTED_DID_FILTER = "UPDATE_SELECTED_DID_FILTER";
export const FETCH_NETWORK_ENVIRONMENTS = "FETCH_NETWORK_ENVIRONMENTS";
export const UPDATE_DID_UPLOAD_CRITERIA = "UPDATE_DID_UPLOAD_CRITERIA";
export const UPDATE_DIDS_SELECTED_ROW_KEYS = "UPDATE_DIDS_SELECTED_ROW_KEYS";
export const TOGGLE_IS_UPLOADING_DOWNLOADING_FLAG = "TOGGLE_IS_UPLOADING_DOWNLOADING_FLAG";
export const TOGGLE_IS_COLLAPSED_STATUS = "TOGGLE_IS_COLLAPSED_STATUS"
export const CURRENT_DIDS_FILTER = "CURRENT_DIDS_FILTER"



export const FETCH_PROVIDERS = "FETCH_PROVIDERS";
export const UPDATE_PROVIDER_EDITING_KEY = "UPDATE_PROVIDER_EDITING_KEY";
export const ADD_PROVIDER = "ADD_PROVIDER";
export const REMOVE_EMPTY_PROVIDER = "REMOVE_EMPTY_PROVIDER";

export const FETCH_NETWORKS = "FETCH_NETWORKS";
export const FETCH_DELETED_NETWORKS = "FETCH_DELETED_NETWORKS";
export const FETCH_DELETED_NETWORKS_BY_DC_ID = "FETCH_DELETED_NETWORKS_BY_DC_ID";
export const FETCH_IP_ADDRESSES = "FETCH_IP_ADDRESSES";
export const TOGGLE_IS_FETCHING_NETWORKS = "TOGGLE_IS_FETCHING_NETWORKS";
export const TOGGLE_IS_FETCHING_IP_ADDRESSES = "TOGGLE_IS_FETCHING_IP_ADDRESSES";
export const TOGGLE_IS_IP_ADDRESS_FORM_VISIBLE = "TOGGLE_IS_IP_ADDRESS_FORM_VISIBLE";
export const UPDATE_IP_ADDRESS_FORM = "UPDATE_IP_ADDRESS_FORM";
export const FETCH_NETWORK_DETAILS = "FETCH_NETWORK_DETAILS";
export const CLEAR_NETWORKS = "CLEAR_NETWORKS";
export const UPDATE_NETWORK_DETAILS = "UPDATE_NETWORK_DETAILS";
export const UPDATE_NETWORK_ROLE_TEMPLATE = "UPDATE_NETWORK_ROLE_TEMPLATE";
export const CLEAR_NETWORK_DATA = "CLEAR_NETWORK_DATA";
export const UPDATE_FILTERSORT = "UPDATE_FILTERSORT";
export const UPDATE_NETWORK_LOGIN_PASSWORD = "UPDATE_NETWORK_LOGIN_PASSWORD";
export const UPDATE_NETWORK_CURRENT_PAGE = "UPDATE_NETWORK_CURRENT_PAGE";
export const TOGGLE_IS_FETCHING_DELETED_NETWORK = "TOGGLE_IS_FETCHING_DELETED_NETWORK";
export const CURRENT_NETWORKS_FILTER = "CURRENT_NETWORKS_FILTER"
export const UPDATE_SELECTED_NETWORKS_DATACENTER = "UPDATE_SELECTED_NETWORKS_DATACENTER";
export const CREATE_NETWORK_DATA = "CREATE_NETWORK_DATA";

export const ADD_DID = "ADD_DID";
export const REMOVE_EMPTY_DIDS = "REMOVE_EMPTY_DIDS";
export const CLEAR_SOLUTION_TYPE = "CLEAR_SOLUTION_TYPE";
export const FETCH_ALL_CUSTOM_EMAIL_TEMPLATES = "FETCH_ALL_CUSTOM_EMAIL_TEMPLATES";
export const FETCH_ALL_STANDARD_EMAIL_TEMPLATES = "FETCH_ALL_STANDARD_EMAIL_TEMPLATES";
export const CLEAR_CUSTOM_EMAIL_TEMPLATES = "CLEAR_CUSTOM_EMAIL_TEMPLATES";
export const CLEAR_STANDARD_EMAIL_TEMPLATES = "CLEAR_STANDARD_EMAIL_TEMPLATES";
export const FETCH_ALL_EMAIL_CATEGORIES = "FETCH_ALL_EMAIL_CATEGORIES";
export const LAST_CUSTOM_EMAIL_TEMPLATE_FILTER = "LAST_CUSTOM_EMAIL_TEMPLATE_FILTER"
export const LAST_STANDARD_EMAIL_TEMPLATE_FILTER = "LAST_STANDARD_EMAIL_TEMPLATE_FILTER"
export const FETCH_DYNAMIC_EMAIL_TEMPLATE = "FETCH_DYNAMIC_EMAIL_TEMPLATE"
export const UPDATE_EMAIL_TEMPLATE_EDITOR_CONTENT = "UPDATE_EMAIL_TEMPLATE_EDITOR_CONTENT";
export const UPDATE_EDITOR_CONTENT_DYNAMIC_FLOW = "UPDATE_EDITOR_CONTENT_DYNAMIC_FLOW";
export const UPDATE_EMAIL_TEMPLATE_TAB_DETAILS = "UPDATE_EMAIL_TEMPLATE_TAB_DETAILS"
export const FETCH_EMAIL_TEMPLATE = "FETCH_EMAIL_TEMPLATE";
export const CLEAR_EMAIL_TEMPLATE_CONTENT = "CLEAR_EMAIL_TEMPLATE_CONTENT";
export const TOGGLE_IS_FETCHING_EMAIL_TEMPLATE = "TOGGLE_IS_FETCHING_EMAIL_TEMPLATE";
export const FETCH_PLACEHOLDERS = "FETCH_PLACEHOLDERS";
export const FETCH_REAL_TIME_PUSH_NOTIFICATION = "FETCH_REAL_TIME_PUSH_NOTIFICATION";
export const SAVE_REAL_TIME_PUSH_NOTIFICATION = "SAVE_REAL_TIME_PUSH_NOTIFICATION";
export const FETCH_USER_BELL_NOTIFICATIONS = "FETCH_USER_BELL_NOTIFICATIONS";
export const TOGGLE_IS_FETCHING_BELL_NOTIFICATIONS = "TOGGLE_IS_FETCHING_BELL_NOTIFICATIONS";
export const UPDATE_USER_NOTIFICATION_COUNT = "UPDATE_USER_NOTIFICATION_COUNT";
export const CLEAR_USER_BELL_NOTIFICATIONS = "CLEAR_USER_BELL_NOTIFICATIONS"
export const FETCH_USER_DASHBOARD_NOTIFICATIONS = "FETCH_USER_DASHBOARD_NOTIFICATIONS"
export const CLEAR_USER_DASHBOARD_NOTIFICATIONS = "CLEAR_USER_DASHBOARD_NOTIFICATIONS"
export const CLOSE_NOTIFICATION_BANNER = "CLOSE_NOTIFICATION_BANNER"

export const FETCH_ALL_TIPS = "FETCH_ALL_TIPS";
export const UPDATE_TIP_DETAILS = "UPDATE_TIP_DETAILS";
export const UPDATE_TIP_BODY = "UPDATE_TIP_BODY";
export const UPDATE_TIP_INTRO = "UPDATE_TIP_INTRO";
export const CLEAR_TIP_DETAILS = "CLEAR_TIP_DETAILS";
export const CLEAR_TIP_INTRO = "CLEAR_TIP_INTRO";
export const CLEAR_TIP_BODY = "CLEAR_TIP_BODY";
export const TOGGLE_IS_FETCHING_TIPS = "TOGGLE_IS_FETCHING_TIPS";
export const UPDATE_TIPS_CURRENT_PAGE = "UPDATE_TIPS_CURRENT_PAGE";
export const UPDATE_TIP_ROLE_TEMPLATE = "UPDATE_TIP_ROLE_TEMPLATE";
export const CLEAR_TIPS = "CLEAR_TIPS";
export const FETCH_USER_TIP = "FETCH_USER_TIP";
export const TOGGLE_IS_FETCHING_TIP = "TOGGLE_IS_FETCHING_TIP";
export const CLEAR_TIPS_TABLE_DATA = "CLEAR_TIPS_TABLE_DATA";

export const FETCH_TRENDING_DEMOS = "FETCH_TRENDING_DEMOS";
export const REFRESH_TRENDING_DEMOS = "REFRESH_TRENDING_DEMOS";
export const TOGGLE_IS_FETCHING_TRENDING_DEMOS = "TOGGLE_IS_FETCHING_TRENDING_DEMOS"
export const FETCH_MOST_SCHEDULED_DEMOS = "FETCH_MOST_SCHEDULED_DEMOS"
export const CLEAR_FETCH_MOST_SCHEDULED_DEMOS = "CLEAR_FETCH_MOST_SCHEDULED_DEMOS"

export const TOGGLE_IS_FETCHING_REPORTS = "TOGGLE_IS_FETCHING_REPORTS"
export const FETCH_USER_REPORTS = "FETCH_USER_REPORTS"
export const FETCH_USER_MY_REPORTS = "FETCH_USER_MY_REPORTS"
export const FETCH_REPORTS = "FETCH_REPORTS"
export const TOGGLE_IS_FETCHING_USER_REPORTS = "TOGGLE_IS_FETCHING_USER_REPORTS"
export const UPDATE_USER_REPORT_DETAILS = "UPDATE_USER_REPORT_DETAILS"
export const COLUMNS_OF_USER_REPORT = "COLUMNS_OF_USER_REPORT"
export const RUN_USER_REPORT = "RUN_USER_REPORT";
export const CLEAR_USER_REPORT = "CLEAR_USER_REPORT"
export const UPDATE_SELECTED_COLUMNS_FOR_REPORT = "UPDATE_SELECTED_COLUMNS_FOR_REPORT"
export const TOGGLE_IS_ENTITY_CHANGED = "TOGGLE_IS_ENTITY_CHANGED"
export const CLEAR_REPORTS_CONTENT = "CLEAR_REPORTS_CONTENT"
export const RUN_USER_GENERAL_REPORT = "RUN_USER_GENERAL_REPORT"
export const FETCH_GENERAL_REPORTS = "FETCH_GENERAL_REPORTS"
export const FETCH_USER_CREATED_REPORTS = "FETCH_USER_CREATED_REPORTS"
export const UPDATE_REPORT_DETAILS = "UPDATE_REPORT_DETAILS"
export const FETCH_ENTITY_TYPES = "FETCH_ENTITY_TYPES"
export const TOGGLE_IS_FETCHING_REPORT_FORM = "TOGGLE_IS_FETCHING_REPORT_FORM"
export const COLUMNS_OF_THE_REPORT = "COLUMNS_OF_THE_REPORT"
export const CLEAR_REPORT_FORM = "CLEAR_REPORT_FORM"
export const UPDATE_REPORTS_ROLE_TEMPLATE = "UPDATE_REPORTS_ROLE_TEMPLATE";
export const CLEAR_REPORT_VALUES = "CLEAR_REPORT_VALUES";
export const UPDATE_REPORTS_CURRENT_PAGE = "UPDATE_REPORTS_CURRENT_PAGE"
export const UPDATE_CUSTOM_REPORTS_CURRENT_PAGE = "UPDATE_CUSTOM_REPORTS_CURRENT_PAGE"
export const UPDATE_REPORT_ROLE_TEMPLATE = "UPDATE_REPORT_ROLE_TEMPLATE"
export const RUN_REPORT = "RUN_REPORT"
export const RUN_GENERAL_REPORT = "RUN_GENERAL_REPORT"
export const CLEAR_REPORT = "CLEAR_REPORT"
export const UPDATE_RUN_REPORT_CURRENT_PAGE = "UPDATE_RUN_REPORT_CURRENT_PAGE";
export const LAST_TIP_FILTER = "LAST_TIP_FILTER";
export const LAST_DEMO_FILTER = "LAST_DEMO_FILTER";
export const CLEAR_FETCHED_DEMOS = "CLEAR_FETCHED_DEMOS";
export const LAST_NOTIFICATION_FILTER = "LAST_NOTIFICATION_FILTER"
export const LAST_SUPPORT_FILTER = "LAST_SUPPORT_FILTER"
export const LAST_SOLUTIONS_FILTER = "LAST_SOLUTIONS_FILTER";
export const LAST_SOLUTIONS_TYPE_FILTER = "LAST_SOLUTIONS_TYPE_FILTER";
export const LAST_LOCAL_USERS_FILTER = "LAST_LOCAL_USERS_FILTER";
export const LAST_RESOURCES_FILTER = "LAST_RESOURCES_FILTER";
export const LAST_NEWS_FILTER = "LAST_NEWS_FILTER"
export const LAST_ROLE_TEMPLATES_FILTER = "LAST_ROLE_TEMPLATES_FILTER";
export const LAST_REPORT_FILTER = "LAST_REPORT_FILTER";
export const LAST_USER_REPORT_FILTER = "LAST_USER_REPORT_FILTER"
export const LAST_MAINTENANCE_FILTER = "LAST_MAINTENANCE_FILTER"
export const CLEAR_REPORT_ON_ENTITY_CHANGE = "CLEAR_REPORT_ON_ENTITY_CHANGE"
export const HAS_ACCESS_TO_EAP_OFFLINE = "HAS_ACCESS_TO_EAP_OFFLINE"
export const TOGGLE_IS_SAVE_REPORT_VISIBLE = "TOGGLE_IS_SAVE_REPORT_VISIBLE"
export const CLEAR_DEMO_RESPONSE = "CLEAR_DEMO_RESPONSE"
export const CLEAR_NEWS_RESPONSE = "CLEAR_NEWS_RESPONSE"
export const CLEAR_NOTIFICATIONS_RESPONSE = "CLEAR_NOTIFICATIONS_RESPONSE"
export const CLEAR_DIDS_RESPONSE = "CLEAR_DIDS_RESPONSE"
export const CLEAR_TIPS_RESPONSE = "CLEAR_TIPS_RESPONSE"
export const UPDATE_MANAGE_SCHEDULES_CURRENT_PAGE = "UPDATE_MANAGE_SCHEDULES_CURRENT_PAGE"
export const REFRESH_NEWS = "REFRESH_NEWS"
export const TOGGLE_HAS_NEWS_UPDATE = "TOGGLE_HAS_NEWS_UPDATE"
export const UPDATE_REPORT_TAB_DETAILS = "UPDATE_REPORT_TAB_DETAILS"


// DEMO APPROVALS TYPES
export const FETCH_DEMO_APPROVALS = "FETCH_DEMO_APPROVALS"
export const FETCH_DEMO_APPROVAL_REQUEST = "FETCH_DEMO_APPROVAL_REQUEST"
export const TOGGLE_IS_FETCHING_APPROVAL_REQUESTS = "TOGGLE_IS_FETCHING_APPROVAL_REQUESTS"
export const CLEAR_DEMO_APPROVALS = "CLEAR_DEMO_APPROVALS"
export const UPDATE_DEMO_APPROVAL_CURRENT_PAGE = "UPDATE_DEMO_APPROVAL_CURRENT_PAGE"
export const LAST_DEMO_APPROVAL_FILTER = "LAST_DEMO_APPROVAL_FILTER"
export const REFRESH_DEMO_APPROVALS = "REFRESH_DEMO_APPROVALS"
export const UPDATE_DEMO_REQUEST_EMAIL_PREVIEW_EDITOR_CONTENT = "UPDATE_DEMO_REQUEST_EMAIL_PREVIEW_EDITOR_CONTENT"
export const CLEAR_DEMO_REQUEST_EMAIL_PREVIEW = "CLEAR_DEMO_REQUEST_EMAIL_PREVIEW"

export const FETCH_ALL_PURPOSE = "FETCH_ALL_PURPOSE"
export const TOGGLE_IS_FETCHING_PURPOSE = "TOGGLE_IS_FETCHING_PURPOSE";
export const REORDER_DEMO_PURPOSE = "REORDER_DEMO_PURPOSE";
export const TOGGLE_IS_DEMO_PURPOSE_REORDERED = "TOGGLE_IS_DEMO_PURPOSE_REORDERED"
export const LAST_DEMO_PURPOSE_FILTER = "LAST_DEMO_PURPOSE_FILTER";
export const CLEAR_DEMO_PURPOSE = "CLEAR_DEMO_PURPOSE"
export const TOGGLE_IS_FETCHING_DEMO_PURPOSE_FORM = "TOGGLE_IS_FETCHING_DEMO_PURPOSE_FORM"
export const FETCH_DEMO_PURPOSE_BY_ID = "FETCH_DEMO_PURPOSE_BY_ID";
export const UPDATE_DEMO_PURPOSE_ROLE_TEMPLATE = "UPDATE_DEMO_PURPOSE_ROLE_TEMPLATE"
export const CLEAR_DEMO_PURPOSE_FORM = "CLEAR_DEMO_PURPOSE_FORM"
export const FETCH_USER_DEMO_PURPOSE_TYPES = "FETCH_USER_DEMO_PURPOSE_TYPES"
export const FETCH_DEMO_PURPOSE_TYPES_LITE = "FETCH_DEMO_PURPOSE_TYPES_LITE"
export const FETCH_DEMO_VERTICAL_SOLUTIONS_LITE = "FETCH_DEMO_VERTICAL_SOLUTIONS_LITE"
export const UPDATE_ROLE_TEMPLATE_DEMO_PURPOSE = "UPDATE_ROLE_TEMPLATE_DEMO_PURPOSE"
export const TOGGLE_HAS_DEMO_PURPOSE_UPDATED = "TOGGLE_HAS_DEMO_PURPOSE_UPDATED"
export const REFRESH_DEMO_PURPOSE = "REFRESH_DEMO_PURPOSE"


export const UPDATE_DATA_CENTER_STATUS_EDITING_KEY = "UPDATE_DATA_CENTER_STATUS_EDITING_KEY"
export const FETCH_ALL_DATA_CENTER_STATUS = "FETCH_ALL_DATA_CENTER_STATUS"
export const TOGGLE_IS_FETCHING_DATA_CENTER_STATUS = "TOGGLE_IS_FETCHING_DATA_CENTER_STATUS";
export const TOGGLE_IS_FETCHING_USER_DATA_CENTER_STATUS = "TOGGLE_IS_FETCHING_USER_DATA_CENTER_STATUS"
export const CLEAR_DEMO_DC_RESPONSE = "CLEAR_DEMO_DC_RESPONSE";
export const FETCH_USER_DATA_CENTER_STATUS = "FETCH_USER_DATA_CENTER_STATUS";
export const CLEAR_DEMO_DC_STATUS = "CLEAR_DEMO_DC_STATUS";
export const LAST_DEMO_STATUS_FILTER = "LAST_DEMO_STATUS_FILTER";
export const CLEAR_DEMO_DC_STATUS_TABLE_DATA = "CLEAR_DEMO_DC_STATUS_TABLE_DATA";

//Feedback Question Types
export const FETCH_DEMO_FEEDBACK_QUESTIONS = "FETCH_DEMO_FEEDBACK_QUESTIONS"
export const TOGGLE_IS_FETCHING_FEEDBACK_QUESTIONS = "TOGGLE_IS_FETCHING_FEEDBACK_QUESTIONS"
export const LAST_FEEDBACK_QUESTIONS_FILTER = "LAST_FEEDBACK_QUESTIONS_FILTER"
export const CLEAR_FEEDBACK_QUESTIONS = "CLEAR_FEEDBACK_QUESTIONS"
export const UPDATE_FEEDBACK_QUESTIONS_ROLE_TEMPLATE = "UPDATE_FEEDBACK_QUESTIONS_ROLE_TEMPLATE"
export const GET_SELECTED_FEEDBACK_QUESTION = "GET_SELECTED_FEEDBACK_QUESTION"
export const CLEAR_FEEDBACK_QUESTIONS_FORM = "CLEAR_FEEDBACK_QUESTIONS_FORM"
export const FETCH_FEEDBACK_QUESTION_DETAILS = "FETCH_FEEDBACK_QUESTION_DETAILS"
export const UPDATE_ROLE_TEMPLATE_DEMO_FEEDBACK_QUESTIONS = "UPDATE_ROLE_TEMPLATE_DEMO_FEEDBACK_QUESTIONS"
export const TOGGLE_HAS_FEEDBACK_QUESTIONS_UPDATED = "TOGGLE_HAS_FEEDBACK_QUESTIONS_UPDATED"
export const REFRESH_FEEDBACK_QUESTIONS = "REFRESH_FEEDBACK_QUESTIONS"
export const UPDATE_FEEDBACK_QUESTIONS_FORM_TAB_DATA = "UPDATE_FEEDBACK_QUESTIONS_FORM_TAB_DATA"

//Demo Question Types
export const FETCH_DEMO_QUESTIONS = "FETCH_DEMO_QUESTIONS"
export const TOGGLE_IS_FETCHING_DEMO_QUESTIONS = "TOGGLE_IS_FETCHING_DEMO_QUESTIONS"
export const LAST_DEMO_QUESTIONS_FILTER = "LAST_DEMO_QUESTIONS_FILTER"
export const UPDATE_DEMO_QUESTIONS_ROLE_TEMPLATE = "UPDATE_DEMO_QUESTIONS_ROLE_TEMPLATE"
export const GET_SELECTED_DEMO_QUESTION = "GET_SELECTED_DEMO_QUESTION"
export const CLEAR_DEMO_QUESTIONS = "CLEAR_DEMO_QUESTIONS"
export const CLEAR_DEMO_QUESTION_RESPONSE = "CLEAR_DEMO_QUESTION_RESPONSE"
export const CLEAR_DEMO_QUESTIONS_FORM = "CLEAR_DEMO_QUESTIONS_FORM"
export const FETCH_DEMO_QUESTION_DETAILS = "FETCH_DEMO_QUESTION_DETAILS"
export const UPDATE_ROLE_TEMPLATE_DEMO_QUESTIONS = "UPDATE_ROLE_TEMPLATE_DEMO_QUESTIONS";
export const UPDATE_ROLE_TEMPLATE_EZ_DEMOS = "UPDATE_ROLE_TEMPLATE_EZ_DEMOS";
export const ADD_NEW_ADDITIONAL_INFO_QUESTION = "ADD_NEW_ADDITIONAL_INFO_QUESTION"
export const ADD_NEW_DEMO_PRE_REG_QUESTION = "ADD_NEW_DEMO_PRE_REG_QUESTION"
export const ADD_DEMO_QUESTIONS = "ADD_DEMO_QUESTIONS"
export const DELETE_DEMO_QUESTION_ASSOCIATION = "DELETE_DEMO_QUESTION_ASSOCIATION"
export const UPDATE_DEMO_QUESTION_CURRENT_PAGE = "UPDATE_DEMO_QUESTION_CURRENT_PAGE"
export const LAST_DEMO_QUESTION_FILTER = "LAST_DEMO_QUESTION_FILTER"
export const TOGGLE_HAS_DEMO_QUESTIONS_UPDATED = "TOGGLE_HAS_DEMO_QUESTIONS_UPDATED"
export const UPDATE_DEMO_QUESTIONS_FORM_TAB_DATA = "CLEAR_DEMO_QUESTIONS_TABLE_DATA"
export const CLEAR_DEMO_QUESTIONS_TABLE_DATA = "CLEAR_DEMO_QUESTIONS_TABLE_DATA"
export const FETCH_ALL_DEMO_QUESTION = "FETCH_ALL_DEMO_QUESTION"


// Demo Request 

export const UPDATE_DEMO_REQUEST_EDITING_KEY = "UPDATE_DEMO_REQUEST_EDITING_KEY"
export const CLEAR_DEMO_REQUEST_DATA = "CLEAR_DEMO_REQUEST_DATA"
export const TOGGLE_IS_FETCHING_DEMO_REQUEST_USER_STATUS = "TOGGLE_IS_FETCHING_DEMO_REQUEST_USER_STATUS"
export const TOGGLE_IS_FETCHING_DEMO_REQUESTS = "TOGGLE_IS_FETCHING_DEMO_REQUESTS"
export const DELETE_DEMO_PRE_REG_QUESTION_ASSOCIATION = "DELETE_DEMO_PRE_REG_QUESTION_ASSOCIATION"
export const DELETE_DEMO_ADDITIONAL_INFO_QUESTION_ASSOCIATION = "DELETE_DEMO_ADDITIONAL_INFO_QUESTION_ASSOCIATION"
export const LAST_DEMO_REQUEST_FILTER = "LAST_DEMO_REQUEST_FILTER";
export const UPDATE_DEMOS_REQUEST_CURRENT_PAGE = "UPDATE_DEMOS_REQUEST_CURRENT_PAGE"
export const FETCH_DEMO_REQUEST = "FETCH_DEMO_REQUEST"
export const FETCH_ALL_DEMO_REQUESTS = "FETCH_ALL_DEMO_REQUESTS"
export const FETCH_USER_DEMO_REQUEST = "FETCH_USER_DEMO_REQUEST"


// Demo Build Email Template Flow
export const FETCH_DYNAMIC_EMAIL_TEMP_VERS = "FETCH_DYNAMIC_EMAIL_TEMP_VERS";
export const CLEAR_DYNAMIC_EMAIL_TEMP_VERS = "CLEAR_DYNAMIC_EMAIL_TEMP_VERS";
export const TOGGLE_IS_FETCHING_EMAIL_TEMPLATE_VERSIONS = "TOGGLE_IS_FETCHING_EMAIL_TEMPLATE_VERSIONS"
export const CLEAR_DYNAMIC_EMAIL_TEMPLATE = "CLEAR_DYNAMIC_EMAIL_TEMPLATE"
export const CLEAR_DYNAMIC_EMAIL_PREVIEW = "CLEAR_DYNAMIC_EMAIL_PREVIEW"
export const UPDATE_EMAIL_TEMPLATE_PREVIEW_CONTENT = "UPDATE_EMAIL_TEMPLATE_PREVIEW_CONTENT"
export const UPDATE_EMAIL_PREVIEW_EDITOR_CONTENT = "UPDATE_EMAIL_PREVIEW_EDITOR_CONTENT"
export const TOGGLE_IS_PREVIEW_SUCCESSFUL = "TOGGLE_IS_PREVIEW_SUCCESSFUL"
export const TOGGLE_CK_EDITOR_CONTENT_CHANGED_FLAG = "TOGGLE_CK_EDITOR_CONTENT_CHANGED_FLAG"


// Demo Custom Email Template Flow
export const FETCH_CUSTOM_EMAIL_TEMPLATE = "FETCH_CUSTOM_EMAIL_TEMPLATE"
export const UPDATE_EDITOR_CONTENT_CUSTOM_EMAIL_TEMPLATE = "UPDATE_EDITOR_CONTENT_CUSTOM_EMAIL_TEMPLATE"
export const TOGGLE_CUSTOM_EMAIL_CK_EDITOR_CONTENT_CHANGED_FLAG = "UPDATE_EDITOR_CONTENT_CUSTOM_EMAIL_TEMPLATE"
export const CLEAR_CUSTOM_EMAIL_TEMPLATE = "CLEAR_CUSTOM_EMAIL_TEMPLATE"


//Tab Layout
export const ADD_NEW_TAB = "ADD_NEW_TAB"
export const UPDATE_ACTIVE_TAB = "UPDATE_ACTIVE_TAB"
export const DELETE_TAB = "DELETE_TAB"
export const CLOSE_ALL_TABS = "CLOSE_ALL_TABS"
export const CLOSE_CURRENT_OPEN_TAB = "CLOSE_CURRENT_OPEN_TAB"
export const CLEAR_TAB_DATA = "CLEAR_TAB_DATA"
export const CLOSE_ACTIVE_TAB = "CLOSE_ACTIVE_TAB"
export const CLOSE_OTHER_TABS = "CLOSE_OTHER_TABS"
export const RESTORE_TABS_ON_REFRESH = "RESTORE_TABS_ON_REFRESH";
export const UPDATE_TABS_LOCAL_STORAGE = "UPDATE_TABS_LOCAL_STORAGE";
export const HAS_SAVE_CHANGES = "HAS_SAVE_CHANGES"
export const REPLACE_TAB_DETAILS = "REPLACE_TAB_DETAILS"
export const TOGGLE_HAS_UNSAVED_CHANGES_FLAG = "TOGGLE_HAS_UNSAVED_CHANGES_FLAG"
export const CLEAR_HAS_UNSAVED_FLAG = "CLEAR_HAS_UNSAVED_FLAG"



// Daily Report attributes

export const TOGGLE_IS_FETCHING_DAILY_ATTRIBUTES = "TOGGLE_IS_FETCHING_DAILY_ATTRIBUTES"
export const GET_ALL_DAILY_ATTRIBUTES = "GET_ALL_DAILY_ATTRIBUTES"
export const ADD_NEW_DEMO_ATTRIBUTE = "ADD_NEW_DEMO_ATTRIBUTE"
export const DELETE_DEMO_ATTRIBUTE = "DELETE_DEMO_ATTRIBUTE"
export const CLEAR_ATTRIBUTE_FORM = "CLEAR_ATTRIBUTE_FORM"
export const GET_EACH_DAILY_ATTRIBUTE = "GET_EACH_DAILY_ATTRIBUTE"
export const CLEAR_DAILY_ATTRIBUTE = "CLEAR_DAILY_ATTRIBUTE"
export const GET_ALL_PRODUCTS_FOR_ATTRIBUTES = "GET_ALL_PRODUCTS_FOR_ATTRIBUTES"
export const LAST_ATTRIBUTE_FILTERS = "LAST_ATTRIBUTE_FILTERSS";

// Daily Reports

export const TOGGLE_IS_FETCHING_DAILY_REPORTS = "TOGGLE_IS_FETCHING_DAILY_REPORTS"
export const GET_ALL_DAILY_REPORTS = "GET_ALL_DAILY_REPORTS"
export const GET_EACH_DAILY_REPORT = "GET_EACH_DAILY_REPORT"
export const CLEAR_DAILY_REPORT_FORM = "CLEAR_DAILY_REPORT_FORM"
export const CLEAR_DAILY_REPORT = "CLEAR_DAILY_REPORT"
export const GET_COL_ATTRIBUTE_MAPPING = "GET_COL_ATTRIBUTE_MAPPING"
export const GET_DEMO_ATTRIBUTES = "GET_DEMO_ATTRIBUTES"
export const GET_PRODUCT_ATTRIBUTES = "GET_PRODUCT_ATTRIBUTES"
export const FETCH_UNTESTED_PRODUCT = "FETCH_UNTESTED_PRODUCT"
export const LAST_DAILY_REPORT_FILTER = "LAST_DAILY_REPORT_FILTER"
export const REFRESH_DAILY_REPORT = "REFRESH_DAILY_REPORT"

//Reporting Products

export const TOGGLE_IS_FETCHING_PRODUCTS = "TOGGLE_IS_FETCHING_PRODUCTS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const CLEAR_ALL_PRODUCTS = "CLEAR_ALL_PRODUCTS";
export const UPDATE_PRODUCT_EDITING_KEY = "UPDATE_PRODUCT_EDITING_KEY";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_EMPTY_PRODUCT = "REMOVE_EMPTY_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const LAST_PRODUCT_FILTERS = "LAST_PRODUCT_FILTERS";
export const REFRESH_PRODUCTS = "REFRESH_PRODUCTS";

export const UPDATE_NOTIFICATION_TAB_DETAILS = "UPDATE_NOTIFICATION_TAB_DETAILS";
export const CLEAR_NOTIFICATION_CONTENT = "CLEAR_NOTIFICATION_CONTENT";
export const CLEAR_NOTIFICATION_DATA = "CLEAR_NOTIFICATION_DATA";


//Role Templates data

export const SAVE_TIPS_ROLE_TEMPLATES = "SAVE_TIPS_ROLE_TEMPLATES";
export const CANCEL_TIPS_ROLE_TEMPLATES = "CANCEL_TIPS_ROLE_TEMPLATES";

export const SAVE_SUPPORT_ROLE_TEMPLATES = "SAVE_SUPPORT_ROLE_TEMPLATES";
export const CANCEL_SUPPORT_ROLE_TEMPLATES = "CANCEL_SUPPORT_ROLE_TEMPLATES";

export const SAVE_SOLUTIONS_ROLE_TEMPLATES = "SAVE_SOLUTIONS_ROLE_TEMPLATES";
export const CANCEL_SOLUTIONS_ROLE_TEMPLATES = "CANCEL_SOLUTIONS_ROLE_TEMPLATES";

export const SAVE_SOLUTION_TYPE_ROLE_TEMPLATES = "SAVE_SOLUTION_TYPE_ROLE_TEMPLATES";
export const CANCEL_SOLUTION_TYPE_ROLE_TEMPLATES = "CANCEL_SOLUTION_TYPE_ROLE_TEMPLATES";

export const SAVE_RESOURCES_ROLE_TEMPLATES = "SAVE_RESOURCES_ROLE_TEMPLATES";
export const CANCEL_RESOURCES_ROLE_TEMPLATES = "CANCEL_RESOURCES_ROLE_TEMPLATES";

export const SAVE_REPORTS_ROLE_TEMPLATES = "SAVE_REPORTS_ROLE_TEMPLATES";
export const CANCEL_REPORTS_ROLE_TEMPLATES = "CANCEL_REPORTS_ROLE_TEMPLATES";

export const SAVE_NOTIFICATIONS_ROLE_TEMPLATES = "SAVE_NOTIFICATIONS_ROLE_TEMPLATES";
export const CANCEL_NOTIFICATIONS_ROLE_TEMPLATES = "CANCEL_NOTIFICATIONS_ROLE_TEMPLATES";

export const SAVE_NEWS_ROLE_TEMPLATES = "SAVE_NEWS_ROLE_TEMPLATES";
export const CANCEL_NEWS_ROLE_TEMPLATES = "CANCEL_NEWS_ROLE_TEMPLATES";

export const SAVE_DEMO_QUESTIONS_ROLE_TEMPLATES = "SAVE_DEMO_QUESTIONS_ROLE_TEMPLATES";
export const CANCEL_DEMO_QUESTIONS_ROLE_TEMPLATES = "CANCEL_DEMO_QUESTIONS_ROLE_TEMPLATES";

export const SAVE_FEEDBACK_QUESTIONS_ROLE_TEMPLATES = "SAVE_FEEDBACK_QUESTIONS_ROLE_TEMPLATES";
export const CANCEL_FEEDBACK_QUESTIONS_ROLE_TEMPLATES = "CANCEL_FEEDBACK_QUESTIONS_ROLE_TEMPLATES";

export const SAVE_DEMO_PURPOSE_ROLE_TEMPLATES = "SAVE_DEMO_PURPOSE_ROLE_TEMPLATES";
export const CANCEL_DEMO_PURPOSE_ROLE_TEMPLATES = "CANCEL_DEMO_PURPOSE_ROLE_TEMPLATES";

export const SAVE_DEMO_ROLE_TEMPLATES = "SAVE_DEMO_ROLE_TEMPLATES";
export const CANCEL_DEMO_ROLE_TEMPLATES = "CANCEL_DEMO_ROLE_TEMPLATES";

export const SAVE_NETWORKS_ROLE_TEMPLATES = "SAVE_NETWORKS_ROLE_TEMPLATES";
export const CANCEL_NETWORKS_ROLE_TEMPLATES = "CANCEL_NETWORKS_ROLE_TEMPLATES";

export const SAVE_DEMO_CAPABILITY_ROLE_TEMPLATES = "SAVE_DEMO_CAPABILITY_ROLE_TEMPLATES";
export const CANCEL_DEMO_CAPABILITY_ROLE_TEMPLATES = "CANCEL_DEMO_CAPABILITY_ROLE_TEMPLATES"

export const CLEAR_MANAGE_DEMO_SCHEDULES = "CLEAR_MANAGE_DEMO_SCHEDULES";

// Automatic builds 
export const CLEAR_AUTO_BUILDS_SCHEDULES = "CLEAR_AUTO_BUILDS_SCHEDULES";
export const UPDATE_AUTOBUILDS_SCHEDULES_CURRENT_PAGE = "UPDATE_AUTOBUILDS_SCHEDULES_CURRENT_PAGE";
export const FETCH_AUTO_BUILDS_SCHEDULES = "FETCH_AUTO_BUILDS_SCHEDULES";
export const FETCH_AUTO_DEMO_SCHEDULE_COMMENTS = "FETCH_AUTO_DEMO_SCHEDULE_COMMENTS";
export const LAST_AUTO_BUILDS_FILTER = "LAST_AUTO_BUILDS_FILTER";

// Manual builds 
export const FETCH_MANUAL_DEMO_SCHEDULE_COMMENTS = "FETCH_MANUAL_DEMO_SCHEDULE_COMMENTS";
export const FETCH_MANUAL_BUILDS_SCHEDULES = "FETCH_MANUAL_BUILDS_SCHEDULES";
export const UPDATE_MANUALBUILDS_SCHEDULES_CURRENT_PAGE = "UPDATE_MANUALBUILDS_SCHEDULES_CURRENT_PAGE";
export const CLEAR_MANUAL_BUILDS_SCHEDULES = "CLEAR_MANUAL_BUILDS_SCHEDULES";
export const LAST_MANUAL_BUILDS_FILTER = "LAST_MANUAL_BUILDS_FILTER";

// EZ Demos
export const TOGGLE_IS_FETCHING_USER_EZ_DEMOS = "TOGGLE_IS_FETCHING_USER_EZ_DEMOS"
export const REORDER_EZ_DEMOS = "REORDER_EZ_DEMOS"
export const ADD_NEW_EZ_DEMO = "ADD_NEW_EZ_DEMO"
export const DELETE_DEMO_EZ_DEMO = "DELETE_DEMO_EZ_DEMO"
export const FETCH_ALL_MANAGE_EZ_DEMOS = "FETCH_ALL_MANAGE_EZ_DEMOS";
export const FETCH_USER_EZ_DEMOS = "FETCH_USER_EZ_DEMOS"
export const CLEAR_USER_EZ_DEMOS = "CLEAR_USER_EZ_DEMOS"
export const FETCH_ALL_USER_EZ_DEMOS = "FETCH_ALL_USER_EZ_DEMOS"
export const CURRENT_USER_EZ_DEMO = "CURRENT_USER_EZ_DEMO";

// EZ Demo Reviews

export const FETCH_ALL_USERS_REVIEWS_EZ_DEMOS = "FETCH_ALL_USERS_REVIEWS_EZ_DEMOS"
export const TOGGLE_IS_FETCHING_EZ_DEMOS_FEEDBACK = "TOGGLE_IS_FETCHING_EZ_DEMOS_FEEDBACK"
export const FETCH_EZ_DEMO_RATING_SPLIT_UP = "FETCH_EZ_DEMO_RATING_SPLIT_UP"
export const CLEAR_EZ_DEMO_REVIEWS = "CLEAR_EZ_DEMO_REVIEWS";

export const FETCH_ALL_EZ_DEMOS = "FETCH_ALL_EZ_DEMOS";
export const FETCH_ACTIVE_EZ_DEMOS = "FETCH_ACTIVE_EZ_DEMOS"
export const RE_ORDER_EZ_DEMOS_TABLE = "RE_ORDER_EZ_DEMOS_TABLE"
export const TOGGLE_IS_EZ_DEMOS_REORDERED = "TOGGLE_IS_EZ_DEMOS_REORDERED"
export const TOGGLE_IS_FETCHING_EZ_DEMOS = "TOGGLE_IS_FETCHING_EZ_DEMOS"
export const CLEAR_EZ_DEMOS = "CLEAR_EZ_DEMOS"
export const DELETE_EZ_DEMOS_DEMO = "DELETE_EZ_DEMOS_DEMO";
export const ADD_EZ_DEMOS_DEMO = "ADD_EZ_DEMOS_DEMO";
export const CLEAR_EZ_DEMO_FORM = "CLEAR_EZ_DEMO_FORM";
export const FETCH_ALL_SOLUTION_DEMOS = "FETCH_ALL_SOLUTION_DEMOS";
export const FETCH_EZ_DEMO_BY_ID = "FETCH_EZ_DEMO_BY_ID";
export const UPDATE_EZ_DEMOS_ROLE_TEMPLATE = "UPDATE_EZ_DEMOS_ROLE_TEMPLATE";
export const SAVE_EZ_DEMOS_ROLE_TEMPLATES = "SAVE_EZ_DEMOS_ROLE_TEMPLATES";
export const CANCEL_EZ_DEMOS_ROLE_TEMPLATES = "CANCEL_EZ_DEMOS_ROLE_TEMPLATES";
export const FETCH_EZ_DEMO_REVIEW_BY_AUDIT_ID = "FETCH_EZ_DEMO_REVIEW_BY_AUDIT_ID";

export const UPDATE_EZ_DEMO_CURRENT_PAGE = "UPDATE_EZ_DEMO_CURRENT_PAGE"
export const CLEAR_EZ_DEMOS_TABLE_DATA = "CLEAR_EZ_DEMOS_TABLE_DATA"
export const LAST_EZ_DEMO_FILTER = "LAST_EZ_DEMO_FILTER"

//Capability
export const FETCH_ALL_CAPABILITY = "FETCH_ALL_CAPABILITY"
export const FETCH_LITE_CAPABILITY = "FETCH_LITE_CAPABILITY"
export const TOGGLE_IS_FETCHING_CAPABILITY = "TOGGLE_IS_FETCHING_CAPABILITY"
export const CLEAR_CAPABILITY_RESPONSE = "CLEAR_CAPABILITY_RESPONSE"
export const CLEAR_CAPABILITY_TABLE_DATA = "CLEAR_CAPABILITY_TABLE_DATA"
export const CURRENT_CAPABILITY_FILTER = "CURRENT_CAPABILITY_FILTER"
export const CLEAR_CAPABILITY = "CLEAR_CAPABILITY"
export const REFRESH_CAPABILITY = "REFRESH_CAPABILITY"

// Demo Capability
export const TOGGLE_IS_FETCHING_CAPABILITY_FORM = "TOGGLE_IS_FETCHING_CAPABILITY_FORM";
export const FETCH_CAPABILITY_DETAILS = "FETCH_CAPABILITY_DETAILS";
export const CLEAR_CAPABILITY_FORM = "CLEAR_CAPABILITY_FORM";
export const UPDATE_DEMO_CAPABILITY_ROLE_TEMPLATE = "UPDATE_DEMO_CAPABILITY_ROLE_TEMPLATE"
export const FETCH_CAPABILITY_REQUEST_DETAILS = "FETCH_CAPABILITY_REQUEST_DETAILS";
export const TOGGLE_IS_FETCHING_CAPABILITY_REQUEST_DETAILS = "TOGGLE_IS_FETCHING_CAPABILITY_REQUEST_DETAILS";
export const UPDATE_CAPABILITY_DETAILS_COMMENTS = "UPDATE_CAPABILITY_DETAILS_COMMENTS";
export const CLEAR_COMMENT_DETAILS = "CLEAR_COMMENT_DETAILS";
export const UPDATE_CAPABILITY_GENERAL_INSTRUCTION = "UPDATE_CAPABILITY_GENERAL_INSTRUCTION";
export const CLEAR_CAPABILITY_GENERAL_INSTRUCTION = "CLEAR_CAPABILITY_GENERAL_INSTRUCTION";
export const UPDATE_CAPABILITY_QUESTION_INSTRUCTION = "UPDATE_CAPABILITY_QUESTION_INSTRUCTION";
export const CAPABILITY_QUESTION_INSTRUCTIONS = "CAPABILITY_QUESTION_INSTRUCTIONS";
export const CLEAR_CAPABILITY_QUESTION_INSTRUCTION = "CLEAR_CAPABILITY_QUESTION_INSTRUCTION";
export const DELETE_CAPABILITY_QUESTION_INSTRUCTION = "DELETE_CAPABILITY_QUESTION_INSTRUCTION";

//Capability Requests
export const CLEAR_CAPABILITY_REQUEST_DATA = "CLEAR_CAPABILITY_REQUEST_DATA";
export const TOGGLE_IS_FETCHING_CAPABILITY_REQUESTS = "TOGGLE_IS_FETCHING_CAPABILITY_REQUESTS";
export const FETCH_ALL_CAPABILITY_REQUESTS = "FETCH_ALL_CAPABILITY_REQUESTS";
export const UPDATE_CAPABILITY_REQUEST_EDITING_KEY = "UPDATE_CAPABILITY_REQUEST_EDITING_KEY";
export const CURRENT_CAPABILITY_REQUEST_FILTER = "CURRENT_CAPABILITY_REQUEST_FILTER";
export const FETCH_CAPABILITY = "FETCH_CAPABILITY";

// User side my schedule section
export const FETCH_MY_DEMO_SCHEDULES = "FETCH_MY_DEMO_SCHEDULES";
export const TOGGLE_IS_FETCHING_MY_DEMO_SCHEDULES = "TOGGLE_IS_FETCHING_MY_DEMO_SCHEDULES";
export const CLEAR_MY_SCHEDULES_RESPONSE = "CLEAR_MY_SCHEDULES_RESPONSE";
export const RETAIN_MY_SCHEDULE_FILTER = "RETAIN_MY_SCHEDULE_FILTER";
export const CLEAR_MY_DEMO_SCHEDULES = "CLEAR_MY_DEMO_SCHEDULES";


// User side my requests section
export const FETCH_MY_REQUESTS = "FETCH_MY_REQUESTS";
export const TOGGLE_IS_FETCHING_MY_REQUESTS = "TOGGLE_IS_FETCHING_MY_REQUESTS";
export const CLEAR_MY_REQUESTS = "CLEAR_MY_REQUESTS";
export const LAST_MY_REQUESTS_FILTER = "LAST_MY_REQUESTS_FILTER";

export const FETCH_SFDC_STATS_DATA = "FETCH_SFDC_STATS_DATA";
export const CLEAR_SFDC_STATS_DATA = "CLEAR_SFDC_STATS_DATA";
export const LAST_SFDC_STATS_FILTER = "LAST_SFDC_STATS_FILTER";