import React, { Component } from 'react'
import {
    toggleBackButton,
    getProductsForAttributes,
    addNewDemoAttribute,
    deleteDemoAttribute,
    toggleIsFetchingDailyAttributes,
    postDailyReportAttribute,
    clearAttributeForm,
    getDailyAttributeById,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag
} from "../../actions";
import { connect, batch } from "react-redux";
import { Spin, Form, Input, Row, Col, Select, Button, Icon, Popconfirm, Table } from "antd";
import FormActionButtons from '../common/FormActionButtons';
const { Option } = Select;
class DailyReportAttributeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false
        };
    }

    componentDidMount() {
        this.props.toggleBackButton(false);
        const { attributeId } = this.props.match.params;

        document.getElementById("tab-pane").scrollIntoView();
        batch(async () => {
            this.props.getProductsForAttributes(true);
            attributeId && await this.props.getDailyAttributeById(attributeId)
        })
    }

    handleCancel = () => {
        // history.goBack(); 
        this.props.clearAttributeForm();
        this.props.closeCurrentOpenTab();
    }

    handleSave = async () => {
        const { attributeId } = this.props.match.params;
        const id = attributeId ? attributeId : undefined;
        const { form, demos } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return
            }
            const attributeName = values?.title
            const productAttributes = demos?.length > 0 ? demos?.map((demo) => {
                return {
                    product: {
                        id: demo?.demo.id,
                        title: demo?.demo.title
                    }
                }
            }) : []

            const data = {
                id: id,
                attributeName,
                productAttributes
            }
            this.props.toggleIsFetchingDailyAttributes(true);
            const result = await this.props.postDailyReportAttribute(id, data);
            this.props.toggleIsFetchingDailyAttributes(false);
            if (result) {
                this.props.clearAttributeForm();
                // history.goBack();
                this.props.closeCurrentOpenTab();
            }
        });
    }

    componentWillUnmount() {
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.props.clearAttributeForm();
    }

    renderFormButtons = () => {
        return (
            <div>
                <Row className="right-align vertical-spacing" >
                    <FormActionButtons
                        handleSubmit={this.handleSave}
                        cancelText={"Discard & Close"}
                        isDisabled={!this.hasChanged}
                        handleCancel={() => this.props.closeCurrentOpenTab()}
                        okText="Save" />
                </Row>
            </div>
        );
    }

    renderDemoOptions = () => {
        const { allDemos, demos } = this.props;
        const filteredDemo = allDemos?.filter(ad =>
            demos.every(demo => demo?.demo?.id !== ad.id));
        if (filteredDemo)
            return filteredDemo.map((demo, index) => <Option key={index} value={JSON.stringify(demo)} title={demo.title}>{demo.title}</Option>);
        return [];
    }

    handleAddDemo = () => {
        const { form } = this.props;
        const demo = form.getFieldsValue().demo;

        demo.forEach(item => {
            const selectedDemo = JSON.parse(item);
            this.props.addNewDemoAttribute(selectedDemo);
        })
        form.setFieldsValue({ demo: undefined });
    }

    getCurrentData = (formDetails) => {
        return {
            title: formDetails?.title,
            products: formDetails?.demo,
            demos: this.props.demos
        };
    }

    componentDidUpdate() {
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    }

    hasFormChanges = (currentData, attribute) => {
        let hasChanged = false;
        if (
            currentData?.title !== attribute?.attributeName ||
            currentData?.demo !== undefined
        ) {
            hasChanged = true
        }

        if (currentData?.demos.length !== attribute?.productAttributes.length) {
            hasChanged = true;
        } else {
            const changed = currentData?.demos?.map(newData => attribute?.productAttributes?.some(oldData => oldData?.product?.id === newData?.demo?.id))
            if (changed?.includes(false)) { hasChanged = true };
        }

        return hasChanged;
    }

    render() {
        const { form, demos, attribute, loading } = this.props;
        const isEditable = this.props.match.params.attributeId ? true : false
        const { getFieldDecorator } = form;
        const currentAttributeData = this.getCurrentData(this.props.form.getFieldsValue());
        this.hasChanged = isEditable ? this.hasFormChanges(currentAttributeData, attribute) : true;
        const columns = [
            {
                title: 'Product',
                key: 'title',
                width: '20%',
                align: 'left',
                render: (record) => record.demo?.title
            },
            {
                title: 'Action',
                key: 'action',
                width: '10%',
                render: (record) => {
                    return (
                        <div>
                            <Popconfirm okText="Yes" cancelText="No" title="Confirm delete?" onConfirm={() => this.props.deleteDemoAttribute(record.demo.id)}>
                                <button className="link"><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                            </Popconfirm>
                        </div>
                    )
                },
                align: 'center'
            },
        ];
        return (
            <div className="content-container">
                <Spin spinning={loading}>
                    {this.renderFormButtons()}
                    <Form id="dailyReportAttributeForm">
                        <Row>
                            <Col xl={8} sm={10} xs={24}>
                                <Form.Item label="Title">
                                    {getFieldDecorator("title", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input the title",
                                            },
                                        ],
                                        initialValue: isEditable ? attribute?.attributeName : undefined,
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item>
                                    {
                                        getFieldDecorator('demo')(
                                            <Select
                                                mode="multiple"
                                                showSearch={true}
                                                getPopupContainer={() => document.getElementById('dailyReportAttributeForm')}
                                                placeholder="Select a Product">
                                                {this.renderDemoOptions()}
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Col>
                            <Col xl={4} sm={4} xs={24}>
                                <Button className="primary-action-button-bordered horizontal-spacing-2 antd-button-fix" onClick={this.handleAddDemo} disabled={(!form.getFieldValue('demo') || form.getFieldValue('demo')?.length === 0)}>
                                    Add
                                    <Icon type="plus-circle" />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={18} sm={18} xs={24}>
                                <Table
                                    className="vertical-spacing-2 medium-content responsive-container"
                                    columns={columns}
                                    dataSource={demos}
                                    bordered
                                    pagination={false}
                                    rowKey={(record) => record.id}
                                />
                            </Col>
                        </Row>
                    </Form>
                    {this.renderFormButtons()}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = ({ user, dailyAttribute, tabsLayout }) => {
    return {
        allDemos: dailyAttribute.allProducts,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        demos: dailyAttribute.demo,
        loading: dailyAttribute.loading,
        attribute: dailyAttribute.attribute,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
    };
};

const DailyReportAttributeFormrRef = Form.create()(DailyReportAttributeForm);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        getProductsForAttributes,
        addNewDemoAttribute,
        deleteDemoAttribute,
        toggleIsFetchingDailyAttributes,
        postDailyReportAttribute,
        clearAttributeForm,
        getDailyAttributeById,
        closeCurrentOpenTab,
        toggleHasUnsavedChangesFlag

    }
)(DailyReportAttributeFormrRef);