import React from 'react';
import { connect, batch } from 'react-redux';
import { Form, Input, Row, Col, Spin, Switch, Select } from 'antd';
import moment from 'moment-timezone';
import debounce from 'lodash/debounce';
import { fetchCompaniesBySearchString } from '../../actions/localUsers'
import CONFIG from '../../config';
import openConfirm from '../common/Confirm';
import FormActionButtons from '../common/FormActionButtons';
import { checkIfRoleTempleteTagsChanged } from "../../utils/formChanged";
import {
    fetchAllUsers,
    toggleBackButton,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    updateLocalUsersCurrentPage,
    selectTitle,
    clearLocalUsers,
    toggleIsFetchingLocalUsersFlag,
    fetchAllCountries,
    postLocalUser,
    fetchLocalUser,
    fetchAllCompanies,
    clearLocalUsersDetails,
    postNewCompany,
    currentLocalUsersFilter,
    removeLocalUserTab,
    updateLocalUserFormTabData,
    updateTabsLocalStorage,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag,
    handleOnClickAction,
    closeActiveTab

} from '../../actions';

const { Option } = Select;

class LocalUsersForm extends React.Component {

    constructor(props) {
        super(props);
        this.fetchCompanies = debounce(this.fetchCompanies, 800);
    }

    state = {
        matchedCompanies: [],
        searchString: [],
        fetchingCompanies: false,
    };

    fetchCompanies = searchString => {
        this.setState({ matchedCompanies: [], fetchingCompanies: true });
        fetchCompaniesBySearchString({ searchString })
            .then((result) => {
                result?.length !== 0 ? this.setState({ matchedCompanies: result, fetchingCompanies: true }) : this.setState({ fetchingCompanies: false });
            });
    };

    handleChange = searchString => {
        this.setState({
            searchString,
            matchedCompanies: [],
            fetchingCompanies: false,
        });
    };

    isCompanyExisting = (newCompany) => {
        const { allCompanies } = this.props;
        const isCompanyExisting = allCompanies.filter(company => company.company === newCompany);
        return isCompanyExisting.length !== 0;
    }

    createNewCompany = (selectedCompany) => {
        const { form } = this.props;
        this.props.postNewCompany((selectedCompany))
            .then(() => {
                form.setFieldsValue({ company: selectedCompany })
                this.props.fetchAllCompanies()
            })
    }

    handleCompanySelect = (selectedCompany) => {
        const { form, allCompanies } = this.props;
        selectedCompany = selectedCompany?.trim();
        if (selectedCompany) {
            form.setFieldsValue({ company: selectedCompany })
            let companyObj = allCompanies.find(company => company.company === selectedCompany);
            if (!this.isCompanyExisting(selectedCompany) && companyObj === undefined) {
                openConfirm(
                    'Yes',
                    () => this.createNewCompany(selectedCompany),
                    () => form.setFieldsValue({ company: [] }),
                    CONFIG.localUser.createNewCompany.replace('<placeHolder>', JSON.stringify(selectedCompany)),
                    null);
            }
            else form.setFieldsValue({ company: selectedCompany })
        }
        else
            form.setFieldsValue({ company: [] })
    }

    handleCountrySelect = (selectedCountry) => {
        this.props.countries.forEach(country => { if (country.name === selectedCountry) this.props.form.setFieldsValue({ region: country.region.name }) })
    }

    handleCreate = async () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) return;
            this.props.toggleIsFetchingLocalUsersFlag(true)
            const title = form.getFieldValue('firstName')
            await this.props.postLocalUser(values, this.props.match?.params?.userId, this.props.activeKey)
                .then(async (response) => {
                    if (response !== undefined) {
                        await this.props.closeActiveTab();
                        setTimeout(async () => {
                            await this.props.handleOnClickAction({
                                component: CONFIG.editComponentRoute.nonSsoUsers,
                                tabTitle: title,
                                recordId: parseInt(response?.localUserId)
                            })
                        }, 100)
                    }
                })
        });
    }

    getCurrentData = (formData) => {
        return {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            sslHandle: formData.sslHandle,
            contactNumber: formData.contactNumber,
            roleTemplate: formData.roleTemplate,
            country: formData.country,
            region: formData.region,
            company: formData.company,
            timezone: formData.timezone,
            active: formData.active,
            isPeriodicEnabled: formData.isPeriodicEnabled,
            isMultipleDays: formData.isMultipleDays,
            isAddParticipantsEnabled: formData.isAddParticipantsEnabled,
            isMultipleScheduleBooking: formData.isMultipleScheduleBooking
        }
    }

    checkLocalUserFormChanged = (currentLocalUserForm) => {
        const { firstName, lastName, email, sslHandle, company, contactNumber, country, timezone, isActive, isPeriodicEnabled, isMultipleDays, isAddParticipantsEnabled, isMultipleScheduleBooking } = this.props.localUserDetails || {}
        const { name } = this.props.region || {}
        const { userId } = this.props.match.params;
        const { localUserObj } = this.props || {}
        let hasChanged = false;

        if (userId) {
            const isCompanyChanged = (currentLocalUserForm?.company === undefined || currentLocalUserForm?.company === null || currentLocalUserForm?.company.length === 0)
                ? (company?.company !== undefined && company?.company !== null && company?.company !== '')
                : currentLocalUserForm?.company !== company?.company;

            hasChanged = currentLocalUserForm?.firstName !== firstName
                || currentLocalUserForm?.lastName !== lastName
                || currentLocalUserForm?.email !== email
                || currentLocalUserForm?.sslHandle !== sslHandle
                || currentLocalUserForm?.contactNumber !== contactNumber
                || currentLocalUserForm?.country !== country
                || currentLocalUserForm?.region !== name
                || isCompanyChanged
                || currentLocalUserForm?.active !== isActive
                || currentLocalUserForm?.timezone !== timezone
                || currentLocalUserForm?.isPeriodicEnabled !== isPeriodicEnabled
                || currentLocalUserForm?.isMultipleDays !== isMultipleDays
                || currentLocalUserForm?.isAddParticipantsEnabled !== isAddParticipantsEnabled
                || currentLocalUserForm?.isMultipleScheduleBooking !== isMultipleScheduleBooking
                || checkIfRoleTempleteTagsChanged(localUserObj?.userRoleTemplates, currentLocalUserForm?.roleTemplate)

        }

        else {
            hasChanged = true;
        }
        if (hasChanged) return hasChanged;
        return hasChanged;
    }

    componentDidUpdate() {
        LocalUsersFormRef = undefined;
        const { userId, user } = this.props.match.params;
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const { form } = this.props;
        const currentLocalUserForm = this.getCurrentData(form.getFieldsValue());
        this.hasChanged = userId ? this.checkLocalUserFormChanged(currentLocalUserForm, isSuperAdminUser) : true;
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged)
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged)
    }

    async componentDidMount() {
        // Edit form contains userId. CREATE NEW does not.
        const { userId } = this.props.match?.params || [];
        const currentPath = window.location.pathname
        this.setState({ currentPath: currentPath });

        batch(async () => {
            this.props.toggleIsFetchingLocalUsersFlag(true);
            await this.props.fetchAllCompanies();
            await this.props.fetchAllCountries();
            await this.props.fetchRoleTemplates(false);
            this.props.toggleIsFetchingLocalUsersFlag(false);
            if (userId) {
                this.props.fetchLocalUser(userId);
            }
        })
    }

    componentWillUnmount() {
        // clear the corresponding form data from Store.
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.props.clearLocalUsersDetails()
    }

    renderTimeZoneOptions = () => {
        const timezones = moment?.tz?.names();
        return timezones?.map((timezone, index) => {
            return <Option key={index} value={timezone}>{timezone}</Option>;
        });
    }

    renderRoleTemplateOptions = () => {
        const { roleTemplates } = this.props;
        return roleTemplates?.map(roleTemplate => {
            return (
                <Option key={roleTemplate.id} value={JSON.stringify({ id: roleTemplate.id, title: roleTemplate.title })}>
                    {roleTemplate.title}
                </Option>
            );
        });
    }


    renderCountryOptions = () => {
        const country = this.props.countries;
        return country?.map(country => {
            return (<Option key={country.id} value={(country.name)}>{country.name}</Option>
            );
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { isFetching, tabData, roleTemplates } = this.props;
        const userId = this.props.match?.params?.userId ?? 0;
        const currentUserData = tabData?.find(item => item?.id === userId)?.data
        const { firstName, lastName, timezone, sslHandle, country, email, contactNumber, isActive, userRoleTemplates, region, company, isAddParticipantsEnabled, isMultipleDays, isPeriodicEnabled, isMultipleScheduleBooking } = currentUserData || {};
        const { fetchingCompanies, matchedCompanies, value } = this.state;
        return (
            <div className="content-container">
                <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                    <Form>
                        <Row gutter={48}>
                            <Col xl={5} sm={20} xs={24}>
                                <Form.Item label="First Name">
                                    {getFieldDecorator("firstName", {
                                        rules: [
                                            { required: true, message: "Please input First Name" },
                                        ], initialValue: firstName || ""
                                    })(<Input />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col xl={5} sm={20} xs={24}>
                                <Form.Item label="Last Name">
                                    {getFieldDecorator("lastName", {
                                        rules: [
                                            { required: true, message: "Please input Last Name" },
                                        ], initialValue: lastName || ""
                                    }
                                    )(<Input />)
                                    }
                                </Form.Item>
                            </Col>

                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item label="Email">
                                    {getFieldDecorator("email", {
                                        rules: [{
                                            required: true,
                                            type: "email",
                                            message: "Please input the Email ID"
                                        }], initialValue: email || ""
                                    })(<Input />)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col xl={5} sm={20} xs={24}>
                                <Form.Item label="Username">
                                    {getFieldDecorator("sslHandle", {
                                        rules: [
                                            { required: true, message: "Please input username" },
                                            { pattern: /^\S*$/, message: "Username cannot have spaces." },
                                        ], initialValue: sslHandle || ""
                                    })(<Input />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Phone Number">
                                    {getFieldDecorator("contactNumber", {
                                        rules: [
                                            { required: false, message: "Please input the Contact Number" },
                                            { pattern: CONFIG.regEx.phoneNumber, message: "Please input valid Phone Number" },
                                        ], initialValue: contactNumber || ""
                                    })(<Input />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item label="Assign Role Template">
                                    {getFieldDecorator("roleTemplate", {
                                        initialValue: userRoleTemplates?.filter(userRoleTemplate => roleTemplates?.some(role => role?.id === userRoleTemplate?.roleTemplate?.id))
                                            .map(userRoleTemplate => JSON.stringify({ id: userRoleTemplate?.roleTemplate?.id, title: userRoleTemplate?.roleTemplate?.title }))
                                    })(
                                        <Select showSearch={true} placeholder="Assign Role Template" mode="multiple">
                                            {this.renderRoleTemplateOptions()}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Country">
                                    {getFieldDecorator("country", {
                                        rules: [{
                                            required: true,
                                            message: "Please select a Country"
                                        }], initialValue: country || ""
                                    })(<Select showSearch={true} placeholder="Select Country" onSelect={this.handleCountrySelect}>
                                        {this.renderCountryOptions()}
                                    </Select>)
                                    }
                                </Form.Item>
                            </Col>


                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Region">
                                    {getFieldDecorator("region", {
                                        rules: [{
                                            required: false,
                                            message: "Please select the region"
                                        }], initialValue: region?.name || ""
                                    })(<Input readonly="readonly" />)
                                    }
                                </Form.Item>
                            </Col>

                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Company">
                                    {getFieldDecorator("company", {
                                        rules: [{
                                            required: false,
                                            message: "Please input the Company name"
                                        }], initialValue: company?.company
                                    })
                                        (<Select
                                            mode="tags"
                                            showSearch={true}
                                            value={value}
                                            placeholder="Select/Create a Company"
                                            notFoundContent={fetchingCompanies ? <Spin size="small" /> : null}
                                            onSearch={this.fetchCompanies}
                                            onChange={this.handleChange}
                                            style={{ width: '100%' }}
                                            onSelect={this.handleCompanySelect}
                                        >
                                            {matchedCompanies?.map((eachCompany) => (
                                                <Option key={eachCompany.id} value={eachCompany.company} title={eachCompany.company}>{eachCompany.company}</Option>
                                            ))}
                                        </Select>)
                                    }
                                </Form.Item>
                            </Col>
                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Time Zone">
                                    {getFieldDecorator("timezone", {
                                        rules: [{
                                            required: true,
                                            message: "Please select Time Zone"
                                        }], initialValue: timezone || ""
                                    })(<Select showSearch={true} placeholder="Select a Time Zone">
                                        {this.renderTimeZoneOptions()}
                                    </Select>)
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={48}>
                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Active">
                                    {getFieldDecorator("active", {
                                        valuePropName: "checked",
                                        initialValue: sslHandle ? isActive : false
                                    })(<Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />)}

                                </Form.Item>
                            </Col>
                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Periodic Schedules">
                                    {getFieldDecorator("isPeriodicEnabled", {
                                        valuePropName: "checked",
                                        initialValue: sslHandle ? isPeriodicEnabled : false
                                    })(<Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />)}

                                </Form.Item>
                            </Col>
                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Add Participants">
                                    {getFieldDecorator("isAddParticipantsEnabled", {
                                        valuePropName: "checked",
                                        initialValue: sslHandle ? isAddParticipantsEnabled : false
                                    })(<Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />)}

                                </Form.Item>
                            </Col>
                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Multiple Days">
                                    {getFieldDecorator("isMultipleDays", {
                                        valuePropName: "checked",
                                        initialValue: sslHandle ? isMultipleDays : false
                                    })(<Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />)}

                                </Form.Item>
                            </Col>
                            <Col xl={5} sm={10} xs={24}>
                                <Form.Item label="Multiple Schedule Booking">
                                    {getFieldDecorator("isMultipleScheduleBooking", {
                                        valuePropName: "checked",
                                        initialValue: sslHandle ? isMultipleScheduleBooking : false
                                    })(<Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />)}

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="vertical-spacing right-align">
                            <Col xl={20} sm={24} xs={24} >
                                <FormActionButtons
                                    handleSubmit={this.handleCreate}
                                    handleCancel={() => this.props.closeCurrentOpenTab()}
                                    cancelText={"Discard & Close"}
                                    isDisabled={!this.hasChanged}
                                    okText={userId ? 'Update' : 'Create'} />
                            </Col>
                        </Row>
                    </Form>
                </Spin >
            </div >
        );
    }
}

const mapStateToProps = ({ roleTemplates, user, localUsersForm, localUsers, tabsLayout }) => {
    return {
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        localUsersForm,
        localUserDetails: localUsersForm.localUserDetails,
        isFetching: localUsers.isFetching,
        roleTemplates: roleTemplates.all,
        countries: localUsersForm.countries,
        companies: localUsersForm.companies,
        allCompanies: localUsersForm.allCompanies,
        tabData: localUsersForm.tabData,
        openedTabs: tabsLayout?.panes,
        localUserObj: localUsersForm?.localUserObj,
        activeKey: tabsLayout.activeKey,
        region: localUsersForm?.localUserObj?.region,
    };
}

let LocalUsersFormRef = Form.create()(LocalUsersForm);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        fetchRoleTemplates,
        fetchAdminRoleTemplates,
        fetchAllUsers,
        updateLocalUsersCurrentPage,
        selectTitle,
        clearLocalUsers,
        toggleIsFetchingLocalUsersFlag,
        fetchAllCountries,
        postLocalUser,
        fetchLocalUser,
        fetchAllCompanies,
        clearLocalUsersDetails,
        postNewCompany,
        removeLocalUserTab,
        currentLocalUsersFilter,
        updateLocalUserFormTabData,
        updateTabsLocalStorage,
        closeCurrentOpenTab,
        toggleHasUnsavedChangesFlag,
        handleOnClickAction,
        closeActiveTab
    }
)(LocalUsersFormRef);