import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone';
import { Spin, Popover, Select, Divider, Icon, Tooltip, DatePicker } from "antd"
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    fetchMaintenanceSchedule,
    handleOnClickAction
} from '../../actions';
import CONFIG from "../../config";
import { getMaintenanceView, computeTime } from '../../utils/scheduler';
import { Eventcalendar, momentTimezone, CalendarPrev, CalendarNext, CalendarToday } from '@mobiscroll/react';
momentTimezone.moment = moment;
const { Option } = Select;
const { MonthPicker } = DatePicker;

function ScheduleMaintenanceCalendar(props) {
    const [view, setView] = useState({ calendar: { labels: 8, type: "month", popover: true, popoverClass: 'custom-event-popover' } })
    const [calendarDate, setCalendarDate] = useState(moment(moment(moment()).format("YYYY-MM-DD HH:mm:ss")).toDate());
    const [data, setData] = useState([]);
    const [month, setMonth] = useState(moment(calendarDate).format("MM-YYYY"));
    const [viewType, setViewType] = useState("month");
    const [loading, setLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const maintenanceSchedule = useSelector(({ maintenanceSchedule }) => maintenanceSchedule || {});
    const { isEdit, calendarCurrentDate, calendarViewType } = maintenanceSchedule || {};
    const dispatch = useDispatch();
    const { timeZone, status, isCalendarDeleted } = props;
    const filterSortObject = { filter: props.filterSort }

    const getEvents = (schedules) => {
        const data = schedules?.map((schedule) => {
            return {
                scheduleId: schedule.id,
                title: schedule.title ?? "Maintenance",
                start: moment(moment(schedule.startTime).format("YYYY-MM-DD HH:mm:ss")).toISOString(),
                end: moment(moment(schedule.endTime).format("YYYY-MM-DD HH:mm:ss")).toISOString(),
                color: "rgb(255 109 66)",
                editable: false,
                allDay: false,
                schedule,
                startTime: moment.tz(schedule.startTime, timeZone),
                endTime: moment.tz(schedule.endTime, timeZone),
            }
        })
        setData(data);
    }

    useEffect(() => {
        if (isEdit) {
            calendarCurrentDate && setCalendarDate(calendarCurrentDate);
            calendarViewType && setViewType(calendarViewType);
            setLoading(true);
            let calView = getMaintenanceView(calendarViewType);
            const monthNumber = moment(calendarCurrentDate).format("MM-YYYY");
            setView(calView);
            setMonth(monthNumber);
            const [start, end] = computeTime(calendarViewType, null, calendarCurrentDate, timeZone);
            const filterSort = {
                ...filterSortObject,
                filter: {
                    ...filterSortObject?.filter,
                    startTime: start,
                    endTime: end,
                }
            }
            dispatch(fetchMaintenanceSchedule({ status, filterSort, pageNumber: 1, pageSize: null }))
                .then((result) => {
                    setLoading(false);
                    getEvents(result);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isEdit) {
            setLoading(true);
            const [start, end] = computeTime(viewType, null, calendarDate, timeZone);
            const filterSort = {
                ...filterSortObject,
                filter: {
                    ...filterSortObject?.filter,
                    startTime: start,
                    endTime: end,
                }
            }
            dispatch(fetchMaintenanceSchedule({ status, filterSort, pageNumber: 1, pageSize: null }))
                .then((result) => {
                    setLoading(false);
                    getEvents(result);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, props.filterSort, isCalendarDeleted]);

    const handleViewChange = async (value) => {
        const { timeZone } = props;
        let calView = getMaintenanceView(value);
        const [start, end] = computeTime(value, null, calendarDate, timeZone);
        setView(calView);
        setViewType(value);
        setCalendarDate(moment(start).toDate())
        fetchChangedData(start, end);
        props.updateCalendarProps(moment(start).toDate(), value)
    }

    const renderViewOptions = () => {
        return CONFIG.maintenanceViews?.map((view) => {
            return <Option key={view.value} value={view.value}>{view.text}</Option>
        })
    }

    const fetchChangedData = async (start, end) => {
        setLoading(true);
        const filterSort = {
            ...filterSortObject,
            filter: {
                ...filterSortObject?.filter,
                startTime: start,
                endTime: end,
            }
        }
        await dispatch(fetchMaintenanceSchedule({ status, filterSort, pageNumber: 1, pageSize: null }))
            .then((result) => {
                setLoading(false);
                getEvents(result);
            })
    }

    const handleDateChange = async (value) => {
        if (value === null) return;
        const monthNumber = moment(value).format("MM-YYYY");
        if (month !== monthNumber) {
            const isSameMonth = moment.tz(moment(), timeZone).isSame(moment(value), "month");
            const [start, end] = computeTime(viewType, value, calendarDate, timeZone);
            const selectedDate = isSameMonth ? moment(moment()) : moment(start).toDate()
            setCalendarDate(selectedDate);
            setMonth(monthNumber);
            fetchChangedData(start, end);
            props.updateCalendarProps(selectedDate, viewType)
        }
    }

    const onSelectedDateChange = async (event, inst) => {
        const monthNumber = moment(event.date).format("MM-YYYY");
        if (month !== monthNumber) {
            const isSameMonth = moment.tz(moment(), timeZone).isSame(moment(event.date), "month");
            const [start, end] = computeTime(viewType, moment(event?.date), calendarDate, timeZone)
            const selectedDate = isSameMonth ? moment(moment()) : moment(start).toDate()
            setCalendarDate(selectedDate);
            setMonth(monthNumber);
            fetchChangedData(start, end);
            props.updateCalendarProps(selectedDate, viewType)
        }
        //fetch the Call
    }

    const hideOtherDisplays = () => {
        const childElements = document.querySelectorAll('.display-none');
        childElements.forEach(childElement => {
            const parentElement = childElement.closest('.mbsc-ios.mbsc-event.mbsc-list-item');

            if (parentElement) {
                parentElement.style.display = 'none';
            }
        });
    }

    const renderAgendaEvent = (data) => {
        const { deleteView } = props;
        const item = data?.original?.schedule;
        const start = moment.tz(item?.startTime, timeZone).format("YYYY-MM-DD HH:mm:ss");
        const end = moment.tz(item?.endTime, timeZone).format("YYYY-MM-DD HH:mm:ss");
        const currentTime = moment().tz(timeZone, true);
        const endTime = moment(item?.endTime).tz(timeZone, true);
        const isActiveMaintenance = endTime > currentTime;
        const maintenanceRelations = item?.maintenanceRelations || [];
        const isEditEnabled = maintenanceRelations?.some(item => item?.demo?.adminAccess === CONFIG.roleTemplateAccess.fullAccess)
        const isDeleteEnabled = maintenanceRelations?.every(item => item?.demo?.adminAccess === CONFIG.roleTemplateAccess.fullAccess)
        const content = <div className='font' >{maintenanceRelations?.map((eachItem, index) => (
            <span key={index} style={{ fontSize: "12px", color: "black" }}>
                <div> <span style={{ fontWeight: "600", fontFamily: 'Noto Sans', }}>Demo :</span>   {eachItem?.demo?.title || "-"} </div>
                <div> <span style={{ fontWeight: "600", fontFamily: 'Noto Sans', }}>Data Center(s) :</span>  {eachItem?.dataCenter?.title || "ALL"} </div>
                <div> <span style={{ fontWeight: "600", fontFamily: 'Noto Sans', }}>Resource(s) : </span>{eachItem?.resource?.name || "ALL"} </div>
                {(maintenanceRelations?.length > 1) && <Divider style={index === maintenanceRelations?.length - 1 ? { display: "none" } : {}} />}
            </span>))} </div>
        setTimeout(() => hideOtherDisplays(), 1);
        const actions = isActiveMaintenance ? <div>
            {!deleteView &&
                <>
                    <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={item?.id}>
                        <button disabled={!isEditEnabled}
                            onClick={async () => {
                                await dispatch(handleOnClickAction({
                                    component: CONFIG.editComponentRoute.scheduleMaintenance,
                                    tabTitle: item?.title,
                                    recordId: item?.id
                                }
                                ))
                            }}
                            className={isEditEnabled ? "link" : "link-disabled"}>
                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "16px" }}></span></button>
                    </Tooltip>

                    <Divider type="vertical" />
                </>
            }
            <button title={!isDeleteEnabled ? CONFIG.warningMessages.noAccess : undefined} disabled={!isDeleteEnabled} className={isDeleteEnabled ? "link" : "link-disabled"} onClick={
                () => {
                    props.handleDelete(item, true);
                }}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "16px" }}></span></button>
            {!deleteView &&
                <>
                    <Divider type="vertical" />
                    <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={item?.id}>
                        <button disabled={!isEditEnabled}
                            onClick={async () => {
                                await dispatch(handleOnClickAction({
                                    component: CONFIG.cloneComponentRoute.scheduleMaintenance,
                                    tabTitle: item?.title,
                                    recordId: item?.id,
                                    isClone: true
                                }
                                ))
                            }}
                            className={isEditEnabled ? "link" : "link-disabled"}>
                            <span class="neo-icon-copy" title="Clone" style={{ fontSize: "16px" }}></span></button>
                    </Tooltip>
                </>
            }
        </div> : <>
            <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={item?.id}>
                <button disabled={!isEditEnabled}
                    onClick={async () => {
                        await dispatch(handleOnClickAction({
                            component: CONFIG.cloneComponentRoute.scheduleMaintenance,
                            tabTitle: item?.title,
                            recordId: item?.id,
                            isClone: true
                        }
                        ))
                    }}
                    className={isEditEnabled ? "link" : "link-disabled"}>
                    <span class="neo-icon-copy" title="Clone" style={{ fontSize: "16px" }}></span></button>
            </Tooltip>
        </>

        return (<React.Fragment>
            <div className='font' style={{ fontSize: "12px", fontWeight: "600", color: "black", fontFamily: 'Noto Sans', }}>{data.title}  </div>
            <div className="md-custom-event-cont font">
                <div style={{ fontSize: "12px" }}>
                    <div style={{ marginRight: "5px" }}>{start} - {end} <span><Popover
                        content={content}
                        trigger="click"
                        style={{ zIndex: "100" }}
                    >
                        <span className="small-text"> <Icon type="info-circle" className="text-color-primary" width={1} /></span>
                    </Popover></span></div>
                </div>
            </div>
            <div>
                <span style={{ marginLeft: "10px", display: "flex", marginTop: "10px" }}>  {actions}</span>
            </div>
        </React.Fragment>);
    }

    const renderEventContent = (data) => {
        const { deleteView } = props;
        const selectedItem = data?.original?.scheduleId === selectedEvent?.scheduleId;
        const start = moment.tz(selectedEvent?.start, timeZone).format("YYYY-MM-DD HH:mm:ss");
        const end = moment.tz(selectedEvent?.end, timeZone).format("YYYY-MM-DD HH:mm:ss");
        const currentTime = moment().tz(timeZone, true);
        const endTime = moment(selectedEvent?.end).tz(timeZone, true);
        const isActiveMaintenance = endTime > currentTime;
        const maintenanceRelations = selectedEvent?.schedule?.maintenanceRelations || [];
        const isEditEnabled = maintenanceRelations?.some(item => item?.demo?.adminAccess === CONFIG.roleTemplateAccess.fullAccess)
        const isDeleteEnabled = maintenanceRelations?.every(item => item?.demo?.adminAccess === CONFIG.roleTemplateAccess.fullAccess)
        const content = <div className='font' >{maintenanceRelations?.map((eachItem, index) => (
            <span key={index} style={{ fontSize: "12px", color: "black" }}>
                <div> <span style={{ fontWeight: "600", fontFamily: 'Noto Sans', }}>Demo :</span>   {eachItem?.demo?.title || "-"} </div>
                <div> <span style={{ fontWeight: "600", fontFamily: 'Noto Sans', }}>Data Center(s) :</span>  {eachItem?.dataCenter?.title || "ALL"} </div>
                <div> <span style={{ fontWeight: "600", fontFamily: 'Noto Sans', }}>Resource(s) : </span>{eachItem?.resource?.name || "ALL"} </div>
                {(maintenanceRelations?.length > 1) && <Divider style={index === maintenanceRelations?.length - 1 ? { display: "none" } : {}} />}
            </span>))} </div>
        setTimeout(() => hideOtherDisplays(), 1);
        const actions = (viewType !== "agenda") ? isActiveMaintenance ? <div>
            {!deleteView &&
                <>
                    <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={selectedEvent?.schedule?.id}>
                        <button disabled={!isEditEnabled}
                            onClick={async () => {
                                await dispatch(handleOnClickAction({
                                    component: CONFIG.editComponentRoute.scheduleMaintenance,
                                    tabTitle: selectedEvent?.schedule?.title,
                                    recordId: selectedEvent?.schedule?.id
                                }
                                ))
                            }}
                            className={isEditEnabled ? "link" : "link-disabled"}>
                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "16px" }}></span></button>
                    </Tooltip>

                    <Divider type="vertical" />
                </>
            }
            <button title={!isDeleteEnabled ? CONFIG.warningMessages.noAccess : undefined} disabled={!isDeleteEnabled} className={isDeleteEnabled ? "link" : "link-disabled"} onClick={
                () => {
                    props.handleDelete(selectedEvent?.schedule, true);
                }}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "16px" }}></span></button>
            {!deleteView &&
                <>
                    <Divider type="vertical" />
                    <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={selectedEvent?.schedule?.id}>
                        <button disabled={!isEditEnabled}
                            onClick={async () => {
                                await dispatch(handleOnClickAction({
                                    component: CONFIG.cloneComponentRoute.scheduleMaintenance,
                                    tabTitle: selectedEvent?.schedule?.title,
                                    recordId: selectedEvent?.schedule?.id,
                                    isClone: true
                                }
                                ))
                            }}
                            className={isEditEnabled ? "link" : "link-disabled"}>
                            <span class="neo-icon-copy" title="Clone" style={{ fontSize: "16px" }}></span></button>
                    </Tooltip>
                </>
            }
        </div> : <>
            <Tooltip title={!isEditEnabled ? CONFIG.warningMessages.noAccess : undefined} key={selectedEvent?.schedule?.id}>
                <button disabled={!isEditEnabled}
                    onClick={async () => {
                        await dispatch(handleOnClickAction({
                            component: CONFIG.cloneComponentRoute.scheduleMaintenance,
                            tabTitle: selectedEvent?.schedule?.title,
                            recordId: selectedEvent?.schedule?.id,
                            isClone: true
                        }
                        ))
                    }}
                    className={isEditEnabled ? "link" : "link-disabled"}>
                    <span class="neo-icon-copy" title="Clone" style={{ fontSize: "16px" }}></span></button>
            </Tooltip>
        </> : null;

        return ((selectedItem || viewType === "agenda") ? <React.Fragment>
            <div className='font' style={{ fontSize: "12px", fontWeight: "600", color: "black", fontFamily: 'Noto Sans', }}>{data.title} <span style={{ marginLeft: "10px" }}>  {actions}</span> </div>
            <div className="md-custom-event-cont font">
                <div style={{ fontSize: "12px" }}>
                    <div style={{ marginRight: "5px" }}>{start} - {end}</div>
                    {
                        (maintenanceRelations?.length > 0 && viewType === "agenda") ? <Popover
                            content={content}
                            trigger="hover"
                            style={{ zIndex: "100" }}
                        >
                            <span className="small-text"> <Icon type="info-circle" className="text-color-primary" width={1} /></span>
                        </Popover>
                            :
                            content
                    }
                </div>
            </div>
        </React.Fragment> : <div className='display-none'></div>);
    }

    const customWithNavButtons = () => {
        return <React.Fragment>
            <MonthPicker onChange={handleDateChange}
                value={moment(calendarDate)}
                allowClear={false}
                format="MMM-YYYY"
                className="cal-header-nav" />
            <div className="md-event-listing-picker">
                <span style={{ fontSize: "14px" }}>Selected View &nbsp;</span>
                <Select value={viewType} style={{ width: 120 }} onChange={handleViewChange}>
                    {renderViewOptions()}
                </Select>
            </div>
            <div style={{ float: "right" }} className="md-custom-range-view-controls">
                <span style={{ width: "50px" }}><CalendarPrev className="calendar-prev" /></span>
                <span className='calendar-today' ><CalendarToday /></span>
                <span><CalendarNext className='calendar-next' /></span>
            </div>
        </React.Fragment >;
    }

    const handleEventClick = (event) => {
        setSelectedEvent(event.event);
    };

    const renderLabel = (data) => {
        const bgColor = data.original.color?.replace('rgb', 'rgba').replace(')', `/ 30%)`);
        return <div style={{ background: bgColor }} className="multi-day-event">{data.original.title}</div>
    }

    return (
        <div className="vertical-spacing">
            <Spin spinning={loading} >
                <Eventcalendar
                    dataTimezone="utc"
                    displayTimezone={timeZone}
                    timezonePlugin={momentTimezone}
                    renderHeader={customWithNavButtons}
                    dateFormatLong="DDDD, MMMM D, YYYY"
                    theme="ios"
                    themeVariant="light"
                    view={view}
                    clickToCreate={false}
                    dragToCreate={false}
                    dragToMove={false}
                    dragToResize={false}
                    eventDelete={false}
                    renderEventContent={viewType === "month" ? renderEventContent : renderAgendaEvent}
                    onEventClick={handleEventClick}
                    data={data}
                    renderLabel={renderLabel}
                    onSelectedDateChange={onSelectedDateChange}
                    selectedDate={calendarDate}
                    cssClass="md-event-listing md-resource-details custom-event-popover font"
                />
            </Spin>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        timeZone: state.user.profile.timezone,
        user: state.user.profile,
    };
}
export default connect(mapStateToProps)(ScheduleMaintenanceCalendar)

