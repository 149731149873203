import React from 'react';
import { Form, Input, Row, Rate, Descriptions, Divider, Result, Spin } from 'antd';
import CONFIG from '../../config';
import { connect, batch } from "react-redux";
import moment from 'moment-timezone';
import FormActionButtons from '../common/FormActionButtons';

import {
    selectTitle,
    toggleBackButton,
    postScheduleFeedback,
    getScheduleFeedbackByScheduleId,
    clearDemoSchedules,
    isFetchingScheduleFeedback,
    getScheduleFeedbackAssignedQuestions,
    closeCurrentOpenTab
}
    from '../../actions';

const { TextArea } = Input;
class DemoFeedbackForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showSubmittedView: true,
            isRedirectedFromEmail: !!localStorage.getItem('redirectToFeedbackForm')
        }

    }


    componentDidMount() {
        const { scheduleFeedbackId } = this.props.match.params
        batch(async () => {
            await this.props.isFetchingScheduleFeedback(true);
            await this.props.getScheduleFeedbackAssignedQuestions();
            await this.props.getScheduleFeedbackByScheduleId(scheduleFeedbackId ?? localStorage.getItem('scheduleFeedbackId'))
            this.props.selectTitle("Feedback Form");
        });
    }


    componentWillUnmount() {
        if (localStorage.getItem('scheduleFeedbackId')) localStorage.removeItem('scheduleFeedbackId');
        if (localStorage.getItem('redirectToFeedbackForm')) localStorage.removeItem('redirectToFeedbackForm')
        this.props.clearDemoSchedules()
    }


    handleSubmit = () => {
        const { form } = this.props;
        const { scheduleFeedbackId, isModifyRating } = this.props.match.params
        this.props.form.getFieldValue('overallRating') === 0 && this.props.form.setFieldsValue({ overallRating: undefined })
        form.validateFields((err, values) => {
            if (err) return;
            else this.props.postScheduleFeedback(
                values,
                !!localStorage.getItem('redirectToFeedbackForm') ? localStorage.getItem('scheduleFeedbackId') : scheduleFeedbackId,
                isModifyRating !== undefined && JSON.parse(isModifyRating))
        });
    }

    renderScheduleDetails = () => {
        const { timeZoneName, scheduleFeedback } = this.props;
        return (
            <Descriptions className="demo-approvers-details" title="Please rate your overall experience for the demo listed below that you recently performed:">
                <Descriptions.Item span={4} label="Demo Name">{scheduleFeedback?.demo?.title}</Descriptions.Item>
                <Descriptions.Item span={4} label="Demo was scheduled for">{moment.tz(scheduleFeedback?.startTime, timeZoneName).format(CONFIG.dateFormats.calendar)} -  {moment.tz(scheduleFeedback?.endTime, timeZoneName).format(CONFIG.dateFormats.calendar)}</Descriptions.Item>
            </Descriptions>
        )
    }

    renderFeedbackSubmitted = () => {
        return (
            <>
                <Result
                    status="success"
                    title="We have already received your feedback for this schedule!"
                />
            </>
        )
    }

    renderFeedbackFallbackUI = (isExpired) => {
        return (<>
            <Result
                status="warning"
                title={isExpired ? "Sorry, this feedback link has now expired." : "Sorry, You are no longer authorized to view this item or this item has been removed."}
            />
        </>)
    }


    getAssignedFeedbackQuestions = () => {
        const { user } = this.props;
        const { scheduleParticipants, hasParticipants, questionRatings } = this.props.scheduleFeedback;
        const currentUser = scheduleParticipants?.find((eachUser) => eachUser?.eapUser?.id === user?.id);
        const ratingsOfQuestion = hasParticipants ? currentUser?.questionRatings : questionRatings
        return this.props.assignedQuestions?.map(item => {
            return ({
                id: item?.id,
                title: item?.title,
                intialRating: ratingsOfQuestion?.find(o => o?.question?.id === item?.id)?.rating || 0
            })
        })
    }


    renderAssignedFeedbackQuestions = () => {
        const { getFieldDecorator } = this.props.form;
        const ratingTooltip = ['Very Unsatisfied', 'Unsatisfied', 'Neutral', 'Satisfied', 'Very Satisfied']
        const assignedFeedbackQuestions = this.getAssignedFeedbackQuestions()
        return (
            assignedFeedbackQuestions?.map(eachQuestion => {
                return <Form.Item label={eachQuestion.title}>
                    {getFieldDecorator(`${eachQuestion?.id}`, { initialValue: eachQuestion?.intialRating })(<Rate style={{ fontSize: 36 }} tooltips={ratingTooltip} defaultValue={0} />)}
                </Form.Item>
            }))
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { fromDashboard, scheduleFeedback, isFetchingFeedback, user } = this.props;
        const { isOutOfRatingWindow, scheduleParticipants, hasParticipants, scheduleComments } = scheduleFeedback;
        const currentUser = hasParticipants ? scheduleParticipants?.find((eachUser) => eachUser?.eapUser?.id === user?.id) : null;
        const { isModifyRating } = this.props.match.params;
        const ratingTooltip = ['Very Unsatisfied', 'Unsatisfied', 'Neutral', 'Satisfied', 'Very Satisfied']
        const isFeedbackLinkExpired = !isFetchingFeedback && isOutOfRatingWindow
        const showAlreadyRatedMessage = this.state.isRedirectedFromEmail
            && (hasParticipants
                ? currentUser?.scheduleComment !== null
                && currentUser?.scheduleComment?.rating !== 0
                : scheduleComments?.length > 0 && scheduleComments?.[0]?.rating !== 0)
        const showFeedbackExpiredPage = !isFetchingFeedback && showAlreadyRatedMessage && scheduleFeedback?.id !== undefined && this.state.showSubmittedView
        const showFallbackPage = this.props.scheduleFeedback?.id === undefined && !isFetchingFeedback

        return (
            <div className={!fromDashboard && "content-container"}>
                <Spin spinning={isFetchingFeedback}>
                    {(isFeedbackLinkExpired && !isFetchingFeedback) ?
                        this.renderFeedbackFallbackUI(true)
                        : showFeedbackExpiredPage
                            ? this.renderFeedbackSubmitted()
                            : (showFallbackPage && !isFetchingFeedback) ?
                                this.renderFeedbackFallbackUI(false)
                                : <div>
                                    {this.renderScheduleDetails()}
                                    <Divider />
                                    <Form>
                                        <Row>
                                            {this.renderAssignedFeedbackQuestions()}
                                        </Row>
                                        <Row>
                                            <Form.Item label="On a scale of 1-5, how satisifed are you with the overall demo experience?">
                                                {getFieldDecorator("overallRating", {
                                                    rules: [
                                                        { required: true, message: "Please provide a rating." },
                                                    ],
                                                    initialValue: hasParticipants ? currentUser?.scheduleComment?.rating : scheduleComments?.length > 0 && scheduleComments[0]?.rating,
                                                })(<Rate style={{ fontSize: 36 }} tooltips={ratingTooltip} />)
                                                }
                                            </Form.Item>
                                        </Row>
                                        <Form.Item
                                            className='feedback-note-form-item'
                                            label={<span style={{ textAlign: 'left', display: 'list-item' }}><b>Provide any comments regarding your experience with the demo:</b>
                                                <br />NOTE: Any issues with the demo requires a support ticket to be raised at <a rel="noopener noreferrer" href="https://onecare.avaya.com/customercare/en/home/" target="_blank">https://onecare.avaya.com/customercare/en/home/</a> and select "Experience Avaya Support" tile</span>}>
                                            {getFieldDecorator("comment", {
                                                // initialValue: scheduleComments?.filter(comment => comment?.isReply === false)?.[0]?.comment,
                                                initialValue: hasParticipants ? currentUser?.scheduleComment?.comment : scheduleComments?.filter(comment => comment?.isReply === false)?.[0]?.comment,

                                                rules: [
                                                    { required: false },
                                                ]
                                            }
                                            )(<TextArea rows={4} />)
                                            }
                                        </Form.Item>
                                        <Row className="vertical-spacing right-align">
                                            <FormActionButtons handleSubmit={this.handleSubmit} handleCancel={() => this.props.closeCurrentOpenTab()} okText={(isModifyRating !== undefined && JSON.parse(isModifyRating)) ? "Update" : 'Submit'} />
                                        </Row>
                                    </Form>
                                </div >}
                </Spin>
            </div >
        );
    }
}
const mapStateToProps = ({ user, demoSchedules, header }) => {
    return {
        timeZoneName: user.profile ? user.profile.timezone : '',
        scheduleFeedback: demoSchedules.scheduleFeedback,
        isFetchingFeedback: demoSchedules.isFetchingFeedback,
        assignedQuestions: demoSchedules.scheduleFeedbackAssignedQuestions,
        user: user.profile,
        previousPath: header.previousPath
    };
};

const DemoFeedbackFormRef = Form.create()(DemoFeedbackForm);

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        postScheduleFeedback,
        getScheduleFeedbackByScheduleId,
        clearDemoSchedules,
        isFetchingScheduleFeedback,
        getScheduleFeedbackAssignedQuestions,
        closeCurrentOpenTab
    }
)(DemoFeedbackFormRef);