import React from 'react';
import { FloatButton } from 'antd5';
import { QuestionCircleOutlined } from '@ant-design/icons';

const SchedulerHelpButton = () => (
    <div className='scheduler-help'> 
    <FloatButton icon = {<QuestionCircleOutlined/>} type="primary" style={{ right: 24 }} badge={{ dot: true }} tooltip="Help"
    href='https://ez.experience.avaya.com/player/?demoId=56b4a731-84b2-4c7d-96bf-2c434d464030&showGuide=true&showGuidesToolbar=true&showHotspots=true&source=app'
    target="_blank" />
    </div>
);

export default SchedulerHelpButton;