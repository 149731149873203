import {
    UPDATE_DEMO_REQUEST_EDITING_KEY,
    CLEAR_DEMO_REQUEST_DATA,
    TOGGLE_IS_FETCHING_DEMO_REQUEST_USER_STATUS,
    LAST_DEMO_REQUEST_FILTER,
    UPDATE_DEMOS_REQUEST_CURRENT_PAGE,
    FETCH_DEMO_REQUEST,
    FETCH_ALL_DEMO_REQUESTS,
    FETCH_USER_DEMO_REQUEST,
    TOGGLE_IS_FETCHING_DEMO_REQUESTS
} from "../actions/types";

const demoRequestState = {
    currentPage: 1,
    editingKey: "",
    isFetchingUser: false,
    isEdit: false,
    users: [],
    demoRequests: [],
    demoRequest: [],
    currentPageNumber: undefined,
    currentRequestId: undefined,
    demoRequestCount: 0
}

export const demoRequestReducer = (state = demoRequestState, action) => {
    switch (action.type) {
        case UPDATE_DEMO_REQUEST_EDITING_KEY:
            return { ...state, editingKey: action.payload };
        case FETCH_ALL_DEMO_REQUESTS:
            return {
                ...state,
                demoRequests: action?.payload || [],
                demoRequestCount: action.payload?.count || 0
            };
        case FETCH_DEMO_REQUEST:
            return { ...state, demoRequest: action.payload };
        case TOGGLE_IS_FETCHING_DEMO_REQUEST_USER_STATUS:
            return { ...state, isFetchingUser: action.payload }
        case TOGGLE_IS_FETCHING_DEMO_REQUESTS:
            return { ...state, isFetchingDemoRequests: action.payload }
        case LAST_DEMO_REQUEST_FILTER:
            return { filters: action.payload[0], status: action.payload[1], isEdit: true }
        case UPDATE_DEMOS_REQUEST_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentRequestId: action.payload[2] || undefined, requestIndex: action.payload[3] || undefined };
        case FETCH_USER_DEMO_REQUEST:
            return { ...state, users: action.payload.dataSet || [] }
        case CLEAR_DEMO_REQUEST_DATA:
            return { ...demoRequestState, filters: {}, isEdit: false };
        default:
            return state;
    }
}