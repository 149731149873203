import React from 'react';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { login, forgotPassword } from '../actions';
import CONFIG from "../config";
import { Typography } from 'antd';
import pushMessage from './common/PushMessage';
import { Button, Icon as NeoIcon } from "@avaya/neo-react"
// import ssoLogin from "../assets/images/Avaya-SSO.png";
// import moment from 'moment-timezone';
const { Text } = Typography;


class LoginForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isForgotPasswordVisible: false,
            resetEmailSentModalVisible: false,
            enteredEmailId: undefined,
        }
    }

    handleLogInSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                await this.props.login(undefined, values.email, values.password);
                if (this.props.isLoginComplete) this.props.loginComplete();
            }
        });
    };


    obfuscateEmail = (email) => {
        const atIndex = email.indexOf('@');

        if (atIndex > 0 && atIndex < email.length - 9) { // Minimum length of 10 characters for "@betsol.com"
            const username = email.substring(0, atIndex);
            const domain = email.substring(atIndex + 1);

            if (username.length >= 3) {
                const obfuscatedUsername = username[0] + username[1] + '*'.repeat(username.length - 3) + username[username.length - 1];
                return obfuscatedUsername + '@' + domain;
            }
        }
        // Return the original email if it doesn't match the expected format
        return email;
    }

    handleForgotPasswordSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.forgotPassword(values.email)
                    .then(() => {
                        this.setState({
                            enteredEmailId: this.obfuscateEmail(this.props.form.getFieldValue('email')),
                            isForgotPasswordVisible: false,
                            resetEmailSentModalVisible: true
                        })
                    })
                    .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
            }
        });
    };

    renderForgotPasswordModal = () => {
        this.props.setModalTitle('Reset Password')
        this.setState({ isForgotPasswordVisible: true });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        // const plainText = moment().utc().add(process.env.REACT_APP_LOGIN_AES_SALT, 'm').format(CONFIG.dateFormats.scheduler);
        // const id = encrypt(plainText);
        // const encodedUrl = encodeURIComponent(id);
        // const url = `${process.env.REACT_APP_SAML_LOGIN}?id=${encodedUrl}`
        if (!this.state.resetEmailSentModalVisible) {
            return (
                <Form
                    onSubmit={this.state.isForgotPasswordVisible ? this.handleForgotPasswordSubmit : this.handleLogInSubmit}
                    className="login-form">
                    <Form.Item
                        label={<span style={{ fontSize: "13px" }}>Email</span>}
                        extra={this.state.isForgotPasswordVisible ? CONFIG.myProfile.defaultEmailInfo : undefined}>
                        {getFieldDecorator('email', {
                            rules: [{ required: true, message: <span style={{ fontSize: "12px" }}>Please input your Email!</span> }],
                        })(<Input
                            prefix={<NeoIcon icon="email" className="login-form-icons" />}
                        // type="email"
                        />)}

                    </Form.Item>
                    {(!this.state.isForgotPasswordVisible) && <Form.Item
                        style={{ marginTop: "10px" }}
                        label={<span style={{ fontSize: "13px" }}>Password</span>}
                    >
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: <span style={{ fontSize: "12px" }}>Please input your Password!</span> }],
                        })(<Input.Password prefix={<NeoIcon icon="lock" className="login-form-icons" />} />)}
                    </Form.Item>}
                    <Form.Item className='bottom-spacing-5'>
                        <Button type="primary" htmlType="submit" id={this.state.isForgotPasswordVisible ? "reset-login-password" : "submit-login-form"} className="login-form-button">{this.state.isForgotPasswordVisible ? 'Reset Password' : 'Login'}</Button>
                    </Form.Item>
                    {!this.state.isForgotPasswordVisible && <div className='center-align' >
                        <span className=" center-align login-form-forgot-password" id="forgot-password" onClick={this.renderForgotPasswordModal}>Forgot Password?</span>
                        {/* <div className='bottom-spacing-5 top-spacing-5'>Or sign-in with SSO</div>
                        <div><Button className='sso-login-button' onClick={() => window.open(url, "_self")} animation="none" size="compact" status="default" variant="secondary" ><img className="sso-login" alt="sso-login" src={ssoLogin} /></Button></div> */}
                    </div>}
                </Form>
            )
        }

        else if (this.state.resetEmailSentModalVisible) {
            return (
                <Form onSubmit={() => this.props.loginComplete()} className="login-form">
                    <Text strong type="success" style={{ fontSize: "16px" }}>{CONFIG.myProfile.resetEmailLinkSent.replace('<placeholder>', this.state.enteredEmailId)}</Text>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" style={{ marginTop: "30px" }}>Close</Button>
                    </Form.Item>
                </Form>
            )
        }
    }
}

const mapStateToProps = ({ user }) => { return { isLoginComplete: user.isLoginComplete }; };

const LocalUserLoginForm = Form.create({ name: 'login_form' })(LoginForm);

export default connect(mapStateToProps, { login, forgotPassword })(LocalUserLoginForm);
