import { batch } from 'react-redux';
import { aepDemo, aepSchedule } from "../apis";
import {
    FETCH_DEMO_QUESTIONS,
    UPDATE_DEMO_QUESTIONS_ROLE_TEMPLATE,
    TOGGLE_IS_FETCHING_DEMO_QUESTIONS,
    CLEAR_DEMO_QUESTIONS_FORM,
    FETCH_DEMO_QUESTION_DETAILS,
    CLEAR_DEMO_QUESTIONS,
    LAST_DEMO_QUESTIONS_FILTER,
    ADD_NEW_ADDITIONAL_INFO_QUESTION,
    ADD_NEW_DEMO_PRE_REG_QUESTION,
    DELETE_DEMO_PRE_REG_QUESTION_ASSOCIATION,
    DELETE_DEMO_ADDITIONAL_INFO_QUESTION_ASSOCIATION,
    CLEAR_DEMO_QUESTION_RESPONSE,
    LAST_DEMO_QUESTION_FILTER,
    CLEAR_DEMO_QUESTIONS_TABLE_DATA,
    UPDATE_DEMO_QUESTIONS_FORM_TAB_DATA,
    FETCH_ALL_DEMO_QUESTION,
    FETCH_DEMO_FEEDBACK_QUESTIONS,
}
    from './types';
import { getUpdatedRoleTemplateAssociations } from "./roleTemplates";
import { toggleIsFetchingFeedbackQuestions } from './feedbackQuestions';
import pushMessage from '../components/common/PushMessage';
import CONFIG from '../config';
import { closeCurrentOpenTab } from './tabsLayout';

export const fetchAllDemoQuestions = ({ pageNumber = 1, pageSize = 12, filterSort, status, getAll = false, access }) => async dispatch => {
    const contextPath = '/scheduleMaintenanceService/questions'
    const response = getAll
        ? await aepSchedule.get(contextPath,
            {
                params: {
                    access, filterSort: {
                        ...filterSort,
                        filter: {
                            ...filterSort?.filter,
                            isActive: filterSort?.filter?.viewDeletedItems ? undefined : status,
                            questionType: "demo"
                        }
                    }
                }
            })
        : await aepSchedule.get(contextPath,
            {
                params: {
                    access,
                    pageSize,
                    pageNumber,
                    filterSort: {
                        ...filterSort,
                        filter: {
                            ...filterSort?.filter,
                            isActive: filterSort?.filter?.viewDeletedItems ? undefined : status,
                            questionType: "demo"
                        }
                    }
                }
            })

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (pageNumber !== 1 && !response.data) {
            this.props.toggleIsFetchingDemoQuestions(true)
            dispatch(fetchAllDemoQuestions({ pageNumber: pageNumber - 1, filterSort, status }));
            return;
        }
        else dispatch({ type: getAll ? FETCH_ALL_DEMO_QUESTION : FETCH_DEMO_QUESTIONS, payload: response.data?.dataSet || [] });
    }
    else {
        dispatch({
            type: CLEAR_DEMO_QUESTION_RESPONSE,
            payload: response.status
        })
        pushMessage(CONFIG.messageType.error, 'Unable to fetch demo questions');
    }
    dispatch(toggleIsFetchingDemoQuestions(false));
    return response.data ? response.data.dataSet : [];
}

export const fetchLiteDemoQuestionsOrFeedbackQuestions = ({ getAll = false, filterSort, status, questionType }) => async dispatch => {
    const contextPath = '/scheduleMaintenanceService/v1/lite/questions';
    const response = await aepSchedule.get(contextPath,
        {
            params: {
                filterSort: {
                    ...filterSort,
                    filter: {
                        ...filterSort?.filter,
                        isActive: filterSort?.filter?.viewDeletedItems ? undefined : status ? status === "true" ? true : status === "false" ? false : undefined : undefined,
                        questionType: questionType
                    }
                }
            }
        });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        questionType === "demo" ?
            dispatch({
                type: getAll
                    ? FETCH_ALL_DEMO_QUESTION
                    : FETCH_DEMO_QUESTIONS,
                payload: response.data || []
            })
            :
            dispatch({
                type: FETCH_DEMO_FEEDBACK_QUESTIONS,
                payload: response?.data || []
            });
    }
    else {
        pushMessage(CONFIG.messageType.error, `Unable to fetch ${questionType === "demo" ? "demo" : "feedback"} questions`);
    }
    questionType === "demo" ? dispatch(toggleIsFetchingDemoQuestions(false)) : dispatch(toggleIsFetchingFeedbackQuestions(false));
    return response.data || [];
}

export const getAllDemoQuestions = async () => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/questions", { params: { filterSort: { "filter": { "questionType": "demo" } } } });
    return response.data ? response.data.dataSet : [];
}

export const fetchDemoQuestionById = (questionId) => async (dispatch, getState) => {
    const response = await aepSchedule(`/scheduleMaintenanceService/questions/${questionId}`);
    let questionDetails;
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { roleTemplates, user } = getState();
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const demoQuestion = response.data;
        questionDetails = {
            ...response.data,
            roleTemplates: isAdmin ? demoQuestion.roleTemplates.filter(questionRoleTemplate => allRoleTemplates.some(roleTemplate => questionRoleTemplate.roleTemplate.id === roleTemplate.id)) : demoQuestion.roleTemplates,
            assignedRoleTemplates: isAdmin ? demoQuestion.roleTemplates.filter(questionRoleTemplate => allRoleTemplates.some(roleTemplate => questionRoleTemplate.roleTemplate.id === roleTemplate.id)) : demoQuestion.roleTemplates
        };
        batch(() => {
            dispatch({
                type: FETCH_DEMO_QUESTION_DETAILS,
                payload: questionDetails
            })
            dispatch(updateDemoQuestionsFormTabData({ questionId, questionDetails, fetchFromAPI: true }));
        })
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch Demo Question");
    return questionDetails || []
}

export const updateDemoQuestionsFormTabData = ({ questionId, questionDetails, fetchFromAPI = false }) => (dispatch, getState) => {
    var formTabData = {};
    if (fetchFromAPI) {
        formTabData = {
            id: parseInt(questionId),
            data: questionDetails,
            originalData: questionDetails
        }
    }
    else {
        const state = getState();
        const roleTemplates = state.demoQuestionsForm?.tabData.find(data => ((parseInt(data?.id) === parseInt(questionId))))?.data?.roleTemplates;
        const assignedRoleTemplates = state.demoQuestionsForm?.tabData.find(data => ((parseInt(data?.id) === parseInt(questionId))))?.data?.assignedRoleTemplates;
        let requestData = []
        formTabData = {
            id: parseInt(questionId),
            data: {
                ...requestData,
                ...questionDetails,
                roleTemplates: roleTemplates,
                assignedRoleTemplates: assignedRoleTemplates
            },
            originalData: questionDetails?.originalData
        }
    }
    dispatch({
        type: UPDATE_DEMO_QUESTIONS_FORM_TAB_DATA,
        payload: formTabData
    })
    dispatch(toggleIsFetchingDemoQuestions(false));

}

export const postDemoQuestion = (updatedQuestion, questionId) => async (dispatch, getState) => {
    const roleTemplates = getState().demoQuestionsForm?.tabData.find(data => ((parseInt(data?.id) === parseInt(questionId))))?.data?.roleTemplates;
    const questionObj = {
        id: questionId !== "-1" ? questionId : undefined,
        title: updatedQuestion.title,
        answers: updatedQuestion?.answers?.map(ans => ({ title: ans })),
        roleTemplates: roleTemplates,
        questionType: "demo",
        isMandatory: updatedQuestion?.isMandatory ? true : false,
        answerType: updatedQuestion?.answerType,
        isActive: updatedQuestion?.isActive,
    }


    const contextPath = '/scheduleMaintenanceService/questions'
    const response = await aepSchedule({
        url: questionId === undefined || questionId === "-1" ? contextPath : `${contextPath}/${questionId}`,
        method: questionId === undefined || questionId === "-1" ? 'post' : 'put',
        data: questionObj,
    });
    const successMessage = questionId !== "-1" ? "Demo Question updated successfully" : "Demo Question created successfully";
    const errorMessage = questionId !== "-1" ? "Unable to update Demo Question" : "Unable to create Demo Question";
    if (response.status === CONFIG.HTTP_STATUS.CREATED || response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, successMessage)
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingDemoQuestions(false));
    dispatch(closeCurrentOpenTab());
};

export const deleteDemoQuestion = async (questionId, hardDelete = false) => {
    const response = await aepSchedule.delete("/scheduleMaintenanceService/questions/" + questionId, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Demo question deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete demo question");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
};

export const restoreDemoQuestion = async (questionId) => {
    const response = await aepSchedule.patch("/scheduleMaintenanceService/restore/questions/" + questionId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Demo question restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore demo question");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const fetchDemoQuestionsForRoleTemplate = ({ pageNumber = 1, pageSize = CONFIG.shortPageSize + 1, access, filterSort }) => async (dispatch) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/questions", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            access,
            filterSort
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (pageNumber !== 1 && !response.data) {
            dispatch(fetchDemoQuestionsForRoleTemplate({ pageNumber: pageNumber - 1 }));
            return;
        }
        else dispatch({ type: FETCH_DEMO_QUESTIONS, payload: [response.data, response.status] || [] });
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch demo demo questions');
    }
    dispatch(toggleIsFetchingDemoQuestions(false));
    return response.data || []
}

export const postPreRegistrationForm = (values, demoId) => async () => {
    let userAnswers = values.map(obj => ({
        question: { id: parseInt(obj.questionId) },
        questionOptions: obj.questionOptions,
        answerText: Array.isArray(obj?.answerText) ? obj?.answerText.join('//') : obj?.answerText
    }))

    const contextPath = `/demoSolutionService/demos/${demoId}/request`
    const response = await aepDemo({
        method: 'post',
        url: contextPath,
        data: { userAnswers }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) pushMessage(CONFIG.messageType.success, `Demo request submitted successfully!`);
    else pushMessage(CONFIG.messageType.error, `Failed to submit demo request!`);
}

export const putPreRegistrationForm = (values, requestId) => async () => {
    let userAnswers = values.map(obj => ({
        question: { id: parseInt(obj.questionId) },
        questionOptions: obj.questionOptions,
        answerText: Array.isArray(obj?.answerText) ? obj?.answerText.join('//') : obj?.answerText
    }))

    const contextPath = `/demoSolutionService/demo/request/${requestId}`
    const response = await aepDemo({
        method: 'put',
        url: contextPath,
        data: {
            id: requestId,
            userAnswers
        }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) pushMessage(CONFIG.messageType.success, `Demo request updated successfully!`);
    else pushMessage(CONFIG.messageType.error, `Failed to update demo request!`);
}

export const toggleIsFetchingDemoQuestions = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DEMO_QUESTIONS,
        payload: flag
    };
};


export const clearDemoQuestions = () => {
    return {
        type: CLEAR_DEMO_QUESTIONS
    }
}

export const clearDemoQuestionsForm = (id) => {
    return {
        type: CLEAR_DEMO_QUESTIONS_FORM,
        payload: { id }
    }
}

export const updateDemoQuestionsRoleTemplate = (access, accessType, roleType, selectedRoleTemplate, questionId) => async (dispatch, getState) => {
    const { demoQuestionsForm, roleTemplates } = getState();
    const allRoleTemplates = roleTemplates.all;
    const accessKey = roleType === CONFIG.roleTypes.user ? "allRoleTemplatesUserAccess" : "allRoleTemplatesAdminAccess";
    let demoQuestionInfo = demoQuestionsForm?.tabData.find(data => parseInt(data?.id) === parseInt(questionId))
    let assignedRoleTemplates = demoQuestionInfo?.data?.roleTemplates;
    let updatedRoleTemplates = getUpdatedRoleTemplateAssociations(
        access,
        roleType,
        accessType,
        selectedRoleTemplate,
        assignedRoleTemplates,
        "roleTemplate",
        allRoleTemplates,
        false
    );
    dispatch(
        {
            type: UPDATE_DEMO_QUESTIONS_ROLE_TEMPLATE,
            payload: {
                updatedRoleTemplates,
                selectedRoleTemplate,
                accessType,
                access,
                accessKey,
                questionId
            },
        });
};

export const currentDemoQuestionsFilter = (filterSort, active, pageNumber) => {
    return {
        type: LAST_DEMO_QUESTIONS_FILTER,
        payload: [filterSort, active, pageNumber]
    }
}

export const currentDemoQuestionFilter = (filters, status) => {
    return {
        type: LAST_DEMO_QUESTION_FILTER,
        payload: [filters, status]
    }
}

// Actions for Demo Form demo-question assigning.
export const addNewDemoQuestions = (demoId, question, questionType) => {
    return {
        type: questionType === CONFIG.demoQuestionTypes?.[0]?.value
            ? ADD_NEW_ADDITIONAL_INFO_QUESTION
            : ADD_NEW_DEMO_PRE_REG_QUESTION,
        payload: { demoId, question }
    };
};

export const deleteDemoQuestionAssociation = (questionId, type) => {
    return {
        type: type === CONFIG.demoQuestionTypes?.[0]?.value ? DELETE_DEMO_ADDITIONAL_INFO_QUESTION_ASSOCIATION : DELETE_DEMO_PRE_REG_QUESTION_ASSOCIATION,
        payload: questionId
    };
};

export const clearDemoQuestionsTableData = () => {
    return {
        type: CLEAR_DEMO_QUESTIONS_TABLE_DATA
    };
};


