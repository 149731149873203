import { batch } from 'react-redux';

import { aepNetwork } from '../apis';
import {
    FETCH_DIDS,
    UPDATE_SELECTED_DIDS_DATACENTER,
    UPDATE_DIDS_CURRENT_PAGE,
    TOGGLE_IS_FETCHING_DIDS,
    UPDATE_DID_EDITING_KEY,
    CLEAR_DIDS,
    FETCH_NETWORK_ENVIRONMENTS,
    UPDATE_DID_UPLOAD_CRITERIA,
    UPDATE_DIDS_SELECTED_ROW_KEYS,
    TOGGLE_IS_UPLOADING_DOWNLOADING_FLAG,
    CLEAR_DIDS_TABLE_DATA,
    ADD_DID,
    REMOVE_EMPTY_DIDS,
    FETCH_PROVIDERS,
    UPDATE_PROVIDER_EDITING_KEY,
    ADD_PROVIDER,
    REMOVE_EMPTY_PROVIDER,
    CLEAR_DIDS_RESPONSE,
    TOGGLE_IS_COLLAPSED_STATUS,
    CURRENT_DIDS_FILTER
} from './types';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { DIDImportErrorNotification } from '../components/common/PushNotification';
import { download } from '../utils/file';


export const addDID = didId => {

    const did = {
        id: didId,
        did: ""
    };
    return {
        type: ADD_DID,
        payload: did
    };
};

export const fetchDIDs = ({ dataCenter, pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, didId }) => async dispatch => {
    const params = {
        pageNumber,
        pageSize,
        filterSort
    };
    const response = await aepNetwork.get(`/networkManagementService/dids/${dataCenter.id}`, { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            const data = response.data ? response.data.dataSet : undefined
            if (data) {
                if (pageNumber !== 1 && data.length === 0) {
                    dispatch(fetchDIDs({ dataCenter, pageNumber: pageNumber - 1, filterSort }));
                    return;
                }
            }
            dispatch({
                type: FETCH_DIDS,
                payload: { dataSet: response.data ? response.data.dataSet : [], count: response.data ? response.data.count : undefined, response: response.status }
            });
        });
    }
    else {
        dispatch({ type: CLEAR_DIDS_RESPONSE, payload: response.status })
        pushMessage(CONFIG.messageType.error, 'Unable to fetch DIDs');
    }
    if (didId) {
        dispatch(addDID(didId));
    }
    dispatch(toggleIsFetchingDids(false));
};

export const toggleIsFetchingDids = (flag) => async dispatch => {
    dispatch({
        type: TOGGLE_IS_FETCHING_DIDS,
        payload: flag
    });
};

export const updateSelectedDidsDataCenter = (dataCenter) => {
    return {
        type: UPDATE_SELECTED_DIDS_DATACENTER,
        payload: dataCenter
    }
};

export const updateDidsCurrentPage = ({ pageNumber = 1, dataCenter, filterSort, didId }) => (dispatch) => {

    batch(() => {
        dispatch({
            type: UPDATE_DIDS_CURRENT_PAGE,
            payload: pageNumber
        });
        dispatch(clearDidsTableData());
        dispatch(toggleIsFetchingDids(true));
        if (didId) {
            dispatch(fetchDIDs({ dataCenter, pageNumber, filterSort, didId }));
        }
        else {
            dispatch(fetchDIDs({ dataCenter, pageNumber, filterSort }));
            dispatch(updateDidEditingKey());
        }
    });

};

export const updateDidEditingKey = (key = "") => {

    return {
        type: UPDATE_DID_EDITING_KEY,
        payload: key
    };
};

export const removeEmptyDids = () => (dispatch) => {
    batch(() => {
        dispatch({
            type: REMOVE_EMPTY_DIDS
        });
        dispatch(updateDidEditingKey())
    });
};

export const clearDids = () => ({ type: CLEAR_DIDS })

export const clearDidsTableData = () => {
    return { type: CLEAR_DIDS_TABLE_DATA }
}

export const fetchNetworkEnvironments = () => async dispatch => {
    const response = await aepNetwork.get('/networkManagementService/networks/environments');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_NETWORK_ENVIRONMENTS,
            payload: response.data ? response.data : []
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch environments');
}

export const exportDIDs = (fileType, filterSort) => async (dispatch, getState) => {

    const dataCenter = getState().dids.dataCenter;
    const response = await aepNetwork({
        url: `/networkManagementService/export/dids/${fileType}?dataCenterId=${dataCenter.id}`,
        method: 'GET',
        responseType: 'blob',
        params: {
            filterSort
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(`DIDs.${fileType}`, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export DIDs");
    dispatch(toggleIsUploadingOrDownloading(false));
}

export const updateDIDUploadCriteria = (uploadType) => {
    return {
        type: UPDATE_DID_UPLOAD_CRITERIA,
        payload: uploadType
    };
}

export const importDIDs = (file) => async (dispatch, getState) => {
    const { dataCenter, uploadType } = getState().dids;
    const formData = new FormData();
    formData.append('didsInExcel', file);
    if (uploadType !== undefined && dataCenter !== undefined) {
        dispatch(toggleIsUploadingOrDownloading(true));
        const response = await aepNetwork({
            method: uploadType === CONFIG.didUploadType.full ? 'post' : 'put',
            url: `networkManagementService/importDids/${dataCenter.id}`,
            data: formData
        });

        const data = response.data ? response.data : undefined;

        if (response.status === CONFIG.HTTP_STATUS.OK)
            data.invalidDIDs.length === 0 ? pushMessage(CONFIG.messageType.success, 'DIDs imported successfully') : DIDImportErrorNotification(data);
        else if (response.status === CONFIG.HTTP_STATUS.NOT_ACCEPTABLE) pushMessage(CONFIG.messageType.error, 'Invalid file type. Upload a .csv file')
        else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) pushMessage(CONFIG.messageType.error, 'DID already exists')
        else if (response.status === CONFIG.HTTP_STATUS.UNPROCESSABLE_ENTITY) pushMessage(CONFIG.messageType.error, 'The file is empty.');
        else pushMessage(CONFIG.messageType.error, 'Unable to import DIDs');
        dispatch(toggleIsUploadingOrDownloading(false));
    }
}

export const updateDIDsSelectedRowKeys = (rowKeys = []) => {

    return {
        type: UPDATE_DIDS_SELECTED_ROW_KEYS,
        payload: rowKeys
    };
};

export const deleteDIDs = (hardDelete = false) => async (dispatch, getState) => {
    const selectedDIDs = getState().dids.selectedRowKeys;
    let response;
    if (selectedDIDs) {
        response = await aepNetwork.delete('/networkManagementService/dids',
            { data: selectedDIDs, params: { hardDelete } });
        if (response.status === CONFIG.HTTP_STATUS.OK) {
            pushMessage(CONFIG.messageType.success, 'DID(s) deleted successfully');
            dispatch(updateDIDsSelectedRowKeys());
            dispatch(toggleIsFetchingDids(true));
        }
        else pushMessage(CONFIG.messageType.error, 'Unable to delete DIDs');
    }
    return [response.status === CONFIG.HTTP_STATUS.OK ? true : false, selectedDIDs]
}

export const restoreDIDs = () => async (dispatch, getState) => {
    const selectedDIDs = getState().dids.selectedRowKeys;
    const response = await aepNetwork.put("/networkManagementService/restore/dids", selectedDIDs);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "DID(s) restored successfully")
        dispatch(updateDIDsSelectedRowKeys());
        dispatch(toggleIsFetchingDids(true));
    }
    else pushMessage(CONFIG.messageType.error, "Unable to restore DID(s)");
    return [response.status === CONFIG.HTTP_STATUS.OK ? true : false, selectedDIDs]
}

export const postNewDid = (did, didId, dataCenter, pageNumber = 1, filterSort) => async dispatch => {
    const requestData = { ...did };
    const contextPath = "/networkManagementService/did/";

    const response = await aepNetwork({
        method: didId < 0 ? 'post' : 'put',
        url: didId < 0 ? `${contextPath}${dataCenter.id}` : `${contextPath}${didId}`,
        data: did < 0 ? null : requestData
    });

    batch(() => {
        dispatch(updateDidEditingKey())
    })
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'DID updated successfully');
    }
    else if (response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, 'DID added successfully')
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.error, 'DID -' + did.did + ' already exists')
    }
    else if (response.status === CONFIG.HTTP_STATUS.UNPROCESSABLE_ENTITY) pushMessage(CONFIG.messageType.error, response.data)
    else pushMessage(CONFIG.messageType.error, 'Failed to add new DID');
    dispatch(fetchDIDs({ dataCenter, pageNumber, filterSort }));
    return response.status

};

export const toggleIsUploadingOrDownloading = (flag) => {
    return {
        type: TOGGLE_IS_UPLOADING_DOWNLOADING_FLAG,
        payload: flag
    }
}

export const fetchProviders = (viewDeletedItems = false) => async dispatch => {
    const response = await aepNetwork.get('/networkManagementService/providers', { params: { viewDeletedItems } });
    batch(() => {
        if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
            dispatch({
                type: FETCH_PROVIDERS,
                payload: { data: response.data || [], key: viewDeletedItems ? "deletedData" : "data" }
            });
        }
        else pushMessage(CONFIG.messageType.error, 'Unable to fetch Providers');
        dispatch(toggleIsFetchingDids(false));
    })
};

export const updateProviderEditingKey = (key = "") => {
    return {
        type: UPDATE_PROVIDER_EDITING_KEY,
        payload: key
    };
};

export const addProvider = providerId => {
    const provider = {
        id: providerId,
        title: ""
    };

    return {
        type: ADD_PROVIDER,
        payload: provider
    };
};

export const postNewProvider = (providerId, provider) => async dispatch => {
    const contextPath = providerId < 0 ? '/networkManagementService/providers' : `/networkManagementService/providers/${providerId}`
    const response = await aepNetwork({
        url: contextPath,
        method: providerId < 0 ? 'post' : 'put',
        data: provider,
    });

    batch(() => {
        dispatch(updateProviderEditingKey())
        dispatch(fetchProviders());
    })
    if (response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, 'Provider created successfully')
    }
    else if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Provider updated successfully')
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, 'Provider already exists')
    }
    else pushMessage(CONFIG.messageType.error, 'Failed to add new provider');
};

export const removeEmptyProvider = () => (dispatch) => {
    batch(() => {
        dispatch({
            type: REMOVE_EMPTY_PROVIDER
        });
        dispatch(updateProviderEditingKey())
    });
};

export const deleteProvider = (providerId, hardDelete = false) => async (dispatch, getState) => {
    const response = await aepNetwork.delete('/networkManagementService/providers/' + providerId, { params: { hardDelete } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Provider deleted successfully');
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to delete Provider');
}

export const restoreProvider = (providerId) => async () => {
    const response = await aepNetwork.put("/networkManagementService/restore/providers/" + providerId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Provider restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore provider");
}

export const checkProviderDependencies = async (providerId, viewDeletedItems) => {
    const response = await aepNetwork.get(`/networkManagementService/providers/${providerId}/dependencies`, { params: { viewDeletedItems } })
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
    else throw Error("Unable to get provider dependency list");
};

export const toggleIsCollapsedStatusDID = (data) => {
    return {
        type: TOGGLE_IS_COLLAPSED_STATUS,
        payload: data
    }
}

export const currentDidsFilter = (status) => {
    return {
        type: CURRENT_DIDS_FILTER,
        payload: status
    }
}
