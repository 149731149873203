import React from 'react';
import { connect } from 'react-redux';
import { Spin } from "antd"
import {
    toggleBackButton,
    fetchDemoPurposeTypes,
    clearDemoScheduler,
    getUserDemoById,
    getUserScheduleById,
    clearSolutionDemos
} from '../../../actions';
import LabDemoScheduleForm from './LabDemoScheduleForm';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class ScheduleLabDemo extends React.Component {

    state = {
        hasChanged: true,
        hasTimeChanged: true,
        demo: undefined,
        schedule: null,
        loading: true
    }

    async componentDidMount() {
        const { fromManageSide } = this.props.location;
        setTimeout(() => {
            const isValidView = window.location.pathname.includes("/scheduler/lab")
            isValidView && this.props.fetchDemoPurposeTypes(true);
        }, 100)
        const isCreate = window.location.pathname.includes("new");
        if (isCreate) {
            await getUserDemoById(this.props.match.params.demoId)
                .then((response) => {
                    this.setState({ demo: response, loading: false })
                })
        }
        else {
            await getUserScheduleById(this.props.match.params.scheduleId, fromManageSide)
                .then((response) => {
                    this.setState({ demo: response?.demo, schedule: response, loading: false })
                })
        }
    }

    async componentDidUpdate(prevProps) {
        if (this.props.location?.isEdit !== prevProps?.location?.isEdit) {
            this.setState({ loading: true })
            await getUserScheduleById(this.props.match.params.scheduleId)
                .then((response) => {
                    this.setState({ demo: response?.demo, schedule: response, loading: false })
                })
        }
    }

    componentWillUnmount() {
        this.props.clearDemoScheduler();
        this.props.clearSolutionDemos();
    }

    handleTimeZoneChange = (flag) => {
        this.setState({
            hasChanged: flag
        })
    }

    handleTimeChange = (flag) => {
        setTimeout(() => {
            this.setState({
                hasTimeChanged: flag
            })
        }, 0)
    }

    render() {
        const { fromDashboard, pathValues, isReSchedule } = this.props.location;
        const { demo, schedule } = this.state;
        const isEditSchedule = window.location.pathname.includes("/scheduler/lab") && window.location.pathname.includes("edit");
        const isObjectLoaded = isEditSchedule ? schedule?.id : demo?.id;
        return (
            <div className="font">
                <Spin indicator={antIcon} spinning={!this.state.hasTimeChanged}>
                    {(isObjectLoaded) ? <div>
                        <LabDemoScheduleForm
                            demo={demo}
                            fromDashboard={fromDashboard}
                            schedule={this.state.schedule}
                            pathValues={pathValues}
                            isReSchedule={isReSchedule}
                            timeZoneChanged={this.handleTimeZoneChange}
                        />
                        <br />
                    </div> :
                        <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}><Spin indicator={antIcon} spinning={this.state.loading} /></div>}
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
        timeZone: user.profile?.timezone,
    };
};

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        fetchDemoPurposeTypes,
        clearDemoScheduler,
        getUserDemoById,
        getUserScheduleById,
        clearSolutionDemos
    }
)(ScheduleLabDemo);