import React from 'react';
import { connect, batch } from 'react-redux';

import {
    fetchAllSolutions,
    updateRoleTemplateSolutions,
    toggleIsFetchingSolutions,
    fetchSolutionTypes
} from '../../actions';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';

class RoleTemplateSolutions extends React.Component {

    state = {
        selectAllDisabled: false,
        solutionTypeFilters: undefined
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    columnFilters = {
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "title"),
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        onFilter: (value, record) =>
            record.title
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    }

    handleFilter = (title, confirm) => {
        title = title?.trim();
        if (!title && !this.state.selectAllDisabled) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else confirm();
    }

    handleClear = (clearFilters) => {
        clearFilters();
    }

    handleChange = (_, filters) => {
        const selectAllDisabled = Object.values(filters).filter(value => value.length !== 0)?.length ? true : false;
        this.setState({ selectAllDisabled });
    }

    componentDidUpdate() {
        const { solutionTypes } = this.props;
        const { solutionTypeFilters } = this.state;
        if (!solutionTypeFilters && solutionTypes) {
            this.setState({
                solutionTypeFilters: solutionTypes.map(type => ({
                    text: type.name,
                    value: type.id
                }))
            })
        }
    }

    componentDidMount() {
        const { userRoleTemplate } = this.props;
        const access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
        batch(() => {
            this.props.toggleIsFetchingSolutions(true);
            this.props.fetchAllSolutions(access);
            this.props.fetchSolutionTypes()
        })
    }

    render() {
        const { solutions, assignedSolutions, isFetching, allSolutionsUserAccess, allSolutionsAdminAccess, updateRoleTemplateSolutions, templateType } = this.props;
        const { selectAllDisabled, solutionTypeFilters } = this.state;
        const extraColumn = {
            title: "Type",
            key: "type",
            width: '20%',
            align: "left",
            filters: solutionTypeFilters || [],
            sorter: (a, b) => a.solutionType.name.toLowerCase().localeCompare(b.solutionType.name.toLowerCase()),
            onFilter: (value, record) => record.solutionType?.id === value,
            render: (record) => <span>{record.solutionType?.name || ''}</span>
        }
        return (
            <RoleTemplatesTable
                dataKey="solution"
                hideColumns={templateType === CONFIG.roleTypes.admin ? [] : ['admin']}
                marginTop={true}
                extraColumns={[extraColumn]}
                bordered={true}
                data={solutions || []}
                selectAllDisabled={selectAllDisabled}
                columnFilters={this.columnFilters}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1
                }}
                isFetching={isFetching}
                assignedData={assignedSolutions}
                allAdminAccess={allSolutionsAdminAccess}
                allUserAccess={allSolutionsUserAccess}
                updateRoleTemplateData={updateRoleTemplateSolutions}
            />
        );
    }
}

const mapStateToProps = ({ solutions, roleTemplate, user, solutionTypes }) => {
    return {
        solutions: solutions.data,
        isFetching: solutions.isFetching,
        assignedSolutions: roleTemplate.solutions,
        allSolutionsUserAccess: roleTemplate.allSolutionsUserAccess,
        allSolutionsAdminAccess: roleTemplate.allSolutionsAdminAccess,
        templateType: roleTemplate.templateType,
        solutionTypes: solutionTypes.types,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined
    }
}

export default connect(
    mapStateToProps,
    {
        fetchAllSolutions,
        updateRoleTemplateSolutions,
        toggleIsFetchingSolutions,
        fetchSolutionTypes
    }
)(RoleTemplateSolutions);