import {
    UPDATE_VIEW_PORT
} from '../actions/types';

const viewPortState = {
    isMobileView: false,
    isDesktopView: false,
    isTabletView: false
};

export const viewportReducer = (state = viewPortState, action) => {
    switch (action.type) {
        case UPDATE_VIEW_PORT:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}