import React, { Component } from 'react'
import { Carousel } from 'antd';
import { connect } from 'react-redux';

class PromotionCarousel extends Component {

    render() {
        const importAll = (r) => r.keys().map(r);
        const promotionImages = importAll(require.context('../../assets/images/marketing-images', false, /\.(png|jpe?g|svg)$/));
        return (
            <Carousel
                dotPosition={"bottom"}
                autoplay={true}
            >
                {promotionImages.map((imageSrc, index) => (
                    <div key={index}>
                        <img className="tour-images" src={imageSrc} alt={`${index}`} />
                    </div>
                ))}
            </Carousel>
        )
    }
}

const mapStateToProps = () => {
    return null;
};

export default connect(mapStateToProps, {})(PromotionCarousel);