import { Modal } from 'antd';
const { info, warning } = Modal;

const openInfo = (title, content, onOk, okText, isWarning = false, className) => {
    !isWarning ? info({
        title,
        content,
        onOk,
        okText,
        okButtonProps: { id: 'pop-up-confirm' },
    }) :
        warning({
            title,
            content,
            onOk,
            okText,
            className,
            okButtonProps: { id: 'pop-up-confirm' },
        });
}

export default openInfo;