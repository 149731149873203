import {
    TOGGLE_IS_FETCHING_DAILY_REPORTS,
    GET_ALL_DAILY_REPORTS,
    GET_EACH_DAILY_REPORT,
    CLEAR_DAILY_REPORT_FORM,
    CLEAR_DAILY_REPORT,
    GET_COL_ATTRIBUTE_MAPPING,
    GET_PRODUCT_ATTRIBUTES,
    FETCH_UNTESTED_PRODUCT,
    REFRESH_DAILY_REPORT,
    LAST_DAILY_REPORT_FILTER
} from '../actions/types';

const dailyReportState = {
    loading: false,
    data: [],
    colAttributeMap: [],
    productAttributes: [],
    untestedProduct: [],
    dailyReportById: {}
}

export const dailyReportReducer = (state = dailyReportState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_DAILY_REPORTS:
            return { ...state, loading: action.payload }
        case GET_ALL_DAILY_REPORTS:
            return { ...state, data: action.payload }
        case GET_COL_ATTRIBUTE_MAPPING:
            return { ...state, colAttributeMap: action.payload }
        case CLEAR_DAILY_REPORT:
            return { ...dailyReportState }
        case GET_PRODUCT_ATTRIBUTES:
            return { ...state, productAttributes: action.payload }
        case FETCH_UNTESTED_PRODUCT:
            return { ...state, untestedProduct: action.payload || [] }
        case GET_EACH_DAILY_REPORT:
            return {...state, dailyReportById: action.payload}
        case CLEAR_DAILY_REPORT_FORM:
            return {...state, dailyReportById: {}}
        case LAST_DAILY_REPORT_FILTER:
            return {...state, date: action.payload[0], sortInfo: action.payload[1], timeZone: action.payload[2]}
        case REFRESH_DAILY_REPORT:
            return {...state, data: [],colAttributeMap: [],untestedProduct: []}
        default: return state
    }
}