import {
    FETCH_ALL_PURPOSE,
    TOGGLE_IS_FETCHING_PURPOSE,
    REORDER_DEMO_PURPOSE,
    TOGGLE_IS_DEMO_PURPOSE_REORDERED,
    LAST_DEMO_PURPOSE_FILTER,
    CLEAR_DEMO_PURPOSE,
    TOGGLE_IS_FETCHING_DEMO_PURPOSE_FORM,
    FETCH_DEMO_PURPOSE_BY_ID,
    UPDATE_DEMO_PURPOSE_ROLE_TEMPLATE,
    SAVE_DEMO_PURPOSE_ROLE_TEMPLATES,
    CANCEL_DEMO_PURPOSE_ROLE_TEMPLATES,
    CLEAR_DEMO_PURPOSE_FORM,
    REFRESH_DEMO_PURPOSE,
    TOGGLE_HAS_DEMO_PURPOSE_UPDATED
} from '../actions/types';
import CONFIG from '../config';
const purposeState = {
    isFetching: false,
    isReordered: false,
};

const purposeFormState = {
    isFetching: false,
    allRTAdminAccess: undefined,
    allRTUserAccess: undefined,
}

export const demoPurposeReducer = (state = purposeState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_PURPOSE:
            return { ...state, isFetching: action.payload };
        case REORDER_DEMO_PURPOSE:
            return { ...state, demoPurposes: action.payload || [], count: action.payload?.length || 0 };
        case FETCH_ALL_PURPOSE:
            return { ...state, demoPurposes: action.payload.dataSet || [], count: action.payload.count || 0 };
        case TOGGLE_IS_DEMO_PURPOSE_REORDERED:
            return { ...state, isReordered: action.payload };
        case LAST_DEMO_PURPOSE_FILTER:
            return { ...state, currentFilter: action.payload[0], isEdit: true, status: action.payload[1] }
        case CLEAR_DEMO_PURPOSE:
            return purposeState
        case REFRESH_DEMO_PURPOSE:
            return { ...state, data: [], count: undefined };
        case TOGGLE_HAS_DEMO_PURPOSE_UPDATED:
            return { ...state, data: [], count: undefined };
        default:
            return state;
    }
}

export const demoPurposeFormReducer = (state = purposeFormState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_DEMO_PURPOSE_FORM:
            return { ...state, isFetching: action.payload }
        case FETCH_DEMO_PURPOSE_BY_ID:
            return {
                ...state,
                ...action.payload,
                demoPurposes: action.demoPurpose,
                assignedRoleTemplates: action.payload.roleTemplates || [],
                demoPurposeObj: action.payload
            }
        case UPDATE_DEMO_PURPOSE_ROLE_TEMPLATE:
            return {
                ...state,
                roleTemplates: action.payload.updatedRoleTemplates,
                [action.payload.allAccessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                    ? action.payload.accessType
                    : CONFIG.roleTemplateAccess.none
            }
        case SAVE_DEMO_PURPOSE_ROLE_TEMPLATES:
            return { ...state, assignedRoleTemplates: state.roleTemplates };
        case CANCEL_DEMO_PURPOSE_ROLE_TEMPLATES:
            return { ...state, roleTemplates: state.assignedRoleTemplates || [], allRTAdminAccess: undefined, allRTUserAccess: undefined };
        case CLEAR_DEMO_PURPOSE_FORM:
            return { ...purposeFormState }
        default:
            return state;
    }
}