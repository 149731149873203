import { aepSchedule } from "../apis";
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';

import {
    FETCH_SFDC_STATS_DATA,
    CLEAR_SFDC_STATS_DATA,
    LAST_SFDC_STATS_FILTER
} from './types';

export const fetchSFDCStatsData = (filterSort, status) => async (dispatch) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/v1/sfdc/exception/schedules", {
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    exceptionStatus: status
                }
            }
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_SFDC_STATS_DATA,
            payload: response?.data || []
        });
        return response?.data || [];
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch demos");
}

export const clearSFDCStatsData = () => {
    return {
        type: CLEAR_SFDC_STATS_DATA
    }
}

export const updateSDFCRecord = (payload) => async () => {
    const response = await aepSchedule({
        url: "/scheduleMaintenanceService/v1/sfdcSchedule/toggleArchive",
        method: "patch",
        data: payload
    })
    if (response.status === CONFIG.HTTP_STATUS.OK) pushMessage(CONFIG.messageType.success, "Record updated successfully");
    else pushMessage(CONFIG.messageType.error, "Unable to update the record");
    return response.status === CONFIG.HTTP_STATUS.OK;
}

export const sendSFDCReminderToAll = (status) => async () => {
    const response = await aepSchedule.post(`/scheduleMaintenanceService/v1/sfdcOpportunity/resendEmail?sfdcStatus=${status}`)
    if (response.status === CONFIG.HTTP_STATUS.OK) pushMessage(CONFIG.messageType.success, "Reminder email sent successfully");
    else pushMessage(CONFIG.messageType.error, "Unable to send reminder email to everyone");
    return response.status === CONFIG.HTTP_STATUS.OK;
}

export const currentSFDCStatsFilter = (filters, status, currentPage, isRedirection, record) => {
    return {
        type: LAST_SFDC_STATS_FILTER,
        payload: [filters, status, currentPage, isRedirection, record]
    }
}