import React from 'react';
import { Tabs, Alert } from 'antd';
import { connect } from 'react-redux';

import TextEditor from '../common/TextEditor';
import DemoStatus from './DemoStatus';
import { updateDemoCkEditorContents } from '../../actions';
import CONFIG from '../../config';

const { TabPane } = Tabs;

class DemoDetails extends React.Component {

    renderWarningMessage = () => {
        return (
            <Alert
                message={CONFIG.systemMessage.ckEditorSourceModeWarning}
                type="warning"
                showIcon
            />
        )
    }

    render() {
        // Props coming from DemoForm.
        const { viewport, editorKeys, isEditMode, demoType, demoId, demoEditorContent, isClone } = this.props
        const { descriptionHtml, connectivityDetailsHtml, componentDetailsHtml } = demoEditorContent || {}
        const { descriptionKey, componentDetailsKey, accessKey } = editorKeys;
        return (
            <>
                <Tabs className={!viewport.isMobileView ? "demo-details-tabs" : null} defaultActiveKey="desc" animated={{ tabPane: false }}>
                    <TabPane tab="Description" key="desc" forceRender={true} >
                        {this.renderWarningMessage()}
                        <br />
                        <TextEditor
                            updateEditorContent={this.props.updateDemoCkEditorContents}
                            contentData={descriptionHtml}
                            recordId={parseInt(demoId) || -1}
                            storeKey={CONFIG.editorType.demoDescription}
                            componentKey={CONFIG.editorType.demoDescription}
                            editorKey={descriptionKey}
                            isClone={isClone}
                            isContentLoaded={true}
                            isEdit={isEditMode} />
                    </TabPane>
                    <TabPane tab="Components" key="component" forceRender={true}>
                        {this.renderWarningMessage()}
                        <br />
                        <TextEditor
                            updateEditorContent={this.props.updateDemoCkEditorContents}
                            contentData={componentDetailsHtml}
                            recordId={parseInt(demoId) || -1}
                            storeKey={CONFIG.editorType.demoComponent}
                            componentKey={CONFIG.editorType.demoComponent}
                            editorKey={componentDetailsKey}
                            isClone={isClone}
                            isContentLoaded={true}
                            isEdit={isEditMode} />
                    </TabPane>
                    <TabPane tab="Access" key="access" forceRender={true}>
                        {this.renderWarningMessage()}
                        <br />
                        <TextEditor
                            updateEditorContent={this.props.updateDemoCkEditorContents}
                            contentData={connectivityDetailsHtml}
                            recordId={parseInt(demoId) || -1}
                            storeKey={CONFIG.editorType.demoAccess}
                            componentKey={CONFIG.editorType.demoAccess}
                            editorKey={accessKey}
                            isClone={isClone}
                            isContentLoaded={true}
                            isEdit={isEditMode} />
                    </TabPane>
                    {demoType === CONFIG.demoTypes.standardDemo &&
                        <TabPane tab="Status" key="status">
                            <DemoStatus demoId={parseInt(demoId) || -1} componentKey="status" />
                        </TabPane>
                    }
                </Tabs>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewport: state.viewport,
        demoEditorContent: state.openTabDemoDetails?.currentTabData?.demoEditorContent,
    };
};

export default connect(
    mapStateToProps,
    { updateDemoCkEditorContents }
)(DemoDetails);
