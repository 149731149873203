import React from 'react';
import { Modal, Tag, Alert, Tooltip } from 'antd';
import CONFIG from '../../config';
import moment from 'moment-timezone';
import HTML from '../common/HTML';
import EZDemoRatingNotification from '../ManageEZDemos/EZDemoRatingNotification';

const renderAffectedDemosList = (maintenanceRelations) => {
    return maintenanceRelations?.map(item => {
        if (item.resource && item.resource !== null) {
            return <ul><li>{item.demo.title}({item.dataCenter.title})({item.resource.name})</li></ul>
        }
        else if (item.dataCenter) {
            return <ul><li>{item.demo.title}({item.dataCenter.title})</li></ul>
        }
        else return <ul><li>{item.demo.title}</li></ul>

    })

}


const renderScheduleDetails = (notification, isDemoResourceUpdate, isDemoDcUpdate, isScheduleParticipantAdded, isAdminUpdatedSchedule, timeZoneName) => {
    const startDateTime = moment.tz(notification.startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime);
    const endDateTime = moment.tz(notification.endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime);

    if (isScheduleParticipantAdded)
        return (
            <>
                <><b>Resource : </b>{notification?.resourceTitle}<br /></>
                <b>Schedule start time : </b>{startDateTime}<br />
                <b>Schedule end time : </b>{endDateTime}<br /><br />
                <>You can view this schedule on your dashboard. Please check your email for more information!</>
            </>
        )
    else if (isAdminUpdatedSchedule) {
        return (<>
            <HTML htmlContent={notification?.body}></HTML>
            {notification?.type === CONFIG.pushNotificationTypes.SCHEDULE_DELETE_ON_BEHALF_OF_USER ? `Please check your email for more information!` : `You can view this schedule on your dashboard. Please check your email for more information!`}
        </>)
    }
    else
        return (
            <>
                {notification.additionalDetails}<br /><br />
                {(isDemoResourceUpdate || isScheduleParticipantAdded) && <><b>Resource : </b>{notification?.dcResourceTitle}<br /></>}
                {isDemoDcUpdate && notification?.dcResourceTitle}
                <b>Schedule start time : </b>{startDateTime}<br />
                <b>Schedule end time : </b>{endDateTime}<br /><br />
                {notification.description}<br />
                <>Please check your email for more information!</>
            </>
        )
}

const renderEZDemoAdminReplyDialog = (ezDemoAuditId, onCancel, notificationType, handleDropdownClose) => {
    return (<>
        <EZDemoRatingNotification
            ezDemoAuditId={ezDemoAuditId}
            onCancel={onCancel}
            notificationType={notificationType}
            handleDropdownClose={handleDropdownClose}
        />
    </>
    )
}

const renderCapabilityDetails = (notification, isAdmin) => {

    return (
        <React.Fragment>
            <h3>{isAdmin ? "You have pending capability request" : "Your request has been updated by the admin"}  </h3>
            <div className='notification-margin'><b>Capability : </b>{notification.capability}<br /></div>
            <div className='notification-margin'><b>Demo : </b>{notification.demo}<br /></div>
            {
                !isAdmin && <>
                    <div className='notification-margin'><b>Status : </b><Tag color={CONFIG.capabilityRequestColors[notification.tag]}>{notification.tag}</Tag><br /></div>
                    {notification.comment && <div className='notification-margin'><b>Admin comment : </b>  <HTML htmlContent={notification.comment} /><br /></div>}
                </>
            }
            {isAdmin && <div style={{ fontStyle: "italic", fontSize: "12px", marginTop: "20px", fontFamily: 'Noto Sans', }}>
                Navigate to "Capability Requests" section under Manage Requests to complete or decline the request.

            </div>}
        </React.Fragment>
    )
}

export const NotificationModal = ({ notification, visible, timeZoneName, onCancel, notificationAccess, handleDropdownClose }) => {
    const alertMessage = `You are no longer authorized to view this item or this item has been removed`;
    const isManage = notification?.access_level === CONFIG.manage;
    const isEZDemoRatingNotification = CONFIG.ezDemoRatingNotificationTypes.includes(notification?.type)
    const isMaintainanceNotification = CONFIG.notificationTypes.maintenance.includes(notification.messageType)
    const isDemoDcUpdate = CONFIG.notificationTypes.dcResourceUpdate[1] === notification.messageType
    const isDemoResourceUpdate = CONFIG.notificationTypes.dcResourceUpdate[0] === notification.messageType
    const isAdminUpdatedSchedule = CONFIG.notificationTypes.adminSchedule.includes(notification.messageType)
    const isScheduleParticipantAdded = CONFIG.notificationTypes.participantAddedSchedule.includes(notification.messageType)
    const isCapabilityNotification = CONFIG.capabilityNotificationTypes.includes(notification.type);
    const adminCapabilities = CONFIG.capabilityNotificationTypes.slice(0, -1);
    const isAdminCapabilityNotification = adminCapabilities.includes(notification.type);
    const shouldDisplayWarningMessage = CONFIG.notificationTypes.demo.includes(notification.messageType) || CONFIG.notificationTypes.news.includes(notification.messageType) || notification.messageType === CONFIG.notificationTypes.access || notification.type === CONFIG.manage || isManage;
    let startDateTime, endDateTime;
    if (isMaintainanceNotification || isDemoResourceUpdate || isDemoDcUpdate) {
        startDateTime = moment.tz(notification.startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime);
        endDateTime = moment.tz(notification.endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime);
    }

    const timestamp = moment.tz(notification?.timestamp, timeZoneName).format(CONFIG.dateFormats.userDateTime)
    const tag = notification.notificationAging ? <Tooltip title={timestamp}><Tag color={CONFIG.colors.userDemo} className="bottom-spacing-3 top-spacing-6">{notification?.tag + " " + notification.notificationAging}</Tag></Tooltip> : <Tag color={CONFIG.colors.userDemo} className="bottom-spacing-3 top-spacing-6">{notification?.tag + " " + timestamp}</Tag>
    return <Modal
        title=""
        visible={visible}
        onCancel={() => onCancel()}
        footer={null}
        className={notificationAccess && isEZDemoRatingNotification ? "wide-modal" : ""}
    >
        {notificationAccess ?
            isEZDemoRatingNotification
                ? <>{renderEZDemoAdminReplyDialog(notification?.ezDemoAuditId, onCancel, notification?.type, handleDropdownClose)}</>
                :
                <div className="responsive-container" >
                    <span>{notification?.messageType}</span> <br />
                    {notification.tag && tag}
                    <h1>{notification?.title}</h1>
                    {shouldDisplayWarningMessage && <Alert message={CONFIG.warningMessages.notifications[notification.messageType]} type="warning" showIcon />}
                    {isMaintainanceNotification && <p className="large-text">{startDateTime} - {endDateTime}</p>}

                    {(isDemoResourceUpdate || isDemoDcUpdate || isScheduleParticipantAdded || isAdminUpdatedSchedule) ?
                        renderScheduleDetails(notification, isDemoResourceUpdate, isDemoDcUpdate, isScheduleParticipantAdded, isAdminUpdatedSchedule, timeZoneName)
                        :
                        isCapabilityNotification ?
                            <div>
                                {renderCapabilityDetails(notification, isAdminCapabilityNotification)}
                            </div>
                            :
                            <>
                                <br />
                                <HTML htmlContent={notification.description} />
                                <br />
                                {isMaintainanceNotification && <b>Demos Affected :</b>}
                                {notification.additionalDetails}
                                {isAdminUpdatedSchedule && <>Please check your email for more information!</>}
                                {isMaintainanceNotification && renderAffectedDemosList(notification.maintenanceRelations)}
                            </>}
                </div>
            :
            <div className='responsive-container'>
                <span>{notification?.messageType}</span> <br />
                {notification.tag && tag}
                <h1>{notification?.title}</h1>

                <Alert message={alertMessage} type="warning" showIcon />
            </div>
        }
    </Modal >
}