import {
    TOGGLE_IS_FETCHING_CAPABILITY_REQUESTS,
    CLEAR_CAPABILITY_REQUEST_DATA,
    FETCH_ALL_CAPABILITY_REQUESTS,
    UPDATE_CAPABILITY_REQUEST_EDITING_KEY,
    CURRENT_CAPABILITY_REQUEST_FILTER,
    FETCH_CAPABILITY,
} from "../actions/types";

const capabilityRequestState = {
    editingKey: "",
    isEdit: false,
    isFetchingUser: false,
    capabilityRequests: [],
    users: [],
}

export const capabilityRequestReducer = (state = capabilityRequestState, action) => {
    switch (action.type) {
        case FETCH_ALL_CAPABILITY_REQUESTS:
            return {
                ...state,
                capabilityRequests: action.payload ? action.payload : [] || []
            };
        case TOGGLE_IS_FETCHING_CAPABILITY_REQUESTS:
            return { ...state, isFetchingCapabilityRequests: action.payload };
        case UPDATE_CAPABILITY_REQUEST_EDITING_KEY:
            return { ...state, editingKey: action.payload };
        case CURRENT_CAPABILITY_REQUEST_FILTER:
            return { ...state, filters: action.payload[0], status: action.payload[1], currentPage: action.payload[2], isEdit: true };
        case CLEAR_CAPABILITY_REQUEST_DATA:
            return { ...capabilityRequestState, filters: {}, isEdit: false };
        default:
            return state;
    }
}

const allCapabilityState = {
    allCapability: []
}

export const allCapabilityReducer = (state = allCapabilityState, action) => {
    switch (action.type) {
        case FETCH_CAPABILITY:
            return { ...state, allCapabilities: action.payload || [] };
        default:
            return state;
    }
}