import React from 'react'
import { Select, Form, Row, Col, Input, Switch, DatePicker, Checkbox, Tooltip } from 'antd';
import moment from 'moment-timezone';
import CONFIG from '../../config';
import { Button } from "neo-latest"
import { Drawer } from "antd5"
import { getDateTime } from '../../utils/datepicker';
const { RangePicker } = DatePicker;
const { Option } = Select;
const FilterDrawer = ({
    visible,
    width,
    placement,
    drawerContainer,
    style,
    handleCloseFilterDrawer,
    formFields,
    handleFilter,
    handleClear,
    form,
    timeZone,
    label,
    hideSoftDeleteCheckbox,
    getSearchData,
    getSearchEmailData,
    hideApplyToAll,
    handleSave,
    viewDeletedItemsValue,
    hideSave
}) => {
    const { getFieldDecorator } = form;
    const colSpan = formFields.length % 2 === 0 ? 12 : 8;
    const currentDateTime = getDateTime({ convertTimeZone: true, timeZone });
    const ranges = {
        'Tomorrow': [moment.tz(timeZone), moment.tz(timeZone).add(1, 'days')],
        'Next 7 days': [moment.tz(timeZone), moment.tz(timeZone).add(7, 'days')],
        'Next 30 days': [moment.tz(timeZone), moment.tz(timeZone).add(30, 'days')],
        'Next 365 days': [moment.tz(timeZone), moment.tz(timeZone).add(365, 'days')],
        'Yesterday': [moment.tz(timeZone).subtract(1, 'days'), moment.tz(timeZone)],
        'Last 7 days': [moment.tz(timeZone).subtract(7, 'days'), moment.tz(timeZone)],
        'Last 30 days': [moment.tz(timeZone).subtract(30, 'days'), moment.tz(timeZone)],
        'Last 365 days': [moment.tz(timeZone).subtract(365, 'days'), moment.tz(timeZone)]
    }
    const getInitialValue = (value, type) => {
        if (value) {
            if (type === CONFIG.formFieldTypes.SELECT) return value?.split("\\")
            else if (type === CONFIG.formFieldTypes.SEARCH_SELECT) return value
            else if (type === CONFIG.formFieldTypes.DROPDOWN) return value
            return
        }
    }
    const getFormElement = (field, idx) => {
        switch (field.type) {
            case CONFIG.formFieldTypes.INPUT:
                return getFieldDecorator(field.key, { initialValue: field.value })
                    (<Input key={idx} />)
            case CONFIG.formFieldTypes.SELECT:
                return (
                    getFieldDecorator(field.key, {
                        initialValue: getInitialValue(field.value, CONFIG.formFieldTypes.SELECT),
                        rules: [field.mode === "default" ? { type: "string" } : { type: 'array' }]
                    })(
                        <Select
                            mode={field.mode || "multiple"}
                            placeholder={`Select ${field.label}`}
                            key={idx}
                            getPopupContainer={() => document.getElementById('filterForm')}
                        >
                            {
                                field.data?.map((dataObj, index) =>
                                    <Option key={index} title={dataObj[field.dataKey || "text"]} value={dataObj[field.valueKey || "value"]}>{dataObj[field.dataKey || "text"]}</Option>
                                )
                            }
                        </Select>
                    )
                )
            case CONFIG.formFieldTypes.SEARCH_SELECT:
                return (
                    getFieldDecorator(field.key, {
                        initialValue: getInitialValue(field.value, CONFIG.formFieldTypes.SEARCH_SELECT)
                    })(
                        <Select
                            showSearch={true}
                            placeholder={`Type ${field.label} to search`}
                            onSearch={getSearchData}
                            notFoundContent="No Match found!"
                            getPopupContainer={() => document.getElementById('filterForm')}
                        >
                            {field.data?.map((eachName, index) => (
                                <Option title={eachName} key={index} value={eachName} >{eachName}</Option>
                            ))}
                        </Select>
                    )
                )
            case CONFIG.formFieldTypes.EMAIL_SEARCH:
                return (
                    getFieldDecorator(field.key, {
                        initialValue: getInitialValue(field.value, CONFIG.formFieldTypes.EMAIL_SEARCH)
                    })(
                        <Select
                            showSearch={true}
                            placeholder={`Type ${field.label} to search`}
                            onSearch={getSearchEmailData}
                            notFoundContent="No Match found!"
                            getPopupContainer={() => document.getElementById('filterForm')}
                        >
                            {field.data?.map((eachName, index) => (
                                <Option title={eachName} key={index} value={eachName} >{eachName}</Option>
                            ))}
                        </Select>
                    )
                )
            case CONFIG.formFieldTypes.SWITCH:
                return getFieldDecorator(field.key, { valuePropName: "checked" })
                    (<Switch
                        key={idx}
                        checkedChildren={field.checked}
                        unCheckedChildren={field.unchecked}
                    />)
            case CONFIG.formFieldTypes.RANGE_PICKER:
                return (
                    <Row gutter={24}>
                        <Col span={!hideApplyToAll ? 17 : 24}>
                            {getFieldDecorator(field.key, {
                                initialValue: field.value[0] !== undefined ? [moment(field.value[0]), moment(field.value[1])] : [],
                                rules: [{ type: 'array' }]
                            })(
                                <RangePicker
                                    key={idx}
                                    className="full-width"
                                    ranges={ranges}
                                    showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                    format={CONFIG.dateFormats.default}
                                    defaultPickerValue={[currentDateTime, currentDateTime]}
                                    getPopupContainer={() => document.getElementById('filterForm')}
                                    disabled={field.disabled}
                                />
                            )}
                        </Col>
                        {!hideApplyToAll && <Col span={7}>
                            {getFieldDecorator("applyToAllCheck", {
                                initialValue: field.value[2],
                                valuePropName: "checked"
                            })(
                                <Checkbox disabled={field.disabled}>
                                    <Tooltip title={CONFIG.systemMessage.applyToAllCheck}>
                                        Apply to all
                                    </Tooltip>
                                </Checkbox>
                            )}
                        </Col>
                        }
                    </Row>
                )
            case CONFIG.formFieldTypes.DROPDOWN:
                return (
                    getFieldDecorator(field.key, {
                        initialValue: getInitialValue(field.value, CONFIG.formFieldTypes.DROPDOWN),
                        rules: [{ type: "string" }]
                    })(
                        <Select
                            disabled={field.disabled}
                            mode={field.mode || "multiple"}
                            placeholder={`Select ${field.label}`}
                            key={idx}
                        >
                            {
                                field.data?.map((dataObj, index) =>
                                    <Option key={index} title={dataObj[field.dataKey || "text"]} value={dataObj[field.valueKey || "value"]}>{dataObj[field.dataKey || "text"]}</Option>
                                )
                            }
                        </Select>
                    )
                )
            default:
                return null
        }
    }
    const clearFilters = () => {
        form.resetFields();
        handleClear();
    }

    return (
        visible && (
            <Drawer
                className="filter-drawer"
                closeIcon={null}
                open={visible}
                width={width}
                onClose={handleCloseFilterDrawer}
                placement={placement}
                getContainer={drawerContainer}
                style={style}
                title={<span style={{ color: "#242424", fontSize: "19px", fontWeight: "400" }}>Filter By</span>}
                footer={
                    <Button
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                        onClick={() => {
                            clearFilters();
                            // handleCloseFilterDrawer()
                        }} status='alert' size="wide" variant="tertiary" className="float-left avaya-default-button" >
                        Reset Filters
                    </Button>
                }
                extra={
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }} className='float-right'>
                        {!hideSave && <Button onClick={() => handleSave(form.getFieldsValue())} variant="tertiary" className="left-spacing-3 avaya-default-button" >
                            Save
                        </Button>}
                        <Button onClick={() => handleCloseFilterDrawer()} variant="secondary" className="left-spacing-3 avaya-default-button" >
                            Cancel
                        </Button>
                        <Button onClick={() => handleFilter(form.getFieldsValue())} status='default' className="left-spacing-3 avaya-default-button" >
                            Apply
                        </Button>

                    </div>
                }
            // maskClosable={false}
            >

                <Form id="filterForm">
                    <Row>
                        {!hideSoftDeleteCheckbox && (
                            <Form.Item>
                                {getFieldDecorator("viewDeletedItems",
                                    {
                                        valuePropName: "checked",
                                        initialValue: viewDeletedItemsValue
                                    })(
                                        <Checkbox style={{ color: "#242424" }} className='remove-margin-wrapper'>
                                            {label}
                                        </Checkbox>
                                    )}
                            </Form.Item>
                        )
                        }
                    </Row>
                    <Row gutter={48} >
                        {
                            formFields?.map((field, idx) => {
                                return <Col style={{ marginBottom: "16px" }} lg={field.colSpan || colSpan} xs={24} key={idx}>
                                    <Form.Item label={field.label} key={idx}>
                                        {getFormElement(field, idx)}
                                    </Form.Item>
                                </Col>
                            })
                        }
                    </Row>
                </Form>
            </Drawer>)
    )
}
const FilterDrawerRef = Form.create()(FilterDrawer);
export default FilterDrawerRef;