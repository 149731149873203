import {
    FETCH_USER,
    UPDATE_USER_TIME_ZONE,
    TOGGLE_IS_FIRST_TIME_LOGIN,
    SET_IS_LOGIN_COMPLETE,
    TOGGLE_IS_USER_LOADING,
    RESET_LDAP_PASSWORD,
    UPDATE_USER_EMAIL_SUBSCRIPTION,
    UPDATE_INVITE_OPT_OUT_SUBSCRIPTION,
    SET_HAS_PASSWORD_CHANGED,
    FETCH_DECRYPTED_LDAP_PASSWORD,
    UPDATE_SA_USER_TAB_LIMIT,
    FETCH_TAB_LIMITS_FOR_USERS,
    UPDATE_USER_PROFILE_PIC,
    UPDATE_TESTING_RECIPIENTS,
    SHOW_PORTAL_TOUR_MODAL,
    SET_PORTAL_TOUR_COMPLETE
} from '../actions/types';

import CONFIG from "../config"
const userState = {
    isFirstTimeLogin: false,
    isLoginComplete: false,
    isLoading: false,
    showPortalTourModal: false,
    isWhatsNew: false,
}

export const userReducer = (state = userState, action) => {
    switch (action.type) {
        case FETCH_USER:
            return {
                ...state, profile:
                {
                    ...action.payload.user,
                    roleTemplate: { templateType: action.payload.user?.precedenceTemplateType }
                },
                regions: action.payload.regions,
                menu: action.payload.menu,
                isRatingEnabled: action.payload?.isRatingEnabled,
                isViewCommentEnabled: action.payload?.isViewCommentEnabled,
                isEzDemoRatingEnabled: action.payload?.isEzDemoRatingEnabled,
                isViewEzDemoCommentEnabled: action.payload?.isViewEzDemoCommentEnabled,
                isAddParticipantsEnabled: action.payload?.isAddParticipantsEnabled,
                isPeriodicEnabled: action.payload?.isPeriodicEnabled,
                isMultipleDays: action.payload?.isMultipleDays,
                isAdmin: action.payload?.isAdmin,
                testingRecipients: action.payload?.testingRecipients,
                isTestingMode: action.payload?.configurations?.isTestingEnvironment,
                userProfilePicture: action.payload?.user?.profilePictureLink,
                configurations: action.payload?.configurations
            }
        case UPDATE_USER_TIME_ZONE:
            return { ...state, profile: { ...state.profile, timezone: action.payload } }
        case FETCH_TAB_LIMITS_FOR_USERS:
            return {
                ...state,
                superAdminTabLimit: action.payload?.find((el) => el?.templateType === CONFIG.roleTypes.superAdmin)?.tabLimit,
                adminTabLimit: action.payload?.find((el) => el?.templateType === CONFIG.roleTypes.admin)?.tabLimit,
                userTabLimit: action.payload?.find((el) => el?.templateType === CONFIG.roleTypes.user)?.tabLimit
            }
        case TOGGLE_IS_FIRST_TIME_LOGIN:
            return { ...state, isFirstTimeLogin: action.payload }
        case UPDATE_USER_EMAIL_SUBSCRIPTION:
            return { ...state, profile: { ...state.profile, emailNotificationSubscription: action.payload } }
        case UPDATE_SA_USER_TAB_LIMIT:
            return { ...state, profile: { ...state.profile, tabLimit: action.payload } }
        case UPDATE_INVITE_OPT_OUT_SUBSCRIPTION:
            return { ...state, profile: { ...state.profile, optOutInviteSubs: action.payload } }
        case SET_IS_LOGIN_COMPLETE:
            return { ...state, isLoginComplete: true, aepHeader: action.payload }
        case SHOW_PORTAL_TOUR_MODAL:
            return { ...state, showPortalTourModal: action.payload.flag, isWhatsNew: action.payload.fromMenu }
        case TOGGLE_IS_USER_LOADING:
            return { ...state, isLoading: action.payload };
        case RESET_LDAP_PASSWORD:
            return { ...state, profile: { ...state.profile, ldapPassword: action.payload.ldapPassword } }
        case SET_HAS_PASSWORD_CHANGED:
            return { ...state, hasPasswordChanged: action.payload };
        case FETCH_DECRYPTED_LDAP_PASSWORD:
            return { ...state, decryptedLdapPassword: action.payload }
        case UPDATE_USER_PROFILE_PIC:
            return { ...state, userProfilePicture: action.payload }
        case UPDATE_TESTING_RECIPIENTS:
            return { ...state, testingRecipients: action.payload }
        case SET_PORTAL_TOUR_COMPLETE:
            return { ...state, profile: { ...state.profile, isPortalTourCompleted: true } }
        default:
            return state;
    }
}