import {
    FETCH_DATA_CENTERS,
    ADD_DATA_CENTER,
    UPDATE_DATA_CENTER,
    REMOVE_EMPTY_DATA_CENTER,
    TOGGLE_IS_FETCHING_DATA_CENTERS,
    UPDATE_DATA_CENTER_EDITING_KEY,
    CLEAR_DATA_CENTERS,
    TOGGLE_SHOULD_UPDATE_FILTERED_DATA_FLAG,
    REFRESH_DATA_CENTERS,
    TOGGLE_HAS_DATA_CENTERS_UPDATED,
    LAST_DATA_CENTERS_FILTER
} from '../actions/types';

const dataCentersState = {
    isFetching: false,
    currentPage: 1,
    editingKey: "",
    shouldUpdateFilteredData: false
};

export const dataCentersReducer = (state = dataCentersState, action) => {
    switch (action.type) {
        case FETCH_DATA_CENTERS:
            return { ...state, data: action.payload };
        case ADD_DATA_CENTER:
            return state.data
                ? { ...state, data: [action.payload, ...state.data], editingKey: action.payload.id }
                : { ...state, data: [action.payload], editingKey: action.payload.id }
        case UPDATE_DATA_CENTER:
            return {
                ...state,
                data: state.data.map(dataCenter =>
                    dataCenter.id === action.payload.id
                        ? action.payload
                        : dataCenter
                )
            };
        case TOGGLE_SHOULD_UPDATE_FILTERED_DATA_FLAG:
            return { ...state, shouldUpdateFilteredData: action.payload }
        case REMOVE_EMPTY_DATA_CENTER:
            return { ...state, data: state.data.filter(dataCenter => dataCenter.title !== "") }
        case TOGGLE_IS_FETCHING_DATA_CENTERS:
            return { ...state, isFetching: action.payload };
        case UPDATE_DATA_CENTER_EDITING_KEY:
            return { ...state, editingKey: action.payload };
        case CLEAR_DATA_CENTERS:
            return dataCentersState;

        case REFRESH_DATA_CENTERS:
            return {
                ...state,
                data: [],
                count: undefined
            }
        case TOGGLE_HAS_DATA_CENTERS_UPDATED:
            return {
                ...state,
                hasUpdated: action.payload,
            };
        case LAST_DATA_CENTERS_FILTER:
            return {
                ...state,
                currentFilter: action.payload.filters,
                status: action.payload.status,
                currentPage: action.payload.currentPage,
                isEdit: true
            }

        default:
            return state;
    }
};
