import {
    SHOW_USER_DASHBOARD_TODAY_SCHEDULES_SECTION,
    SHOW_USER_DASHBOARD_UPCOMING_SCHEDULES_SECTION,
    SHOW_USER_DASHBOARD_PREVIOUS_SCHEDULES_SECTION,
    SHOW_USER_DASHBOARD_EZ_DEMO_SECTION,
    SHOW_USER_DASHBOARD_NEWS_SECTION,
    SHOW_USER_DASHBOARD_MOST_SCHEDULED_DEMO_SECTION,
    CLEAR_USER_DASHBOARD_SECTION_VIEWS,
    SHOW_ADMIN_DASHBOARD_UPCOMING_SCHEDULES_SECTION,
    SHOW_ADMIN_DASHBOARD_UPCOMING_MAINTENENCE_SECTION,
    CLEAR_ADMIN_DASHBOARD_SECTION_VIEWS
} from './types'

export const showUserDashboardTodaySchedulesSection = (flag) => {
    return {
        type: SHOW_USER_DASHBOARD_TODAY_SCHEDULES_SECTION,
        payload: flag
    }
}


export const showUserDashboardUpcomingSchedulesSection = (flag) => {
    return {
        type: SHOW_USER_DASHBOARD_UPCOMING_SCHEDULES_SECTION,
        payload: flag
    }
}

export const showUserDashboardPreviousSchedulesSection = (flag) => {
    return {
        type: SHOW_USER_DASHBOARD_PREVIOUS_SCHEDULES_SECTION,
        payload: flag
    }
}

export const showUserDashboardEZDemosSection = (flag) => {
    return {
        type: SHOW_USER_DASHBOARD_EZ_DEMO_SECTION,
        payload: flag
    }
}

export const showUserDashboardNewsSection = (flag) => {
    return {
        type: SHOW_USER_DASHBOARD_NEWS_SECTION,
        payload: flag
    }
}
export const showUserDashboardMostScheduledDemosSection = (flag) => {
    return {
        type: SHOW_USER_DASHBOARD_MOST_SCHEDULED_DEMO_SECTION,
        payload: flag
    }
}




export const clearUserDashboardSectionViews = () => {
    return {
        type: CLEAR_USER_DASHBOARD_SECTION_VIEWS
    }
}

export const clearAdminDashboardSectionViews = () => {
    return {
        type: CLEAR_ADMIN_DASHBOARD_SECTION_VIEWS
    }
}

export const refreshUserDashboardView = () => async (dispatch, getState) => {
    if (getState().user.isAdmin) {
        const upcomingSchedules = getState().dashboard.upcomingSchedules;
        const todaySchedules = getState().dashboard.todaySchedules;
        (upcomingSchedules?.length === 0 && todaySchedules?.length === 0) && dispatch(showAdminDashboardUpcomingSchedulesSection(false));
    }
    else {
        // Schedules
        const todaySchedules = getState().dashboard.dashboardTodaySchedules;
        const upcomingSchedules = getState().dashboard.dashboardUpcomingSchedules;
        const previousSchedules = getState().dashboard.dashboardPreviousSchedules;
        const userNews = getState().userNews.userNewsData;
        const popularEzDemos = getState().dashboard.popularEzDemos;
        const mostScheduledDemos = getState().mostScheduledDemos.mostScheduledDemos;

        (todaySchedules === null || !todaySchedules?.length > 0) && dispatch(showUserDashboardTodaySchedulesSection(false));
        (upcomingSchedules === null || !upcomingSchedules?.length > 0) && dispatch(showUserDashboardUpcomingSchedulesSection(false));
        (previousSchedules === null || !previousSchedules?.length > 0) && dispatch(showUserDashboardPreviousSchedulesSection(false));
        // News
        (userNews === null || !userNews?.length > 0) && dispatch(showUserDashboardNewsSection(false));
        // Popular EZ Demos
        (popularEzDemos === null || !popularEzDemos?.length > 0) && dispatch(showUserDashboardEZDemosSection(false));
        // Popular Scheduled Demos
        (mostScheduledDemos === null || (Object.keys(mostScheduledDemos).length === 0 && mostScheduledDemos.constructor === Object)) && dispatch(showUserDashboardMostScheduledDemosSection(false));

    }

};


export const showAdminDashboardUpcomingSchedulesSection = (flag) => {
    return {
        type: SHOW_ADMIN_DASHBOARD_UPCOMING_SCHEDULES_SECTION,
        payload: flag
    }
}

export const showAdminDashboardUpcomingMaintenanceSection = (flag) => {
    return {
        type: SHOW_ADMIN_DASHBOARD_UPCOMING_MAINTENENCE_SECTION,
        payload: flag
    }
}