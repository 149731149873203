import React from "react";
import { connect, batch } from "react-redux";
import { Form, Input, Row, Col, Switch, Button, Modal, Spin } from 'antd';
import {
    toggleBackButton,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    cancelFeedbackQuestionsRoleTemplates,
    saveFeedbackQuestionsRoleTemplates,
    updateFeedbackQuestionsRoleTemplate,
    postFeedbackQuestion,
    toggleIsFetchingFeedbackQuestions,
    clearFeedbackQuestionsForm,
    fetchfeedbackQuestionById,
    currentFeedbackQuestionsFilter,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag,
    updateFeedBackQuestionsFormTabData,
    clearFormData,
    updateTabsLocalStorage,
} from "../../actions";
import FormActionButtons from "../common/FormActionButtons";
import RoleTemplatesTable from "../common/RoleTemplatesTable";
import CONFIG from "../../config";
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
import { checkRoleTemplateChanges } from "../../utils/status";
import pushMessage from '../common/PushMessage';

class FeedbackQuestionsForm extends React.Component {
    constructor(props) {
        super(props);
        this.isEditMode = this.props.match.params.feedbackQuestionId !== undefined;
        this.state = {
            visible: false,
        };
    }
    getAssignedRoleTemplates = (isSuperAdminUser) => {
        const currentQuestionsData = this.getCurrentFeedBackQuestion();
        const { data } = currentQuestionsData || {};
        const { roleTemplates } = data || [];
        let assignedRoleTemplates = [];
        if (roleTemplates) {
            roleTemplates.forEach((obj) => {
                const userAccessCondition =
                    obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none;
                const adminAccessCondition =
                    obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none;
                if (
                    isSuperAdminUser
                        ? userAccessCondition || adminAccessCondition
                        : userAccessCondition
                ) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            });
        }
        return assignedRoleTemplates;
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    renderAssignButton = () => {
        return (
            <Button
                className="primary-action-button-bordered right-border-radius"
                onClick={this.showModal}
            >
                Assign
            </Button>
        );
    };

    handleRoleTemplatesCancel = (e) => {
        const { feedbackQuestionId } = this.props.match.params;
        const id = feedbackQuestionId !== undefined ? feedbackQuestionId : "-1";
        this.props.cancelFeedbackQuestionsRoleTemplates(id, false);
        this.setState({
            visible: false,
        });
    };

    componentDidMount() {
        const { userRoleTemplate, previousTabs } = this.props;
        const currentPath = window.location.pathname
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const feedbackQuestionId = this.props.match.params.feedbackQuestionId;
        batch(() => {
            this.props.toggleIsFetchingFeedbackQuestions(false)
            this.props.toggleBackButton(false);
            const fetchAllRoleTemplates = isAdmin
                ? this.props.fetchAdminRoleTemplates
                : this.props.fetchRoleTemplates;
            fetchAllRoleTemplates().then(() => {
                if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                    if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {

                        batch(() => {
                            if (feedbackQuestionId) {
                                this.props.toggleIsFetchingFeedbackQuestions(true);
                                this.props.fetchfeedbackQuestionById(feedbackQuestionId);
                            }
                            this.props.updateTabsLocalStorage(currentPath);
                            this.props.updateFeedBackQuestionsFormTabData({
                                questionId: this.props.match.params?.feedbackQuestionId !== undefined
                                    ? this.props.match.params?.feedbackQuestionId
                                    : "-1",
                                questionDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                            })
                        })
                    }
                }
                else {
                    batch(async () => {
                        // Clearing the old form data - could be uncleared CREATE form / UPDATE form
                        await this.props.clearFormData(CONFIG.createComponentRoute.feedbackQuestions.title, (this.props.match.params.feedbackQuestionId || '-1'));
                        if (feedbackQuestionId) {
                            this.props.toggleIsFetchingFeedbackQuestions(true);
                            this.props.fetchfeedbackQuestionById(feedbackQuestionId);
                        }
                    })
                }
            });
        });
    }

    getCurrentFeedBackQuestion = () => {
        const { feedBackQuestionsTabData } = this.props;
        const { feedbackQuestionId } = this.props.match.params;
        const id = feedbackQuestionId !== undefined ? feedbackQuestionId : "-1";

        return feedBackQuestionsTabData?.find(item => (
            (parseInt(item?.id) === parseInt(id))));
    }

    getCurrentData = (formData) => {
        return {
            title: formData.title,
            isActive: formData.isActive,
            roleTemplate: formData?.roleTemplate
        }
    }

    handleCreate = () => {
        const { form } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                this.props.toggleIsFetchingFeedbackQuestions(true)
                this.props.postFeedbackQuestion(values, (this.props.match.params.feedbackQuestionId || "-1"))
            } else {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return;
            }
        });
    };

    checkFeedBackQuestionFormChanged = (currentFeedbackQuestionForm, isSuperAdminUser) => {
        const currentQuestions = this.getCurrentFeedBackQuestion();
        const { originalData, data } = currentQuestions || [];
        const { title, isActive, roleTemplates } = originalData || {}
        const { feedbackQuestionId } = this.props.match.params;
        let hasChanged = false;

        if (feedbackQuestionId) {
            hasChanged = currentFeedbackQuestionForm?.title !== title
                || currentFeedbackQuestionForm?.isActive !== isActive
                || checkRoleTemplateChanges(data?.roleTemplates, roleTemplates, isSuperAdminUser)
        }

        if (hasChanged) return hasChanged;
        return hasChanged;
    }

    handleRoleTemplatesAssign = () => {
        const { feedbackQuestionId } = this.props.match.params;
        const id = feedbackQuestionId !== undefined ? feedbackQuestionId : "-1";
        this.props.saveFeedbackQuestionsRoleTemplates(id, false);
        this.setState({ visible: false });
    };

    componentWillUnmount() {
        // clear the corresponding form data from Store.
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.props.clearFeedbackQuestionsForm();
    }

    componentDidUpdate() {
        const { openedTabs, activeKey, userRoleTemplate } = this.props;
        const { feedbackQuestionId } = this.props.match.params;
        const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const currentFeedbackQuestionForm = this.getCurrentData(this.props.form.getFieldsValue());
        this.hasChanged = feedbackQuestionId ? this.checkFeedBackQuestionFormChanged(currentFeedbackQuestionForm, isSuperAdminUser) : true;

        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, true)
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false)
    }

    render() {
        const { form, allRoleTemplates, userRoleTemplate, isFetching } = this.props;
        const currentFeedbackQuestion = this.getCurrentFeedBackQuestion();
        const { title, isActive, roleTemplates, allRoleTemplatesUserAccess, allRoleTemplatesAdminAccess } = currentFeedbackQuestion?.data || {}
        const { getFieldDecorator } = form;
        const { feedbackQuestionId } = this.props.match.params;
        const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);

        return (
            <div className="content-container">
                <Spin spinning={isFetching} wrapperClassName="spin-overlay">
                    <Form>
                        <Row>
                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item label="Question Title">
                                    {getFieldDecorator("title", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input the question",
                                            },
                                        ],
                                        initialValue: title ? title : "",
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item label="Assign Role Template">
                                    {getFieldDecorator("roleTemplates", {
                                        initialValue:
                                            assignedRoleTemplates.length !== 0
                                                ? assignedRoleTemplates
                                                : "None",
                                    })(<Input disabled addonAfter={this.renderAssignButton()} />)}
                                    {
                                        <Modal
                                            title="Role Template"
                                            visible={this.state.visible}
                                            onCancel={this.handleRoleTemplatesCancel}
                                            footer={
                                                <FormActionButtons
                                                    okText="Assign"
                                                    handleCancel={this.handleRoleTemplatesCancel}
                                                    handleSubmit={this.handleRoleTemplatesAssign}
                                                />
                                            }
                                        >
                                            <RoleTemplatesTable
                                                hideColumns={isSuperAdminUser ? [] : ["admin"]}
                                                dataKey="roleTemplate"
                                                data={allRoleTemplates}
                                                pagination={false}
                                                assignedData={roleTemplates}
                                                allUserAccess={allRoleTemplatesUserAccess}
                                                allAdminAccess={allRoleTemplatesAdminAccess}
                                                updateRoleTemplateData={this.props.updateFeedbackQuestionsRoleTemplate}
                                                recordId={feedbackQuestionId ? feedbackQuestionId : "-1"}
                                                isClone={false}
                                            />
                                        </Modal>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4} sm={4} xs={24}>
                                <Form.Item label="Status">
                                    {getFieldDecorator("isActive", {
                                        valuePropName: "checked",
                                        initialValue: isActive,
                                    })(
                                        <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="right-align">
                            <FormActionButtons
                                handleSubmit={this.handleCreate}
                                cancelText={"Discard & Close"}
                                isDisabled={!this.hasChanged}
                                handleCancel={() => this.props.closeCurrentOpenTab()}
                                okText={this.isEditMode ? "Update" : "Create"}
                            />
                        </Row>
                    </Form>
                </Spin>
            </div>
        );
    }
}

const FeedbackQuestionsFormRef = Form.create()(FeedbackQuestionsForm);

const mapStateToProps = ({ roleTemplates, user, feedbackQuestionsForm, tabsLayout }) => {
    return {
        questionDetails: feedbackQuestionsForm,
        feedBackQuestionObj: feedbackQuestionsForm?.feedBackQuestionObj,
        allRoleTemplates: roleTemplates.all,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        openedTabs: tabsLayout.panes,
        isFetching: feedbackQuestionsForm.isFetching,
        activeKey: tabsLayout.activeKey,
        previousTabs: tabsLayout.previousTabs,
        feedBackQuestionsTabData: feedbackQuestionsForm?.tabData
    };
};

export default connect(mapStateToProps,
    {
        toggleBackButton,
        fetchAdminRoleTemplates,
        fetchRoleTemplates,
        cancelFeedbackQuestionsRoleTemplates,
        saveFeedbackQuestionsRoleTemplates,
        updateFeedbackQuestionsRoleTemplate,
        postFeedbackQuestion,
        clearFeedbackQuestionsForm,
        toggleIsFetchingFeedbackQuestions,
        fetchfeedbackQuestionById,
        currentFeedbackQuestionsFilter,
        closeCurrentOpenTab,
        toggleHasUnsavedChangesFlag,
        updateFeedBackQuestionsFormTabData,
        clearFormData,
        updateTabsLocalStorage
    })
    (FeedbackQuestionsFormRef);
