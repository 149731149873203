import { aepSchedule, aepEmail } from "../apis";
import {
    FETCH_DEMO_APPROVALS,
    FETCH_DEMO_APPROVAL_REQUEST,
    TOGGLE_IS_FETCHING_APPROVAL_REQUESTS,
    CLEAR_DEMO_APPROVALS,
    UPDATE_DEMO_APPROVAL_CURRENT_PAGE,
    LAST_DEMO_APPROVAL_FILTER,
    REFRESH_DEMO_APPROVALS,
    UPDATE_DEMO_REQUEST_EMAIL_PREVIEW_EDITOR_CONTENT,
    CLEAR_DEMO_REQUEST_EMAIL_PREVIEW
}
    from './types';
import pushMessage from '../components/common/PushMessage';
import CONFIG from '../config';

export const fetchDemosApprovals = ({ pageNumber = 1, pageSize, filterSort, status, isRefresh = false }) => async dispatch => {
    const response = await aepSchedule.get('/scheduleMaintenanceService/scheduleApproval', {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            status: status,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter
                }
            },
        }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_DEMO_APPROVALS,
            payload: [response.data, response.status] || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch approval requests');
    dispatch(toggleIsFetchingApprovalRequestsFlag(false));
    return response.data ? response.data : null;
};

export const fetchDemosApprovalRequestById = (scheduleId) => async dispatch => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/scheduleApproval/" + scheduleId);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: FETCH_DEMO_APPROVAL_REQUEST,
            payload: response.data || []
        })
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Approval Request');
    }
    dispatch(toggleIsFetchingApprovalRequestsFlag(false));
};

export const toggleIsFetchingApprovalRequestsFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_APPROVAL_REQUESTS,
        payload: flag
    }
}

export const clearDemoApprovals = () => {
    return {
        type: CLEAR_DEMO_APPROVALS
    }
}

export const refreshDemoApprovals = () => {
    return {
        type: REFRESH_DEMO_APPROVALS
    }
}


export const currentDemoApprovalFilter = (filters, status, currentPage) => {
    return {
        type: LAST_DEMO_APPROVAL_FILTER,
        payload: [filters, status, currentPage]
    }
}


export const updateDemoApprovalCurrentPage = (currentPageNumber, pageSize, approvalRequestScheduleId, approvalRequestIndex) => {
    return {
        type: UPDATE_DEMO_APPROVAL_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, approvalRequestScheduleId, approvalRequestIndex]
    }
}

export const approveOrDeclineScheduleRequest = (payload) => async dispatch => {
    const putAction = payload?.status?.includes("APPROVE") ? "approved" : "declined"
    const response = await aepSchedule.put(`/scheduleMaintenanceService/scheduleApproval/${payload?.id}`, payload)
    if (response.status === CONFIG.HTTP_STATUS.OK)
        pushMessage(CONFIG.messageType.success, `You have successfully ${putAction} the approval request!`)
    else if (response.status === CONFIG.HTTP_STATUS.FORBIDDEN)
        pushMessage(CONFIG.messageType.error, response?.data);
    else pushMessage(CONFIG.messageType.error, `Approval request could not be ${putAction}`);
}

export const getEmailTemplateForDemoRequest = (demoId, data) => async (dispatch) => {
    const response = await aepEmail({
        url: `/emailPushNotificationService/adminDashboard/preview/email/demo/${demoId}`,
        method: "POST",
        data: data
    })
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: UPDATE_DEMO_REQUEST_EMAIL_PREVIEW_EDITOR_CONTENT,
            payload: response?.data ? response?.data?.body : null
        })
    }
    return response?.data ?? {};
}

export const updateDemoRequestEmailPreviewEditorContent = (emailTemplate) => async dispatch => {
    dispatch({
        type: UPDATE_DEMO_REQUEST_EMAIL_PREVIEW_EDITOR_CONTENT,
        payload: emailTemplate
    })
}

export const clearDemoRequestEmailPreview = () => {
    return {
        type: CLEAR_DEMO_REQUEST_EMAIL_PREVIEW
    }
}