export const processEmailRedirectionLink = () => {

    if (window.location.pathname.includes("/redirect/sfdc/opportunity")) {
        sessionStorage.setItem("isSFDCRedirection", true);
        sessionStorage.setItem("sfdcURL", window.location.pathname)
    }
    //  Checking if user needs to be redirected to the approval page and not dashboard.
    else if (window.location.pathname.includes("/redirect/schedule-approvals/details")) {
        const approvalLink = window.location.pathname
        localStorage.setItem('redirectToApprovalPage', true);
        localStorage.setItem('scheduleApprovalId', approvalLink.substring(approvalLink.lastIndexOf('/') + 1));
    }
    //  Checking if user needs to be redirected to the Capability page and not dashboard.
    else if (window.location.pathname.includes("/redirect/capability-details/")) {
        const capabilityRequestLink = window.location.pathname
        localStorage.setItem('redirectToCapabilityRequest', true);
        localStorage.setItem('capabilityRequestId', capabilityRequestLink.substring(capabilityRequestLink.lastIndexOf('/') + 1));
    }

    //  Checking if user needs to be redirected to the Demo request page and not dashboard.
    else if (window.location.pathname.includes("/redirect/demo-request/details")) {
        const demoRequestLink = window.location.pathname
        localStorage.setItem('redirectToDemoRequest', true);
        localStorage.setItem('demoRequestId', demoRequestLink.substring(demoRequestLink.lastIndexOf('/') + 1));
    }

    //  Checking if user needs to be redirected to the feedback form and not dashboard.
    else if (window.location.pathname.includes("/redirect/demo-feedback")) {
        const scheduleFeedbackLink = window.location.pathname
        localStorage.setItem('redirectToFeedbackForm', true);
        localStorage.setItem('scheduleFeedbackId', scheduleFeedbackLink.substring(scheduleFeedbackLink.lastIndexOf('/') + 1));
    }

    //  Checking if user needs to be redirected to the feedback form and not dashboard.
    else if (window.location.pathname.includes("/redirect/ez-demo-feedback")) {
        const ezDemoFeedbackLink = window.location.pathname
        localStorage.setItem('redirectToEZDemoModal', true);
        localStorage.setItem('ezDemoFeedbackId', ezDemoFeedbackLink.toString().split("/").slice(-2)[0]);
        localStorage.setItem('ezDemoTitle', ezDemoFeedbackLink.toString().split("/").slice(-1)[0]);
    }

    //  Checking if user clicked on delete schedule from email
    else if (window.location.pathname.includes("/redirect/delete-schedule")) {
        const scheduleLink = window.location.pathname
        localStorage.setItem('deleteSchedule', true);
        localStorage.setItem('scheduleId', scheduleLink.substring(scheduleLink.lastIndexOf('/') + 1));

        // +--------+---------+-----------------------------------------------------------------------+
        // | Active | Deleted |                                Action                                 |
        // +--------+---------+-----------------------------------------------------------------------+
        // | ✔      | ✖       | Redirect to Dashboard, and prompt cancellation pop-up.                |
        // | ✖      | ✔       | Redirect to Dashboard, and prompt "Schedule no longer exists" pop-up. |
        // | ✔      | ✔       | Redirect to Dashboard, and prompt "Schedule no longer exists" pop-up. |
        // | ✖      | ✖       | Redirect to Dashboard, and prompt "Schedule no longer exists" pop-up. |
        // +--------+---------+-----------------------------------------------------------------------+
    }

    //  Checking if user clicked on delete schedule from email
    else if (window.location.pathname.includes("/redirect/edit-schedule")) {
        const scheduleLink = window.location.pathname
        localStorage.setItem('editSchedule', true);
        localStorage.setItem('scheduleId', scheduleLink.substring(scheduleLink.lastIndexOf('/') + 1));
    }

    else if (window.location.pathname.includes("/redirect/re-schedule")) {
        const scheduleLink = window.location.pathname
        localStorage.setItem('reSchedule', true);
        localStorage.setItem('scheduleId', scheduleLink.substring(scheduleLink.lastIndexOf('/') + 1));
    }

    else if (window.location.pathname.includes("/redirect/news")) {
        localStorage.setItem('news', true);
    }

    else if (window.location.pathname.includes("/redirect/support")) {
        localStorage.setItem('support', true);
    }

    else if (window.location.pathname.includes("/redirect/email/unsubscribe")) {
        localStorage.setItem('emailUnsubscribe', true);
    }
}