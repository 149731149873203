import React, { useEffect, useState } from 'react';
import { getBuildsById, fetchScheduleComments, addScheduleComment, updateScheduleComment, deleteScheduleComment } from "../../actions";
import { useDispatch, useSelector } from 'react-redux';
import {
    TextInput, Form as NeoForm, Select, SelectOption,
    TextArea as NeoTextArea,
    Chip, Button
} from "neo-latest";
import moment from 'moment-timezone';
import CONFIG from '../../config';
import { Spin, Comment, Form, List, Avatar, Divider, Popconfirm, DatePicker } from "antd";
import { Steps } from 'antd5';
import pushMessage from '../common/PushMessage';
import { getStartOfDateValue, range, areDatesEqual, areHoursEqual } from '../../utils/datepicker';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Step } = Steps;

function ManualDemoBuildsDetails(props) {
    const { record, readOnly, timezone, form,
        editingKey, submitting, handleCommentEdit, handleCommentCancel,
        comment, loggedInUser, handleChange, handleEndDateChange, handleEndDateSubmit, endDateTime, setIsEditing, isEditing,
    } = props;
    const [statusData, setStatusData] = useState({});
    const [userComments, setUserComments] = useState("");
    const [commentItem, setCommentItem] = useState({});
    const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
    const [loading, setLoading] = useState(false);
    const users = useSelector(({ dataCenterStatus }) => dataCenterStatus?.users || []);
    const comments = useSelector(({ demoSchedulesManual }) => demoSchedulesManual?.comments);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchBuildDetails = async () => {
            setLoading(true);
            const data = await dispatch(getBuildsById(record?.id));
            setStatusData(data);
            setLoading(false);
            dispatch(fetchScheduleComments(record.id, true));
        }
        fetchBuildDetails();
        // eslint-disable-next-line
    }, [readOnly]);

    const handleCommentDelete = async (commentId, scheduleId) => {
        setLoading(true);
        await dispatch(deleteScheduleComment(commentId, scheduleId));
        await dispatch(fetchScheduleComments(record.id, true));
        setLoading(false);
    }

    const renderStepsOptions = () => {
        return statusData?.scheduleBuildStatus?.map((item, index) => {
            return (
                <Step
                    key={index}
                    className="approvalStep"
                    title={item.buildStatus}
                    description={<span style={{ color: "#757474" }}>on {moment.tz(item.createdTime, timezone).format(CONFIG.dateFormats.userDateTime)}</span>}
                />
            )
        })
    }

    const handleCommentSubmit = async (isEdit, commentId) => {
        if (!userComments?.trim()) {
            pushMessage(CONFIG.messageType.info, "The comment field cannot be empty.")
            return;
        }
        else {
            setLoading(true);
            if (!isEdit) {
                await dispatch(addScheduleComment(userComments, statusData.id))
                    .then(() => setUserComments(""))
            }

            else {
                dispatch(updateScheduleComment(userComments, statusData.id, commentId))
                    .then(() => setUserComments(""));
                handleCommentCancel();
            }
            setUserComments("");
            setFieldsValue({ comments: "" })
            dispatch(fetchScheduleComments(record.id, true));
            setLoading(false);
        }
    }

    const CommentList = ({ comments, timeZoneName, loggedInUser, editingKey, handleCommentEdit, handleCommentDelete }) => {
        return <List
            dataSource={comments}
            header={`${comments.length} ${comments.length > 1 ? 'comments' : 'comment'}`}
            itemLayout="horizontal"
            renderItem={item => {
                return (
                    <li>
                        {editingKey !== item.id
                            && <Comment
                                content={item.comment}
                                datetime={moment.tz(item.commentDate, timeZoneName).format(CONFIG.dateFormats.userDateTime)}
                                avatar={<Avatar style={{ color: '#FFFFF', backgroundColor: '#003A51', verticalAlign: 'middle' }}> {item.commentedBy?.firstName?.charAt(0)}</Avatar>}
                                author={item.commentedBy?.fullName}
                                actions={(item.commentedBy?.id === loggedInUser.id || loggedInUser.precedenceTemplateType === CONFIG.roleTypes.superAdmin) && editingKey === "" &&
                                    [<span key="comment-list-reply-to-0" onClick={() => {
                                        setCommentItem(item);
                                        setUserComments(item?.comment);
                                        setFieldsValue({ comments: item?.comment });
                                        handleCommentEdit(item.id, item.comment)
                                    }} style={{ paddingRight: 0 }} >Edit</span>, <Divider type="vertical" />,
                                    <Popconfirm title="Confirm Delete?" okText="Yes" cancelText="No" onConfirm={() => handleCommentDelete(item?.id, statusData?.id)}><span key="comment-list-reply-to-0">Delete</span></Popconfirm>
                                    ]
                                }
                            />
                        }
                    </li>
                )
            }}
        />
    }

    const renderBuildStatus = () => {
        const record = statusData;
        const options = CONFIG.manualBuildStatusDataSet[record?.buildStatus]?.map(item => {
            return (
                <SelectOption key={item} value={item}>
                    {item}
                </SelectOption>
            );
        });
        const assignedOptions = <SelectOption disabled key={record?.buildStatus} value={record?.buildStatus}>{record?.buildStatus}</SelectOption>
        return [assignedOptions, ...(options?.length > 0 ? options : [])];
    }

    const renderUsers = () => {
        if (users) {
            return users?.map(user => {
                return (
                    <SelectOption key={user.id} value={JSON.stringify({ id: user?.id, fullName: user?.fullName })}>
                        {user.fullName}
                    </SelectOption>
                );
            });
        }
        return;
    };

    const disabledDate = end => {
        let { startTime } = statusData;
        const endValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timezone, date: moment(end) });
        const startValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timezone, date: moment(startTime) });
        const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timezone });

        return endValue < startValue || endValue < currentValue;
    };

    const disabledTime = (current) => {
        const { startTime } = statusData;
        const startValue = moment.tz(startTime, timezone)
        if (startValue && areDatesEqual(startValue, current)) {
            return {
                disabledHours: () => range(0, startValue.get('hours')),
                disabledMinutes: () => areHoursEqual(startValue, current) ? range(0, startValue.get('minute')) : []
            };
        }
    }

    const isEditDisabled = statusData?.buildStatus === "Torn Down" || statusData?.buildStatus === "Closed"

    return (
        <Spin spinning={loading} indicator={antIcon}>
            <NeoForm>
                <Form>
                    <div>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: "flex", flex: "1" }}>
                                <TextInput label="Request No." value={(statusData?.referenceNo || "-")} readOnly />
                            </div>
                            <div style={{ display: "flex", flex: "1" }}>
                                <TextInput label="Created By" value={(statusData?.createdBy?.fullName || "-")} readOnly />
                            </div>
                        </div>
                        <div style={{ marginTop: "16px", display: "flex", flexDirection: "column", gap: "16px" }}>
                            <div>
                                <TextInput label="Start Date" value={statusData?.startTime ? moment.tz(statusData?.startTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                            </div>
                            <div>
                                {(readOnly || (!readOnly && !isEditing))
                                    ? <div>
                                        <TextInput label="End Date" value={statusData?.endTime ? moment.tz(statusData?.endTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                                        {!readOnly && <div>
                                            <button disabled={isEditDisabled} onClick={() => setIsEditing(true)} className={!isEditDisabled ? "link" : "link-disabled"}>Edit</button>
                                        </div>}
                                    </div>
                                    : <span>
                                        <DatePicker
                                            showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                                            format={CONFIG.dateFormats.default}
                                            onChange={handleEndDateChange}
                                            value={endDateTime}
                                            className="right-spacing-5"
                                            disabledTime={disabledTime}
                                            disabledDate={disabledDate}
                                            showToday={false}
                                        />
                                        {(!readOnly || isEditing) &&
                                            <span> <span onClick={async () => {
                                                setLoading(true);
                                                await handleEndDateSubmit();
                                                const data = await dispatch(getBuildsById(record?.id));
                                                setStatusData(data);
                                                setLoading(false);
                                            }} className="link">Save</span> <Divider type="vertical" /> <span onClick={() => setIsEditing(false)} className="link">Close</span></span>
                                        }
                                    </span>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: "16px", fontSize: "12px", fontWeight: "400", color: "#323232", lineHeight: "16px", marginBottom: "4px" }}>
                            Demo Title
                        </div>
                        <div style={{ padding: "12px 0", fontSize: "14px", fontWeight: "400", color: "#000", lineHeight: "20px" }}>
                            {(statusData?.demo?.title || "-")}
                        </div>
                        <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
                            <div style={{ flex: "1" }}>
                                {readOnly ? <> <div style={{ fontSize: "12px", color: "#242424" }}>Build Status</div>
                                    <Chip style={{ margin: "8px 0px", textAlign: "center" }} variant={CONFIG.demoBuildStatusChips[statusData?.buildStatus]} >{statusData?.buildStatus}</Chip></> :
                                    <Form.Item>
                                        {
                                            getFieldDecorator("buildStatus", {
                                                initialValue: statusData?.buildStatus
                                            })(<Select
                                                label="Build Status"
                                                required
                                                className="remove-padding-style"
                                                disabled={statusData.buildStatus === "New" || statusData?.assignedUser == null}
                                            >
                                                {renderBuildStatus(statusData)}
                                            </Select>)
                                        }
                                    </Form.Item>
                                }
                            </div>
                            <div style={{ flex: "1" }}>
                                {readOnly ? <TextInput label="Assigned User" value={statusData?.assignedUser?.fullName || "-"} readOnly /> :
                                    <Form.Item style={{ width: "100%" }}>
                                        {
                                            getFieldDecorator("assignedUser", {
                                                rules: [{ required: true, message: "Please select the user." }],
                                                initialValue: statusData?.assignedUser ? JSON.stringify({ id: statusData?.assignedUser?.id, fullName: statusData?.assignedUser?.fullName }) : undefined
                                            })(<Select
                                                label="Assigned User"
                                                required
                                                className="remove-padding-style"
                                            >
                                                {renderUsers()}
                                            </Select>)
                                        }
                                    </Form.Item>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
                            <div style={{ display: "flex", flex: "1" }}>
                                {readOnly ? <TextInput label="Ticket No." value={statusData?.ticketNo || "-"} readOnly />
                                    : <Form.Item style={{ width: "226px" }}>
                                        {
                                            getFieldDecorator("ticketNo", {
                                                initialValue: statusData?.ticketNo
                                            })(<TextInput label="Ticket No." disabled={statusData?.assignedUser === null} />)
                                        }
                                    </Form.Item>
                                }
                            </div>
                            <div style={{ display: "flex", flex: "1" }}>
                                {readOnly ? <TextInput label="Build ID" value={statusData?.demoBuildId || "-"} readOnly />
                                    : <Form.Item style={{ width: "226px" }}>
                                        {
                                            getFieldDecorator("demoBuildId", {
                                                rules: [{
                                                    message: `Please enter a value!`,
                                                    required: (getFieldValue("buildStatus") !== "New" && getFieldValue("buildStatus") !== "Assigned" && getFieldValue("buildStatus") !== "Closed" && getFieldValue("buildStatus") !== "Canceled" && getFieldValue("buildStatus") !== "Expired") ? true : false,
                                                }],
                                                initialValue: statusData?.demoBuildId
                                            })(<TextInput required={(getFieldValue("buildStatus") !== "New" && getFieldValue("buildStatus") !== "Assigned" && getFieldValue("buildStatus") !== "Closed" && getFieldValue("buildStatus") !== "Canceled" && getFieldValue("buildStatus") !== "Expired") ? true : false} label="Build ID" disabled={statusData?.assignedUser === null} />)
                                        }
                                    </Form.Item>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
                            <div style={{ display: "flex", flex: "1" }}>
                                {readOnly ? <TextInput label="Env. Name" value={statusData?.envName || "-"} readOnly /> :
                                    <Form.Item style={{ width: "226px" }}>
                                        {
                                            getFieldDecorator("envName", {
                                                initialValue: statusData?.envName
                                            })(<TextInput label="Env. Name" disabled={statusData?.assignedUser === null} />)
                                        }
                                    </Form.Item>
                                }
                            </div>
                            <div style={{ display: "flex", flex: "1" }}>
                                {readOnly ? <TextInput label="Region" value={statusData?.region || "-"} readOnly /> :
                                    <Form.Item style={{ width: "226px" }}>
                                        {
                                            getFieldDecorator("region", {
                                                rules: [{
                                                    message: `Please enter a value!`,
                                                    required: (getFieldValue("buildStatus") !== "New" && getFieldValue("buildStatus") !== "Assigned" && getFieldValue("buildStatus") !== "Closed" && getFieldValue("buildStatus") !== "Canceled" && getFieldValue("buildStatus") !== "Expired") ? true : false,
                                                }],
                                                initialValue: statusData?.region
                                            })(<TextInput required={(getFieldValue("buildStatus") !== "New" && getFieldValue("buildStatus") !== "Assigned" && getFieldValue("buildStatus") !== "Closed" && getFieldValue("buildStatus") !== "Canceled" && getFieldValue("buildStatus") !== "Expired") ? true : false} label="Region" disabled={statusData?.assignedUser === null} />)
                                        }
                                    </Form.Item>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
                            <div style={{ flex: "1" }}>
                                <TextInput label="Customer Name" value={statusData?.customerName || "-"} readOnly />
                            </div>
                            <div style={{ flex: "1" }}>
                                <TextInput label="Demo Type" value={(statusData?.demo?.demoType || "-")} readOnly />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                        <Steps current={statusData?.scheduleBuildStatus?.length} direction="vertical">
                            {renderStepsOptions(statusData)}
                        </Steps>
                    </div>
                    <div style={{ marginTop: "16px" }}>
                        {comments?.length > 0 && <CommentList
                            comments={comments}
                            timeZoneName={timezone}
                            loggedInUser={loggedInUser}
                            onChange={handleChange}
                            submitting={submitting}
                            value={comment}
                            handleCommentEdit={handleCommentEdit}
                            editingKey={editingKey}
                            handleCommentDelete={handleCommentDelete}
                            selectedSchedule={statusData}
                            handleCommentCancel={handleCommentCancel}
                        />}
                    </div>
                    {editingKey === "" && <div style={{ marginTop: "16px" }}>
                        <Comment
                            content={
                                <div>
                                    <Form.Item>
                                        {
                                            getFieldDecorator("comments")(
                                                <NeoTextArea label="Comments" rows={4}
                                                    onChange={(e) => {
                                                        setUserComments(e.target.value);
                                                    }}
                                                />
                                            )
                                        }
                                    </Form.Item>
                                    {editingKey === "" && <Form.Item>
                                        <Button onClick={(e) => { handleCommentSubmit(); e.preventDefault(); }} >
                                            Add Comment
                                        </Button>
                                    </Form.Item>}
                                </div>
                            }
                        />
                    </div>}
                    {editingKey === commentItem?.id && <Comment
                        datetime={moment.tz(commentItem.commentDate, timezone).format(CONFIG.dateFormats.userDateTime)}
                        avatar={<Avatar style={{ color: '#FFFFF', backgroundColor: '#003A51', verticalAlign: 'middle' }}> {commentItem.commentedBy?.firstName?.charAt(0)}</Avatar>}
                        author={commentItem.commentedBy?.fullName}
                        content={
                            <Form.Item>
                                {
                                    getFieldDecorator("comments")(
                                        <NeoTextArea label="Comments" rows={4}
                                            onChange={(e) => {
                                                setUserComments(e.target.value);
                                            }}
                                            value={comment}
                                        />
                                    )
                                }
                            </Form.Item>
                        }
                        actions={[<button key="comment-list-reply-to-0" className="link" onClick={async () => {
                            setLoading(true);
                            await handleCommentSubmit(true, commentItem.id)
                            setUserComments("");
                            setFieldsValue({ comments: "" })
                            await dispatch(fetchScheduleComments(record.id, true));
                            setLoading(false);
                            setCommentItem({})
                        }} >Save</button>,
                        <Divider type="vertical" />, <button key="comment-list-reply-to-0" className="link" onClick={() => {
                            setUserComments("");
                            setFieldsValue({ comments: "" });
                            handleCommentCancel(commentItem.id, commentItem.comment)
                            setCommentItem({})
                        }} >Cancel</button>
                        ]}
                    />}
                </Form>
            </NeoForm>
        </Spin>
    )
}

export default ManualDemoBuildsDetails