import {
    FETCH_ALL_EXISTING_DEMOS,
    FETCH_ALL_RESOURCES
} from '../actions/types';

const filterPanelState = {
    allDemos: [],
};
export const filterPanelReducer = (state = filterPanelState, action) => {
    switch (action.type) {
        case FETCH_ALL_EXISTING_DEMOS:
            return { ...state, allDemos: action.payload.dataSet || [] };
        case FETCH_ALL_RESOURCES:
            return { ...state, allResources: action.payload.dataSet || [] };
        default:
            return state;
    }
};
