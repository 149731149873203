import React, { Component } from 'react'
import {
    selectTitle,
    fetchUserMyReports,
    toggleIsFetchingUserReports,
    exportTable,
    toggleBackButton,
    fetchTrendingDemos,
    openLinkInNewTab,
    fetchUserMyReportsLite,
    exportCommonStandardReport
} from '../actions';
import { getReportExportData } from "../actions/reports";
import pushMessage from './common/PushMessage';
import { Link } from 'react-router-dom';
import { batch, connect } from 'react-redux';
import { Spin, Row, Col, Card, Tooltip, Tag, Icon, message } from 'antd';
import CONFIG from "../config"
import moment from 'moment-timezone';
import Highlighter from 'react-highlight-words';
import { LoadingOutlined } from '@ant-design/icons';
import { Icon as NeoIcon } from "@avaya/neo-react";
import ViewReports from './common/ViewReports';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class MyReports extends Component {

    state = {
        loading: true,
    }

    componentDidMount() {
        const { filterSort } = this.props;
        batch(async () => {
            this.props.selectTitle("My Reports");
            this.props.toggleIsFetchingUserReports(true);
            setTimeout(async () => {
                this.props.toggleIsFetchingUserReports(true);
                await this.props.fetchUserMyReportsLite(filterSort || { "filter": {} });
                this.setState({
                    loading: false
                })
            }, 1800)
        })
    }

    handleExport = async (record) => {
        const { timeZoneName } = this.props;
        const userRunReport = "fromUser";
        let reportName = record?.name;
        reportName = reportName + ".xlsx";
        const isDefault = record?.default;
        let reportData;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const filterSort = JSON.parse(record?.filterSortObject)
        if(!isDefault) {
            reportData = await getReportExportData({ reportId: record.id })
        }
            (!isDefault?
                exportTable(CONFIG.exportTable.report.name,  reportData.dataSet, timeZoneName, reportName, "xlsx",  reportData.exportViewColumns)
                : this.props.exportCommonStandardReport({ name: reportName, filterSort: filterSort, userRunReport, reportId: record?.id, reportKey: record?.reportKey })
                ).then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Report exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export report")
                })
    }

    getTimeLine = (startTime, endTime) => {
        let timeLine;
        const dateDiff = moment.tz(endTime, this.props.timeZoneName).diff(moment.tz(startTime, this.props.timeZoneName), 'days');
        if (dateDiff <= 7) {
            timeLine = CONFIG.trendingDemosTimeline[0]
        } else if (dateDiff > 7 && dateDiff <= 31) {
            timeLine = CONFIG.trendingDemosTimeline[1]
        } else {
            timeLine = CONFIG.trendingDemosTimeline[2]
        }
        return timeLine;
    }

    renderTitle = (title) => {
        const { universalSearchString } = this.props?.location || this.props;
        return (
            <Highlighter
                searchWords={[universalSearchString]}
                autoEscape={true}
                textToHighlight={title}
            />
        )
    }

    renderReportsCard = () => {
        let { reports, timeZoneName } = this.props;
        const { reportId } = this.props?.match?.params || this.props;
        if (reportId) reports = reports.filter(report => report.id.toString() === reportId)
        return (
            <>
                <Row gutter={10}>
                    {reports.map((report) => {
                        const cardTag = report?.isParentUpdatedFlag ? "Updated" : report?.isNew ? "New" : undefined;
                        const createdString = cardTag === "New" ? "Created" : cardTag === "Updated" ? "Updated" : undefined;
                        const reportUpdatedTSTag = `${createdString}: ${moment.tz(cardTag === "New" ? report.createdTime : cardTag === "Updated" ? report.parentUpdatedTime : undefined, timeZoneName).format(CONFIG.dateFormats.userDateTime)}`;
                        return (
                            <>
                                <Col xl={6} sm={24} xs={24} className="report-card-col" span={7}>
                                    <Card
                                        headStyle={{ height: '5px' }}
                                        bodyStyle={{ height: '27vh', overflowY: 'auto' }} id="demo-card"
                                        title={<>
                                            {cardTag && <Tooltip title={reportUpdatedTSTag}>
                                                <Tag
                                                    color={cardTag === "New" ? "red" : "blue"}>{cardTag}
                                                </Tag>
                                            </Tooltip>}
                                            <span className="demo-card-body-details bold">{this.renderTitle(report.name)}</span>
                                        </>}
                                        bordered={true}
                                        actions={[
                                            <Link to={{ pathname: `/reports/view/${report?.id}`, report, isMyReport: true }}>
                                                <span className="report-card-actions">View</span>
                                            </Link>,
                                            <Tooltip title="Export"><Icon type="cloud-download" key="download" onClick={() => this.handleExport(report)} /></Tooltip>
                                        ]}>
                                        {this.renderTitle(report.description)}
                                    </Card>
                                </Col>
                            </>
                        )
                    })}
                </Row>
            </>
        )
    }
    render() {
        const { isFetching, reports } = this.props;
        const { reportId } = this.props?.match?.params || this.props;
        const { loading } = this.state;
        return (
            <Spin indicator={antIcon} spinning={isFetching || loading}>
                <div>
                    {reports && reports.length > 0 ? <ViewReports
                        reports={reports}
                        timeZoneName={this.props.timeZoneName}
                        handleExport={this.handleExport}
                        reportId={reportId}
                        fromMyReports={true}
                    /> : <div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Reports Found</div>}
                </div>
            </Spin>
        )
    }
}

const mapStateToProps = ({ userReports, user }) => {
    return {
        reports: userReports.userMyReports,
        isFetching: userReports.isFetching,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
    }
}

export default connect(mapStateToProps, {
    selectTitle,
    fetchUserMyReports,
    toggleIsFetchingUserReports,
    toggleBackButton,
    fetchTrendingDemos,
    openLinkInNewTab,
    fetchUserMyReportsLite,
    exportCommonStandardReport
})
    (MyReports);
