import React, { Component } from 'react'
import {
    selectTitle,
    toggleBackButton,
    fetchUserStandardReports,
    toggleIsFetchingUserReports,
    exportTable,
    postSaveAsReport,
    fetchTrendingDemos,
    openLinkInNewTab,
    fetchUserStandardReportsLite,
    exportCommonStandardReport
} from '../actions';
import pushMessage from './common/PushMessage';
import { Card, Spin, Row, Col, Tooltip, Icon, Tag, message, Button, Modal, Input, Form } from 'antd';
import { batch, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CONFIG from "../config"
import moment from 'moment-timezone';
import { getReportExportData } from "../actions/reports";
import Highlighter from 'react-highlight-words';
import { LoadingOutlined } from '@ant-design/icons';
import { Icon as NeoIcon } from "@avaya/neo-react";
import ViewReports from './common/ViewReports';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Reports = Form.create({ name: "view_all_reports" })(
    class Reports extends Component {

        state = {
            modalVisible: false,
            loading: true,
            modalLoading: false
        }

        componentDidMount() {
            document.getElementById("tab-pane").scrollIntoView({ behavior: "smooth" });
            const {filterSort} = this.props;
            batch(async () => {
                this.props.selectTitle("Standard Reports");
                await this.props.toggleIsFetchingUserReports(true);
                await this.props.fetchUserStandardReportsLite(filterSort || {"filter":{}});
            })
            setTimeout(() => {
                this.setState({ loading: false })
            }, 1800)
        }

        handleModalOpen = (report) => {
            this.setState({ modalVisible: true })
            this.reportToBeClone = report
        }

        handleModalClose = () => {
            this.props.form.resetFields()
            this.setState({ modalVisible: false })
        }

        handleOk = async () => {
            let reportName;
            this.props.form.validateFields((err, values) => {
                reportName = values?.reportName;
            });
            reportName = reportName?.trim();
            if (reportName?.length > 0 && !this.state.modalLoading) {
                await this.setState({modalLoading: true})
                await this.props.postSaveAsReport(reportName, JSON.parse(this.reportToBeClone?.filterSortObject), this.reportToBeClone, true);
                await this.setState({modalLoading: false})
                this.handleModalClose();
            }
            else {
                pushMessage(CONFIG.messageType.warning, "Please Enter the report name")
            }
        }

        handleExport = async (record) => {
            const { timeZoneName } = this.props;
            const userRunReport = "fromUser";
            let reportName = record?.name;
            reportName = reportName + ".xlsx";
            const isDefault = record?.default;
            let reportData;
            pushMessage(CONFIG.messageType.loading, "Exporting", 0);
            const filterSort = JSON.parse(record?.filterSortObject)
            if(!isDefault) {
                reportData = await getReportExportData({ reportId: record.id })
            }
            (!isDefault?
                exportTable(CONFIG.exportTable.report.name,  reportData.dataSet, timeZoneName, reportName, "xlsx",  reportData.exportViewColumns)
                : this.props.exportCommonStandardReport({ name: reportName, filterSort: filterSort, userRunReport, reportId: record?.id, reportKey: record?.reportKey })
                ).then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Report exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export report")
                })
        }

        getTimeLine = (startTime, endTime) => {
            let timeLine;
            const dateDiff = moment.tz(endTime, this.props.timeZoneName).diff(moment.tz(startTime, this.props.timeZoneName), 'days');
            if (dateDiff <= 7) {
                timeLine = CONFIG.trendingDemosTimeline[0]
            } else if (dateDiff > 7 && dateDiff <= 31) {
                timeLine = CONFIG.trendingDemosTimeline[1]
            } else {
                timeLine = CONFIG.trendingDemosTimeline[2]
            }
            return timeLine;
        }

        renderTitle = (title) => {
            const { universalSearchString } = this.props?.location || this.props;
            return (
                <Highlighter
                    searchWords={[universalSearchString]}
                    autoEscape={true}
                    textToHighlight={title}
                />
            )
        }

        renderReportsCard = () => {
            let { reports, timeZoneName } = this.props;
            const { reportId } = this.props?.match?.params || this.props;

            if (reportId) reports = reports?.filter(report => report.id.toString() === reportId)

            return (
                <>
                    <Row gutter={10}>
                        {reports.map((report) => {
                            const cardTag = report?.isParentUpdatedFlag ? "Updated" : report?.isNew ? "New" : undefined;
                            const createdString = cardTag === "New" ? "Created" : cardTag === "Updated" ? "Updated" : undefined;
                            const reportUpdatedTSTag = `${createdString}: ${moment.tz(cardTag === "New" ? report.createdTime : cardTag === "Updated" ? report.parentUpdatedTime : undefined, timeZoneName).format(CONFIG.dateFormats.userDateTime)}`;
                            return (
                                <>
                                    <Col xl={6} sm={24} xs={24} className="report-card-col" span={7}>
                                        <Card
                                            className={!report?.default ? 'left-border-secondary' : 'left-border-primary'}
                                            headStyle={{ height: '5px' }}
                                            bodyStyle={{ height: '27vh', overflowY: 'auto' }} id="demo-card"
                                            title={<>

                                                <span
                                                    className="reports-card-body-details bold"
                                                >
                                                    {cardTag && <Tooltip title={reportUpdatedTSTag}>
                                                        <Tag
                                                            color={cardTag === "New" ? "red" : "blue"}>{cardTag}
                                                        </Tag>
                                                    </Tooltip>}<Tooltip placement="topLeft" title={report.name}> {this.renderTitle(report.name)}</Tooltip></span>
                                            </>}
                                            bordered={true}
                                            actions={[
                                                <Link to={{ pathname: `/reports/view/${report?.id}`, report, isMyReport: false }}>
                                                    <span className="report-card-actions">View</span>
                                                </Link>,
                                                <Tooltip title="Export"><Icon type="cloud-download" key="download" onClick={() => this.handleExport(report)} /></Tooltip>,
                                                <Tooltip title="Clone"><Icon type="copy" key="clone" onClick={() => this.handleModalOpen(report)} />
                                                </Tooltip>
                                            ]}>
                                            {this.renderTitle(report.description)}
                                        </Card>
                                    </Col>
                                </>
                            )
                        })}
                    </Row>
                </>
            )
        }

        render() {
            let { reports, isFetching } = this.props;
            const { loading, modalLoading } = this.state;
            const { getFieldDecorator } = this.props.form;
            const { reportId } = this.props?.match?.params || this.props;
            return (
                <Spin indicator={antIcon} spinning={isFetching || loading}>
                    <div>
                        {reports && reports.length > 0 ? <ViewReports
                            reports = {reports}
                            timeZoneName = {this.props.timeZoneName}
                            handleExport = {this.handleExport}
                            handleModalOpen = {this.handleModalOpen}
                            reportId = {reportId}
                            fromMyReports = {false}
                        /> : <div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Reports Found</div>}
                        <Modal
                            title="Clone"
                            visible={this.state.modalVisible}
                            onCancel={this.handleModalClose}
                            onOk={this.handleOk}
                            footer={[
                                <Button key="back" onClick={this.handleModalClose}>
                                    Cancel
                                </Button>,
                                <Button disabled = {modalLoading} key="submit" className="primary-action-button-filled" type="primary" onClick={this.handleOk}>
                                    Clone
                                </Button>,
                            ]}
                        >
                            <Spin indicator={antIcon} spinning={modalLoading}>
                            <Form.Item label="Report Name">
                                {getFieldDecorator("reportName", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input the Report name"
                                        }
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            </Spin>
                        </Modal>
                    </div>
                </Spin>
            )
        }
    }
)
const mapStateToProps = ({ user, userReports }) => {
    return {
        reports: userReports.userReports,
        isFetching: userReports.isFetching,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
    }
}

export default connect(mapStateToProps, {
    selectTitle,
    toggleBackButton,
    fetchUserStandardReports,
    toggleIsFetchingUserReports,
    exportTable,
    fetchTrendingDemos,
    postSaveAsReport,
    openLinkInNewTab,
    fetchUserStandardReportsLite,
    exportCommonStandardReport
})
    (Reports);
