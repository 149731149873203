import { batch } from 'react-redux';
import React from 'react';
import moment from 'moment-timezone';

import {
    FETCH_USER,
    UPDATE_USER_TIME_ZONE,
    TOGGLE_IS_FIRST_TIME_LOGIN,
    SET_IS_LOGIN_COMPLETE,
    FETCH_USER_GROUPS,
    TOGGLE_IS_USER_LOADING,
    RESET_LDAP_PASSWORD,
    UPDATE_USER_EMAIL_SUBSCRIPTION,
    UPDATE_INVITE_OPT_OUT_SUBSCRIPTION,
    SET_HAS_PASSWORD_CHANGED,
    FETCH_DECRYPTED_LDAP_PASSWORD,
    UPDATE_SA_USER_TAB_LIMIT,
    FETCH_TAB_LIMITS_FOR_USERS,
    UPDATE_USER_PROFILE_PIC,
    UPDATE_TESTING_RECIPIENTS,
    SHOW_PORTAL_TOUR_MODAL,
    SET_PORTAL_TOUR_COMPLETE
} from './types';
import { aepUser, initializeInterceptors, aepLDAP } from '../apis';
import { updateSchedulerTimezone } from './scheduler';
import { clearTabStoreData, closeAllTabs, addNewTab, redirectToDashboard } from './tabsLayout';
import CONFIG from "../config"
import pushMessage from '../components/common/PushMessage';
import history from '../history';
import cookie from 'react-cookies'
import openInfo from '../components/common/Info';
import openSuccess from '../components/common/success';
import { removeAllCookies, getUserFromCookies } from '../utils/cookies';
import { download } from '../utils/file';

const contactSupport = () => window.open(CONFIG.contactUsLink);

export const fetchUserDetails = (user) => async (dispatch, getState) => {
    const { email, linkId, companyName, dn } = user;
    let headers = undefined;
    if (email) {
        const jwtToken = cookie.load('__s2jt');
        if (jwtToken !== undefined) {
            headers = {
                'aep-header': jwtToken
            };
        }
        const params = {
            linkId,
            companyName,
            dn
        };

        const requestConfig = headers ? { params, headers } : { params };
        let response = await aepUser.get(`/userTemplateService/configDetails/${email}`, requestConfig);
        const showErrorInfo = response.data ? CONFIG.warningMessages[response.data.errorString] !== undefined : false;
        const aepHeader = response.headers['aep-header']
        if (response.status === CONFIG.HTTP_STATUS.OK) {
            initializeInterceptors(aepHeader);
            batch(() => {
                dispatch({
                    type: FETCH_USER,
                    payload: response.data
                });
                const state = getState();
                const isSuperAdminUser = state.user && state.user?.profile?.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
                isSuperAdminUser && dispatch(fetchUserTabLimits());
                dispatch(updateSchedulerTimezone(response.data.user.timezone));
                dispatch(toggleIsFirstTimeLogin(false));
                !response.data.user.firstTimeLogin && dispatch(showPortalTourModal(!(response.data.user.isPortalTourCompleted), false));
                dispatch(setIsLoginComplete(aepHeader));
            });
            removeAllCookies(['isNotificationsPushed', 'ssoComplete']);
            if (showErrorInfo) openInfo(CONFIG.warningMessages.userMigratedToSSO.title, CONFIG.warningMessages.userMigratedToSSO.content, null, "OK");
        }
        else if (response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
            openInfo(CONFIG.systemMessage.firstTimeLoginTitle, CONFIG.systemMessage.firstTimeLoginContentSSO, null, "OK");
            // history.replace({ pathname: '/my-profile' });
            dispatch(closeAllTabs());
            dispatch(addNewTab('Welcome', "/my-profile"));
            history.replace("/my-profile");
            dispatch(toggleIsFirstTimeLogin(true));
            dispatch(setIsLoginComplete(aepHeader))
        }
        else if (showErrorInfo) openInfo(CONFIG.warningMessages[response.data.errorString].title, CONFIG.warningMessages[response.data.errorString].content, contactSupport, "Contact Support");
        else {
            pushMessage(CONFIG.messageType.error, 'Unable to fetch user details');
        }
    }
    else openInfo(CONFIG.warningMessages.invalidSSO.title, CONFIG.warningMessages.invalidSSO.content, contactSupport, 'Contact Support');
};

export const postUserDetails = (timezone, emailOptInFlag) => async dispatch => {
    const user = getUserFromCookies();
    const jwtToken = cookie.load('__s2jt');
    const data = {
        ...user,
        timezone,
        isActive: true,
        emailNotificationSubscription: emailOptInFlag,
    };
    if (jwtToken) {
        const response = await aepUser.post("/userTemplateService/configDetails/users", data, {
            headers: {
                'aep-header': jwtToken
            }
        });
        if (response.status === CONFIG.HTTP_STATUS.CREATED) {
            dispatch(fetchUserDetails(user));
            dispatch(closeAllTabs());
            dispatch(redirectToDashboard());
            history.replace("/dashboard")
        }
        else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) pushMessage(CONFIG.messageType.error, response.data)
        else pushMessage(CONFIG.messageType.error, 'Unable to update profile');
    }
};

export const changeLocalUserPassword = (values, email) => async (dispatch, getState) => {
    const requestData = {
        email: email,
        currentPassword: values.currentPassword,
        password: values.newPassword,
        confirmPassword: values.confirmPassword
    }

    const response = await aepUser({
        url: '/userTemplateService/reset/password',
        method: 'put',
        data: requestData,
    });

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        batch(() => {
            dispatch(toggleIsFirstTimeLogin(false));
            dispatch(setIsLoginComplete());
            dispatch(setHasPasswordChanged(true));
            setTimeout(() => {
                dispatch(logoutLocalUser());
                window.location.reload();
            }, 3500)
            openSuccess(CONFIG.myProfile.passwordChangeSuccessfull.title, CONFIG.myProfile.passwordChangeSuccessfull.content,
                null, <a href={CONFIG.myProfile.localUserLoginPageURL} onClick={() => dispatch(logoutLocalUser())}>Continue to Login</a>)
        })
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT)
        pushMessage(CONFIG.messageType.error, 'Your current Password is incorrect!');
    else
        pushMessage(CONFIG.messageType.error, 'Could not update Password');
}

export const logoutLocalUser = () => dispatch => {
    removeAllCookies();
    localStorage.removeItem('header');
    dispatch(clearTabStoreData());
    localStorage.removeItem('openTabs');
    localStorage.removeItem('openTabsActiveKey');
    history.replace({ pathname: '/home/non-sso-users' });
}


export const putUserTimeZone = (timezone, userId) => async dispatch => {
    const requestData = { timezone: timezone }
    const response = await aepUser({
        url: '/userTemplateService/users',
        method: 'put',
        data: requestData,
        params: { isOnlyTimeZoneChange: true }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Timezone updated successfully');
        batch(() => {
            dispatch(updateUserTimeZone(timezone, userId));
            dispatch(updateSchedulerTimezone(timezone));
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update Timezone');
};

export const updateTabLimit = (userType, limit) => async dispatch => {
    const response = await aepUser.patch(`/userTemplateService/roleTemplate/tabLimit?roleTemplateTypes=${userType}&limit=${limit}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Tab limit preference updated successfully');
        await dispatch(fetchUserTabLimits());
        userType === CONFIG.roleTypes.superAdmin && dispatch(updateSAUserTabLimit(limit));
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update Timezone');
};


export const putUserEmailSubscription = (optIn) => async dispatch => {
    const aepHeader = localStorage.getItem('header');
    const response = await aepUser.put(`/userTemplateService/user/emailNotificationSubscription/${optIn}`, null, {
        headers: {
            'aep-header': aepHeader
        }
    })

    if (response.status === CONFIG.HTTP_STATUS.OK) {

        optIn
            ? pushMessage(CONFIG.messageType.success, 'You are now subscribed to emails from Experience Avaya Portal..')
            : pushMessage(CONFIG.messageType.warning, 'You will no longer receive emails updates from Experience Avaya Portal.')
        dispatch(updateUserEmailSubscription(optIn));

    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update Email notification preference');
};

export const putUserOptOutCalendarInviteSubscription = (optOut) => async dispatch => {
    const aepHeader = localStorage.getItem('header');
    const response = await aepUser.put(`/userTemplateService/user/optOutInviteSubs/${optOut}`, null, {
        headers: {
            'aep-header': aepHeader
        }
    })

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        optOut
            ? pushMessage(CONFIG.messageType.warning, 'You will no longer receive Calendar Invites & Reminder emails.')
            : pushMessage(CONFIG.messageType.success, 'You are now subscribed to Calendar Invites & Reminder emails.')
        dispatch(updateInviteOptOutSubscription(optOut));
    }
    else pushMessage(CONFIG.messageType.error, 'Could not update Calendar Invites preference');
};

export const updateUserTimeZone = (timezone) => {
    return {
        type: UPDATE_USER_TIME_ZONE,
        payload: timezone
    }
}

export const updateUserEmailSubscription = (flag) => {
    return {
        type: UPDATE_USER_EMAIL_SUBSCRIPTION,
        payload: flag
    }
}

export const updateSAUserTabLimit = (limit) => {
    return {
        type: UPDATE_SA_USER_TAB_LIMIT,
        payload: limit
    }
}

export const updateInviteOptOutSubscription = (flag) => {
    return {
        type: UPDATE_INVITE_OPT_OUT_SUBSCRIPTION,
        payload: flag
    }
}

export const toggleIsFirstTimeLogin = (flag) => {
    return {
        type: TOGGLE_IS_FIRST_TIME_LOGIN,
        payload: flag
    }
}

export const setIsLoginComplete = (aepHeader) => {
    return {
        type: SET_IS_LOGIN_COMPLETE,
        payload: aepHeader
    }
}

export const showPortalTourModal = (flag, fromMenu) => {
    return {
        type: SHOW_PORTAL_TOUR_MODAL,
        payload: { flag, fromMenu }
    }
}

export const setPortalTourComplete = () => async (dispatch) => {
    const response = await aepUser.patch("/userTemplateService/portalTour/completed");
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: SET_PORTAL_TOUR_COMPLETE,
        })
    }
}

export const setHasPasswordChanged = (flag) => {
    return {
        type: SET_HAS_PASSWORD_CHANGED,
        payload: flag
    }
}

export const getDecryptedLdapPassword = () => async (dispatch) => {
    const response = await aepLDAP.get("/ldapService/user/ldapPassword");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_DECRYPTED_LDAP_PASSWORD,
            payload: response.data || []
        });
    }
    else if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.unauthorized)
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch LDAP password");
}

export const getLDAPUserGroups = () => async (dispatch) => {
    const response = await aepUser.get("/userTemplateService/users/ldapgroup");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_USER_GROUPS,
            payload: response.data || []
        });
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch user groups");
}

const refreshLDAPUserGroups = async () => await aepLDAP.get('/ldapService/groups/refresh');

export const resetLDAPPassword = (userId) => async (dispatch, getState) => {
    const userProfile = getState().user.profile
    const response = await aepLDAP.post('/ldapService/password/reset', null, { params: { userId } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        if (!userId || userId === userProfile.id) {
            dispatch({
                type: RESET_LDAP_PASSWORD,
                payload: response.data || {}
            })
        }
        pushMessage(CONFIG.messageType.success, "Demo password updated successfully")
    }
    else if (response.status === CONFIG.HTTP_STATUS.PRECONDITION_FAILED) {
        const timeZoneName = userProfile.timezone;
        let warningMessage = CONFIG.warningMessages.ldapResetPasswordFailure.content;

        const scheduleStartTime = moment.tz(response.data?.[0].startTime, timeZoneName).subtract(response.data?.startTimeBuffer, 'm').format(CONFIG.dateFormats.userDateTime);
        const scheduleEndTime = moment.tz(response.data?.[0].endTime, timeZoneName).add(response.data?.endTimeBuffer, 'm').format(CONFIG.dateFormats.userDateTime);

        warningMessage = warningMessage.replace('<startTimePlaceholder>', scheduleStartTime);
        warningMessage = warningMessage.replace('<endTimePlaceholder>', scheduleEndTime);

        openInfo(CONFIG.warningMessages.ldapResetPasswordFailure.title, warningMessage, null, "OK")
    }
    else if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.unauthorized)
    }
    else pushMessage(CONFIG.messageType.error, "Unable to update demo password")
}

export const syncUserGroups = () => async (dispatch) => {
    dispatch(toggleIsUserLoading(true));
    const response = await refreshLDAPUserGroups();
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch(getLDAPUserGroups())
            .then(() => dispatch(toggleIsUserLoading(false)));
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to sync user groups');
        dispatch(toggleIsUserLoading(false));
    }
}

export const toggleIsUserLoading = (loading) => ({
    type: TOGGLE_IS_USER_LOADING,
    payload: loading
})

export const exportTable = async (endpoint, dataSet, timezone, fileName, fileFormat, exportViewColumns) => {
    dataSet = dataSet || []
    const data = { dataSet, timezone, exportViewColumns }
    const response = await aepUser({
        method: 'post',
        url: `/userTemplateService/download/${endpoint}/${fileFormat}`,
        data,
        responseType: "blob"
    });

    if (response.status === CONFIG.HTTP_STATUS.OK) download(fileName, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}


export const fetchUserTabLimits = () => async (dispatch) => {
    const response = await aepUser.get("/userTemplateService/roleTemplate/tabLimit");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_TAB_LIMITS_FOR_USERS,
            payload: response.data || []
        });
    }
}

export const updateProfilePicture = (file) => async (dispatch) => {
    const formData = new FormData();
    formData.append('multipartFile', file);
    const response = await aepUser({
        method: 'put',
        url: "userTemplateService/upload/profilePicture",
        data: formData
    })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, 'Profile picture updated successfully');
        dispatch({
            type: UPDATE_USER_PROFILE_PIC,
            payload: response.data || undefined
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to upload profile picture');
}

export const deleteProfilePicture = () => async (dispatch) => {
    const response = await aepUser({
        method: 'delete',
        url: "userTemplateService/delete/profilePicture",
    })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, 'Profile picture removed successfully');
        dispatch({
            type: UPDATE_USER_PROFILE_PIC,
            payload: undefined
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to delete profile picture');
}

export const updateTestingEmails = (userList) => async dispatch => {
    const response = await aepUser.put(`/userTemplateService/testingEnvironment/recipients?recipientList=${userList}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Developers list updated successfully');
        dispatch({
            type: UPDATE_TESTING_RECIPIENTS,
            payload: userList
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update Developers list');
};

export const putTestingMode = (flag) => async dispatch => {
    const response = await aepUser.put(`/userTemplateService/testingEnvironment/${flag}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Testing mode is now ${flag ? "enabled" : "disabled"}. Portal will now reload for changes to take effect!`);
        return true;
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Failed to update testing mode');
        return false;
    }
};

export const putSFDCBypass = (flag) => async () => {
    const response = await aepUser.patch(`/userTemplateService/sfdcByPass/${flag}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `SFDC Opportunity Bypass is now ${flag ? "enabled" : "disabled"}. Portal will now reload for changes to take effect!`);
        return true;
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Failed to update SFDC Opportunity Bypass');
        return false;
    }
}