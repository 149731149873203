import React from 'react';
import CONFIG from '../config';
import { Tag, Popover } from "antd";
import { Icon, Chip } from "@avaya/neo-react"

export const renderStatusTag = (schedule, isMaintenance, idIndex) => {

    const buildStatus = schedule?.buildStatus;
    if (isMaintenance) {
        return <Tag id={`demo-status-tag-${idIndex}`} className='maintenance-tag'><Icon className={`maintenance-tag-color`} icon="configure" /><span style={{ marginLeft: "5px" }}>Maintenance</span></Tag>
    }
    else if (buildStatus !== null) {
        if (buildStatus === "Assigned" || buildStatus === "New") {
            return <Tag id={`demo-status-tag-${idIndex}`} color={CONFIG.demoBuildStatusTagColors[buildStatus]}>Build In-Progress</Tag>
        }
        else return <Tag id={`demo-status-tag-${idIndex}`} color={CONFIG.demoBuildStatusTagColors[buildStatus]}>Build {buildStatus}</Tag>
    }
    else if (schedule?.status === CONFIG.approvalStatusTypes.pendingRequests.value) {
        const currentPendingLevel = schedule?.scheduleApproval?.[schedule?.scheduleApproval?.length - 1]
        const currentLevelApprovalEmailList = currentPendingLevel?.approversList?.map(eachUser => eachUser.approversEmailId)
        return (
            <Popover
                placement="right"
                content={<div>{currentLevelApprovalEmailList?.map((email, index) => (<p className="new-domain-form-item ">{email}</p>))}</div>}
                title="Approval pending with : "
                trigger="hover">
                <Tag id={`demo-status-tag-${idIndex}`} color={CONFIG.tagColors.pending}>{CONFIG.approvalStatusTypes.pendingRequests.status}</Tag>
            </Popover>
        )
    }
    else if (schedule?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value))
        return <Tag id={`demo-status-tag-${idIndex}`} color={CONFIG.tagColors.appoved}>{CONFIG.approvalStatusTypes.approvedRequests.status}</Tag>
    else if (schedule?.status === CONFIG.approvalStatusTypes.declinedRequests.value)
        return <Tag id={`demo-status-tag-${idIndex}`} color={CONFIG.tagColors.declined}>{CONFIG.approvalStatusTypes.declinedRequests.status}</Tag>
    else if (schedule?.status === CONFIG.approvalStatusTypes.expiredRequests.value)
        return <Tag id={`demo-status-tag-${idIndex}`} color={CONFIG.tagColors.expired}>{CONFIG.approvalStatusTypes.expiredRequests.status}</Tag>
}

export const renderStatusChip = (schedule, isMaintenance) => {

    const buildStatus = schedule?.buildStatus;
    if (isMaintenance) {
        return <Chip variant='info'>Maintenance</Chip>
    }
    else if (buildStatus !== null) {
        if (buildStatus === "Assigned" || buildStatus === "New") {
            return <Chip variant={CONFIG.demoBuildStatusChips[buildStatus]}>Build In-Progress</Chip>
        }
        else return <Chip variant={CONFIG.demoBuildStatusChips[buildStatus]}>Build {buildStatus}</Chip>
    }
    else if (schedule?.status === CONFIG.approvalStatusTypes.pendingRequests.value) {
        const currentPendingLevel = schedule?.scheduleApproval?.[schedule?.scheduleApproval?.length - 1]
        const currentLevelApprovalEmailList = currentPendingLevel?.approversList?.map(eachUser => eachUser.approversEmailId)
        return (
            <Popover
                placement="right"
                content={<div>{currentLevelApprovalEmailList?.map((email, index) => (<p className="new-domain-form-item ">{email}</p>))}</div>}
                title="Approval pending with : "
                trigger="hover">
                <Chip variant={CONFIG.tagChips.pending}>{CONFIG.approvalStatusTypes.pendingRequests.status}</Chip>
            </Popover>
        )
    }
    else if (schedule?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value))
        return <Chip variant={CONFIG.tagChips.appoved}>{CONFIG.approvalStatusTypes.approvedRequests.status}</Chip>
    else if (schedule?.status === CONFIG.approvalStatusTypes.declinedRequests.value)
        return <Chip variant={CONFIG.tagChips.declined}>{CONFIG.approvalStatusTypes.declinedRequests.status}</Chip>
    else if (schedule?.status === CONFIG.approvalStatusTypes.expiredRequests.value)
        return <Chip variant={CONFIG.tagChips.expired}>{CONFIG.approvalStatusTypes.expiredRequests.status}</Chip>
}


// To check if edit should be enabled.
export const getApprovalStatus = (demo) => {
    const approver = demo && demo?.length > 0 ? demo[0] : [];
    if (approver?.status !== undefined) {
        return ((approver?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value)) || (approver?.status === CONFIG.approvalStatusTypes.declinedRequests.value)) ? true : false;
    }
}

// To ensure declined/Expired/Pending scheduled cards are not shown as upcoming.
export const getDeclinedRequests = (approvals) => {
    const approver = approvals && approvals?.length > 0 ? approvals.slice(-1)[0] : []
    if (approver?.status !== undefined) {
        return approver?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value) ? false : true;
    }
}