import React from 'react';
import { connect, batch } from "react-redux";
import DemoReviews from "../DemoSolutions/DemoReviews";
import CONFIG from '../../config';

import {
    selectTitle,
    toggleBackButton,
    updateDemosCurrentPage,
    currentDemoFilter
}
    from '../../actions';

class DemoRatingsAndReviews extends React.Component {
    componentDidMount() {
        const { filterSort, status, demoIndex, demoId } = this.props.location || [];
        const pageSize = ((Math.ceil(demoIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
        const currentPageNumber = ((Math.ceil(demoIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
        this.props.updateDemosCurrentPage(currentPageNumber, pageSize, demoId, demoIndex);

        batch(() => {
            demoId && this.props.currentDemoFilter(filterSort, status)
            this.props.selectTitle("Demo Ratings and Reviews");
            this.props.toggleBackButton(false);
        });
    }

    render() {
        const { demoId } = this.props.location
        const { isRatingRedirect, scheduleId, isLowRatingAlert } = this.props.location.state || []
        const newDemoId = this.props.match?.params?.demoId || demoId;
        const newScheduleId= this.props.match?.params?.scheduleId || scheduleId;
        const newIsRatingRedirect= this.props.match?.params?.isRatingRedirect || isRatingRedirect;
        const newIsLowRatingAlert= this.props.match?.params?.isLowRatingAlert || isLowRatingAlert;
        
        return (
            <div className="content-container">
                <DemoReviews
                    fromManageDemos={true}
                    demoId={newDemoId}
                    isRatingRedirect={newIsRatingRedirect}
                    scheduleId={newScheduleId}
                    isLowRatingAlert={newIsLowRatingAlert} />
            </div >
        );
    }
}
const mapStateToProps = ({ demoApprovals, user, demos, newSchedule }) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        updateDemosCurrentPage,
        currentDemoFilter
    }
)(DemoRatingsAndReviews);