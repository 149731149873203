import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Dropdown, message, Tooltip, Spin, Tabs } from 'antd';
import CONFIG from '../../config';
import moment from 'moment-timezone';

import {
    selectTitle,
    handleOnClickAction,
    fetchAllCapability,
    exportTable,
    toggleIsFetchingCapability,
    currentCapabilityFilter,
    clearCapability,
    refreshCapability,
    toggleBackButton,
    deleteCapability,
    restoreCapability,
    fetchLiteCapability
} from '../../actions'
import { exportCapabilityTable } from "../../actions/demoCapability"
import { exportMenu } from "../../utils/strings";
import { fetchNamesBySearchString } from '../../actions/userRole';

import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';
import throttle from 'lodash/throttle';
import { isTabAlreadyOpen } from "../common/TabLayout";
import { LoadingOutlined } from "@ant-design/icons";
import { Table as NeoTable, Icon as NeoIcon, Button as NeoButton } from "neo-latest";
import { checkIfFilterSortIsEmpty } from "../../utils/formChanged.js";
import { otherFields } from "../../utils/neoTableFields";
import FilterDrawer from "../common/FilterDrawer";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;


const dataIndexMap = {
    title: 'title',
    description: "description",
    createdBy: 'createdBy.fullName',
    createdTime: 'createdTime',
    isActive: 'isActive',
    viewDeletedItems: 'viewDeletedItems',
};

class ManageDemoCapability extends Component {
    constructor(props) {
        super(props);

        this.handleClear = throttle(this.handleClear, 1000);

        this.state = {
            status: "true",
            showFilter: false,
            matchedUsersNames: [],
            searchString: [],
            hasEnteredString: false,
            isFetching: false,
            currentPath: null,
            currentPage: 0
        };
    }

    filterSort = {};
    demoCapabilityId = undefined;
    tableRef = React.createRef();
    isViewDeletedItemsChecked = undefined;


    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleStatusChange = async (value) => {
        await this.clearCurrentCapability()
        this.setState({ status: value })
        this.props.toggleIsFetchingCapability(true);
        this.props.fetchLiteCapability({ filterSort: this.filterSort, status: value })
    }

    clearCurrentCapability = async () => {
        this.setState({ demoCapabilityData: [] })
    }

    handleFilter = async (filters) => {
        let { title, viewDeletedItems, createdBy, description } = filters;
        title = title?.trim();
        description = description?.trim();
        if (!title && !viewDeletedItems && !createdBy && !description) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter");
            return;
        }
        if (viewDeletedItems || this.isViewDeletedItemsChecked) {
            this.setState({ status: "false" });
        }
        this.titleSearchString = title;
        await this.props.clearCapability();
        this.filterSort = {
            filter: {
                viewDeletedItems,
                [dataIndexMap.title]: title || undefined,
                [dataIndexMap.description]: description || undefined,
                [dataIndexMap.createdBy]: createdBy || undefined,
            }
        }
        this.props.toggleIsFetchingCapability(true);
        await this.props.fetchLiteCapability({ filterSort: this.filterSort, status: this.state.status })
    }

    handleClear = async (refresh) => {
        await this.clearCurrentCapability()
        if (refresh) {
            this.props.refreshCapability();
        }
        else {
            this.filterSort = {}
        }
        this.props.toggleIsFetchingCapability(true);
        await this.props.fetchLiteCapability({ filterSort: this.filterSort, status: this.state.status });
        this.setState({ matchedUsersNames: [] })
    }

    handleExport = async (fileType) => {
        const timeZone = this.props.timeZoneName;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportCapabilityTable(CONFIG.exportTable.demoCapability.name, CONFIG.exportTable.demoCapability.fileName + `.${fileType}`, fileType, this.filterSort, timeZone, this.state.status)
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    handleDeleteConfirmation = async () => {
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        const response = await this.props.deleteCapability(this.demoCapabilityId, hardDelete);
        if (response) {
            this.props.toggleIsFetchingCapability(true);
            this.props.fetchLiteCapability({ filterSort: this.filterSort, status: this.state.status });
        }
    }

    handleRestoreConfirmation = async () => {
        const response = await this.props.restoreCapability(this.demoCapabilityId);
        if (response) {
            this.props.toggleIsFetchingCapability(true);
            this.props.fetchLiteCapability({ filterSort: this.filterSort });
        }
    }

    handleDeleteRestore = (capabilityId, isRestore) => {
        this.demoCapabilityId = capabilityId;
        this.renderDeleteRestoreConfirmation(isRestore)
    }

    renderDeleteRestoreConfirmation = (isRestore) => {
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        const okButtonText = isRestore ? "Confirm Restore" : hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
        const modalTitle = isRestore ? CONFIG.restoreModalTitle?.replace('<placeholder>', 'Demo Capabilities') : CONFIG.deleteModalTitle?.replace('<placeholder>', 'Demo Capabilities')
        const content = <>
            {hardDelete && !isRestore ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Demo Capabilities")}</h4> : !isRestore ? <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Demo Capabilities")}</h4> : ""}
        </>
        openConfirm(okButtonText, isRestore ? this.handleRestoreConfirmation : this.handleDeleteConfirmation, null, content, modalTitle);
    }

    async componentDidMount() {
        this.setState({ currentPath: window.location.pathname })
        const { isEdit, currentFilter, status, currentPageNumber } = this.props;
        if (isEdit) {
            this.setState({ currentPage: currentPageNumber });
            this.filterSort = currentFilter;
            if (Object.keys(JSON.parse(JSON.stringify(currentFilter)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(currentFilter?.filter));
                Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
            }
            await this.setState({ status: status })
        }
        else {
            this.filterSort = {}
        }
        this.props.toggleIsFetchingCapability(true);
        this.props.fetchLiteCapability({ filterSort: this.filterSort, status: this.state.status });
        this.props.toggleIsFetchingCapability(false);
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentCapabilityFilter(this.filterSort, this.state.status, this.state.currentPage)
        }
        else {
            this.props.clearCapability();
        }
    }

    handleCloseFilterDrawer = () => {
        this.setState((prevState) => ({
            showFilter: !prevState.showFilter,
        }));
    };

    renderFilterPanel = () => {
        return (
            <div
                style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
            >
                {
                    <div style={{ display: "flex", flex: "1", zIndex: "1", position: "relative" }}>
                        <NeoButton
                            onClick={() => {
                                this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.demoCapability,
                                    tabTitle: "Create",
                                });
                            }}
                        >
                            Add New
                        </NeoButton>
                    </div>
                }
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        flex: "1",
                        flexDirection: "row-reverse",
                        gap: "24px",
                    }}
                >
                    <div>
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoButton
                                className="export-download-button"
                                icon="chevron-down"
                                variant="secondary"
                            >
                                Download
                            </NeoButton>
                        </Dropdown>
                    </div>
                    <div>
                        <NeoIcon
                            size="md"
                            className="clickable"
                            onClick={() =>
                                this.setState((prevState) => ({
                                    showFilter: !prevState.showFilter,
                                }))
                            }
                            title="Filter"
                            icon={
                                this.state.showFilter ||
                                    !checkIfFilterSortIsEmpty({ filter: this.filterSort })
                                    ? "filter-filled"
                                    : "filter"
                            }
                            style={{ fontSize: "23px", color: "#0b67bd" }}
                        />
                    </div>
                    <div>
                        <NeoIcon
                            size="md"
                            className="clickable"
                            title="Refresh"
                            icon="refresh"
                            onClick={() => this.handleClear(true)}
                            style={{ fontSize: "23px", color: "#0b67bd" }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderTabContents = () => {
        return (
            <React.Fragment>
                <div>{this.renderFilterPanel()}</div>
                <div style={{ minHeight: "250px" }}>{this.renderTable()}</div>
            </React.Fragment>
        );
    };

    renderTable = () => {
        const { userRoleTemplate, timeZoneName, demoCapabilityData, isFetching } = this.props;
        const deleteView = this.filterSort?.filter?.viewDeletedItems;
        this.isViewDeletedItemsChecked = deleteView;
        const neoColumns = [
            {
                Cell: (a) => {
                    return (
                        <span style={{ textAlign: "center" }}>
                            {a?.cell?.row?.original?.title}
                        </span>
                    );
                },
                Header: "Title",
                sortType: "string",
                accessor: "title",
            },

            {
                Cell: (a) => {
                    return (
                        <span style={{ textAlign: "center" }}>
                            {a?.cell?.row?.original?.description}
                        </span>
                    );
                },
                Header: "Description",
                sortType: "string",
                accessor: "description",
            },

            {
                Cell: (a) => {
                    return (
                        <span style={{ textAlign: "center" }}>
                            {a?.cell?.row?.original?.createdBy?.fullName}
                        </span>
                    );
                },
                Header: "Created By",
                sortType: "string",
                accessor: "createdBy.fullName",
            },

            {
                Cell: (a) => {
                    return (
                        <span style={{ textAlign: "center" }}>
                            {a?.cell?.row?.original?.createdTime ? moment.tz(a?.cell?.row?.original?.createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}
                        </span>
                    );
                },
                Header: "Created Date",
                sortType: "string",
                accessor: "createdTime",
            },

            {
                Cell: (a) => {
                    return (
                        <span style={{ textAlign: "center" }}>
                            {
                                <NeoIcon
                                    style={
                                        a?.cell?.row?.original?.isActive
                                            ? { color: "#088A08" }
                                            : { color: "#DA291C" }
                                    }
                                    icon={
                                        a?.cell?.row?.original?.isActive ? "available" : "missed"
                                    }
                                />
                            }
                        </span>
                    );
                },
                sortType: "basic",
                Header: "Status",
                accessor: "isActive",
                disableFilters: true,
                disableSortBy: true,
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    const isEditDisabled = userRoleTemplate?.templateType !== CONFIG.roleTypes.superAdmin && record.adminAccess === CONFIG.roleTemplateAccess.readOnly;
                    return isEditDisabled ? null : (
                        <React.Fragment>
                            <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
                                {!deleteView &&
                                    <>
                                        <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                            <button
                                                onClick={() => {
                                                    this.props.handleOnClickAction({
                                                        component: CONFIG.editComponentRoute.demoCapability,
                                                        tabTitle: record?.title,
                                                        recordId: record?.id
                                                    })
                                                }}
                                                disabled={isEditDisabled}
                                                className={!isEditDisabled ? "link" : "link-disabled"}>
                                                <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                            </button>
                                        </Tooltip>

                                    </>
                                }
                                {!deleteView && <>
                                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id)}>
                                            <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </>
                                }
                                {deleteView &&
                                    <>
                                        <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleDeleteRestore(record.id, true)}>
                                            <span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </>
                                }
                            </div>
                        </React.Fragment>
                    );
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true,
            },
        ];

        return isFetching ? (
            <Spin
                style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                spinning={isFetching}
                indicator={antIcon}
            />
        ) : (
            <NeoTable
                columns={neoColumns}
                data={demoCapabilityData || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                showRowSeparator
                initialStatePageIndex={this.state.currentPage}
                handlePageChange={(newPageIndex) => {
                    if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
                }}
                {...otherFields}
            />
        );
    };


    render() {
        const { timeZone } = this.props;
        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                value: this.filterSort?.filter?.title,
                colSpan: 24
            },
            {
                label: "Description",
                type: CONFIG.formFieldTypes.INPUT,
                key: "description",
                value: this.filterSort?.filter?.description,
                colSpan: 24
            },
            {
                label: "Created By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "createdBy",
                data: this.state.matchedUsersNames,
                value: this.filterSort?.filter?.[dataIndexMap.createdBy],
                colSpan: 24
            },
        ];

        return (
            <React.Fragment>
                <div
                    style={{
                        color: "#242424",
                        fontSize: "19px",
                        fontWeight: "400",
                        lineHeight: "28px",
                        marginTop: "28px",
                    }}
                >
                    List of Capabilities
                </div>
                <div style={{ marginTop: "16px" }}>
                    <Tabs
                        disabled={this.isViewDeletedItemsChecked}
                        activeKey={this.state.status}
                        className="demo-status-tabs"
                        defaultActiveKey={this.state.status}
                        onChange={this.handleStatusChange}
                    >
                        <TabPane
                            disabled={this.isViewDeletedItemsChecked}
                            tab="Active"
                            key="true"
                        >
                            {this.renderTabContents()}
                        </TabPane>
                        <TabPane
                            tab="Inactive"
                            key="false"
                        >
                            {this.renderTabContents()}
                        </TabPane>
                        <TabPane
                            disabled={this.isViewDeletedItemsChecked}
                            tab="All"
                            key="all"
                        >
                            {this.renderTabContents()}
                        </TabPane>
                    </Tabs>
                </div>
                <div id="component-content" className="approvals-pagination-row">
                    <div></div>
                    <Row>
                        {this.state.showFilter && (
                            <FilterDrawer
                                visible={this.state.showFilter}
                                width="520"
                                handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                                placement="right"
                                maskClosable={true}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                timeZone={timeZone}
                                hideSoftDeleteCheckbox={false}
                                hideSave={true}
                                getSearchData={this.fetchUsersName}
                                label='View Deleted Capabilities'
                                viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                                hideApplyToAll={true}
                            />
                        )}
                    </Row>
                </div>
            </React.Fragment>
        )
    }
};

const mapStateToProps = ({ user, tabsLayout, demoCapability }) => {
    return {
        demoCapabilityData: demoCapability?.demoCapabilityData,
        response: demoCapability?.response,
        demoCapabilityCount: demoCapability?.count,
        isFetching: demoCapability?.isFetching,
        isEdit: demoCapability?.isEdit,
        currentFilter: demoCapability?.currentFilter,
        status: demoCapability?.status,
        openedTabs: tabsLayout.panes,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        currentPageNumber: demoCapability.currentPageNumber
    };
};
export default connect(
    mapStateToProps,
    {
        selectTitle,
        exportTable,
        handleOnClickAction,
        fetchAllCapability,
        toggleIsFetchingCapability,
        currentCapabilityFilter,
        clearCapability,
        refreshCapability,
        toggleBackButton,
        deleteCapability,
        restoreCapability,
        fetchLiteCapability
    }
)(ManageDemoCapability);