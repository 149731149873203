import React from 'react';
import { notification, Modal, Tag } from 'antd';
import CONFIG from '../../config';
import HTML from '../../components/common/HTML';

export const pushNotification = (msg, desc) => {
    setTimeout(() => {
        notification.open({
            message: msg,
            description: <HTML htmlContent={desc} />,
            duration: 0,
            placement: 'topRight',
        });
    }, CONFIG.pushNotificationDelay);
};

export const DIDImportErrorNotification = (data) => {
    Modal.warning({
        title: CONFIG.warningMessages.errorImportDIDTitle,
        content: renderModalBody(data),
        width: 600,
        destroyOnClose: true,
    });
}

const renderModalBody = (data) => {
    const invalidDIDCount = data.invalidDIDs.length;
    const { validDiDsCount, invalidDIDs } = data;

    return (
        <React.Fragment>
            <Tag color={CONFIG.colors.success} className="bottom-spacing-3">Imported Successfully : {validDiDsCount}</Tag>
            <Tag color={CONFIG.colors.failed} className="bottom-spacing-3"> Failed to Import : {invalidDIDCount}</Tag>
            <p>{CONFIG.warningMessages.errorImportDIDHeader}<br /><br />{invalidDIDs.join(', ')}</p>
        </React.Fragment >
    )
}
