import React from "react";
import { connect, batch } from "react-redux";
import { Form, Input, Row, Col, Switch, Button, Modal, Spin } from "antd";
import {
  toggleBackButton,
  fetchRoleTemplates,
  fetchAdminRoleTemplates,
  cancelSolutionTypeRoleTemplates,
  postSolutionType,
  updateSolutionTypeRoleTemplate,
  saveSolutionTypeRoleTemplates,
  putSolutionType,
  getSelectedSolutionType,
  toggleIsFetchingSolutionTypes,
  closeCurrentOpenTab,
  toggleHasUnsavedChangesFlag,
  clearSolutionTypeForm
} from "../../actions";
import FormActionButtons from "../common/FormActionButtons";
import RoleTemplatesTable from "../common/RoleTemplatesTable";
import CONFIG from "../../config";
import pushMessage from '../common/PushMessage';
import { checkRoleTemplateChanges } from "../../utils/status";

class SolutionTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.isEditMode = this.props.match.params.solutionTypeId !== undefined;
    this.state = {
      visible: false,
    };
  }
  getAssignedRoleTemplates = (roleTemplates, isSuperAdminUser) => {
    let assignedRoleTemplates = [];
    if (roleTemplates) {
      roleTemplates.forEach((obj) => {
        const userAccessCondition =
          obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none;
        const adminAccessCondition =
          obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none;
        if (
          isSuperAdminUser
            ? userAccessCondition || adminAccessCondition
            : userAccessCondition
        ) {
          assignedRoleTemplates.push(obj.roleTemplate.title);
        }
      });
    }
    return assignedRoleTemplates;
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  renderAssignButton = () => {
    return (
      <Button
        className="primary-action-button-bordered right-border-radius"
        id="solutionType-roletemplate-assign"
        onClick={this.showModal}
      >
        Assign
      </Button>
    );
  };
  handleRoleTemplatesCancel = (e) => {
    this.props.cancelSolutionTypeRoleTemplates();
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    const { userRoleTemplate } = this.props;
    const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
    const { solutionTypeId } = this.props.match.params;
    batch(() => {
      this.props.toggleIsFetchingSolutionTypes(false);
      const fetchAllRoleTemplates = isAdmin
        ? this.props.fetchAdminRoleTemplates
        : this.props.fetchRoleTemplates;
      fetchAllRoleTemplates().then(() => {
        if (solutionTypeId) {
          this.props.getSelectedSolutionType(solutionTypeId);
        }
      });
    });
  }

  getCurrentData = (solutionTypeFormDetails) => {
    // const { solutionTypeForm, currentSolutionType } = this.props;
    return {
      name: solutionTypeFormDetails.name,
      isActive: solutionTypeFormDetails.isActive,
      roleTemplates: solutionTypeFormDetails?.roleTemplates,
    }
  }

  componentDidUpdate() {
    const { openedTabs, activeKey, userRoleTemplate } = this.props;
    const { solutionTypeId } = this.props.match.params;
    const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    const currentSolutionType = this.getCurrentData(this.props.form.getFieldsValue());
    this.hasChanged = solutionTypeId ? this.checkSolutionTypesFormChanged(currentSolutionType, isSuperAdminUser) : true;
    const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
    if (!hasUnsavedChanges && this.hasChanged)
      this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    else if (hasUnsavedChanges && !this.hasChanged)
      this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
  }


  componentWillUnmount() {
    this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
    this.props.clearSolutionTypeForm();
  }

  handleCreate = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        pushMessage(CONFIG.messageType.warning, "Please verify the fields");
      return;
      }else{
      this.props.toggleIsFetchingSolutionTypes(true)
      this.isEditMode
        ? this.props.putSolutionType(this.props.match.params.solutionTypeId, values)
        : this.props.postSolutionType(values);
      }
    });
  };

  handleRoleTemplatesAssign = () => {
    this.props.saveSolutionTypeRoleTemplates();
    this.setState({ visible: false });
  };

  checkSolutionTypesFormChanged = (currentSolutionType, isSuperAdminUser) => {
    const { solutionTypeForm } = this.props;
    let hasChanged = false;
    hasChanged = currentSolutionType?.name?.trim() !== solutionTypeForm?.name?.trim()
      || currentSolutionType?.isActive !== solutionTypeForm?.isActive
      || checkRoleTemplateChanges(solutionTypeForm?.solutionTypeObj?.roleTemplates, solutionTypeForm?.roleTemplates, isSuperAdminUser)

    if (hasChanged) return hasChanged;
    return hasChanged;
  }

  render() {
    const {
      form: { getFieldDecorator },
      name,
      isActive,
      userRoleTemplate,
      allRoleTemplates,
      roleTemplates,
      allRoleTemplatesUserAccess,
      allRoleTemplatesAdminAccess,
    } = this.props;
    const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    const assignedRoleTemplates = this.getAssignedRoleTemplates(
      roleTemplates,
      isSuperAdminUser
    );

    return (
      <div className="content-container">
        <Spin spinning={this.props.isFetching} wrapperClassName="spin-overlay">
          <Form>
            <Row>
              <Col xl={10} sm={10} xs={24}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the name of the solution type",
                      },
                    ],
                    initialValue: name ? name : "",
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xl={10} sm={10} xs={24}>
                <Form.Item label="Assign Role Template">
                  {getFieldDecorator("roletemplate", {
                    initialValue:
                      assignedRoleTemplates.length !== 0
                        ? assignedRoleTemplates
                        : "None",
                  })(<Input disabled addonAfter={this.renderAssignButton()} />)}
                  {
                    <Modal
                      title="Role Template"
                      visible={this.state.visible}
                      onCancel={this.handleRoleTemplatesCancel}
                      footer={
                        <FormActionButtons
                          okText="Assign"
                          handleCancel={this.handleRoleTemplatesCancel}
                          handleSubmit={this.handleRoleTemplatesAssign}
                        />
                      }
                    >
                      <RoleTemplatesTable
                        hideColumns={isSuperAdminUser ? [] : ["admin"]}
                        dataKey="roleTemplate"
                        data={allRoleTemplates}
                        pagination={false}
                        assignedData={roleTemplates}
                        allUserAccess={allRoleTemplatesUserAccess}
                        allAdminAccess={allRoleTemplatesAdminAccess}
                        updateRoleTemplateData={
                          this.props.updateSolutionTypeRoleTemplate
                        }
                      />
                    </Modal>
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xl={4} sm={4} xs={24}>
                <Form.Item label="Status">
                  {getFieldDecorator("isActive", {
                    valuePropName: "checked",
                    initialValue: isActive,
                  })(
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row className="right-align">
            <Button onClick={() => this.props.closeCurrentOpenTab()} className={`cancel-button`} id='solution-type-discard-changes'>
                {"Discard & Close"}
            </Button>
            <Button disabled={!this.hasChanged}
                    className={`left-spacing primary-action-button-filled`}
                    onClick={this.handleCreate} id='solution-type-save-changes'>
                {this.isEditMode ? "Update" : "Create"}
            </Button>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const SolutionTypeFormRef = Form.create()(SolutionTypeForm);

const mapStateToProps = ({
  viewport,
  roleTemplates,
  user,
  solutionTypeForm, tabsLayout
}) => {
  return {
    ...solutionTypeForm,
    viewport: viewport,
    allRoleTemplates: roleTemplates.all,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    hasSave: tabsLayout.hasSave,
    openedTabs: tabsLayout.panes,
    activeKey: tabsLayout.activeKey,
    solutionTypeForm
  };
};

export default connect(mapStateToProps, {
  toggleBackButton,
  fetchAdminRoleTemplates,
  fetchRoleTemplates,
  cancelSolutionTypeRoleTemplates,
  postSolutionType,
  putSolutionType,
  updateSolutionTypeRoleTemplate,
  saveSolutionTypeRoleTemplates,
  getSelectedSolutionType,
  toggleIsFetchingSolutionTypes,
  closeCurrentOpenTab,
  toggleHasUnsavedChangesFlag,
  clearSolutionTypeForm
})(SolutionTypeFormRef);
