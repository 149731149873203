import React from 'react';
import { Row, Form, Spin } from 'antd';
import ReportBasicDetailsNeo from './ReportBasicDetailsNeo';
import FormActionButtonsNeo from '../common/FormActionButtonsNeo';
import { connect, batch } from 'react-redux';
import {
    toggleBackButton,
    clearSaveReports,
    clearReportsForm,
    fetchEntityTypes,
    selectTitle,
    toggleIsFetchingReportForm,
    fetchAdminRoleTemplates,
    updateCustomReportsCurrentPage,
    fetchRoleTemplates,
    postReport,
    fetchReport,
    currentReportFilter,
    updateReportDetails,
    updateTabsLocalStorage,
    updatedSelectedColumns,
    toggleEntityHasChanged,
    resetReportForm,
    toggleHasUnsavedChangesFlag,
    deleteTab,
    fetchTrendingDemos
} from '../../actions';
import CONFIG from '../../config';
import history from '../../history';
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
import RefineReportNeo from './RefineReportNeo';
import ReportOutputGraph from './ReportOutputGraph';
import pushMessage from '../../components/common/PushMessage';
import { checkRoleTemplateChanges } from "../../utils/status";
import { createCommonFilterObject } from '../../utils/reports';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ReportFormNeo = Form.create({ name: 'report_form_neo' })(
    class ReportFormNeo extends React.Component {

        constructor(props) {
            super(props)

            this.state = {
                formLoading: false,
                isClone: false,
            }
            this.isGeneralReport = props.match.params.reportType === "standard";
            this.isCloneReport = window.location.pathname?.includes("/reports/clone");
            this.isEditMode = this.props.match.params.reportId !== undefined && window.location.pathname?.includes("/reports/neo/edit")
            this.isCloneReport = window.location.pathname?.includes("/reports/clone");
            this.filterSort = {}
        }

        async componentDidMount() {
            const { reportId } = this.props.match.params;
            const { previousTabs } = this.props;
            this.props.selectTitle("Manage Reports");
            const currentPath = window.location.pathname;
            const isGeneralReport = this.props.match.params.reportType === "standard";
            this.props.toggleBackButton(false);
            const { userRoleTemplate } = this.props;
            const isCloneReport = window.location.pathname?.includes("/reports/clone");
            this.setState({
                isClone: isCloneReport,
                formLoading: true
            })
            const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
            document.getElementById('tab-pane').scrollIntoView()
            const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;

            if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                    batch(async () => {
                        // const entityTypes = await this.props.fetchEntityTypes();
                        fetchAllRoleTemplates()
                            .then(async () => {
                                if (reportId) {
                                    await this.props.toggleIsFetchingReportForm(true);
                                    await this.props.fetchReport(reportId, false, isGeneralReport, null, this.isCloneReport, []);
                                }
                                else {
                                    await this.props.updateReportDetails({
                                        reportId: this.props.match.params?.reportId !== undefined
                                            ? this.props.match.params?.reportId
                                            : "-1",
                                        reportData: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                                        isClone: this.state.isClone,
                                        isGeneralReport: isGeneralReport,
                                        entites: []
                                    });
                                }
                            });
                        await this.props.updateTabsLocalStorage(currentPath);
                        await this.props.updateReportDetails({
                            reportId: this.props.match.params?.reportId !== undefined
                                ? this.props.match.params?.reportId
                                : "-1",
                            reportData: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                            isClone: this.state.isClone,
                            isGeneralReport: isGeneralReport,
                            entites: []
                        });
                    });
                }
                this.setState({ hasReportFormChanged: false })
                setTimeout(() => {
                    this.setState({
                        formLoading: false,
                    })
                }, 3500)
            } else {
                await this.props.resetReportForm(reportId, this.state.isClone, isGeneralReport);
                batch(async () => {
                    this.props.toggleBackButton(false);
                    fetchAllRoleTemplates()
                        .then(async () => {
                            // const entityTypes = await this.props.fetchEntityTypes();
                            if (reportId) {
                                await this.props.toggleIsFetchingReportForm(true);
                                await this.props.fetchReport(reportId, false, isGeneralReport, null, this.isCloneReport, []);
                            }
                            else {
                                await this.props.updateReportDetails({
                                    reportId: this.props.match.params?.reportId !== undefined
                                        ? this.props.match.params?.reportId
                                        : "-1",
                                    reportData: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                                    isClone: this.state.isClone,
                                    isGeneralReport: isGeneralReport,
                                    entites: []
                                });
                            }
                            this.setState({ hasReportFormChanged: false })
                            setTimeout(() => {
                                this.setState({
                                    formLoading: false,
                                });

                            }, 3500)
                        });
                });
                this.props.toggleIsFetchingReportForm(false);
            }
        }

        componentWillUnmount() {
            const { reportTabDetails } = this.props;
            const isClone = this.state.isClone;
            const { reportId } = this.props.match.params;
            const id = reportId !== undefined ? reportId : "-1";
            const currentReportData = reportTabDetails?.find(item => (
                (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isClone) && (item?.isGeneralReport === this.isGeneralReport)));
            const { originalDetails, details } = currentReportData || [];
            const filterSortObject = this.createFilterSortObject(details)
            if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
                this.props.updateReportDetails({
                    reportId: this.props.match.params?.reportId !== undefined
                        ? this.props.match.params?.reportId
                        : "-1",
                    reportData: { ...this.props.form.getFieldsValue(), filterSortObject, default: this.isGeneralReport, assignedRoleTemplates: undefined, originalDetails: originalDetails },
                    isClone: this.state.isClone,
                    isGeneralReport: this.isGeneralReport
                });
            }
            else {
                const isClone = this.state.isClone;
                const { reportId } = this.props.match.params;
                const id = reportId !== undefined ? reportId : "-1";
                this.props.resetReportForm(id, isClone, this.isGeneralReport);
            }
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
            // this.props.clearReportsForm();
        }

        checkEqualFilterSort = (oldFilter, currentFilter) => {
            const keys1 = oldFilter ? Object?.keys(oldFilter) : [];
            const keys2 = currentFilter ? Object?.keys(currentFilter) : [];

            if (keys1?.length !== keys2?.length) {
                return false;
            }

            for (const key of keys1) {
                const val1 = oldFilter[key];
                const val2 = currentFilter[key];
                const areObjects = this.isObject(val1) && this.isObject(val2);
                if (
                    (areObjects && !this.checkEqualFilterSort(val1, val2)) ||
                    (!areObjects && val1 !== val2)
                ) {
                    return false;
                }
            }
            return true;
        }

        isObject = (object) => {
            return object != null && typeof object === 'object';
        }

        createFilterSortObject = (currentReport) => {
            const values = this.props.form.getFieldsValue();
            if (currentReport?.reportKey === CONFIG.standardReportKeys.userDistribution) {
                return createCommonFilterObject({ users: values.users, type: values?.type, [values?.type]: values[values?.type], isTotalRequired: values?.isTotalRequired })
            }
            const commonProperties = { quarters: values.quarters, users: values.users, year: values.year, demoPurposes: values.demoPurposes };
            const specificProperties = currentReport?.reportKey === CONFIG.standardReportKeys.demoUsage ? { regions: values.regions } : (currentReport?.reportKey === CONFIG.standardReportKeys.demoUsageSolutionDemo || currentReport?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend) ? { type: values?.type, [values?.type]: values[values?.type], reportType: values?.reportType, isTotalRequired: values?.isTotalRequired } : {}
            return createCommonFilterObject({ ...commonProperties, ...specificProperties }) || {};
        }

        getReportObject = (values, currentReport) => {
            const filterSortObject = this.createFilterSortObject(currentReport);
            return {
                name: values.name,
                description: values.description,
                roleTemplates: currentReport?.roleTemplates,
                filterSortObject: filterSortObject && JSON.stringify({ filter: filterSortObject?.filter }),
                isActive: values.isActive,
                viewColumnsJson: (values?.columns && values?.columns?.length > 0) ? JSON.stringify(values.columns?.map(column => column)) : currentReport?.reportColumns
            }
        }

        checkIfReportHasChanged = () => {
            const { reportTabDetails } = this.props;
            const isClone = this.state.isClone;
            const { reportId } = this.props.match.params;
            const id = reportId !== undefined ? reportId : "-1";
            const currentReport = reportTabDetails?.find(item => (
                (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isClone) && (item?.isGeneralReport === this.isGeneralReport)));
            const formValues = this.getReportObject(this.props.form.getFieldsValue(), currentReport?.details);
            const oldReport = currentReport?.originalDetails;
            const report = { ...currentReport?.details, ...formValues };
            const currentViewColumnJson = typeof report?.viewColumnsJson === 'string' ? report?.viewColumnsJson : JSON.stringify(report?.viewColumnsJson);
            const { userRoleTemplate } = this.props;
            const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
            let hasChanged = false;
            let hasFilterSortObjChanged = true;
            const oldRepFiltObj = oldReport?.filterSortObject
            const newRepFiltObj = report?.filterSortObject && JSON.parse(report?.filterSortObject)
            if (this.checkEqualFilterSort(oldRepFiltObj, newRepFiltObj)) {
                hasFilterSortObjChanged = false;
            }

            hasChanged = report?.name?.trim() !== oldReport?.name?.trim()
                || report?.isActive !== oldReport?.isActive
                || report?.description?.trim() !== oldReport?.description?.trim()
                || hasFilterSortObjChanged
                || (this.isGeneralReport ? false : currentViewColumnJson?.trim() !== JSON.stringify(oldReport?.viewColumnsJson)?.trim())
                || (this.isGeneralReport ? false : report?.filterTable?.tableName !== oldReport?.filterTable?.tableName)
                || checkRoleTemplateChanges(oldReport?.roleTemplates, report?.roleTemplates, isSuperAdminUser)
            if (hasChanged) return hasChanged;
            return hasChanged;
        }

        componentDidUpdate() {
            const { openedTabs, activeKey } = this.props;
            this.hasReportChanged = (this.isEditMode && !this.isCloneReport) ? this.checkIfReportHasChanged() : true;
            const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
            if (!hasUnsavedChanges && this.hasReportChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasReportChanged);
            else if (hasUnsavedChanges && !this.hasReportChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasReportChanged);
        }

        handleReset = async () => {
            await this.props.deleteTab(this.props.activeKey, true)
            history.replace(this.props.activeKey)
            if (this.props.openedTabs?.length === 0) {
                history.replace('/getStarted');
                // this.props.selectTitle(this.getTitle());
            }
        }

        handleSubmit = (currentReport) => {
            this.props.form.validateFields(async (err, values) => {
                const reportId = this.props.match.params?.reportId;
                if (err) {
                    pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
                    return
                }
                if (!err) {
                    const filterSort = this.createFilterSortObject(currentReport);
                    this.props.toggleIsFetchingReportForm(true)
                    const response = await this.props.postReport(
                        parseInt(reportId) ?? undefined,
                        { ...values, filter: filterSort?.filter, entity: JSON.stringify({ id: 9, tableName: "Default" }) },
                        this.props.match.params.reportType === "standard",
                        null,
                        this.isCloneReport
                    );
                    if (response) {
                        this.setState({
                            formLoading: true,
                        })
                    }
                    setTimeout(() => {
                        this.setState({
                            formLoading: false,
                        })
                        this.forceUpdate();
                    }, 1000)
                }
            });
        }

        renderFormButtons = (currentReportData) => {
            return (
                <Row className="right-align">
                    <FormActionButtonsNeo
                        handleSubmit={() => this.handleSubmit(currentReportData)}
                        isDisabled={!this.hasReportChanged}
                        handleCancel={this.handleReset}
                        cancelText={"Discard & Close"}
                        okText="Update" />
                </Row>
            );
        }

        getCurrentReportData = (isAll = false) => {
            const { reportTabDetails } = this.props;
            const reportId = this.props.match.params?.reportId || undefined;
            const id = reportId !== undefined ? reportId : "-1";
            if (isAll) return reportTabDetails?.find(item => (
                (parseInt(item?.id) === parseInt(id)) && (item?.isClone === this.isCloneReport) && (item?.isGeneralReport === this.isGeneralReport)))
            return reportTabDetails?.find(item => (
                (parseInt(item?.id) === parseInt(id)) && (item?.isClone === this.isCloneReport) && (item?.isGeneralReport === this.isGeneralReport)))?.details;
        }

        handleUpdateFilterSort = (filters) => {
            this.filterSort = filters;
        }

        render() {
            const currentReportData = this.getCurrentReportData();
            return (
                <Spin indicator={antIcon} spinning={(this.props.reportFormFetching || this.state.formLoading) || !((currentReportData?.id && currentReportData?.name))} wrapperClassName="spin-overlay">
                    <div>
                        {this.renderFormButtons(currentReportData)}
                        <div>
                            <ReportBasicDetailsNeo
                                params={this.props.match.params}
                                isGeneralReport={this.isGeneralReport}
                                form={this.props.form}
                                isCloneReport={this.isCloneReport}
                            />
                            {(currentReportData?.id && currentReportData?.name) && <> <RefineReportNeo
                                params={this.props.match.params}
                                isGeneralReport={this.isGeneralReport}
                                form={this.props.form}
                                isCloneReport={this.isCloneReport}
                                currentReport={currentReportData}
                                handleUpdateFilterSort={this.handleUpdateFilterSort}
                            />
                                <ReportOutputGraph
                                    form={this.props.form}
                                    params={this.props.match.params}
                                    isCloneReport={this.isCloneReport}
                                    isGeneralReport={this.isGeneralReport}
                                    isRunByUser={false}
                                    handleUpdateFilterSort={this.handleUpdateFilterSort}
                                /></>}
                        </div >
                    </div >
                </Spin>
            )
        }
    }
)

const mapStateToProps = ({ currentReport, user, tabsLayout }) => {
    return {
        reportFormFetching: currentReport.isFetchingReportForm,
        data: currentReport.runReportData,
        reportColumns: currentReport?.reportColumns,
        viewColumnsJson: currentReport?.viewColumnsJson,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        timeZoneName: user.profile?.timezone,
        currentReport,
        oldReport: currentReport.reportObj,
        activeKey: tabsLayout.activeKey,
        openedTabs: tabsLayout.panes,
        previousTabs: tabsLayout?.previousTabs,
        reportTabDetails: currentReport.reportDetails,
    };
}

export default connect(mapStateToProps, {
    toggleBackButton,
    deleteTab,
    resetReportForm,
    toggleHasUnsavedChangesFlag,
    clearSaveReports,
    selectTitle,
    clearReportsForm,
    fetchEntityTypes,
    toggleIsFetchingReportForm,
    fetchAdminRoleTemplates,
    fetchRoleTemplates,
    postReport,
    fetchReport,
    updateCustomReportsCurrentPage,
    currentReportFilter,
    updateReportDetails,
    updateTabsLocalStorage,
    updatedSelectedColumns,
    toggleEntityHasChanged,
    fetchTrendingDemos
})(ReportFormNeo);
