import React from 'react'
import { connect } from "react-redux";
import { Form, Row, Col, Input, Button, Switch, Spin, Modal, Alert, Tag, Select, Upload, Icon, Collapse, Table, Tooltip, Popconfirm } from 'antd';
import { batch } from 'react-redux';

import {
    toggleBackButton,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag,
    fetchRoleTemplates,
    fetchDemos,
    fetchSolutionTypes,
    addEZDemosDemo,
    deleteEZDemosDemo,
    allSolutionDemos,
    getEZDemoById,
    toggleIsFetchingEZDemos,
    updateEZDemoRoleTemplate,
    saveEZDemosRoleTemplates,
    cancelEZDemosRoleTemplates,
    postEZDemo,
    clearEZDemoForm,
} from '../../actions';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import CONFIG from '../../config'
import StatusIcon from '../common/StatusIcon';
import pushMessage from '../common/PushMessage';
import FormActionButtons from '../common/FormActionButtons';
import { checkRoleTemplateChanges } from "../../utils/status";
import { getFileName } from '../../utils/file';
import { validateURL } from '../../utils/strings';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

let fileDropped = false;
const customPanelStyle = {
    background: '#fbfbfb',
    borderRadius: 4,
    marginBottom: 5,
    border: 0,
    overflow: 'hidden',
};
const EZDemosForm = Form.create({ name: '_form' })(
    class extends React.Component {

        constructor(props) {
            super(props);
            this.isEditMode = this.props.match.params.ezDemoId !== undefined;
            this.state = {
                visible: false,
                isFile: true,
                file: undefined,
                hasFileUpdated: false,
                isLoaded: false
            };
        }

        componentWillUnmount() {
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
            this.props.clearEZDemoForm();
            this.setState({
                file: undefined,
                hasFileUpdated: false,
                isLoaded: false
            })
            fileDropped = false;
        }

        getCurrentData = (formDetails) => {
            const { data } = this.props;
            const isFile = formDetails?.isFile?.includes?.("File")
            return {
                title: formDetails.title,
                isActive: formDetails.isActive,
                roleTemplates: data?.roleTemplates,
                description: formDetails.description,
                isFile,
                link: isFile === false ? formDetails?.url : null,
                media: formDetails.media,
                isRatingReset: formDetails.isRatingReset,
            }
        }

        componentDidUpdate() {
            const { openedTabs, activeKey } = this.props;
            const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
            if (!hasUnsavedChanges && this.hasChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
            else if (hasUnsavedChanges && !this.hasChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        }

        static getDerivedStateFromProps(props, state) {
            const { data } = props;
            const editMode = props.match.params.ezDemoId;
            const { link, isFile } = data;
            if (editMode && !fileDropped && isFile && !state.isLoaded) {
                let mediaFile;
                mediaFile = new File([], getFileName(link));
                mediaFile.uid = Date.now();
                const uploadedFile = link ? mediaFile : undefined;
                return { ...state, file: uploadedFile, isLoaded: true }
            }
            return state;
        }

        componentDidMount() {
            const { ezDemoId } = this.props.match.params;
            batch(async () => {
                this.props.toggleIsFetchingEZDemos(true);
                this.props.toggleBackButton(false);
                await this.props.fetchDemos({ access: 2, getAll: true })
                await this.props.fetchSolutionTypes(undefined, false)
                const fetchAllRoleTemplates = this.props.fetchRoleTemplates;
                await fetchAllRoleTemplates()
                    .then(async () => {
                        if (ezDemoId) {
                            await this.props.getEZDemoById(ezDemoId);
                            this.setState({
                                isFile: this.props.data?.isFile
                            })
                        }
                    });
                const solutionDemos = [...(this.props.solutionTypes?.length > 0 ? this.props.solutionTypes?.map(solution => { return { ...solution, isDemo: false, } }) : []), ...(this.props.allDemos?.length > 0 ? this.props.allDemos?.map(demo => { return { ...demo, isDemo: true } }) : [])]
                await this.props.allSolutionDemos(solutionDemos);
                this.props.toggleIsFetchingEZDemos(false);
            });
            this.introKey = Math.random().toString();
            this.bodyKey = Math.random().toString();
        }

        showModal = () => {
            this.setState({
                visible: true
            });
        };

        handleRoleTemplatesCancel = e => {
            this.props.cancelEZDemosRoleTemplates();
            this.setState({
                visible: false
            });
        };

        handleRoleTemplatesAssign = () => {
            this.props.saveEZDemosRoleTemplates();
            this.setState({ visible: false });
        }

        handleSubmit = () => {
            this.props.form.validateFields(async (err, values) => {
                const isFile = values?.isFile?.includes("File")
                if (err) {
                    return;
                }
                else if (!this.state.file && isFile) {
                    this.props.form.setFields({
                        file: { errors: [Error("Please upload a file")] }
                    });
                    return;
                }
                else {
                    const { mappedDemos } = this.props;
                    const [demos, solutionTypes] = [mappedDemos?.filter(demo => demo?.isDemo)?.map(eachDemo => { return { demo: eachDemo } }), mappedDemos?.filter(demo => !demo?.isDemo)?.map(solution => { return { solutionType: solution } })];
                    this.props.toggleIsFetchingEZDemos(true);
                    await this.props.postEZDemo(this.props.match.params.ezDemoId, values, demos, solutionTypes, this.state.file)
                }
            });
        }

        getAssignedRoleTemplates = (isSuperAdminUser) => {
            const { data } = this.props;
            const { roleTemplates } = data;
            let assignedRoleTemplates = [];
            if (roleTemplates) {
                roleTemplates.forEach(obj => {
                    const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                    const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                    if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                        assignedRoleTemplates.push(obj.roleTemplate.title);
                    }
                })
            }
            return assignedRoleTemplates;
        }

        renderAssignButton = () => {
            return <Button className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
        }

        renderWarningMessage = () => {
            return <Alert message={CONFIG.systemMessage.ckEditorSourceModeWarning} type="warning" showIcon />;
        }

        checkSolutionDemoChanges = (oldMappedDemos, mappedDemos) => {
            const modifiedOldData = oldMappedDemos?.length > 0 ? oldMappedDemos?.map(demo => { return JSON.stringify({ id: demo?.id, isDemo: demo?.isDemo }) }) : []
            const modifiedNewData = mappedDemos?.length > 0 ? mappedDemos?.map(demo => { return JSON.stringify({ id: demo?.id, isDemo: demo?.isDemo }) }) : []
            const data = modifiedNewData?.filter(item => !modifiedOldData?.includes(item))
            if (data?.length !== 0) return true;
            return false;
        }

        checkEZDemoFormChanged = (currentEZDemo, isSuperAdminUser) => {
            const { data, mappedDemos } = this.props;
            const { hasFileUpdated } = this.state;
            let hasChanged = false;
            const oldMappedDemos = [...(data?.ezDemoObj?.demos?.length > 0 ? data?.ezDemoObj?.demos?.map((demo) => { return { id: demo?.demo?.id, title: demo?.demo?.title, isActive: demo?.demo?.isActive, isDemo: true } }) : []), ...(data?.ezDemoObj?.solutionTypes?.length > 0 ? data?.ezDemoObj?.solutionTypes?.map((solution) => { return { id: solution?.solutionType?.id, title: solution?.solutionType?.name, isActive: solution?.solutionType?.isActive, isDemo: false } }) : [])]
            hasChanged = data?.title?.trim() !== currentEZDemo?.title?.trim()
                || data?.isActive !== currentEZDemo?.isActive
                || checkRoleTemplateChanges(data?.ezDemoObj?.roleTemplates, data?.roleTemplates, isSuperAdminUser)
                || (!currentEZDemo?.isFile ? data?.link?.trim() !== currentEZDemo?.link?.trim() : false)
                || data?.isFile !== currentEZDemo?.isFile
                || data?.description?.trim() !== currentEZDemo?.description?.trim()
                || hasFileUpdated
                || currentEZDemo?.isRatingReset

            if (hasChanged) return hasChanged;

            hasChanged = oldMappedDemos?.length !== mappedDemos?.length ? true : this.checkSolutionDemoChanges(oldMappedDemos, mappedDemos);
            if (hasChanged) return hasChanged;

            return hasChanged;
        }

        renderEZDemoOption = () => {
            return CONFIG.ezDemoType.map((type, index) => {
                return <Option key={type} value={JSON.stringify(type)}>{type?.text}</Option>
            });
        }

        handleTypeChange = (value) => {
            const item = JSON.parse(value);
            this.setState({ isFile: item?.value })
        }

        renderPanelHeader = (headerText, icon) => {
            return (
                <span className="large-text bold">
                    <Icon className="primary-text-color" type={icon} />
                    &nbsp;&nbsp;&nbsp;
                    {headerText}
                </span>
            )
        }

        renderDemoOptions = (mappedDemos) => {
            const { solutionDemos } = this.props;
            const filteredDemos = solutionDemos?.length > 0 && solutionDemos?.filter(oldArr =>
                mappedDemos?.every(demo => ((demo?.id !== oldArr?.id) || (demo?.id === oldArr?.id && demo?.isDemo !== oldArr?.isDemo))));
            if (filteredDemos) {
                return filteredDemos.map((demo, index) => <Option key={index} value={JSON.stringify({ id: demo?.id, title: demo?.isDemo ? demo?.title : demo?.name, isActive: demo?.isActive, isDemo: demo?.isDemo })} title={demo?.isDemo ? demo?.title : demo?.name}>
                    {this.renderSolutionDemosDropDown(demo)}
                </Option>);
            }
            else {
                return [];
            }
        }

        renderSolutionDemosDropDown = (demo) => {
            return (
                <div>
                    {demo?.isDemo ? demo?.title : demo?.name}
                    &nbsp;{!demo?.isDemo && <span className='float-right'>
                        <Tag color="volcano">Solution</Tag></span>}
                </div>
            )
        }

        handleAddDemo = () => {
            const { form } = this.props;
            const newDemo = form.getFieldsValue().demo;
            newDemo.forEach(async (demo) => {
                await this.props.addEZDemosDemo(JSON.parse(demo))
            })
            form.setFieldsValue({ demo: undefined });
        }

        render() {
            const { getFieldDecorator } = this.props.form;
            const { roleTemplates, user, form, mappedDemos, allDemos, loading, data } = this.props;
            const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
            const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
            const selectedType = CONFIG.ezDemoType.find(type => type.value === data?.isFile);
            const { isFile } = this.state;
            const { ezDemoId } = this.props.match.params;
            const currentEZDemo = this.getCurrentData(this.props.form.getFieldsValue());
            this.hasChanged = ezDemoId ? data && this.checkEZDemoFormChanged(currentEZDemo, isSuperAdminUser) : true;

            const uploadProps = {
                multiple: false,
                // listType: "picture",
                fileList: this.state.file ? [this.state.file] : [],
                beforeUpload: (file) => {
                    const { form } = this.props;
                    const isWithinUploadLimit = file.size / 1024 / 1024 < CONFIG.fileSize.collateral;
                    if (!isWithinUploadLimit) {
                        pushMessage(CONFIG.messageType.warning, `File must be smaller than ${CONFIG.fileSize.collateral} MB`)
                        return true;
                    }
                    fileDropped = true;
                    this.setState({
                        file,
                        hasFileUpdated: true
                    });
                    form.setFields({
                        file: { errors: [] }
                    });
                    return false;
                },
                onRemove: () => this.setState({ file: undefined, hasFileUpdated: true })
            };

            const demoColumns = [
                {
                    title: 'Solution Demos',
                    key: 'title',
                    width: '60%',
                    align: 'left',
                    render: (record) => record.title
                },
                {
                    title: 'Status',
                    dataIndex: 'isActive',
                    key: 'isActive',
                    width: '15%',
                    render: (isActive) => <StatusIcon status={isActive} />,
                    align: 'center'
                },
                {
                    title: 'Type',
                    key: 'type',
                    width: '30%',
                    align: 'center',
                    render: (record) => <Tag color={record?.isDemo ? "geekblue" : "volcano"}>{record?.isDemo ? "Demo" : "Solution"}</Tag>
                },
                {
                    title: 'Action',
                    key: 'action',
                    width: '10%',
                    render: (record) => {
                        const hasFullAccess = allDemos && allDemos.find(demo => (demo.id === record.demo?.id) || (demo.id === record.id)) !== undefined;
                        const isDisabled = user && user.roleTemplate.templateType !== CONFIG.roleTypes.superAdmin && !hasFullAccess
                        return (
                            <div>
                                <Popconfirm disabled={isDisabled} title="Confirm Delete?" okText="Yes" cancelText="No" onConfirm={() => this.props.deleteEZDemosDemo(record.id, record.isDemo)}>
                                    <Tooltip title={isDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        <button disabled={isDisabled} className={!isDisabled ? "link" : "link-disabled"}>
                                            <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </Popconfirm>
                            </div>
                        )
                    },
                    align: 'center'
                },
            ];

            return (
                <Spin spinning={loading} wrapperClassName="spin-overlay">
                    <Row className="right-align vertical-spacing-2" style={{ paddingTop: "13px", paddingRight: "10px" }}>
                        <FormActionButtons
                            handleSubmit={this.handleSubmit}
                            handleCancel={() => this.props.closeCurrentOpenTab()}
                            cancelText={"Discard & Close"}
                            isDisabled={!this.hasChanged}
                            okText={this.isEditMode ? "Update" : "Create"} />
                    </Row>
                    <Collapse
                        style={{ backgroundColor: 'white' }}
                        accordion
                        defaultActiveKey={"1"}
                        bordered={false}
                        // expandIconPosition='right'
                        expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                    >
                        <Panel style={customPanelStyle} header={this.renderPanelHeader('Basic Details', 'profile')} key="1" >
                            <div className="vertical-spacing-3">
                                <Row gutter={48}>
                                    <Col xl={8} sm={10} xs={24}>
                                        <Form.Item label="Title">
                                            {getFieldDecorator("title", {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "Please input the title"
                                                    }
                                                ],
                                                initialValue: data?.title
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                    {isSuperAdminUser && <Col xl={8} sm={10} xs={24}>
                                        <Form.Item label="Assign Role Template">
                                            {getFieldDecorator("roletemplate", {
                                                initialValue: assignedRoleTemplates.length !== 0
                                                    ? assignedRoleTemplates
                                                    : "None"
                                            })(<Input className="role-template-input" disabled addonAfter={this.renderAssignButton()} />)}
                                            {<Modal
                                                title="Role Template"
                                                visible={this.state.visible}
                                                onCancel={this.handleRoleTemplatesCancel}
                                                footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                                            >
                                                <RoleTemplatesTable
                                                    hideColumns={["user"]}
                                                    dataKey="roleTemplate"
                                                    data={roleTemplates.all}
                                                    pagination={false}
                                                    assignedData={data.roleTemplates}
                                                    allAdminAccess={data.allRTAdminAccess}
                                                    allUserAccess={data.allRTUserAccess}
                                                    updateRoleTemplateData={this.props.updateEZDemoRoleTemplate}
                                                />
                                            </Modal>
                                            }
                                        </Form.Item>
                                    </Col>}
                                    <Col span={4} sm={4} xs={24}>
                                        <Form.Item label="Status">
                                            {getFieldDecorator("isActive", {
                                                valuePropName: "checked",
                                                initialValue: data?.isActive
                                            })(<Switch
                                                checkedChildren="Active"
                                                unCheckedChildren="Inactive"
                                            />)}

                                        </Form.Item>
                                    </Col>
                                    <Col span={4} sm={4} xs={24}>
                                        <Form.Item label="Reset Ratings">
                                            {getFieldDecorator("isRatingReset", {
                                                valuePropName: "checked",
                                                initialValue: false
                                            })(
                                                <Switch
                                                    checkedChildren="Yes"
                                                    unCheckedChildren="No"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Item label="Description">
                                        {getFieldDecorator("description", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please input the description"
                                                }
                                            ],
                                            initialValue: data?.description
                                        })(<TextArea rows={4} />)}
                                    </Form.Item>
                                </Row>
                                <Row gutter={48}>
                                    <Col span={5}>
                                        <Form.Item label="Type">
                                            {getFieldDecorator('isFile', {
                                                rules: [{ required: true, message: 'Please select a type' }],
                                                initialValue: selectedType ? JSON.stringify(selectedType) : JSON.stringify(CONFIG.ezDemoType[0])
                                            })(
                                                <Select
                                                    onChange={this.handleTypeChange}
                                                    showSearch={true}
                                                    placeholder="Select a type" >
                                                    {this.renderEZDemoOption()}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={<span>{isFile ? "File" : "Link"} {isFile && <span>
                                            <Tooltip title="Allowed Types : ppt, pptx, doc, docx, txt, csv, xlx, xlsx, pdf, epub, mp4, mpg, mpv, avi, gif, jpg, jpeg, png, zip, tar, gz, rar">
                                                <Icon type="info-circle" className="text-color-primary" />
                                            </Tooltip>
                                        </span>}</span>} required>
                                            {
                                                isFile ?
                                                    getFieldDecorator('link')(
                                                        <Upload {...uploadProps}>
                                                            <Button className='cancel-button'>
                                                                <Icon type="upload" /> Click to Upload</Button>
                                                        </Upload>
                                                    )
                                                    : getFieldDecorator('url', {
                                                        rules: [{ required: true, message: "Please enter a Valid URL", validator: validateURL, }],
                                                        initialValue: !data?.isFile ? data?.link : undefined,
                                                    })(<Input />)
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                        <Panel style={customPanelStyle} className="bottom-spacing" header={this.renderPanelHeader('Assign Solution Demos', 'solution')} key="2" >
                            <div className="vertical-spacing-3">
                                <Row gutter={24}>
                                    <Col xl={18} sm={18} xs={24}>
                                        <Row gutter={24}>
                                            <Col xl={18} sm={18} xs={24}>
                                                <Form.Item>
                                                    {
                                                        getFieldDecorator('demo')(
                                                            <Select
                                                                showSearch={true}
                                                                mode="multiple"
                                                                placeholder="Select a solution/demos">
                                                                {this.renderDemoOptions(mappedDemos)}
                                                            </Select>
                                                        )
                                                    }
                                                </Form.Item>
                                            </Col>
                                            <Col xl={4} sm={6} xs={24}>
                                                <Button className="primary-action-button-bordered horizontal-spacing antd-button-fix" onClick={this.handleAddDemo} disabled={(!form.getFieldValue('demo') || form.getFieldValue('demo')?.length === 0)}>
                                                    Add
                                                    <Icon type="plus-circle" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Table
                                            size='middle'
                                            className="vertical-spacing-2 medium-content responsive-container"
                                            columns={demoColumns}
                                            dataSource={mappedDemos}
                                            bordered={false}
                                            pagination={false}
                                            rowKey={(record) => `${record.id}${record?.isDemo}`}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                    <Row className="right-align bottom-spacing" style={{ paddingBottom: "13px", paddingRight: "10px" }}>
                        <FormActionButtons
                            handleSubmit={this.handleSubmit}
                            handleCancel={() => this.props.closeCurrentOpenTab()}
                            cancelText={"Discard & Close"}
                            isDisabled={!this.hasChanged}
                            okText={this.isEditMode ? "Update" : "Create"} />
                    </Row>
                </Spin >
            );
        }
    })

const mapStateToProps = ({ manageEZDemos, roleTemplates, user, tabsLayout, demos, solutionTypes, ezDemosForm }) => {
    return {
        timeZoneName: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        user: user.profile,
        roleTemplates,
        hasSave: tabsLayout.hasSave,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        allDemos: demos.allDemos,
        solutionTypes: solutionTypes.types,
        mappedDemos: ezDemosForm.mappedDemos,
        solutionDemos: ezDemosForm.solutionDemos,
        loading: manageEZDemos.loading,
        data: ezDemosForm,
        ezDemoObj: ezDemosForm?.ezDemoObj,
    };
};

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        closeCurrentOpenTab,
        toggleHasUnsavedChangesFlag,
        fetchRoleTemplates,
        fetchDemos,
        fetchSolutionTypes,
        addEZDemosDemo,
        deleteEZDemosDemo,
        allSolutionDemos,
        getEZDemoById,
        toggleIsFetchingEZDemos,
        updateEZDemoRoleTemplate,
        saveEZDemosRoleTemplates,
        cancelEZDemosRoleTemplates,
        postEZDemo,
        clearEZDemoForm,
    }
)(EZDemosForm);

