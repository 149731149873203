import React from 'react';
import { Form, Input, Row, Divider, Checkbox, Radio, Descriptions, Col, Icon, Spin } from 'antd';
import moment from 'moment-timezone';
import { Button } from "@avaya/neo-react"
import CONFIG from '../../config';
import { connect } from "react-redux";


import {
    selectTitle,
    toggleBackButton,
    currentScheduleFilter,
    toggleIsFetchingDemoSchedules,
    fetchScheduleDetailsById,
    closeCurrentOpenTab
}
    from '../../actions';

const { TextArea } = Input;
class ScheduledDemoViewDetails extends React.Component {
    renderUserQuestionsAnswers = (questions) => {
        const { question, answerText, questionOptions } = questions;
        const { answerType, title } = question;
        let options, answers;
        options = questionOptions && questionOptions?.split("//");
        answers = answerType === CONFIG.demoAnswerTypes.Checkbox && answerText?.split("//");

        return (
            <React.Fragment>
                <Form.Item label={title}>
                    {answerType === CONFIG.demoAnswerTypes.Textbox ?
                        <><TextArea style={{ width: '500px' }} value={answerText} readOnly={true} rows={2} /></>
                        : answerType === CONFIG.demoAnswerTypes.RadioButton ?
                            <Radio.Group value={answerText} readOnly={true}>
                                {options?.map(opt =>
                                    (<Radio key={opt} label={opt} value={opt}>{opt}</Radio>))}
                            </Radio.Group>
                            : <Checkbox.Group readOnly={true} value={answers} >{options?.map(opt =>
                                (<Checkbox key={opt} label={opt} value={opt}>{opt}</Checkbox>))}
                            </Checkbox.Group>
                    }
                </Form.Item>
            </React.Fragment>
        )
    }


    renderPurposeAttributes = (purposeAttributesSchedule) => {
        return (purposeAttributesSchedule?.map((purpose) => {
            return (
                purpose?.demoPurposeAttribute?.isVisible &&
                <Descriptions.Item span={4} label={purpose?.demoPurposeAttribute?.attributeLabel}>{purpose?.attributeValue || "N/A"}</Descriptions.Item>
            )
        })
        )
    }

    renderDemoPurposeDetails = () => {
        const { demoPurpose, purposeAttributesSchedule, demo } = this.props.scheduleDetails
        return (<>
            <Descriptions.Item span={4} label="Demo Type">{demo?.demoType}</Descriptions.Item>
            <Descriptions.Item span={4} label="Demo Purpose">{demoPurpose?.purpose}</Descriptions.Item>
            {purposeAttributesSchedule?.length > 0 && this.renderPurposeAttributes(purposeAttributesSchedule)}
        </>
        )
    }

    renderViewDetailsSection = (userAnswers) => {
        const schedule = this.props.scheduleDetails || {}
        const { timeZoneName } = this.props
        const { demo, startTime, endTime, createdTime, createdBy, resource, managerEmail, parameters, country, participants } = schedule
        const scheduleParticipants = participants !== null && participants?.length > 0 && [...participants?.eapUsers, ...participants?.extUsers]?.filter(par => par?.isCreator !== true);
        return (<Row>
            <div className='dashboard-schedules-category-heading'>
                Schedule Details
            </div>
            <Col span={14}>
                <Descriptions className="demo-approvers-details" >
                    <Descriptions.Item span={4} label="Demo Name" id="view-details-demo-name">{demo?.title}</Descriptions.Item>
                    {resource !== null && <Descriptions.Item span={4} label="Resource Name">{demo?.demoType === CONFIG.demoTypes.standardDemo ? resource?.name : '-'}</Descriptions.Item>}
                    <Descriptions.Item span={4} label="Created By" id="view-details-created-by">{`${createdBy?.firstName}  ${createdBy?.lastName}  (${createdBy?.email || "N/A"})`}</Descriptions.Item>
                    <Descriptions.Item span={4} label="Schedule Created on">{moment.tz(createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</Descriptions.Item>
                    <Descriptions.Item span={4} label="Schedule Start time">{moment.tz(startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</Descriptions.Item>
                    <Descriptions.Item span={4} label="Schedule End time">{moment.tz(endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</Descriptions.Item>
                    {scheduleParticipants && <Descriptions.Item span={4} label="Participants">{scheduleParticipants?.reduce((acc, item) => { acc = acc !== "" ? acc + ", " + item.email : acc + item.email; return acc }, "")}</Descriptions.Item>}
                    {managerEmail !== null && <Descriptions.Item span={4} label="Manager's Email">{managerEmail ? managerEmail : 'N/A'}</Descriptions.Item>}
                    {(parameters != null || parameters !== "") && <Descriptions.Item span={4} label="Selected Capabilities">{parameters ? parameters?.replaceAll("\\", ", ") : "N/A"}</Descriptions.Item>}
                    {country !== null && <Descriptions.Item span={4} label="Country">{country ? country?.name : "N/A"}</Descriptions.Item>}
                    {!userAnswers?.length > 0 && this.renderDemoPurposeDetails()}
                </Descriptions>
            </Col>
            {userAnswers?.length > 0 &&
                <><Col span={10}>
                    <Descriptions className="demo-approvers-details" >
                        {this.renderDemoPurposeDetails()}
                    </Descriptions>
                </Col>
                </>}
        </Row>
        )
    }

    componentDidMount() {
        this.props.toggleIsFetchingDemoSchedules(true)
        // const { scheduleId } = this.props.match.params
        const { fromDashboard } = this.props
        this.props.fetchScheduleDetailsById(fromDashboard ? this.props.scheduleId : this.props.match.params.scheduleId, fromDashboard);
    }

    render() {
        const { userAnswers } = this.props.scheduleDetails
        const showUserAnswers = userAnswers?.length > 0
        return (
            <div className={!this.props.fromDashboard && "content-container"}>
                <Spin spinning={this.props.isFetching} wrapperClassName="spin-overlay">
                    {this.renderViewDetailsSection(userAnswers)}
                    <Form >
                        {showUserAnswers
                            &&
                            <>
                                <Divider />
                                <div className='demo-registration-form-header left-align'>
                                    <Icon style={{ fontSize: '20px', paddingRight: '5px', color: "#da291c" }} width={1} type="form" />
                                    Additional Information
                                </div>
                                {userAnswers?.map(ques => { return this.renderUserQuestionsAnswers(ques) })}
                            </>
                        }
                    </Form>
                    {!this.props.fromDashboard && <Row className="vertical-spacing right-align">
                        <Button className="float-right right-spacing cancel-button" onClick={() => this.props.closeCurrentOpenTab()}>Close</Button>
                    </Row>}
                </Spin>
            </div>
        )
    }
}
const mapStateToProps = ({ user, manageDemoSchedule }) => {
    return {
        timeZoneName: user.profile ? user.profile.timezone : '',
        isFetching: manageDemoSchedule.isFetching,
        scheduleDetails: manageDemoSchedule.scheduleDetails,
    };
};

const SchedulePreRequisiteRef = Form.create()(ScheduledDemoViewDetails);

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        currentScheduleFilter,
        toggleIsFetchingDemoSchedules,
        fetchScheduleDetailsById,
        closeCurrentOpenTab
    }
)(SchedulePreRequisiteRef);