import React from 'react'
import { connect } from "react-redux";
import { Form, Row, Col, Input, Button, Switch, Spin, Modal, Alert } from 'antd';
import { batch } from 'react-redux';

import {
    toggleBackButton,
    fetchTip,
    toggleIsFetchingTipsFlag,
    postTip,
    updateTipsCurrentPage,
    updateTipDescription,
    updateTipRoleTemplate,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    saveTipsRoleTemplates,
    cancelTipsRoleTemplates,
    clearCurrentTip,
    currentTipFilter,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag
} from '../../actions';
import TextEditor from '../common/TextEditor';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import CONFIG from '../../config'
import FormActionButtons from '../common/FormActionButtons';
import { checkRoleTemplateChanges } from "../../utils/status";

const TipForm = Form.create({ name: '_form' })(
    class extends React.Component {

        constructor(props) {
            super(props);
            this.isEditMode = this.props.match.params.tipId !== undefined;
            this.state = {
                visible: false,
            };
        }

        componentWillUnmount() {
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
            this.props.clearCurrentTip();
        }

        getCurrentData = (formDetails) => {
            const { tipDetails, tipIntro, tipBody } = this.props;
            return {
                title: formDetails.title,
                tipIntro: tipIntro.html,
                tipBody: tipBody.html,
                isActive: formDetails.isActive,
                roleTemplates: tipDetails?.roleTemplates

            }
        }

        componentDidUpdate() {
            // const { hasSave } = this.props;
            // const { roleTemplateId } = this.props.match.params;
            // if (roleTemplateId) {
            //     if (!hasSave && this.hasChanged) this.props.hasSaveChanges(true);
            //     if (hasSave && !this.hasChanged) this.props.hasSaveChanges(false);
            // }
            // else this.props.hasSaveChanges(true);
            const { openedTabs, activeKey } = this.props;
            const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
            if (!hasUnsavedChanges && this.hasChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
            else if (hasUnsavedChanges && !this.hasChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        }

        componentDidMount() {
            const { tipId } = this.props.match.params;
            const { userRoleTemplate } = this.props;
            const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
            batch(() => {
                this.props.toggleBackButton(false);
                const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;
                fetchAllRoleTemplates()
                    .then(() => {
                        if (tipId) {
                            this.props.toggleIsFetchingTipsFlag(true);
                            this.props.fetchTip(tipId);
                        }
                    });
            });
            this.introKey = Math.random().toString();
            this.bodyKey = Math.random().toString();
        }

        showModal = () => {
            this.setState({
                visible: true
            });
        };

        handleRoleTemplatesCancel = e => {
            this.props.cancelTipsRoleTemplates();
            this.setState({
                visible: false
            });
        };

        handleRoleTemplatesAssign = () => {
            this.props.saveTipsRoleTemplates();
            this.setState({ visible: false });
        }

        handleSubmit = () => {
            this.props.form.validateFields(async (err, values) => {
                if (!err) {
                    this.props.toggleIsFetchingTipsFlag(true);
                    await this.props.postTip(this.props.match.params.tipId, values)
                }
            });
        }

        handleReset = () => {
            this.props.updateTipsCurrentPage({ pageNumber: 1 })
            this.props.closeCurrentOpenTab();
        }

        getAssignedRoleTemplates = (isSuperAdminUser) => {
            const { tipDetails } = this.props;
            const { roleTemplates } = tipDetails;
            let assignedRoleTemplates = [];
            if (roleTemplates) {
                roleTemplates.forEach(obj => {
                    const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                    const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                    if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                        assignedRoleTemplates.push(obj.roleTemplate.title);
                    }
                })
            }
            return assignedRoleTemplates;
        }

        renderAssignButton = () => {
            return <Button className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
        }

        renderWarningMessage = () => {
            return <Alert message={CONFIG.systemMessage.ckEditorSourceModeWarning} type="warning" showIcon />;
        }

        checkTipsFormChanged = (currentTip, isSuperAdminUser) => {
            const { tipDetails } = this.props;
            let hasChanged = false;
            hasChanged = currentTip?.title?.trim() !== tipDetails?.title?.trim()
                || (currentTip?.tipIntro === null ? "" : currentTip?.tipIntro?.trim()) !== (tipDetails?.intro === null ? "" : tipDetails?.intro?.trim())
                || (currentTip?.tipBody === null ? "" : currentTip?.tipBody?.trim()) !== (tipDetails?.body === null ? "" : tipDetails?.body?.trim())
                || currentTip?.isActive !== tipDetails?.isActive
                || checkRoleTemplateChanges(tipDetails?.tipObj?.roleTemplates, currentTip?.roleTemplates, isSuperAdminUser)

            if (hasChanged) return hasChanged;
            return hasChanged;
        }

        render() {
            const { getFieldDecorator } = this.props.form;
            const { tipDetails, roleTemplates, user } = this.props;
            const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
            const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);

            const { tipId } = this.props.match.params;
            const currentTip = this.getCurrentData(this.props.form.getFieldsValue());
            this.hasChanged = tipId ? tipDetails && this.checkTipsFormChanged(currentTip, isSuperAdminUser) : true;
            return (
                <Spin spinning={this.props.tips.isFetching} wrapperClassName="spin-overlay">
                    <div className="content-container">
                        <Row gutter={48}>
                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item label="Title">
                                    {getFieldDecorator("title", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input the title of the tip"
                                            }
                                        ],
                                        initialValue: tipDetails?.title
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xl={10} sm={10} xs={24}>
                                <Form.Item label="Assign Role Template">
                                    {getFieldDecorator("roletemplate", {
                                        initialValue: assignedRoleTemplates.length !== 0
                                            ? assignedRoleTemplates
                                            : "None"
                                    })(<Input className="role-template-input" disabled addonAfter={this.renderAssignButton()} />)}
                                    {<Modal
                                        title="Role Template"
                                        visible={this.state.visible}
                                        onCancel={this.handleRoleTemplatesCancel}
                                        footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                                    >
                                        <RoleTemplatesTable
                                            hideColumns={isSuperAdminUser ? [] : ['admin']}
                                            dataKey="roleTemplate"
                                            data={roleTemplates.all}
                                            pagination={false}
                                            assignedData={tipDetails.roleTemplates}
                                            allAdminAccess={tipDetails.allRTAdminAccess}
                                            allUserAccess={tipDetails.allRTUserAccess}
                                            updateRoleTemplateData={this.props.updateTipRoleTemplate}
                                        />
                                    </Modal>
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={4} sm={4} xs={24}>
                                <Form.Item label="Status">
                                    {getFieldDecorator("isActive", {
                                        valuePropName: "checked",
                                        initialValue: tipDetails?.isActive
                                    })(<Switch
                                        checkedChildren="Active"
                                        unCheckedChildren="Inactive"
                                    />)}

                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="bottom-spacing">
                            <h3>Description</h3>
                            {this.renderWarningMessage()}
                            <br />
                            <TextEditor
                                updateEditorContent={this.props.updateTipDescription}
                                storeKey="tipIntro"
                                componentKey="intro"
                                editorKey={this.introKey}
                                isEdit={this.isEditMode}
                            />
                        </div>
                        <div className="vertical-spacing">
                            <h3>Additional Info</h3>
                            {this.renderWarningMessage()}
                            <br />
                            <TextEditor updateEditorContent={this.props.updateTipDescription} storeKey="tipBody" componentKey="body" editorKey={this.bodyKey} isEdit={this.isEditMode} />
                        </div>
                        <Row className="right-align">
                            <FormActionButtons
                                handleSubmit={this.handleSubmit}
                                handleCancel={() => this.props.closeCurrentOpenTab()}
                                cancelText={"Discard & Close"}
                                isDisabled={!this.hasChanged}
                                okText={this.isEditMode ? "Update" : "Create"} />
                        </Row>
                    </div>
                </Spin >
            );
        }
    })

const mapStateToProps = ({ currentTip, tips, roleTemplates, viewport, user, tipIntro, tipBody, tabsLayout }) => {
    return {
        tipDetails: currentTip,
        timeZoneName: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        user: user.profile,
        tips,
        roleTemplates,
        viewport,
        tipIntro,
        tipBody,
        hasSave: tabsLayout.hasSave,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
    };
};

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        fetchTip,
        toggleIsFetchingTipsFlag,
        postTip,
        updateTipsCurrentPage,
        updateTipDescription,
        updateTipRoleTemplate,
        fetchRoleTemplates,
        fetchAdminRoleTemplates,
        saveTipsRoleTemplates,
        cancelTipsRoleTemplates,
        clearCurrentTip,
        closeCurrentOpenTab,
        toggleHasUnsavedChangesFlag,
        currentTipFilter
    }
)(TipForm);

