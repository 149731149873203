import React from 'react';
import { connect, batch } from 'react-redux';
import TextEditor from '../common/TextEditor';
import {
    updateEmailTemplateEditorContent,
    fetchEmailTemplateById,
    saveEmailTemplates,
    clearEmailTemplateContent,
    toggleIsFetchingEmailTemplateFlag,
    fetchPlaceHoldersByEmailTemplateId,
    toggleBackButton,
    updateEmailTemplateTabDetails,
    updateTabsLocalStorage,
    toggleHasUnsavedChangesFlag,
    closeCurrentOpenTab
} from '../../actions';
import FormActionButtons from '../common/FormActionButtons';
import history from '../../history';
import { Switch, Form, Input, Row, Col } from "antd"
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage, } from "../common/TabLayout";
import CONFIG from '../../config'
import { checkIfStandardEmailTemplateHasChanged } from '../../utils/formChanged'


const { TextArea } = Input

class EmailTemplateForm extends React.Component {


    componentDidUpdate(prevProps, prevState) {
        const currentTemplateFormData = this.getCurrentData(this.props.form.getFieldsValue());
        const currentEmailTemplate = this.getCurrentEmailTemplateData();
        const { originalDetails } = currentEmailTemplate || [];
        this.hasChanged = checkIfStandardEmailTemplateHasChanged(currentTemplateFormData, originalDetails);
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    }

    componentDidMount() {
        this.emailKey = Math.random().toString();
        const { emailTemplateId } = this.props.match.params;
        const { previousTabs } = this.props;
        const currentPath = window.location.pathname

        // Checking if the current exact tab is the openTabs list. 
        if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
            if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                // Althought the tab is present in  openTabs list, data is not present since there was a browser refresh. Hence fetching from API
                batch(() => {
                    if (emailTemplateId) {
                        this.props.toggleIsFetchingEmailTemplateFlag(true);
                        this.props.fetchPlaceHoldersByEmailTemplateId(emailTemplateId)
                            .then(async () =>
                                await this.props.fetchEmailTemplateById(emailTemplateId))
                    }
                });
                this.props.updateTabsLocalStorage(currentPath);
            }
        }
        else {
            // Current exact tab is not in the openTabs list. Hence fetching from API
            batch(() => {
                if (emailTemplateId) {
                    this.props.toggleIsFetchingEmailTemplateFlag(true);
                    this.props.fetchPlaceHoldersByEmailTemplateId(emailTemplateId).then(() =>
                        this.props.fetchEmailTemplateById(emailTemplateId))
                }
            })
        }
    }

    componentWillUnmount() {
        // Checking if the tab is still in the openTab list. If yes, then update store with form data.
        // Email template will always have ID, since there is no CREATE NEW for email templates.
        const { emailTemplateId } = this.props.match.params
        const currentEmailTemplate = this.getCurrentEmailTemplateData();
        const { bodyHtml, details, originalDetails } = currentEmailTemplate || [];

        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
            emailTemplateId === undefined && this.props.updateEmailTemplateTabDetails({
                emailTemplateDetails: {
                    id: parseInt(emailTemplateId),
                    details: { ...details, ...this.props.form.getFieldsValue() },
                    bodyHtml: bodyHtml,
                    originalDetails: originalDetails,
                    isContentLoaded: true
                }
            });
            if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
                this.props.updateEmailTemplateTabDetails({
                    emailTemplateDetails: {
                        id: parseInt(emailTemplateId),
                        details: { ...details, ...this.props.form.getFieldsValue() },
                        bodyHtml: bodyHtml,
                        originalDetails: originalDetails,
                        isContentLoaded: true
                    }
                })
            }
        }
        else {
            // this.props.clearEmailTemplateContent();
            // clear the corresponding form data from Store.
        }
    }

    handleSave = () => {
        const { form } = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const data = {
                title: values.title,
                isAttachment: values.isAttachment,
                description: values.description
            };
            batch(() => {
                this.props.toggleIsFetchingEmailTemplateFlag(true);
                this.props.saveEmailTemplates(data, this.props.match.params?.emailTemplateId).then(() => this.props.clearEmailTemplateContent())
            })
        });

    }

    handleClose = () => {
        this.props.clearEmailTemplateContent();
        history.goBack()
    }

    getCurrentEmailTemplateData = () => {
        const { emailTemplateDetailsTabData } = this.props;
        const { emailTemplateId } = this.props.match.params;
        const id = emailTemplateId !== undefined ? emailTemplateId : "-1";

        return emailTemplateDetailsTabData?.find(item => (
            (parseInt(item?.id) === parseInt(id))));
    }

    getCurrentData = (templateFormDetails) => {
        const currentEmailTemplate = this.getCurrentEmailTemplateData();
        const { bodyHtml } = currentEmailTemplate || [];
        return {
            title: templateFormDetails.title,
            isAttachment: templateFormDetails.isAttachment === undefined ? false : templateFormDetails.isAttachment,
            description: templateFormDetails.description,
            body: bodyHtml
        }
    }

    render() {
        const currentEmailTemplate = this.getCurrentEmailTemplateData();
        const { bodyHtml, details } = currentEmailTemplate || [];
        const { emailPlaceholders, title, description, isAttachment, meeting, type } = details || []
        const { emailTemplateId } = this.props.match.params
        const { getFieldDecorator } = this.props.form;

        return (
            <React.Fragment>
                <div className="content-container">
                    <div class="right-align">
                        <FormActionButtons
                            size="medium"
                            handleSubmit={this.handleSave}
                            handleCancel={() => this.props.closeCurrentOpenTab()}
                            okText="Update"
                            isDisabled={!this.hasChanged}
                            cancelText={"Discard & Close"} />
                    </div>
                    <br />
                    <div>
                        <Form>
                            <Row gutter={48}>
                                <Col xl={12} xs={24}>
                                    <Form.Item label="Title">
                                        {getFieldDecorator("title", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please input the title"
                                                }
                                            ],
                                            initialValue: title ? title : ""
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xl={12} xs={24}>
                                    <Form.Item label="Email Type">
                                        {getFieldDecorator("type", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Please input the title"
                                                }
                                            ],
                                            initialValue: type ? type : ""
                                        })(<Input title={type ? type : null} disabled />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Row>
                                    <Form.Item label="Description">
                                        {getFieldDecorator("description", {
                                            initialValue: description ? description : ""
                                        })(<TextArea rows={4} />)}
                                    </Form.Item>
                                </Row>
                            </Row>
                            {meeting &&
                                <Form.Item label="Attachments">
                                    {getFieldDecorator("isAttachment", {
                                        valuePropName: "checked",
                                        initialValue: isAttachment
                                    })(<Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                    />)}
                                </Form.Item>
                            }
                        </Form>
                    </div>
                    <br />
                    <TextEditor
                        updateEditorContent={this.props.updateEmailTemplateEditorContent}
                        storeKey="emailTemplateForm"
                        componentKey={CONFIG.editorType.standardEmailBody}
                        editorKey={this.emailKey}
                        isEdit={true}
                        recordId={emailTemplateId ? emailTemplateId : "-1 "}
                        contentData={bodyHtml}
                        emailType={this.props.match.params.emailTemplateType}
                        placeholders={emailPlaceholders} />
                </div>
            </React.Fragment>
        )
    }

}

const mapStateToProps = ({ emailTemplateForm, tabsLayout, userRoleTemplate, user }) => {
    return {
        emailTemplateDetailsTabData: emailTemplateForm.emailTemplateDetailsTabData,
        activeKey: tabsLayout.activeKey,
        openedTabs: tabsLayout.panes,
        previousTabs: tabsLayout?.previousTabs,
    }
}

//Here when this page is called call an action to getEmailTemplateById which will have placeholdes content etc
const EmailTemplateDetailsForm = Form.create({ name: 'email_template_details' })(EmailTemplateForm);

export default connect(mapStateToProps,
    {
        fetchEmailTemplateById,
        updateEmailTemplateEditorContent,
        saveEmailTemplates,
        clearEmailTemplateContent,
        toggleIsFetchingEmailTemplateFlag,
        fetchPlaceHoldersByEmailTemplateId,
        toggleBackButton,
        updateEmailTemplateTabDetails,
        updateTabsLocalStorage,
        toggleHasUnsavedChangesFlag,
        closeCurrentOpenTab
    })(EmailTemplateDetailsForm);