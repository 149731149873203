import CONFIG from '../config';
import moment from 'moment-timezone';

// 1. DEMOS
export const checkIfDemoHasChanged = (demo, oldDemo, isSuperAdminUser, formValues) => {
    if (!demo?.length > 0 && oldDemo === undefined) { return true }
    const demoBasicDetails = demo?.demoBasicDetails || {};
    const oldDemoBasicDetails = oldDemo?.demoBasicDetails || {}
    const demoEditorContent = demo?.demoEditorContent || {};
    const oldDemoEditorContent = oldDemo?.demoEditorContent || {};
    let hasChanged = false;
    const isStandardDemo = formValues?.demoType === CONFIG.demoTypes.standardDemo
    const isLabDemo = formValues?.demoType === CONFIG.demoTypes.labPocDemo
    hasChanged = formValues?.title?.trim() !== oldDemoBasicDetails?.title?.trim()
        || formValues?.isActive !== oldDemoBasicDetails?.isActive
        || formValues?.isExpired !== oldDemoBasicDetails?.isExpired
        || formValues?.startTimeBuffer !== oldDemoBasicDetails?.startTimeBuffer
        || formValues?.endTimeBuffer !== oldDemoBasicDetails?.endTimeBuffer
        || (formValues?.schTimeLimit > 0 ? formValues?.schTimeLimit / 60 : 0) !== (oldDemoBasicDetails?.schTimeLimit > 0 ? oldDemoBasicDetails?.schTimeLimit / 60 : 0)
        || demoEditorContent?.descriptionHtml?.trim() !== oldDemoEditorContent?.descriptionHtml?.trim()
        || demoEditorContent?.componentDetailsHtml?.trim() !== oldDemoEditorContent?.componentDetailsHtml?.trim()
        || demoEditorContent?.connectivityDetailsHtml?.trim() !== oldDemoEditorContent?.connectivityDetailsHtml?.trim()
        || formValues?.isProduction === true
        || formValues?.isRatingReset === true
        || formValues?.notifyUsers === true
        || formValues?.isManagerMandatory !== oldDemoBasicDetails?.isManagerMandatory
        || formValues?.approvalRequired !== oldDemoBasicDetails?.approvalRequired
        || formValues?.managerTitle?.trim() !== oldDemoBasicDetails?.managerTitle?.trim()
        || formValues?.notes?.trim() !== oldDemoBasicDetails?.notes?.trim()
        || formValues?.isManagerEmail !== oldDemoBasicDetails?.isManagerEmail
        || formValues?.isRegionalPrime !== oldDemoBasicDetails?.isRegionalPrime
        || formValues?.isMultipleApprover !== oldDemoBasicDetails?.isMultipleApprover
        || formValues?.regionalPrimeTitle?.trim() !== oldDemoBasicDetails?.regionalPrimeTitle?.trim()
        || formValues?.isPreRegistration !== oldDemoBasicDetails?.isPreRegistration
        || formValues?.isOneTime !== oldDemoBasicDetails?.isOneTime
        || formValues?.maxSchedule !== oldDemoBasicDetails?.maxSchedule
        || formValues?.autoDestroy !== oldDemoBasicDetails?.autoDestroy
        || formValues?.vdiRequired !== oldDemoBasicDetails?.vdiRequired
        || formValues?.isManual !== oldDemoBasicDetails?.isManual
        || formValues?.parameters?.trim() !== oldDemoBasicDetails?.parameters?.trim()
        || formValues?.scheduleRange !== oldDemoBasicDetails?.scheduleRange
        || formValues?.reminderHours !== oldDemoBasicDetails?.reminderHours;

    if (hasChanged) return hasChanged;

    //External APIs
    hasChanged = isStandardDemo ? false : formValues?.isManual ? false : JSON.stringify(formValues?.externalApis) !== JSON.stringify(oldDemoBasicDetails?.externalApis);
    if (hasChanged) return hasChanged;

    //Demo Approvers
    const oldDemoApprovers = oldDemoBasicDetails?.demoApprovers?.map(item => { return { level: item.level, approver: item.approver } });
    hasChanged = (!isLabDemo || !formValues?.isMultipleApprover) ? false : JSON.stringify(formValues.demoApprovers) !== JSON.stringify(oldDemoApprovers);
    if (hasChanged) return hasChanged;

    //Regional Prime
    const oldPrimes = oldDemoBasicDetails?.demoRegionalPrimes;
    const currentPrimes = formValues?.demoRegionalPrimes;
    hasChanged = checkPrimeChanges(oldPrimes, currentPrimes);
    if (hasChanged) return hasChanged;

    //Checkbox Group
    hasChanged = isStandardDemo ? false : JSON.stringify(demoBasicDetails?.parameters) !== JSON.stringify(oldDemoBasicDetails?.parameters);
    if (hasChanged) return hasChanged;
    //Resources
    hasChanged = JSON.stringify(demo?.demoResources) !== JSON.stringify(oldDemo?.demoResources);
    if (hasChanged) return hasChanged;

    //EZ Demos

    hasChanged = JSON.stringify(demo?.demoEzDemos) !== JSON.stringify(oldDemo?.demoEzDemos);
    if (hasChanged) return hasChanged;

    //Capabilities
    hasChanged = JSON.stringify(demo?.demoCapability) !== JSON.stringify(oldDemo?.demoCapability);
    if (hasChanged) return hasChanged;

    // Collaterals
    let newCollaterals = demo?.demoCollaterals?.assignedCollaterals;
    let oldCollaterals = oldDemo?.demoCollaterals?.assignedCollaterals;
    newCollaterals = getCollateralObjs(newCollaterals);
    oldCollaterals = getCollateralObjs(oldCollaterals);
    hasChanged = JSON.stringify(newCollaterals) !== JSON.stringify(oldCollaterals);

    if (hasChanged) return hasChanged;

    //Status
    let newStatus = demo?.demoDCStatuses;
    let oldStatus = oldDemo?.demoDCStatuses;
    newStatus.forEach(dataCenterStatus => {
        const oldMatchedDCStatus = oldStatus?.find(status => status?.dataCenter?.id === dataCenterStatus?.dataCenter?.id);
        if (oldMatchedDCStatus && (dataCenterStatus?.status !== oldMatchedDCStatus?.status || dataCenterStatus?.description?.trim() !== oldMatchedDCStatus?.description?.trim())) {
            hasChanged = true;
            return;
        }
    })
    /*
    added this condition to fix 'No changes made' on demo form when demo status is been changed
  */
    if (hasChanged) return hasChanged;
    // Role Templates
    let newRTIds = []
    let newRTs = demoBasicDetails?.roleTemplates?.filter(rT => ((rT.adminAccess && rT.adminAccess !== 0) || (rT.userAccess && rT.userAccess !== 0)));
    newRTs.forEach(item => newRTIds?.push(item?.id))
    const oldRTs = oldDemoBasicDetails?.roleTemplates?.filter(rT => ((rT.adminAccess && rT.adminAccess !== 0) || (rT.userAccess && rT.userAccess !== 0)));
    let oldRTsFilter = isSuperAdminUser ? oldRTs : oldRTs?.filter(item => newRTIds?.includes(item?.id));
    hasChanged = JSON.stringify(newRTs) !== JSON.stringify(oldRTsFilter);
    if (hasChanged) return hasChanged;
    // Additional information questions
    const assignedAdditionalInfoQuestions = demo?.assignedAdditionalInfoQuestions;
    const oldAssignedAdditionalInfoQuestions = oldDemo?.assignedAdditionalInfoQuestions;
    hasChanged = checkIfQuestionsHasChanged(assignedAdditionalInfoQuestions, oldAssignedAdditionalInfoQuestions);
    if (hasChanged) return hasChanged;
    // Pre-registration Questions
    const assignedPreRegisterQuestions = demo?.assignedPreRegisterQuestions;
    const oldAssignedPreRegisterQuestions = oldDemo?.assignedPreRegisterQuestions;
    hasChanged = checkIfQuestionsHasChanged(assignedPreRegisterQuestions, oldAssignedPreRegisterQuestions);
    if (hasChanged) return hasChanged;

    return hasChanged;
}

const checkPrimeChanges = (oldPrimes, currentPrimes) => {
    const newDataIds = []
    const oldDataIds = []
    let hasChanged = false;
    if (currentPrimes?.length > 0) currentPrimes.forEach(item => newDataIds.push(item?.regionalPrime?.id));
    if (oldPrimes?.length > 0) oldPrimes.forEach(item => oldDataIds.push(item?.regionalPrime?.id));

    if (newDataIds?.length !== oldDataIds?.length) return true;

    const data = newDataIds?.filter(item => !oldDataIds?.includes(item))
    if (data?.length !== 0) return true;
    return hasChanged
}

const checkIfQuestionsHasChanged = (newQuestions, oldQuestions) => {
    if (newQuestions?.length === 0 && oldQuestions?.length === 0) return false;
    if (newQuestions?.length !== oldQuestions?.length) return true;

    const hasChanged = newQuestions?.map(newQuestion => oldQuestions.some(oldQuestion => oldQuestion?.question?.id === newQuestion?.question?.id))
    if (hasChanged?.includes(false)) return true
    else return false
}

export const getCollateralObjs = (collaterals) => {
    return collaterals?.map(collateral => ({
        id: collateral.id,
        collateralType: collateral.collateralType,
        description: collateral.description?.trim() === "" ? null : (collateral.description?.trim() || collateral.description),
        title: collateral.title.trim(),
        dataCenter: collateral.dataCenter,
        isActive: collateral.isActive,
        link: collateral.link
    }));
}


// ----------------------------------------------------------------------------------------------------------------------------
// 2. NEWS

export const checkIfNewsHasChanged = (news, oldNews) => {
    let hasNewsChanged = false;
    hasNewsChanged = news?.title?.trim() !== oldNews?.title?.trim()
        || news?.link?.trim() !== oldNews?.link?.trim()
        || news?.linkText?.trim() !== oldNews?.linkText?.trim()
        || news?.hasThumbnail !== oldNews?.hasThumbnail
        || moment(news?.publishTime).toISOString() !== moment(oldNews?.publishTime).toISOString()
        || moment(news?.expiryTime).toISOString() !== moment(oldNews?.expiryTime).toISOString()
        || news?.notifyUsers !== oldNews?.notifyUsers
        || news?.isOverride !== oldNews?.isOverride
        || news?.introHtml?.trim() !== oldNews?.intro?.trim()
        || news?.bodyHtml?.trim() !== oldNews?.body?.trim()
        || news?.mediaPath?.trim() !== oldNews?.mediaPath?.trim()
        || news?.coverImagePath?.trim() !== oldNews?.coverImagePath?.trim()

    // if (hasNewsChanged) return hasNewsChanged;

    // Role Templates
    let newRTs = news?.roleTemplates?.filter(rT => ((rT?.adminAccess && rT.adminAccess !== 0) || (rT?.userAccess && rT.userAccess !== 0)));
    let oldRt = oldNews?.roleTemplates?.filter(rT => ((rT?.adminAccess && rT.adminAccess !== 0) || (rT?.userAccess && rT.userAccess !== 0)));
    let roleTemplatesChanged = JSON.stringify(newRTs) !== JSON.stringify(oldRt);
    return [hasNewsChanged, roleTemplatesChanged]
}

// ----------------------------------------------------------------------------------------------------------------------------
// 3. NOTIFICATION

// ----------------------------------------------------------------------------------------------------------------------------
// 4. TIPS


// ----------------------------------------------------------------------------------------------------------------------------
// 4. STANDARD EMAIL TEMPLATE
export const checkIfStandardEmailTemplateHasChanged = (template, oldTemplate) => {
    let hasTemplateChanged = false;
    hasTemplateChanged = template?.title?.trim() !== oldTemplate?.title?.trim()
        || template?.body?.trim() !== oldTemplate?.body?.trim()
        || template?.description?.trim() !== oldTemplate?.description?.trim()
        || template?.isAttachment !== oldTemplate?.isAttachment

    if (hasTemplateChanged) return hasTemplateChanged;
    return hasTemplateChanged
}


export const checkIfRoleTempleteTagsChanged = (oldRT, newRT) => {
    let hasChanged = false;
    const newDataIds = []
    const oldDataIds = []

    hasChanged = oldRT?.length !== newRT?.length;
    if (hasChanged) return hasChanged

    if (newRT?.length > 0) newRT.forEach(item => {
        const parsedEl = JSON.parse(item);
        newDataIds.push(parsedEl?.id)
    });
    if (oldRT?.length > 0) oldRT.forEach(item => oldDataIds.push(item?.roleTemplate?.id));
    const data = newDataIds?.filter(item => !oldDataIds?.includes(item))
    hasChanged = data?.length === 0 ? false : true
    return hasChanged
}

export const checkIfFilterSortIsEmpty = (filterSort, keysToSkip = []) => {
    if (filterSort && typeof filterSort?.filter === 'object') {
        const filter = filterSort?.filter;
        if (filter && Object.keys(filter).length > 0) {
            for (const key in filter) {
                if (!keysToSkip?.includes(key) && filter[key] !== undefined && filter[key] !== null && filter[key] !== '') {
                    return false;
                }
            }
        }
        else return true;
    }
    return true;
}