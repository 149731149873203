import {
    FETCH_SUPPORT_GUIDES,
    FETCH_ALL_SUPPORT_GUIDES,
    TOGGLE_IS_FETCHING_SUPPORT_GUIDES,
    REORDER_SUPPORT_GUIDES,
    TOGGLE_IS_SUPPORT_GUIDES_REORDERED,
    DELETE_SUPPORT_GUIDE,
    CLEAR_SUPPORT,
    UPDATE_SUPPORT_ROLE_TEMPLATE,
    FETCH_SUPPORT_GUIDE,
    SAVE_SUPPORT_ROLE_TEMPLATES,
    CANCEL_SUPPORT_ROLE_TEMPLATES,
    UPDATE_CURRENT_GUIDE,
    LAST_SUPPORT_FILTER,
    CLEAR_SUPPORT_FORM,
    FETCH_ALL_USER_SUPPORT_GUIDES,
    TOGGLE_IS_FETCHING_USER_SUPPORT,
    CLEAR_USER_SUPPORT_GUIDES
} from '../actions/types';
import CONFIG from '../config';

const supportState = {
    isFetching: false,
    isReordered: false,
    allRTAdminAccess: 0,
    selectedGuide: CONFIG.guideType.gettingStarted.value
};

export const supportReducer = (state = supportState, action) => {
    switch (action.type) {
        case FETCH_SUPPORT_GUIDES:
            return { ...state, userSupportGuides: action.payload };
        case FETCH_ALL_SUPPORT_GUIDES:
            return { ...state, adminSupportGuides: action.payload }
        case UPDATE_CURRENT_GUIDE:
            return { ...state, selectedGuide: action.payload }
        case REORDER_SUPPORT_GUIDES:
            return { ...state, adminSupportGuides: action.payload };
        case TOGGLE_IS_FETCHING_SUPPORT_GUIDES:
            return { ...state, isFetching: action.payload };
        case TOGGLE_IS_SUPPORT_GUIDES_REORDERED:
            return { ...state, isReordered: action.payload };
        case LAST_SUPPORT_FILTER:
            return { ...state, currentFilter: action.payload[0], guideType: action.payload[1], isEdit: true }
        case DELETE_SUPPORT_GUIDE:
            return { ...state, adminSupportGuides: state.adminSupportGuides.filter(guide => guide.id !== action.payload) };
        case CLEAR_SUPPORT:
            return supportState;
        default:
            return state;
    };
}

const supportFormState = {
    currentGuide: null
}

export const supportFormReducer = (state = supportFormState, action) => {
    switch (action.type) {
        case FETCH_SUPPORT_GUIDE:
            return {
                ...state,
                currentGuide: action.payload,
                assignedRoleTemplates: action.payload.roleTemplates,
                supportObj: action.payload
            };
        case UPDATE_SUPPORT_ROLE_TEMPLATE:
            return {
                ...state,
                currentGuide: state.currentGuide
                    ? {
                        ...state.currentGuide,
                        roleTemplates: action.payload.updatedRoleTemplates
                    }
                    : { roleTemplates: action.payload.updatedRoleTemplates }
                ,
                allRTAdminAccess: !action.payload.selectedRoleTemplate && action.payload.access
                    ? action.payload.accessType
                    : CONFIG.roleTemplateAccess.none
            }
        case SAVE_SUPPORT_ROLE_TEMPLATES:
            return { ...state, assignedRoleTemplates: state.currentGuide?.roleTemplates };
        case CANCEL_SUPPORT_ROLE_TEMPLATES:
            return {
                ...state,
                allRTAdminAccess: 0,
                currentGuide: state.currentGuide
                    ? { ...state.currentGuide, roleTemplates: state.assignedRoleTemplates || [] }
                    : { roleTemplates: state.assignedRoleTemplates || [] }
            };
        case CLEAR_SUPPORT_FORM:
            return supportFormState;
        default: return state;
    }
}

const userSupportState = {
    isFetching: false,
    guides: []
}

export const userSupportReducer = (state = userSupportState, action) => {
    switch (action.type) {
        case FETCH_ALL_USER_SUPPORT_GUIDES:
            return { ...state, guides: action.payload || [] };
        case TOGGLE_IS_FETCHING_USER_SUPPORT:
            return { ...state, isFetching: action.payload };
        case CLEAR_USER_SUPPORT_GUIDES: return userSupportState;
        default: return state;
    }
}