import {
    FETCH_RESOURCES,
    FETCH_ACTIVE_RESOURCES,
    TOGGLE_IS_FETCHING_RESOURCES,
    CLEAR_RESOURCES,
    FETCH_RESOURCE_DETAILS,
    CLEAR_RESOURCE_FORM,
    UPDATE_RESOURCE_ROLE_TEMPLATE,
    SAVE_RESOURCES_ROLE_TEMPLATES,
    CANCEL_RESOURCES_ROLE_TEMPLATES,
    LAST_RESOURCES_FILTER,
    ADD_RESOURCE_DEMO,
    DELETE_RESOURCE_DEMO,
    REFRESH_RESOURCES,
    TOGGLE_HAS_RESOURCES_UPDATE,
    UPDATE_RESOURCE_FORM_TAB_DATA,
    RESET_CREATE_RESOURCE,
    CLEAR_RESOURCE_DATA,
    TOGGLE_IS_FETCHING_RESOURCES_RT,
    FETCH_RESOURCES_RT,
    CLEAR_RESOURCES_RT_TABLE
} from '../actions/types';
import CONFIG from '../config';


const resourcesState = {
    currentPage: 1,
    isFetching: false,
    editingKey: "",
    currentPageNumber: undefined,
    currentResourceId: undefined,
    data: []
};

export const resourcesReducer = (state = resourcesState, action) => {
    switch (action.type) {
        case FETCH_RESOURCES:
            return { ...state, data: action.payload || [], isEdit: false };
        case CLEAR_RESOURCE_DATA:
            return { ...state, data: [] };
        case LAST_RESOURCES_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true, currentPageNumber: action.payload[2] }
        case TOGGLE_IS_FETCHING_RESOURCES:
            return { ...state, isFetching: action.payload }
        case CLEAR_RESOURCES:
            return resourcesState
        case REFRESH_RESOURCES:
            return { ...state, data: [], count: undefined };
        case TOGGLE_HAS_RESOURCES_UPDATE:
            return { ...state, data: [], count: undefined };
        default:
            return state;
    }
};

const resourceFormState = {
    isFetching: false,
    allRoleTemplatesUserAccess: undefined,
    allRoleTemplatesAdminAccess: undefined,
    tabData: [],
    data: [],
    demoResources: []
};

export const resourceFormReducer = (state = resourceFormState, action) => {
    switch (action.type) {
        case FETCH_RESOURCE_DETAILS:
            return { ...state, data: state.data.filter(obj => ((parseInt(obj.id) !== parseInt(action.payload?.id)) || (parseInt(obj.id) === parseInt(action.payload?.id) && obj.isClone !== action.payload?.isClone))).concat(action.payload) };
        case UPDATE_RESOURCE_FORM_TAB_DATA:
            return { ...state, tabData: state.tabData.filter(obj => ((parseInt(obj.id) !== parseInt(action.payload?.id)) || (parseInt(obj.id) === parseInt(action.payload?.id) && obj.isClone !== action.payload?.isClone))).concat(action.payload) }
        case TOGGLE_IS_FETCHING_RESOURCES:
            return { ...state, isFetching: action.payload };
        case UPDATE_RESOURCE_ROLE_TEMPLATE:
            const updatedData = state.tabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.resourceId)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el,
                    data: {
                        ...el.data,
                        roleTemplates: action.payload.updatedRoleTemplates,
                        [action.payload.accessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                            ? action.payload.accessType
                            : CONFIG.roleTemplateAccess.none
                    }
                }
                : el)
            return {
                ...state, tabData: updatedData,
            }
        case CLEAR_RESOURCE_FORM:
            return resourceFormState;
        case ADD_RESOURCE_DEMO:
            return { ...state, tabData: state.tabData?.filter(obj => ((obj.id !== action.payload?.id) || (obj.id === action.payload?.id && obj.isClone !== action.payload?.isClone))).concat(action.payload) }
        case DELETE_RESOURCE_DEMO:
            return { ...state, tabData: state.tabData?.filter(obj => ((obj.id !== action.payload?.id) || (obj.id === action.payload?.id && obj.isClone !== action.payload?.isClone))).concat(action.payload) }
        case SAVE_RESOURCES_ROLE_TEMPLATES:
            const updatedRTSaveData = state.tabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.id)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el, data: {
                        ...el.data,
                        assignedRoleTemplates: el.data.roleTemplates
                    }
                } : el)
            return { ...state, tabData: updatedRTSaveData };

        case CANCEL_RESOURCES_ROLE_TEMPLATES:
            const updatedRTCancelData = state.tabData?.map(el => ((parseInt(el.id) === parseInt(action.payload.id)) && (el.isClone === action.payload.isClone))
                ? {
                    ...el, data: {
                        ...el.data,
                        roleTemplates: el.data.assignedRoleTemplates,
                        allRoleTemplatesAdminAccess: undefined,
                        allRoleTemplatesUserAccess: undefined
                    }
                } : el)
            return { ...state, tabData: updatedRTCancelData };
        case RESET_CREATE_RESOURCE:
            const updatedResourceData = state.data?.filter(el => ((parseInt(el.id) !== parseInt(action.payload.id)) || ((parseInt(el.id) === parseInt(action.payload.id)) && (el?.isClone !== action.payload.isClone))));
            const updatedResourceTabData = state.tabData?.filter(el => ((parseInt(el.id) !== parseInt(action.payload.id)) || ((parseInt(el.id) === parseInt(action.payload.id)) && (el?.isClone !== action.payload.isClone))));
            return { ...state, data: updatedResourceData, tabData: updatedResourceTabData }
        default: return state;
    };
};

export const allResourcesReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_ACTIVE_RESOURCES:
            return action.payload;
        default:
            return state;
    };
}

const rtResourceState = {
    data: [],
    isFetching: false,
}

export const rtResourceReducer = (state = rtResourceState, action) => {
    switch (action.type) {
        case FETCH_RESOURCES_RT:
            return { ...state, data: action.payload?.dataSet || [], count: action.payload?.count || 0 };
        case TOGGLE_IS_FETCHING_RESOURCES_RT:
            return { ...state, isFetching: action.payload }
        case CLEAR_RESOURCES_RT_TABLE:
            return rtResourceState;
        default: return state;
    }
}
