import React, { useEffect, useState } from 'react';
import {
    toggleIsFetchingCapabilityRequestTable,
    fetchCapabilityRequestById,
    closeCurrentOpenTab,
    putCapabilityRequest,
} from "../../actions";
import HTML from '../common/HTML'
import { useDispatch, useSelector } from "react-redux";
import { Row, Button, Descriptions, Checkbox, Radio, Icon, Spin, Form, Typography, Col, Tag } from 'antd';
import CONFIG from '../../config';
import moment from 'moment-timezone';
import CapabilityDetailsModal from './CapabilityDetailsModal';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Text } = Typography;

function CapabilityDetailsRef(props) {

    //Checks to determine if the user was re-directed to this page from an external link.
    const redirectToCapabilityRequest = localStorage.getItem('redirectToCapabilityRequest')
    const capabilityRequestId = localStorage.getItem('capabilityRequestId')

    const dispatch = useDispatch();
    const { capabilityId } = props.match.params;
    const requestDetails = useSelector(({ capabilityRequestDetails }) => capabilityRequestDetails?.requestDetails || []);
    const loading = useSelector(({ capabilityRequestDetails }) => capabilityRequestDetails?.loading);
    const comments = useSelector(({ capabilityCommentDetails }) => capabilityCommentDetails?.html)
    const timeZone = useSelector(({ user }) => user.profile?.timezone)
    const userAnswers = requestDetails?.capabilityAnswers || [];
    const isPending = requestDetails?.status === CONFIG.myRequestsStatus.Pending;
    const isExpired = requestDetails?.isExpired;
    const isRevokeEnabled = requestDetails?.status === CONFIG.myRequestsStatus.Approved && !requestDetails?.isExpired;
    const [data, setData] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchRequestDetails = async () => {
            dispatch(toggleIsFetchingCapabilityRequestTable(true));
            await dispatch(fetchCapabilityRequestById(redirectToCapabilityRequest ? capabilityRequestId : capabilityId))
            if (redirectToCapabilityRequest) localStorage.removeItem('redirectToCapabilityRequest')
        }
        fetchRequestDetails();
        return function clearCookies() {
            if (capabilityRequestId)
                localStorage.removeItem('capabilityRequestId');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleCancel = () => {
        dispatch(closeCurrentOpenTab());
    }

    const openConfirm = (action) => {

        let modalTitle, content;
        modalTitle = action === CONFIG.myRequestsStatus.Approved ? CONFIG.warningMessages.requestApproveTitle : action === CONFIG.capabilityDetailsRequestStatus.Declined ? CONFIG.warningMessages.requestDeclineTitle : CONFIG.warningMessages.requestRevokeTitle;
        content =
            <>
                <div>
                    <div style={{ fontWeight: "500", color: "black", fontFamily: 'Noto Sans', }}>
                        {action === CONFIG.myRequestsStatus.Approved ?
                            CONFIG.warningMessages.requestApprove?.replace('<placeHolder>', requestDetails?.capability?.title) :
                            action === CONFIG.myRequestsStatus.Declined ? CONFIG.warningMessages.demoDecline?.replace('<placeHolder>', requestDetails?.capability?.title) :
                                CONFIG.warningMessages.requestRevoke?.replace('<placeHolder>', requestDetails?.capability?.title)}
                    </div>
                </div>
            </>
        setShowModal(true);
        setData({
            content,
            modalTitle,
            action
        })

    }

    const handleApproveOrDeclineConfirmation = async (action) => {
        let comment = comments?.trim();
        const data = {
            ...requestDetails,
            id: requestDetails?.id,
            notes: comment,
            status: action === CONFIG.capabilityDetailsRequestStatus.Revoked ? CONFIG.capabilityDetailsRequestStatus.Declined : action,
        }
        const typeOfAction = action.substring(0, action?.length - 1).toLowerCase();
        dispatch(toggleIsFetchingCapabilityRequestTable(true));
        await dispatch(
            putCapabilityRequest(
                requestDetails?.id,
                data,
                typeOfAction
            )
        );
        setShowModal(false);
        setData({});
        dispatch(toggleIsFetchingCapabilityRequestTable(false));
    }

    const renderFormButtons = (displayTitle) => {
        return (
            <Row className="right-align vertical-spacing">
                <React.Fragment>
                    {displayTitle && <span style={{ fontSize: "16px", color: "black", fontWeight: "600", float: "left", fontFamily: 'Noto Sans', }}>Capability - {requestDetails?.capability?.title}</span>}
                    <Button className="cancel-button button-width-small" onClick={handleCancel}>Cancel</Button>
                    {
                        isRevokeEnabled && <Button className="left-spacing-3 primary-action-button-filled" onClick={() => openConfirm(CONFIG.myRequestsStatus.Revoked)}>Revoke</Button>
                    }
                    {(isPending && !isExpired) && <Button.Group>
                        <Button className="left-spacing-3 approve-action-button-filled" onClick={() => openConfirm(CONFIG.myRequestsStatus.Approved)}>Approve</Button>
                        <Button className="left-spacing-3 primary-action-button-filled" onClick={() => openConfirm(CONFIG.myRequestsStatus.Declined)}>Decline</Button>
                    </Button.Group>}
                </React.Fragment>
            </Row>
        );
    }


    const renderQuestionsAnswers = (questions, index) => {
        const { question, answerText, questionOptions } = questions;
        const { answerType, title } = question;
        let options, answers;
        options = questionOptions && questionOptions?.split("//");
        answers = answerType === CONFIG.demoAnswerTypes.Checkbox && answerText?.split("//");

        return (
            <React.Fragment key={index}>
                <Form.Item style={{ pointerEvents: "none" }} label={<div className="label-request">{title}</div>}>
                    {answerType === CONFIG.demoAnswerTypes.Textbox ?
                        <Text type="secondary">{answerText || "N/A"}</Text>
                        : answerType === CONFIG.demoAnswerTypes.RadioButton ?
                            <Radio.Group value={answerText} readOnly={true}>
                                {options?.map(opt =>
                                    (<Radio key={opt} label={opt} value={opt}>{opt}</Radio>))}
                            </Radio.Group>
                            : <Checkbox.Group readOnly={true} value={answers} >{options?.map(opt =>
                                (<Checkbox key={opt} label={opt} value={opt}>{opt}</Checkbox>))}
                            </Checkbox.Group>
                    }
                </Form.Item>
            </React.Fragment>
        )
    }

    const customPanelStyle = {
        background: '#f9f9f9',
        marginBottom: 24,
    };

    return (
        <div className="content-container vertical-spacing" >
            <Spin indicator={antIcon} spinning={loading}>
                {renderFormButtons(true)}
                <Row gutter={48}>
                    <Col xl={10} lg={12} md={24} sm={24} xs={24}>
                        <div style={{ marginLeft: "35px" }}>
                            <Descriptions className="demo-approvers-details" >
                                <Descriptions.Item span={4} label="Demo">{requestDetails?.demo?.title || "-"}</Descriptions.Item>
                                <Descriptions.Item span={4} label="Requested By">{requestDetails?.requestedBy?.fullName || "-"}</Descriptions.Item>
                                <Descriptions.Item span={4} label="Email"> {requestDetails?.requestedBy?.email || "-"}
                                </Descriptions.Item>
                                <Descriptions.Item span={4} label="Status"><Tag
                                    color={CONFIG.capabilityRequestColors[requestDetails?.status]}>
                                    {requestDetails?.status}
                                </Tag></Descriptions.Item>
                                <Descriptions.Item span={4} label="Assigned to">{requestDetails?.assignedUser?.fullName || "-"}</Descriptions.Item>
                                <Descriptions.Item span={4} label="Admin Email">{requestDetails?.assignedUser?.email || "-"}</Descriptions.Item>
                                {requestDetails?.status !== "Pending" && <>
                                    < Descriptions.Item span={4} label="Resovled By">{requestDetails?.resolvedBy?.fullName || "-"}</Descriptions.Item>
                                    <Descriptions.Item span={4} label={`${requestDetails?.status} Time`}>{requestDetails?.resolvedTime ? moment.tz(requestDetails?.resolvedTime, timeZone).format(CONFIG.dateFormats.userDateTime) : "-"}</Descriptions.Item>
                                    <Descriptions.Item span={4} label="Notes"><HTML htmlContent={requestDetails?.notes || ""} /></Descriptions.Item>
                                </>
                                }
                            </Descriptions>
                        </div>
                    </Col>
                    <Col xl={14} lg={12} md={24} sm={24} xs={24}>
                        <div>
                            <div style={customPanelStyle}>
                                <div style={{ fontSize: "15px", color: "black", fontWeight: "600", fontFamily: 'Noto Sans', }}><Icon style={{ fontSize: '15px', color: "#da291c" }} width={1} type="form" />&nbsp; User Answers <span style={{ color: "red", float: "right", fontWeight: "400", fontSize: "12px", fontFamily: 'Noto Sans', }}> * Read only</span> </div>
                                <div style={{ marginLeft: "30px", marginTop: "5px" }}>
                                    {userAnswers && userAnswers?.map((eachAnswer, index) => { return renderQuestionsAnswers(eachAnswer, index) })}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    showModal && <CapabilityDetailsModal
                        showModal={showModal}
                        setShowModal={setShowModal}
                        data={data}
                        form={props.form}
                        handleApproveOrDeclineConfirmation={handleApproveOrDeclineConfirmation}
                    />
                }
            </Spin>
        </div >
    )
}

const CapabilityRequestDetails = Form.create()(CapabilityDetailsRef);

export default CapabilityRequestDetails;