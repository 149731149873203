import CONFIG from '../config';
import moment from 'moment-timezone';
export const shadeColor = (color, percent) => {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export const calcBufferTimeToAdd = (bufferTime) => {
    // Display buffer time only if it's a multiple of 30 (scheduler interval)
    if (bufferTime >= 30) {
        if (bufferTime % 30 === 0) return bufferTime;
        else {
            let remainder = bufferTime % 30;
            return bufferTime - remainder;
        }
    }
    return 0;
}

// const createEvent = (schedule, timeZone, userId, resourceId) => {
//     const bufferStart = calcBufferTimeToAdd(schedule.startTimeBuffer);
//     const bufferEnd = calcBufferTimeToAdd(schedule.endTimeBuffer);
//     return {
//         id: schedule.id,
//         start: moment.tz(schedule.startTime, timeZone).subtract(bufferStart, 'm').format(CONFIG.dateFormats.scheduler),
//         end: moment.tz(schedule.endTime, timeZone).add(bufferEnd, 'm').format(CONFIG.dateFormats.scheduler),
//         scheduleStartTime: moment.tz(schedule.startTime, timeZone).format(CONFIG.dateFormats.scheduler),
//         scheduleEndTime: moment.tz(schedule.endTime, timeZone).format(CONFIG.dateFormats.scheduler),
//         bufferStartTime: !schedule.isMaintenance ? schedule.startTimeBuffer : undefined,
//         bufferEndTime: !schedule.isMaintenance ? schedule.endTimeBuffer : undefined,
//         resourceId: resourceId ? resourceId : schedule.resource.id,
//         bgColor: !schedule.isMaintenance ? (schedule.createdBy.id === userId ? CONFIG.colors.userDemo : CONFIG.colors.demo) : CONFIG.colors.maintenance,
//         title: !schedule.isMaintenance ? schedule.createdBy.userName : CONFIG.demoScheduler.eventText.maintenance,
//         movable: schedule.isMaintenance ? false : true,
//         resizable: false,
//         user: schedule.createdBy,
//         purpose: !schedule.isMaintenance ? schedule.demoPurpose.purpose : undefined,
//         demo: schedule.demo,
//         createdBy: schedule.createdBy,
//         demoPurpose: schedule.demoPurpose,
//         purposeAttributesSchedule: schedule.purposeAttributesSchedule,
//         startTime: moment.tz(schedule.startTime, timeZone),
//         endTime: moment.tz(schedule.endTime, timeZone),
//         endTimeBuffer: schedule.endTimeBuffer,
//         startTimeBuffer: schedule.startTimeBuffer,
//         resource: schedule.resource,
//         adminCreatedBy: schedule.adminCreatedBy,
//     }
// }

export const createCalendarEvent = (schedule, timeZone, userId, resourceId, isAdmin) => {

    let participant;
    if (schedule?.participants !== null) {
        const participants = schedule?.participants;
        const { eapUsers } = participants || [];
        const existingParticipant = eapUsers?.find(item => item?.id === userId);
        participant = existingParticipant ? existingParticipant : schedule?.createdBy;
    }

    return {
        // id: schedule.id,
        scheduleId: schedule.id,
        title: !schedule.isMaintenance ? schedule?.participants !== null ? participant?.fullName : schedule.createdBy.fullName : `${CONFIG.demoScheduler.eventText.maintenance} ( ${schedule?.title} )`,
        start: moment(moment(schedule.startTime).subtract(schedule.startTimeBuffer, 'm').format("YYYY-MM-DD HH:mm:ss")).toISOString(),
        end: moment(moment(schedule.endTime).add(schedule.endTimeBuffer, 'm').format("YYYY-MM-DD HH:mm:ss")).toISOString(),
        demoType: !schedule.isMaintenance ? schedule.demo.demoType : "-",
        email: schedule?.participants !== null ? participant?.email : schedule.createdBy.email,
        handle: schedule?.participants !== null ? participant?.userName : schedule.createdBy.userName,
        color: !schedule.isMaintenance ? ((schedule?.participants !== null ? participant?.id === userId : schedule.createdBy.id === userId) ? CONFIG.colors.userDemo : CONFIG.colors.demo) : CONFIG.colors.maintenance,
        isMySchedule: !schedule.isMaintenance ? (schedule?.participants !== null ? participant?.id === userId : schedule.createdBy.id === userId) : false,
        // resource: schedule.resource,
        editable: schedule.isMaintenance ? false : ((schedule?.participants !== null ? participant?.id === userId : schedule.createdBy.id === userId) || isAdmin),
        resource: schedule?.isMaintenance ? resourceId : schedule.resource?.id,
        allDay: false,
        isMaintenance: schedule?.isMaintenance,
        schedule,
        demoTitle: !schedule.isMaintenance ? schedule.demo.title : '-',
        demo: schedule.demo,
        user: schedule?.participants !== null ? participant : schedule.createdBy,
        purpose: !schedule.isMaintenance ? schedule.demoPurpose.purpose : undefined,
        createdBy: schedule?.participants !== null ? participant : schedule.createdBy,
        originalCreatedBy: schedule.createdBy,
        demoPurpose: schedule.demoPurpose,
        purposeAttributesSchedule: schedule.purposeAttributesSchedule,
        startTime: moment.tz(schedule.startTime, timeZone),
        endTime: moment.tz(schedule.endTime, timeZone),
        endTimeBuffer: schedule.endTimeBuffer,
        startTimeBuffer: schedule.startTimeBuffer,
        selectedResource: schedule.resource,
        adminCreatedBy: schedule.adminCreatedBy,
        isAvailable: true,
        userAnswers: schedule.userAnswers,
        participants: schedule?.participants
    }
}

export const getEventsForSchedules = (schedules, resources, timeZone, userId, isAdmin) => {
    let scheduleEvents = [];
    let maintenanceEvents = [];
    schedules.forEach(schedule => {
        let event = undefined;
        if (schedule?.isMaintenance) {
            for (let index = 0; index < schedule?.maintenanceRelations?.length; index++) {
                const eachItem = schedule?.maintenanceRelations[index]
                if (eachItem?.resource === null && eachItem.dataCenter !== null) {
                    const eachDCResource = resources?.filter((item) => item?.dataCenter?.id === eachItem?.dataCenter?.id)
                    event = eachDCResource?.map(resource => {
                        return createCalendarEvent(schedule, timeZone, userId, resource?.id);
                    });
                    Array.isArray(event) ? maintenanceEvents.push(...event) : maintenanceEvents.push(event);
                }
                else if (eachItem?.dataCenter === null && eachItem?.resource === null) {
                    event = resources?.map(resource => {
                        return createCalendarEvent(schedule, timeZone, userId, resource.id);
                    });
                    Array.isArray(event) ? maintenanceEvents.push(...event) : maintenanceEvents.push(event);
                }
                else {
                    event = createCalendarEvent(schedule, timeZone, userId, eachItem?.resource?.id);
                    Array.isArray(event) ? maintenanceEvents.push(...event) : maintenanceEvents.push(event);
                }
            }
        }

        else {
            event = createCalendarEvent(schedule, timeZone, userId, null, isAdmin);
            Array.isArray(event) ? scheduleEvents.push(...event) : scheduleEvents.push(event);
        }
    });
    const sortedEvents = [...scheduleEvents, ...maintenanceEvents]?.sort((a, b) => { return new Date(a.scheduleStartTime) - new Date(b.scheduleStartTime) });
    return sortedEvents;
}

export const getSchedulerView = (value) => {
    let calView;
    switch (value) {
        case 'month':
            calView = {
                timeline: {
                    type: 'month',
                    startDay: 0,
                    endDay: 6,
                    eventList: true,
                    timeCellStep: 720,
                    timeLabelStep: 1440,
                }
            }
            break;
        case 'week':
            calView = {
                timeline: {
                    type: 'week',
                    startDay: 0,
                    endDay: 6,
                    eventList: true,
                }
            }
            break;
        case 'day':
            calView = {
                timeline: {
                    type: 'day',
                    timeCellStep: 30,
                    timeLabelStep: 60,
                },
            }
            break;
        case 'workweek':
            calView = {
                timeline: {
                    type: 'week',
                    eventList: true,
                    startDay: 1,
                    endDay: 5
                }
            }
            break;
        default: break;
    }
    return calView;
}

export const computeTime = (type, value, currentDate, selectedTimezone) => {
    let start, end;
    // date?.tz(timeZone, true).startOf('day').valueOf();

    if (type === "month" || type === "agenda") {
        start = moment(value ? value : currentDate).tz(selectedTimezone, true)?.startOf("month").toISOString();
        end = moment(value ? value : currentDate).tz(selectedTimezone, true)?.endOf("month").toISOString();
    }

    if (type === "workweek") {
        start = moment(value ? value : currentDate).tz(selectedTimezone, true)?.startOf("week").add(1, "d").toISOString();
        end = moment(value ? value : currentDate).tz(selectedTimezone, true)?.endOf("week").subtract(1, "d").toISOString();
    }

    if (type === 'week') {
        start = moment(value ? value : currentDate).tz(selectedTimezone, true)?.startOf("week").toISOString();
        end = moment(value ? value : currentDate).tz(selectedTimezone, true)?.endOf("week").toISOString();
    }

    if (type === "day") {
        start = moment(value ? value : currentDate).tz(selectedTimezone, true).startOf('day').toISOString();
        end = moment(value ? value : currentDate).tz(selectedTimezone, true).endOf('day').toISOString();
    }

    return [start, end]
}

export const getMaintenanceView = (value) => {
    let calView;
    switch (value) {
        case 'month':
            calView = { calendar: { labels: 8, type: "month", popover: true, popoverClass: 'custom-event-popover' } }
            break;
        // case 'week':
        //     calView = { calendar: { type: 'week', labels: 5, popover: true, popoverClass: 'custom-event-popover' } }
        //     break;
        // case 'day':
        //     calView = { agenda: { type: 'day', labels: 5, popover: true, popoverClass: 'custom-event-popover' } }
        //     break;
        case 'agenda':
            calView = { agenda: { type: 'month', labels: 8, popover: true, popoverClass: 'custom-event-popover' } }
            break;
        default: break;
    }
    return calView;
}