import { batch } from 'react-redux';

import CONFIG from "../config"
import pushMessage from "../components/common/PushMessage"
import { aepUser } from '../apis';
import { toggleIsFetchingLocalUsersFlag } from './localUsers';

import {
    FETCH_ALL_USERS,
    TOGGLE_IS_FETCHING_USERS,
    UPDATE_USER,
    UPDATE_USERS_CURRENT_PAGE,
    UPDATE_USER_EDITING_KEY,
    CLEAR_USERS,
    FETCH_LOCAL_USERS,
    REFRESH_SSO_USERS,
    UPDATE_SSO_USER_FILTERS
} from './types';

export const fetchAllUsers = ({ pageNumber = 1, filterSort, isBackendUser, pageSize = CONFIG.lazyLoadPageSize, status }) => async dispatch => {
    const contextPath = '/userTemplateService/users';
    const response = await aepUser.get(contextPath, {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isBackendUser,
                    isActive: status
                }
            }
        }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        isBackendUser
            ? dispatch({ type: FETCH_LOCAL_USERS, payload: response.data || [] })
            : dispatch({ type: FETCH_ALL_USERS, payload: response.data || [] });
    }
    else {
        isBackendUser
            ? pushMessage(CONFIG.messageType.error, "Unable to fetch Non-SSO User")
            : pushMessage(CONFIG.messageType.error, "Unable to fetch SSO users");
    }
    dispatch(toggleIsFetchingUsersFlag(false));
    dispatch(toggleIsFetchingLocalUsersFlag(false));
    return response.data?.dataSet || []
};

export const getAllLiteUsers = ({ filterSort, isBackendUser, status }) => async dispatch => {
    const contextPath = '/userTemplateService/v1/lite/users';
    const response = await aepUser.get(contextPath, {
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isBackendUser,
                    isActive: status ? status === "true" ? true : status === "false" ? false : undefined : undefined
                }
            }
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        isBackendUser
            ? dispatch({ type: FETCH_LOCAL_USERS, payload: response.data || [] })
            : dispatch({ type: FETCH_ALL_USERS, payload: response.data || [] });
    }
    else {
        isBackendUser
            ? pushMessage(CONFIG.messageType.error, "Unable to fetch Non-SSO User")
            : pushMessage(CONFIG.messageType.error, "Unable to fetch SSO users");
    }
    dispatch(toggleIsFetchingUsersFlag(false));
    dispatch(toggleIsFetchingLocalUsersFlag(false));
    return response.data || []
}

// -- Action intended for Export functionality --
export const getAllUsers = async ({ filterSort, isBackendUser, status }) => {
    const response = await aepUser.get("/userTemplateService/users", {
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isBackendUser,
                    isActive: status ? status === "true" ? true : status === "false" ? false : undefined : undefined
                }
            }
        }
    });
    return response.data ? response.data.dataSet : [];
}

export const updateUser = (updatedUser, userId) => {
    return {
        type: UPDATE_USER,
        payload: updatedUser,
        userId
    };
};

export const updateUsersCurrentPage = ({ pageNumber = 1, filterSort }) => (dispatch) => {
    batch(() => {
        dispatch({
            type: UPDATE_USERS_CURRENT_PAGE,
            payload: pageNumber
        });
        dispatch(toggleIsFetchingUsersFlag(true));
        dispatch(fetchAllUsers({ pageNumber, filterSort, isBackendUser: false }));
        dispatch(updateUserEditingKey());
    });
}

export const toggleIsFetchingUsersFlag = flag => {
    return {
        type: TOGGLE_IS_FETCHING_USERS,
        payload: flag
    }
}

export const putUser = (updatedUser, userId, record) => async dispatch => {
    const requestData = {
        id: userId,
        userRoleTemplates: updatedUser.roleTemplates?.map(roleTemplate => { return { "roleTemplate": JSON.parse(roleTemplate) } }),
        isPeriodicEnabled: updatedUser.isPeriodicEnabled,
        isAddParticipantsEnabled: updatedUser.isAddParticipantsEnabled,
        isMultipleDays: updatedUser.isMultipleDays,
        isMultipleScheduleBooking: updatedUser.isMultipleScheduleBooking,
        crmUserStatus: updatedUser.crmUserStatus ?? record.crmUserStatus

    }
    const response = await aepUser({
        url: '/userTemplateService/users',
        method: 'put',
        data: requestData,
        params: { isOnlyTimeZoneChange: false }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'SSO User updated successfully')
        dispatch(updateUser(updatedUser, userId))
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update SSO user!');
    dispatch(updateUserEditingKey());
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
};

export const updateUserEditingKey = (key = "") => {
    return {
        type: UPDATE_USER_EDITING_KEY,
        payload: key
    };
};

export const fetchNamesBySearchString = async ({ pageNumber = 0, pageSize = CONFIG.fetchCompaniesSize, searchString, isBackendUser }) => {
    const contextPath = '/userTemplateService/userslist/search'
    const response = await aepUser.get(contextPath, {
        params: {
            pageSize,
            pageNumber,
            searchString,
            isBackendUser
        }
    })
    return response.data ? response.data.dataSet : [];
}

export const clearUsers = () => {
    return { type: CLEAR_USERS };
}

export const refreshSSOUsers = () => {
    return {
        type: REFRESH_SSO_USERS
    }
}

export const currentSSOUsersFilter = (filterSort, pageNumber, pageSize) => {
    return {
        type: UPDATE_SSO_USER_FILTERS,
        payload: { filterSort, pageNumber, pageSize }
    }
}