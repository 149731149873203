import React from "react";
import { connect, batch } from "react-redux";
import { Form, Input, Row, Col, Switch, Button, Modal, Spin, Select } from 'antd';
import {
    fetchRoleTemplates,
    selectTitle,
    fetchAdminRoleTemplates,
    cancelDemoQuestionsRoleTemplates,
    saveDemoQuestionsRoleTemplates,
    updateDemoQuestionsRoleTemplate,
    postDemoQuestion,
    toggleIsFetchingDemoQuestions,
    clearDemoQuestionsForm,
    fetchDemoQuestionById,
    currentDemoQuestionsFilter,
    fetchDemos,
    currentDemoQuestionFilter,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag,
    updateDemoQuestionsFormTabData,
    updateTabsLocalStorage,
    clearFormData
} from "../../actions";
import FormActionButtons from "../common/FormActionButtons";
import RoleTemplatesTable from "../common/RoleTemplatesTable";
import CONFIG from "../../config";
import { checkRoleTemplateChanges } from "../../utils/status";
import pushMessage from '../common/PushMessage';
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
const { Option } = Select;

class DemoQuestionForm extends React.Component {
    constructor(props) {
        super(props);
        this.isEditMode = this.props.match.params.demoQuestionId !== undefined;
        this.state = {
            visible: false,
            enableAnswerOptionField: false,
        };
    }
    getAssignedRoleTemplates = (isSuperAdminUser) => {
        const currentQuestionsData = this.getCurrentDemoQuestion();
        const { data } = currentQuestionsData || {};
        const { roleTemplates } = data || [];
        let assignedRoleTemplates = [];
        if (roleTemplates) {
            roleTemplates.forEach((obj) => {
                const userAccessCondition =
                    obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none;
                const adminAccessCondition =
                    obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none;
                if (
                    isSuperAdminUser
                        ? userAccessCondition || adminAccessCondition
                        : userAccessCondition
                ) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            });
        }
        return assignedRoleTemplates;
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    renderAssignButton = () => {
        return (
            <Button
                className="primary-action-button-bordered right-border-radius"
                onClick={this.showModal}
            >
                Assign
            </Button>
        );
    };

    handleRoleTemplatesCancel = (e) => {
        const { demoQuestionId } = this.props.match.params;
        const id = demoQuestionId !== undefined ? demoQuestionId : "-1";
        this.props.cancelDemoQuestionsRoleTemplates(id, false);
        this.setState({
            visible: false,
        });
    };

    componentDidUpdate() {
        const isSuperAdminUser = this.props.userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const currentDemoQuestionForm = this.getCurrentData(this.props.form.getFieldsValue());
        this.hasChanged = this.props.match.params?.demoQuestionId ? this.checkDemoQuestionFormChanged(currentDemoQuestionForm, isSuperAdminUser) : true;
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, true)
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false)
    }

    componentDidMount() {
        const { userRoleTemplate, previousTabs } = this.props;
        const currentPath = window.location.pathname
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const { demoQuestionId } = this.props.match.params;

        batch(() => {
            this.props.selectTitle("Demo Question Form");
            this.props.toggleIsFetchingDemoQuestions(false)
            const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;
            fetchAllRoleTemplates().then(() => {
                if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                    if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                        batch(() => {
                            // Checking if the tab is a edit form.
                            if (demoQuestionId) {
                                this.props.toggleIsFetchingDemoQuestions(true);
                                this.props.fetchDemoQuestionById(demoQuestionId)
                                    .then((questionDetails) => {
                                        (questionDetails?.answerType === CONFIG.demoQuestionAnswerTypes[1].name || questionDetails?.answerType === CONFIG.demoQuestionAnswerTypes[2].name)
                                            ? this.setState({ enableAnswerOptionField: true })
                                            : this.setState({ enableAnswerOptionField: false }
                                            )
                                    })
                            }
                            this.props.updateTabsLocalStorage(currentPath);
                            this.props.updateDemoQuestionsFormTabData({
                                questionId: this.props.match.params?.demoQuestionId !== undefined
                                    ? this.props.match.params?.demoQuestionId
                                    : "-1",
                                questionDetails: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                            })
                        })
                    } else {
                        const currentQuestions = this.getCurrentDemoQuestion();
                        const { data } = currentQuestions || [];
                        (data?.answerType === CONFIG.demoQuestionAnswerTypes[1].name || data?.answerType === CONFIG.demoQuestionAnswerTypes[2].name)
                            ? this.setState({ enableAnswerOptionField: true })
                            : this.setState({ enableAnswerOptionField: false }
                            )
                    }
                }
                else {
                    // Current exact tab is not in the openTabs list. Hence fetching from API
                    batch(async () => {
                        // Clearing the old form data - could be uncleared CREATE form / UPDATE form
                        await this.props.clearFormData(CONFIG.createComponentRoute.demoQuestions.title, (this.props.match.params.demoQuestionId || '-1'));
                        if (demoQuestionId) {
                            this.props.toggleIsFetchingDemoQuestions(true);
                            this.props.fetchDemoQuestionById(demoQuestionId)
                                .then((questionDetails) => {
                                    (questionDetails?.answerType === CONFIG.demoQuestionAnswerTypes[1].name || questionDetails?.answerType === CONFIG.demoQuestionAnswerTypes[2].name)
                                        ? this.setState({ enableAnswerOptionField: true })
                                        : this.setState({ enableAnswerOptionField: false }
                                        )
                                })
                        }
                    })
                }
            });
        });

    }

    getCurrentData = (formData) => {
        return {
            title: formData.title,
            roleTemplates: formData?.roleTemplates,
            answerType: formData.answerType,
            isActive: formData.isActive,
            isMandatory: formData.isMandatory,
            answers: formData.answers
        }
    }

    checkDemoQuestionFormChanged = (currentDemoQuestionForm, isSuperAdminUser) => {
        const currentQuestions = this.getCurrentDemoQuestion();
        const { originalData, data } = currentQuestions || [];
        const { title, isActive, roleTemplates, answerType, isMandatory, answers } = originalData || {}
        const { demoQuestionId } = this.props.match.params;
        let hasChanged = false;
        if (demoQuestionId) {
            hasChanged = currentDemoQuestionForm?.title !== title
                || currentDemoQuestionForm?.isActive !== isActive
                || currentDemoQuestionForm?.answerType !== answerType
                || currentDemoQuestionForm?.isMandatory !== isMandatory
                || checkRoleTemplateChanges(data?.roleTemplates, roleTemplates, isSuperAdminUser)

            if (originalData?.answerType !== CONFIG.demoQuestionAnswerTypes[0].name) {
                if (currentDemoQuestionForm?.answers?.length !== answers?.length) {
                    hasChanged = true
                } else {
                    const isPresent = currentDemoQuestionForm?.answers?.map(newAns => answers?.some(oldAns => oldAns?.title === newAns))
                    if (isPresent?.includes(false)) {
                        hasChanged = true;
                    }
                }
            }
        }
        if (hasChanged) return hasChanged;
        return hasChanged;
    }
    handleCreate = () => {
        const { form } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                this.props.toggleIsFetchingDemoQuestions(true)
                this.props.postDemoQuestion(values, (this.props.match.params.demoQuestionId || "-1"))
            } else {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return;
            }
        });
    };

    handleRoleTemplatesAssign = () => {
        const { demoQuestionId } = this.props.match.params;
        const id = demoQuestionId !== undefined ? demoQuestionId : "-1";
        this.props.saveDemoQuestionsRoleTemplates(id, false);
        this.setState({ visible: false });
    };

    handleAnswerTypeSelect = (answer) => {
        (answer === CONFIG.demoQuestionAnswerTypes[1].name || answer === CONFIG.demoQuestionAnswerTypes[2].name)
            ? this.setState({ enableAnswerOptionField: true })
            : this.setState({ enableAnswerOptionField: false })
    }

    componentWillUnmount() {
        // clear the corresponding form data from Store.
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.props.clearDemoQuestionsForm();

    }

    getCurrentDemoQuestion = () => {
        const { demoQuestionTabData } = this.props;
        const { demoQuestionId } = this.props.match.params;
        const id = demoQuestionId !== undefined ? demoQuestionId : "-1";

        return demoQuestionTabData?.find(item => (
            (parseInt(item?.id) === parseInt(id))));
    }

    render() {
        const { form, userRoleTemplate, allRoleTemplates } = this.props;
        const { getFieldDecorator } = form;
        const { demoQuestionId } = this.props.match.params;
        const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const currentDemoQuestion = this.getCurrentDemoQuestion();
        const { title, isActive, answerType, answers, isMandatory } = currentDemoQuestion?.data || []
        // const currentDemoQuestionForm = this.getCurrentData(form.getFieldsValue());
        const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
        // this.hasChanged = demoQuestionId ? this.checkDemoQuestionFormChanged(currentDemoQuestionForm, isSuperAdminUser) : true ;
        return (
            <div className="content-container">
                <Spin spinning={this.props.isFetching} wrapperClassName="spin-overlay">
                    <Form>
                        <Row gutter={48}>
                            <Col xl={10} lg={12} sm={20} xs={24}>
                                <Form.Item label="Question Title">
                                    {getFieldDecorator("title", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input the question",
                                            },
                                        ],
                                        initialValue: title ? title : "",
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            {isSuperAdminUser && <Col xl={10} lg={12} sm={20} xs={24}>
                                <Form.Item label="Assign Role Template">
                                    {getFieldDecorator("roleTemplates", {
                                        initialValue:
                                            assignedRoleTemplates.length !== 0
                                                ? assignedRoleTemplates
                                                : "None",
                                    })(<Input disabled addonAfter={this.renderAssignButton()} />)}
                                    {
                                        <Modal
                                            title="Role Template"
                                            visible={this.state.visible}
                                            onCancel={this.handleRoleTemplatesCancel}
                                            footer={
                                                <FormActionButtons
                                                    okText="Assign"
                                                    handleCancel={this.handleRoleTemplatesCancel}
                                                    handleSubmit={this.handleRoleTemplatesAssign}
                                                />
                                            }
                                        >
                                            <RoleTemplatesTable
                                                hideColumns={isSuperAdminUser ? ["user"] : ["user", "admin"]}
                                                dataKey="roleTemplate"
                                                data={allRoleTemplates}
                                                pagination={false}
                                                assignedData={currentDemoQuestion?.data?.roleTemplates}
                                                allUserAccess={currentDemoQuestion?.data?.allRoleTemplatesUserAccess}
                                                allAdminAccess={currentDemoQuestion?.data?.allRoleTemplatesAdminAccess}
                                                updateRoleTemplateData={this.props.updateDemoQuestionsRoleTemplate}
                                                recordId={demoQuestionId ? demoQuestionId : "-1"}
                                                isClone={false}
                                            />
                                        </Modal>
                                    }
                                </Form.Item>
                            </Col>
                            }
                            <Col xl={4} sm={4} xs={24}>
                                <Form.Item label="Status">
                                    {getFieldDecorator("isActive", {
                                        valuePropName: "checked",
                                        initialValue: isActive,
                                    })(
                                        <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row gutter={48}>
                            <Col xl={10} lg={12} sm={20} xs={24}>
                                <Form.Item label="Answer Type">
                                    {getFieldDecorator("answerType", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Select the field type",
                                            },
                                        ],
                                        initialValue: answerType
                                    })(<Select
                                        allowClear={true}
                                        showArrow={true}
                                        disabled={demoQuestionId ? true : false}
                                        onSelect={this.handleAnswerTypeSelect}
                                        placeholder="Select the field type"
                                    >
                                        {CONFIG.demoQuestionAnswerTypes?.map((item, index) => (
                                            <Option key={index} value={item.value} >{item.name}</Option>
                                        ))}
                                    </Select>)}
                                </Form.Item>
                            </Col>
                            {this.state.enableAnswerOptionField && <Col xl={10} lg={12} sm={20} xs={24}>
                                <Form.Item label="Answer Options">
                                    {getFieldDecorator("answers", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Select the field type",
                                            },
                                        ],
                                        initialValue: answers?.[0]?.title ? answers?.map(answer => answer?.title) : answers?.map(answer => answer)

                                    })(< Select
                                        allowClear={true}
                                        showArrow={true}
                                        placeholder="Select the field type"
                                        mode="tags">
                                    </Select>)}
                                </Form.Item>
                            </Col>}
                            <Col xl={4} sm={4} xs={24}>
                                <Form.Item label="Mandatory">
                                    {getFieldDecorator("isMandatory", {
                                        valuePropName: "checked",
                                        initialValue: isMandatory,
                                    })(
                                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="right-align">
                            <FormActionButtons
                                handleSubmit={this.handleCreate}
                                cancelText={"Discard & Close"}
                                isDisabled={!this.hasChanged}
                                handleCancel={() => this.props.closeCurrentOpenTab()}
                                okText={this.isEditMode ? "Update" : "Create"}
                            />
                        </Row>
                    </Form>
                </Spin>
            </div>
        );
    }
}

const DemoQuestionFormRef = Form.create()(DemoQuestionForm);

const mapStateToProps = ({ roleTemplates, user, demoQuestionsForm, demos, tabsLayout }) => {
    return {
        ...demoQuestionsForm,
        allRoleTemplates: roleTemplates.all,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        questionDetails: demoQuestionsForm,
        allDemos: demos.allDemos,
        demoQuestionObj: demoQuestionsForm?.demoQuestionObj,
        demoQuestionTabData: demoQuestionsForm?.tabData,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        previousTabs: tabsLayout.previousTabs,
    };
};

export default connect(mapStateToProps,
    {
        selectTitle,
        fetchAdminRoleTemplates,
        fetchRoleTemplates,
        cancelDemoQuestionsRoleTemplates,
        saveDemoQuestionsRoleTemplates,
        updateDemoQuestionsRoleTemplate,
        postDemoQuestion,
        clearDemoQuestionsForm,
        toggleIsFetchingDemoQuestions,
        fetchDemoQuestionById,
        currentDemoQuestionsFilter,
        fetchDemos,
        currentDemoQuestionFilter,
        closeCurrentOpenTab,
        toggleHasUnsavedChangesFlag,
        updateDemoQuestionsFormTabData,
        updateTabsLocalStorage,
        clearFormData
    })
    (DemoQuestionFormRef);
