import React, { Component } from 'react'
import {
    toggleBackButton,
    getProductAttributes,
    toggleIsFetchingDailyReports,
    postDailyReport,
    clearReportForm,
    getDailyReportById,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag

} from "../../actions";
import { connect, batch } from "react-redux";
import { Spin, Form, Input, Row, Col, Select, Empty, Tooltip } from "antd";
import { Icon } from "@avaya/neo-react";
import CONFIG from '../../config';
import moment from 'moment-timezone';
import pushMessage from '../../components/common/PushMessage';
import FormActionButtons from '../common/FormActionButtons';
import { computeTime } from '../../utils/scheduler';
const { Option } = Select;

class DailyReportForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            product: [],
            isEditMode: false,
            usableObject: [],
            isCreateDisabled: false,
        }
    }

    componentDidMount() {
        this.props.toggleBackButton(false);
        const { reportId } = this.props.match.params;
        batch(async () => {
            if (reportId) {
                this.props.toggleIsFetchingDailyReports(true)
                await this.props.getDailyReportById(reportId)
                if (this.props.dailyReport?.product?.id) {
                    await this.props.getProductAttributes(this.props.dailyReport?.product?.id)
                }
                this.props.toggleIsFetchingDailyReports(false)
                this.setState({
                    product: JSON.stringify(this.props.productAttributes[0]),
                    isEditMode: true
                })
                this.createUsableObject()
            } else {
                await this.props.getProductAttributes(0)
            }
            this.props.toggleIsFetchingDailyReports(false)
        })
    }

    componentWillUnmount() {
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.clearForm();
    }

    clearForm = () => {
        this.props.clearReportForm();
        this.setState({
            product: [],
            isEditMode: false,
            usableObject: []
        })
    }

    createUsableObject = () => {
        const { dailyReport } = this.props;
        let usableObject = []
        let eachOject = {}
        for (let i = 0; i < dailyReport?.testingDataAttributes?.length; i++) {
            let eachTestingDataAttribute = dailyReport.testingDataAttributes[i]
            eachOject = {
                attributeId: eachTestingDataAttribute.attribute.id,
                attributeName: eachTestingDataAttribute.attribute.attributeName,
                attributeStatus: eachTestingDataAttribute.attributeStatus,
                productTestingIssueId: eachTestingDataAttribute?.id,
                issueDescription: eachTestingDataAttribute?.issueDescription,
                link: eachTestingDataAttribute?.link, 
                recentOutages: eachTestingDataAttribute?.recentOutages
            }
           
            usableObject.push(eachOject)
            eachOject = {}
        }

        this.setState({ usableObject: usableObject })
    }

    findInitialValue = (attributename, key) => {
        const { usableObject } = this.state;
        let returnValue
        for (let i = 0; i < usableObject?.length; i++) {
            let eachUsableObject = usableObject[i]
            if (eachUsableObject.attributeName === attributename)
                returnValue = eachUsableObject[key]
        }
        return returnValue;
    }

    renderFormButtons = () => {

        return (
            <div>
                <Row className="right-align vertical-spacing" >
                    <FormActionButtons
                        isDisabled={this.state.isCreateDisabled && !this.hasChanged}
                        handleSubmit={this.handleCreate}
                        cancelText={"Discard & Close"}
                        handleCancel={() => this.props.closeCurrentOpenTab()}
                        okText="Save" />
                </Row>
            </div>
        );

    }

    renderProductOptions = () => {
        const { productAttributes } = this.props;
        if (productAttributes)
            return productAttributes.map((product, index) => <Option key={product?.id} value={JSON.stringify(product)} title={product.title}>{product.title}</Option>);
        return [];
    }

    handleCancel = () => { this.props.closeCurrentOpenTab(); this.clearForm(); }

    handleProductSelect = (value) => {
        const data = JSON.parse(value);
        this.setState({
            product: value,
            isCreateDisabled: data?.productAttributes?.length === 0 ? true : false
        })
    };


    handleCreate = async () => {
        const { form } = this.props;
        const { dailyReport } = this.props;
        const { getFieldValue } = this.props.form;
        const { isEditMode } = this.state;
        const { reportId } = this.props.match.params;
        const id = reportId ? reportId : 0;
        form.validateFields(async (err, values) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return
            }
            else {
                const productObj = this.state.product.length > 0 ? JSON.parse(this.state.product) : [];
                let valueObject = {}
                let productTestingIssues = []
                let product = {
                    id: productObj?.id,
                    title: productObj?.title,
                    productAttributes: productObj?.productAttributes
                }

                if (values?.attributeName?.length > 0) {
                    let notTestedValues = values?.attributeName?.every(attribute => attribute === CONFIG.dailyReportAttrStatus.status[1])
                    if (notTestedValues) {
                        pushMessage(CONFIG.messageType.warning,
                            id === 0
                                ? "You cannot create reports on not tested products."
                                : "You cannot update reports on not tested products. You can delete existing reports and create a new"
                        );
                        return;
                    }
                    let eachObject = {}
                    const keys = (getFieldValue('keys') || [])
                    for (let i = 0; i < values?.attributeName?.length; i++) {
                        if (keys.includes(i)) {
                                eachObject = {
                                    attribute: { id: values?.id[i] },
                                    attributeStatus: values?.attributeName[i],
                                    issueDescription:  (values?.attributeName[i] === (CONFIG.dailyReportAttrStatus.status[0]) || values?.attributeName[i] === ( CONFIG.dailyReportAttrStatus.status[1]))  ? values?.issueDescription[i] : undefined,
                                    link: values?.attributeName[i] === CONFIG.dailyReportAttrStatus.status[0] ? values?.link[i] : undefined
                                }
                                if (isEditMode && this.findInitialValue(productObj?.productAttributes[i]?.attribute.attributeName, "productTestingIssueId")) {
                                    eachObject["id"] = this.findInitialValue(productObj?.productAttributes[i]?.attribute.attributeName, "productTestingIssueId")
                                }
                                productTestingIssues.push(eachObject)
                                eachObject = {}
                        }
                    }
                }
                if (isEditMode) {
                    valueObject["createdBy"] = dailyReport.createdBy;
                    valueObject["createdTime"] = dailyReport.createdTime;
                    valueObject["id"] = dailyReport.id;
                } else {
                    const date = moment(moment()).tz(this.props?.timeZoneName).format("YYYY-MM-DD");
                    const [start, end] = computeTime("day", null, date, this.props.timeZoneName);
                    valueObject["startTime"] = start;
                    valueObject["endTime"] = end;
                }
                valueObject["testingDataAttributes"] = productTestingIssues;
                valueObject["product"] = product;
                valueObject["location"] = values.location || null;

                this.props.toggleIsFetchingDailyReports(true);
                await this.props.postDailyReport(id, valueObject);
                this.props.toggleIsFetchingDailyReports(false);
                this.props.closeCurrentOpenTab();
            }
        })
    }

    recentOutageInfo = (attributeName) => {
        const {usableObject} = this.state
        const recentOutage = usableObject ? this.findInitialValue(attributeName, "recentOutages") : [];
        const recentOutageJsx = <div style = {{display: "flex", flexDirection: "column"}}>
            <div><span>Recent Outage:</span></div>
        {(recentOutage && recentOutage?.length >0) && recentOutage.map((recentOutageObj) =>(
             <div><span>{ moment.tz(recentOutageObj?.issueStartTime , this.props.timeZoneName).format(CONFIG.dateFormats.userDateTimeWithTZ2)} to {recentOutageObj?.issueEndTime ? moment.tz(recentOutageObj?.issueEndTime , this.props.timeZoneName).format(CONFIG.dateFormats.userDateTimeWithTZ2) : "Present"}</span></div>
         )
         )}
         </div>
        return(
        <>
        {(recentOutage && recentOutage?.length >0)&&
        <Tooltip title={recentOutageJsx} placement="topLeft" overlayStyle={{ minWidth: "500px", minHeight: "30px" }}>
        {" "}<Icon className="text-color-primary" icon="info-outline" />
    </Tooltip>
        }
        </>
        )
    }

    renderAttributes = (key) => {
        const { form } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const productObj = this.state.product?.length > 0 ? JSON.parse(this.state?.product) : [];
        const { isEditMode } = this.state;
        if (productObj?.productAttributes?.length > 0) {
            return (
                <Row gutter={24}>

                    <Form.Item style={{ display: 'none' }} >
                        {
                            getFieldDecorator(`id[${key}]`, {
                                initialValue: productObj?.productAttributes[key]?.attribute?.id
                            })
                        }
                    </Form.Item>
                    <Col xl={7} md={24} lg={8} sm={24} xs={24}>
                        <Form.Item label={<>
                            {productObj?.productAttributes[key]?.attribute.attributeName}
                            {isEditMode && this.recentOutageInfo(productObj?.productAttributes[key]?.attribute.attributeName)}
                            </>
                            }>
                            {getFieldDecorator(`attributeName[${key}]`, {

                                initialValue: !isEditMode ? CONFIG.dailyReportAttrStatus.status[2] : this.findInitialValue(productObj?.productAttributes[key]?.attribute.attributeName, "attributeStatus")
                            })(<Select
                                showSearch={true}
                                getPopupContainer={() => document.getElementById('dailyReportForm')}
                                placeholder="Status">
                                <Option value={CONFIG.dailyReportAttrStatus.status[0]}>{CONFIG.dailyReportAttrStatus.status[0]}</Option>
                                <Option value={CONFIG.dailyReportAttrStatus.status[1]}>{CONFIG.dailyReportAttrStatus.status[1]}</Option>
                                <Option value={CONFIG.dailyReportAttrStatus.status[2]}>{CONFIG.dailyReportAttrStatus.status[2]}</Option>
                            </Select>)}
                        </Form.Item>
                    </Col>
                    {(getFieldValue(`attributeName[${key}]`) === CONFIG.dailyReportAttrStatus.status[0] || getFieldValue(`attributeName[${key}]`) === CONFIG.dailyReportAttrStatus.status[1]) &&
                        <Col xl={8} md={24} lg={8} sm={24} xs={24} >
                            <Form.Item label="Issue Description">
                                {getFieldDecorator(`issueDescription[${key}]`, {
                                    rules: [
                                        {
                                            required: (getFieldValue(`attributeName[${key}]`) === CONFIG.dailyReportAttrStatus.status[0]) ? true : false,
                                            message: "Please input the description",
                                        },
                                    ],
                                    initialValue: !isEditMode ? "" : this.findInitialValue(productObj?.productAttributes[key]?.attribute.attributeName, "issueDescription"),
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                    }
                    {(getFieldValue(`attributeName[${key}]`) === CONFIG.dailyReportAttrStatus.status[0]) &&
                        <Col xl={9} md={24} lg={8} sm={24} xs={24} >
                            <Form.Item label="Ticket No. / URL">
                                {getFieldDecorator(`link[${key}]`, {

                                    initialValue: !isEditMode ? "" : this.findInitialValue(productObj?.productAttributes[key]?.attribute.attributeName, "link"),
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                    }
                </Row>
            )
        } else {
            return [];
        }
    }

    getCurrentAttributedata = (values) => {
        return {
            attributeStatus: values.attributeName,
            issueDescription: values.issueDescription,
            link: values.link
        };

    }

    hasFormChanged = (values) => {
        const { usableObject } = this.state;
        const { getFieldValue } = this.props.form;
        let hasChanged = false;
        const keys = (getFieldValue('keys') || [])
        for (let i = 0; i < values?.attributeStatus?.length; i++) {
            if (keys.includes(i)) {
                if (usableObject[i]?.attributeStatus !== values?.attributeStatus?.[i]) {
                    hasChanged = true
                }
                if ((values?.issueDescription?.[i] !== undefined && usableObject[i]?.issueDescription !== undefined
                    && values?.issueDescription?.[i] !== usableObject[i]?.issueDescription) ||
                    (values?.issueDescription?.[i] !== undefined && usableObject[i]?.issueDescription === undefined) ||
                    (values?.issueDescription?.[i] === undefined && usableObject[i]?.issueDescription !== undefined)) {
                    hasChanged = true
                }
                if ((values?.link?.[i] !== undefined && usableObject[i]?.link !== undefined
                    && values?.link?.[i] !== usableObject[i]?.link) ||
                    (values?.link?.[i] !== undefined && usableObject[i]?.link === undefined) ||
                    (values?.link?.[i] === undefined && usableObject[i]?.link !== undefined)) {
                    hasChanged = true
                }
            }
        }

        return hasChanged
    }

    componentDidUpdate() {
        const currentData = this.getCurrentAttributedata(this.props.form.getFieldsValue());
        this.hasChanged = this.state.isEditMode ? this.hasFormChanged(currentData) : true;
        const { openedTabs, activeKey } = this.props;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
        else if (hasUnsavedChanges && !this.hasChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    }

    render() {
        const { form, loading, loggedInUser, dailyReport } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const { isEditMode } = this.state;
        const productObj = this.state.product?.length > 0 ? JSON.parse(this.state?.product) : [];
        getFieldDecorator('keys', { initialValue: ([...Array(productObj?.productAttributes?.length).keys()]) });
        const keys = (getFieldValue('keys') || [])
        return (
            <div className="content-container">
                <Spin spinning={loading}>
                    {this.renderFormButtons()}
                    <Form id="dailyReportForm">
                        <Row gutter={15}>
                            <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                                <Form.Item label="Product">
                                    {
                                        getFieldDecorator('product', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Please select a product",
                                                },
                                            ],
                                            initialValue: isEditMode ? JSON.stringify(productObj) : "",
                                        })(
                                            <Select
                                                showSearch={true}
                                                getPopupContainer={() => document.getElementById('dailyReportForm')}
                                                onChange={this.handleProductSelect}
                                                disabled={isEditMode}
                                                placeholder="Select a demo">
                                                {this.renderProductOptions()}
                                            </Select>
                                        )
                                    }

                                </Form.Item>
                            </Col>
                            <Col xl={5} lg={12} md={24} sm={24} xs={24}>
                                <Form.Item label="Location/Resource">
                                    {getFieldDecorator("location", {
                                        initialValue: dailyReport?.location,
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xl={5} lg={12} md={24} sm={24} xs={24} className="float-right">
                                <Form.Item label="Test Engineer">
                                    {getFieldDecorator("testEngineer", {

                                        initialValue: dailyReport?.createdBy?.fullName || loggedInUser?.fullName,
                                    })(<Input disabled='true' />)}
                                </Form.Item>
                            </Col>
                            <Col xl={4} lg={12} md={24} sm={24} xs={24} className="float-right">
                                <Form.Item label="Test Date">
                                    {getFieldDecorator("todaysDate", {

                                        initialValue: moment.tz(moment(), this.props.timeZoneName).format(CONFIG.dateFormats.user),
                                    })(<Input disabled='true' />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Row>
                                &nbsp;&nbsp;
                            </Row>
                            {productObj?.productAttributes?.length > 0 ? <Row>
                                <div style={{ fontSize: "15px", color: "black" }}>Attributes</div>
                            </Row>
                                : productObj?.attributesAndStatuses === null && <Empty
                                    description={<div>There are no attributes included for the selected product to create a report. <br />Please add the attributes to create the report</div>}
                                />
                            }
                            {
                                keys.map((key) => {
                                    return this.renderAttributes(key)
                                })
                            }
                        </div>
                    </Form>
                    {this.renderFormButtons()}
                </Spin>
            </div>
        );
    }
}
const mapStateToProps = ({ user, dailyReport, tabsLayout }) => {
    return {
        data: dailyReport.data,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        loading: dailyReport.loading,
        colAttributeMap: dailyReport.colAttributeMap,
        loggedInUser: user.profile,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        productAttributes: dailyReport.productAttributes,
        dailyReport: dailyReport.dailyReportById,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
    };
};

const DailyReportFormrRef = Form.create()(DailyReportForm);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        getProductAttributes,
        toggleIsFetchingDailyReports,
        postDailyReport,
        clearReportForm,
        getDailyReportById,
        closeCurrentOpenTab,
        toggleHasUnsavedChangesFlag
    }
)(DailyReportFormrRef);