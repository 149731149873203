import {
    TOGGLE_MAINTENANCE_AVAILABILITY,
    CHECK_MAINTENANCE_AVAILABILITY,
    SET_MAINTENANCE_RESOURCE_ID,
    CLEAR_MAINTENANCE_FORM,
    EDIT_MAINTENANCE_SCHEDULE,
    TOGGLE_MAINTENANCE_FORM_MODAL,
    UPDATE_MAINTENANCE_CURRENT_PAGE,
    FETCH_MAINTENANCE_SCHEDULE,
    TOGGLE_IS_FETCHING_MAINTENANCE_SCHEDULE,
    CLEAR_MAINTENANCE_FORM_ERRORS,
    CLEAR_MAINTENANCE_SCHEDULES,
    UPDATE_FILTERSORT,
    LAST_MAINTENANCE_FILTER,
    CLEAR_UPDATED_MAINTENANCE_DETAILS,
    FETCH_SHARED_DEMO_DETAILS,
    CLEAR_SHARED_DEMOS,
    SET_MAINTENANCE_ERROR_TYPE,
    CHANGE_MAINTENANCE_TYPE,
    UPDATE_SHARED_DEMO_LIST
} from '../actions/types';
import CONFIG from '../config';
const maintenanceFormState = {
    availabilityVisible: false,
    isMaintenanceAvailable: undefined,
    selectedResource: undefined,
    modalVisible: false,
    selectedErrorType: undefined,
    maintenanceType: CONFIG.maintenanceTypes.demo,
};
export const maintenanceFormReducer = (state = maintenanceFormState, action) => {
    switch (action.type) {
        case TOGGLE_MAINTENANCE_AVAILABILITY:
            return { ...state, availabilityVisible: action.payload };
        case CHECK_MAINTENANCE_AVAILABILITY:
            return { ...state, ...action.payload };
        case SET_MAINTENANCE_RESOURCE_ID:
            return { ...state, selectedResource: action.payload };
        case SET_MAINTENANCE_ERROR_TYPE:
            return { ...state, selectedErrorType: action.payload };
        case CLEAR_MAINTENANCE_FORM:
            return maintenanceFormState;
        case CHANGE_MAINTENANCE_TYPE:
            return { ...state, maintenanceType: action.payload, sharedDemos: [] }
        case TOGGLE_MAINTENANCE_FORM_MODAL:
            return { ...state, modalVisible: action.payload };
        case CLEAR_SHARED_DEMOS:
            return { ...state, sharedDemos: [] };
        case UPDATE_SHARED_DEMO_LIST:
            return { ...state, sharedDemos: state.sharedDemos.filter(item => item.demo.id !== action.payload.demo.id) }
        case FETCH_SHARED_DEMO_DETAILS:
            return { ...state, sharedDemos: action.payload };
        case EDIT_MAINTENANCE_SCHEDULE:
            return {
                ...state,
                startTime: action.payload.startTime,
                endTime: action.payload.endTime,
                description: action.payload.description,
                title: action.payload.title,
                maintenanceType: action.payload.maintenanceType,
                maintenanceRelations: action.payload.demos,
                maintenanceRelationsObj: action.payload.maintenanceRelations,
                maintenanceId: action.payload.id,
                demo: action.payload.demo,
                isAllDay: action.payload.isAllDay,
                notifyUsers: action.payload.notifyUsers,
                freqType: action.payload.freqType,
                groupId: action.payload.groupId,
                createdBy: action.payload.createdBy,
                createdTime: action.payload.createdTime,
                dateRangeList: action.payload.dateRangeList,
                dayOfWeek: action.payload.dayOfWeek,
                endSeriesDate: action.payload.endSeriesDate,
                monthDay: action.payload.monthDay,
                dayName: action.payload.dayName,
                value: action.payload.value,
                timeZone: action.payload.timeZone
            };
        case CLEAR_MAINTENANCE_FORM_ERRORS:
            return { ...state, isMaintenanceAvailable: undefined, maintenanceUnavailableCount: undefined, maintenanceSchedules: undefined, resourceSchedules: undefined, unavailableResources: undefined }
        case UPDATE_FILTERSORT:
            return { ...state, activeFilterSort: action.payload.activeFilterSort, inactiveFilterSort: action.payload.inactiveFilterSort, status: action.payload.status }
        default:
            return state;
    }
};

const maintenanceScheduleState = {
    currentActivePage: 1,
    currentInactivePage: 1,
    isFetching: false,
    activePageSize: CONFIG.lazyLoadPageSize,
    inactivePageSize: CONFIG.lazyLoadPageSize
};

export const maintenanceScheduleReducer = (state = maintenanceScheduleState, action) => {
    switch (action.type) {
        case FETCH_MAINTENANCE_SCHEDULE:
            return { ...state, ...action.payload, isEdit: false }
        case UPDATE_MAINTENANCE_CURRENT_PAGE:
            return { ...state, ...action.payload }
        case TOGGLE_IS_FETCHING_MAINTENANCE_SCHEDULE:
            return { ...state, isFetching: action.payload }
        case CLEAR_MAINTENANCE_SCHEDULES:
            return { ...state, active: undefined, inactive: undefined, filter: undefined, status: true, isEdit: false };
        case LAST_MAINTENANCE_FILTER:
            return {
                ...state, filter: action?.payload[0] || {},
                activeFilterSort: action?.payload[1] || {},
                inactiveFilterSort: action?.payload[2] || {},
                status: action?.payload[3],
                currentActivePage: action?.payload[4],
                currentInactivePage: action?.payload[5],
                activePageSize: action?.payload[6],
                inactivePageSize: action?.payload[7],
                viewType: action?.payload[8],
                calendarCurrentDate: action?.payload[9],
                calendarViewType: action?.payload[10],
                isEdit: true
            }
        case CLEAR_UPDATED_MAINTENANCE_DETAILS:
            return { ...state, updatedMaintenanceDetails: undefined, updatedMaintenanceId: undefined, typeOfOperation: undefined };
        default:
            return state;
    }
}