import React, { useEffect, useState } from 'react'
import { getDemoStatusDetails, fetchUsersForDemoDataCenter } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput, Form as NeoForm, Select, SelectOption, TextArea } from "neo-latest";
import moment from 'moment-timezone';
import CONFIG from '../../config';
import { Spin, Form } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function DemoStatusDetails(props) {
    const { record, readOnly, timezone, form, panelKey } = props;
    const [statusData, setStatusData] = useState({});
    const { getFieldDecorator } = form;
    const [loading, setLoading] = useState(false);
    const users = useSelector(({ dataCenterStatus }) => dataCenterStatus?.users || []);

    const dispatch = useDispatch();
    useEffect(() => {
        const fetchUserDetails = async () => {
            setLoading(true);
            const data = await dispatch(getDemoStatusDetails(record?.id));
            setStatusData(data);
            setLoading(false);
            if (!readOnly) {
                dispatch(fetchUsersForDemoDataCenter(record))
            }
        }
        fetchUserDetails();
        // eslint-disable-next-line
    }, [readOnly]);

    const getInitialValue = (record) => {
        if (users !== undefined && users?.length !== 0) {
            const result = users?.filter((user) => user?.id === record?.id);
            if (result?.length === 1) {
                return JSON.stringify({ id: record?.id, email: record?.email })
            }
            else return undefined
        }
        else return undefined
    }

    const renderUsersOptions = () => {
        if (users) {
            return users?.map(user => {
                return (
                    <SelectOption className="remove-padding-style" key={user.id} value={JSON.stringify({ id: user?.id, email: user?.email })}>
                        {user.fullName}
                    </SelectOption>
                );
            });
        }
        return;
    }

    return (
        <Spin spinning={loading} indicator={antIcon}>
            <NeoForm>
                <div>
                    <div style={{ fontSize: "12px", fontWeight: "400", color: "#323232", lineHeight: "16px", marginBottom: "4px" }}>
                        Demo Title
                    </div>
                    <div style={{ padding: "12px 0", fontSize: "14px", fontWeight: "400", color: "#000", lineHeight: "20px" }}>
                        {(statusData?.demo?.title || "-")}
                    </div>
                    <div style={{ marginTop: "16px", display: "flex" }}>
                        <div style={{ display: "flex", flex: "1" }}>
                            <TextInput label="Issue Type" value={(statusData?.type || "-")} readOnly />
                        </div>
                        <div style={{ display: "flex", flex: "1" }}>
                            <TextInput label={statusData?.type === "Demo DC" ? "Affected Data Center" : "Affected Resource"} value={((statusData?.type === "Demo DC" ? statusData?.dataCenter?.title : statusData?.resource?.name) || "-")} readOnly />
                        </div>
                    </div>
                    <div style={{ marginTop: "16px", display: "flex" }}>
                        <div style={{ display: "flex", flex: "1" }}>
                            <TextInput label="Status" value={((statusData?.type === "Demo DC" ? CONFIG.demoStatus.types[statusData?.status] : CONFIG.demoResourceStatus.types[statusData?.status]) || "-")} readOnly />
                        </div>
                        <div style={{ display: "flex", flex: "1" }}>
                            {readOnly ?
                                <TextInput label="Assigned User" value={((statusData?.assignedUser?.fullName) || "-")} readOnly />
                                : <Form.Item style={{ width: "100%" }}>
                                    {
                                        getFieldDecorator("assignedUser", {
                                            rules: [
                                                {
                                                    type: 'string',
                                                    required: true,
                                                    message: `Please select a user!`
                                                }
                                            ],
                                            initialValue: getInitialValue(record?.assignedUser)
                                        })(<Select required className="remove-padding-style" label="Assigned User">{renderUsersOptions()}</Select>)
                                    }
                                </Form.Item>
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: "16px", display: "flex", flexDirection: "column", gap: "16px" }}>
                        <div>
                            <TextInput label="Updated By" value={((statusData?.updatedBy?.fullName) || "-")} readOnly />
                        </div>
                        <div>
                            <TextInput label="Updated Date" value={statusData?.updatedTime ? moment.tz(statusData?.updatedTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                        </div>
                    </div>
                    {
                        panelKey === "closed" && <div style={{ marginTop: "16px", display: "flex", flexDirection: "column", gap: "16px" }}>
                            <div>
                                <TextInput label="Resolved By" value={((statusData?.resolvedBy?.fullName) || "-")} readOnly />
                            </div>
                            <div>
                                <TextInput label="Resolved Date" value={statusData?.resolvedTime ? moment.tz(statusData?.resolvedTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                            </div>
                        </div>
                    }
                    <div style={{ marginTop: "16px" }}>
                        <TextArea label="Description" rows={4} value={(statusData?.description || "-")} readOnly />
                    </div>
                    {readOnly ? <div style={{ marginTop: "16px" }}>
                        <TextArea label="Notes" rows={4} value={(statusData?.notes || "-")} readOnly />
                    </div> : <Form.Item>
                        {getFieldDecorator("notes", { initialValue: statusData?.notes })(<TextArea label="Notes" rows={4} />)}
                    </Form.Item>}
                </div>
            </NeoForm>
        </Spin>
    )
}

export default DemoStatusDetails;