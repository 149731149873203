import React from 'react';
import { Spin } from 'antd';
import { Icon } from "@avaya/neo-react"
import NoData from '../common/NoData';
import { LoadingOutlined } from '@ant-design/icons';

const DashboardNoData = ({heading, icon}) => (
<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={true}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
        <div> <span className='dashboard-schedules-category-heading left-spacing'><Icon icon={icon} style={{ fontSize: "20px", paddingRight: "10px" }} />{heading}</span></div>
        <div><NoData size="exsm" /></div>
    </div>
    </Spin>
);

export default DashboardNoData;