import React, { useEffect, useState, useRef } from 'react';
import CONFIG from '../../config';
import { DualAxes } from '@ant-design/charts';
import { Badge, Menu, Dropdown, Alert } from "antd";
import * as htmlToImage from 'html-to-image';
import { Spin, Icon } from 'antd';
import download from 'downloadjs';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function DemoTrendReport(props) {
    const reportData = props?.reportData;
    const data = reportData?.graphData || [];
    const showPercentage = typeof data[0]?.data[0]?.count === "number" ? false : data[0]?.data[0]?.count?.includes("%")
    const [avayaData, setAvayaData] = useState([]);
    const [bpData, setBpData] = useState([]);
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        setAvayaData(createDataForUsers(CONFIG.userTypes[0]?.value));
        setBpData(createDataForUsers(CONFIG.userTypes[1]?.value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //eslint-disable-next-line
    }, [reportData]);


    const downloadScreenshot = (key) => {
        setLoading(true);
        const element = document.getElementById('myChart');
        (key === "png" ? htmlToImage.toPng(element) : htmlToImage.toJpeg(element, { backgroundColor: "#fff" }))
            .then((dataUrl) => {
                download(dataUrl, key === "png" ? 'Demo Schedules Trend.png' : "Demo Schedules Trend.jpeg");
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const createDataForUsers = (userType) => {
        return data.flatMap(item => {
            return item.data.filter(entry => entry.userType === userType).map(entry => ({
                name: `${userType.toUpperCase()} - ${item.entityName} `,
                count: parseInt(entry.count),
                quarter: entry.quarter
            }));
        });
    }

    const tooltipConfig = {
        follow: true,
        enterable: true,
        offset: 5,
        customContent: (value, items) => {
            items.reverse();
            const uniqueItems = [...new Map(items.map(item => [item['name'].trim(), item])).values()]
            uniqueItems.reverse();
            const container = <div style={{ padding: '20px' }}>
                <div style={{ fontSize: "14px", fontWeight: "600", textAlign: "center", marginBottom: "4px" }}>Demo Schedules Count</div>
                {uniqueItems.map((item, index) => {
                    const [user, demo] = item?.name?.split("-", 2);
                    return (
                        <div key={index}>
                            <Badge color={item.color} className="vertical-spacing-3" text={<span onClick={() => null}>
                                <b>{user}</b> - {demo}<span><b>{item.value}{showPercentage ? "%" : ""}</b></span> </span>} />
                        </div>
                    )
                })}
            </div>
            return container;
        }
    }

    const graphConfig = {
        data: [avayaData, bpData],
        xField: 'quarter',
        yField: ['count', 'count'],
        xAxis: {
            label: {
                autoRotate: false,
                offset: 10,
                style: {
                    fontSize: 12,
                }
            },
            grid: {
                line: {
                    style: {
                        stroke: '#efefef',
                        lineWidth: 2,
                    },
                },
            },
            meta: {
                nice: true,
            },
        },
        yAxis: {
            meta: {
                nice: true,
            },
            label: {
                autoRotate: false,
                offset: 10,
            },
            grid: {
                line: {
                    style: {
                        stroke: '#efefef',
                        lineWidth: 2,
                    },
                },
            },
        },
        autoFit: true,
        meta: {
            nice: true,
        },
        geometryOptions: [
            {
                geometry: 'line',
                meta: {
                    nice: true,
                },
                seriesField: 'name',
                color: CONFIG.neoGraphColors,
            },
            {
                geometry: 'line',
                meta: {
                    nice: true,
                },
                seriesField: 'name',
                color: CONFIG.neoGraphColors,
                lineStyle: () => {
                    return {
                        lineDash: [3, 9],
                        opacity: 1,
                    };
                },
            }
        ],
        tooltip: { ...tooltipConfig },
        legend: {
            itemWidth: 170,
            position: 'bottom'
        },
        animation: {
            appear: {
                animation: 'path-in',
                duration: 4000,
            },
        },
        smooth: true,
    };


    const menu = (
        <Menu onClick={(e) => downloadScreenshot(e.key)}>
            <Menu.Item key="png">
                Download PNG
            </Menu.Item>
            <Menu.Item key="jpg">
                Download JPEG
            </Menu.Item>
        </Menu>
    );

    return (
        <Spin indicator={antIcon} spinning={loading} wrapperClassName="spin-overlay">
            <div>
                <div id="myChart">
                    <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", flex: 1, alignItems: 'center', justifyContent: "center", fontWeight: 600 }}>
                            Avaya users <span className='straight-line'></span>
                        </div>
                        <div style={{ display: "flex", flex: 1, alignItems: 'center', justifyContent: "center", fontWeight: 600 }}>
                            BP users <span className="dotted-line"></span>
                        </div>
                        <div style={{ padding: "16px", display: "flex", flex: 5, justifyContent: "flex-end" }}>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <span onClick={e => e.preventDefault()}>
                                    <Icon style={{ cursor: "pointer" }} type="menu" />
                                </span>
                            </Dropdown>
                        </div>
                    </div>
                    <div ref={ref} >
                        <DualAxes   {...graphConfig} />
                    </div>
                </div>
                <div style={{ margin: "0 24px" }}>
                    <Alert message={CONFIG.systemMessage.demoTrendReportMessage} type="warning" showIcon />
                </div>
            </div>
        </Spin>
    )
}

export default DemoTrendReport