import { batch } from 'react-redux';
import moment from 'moment-timezone';

import {
    FETCH_ALL_NEWS,
    UPDATE_NEWS_DETAILS,
    UPDATE_NEWS_BODY,
    UPDATE_NEWS_INTRO,
    CLEAR_NEWS_DETAILS,
    CLEAR_NEWS_INTRO,
    CLEAR_NEWS_BODY,
    TOGGLE_IS_FETCHING_NEWS,
    UPDATE_NEWS_CURRENT_PAGE,
    UPDATE_NEWS_ROLE_TEMPLATE,
    FETCH_USER_NEWS,
    FETCH_USER_INACTIVE_NEWS,
    CLEAR_NEWS,
    CLEAR_INACTIVE_NEWS,
    REORDER_NEWS,
    TOGGLE_IS_NEWS_REORDERED,
    FETCH_ACTIVE_NEWS,
    FETCH_INACTIVE_NEWS,
    UPDATE_USER_NEWS_DETAILS,
    LAST_NEWS_FILTER,
    CLEAR_NEWS_RESPONSE,
    TOGGLE_HAS_NEWS_UPDATE,
    REFRESH_NEWS,
    UPDATE_NEWS_FORM_DETAILS_TAB_DATA,
    UPDATE_INTRO_TAB_DATA,
    UPDATE_BODY_TAB_DATA,
    CLEAR_NEWS_DATA,
    UPDATE_NEWS_MEDIA_PATH,
    UPDATE_NEWS_COVER_IMAGE_PATH,
    DELETE_NEWS_MEDIA,
    DELETE_COVER_IMAGE_MEDIA,
    FETCH_DRAFT_NEWS,
    FETCH_ALL_USER_NEWS
} from './types'
import CONFIG from '../config';
import { aepNewsNotificationSupport } from "../apis";
import pushMessage from '../components/common/PushMessage';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';
import { download } from '../utils/file';
import { closeCurrentOpenTab } from './tabsLayout';

export const getAllNews = async (access) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/news", { params: { access } });
    return response.data ? response.data.dataSet : [];
}

export const getNews = async (newsId) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/news/" + newsId);
    return response.data || [];
}

export const fetchUserNewsAccess = async (newsId) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/access/news/" + newsId);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        return response.data;
    }
    else return CONFIG.unableToFetch;
}

export const fetchUserNews = (active, newsId) => async (dispatch) => {
    const expired = active ? undefined : 1;
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/user/news", { params: { expired } });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            const currentNews = response.data ? newsId ? response.data?.find(news => news.id === parseInt(newsId)) : response.data[0] : undefined;
            if (currentNews) dispatch(updateCurrentUserNews(currentNews));
            dispatch({
                type: expired === 1
                    ? FETCH_USER_INACTIVE_NEWS
                    : FETCH_USER_NEWS,
                payload: response.data ? response.data : []
            });
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch News');
    dispatch(toggleIsFetchingNewsFlag(false));
}

export const fetchAllUserNews = ({ pageNumber = 1, newsId }) => async (dispatch) => {

    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/v1/user/news", {
        params: {
            pageSize: CONFIG.cardPageSize,
            pageNumber: pageNumber,
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            const currentNews = response.data ? newsId ? response.data?.dataSet?.find(news => news.id === parseInt(newsId)) : response.data.dataSet[0] : undefined;
            if (currentNews) dispatch(updateCurrentUserNews(currentNews));
            dispatch({
                type: FETCH_ALL_USER_NEWS,
                payload: response.data ? response.data : []
            });
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch News');
    dispatch(toggleIsFetchingNewsFlag(false));
}

export const fetchAllNews = ({ pageNumber = 1, pageSize = CONFIG.pageSize, access, fetchInactiveNews = false, filterSort }) => async (dispatch) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/news", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            access,
            expired: CONFIG.expired[fetchInactiveNews],
            filterSort
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (pageNumber !== 1 && !response.data) {
            dispatch(fetchAllNews({ pageNumber: pageNumber - 1, fetchInactiveNews }));
            return;
        }
        else dispatch({ type: fetchInactiveNews ? FETCH_INACTIVE_NEWS : FETCH_ALL_NEWS, payload: [response.data, response.status] || [] });
    }
    else {
        dispatch({ type: CLEAR_NEWS_RESPONSE, payload: response.status })
        pushMessage(CONFIG.messageType.error, 'Unable to fetch news');
    }
    dispatch(toggleIsFetchingNewsFlag(false));
    return response.data || []
}

export const fetchActiveNews = (filterSort) => async (dispatch) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/news/active", {
        params: {
            filterSort
        }
    })
    dispatch(toggleIsFetchingNewsFlag(false));
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ACTIVE_NEWS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch news');
}

export const fetchNews = (newsId, userNews = false, searchString, isNewsClone) => async (dispatch, getState) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/news/" + newsId);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { user, roleTemplates } = getState();
        const timeZoneName = user.profile.timezone;
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const news = response.data;
        let updatedNews;
        const newsData = {
            ...response.data,
            mediaPath: isNewsClone ? null : response.data.mediaPath,
            coverImagePath: isNewsClone ? null : response.data.coverImagePath,
            publishTime: isNewsClone ? undefined : response.data?.publishTime ? moment.tz(response.data?.publishTime, timeZoneName) : undefined,
            expiryTime: isNewsClone ? undefined : response.data?.expiryTime ? moment.tz(response.data?.expiryTime, timeZoneName) : undefined,
            roleTemplates: isAdmin ? response.data.roleTemplates.filter(newsRoleTemplate => allRoleTemplates?.some(roleTemplate => newsRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates,
            assignedRoleTemplates: isAdmin ? response.data.roleTemplates.filter(newsRoleTemplate => allRoleTemplates?.some(roleTemplate => newsRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates,
            isOverride: false,
            notifyUsers: false
        }
        if (searchString) {
            const searchTextRegEx = CONFIG.regEx.searchText.source.replace('searchText', searchString);
            updatedNews = { ...news, intro: news.intro ? news.intro.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`) : news.intro, body: news.body ? news.body.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`) : news.body }
        }

        if (userNews) {
            dispatch(updateCurrentUserNews(updatedNews))
        }
        else {
            batch(() => {
                dispatch(
                    {
                        type: UPDATE_NEWS_DETAILS,
                        payload: newsData,
                    })
                dispatch(updateNewsDetailsTabData({ newsId: newsId, newsDetails: newsData, fetchFromAPI: true, isClone: isNewsClone }));
            });
        }
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch News');
    }
    dispatch(toggleIsFetchingNewsFlag(false));
    return response.data || []
}

export const updateNewsDetailsTabData = ({ newsId, newsDetails, fetchFromAPI = false, isClone = false }) => (dispatch, getState) => {
    var formTabData = {};
    if (fetchFromAPI) {
        formTabData = {
            id: parseInt(newsId),
            details: newsDetails,
            originalDetails: newsDetails,
            introHtml: newsDetails.intro,
            bodyHtml: newsDetails.body,
            isContentLoaded: true,
            isClone: isClone,
            fetchFromAPI
        }
    }
    else {
        let requestData = []
        const state = getState();
        const originalDetails = state.currentNews.newsDetailsTabData.find(data => ((parseInt(data.id) === parseInt(newsId)) && (data?.isClone === isClone)))?.originalDetails;
        const roleTemplates = state.currentNews.newsDetailsTabData.find(data => ((parseInt(data.id) === parseInt(newsId)) && (data?.isClone === isClone)))?.details?.roleTemplates;
        const assignedRoleTemplates = state.currentNews.newsDetailsTabData.find(data => ((parseInt(data.id) === parseInt(newsId)) && (data?.isClone === isClone)))?.details?.assignedRoleTemplates;
        requestData = {
            ...newsDetails,
            roleTemplates: isClone ? roleTemplates?.map(item => { return { ...item, id: undefined } }) : roleTemplates,
            assignedRoleTemplates: isClone ? assignedRoleTemplates?.map(item => { return { ...item, id: undefined } }) : assignedRoleTemplates,
            title: newsDetails?.title,
            link: newsDetails?.link,
            linkText: newsDetails?.linkText,
            hasThumbnail: newsDetails?.hasThumbnail,
            publishTime: newsDetails?.publishTime,
            expiryTime: newsDetails?.expiryTime,
            notifyUsers: newsDetails?.notifyUsers,
            isOverride: newsDetails?.isOverride,
            media: newsDetails?.media,
            mediaPath: newsDetails?.mediaPath,
            coverMedia: newsDetails?.coverMedia,
            coverImagePath: newsDetails.coverImagePath,
            isContentLoaded: false,
            hasDraft: originalDetails?.hasDraft,
            draftNews: originalDetails?.draftNews,
            isDraftMode: originalDetails?.isDraftMode,
            parentNews: originalDetails?.parentNews,
            createdBy: originalDetails?.createdBy,
        }

        formTabData = {
            id: parseInt(newsId),
            details: requestData,
            originalDetails: newsDetails?.originalDetails,
            introHtml: newsDetails.introHtml,
            bodyHtml: newsDetails.bodyHtml,
            isContentLoaded: false,
            isClone: isClone,
            fetchFromAPI
        }
    }

    dispatch({
        type: UPDATE_NEWS_FORM_DETAILS_TAB_DATA,
        payload: formTabData
    })
    dispatch(toggleIsFetchingNewsFlag(false));
}

export const updateNewsEditorTabData = (editorType, newsId, editorContent, isClone) => (dispatch, getState) => {
    dispatch({
        type: editorType === "intro" ? UPDATE_INTRO_TAB_DATA : UPDATE_BODY_TAB_DATA,
        payload: {
            id: newsId,
            editorContent,
            isClone,
            isContentLoaded: true
        }
    })
}


/*
    news: Contains the current news in NewsForm.js and News.js
    panelKey: The active panel key in News.js
*/


export const updateCurrentUserNews = (news) => {
    return {
        type: UPDATE_USER_NEWS_DETAILS,
        payload: news
    }
}

export const updateNewsDetails = ({ news, panelKey, isUserNews = false, newsObj }) => {
    return {
        type: isUserNews ? UPDATE_USER_NEWS_DETAILS : UPDATE_NEWS_DETAILS,
        payload: news,
        newsObj,
        panelKey
    }
}

export const updateNewsDescription = (key, payload) => {
    const type = key === 'intro'
        ? UPDATE_NEWS_INTRO
        : UPDATE_NEWS_BODY
    return { type, payload }
}

export const updateNewsCurrentPage = (currentPageNumber, pageSize, newsId, newsIndex) => {
    return {
        type: UPDATE_NEWS_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, newsId, newsIndex]
    }
};

export const clearCurrentNews = () => async (dispatch) => {
    batch(() => {
        dispatch(clearNewsDetails());
        dispatch(clearNewsDescription("intro"));
        dispatch(clearNewsDescription("body"));
    })
}

export const clearNewsDescription = (key) => {
    const type = key === 'intro'
        ? CLEAR_NEWS_INTRO
        : CLEAR_NEWS_BODY
    return { type }
}

export const clearNewsDetails = () => {
    return {
        type: CLEAR_NEWS_DETAILS
    }
}

export const toggleIsFetchingNewsFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_NEWS,
        payload: flag
    }
}


export const postNews = (newsId, values, isNewsClone, onlyRoletemplateChanged, isDraftMode) => async (dispatch, getState) => {
    const state = getState();
    const currentNews = state.currentNews.newsDetailsTabData.find(data => ((parseInt(data.id) === parseInt(newsId)) && (data?.isClone === isNewsClone)))
    const roleTemplates = currentNews?.details?.roleTemplates;
    const intro = currentNews?.introHtml;
    const body = currentNews?.bodyHtml;
    const timeZone = state.user.profile.timezone;

    const postNewsData = {
        roleTemplates: (isNewsClone || isDraftMode) ? roleTemplates?.map(item => { return { ...item, id: undefined } }) : roleTemplates,
        title: values.title,
        link: values.link,
        linkText: values.linkText,
        hasThumbnail: values.hasThumbnail,
        publishTime: values.publishTime.tz(timeZone, true).toISOString(),
        expiryTime: values.expiryTime.tz(timeZone, true).toISOString(),
        intro: intro,
        body: body,
        notifyUsers: values.notifyUsers,
        isOverride: values.isOverride,
        mediaPath: currentNews?.details?.mediaPath || null,
        coverImagePath: currentNews?.details?.coverImagePath || null,
        isDraftMode: false,
    };

    const putNewsData = {
        roleTemplates: (isNewsClone || isDraftMode) ? roleTemplates?.map(item => { return { ...item, id: undefined } }) : roleTemplates,
        id: isDraftMode ? (currentNews?.details?.parentNews !== null ? currentNews?.details?.parentNews?.id : parseInt(newsId)) : isNewsClone ? null : newsId !== "-1" ? parseInt(newsId) : undefined,
        title: values.title,
        link: values.link,
        linkText: values.linkText,
        hasThumbnail: values.hasThumbnail,
        publishTime: values.publishTime.tz(timeZone, true).toISOString(),
        expiryTime: values.expiryTime.tz(timeZone, true).toISOString(),
        intro: intro,
        body: body,
        notifyUsers: values.notifyUsers,
        isOverride: values.isOverride,
        mediaPath: currentNews?.details?.mediaPath,
        isDraftMode: false,
        coverImagePath: currentNews?.details?.coverImagePath || null,
        draftNews: (isDraftMode && currentNews?.details?.parentNews !== null) ? { id: parseInt(newsId), createdBy: currentNews?.details?.createdBy || null } : null,
    };
    const contextPath = "/newsNotificationSupportService/news";
    const response = await aepNewsNotificationSupport({
        method: (newsId === undefined || newsId === "-1" || isNewsClone) ? 'post' : 'put',
        url: (newsId === undefined || newsId === "-1" || isNewsClone) ? contextPath : `${contextPath}/${parseInt((isDraftMode && currentNews?.details?.parentNews) ? currentNews?.details?.parentNews?.id : newsId)}`,
        data: (newsId === undefined || newsId === "-1" || isNewsClone) ? postNewsData : putNewsData,
        params: { onlyRoleTemplateUpdate: onlyRoletemplateChanged }
    });
    const successMessage = (newsId === undefined || newsId === "-1" || isNewsClone || isDraftMode) ? 'News created successfully' : 'News updated successfully';
    const errorMessage = (newsId === undefined || newsId === "-1" || isNewsClone) ? 'Unable to create News' : 'Unable to update News';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage)
        dispatch(closeCurrentOpenTab())
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, response.data)
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingNewsFlag(false));
}

export const putNewsMedia = (file, newsId, isClone, isThumbnail) => async (dispatch) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await aepNewsNotificationSupport({
        method: 'put',
        url: "newsNotificationSupportService/news/uploadFile/",
        data: formData
    })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, 'Media uploaded successfully');
        dispatch({
            type: isThumbnail ? UPDATE_NEWS_MEDIA_PATH : UPDATE_NEWS_COVER_IMAGE_PATH,
            payload: { newsId: newsId || "-1", isClone, path: response.data || null }
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to upload media');
}

export const deleteNews = (newsId, hardDelete = false, isDraftMode) => async (dispatch) => {
    const response = await aepNewsNotificationSupport.delete("/newsNotificationSupportService/news/" + newsId, { params: { hardDelete } });
    const deleteMsg = "deleted successfully";
    const errorMsg = "Unable to delete News"
    const successMessage = (isDraftMode === true) ? `News draft ${deleteMsg}` : `News ${deleteMsg}`;
    const errorMessage = (isDraftMode === true) ? `${errorMsg} draft` : `${errorMsg}`;
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, successMessage)
        : pushMessage(CONFIG.messageType.error, errorMessage);
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
};

export const restoreNews = (newsId) => async () => {
    const response = await aepNewsNotificationSupport.put("/newsNotificationSupportService/restore/news/" + newsId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "News restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore news");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
}

export const updateNewsRoleTemplate = (access, accessType, roleType, selectedRoleTemplate, newsId, isClone) => (dispatch, getState) => {
    const allRoleTemplates = getState().roleTemplates.all;
    const { currentNews } = getState();
    let roleTemplate = currentNews.newsDetailsTabData.find(data => ((parseInt(data.id) === parseInt(newsId)) && (data?.isClone === isClone)))
    let assignedRoleTemplates = roleTemplate?.details?.roleTemplates;
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allRTUserAccess' : 'allRTAdminAccess';
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_NEWS_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access, allAccessKey, newsId, isClone }
    });
}

export const clearNews = () => {
    return { type: CLEAR_NEWS }
}

export const clearInactiveNews = () => {
    return { type: CLEAR_INACTIVE_NEWS }
}

export const reorderNews = (reorderedNews) => dispatch => {
    batch(() => {
        dispatch({
            type: REORDER_NEWS,
            payload: [...reorderedNews]
        });
        dispatch(toggleIsNewsReordered(true));
    });
};

export const toggleIsNewsReordered = (flag) => {
    return {
        type: TOGGLE_IS_NEWS_REORDERED,
        payload: flag
    };
};

export const putNewsOrder = (news) => async dispatch => {
    const response = await aepNewsNotificationSupport.put('newsNotificationSupportService/news/updateSortOrder', news);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "News order updated successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to update News order");
    dispatch(toggleIsNewsReordered(false));
};

export const currentNewsFilter = (activeFilterSort, inactiveFilterSort, draftFilterSort, filters, status) => {
    return {
        type: LAST_NEWS_FILTER,
        payload: [activeFilterSort, inactiveFilterSort, draftFilterSort, filters, status]
    }
}

export const exportNewsNotificationTable = async (endpoint, fileName, fileFormat, filterSort, status, timeZone) => {
    const response = await aepNewsNotificationSupport({
        method: 'get',
        url: `/newsNotificationSupportService/export/${endpoint}/${fileFormat}`,
        responseType: "blob",
        params: {
            filterSort,
            expired: status === true ? 0 : status === false ? 1 : status === CONFIG.draftStatusDataSet[2].value ? 3 : 2,

            timeZone
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(fileName, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}

export const clearUserNewsTab = (dispatch) => {
    batch(() => {
        dispatch(clearNews);
        dispatch(clearCurrentNews);
        dispatch(clearInactiveNews);
    })
}

export const refreshNews = () => {
    return {
        type: REFRESH_NEWS
    };
};

export const toggleHasNewsUpdated = () => {
    return {
        type: TOGGLE_HAS_NEWS_UPDATE
    };
};

export const deleteNewsMedia = (fileName, newsId, isClone, path, isThumbnail) => async (dispatch) => {
    const response = await aepNewsNotificationSupport({
        url: "/newsNotificationSupportService/news/deleteFile",
        data: { fileName },
        method: "PUT"
    });
    dispatch({
        type: isThumbnail ? DELETE_NEWS_MEDIA : DELETE_COVER_IMAGE_MEDIA,
        payload: { newsId: newsId || "-1", isClone, path: path || null }
    })
    return response;
}


export const clearNewsData = () => {
    return { type: CLEAR_NEWS_DATA }
}



//For Draft Mode
export const fetchDraftNews = (filterSort) => async (dispatch) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/news/draft", {
        params: {
            filterSort
        }
    })
    dispatch(toggleIsFetchingNewsFlag(false));
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_DRAFT_NEWS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch draft news');
}


export const postDraftNews = ({ newsId, values, onlyRoletemplateChanged, isDraftEdit, parentNewsObj, isNewsClone }) => async (dispatch, getState) => {
    const isDraftCreatedOutOfParent = (isDraftEdit === false && parentNewsObj !== null)
    const state = getState();
    const currentNews = state.currentNews.newsDetailsTabData.find(data => ((parseInt(data.id) === parseInt(newsId))))
    const roleTemplates = currentNews?.details?.roleTemplates;
    const intro = currentNews?.introHtml;
    const body = currentNews?.bodyHtml;
    const timeZone = state.user.profile.timezone;
    const newsDraftObj = {
        roleTemplates: isDraftCreatedOutOfParent ? roleTemplates?.map(item => { return { ...item, id: undefined } }) : roleTemplates,
        id: (isDraftCreatedOutOfParent || newsId === undefined || newsId === "-1") ? undefined : parseInt(newsId),
        title: values.title ? values.title : null,
        link: values.link ? values.link : null,
        linkText: values.linkText ? values.linkText : null,
        hasThumbnail: values.hasThumbnail ? values.hasThumbnail : values.hasThumbnail,
        publishTime: values.publishTime ? values.publishTime.tz(timeZone, true).toISOString() : null,
        expiryTime: values.expiryTime ? values.expiryTime.tz(timeZone, true).toISOString() : null,
        intro: intro ? intro : null,
        body: body ? body : null,
        notifyUsers: values.notifyUsers ? values.notifyUsers : null,
        isOverride: values.isOverride ? values.isOverride : null,
        mediaPath: currentNews?.details?.mediaPath || null,
        coverImagePath: currentNews?.details?.coverImagePath || null,
        isDraftMode: true,
        parentNews: isNewsClone ? null : parentNewsObj
    };
    const contextPath = `newsNotificationSupportService/news/draft`
    const response = await aepNewsNotificationSupport({
        url: (isDraftCreatedOutOfParent || newsId === undefined || newsId === "-1") ? contextPath : `${contextPath}/${newsId}`,
        method: (isDraftCreatedOutOfParent || newsId === undefined || newsId === "-1") ? 'post' : 'put',
        data: newsDraftObj,
        params: { onlyRoleTemplateUpdate: (isDraftCreatedOutOfParent || newsId === undefined || newsId === "-1") ? onlyRoletemplateChanged : undefined }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, "News is saved as Draft")
        dispatch(closeCurrentOpenTab())
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, "Draft of the same name already exists")
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to update News Draft");
    }
    dispatch(toggleIsFetchingNewsFlag(false))
}