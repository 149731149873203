import React from "react";
import { connect } from "react-redux";
import { openLinkInNewTab } from "../../actions";
import { Spin, Divider } from "antd";

import { Icon } from "@avaya/neo-react";
import { LoadingOutlined } from "@ant-design/icons";
import DemoStatsViewDetailsModal from "./DemoStatsViewDetailsModal";
import DailyTestDemoStats from "./DailyTestDemoStats";

class AdminDashboardDemoStats extends React.Component {
  state = {
    isModalVisible: false,
    modalData: [],
    isDailyTesting: false,
    status: "",
    tabIndex: 0,
  };

  onTabChange = async (value) => {
    await this.setState({ tabIndex: value });
  };

  handleModalOpen = async ({ data, isDailyTesting, status }) => {
    await this.setState({
      isModalVisible: true,
      modalData: data,
      isDailyTesting: isDailyTesting,
      status: status,
    });
  };

  handleModalClose = async () => {
    this.setState(
      {
        isModalVisible: false,
      },
      () => {
        // Clear data after modal is closed
        this.setState({
          modalData: [],
          isDailyTesting: false,
          status: "",
        });
      }
    );
  };
  render() {
    const { demoStatusData, demoTestResult } = this.props;

    return (
      <div>
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={
            this.props.isFetchingReport || this.props.isFetchingDemoStatus
          }
        >
          <span className="dashboard-schedules-category-heading left-spacing">
            <Icon
              icon="preferences"
              style={{ fontSize: "19px", paddingRight: "12px" }}
            />
            Demo Status Report
          </span>
          {!(
            Object.keys(demoTestResult).length === 0 &&
            demoTestResult.constructor === Object
          ) &&
          !(
            Object.keys(demoStatusData).length === 0 &&
            demoStatusData.constructor === Object
          ) ? (
            <DailyTestDemoStats handleModalOpen={this.handleModalOpen} />
          ) : (
            <div className="dashboard-no-schedules">
              <Icon icon="info" size="md" />
              No data
            </div>
          )}
          <div style={{ marginLeft: "8px" }}></div>
          <DemoStatsViewDetailsModal
            isModalVisible={this.state.isModalVisible}
            data={this.state.modalData}
            isDailyTesting={this.state.isDailyTesting}
            status={this.state.status}
            handleModalClose={this.handleModalClose}
          />
          <div style={{ marginLeft: "2px" }}>
            <Divider style={{ marginBottom: "6px" }} />
          </div>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ user, adminDashboard, dailyReport }) => {
  return {
    userDetails: user.profile,
    demoStatusData: adminDashboard.demoStatusData,
    isFetchingDemoStatus: adminDashboard.isFetchingDemoStatus,
    demoTestResult: adminDashboard.demoTestResult,
    isFetchingReport: dailyReport.loading,
  };
};

export default connect(mapStateToProps, {
  openLinkInNewTab,
})(AdminDashboardDemoStats);
