import React from 'react';
import { Result, Button } from "antd";
import { connect } from 'react-redux'
import {
    openLinkInNewTab
} from "../actions"
class FallbackUI extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Result
                    status="500"
                    title="Something went wrong."
                    subTitle=" Please reload the page"
                    extra={<Button type="primary" onClick={() => {
                        this.props.openLinkInNewTab(this.props.isAdmin ? "Admin Dashboard" : "User Dashboard", '/dashboard')
                    }}>Go to Dashboard</Button>}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        panes: state.tabsLayout.panes,
        activeKey: state.tabsLayout.activeKey,
        isAdmin: state.user.isAdmin,
    };
};

export default connect(mapStateToProps, {
    openLinkInNewTab
})(FallbackUI);