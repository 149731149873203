import React from "react";
import { connect, batch } from "react-redux";
import { Table, Button, Row, Col, Icon, Divider, Tooltip, Alert, Dropdown, message } from "antd";
import DragListView from "react-drag-listview";
import CONFIG from "../../config";
import {
    selectTitle,
    toggleBackButton,
    toggleIsFetchingPurposeFlag,
    fetchAllPurposes,
    reorderDemoPurposes,
    putDemoPurposesOrder,
    clearDemoPurpose,
    deletePurpose,
    restorePurpose,
    exportTable,
    refreshDemoPurpose,
    handleOnClickAction,
    currentDemoPurposeFilter

} from "../../actions";
import { checkDemoPurposeDependencies } from "../../actions/demoPurpose"
import pushMessage from "../common/PushMessage";
import FilterPanel from "../common/FilterPanel";
import openConfirm from '../common/Confirm';
import StatusFilter from '../common/StatusFilter';
import StatusIcon from "../common/StatusIcon";
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";

class ManageDemoPurpose extends React.Component {
    constructor(props) {
        super(props);
        const that = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const demoPurposes = that.props.demoPurposes;
                const item = demoPurposes.splice(fromIndex, 1)[0];
                demoPurposes.splice(toIndex, 0, item);
                that.props.reorderDemoPurposes(demoPurposes);
            },
            handleSelector: "i",
        };
        this.state = {
            sortedInfo: null,
            filteredData: undefined,
            status: true,
            showFilter: false,
        };

        this.filterSort = {}
        this.tableRef = React.createRef();
        this.feedbackQuestionId = null;
    }
    handleChange = (_, __, sorter) => {
        this.setState({
            sortedInfo: sorter,
        });
    };

    isViewDeletedItemsChecked = undefined;
    solutionTypeId = undefined;

    getSortedDemoPurposeTypes = () => {
        const { demoPurposes } = this.props;
        const { sortedInfo } = this.state;
        if (this.isTableSorted)
            demoPurposes.sort((a, b) => {
                if (sortedInfo.order === "descend") [a, b] = [b, a];
                return a.purpose.toLowerCase().localeCompare(b.purpose.toLowerCase());
            });
        return demoPurposes;
    };

    handleFilter = (filters) => {
        let { viewDeletedItems } = filters;
        if (viewDeletedItems || this.isViewDeletedItemsChecked) {
            this.isViewDeletedItemsChecked = viewDeletedItems
            this.setState({ status: false })
            this.filterRecords(filters)
        }
        else this.filterRecords(filters);
    };

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const purposes = await this.props.fetchAllPurposes(this.filterSort, this.state.status);
        if (purposes?.length > 0 && purposes !== undefined) {
            exportTable(CONFIG.exportTable.purpose.name, purposes, timeZoneName, CONFIG.exportTable.purpose.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }


    filterRecords = async (filters) => {
        let { purpose, viewDeletedItems } = filters;
        this.isViewDeletedItemsChecked = viewDeletedItems;
        purpose = purpose?.trim();

        if (!purpose && !viewDeletedItems) {
            pushMessage(
                CONFIG.messageType.warning,
                "Please select some fields to filter"
            );
            return;
        }

        this.titleSearchString = purpose;
        this.filterSort = {
            filter: {
                purpose,
                viewDeletedItems
            }
        }
        this.props.toggleIsFetchingPurposeFlag(true);
        await this.props.fetchAllPurposes(this.filterSort, !viewDeletedItems ? this.state.status : undefined)
    }

    handleClear = async (refresh) => {
        if (refresh) {
            this.props.refreshDemoPurpose();
        }
        else {
            this.titleSearchString = undefined;
            this.filterStatusTypes = undefined;
            this.filterSort = {}
            this.isViewDeletedItemsChecked = undefined;
            this.setState({ filteredData: undefined });
        }
        this.props.toggleIsFetchingPurposeFlag(true);
        await this.props.fetchAllPurposes(this.filterSort, !this.filterSort?.filter?.viewDeletedItems ? this.state.status : undefined);
    };



    renderUpdateButton = () => {
        const isUpdateDisabled = !this.isTableSorted || this.titleSearchString !== undefined || this.state.status !== undefined || this.isViewDeletedItemsChecked !== undefined
            || this.props.demoPurposes?.length === 0;
        const deleteView = this.isViewDeletedItemsChecked;
        return !deleteView && (
            <Button
                className="primary-action-button-bordered"
                id="update-order-demo-purpose"
                disabled={!this.props.isReordered && isUpdateDisabled}
                onClick={() =>
                    this.props.putDemoPurposesOrder(this.getSortedDemoPurposeTypes())
                }
            >
                Update order
            </Button>
        );
    };

    handleDelete = (id) => {
        this.feedbackQuestionId = id;
        const content = <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "demo purpose")}</h4>
        const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'Demo Purpose')
        openConfirm("Confirm Deletion", this.handleDeleteConfirmation, null, content, modalTitle);
    }

    handleDeleteConfirmation = async () => {
        const response = await this.props.deletePurpose(this.feedbackQuestionId);
        if (response) {
            this.props.toggleIsFetchingPurposeFlag(true);
            this.props.fetchAllPurposes(this.filterSort, this.state.status);
        }
    }

    handleRestoreConfirmation = async () => {
        const response = await this.props.restorePurpose(this.feedbackQuestionId);
        if (response) {
            this.props.toggleIsFetchingPurposeFlag(true);
            this.props.fetchAllPurposes(this.filterSort);
        }
    }

    renderDeleteRestoreConfirmation = (dependencyList, isRestore) => {
        const { scheduleCount } = dependencyList;
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        const okButtonText = isRestore ? "Confirm Restore" : hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
        const text = isRestore ? "Restoration" : "Deletion"

        const showDependencyList = scheduleCount !== 0;
        const modalTitle = isRestore ? CONFIG.restoreModalTitle?.replace('<placeholder>', 'demo purpose') : showDependencyList ? CONFIG.dependencyModalTitle : CONFIG.deleteModalTitle?.replace('<placeholder>', 'demo purpose')
        const content = <>
            {hardDelete && !isRestore ? <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "demo purpose")}</h4> : ""}
            {showDependencyList &&
                <>
                    <h4>{text} of selected demo purpose would affect</h4>
                    {!hardDelete && <>{scheduleCount} * Future Scheduled Demos <br /></>}
                </>
            }
        </>
        openConfirm(okButtonText, isRestore ? this.handleRestoreConfirmation : this.handleDeleteConfirmation, null, content, modalTitle);
    }

    handleDeleteRestore = (demoId, isRestore) => {
        this.feedbackQuestionId = demoId;
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        checkDemoPurposeDependencies(demoId, hardDelete)
            .then(dependencyList => this.renderDeleteRestoreConfirmation(dependencyList, isRestore))
            .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
    }



    async componentDidMount() {
        const { isEdit, currentFilter, status, currentPageNumber } = this.props;
        this.setState({ currentPath: window.location.pathname })
        if (isEdit) {
            this.currentPage = currentPageNumber + 1;
            this.filterSort = currentFilter;
            if (Object.keys(JSON.parse(JSON.stringify(currentFilter)))?.length > 0) {
                const filterObject = JSON.parse(JSON.stringify(currentFilter?.filter));
                Object.keys(filterObject)?.length > 0 && this.setState({ showFilter: true })
            }
            await this.setState({ status: status })
        }
        batch(() => {
            this.props.selectTitle("Manage Demo Purpose");
            this.props.toggleIsFetchingPurposeFlag(true);
            this.props.fetchAllPurposes(currentFilter, this.state.status);
        });
    }
    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentDemoPurposeFilter(this.filterSort, this.state.status)
        }
        else {
            this.props.clearDemoPurpose();
        }
    }

    renderWarningMessage = () => {
        return (
            <Alert
                message={CONFIG.warningMessages.noEnvironmentAccess}
                type="warning"
                showIcon
            />
        )
    }

    handleStatusChange = (value) => {
        this.setState({ status: value })
        this.props.toggleIsFetchingPurposeFlag(true);
        this.props.fetchAllPurposes(this.filterSort, value)
    }

    render() {
        const { userRoleTemplate, isFetching } = this.props;
        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};
        const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        this.isTableSorted = Object.keys(sortedInfo).length !== 0;
        const deleteView = this.isViewDeletedItemsChecked;
        const isDragDisabled = this.titleSearchString !== undefined || this.state.status !== undefined || this.isViewDeletedItemsChecked !== undefined
        const formFields = [
            {
                label: "Title",
                type: CONFIG.formFieldTypes.INPUT,
                key: "purpose",
                value: this.filterSort?.filter?.purpose
            }
        ];

        const adminColumns = [
            {
                title: "",
                key: "operate",
                align: "center",
                render: () => <Icon className="drag-handle" type="drag" />,
                width: "5%",
            },
        ];

        const columns = [
            {
                title: (
                    <span className={this.titleSearchString ? "filtered-column" : ""}>
                        Demo Purpose
                    </span>
                ),
                dataIndex: "purpose",
                key: "purpose",
                width: "20%",
                align: "left",
                sorter: (a, b) =>
                    a.purpose.toLowerCase().localeCompare(b.purpose.toLowerCase()),
                sortOrder: sortedInfo.columnKey === "purpose" && sortedInfo.order,
                render: (record) => {
                    return (
                        (record !== undefined && record !== null) ? record : null
                    )
                }
            },
            {
                title: (
                    <span className={this.filterStatusTypes ? "filtered-column" : ""}>
                        Status
                    </span>
                ),
                dataIndex: "isActive",
                key: "isActive",
                width: "15%",
                render: (status) => <StatusIcon status={status} />,
                align: "center",
                sorter: (a, b) => a.isActive.toString().localeCompare(b.isActive.toString()),
                sortOrder: sortedInfo.columnKey === 'isActive' && sortedInfo.order,
            },
            {
                title: "Actions",
                key: "actions",
                width: "10%",
                render: (record) => {
                    const roleTemplateDisabled = (userRoleTemplate &&
                        userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin &&
                        record.adminAccess ===
                        CONFIG.roleTemplateAccess.readOnly)
                    const isEditDisabled = roleTemplateDisabled || (record?.purpose === "Testing")

                    return (
                        <div>
                            {!deleteView &&
                                <>
                                    <Tooltip title={roleTemplateDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        <button
                                            onClick={() => {
                                                this.props.handleOnClickAction({
                                                    component: CONFIG.editComponentRoute.purpose,
                                                    tabTitle: record?.purpose,
                                                    recordId: record?.id
                                                })
                                            }}
                                            disabled={roleTemplateDisabled}
                                            className={!roleTemplateDisabled ? "link" : "link-disabled"}>
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                    <Divider type="vertical" />
                                </>
                            }
                            <>
                                {!deleteView && <Tooltip
                                    title={
                                        isEditDisabled ? CONFIG.warningMessages.noAccess : undefined
                                    }
                                    key={record.id}
                                >
                                    <button
                                        id={record.id}
                                        disabled={isEditDisabled}
                                        className={!isEditDisabled ? "link" : "link-disabled"}
                                        onClick={() => this.handleDeleteRestore(record.id, false)}
                                    >
                                        <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span>
                                    </button>

                                </Tooltip>}
                                {deleteView &&
                                    <>
                                        <button
                                            id={record.id}
                                            disabled={isEditDisabled}
                                            className={!isEditDisabled ? "link" : "link-disabled"}
                                            onClick={() => this.handleDeleteRestore(record.id, true)}
                                        >
                                            <span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </>
                                }
                            </>
                        </div >
                    );
                },
                align: "center",
            },
        ];

        const { demoPurposes } = this.props;
        const { showFilter } = this.state
        return (
            <div>
                <React.Fragment>
                    <Row className="vertical-spacing">
                        <Col xl={4} sm={6} xs={24}>
                            <StatusFilter id="demo-purpose-status-filter" disabled={this.filterSort?.filter?.viewDeletedItems} onChange={this.handleStatusChange} value={this.state.status} />
                        </Col>
                        <Col xl={2} sm={6} xs={24} className="left-spacing">
                            <Button className="primary-action-button-filled" onClick={() => {
                                this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.purpose,
                                    tabTitle: "Create"
                                })
                            }}>New
                                <Icon type="plus-circle" />
                            </Button>
                        </Col>
                        <Col xl={1} sm={2} xs={2} className="float-right">
                            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                                <NeoIcon title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                            </Dropdown>
                        </Col>
                        <Col xl={1} sm={2} xs={2} className="float-right">

                            <NeoIcon className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Col>
                        <Col xl={1} sm={2} xs={2} className="float-right">
                            <NeoIcon className="clickable" onClick={() => this.setState(prevState => ({
                                showFilter: !prevState.showFilter
                            }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Col>
                    </Row>
                    <div className="content-container responsive-container">
                        <Row>
                            {this.state.showFilter &&
                                <FilterPanel
                                    label='View Deleted Demo Purpose'
                                    formFields={formFields}
                                    panelHeader="Filters"
                                    handleClear={this.handleClear}
                                    handleFilter={this.handleFilter}
                                    id="demo-purpose-filter-panel"
                                    viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                                />}
                        </Row>
                        {isUserSuperAdmin && this.state.status === undefined && this.renderUpdateButton()}
                        <Row>
                            <h3>{this.isViewDeletedItemsChecked ? 'List of Deleted Demo Purpose' : this.state.status ? 'List of Active Demo Purpose' : this.state.status === false ? 'List of Inactive Demo Purpose' : 'List of All Demo Purpose'}</h3>
                            <div ref={this.tableRef}>
                                <DragListView {...this.dragProps}>
                                    <Table
                                        size='middle'
                                        id="demo-purpose-table"
                                        className="vertical-spacing"
                                        columns={
                                            isUserSuperAdmin && isDragDisabled === false
                                                ? [...adminColumns, ...columns]
                                                : columns
                                        }
                                        dataSource={demoPurposes}
                                        bordered
                                        pagination={false}
                                        rowKey={(record) => record.id}
                                        loading={isFetching}
                                        onChange={this.handleChange}
                                    />
                                </DragListView>
                            </div>
                        </Row>
                        {isUserSuperAdmin && this.renderUpdateButton()}
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

const mapStateToProps = ({ user, demoPurpose, tabsLayout }) => {
    return {
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isFetching: demoPurpose.isFetching,
        demoPurposes: demoPurpose.demoPurposes,
        isReordered: demoPurpose.isReordered,
        isEdit: demoPurpose.isEdit,
        currentFilter: demoPurpose.currentFilter,
        status: demoPurpose.status,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        openedTabs: tabsLayout.panes
    };
};

export default connect(mapStateToProps, {
    selectTitle,
    toggleBackButton,
    toggleIsFetchingPurposeFlag,
    fetchAllPurposes,
    reorderDemoPurposes,
    putDemoPurposesOrder,
    clearDemoPurpose,
    deletePurpose,
    restorePurpose,
    exportTable,
    refreshDemoPurpose,
    handleOnClickAction,
    currentDemoPurposeFilter
})(ManageDemoPurpose);
