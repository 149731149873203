import React, { Component } from "react";
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { Icon, Button } from "@avaya/neo-react"
import { Col, Row, Rate, Spin } from "antd"
import { connect } from 'react-redux';
import cardSidePattern from "../../assets/images/darkbluepattern.png";
import { roundToNearestHalf } from "../../utils/strings"
import EZDemoFeedbackModal from "../common/EZDemoFeedbackModal";
import { analytics } from "../../firebase";
import CONFIG from "../../config";
import { LoadingOutlined } from '@ant-design/icons';
import NoData from '../common/NoData';
import {
    fetchPopularEzDemos,
    clearDashboardEZDemos,
    audit,
    showUserDashboardEZDemosSection
} from '../../actions';
class DashboardPopularEZDemos extends Component {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
            showFeedbackModal: false,
            currentViewedEZDemo: null,
            feedbackAuditId: null,
            loading: true
        }
    }

    async componentDidMount() {
        this.fetchOnMount();
    }

    fetchOnMount = async () => {
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        this.props.clearDashboardEZDemos()
    }

    onLeftArrowClick = () => {
        const right = document.querySelector(".ez-demo-dashboard-scrollable-div");
        right.scrollBy(-350, 0);
    }

    onRightArrowClick = () => {
        const left = document.querySelector(".ez-demo-dashboard-scrollable-div");
        left.scrollBy(350, 0);
    }

    handleLinkClick = async (value) => {
        let item = value;
        item = { ...item, fileName: item?.isFile ? item.link?.match(CONFIG.regEx.getFileName)?.[0] : item?.link };

        const auditId = await this.props.audit(item?.id, value?.demo?.id, value?.solutionType?.id)

        setTimeout(() => {
            this.setState({
                showFeedbackModal: true,
                feedbackAuditId: auditId,
                currentViewedEZDemo: item,
            })
        }, 3000)

        /* Firebase Analytics Log Event */
        const { demoGuide: {
            name: eventName,
            parameters: eventParams
        } } = CONFIG.firebaseEvents;
        let parameters = {};
        Object.keys(eventParams).forEach(paramKey => {
            parameters[paramKey] = item[eventParams[paramKey]]
        });
        analytics().logEvent(eventName, parameters);

        window.open(item.link, '_blank');
    }

    render() {
        const { isEzDemoRatingEnabled, popularEzDemos,loading } = this.props;
        if (popularEzDemos?.length === 0) return null;
        const showArrows = popularEzDemos?.length > 2;
        return (<>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading}>
            {(popularEzDemos && popularEzDemos?.length>0) ? <div class="container">
                <span className='dashboard-schedules-category-heading left-spacing'><Icon icon="presentation-only" style={{ fontSize: "20px", paddingRight: "10px" }} />Popular EZ Demos</span>
                
                <div className={showArrows ? "dashboard-ez-demo" : ''}>
                    {showArrows && <LeftCircleFilled onClick={this.onLeftArrowClick} className="left-scroll-arrows-ez-demo" style={{ fontSize: "30px" }} />}
                    <div className="ez-demo-dashboard-scrollable-div" ref={this.containerRef}>
                        {popularEzDemos && popularEzDemos?.map((eachEZDemo, index) => {
                            return (<div className="ez-demo-dashboard-card"
                                style={{ height: `${isEzDemoRatingEnabled ? `120px` : `100px`}` }}
                            >
                                <Col span={1}>
                                    <img
                                        src={cardSidePattern} // Replace with the actual image URL
                                        className="ez-demo-dashboard-card-border"
                                        alt="ez-demo-card-border"
                                    />
                                </Col>
                                <Col span={23} className="ez-demo-dashboard-card-col2">
                                    <p class="dashboard-most-used-demo-title">{eachEZDemo?.title}</p>
                                    {isEzDemoRatingEnabled && (eachEZDemo?.ratingCount > 0) && <Rate style={{ color: Math.round(eachEZDemo?.rating) <= 2 ? "#ff4545" : "#f7a032" }} disabled allowHalf value={roundToNearestHalf(eachEZDemo?.rating)} />}

                                    <Row className="ez-dashboard-start-button">
                                        <div>
                                            <Button onClick={() => this.handleLinkClick(eachEZDemo)} className="vertical-spacing float-right" animation="none" size="compact" status="default" >Start Demo</Button>
                                        </div>
                                    </Row>
                                </Col>
                            </div>)
                        })}
                    </div>
                    {showArrows && <RightCircleFilled onClick={this.onRightArrowClick} className="right-scroll-arrows-ez-demo" style={{ fontSize: "30px" }} />}
                </div>
                {this.state.showFeedbackModal && <EZDemoFeedbackModal
                    showFeedbackModal={this.state.showFeedbackModal}
                    feedbackAuditId={this.state.feedbackAuditId}
                    fetchOnMount={this.fetchOnMount}
                    title={this.state.currentViewedEZDemo?.title}
                    handleCancel={() => this.setState({ showFeedbackModal: false })}
                />}
            </div> : <NoData size="exsm" />}
            </Spin>
        </>
        );
    }
}

const mapStateToProps = ({ user, dashboard,ezDemos }) => {
    return {
        isEzDemoRatingEnabled: user.isEzDemoRatingEnabled,
        popularEzDemos: dashboard.popularEzDemos,
        loading: ezDemos?.loading
    }
}

export default connect(mapStateToProps, { fetchPopularEzDemos, audit, clearDashboardEZDemos, showUserDashboardEZDemosSection })(DashboardPopularEZDemos);