import { Collapse, Col, Row, Modal, Form, Input, Tag, Divider } from 'antd'
import React from 'react';
import { Dropdown } from 'antd5'
import { connect } from 'react-redux';
import CONFIG from '../../../config';
import moment from 'moment-timezone';
import { Icon, Button } from "@avaya/neo-react"
import { getUserInformationCard } from "../../../utils/getUserInformationCard";
import {
    openLinkInNewTab,
    approveOrDeclineScheduleRequest
} from '../../../actions'
const { TextArea } = Input;
const { Panel } = Collapse;

class ScheduleApprovals extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            showMore: true,
            showCommentModal: false,
            clickedRecord: undefined
        };
    }

    toggleCollapse = () => {
        this.setState(prevState => ({
            collapsed: !prevState.collapsed,
        }));
        setTimeout(() => {
            this.setState(prevState => ({
                showMore: !prevState.showMore,
            }));
        }, 100);
    };


    renderRowInformation = (title, data) => {
        return (
            data && <div className=" font-12px">
                <span className="bold">{title}: </span>{data}
            </div>
        )
    }
    handleSubmit = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            else {
                let userComment = this.props.form.getFieldValue('comment')
                userComment = userComment?.trim();
                let payload = {
                    ...this.state.clickedRecord,
                    "status": this.state.typeOfApprove,
                    "approversComment": userComment?.length > 0 ? userComment : undefined,
                    "scheduleId": this.state.clickedRecord?.schedule?.id,
                    "createdDate": this.state.clickedRecord?.createdTime
                }
                await this.props.approveOrDeclineScheduleRequest(payload)
                await this.setState({ showCommentModal: false })
                setTimeout(() => this.setState({
                    clickedRecord: undefined,
                    modalButtonText: undefined,
                    typeOfApprove: undefined
                }), 100)
                this.props.form.resetFields();
                this.props.fetchAdminApprovals();
            }
        })
    }

    handleCancel = async () => {
        await this.setState({ showCommentModal: false })
        setTimeout(() => this.setState({
            clickedRecord: undefined,
            modalButtonText: undefined,
            typeOfApprove: undefined
        }), 100)
        this.props.form.resetFields();
    }

    handleActionButton = (typeOfApprove, approvalObj) => {
        this.setState({
            showCommentModal: true,
            clickedRecord: approvalObj,
            modalButtonText: typeOfApprove === "DECLINED" ? "Decline" : "Approve",
            typeOfApprove: typeOfApprove
        })
    }

    renderScheduleInformation = () => {
        const { collapsed, showMore } = this.state;
        const { demoApprovalsData, userObj, isPending } = this.props
        const { timezone, email, roleTemplate } = userObj || []
        const eachCard = demoApprovalsData;

        const status = eachCard.requestStatus === "APPROVED" || eachCard.requestStatus === "APPROVED_SA";
        const isCompleted = eachCard.requestStatus?.toLowerCase()?.includes("approved");
        // Compute each Object

        let scheduleRequestDetails = {
            "Reference No": eachCard?.schedule?.referenceNo,
            "Requested by": getUserInformationCard(eachCard?.schedule?.createdBy, eachCard?.schedule?.createdBy?.fullName),
            "Requested on": moment.tz(eachCard?.schedule?.createdTime, timezone).format(CONFIG.dateFormats.userDateTimeWithTZ2),
        }

        let completedApprovalsObject = {
            [isCompleted ? "Completed By" : "Declined By"]: getUserInformationCard(eachCard?.resolvedBy, eachCard?.resolvedBy?.fullName),
            [isCompleted ? "Completed On" : "Declined On"]: moment.tz(eachCard?.resolvedTime, timezone).format(CONFIG.dateFormats.userDateTimeWithTZ2)
        }

        let scheduleTimeDetails = {
            "Schedule Start Time": moment.tz(eachCard?.schedule?.startTime, timezone).format(CONFIG.dateFormats.user),
            "Schedule End Time": moment.tz(eachCard?.schedule?.endTime, timezone).format(CONFIG.dateFormats.user),
        }

        let attributesDetails = {
            "Manager's Email": eachCard?.schedule?.managerEmail,
            "Regional Prime Email": eachCard?.schedule?.regionalPrimeEmail,
            "Build Status": eachCard?.schedule?.buildStatus ? <Tag color={CONFIG.demoBuildStatusTagColors[eachCard?.schedule?.buildStatus]}>{eachCard?.schedule?.buildStatus}</Tag> : null,
            "Assignee": eachCard?.schedule?.assignedUser?.fullName,
            "Selected Capabilities": eachCard?.schedule?.parameters ? eachCard?.schedule?.parameters?.replaceAll("\\", ", ") : null,
            "Demo Purpose": eachCard?.schedule?.demoPurpose?.purpose,
        }

        let schedulerProfileDetails = {
            "Country": eachCard?.schedule?.country?.name,
            "Region": eachCard?.schedule?.region,
            "INC/IT No": eachCard?.schedule?.incNo,
            "Tenant Name": eachCard?.schedule?.tenantName,

        }

        let demoPurposeAttributes = {};
        eachCard?.schedule?.purposeAttributesSchedule?.forEach((item) => {
            demoPurposeAttributes[item?.demoPurposeAttribute?.attributeLabel] = item?.attributeValue
        });

        const scheduleAttributesDetails = { ...attributesDetails, ...demoPurposeAttributes }


        const isSuperAdmin = roleTemplate.templateType === CONFIG.roleTypes.superAdmin
        const isUserPartOfApprovalList = eachCard?.approversList?.map(eachUser => eachUser.approversEmailId?.toLowerCase()).includes(email?.toLowerCase());
        const showApproveButton = isUserPartOfApprovalList
        const showDeclineButton = (isSuperAdmin) || (isUserPartOfApprovalList)
        const items = [
            {
                key: 'details',
                openLinkInNewTab,
                label: (<span className="font-12px full-width-options" onClick={() => this.props.openLinkInNewTab("Schedule Approval Details", `/schedule-approvals/details/${eachCard?.schedule?.id}`)}><Icon icon="forward-skip" style={{ fontSize: "19px", paddingRight: "8px" }} />Go to Request</span>),
            },
            (isPending && !status) ? {
                key: 'edit',
                disabled: !showApproveButton,
                label: (<span className=" font-12px full-width-options" onClick={() => showApproveButton ? this.handleActionButton("APPROVED", eachCard) : null}><Icon icon="check" style={{ fontSize: "19px", paddingRight: "8px" }} />Approve</span>)
            } : undefined,
            (isSuperAdmin && isPending && !status) ? {
                key: 'override',
                label: (<span className=" font-12px full-width-options" onClick={() => this.handleActionButton("APPROVED_SA", eachCard)}><Icon icon="message-read" style={{ fontSize: "19px", paddingRight: "8px" }} />Override Approve</span>)
            } : undefined,
            (isPending && !status) ? {
                key: 'delete',
                disabled: !showDeclineButton,
                label: (<span className="font-12px full-width-options " onClick={() => this.handleActionButton("DECLINED", eachCard)}><Icon icon="close" style={{ fontSize: "19px", paddingRight: "8px" }} />Decline</span>)
            } : undefined
        ];

        const eachCardJSX = (<div >
            <Collapse
                bordered={false}
                className='admin-dashoard-requests-collapse'
                activeKey={collapsed ? [] : ['1']}
                expandIconPosition="right"
            >
                <Panel
                    header={
                        <div>
                            <Row>
                                <Col span={17}>
                                    <Row> <span className='dashboard-requests-sub-heading'>Lab & POC Request</span></Row>
                                    <span className='medium-text dashboard-schedules-demo-title '>{demoApprovalsData?.demo?.title}</span>
                                    <Row className='baseline-flex'>
                                        <Col span={1}>
                                            <Icon icon="screenshare-on" style={{ fontSize: "18px" }} />
                                        </Col>
                                        <Col style={{ paddingLeft: "7px" }} span={23}>
                                            {Object.entries(scheduleRequestDetails).map(([key, value]) => (
                                                <div>
                                                    {this.renderRowInformation(key, value)}
                                                </div>
                                            ))}
                                            {
                                                !isPending ?
                                                    Object.entries(completedApprovalsObject).map(([key, value]) => (
                                                        <div>
                                                            {this.renderRowInformation(key, value)}
                                                        </div>
                                                    ))
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                    {showMore && (
                                        <div className="font-12px vertical-spacing-3 view-more-link" onClick={this.toggleCollapse}>
                                            {showMore ? 'View More' : 'View Less'}
                                        </div>
                                    )}
                                </Col>
                                <Col className="dashboard-daily-testing-row" span={7}>
                                    <Dropdown
                                        className='schedule-approvals-dashboard-action-menu'
                                        trigger="click"
                                        menu={{ items }}
                                        placement="bottomLeft"
                                        arrow
                                    >
                                        <Icon icon="ellipses-vertical" className='pointer' style={{ fontSize: "19px", color: "#1B77AF" }} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    }
                    key="1"
                >
                    <div >
                        <Row className='vertical-spacing-3 baseline-flex'>
                            <Col span={1}>
                                <Icon icon="calendar" style={{ fontSize: "18px" }} />
                            </Col>
                            <Col span={23}>
                                {Object.entries(scheduleTimeDetails).map(([key, value]) => (
                                    <div>
                                        {this.renderRowInformation(key, value)}
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        {Object.values(scheduleAttributesDetails).some(value => !!value) && <Row className='vertical-spacing-3 baseline-flex'>

                            <Col span={1}>
                                <Icon icon="user" style={{ fontSize: "18px" }} />
                            </Col>
                            <Col span={23}>
                                {Object.entries(scheduleAttributesDetails).map(([key, value]) => (
                                    <div>
                                        {this.renderRowInformation(key, value)}
                                    </div>
                                ))}
                            </Col>
                        </Row>}

                        {Object.values(schedulerProfileDetails).some(value => !!value) && <Row className='vertical-spacing-3 baseline-flex'>
                            <Col span={1}>
                                <Icon icon="global" style={{ fontSize: "18px" }} />
                            </Col>
                            <Col span={23}>
                                {Object.entries(schedulerProfileDetails).map(([key, value]) => (
                                    <div>
                                        {this.renderRowInformation(key, value)}
                                    </div>
                                ))}
                            </Col>
                        </Row>}
                        <div className="vertical-spacing-3 view-more-link font-12px" onClick={this.toggleCollapse}>
                            {showMore ? 'View More' : 'View Less'}
                        </div>
                    </div>
                </Panel>
            </Collapse>
            <Divider style={{ margin: 0, marginBottom: "5px" }} />
        </div >);

        return eachCardJSX;
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                {this.renderScheduleInformation()}
                <Modal
                    title="Confirmation"
                    visible={this.state.showCommentModal}
                    onCancel={() => this.setState({ showCommentModal: false })}
                    // onOk={this.handleSubmit}
                    footer={[
                        <Button variant="secondary" size="compact" className="left-spacing-3 avaya-default-button" onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <Button status='default' size="compact" className="left-spacing-3 avaya-default-button" onClick={this.handleSubmit} >
                            {`${this.state.modalButtonText}`}
                        </Button>,
                    ]}
                >
                    <Form.Item label={`Comment ${this.state.modalButtonText !== "Decline" ? " (Optional)" : ""}`}>
                        {getFieldDecorator("comment", {
                            rules: [
                                {
                                    required: this.state.modalButtonText === "Decline",
                                    message: "Comment is mandatory!",
                                }
                            ],
                        })(<TextArea />)}
                    </Form.Item>

                </Modal>
            </div>
        )
    }
}

const ScheduleApprovalsRef = Form.create()(ScheduleApprovals);

export default connect(
    null,
    {
        openLinkInNewTab,
        approveOrDeclineScheduleRequest
    }
)(ScheduleApprovalsRef);