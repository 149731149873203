import React from 'react';
import { connect, batch } from 'react-redux';
import { Form, Divider, Row, Collapse, Spin } from 'antd';
import ReportBasicDetails from './ReportBasicDetails';
import RefineReport from '../common/RefineReport';
import moment from 'moment-timezone';
import {
    toggleBackButton,
    clearSaveReports,
    clearReportsForm,
    fetchEntityTypes,
    selectTitle,
    toggleIsFetchingReportForm,
    fetchAdminRoleTemplates,
    updateCustomReportsCurrentPage,
    fetchRoleTemplates,
    postReport,
    fetchReport,
    currentReportFilter,
    updateReportDetails,
    updateTabsLocalStorage,
    updatedSelectedColumns,
    toggleEntityHasChanged,
    resetReportForm,
    toggleHasUnsavedChangesFlag,
    deleteTab,
    fetchTrendingDemos
} from '../../actions'
import FormActionButtons from '../common/FormActionButtons';
import ReportOutput from '../common/ReportOutput';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';
import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
import history from '../../history';
import { checkRoleTemplateChanges } from "../../utils/status";
import TrendingDemos from "../Dashboard/TrendingDemos";
const { Panel } = Collapse;

const ReportForm = Form.create({ name: 'report_form' })(
    class ReportForm extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                displayReportOutput: true,
                isFilterRemoved: false,
                entityChanged: false,
                formLoading: true,
                isClone: false,
                hasReportFormChanged: true,
                entity: undefined
            }
        }
        shouldCreateFilterSort = true //For Calculating Filtersort object in ComponentDidMount() of ReportOutput
        isEditMode = this.props.match.params.reportId !== undefined && window.location.pathname?.includes("/reports/edit")
        isGeneralReport = this.props.match.params.reportType === "standard";
        isCloneReport = window.location.pathname?.includes("/reports/clone");
        reportId = this.props.match.params.reportId

        setSelectedColumns = (selectedColumns = []) => {
            const isClone = this.state.isClone;
            const { reportId } = this.props.match.params;
            const id = reportId !== undefined ? reportId : "-1";
            this.props.updatedSelectedColumns(selectedColumns, id, isClone, this.isGeneralReport);
        }

        toggleShouldCreateFilterSort = (flag) => {
            this.shouldCreateFilterSort = flag;
        }

        toggleDisplayReportOutput = (flag) => {
            this.setState({
                displayReportOutput: flag
            })
        }

        async componentDidMount() {
            const { reportId } = this.props.match.params;
            const { previousTabs } = this.props;
            this.props.selectTitle("Manage Reports");
            const currentPath = window.location.pathname;
            const isGeneralReport = this.props.match.params.reportType === "standard";
            this.props.toggleBackButton(false);
            const { userRoleTemplate } = this.props;
            const isCloneReport = window.location.pathname?.includes("/reports/clone");
            this.setState({
                isClone: isCloneReport
            })
            const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
            document.getElementById('tab-pane').scrollIntoView()
            const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;

            if (isTabAlreadyOpen({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                if (isTabRestoredFromLocalStorage({ openedTabs: previousTabs, currentComponentPath: currentPath })) {
                    batch(async () => {
                        const entityTypes = await this.props.fetchEntityTypes();
                        fetchAllRoleTemplates()
                            .then(async () => {
                                if (reportId) {
                                    await this.props.toggleIsFetchingReportForm(true);
                                    await this.props.fetchReport(reportId, false, isGeneralReport, null, this.isCloneReport, entityTypes);
                                }
                                else {
                                    await this.props.updateReportDetails({
                                        reportId: this.props.match.params?.reportId !== undefined
                                            ? this.props.match.params?.reportId
                                            : "-1",
                                        reportData: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                                        isClone: this.state.isClone,
                                        isGeneralReport: isGeneralReport,
                                        entites: entityTypes
                                    });
                                }
                            });
                        await this.props.updateTabsLocalStorage(currentPath);
                        await this.props.updateReportDetails({
                            reportId: this.props.match.params?.reportId !== undefined
                                ? this.props.match.params?.reportId
                                : "-1",
                            reportData: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                            isClone: this.state.isClone,
                            isGeneralReport: isGeneralReport,
                            entites: entityTypes
                        });
                    });
                }
                this.setState({ hasReportFormChanged: false })
                setTimeout(() => {
                    this.setState({
                        formLoading: false,
                    })
                }, 3500)
            } else {
                await this.props.resetReportForm(reportId, this.state.isClone, isGeneralReport);
                batch(async () => {
                    this.props.toggleBackButton(false);
                    fetchAllRoleTemplates()
                        .then(async () => {
                            const entityTypes = await this.props.fetchEntityTypes();
                            if (reportId) {
                                await this.props.toggleIsFetchingReportForm(true);
                                await this.props.fetchReport(reportId, false, isGeneralReport, null, this.isCloneReport, entityTypes);
                            }
                            else {
                                await this.props.updateReportDetails({
                                    reportId: this.props.match.params?.reportId !== undefined
                                        ? this.props.match.params?.reportId
                                        : "-1",
                                    reportData: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                                    isClone: this.state.isClone,
                                    isGeneralReport: isGeneralReport,
                                    entites: entityTypes
                                });
                            }
                            this.setState({ hasReportFormChanged: false })
                            setTimeout(() => {
                                this.setState({
                                    formLoading: false,
                                })
                            }, 3500)
                        });
                });
            }
        }

        componentWillUnmount() {
            const { reportTabDetails } = this.props;
            const isClone = this.state.isClone;
            const { reportId } = this.props.match.params;
            const id = reportId !== undefined ? reportId : "-1";
            const currentReportData = reportTabDetails?.find(item => (
                (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isClone) && (item?.isGeneralReport === this.isGeneralReport)));
            const { originalDetails } = currentReportData || [];
            const filters = this.getFilterObj();
            const filter = Object.fromEntries(
                Object.entries(filters).filter(([key, value]) => {
                    return key !== 'undefined' && key !== undefined && value?.operator !== undefined && value?.oprVal !== undefined;
                })
            );
            const filterSortObject = { filter };
            if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: window.location.pathname })) {
                this.props.updateReportDetails({
                    reportId: this.props.match.params?.reportId !== undefined
                        ? this.props.match.params?.reportId
                        : "-1",
                    reportData: { ...this.props.form.getFieldsValue(), filterSortObject, default: this.isGeneralReport, assignedRoleTemplates: undefined, originalDetails: originalDetails },
                    isClone: this.state.isClone,
                    isGeneralReport: this.isGeneralReport
                });
            }
            else {
                const isClone = this.state.isClone;
                const { reportId } = this.props.match.params;
                const id = reportId !== undefined ? reportId : "-1";
                this.props.resetReportForm(id, isClone, this.isGeneralReport);
            }
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
            // this.props.clearReportsForm();
        }

        getOperatorValue = (columnType, value, operator) => {
            const { timeZoneName } = this.props;
            switch (columnType) {
                case CONFIG.formFieldTypes.MULTISELECT:
                case CONFIG.formFieldTypes.PACKAGE:
                case CONFIG.formFieldTypes.AUTOCOMPLETE:
                    return value.join('\\\\')
                case CONFIG.formFieldTypes.DATE:
                    if (Array.isArray(value)) {
                        const startTime = value[0].tz(timeZoneName, true).startOf('day').toISOString();
                        const endTime = value[1].tz(timeZoneName, true).endOf('day').toISOString();
                        return startTime + '@' + endTime
                    }
                    return value.tz(timeZoneName, true).startOf('day').toISOString();
                case CONFIG.formFieldTypes.NUMBER:
                    return value?.toString();
                case CONFIG.formFieldTypes.MONTH:
                    if (operator === "<" || operator === ">=") return value.startOf('month').startOf('day').toISOString();
                    else if (operator === "<=" || operator === ">") return value.endOf('month').endOf('day').toISOString();
                    else return value.startOf('month').startOf('day').toISOString() + "@" + value.endOf('month').endOf('day').toISOString();
                case CONFIG.formFieldTypes.DURATION:
                    let minutes = value.Days ? value.Days * 24 * 60 : 0
                    minutes += value.Hours ? value.Hours * 60 : 0
                    minutes += value.Minutes ? value.Minutes : 0
                    return minutes.toString()

                default:
                    return value
            }
        }

        getFilterObj = () => {
            const isGeneralReport = this.props.match.params.reportType === "standard";
            const { timeZoneName } = this.props;
            const { getFieldValue } = this.props.form;
            const tableName = getFieldValue('entity') && JSON.parse(getFieldValue('entity')).tableName;
            let filter = {};
            const values = this.props.form.getFieldsValue()
            if (!isGeneralReport) {
                if (values.keys || values?.startEndTime?.length > 0) {
                    filter =
                        tableName === "Daily Test Report" ? {
                            ...(values.startEndTime?.length > 0 && {
                                createdTime: {
                                    operator: 'IN',
                                    oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() + "@" + values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                                }
                            }),
                        }
                            :
                            {
                                ...(values.startEndTime?.length > 0 && {
                                    startTime: {
                                        operator: '>=',
                                        oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() : undefined
                                    }
                                }),
                                ...(values.startEndTime?.length > 0 && {
                                    endTime: {
                                        operator: '<',
                                        oprVal: values.startEndTime ? values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                                    }
                                }),
                            };
                    if (values.keys) {
                        values.keys.forEach(k => {
                            filter = {
                                ...filter,
                                [values?.names?.[(k * 3) + 1] && JSON.parse(values.names[(k * 3) + 1]).columnKey]: {
                                    operator: values.names?.[(k * 3) + 2],
                                    oprVal: values.names?.[(k * 3) + 1] && values.names[(k * 3) + 3] && values.names[(k * 3) + 2] && this.getOperatorValue(JSON.parse(values.names[(k * 3) + 1])?.columnType, values.names[(k * 3) + 3], values.names[(k * 3) + 2])
                                },
                            }
                        })
                    }
                }
            }
            else {
                filter = {
                    ...filter,
                    ...(values.startEndTime?.length > 0 && {
                        startTime: {
                            operator: '>=',
                            oprVal: values.startEndTime ? values.startEndTime[0].tz(timeZoneName, true).startOf('day').toISOString() : undefined
                        }
                    }),
                    ...(values.startEndTime?.length > 0 && {
                        endTime: {
                            operator: '<',
                            oprVal: values.startEndTime ? values.startEndTime[1].tz(timeZoneName, true).endOf('day').toISOString() : undefined
                        }
                    })
                }
            }
            return filter
        }

        handleSubmit = () => {
            this.props.form.validateFields(async (err, values) => {
                if (err) {
                    pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
                    return
                }
                if (!err) {
                    var filter = this.getFilterObj() || {}
                    this.props.toggleIsFetchingReportForm(true)
                    const response = await this.props.postReport(
                        this.reportId ? this.reportId : undefined,
                        { ...values, filter },
                        this.props.match.params.reportType === "standard",
                        null,
                        this.isCloneReport
                    );
                    if (response) {
                        this.setState({
                            formLoading: true,
                        })
                    }
                    setTimeout(() => {
                        this.setState({
                            formLoading: false,
                        })
                        this.forceUpdate();
                    }, 1000)
                }
            });
        }

        handleReset = async () => {
            await this.props.deleteTab(this.props.activeKey, true)
            history.replace(this.props.activeKey)
            if (this.props.openedTabs?.length === 0) {
                history.replace('/getStarted');
                // this.props.selectTitle(this.getTitle());
            }
        }

        renderFormButtons = () => {
            return (
                <Row className="right-align">
                    <FormActionButtons handleSubmit={this.handleSubmit} isDisabled={!this.hasReportChanged} handleCancel={this.handleReset} cancelText={"Discard & Close"} okText={this.isEditMode && !this.isCloneReport ? "Update" : "Create"} />
                </Row>
            );
        }

        handleEntityChange = async (value) => {
            const isClone = this.state.isClone;
            const { reportId } = this.props.match.params;
            const id = reportId !== undefined ? reportId : "-1";
            this.props.toggleEntityHasChanged(true, id, isClone, this.isGeneralReport);
            this.setState({ entity: value })
        }

        clearEntityFlag = async () => {
            const isClone = this.state.isClone;
            const { reportId } = this.props.match.params;
            const id = reportId !== undefined ? reportId : "-1";
            this.props.toggleEntityHasChanged(false, id, isClone, this.isGeneralReport)
        }

        updateFilterSortObject = (data) => {
            this.filterSortObject = data
        }

        checkEqualFilterSort = (oldFilter, currentFilter) => {
            const keys1 = oldFilter ? Object?.keys(oldFilter) : [];
            const keys2 = currentFilter ? Object?.keys(currentFilter) : [];

            if (keys1?.length !== keys2?.length) {
                return false;
            }

            for (const key of keys1) {
                const val1 = oldFilter[key];
                const val2 = currentFilter[key];
                const areObjects = this.isObject(val1) && this.isObject(val2);
                if (
                    (areObjects && !this.checkEqualFilterSort(val1, val2)) ||
                    (!areObjects && val1 !== val2)
                ) {
                    return false;
                }
            }
            return true;
        }

        isObject = (object) => {
            return object != null && typeof object === 'object';
        }

        getReportObject = (values, currentReport) => {
            return {
                name: values.name,
                description: values.description,
                roleTemplates: currentReport?.roleTemplates,
                filterSortObject: this.getFilterObj() && JSON.stringify({ filter: this.getFilterObj() }),
                filterTable: values.entity && JSON.parse(values.entity),
                isActive: values.isActive,
                viewColumnsJson: (values?.columns && values?.columns?.length > 0) ? JSON.stringify(values.columns?.map(column => column)) : currentReport?.reportColumns
            }
        }

        componentDidUpdate() {
            const { openedTabs, activeKey } = this.props;
            this.hasReportChanged = (this.isEditMode && !this.isCloneReport) ? this.checkIfReportHasChanged() : true;
            const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
            if (!hasUnsavedChanges && this.hasReportChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasReportChanged);
            else if (hasUnsavedChanges && !this.hasReportChanged)
                this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasReportChanged);
        }

        checkIfReportHasChanged = () => {
            const { reportTabDetails } = this.props;
            const isClone = this.state.isClone;
            const { reportId } = this.props.match.params;
            const id = reportId !== undefined ? reportId : "-1";
            const currentReport = reportTabDetails?.find(item => (
                (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isClone) && (item?.isGeneralReport === this.isGeneralReport)));
            const formValues = this.getReportObject(this.props.form.getFieldsValue(), currentReport?.details);
            const oldReport = currentReport?.originalDetails;
            const report = { ...currentReport?.details, ...formValues };
            const currentViewColumnJson = typeof report?.viewColumnsJson === 'string' ? report?.viewColumnsJson : JSON.stringify(report?.viewColumnsJson);
            // oldReport = { ...oldReport, filterSortObject: JSON.stringify(this.filterSortObject) } @Kalpitha's code
            const { userRoleTemplate } = this.props;
            const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
            let hasChanged = false;
            let hasFilterSortObjChanged = true;
            const oldRepFiltObj = oldReport?.filterSortObject
            const newRepFiltObj = report?.filterSortObject && JSON.parse(report?.filterSortObject)
            if (this.checkEqualFilterSort(oldRepFiltObj, newRepFiltObj)) {
                hasFilterSortObjChanged = false;
            }
            hasChanged = report?.name?.trim() !== oldReport?.name?.trim()
                || report?.isActive !== oldReport?.isActive
                || report?.description?.trim() !== oldReport?.description?.trim()
                || hasFilterSortObjChanged
                || (this.isGeneralReport ? false : currentViewColumnJson?.trim() !== JSON.stringify(oldReport?.viewColumnsJson)?.trim())
                || (this.isGeneralReport ? false : report?.filterTable?.tableName !== oldReport?.filterTable?.tableName)
                || checkRoleTemplateChanges(oldReport?.roleTemplates, report?.roleTemplates, isSuperAdminUser)
            if (hasChanged) return hasChanged;
            return hasChanged;
        }

        filterRemoved = () => {
            this.setState({ isFilterRemoved: true })
        }

        getCurrentReportData = () => {
            const { reportTabDetails } = this.props;
            const isClone = this.state.isClone;
            const { reportId } = this.props.match.params;
            const id = reportId !== undefined ? reportId : "-1";
            return reportTabDetails?.find(item => (
                (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isClone) && (item?.isGeneralReport === this.isGeneralReport)))?.details;
        }

        childUpdated = (flag) => {
            this.setState({ childUpdated: flag });
            this.forceUpdate();
        }

        handleTrendingDemosRunReport = (graphType) => {
            const trendingStartTime = this.props?.form?.getFieldsValue()?.startEndTime[0].toISOString();
            const trendingEndTime = this.props?.form?.getFieldsValue()?.startEndTime[1].toISOString();
            const timeLine = this.getTimeLine(trendingStartTime, trendingEndTime);
            this.props.fetchTrendingDemos({ graphType, startDate: trendingStartTime, timeline: timeLine, endDate: trendingEndTime })
        }

        getTimeLine = (startTime, endTime) => {
            let timeLine;
            const dateDiff = moment.tz(endTime, this.props.timeZoneName).diff(moment.tz(startTime, this.props.timeZoneName), 'days');
            if (dateDiff <= 7) {
                timeLine = CONFIG.trendingDemosTimeline[0]
            } else if (dateDiff > 7 && dateDiff <= 31) {
                timeLine = CONFIG.trendingDemosTimeline[1]
            } else {
                timeLine = CONFIG.trendingDemosTimeline[2]
            }
            return timeLine;
        }

        render() {
            const currentReportData = this.getCurrentReportData();
            const { getFieldValue } = this.props.form;
            const isTrendingDemo = this.isGeneralReport && (currentReportData?.name === CONFIG.generalReportNames.trendingDemos || currentReportData?.parentReport?.name === CONFIG.generalReportNames.trendingDemos) ? true : false;
            const selectedColumns = currentReportData?.selectedColumns || [];
            const entityChanged = currentReportData?.entityChanged || false;
            this.isEditMode = currentReportData?.id > 0 ? true : false;
            this.reportId = !this.isCloneReport ? currentReportData?.id ? currentReportData?.id : this.props.match.params.reportId : undefined
            const tableName = getFieldValue('entity') && JSON.parse(getFieldValue('entity')).tableName;
            const showRangePicker = CONFIG.reportDatePickerEntities.includes(tableName);
            const trendingStartTime = currentReportData?.filterSortObject?.filter?.startTime?.oprVal && moment.tz(currentReportData?.filterSortObject?.filter?.startTime?.oprVal, this.props.timeZoneName).toISOString();
            const trendingEndTime = currentReportData?.filterSortObject?.filter?.endTime?.oprVal && moment.tz(currentReportData?.filterSortObject?.filter?.endTime?.oprVal, this.props.timeZoneName).toISOString();

            return (
                <Spin spinning={this.props.reportFormFetching || this.state.formLoading} wrapperClassName="spin-overlay">
                    <div className="content-container">
                        {this.renderFormButtons()}
                        <br />
                        <ReportBasicDetails
                            form={this.props.form}
                            isGeneralReport={this.isGeneralReport}
                            isCloneReport={this.isCloneReport}
                            params={this.props.match.params}
                            toggleDisplayReportOutput={this.toggleDisplayReportOutput}
                            setSelectedColumns={this.setSelectedColumns}
                            childUpdated={this.childUpdated}
                            formLoading={this.state.formLoading}
                            handleSelectChangeForParent={this.handleEntityChange}
                        />
                        {((getFieldValue('entity') && getFieldValue('name')) && (showRangePicker ? getFieldValue("startEndTime") : true))
                            &&
                            <>
                                <Collapse expandIconPosition='right' defaultActiveKey="1" >
                                    <Panel key="1" isActive={true} forceRender={true} header={<span className="large-text" >Refine Report</span>}>
                                        <RefineReport
                                            form={this.props.form}
                                            isGeneralReport={this.isGeneralReport}
                                            isEditMode={this.isEditMode}
                                            setSelectedColumns={this.setSelectedColumns}
                                            isFilterRemoved={this.filterRemoved}
                                            selectedColumns={selectedColumns}
                                            toggleShouldCreateFilterSort={this.toggleShouldCreateFilterSort}
                                            shouldCreateFilterSort={this.shouldCreateFilterSort}
                                            tableName={tableName}
                                            updateFilterSortObject={this.updateFilterSortObject}
                                            params={this.props.match.params}
                                            isCloneReport={this.isCloneReport}
                                        />
                                    </Panel>
                                </Collapse>
                                <br />
                                <Divider />
                                <br />
                                {this.state.displayReportOutput && !isTrendingDemo &&
                                    <ReportOutput
                                        form={this.props.form}
                                        isGeneralReport={this.isGeneralReport}
                                        isEditMode={this.isEditMode}
                                        entityChanged={entityChanged}
                                        clearEntityFlag={this.clearEntityFlag}
                                        toggleShouldCreateFilterSort={this.toggleShouldCreateFilterSort}
                                        shouldCreateFilterSort={this.shouldCreateFilterSort}
                                        params={this.props.match.params}
                                        isCloneReport={this.isCloneReport}
                                        stateEntity={this.state.entity}
                                    />
                                }
                                {this.state.displayReportOutput && isTrendingDemo && trendingStartTime && trendingEndTime && <TrendingDemos
                                    reportName={currentReportData?.name}
                                    startTime={trendingStartTime}
                                    endTime={trendingEndTime}
                                    handleTrendingDemosRunReport={this.handleTrendingDemosRunReport}
                                    filterSort={{ filter: this.getFilterObj() }}
                                    timeLine={this.getTimeLine(trendingStartTime, trendingEndTime)} />}
                                <br />
                            </>
                        }
                        {this.renderFormButtons()}
                    </div >
                </Spin>
            );
        }
    }
)

const mapStateToProps = ({ currentReport, user, tabsLayout }) => {
    return {
        reportFormFetching: currentReport.isFetchingReportForm,
        data: currentReport.runReportData,
        reportColumns: currentReport?.reportColumns,
        viewColumnsJson: currentReport?.viewColumnsJson,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        timeZoneName: user.profile?.timezone,
        currentReport,
        oldReport: currentReport.reportObj,
        activeKey: tabsLayout.activeKey,
        openedTabs: tabsLayout.panes,
        previousTabs: tabsLayout?.previousTabs,
        reportTabDetails: currentReport.reportDetails,
    };
}

export default connect(mapStateToProps, { toggleBackButton, deleteTab, resetReportForm, toggleHasUnsavedChangesFlag, clearSaveReports, selectTitle, clearReportsForm, fetchEntityTypes, toggleIsFetchingReportForm, fetchAdminRoleTemplates, fetchRoleTemplates, postReport, fetchReport, updateCustomReportsCurrentPage, currentReportFilter, updateReportDetails, updateTabsLocalStorage, updatedSelectedColumns, toggleEntityHasChanged, fetchTrendingDemos })(ReportForm);
