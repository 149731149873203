import React from "react";
import { Modal, Table, Checkbox } from "antd";
import { connect } from "react-redux";

import CONFIG from "../../config";
import { updateDemoRoleTemplates } from '../../actions';
import FormActionButtons from '../common/FormActionButtons';

class DemoRoleTemplates extends React.Component {

  getRoleTemplateAccess = (roleTemplateId, roleType) => {
    const { assignedRoleTemplates } = this.props;
    const roleTemplate = assignedRoleTemplates ? assignedRoleTemplates.find(assignedRoleTemplate => assignedRoleTemplate.roleTemplate.id === roleTemplateId) : undefined;
    return roleTemplate
      ? roleType === CONFIG.roleTypes.user
        ? roleTemplate.userAccess
        : roleTemplate.adminAccess
      : undefined
  }

  renderCheckbox = (roleTemplate, roleType, allowedAccess) => {
    const roleTemplateAccess = this.getRoleTemplateAccess(roleTemplate.id, roleType);
    const templateType = roleTemplate.templateType
    return (
      <Checkbox
        disabled={(roleType === CONFIG.roleTypes.admin) && (templateType !== CONFIG.roleTypes.admin)}
        checked={roleTemplateAccess === allowedAccess}
        onChange={(e) => this.props.updateDemoRoleTemplates(e.target.checked, roleType, allowedAccess, roleTemplate)}
      />
    );
  };

  render() {
    const { visible, handleCancel, handleAssign, userRoleTemplate, allRoleTemplates } = this.props;
    const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    const adminColumns = [
      {
        title: <span className="primary-text-color">Admin</span>,
        children: [
          {
            title: (
              <div>
                Read
                <br />
                <Checkbox
                  checked={this.props.allRoleTemplatesAdminAccess === CONFIG.roleTemplateAccess.readOnly}
                  onChange={e => this.props.updateDemoRoleTemplates(e.target.checked, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.readOnly)}
                />
              </div>
            ),
            key: "adminReadAccess",
            align: "center",
            width: '17%',
            render: (access, record) =>
              this.renderCheckbox(record, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.readOnly)
          },
          {
            title: (
              <div>
                Write
                <br />
                <Checkbox
                  checked={this.props.allRoleTemplatesAdminAccess === CONFIG.roleTemplateAccess.fullAccess}
                  onChange={e => this.props.updateDemoRoleTemplates(e.target.checked, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.fullAccess)}
                />
              </div>
            ),
            key: "adminWriteAccess",
            align: "center",
            width: '17%',
            render: (access, record) =>
              this.renderCheckbox(record, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.fullAccess)
          }
        ]
      }
    ]

    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "name",
        width: '30%'
      },
      {
        title: "User",
        children: [
          {
            title: (
              <div>
                Read-only
                <br />
                <Checkbox
                  checked={this.props.allRoleTemplatesUserAccess === CONFIG.roleTemplateAccess.readOnly}
                  onChange={e => this.props.updateDemoRoleTemplates(e.target.checked, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.readOnly)}
                  disabled={allRoleTemplates && allRoleTemplates.length === 0}
                />
              </div>
            ),
            key: "userReadAccess",
            align: "center",
            width: '17%',
            render: (access, record) =>
              this.renderCheckbox(record, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.readOnly)
          },
          {
            title: (
              <div>
                Full-access
                <br />
                <Checkbox
                  checked={this.props.allRoleTemplatesUserAccess === CONFIG.roleTemplateAccess.fullAccess}
                  onChange={e => this.props.updateDemoRoleTemplates(e.target.checked, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.fullAccess)}
                  disabled={allRoleTemplates && allRoleTemplates.length === 0}
                />
              </div>
            ),
            key: "userFullAccess",
            align: "center",
            width: '15%',
            render: (access, record) =>
              this.renderCheckbox(record, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.fullAccess)
          }
        ]
      }
    ];

    return (
      <div>
        <Modal
          className="role-template-modal"
          title="Role Template"
          visible={visible}
          onCancel={handleCancel}
          footer={<FormActionButtons okText="Assign" handleCancel={handleCancel} handleSubmit={handleAssign} />}
        >
          <Table
            size='middle'
            bordered
            pagination={false}
            dataSource={allRoleTemplates}
            columns={isUserSuperAdmin ? [...columns, ...adminColumns] : columns}
            rowKey={(record) => record.id}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ openTabDemoDetails, user, roleTemplates }) => {
  return {
    allRoleTemplates: roleTemplates.all,
    allRoleTemplatesUserAccess: openTabDemoDetails?.currentTabData?.demoBasicDetails?.allRoleTemplatesUserAccess,
    allRoleTemplatesAdminAccess: openTabDemoDetails?.currentTabData?.demoBasicDetails?.allRoleTemplatesAdminAccess,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined
  }
}

export default connect(
  mapStateToProps,
  { updateDemoRoleTemplates }
)(DemoRoleTemplates);
