import React from "react";
import { Input, Form, Switch } from "antd";
import { ManageProvidersContext } from "./Providers";

export class ProviderTableCell extends React.Component {

    renderCellInputPlaceHolder = (dataIndex) => {
        return <Input placeholder="Provider Name" />;
    };

    renderCellInput = (dataIndex, record, getFieldDecorator) => {
        if (dataIndex === "title") return this.renderTitleCellInput(dataIndex, record, getFieldDecorator);
        else if (dataIndex === "active") return this.renderStatusCellInput(dataIndex, record, getFieldDecorator);
    }


    renderTitleCellInput = (dataIndex, record, getFieldDecorator) => {
        return (
            <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `Please enter provider name!`
                        },
                        {
                            validator: (_, value) => {
                                if (!value || value?.trim()?.length < 256) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Provider name cannot exceed 256 characters!');
                            },
                        }
                    ],
                    initialValue: record[dataIndex]
                })(this.renderCellInputPlaceHolder(dataIndex))}
            </Form.Item>
        );
    }

    renderStatusCellInput = (dataIndex, record, getFieldDecorator) => {
        return (
            <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator(dataIndex, {
                    initialValue: record[dataIndex] ? record[dataIndex] : false,
                    valuePropName: 'checked'
                })(<Switch checkedChildren="Active" unCheckedChildren="Inactive" />)}
            </Form.Item>
        );
    }

    renderCell = ({ getFieldDecorator }) => {
        const { editing, dataIndex, inputType, record, children, ...restProps } = this.props;
        return (
            <td {...restProps}>
                {editing ? this.renderCellInput(dataIndex, record, getFieldDecorator) : children}
            </td>
        );
    };

    render() {
        return <ManageProvidersContext.Consumer>{this.renderCell}</ManageProvidersContext.Consumer>;
    }
}
