import React from 'react';
import { Select } from "antd";

import CONFIG from '../../config';

const { Option } = Select;

const renderStatusOptions = (statusDataSet) => {
    return statusDataSet.map((status, index) => {
        return <Option key={index} value={status.value}>{status.text}</Option>
    });
}

const StatusFilter = (props) => {
    const statusDataSet = props.dataSet || CONFIG.statusDataSet
    const defaultValue = props.defaultValue || "Active";
    return (
        <Select showSearch={true} disabled={props.disabled} defaultValue={defaultValue} onChange={props?.onChange} value={props?.value === undefined ? props.allText === undefined ? "All" : props.allText : props.value} >
            {renderStatusOptions(statusDataSet)}
        </Select>
    )

}

export default StatusFilter