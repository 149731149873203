import { batch } from 'react-redux';

import {
    FETCH_ALL_TIPS,
    UPDATE_TIP_DETAILS,
    UPDATE_TIP_BODY,
    UPDATE_TIP_INTRO,
    CLEAR_TIP_DETAILS,
    CLEAR_TIP_INTRO,
    CLEAR_TIP_BODY,
    TOGGLE_IS_FETCHING_TIPS,
    UPDATE_TIPS_CURRENT_PAGE,
    UPDATE_TIP_ROLE_TEMPLATE,
    CLEAR_TIPS,
    FETCH_USER_TIP,
    LAST_TIP_FILTER,
    CLEAR_TIPS_RESPONSE,
    CLEAR_TIPS_TABLE_DATA,
} from './types'
import CONFIG from '../config';
import { aepNewsNotificationSupport } from "../apis";
import pushMessage from '../components/common/PushMessage';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';
import { download } from '../utils/file';
import { closeCurrentOpenTab } from './tabsLayout';

export const getAllTips = async (access) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/tips", { params: { access } });
    return response.data ? response.data.dataSet : [];
}

export const fetchAllTips = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, access, status }) => async (dispatch) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/tips", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
                }
            },
            access
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        // if (pageNumber !== 1 && !response.data) {
        //     dispatch(fetchAllTips({ pageNumber: pageNumber - 1, filterSort, access, status }));
        //     return;
        // }
        dispatch({ type: FETCH_ALL_TIPS, payload: [response.data, response.status] || [] });
    }
    else {
        dispatch({
            type: CLEAR_TIPS_RESPONSE,
            payload: response.status
        })
        pushMessage(CONFIG.messageType.error, 'Unable to fetch tips');
    }
    dispatch(toggleIsFetchingTipsFlag(false));
}

export const fetchTip = (tipId) => async (dispatch, getState) => {
    const response = await aepNewsNotificationSupport.get("newsNotificationSupportService/tips/" + tipId);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { user, roleTemplates } = getState();
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const tip = response.data;
        const tipData = {
            ...response.data,
            roleTemplates: isAdmin ? response.data.roleTemplates.filter(tipRoleTemplate => allRoleTemplates.some(roleTemplate => tipRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates
        }
        batch(() => {
            dispatch(updateTipDetails({ tip: tipData, tipObj: tip }));
            dispatch(updateTipDescription("intro", response.data.intro));
            dispatch(updateTipDescription("body", response.data.body));
        });
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Tip');
    }
    dispatch(toggleIsFetchingTipsFlag(false));
    return response.data || []
}

export const restoreTip = (tipId) => async () => {
    const response = await aepNewsNotificationSupport.put("/newsNotificationSupportService/restore/tips/" + tipId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Tip restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore tip");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false

}

export const fetchUserTip = () => async (dispatch) => {
    dispatch(toggleIsFetchingTipsFlag(true))
    const response = await aepNewsNotificationSupport.get("newsNotificationSupportService/roleTemplate/tip");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_USER_TIP,
            payload: response.data || []
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Tip');

    dispatch(toggleIsFetchingTipsFlag(false));
}


export const updateTipDetails = ({ tip, tipObj }) => {
    return {
        type: UPDATE_TIP_DETAILS,
        payload: tip,
        tipObj
    }
}

export const updateTipDescription = (key, payload) => {
    const type = key === 'intro'
        ? UPDATE_TIP_INTRO
        : UPDATE_TIP_BODY
    return { type, payload }
}

export const updateTipsCurrentPage = (currentPageNumber, pageSize, tipId, tipIndex) => {
    return {
        type: UPDATE_TIPS_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, tipId, tipIndex]
    }
};

export const clearCurrentTip = () => async (dispatch) => {
    batch(() => {
        dispatch(clearTipDetails());
        dispatch(clearTipDescription("intro"));
        dispatch(clearTipDescription("body"));
    })
}

export const clearTipDescription = (key) => {
    const type = key === 'intro'
        ? CLEAR_TIP_INTRO
        : CLEAR_TIP_BODY
    return { type }
}

export const clearTipDetails = () => {
    return {
        type: CLEAR_TIP_DETAILS
    }
}

export const toggleIsFetchingTipsFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_TIPS,
        payload: flag
    }
}

export const postTip = (tipId, values) => async (dispatch, getState) => {
    const state = getState();
    const tip = {
        ...state.currentTip,
        id: tipId,
        title: values.title,
        intro: state.tipIntro.html,
        body: state.tipBody.html,
        isActive: values.isActive,
        assignedRoleTemplates: undefined,
    };
    const contextPath = "/newsNotificationSupportService/tips";
    const response = await aepNewsNotificationSupport({
        method: tipId === undefined ? 'post' : 'put',
        url: tipId === undefined ? contextPath : `${contextPath}/${tipId}`,
        data: tip
    });
    const successMessage = tipId ? 'Tip updated successfully' : 'Tip created successfully';
    const errorMessage = tipId ? 'Unable to update Tip' : 'Unable to create Tip';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage)
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(closeCurrentOpenTab())
}

export const updateTipRoleTemplate = (access, accessType, roleType, selectedRoleTemplate) => (dispatch, getState) => {
    const allRoleTemplates = getState().roleTemplates.all;
    const assignedRoleTemplates = getState().currentTip.roleTemplates;
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allRTUserAccess' : 'allRTAdminAccess';
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_TIP_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access, allAccessKey }
    });
}

export const clearTips = () => {
    return { type: CLEAR_TIPS }
}

export const deleteTip = (tipId, hardDelete = false) => async () => {
    const response = await aepNewsNotificationSupport.delete("/newsNotificationSupportService/tips/" + tipId, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Tip deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete tip");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
};

export const currentTipFilter = (filters, status) => {
    return {
        type: LAST_TIP_FILTER,
        payload: [filters, status]
    }
}

export const exportTips = async (fileName, fileFormat, filterSort, timeZone) => {
    const response = await aepNewsNotificationSupport({
        method: 'get',
        url: `/newsNotificationSupportService/export/tips/${fileFormat}`,
        responseType: "blob",
        params: {
            filterSort,
            timeZone
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(fileName, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}

export const clearTipsTableData = () => {
    return {
        type: CLEAR_TIPS_TABLE_DATA
    }
}
