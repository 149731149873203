import React from 'react';
import { connect, batch } from 'react-redux';
import { Collapse, Icon, Alert } from 'antd';
import { isTabAlreadyOpen } from "../common/TabLayout";
import {
    toggleBackButton,
    selectTitle,
    clearNetworks,
    fetchRoleTemplates,
    fetchNetworks,
    clearNetworkData,
    toggleIsCollapsedStatusNetwork,
    currentNetworksFilter
} from '../../actions';
import CreateNetwork from './CreateNetwork';
import EditNetwork from './EditNetwork';
import DeletedNetwork from './DeletedNetwork';
import CONFIG from '../../config';

const { Panel } = Collapse;

class ManageNetworks extends React.Component {

    renderPanelHeader = (headerText, icon) => {
        return (
            <span className="large-text">
                <Icon className="primary-text-color" type={icon} />
                &nbsp;&nbsp;&nbsp;
                {headerText}
            </span>
        )
    }

    handlePanelChange = async () => {
        await this.props.clearNetworkData();
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentNetworksFilter(this.props.isCollapsed?.length > 0 ? this.state.status : true);
        }
        else this.props.clearNetworks();
    }

    async componentDidMount() {
        this.setState({ currentPath: window.location.pathname })
        batch(() => {
            this.props.toggleBackButton(false);
            this.props.selectTitle('Manage Networks');
            this.props.fetchRoleTemplates();
            this.props.fetchNetworks();
        });
    }

    renderWarningMessage = () => {
        return (
            <Alert
                message={CONFIG.warningMessages.noEnvironmentAccess}
                type="warning"
                showIcon
            />
        )
    }

    render() {

        const { templateType, networks } = this.props;
        const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
        const shouldDisplayEditPanel = isSuperAdmin || networks?.filter(network => network?.adminAccess === CONFIG.roleTemplateAccess.fullAccess)?.length !== 0;
        const shouldDisplayViewPanel = isSuperAdmin || networks?.filter(network => network?.adminAccess === CONFIG.roleTemplateAccess.readOnly)?.length !== 0;
        return (
            <>
                <Collapse
                    style={{ backgroundColor: 'white' }}
                    // defaultActiveKey="1"
                    // destroyInactivePanel={true}
                    destroyInactivePanel={true}
                    bordered={false}
                    expandIconPosition='right'
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                    // onChange={e => { this.functionOne(e); this.functionTwo() }}

                    onChange={(event) => {
                        this.props.toggleIsCollapsedStatusNetwork(event);
                        this.handlePanelChange()
                    }}
                    activeKey={this.props.isCollapsed}
                    accordion
                >
                    {(shouldDisplayEditPanel || shouldDisplayViewPanel) &&
                        <Panel header={this.renderPanelHeader('View Network', 'eye')} key="1">
                            <EditNetwork name="view" isReadOnly={true} />
                        </Panel>
                    }
                    {
                        shouldDisplayEditPanel &&
                        <Panel header={this.renderPanelHeader('Edit Network', 'edit')} key="2">
                            <EditNetwork name="edit" isSuperAdmin={isSuperAdmin} />
                        </Panel>
                    }
                    {isSuperAdmin &&
                        <Panel header={this.renderPanelHeader('Create Network', 'plus')} key="3">
                            <CreateNetwork isSuperAdmin={isSuperAdmin} />
                        </Panel>
                    }
                    {isSuperAdmin &&
                        <Panel header={this.renderPanelHeader('Deleted Networks', 'delete')} key="4">
                            <DeletedNetwork isSuperAdmin={isSuperAdmin} />
                        </Panel>
                    }
                    {shouldDisplayEditPanel &&
                        <Panel header={this.renderPanelHeader('Deleted IP Addresses', 'delete')} key="5">
                            <EditNetwork name="delete" isDeletedView={true} />
                        </Panel>
                    }

                </Collapse>
                {
                    !shouldDisplayEditPanel && !shouldDisplayViewPanel && this.renderWarningMessage()
                }
            </>
        )
    }
}

const mapStateToProps = ({ user, networks, tabsLayout }) => {
    return {
        selectedDataCenter: networks.dataCenter,
        networks: networks.data,
        currentPage: networks.currentPage,
        templateType: user.profile.roleTemplate.templateType,
        isCollapsed: networks.isCollapsed,
        openedTabs: tabsLayout.panes,
    };
};

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        selectTitle,
        clearNetworks,
        fetchRoleTemplates,
        fetchNetworks,
        clearNetworkData,
        toggleIsCollapsedStatusNetwork,
        currentNetworksFilter
    }
)(ManageNetworks);
