import React, { Component } from 'react'
import { Comment, Tooltip, Rate, List, Progress, Form, Input, Popconfirm, Avatar, Icon, Spin, Divider, BackTop, Typography, Popover } from 'antd';
import moment from 'moment-timezone';
import { connect } from "react-redux";
import { getAvatarColor } from '../../utils/avatarColorPicker'
import CONFIG from '../../config';
import {
    toggleIsFetchingEZDemoFeedback,
    getAllReviewsForEZDemos,
    getSplitRatingForEZDemos,
    clearEZDemoReviews,
    updateEZDemoFeedback,
    deleteEZDemoFeedback
} from "../../actions";
import pushMessage from '../common/PushMessage';
import { LoadingOutlined } from '@ant-design/icons';
import { Icon as NeoIcon, Button } from "@avaya/neo-react";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { TextArea } = Input;
const { Text } = Typography;

const EZDemoReviews = Form.create({ name: 'EZ_demo_reviews' })(
    class EZDemoReviews extends Component {

        constructor(props) {
            super(props)

            this.state = {
                replySectionVisible: false,
                replySectionRecordId: undefined,
                displayQARating: undefined,
                updateReplyVisible: false,
                allUsersReviews: [],
                isRedirectedFromPushNotification: this.props.isRatingRedirect
            }
        }

        async componentDidMount() {
            await this.fetchOnMount();
        }

        fetchOnMount = async () => {
            const isManageSection = window.location.pathname?.includes("/manage/ez-demos");
            const ezDemoId = isManageSection ? this.props.match.params?.ezDemoId : this.props.ezDemoId;
            this.props.toggleIsFetchingEZDemoFeedback(true);
            await this.props.getSplitRatingForEZDemos(ezDemoId);
            await this.props.getAllReviewsForEZDemos(ezDemoId, isManageSection);
            this.props.toggleIsFetchingEZDemoFeedback(false);
        }

        editor = (record, isAdmin) => {
            const { getFieldDecorator } = this.props.form;
            if (this.state?.replySectionVisible) {
                return (
                    <div>
                        {
                            !isAdmin && <Form.Item colon={false}>
                                {getFieldDecorator("rating", {
                                    rules: [
                                        { required: true, message: "Please provide a rating." },
                                    ],
                                    initialValue: record?.ezDemoAuditRating?.rating,
                                })(<Rate style={{ fontSize: 36, color: '#F38D00' }} tooltips={CONFIG.ratingTooltip} />)
                                }
                            </Form.Item>
                        }
                        <Form.Item >
                            {getFieldDecorator("superAdminReply",
                                {
                                    initialValue: isAdmin ? record?.ezDemoAuditRating?.replyComment : record?.ezDemoAuditRating?.comment,
                                    rules: [
                                        { required: isAdmin, message: "Reply cannot be blank." },
                                    ]
                                })
                                (<TextArea rows={4} />)
                            }
                        </Form.Item>
                        <Form.Item>
                            <Button aria-label='button' size='compact' className="primary-action-button-filled" htmlType="submit" type="primary" onClick={() => this.handleReplySubmit(record, this.state.updateReplyVisible, false, isAdmin)} >{this.state.updateReplyVisible ? isAdmin ? "Update Comment" : "Update Feedback" : "Add Comment"}</Button>
                            <Button aria-label='button' size='compact' variant='secondary' className="left-spacing" htmlType="submit" onClick={() => this.setState({ replySectionVisible: false, replySectionRecordId: undefined, updateReplyVisible: false })} >Cancel</Button>
                        </Form.Item>
                    </div>
                )
            }
        }

        handleDeleteReview = async (record, isAdmin) => {
            const isManageSection = window.location.pathname?.includes("/manage/ez-demos");
            const id = record?.ezDemoAuditRating?.id;
            if (!isManageSection) this.props.updateUserReviews();
            // API to delete review either by user himself -- from My reviews, or by Super Admin --from All Reviews
            await this.props.deleteEZDemoFeedback(id, isAdmin)
            this.setState({ isRedirectedFromPushNotification: false })
            await this.fetchOnMount();
        }

        handleReplySubmit = async (record, isEdit, isDelete = false, isAdmin) => {
            // API call to submit Super Admin's reply
            this.props.form.validateFields(async (err, values) => {
                if (err) return;
                if (values?.superAdminReply?.trim()?.length <= 0) {
                    pushMessage(CONFIG.messageType.warning, "Please add comment before submitting");
                    return;
                }
                const isManageSection = window.location.pathname?.includes("/manage/ez-demos");
                const payload = {
                    id: record?.ezDemoAuditRating?.id,
                    isAdminReply: isAdmin,
                    replyComment: isAdmin ? isDelete ? null : values?.superAdminReply : undefined,
                    comment: !isAdmin ? values?.superAdminReply : undefined,
                    rating: !isAdmin ? values?.rating : undefined,
                }
                await this.props.updateEZDemoFeedback(record?.ezDemoAuditRating?.id, payload, isEdit, isDelete, isAdmin)
                this.setState({
                    replySectionVisible: false,
                    replySectionRecordId: undefined,
                    updateReplyVisible: false,
                    isRedirectedFromPushNotification: false
                });
                await this.props.clearEZDemoReviews();
                await this.fetchOnMount();
                if (!isManageSection) this.props.updateUserReviews();
            });
        }

        handleReplyReview = (record) => {
            this.setState({
                replySectionVisible: true,
                replySectionRecordId: (record?.id + record?.ezDemoAuditRating?.commenter?.id)
            })
        }

        handleEditReview = (record) => {
            const { userRoleTemplate, user } = this.props
            const isSuperAdminUser = userRoleTemplate && (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin || userRoleTemplate.templateType === CONFIG.roleTypes.admin)
            // Redirect the user to feedback form.
            // Enable comment section for Super Admin.
            if (isSuperAdminUser || (user?.id === record?.ezDemoAuditRating?.commenter?.id)) {
                this.setState({ updateReplyVisible: true })
                this.handleReplyReview(record)
            }
        }

        actionList = (item) => {
            const isManageSection = window.location.pathname?.includes("/manage/ez-demos");
            const { userRoleTemplate } = this.props
            const isSuperAdminUser = userRoleTemplate && (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin)
            return (isManageSection ?
                [item?.ezDemoAuditRating?.replyBy === null && !this.state.replySectionVisible && <span key="comment-list-reply-to-0" onClick={() => this.handleReplyReview(item)}>Reply</span>,
                isSuperAdminUser && <Popconfirm title="Confirm Delete?" onConfirm={() => this.handleDeleteReview(item, true)} okText="Yes" cancelText="No">
                    {!this.state.replySectionVisible && <span key="comment-list-reply-to-0">Delete</span>}
                </Popconfirm>]
                : []
            )
        }

        renderReviews = () => {
            const { data, timeZone, user } = this.props;
            const isManageSection = window.location.pathname?.includes("/manage/ez-demos");
            const updateReply = this.state.updateReplyVisible
            const replyingRecord = this.state.replySectionRecordId;
            return (
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        <li>
                            <Comment
                                author={
                                    <span className="rating-author-title">
                                        {item?.ezDemoAuditRating?.commenter?.fullName}
                                        {isManageSection &&
                                            <Popover content={<>
                                                <span><Text strong>Full Name:</Text> {item?.ezDemoAuditRating?.commenter?.firstName + " " + item?.ezDemoAuditRating?.commenter?.lastName}</span><br />
                                                <span><Text strong>Handle: </Text> {item?.ezDemoAuditRating?.commenter?.userName}</span><br />
                                                <span><Text strong>Email:</Text> {item?.ezDemoAuditRating?.commenter?.email}</span>
                                            </>} title="User Details">
                                                <Icon style={{ paddingLeft: '5px', color: "#da291c" }} type="info-circle" width={1} /></Popover>}
                                        <Rate style={{ color: '#F38D00' }}
                                            className="review-Rating"
                                            disabled allowHalf
                                            value={item?.ezDemoAuditRating?.rating} />
                                        {<Tooltip title={moment.tz(item?.ezDemoAuditRating?.ratingTime, timeZone).format(CONFIG.dateFormats.userDateTime)}><span>{moment.tz(item?.ezDemoAuditRating?.ratingTime, timeZone).fromNow()}</span></Tooltip>}

                                    </span>
                                }
                                avatar={<Avatar style={{ backgroundColor: getAvatarColor(item?.ezDemoAuditRating?.commenter?.firstName?.charAt(0)) }}>{item?.ezDemoAuditRating?.commenter?.firstName ? item?.ezDemoAuditRating?.commenter?.firstName?.charAt(0) : ''}</Avatar>}
                                content={<>
                                    <p style={{ fontSize: "12.5px" }}>{item?.ezDemoAuditRating?.comment}</p>
                                    {isManageSection && !updateReply && replyingRecord === (item?.id + item?.ezDemoAuditRating?.commenter?.id) && this.editor(item, true)}
                                    {isManageSection && updateReply && replyingRecord === (item?.id + item?.ezDemoAuditRating?.commenter?.id) && this.editor(item, true)}
                                    {!isManageSection && updateReply && (user?.id === item?.ezDemoAuditRating?.commenter?.id) && replyingRecord === (item?.id + item?.ezDemoAuditRating?.commenter?.id) && this.editor(item, false)}
                                </>}
                                actions={isManageSection
                                    ? this.actionList(item)
                                    : (!this.state.replySectionVisible && item?.ezDemoAuditRating?.replyComment === null && user?.id === item?.ezDemoAuditRating?.commenter?.id) &&
                                    [<Tooltip title={item?.isOutOfRatingWindow ? CONFIG.warningMessages.ratingExpired : undefined}><span className={item?.isOutOfRatingWindow ? "comments-action-button-disabled" : undefined} key="comment-list-reply-to-0" onClick={() => !item?.isOutOfRatingWindow ? this.handleEditReview(item) : undefined} >Edit</span></Tooltip>,
                                    <Tooltip title={item?.isOutOfRatingWindow ? CONFIG.warningMessages.ratingExpired : undefined}>{!item?.isOutOfRatingWindow ? <Popconfirm title="Confirm Delete?" onConfirm={() => this.handleDeleteReview(item, false)}><span key="comment-list-reply-to-0">Delete</span></Popconfirm> : <span className="comments-action-button-disabled">Delete</span>}
                                    </Tooltip>]
                                }
                            >
                                {item?.ezDemoAuditRating?.replyBy && <>
                                    <Divider className="ez-demo-divider" />
                                    <Comment
                                        author={<span className="rating-author-title">{replyingRecord !== (item?.id + item?.ezDemoAuditRating?.commenter?.id) && item?.ezDemoAuditRating?.replyBy?.fullName} <Tooltip title={moment.tz(item?.ezDemoAuditRating?.replyTime, timeZone).format(CONFIG.dateFormats.userDateTime)}><span>{moment.tz(item?.ezDemoAuditRating?.replyTime, timeZone).fromNow()}</span></Tooltip></span>}
                                        avatar={replyingRecord !== (item?.id + item?.ezDemoAuditRating?.commenter?.id) && <Avatar className="avatar-fill-color">{item?.ezDemoAuditRating?.replyBy?.fullName ? item?.ezDemoAuditRating?.replyBy?.firstName?.charAt(0) : ''}</Avatar>}
                                        content={replyingRecord !== (item?.id + item?.ezDemoAuditRating?.commenter?.id) && <div className=' rating-reply-box'> <p style={{ fontSize: "12.5px" }}>{item?.ezDemoAuditRating?.replyComment}</p></div>}
                                        actions={isManageSection && !this.state.replySectionVisible &&
                                            [<span key="comment-list-reply-to-0" onClick={() => this.handleEditReview(item)} >Edit</span>,
                                            <Popconfirm title="Confirm Delete?" okText="Yes" cancelText="No" onConfirm={() => this.handleReplySubmit(item, false, true, true)}><span key="comment-list-reply-to-0">Delete</span></Popconfirm>]
                                        }
                                    ></Comment>
                                </>}
                            </Comment>
                            <Divider className="ez-demo-divider" />
                        </li>
                    )}
                />
            )
        }

        getRatingSplitUp = (ratingSplitMap) => {
            let splitUpArray = []
            for (let i = 0; i <= 4; i++) {
                splitUpArray.push(ratingSplitMap?.find(item => item?.rating === (i + 1))?.ratingCount || 0)
            }
            return splitUpArray
        }


        renderRatingSplitUp = (splitUpArray, totalRating) => {
            let progressBar = []
            for (let i = 4; i >= 0; i--) {
                const progress = (
                    <Progress percent={Math.ceil((splitUpArray[i] * 100) / totalRating)} format={() => splitUpArray[i]} strokeColor={'#F38D00'} />
                )
                progressBar.push(progress)
            }
            return progressBar
        }

        renderRatingsTitle = () => {
            const { ratingsCount } = this.props.ratingSplitUp
            return <h4 >{ratingsCount} Ratings</h4>
        }

        render() {
            const { ratingSplitUp, loading, data } = this.props || [];
            const { avgRating, ratingsCount } = this.props.ratingSplitUp || []
            const ratingStar = ['5 ★', '4 ★', '3 ★', '2 ★', '1 ★']
            const listOfStars = ratingStar.map((star) => <>{star}<br /></>)
            const eachStarSplitArray = this.getRatingSplitUp(ratingSplitUp?.ratingSplitForEzDemos);
            const empty = <div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Data Found</div>;
            return (
                <Spin indicator={antIcon} spinning={loading}>
                    {(data?.length > 0 && !loading) ? <div className='content-container font'>
                        <BackTop visibilityHeight />
                        {ratingSplitUp?.ratingSplitForEzDemos?.length > 0 && <div className="rating-split-up">
                            <div className="rating-section">
                                <div className="rating-container">
                                    <div className="rating-average-demo-review font">
                                        {avgRating}
                                        <Rate style={{ color: '#F38D00', fontSize: '32px' }} className="review-Rating" disabled allowHalf defaultValue={avgRating} />
                                    </div>
                                    {this.renderRatingsTitle()}
                                </div>
                                <div className="rating-details">
                                    <div className="star-count">
                                        {listOfStars}
                                    </div>
                                    <div className="rating-breakdown">
                                        {this.renderRatingSplitUp(eachStarSplitArray, ratingsCount)}
                                    </div>
                                </div>
                            </div>
                            <Divider />
                        </div>}
                        <div className="reviews-section-panel">
                            {this.renderReviews()}

                        </div>
                    </div > : empty}
                </Spin>

            )
        }
    })

const mapStateToProps = ({ user, ezDemoFeedback }) => {
    return {
        user: user?.profile,
        loading: ezDemoFeedback?.loading,
        data: ezDemoFeedback?.allReviews,
        timeZone: user.profile.timezone,
        ratingSplitUp: ezDemoFeedback.ratingSplitUp,
        isRatingEnabled: user.isRatingEnabled,
        isViewCommentEnabled: user.isViewCommentEnabled,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    };
};

export default connect(
    mapStateToProps,
    {
        toggleIsFetchingEZDemoFeedback,
        getAllReviewsForEZDemos,
        getSplitRatingForEZDemos,
        clearEZDemoReviews,
        updateEZDemoFeedback,
        deleteEZDemoFeedback
    }
)(EZDemoReviews);