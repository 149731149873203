import React from 'react';
import { Modal, Form, Descriptions, Comment, List, Input, Button, Avatar, Divider, DatePicker, Steps, Icon, Popconfirm } from 'antd';
import moment from 'moment-timezone';
import CONFIG from '../../config';
import { getStartOfDateValue, range, areDatesEqual, areHoursEqual } from '../../utils/datepicker';

const { TextArea } = Input;

const { Step } = Steps;

const CommentList = ({ comments, timeZoneName, user, editingKey, onChange, onSubmit, value, submitting, handleCommentEdit, handleCommentDelete, handleCommentCancel, selectedSchedule }) => {

  return <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? 'comments' : 'comment'}`}
    itemLayout="horizontal"
    renderItem={item => {
      return (
        <li>
          {editingKey === item.id
            ? <Comment
              datetime={moment.tz(item.commentDate, timeZoneName).format(CONFIG.dateFormats.userDateTime)}
              avatar={<Avatar className="avatar-fill-color"> {item.commentedBy?.firstName?.charAt(0)}</Avatar>}
              author={item.commentedBy?.fullName}
              content={
                <Editor
                  onChange={onChange}
                  onSubmit={onSubmit}
                  submitting={submitting}
                  value={value}
                  editingKey={editingKey}
                />
              }
              actions={[<button key="comment-list-reply-to-0" className="link" onClick={() => onSubmit(true, item.id)} >Save</button>,
              <Divider type="vertical" />, <button key="comment-list-reply-to-0" className="link" onClick={() => handleCommentCancel(item.id, item.comment)} >Cancel</button>
              ]}
            />
            : <Comment
              content={item.comment}
              datetime={moment.tz(item.commentDate, timeZoneName).format(CONFIG.dateFormats.userDateTime)}
              avatar={<Avatar className="avatar-fill-color"> {item.commentedBy?.firstName?.charAt(0)}</Avatar>}
              author={item.commentedBy?.fullName}
              actions={(item.commentedBy?.id === user.id || user.precedenceTemplateType === CONFIG.roleTypes.superAdmin) && editingKey === "" &&
                [<span key="comment-list-reply-to-0" onClick={() => handleCommentEdit(item.id, item.comment)} style={{ paddingRight: 0 }} >Edit</span>, <Divider type="vertical" />,
                <Popconfirm title="Confirm Delete?" onConfirm={() => handleCommentDelete(item.id, selectedSchedule.id)} okText="Yes" cancelText="No"><span key="comment-list-reply-to-0">Delete</span></Popconfirm>
                ]
              }
            />
          }
        </li>
      )
    }}
  />
}

const Editor = ({ onChange, onSubmit, submitting, value, editingKey, defaultValue }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} defaultValue={defaultValue} />
    </Form.Item>
    {editingKey === "" &&
      <Form.Item>
        <Button htmlType="submit" loading={submitting} onClick={() => onSubmit()} type="primary">
          Add Comment
        </Button>
      </Form.Item>
    }
  </div>
);

const TearDownForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  function TearDownForm(props) {
    const { visible, onCancel, timeZoneName, selectedSchedule, comments, comment, submitting, handleChange, handleSubmit, handleEndDateChange, handleEndDateSubmit, endDateTime, setIsEditing, isEditing, user, handleCommentEdit, editingKey, handleCommentDelete, handleCommentCancel } = props;

    const disabledDate = end => {
      let { startTime } = selectedSchedule;
      const endValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(end) });
      const startValue = getStartOfDateValue({ changeTimeZone: true, timeZone: timeZoneName, date: moment(startTime) });
      const currentValue = getStartOfDateValue({ convertTimeZone: true, timeZone: timeZoneName });

      return endValue < startValue || endValue < currentValue;
    };

    const disabledTime = (current) => {
      const { startTime } = selectedSchedule;
      const startValue = moment.tz(startTime, timeZoneName)
      if (startValue && areDatesEqual(startValue, current)) {
        return {
          disabledHours: () => range(0, startValue.get('hours')),
          disabledMinutes: () => areHoursEqual(startValue, current) ? range(0, startValue.get('minute')) : []
        };
      }
    }

    const renderEndDateField = () => {
      const EndState = ["Torn Down (Manual)", "Torn Down (Auto)", "Torn Down (Canceled)", "Closed", "Failed", "Failed (In-Progress)", "Canceled (In-Progress)", "Canceled (Available)", "Canceled (Failed)"]
      const isEditDisabled = EndState.includes(selectedSchedule?.buildStatus)
      return <span>
        {isEditing ?
          <DatePicker
            showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
            format={CONFIG.dateFormats.default}
            onChange={handleEndDateChange}
            value={endDateTime}
            className="right-spacing-5"
            disabledTime={disabledTime}
            disabledDate={disabledDate}
            showToday={false}
          />
          : <span className="right-spacing-5">{moment.tz(selectedSchedule?.endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</span>}
        {isEditing ?
          <span> <span onClick={handleEndDateSubmit} className="link">Save</span> <Divider type="vertical" /> <span onClick={() => setIsEditing(false)} className="link">Close</span></span>
          : <button disabled={isEditDisabled} onClick={() => setIsEditing(true)} className={!isEditDisabled ? "link" : "link-disabled"}>Edit</button>
        }
      </span>
    }

    const renderStepsOptions = () => {

      return selectedSchedule?.scheduleBuildStatus?.map(item => {
        const IconType = (item.buildStatus === "In-Progress" && selectedSchedule.scheduleBuildStatus.length === 1)
          ? "clock-circle"
          : (item.buildStatus === "Failed" || item.buildStatus === "Failed (In-Progress)")
            ? "close-circle"
            : "check-circle"

        const IconColor = (item.buildStatus === "In-Progress" && selectedSchedule.scheduleBuildStatus.length === 1)
          ? "#6DB5EC"
          : (item.buildStatus === "Failed" || item.buildStatus === "Failed (In-Progress)")
            ? "#F7434F"
            : "#52c41a"

        return (
          <Step
            className="approvalStep"
            title={item.buildStatus}
            description={`on ${moment.tz(item.createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}`}
            icon={<Icon type={IconType} theme="twoTone" twoToneColor={IconColor} style={{ fontSize: '26px' }} />}
          />
        )
      })
    }

    return (
      <Modal
        visible={visible}
        title="Build Details"
        onCancel={onCancel}
        footer={false}
        width="90%"
      >
        <>
          <Descriptions className="user-details">
            <Descriptions.Item label="Demo Name" span={4}>{selectedSchedule?.demo?.title}</Descriptions.Item>
            <Descriptions.Item label="Demo Type" span={4}>{selectedSchedule?.demo?.demoType}</Descriptions.Item>
            <Descriptions.Item label="Created By" span={4} >{selectedSchedule?.createdBy?.fullName}</Descriptions.Item>
            <Descriptions.Item label="Start Date/Time" span={4}>{moment.tz(selectedSchedule?.startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</Descriptions.Item>
            <Descriptions.Item label="End Date/Time" span={4}>{renderEndDateField()}</Descriptions.Item>
            <Descriptions.Item label="Build Status" span={4}>{selectedSchedule?.buildStatus}</Descriptions.Item>
          </Descriptions>
          <Steps current={selectedSchedule?.scheduleBuildStatus?.length} status="error" direction="vertical">
            {renderStepsOptions()}
          </Steps>
          <div>
            {comments?.length > 0 && <CommentList
              comments={comments}
              timeZoneName={timeZoneName}
              user={user}
              onChange={handleChange}
              onSubmit={handleSubmit}
              submitting={submitting}
              value={comment}
              handleCommentEdit={handleCommentEdit}
              editingKey={editingKey}
              handleCommentDelete={handleCommentDelete}
              selectedSchedule={selectedSchedule}
              handleCommentCancel={handleCommentCancel}
            />}
            {editingKey === "" &&
              <Comment
                content={
                  <Editor
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                    value={comment}
                    editingKey={editingKey}
                  />
                }
              />
            }
          </div>
        </>
      </Modal>
    );
  },
);

export default TearDownForm