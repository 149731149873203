import React, { useEffect, useState } from 'react'
import { fetchUserById } from '../../actions';
import { useDispatch } from 'react-redux';
import { TextInput, Form as NeoForm, Select, SelectOption } from "neo-latest";
import moment from 'moment-timezone';
import CONFIG from '../../config';
import { Spin, Form, Checkbox } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function UserDetails(props) {
    const { record, readOnly, timezone, form, roleTemplates } = props;
    const { getFieldDecorator } = form;
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchUserDetails = async () => {
            setLoading(true);
            const data = await dispatch(fetchUserById(record?.id, false));
            setUserData(data);
            setLoading(false);
        }
        fetchUserDetails();
        // eslint-disable-next-line
    }, [])

    const getRoleTemplateNamesString = (roleTemplates, delimiter = ' ', defaultString = '') => {
        return roleTemplates?.map(roleTemplate => roleTemplate.title)?.join(delimiter) || defaultString;
    }

    const renderRoleTemplates = () => {
        if (roleTemplates?.length > 0) {
            return roleTemplates.map(roleTemplate => {
                return (
                    <SelectOption key={roleTemplate.id} value={JSON.stringify({ id: roleTemplate.id, title: roleTemplate.title })}>
                        {roleTemplate.title}
                    </SelectOption>
                );
            });
        }
        return;
    };

    const notCreatedStatus = userData?.crmUserStatus === CONFIG.crmStatus[0].value;

    return (
        <Spin spinning={loading} indicator={antIcon}>
            <NeoForm>
                <Form>
                    <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                            <div>
                                <TextInput label="Name" value={(userData?.fullName || "-")} readOnly />
                            </div>
                            <div>
                                <TextInput label="Email" value={(userData?.email || "-")} readOnly />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "16px", marginTop: "16px" }}>
                            <div>
                                <TextInput label="Handle" value={(userData?.sslHandle || "-")} readOnly />
                            </div>
                            <div>
                                <TextInput label="Company" value={(userData?.company?.company || "-")} readOnly />
                            </div>
                        </div>
                        <div style={{ display: "flex", marginTop: "16px" }}>
                            <div style={{ display: "flex", flex: "1" }}>
                                <TextInput label="Last Login Time" value={userData?.lastLoginTime ? moment.tz(userData?.lastLoginTime, timezone).format(CONFIG.dateFormats.userDateTime) : "-"} readOnly />
                            </div>
                            <div style={{ display: "flex", flex: "1" }}>
                                {readOnly ? <TextInput label="CRM Status" value={(userData?.crmUserStatus || "-")} readOnly />
                                    : !(notCreatedStatus) ? <TextInput label="CRM Status" value={(userData?.crmUserStatus || "-")} readOnly /> : <Form.Item>
                                        {getFieldDecorator("crmUserStatus", {
                                            initialValue: userData?.crmUserStatus,
                                            rules: [
                                                {
                                                    type: 'string',
                                                    message: `Please Input CRM Status!`
                                                }
                                            ],
                                        })(
                                            <Select style={{ width: 180 }} label="CRM Status" className="remove-padding-style" placeholder="Select CRM Status" >
                                                {CONFIG.crmStatus.filter(opt => opt.text !== CONFIG.crmStatus[1].text)
                                                    .map((option) => (
                                                        <SelectOption value={option.value}>{option.text}</SelectOption>
                                                    ))
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                }
                            </div>
                        </div>
                        <div style={{ marginTop: "16px", width: "100%" }}>
                            {readOnly ? <div>
                                <TextInput label="Role Templates" value={(getRoleTemplateNamesString(userData?.roleTemplates, ', ') || "-")} readOnly />
                            </div> : <Form.Item>
                                {
                                    getFieldDecorator("roleTemplates", {
                                        rules: [
                                            {
                                                type: 'array',
                                                required: true,
                                                message: `Please Input RoleTemplate!`
                                            }
                                        ],
                                        initialValue: userData ? userData?.roleTemplates?.map(roleTemplate => JSON.stringify({ id: roleTemplate.id, title: roleTemplate.title })) : []
                                    })(<Select multiple searchable={true} label='Role Templates'>{renderRoleTemplates()}</Select>)
                                }
                            </Form.Item>}
                        </div>
                        <div style={{ marginTop: "16px", width: "100%", }}>
                            <div style={{ fontSize: "12px", color: "#242424" }}>Permissions</div>
                            <div style={{ marginTop: "8px", width: "100%", }}>

                                <Form.Item>
                                    {
                                        getFieldDecorator("isAddParticipantsEnabled", {
                                            initialValue: userData?.isAddParticipantsEnabled,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox
                                                disabled={readOnly}
                                                className={readOnly ? "read-only-antd-checkbox" : ""}
                                                value={`${readOnly ? "readOnly" : ""} ${userData?.isAddParticipantsEnabled ? "checked" : "unchecked"}`}
                                                name="Add Participants" style={{ color: "#000" }} >Add Participants</Checkbox >
                                        )
                                    }
                                </Form.Item>
                                <Form.Item>
                                    {
                                        getFieldDecorator("isMultipleDays", {
                                            initialValue: userData?.isMultipleDays,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox className={readOnly ? "read-only-antd-checkbox" : ""}
                                                value={`${readOnly ? "readOnly" : ""} ${userData?.isMultipleDays ? "checked" : "unchecked"}`}
                                                name="Multiple Days" disabled={readOnly}
                                                style={{ color: "#000" }}
                                            >Multiple Days</Checkbox>
                                        )
                                    }
                                </Form.Item>
                                <Form.Item>
                                    {
                                        getFieldDecorator("isPeriodicEnabled", {
                                            initialValue: userData?.isPeriodicEnabled,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox disabled={readOnly} style={{ color: "#000" }} className={readOnly ? "read-only-antd-checkbox" : ""} name="Periodic Schedules" >Periodic Schedules</Checkbox>
                                        )
                                    }
                                </Form.Item>
                                <Form.Item>
                                    {
                                        getFieldDecorator("isMultipleScheduleBooking", {
                                            initialValue: userData?.isMultipleScheduleBooking,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox disabled={readOnly} style={{ color: "#000" }} className={readOnly ? "read-only-antd-checkbox" : ""} name="Multiple Schedule Booking">Multiple Schedule Booking</Checkbox>
                                        )
                                    }
                                </Form.Item>
                                <Form.Item>
                                    {
                                        getFieldDecorator("emailNotificationSubscription", {
                                            initialValue: userData?.emailNotificationSubscription,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox disabled className='read-only-antd-checkbox' style={{ color: "#000" }} name="Portal Email Subscription" readOnly >Portal Email Subscription</Checkbox>
                                        )
                                    }
                                </Form.Item>
                                <Form.Item>
                                    {
                                        getFieldDecorator("optOutInviteSubs", {
                                            initialValue: userData?.optOutInviteSubs,
                                            valuePropName: 'checked'
                                        })(
                                            <Checkbox disabled className='read-only-antd-checkbox' style={{ color: "#000" }} name="Meeting Invite Subscription" readOnly >Meeting Invite Subscription</Checkbox>
                                        )
                                    }
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>
            </NeoForm>
        </Spin>
    )
}

export default UserDetails;