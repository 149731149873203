import { batch } from 'react-redux';
import { aepDemo, aepLDAP, aepUser } from "../apis";
import {
    FETCH_DEMOS,
    FETCH_ALL_EXISTING_DEMOS,
    FETCH_ALL_DEMOS,
    CLEAR_CURRENT_DEMO,
    TOGGLE_IS_FETCHING_DEMOS,
    CLEAR_DEMOS,
    UPDATE_DEMO_SAVE_WARNING,
    UPDATE_DEMOS_CURRENT_PAGE,
    LAST_DEMO_FILTER,
    CLEAR_FETCHED_DEMOS,
    CLEAR_DEMO_RESPONSE,
    CURRENT_ACTIVE_DEMO_PANEL,
    CLEAR_DEMO_FORM_TAB_DATA,
    FETCH_CURRENT_DEMO_BASIC_DETAILS,
    FETCH_ALL_USERS_FOR_DEMO
} from './types';
import {
    addDemoTabDetails,
} from './demoDetails';
import { addDemoTabCollaterals } from './demoCollaterals';
import pushMessage from '../components/common/PushMessage';
import { findCurrentTabData } from '../components/common/TabLayout';
import CONFIG from '../config';
let onlyRoleTemplateUpdate = false;

export const testConnection = async (requestData) => {
    const response = await aepLDAP.post("/ldapService/testapi", requestData);
    if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.incorrectCredentials)
    }
    return response
}

export const getAllDemos = async ({ access, filterSort, status }) => {
    const response = await aepDemo.get("/demoSolutionService/demos", {
        params: {
            access,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: status
                }
            }
        }
    });
    return response.data ? response.data.dataSet : [];
}

export const getDemo = async (demoId) => {
    const response = await aepDemo.get(`/demoSolutionService/demos/${demoId}`);
    return response.data || []
}

export const getUserDemoById = async (demoId) => {
    const response = await aepDemo.get(`/demoSolutionService/user/demo/${demoId}`);
    return response.data || []
}

export const getUserDemoByIdLite = async (demoId) => {
    const response = await aepDemo.get(`/demoSolutionService/v1/lite/demos/${demoId}`);
    return response.data || []
}

export const fetchUserDemoAccess = async (demoId) => {
    const response = await aepDemo.post("/demoSolutionService/access/demo/" + demoId);
    return response.data
}

export const fetchUserEZDemoAccess = async (ezDemoId) => {
    const response = await aepDemo.get("/demoSolutionService/access/ezdemo/" + ezDemoId);
    return response.data
}

export const fetchDemos = ({ pageNumber = 1, pageSize = 12, access, getAll = false, filterSort, status, demoType }) => async dispatch => {

    const contextPath = "/demoSolutionService/demos";
    const response = getAll
        ? await aepDemo.get(contextPath, {
            params: {
                access,
                filterSort: {
                    sort: { title: 'asc' },
                    filter: demoType && {
                        demoType: demoType === CONFIG.demoTypes.restfulPoC
                            ? `${CONFIG.demoTypes.labPocDemo}\\${CONFIG.demoTypes.restfulApiDemo}`
                            : demoType === CONFIG.demoTypes.standardDemo ? CONFIG.demoTypes.standardDemo : CONFIG.demoTypes.labPocDemo
                    }
                }
            }
        })
        : await aepDemo.get(contextPath, {
            params: {
                pageSize,
                pageNumber,
                access,
                filterSort: {
                    ...filterSort,
                    filter: {
                        ...filterSort?.filter,
                        isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
                    }
                }
            }
        })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (pageNumber !== 1 && !response.data) {
            dispatch(fetchDemos({ pageNumber: pageNumber - 1, status, access, filterSort }));
            return;
        }
        else {
            if (getAll)
                dispatch({
                    type: FETCH_ALL_DEMOS,
                    payload: [response.data] || []
                })
            else
                dispatch({
                    type: FETCH_DEMOS,
                    payload: [response.data?.dataSet || [], response.status] || []
                })
        };

    }
    else {
        dispatch({
            type: CLEAR_DEMO_RESPONSE,
            payload: response.status
        })
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Demos');
    }
    dispatch(toggleIsFetchingDemosFlag(false));
};


export const fetchAllDemosLite = ({ access, filterSort, status }) => async dispatch => {
    const contextPath = "/demoSolutionService/v1/lite/demoList";
    const response = await aepDemo.get(contextPath, {
        params: {
            access,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: filterSort?.filter?.viewDeletedItems ? undefined : status ? status === "true" ? true : status === "false" ? false : undefined : undefined
                }
            }
        }
    })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_DEMOS,
            payload: [response.data, response.status] || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Demos');
    dispatch(toggleIsFetchingDemosFlag(false));
};

export const fetchAllExistingDemos = ({ access, demoType }) => async dispatch => {
    const contextPath = "/demoSolutionService/demos";
    const response = await aepDemo.get(contextPath, {
        params: {
            access,
            filterSort: {
                sort: { title: 'asc' },
                filter: demoType && {
                    demoType: demoType === CONFIG.demoTypes.restfulPoC
                        ? `${CONFIG.demoTypes.labPocDemo}\\${CONFIG.demoTypes.restfulApiDemo}`
                        : demoType === CONFIG.demoTypes.standardDemo ? CONFIG.demoTypes.standardDemo : CONFIG.demoTypes.labPocDemo
                }
            }
        }
    })

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ALL_EXISTING_DEMOS,
            payload: response.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Demos');
    dispatch(toggleIsFetchingDemosFlag(false));
};

export const fetchDemo = ({ demoId, updateCollateral = false, isDemoClone }) => async (dispatch, getState) => {
    const response = await aepDemo.get(`/demoSolutionService/demos/${demoId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { roleTemplates, user } = getState();
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin
        const demo = response.data;
        const basicDetails = {
            id: demo.id,
            title: demo.title,
            roleTemplates: isAdmin ? demo.roleTemplates.filter(demoRoleTemplate => allRoleTemplates?.some(roleTemplate => demoRoleTemplate.roleTemplate.id === roleTemplate.id)) : demo.roleTemplates,
            isActive: demo.isActive,
            demoSolutions: demo.demoSolutions,
            isExpired: demo.isExpired,
            startTimeBuffer: demo.startTimeBuffer,
            endTimeBuffer: demo.endTimeBuffer,
            notifyUsers: demo.notifyUsers,
            demoType: demo.demoType,
            maxSchedule: demo.maxSchedule,
            autoDestroy: demo.autoDestroy,
            vdiRequired: demo.vdiRequired,
            scheduleRange: demo.scheduleRange,
            externalApis: demo.externalApis,
            demoApprovers: demo.demoApprovers,
            parameters: demo.parameters,
            isManual: demo.isManual,
            schTimeLimit: demo.schTimeLimit,
            isPreRegistration: demo.isPreRegistration,
            isOneTime: demo.isOneTime,
            isManagerMandatory: demo.isManagerMandatory,
            approvalRequired: demo.approvalRequired,
            currentCollapsePanelKey: "1",
            notes: demo.notes,
            managerTitle: demo.managerTitle,
            regionalPrimeTitle: demo.regionalPrimeTitle,
            isMultipleApprover: demo.isMultipleApprover,
            isManagerEmail: demo.isManagerEmail,
            isRegionalPrime: demo.isRegionalPrime,
            demoRegionalPrimes: demo.demoRegionalPrimes,
            reminderHours: demo?.reminderHours || 2
        };

        if (updateCollateral) {
            dispatch(addDemoTabCollaterals(demoId, [...demo.collaterals]));
            return;
        }

        batch(() => {
            dispatch(addDemoTabDetails({ demoId, basicDetails, demo, fromAPI: true, isDemoClone }));
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch demo details');
    setTimeout(() => {
        dispatch(currentActivePanel("1"))
        dispatch(toggleIsFetchingDemosFlag(false));
    }, 1000)
    return response.data || []
};


export const clearCurrentDemo = () => {
    return { type: CLEAR_CURRENT_DEMO };
}


export const updateCurrentTabDemoDetails = (demoId, isDemoClone) => async (dispatch) => {
    dispatch(fetchCurrentOpenTabDemoDetails(demoId, isDemoClone));
}


export const fetchCurrentOpenTabDemoDetails = (demoId, isDemoClone) => async (dispatch, getState) => {
    const state = getState();
    const currentTabDetails = findCurrentTabData(state?.demoForm?.demoTabData, demoId, isDemoClone);
    dispatch({
        type: FETCH_CURRENT_DEMO_BASIC_DETAILS,
        payload: currentTabDetails
    })
}

export const currentActivePanel = (key) => {
    return {
        type: CURRENT_ACTIVE_DEMO_PANEL,
        payload: key
    }
}

const checkDemoFormCompletion = (demo, checkForCompletion) => {
    let warningMessage;
    if (checkForCompletion && demo.isActive) {
        const searchString = '<placeholder>';
        let itemsString = undefined;
        if (!demo.collaterals || demo.collaterals.length === 0) itemsString = itemsString ? `${itemsString}, collaterals` : 'collaterals';
        if (demo.demoType === CONFIG.demoTypes.standardDemo && (!demo.demoResources || demo.demoResources.length === 0)) itemsString = itemsString ? `${itemsString}, resources` : 'resources';
        if (!demo.roleTemplates || demo.roleTemplates.length === 0) itemsString = itemsString ? `${itemsString}, role templates` : 'role templates';

        warningMessage = itemsString ? CONFIG.warningMessages.demoActivate.replace(searchString, itemsString) : undefined;
    }
    return { isDemoComplete: warningMessage === undefined, warningMessage };
}

const getCollateralObjs = (collaterals) => {
    return collaterals?.map(collateral => ({
        id: collateral.id,
        collateralType: collateral?.collateralType,
        description: collateral.description?.trim() === "" ? null : (collateral.description?.trim() || collateral.description),
        title: collateral.title.trim(),
        dataCenter: collateral.dataCenter,
        isActive: collateral.isActive,
        link: collateral.link
    }));
}


const getFlags = (demo, oldDemo) => {
    let isDemoDescUpdated, isDemoAccessUpdated, isDemoDemoGuideUpdated = false;

    isDemoDescUpdated = demo?.description?.trim() !== oldDemo?.demoEditorContent?.description?.trim()

    isDemoAccessUpdated = demo?.connectivityDetails?.trim() !== oldDemo?.demoEditorContent?.connectivityDetails?.trim()

    // Collaterals
    let newCollaterals = demo?.collaterals;
    let oldCollaterals = oldDemo?.demoCollaterals?.assignedCollaterals;
    newCollaterals = getCollateralObjs(newCollaterals);
    oldCollaterals = getCollateralObjs(oldCollaterals);
    isDemoDemoGuideUpdated = JSON.stringify(newCollaterals) !== JSON.stringify(oldCollaterals);

    return { isDemoDescUpdated, isDemoAccessUpdated, isDemoDemoGuideUpdated }

}

const getDemoApproversList = (demoApprovers) => {
    let approversList = []
    if (demoApprovers) demoApprovers.forEach((approver, index) => (approver) && approver.forEach(item => approversList.push({ level: index, approver: item.trim() })))
    return approversList
}

const getRegionalManagersList = (primes) => {
    let primeList = []
    if (primes) primes.forEach((approver, index) => primeList.push({ regionalPrime: JSON.parse(approver) }));
    return primeList;
}

export const postNewDemo = (values, checkForCompletion = true, isDemoClone, demoId) => async (dispatch, getState) => {
    const state = getState();
    const { demoEditorContent, originalDemoObj, demoCollaterals, demoEzDemos, demoResources, demoBasicDetails, assignedAdditionalInfoQuestions, assignedPreRegisterQuestions, demoDCStatuses, demoCapability } = state.openTabDemoDetails?.currentTabData
    const oldDemo = originalDemoObj
    let resourcesDataCenter = []
    if (demoResources) {
        demoResources.forEach(item => resourcesDataCenter.push(item.resource.dataCenter.title))
    }

    const demoId = isDemoClone || state.openTabDemoDetails?.currentTabData?.demoId === -1 ? undefined : state.openTabDemoDetails?.currentTabData?.demoId
    const demo = {
        // Demo Basic Details
        id: demoId,
        title: values.title,
        startTimeBuffer: values.demoType === CONFIG.demoTypes.standardDemo ? (values.startTimeBuffer || 0) : values.buildDuration * 60,
        endTimeBuffer: values.endTimeBuffer || 0,
        isActive: values.isActive,
        isExpired: values.isExpired,
        isProduction: values.isProduction,
        isRatingReset: values.isRatingReset,
        isManagerMandatory: values.isManagerMandatory,
        approvalRequired: (!values?.isManagerEmail && !values?.isRegionalPrime && !values?.isMultipleApprover) ? false : values.approvalRequired,
        notes: values.notes,
        managerTitle: values.managerTitle,
        isManagerEmail: values.isManagerEmail,
        regionalPrimeTitle: values.regionalPrimeTitle,
        isRegionalPrime: values.isRegionalPrime,
        isMultipleApprover: values.isMultipleApprover,
        notifyUsers: values.notifyUsers,
        roletemplate: undefined,
        assignedRoleTemplates: undefined,
        reminderHours: values?.reminderHours || 2,
        // roleTemplates: isDemoClone ? state.demoBasicDetails.roleTemplates?.map(item => { return { ...item, id: undefined } }) : state.demoBasicDetails.roleTemplates,
        roleTemplates: isDemoClone ? demoBasicDetails?.assignedRoleTemplates?.map(item => { return { ...item, id: undefined } }) : demoBasicDetails?.assignedRoleTemplates,
        demoType: values.demoType,
        maxSchedule: values.maxSchedule || null,
        autoDestroy: values.autoDestroy !== undefined ? values.autoDestroy : false,
        vdiRequired: values.vdiRequired,
        isPreRegistration: values.isPreRegistration,
        isOneTime: values.isOneTime,
        scheduleRange: values.scheduleRange || null,
        schTimeLimit: (values.demoType === CONFIG.demoTypes.standardDemo && values.schTimeLimit * 60) || null,
        isManual: values.isManual,
        parameters: (values.parameters && values.parameters?.length > 0) ? values.parameters?.join(CONFIG.delimiters.selectFilter) : null,
        externalApis: ((values.isManual || values.demoType === CONFIG.demoTypes.standardDemo) ? null : [
            {
                id: isDemoClone ? undefined : oldDemo?.demoBasicDetails?.externalApis?.[0]?.id,
                url: values.createURL || null,
                methodType: values.createMethodType || null,
                phase: "BUILD_DEMO",
                // eslint-disable-next-line
                headerJson: values.createHeaderKeys && values.createHeaderKeys?.length !== 0 ? JSON.stringify(values.createHeaderKey?.reduce((acc, curr, index) => { (acc[curr] = values.createHeaderValue[index], acc) }, {})) : null,
                bodyJson: values.createBodyJson || JSON.stringify({}),
                authorisationJson: JSON.stringify({ userName: values.createAuthUserName, password: values.createAuthPassword }),
                // eslint-disable-next-line
                paramsJson: values.createParamKeys && values.createParamKeys?.length !== 0 ? JSON.stringify(values.createParamKey?.reduce((acc, curr, index) => (acc[curr] = demoBasicDetails.createParamValue[index], acc), {})) : null
            },
            {
                id: isDemoClone ? undefined : oldDemo?.demoBasicDetails?.externalApis?.[1]?.id,
                url: values.deleteURL || null,
                methodType: values.deleteMethodType || null,
                phase: "DELETE_DEMO",
                // eslint-disable-next-line
                headerJson: values.deleteHeaderKeys && values.deleteHeaderKeys?.length !== 0 ? JSON.stringify(values.deleteHeaderKey?.reduce((acc, curr, index) => (acc[curr] = values.deleteHeaderValue[index], acc), {})) : null,
                bodyJson: values.deleteBodyJson || JSON.stringify({}),
                authorisationJson: JSON.stringify({ userName: values.deleteAuthUserName, password: values.deleteAuthPassword }),
                // eslint-disable-next-line
                paramsJson: values.deleteParamKeys && values.deleteParamKeys?.length !== 0 ? JSON.stringify(values.deleteParamKey?.reduce((acc, curr, index) => (acc[curr] = demoBasicDetails.deleteParamValue[index], acc), {})) : null
            }
        ]),
        demoApprovers: getDemoApproversList(values.demoApprovers)?.map(item => { return { ...item, id: undefined } }) || [],
        demoRegionalPrimes: getRegionalManagersList(values.regionalPrimes) || [],
        // Demo CkEditor Contents
        componentDetails: demoEditorContent?.componentDetailsHtml === "" ? null : demoEditorContent?.componentDetailsHtml,
        connectivityDetails: demoEditorContent?.connectivityDetailsHtml === "" ? null : demoEditorContent?.connectivityDetailsHtml,
        description: demoEditorContent?.descriptionHtml === "" ? null : demoEditorContent?.descriptionHtml,

        // Demo Resources
        demoResources: demoResources?.map(item => { return { ...item, id: undefined } }),

        demoQuestions: isDemoClone
            ? [...(assignedAdditionalInfoQuestions?.length > 0
                ? assignedAdditionalInfoQuestions
                : []), ...(assignedPreRegisterQuestions?.length > 0
                    ? assignedPreRegisterQuestions
                    : [])]?.map(item => {
                        return { ...item, id: undefined }
                    }) : [...(assignedAdditionalInfoQuestions?.length > 0
                        ? assignedAdditionalInfoQuestions
                        : []), ...(assignedPreRegisterQuestions?.length > 0
                            ? assignedPreRegisterQuestions
                            : [])],
        demoDCStatuses: demoDCStatuses?.flatMap(item => resourcesDataCenter.includes(item.dataCenter.title) ? [item] : []),

        // Demo Collaterals
        collaterals: demoCollaterals?.assignedCollaterals,
        demoEzDemos: demoEzDemos,
        demoCapabilities: demoCapability,
    };

    const { isDemoComplete } = checkDemoFormCompletion(demo, checkForCompletion);
    const hasDemoChanged = true;
    const { isDemoDescUpdated, isDemoAccessUpdated, isDemoDemoGuideUpdated } = (demo?.id !== -1 || demo?.id === undefined) ? getFlags(demo, originalDemoObj) : {};

    /*
        Adding this condition to fix 'No changes made' on demo form when demo status is been changed
        Should render Demo save warning only when something has changed on demo form
    */
    dispatch(toggleIsFetchingDemosFlag(true))
    if (((checkForCompletion && isDemoComplete) || !checkForCompletion) && hasDemoChanged) {
        const requestData = { ...demo, allRoleTemplates: undefined };
        const contextPath = "/demoSolutionService/demos";
        const response = await aepDemo({
            method: demo.id === undefined || isDemoClone ? 'post' : 'put',
            url: demo.id === undefined || isDemoClone ? contextPath : `${contextPath}/${demo.id}`,
            params: demo.id && !isDemoClone && { onlyRoleTemplateUpdate },
            data: demo.id === undefined || isDemoClone ? requestData : { ...requestData, isDemoDescUpdated, isDemoAccessUpdated, isDemoDemoGuideUpdated }
        });
        const successMessage = demo.id !== undefined && !isDemoClone ? 'Demo updated successfully' : 'Demo created successfully';
        const errorMessage = demo.id !== undefined && !isDemoClone ? 'Unable to update demo' : 'Unable to create demo';
        onlyRoleTemplateUpdate = false;
        if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
            pushMessage(CONFIG.messageType.success, successMessage);
            const newCollaterals = state.demoCollaterals.newCollaterals;
            const collaterals = newCollaterals ? newCollaterals.filter(collateral => !demo.collaterals.includes(collateral)) : undefined;
            return {
                collaterals: collaterals?.length !== 0 ? collaterals : undefined,
                demoId: demo.id === undefined || isDemoClone ? response.data : demo.id
            }
        }
        else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
            pushMessage(CONFIG.messageType.warning, "Demo of the same name already exists")
        }
        else {
            pushMessage(CONFIG.messageType.error, errorMessage);
        }
    }
    else if (!hasDemoChanged) pushMessage(CONFIG.messageType.info, "No changes made");
    dispatch(toggleIsFetchingDemosFlag(false))
}

export const toggleIsFetchingDemosFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DEMOS,
        payload: flag
    };
}


export const clearDemos = () => {
    return {
        type: CLEAR_DEMOS
    }
}

export const resetDemoForm = (id, isClone) => {
    return {
        type: CLEAR_DEMO_FORM_TAB_DATA,
        payload: { id, isClone }
    }
}

export const updateDemosCurrentPage = (currentPageNumber, pageSize, demoId, demoIndex) => {
    return {
        type: UPDATE_DEMOS_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, demoId, demoIndex]
    }
};

export const updateDemoSaveWarning = (message) => {
    return {
        type: UPDATE_DEMO_SAVE_WARNING,
        payload: message
    };
};

export const checkDemoDependencies = async (demoId, viewDeletedItems = false) => {
    const response = await aepDemo.get(`/demoSolutionService/demos/${demoId}/dependencies`, { params: { viewDeletedItems } })
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
    else throw Error("Unable to get demo dependency list");
};

export const checkDemoResourceDependencies = async (demoId, resourceId, viewDeletedItems = false) => {
    const response = await aepDemo.get(`demoSolutionService/resources/${resourceId}/dependencies`, { params: { viewDeletedItems, demoId } })
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
    else throw Error("Unable to get demo dependency list");
};

export const checkDemoResourceInactiveDependencies = async (demoId, resourceId) => {
    const response = await aepDemo.get(`demoSolutionService/schedules/${demoId}/${resourceId}/scheduleDependencies`)
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
};


export const deleteDemo = async (demoId, hardDelete = false) => {
    const response = await aepDemo.delete("/demoSolutionService/demo/" + demoId, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Demo deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete demo");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
};

export const restoreDemo = (demoId) => async () => {
    const response = await aepDemo.put("/demoSolutionService/restore/demos/" + demoId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Demo restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore demo");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
};

export const currentDemoFilter = (filters, status, currentPage) => {
    return {
        type: LAST_DEMO_FILTER,
        payload: [filters, status, currentPage]
    }
}

export const clearFetchedDemos = () => {
    return {
        type: CLEAR_FETCHED_DEMOS
    }
}

export const updateScheduleWarning = (demoId, flag) => async (dispatch) => {
    const isAdd = flag === false ? "removed from" : "added to";
    const response = await aepDemo.patch(`/demoSolutionService/scheduleException/demos/${demoId}?scheduleException=${flag}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Demo ${isAdd} Schedule Exception`);
        return true
    }
    if (response.status === CONFIG.HTTP_STATUS.NOT_FOUND) pushMessage(CONFIG.messageType.error, "Demo not available");
    if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) pushMessage(CONFIG.messageType.success, "Your are not authorised to perform this operation");
}

export const fetchAllUsersForDemo = () => async (dispatch) => {
    const contextPath = '/userTemplateService/userslist/search'
    const response = await aepUser.get(contextPath, {
        params: {
            searchString: "",
            sslHandleIncluded: true
        }
    })
    dispatch({
        type: FETCH_ALL_USERS_FOR_DEMO,
        payload: response.data ? response.data.dataSet : []
    })
}

export const getAllDemosLite = (filterSort, filterPanel = false) => async (dispatch) => {
    const response = await aepDemo.get("/demoSolutionService/v1/lite/demos", {
        params: {
            filterSort
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: filterPanel ? FETCH_ALL_EXISTING_DEMOS : FETCH_ALL_DEMOS,
            payload: (filterPanel ? { dataSet: response.data } : [{ dataSet: response.data }]) || []
        });
        return response?.data || [];
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch demos");
}