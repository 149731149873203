import React, { Component } from 'react'
import { Eventcalendar, momentTimezone, CalendarPrev, CalendarNext, CalendarToday, } from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { fetchSchedulesOverDuration, fetchDemoPurposeTypes, deleteDemoSchedule, toggleDateChanged } from "../../../actions";
import { Spin, Row, Col, Popconfirm, Select, DatePicker, Popover } from "antd"
import moment from 'moment-timezone';
import CONFIG from '../../../config';
import { connect } from 'react-redux';
import { getSchedulerView, computeTime } from '../../../utils/scheduler';
import history from '../../../history';
import { Button } from "@avaya/neo-react"
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;
const { MonthPicker } = DatePicker;

momentTimezone.moment = moment;
let resources = [
    {
        id: 0,
        name: '',
    },
];
class LabDemoSchedulerMobiscroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            calendarDate: moment(moment(moment()).tz(props.timeZone)?.format("YYYY-MM-DD HH:mm:ss")).toDate(),
            resources: null,
            datePickerValue: moment(moment()).tz(props.timeZone),
            events: null,
            loading: false,
            currentEvent: null,
            view: {
                timeline: {
                    type: 'day',
                    timeCellStep: 30,
                    timeLabelStep: 60,
                },
            },
            viewType: 'day'
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getScheduleEvents();
        this.scrollToCurrentTime();
    }

    scrollToCurrentTime = () => {
        const ele = document.querySelector(".mbsc-ios.mbsc-schedule-time-indicator");
        if (ele !== null) {
            document.querySelector(".mbsc-ios.mbsc-schedule-time-indicator").scrollIntoView({
                block: "end",
                inline: "center",
                behavior: "smooth",
                alignToTop: false
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { hasDateChanged } = this.props;
        const ele = document.querySelector(".mbsc-ios.mbsc-schedule-time-indicator");
        if (this.props.timeZone !== prevProps.timeZone) {
            this.setState({ loading: true });
            this.getScheduleEvents();
            if (ele !== null) {
                this.scrollToCurrentTime();
            }
        }
        if (ele !== null && hasDateChanged) {
            this.props.toggleDateChanged(false);
            this.scrollToCurrentTime();
        }
    }

    getScheduleEvents = async () => {
        const { demo, fromDashboard, user, timeZone, schedule } = this.props;
        let events = []
        const startTime = fromDashboard ? moment(schedule?.startTime).tz(timeZone).startOf('day').toISOString() : moment.tz(moment(), timeZone).startOf('day').toISOString();
        const endTime = fromDashboard ? moment(schedule?.startTime).tz(timeZone).endOf('day').toISOString() : moment.tz(moment(), timeZone).endOf('day').toISOString();
        const demoId = fromDashboard ? schedule?.demo?.id : demo?.id
        const result = await this.props.fetchSchedulesOverDuration(demoId, startTime, endTime);
        const event = result?.map((event) => { return this.getEvents(event, this.props.timeZone, user?.id, 0) })
        Array.isArray(event) ? events.push(...event) : events.push(event);
        await this.setState({
            events,
            loading: false,
            calendarDate: moment(startTime).startOf('day').toDate(),
        });
    }

    getEvents = (schedule, timeZone, userId, resourceId) => {
        return {
            // id: schedule.id,
            scheduleId: schedule.id,
            start: moment(moment(schedule.startTime).subtract(schedule.startTimeBuffer, 'm').format("YYYY-MM-DD HH:mm:ss")).toISOString(),
            end: moment(moment(schedule.endTime).add(schedule.endTimeBuffer, 'm').format("YYYY-MM-DD HH:mm:ss")).toISOString(),
            endTime: schedule.endTime,
            startTime: schedule.startTime,
            scheduleStartTime: moment.tz(schedule.startTime, timeZone).format(CONFIG.dateFormats.scheduler),
            scheduleEndTime: moment.tz(schedule.endTime, timeZone).format(CONFIG.dateFormats.scheduler),
            bufferStartTime: !schedule.isMaintenance ? schedule.startTimeBuffer : undefined,
            startTimeBuffer: !schedule.isMaintenance ? schedule.startTimeBuffer : undefined,
            bufferEndTime: !schedule.isMaintenance ? schedule.endTimeBuffer : undefined,
            resourceId: resourceId,
            resource: 0,
            bgColor: !schedule.isMaintenance ? (schedule.createdBy.id === userId ? CONFIG.colors.userDemo : CONFIG.colors.demo) : CONFIG.colors.maintenance,
            title: !schedule.isMaintenance ? schedule?.createdBy?.userName : CONFIG.demoScheduler.eventText.maintenance,
            editable: false,
            user: schedule.createdBy,
            createdBy: schedule.createdBy,
            purpose: !schedule.isMaintenance ? schedule?.demoPurpose?.purpose : undefined,
            demoPurpose: !schedule.isMaintenance ? schedule?.demoPurpose : undefined,
            demo: schedule.demo,
            country: schedule.country,
            scheduleApproval: schedule?.scheduleApproval,
            managerEmail: schedule?.managerEmail,
            purposeAttributesSchedule: schedule?.purposeAttributesSchedule,
            parameters: schedule?.parameters,
            status: schedule?.status,
            isMaintenance: schedule?.isMaintenance,
            userAnswers: schedule?.userAnswers,
            schedule
        }
    }

    handleDateChange = async (value) => {
        const { viewType } = this.state;
        const { user } = this.props;
        if (value === null) return;
        this.props.toggleDateChanged(true);
        const [start, end] = computeTime(viewType, value, this.state.calendarDate, this.props.timeZone)
        this.setState({
            calendarDate: value,
            loading: true
        });
        let events = []
        await this.props.fetchSchedulesOverDuration(this.props.demo.id, start, end);
        const result = await this.props.fetchSchedulesOverDuration(this.props.demo.id, start, end);
        const event = result?.map((event) => { return this.getEvents(event, this.props.timeZone, user?.id, 0) })
        Array.isArray(event) ? events.push(...event) : events.push(event);
        await this.setState({
            events,
            loading: false
        });
    }

    handleViewChange = async (value) => {
        const { timeZone, user } = this.props;
        let calView = getSchedulerView(value);
        this.props.toggleDateChanged(true);
        const [start, end] = computeTime(value, null, this.state.calendarDate, timeZone)
        this.setState({
            view: calView,
            viewType: value,
            calendarDate: moment(start).toDate(),
            loading: true
        });
        let events = []
        await this.props.fetchSchedulesOverDuration(this.props.demo.id, start, end);
        const result = await this.props.fetchSchedulesOverDuration(this.props.demo.id, start, end);
        const event = result?.map((event) => { return this.getEvents(event, this.props.timeZone, user?.id, 0) })
        Array.isArray(event) ? events.push(...event) : events.push(event);
        await this.setState({
            events,
            loading: false
        });
    }

    renderViewOptions = () => {
        return CONFIG.schedulerViews?.map((view) => {
            return <Option key={view.value} value={view.value}>{view.text}</Option>
        })
    }

    onSelectedDateChange = async (event, inst) => {
        const { timeZone, user } = this.props;
        const { viewType } = this.state;
        this.props.toggleDateChanged(true);
        const [start, end] = computeTime(viewType, moment(event?.date), this.state.calendarDate, timeZone)
        this.setState({
            calendarDate: moment(event?.date).toDate(),
            loading: true
        })
        let events = []
        this.shouldSchedulerUpdate = true;
        const result = await this.props.fetchSchedulesOverDuration(this.props.demo.id, start, end);
        const data = result?.map((data) => { return this.getEvents(data, this.props.timeZone, user?.id, 0) })
        Array.isArray(data) ? events.push(...data) : events.push(data);
        await this.setState({
            events,
            loading: false
        });
    }

    customWithNavButtons = () => {
        return <React.Fragment>
            {this.state.viewType !== "month" ? < div >
                <DatePicker
                    onChange={this.handleDateChange}
                    allowClear={false}
                    value={moment(this.state.calendarDate)}
                    format="MM-DD-YYYY"
                />
            </div>
                :
                <MonthPicker onChange={this.handleDateChange}
                    value={moment(this.state.calendarDate)}
                    allowClear={false}
                    format="MM-YYYY"
                    className="cal-header-nav" />
            }
            <div className="md-event-listing-picker">
                <span style={{ fontSize: "14px" }}>Selected View &nbsp;</span>
                <Select value={this.state.viewType} style={{ width: 120 }} onChange={this.handleViewChange}>
                    {this.renderViewOptions()}
                </Select>
            </div>
            <div style={{ float: "right" }} className="md-custom-range-view-controls">
                <span style={{ width: "50px" }}><CalendarPrev className="calendar-prev" /></span>
                <span className='calendar-today' ><CalendarToday /></span>
                <CalendarNext className='calendar-next' />
            </div>
        </React.Fragment >;
    }

    handleCellClick = (event, inst) => {
        const timezone = this.props.timeZone;
        const { user } = this.props;
        const { viewType, events } = this.state;
        if (viewType !== "day") {
            this.setState({
                view: {
                    timeline: {
                        type: 'day',
                        timeCellStep: 30,
                        timeLabelStep: 60,
                    },
                },
                viewType: "day",
                loading: true,
                calendarDate: moment(event?.date).toDate()
            })
            const startTime = moment(event?.date).tz(timezone, true).startOf('day').toISOString();
            const endTime = moment(event?.date).tz(timezone, true).endOf('day').toISOString();
            this.shouldSchedulerUpdate = true;
            this.props.fetchSchedulesOverDuration(this.props.demo.id, startTime, endTime)
                .then((result) => {
                    const data = result?.map((data) => { return this.getEvents(data, this.props.timeZone, user?.id, 0) })
                    Array.isArray(data) ? events.push(...data) : events.push(data);
                    this.setState({
                        events,
                        loading: false
                    });
                    return false;
                })
        }
        else return false;
    }

    handleDelete = async (eventItem) => {
        const { demo, fromDashboard, timeZone, schedule, user } = this.props;
        const startTime = fromDashboard ? moment(schedule?.startTime).tz(timeZone).startOf('day').toISOString() : moment.tz(moment(), timeZone).startOf('day').toISOString();
        const endTime = fromDashboard ? moment(schedule?.startTime).tz(timeZone).endOf('day').toISOString() : moment.tz(moment(), timeZone).endOf('day').toISOString();
        await this.props.deleteDemoSchedule(eventItem.scheduleId, false, eventItem.demo?.demoType)
            .then(async () => {
                let events = []
                await this.setState({ loading: true });
                const result = await this.props.fetchSchedulesOverDuration(demo.id, startTime, endTime);
                const event = result && result?.map((event) => { return this.getEvents(event, timeZone, user?.id, 0) })
                Array.isArray(event) ? events.push(...event) : events.push(event);
                await this.setState({
                    loading: false,
                    events
                });
            })
    }

    handleUpdate = async (event) => {
        await this.props.fetchDemoPurposeTypes(true, event?.user);
        history.replace({
            pathname: `/scheduler/lab/${event?.scheduleId}/edit`,
            fromDashboard: true,
            isEdit: Date.now() + Math.random(),
            pathValues: this.props.pathValues
        });
        document.getElementById("tab-pane").scrollIntoView();
    }

    renderScheduleEvent = (data) => {
        const { user, demo } = this.props;
        const { viewType } = this.state;
        const ev = data.original;
        const timezone = this.props.timeZone;
        const startUTC = moment(ev?.start).tz(timezone, true).utc();
        const endUTC = moment(ev?.end).tz(timezone, true).utc();
        const utcNow = moment().tz(timezone, true).utc();
        const isEditable = demo && ev.demo ? ev.demo.id === demo.id : undefined;
        const color = data.color;
        const isMaintenance = ev?.isMaintenance;
        const isNew = isMaintenance ? false : data?.title === "New event" || data?.title === "New Schedule";
        const isCurrentUser = user?.id === ev?.createdBy?.id;
        const isSameDaySchedule = moment.tz(ev.start, timezone).isSame(moment.tz(ev.end, timezone), 'day');
        const actualStart = moment.tz(ev?.start, timezone).add(ev?.startTimeBuffer || 0, 'm');
        const actualEnd = moment.tz(ev?.end, timezone).subtract(ev?.endTimeBuffer || 0, 'm');
        const isScheduleStatusPending = demo && ev.demo ? ev.demo.id === demo.id && ((ev?.status === "PENDING") || (ev?.status === "NA")) : undefined;
        const isStartSameDay = moment(this.state.calendarDate).tz(timezone, true).isSame(moment.tz(ev.start, timezone), 'day');
        const eventDisplay = <div className="md-timeline-template-event" style={
            isMaintenance ?
                {
                    borderColor: color,
                    background: "rgb(226 0 0 / 50%)",
                    color: "black",
                    fontWeight: "500", fontFamily: 'Noto Sans',
                }
                :
                (isCurrentUser) ? {
                    borderColor: color,
                    background: "rgb(96 232 26 / 60%)",
                    color: "black",
                    borderLeft: (isSameDaySchedule && viewType === "day") ? `${ev?.startTimeBuffer * 2.123}px solid rgb(255 255 255 / 60%)` : (viewType === "day" && isStartSameDay) ? `${ev?.startTimeBuffer * 2.123}px solid rgb(255 255 255 / 60%)` : "0px",
                    fontSize: "11px",
                    fontWeight: "500", fontFamily: 'Noto Sans',
                } :
                    {
                        borderColor: color,
                        background: "rgb(143 30 214 / 30%)",
                        color: "black",
                        height: "auto",
                        fontWeight: "500",
                        fontFamily: 'Noto Sans',
                    }

        }
            title={`${ev.title}, ${data.start} - ${data.end}`}
        >
            <div className="md-timeline-template-event-cont" style={{ height: "40px", overflow: "hidden" }}>
                <span className="md-timeline-template-title" style={isMaintenance ?
                    { color: "black", fontSize: "11px", paddingLeft: "2px" }
                    : { color: "black", fontSize: isNew ? "10px" : "11px", display: "flex", overflow: "hidden" }}>{ev.title}</span>
                {isMaintenance && <br />}

                <span className="md-timeline-template-time" style={isMaintenance ? { color: "black", fontSize: "8px", paddingLeft: "2px", display: "flex", overflow: "hidden" } : { color: "black", fontSize: "8px", display: "flex", overflow: "hidden" }}>
                    {data?.start} - {data?.end}
                </span>
            </div>
        </div >

        const content = <div style={{ width: '300px' }}>
            <Row type="flex" align="middle">
                <Col span={2}>
                    <div className="status-dot" style={{ backgroundColor: (!isNew && isCurrentUser) ? "rgb(96 232 26 / 60%)" : (!isNew && !isCurrentUser) ? "rgb(143 30 214 / 30%)" : isMaintenance ? "rgb(226 0 0 / 50%)" : "none" }} />
                </Col>
                <Col span={22} className="overflow-text">
                    <span className="header2-text" title={data?.title} style={{ fontWeight: "bold", fontFamily: 'Noto Sans', }}>{data?.title}</span>
                </Col>
            </Row>
            <Row type="flex" align="middle">
                <Col span={2} />
                <Col span={22} className="overflow-text">
                    {ev.demo && <span className="header1-text header2-text" title={ev.demo?.title}>{ev.demo?.title}</span>}
                    <br />
                    {ev.purpose && <span className="header1-text header2-text" title={ev.purpose}>{`Purpose - ${ev.purpose}`}</span>}
                </Col>
            </Row>
            <Row type="flex" align="middle">
                <Col span={2}>
                    <div />
                </Col>
                <Col span={22}>
                    {
                        (ev.user && ev.user.id === this.props.user.id && ev?.startTimeBuffer) ?
                            <div>
                                <span className="header1-text">{actualStart.format("hh:mm")} <span className="header1-text header2-text">{actualStart.format("DD MMM")}</span> - {actualEnd.format("hh:mm")} <span className="header1-text header2-text">{actualEnd.format("DD MMM")}</span></span>
                                <br />
                                {ev?.startTimeBuffer !== 0 && <React.Fragment><span className="header2-text header1-text">{`Buffer Start Time  - ${moment.tz(ev?.start, timezone).format(CONFIG.dateFormats.userTime)}`}</span><br /></React.Fragment>}
                            </div>
                            :
                            (<span className="header1-text ">
                                {moment(ev?.start).tz(timezone, true).format("hh:mm")} <span className="header1-text header2-text">{moment.tz(ev?.start, timezone).format("DD MMM")}</span> - {moment.tz(ev?.end, timezone).format("hh:mm")} <span className="header1-text header2-text">{moment.tz(ev?.end, timezone).format("DD MMM")}</span>

                            </span>)

                    }
                </Col>
            </Row>
            {
                (ev.user ? (ev.user.id === user.id || user.roleTemplate.templateType === CONFIG.roleTypes.admin || user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin) : false) && !isMaintenance && (startUTC > utcNow || endUTC > utcNow) && isEditable && viewType === "day" &&
                <Row gutter={48} className="vertical-spacing">
                    <Col span={12}>
                        <Popconfirm title="Confirm delete?" okText="Yes"
                            cancelText="No" onConfirm={() => this.handleDelete(ev)}>
                            <Button size="compact" variant="secondary" className="full-width">Delete</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={12}>
                        {isScheduleStatusPending && startUTC > utcNow && <Button size="compact" className="full-width" onClick={() => this.handleUpdate(ev)}>Edit</Button>}
                    </Col>
                </Row>
            }
        </div>
        return (
            <Popover placement="top" content={content} trigger="hover">
                {eventDisplay}
            </Popover>
        )
    }

    render() {
        const { calendarDate } = this.state;
        return (
            <Spin indicator={antIcon} spinning={this.state.loading}>
                <div>
                    <Eventcalendar
                        dataTimezone="utc"
                        displayTimezone={this.props.timeZone}
                        timezonePlugin={momentTimezone}
                        selectedDate={calendarDate}
                        renderHeader={this.customWithNavButtons}
                        theme="ios"
                        dateFormatLong="MMMM D, YYYY"
                        themeVariant="light"
                        clickToCreate={false}
                        dragToCreate={false}
                        dragToMove={false}
                        view={this.state.view}
                        renderScheduleEvent={this.renderScheduleEvent}
                        data={this.state.events}
                        onCellClick={this.handleCellClick}
                        resources={resources}
                        slots={null}
                        dragTimeStep={30}
                        onSelectedDateChange={this.onSelectedDateChange}
                        cssClass="md-event-listing md-resource-details"
                    />
                </div>
            </Spin>
        )
    }
}


const mapStateToProps = ({ user, viewport, newSchedule }) => {
    return {
        user: user.profile,
        viewport,
        hasDateChanged: newSchedule.hasDateChanged
    };
};


export default connect(
    mapStateToProps,
    {
        fetchSchedulesOverDuration,
        fetchDemoPurposeTypes,
        deleteDemoSchedule,
        toggleDateChanged
    }
)(LabDemoSchedulerMobiscroll);