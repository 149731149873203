import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    toggleBackButton,
    selectTitle,
    saveReportRoleTemplates,
    cancelReportRoleTemplates,
    fetchAdminRoleTemplates,
    fetchRoleTemplates,
    updateReportRoleTemplate,
    clearSaveReports,
    postReport,
    toggleSaveReportVisible,
    fetchReportColumns,
    updateReportDetails,
    resetReportForm
} from "../../actions";
import { Form, Row, Col, Input, Button, Switch, Modal, Spin } from 'antd';
import CONFIG from "../../config";
import ReportRoleTemplates from '../ManageReports/ReportRoleTemplates';
import FormActionButtons from './FormActionButtons';
const { TextArea } = Input;
const SaveReport = Form.create({ name: 'save_report' })(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                visible: false,
                isFetching: true
            }
        }

        async componentDidMount() {
            this.props.toggleBackButton(false);
            this.props.selectTitle("Save Reports");
            const entityId = this.props.reportFilter?.filterTable?.id;
            this.reportColumns = await this.props.fetchReportColumns(entityId);
            const { userRoleTemplate } = this.props;
            const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
            const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;
            fetchAllRoleTemplates()
                .then(() => {
                    this.setState({
                        isFetching: false
                    })
                });

            await this.props.updateReportDetails({
                reportId: "-2",
                reportData: { ...this.props.form.getFieldsValue(), assignedRoleTemplates: undefined },
                isClone: false,
                isGeneralReport: false,
            });
        }

        componentWillUnmount() {
            this.setState({
                isFetching: true
            });
            this.props.resetReportForm('-2', false, false);
        }

        showModal = () => {
            this.setState({
                visible: true
            });
        };

        handleRoleTemplatesCancel = e => {
            this.props.cancelReportRoleTemplates('-2', false, false);
            this.setState({
                visible: false
            });
        };

        handleRoleTemplatesAssign = () => {
            this.props.saveReportRoleTemplates('-2', false, false);
            this.setState({ visible: false });
        }

        renderAssignButton = () => {
            return <Button className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
        }


        handleSubmit = () => {
            const { reportFilter } = this.props;
            let criteriaJson = { ...reportFilter, reportColumns: this.reportColumns };
            const id = 'saveReport';
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.postReport(id, values, false, criteriaJson, false)
                }
            });
            this.props.closeModal();
        }

        getAssignedRoleTemplates = (isSuperAdminUser) => {
            const { reportDetails } = this.props;
            const { roleTemplates } = reportDetails || [];
            let assignedRoleTemplates = [];
            if (roleTemplates) {
                roleTemplates.forEach(obj => {
                    const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                    const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                    if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                        assignedRoleTemplates.push(obj.roleTemplate.title);
                    }
                })
            }
            return assignedRoleTemplates;
        }

        renderFooterButtons = () => {
            return [
                <FormActionButtons handleSubmit={this.handleSubmit} handleCancel={() => {
                    this.props.toggleSaveReportVisible(false)
                    this.props.closeModal();
                }} okText={"Save"} />
            ]
        }

        render() {
            const { getFieldDecorator } = this.props.form;
            const { roleTemplates, user, reportDetails, isSaveReportVisible } = this.props;
            const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
            const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
            return (
                <Modal
                    className="save-report-modal"
                    title="Save Report"
                    visible={isSaveReportVisible}
                    footer={this.renderFooterButtons()}
                    bodyStyle={{ maxHeight: "78vh" }}
                    onCancel={() => {
                        this.props.toggleSaveReportVisible(false)
                        this.props.closeModal()
                    }}
                    maskClosable={false}
                    destroyOnClose
                >
                    <Spin spinning={this.state.isFetching}>
                        <Row gutter={48}>
                            <Col xl={10} sm={12} xs={24}>
                                <Form.Item label="Title">
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input the title of the report"
                                            }
                                        ]
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xl={10} sm={12} xs={24}>
                                <Form.Item label="Assign Role Template">
                                    {getFieldDecorator("roletemplate", {
                                        initialValue: assignedRoleTemplates.length !== 0
                                            ? assignedRoleTemplates
                                            : "None"
                                    })(<Input disabled addonAfter={this.renderAssignButton()} />)}
                                    <ReportRoleTemplates
                                        visible={this.state.visible}
                                        handleCancel={this.handleRoleTemplatesCancel}
                                        handleAssign={this.handleRoleTemplatesAssign}
                                        assignedRoleTemplates={reportDetails?.roleTemplates}
                                        allRoleTemplates={roleTemplates?.all}
                                        isClone={false}
                                        isGeneralReport={false}
                                        allRoleTemplatesAdminAccess={reportDetails?.allRTAdminAccess}
                                        allRoleTemplatesUserAccess={reportDetails?.allRTUserAccess}
                                        recordId={"-2"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={24} xl={4} xs={24}>
                                <Form.Item label="Status">
                                    {getFieldDecorator("isActive", {
                                        valuePropName: "checked",
                                    })(<Switch
                                        checkedChildren="Active"
                                        unCheckedChildren="Inactive"
                                    />)}

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="report-description" gutter={48}>
                            <Form.Item label="Report Description">
                                {getFieldDecorator("description")
                                    (<TextArea rows={4} />)
                                }
                            </Form.Item>
                        </Row>
                    </Spin>
                </Modal>
            )
        }
    }
)


const mapStateToProps = ({ roleTemplates, viewport, user, currentReport }) => {
    const actualReport = currentReport.reportDetails.find(item => parseInt(item?.id) === parseInt("-2"))?.details;
    return {
        timeZoneName: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        user: user.profile,
        roleTemplates,
        viewport,
        reportDetails: actualReport,
        isSaveReportVisible: currentReport.isSaveReportVisible
    };
};
export default connect(mapStateToProps, {
    toggleBackButton,
    selectTitle,
    saveReportRoleTemplates,
    cancelReportRoleTemplates,
    fetchAdminRoleTemplates,
    fetchRoleTemplates,
    updateReportRoleTemplate,
    clearSaveReports,
    postReport,
    fetchReportColumns,
    toggleSaveReportVisible,
    updateReportDetails,
    resetReportForm
})(SaveReport)