import React from 'react';
import { Table, Popconfirm, Divider, Button, Icon, Row, Col, Form } from 'antd';
import { connect } from 'react-redux';
import DragListView from 'react-drag-listview';

import {
    deleteDemoCollateral,
    reorderDemoCollaterals,
    addNewDemoCollateral,
    updateDemoCollateral,
    uploadDemoCollateral,
    toggleIsFetchingDemosFlag,
    fetchDataCenters,
    fetchDeletedCollaterals,
    restoreDemoCollateral,
    fetchDemo,
    addOrUpdateDemoCollateral
} from '../../actions';
import CONFIG from '../../config';
import StatusIcon from '../common/StatusIcon';
import CollateralForm from './CollateralForm';
import DeletedCollateralsModal from './DeletedCollateralsModal';
import StatusFilter from '../common/StatusFilter';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import { camelToTitleCase } from '../../utils/strings'
import { deleteCollaterals } from '../../actions';

class DemoCollateral extends React.Component {
    state = {
        visible: false,
        currentCollateral: {},
        editMode: false,
        viewDeletedCollaterals: false,
        status: true,
        reorder: [],
        sortedInfo: null,
        filters: null
    };

    viewDeletedItems = false;

    constructor(props) {
        super(props);

        this.dragItems = [
            {
                title: "",
                key: "operate",
                align: 'center',
                render: () => <Icon className="drag-handle" type="drag" />
            }
        ]
        this.columns = [
            {
                title: 'Type',
                dataIndex: 'collateralType',
                key: 'type',
                align: 'center',
                width: '15%',
                sorter: (a, b) =>
                    a.collateralType.name.toLowerCase().localeCompare(b.collateralType.name.toLowerCase()),
                filters: this.props.collateralTypes?.map(collateralType => {
                    return {
                        text: collateralType.name,
                        value: collateralType.name
                    }
                }),
                onFilter: (value, record) => record['collateralType']['name'].toString().toLowerCase().includes(value.toLowerCase()),
                render: (collateralType) => collateralType.name,

            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                align: 'left',
                sorter: (a, b) =>
                    a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
                filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, camelToTitleCase('title')),
                onFilter: (value, record) => record['title'].toString().toLowerCase().includes(value.toLowerCase()),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                align: 'center',
                sorter: (a, b) => a['description'] === null ? -1 : b['description'] === null ? 1 : a['description']?.toLowerCase().localeCompare(b['description']?.toLowerCase()),
                filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, camelToTitleCase('description')),
                onFilter: (value, record) => record.description?.toString().toLowerCase().includes(value.toLowerCase()),
            },
            {
                title: 'Status',
                dataIndex: 'isActive',
                key: 'status',
                width: '15%',
                render: (status) => <StatusIcon status={status} />,
                align: 'center'
            },
            {
                title: 'Action',
                key: 'action',
                width: '15%',

                render: (option, record) => {
                    return (
                        <div>
                            <span onClick={() => this.handleEdit(record)} className="link">Edit</span>
                            <Divider type="vertical" />
                            <Popconfirm title="Confirm Delete?" onConfirm={() => this.handleDelete(record.id)} okText="Yes" cancelText="No">
                                <span className="link" >Delete</span>
                            </Popconfirm>
                        </div>
                    )
                },
                align: 'center'
            },
        ];
        this.deletedCollateralsActionColumn = {
            title: 'Action',
            key: 'action',
            width: '15%',

            render: (option, record) => {
                return (
                    <div>
                        <Popconfirm title="Permanently Delete?" onConfirm={() => this.handleDelete(record.id)}>
                            <span className="link" >Delete</span>
                        </Popconfirm>
                        <Divider type="vertical" />
                        <Popconfirm title="Confirm restore?" onConfirm={() => this.handleRestore(record.id)}>
                            <button aria-label="Click" className="link">Restore</button>
                        </Popconfirm>
                    </div>
                )
            },
            align: 'center'
        }
        const that = this;
        this.dragProps = {
            async onDragEnd(fromIndex, toIndex) {
                const newCollaterals = that.props.demoCollaterals;
                const item = newCollaterals.splice(fromIndex, 1)[0];
                newCollaterals.splice(toIndex, 0, item);
                await that.props.reorderDemoCollaterals(newCollaterals);
                that.setState({ reorder: newCollaterals })
            },
            handleSelector: "i"
        };
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    handleFilter = (dataIndex, confirm) => {
        dataIndex = dataIndex?.trim();
        if (!dataIndex) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else confirm()
    };

    handleClear = clearFilters => {
        clearFilters();
    };


    componentDidMount() {
        this.props.fetchDataCenters();
    }

    handleEdit = (collateralData) => {
        let selectedCollateral = this.props.demoCollaterals.find(collateral => collateral.id === collateralData.id);
        this.setState({
            currentCollateral: selectedCollateral,
            visible: true,
            editMode: true
        });
    }

    handleDelete = (collateralId) => {
        if (this.state.viewDeletedCollaterals) {
            this.props.deleteCollaterals(collateralId);
        }
        else
            this.props.deleteDemoCollateral(collateralId)
    }

    handleRestore = async (collateralId) => {
        const { demoId } = this.props
        const response = await this.props.restoreDemoCollateral(collateralId)
        if (response) {
            await this.props.fetchDeletedCollaterals(demoId, this.viewDeletedItems);
            await this.props.fetchDemo({ demoId, updateCollateral: true });
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleAdd = (file, formValues, isFile) => {
        this.props.uploadDemoCollateral(file, formValues, this.state.currentCollateral, isFile)
            .then(() => {
                this.setState({
                    visible: false,
                    editMode: false,
                    currentCollateral: {}
                });
            })
    };

    handleCancel = () => {
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({
            visible: false,
            currentCollateral: {},
            editMode: false
        });
    };

    handleDeleteModalOk = () => {
        this.setState({
            viewDeletedCollaterals: false
        })
    }

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    showDeletedCollateralsModal = () => {
        this.viewDeletedItems = true;
        this.props.fetchDeletedCollaterals(this.props.demoId, this.viewDeletedItems).then(() => {
            this.setState({
                viewDeletedCollaterals: true
            })
        })
    }

    getFilteredData = (status) => {
        const { demoCollaterals } = this.props;

        let filteredData = status !== undefined
            ? demoCollaterals?.filter(collateral => collateral?.isActive === status)
            : demoCollaterals;
        return filteredData
    }

    handleStatusChange = (value) => {

        let filteredData = this.getFilteredData(value)

        this.setState({ status: value, filteredData })
    }

    handleChange = (_, onFilter, sorter) => {
        this.setState({
            sortedInfo: sorter,
            filters: onFilter
        });
    };

    checkDragDisabled = () => {
        let dragDisabled = false;

        if (this.state?.status !== true) {
            dragDisabled = true
        }

        if (this.state.sortedInfo !== null) {
            if (this.state.sortedInfo?.order !== undefined) {
                dragDisabled = true
            }
        }

        if (this.state.filters !== null) {
            if (this.state.filters?.type?.length > 0 || this.state.filters?.title?.length > 0 || this.state.filters?.description?.length > 0) {
                dragDisabled = true
            }
        }

        return dragDisabled
    }

    render() {
        const { collateralTypes, dataCenters, isUploading } = this.props;
        const { viewport } = this.props;
        let deletedCollateralsColumns = [...this.columns];
        deletedCollateralsColumns[deletedCollateralsColumns.length - 1] = this.deletedCollateralsActionColumn;
        const tableData = this.getFilteredData(this.state.status)
        const isDragDisabled = this.checkDragDisabled();
        return (
            <div className={!viewport.isMobileView ? "vertical-spacing" : "vertical-spacing-2"}>
                <Button aria-label="Click" id='add-button' className={!viewport.isMobileView ? "primary-action-button-bordered" : "primary-action-button-bordered bottom-spacing-2"} onClick={this.showModal}>
                    Add
                    <Icon type="plus-circle" />
                </Button>
                {this.props.isEditMode && <Button aria-label="Click" className={!viewport.isMobileView ? "primary-action-button-bordered horizontal-spacing" : "primary-action-button-bordered horizontal-spacing bottom-spacing-2"} onClick={this.showDeletedCollateralsModal}>
                    View Deleted Collaterals
                </Button>}
                <Row className="vertical-spacing">
                    <Col xl={6} sm={6} xs={24}>
                        <StatusFilter onChange={this.handleStatusChange} value={this.state.status} />
                    </Col>
                </Row>
                <DragListView {...this.dragProps}>
                    <Table
                        size='middle'
                        className="responsive-container vertical-spacing"
                        columns={!isDragDisabled ? [...this.dragItems, ...this.columns] : this.columns}
                        dataSource={tableData}
                        bordered
                        pagination={false}
                        rowKey={(record) => record.id}
                        onChange={this.handleChange}
                    />
                </DragListView>
                {this.state.visible && <CollateralForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    onAdd={this.handleAdd}
                    collateralTypes={collateralTypes}
                    dataCenters={dataCenters}
                    currentCollateral={this.state.currentCollateral}
                    editMode={this.state.editMode}
                    isUploading={isUploading}
                />}
                <DeletedCollateralsModal visible={this.state.viewDeletedCollaterals} onDeleteModalOk={this.handleDeleteModalOk} columns={deletedCollateralsColumns} deletedCollaterals={this.props.deletedCollaterals} okButtonText="Ok" />
            </div>
        );
    }
}

const mapStateToProps = ({ openTabDemoDetails, viewport, dataCenters, demoCollaterals }) => {
    const { assignedCollaterals } = openTabDemoDetails?.currentTabData?.demoCollaterals || []
    return {
        collateralTypes: demoCollaterals.collateralTypes,
        dataCenters: dataCenters.data,
        demoCollaterals: assignedCollaterals,
        viewport,
        isUploading: demoCollaterals.isUploading,
        deletedCollaterals: demoCollaterals.deletedCollaterals,
        openTabDemoDetails
    };
}

const EditableDemoCollateralTable = Form.create()(DemoCollateral);

export default connect(
    mapStateToProps,
    {
        deleteDemoCollateral,
        reorderDemoCollaterals,
        addNewDemoCollateral,
        updateDemoCollateral,
        uploadDemoCollateral,
        toggleIsFetchingDemosFlag,
        fetchDataCenters,
        fetchDeletedCollaterals,
        deleteCollaterals,
        restoreDemoCollateral,
        fetchDemo,
        addOrUpdateDemoCollateral
    }
)(EditableDemoCollateralTable);
