import axios from "axios";
import pushMessage from "../components/common/PushMessage";
import CONFIG from "../config";
import openInfo from '../components/common/Info';
import { removeAllCookies } from '../utils/cookies';
import history from '../history';


/* Axios instances */

const validateStatusCode = (status) => {
  if (status === CONFIG.HTTP_STATUS.FORBIDDEN) {
    pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden);
    return false;
  }
  else if (status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
    openInfo("ERROR", CONFIG.warningMessages.unauthorized, () => {
      removeAllCookies();
      window.location.href = process.env.REACT_APP_SAML_LOGOUT;
      history.push('/home')
    }, "LOGIN")
    return false;
  }
  else if (status === CONFIG.HTTP_STATUS.REQUEST_TIMEOUT) {
    openInfo("ERROR", CONFIG.warningMessages.requestTimeout, () => {
      removeAllCookies();
      window.location.href = process.env.REACT_APP_SAML_LOGOUT;
      history.push('/home')
    }, "LOGIN")
    return false;
  }
  else return true;
}

export const aepDemo = axios.create({
  baseURL: process.env.REACT_APP_DEMO_SERVICE_API_URL,
  validateStatus: (status) => {
    return validateStatusCode(status)
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

export const aepReport = axios.create({
  baseURL: process.env.REACT_APP_REPORT_SERVICE_API_URL,
  validateStatus: (status) => {
    return validateStatusCode(status);
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

export const aepSchedule = axios.create({
  baseURL: process.env.REACT_APP_SCHEDULE_SERVICE_API_URL,
  validateStatus: (status) => {
    return validateStatusCode(status);
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

export const aepNewsNotificationSupport = axios.create({
  baseURL: process.env.REACT_APP_NEWS_SERVICE_API_URL,
  validateStatus: (status) => {
    return validateStatusCode(status);
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

export const aepUser = axios.create({
  baseURL: process.env.REACT_APP_USER_SERVICE_API_URL,
  validateStatus: (status) => {
    return validateStatusCode(status);
  },

  headers: {
    'Content-Type': 'application/json',
  }

});

export const aepNetwork = axios.create({
  baseURL: process.env.REACT_APP_NETWORK_SERVICE_API_URL,
  validateStatus: (status) => {
    return validateStatusCode(status);
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

export const aepLDAP = axios.create({
  baseURL: process.env.REACT_APP_LDAP_SERVICE_API_URL,
  validateStatus: (status) => { return true; },
  headers: {
    'Content-Type': 'application/json'
  }
});


export const aepEmail = axios.create({
  baseURL: process.env.REACT_APP_EMAIL_TEMPLATE_SERVICE_API_URL,
  validateStatus: (status) => {
    return validateStatusCode(status);
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

export const aepSFDC = axios.create({
  baseURL: process.env.REACT_APP_SCHEDULE_SERVICE_API_URL,
  // validateStatus: (status) => {
  //   return validateStatusCode(status);
  // },
  headers: {
    'Content-Type': 'application/json'
  }
});

/* Axios request handler */

const requestHandler = (request, token) => {
  if (token) request.headers['aep-header'] = token;
  return request;
};

/* Axios Interceptors */

export const initializeInterceptors = (token) => {
  aepDemo.interceptors.request.use(
    request => requestHandler(request, token)
  );

  aepReport.interceptors.request.use(
    request => requestHandler(request, token)
  );

  aepNewsNotificationSupport.interceptors.request.use(
    request => requestHandler(request, token)
  );

  aepSchedule.interceptors.request.use(
    request => requestHandler(request, token)
  );

  aepUser.interceptors.request.use(
    request => requestHandler(request, token)
  );

  aepNetwork.interceptors.request.use(
    request => requestHandler(request, token)
  );

  aepLDAP.interceptors.request.use(
    request => requestHandler(request, token)
  );

  aepEmail.interceptors.request.use(
    request => requestHandler(request, token)
  );
}


