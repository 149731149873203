import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, Table } from 'antd';

import {
    toggleIsFetchingRoleTemplatesFlag,
    fetchUserRoleTemplates,
    updateAdminRoleTemplates
} from '../../actions';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';

class AdminRoleTemplates extends React.Component {

    state = {
        selectAllDisabled: false
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    columnFilters = {
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "title"),
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        onFilter: (value, record) =>
            record.title
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    }

    handleFilter = (title, confirm) => {
        title = title?.trim();
        if (!title && !this.state.selectAllDisabled) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else {
            this.setState({ selectAllDisabled: !!title })
            confirm()
        }
    }

    handleClear = (clearFilters) => {
        this.setState({ selectAllDisabled: false })
        clearFilters();
    }

    componentDidMount() {
        this.props.toggleIsFetchingRoleTemplatesFlag(true);
        this.props.fetchUserRoleTemplates(this.props.roleTemplateId);
    }

    isRoleTemplateChecked = (roleTemplateId) => {
        const { assignedRoleTemplates } = this.props;
        const currentRoleTemplate = assignedRoleTemplates?.find(role => role.roleTemplate.id === roleTemplateId);
        return currentRoleTemplate && currentRoleTemplate.adminAccess === CONFIG.roleTemplateAccess.readOnly;
    }

    render() {
        const { roleTemplates, isFetching, allAdminRTAccess, updateAdminRoleTemplates } = this.props;
        const { selectAllDisabled } = this.state;
        this.columns = [
            {
                title: "Title",
                dataIndex: "title",
                key: "title",
                width: '80%',
                align: "left",
                ...this.columnFilters
            },
            {
                title: <span className="primary-text-color">Admin</span>,
                children: [
                    {
                        title: (
                            <Checkbox
                                onChange={(e) => updateAdminRoleTemplates(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.admin)}
                                checked={allAdminRTAccess === CONFIG.roleTemplateAccess.readOnly}
                                disabled={(roleTemplates && roleTemplates.length === 0) || selectAllDisabled}
                            />
                        ),
                        key: "adminReadAccess",
                        align: "center",
                        width: '20%',
                        render: (access, record) => (
                            <Checkbox
                                onChange={(e) => updateAdminRoleTemplates(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.admin, { id: record.id, title: record.title })}
                                checked={this.isRoleTemplateChecked(record.id)}
                            />
                        )
                    }
                ]
            }
        ]
        return (
            <Table
                size='middle'
                className={'responsive-container top-spacing'}
                bordered
                columns={this.columns}
                loading={isFetching}
                dataSource={roleTemplates || []}
                rowKey={(record) => record.id}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1
                }}
            />
        );
    }
}

const mapStateToProps = ({ roleTemplates, roleTemplate }) => {
    return {
        roleTemplates: roleTemplates.all,
        isFetching: roleTemplates.isFetching,
        assignedRoleTemplates: roleTemplate.roleTemplates,
        allAdminRTAccess: roleTemplate.allAdminRTAccess,
        templateType: roleTemplate.templateType
    }
}

export default connect(
    mapStateToProps,
    {
        toggleIsFetchingRoleTemplatesFlag,
        fetchUserRoleTemplates,
        updateAdminRoleTemplates
    }
)(AdminRoleTemplates);