import React from 'react'
import { Collapse, Icon, Row, Col, Alert, Form, DatePicker, Radio, Checkbox } from 'antd'
import { Form as NeoForm, TextInput, Table, Button, BasicModal, Icon as NeoIcon, TextArea } from "@avaya/neo-react"
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import CONFIG from '../../../config';
import { Select, Input } from 'antd5';

import {
    renderVerticalOptions
} from "../../common/Scheduler"

const { Option } = Select;
const { Panel } = Collapse;
class ScheduleOccurenceDetails extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            modalTitle: "",
        }
    }


    handleSeeDetails = (type, schedules = [], modalTitle) => {
        let ids = []
        let filteredRecords = []
        schedules.forEach(item => {
            if (!ids.includes(item.id)) {
                filteredRecords.push(item)
                ids.push(item.id)
            }
        })
        this.setState({
            conflictType: type,
            showConflictWindow: true,
            modalTitle: modalTitle,
            checkAvailabilitySchedule: filteredRecords

        })
    }

    renderModalFooterButtons = () => {
        return [
            <Button key="cancel" onClick={this.handleModalClose}> Close </Button>,
        ];

    }

    handleModalClose = () => {
        this.setState({
            conflictType: "",
            showConflictWindow: false,
            modalTitle: "",
            checkAvailabilitySchedule: []
        })
    }

    renderErrorAlert = (key) => {
        const { maintenance, conflictingSchedules, dateRangeWithConditions } = this.props
        return (
            <>
                {maintenance?.length > 0 && maintenance.filter(item => item.tempId === key).length !== 0 &&
                    <Alert
                        message="The schedule conflicts with the existing Maintenance Window!"
                        description={<span className="link paragraph" onClick={() => this.handleSeeDetails("maintenance", maintenance.filter(item => item.tempId === key), "Maintenance Window Conflict")}>See Details</span>}
                        type="warning"
                        showIcon
                    />
                }

                {conflictingSchedules?.length > 0 && conflictingSchedules.filter(item => item.tempId === key).length !== 0 && <>
                    <br />
                    <Alert
                        message="The schedule conflicts with the existing schedules!"
                        description={<span className="link paragraph" onClick={() => this.handleSeeDetails("conflictingSchedules", conflictingSchedules.filter(item => item.tempId === key), "Conflicting Schedule(s)")}>See Details</span>}
                        type="warning"
                        showIcon
                    />
                </>}

                {dateRangeWithConditions?.length > 0 && dateRangeWithConditions.filter(item => item.tempId === key)?.length !== 0 &&
                    <>
                        <br /><Alert
                            message={dateRangeWithConditions.filter(item => item.tempId === key)[0]?.demoTimeLimitExd
                                ? "Cannot schedule beyond Demo Time limit"
                                : dateRangeWithConditions.filter(item => item.tempId === key)[0]?.resourceTimeLimitExd
                                    ? "Cannot schedule beyond Resource Time limit"
                                    : dateRangeWithConditions.filter(item => item.tempId === key)[0]?.userMultipleDaysDisabled
                                        ? "Schedule cannot be for more than 24 hours"
                                        : dateRangeWithConditions.filter(item => item.tempId === key)[0]?.startTimeBeforeCurrentTime
                                            ? "Start Time is before the current time"
                                            : null}
                            type="warning"
                            showIcon
                        /></>}
            </>)
    }

    disabledEndDate = end => {
        const { timezone } = this.props;
        const now = end && end < (moment.tz(moment(), timezone).endOf('day').subtract(1, 'day'))
        return now
    };

    disabledStartDate = (start) => {
        const { timezone } = this.props;
        const now = start && start < (moment.tz(moment(), timezone).endOf('day').subtract(1, 'day'))
        return now
    };

    startTime = (key, label, initialValue) => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return <Form.Item label="Start Time">
            {getFieldDecorator(label, {
                rules: [
                    {
                        required: true,
                        message: "Please select start time"
                    }
                ],
                initialValue: initialValue
            })(<DatePicker
                size="large"
                allowClear={false}
                className='antd-neo-date-picker full-width'
                disabledDate={this.disabledStartDate}
                showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                format={CONFIG.dateFormats.default}
                placeholder="Start Time"
                onChange={(...rest) => this.props.handleStartChange(key, ...rest)}
                onOpenChange={(...rest) => this.props.handlePublishOpenChange(key, ...rest)}
                showToday={false}
            />)}
        </Form.Item>
    }

    endTime = (key, label, initialValue) => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return <Form.Item label="End Time">
            {getFieldDecorator(label, {
                rules: [
                    {
                        required: true,
                        message: "Please select end time"
                    }
                ],
                initialValue: initialValue
            })(<DatePicker
                size="large"
                allowClear={false}
                className='antd-neo-date-picker  full-width'
                disabledDate={this.disabledEndDate}
                showTime={{ use12Hours: true, format: CONFIG.timeFormats.default }}
                format={CONFIG.dateFormats.default}
                placeholder="End Time"
                onOpenChange={(...rest) => this.props.handleExpiryOpenChange(key, ...rest)}
                onChange={(...rest) => this.props.handleEndChange(key, ...rest)}
                showToday={false}
            />)}
        </Form.Item>
    }

    renderPurposeTypesOptions = () => {
        const { userPurposeTypesLite } = this.props;
        return userPurposeTypesLite?.length > 0 ? userPurposeTypesLite.map((purposeType) => {
            return <Option key={purposeType.id} value={JSON.stringify(purposeType)}>{purposeType.purpose}</Option>
        }) : []
    }

    schedulePurpose = (key, label, initialValue) => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const initialPurpose = initialValue ? JSON.stringify(initialValue) : undefined;
        return <>
            <Form.Item label="Purpose">
                {
                    getFieldDecorator(label, { rules: [{ required: true, message: 'Please select a purpose' }], initialValue: initialPurpose })(
                        <Select
                            showSearch
                            bordered={false}
                            suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                            className='organiser-select'
                            placeholder="Select a purpose"
                            size='large'
                            onChange={(...rest) => this.props.handlePurposeSelectChange(key, ...rest)}
                        >
                            {this.renderPurposeTypesOptions()}
                        </Select>
                    )
                }
            </Form.Item>
        </>
    }

    renderPurposeFields = (key, fields) => {
        const attributes = fields?.attributes ? fields.attributes : null;
        const isSFDCBypass = this.props.userSettings?.isSfdcByPass;
        if (attributes === null) return;
        if (attributes?.length > 0) {
            return attributes?.map((eachItem) => {
                const { getFieldDecorator } = this.props.form;
                const label = eachItem.attributeLabel;
                const id = eachItem.id;
                const type = eachItem.attributeType
                const isRequired = eachItem.isMandatory;
                const isVisible = eachItem.isVisible;
                const atrType = eachItem?.type;
                const doNotHaveOpportunity = this.props.form.getFieldValue(`${"doNotHaveOpportunity"}-${key}`) !== undefined ? this.props.form.getFieldValue(`${"doNotHaveOpportunity"}-${key}`) : fields?.doNotHaveOpportunity;
                return (
                    <>
                        {isVisible && <Col span={atrType === CONFIG.purposeAttributeTypes.opportunityNumber ? 19 : 7}>
                            <><div style={{ display: 'flex', flexDirection: atrType === CONFIG?.purposeAttributeTypes.opportunityNumber ? 'row' : 'column' }}>
                                <div style={atrType === CONFIG.purposeAttributeTypes.opportunityNumber ? { display: 'flex', flexDirection: 'column', width: '35%' } : { display: 'flex', flexDirection: 'column' }}>
                                    <Form.Item label={label}>
                                        {type === CONFIG.Textbox ?
                                            getFieldDecorator(`${id}-${label}-${key}`, {
                                                rules: [{
                                                    required: (atrType === CONFIG.purposeAttributeTypes.opportunityNumber) ? !doNotHaveOpportunity : atrType === CONFIG.purposeAttributeTypes.customerName ? isRequired : isRequired,
                                                    message: "Please fill this field"
                                                }],
                                                initialValue: eachItem.value
                                            })(
                                                atrType === CONFIG.purposeAttributeTypes.customerName ? <TextInput disabled={!doNotHaveOpportunity} onChange={(e) => this.props.handleTextBoxChange(e.target.value, key, id, atrType, fields?.uniqueId)} aria-label="customer name" />
                                                    : <Input
                                                        className='input-antd' size='large'
                                                        style={fields?.isOpportunityNumberValid === false && !doNotHaveOpportunity ? { border: "1px solid #da291c", marginTop: "1.5px" } : { marginTop: "1.5px" }}
                                                        disabled={atrType === CONFIG.purposeAttributeTypes.opportunityNumber && doNotHaveOpportunity}
                                                        onChange={(e) => this.props.handleTextBoxChange(e.target.value, key, id, atrType, fields?.uniqueId)}
                                                        maxLength={atrType === CONFIG.purposeAttributeTypes.opportunityNumber ? 10 : undefined}
                                                    />
                                            )
                                            : getFieldDecorator(`${id}-${label}-${key}`, {
                                                rules: [{
                                                    required: isRequired,
                                                    message: "Please fill this field"
                                                }],
                                                initialValue: eachItem.value
                                            })(
                                                <Select
                                                    onChange={(value) => this.props.handleTextBoxChange(value, key, id)}
                                                    showSearch
                                                    bordered={false}
                                                    suffixIcon={<Icon style={{ color: "black", fontSize: "14px" }} icon="chevron-down" />}
                                                    className='organiser-select'
                                                    size='large'
                                                    placeholder="Select a vertical"
                                                >
                                                    {renderVerticalOptions(this.props.verticals)}
                                                    <Option value="Other">Other</Option>
                                                </Select>
                                            )
                                        }
                                    </Form.Item>
                                    {(atrType === CONFIG.purposeAttributeTypes.opportunityNumber) && fields?.isOpportunityNumberValid === false && !doNotHaveOpportunity &&
                                        <div><span style={{ color: "#da291c", fontSize: "12px" }}>Invalid opportunity number</span></div>}
                                </div>
                                {(atrType === CONFIG.purposeAttributeTypes.opportunityNumber && isSFDCBypass) &&
                                    <div style={{ marginTop: "20px", marginLeft: "12px" }}>
                                        <Form.Item>
                                            {
                                                getFieldDecorator(`${"doNotHaveOpportunity"}-${key}`, {
                                                    initialValue: (fields?.opportunity?.sfdcOpportunityId || !!!fields?.doNotHaveOpportunity) ? false : true,
                                                    valuePropName: 'checked'
                                                })(
                                                    <Checkbox
                                                        onChange={(e) => this.props.handleNoOpportunityCheckboxForRecurrence(e.target.checked, key)}
                                                        value={`${(fields?.opportunity?.sfdcOpportunityId || !!!fields?.doNotHaveOpportunity) ? "unchecked" : "checked"}`}
                                                        name="Don't have opportunity number" style={{ color: "#000" }} >Don't have opportunity number</Checkbox >
                                                )
                                            }
                                        </Form.Item>
                                    </div>}
                            </div>
                            </>
                        </Col>}
                        {(atrType === CONFIG.purposeAttributeTypes.opportunityNumber && doNotHaveOpportunity) &&
                            <Col span={24}>{this.renderAccountOwnerFields(key, doNotHaveOpportunity, fields)}</Col>}
                    </>
                )
            })
        }
    }

    renderAccountOwnerFields = (key, doNotHaveOpportunity, fields) => {
        const { getFieldDecorator } = this.props.form;
        // const { user } = this.props;
        // const requestOpportunityNumber = this.props.form.getFieldValue(`${"requestOpportunityNumber"}-${key}`);
        return (
            <>
                <div className='schedular-account-owner'>
                    <div style={{ width: '100%' }}><Form.Item
                        label={"Account Owner Email"}
                    >
                        {getFieldDecorator(`${"accountOwnerEmail"}-${key}`, {
                            rules: [
                                {
                                    required: doNotHaveOpportunity,
                                    type: "email",
                                    message: "Please enter a valid email-id"
                                }
                            ],
                            initialValue: fields.opportunity?.accountOwnerEmail
                        })
                            (<TextInput
                                // disabled={requestOpportunityNumber}
                                aria-label={"Account Owner Email"}
                                onChange={(e) => this.props.handleAccountOwnerEmailChange(e.target.value, key)}
                                defaultValue={fields.opportunity?.accountOwnerEmail}
                                value={fields.opportunity?.accountOwnerEmail} />)
                        }
                    </Form.Item></div>
                    {/* <div style={{ marginTop: "20px", marginLeft: "12px" }}>
                        <Form.Item>
                            {
                                getFieldDecorator(`${"requestOpportunityNumber"}-${key}`, {
                                    valuePropName: 'checked'
                                })(
                                    <Checkbox
                                        onChange={(e) => this.props.handleNoAccountOwnerEmailForReccurence(e.target.checked, key)}
                                        name="Request Opportunity number" style={{ color: "#000" }} >Don't know account owner's email?</Checkbox >
                                )
                            }
                        </Form.Item></div>
                    {requestOpportunityNumber && <> <div style={{ width: "1px", border: "1px solid #C9C9C9" }} />
                        <div style={{ marginTop: "20px", }}><Button style={{ height: "40px", width: "260px" }} variant="primary">
                            <a style={{ color: "#FFFFFF" }} href={(user?.dn === "Associates" || user?.dn === "CUST") ?
                                "https://avaya--f4utest.sandbox.lightning.force.com/lightning/o/Opportunity/new?nooverride=1&recordTypeId=01230000000DpZ0AAK" :
                                "https://avaya--f4utest.sandbox.my.site.com/PartnerCentral/s/opportunity/Opportunity/Default"} target="_blank" rel="noreferrer">
                                Request Opportunity Number</a></Button></div></>} */}
                </div>
            </>
        )
    }

    renderNotes = (key, scheduleObj) => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Col xl={12} lg={12} sm={12} xs={12}>
                <Form.Item label="Notes">
                    {getFieldDecorator(`notes-${key}`, {
                        initialValue: scheduleObj?.notes ?? ""
                    })(
                        <TextArea
                            rows={2}
                            onChange={(e) => this.props.handleNotesChange(e.target.value, key)}
                        />
                    )}
                </Form.Item>
            </Col>
        )
    }

    renderResourceOptions = (key, label, data) => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const { resource, availableResources } = data || null;
        const resourcesList = availableResources?.length > 0 ? availableResources?.map(item => { return { id: item?.resource.id, name: item?.resource.name } }) : []
        const initialValue = resource ? { id: resource.id, name: resource.name } : null;

        return (
            <div>
                <Form.Item>
                    {
                        getFieldDecorator(label, {
                            rules: [{ required: true, message: "Please select a resource to schedule." }],
                            initialValue: JSON.stringify(initialValue)
                        })(
                            <Radio.Group
                                onChange={(...rest) => this.props.handleResourceChange(key, ...rest)}
                                className='two-column-radio-group'
                            >
                                {resourcesList?.map((resource) => (
                                    <Radio style={{ margin: "0px 16px" }} className='two-column-radio' value={JSON.stringify(resource)}>{resource.name}</Radio>
                                ))}
                            </Radio.Group>
                        )
                    }
                </Form.Item>
            </div>
        );
    }

    renderSuccessAlert = () => {
        return <Alert message="All instances are available" type="success" showIcon />
    }

    renderWarningAlert = () => {
        return <Alert
            message="Certain schedule(s) need your attention!"
            description="If you do not take any action, the schedules with warning will be dropped."
            type="warning"
            showIcon
        />
    }

    showConflictWindowSchedules = () => {
        const { timezone } = this.props
        const overlappingColumns = [
            {
                Header: "Title",
                Cell: (a) => {
                    const record = a?.cell?.row?.original;
                    return <span style={{ textAlign: "center" }}>{record?.title || ""}</span>
                },
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    const record = a?.cell?.row?.original;
                    return <span style={{ textAlign: "center" }}>{record?.createdBy ? record?.createdBy.firstName + " " + record?.createdBy.lastName : ""}</span>
                },
                Header: "Created By",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{moment.tz(a?.cell?.row?.original?.startTime, timezone).format(CONFIG.dateFormats.userDateTime)}</span>
                },
                Header: "Start Time",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{moment.tz(a?.cell?.row?.original?.endTime, timezone).format(CONFIG.dateFormats.userDateTime)}</span>
                },
                Header: "End Time",
                disableFilters: true,
                disableSortBy: true
            },
        ];

        const columns = this.state.conflictType === "maintenance"
            ? overlappingColumns
            : this.state.conflictType === "conflictingSchedules"
                ? overlappingColumns.slice(1, 4)
                : []

        return <Table
            data={this.state.checkAvailabilitySchedule}
            columns={columns}
        />
    }

    renderConflictWindowModal = () => {
        return (
            <BasicModal
                className="neo-modal conflicting-modal"
                closeButtonLabel={"Close"}
                title={this.state.modalTitle}
                open={this.state.showConflictWindow}
                // footer={this.renderModalFooterButtons()}
                onClose={this.handleModalClose}
            >
                <div>
                    {this.showConflictWindowSchedules()}
                </div>
            </BasicModal>
        )
    };

    render() {
        const { scheduleDetails, demo } = this.props;
        const { maintenance, conflictingSchedules, dateRangeWithConditions, checkAvailability, isAddParticipantsEnabled, neoColumns } = this.props;
        const isAllScheduleInstancesAvailable = checkAvailability && maintenance.length === 0 && conflictingSchedules.length === 0 && dateRangeWithConditions.length === 0
        const unAvailableScheduleInstances = checkAvailability ? [...maintenance, ...conflictingSchedules, ...dateRangeWithConditions].reduce((acc, item) => { acc.push(item.tempId); return acc }, []) : []
        return (
            <div style={{ marginTop: "16px" }}>
                <Row className="vertical-spacing">
                    {isAllScheduleInstancesAvailable ? this.renderSuccessAlert() : checkAvailability ? this.renderWarningAlert() : null}
                </Row>

                <Row className="right-align vertical-spacing">
                    <Button
                        icon="add" dir="rtl"
                        onClick={this.props.addNewSchedule}
                        className="primary-action-button-filled" >Add New Schedule</Button>
                </Row>
                <Collapse
                    style={{ backgroundColor: 'white' }}
                    activeKey={this.props.activePanelKey}
                    onChange={(key) => {
                        const uKey = scheduleDetails.find(item => item.uniqueId === parseInt(key))?.id;
                        this.setState({ matchedParticipants: [] });
                        this.props.onPanelChange(uKey);
                        this.props.handleActivePanelChange(key, false);
                    }}
                    accordion
                    bordered={false}
                    expandIconPosition='right'
                    className='occurence-collapse'
                    destroyInactivePanel={true}
                    expandIcon={({ isActive }) => <Icon type="left" rotate={isActive ? -90 : 0} />}>
                    {
                        scheduleDetails?.map((item, index) => <Panel
                            id={`collapse-panel-${item?.uniqueId}`}
                            header={unAvailableScheduleInstances.includes(index + 1) ? <div> <Row> <Col span={2}>Schedule {index + 1} </Col><Col span={4}>
                                <Icon type="warning" className="warning-text" />
                            </Col>
                                {(item.hasOwnProperty("groupId") && !!item?.groupId) && <span style={{ marginRight: '16px' }} className='float-right'>
                                    <NeoIcon icon='shape-circle-filled' style={{ color: CONFIG.pastelColors[item.groupId] }} />
                                </span>}
                            </Row>
                                <Row gutter={48} style={{ display: 'flex', marginTop: "8px" }}>
                                    <Col span={7}>
                                        Start Time: <span style={{ fontWeight: '600', marginLeft: "5px" }}>{item?.startTime ? moment.tz(item?.startTime, this.props.timezone).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                                    </Col>
                                    <Col span={7}>
                                        End Time:<span style={{ fontWeight: '600', marginLeft: "5px" }}>{item?.endTime ? moment.tz(item?.endTime, this.props.timezone).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                                    </Col>
                                    <Col span={7}>
                                        Resource:<span style={{ fontWeight: '600', marginLeft: "5px" }}>{item?.resource ? item?.resource?.name : "-"}</span>
                                    </Col>
                                </Row>
                            </div> : <div>
                                Schedule {index + 1}
                                {item.hasOwnProperty("groupId") && <span style={{ marginRight: '16px' }} className='float-right'>
                                    <NeoIcon icon='shape-circle-filled' style={{ color: CONFIG.pastelColors[item.groupId] }} />
                                </span>}
                                <Row gutter={48} style={{ display: 'flex', marginTop: "8px" }}>
                                    <Col span={7}>
                                        Start Time: <span style={{ fontWeight: '600', marginLeft: "5px" }}>{item?.startTime ? moment.tz(item?.startTime, this.props.timezone).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                                    </Col>
                                    <Col span={7}>
                                        End Time:<span style={{ fontWeight: '600', marginLeft: "5px" }}>{item?.endTime ? moment.tz(item?.endTime, this.props.timezone).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                                    </Col>
                                    <Col span={7}>
                                        Resource:<span style={{ fontWeight: '600', marginLeft: "5px" }}>{item?.resource ? item?.resource?.name : "-"}</span>
                                    </Col>
                                </Row>
                            </div>
                            }
                            key={item.uniqueId}
                            className='occurence-panel'
                        >
                            <div style={{ margin: "16px" }}>
                                <Row style={{ marginBottom: '16px', marginTop: "-16px" }}>
                                    {this.renderErrorAlert(index + 1)}
                                </Row>
                                <NeoForm className='neo-scheduler-form'>
                                    <Row gutter={24} >
                                        <div>
                                            <Col xl={7} xs={24} key={item.uniqueId}  >{this.startTime(index, `scheduleDetails[${index}].startTime`, item.startTime)}</Col>
                                            <Col xl={7} xs={24} key={item.uniqueId}  >{this.endTime(index, `scheduleDetails[${index}].endTime`, item.endTime)}</Col>
                                            <Col xl={1}>
                                            </Col>
                                            <Col style={{ marginTop: "5px" }} xl={3} lg={2} sm={12} xs={24}>
                                                <TextInput readOnly label="Start Buffer" defaultValue={(demo?.startTimeBuffer || " 0") + " mins"} value={(demo?.startTimeBuffer || " 0") + " mins"} />
                                            </Col>
                                            <Col style={{ marginTop: "5px" }} xl={3} lg={2} sm={12} xs={24}>
                                                <TextInput readOnly label="End Buffer" defaultValue={(demo?.endTimeBuffer || " 0") + " mins"} value={(demo?.endTimeBuffer || " 0") + " mins"} />
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row style={{ marginTop: "16px" }}>
                                        <div className='occurence-resource-body'>
                                            <div style={{ color: "#323232", margin: "12px" }}>
                                                Resources
                                            </div>
                                            <div style={{ color: "#323232" }} key={item.uniqueId}>
                                                {this.renderResourceOptions(index, `scheduleDetails[${index}].resource`, scheduleDetails[index])}
                                            </div>
                                        </div>
                                    </Row>
                                    <Row gutter={24} style={{ marginTop: "16px" }}>
                                        <Col xl={7} xs={24} key={item.uniqueId}>{this.schedulePurpose(index, `scheduleDetails[${index}].purpose`, item.purpose)}</Col>
                                    </Row>
                                    <Row key={`attributes-${item.uniqueId}`} gutter={24} style={{ marginTop: "16px" }}>
                                        {this.renderPurposeFields(index, scheduleDetails[index])}
                                    </Row>
                                    <Row>
                                        {this.renderNotes(index, scheduleDetails[index])}
                                    </Row>
                                    <Row>
                                        <Row key={item.uniqueId}>
                                            {this.props.renderParticipants(index, `scheduleDetails[${index}].scheduleParticipants`, 7)}
                                        </Row>
                                        {scheduleDetails[index]?.scheduleParticipants?.length > 0 &&
                                            <Row gutter={24}  >
                                                <Col key={item.uniqueId} >
                                                    <Table
                                                        showRowSeparator
                                                        rowHeight="medium"
                                                        columns={isAddParticipantsEnabled ? neoColumns : neoColumns.slice(0, 4)}
                                                        data={scheduleDetails[index]?.scheduleParticipants}
                                                        className="schedule-table"
                                                    />
                                                    <br />
                                                </Col>
                                            </Row>
                                        }
                                    </Row>
                                </NeoForm>
                                {scheduleDetails?.length > 1 &&
                                    <Row className="right-align"  >
                                        <Button onClick={() => {
                                            this.props.deleteScheduleInstance(index).then(() => {
                                                this.props.handleActivePanelChange(scheduleDetails[0]?.uniqueId, false)
                                            })
                                        }} className="primary-action-button-filled left-spacing-3"
                                            icon="trash" dir="rtl">Remove Schedule</Button>
                                    </Row>
                                }
                            </div>
                        </Panel>)
                    }
                </Collapse>
                {this.renderConflictWindowModal()}
            </div >
        )
    }
}

const mapStateToProps = ({ newSchedule, scheduler, user }) => {
    return {
        userPurposeTypesLite: newSchedule.userPurposeTypesLite,
        verticals: newSchedule.verticals,
        timezone: scheduler.selectedTimezone,
        startTime: scheduler.startTime,
        endTime: scheduler.endTime,
        isAddParticipantsEnabled: user.isAddParticipantsEnabled,
        selectedResource: scheduler.selectedResource,
        user: user.profile,
        currentSchedule: newSchedule?.currentSchedule,
        isPeriodicEnabled: user.isPeriodicEnabled,
        maintenance: newSchedule.maintenance,
        conflictingSchedules: newSchedule.conflictingSchedules,
        possibleSchedules: newSchedule.possibleSchedules,
        dateRangeWithConditions: newSchedule.dateRangeWithConditions,
        checkAvailability: newSchedule.checkAvailability,
        userSettings: user?.configurations
    };
};

export default connect(mapStateToProps, {})(ScheduleOccurenceDetails);