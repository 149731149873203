import React from 'react';
import { connect } from 'react-redux';

import {
    fetchResourcesForRT,
    toggleIsFetchingResourcesRTFlag,
    updateRoleTemplateResources,
    fetchDemos,
} from '../../actions';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import CONFIG from '../../config';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';

const dataIndexMap = {
    name: "name",
    demo: "demoResources.demo.title"
}

class RoleTemplateResources extends React.Component {

    getFilterDropDown = (filterProps, dataIndex, type, data) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={dataIndex === dataIndexMap.name ? selectedKeys[0] : selectedKeys}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
                type={type}
                data={data}
            />
        )
    }

    columnFilters = {
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "name"),
        sorter: true
    }

    filterSort = {}

    handleFilter = (searchString, confirm) => {
        if (searchString && searchString.length !== 0) {
            confirm();
            return;
        }
        pushMessage(CONFIG.messageType.warning, "Please enter a search string")
    }

    handleClear = (clearFilters) => {
        clearFilters();
    }

    async componentDidMount() {
        const { userRoleTemplate } = this.props;
        this.access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
        this.props.toggleIsFetchingResourcesRTFlag(true);
        await this.props.fetchResourcesForRT({ access: this.access, pageSize: CONFIG.shortPageSize + 1 });
        await this.props.fetchDemos({ getAll: true });
    }

    handleChange = async (pagination, filters, sorter) => {

        const isNameEmpty = !filters[dataIndexMap.name] || filters[dataIndexMap.name]?.length === 0;
        const isDemoEmpty = !filters[dataIndexMap.demo] || filters[dataIndexMap.demo]?.length === 0;

        this.filterSort = {
            filter: (!isNameEmpty || !isDemoEmpty)
                ? {
                    [dataIndexMap.name]: filters.name?.[0],
                    [dataIndexMap.demo]: filters[dataIndexMap.demo]?.join(CONFIG.delimiters.selectFilter)
                }
                : undefined,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.props.toggleIsFetchingResourcesRTFlag(true);
        await this.props.fetchResourcesForRT({
            pageNumber: pagination.current,
            pageSize: (CONFIG.shortPageSize + 1),
            access: this.access,
            filterSort: this.filterSort
        });
    }

    render() {
        const { resources, count, assignedResources, isFetching, allResourcesUserAccess, allResourcesAdminAccess, templateType, updateRoleTemplateResources, demos } = this.props;
        const extraColumn = {
            title: "Associated Demos",
            key: "demoResources.demo.title",
            width: '30%',
            align: "left",
            render: (record) => record?.demoResources?.map(demoResource => { return demoResource.demo.title }).join(", "),
            sorter: true,
            filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "demo", CONFIG.formFieldTypes.SELECT, (demos) ? demos.map(demo => ({ text: demo.title })) : [])
        }

        return (
            <RoleTemplatesTable
                columnFilters={this.columnFilters}
                dataKey="resource"
                hideColumns={templateType === CONFIG.roleTypes.admin ? [] : ['admin']}
                dataIndex="name"
                marginTop={true}
                bordered={true}
                data={resources}
                extraColumns={[extraColumn]}
                isFetching={isFetching}
                assignedData={assignedResources}
                allAdminAccess={allResourcesAdminAccess}
                allUserAccess={allResourcesUserAccess}
                updateRoleTemplateData={updateRoleTemplateResources}
                selectAllDisabled={this.filterSort.filter !== undefined}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1,
                    total: count
                }}
            />
        );
    }
}

const mapStateToProps = ({ rtResource, roleTemplate, user, demos }) => {
    return {
        resources: rtResource.data,
        count: rtResource.count,
        isFetching: rtResource.isFetching,
        assignedResources: roleTemplate.resources,
        allResourcesUserAccess: roleTemplate.allResourcesUserAccess,
        allResourcesAdminAccess: roleTemplate.allResourcesAdminAccess,
        templateType: roleTemplate.templateType,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        demos: demos.allDemos,
    }
}

export default connect(
    mapStateToProps,
    {
        fetchResourcesForRT,
        toggleIsFetchingResourcesRTFlag,
        updateRoleTemplateResources,
        fetchDemos
    }
)(RoleTemplateResources);