import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from "antd";
import { Button } from "@avaya/neo-react"
import { useSelector } from "react-redux"
import moment from 'moment-timezone';
import CONFIG from '../config';
import { encrypt } from "../utils/aesDecrypt";
import catalog_2 from "../assets/images/landing-page/catalog-2.jpg"
import catalog_3 from "../assets/images/landing-page/catalog-3.png"
import catalog_4 from "../assets/images/landing-page/catalog-4.jpg"
import LocalUserLoginForm from "./LoginForm"

function HomeNeo(props) {
    const [isLoginModalOpen, setLoginModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(<span style={{ lineHeight: "28px" }}>Sign in</span>);
    const viewport = useSelector(({ viewport }) => viewport);
    const date = new Date();
    const year = date.getFullYear();
    const plainText = moment().utc().add(process.env.REACT_APP_LOGIN_AES_SALT, 'm').format(CONFIG.dateFormats.scheduler);
    const id = encrypt(plainText);
    const encodedUrl = encodeURIComponent(id);
    const url = `${process.env.REACT_APP_SAML_LOGIN}?id=${encodedUrl}`;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        script.type = 'text/javascript';
        script.setAttribute('data-document-language', 'true');
        script.setAttribute('data-domain-script', '11fcaab1-94a5-4127-a288-57d67b245b37-test');
        document.body.appendChild(script);

        // Define OptanonWrapper function
        window.OptanonWrapper = function () { };
    })

    const loginComplete = () => {
        setLoginModal(false)
        setModalTitle('Sign in')
    }

    return (
        <React.Fragment>
            <div style={{ overflowY: "scroll" }}>
                <div style={{ marginBottom: "-8px" }} className="image-container home-page-section-1 img-home-page-exit ">
                    <span alt="main-page" className="home-page-main-image" />
                    <div className="home-page-image-overlay">
                        <div className='home-page-image-content'>
                            <h3 className="carousel-card-title">Welcome to  Experience Avaya Demo Platform</h3>
                            <p className="carousel-card-subheading">
                                The Experience Avaya Demo Platform provides a live showcase environment to demonstrate how Avaya products and solutions help organizations thrive. Discover a collection of demos highlighting Avaya’s products and solutions that enhance Customer Experiences, Employee Experiences and Innovation Without Disruption.
                            </p>
                            <div style={{ gap: "24px" }}>
                                <Button aria-label="login-filled-home"
                                    onClick={() => { window.location.pathname === '/home/non-sso-users' ? setLoginModal(true) : window.open(url, "_self") }}
                                >Sign in</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", paddingTop: "100px", display: "flex", justifyContent: "center" }} className='home-page-section-1 new-animation'>
                    <div style={{ display: 'flex', alignItems: 'center', flex: "1.725", justifyContent: 'flex-end' }}>
                        <span alt="main-page" className='img-home-page-1' />
                    </div>
                    <div style={{ display: 'flex', flex: "1", justifyContent: 'flex-start' }}>
                        <div className='img-2-home-content'>
                            <div className="homepage-section-title" >Building success one experience at a time</div>
                            <div style={{ marginTop: "16px" }} className="homepage-section-desc">
                                Demonstrate to customers how they can thrive with Avaya. The Experience Avaya Demo Platform offers a variety of product and solution demonstrations on a versatile platform, all designed to deliver exceptional demo experiences. Demos are readily available and easily accessible following user-friendly instructions.</div>
                        </div>
                    </div>
                </div>

                <div style={{ background: "white", paddingTop: "100px" }} className='new-animation home-page-section-1'>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img width={1328} src={catalog_2} alt="catalog-pic" className='rect-catalog-pic' />
                    </div>
                    <div style={{ marginTop: "16px" }} className='center-flex'>
                        <span style={{ letterSpacing: "0.64px", width: "370px", fontSize: "32px", fontWeight: "700", lineHeight: '48px', color: "#000" }}>
                            Experience Avaya Demo Catalogue
                        </span>
                        <span style={{ width: "570px", fontSize: "16px", fontWeight: "400", lineHeight: '24px', color: "#242424", letterSpacing: "0.32px" }}>
                            The Experience Avaya catalog of demos contains demos for products and vertical market solutions across major customer segments.
                        </span>
                    </div>
                </div>

                <div style={{ background: "white", paddingTop: "100px" }} className='new-animation home-page-section-1'>
                    <div style={{ position: "relative" }} >
                        <div className='home-page-image-overlay-2' style={{ display: "flex", justifyContent: "center", width: "100%" }} >
                            <div style={{ display: "flex" }}>
                                <div className='sales-img-cont-1' >
                                    <div style={{ width: "637px", letterSpacing: "0.64px", fontSize: "32px", fontWeight: "700", color: "#151515", lineHeight: "48px" }}>
                                        Avaya is a globally focused company serving markets in Asia, Europe, and the United States
                                    </div>
                                    <div style={{ width: "637px", letterSpacing: "0.52px", marginTop: "10px", fontSize: "26px", fontWeight: "400", color: "#151515", lineHeight: "40px" }}>
                                        Experience Avaya is built around a simple  philosophy:
                                    </div>
                                    <div style={{ letterSpacing: "0.32px", width: "637px", marginTop: "10px", fontSize: "16px", fontWeight: "400", color: "#151515", lineHeight: "28px" }}>
                                        “The platform is built on principles of simplicity, flexibility, and enabling Avaya Associates and Partners to directly correlate Avaya’s capabilities and differentiation to customer challenges and desired outcomes.”
                                    </div>
                                </div>
                                <div className='sales-img-cont-2'>
                                    <img height={395} className='sales-col-img' src={catalog_3} alt="Sales Team" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", paddingTop: "100px", backgroundImage: catalog_4 }} className='home-page-section-1'>
                    <div className='home-page-image-overlay-3  new-animation'>
                        <div className='contact-us-cont'>
                            <div style={{ fontSize: "32px", letterSpacing: "0.64px", fontWeight: "700", color: "#151515", lineHeight: "40px" }}>
                                Contact Us
                            </div>
                            <div style={{ marginTop: "10px", letterSpacing: "0.52px", fontSize: "26px", fontWeight: "400", color: "#151515", lineHeight: "40px" }}>
                                Contact the Experience Avaya team directly through the demo portal.
                            </div>
                            <div style={{ marginTop: "10px", letterSpacing: "0.32px", fontSize: "16px", fontWeight: "400", color: "#151515", lineHeight: "28px" }}>
                                Login using your Avaya Single Sign-On (SSO) credentials.
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <span style={{ display: 'flex', alignItems: "flex-start", gap: "16px" }}>
                                    <Button aria-label="support" onClick={() => window.open(process.env.REACT_APP_SUPPORT_URL)} animation="none" size="default" status="default" >Contact Support</Button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "100px", background: "#EFEFEF", display: "flex", alignItems: "center" }}>
                        <Row style={{ display: 'flex', width: "100%" }}>
                            <Col style={{ flex: 1, justifyContent: "center", display: "flex" }} className="flex-start">
                                <span style={{ color: "#323232" }} >&copy; {year} Avaya, Inc.</span>
                            </Col>
                            <Col style={{ flex: 2, justifyContent: "center", display: "flex" }}>
                                <span><a href="https://www.avaya.com/en/sitemap/" rel="noopener noreferrer" target="_blank" className="light-grey-text">Sitemap</a></span>
                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                <span><a href="https://www.avaya.com/en/termsofuse/" rel="noopener noreferrer" target="_blank" className="light-grey-text">Terms of Use</a></span>
                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                <span><a href="https://www.avaya.com/en/privacy/commitment/" rel="noopener noreferrer" target="_blank" className="light-grey-text">Privacy</a></span>
                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                {/* eslint-disable-next-line */}
                                <p><a class="optanon-show-settings light-grey-text" href="javascript:void(0);">Cookies</a></p>
                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                <span><a href=" https://www.avaya.com/en/trademarks/" rel="noopener noreferrer" target="_blank" className="light-grey-text">Trademarks</a></span>
                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                <span><a href="https://www.avaya.com/en/accessibility/" rel="noopener noreferrer" target="_blank" className="light-grey-text">Accessibility</a></span>
                            </Col>
                        </Row >
                    </div>
                </div>
                {/* </div> */}
                {
                    isLoginModalOpen && <Modal
                        title={modalTitle}
                        wrapClassName="centered-modal" // Assign a custom CSS class for centering the title
                        visible={isLoginModalOpen}
                        onCancel={() => { setLoginModal(false); setModalTitle("Sign In") }}
                        footer={null}
                        className={"login-modal"}
                        width={viewport.isMobileView ? '100%' : '30%'}
                    >
                        <LocalUserLoginForm history={props.history} loginComplete={loginComplete} setModalTitle={setModalTitle} />
                    </Modal>
                }
            </div>
        </React.Fragment >
    )
}

export default HomeNeo