import React from 'react';
import { connect, batch } from 'react-redux';
import { Popconfirm, Tooltip, Row, message, Dropdown, Spin } from 'antd';
import { Icon as NeoIcon } from "@avaya/neo-react";
import {
    selectTitle,
    toggleBackButton,
    fetchRoleTemplatesLite,
    deleteRoleTemplate,
    restoreRoleTemplate,
    toggleIsFetchingRoleTemplatesFlag,
    clearRoleTemplates,
    clearRoleTemplate,
    exportTable,
    currentRoleTemplatesFilter,
    hasSaveChanges,
    handleOnClickAction,
    toggleSaveReportVisible
} from '../../actions';
import { Button, Table as NeoTable } from "neo-latest"
import { isTabAlreadyOpen } from "../common/TabLayout";
import CONFIG from '../../config';
import { toTitleCase } from '../../utils/strings';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import FilterDrawer from "../common/FilterDrawer";
import pushMessage from '../common/PushMessage';
import openConfirm from '../common/Confirm';
import { exportMenu } from "../../utils/strings";
import SaveReport from "../common/SaveReport";
import { otherFields } from "../../utils/neoTableFields";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class ManageRoleTemplates extends React.Component {

    state = {
        filteredData: [],
        currentPage: 0,
    }
    filterSave = {}
    tableRef = React.createRef()
    isViewDeletedItemsChecked = false;
    viewDeletedItems = false;
    filterSort = {}

    filterRecords = (filters) => {
        const { allRoleTemplates } = this.props;
        let { title, templateTypes, viewDeletedItems, description } = filters;
        this.isViewDeletedItemsChecked = viewDeletedItems;
        title = title?.trim();
        description = description?.trim();

        if (!title && (!templateTypes || templateTypes.length === 0) && !viewDeletedItems && !description) {
            pushMessage(CONFIG.messageType.warning, "Please enter search text to filter");
            return;
        }
        this.titleSearchString = title;
        this.descriptionSearchString = description;
        this.templateTypes = templateTypes && templateTypes.length !== 0 ? templateTypes : undefined;
        let filteredData = []
        if (viewDeletedItems) {
            this.setState({ filteredData: this.props.allRoleTemplates });
        }
        filteredData = title
            ? allRoleTemplates.filter((roleTemplate => roleTemplate.title.toLowerCase().includes(title.toLowerCase())))
            : allRoleTemplates;


        filteredData = description
            ? filteredData.filter((roleTemplate => roleTemplate.description?.toLowerCase().includes(description?.toLowerCase())))
            : filteredData;

        filteredData = this.templateTypes
            ? filteredData.filter(roleTemplate => templateTypes.includes(toTitleCase(roleTemplate.templateType)))
            : filteredData;
        this.setState({ filteredData });

        this.filterSort = {
            ...this.filterSort,
            title,
            description,
            templateTypes,
            viewDeletedItems
        }
    }

    handleFilter = async (filters) => {
        let { viewDeletedItems } = filters;
        if (viewDeletedItems || this.isViewDeletedItemsChecked) {
            this.props.toggleIsFetchingRoleTemplatesFlag(true)
            await this.props.fetchRoleTemplatesLite(false, viewDeletedItems);
            setTimeout(() => {
                this.isViewDeletedItemsChecked = viewDeletedItems;
                this.filterRecords(filters);
            }, 100)
        }
        else this.filterRecords(filters);
    }


    handleExport = async (fileType) => {
        const { timeZoneName, allRoleTemplates } = this.props;
        const { filteredData } = this.state;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const exportData = (filteredData && filteredData?.length > 0) ? filteredData : allRoleTemplates
        if (exportData?.length > 0 && exportData !== undefined) {
            exportTable(CONFIG.exportTable.roleTemplates.name, exportData, timeZoneName, CONFIG.exportTable.roleTemplates.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleSave = (filters) => {
        let { title, templateTypes, viewDeletedItems, description } = filters;
        title = title?.trim();
        description = description?.trim();

        if (!title && (!templateTypes || templateTypes.length === 0) && !viewDeletedItems && !description) {
            if (!this.filterSave.save) pushMessage(CONFIG.messageType.warning, "Please enter search text to save");
            return;
        }
        this.filterSave = {
            ...this.filterSave,
            filter: {
                ...(title && {
                    title: {
                        operator: "=",
                        oprVal: title || undefined
                    }
                }),
                ...(description && {
                    description: {
                        operator: "=",
                        oprVal: description || undefined
                    }
                }),
                isSoftDelete: {
                    operator: "=",
                    oprVal: viewDeletedItems ? viewDeletedItems : false
                },
                ...(templateTypes && {
                    templateType: {
                        operator: "=",
                        oprVal: templateTypes ? templateTypes.join(CONFIG.delimiters.saveFilter) : undefined
                    }
                })
            },
            filterTable: {
                id: 7,
                tableName: "RoleTemplate"
            },
        }
        this.props.toggleSaveReportVisible(true)
        this.setState({ saveReport: true })
    }

    handleClear = (refresh) => {
        if (refresh) {
            this.props.toggleIsFetchingRoleTemplatesFlag(true)
            this.props.fetchRoleTemplatesLite(false, this.isViewDeletedItemsChecked);
            const currentFilterSize = this.filterSort?.filter !== undefined ? Object.keys(this.filterSort?.filter)?.length : 0;
            currentFilterSize > 0 && this.filterRecords(this.filterSort)
        }
        else {
            this.titleSearchString = undefined;
            this.domainsSearchString = undefined;
            this.templateTypes = undefined;
            this.descriptionSearchString = undefined;
            this.filterSave = {};
            this.filterSort = {}
            this.isViewDeletedItemsChecked && this.props.fetchRoleTemplatesLite(false);
            this.isViewDeletedItemsChecked = false;
            this.setState({ filteredData: [], currentPage: 0 });
        }

    }

    handleDelete = (roleTemplateId) => {
        this.roleTemplateId = roleTemplateId;
        const hardDelete = this.isViewDeletedItemsChecked;
        if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
            const content = hardDelete ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Role Template")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Role Template")}<br />Users associated with this role template will be assigned to 'Unassigned' role template</h4>
            const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'role template')
            openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
        }
    }

    handleDeleteConfirmation = () => {
        const { filteredData } = this.state;
        this.props.deleteRoleTemplate(this.roleTemplateId, this.isViewDeletedItemsChecked)
            .then(() => {
                if (filteredData) {
                    this.setState({
                        filteredData: filteredData.filter(rT => rT.id !== this.roleTemplateId)
                    })
                }
            })
    }

    handleRestore = (roleTemplateId) => {
        const { filteredData } = this.state;
        this.props.restoreRoleTemplate(roleTemplateId)
            .then(() => {
                if (filteredData) {
                    this.setState({
                        filteredData: filteredData.filter(rT => rT.id !== roleTemplateId)
                    })
                }
            })
    }

    componentDidMount() {
        const { roleTemplates, pageNumber } = this.props;
        this.setState({ currentPath: window.location.pathname })
        if (roleTemplates?.isEdit) {
            this.filterSort = roleTemplates.currentFilter;
            const { sorter, ...filterSort } = roleTemplates.currentFilter;
            const currentFilterSize = filterSort !== undefined ? Object.keys(filterSort)?.length : 0;
            if (currentFilterSize > 0) {
                this.filterRecords(filterSort)
                this.setState({ showFilter: true });
                this.filterSort = filterSort;
            }
            this.setState({ currentPage: pageNumber })
        }
        batch(() => {
            this.props.selectTitle('Manage Role Templates');
            this.props.toggleBackButton(false);
            this.props.toggleIsFetchingRoleTemplatesFlag(true)
            this.props.fetchRoleTemplatesLite(false);
        });
        document.getElementById('tab-pane').scrollIntoView()
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            const filterSort = {
                ...this.filterSort,
            }
            this.props.currentRoleTemplatesFilter(filterSort, this.state.currentPage);
        }
        else {
            this.props.clearRoleTemplates();
        }
    }

    handleCloseFilterDrawer = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }))
    }

    render() {
        const { allRoleTemplates, userRoleTemplate } = this.props;
        let { filteredData } = this.state;
        const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const formFields = [
            {
                label: "Template name",
                type: CONFIG.formFieldTypes.INPUT,
                key: "title",
                value: this.filterSort?.title,
                colSpan: 24,
            },
            {
                label: "Description",
                type: CONFIG.formFieldTypes.INPUT,
                key: "description",
                value: this.filterSort?.description,
                colSpan: 24,
            },
            {
                label: "Template type",
                type: CONFIG.formFieldTypes.SELECT,
                key: "templateTypes",
                data: Object.values(CONFIG.roleTypes).map(type => ({ type: toTitleCase(type) })),
                valueKey: "type",
                dataKey: "type",
                colSpan: 24,
                value: this.filterSort?.templateTypes ? this.filterSort?.templateTypes.join(CONFIG.delimiters.selectFilter) : undefined
            }
        ];

        const neoColumns = [
            {
                Header: "Template name",
                sortType: "string",
                accessor: "title",
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.title}</span>
                },
            },
            {
                Header: "Template Type",
                sortType: "string",
                accessor: "templateType",
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.templateType}</span>
                },
            },
            {
                Header: "Description",
                sortType: "basic",
                accessor: "description",
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.description}</span>
                },
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    const { userRoleTemplate } = this.props;
                    const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
                    const isTemplateSuperAdmin = record.templateType === CONFIG.roleTypes.superAdmin;
                    const isTemplateDefault = record.templateType === CONFIG.roleTypes.default;
                    const hasEditAccess = record.adminAccess === CONFIG.roleTemplateAccess.readOnly;
                    const isEditDisabled = !isUserSuperAdmin && !hasEditAccess;
                    const isDeleteDisabled = isTemplateDefault || isTemplateSuperAdmin || isEditDisabled
                    const deleteView = this.isViewDeletedItemsChecked;
                    const noAccessWarning = CONFIG.warningMessages.noAccess;
                    return (
                        <div style={{ display: "flex", gap: "24px" }} className="demo-card-body-details">
                            {!deleteView &&
                                <>
                                    <Tooltip title={isEditDisabled ? noAccessWarning : undefined} key={record.id}>
                                        <button
                                            onClick={() => {
                                                this.props.handleOnClickAction({
                                                    component: CONFIG.editComponentRoute.roleTemplates,
                                                    tabTitle: record?.title,
                                                    recordId: record?.id
                                                })
                                            }}
                                            disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}>
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </>
                            }
                            <Tooltip title={isDeleteDisabled ? noAccessWarning : undefined} key={record.id}>
                                {
                                    <>
                                        <button disabled={isDeleteDisabled} className={!isDeleteDisabled ? "link" : "link-disabled"} onClick={() => this.handleDelete(record.id)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                        {deleteView && <>
                                            <Popconfirm disabled={isEditDisabled} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id)}>
                                                <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                            </Popconfirm>
                                        </>
                                        }
                                    </>
                                }
                            </Tooltip>
                        </div>
                    )
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true
            }
        ];
        const drawerContainer = document.getElementById('#component-content');

        return (
            <React.Fragment>
                <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                    Manage Role Templates
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "16px", marginTop: "16px", padding: "16px 0px" }}>
                    {isUserSuperAdmin && <div style={{ display: "flex", flex: "1", justifyContent: "flex-start" }}>
                        <Button className="primary-action-button-filled"
                            onClick={() => {
                                this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.roleTemplates,
                                    tabTitle: "Create"
                                });
                                this.props.clearRoleTemplate();
                            }}>Create Role Template
                        </Button>
                    </div>}
                    <div style={{ display: "flex", flex: "1", justifyContent: "flex-start", width: "100%", flexDirection: "row-reverse", gap: "24px" }}>
                        <div >
                            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                                <Button className="export-download-button" icon="chevron-down" variant="secondary">Download</Button>
                            </Dropdown>
                        </div>
                        <div >
                            <NeoIcon size="md" className="clickable" onClick={() => this.setState(prevState => ({
                                showFilter: !prevState.showFilter
                            }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty({ filter: { ...this.filterSort } }))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </div>
                        <div >
                            <NeoIcon size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </div>
                    </div>
                </div>
                <div id="component-content" className="approvals-pagination-row">
                    <Row>
                        {this.state.showFilter && (
                            <FilterDrawer
                                visible={this.state.showFilter}
                                width='520'
                                handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                                placement="right"
                                drawerContainer={drawerContainer}
                                style={{ zIndex: 9999, }}
                                maskClosable={false}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                handleSave={this.handleSave}
                                timeZone={this.props.timeZoneName}
                                hideSoftDeleteCheckbox={false}
                                viewDeletedItemsValue={this.filterSort?.viewDeletedItems}
                                label="View Deleted Role Templates"
                                hideSave={false}
                            />)}
                    </Row>
                    <Row>
                        {
                            this.props.isFetching ? <Spin style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }} spinning={this.state.loading} indicator={antIcon} />
                                : <div style={{ minHeight: "250px" }}>
                                    <NeoTable
                                        columns={neoColumns}
                                        data={((!!filteredData && filteredData?.length) ? (filteredData || []) : allRoleTemplates || [])}
                                        className="table-actions"
                                        allowColumnFilter={false}
                                        itemsPerPageOptions={[10, 20, 50, 100]}
                                        showRowSeparator
                                        initialStatePageIndex={this.state.currentPage}
                                        handlePageChange={(newPageIndex, newPageSize) => {
                                            if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
                                        }}
                                        {...otherFields}
                                    />
                                </div>
                        }
                    </Row>
                </div>
                {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ roleTemplates, user, tabsLayout }) => {
    return {
        allRoleTemplates: roleTemplates.all,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        isFetching: roleTemplates.isFetching,
        roleTemplates,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        userProfile: user.profile,
        openedTabs: tabsLayout.panes,
        hasSave: tabsLayout.hasSave,
        pageNumber: roleTemplates?.pageNumber
    };
};

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        fetchRoleTemplatesLite,
        deleteRoleTemplate,
        toggleIsFetchingRoleTemplatesFlag,
        clearRoleTemplates,
        restoreRoleTemplate,
        exportTable,
        handleOnClickAction,
        currentRoleTemplatesFilter,
        hasSaveChanges,
        clearRoleTemplate,
        toggleSaveReportVisible
    }
)(ManageRoleTemplates);