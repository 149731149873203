import React from "react";
import { Modal, Table, Checkbox } from "antd";
import { connect } from "react-redux";

import CONFIG from "../../config";
import { updateReportRoleTemplate } from '../../actions';
import FormActionButtonsNeo from '../common/FormActionButtonsNeo';

class ReportRoleTemplates extends React.Component {

    getRoleTemplateAccess = (roleTemplateId, roleType) => {
        const { assignedRoleTemplates } = this.props;
        const roleTemplate = assignedRoleTemplates ? assignedRoleTemplates.find(assignedRoleTemplate => assignedRoleTemplate.roleTemplate.id === roleTemplateId) : undefined;
        return roleTemplate
            ? roleType === CONFIG.roleTypes.user
                ? roleTemplate.userAccess
                : roleTemplate.adminAccess
            : undefined
    }

    renderCheckbox = (roleTemplate, roleType, allowedAccess) => {
        const roleTemplateAccess = this.getRoleTemplateAccess(roleTemplate.id, roleType);
        const { isClone, isGeneralReport, recordId } = this.props;
        const templateType = roleTemplate.templateType
        return (
            <Checkbox
                disabled={(roleType === CONFIG.roleTypes.admin) && (templateType !== CONFIG.roleTypes.admin)}
                checked={roleTemplateAccess === allowedAccess}
                onChange={(e) => this.props.updateReportRoleTemplate(e.target.checked, roleType, allowedAccess, roleTemplate, isClone, isGeneralReport, recordId)}
            />
        );
    };

    render() {
        const { visible, handleCancel, handleAssign, userRoleTemplate, allRoleTemplates, isClone, isGeneralReport, recordId } = this.props;
        const isUserSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const adminColumns = [
            {
                title: <span className="primary-text-color">Admin</span>,
                children: [
                    {
                        title: (
                            <div>
                                Read
                                <br />
                                <Checkbox
                                    checked={this.props.allRoleTemplatesAdminAccess === CONFIG.roleTemplateAccess.readOnly}
                                    onChange={e => this.props.updateReportRoleTemplate(e.target.checked, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.readOnly, undefined, isClone, isGeneralReport, recordId)}
                                />
                            </div>
                        ),
                        key: "adminReadAccess",
                        align: "center",
                        width: '17%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.readOnly)
                    },
                    {
                        title: (
                            <div>
                                Write
                                <br />
                                <Checkbox
                                    checked={this.props.allRoleTemplatesAdminAccess === CONFIG.roleTemplateAccess.fullAccess}
                                    onChange={e => this.props.updateReportRoleTemplate(e.target.checked, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.fullAccess, undefined, isClone, isGeneralReport, recordId)}
                                />
                            </div>
                        ),
                        key: "adminWriteAccess",
                        align: "center",
                        width: '17%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.fullAccess)
                    }
                ]
            }
        ]

        const columns = [
            {
                title: "Title",
                dataIndex: "title",
                key: "name",
                width: '30%'
            },
            {
                title: "User",
                children: [
                    {
                        title: (
                            <div>
                                Read-only
                                <br />
                                <Checkbox
                                    checked={this.props.allRoleTemplatesUserAccess === CONFIG.roleTemplateAccess.readOnly}
                                    onChange={e => this.props.updateReportRoleTemplate(e.target.checked, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.readOnly, undefined, isClone, isGeneralReport, recordId)}
                                    disabled={allRoleTemplates && allRoleTemplates.length === 0}
                                />
                            </div>
                        ),
                        key: "userReadAccess",
                        align: "center",
                        width: '17%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.readOnly)
                    },
                    {
                        title: (
                            <div>
                                Full-access
                                <br />
                                <Checkbox
                                    checked={this.props.allRoleTemplatesUserAccess === CONFIG.roleTemplateAccess.fullAccess}
                                    onChange={e => this.props.updateReportRoleTemplate(e.target.checked, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.fullAccess, undefined, isClone, isGeneralReport, recordId)}
                                    disabled={allRoleTemplates && allRoleTemplates.length === 0}
                                />
                            </div>
                        ),
                        key: "userFullAccess",
                        align: "center",
                        width: '15%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.fullAccess)
                    }
                ]
            }
        ];

        return (
            <div>
                <Modal
                    title="Role Template"
                    visible={visible}
                    // style={{ width: "55%" }}
                    className="report-role-template-modal"
                    onCancel={handleCancel}
                    bodyStyle={{ height: `${document.body.clientHeight - 120}px`, overflowY: "auto" }}
                    footer={<FormActionButtonsNeo okText="Assign" handleCancel={handleCancel} handleSubmit={handleAssign} />}
                >
                    <Table
                        size='middle'
                        bordered
                        pagination={false}
                        dataSource={allRoleTemplates}
                        columns={isUserSuperAdmin ? [...columns, ...adminColumns] : columns}
                        rowKey={(record) => record.id}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ user, roleTemplates }) => {
    return {
        allRoleTemplates: roleTemplates.all,
        // allRoleTemplatesUserAccess: currentReport.allRTUserAccess,
        // allRoleTemplatesAdminAccess: currentReport.allRTAdminAccess,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined
    }
}

export default connect(
    mapStateToProps,
    { updateReportRoleTemplate }
)(ReportRoleTemplates);
