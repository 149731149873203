import { combineReducers } from "redux";
import { userReducer } from "./user";
import { headerReducer } from "./header";
import { resourcesReducer, resourceFormReducer, allResourcesReducer, rtResourceReducer } from './resources';
import {
  demosReducer,
  demoFormReducer,
  demoCollateralsReducer,
  openTabDemoDetailsReducer,
  demoEZDemosReducer
} from './demo';
import { maintenanceFormReducer, maintenanceScheduleReducer } from './maintenance';
import { newsReducer, currentNewsReducer, newsIntroReducer, newsBodyReducer, userNewsReducer } from './news';
import { roleTemplatesReducer, roleTemplateReducer } from './roleTemplates'
import { solutionsReducer, solutionFormReducer, demoSolutionsReducer, demoSolutionAccessReducer, solutionTypeDataReducer } from './solutions';
import { solutionTypesReducer, solutionTypeFormReducer } from "./solutionType";
import { feedbackQuestionsReducer, feedbackQuestionsFormReducer } from "./feedbackQuestions";
import { demoQuestionsReducer, demoQuestionsFormReducer } from "./demoQuestions";
import { demoSchedulesReducer, manageDemoSchedulerReducer, demoSchedulesAutomaticReducer, demoSchedulesManualReducer } from './schedules';
import { notificationsReducer, notificationsFormReducer, notificationsDescriptionReducer, realTimePushNotificationReducer, userBellNotificationsReducer, notificationFormDetailsReducers, userDashboardNotificationsReducer } from './notifications';
import { schedulerReducer, newScheduleReducer } from './scheduler';
import { supportReducer, supportFormReducer, userSupportReducer } from './support';
import { viewportReducer } from './viewport';
import { dashboardReducer } from './dashboard';
import { adminDashboardReducer } from './adminDashboard';
import { userRoleReducer } from './userRole';
import { dataCentersReducer } from './dataCenters';
import { didsReducer, providersReducer } from './dids';
import { networksReducer, ipAddressFormReducer } from './networks';
import { standardEmailTemplatesReducers, customEmailTemplatesReducers, emailTemplatesFormReducers, emailTemplatesReducers } from './emailTemplates';
import { localUsersReducer, localUsersFormReducer } from './localUsers';
import { tipsReducer, currentTipReducer, tipIntroReducer, tipBodyReducer } from './tips'
import { trendingDemosReducer } from './trendingDemos';
import { mostScheduledDemosReducer } from './trendingDemos';
import { reportsReducer, currentReportReducer, generalReportsReducer, userCreatedReportsReducer, userReportsReducer } from './reports';
import { demoApprovalsReducer, demoRequestEmailPreviewReducer } from './demoApprovals';
import { demoPurposeReducer, demoPurposeFormReducer } from './demoPurpose'
import { dataCenterStatusReducer } from "./dataCenterStatus";
import { demoRequestReducer } from "./demoRequest";
import { dynamicEmailTemplateReducer, dynamicEmailPreviewReducer, dynamicEmailReducer } from "./dynamicEmailTemplate"
import { customEmailTemplateReducer } from "./customEmailTemplateVersions"
import { tabsLayoutReducer, componentTabsReducer } from "./tabsLayout";
import { dailyAttributeReducer } from "./dailyAttributes";
import { dailyReportReducer } from "./dailyReport";
import { productReducer } from "./products";
import { filterPanelReducer } from "./filterPanel";
import { ezDemosReducer, ezDemosFormReducer, manageEZDemosReducer, ezDemosFeedbackReducer } from "./ezDemos";
import { demoCapabilityReducer, capabilityFormReducer, capabilityRequestDetailsReducer, capabilityGeneralInstructionReducer, capabilityQuestionInstructionReducer, capabilityCommentDetailsReducer } from "./demoCapability";
import { capabilityDemosReducer } from "./capabilityDemos"
import { capabilityRequestReducer, allCapabilityReducer } from "./capabilityRequest";
import { myDemoSchedulesReducer } from "./mySchedules";
import { myRequestsReducer } from "./myRequests";
import { userDashboardSectionViewsReducer, adminDashboardSectionViewsReducer } from "./dashboardSections";
import { sfdcStatsReducer } from "./sfdcStats";

export default combineReducers({
  user: userReducer,
  header: headerReducer,
  resources: resourcesReducer,
  resourceForm: resourceFormReducer,
  allResources: allResourcesReducer,
  demos: demosReducer,
  demoForm: demoFormReducer,
  openTabDemoDetails: openTabDemoDetailsReducer,
  demoCollaterals: demoCollateralsReducer,
  maintenanceForm: maintenanceFormReducer,
  maintenanceSchedule: maintenanceScheduleReducer,
  news: newsReducer,
  userNews: userNewsReducer,
  currentNews: currentNewsReducer,
  newsIntro: newsIntroReducer,
  newsBody: newsBodyReducer,
  roleTemplates: roleTemplatesReducer,
  roleTemplate: roleTemplateReducer,
  solutions: solutionsReducer,
  solutionForm: solutionFormReducer,
  solutionTypes: solutionTypesReducer,
  solutionTypeForm: solutionTypeFormReducer,
  demoSchedules: demoSchedulesReducer,
  demoSolutions: demoSolutionsReducer,
  notifications: notificationsReducer,
  notificationsForm: notificationsFormReducer,
  notificationFormDetails: notificationFormDetailsReducers,
  notificationsDescription: notificationsDescriptionReducer,
  userDashboardNotifications: userDashboardNotificationsReducer,
  support: supportReducer,
  supportForm: supportFormReducer,
  viewport: viewportReducer,
  scheduler: schedulerReducer,
  newSchedule: newScheduleReducer,
  dashboard: dashboardReducer,
  adminDashboard: adminDashboardReducer,
  userRole: userRoleReducer,
  dataCenters: dataCentersReducer,
  dids: didsReducer,
  networks: networksReducer,
  ipAddressForm: ipAddressFormReducer,
  providers: providersReducer,
  standardEmailTemplates: standardEmailTemplatesReducers,
  emailTemplates: emailTemplatesReducers,
  customEmailTemplates: customEmailTemplatesReducers,
  emailTemplateForm: emailTemplatesFormReducers,
  localUsersForm: localUsersFormReducer,
  localUsers: localUsersReducer,
  realTimePushNotification: realTimePushNotificationReducer,
  userBellNotifications: userBellNotificationsReducer,
  tips: tipsReducer,
  currentTip: currentTipReducer,
  tipIntro: tipIntroReducer,
  tipBody: tipBodyReducer,
  trendingDemos: trendingDemosReducer,
  mostScheduledDemos: mostScheduledDemosReducer,
  reports: reportsReducer,
  generalReports: generalReportsReducer,
  userCreatedReports: userCreatedReportsReducer,
  userReports: userReportsReducer,
  currentReport: currentReportReducer,
  demoApprovals: demoApprovalsReducer,
  demoPurpose: demoPurposeReducer,
  demoPurposeForm: demoPurposeFormReducer,
  dataCenterStatus: dataCenterStatusReducer,
  feedbackQuestions: feedbackQuestionsReducer,
  feedbackQuestionsForm: feedbackQuestionsFormReducer,
  demoQuestions: demoQuestionsReducer,
  demoQuestionsForm: demoQuestionsFormReducer,
  demoRequest: demoRequestReducer,
  dynamicEmail: dynamicEmailReducer,
  dynamicEmailTemplate: dynamicEmailTemplateReducer,
  dynamicEmailPreview: dynamicEmailPreviewReducer,
  customEmailTemplate: customEmailTemplateReducer,
  demoSolutionAccess: demoSolutionAccessReducer,
  tabsLayout: tabsLayoutReducer,
  componentTabs: componentTabsReducer,
  dailyAttribute: dailyAttributeReducer,
  dailyReport: dailyReportReducer,
  product: productReducer,
  filterPanel: filterPanelReducer,
  manageDemoSchedule: manageDemoSchedulerReducer,
  demoSchedulesAutomatic: demoSchedulesAutomaticReducer,
  demoSchedulesManual: demoSchedulesManualReducer,
  ezDemos: ezDemosReducer,
  demoEZDemos: demoEZDemosReducer,
  manageEZDemos: manageEZDemosReducer,
  demoCapability: demoCapabilityReducer,
  capabilityForm: capabilityFormReducer,
  capabilityGeneralInstruction: capabilityGeneralInstructionReducer,
  capabilityQuestionInstruction: capabilityQuestionInstructionReducer,
  capabilityDemos: capabilityDemosReducer,
  capabilityRequestDetails: capabilityRequestDetailsReducer,
  capabilityRequest: capabilityRequestReducer,
  allCapability: allCapabilityReducer,
  capabilityCommentDetails: capabilityCommentDetailsReducer,
  ezDemosForm: ezDemosFormReducer,
  ezDemoFeedback: ezDemosFeedbackReducer,
  mySchedules: myDemoSchedulesReducer,
  solutionTypeData: solutionTypeDataReducer,
  userSupport: userSupportReducer,
  myRequests: myRequestsReducer,
  demoRequestEmailPreview: demoRequestEmailPreviewReducer,
  userDashboardSections: userDashboardSectionViewsReducer,
  adminDashboardSections: adminDashboardSectionViewsReducer,
  rtResource: rtResourceReducer,
  sfdcStats: sfdcStatsReducer
});
