import {
    FETCH_DEMO_SCHEDULES,
    CLEAR_DEMO_SCHEDULES_TABLE,
    TOGGLE_IS_FETCHING_DEMO_SCHEDULES,
    TOGGLE_IS_FETCHING_SCHEDULE,
    CLEAR_DEMO_SCHEDULES,
    FETCH_DEMO_SCHEDULE,
    TOGGLE_IS_FETCHING_DEMO_REVIEWS,
    FETCH_ALL_USERS_REVIEWS,
    FETCH_USER_REVIEWS,
    FETCH_SCHEDULE_FEEDBACK,
    TOGGLE_IS_FETCHING_SCHEDULE_FEEDBACK,
    FETCH_DEMO_RATING_SPLIT_UP,
    FETCH_SCHEDULE_FEEDBACK_ASSIGNED_QUESTIONS,
    CLEAR_SCHEDULES_RESPONSE,
    LAST_SCHEDULE_FILTER,
    UPDATE_SCHEDULED_DEMO_CURRENT_PAGE,
    FETCH_MANAGE_SCHEDULE_DETAILS,
    CLEAR_MANAGE_DEMO_SCHEDULES,
    CLEAR_AUTO_BUILDS_SCHEDULES,
    UPDATE_AUTOBUILDS_SCHEDULES_CURRENT_PAGE,
    FETCH_AUTO_BUILDS_SCHEDULES,
    FETCH_AUTO_DEMO_SCHEDULE_COMMENTS,
    LAST_AUTO_BUILDS_FILTER,
    FETCH_MANUAL_DEMO_SCHEDULE_COMMENTS,
    FETCH_MANUAL_BUILDS_SCHEDULES,
    UPDATE_MANUALBUILDS_SCHEDULES_CURRENT_PAGE,
    CLEAR_MANUAL_BUILDS_SCHEDULES,
    LAST_MANUAL_BUILDS_FILTER
} from '../actions/types';

const demoSchedulesState = {
    isFetching: false,
    isFetchingSchedule: false,
    allUsersReviews: [],
    demoSchedule: [],
    userReviews: [],
    scheduleDetails: [],
    scheduleFeedback: [],
    isFetchingFeedback: false,
    isFetchingReviews: false,
    scheduleFeedbackAssignedQuestions: []
};

export const demoSchedulesReducer = (state = demoSchedulesState, action) => {
    switch (action.type) {
        case FETCH_ALL_USERS_REVIEWS:
            return { ...state, allUsersReviews: action.payload?.dataSet || [], allUsersReviewsCount: action.payload?.count };
        case FETCH_USER_REVIEWS:
            return { ...state, userReviews: action.payload || [] };
        case FETCH_DEMO_RATING_SPLIT_UP:
            return { ...state, ratingSplitUp: action.payload || [] };
        case FETCH_SCHEDULE_FEEDBACK:
            return { ...state, scheduleFeedback: action.payload || [] };
        case FETCH_SCHEDULE_FEEDBACK_ASSIGNED_QUESTIONS:
            return { ...state, scheduleFeedbackAssignedQuestions: action.payload || [] };
        case TOGGLE_IS_FETCHING_DEMO_REVIEWS:
            return { ...state, isFetchingReviews: action.payload };
        case TOGGLE_IS_FETCHING_SCHEDULE_FEEDBACK:
            return { ...state, isFetchingFeedback: action.payload };
        case CLEAR_DEMO_SCHEDULES:
            return demoSchedulesState;
        case CLEAR_SCHEDULES_RESPONSE:
            return { ...state, response: action.payload };
        default:
            return state;
    };
}

const manageScheduleDemoState = {
    demoSchedule: [],
    data: [],
    scheduleDetails: [],
    isFetching: false,
}

export const manageDemoSchedulerReducer = (state = manageScheduleDemoState, action) => {
    switch (action.type) {
        case FETCH_DEMO_SCHEDULES:
            return { ...state, data: action.payload?.dataSet || [], count: action.payload?.count || 0 };
        case CLEAR_DEMO_SCHEDULES_TABLE:
            return { ...state, data: [], count: 0 };
        case FETCH_DEMO_SCHEDULE:
            return { ...state, demoSchedule: action?.payload?.data, scheduleResponse: action?.payload?.status }
        case FETCH_MANAGE_SCHEDULE_DETAILS:
            return { ...state, scheduleDetails: action.payload || [] }
        case LAST_SCHEDULE_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], pageNumber: action.payload[2], isEdit: true };
        case UPDATE_SCHEDULED_DEMO_CURRENT_PAGE:
            return { ...state, scheduledDemoCurrentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentScheduleId: action.payload[2] || undefined, scheduledDemoIndex: action.payload[3] || undefined };
        case CLEAR_MANAGE_DEMO_SCHEDULES:
            return manageScheduleDemoState;
        case TOGGLE_IS_FETCHING_DEMO_SCHEDULES:
            return { ...state, isFetching: action.payload };
        case TOGGLE_IS_FETCHING_SCHEDULE:
            return { ...state, isFetchingSchedule: action.payload };
        default:
            return state;
    }
}

const manageAutoBuildsState = {
    tearDownData: [],
    comments: [],
    isFetching: false,
}

export const demoSchedulesAutomaticReducer = (state = manageAutoBuildsState, action) => {
    switch (action.type) {
        case FETCH_AUTO_DEMO_SCHEDULE_COMMENTS:
            return { ...state, comments: action.payload }
        case FETCH_AUTO_BUILDS_SCHEDULES:
            return { ...state, tearDownData: action.payload.dataSet, tearDownCount: action.payload.count }
        case UPDATE_AUTOBUILDS_SCHEDULES_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentscheduleId: action.payload[2] || undefined, scheduleIndex: action.payload[3] || undefined };
        case CLEAR_AUTO_BUILDS_SCHEDULES:
            return manageAutoBuildsState;
        case LAST_AUTO_BUILDS_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true };
        case TOGGLE_IS_FETCHING_DEMO_SCHEDULES:
            return { ...state, isFetching: action.payload };
        default:
            return state;
    }

}

const manageManualBuildsState = {
    tearDownData: [],
    comments: [],
    isFetching: false,
}

export const demoSchedulesManualReducer = (state = manageManualBuildsState, action) => {
    switch (action.type) {
        case FETCH_MANUAL_DEMO_SCHEDULE_COMMENTS:
            return { ...state, comments: action.payload }
        case FETCH_MANUAL_BUILDS_SCHEDULES:
            return { ...state, tearDownData: action.payload?.data || [], tearDownCount: action.payload?.data?.length || 0 }
        case UPDATE_MANUALBUILDS_SCHEDULES_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentscheduleId: action.payload[2] || undefined, scheduleIndex: action.payload[3] || undefined };
        case CLEAR_MANUAL_BUILDS_SCHEDULES:
            return manageManualBuildsState;
        case LAST_MANUAL_BUILDS_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true };
        case TOGGLE_IS_FETCHING_DEMO_SCHEDULES:
            return { ...state, isFetching: action.payload };
        default:
            return state;
    }

}