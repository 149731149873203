import { batch } from 'react-redux';

import {
    FETCH_ROLE_TEMPLATES,
    FETCH_ROLE_TEMPLATE,
    FETCH_DOMAINS,
    UPDATE_DOMAINS,
    DELETE_ROLE_TEMPLATE,
    UPDATE_ROLE_TEMPLATE_DEMOS,
    CLEAR_ROLE_TEMPLATE,
    TOGGLE_ROLE_TEMPLATES_DEMOS_ALL,
    TOGGLE_ROLE_TEMPLATES_ENVIRONMENT_ALL,
    UPDATE_ROLE_TEMPLATE_ENVIRONMENT,
    UPDATE_ROLE_TEMPLATE_NEWS,
    UPDATE_ROLE_TEMPLATE_REPORTS,
    UPDATE_ROLE_TEMPLATE_TIPS,
    UPDATE_ROLE_TEMPLATE_NOTIFICATIONS,
    TOGGLE_IS_FETCHING_ROLE_TEMPLATES,
    CLEAR_ROLE_TEMPLATES,
    UPDATE_ROLE_TEMPLATE_TYPE,
    UPDATE_NETWORK_ADMIN,
    UPDATE_ROLE_TEMPLATE_RESOURCES,
    UPDATE_ROLE_TEMPLATE_SOLUTIONS,
    UPDATE_ROLE_TEMPLATE_SUPPORT,
    UPDATE_ROLE_TEMPLATE_DEMO_QUESTIONS,
    UPDATE_ROLE_TEMPLATE_NETWORKS,
    UPDATE_ADMIN_ROLE_TEMPLATES,
    UPDATE_ROLE_TEMPLATE_CAPABILITY,
    UPDATE_ROLE_TEMPLATE_EZ_DEMOS,
    SAVE_ROLE_TEMPLATES,
    CANCEL_ROLE_TEMPLATES,
    UPDATE_ROLE_TEMPLATE_SOLUTION_TYPES,
    LAST_ROLE_TEMPLATES_FILTER,
    UPDATE_ROLE_TEMPLATE_DEMO_PURPOSE,
    UPDATE_ROLE_TEMPLATE_DEMO_FEEDBACK_QUESTIONS,
    SAVE_TIPS_ROLE_TEMPLATES,
    CANCEL_TIPS_ROLE_TEMPLATES,
    SAVE_SUPPORT_ROLE_TEMPLATES,
    CANCEL_SUPPORT_ROLE_TEMPLATES,
    SAVE_SOLUTIONS_ROLE_TEMPLATES,
    CANCEL_SOLUTIONS_ROLE_TEMPLATES,
    SAVE_SOLUTION_TYPE_ROLE_TEMPLATES,
    CANCEL_SOLUTION_TYPE_ROLE_TEMPLATES,
    SAVE_RESOURCES_ROLE_TEMPLATES,
    CANCEL_RESOURCES_ROLE_TEMPLATES,
    SAVE_REPORTS_ROLE_TEMPLATES,
    CANCEL_REPORTS_ROLE_TEMPLATES,
    SAVE_NOTIFICATIONS_ROLE_TEMPLATES,
    CANCEL_NOTIFICATIONS_ROLE_TEMPLATES,
    SAVE_NEWS_ROLE_TEMPLATES,
    CANCEL_NEWS_ROLE_TEMPLATES,
    SAVE_DEMO_QUESTIONS_ROLE_TEMPLATES,
    CANCEL_DEMO_QUESTIONS_ROLE_TEMPLATES,
    SAVE_FEEDBACK_QUESTIONS_ROLE_TEMPLATES,
    CANCEL_FEEDBACK_QUESTIONS_ROLE_TEMPLATES,
    SAVE_DEMO_PURPOSE_ROLE_TEMPLATES,
    CANCEL_DEMO_PURPOSE_ROLE_TEMPLATES,
    SAVE_DEMO_ROLE_TEMPLATES,
    CANCEL_DEMO_ROLE_TEMPLATES,
    SAVE_NETWORKS_ROLE_TEMPLATES,
    CANCEL_NETWORKS_ROLE_TEMPLATES,
    SAVE_DEMO_CAPABILITY_ROLE_TEMPLATES,
    CANCEL_DEMO_CAPABILITY_ROLE_TEMPLATES,
    SAVE_EZ_DEMOS_ROLE_TEMPLATES,
    CANCEL_EZ_DEMOS_ROLE_TEMPLATES
} from './types';
import CONFIG from '../config'
import pushMessage from '../components/common/PushMessage';
import { aepUser } from '../apis';
import { getAllDemos } from './demos';
import { getAllNews } from './news';
import { getAllNotifications } from './notifications';
import { getAllSupport } from './support'
import { getAllResources } from './resources';
import { getAllTips } from './tips';
import { getAllReports } from './reports';
import { getAllPurpose } from './demoPurpose';
import { getAllFeedbackQuestions } from './feedbackQuestions';
import { getAllDemoQuestions } from './demoQuestions';
import { closeCurrentOpenTab } from './tabsLayout';

export const fetchRoleTemplates = (removeSuperAdmin = true, viewDeletedItems = false) => async dispatch => {
    const response = await aepUser.get('/userTemplateService/roleTemplates', { params: { viewDeletedItems } });
    const roleTemplates = (response.data && removeSuperAdmin)
        ? response.data.filter(roleTemplate => roleTemplate.templateType !== CONFIG.roleTypes.superAdmin)
        : response.data
    batch(() => {
        dispatch({
            type: FETCH_ROLE_TEMPLATES,
            payload: roleTemplates
        });
        dispatch(toggleIsFetchingRoleTemplatesFlag(false));
    });
};

export const fetchRoleTemplatesLite = (removeSuperAdmin = true, viewDeletedItems = false) => async dispatch => {
    const response = await aepUser.get('/userTemplateService/v1/lite/roleTemplates', { params: { viewDeletedItems } });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        const roleTemplates = (response.data && removeSuperAdmin)
            ? response.data.filter(roleTemplate => roleTemplate.templateType !== CONFIG.roleTypes.superAdmin)
            : response.data
        batch(() => {
            dispatch({
                type: FETCH_ROLE_TEMPLATES,
                payload: roleTemplates
            });
        });
    }
    else {
        batch(() => {
            dispatch({
                type: FETCH_ROLE_TEMPLATES,
                payload: []
            });
        });
    }
    dispatch(toggleIsFetchingRoleTemplatesFlag(false));
};

export const fetchRoleTemplate = (roleTemplateId) => async dispatch => {
    const response = await aepUser.get(`/userTemplateService/roleTemplates/${roleTemplateId}`);
    const data = {
        ...response.data,
        questions: response.data.questions?.filter(item => item.question.questionType === "feedback"),
        demoQuestions: response.data.questions?.filter(item => item.question.questionType === "demo")
    }
    batch(() => {
        dispatch({
            type: FETCH_ROLE_TEMPLATE,
            payload: data
        });
        dispatch(toggleIsFetchingRoleTemplatesFlag(false));
    })
};

export const fetchDomains = () => async dispatch => {
    const response = await aepUser.get('/userTemplateService/domains');
    response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT
        ? dispatch({
            type: FETCH_DOMAINS,
            payload: response.data ? response.data : []
        })
        : pushMessage(CONFIG.messageType.error, 'Unable to fetch domains');
};

export const postNewDomain = (domain) => async dispatch => {
    const data = {
        name: domain
    };
    const response = await aepUser.post('/userTemplateService/domain', data);
    if (response.status === CONFIG.HTTP_STATUS.CREATED) {
        dispatch({
            type: UPDATE_DOMAINS,
            payload: response.data
        });
        pushMessage(CONFIG.messageType.success, 'New domain added successfully');
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to create a domain');
};

export const deleteRoleTemplate = (roleTemplateId, hardDelete) => async dispatch => {
    const response = await aepUser.delete(`/userTemplateService/roleTemplates/${roleTemplateId}`, { params: { hardDelete } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: DELETE_ROLE_TEMPLATE,
            payload: roleTemplateId
        });
        pushMessage(CONFIG.messageType.success, 'Role template deleted successfully');
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to delete role template');
}

export const restoreRoleTemplate = (roleTemplateId) => async () => {
    const response = await aepUser.put("/userTemplateService/restore/roleTemplates/" + roleTemplateId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Role Template restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore role template");
}

export const updateRoleTemplateType = (templateType) => {
    return {
        type: UPDATE_ROLE_TEMPLATE_TYPE,
        payload: templateType
    };
}

export const updateNetworkAdmin = (flag) => {
    return {
        type: UPDATE_NETWORK_ADMIN,
        payload: flag
    };
}

/*
    access: 0 or 1 (unchecked/checked)
    roleType: user/admin
    accessType: read/full
    selectedItem: demos/news/notifications/etc. 
    assignedItems: assgined demos/news/notifications/etc. 
    key: demo/news/notification/etc. 
    getAllItems: Fn reference of getAllDemos() / getAllNews() / getAllNotifications()
    fromManageRoleTemplates: To determine whether the Fn is being called from Manage > Users > Role Temaplates or not
*/

export const getUpdatedRoleTemplateAssociations = (access, roleType, accessType, selectedItem, assignedItems, key, allItems, fromManageRoleTemplates = true) => {
    let updatedItems = [];
    let accessKey = roleType === CONFIG.roleTypes.admin ? 'adminAccess' : 'userAccess';
    // If checked
    if (access) {
        // Change user or admin access for a selected item
        if (selectedItem) {
            let isItemUpdated = false;
            // Find if the selected item is already associated with the role template and change user or admin access if it is.
            updatedItems = assignedItems
                ? assignedItems.map(assignedItem => {
                    if (assignedItem[key].id === selectedItem.id) {
                        isItemUpdated = true;
                        return { ...assignedItem, [accessKey]: accessType }
                    }
                    return assignedItem;
                })
                : updatedItems;
            // If the selected item is not associated with RT, add the association
            if (!isItemUpdated) updatedItems = [...updatedItems, { [key]: selectedItem, [accessKey]: accessType }]
        }
        // Change user or admin access for all items
        else {
            // assignedItemsMap: To get the item by it's ID instead of using find() every time.
            let assignedItemsMap = {};
            assignedItems && assignedItems.forEach(assignedItem => {
                const item = assignedItem[key];
                assignedItemsMap[item.id] = assignedItem
            });

            const assignedItemIds = Object.keys(assignedItemsMap);
            /* 
                Loop through all items in the table
                If it is already associated to the role template
                    1.Retain its access for other roleType
                    2.Change the access for selected roleType
                else Add associate the item to the role template with the selected roleType access
            */
            updatedItems = allItems?.map(item => {
                const isItemAssigned = assignedItemIds.includes(item.id.toString());
                let updatedItem = isItemAssigned ? assignedItemsMap[item.id] : { [key]: item };
                if (!fromManageRoleTemplates && roleType === CONFIG.roleTypes.admin && item.templateType !== CONFIG.roleTypes.admin) return updatedItem
                return { ...updatedItem, [accessKey]: accessType };
            });
        }
    }
    // If unchecked
    else if (selectedItem) {
        // Find the item in the assigned items and change the access for selected roleType
        // If not found, add the item to assigned items with the selected roleType access
        assignedItems && assignedItems.forEach(assignedItem => {
            assignedItem[key].id !== selectedItem.id
                ? updatedItems.push(assignedItem)
                : updatedItems.push({ ...assignedItem, [accessKey]: CONFIG.demoAccess.none })
        });
    }
    else {
        // Make the access for selected roleType 'none' for all assigned items while retaining the access for the other roleType
        assignedItems && assignedItems.forEach(assignedItem => {
            updatedItems.push({ ...assignedItem, [accessKey]: CONFIG.demoAccess.none });
        });
    }
    return updatedItems;
}

export const updateRoleTemplateDemos = (access, roleType, accessType, selectedDemo) => async (dispatch, getState) => {
    const { roleTemplate, user } = getState();
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
    let assignedDemos = roleTemplate.demos ? roleTemplate.demos : [];
    const accessKey = roleType === CONFIG.roleTypes.user ? 'allDemosUserAccess' : 'allDemosAdminAccess';
    const allDemos = !selectedDemo && access ? await getAllDemos({ access: adminAccess }) : undefined;
    const updatedDemos = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedDemo, assignedDemos, 'demo', allDemos);

    batch(() => {
        dispatch({
            type: UPDATE_ROLE_TEMPLATE_DEMOS,
            payload: { updatedDemos, isAllAccessChanged: selectedDemo === undefined, accessKey }
        });
        if (!selectedDemo) {
            dispatch({
                type: TOGGLE_ROLE_TEMPLATES_DEMOS_ALL,
                payload: { access, accessType, accessKey }
            });
        }
    });
};

export const clearRoleTemplate = () => {
    return {
        type: CLEAR_ROLE_TEMPLATE
    };
};

export const updateRoleTemplateNews = (access, accessType, roleType, selectedNews) => async (dispatch, getState) => {
    const { roleTemplate, user } = getState();
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
    let assignedNews = roleTemplate.news ? roleTemplate.news : [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allNewsUserAccess' : 'allNewsAdminAccess';
    const allNews = (!selectedNews && access) ? await getAllNews(adminAccess) : undefined;
    const updatedNews = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedNews, assignedNews, 'news', allNews);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_NEWS,
        payload: { updatedNews, selectedNews, accessType, access, allAccessKey }
    });
};

export const updateRoleTemplateDemoPurpose = (access, accessType, roleType, selectedPurpose) => async (dispatch, getState) => {
    const { roleTemplate, user } = getState();
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
    let assignedDemoPurposes = roleTemplate.demoPurposes ? roleTemplate.demoPurposes : [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allDemoPurposeUserAccess' : 'allDemoPurposeAdminAccess';
    const allPurpose = (!selectedPurpose && access) ? await getAllPurpose(adminAccess) : undefined;
    const updatedPurpose = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedPurpose, assignedDemoPurposes, 'demoPurpose', allPurpose);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_DEMO_PURPOSE,
        payload: { updatedPurpose, selectedPurpose, accessType, access, allAccessKey }
    });
};


export const updateRoleTemplateFeedbackQuestions = (access, accessType, roleType, selectedQuestion) => async (dispatch, getState) => {
    const { roleTemplate, user } = getState();
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
    let assignedFeedbackQuestions = roleTemplate.questions ? roleTemplate.questions : [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allFeedbackQuestionUserAccess' : 'allFeedbackQuestionAdminAccess';
    const allQuestions = (!selectedQuestion && access) ? await getAllFeedbackQuestions(adminAccess) : undefined;
    const updatedQuestions = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedQuestion, assignedFeedbackQuestions, 'question', allQuestions);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_DEMO_FEEDBACK_QUESTIONS,
        payload: { updatedQuestions, selectedQuestion, accessType, access, allAccessKey }
    });
};


export const updateRoleTemplateDemoQuestions = (access, accessType, roleType, selectedDemoQuestion) => async (dispatch, getState) => {
    const roleTemplate = getState().roleTemplate;
    let assignedDemoQuestion = roleTemplate.demoQuestions ? roleTemplate.demoQuestions : [];
    const allDemoQuestions = (!selectedDemoQuestion && access) ? await getAllDemoQuestions() : undefined;
    const updatedDemoQuestion = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedDemoQuestion, assignedDemoQuestion, 'question', allDemoQuestions);

    dispatch({
        type: UPDATE_ROLE_TEMPLATE_DEMO_QUESTIONS,
        payload: { updatedDemoQuestion, selectedDemoQuestion, accessType, access }
    });
};


export const updateRoleTemplateReports = (access, roleType, accessType, selectedReport) => async (dispatch, getState) => {
    const { roleTemplate, user } = getState();
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
    let assignedReports = roleTemplate.reports ? roleTemplate.reports : [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allReportsUserAccess' : 'allReportsAdminAccess';
    const allReports = (!selectedReport && access) ? await getAllReports(adminAccess) : undefined;
    const updatedReports = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedReport, assignedReports, 'report', allReports);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_REPORTS,
        payload: { updatedReports, selectedReport, accessType, access, allAccessKey }
    });
};

export const updateRoleTemplateTips = (access, accessType, roleType, selectedTip) => async (dispatch, getState) => {
    const { roleTemplate, user } = getState();
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
    let assignedTips = roleTemplate.tips || [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allTipsUserAccess' : 'allTipsAdminAccess';
    const allTips = (!selectedTip && access) ? await getAllTips(adminAccess) : undefined;
    const updatedTips = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedTip, assignedTips, 'tip', allTips);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_TIPS,
        payload: { updatedTips, selectedTip, accessType, access, allAccessKey }
    });
};

export const updateRoleTemplateNotifications = (access, accessType, roleType, selectedNotification) => async (dispatch, getState) => {
    const { roleTemplate, user } = getState();
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
    let assignedNotifications = roleTemplate.notifications ? roleTemplate.notifications : [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allNotificationsUserAccess' : 'allNotificationsAdminAccess';
    const allNotifications = !selectedNotification && access ? await getAllNotifications(adminAccess) : undefined;
    const updatedNotifications = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedNotification, assignedNotifications, 'notification', allNotifications);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_NOTIFICATIONS,
        payload: { updatedNotifications, selectedNotification, accessType, access, allAccessKey }
    });
};

export const updateRoleTemplateResources = (access, accessType, roleType, selectedResource) => async (dispatch, getState) => {
    const { roleTemplate, user } = getState();
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined;
    const adminAccess = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined
    let assignedResources = roleTemplate.resources ? roleTemplate.resources : [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allResourcesUserAccess' : 'allResourcesAdminAccess';
    const allResources = !selectedResource && access ? await getAllResources({ access: adminAccess }) : undefined;
    const updatedResources = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedResource, assignedResources, 'resource', allResources);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_RESOURCES,
        payload: { updatedResources, selectedResource, accessType, access, allAccessKey }
    });
};

export const updateRoleTemplateSolutions = (access, accessType, roleType, selectedSolution) => async (dispatch, getState) => {
    const { roleTemplate, solutions } = getState();
    let assignedSolutions = roleTemplate.solutions ? roleTemplate.solutions : [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allSolutionsUserAccess' : 'allSolutionsAdminAccess';
    const allSolutions = !selectedSolution && access ? (solutions.data ?? solutions.data) : undefined;

    const updatedSolutions = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedSolution, assignedSolutions, 'solution', allSolutions);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_SOLUTIONS,
        payload: { updatedSolutions, selectedSolution, accessType, access, allAccessKey }
    });
};

export const updateRoleTemplateSolutionTypes = (access, accessType, roleType, selectedSolutionType) => async (dispatch, getState) => {
    const { roleTemplate, solutionTypes } = getState();
    let assignedSolutionTypes = roleTemplate.solutionTypes ? roleTemplate.solutionTypes : [];
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allSolutionTypesUserAccess' : 'allSolutionTypesAdminAccess';
    const allSolutionTypes = !selectedSolutionType && access ? solutionTypes?.types : undefined;
    const updatedSolutionTypes = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedSolutionType, assignedSolutionTypes, 'solutionType', allSolutionTypes);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_SOLUTION_TYPES,
        payload: { updatedSolutionTypes, selectedSolutionType, accessType, access, allAccessKey }
    });
};

export const postRoleTemplate = (basicDetails) => async (dispatch, getState) => {
    const roleTemplate = getState().roleTemplate;

    const data = {
        ...basicDetails,
        // domains: roleTemplate?.allDomains?.filter(domain => basicDetails.domains.includes(domain.name)) || null,
        domains: [],
        demos: roleTemplate.demos,
        news: roleTemplate.news,
        reports: roleTemplate.reports,
        notifications: roleTemplate.notifications,
        tips: roleTemplate.tips,
        resources: roleTemplate.resources,
        solutions: roleTemplate.solutions,
        dataCentersAccess: roleTemplate.dataCentersAccess,
        didsAccess: roleTemplate.didsAccess,
        networks: roleTemplate.networks,
        supportGuides: roleTemplate.supportGuides,
        roleTemplates: roleTemplate.roleTemplates,
        solutionTypes: roleTemplate.solutionTypes,
        demoPurposes: roleTemplate.demoPurposes,
        questions: [...(roleTemplate.questions || []), ...(roleTemplate.demoQuestions || [])],
        capabilities: roleTemplate.capabilities,
        ezDemos: roleTemplate.ezDemos
    };
    const contextPath = '/userTemplateService/roleTemplates';
    const response = await aepUser({
        method: roleTemplate.id ? 'put' : 'post',
        url: roleTemplate.id ? `${contextPath}/${roleTemplate.id}` : contextPath,
        data
    });
    const successMessage = roleTemplate.id !== undefined ? 'Role template updated successfully' : 'Role template created successfully';
    const errorMessage = roleTemplate.id !== undefined ? 'Unable to update role template' : 'Unable to create role template';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage)
        dispatch(fetchRoleTemplate(roleTemplate.id === undefined ? response.data : roleTemplate.id))
        dispatch(closeCurrentOpenTab())

    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) pushMessage(CONFIG.messageType.warning, 'Role Template already exists. Please contact administrator for more details.')
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingRoleTemplatesFlag(false))
}

export const toggleIsFetchingRoleTemplatesFlag = (flag) => {
    return { type: TOGGLE_IS_FETCHING_ROLE_TEMPLATES, payload: flag }
}

export const clearRoleTemplates = () => {
    return { type: CLEAR_ROLE_TEMPLATES }
}

export const updateRoleTemplateEnvironments = (environmentCategory, accessType, access) => {
    if (environmentCategory === null) {
        return {
            type: TOGGLE_ROLE_TEMPLATES_ENVIRONMENT_ALL,
            payload: { access, accessType }
        };
    }
    return {
        type: UPDATE_ROLE_TEMPLATE_ENVIRONMENT,
        payload: { environmentCategory, access, accessType }
    };

};

export const updateRoleTemplateSupport = (access, accessType, roleType, selectedSupport) => async (dispatch, getState) => {
    const roleTemplate = getState().roleTemplate;
    let assignedSupport = roleTemplate.supportGuides ? roleTemplate.supportGuides : [];
    const allSupportGuides = !selectedSupport && access ? await getAllSupport() : undefined;
    const updatedSupport = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedSupport, assignedSupport, 'supportGuide', allSupportGuides);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_SUPPORT,
        payload: { updatedSupport, selectedSupport, accessType, access }
    });
};

export const updateRoleTemplateCapability = (access, accessType, roleType, selectedCapability) => async (dispatch, getState) => {
    const roleTemplate = getState().roleTemplate;
    let assignedCapability = roleTemplate.capabilities ? roleTemplate.capabilities : [];
    const allCapability = !selectedCapability && access ? getState().demoCapability?.demoCapabilityData : undefined;
    const updatedCapability = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedCapability, assignedCapability, 'capability', allCapability);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_CAPABILITY,
        payload: { updatedCapability, selectedCapability, accessType, access }
    })
}

export const updateRoleTemplateEZDemos = (access, accessType, roleType, selectedEZDemo) => async (dispatch, getState) => {
    const roleTemplate = getState().roleTemplate;
    let assignedEZDemos = roleTemplate.ezDemos ? roleTemplate.ezDemos : [];
    const allEZDemos = !selectedEZDemo && access ? getState().manageEZDemos?.data : undefined;
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allEZDemosUserAccess' : 'allEZDemosAdminAccess';
    const updatedEZDemos = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedEZDemo, assignedEZDemos, 'ezDemo', allEZDemos);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_EZ_DEMOS,
        payload: { updatedEZDemos, selectedEZDemo, accessType, access, allAccessKey }
    })
}

export const updateRoleTemplateNetworks = (access, accessType, roleType, selectedNetwork) => async (dispatch, getState) => {
    const roleTemplate = getState().roleTemplate;
    let assignedNetwork = roleTemplate.networks ? roleTemplate.networks : [];
    const allNetworks = !selectedNetwork && access ? getState().networks.data : undefined;
    const updatedNetwork = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedNetwork, assignedNetwork, 'network', allNetworks);
    dispatch({
        type: UPDATE_ROLE_TEMPLATE_NETWORKS,
        payload: { updatedNetwork, selectedNetwork, accessType, access }
    });
};

export const fetchUserRoleTemplates = (currentRoleTemplateId) => async (dispatch) => {
    dispatch(toggleIsFetchingRoleTemplatesFlag(true));
    const response = await aepUser.get('/userTemplateService/roleTemplates/user');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        const userRoleTempaltes = currentRoleTemplateId && response.data
            ? response.data.filter(roleTemplate => roleTemplate.id !== currentRoleTemplateId)
            : (response.data || []);
        dispatch({
            type: FETCH_ROLE_TEMPLATES,
            payload: userRoleTempaltes
        });
    }
    dispatch(toggleIsFetchingRoleTemplatesFlag(false));
}

export const fetchAdminRoleTemplates = () => async (dispatch) => {
    dispatch(toggleIsFetchingRoleTemplatesFlag(true));
    const response = await aepUser.get('/userTemplateService/adminAccessRoleTemplates');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ROLE_TEMPLATES,
            payload: response.data || []
        })
    }
    dispatch(toggleIsFetchingRoleTemplatesFlag(false));
}

export const updateAdminRoleTemplates = (access, accessType, roleType, selectedRoleTemplate) => async (dispatch, getState) => {
    const roleTemplate = getState().roleTemplate;
    const allRoleTemplates = getState().roleTemplates.all || [];
    let assignedRoleTemplates = roleTemplate.roleTemplates || [];
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates);
    dispatch({
        type: UPDATE_ADMIN_ROLE_TEMPLATES,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access }
    });
};

export const saveRoleTemplates = (id, isClone, isGeneralReport) => {
    return {
        type: SAVE_ROLE_TEMPLATES,
        payload: { id, isClone, isGeneralReport }
    }
};

export const cancelRoleTemplates = (id, isClone, isGeneralReport) => {
    return {
        type: CANCEL_ROLE_TEMPLATES,
        payload: { id, isClone, isGeneralReport }
    }
};

export const currentRoleTemplatesFilter = (filters, pageNumber) => {
    return {
        type: LAST_ROLE_TEMPLATES_FILTER,
        payload: { filters, pageNumber }
    }
}

export const saveTipsRoleTemplates = () => {
    return {
        type: SAVE_TIPS_ROLE_TEMPLATES
    }
}

export const cancelTipsRoleTemplates = () => {
    return {
        type: CANCEL_TIPS_ROLE_TEMPLATES
    }
}

export const saveSupportRoleTemplates = () => {
    return {
        type: SAVE_SUPPORT_ROLE_TEMPLATES,
    }
}

export const cancelSupportRoleTemplates = () => {
    return {
        type: CANCEL_SUPPORT_ROLE_TEMPLATES
    }
}

export const saveSolutionRoleTemplates = () => {
    return {
        type: SAVE_SOLUTIONS_ROLE_TEMPLATES,
    }
}

export const cancelSolutionRoleTemplates = () => {
    return {
        type: CANCEL_SOLUTIONS_ROLE_TEMPLATES,
    }
}

export const saveSolutionTypeRoleTemplates = () => {
    return {
        type: SAVE_SOLUTION_TYPE_ROLE_TEMPLATES,
    }
}

export const cancelSolutionTypeRoleTemplates = () => {
    return {
        type: CANCEL_SOLUTION_TYPE_ROLE_TEMPLATES
    }
}

export const saveResourceRoleTemplates = (id, isClone) => {
    return {
        type: SAVE_RESOURCES_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const cancelResourceRoleTemplates = (id, isClone) => {
    return {
        type: CANCEL_RESOURCES_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const saveReportRoleTemplates = (id, isClone, isGeneralReport) => {
    return {
        type: SAVE_REPORTS_ROLE_TEMPLATES,
        payload: { id, isClone, isGeneralReport }
    }
}

export const cancelReportRoleTemplates = (id, isClone, isGeneralReport) => {
    return {
        type: CANCEL_REPORTS_ROLE_TEMPLATES,
        payload: { id, isClone, isGeneralReport }
    }
}

export const saveNotificationRoleTemplates = (id, isClone) => {
    return {
        type: SAVE_NOTIFICATIONS_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const cancelNotificationRoleTemplates = (id, isClone) => {
    return {
        type: CANCEL_NOTIFICATIONS_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const saveNewsRoleTemplates = (id, isClone) => {
    return {
        type: SAVE_NEWS_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const cancelNewsRoleTemplates = (id, isClone) => {
    return {
        type: CANCEL_NEWS_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const saveNetworkRoleTemplates = () => {
    return {
        type: SAVE_NETWORKS_ROLE_TEMPLATES,
    }
}

export const cancelNetworkRoleTemplates = () => {
    return {
        type: CANCEL_NETWORKS_ROLE_TEMPLATES
    }
}

export const saveDemoQuestionsRoleTemplates = (id, isClone) => {
    return {
        type: SAVE_DEMO_QUESTIONS_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const cancelDemoQuestionsRoleTemplates = (id, isClone) => {
    return {
        type: CANCEL_DEMO_QUESTIONS_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const saveFeedbackQuestionsRoleTemplates = (id, isClone) => {
    return {
        type: SAVE_FEEDBACK_QUESTIONS_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const cancelFeedbackQuestionsRoleTemplates = (id, isClone) => {
    return {
        type: CANCEL_FEEDBACK_QUESTIONS_ROLE_TEMPLATES,
        payload: { id, isClone }
    }
}

export const saveDemoPurposeRoleTemplates = () => {
    return {
        type: SAVE_DEMO_PURPOSE_ROLE_TEMPLATES,
    }
}

export const cancelDemoPurposeRoleTemplates = () => {
    return {
        type: CANCEL_DEMO_PURPOSE_ROLE_TEMPLATES
    }
}

export const saveDemoRoleTemplates = () => {
    return {
        type: SAVE_DEMO_ROLE_TEMPLATES,

    }
}

export const cancelDemoRoleTemplates = () => {
    return {
        type: CANCEL_DEMO_ROLE_TEMPLATES
    }
}

export const saveDemoCapabilityRoleTemplates = () => {
    return {
        type: SAVE_DEMO_CAPABILITY_ROLE_TEMPLATES
    }
}

export const cancelDemoCapabilityRoleTemplates = () => {
    return {
        type: CANCEL_DEMO_CAPABILITY_ROLE_TEMPLATES
    }
}

export const saveEZDemosRoleTemplates = () => {
    return {
        type: SAVE_EZ_DEMOS_ROLE_TEMPLATES
    }
}

export const cancelEZDemosRoleTemplates = () => {
    return {
        type: CANCEL_EZ_DEMOS_ROLE_TEMPLATES
    }
}

