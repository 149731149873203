import React, { Component } from 'react'
import { Form, Input, Modal } from "antd";
import FormActionButtons from "../common/FormActionButtons";
import RoleTemplatesTable from "../common/RoleTemplatesTable";
import { connect } from "react-redux"
import {
    cancelDemoCapabilityRoleTemplates,
    saveDemoCapabilityRoleTemplates,
    updateCapabilityRoleTemplate
} from "../../actions"

class CapabilityRoleTemplate extends Component {
    handleRoleTemplatesCancel = () => {
        this.props.cancelDemoCapabilityRoleTemplates();
        this.props.setShowModal(false)
    }

    handleRoleTemplatesAssign = () => {
        this.props.saveDemoCapabilityRoleTemplates();
        this.props.setShowModal(false)
    }

    render() {
        const { form, renderAssignButton, assignedRoleTemplates, showModal, allRoleTemplates, formData, allRTAdminAccess } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form.Item label="Assign Role Template">
                {getFieldDecorator("roleTemplates", {
                    initialValue:
                        assignedRoleTemplates.length !== 0
                            ? assignedRoleTemplates
                            : "None",
                })(<Input disabled addonAfter={renderAssignButton()} />)}
                {
                    <Modal
                        title="Role Template"
                        visible={showModal}
                        onCancel={this.handleRoleTemplatesCancel}
                        footer={
                            <FormActionButtons
                                okText="Assign"
                                handleCancel={this.handleRoleTemplatesCancel}
                                handleSubmit={this.handleRoleTemplatesAssign}
                            />
                        }
                    >
                        <RoleTemplatesTable
                            hideColumns={["user"]}
                            dataKey="roleTemplate"
                            data={allRoleTemplates}
                            pagination={false}
                            assignedData={formData ? formData?.roleTemplates : []}
                            allAdminAccess={allRTAdminAccess}
                            updateRoleTemplateData={this.props.updateCapabilityRoleTemplate}
                        />
                    </Modal>
                }
            </Form.Item>
        )
    }
}

export default connect(null, {
    updateCapabilityRoleTemplate,
    cancelDemoCapabilityRoleTemplates,
    saveDemoCapabilityRoleTemplates
})(CapabilityRoleTemplate);