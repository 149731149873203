import React from 'react';
import { Form, Row, Col, Input, Button, Switch, Select as AntdSelect, DatePicker } from 'antd';
import Select from "react-draggable-multi-select";
import { connect } from 'react-redux';
import CONFIG from '../../config'
import {
    saveReportRoleTemplates,
    cancelReportRoleTemplates,
    toggleIsFetchingReportForm,
    fetchReportColumns,
    toggleIsFetchingReportFlag,
    clearReportOnEntityChange
} from '../../actions';
import moment from 'moment-timezone';
import ReportRoleTemplates from './ReportRoleTemplates';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const { Option } = AntdSelect;

const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "white",
    }),
    option: (styles) => {
        return {
            ...styles,
            fontSize: "14px",
            paddingTop: "5px",
            paddingBottom: "5px",
            height: "30px",
            alignItems: "center",
            color: 'black',
            display: "flex",
        };
    },
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "#fafafa",
            height: "20px",
            alignItems: "center",
            fontSize: "16px",
            border: "1px solid #939393",
            borderRadius: "5px",
            color: 'black'
        };
    },
    multiValueRemove: (styles) => ({
        ...styles,
        ":hover": {
            color: "black",
        },
    })
};
class ReportBasicDetails extends React.Component {
    entity = ""
    state = {
        visible: false,
    };


    showModal = () => {
        this.setState({
            visible: true
        });
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                contentLoaded: true,
            });
            this.props.childUpdated(true)
        }, 3600)
    }

    handleRoleTemplatesCancel = e => {
        const { isGeneralReport, isCloneReport, params } = this.props;
        const { reportId } = params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        this.props.cancelReportRoleTemplates(id, isCloneReport, isGeneralReport);
        this.setState({
            visible: false,
        });
    };

    handleRoleTemplatesAssign = () => {
        const { isGeneralReport, isCloneReport, params } = this.props;
        const { reportId } = params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        this.props.saveReportRoleTemplates(id, isCloneReport, isGeneralReport);
        this.setState({ visible: false });
    }

    renderAssignButton = () => {
        return <Button className="primary-action-button-bordered right-border-radius" onClick={this.showModal}>Assign</Button>;
    }

    renderEntityOptions = () => {
        const currentReportData = this.getCurrentReportData();
        if (currentReportData?.entityTypes)
            return currentReportData?.entityTypes?.map((entity) => <Option key={entity.id} disabled={entity.id === 9} value={JSON.stringify(entity)} title={entity.tableName}>{entity.tableName}</Option>);
        return [];
    }

    handleEntityChange = async (value, key) => {
        const { setFieldsValue } = this.props.form;
        const { isGeneralReport, isCloneReport, params } = this.props;
        const { reportId } = params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        setFieldsValue({ columns: [], keys: [], startEndTime: undefined })
        this.props.clearReportOnEntityChange(id, isCloneReport, isGeneralReport);
        this.props.setSelectedColumns()
        this.props.handleSelectChangeForParent(value)
        await this.props.fetchReportColumns(key.key, false, id, isCloneReport, isGeneralReport)
        this.renderColumnValues();
        await this.props.toggleDisplayReportOutput(true)
        await this.props.toggleIsFetchingReportFlag(true)
    }

    renderColumnValues = () => {
        const currentReportData = this.getCurrentReportData();
        return currentReportData.reportColumns || []
    }

    getAssignedRoleTemplates = (isSuperAdminUser) => {
        const currentReportData = this.getCurrentReportData();
        const { roleTemplates } = currentReportData || [];
        let assignedRoleTemplates = [];
        if (roleTemplates) {
            roleTemplates.forEach(obj => {
                const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            })
        }
        return assignedRoleTemplates;
    }

    getCurrentReportData = (isAll = false) => {
        const { reportTabDetails, isCloneReport, isGeneralReport } = this.props;
        const { reportId } = this.props.params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        if (isAll) return reportTabDetails?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))
        return reportTabDetails?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))?.details;
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { roleTemplates, user, isGeneralReport, isCloneReport, timeZoneName, params } = this.props;
        const { reportId } = params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        const assignedRoleTemplates = this.getAssignedRoleTemplates(isSuperAdminUser);
        const { fetchFromAPI } = this.getCurrentReportData(true) || false;
        const currentReportData = this.getCurrentReportData();
        const viewColumnsJson = currentReportData?.viewColumnsJson;
        const disableColumnsToBeIncluded = currentReportData?.reportColumns?.length === 0 || isGeneralReport;
        this.tableName = getFieldValue('entity') && JSON.parse(getFieldValue('entity')).tableName;
        const showRangePicker = CONFIG.reportDatePickerEntities.includes(this.tableName);
        const rangePickerValue = this.tableName === "Daily Test Report" ? currentReportData?.filterSortObject?.filter?.createdTime ? currentReportData?.filterSortObject?.filter?.createdTime?.oprVal?.split("@") : undefined : undefined

        this.ranges = {
            'Last Year': [moment.tz(timeZoneName).startOf('year').subtract(12, 'months'), moment.tz(this.props.timeZoneName).subtract(12, 'months').endOf('year')],
            'Last 6 Months': [moment.tz(timeZoneName).startOf('month').subtract(6, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
            'Last 3 Months': [moment.tz(timeZoneName).startOf('month').subtract(3, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
            'Last Month': [moment.tz(timeZoneName).startOf('month').subtract(1, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
            'Last Week': [moment.tz(timeZoneName).startOf('week').subtract(1, 'weeks').add(1, 'days'), moment.tz(this.props.timeZoneName).subtract(1, 'weeks').endOf('week').add(1, 'days')],
            'This Year': [moment.tz(timeZoneName).startOf('year'), moment.tz(this.props.timeZoneName).endOf('year')],
            'This Month': [moment.tz(timeZoneName).startOf('month'), moment.tz(this.props.timeZoneName).endOf('month')],
            'Tomorrow': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(1, 'days')],
            'Next 7 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(7, 'days')],
            'Next 14 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(14, 'days')],
            'Next 30 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(30, 'days')],
        }

        return (
            <Form id="basicDetailsForm">
                <Row gutter={48} >
                    <Col xl={10} sm={10} xs={24}>
                        <Form.Item label="Report Name">
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'please input the name of the report'
                                    }
                                ],
                                initialValue: (isCloneReport && fetchFromAPI) ? currentReportData?.name + '_clone' : currentReportData?.name
                            })(< Input
                                disabled={isGeneralReport} />)}
                        </Form.Item>
                    </Col>
                    <Col xl={10} sm={10} xs={24}>
                        <Form.Item label="Assign Role Template">
                            {getFieldDecorator("roletemplate", {
                                initialValue: assignedRoleTemplates.length !== 0
                                    ? assignedRoleTemplates
                                    : "None"
                            })(<Input disabled addonAfter={this.renderAssignButton()} />)}
                            <ReportRoleTemplates
                                visible={this.state.visible}
                                handleCancel={this.handleRoleTemplatesCancel}
                                handleAssign={this.handleRoleTemplatesAssign}
                                assignedRoleTemplates={currentReportData?.roleTemplates}
                                allRoleTemplates={roleTemplates?.all}
                                isClone={isCloneReport}
                                isGeneralReport={isGeneralReport}
                                allRoleTemplatesAdminAccess={currentReportData?.allRTAdminAccess}
                                allRoleTemplatesUserAccess={currentReportData?.allRTUserAccess}
                                recordId={id}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={4} sm={4} xs={24}>
                        <Form.Item label="Status">
                            {getFieldDecorator("isActive", {
                                valuePropName: "checked",
                                initialValue: currentReportData?.isActive
                            })(<Switch
                                checkedChildren="Active"
                                unCheckedChildren="Inactive"
                            />)}

                        </Form.Item>
                    </Col>
                </Row >
                {
                    <Row gutter={48}>
                        <Col xl={showRangePicker ? 8 : 10} md={12} lg={12} sm={24} xs={24}>
                            <Form.Item label="Reporting Entity">
                                {
                                    getFieldDecorator('entity', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'please select an entity'
                                            }
                                        ],
                                        initialValue: JSON.stringify(currentReportData?.filterTable)
                                    })(
                                        <AntdSelect
                                            className="entitySelect"
                                            showSearch={true}
                                            onChange={this.handleEntityChange}
                                            placeholder="Select Entity"
                                            disabled={isGeneralReport}
                                            getPopupContainer={() => document.getElementById('basicDetailsForm')}
                                        >
                                            {this.renderEntityOptions()}

                                        </AntdSelect>
                                    )
                                }
                            </Form.Item>
                        </Col>

                        <Col xl={showRangePicker ? 8 : 10} md={12} lg={12} sm={24} xs={24}>
                            <Form.Item label="Columns to include in the report">
                                {getFieldDecorator("columns", {
                                    initialValue: isGeneralReport ? currentReportData?.reportColumns : viewColumnsJson
                                })
                                    (<Select
                                        styles={colourStyles}
                                        className="report-drag-select"
                                        options={currentReportData?.reportColumns}
                                        closeMenuOnSelect={false}
                                        isDisabled={disableColumnsToBeIncluded}
                                        isMulti={true}
                                        placeholder={currentReportData?.reportColumns?.length > 0 ? "All(selected by default)" : ""}
                                        getPopupContainer={() => document.getElementById('basicDetailsForm')} >
                                    </Select>)
                                }
                            </Form.Item>
                        </Col>

                        {showRangePicker && <Col xl={8} md={12} lg={12} sm={24} xs={24}>
                            <Form.Item label={this.tableName === "Daily Test Report" ? "Created Time" : "Start/End Time"}>
                                {getFieldDecorator("startEndTime", {
                                    initialValue: this.tableName === "Daily Test Report"
                                        ? !!rangePickerValue ? [moment.tz(rangePickerValue[0], this.props.timeZoneName), moment.tz(rangePickerValue[1], this.props.timeZoneName)] : undefined
                                        : currentReportData?.filterSortObject?.filter?.startTime ? [moment.tz(currentReportData?.filterSortObject?.filter?.startTime.oprVal, this.props.timeZoneName), moment.tz(currentReportData?.filterSortObject?.filter?.endTime.oprVal, this.props.timeZoneName)] : undefined,
                                    rules: [
                                        {
                                            required: true,
                                            type: 'array',
                                            whitespace: true,
                                            message: "Please select a value",
                                        },
                                    ],
                                })(<RangePicker
                                    ranges={this.ranges}
                                    allowClear={false}
                                />)
                                }
                            </Form.Item>
                        </Col>
                        }
                    </Row>
                }
                <Row className="custom-report-description" gutter={48}>
                    <Form.Item label="Report Description">
                        {getFieldDecorator("description", {
                            initialValue: currentReportData?.description
                        })
                            (<TextArea rows={5} />)
                        }
                    </Form.Item>
                </Row>
            </Form >
        );
    }
}


const mapStateToProps = ({ roleTemplates, user, currentReport }) => {
    return {
        timeZoneName: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        user: user.profile,
        reportColumns: currentReport?.reportColumns,
        entityTypes: currentReport?.entityTypes,
        reportFormFetching: currentReport.isFetchingReportForm,
        roleTemplates,
        reportTabDetails: currentReport.reportDetails,
    };
}

const ReportBasicDetailsForm = Form.create({ name: 'report_basic_details' })(ReportBasicDetails);
export default connect(mapStateToProps,
    {
        saveReportRoleTemplates,
        cancelReportRoleTemplates,
        toggleIsFetchingReportForm,
        fetchReportColumns,
        toggleIsFetchingReportFlag,
        clearReportOnEntityChange
    })(ReportBasicDetailsForm);
