import {
    FETCH_DEMO_APPROVALS,
    FETCH_DEMO_APPROVAL_REQUEST,
    TOGGLE_IS_FETCHING_APPROVAL_REQUESTS,
    CLEAR_DEMO_APPROVALS,
    UPDATE_DEMO_APPROVAL_CURRENT_PAGE,
    LAST_DEMO_APPROVAL_FILTER,
    REFRESH_DEMO_APPROVALS,
    UPDATE_DEMO_REQUEST_EMAIL_PREVIEW_EDITOR_CONTENT,
    CLEAR_DEMO_REQUEST_EMAIL_PREVIEW
} from '../actions/types';

const demosApprovalState = {
    isFetching: false,
    data: undefined,
    cuurentApprovalScheduleId: undefined,
    isEdit: false
};
export const demoApprovalsReducer = (state = demosApprovalState, action) => {

    switch (action.type) {
        case FETCH_DEMO_APPROVALS:
            return { ...state, data: action.payload?.[0].dataSet || [], count: action.payload?.[0].count, response: action.payload[1], };
        case FETCH_DEMO_APPROVAL_REQUEST:
            return { ...state, demoApprovalRequestData: action.payload || [] };
        case TOGGLE_IS_FETCHING_APPROVAL_REQUESTS:
            return { ...state, isFetching: action.payload };
        case CLEAR_DEMO_APPROVALS:
            return { ...demosApprovalState.currentFilter, ...demosApprovalState }
        case UPDATE_DEMO_APPROVAL_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentApprovalScheduleId: action.payload[2] || undefined, approvalRequestIndex: action.payload[3] || undefined };
        case LAST_DEMO_APPROVAL_FILTER:
            return { ...state, currentFilter: action.payload[0] || undefined, approvalType: action.payload[1], currentPageNumber: action.payload[2] || 1, isEdit: true };
        case REFRESH_DEMO_APPROVALS:
            return { ...state, data: [], count: undefined }
        default:
            return state;
    }
};

const demoRequestPreviewState = {
    isFetching: false,
    html: undefined,
    isContentLoaded: false
};

export const demoRequestEmailPreviewReducer = (state = demoRequestPreviewState, action) => {
    switch (action.type) {
        case UPDATE_DEMO_REQUEST_EMAIL_PREVIEW_EDITOR_CONTENT:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_DEMO_REQUEST_EMAIL_PREVIEW:
            return demoRequestPreviewState;
        default:
            return state;
    };
};