import {
    FETCH_TRENDING_DEMOS,
    REFRESH_TRENDING_DEMOS,
    TOGGLE_IS_FETCHING_TRENDING_DEMOS,
    FETCH_MOST_SCHEDULED_DEMOS,
    CLEAR_FETCH_MOST_SCHEDULED_DEMOS
} from '../actions/types';


export const trendingDemosReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_TRENDING_DEMOS:
            return { ...state, [action.dataKey]: action.payload || [], [action.otherKey]: [] };
        case REFRESH_TRENDING_DEMOS:
            return { ...state, refresh: action.payload }
        case TOGGLE_IS_FETCHING_TRENDING_DEMOS:
            return { ...state, isLoading: action.payload }
        default:
            return state;
    }
}

const mostScheduledDemosReducerState = {
    mostScheduledDemos: {}
};

export const mostScheduledDemosReducer = (state = mostScheduledDemosReducerState, action) => {
    switch (action.type) {
        case FETCH_MOST_SCHEDULED_DEMOS:
            return { ...state, mostScheduledDemos: action.payload || {} };
        case CLEAR_FETCH_MOST_SCHEDULED_DEMOS:
            return mostScheduledDemosReducerState;
        default:
            return state;
    }
}