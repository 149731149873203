import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MapComponent } from "react-map-dashboard";
import { createDataForGlobeMap } from '../../utils/reports';
import { Spin } from 'antd';
import { getCountry } from 'iso-3166-1-alpha-2';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ReportGlobe = (props) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, [props?.generalReportData, props.randomKey])

    const propsData = props?.generalReportData || [];
    const countries = useSelector(({ localUsersForm }) => localUsersForm?.countries);
    const values = props.form.getFieldsValue();
    const totalObjects = createDataForGlobeMap(propsData, props.form, countries)

    const ToolTipForGlobe = (props) => {
        return (
            <div className="custom-tooltip-1">
                {values?.type === "regions" ? <p>Region: <b>{props?.data?.region}</b></p> : <p>Country: {props?.data?.name}</p>}
                {values?.type === "regions" ? <p>Country: <b>{getCountry(props?.data?.country)}</b> </p> : null}
                {((values?.users === undefined || values?.users?.includes("avaya") || values?.users?.length === 0)) && <p>Avaya Users: <b>{props?.data?.values?.avayaUsersCount}</b></p>}
                {((values?.users === undefined || values?.users?.includes("bp") || values?.users?.length === 0)) && <p>BP Users: <b>{props?.data?.values?.bpUsersCount}</b></p>}
                {((!!values?.isTotalRequired)) && <p>Total Users: <b>{props?.data?.values?.totalCount}</b></p>}
            </div>
        );
    }

    return (
        <div style={{ borderRadius: "36%" }}>
            {
                loading ? <div style={{ width: "100%", height: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}> <Spin indicator={antIcon} spinning={loading} /> </div>
                    : <div style={{ margin: "16px", maxWidth: "90%", display: "flex", justifyContent: "center" }}>
                        <MapComponent
                            colors={totalObjects?.colors ?? []}
                            layers={totalObjects?.layers ?? []}
                            countriesData={loading ? [] : totalObjects?.data ?? []}
                            customTooltip={<ToolTipForGlobe />}
                        />
                    </div>
            }
            <br />
        </div>
    )
};

export default ReportGlobe;