import { Icon, Button } from '@avaya/neo-react'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd5';
import { Dropdown, Menu } from "antd"
import ScheduleApprovals from './ScheduleApprovals'
import DemoRequest from './DemoRequest'
import CapabilityRequests from './CapabilityRequests'
import CONFIG from '../../../config';
import ROUTE from "../../ContentRouteConfig";
import {
    fetchingDashboardAdminRequests,
    fetchPendingApprovalsForAdmins,
    fetchCompletedApprovalsForAdmins,
    changeCurrentFilterForApprovals,
    clearAdminRequestData,
    openLinkInNewTab,
    isFetchingDashboardRequestStats,
    fetchAdminDashboardRequestStats
} from '../../../actions'


export class AdminDashboardApprovals extends Component {

    state = {
        isPending: true,
    }

    async componentDidMount() {
        await this.props.fetchPendingApprovalsForAdmins();
        await this.props.fetchCompletedApprovalsForAdmins();
        if (this.props.pendingApprovals?.length === 0) {
            this.setState({
                isPending: false
            })
        }
    }

    renderRequestCards = () => {
        const { pendingApprovals, completedApprovals } = this.props;
        const data = this.state.isPending ? pendingApprovals : completedApprovals;
        return data?.map((eachRequest, index) => (
            <div key={index}>
                {this.renderCardByType(eachRequest)}
            </div>
        ))
    }

    fetchAdminApprovals = async () => {
        this.props.clearAdminRequestData();
        this.props.fetchingDashboardAdminRequests(true);
        await this.props.fetchPendingApprovalsForAdmins();
        await this.props.fetchCompletedApprovalsForAdmins();
        await this.props.isFetchingDashboardRequestStats(true);
        await this.props.fetchAdminDashboardRequestStats();
        await this.props.isFetchingDashboardRequestStats(false);
        setTimeout(() => { this.props.fetchingDashboardAdminRequests(false); }, 100);
        if (this.props.pendingApprovals?.length === 0) {
            this.setState({
                isPending: false
            })
        }
    }

    renderCardByType = (eachRequest) => {
        switch (eachRequest?.type) {
            case CONFIG.requestType.scheduleApproval:
                return <div>
                    <ScheduleApprovals
                        demoApprovalsData={eachRequest}
                        userObj={this.props.user}
                        fetchAdminApprovals={this.fetchAdminApprovals}
                        isPending={this.state.isPending}
                    />
                </div>
            case CONFIG.requestType.demoRequest:
                return <div>
                    <DemoRequest
                        demoApprovalsData={eachRequest}
                        userObj={this.props.user}
                        fetchAdminApprovals={this.fetchAdminApprovals}
                        isPending={this.state.isPending}
                    />
                </div>
            case CONFIG.requestType.capabilityRequest:
                return <div>
                    <CapabilityRequests
                        demoApprovalsData={eachRequest}
                        userObj={this.props.user}
                        fetchAdminApprovals={this.fetchAdminApprovals}
                        isPending={this.state.isPending}
                    />
                </div>;
            default:
                return null; // If the type is not one of the specified values
        }
    }

    handleRedirection = () => {
        const { currentFilter } = this.props;
        if (currentFilter === CONFIG.typesOfRequests[1]) this.props.openLinkInNewTab(currentFilter, ROUTE.demoRequests);
        else if (currentFilter === CONFIG.typesOfRequests[2]) this.props.openLinkInNewTab(currentFilter, ROUTE.capabilityRequest);
        else this.props.openLinkInNewTab("Approvals", '/schedule-approvals');
    }
    render() {
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        const { pendingApprovals, completedApprovals, isFetching } = this.props;
        const { isPending } = this.state;
        const viewText = `View ${isPending ? "Completed" : "Pending"} Requests`;
        const data = isPending ? pendingApprovals : completedApprovals;
        const showPendingRequests = pendingApprovals && pendingApprovals?.length > 0;

        const menu = (
            <Menu className='dropdown-menu-requests'>
                {
                    CONFIG.typesOfRequests?.map((item, index) => <Menu.Item className='request-dropdown-menu' onClick={() => this.props.openLinkInNewTab(index === 0 ? "Approvals" : CONFIG.typesOfRequests[index], index === 0 ? "/schedule-approvals" : index === 1 ? ROUTE.demoRequests : ROUTE.capabilityRequest)}>{item}</Menu.Item>)
                }
            </Menu>
        )

        return (
            <>
                <Spin indicator={antIcon} spinning={isFetching} >
                    <div style={{ marginBottom: "5px" }} className='left-spacing dashboard-category-heading-with-extra'>
                        <span className='dashboard-schedules-category-heading'><Icon icon="user-waiting" style={{ fontSize: "19px", paddingRight: "12px", }} />
                            {`${(isPending || !showPendingRequests) ? "Pending" : "Recently Completed"} Requests`}</span>
                        {showPendingRequests ? <span onClick={() => {
                            this.setState((prevState) => ({
                                isPending: !prevState.isPending
                            }))
                        }} className='dashboard-category-extra'>
                            {viewText}
                        </span> : null}
                    </div>
                    {(showPendingRequests && data?.length > 0) ?
                        <div className='left-spacing'>
                            {this.renderRequestCards()}
                            {!isPending && <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                                <Dropdown overlay={menu}>
                                    <Button variant="secondary" size="compact">
                                        View More Requests
                                        <Icon icon="chevron-down" size="sm" style={{ marginLeft: "5px" }} />
                                    </Button>
                                </Dropdown>
                            </div>}
                        </div>
                        :
                        <>
                            <div className='dashboard-no-schedules' style={{ height: "80px" }}>
                                <Icon icon="info" size='md' />
                                <span> No pending requests</span>
                            </div>
                            {
                                (completedApprovals && completedApprovals?.length > 0) ? <>
                                    <div style={{ marginBottom: "5px" }} className='left-spacing dashboard-schedules-category-heading'>
                                    <Icon icon="user-waiting" style={{ fontSize: "19px", paddingRight: "12px", }} />
                                        Recently Completed Requests
                                    </div>
                                    <div>
                                        {this.renderRequestCards()}
                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                                            <Dropdown overlay={menu}>
                                                <Button variant="secondary" size="compact">
                                                    View More Requests
                                                    <Icon icon="chevron-down" size="sm" style={{ marginLeft: "5px" }} />
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </>
                                    : <div className='dashboard-no-schedules' style={{ height: "80px" }}>
                                        <Icon icon="info" size='md' />
                                        <span> No completed requests</span>
                                    </div>
                            }
                        </>
                    }
                </Spin>
            </>
        )
    }
}

const mapStateToProps = ({ adminDashboard, user }) => {
    return {
        pendingApprovals: adminDashboard.pendingApprovals,
        completedApprovals: adminDashboard.completedApprovals,
        isFetching: adminDashboard.fetchingAdminRequests,
        currentFilter: adminDashboard.currentFilter,
        user: user.profile
    };
}

export default connect(
    mapStateToProps,
    {
        fetchingDashboardAdminRequests,
        fetchPendingApprovalsForAdmins,
        fetchCompletedApprovalsForAdmins,
        changeCurrentFilterForApprovals,
        clearAdminRequestData,
        openLinkInNewTab,
        isFetchingDashboardRequestStats,
        fetchAdminDashboardRequestStats
    }
)(AdminDashboardApprovals);