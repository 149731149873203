import React from 'react';
import { Modal, Form, Input, Row, Col, Spin } from 'antd';
import { connect } from 'react-redux';
import {
    toggleIsIPAddressFormVisible,
    postUpdatedIPAddress
} from '../../actions';
import FormActionButtons from '../common/FormActionButtons';

class IPAddressForm extends React.Component {
    state = { loading: false }
    handleSubmit = () => {
        const { form, id, currentPage } = this.props;
        this.setState({ loading: true });
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.props.postUpdatedIPAddress({ id, ...values }, currentPage);
            form.resetFields();
        });
        this.setState({ loading: false });
    }

    handleCancel = () => {
        const { form } = this.props;
        this.props.toggleIsIPAddressFormVisible(false);
        form.resetFields();
    }

    render() {
        const { visible, form, hostAddress, hostName, description, loginAccount, networkLoginPassword, accessType, accessLink, id, vmhost } = this.props;
        const { getFieldDecorator } = form;
        const { loading } = this.state;
        const okText = loginAccount === undefined || id === 0 ? 'Create' : 'Update';
        const isUpdateDisabled = id !== undefined && loginAccount !== null && networkLoginPassword === undefined
        return (
            <Modal
                className="long-modal"
                visible={visible}
                title="IP Address"
                onCancel={this.handleCancel}
                destroyOnClose={true}
                footer={<FormActionButtons okText={okText} handleSubmit={this.handleSubmit} handleCancel={this.handleCancel} isDisabled={isUpdateDisabled ? true : false} />}
            >
                <Spin spinning={loading} >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Host Address">
                                {getFieldDecorator('hostAddress', {
                                    initialValue: hostAddress
                                })(<Input disabled />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="VM Host">
                                {getFieldDecorator('vmhost', {
                                    initialValue: vmhost
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Host Name">
                                {getFieldDecorator('hostName', {
                                    initialValue: hostName
                                })(<Input disabled={loginAccount === undefined} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Access Type">
                                {getFieldDecorator('accessType', {
                                    initialValue: accessType
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item label="Description">
                                {getFieldDecorator('description', {
                                    initialValue: description
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Login Account">
                                {getFieldDecorator('loginAccount', {
                                    rules: [{ required: true, message: 'Please enter a login account' }],
                                    initialValue: loginAccount
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Login Password">
                                {getFieldDecorator('loginPassword', {
                                    rules: [{ required: true, message: 'Please enter a login password' }],
                                    initialValue: networkLoginPassword
                                })(<Input.Password />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item label="Access Link">
                            {getFieldDecorator('accessLink', {
                                initialValue: accessLink || undefined
                            })(<Input placeholder="http://example.com" />)}
                        </Form.Item>
                    </Row>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = ({ ipAddressForm }) => {
    return {
        ...ipAddressForm,
        networkLoginPassword: ipAddressForm.networkLoginPassword
    };
}

const IPAddressFormRef = Form.create()(IPAddressForm);

export default connect(
    mapStateToProps,
    {
        toggleIsIPAddressFormVisible,
        postUpdatedIPAddress
    }
)(IPAddressFormRef);