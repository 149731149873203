import { batch } from 'react-redux';

import { aepDemo } from "../apis";
import CONFIG from "../config"
import pushMessage from "../components/common/PushMessage"
import {
    FETCH_RESOURCES,
    FETCH_RESOURCES_RT,
    FETCH_ALL_RESOURCES,
    TOGGLE_IS_FETCHING_RESOURCES,
    TOGGLE_IS_FETCHING_RESOURCES_RT,
    CLEAR_RESOURCES,
    FETCH_RESOURCE_DETAILS,
    CLEAR_RESOURCE_FORM,
    UPDATE_RESOURCE_ROLE_TEMPLATE,
    LAST_RESOURCES_FILTER,
    ADD_RESOURCE_DEMO,
    DELETE_RESOURCE_DEMO,
    REFRESH_RESOURCES,
    TOGGLE_HAS_RESOURCES_UPDATE,
    UPDATE_RESOURCE_FORM_TAB_DATA,
    RESET_CREATE_RESOURCE,
    CLEAR_RESOURCE_DATA,
    CLEAR_RESOURCES_RT_TABLE
} from './types';
import history from '../history';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';
import { deleteTab } from './tabsLayout';

export const getAllResources = async ({ access, filterSort }) => {
    const response = await aepDemo.get("/demoSolutionService/resources", { params: { access, filterSort } });
    return response.data ? response.data.dataSet : [];
}

export const fetchResources = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, access, filterSort, getAll = false, status }) => async dispatch => {

    const contextPath = "/demoSolutionService/resources"

    const response = getAll
        ? await aepDemo.get(contextPath)
        : await aepDemo.get(contextPath, {
            params: {
                pageSize: pageSize,
                pageNumber: pageNumber,
                access,
                filterSort: {
                    ...filterSort,
                    filter: {
                        ...filterSort?.filter,
                        isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
                    }
                }
            }
        });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_RESOURCES, payload: response?.data?.dataSet || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch resources');
    dispatch(toggleIsFetchingResourcesFlag(false));
    return response.data ? response.data.dataSet : [];
};


export const fetchLiteResources = ({ access, filterSort, status }) => async dispatch => {
    const contextPath = "/demoSolutionService/v1/lite/resources"
    const response = await aepDemo.get(contextPath, {
        params: {
            access,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: filterSort?.filter?.viewDeletedItems ? undefined : status ? status === 'true' ? true : status === 'false' ? false : undefined : undefined
                }
            }
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_RESOURCES, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch resources');
    dispatch(toggleIsFetchingResourcesFlag(false));
    return response.data || [];
};

export const fetchResourcesForRT = ({ pageNumber = 1, pageSize = 5, access, filterSort, status }) => async (dispatch) => {
    const response = await aepDemo.get("/demoSolutionService/resources", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            access,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
                }
            }
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_RESOURCES_RT, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch resources');
    dispatch(toggleIsFetchingResourcesRTFlag(false));
}

export const toggleIsFetchingResourcesRTFlag = flag => {
    return {
        type: TOGGLE_IS_FETCHING_RESOURCES_RT,
        payload: flag
    }
}

export const clearRtResource = () => {
    return { type: CLEAR_RESOURCES_RT_TABLE }
}

export const fetchAllResources = () => async dispatch => {

    const contextPath = "/demoSolutionService/resources"

    const response = await aepDemo.get(contextPath)
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ALL_RESOURCES, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch resources');
};

export const fetchResourceDetails = (resourceId, isClone) => async (dispatch, getState) => {
    const response = await aepDemo(`/demoSolutionService/resources/${resourceId}`);
    let resourceDetails;
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { roleTemplates, user } = getState();
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const resource = response.data;
        resourceDetails = {
            ...response.data,
            ldapGroupAtom: resource?.ldapGroupAtom && resource?.ldapGroupAtom !== null ? JSON.stringify(resource?.ldapGroupAtom) : CONFIG.demoldapGroupNone,
            roleTemplates: isAdmin ? resource.roleTemplates.filter(resourceRoleTemplate => allRoleTemplates.some(roleTemplate => resourceRoleTemplate.roleTemplate.id === roleTemplate.id)) : resource.roleTemplates,
            assignedRoleTemplates: isAdmin ? resource.roleTemplates.filter(resourceRoleTemplate => allRoleTemplates.some(roleTemplate => resourceRoleTemplate.roleTemplate.id === roleTemplate.id)) : resource.roleTemplates,
        };

        batch(() => {
            dispatch(updateResourceFormTabData({ resourceId: resourceId, resourceDetails: resourceDetails, fetchFromAPI: true, isClone: isClone }))
            dispatch({
                type: FETCH_RESOURCE_DETAILS,
                payload: resourceDetails
            })
            dispatch(toggleIsFetchingResourcesFlag(false));
        })
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch resource");
    return resourceDetails || []
}


export const updateResourceFormTabData = ({ resourceId, resourceDetails, fetchFromAPI = false, isClone = false }) => (dispatch, getState) => {
    var formTabData = {};
    if (fetchFromAPI) {
        formTabData = {
            id: parseInt(resourceId),
            data: resourceDetails,
            originalData: resourceDetails,
            isClone
        }
    }
    else {
        const roleTemplates = getState().resourceForm.tabData.find(data => ((parseInt(data.id) === parseInt(resourceId)) && (data?.isClone === isClone)))?.data?.roleTemplates;
        const assignedRoleTemplates = getState().resourceForm.tabData.find(data => ((parseInt(data.id) === parseInt(resourceId)) && (data?.isClone === isClone)))?.data?.assignedRoleTemplates;
        let requestData = []

        if (!isClone) {
            requestData = {
                ...resourceDetails,
                id: parseInt(resourceId),
                dataCenter: resourceDetails.dataCenter !== undefined ? JSON.parse(resourceDetails?.dataCenter) : undefined,
                roleTemplates,
                assignedRoleTemplates,
                schTimeLimit: resourceDetails?.schTimeLimit ? resourceDetails?.schTimeLimit * 60 : 0,
                demoResources: resourceDetails?.demoResources
            }
        } else {
            requestData = {
                ...resourceDetails,
                id: parseInt(resourceId),
                dataCenter: resourceDetails.dataCenter !== undefined ? JSON.parse(resourceDetails?.dataCenter) : undefined,
                roleTemplates,
                assignedRoleTemplates,
                schTimeLimit: resourceDetails?.schTimeLimit ? resourceDetails?.schTimeLimit * 60 : 0,
                demoResources: resourceDetails?.demoResources
            }
        }

        formTabData = {
            id: parseInt(resourceId),
            data: requestData,
            originalData: resourceDetails?.originalData,
            isClone
        }
    }
    dispatch({
        type: UPDATE_RESOURCE_FORM_TAB_DATA,
        payload: formTabData
    })
    dispatch(toggleIsFetchingResourcesFlag(false));
}

export const deleteResourceDemo = (resourceId, demoId, isClone) => (dispatch, getState) => {
    const currentTabData = getState()?.resourceForm?.tabData.find(data => ((parseInt(data.id) === parseInt(resourceId)) && (data?.isClone === isClone)));
    const updatedDemoResources = currentTabData?.data?.demoResources?.filter(dr => (dr?.demo?.id) !== demoId);
    const updatedTabData = { ...currentTabData, data: { ...currentTabData.data, demoResources: updatedDemoResources } }
    dispatch({
        type: DELETE_RESOURCE_DEMO,
        payload: updatedTabData
    });
};

export const addResourceDemo = (demo, resourceId, isClone) => (dispatch, getState) => {
    const currentTabData = getState()?.resourceForm?.tabData.find(data => ((parseInt(data.id) === parseInt(resourceId)) && (data?.isClone === isClone)));
    const updatedDemoResources = currentTabData?.data?.demoResources?.concat({ demo: demo, ldapGroup: null });
    const updatedTabData = { ...currentTabData, data: { ...currentTabData.data, demoResources: updatedDemoResources } }
    dispatch({
        type: ADD_RESOURCE_DEMO,
        payload: updatedTabData
    });
};

export const toggleIsFetchingResourcesFlag = flag => {
    return {
        type: TOGGLE_IS_FETCHING_RESOURCES,
        payload: flag
    }
}

export const postResource = (updatedResource, resourceId, isCloneResource) => async (dispatch, getState) => {
    const roleTemplates = getState().resourceForm.tabData.find(data => ((parseInt(data.id) === parseInt(resourceId)) && (data?.isClone === isCloneResource)))?.data?.roleTemplates;
    const demoResources = getState().resourceForm.tabData.find(data => ((parseInt(data.id) === parseInt(resourceId)) && (data?.isClone === isCloneResource)))?.data?.demoResources;
    // Condition to remove IDs from the roleTemplates for clone/Create
    isCloneResource && roleTemplates.forEach(rT => delete rT.id);
    const contextPath = isCloneResource ? '/demoSolutionService/resources/clone' : '/demoSolutionService/resources'
    const isPostResource = resourceId === undefined || resourceId === "-1" || isCloneResource
    let requestData = [];
    // If Create/Edit of resource
    if (!isCloneResource) {
        requestData = {
            ...updatedResource,
            id: !isPostResource ? parseInt(resourceId) : undefined,
            name: updatedResource?.name?.trim(),
            dataCenter: JSON.parse(updatedResource.dataCenter),
            roleTemplates,
            schTimeLimit: updatedResource?.schTimeLimit ? updatedResource?.schTimeLimit * 60 : 0,
            demoResources: demoResources || [],
            ldapGroupAtom: updatedResource?.ldapGroupAtom && updatedResource?.ldapGroupAtom !== CONFIG.demoldapGroupNone ? JSON.parse(updatedResource?.ldapGroupAtom) : null
        }
    }

    // If clone of resource
    else requestData = {
        resourceList: updatedResource.keys.map(k => {
            return {
                ...updatedResource,
                id: !isPostResource ? parseInt(resourceId) : undefined,
                dataCenter: JSON.parse(updatedResource.dataCenter),
                roleTemplates,
                schTimeLimit: updatedResource?.schTimeLimit ? updatedResource?.schTimeLimit * 60 : 0,
                name: updatedResource.names[(k * 2) + 1]?.trim(),
                ldapGroupAtom: updatedResource.names[(k * 2) + 2] && updatedResource.names[(k * 2) + 2] !== CONFIG.demoldapGroupNone ? JSON.parse(updatedResource.names[(k * 2) + 2]) : undefined,
            }
        }),
        demoResourceList: demoResources
    }

    const response = await aepDemo({
        url: isPostResource ? contextPath : `${contextPath}/${parseInt(resourceId)}`,
        method: isPostResource ? 'post' : 'put',
        data: requestData,
    });
    const successMessage = !isPostResource ? "Resource updated successfully" : "Resource created successfully";
    const errorMessage = !isPostResource ? "Unable to update Resource" : "Unable to create Resource";
    if (response.status === CONFIG.HTTP_STATUS.CREATED || response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, successMessage)
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, "Resource of the same name already exists")
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingResourcesFlag(false))
    dispatch(deleteTab(resourceId !== "-1" ? isCloneResource ? CONFIG.cloneComponentRoute.resources.key + parseInt(resourceId) : CONFIG.editComponentRoute.resources.key + parseInt(resourceId) : CONFIG.createComponentRoute.resources.key, true))
    const activeKey = getState().tabsLayout.activeKey;
    history.replace(activeKey)
};

export const clearResources = () => {
    return { type: CLEAR_RESOURCES }
}

export const clearResourceData = () => {
    return { type: CLEAR_RESOURCE_DATA }
}

export const clearResourceForm = () => {
    return { type: CLEAR_RESOURCE_FORM }
}

export const updateResourceRoleTemplate = (access, accessType, roleType, selectedRoleTemplate, resourceId, isClone) => (dispatch, getState) => {
    const { resourceForm, roleTemplates } = getState();
    const allRoleTemplates = roleTemplates.all;
    const accessKey = roleType === CONFIG.roleTypes.user ? 'allRoleTemplatesUserAccess' : 'allRoleTemplatesAdminAccess';
    let roleTemplate = resourceForm.tabData.find(data => (parseInt(data.id) === parseInt(resourceId) && data.isClone === isClone))
    let assignedRoleTemplates = roleTemplate?.data?.roleTemplates
    let updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_RESOURCE_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access, accessKey, resourceId, isClone }
    });

}

export const deleteResource = async (resourceId, hardDelete = false) => {
    const response = await aepDemo.delete("/demoSolutionService/resource/" + resourceId, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Resource deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete resource");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const restoreResource = (resourceId) => async () => {
    const response = await aepDemo.put("/demoSolutionService/restore/resources/" + resourceId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Resource restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore resource");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const currentResourceFilter = (filters, status, currentPage) => {
    return {
        type: LAST_RESOURCES_FILTER,
        payload: [filters, status, currentPage]
    }
}

export const checkResourceDependencies = async (resourceId, viewDeletedItems = false) => {
    const response = await aepDemo.get(`/demoSolutionService/resources/${resourceId}/dependencies`, { params: { viewDeletedItems } })
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
    else throw Error("Unable to get resource dependency list");
};

export const refreshResources = () => {
    return {
        type: REFRESH_RESOURCES
    };
};

export const toggleHasResourcesUpdated = () => {
    return {
        type: TOGGLE_HAS_RESOURCES_UPDATE
    };
};

export const resetCreateResource = (id, isClone) => {
    return {
        type: RESET_CREATE_RESOURCE,
        payload: { id, isClone }
    }
}
