import React, { Component } from 'react'
import { connect, } from 'react-redux'
import { Spin, Divider } from 'antd'
import moment from 'moment-timezone';
import { fetchUserSupportGuides, toggleIsFetchingUserSupport, clearUserSupport, openLinkInNewTab } from "../actions";
import CONFIG from "../config"
import { LoadingOutlined } from '@ant-design/icons';
import { Icon as NeoIcon, Chip } from "@avaya/neo-react";
import { upperCaseForSupport, lowerCaseForSupport } from "../utils/strings";
import { analytics } from '../firebase';
import bgImage from "../assets/images/supportImage.jpg";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class SupportNeo extends Component {

    async componentDidMount() {
        const { guideType } = this.props.match.params;
        this.props.toggleIsFetchingUserSupport(true);
        await this.props.fetchUserSupportGuides(upperCaseForSupport(guideType));
        this.props.toggleIsFetchingUserSupport(false);
    }

    componentWillUnmount() {
        this.props.clearUserSupport();
    }

    handleLinkClick = (item) => {
        item = { ...item, fileName: item.path?.replace(CONFIG.regEx.whiteSpace, "_")?.match(CONFIG.regEx.getFileName)?.[0] }
        /* Firebase Analytics Log Event */
        const { supportGuide: {
            name: eventName,
            parameters: eventParams
        } } = CONFIG.firebaseEvents;
        let parameters = {};
        Object.keys(eventParams).forEach(paramKey => {
            parameters[paramKey] = item[eventParams[paramKey]]
        });
        analytics().logEvent(eventName, parameters);

        window.open(item.path, '_blank');
        return true;
    }

    renderOtherSupports = () => {
        return (
            <div>
                <div style={{ fontSize: "13px", color: "black" }}>For more support, please navigate through the links below</div>
                <div>
                    {
                        CONFIG.helpMenus?.subMenu?.map(item => {
                            return (
                                <div
                                    aria-label="menus"
                                    key={`${item.url}`}
                                    id={item.title}
                                    title={item.title}
                                >
                                    <span className="no-underline">
                                        <span><span className='link demo-type-details'
                                            onClick={() => { this.props.openLinkInNewTab(item.title, `${item.url}`) }}>{item.title}</span></span>
                                    </span>
                                </div >
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    renderSupportGuides = () => {

        const { guides, timeZoneName } = this.props;
        let { guideType, guideId } = this.props.match.params;
        guideType = guideId ? lowerCaseForSupport(guideType) : guideType
        const items = guideId ? guides?.filter(item => item?.id === +guideId) : guides;
        return (
            items?.map((item, index) => {
                const createdTag = item?.createdTime ? moment.tz(item?.createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : null;
                const updatedTag = item?.updatedTime ? moment.tz(item?.updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : null;
                return (
                    <div>
                        <div style={{ fontSize: "12px" }}>{guideType !== "getting-started" ? CONFIG.supportNames[guideType].substring(0, CONFIG.supportNames[guideType].length - 1) : CONFIG.supportNames[guideType]}</div>
                        <div onClick={() => this.handleLinkClick(item)} className='ez-demo-title margin-bottom-8 link'>
                            {item?.title}
                            {item?.isNew && <span className='float-right'>
                                <Chip variant="info" style={{ fontWeight: "400", fontSize: "12px", fontFamily: 'Noto Sans', marginRight: "2px" }} >New</Chip>
                            </span>}
                        </div>
                        {createdTag && <div>
                            <span className='demo-ratings'>Created: </span><span style={{ fontSize: "12px" }}>{createdTag}</span>
                        </div>}
                        {updatedTag && <div>
                            <span className='demo-ratings'>Updated: </span><span style={{ fontSize: "12px" }}>{updatedTag}</span>
                        </div>}
                        {(index !== guides?.length - 1) && <Divider className="dashboard-schedule-divider" />}
                    </div>
                )
            })
        )

    }

    render() {
        let { guideType, guideId } = this.props.match.params;
        guideType = guideId ? lowerCaseForSupport(guideType) : guideType
        const { isFetching, guides } = this.props;
        const empty = <div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Support Guides</div>
        return (
            <Spin spinning={isFetching} className="center-loading" indicator={antIcon} >
                <div style={{ margin: "5px" }}>
                    {!guideId && <div style={{ fontSize: "17px", fontWeight: "400", color: "black" }}>
                        {CONFIG.supportNames[guideType]}
                    </div>}
                    {(guides && guides?.length > 0) && !guideId && <div>
                        <img style={{ height: "auto", maxWidth: "100%" }} src={bgImage} alt="logo" />
                    </div>}
                    {
                        guides && guides?.length > 0 ? <div style={{ marginTop: "10px" }}>{this.renderSupportGuides()} <br /></div> : empty
                    }
                    {
                        guideId && this.renderOtherSupports()
                    }
                </div>
            </Spin >
        )
    }
}

const mapStateToProps = ({ userSupport, user, viewport }) => {
    return {
        isFetching: userSupport.isFetching,
        userProfile: user.profile,
        viewport: viewport,
        isLoginComplete: user.isLoginComplete,
        guides: userSupport.guides,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
    };
};

export default connect(mapStateToProps, {
    fetchUserSupportGuides, toggleIsFetchingUserSupport, clearUserSupport, openLinkInNewTab
})(SupportNeo);