import React from 'react';
import { Table, Checkbox } from 'antd';
import { connect } from 'react-redux';

import { updateRoleTemplateEnvironments } from '../../actions';

import CONFIG from '../../config';



class RoleTemplatesEnvironments extends React.Component {

    renderCheckbox = (access, accessKey, allowedAccess, isWriteDisabled) => {
        return (
            <Checkbox
                checked={access === allowedAccess}
                disabled={isWriteDisabled}
                onChange={e => this.props.updateRoleTemplateEnvironments(accessKey, allowedAccess, e.target.checked)}
            />
        );
    };


    render() {
        const { allEnvironmentsAccess } = this.props;
        this.columns = [
            {
                title: "Categories",
                dataIndex: "category",
                key: "category",
                width: '60%',
                align: "left"
            },
            {
                title: (
                    <div>
                        Read
                        <br />
                        <Checkbox
                            checked={allEnvironmentsAccess === CONFIG.roleTemplateAccess.readOnly}
                            onChange={e => this.props.updateRoleTemplateEnvironments(null, CONFIG.roleTemplateAccess.readOnly, e.target.checked)}
                        />
                    </div>
                ),
                key: "readAccess",
                align: "center",
                width: '10%',
                render: (record) =>
                    this.renderCheckbox(this.props[record.key], record.key, CONFIG.roleTemplateAccess.readOnly)
            },
            {
                title: (
                    <div>
                        Write
                        <br />
                        <Checkbox
                            checked={allEnvironmentsAccess === CONFIG.roleTemplateAccess.fullAccess}
                            onChange={e => this.props.updateRoleTemplateEnvironments(null, CONFIG.roleTemplateAccess.fullAccess, e.target.checked)}
                        />
                    </div>
                ),
                key: "writeAccess",
                align: "center",
                width: '10%',
                render: (record) =>
                    this.renderCheckbox(this.props[record.key], record.key, CONFIG.roleTemplateAccess.fullAccess, record.isWriteDisabled)
            }

        ];
        return (
            <Table
                size='middle'
                className="responsive-container top-spacing"
                bordered
                columns={this.columns}
                dataSource={CONFIG.environmentsTableData}
                rowKey={(record) => record.key}
                pagination={false}
            />
        );
    }
}
const mapStateToProps = ({ roleTemplate }) => {
    const { didsAccess, dataCentersAccess, allEnvironmentsAccess } = roleTemplate;
    return { didsAccess, dataCentersAccess, allEnvironmentsAccess };
}

export default connect(mapStateToProps, { updateRoleTemplateEnvironments })(RoleTemplatesEnvironments);