import React from 'react';
import FilterForm from './FilterForm';

const FilterPanel = ({ panelHeader, formFields, handleFilter, handleClear, handleExport, timeZone, label, hideSoftDeleteCheckbox = false, getSearchData, getSearchEmailData, hideApplyToAll = false, handleSave, disabled, viewDeletedItemsValue }) => {

    return (
        <div className="filter-panel-container">
            <FilterForm
                label={label}
                formFields={formFields}
                handleClear={handleClear}
                handleFilter={handleFilter}
                handleExport={handleExport}
                handleSave={handleSave}
                timeZone={timeZone}
                hideSoftDeleteCheckbox={hideSoftDeleteCheckbox}
                getSearchData={getSearchData}
                getSearchEmailData={getSearchEmailData}
                hideApplyToAll={hideApplyToAll}
                viewDeletedItemsValue={viewDeletedItemsValue}
            />
        </div>

    )
}

export default FilterPanel;