export const convertHtmlToNativeStyle = (htmlContent) => {


    // +---------+------------------+----------------+----------+
    // | Element |     padding      |     margin     | fontSize |
    // +---------+------------------+----------------+----------+
    // | <p>     | 1em              | -              |        - |
    // | <ul>    | paddingLeft:30px | marginTop:13px |        - |
    // | <ol>    | paddingLeft:30px | marginTop:13px |        - |
    // | <dl>    | paddingLeft:30px | marginTop:13px |        - |
    // +---------+------------------+----------------+----------+

    const container = document.createElement('div');
    container.innerHTML = htmlContent;

    const paragraphs = container.querySelectorAll('p');
    paragraphs.forEach((p) => {
        p.style.marginTop = '1em';
    });

    const lists = container.querySelectorAll('ul, ol, dl');
    lists.forEach((list) => {
        list.style.marginTop = '13px';
        list.style.marginBottom = '13px';
        list.style.paddingLeft = '30px';
    });

    return container.innerHTML;
}