import React from 'react'
import { connect } from 'react-redux';
import {
    openLinkInNewTab,
    currentDataCenterFilter
} from '../../actions';
import { Table, Button as NeoButton } from '@avaya/neo-react'
import {  Tag, Modal } from 'antd';
import ROUTE from "../../components/ContentRouteConfig";
import CONFIG from '../../config';
import moment from 'moment-timezone';

class DemoStatsViewDetailsModal extends React.Component {

    renderDetails = () =>{
        const {data, status, isDailyTesting,timeZoneName} = this.props;
        const demoStatusColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.demo?.title}</span>
                },
                Header: "Demo Title",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.description? a?.cell?.row?.original?.description : "-"}</span>
                },
                Header: "Description",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    const recordTitle = a?.cell?.row?.original?.type === "Demo DC" ? a?.cell?.row?.original?.dataCenter?.title : a?.cell?.row?.original?.resource?.name
                    return (
                        <Tag color={a?.cell?.row?.original?.type === "Demo DC" ? CONFIG.demoStatus.types[a?.cell?.row?.original?.status]?.toLowerCase() : CONFIG.demoResourceStatus.types[a?.cell?.row?.original?.status]?.toLowerCase()}>{recordTitle}</Tag>
                    )
                },
                Header: "Status",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.type || "-"}</span>
                },
                Header: "Issue Type",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.assignedUser ? a?.cell?.row?.original?.assignedUser.firstName + " " + a?.cell?.row?.original?.assignedUser.lastName : "-"}</span>
                },
                Header: "Assigned User",
                disableFilters: true,
                disableSortBy: true
            },
        ];

        const dailyTestingColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.product?.title}</span>
                },
                Header: "Demo Title",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.createdBy ? a?.cell?.row?.original?.createdBy.firstName + " " + a?.cell?.row?.original?.createdBy.lastName : "-"}</span>
                },
                Header: "Test Engineer",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.location ? a?.cell?.row?.original?.location : "-"}</span>
                },
                Header: "Location",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.createdTime ? moment.tz(a?.cell?.row?.original?.createdTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                },
                Header: "Created Time (UTC)",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    const issues = a?.cell?.row?.original?.activeIssueDescription?.split("\\") || "";
                    return (
                        <>
                            {
                                issues?.length > 0 ? issues?.map((item) => {
                                    const column = item?.substr(0, item?.indexOf("-"));
                                    const filteredColumn = item?.replace(column, "")
                                    return <div><span style={{ fontWeight: 600, fontFamily: 'Noto Sans', }}>{column}</span><span style={{ fontWeight: 400, fontFamily: 'Noto Sans', }}>{filteredColumn}</span></div>
                                })
                                    : "-"
                            }
                        </>
                    )
                },
                Header: "Issue Description",
                disableFilters: true,
                disableSortBy: true
            },
        ];
        return(
            <>
            {status !== CONFIG.dailyTestingStatus.notTested ? data && data?.length > 0 && <Table
                    columns={isDailyTesting ? status === CONFIG.dailyTestingStatus.fullyFunctional ?  dailyTestingColumns.slice(0,-1): dailyTestingColumns: demoStatusColumns}
                    data={data}
                    showRowSeparator = {true}
                    rowHeight = "compact"
                    showPagination = {false}
                /> : <div className="two-columns demo-stats-not-tested">
                <ul>
                    {data?.map((item) => {
                        return <li>{item}</li>
                    })}
                </ul>
            </div>}
            </>
        )

    }

    reDirectToManageSection = async () =>{
        const {status, isDailyTesting} = this.props;
        if(!isDailyTesting){
        const dataCenterStatus = status === "Closed" ? "closed" : "open"
        const filterSort = {filter:{
            status: status === "Open - Not Working" ? "0" : status === "Open - Partially Working" ? "1" : undefined,
            type:"Demo Resource\\Demo DC"}}
        await this.props.currentDataCenterFilter(filterSort, dataCenterStatus, 1, 12);
        this.props.openLinkInNewTab("Demo Status", ROUTE.demoStatus);
        }else{
            this.props.openLinkInNewTab("Daily Testing", ROUTE.dailyReport);
        }
    }
    
    renderFooterButtons = () =>{
        return(
            <React.Fragment>
            <NeoButton key="Confirm"
                        onClick={() => this.reDirectToManageSection()}
                        variant = "secondary"
                    >
                        View all
                    </NeoButton>
                    <NeoButton key="Close"
                    onClick={() =>  this.props.handleModalClose()}
                >
                    Close
                </NeoButton>
        </React.Fragment>
        )
    }

    render() {
        const {isModalVisible, status} = this.props;
        const modalTitle =  <span className='modal-title'>{status} Statuses Summary</span>
        return (
            <>
            <div className='dashboard-demo-stats-modal'>
            <div> <Modal
                        title={modalTitle}
                        visible={isModalVisible}
                        // style={{ width: "55%" }}
                        className="demo-stats-view-details"
                        wrapClassName = "dashboard-demo-stats-modal-wrap"
                        closeIcon={null}
                        onCancel={() => this.props.handleModalClose()}
                        bodyStyle={{ maxHeight: `${document.body.clientHeight - 190}px`, overflowY: "auto" }}
                        footer={this.renderFooterButtons()}
                    >{this.renderDetails()}</Modal></div> 
                </div>
        </>
        )
    }
}

const mapStateToProps = (user) => {
    return {
        timeZoneName: user.profile ? user.profile.timezone : undefined
    };
}

export default connect(mapStateToProps, {
    openLinkInNewTab,
    currentDataCenterFilter
})(DemoStatsViewDetailsModal);