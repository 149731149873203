import React from 'react';
import { connect } from 'react-redux';
import CONFIG from "../../config";
import moment from 'moment-timezone';
import { Divider, Radio } from 'antd';
import { Button as NeoButton, BasicModal, Checkbox, Icon } from "@avaya/neo-react"

class ScheduleDeleteConfirmation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isConfirmed: props?.isBatchDelete ? false : true,
            isDeleteForEveryone: false
        }
    }

    renderDetails = () => {
        const { timezone, isMultipleScheduleBooking, isBatchDelete, schedule, hasParticipants, isLoggedInUserCreator } = this.props;
        const { demo, startTime, endTime, scheduledResources, resource } = schedule;
        const selectedResource = (isMultipleScheduleBooking && isBatchDelete) ? scheduledResources : resource;
        const bufferStart = demo?.startTimeBuffer;
        const bufferEnd = demo?.endTimeBuffer;
        const bufferStartTime = bufferStart > 0 ? moment.tz(startTime, timezone).subtract(bufferStart, 'm') : undefined;
        const bufferEndTime = bufferEnd > 0 ? moment.tz(endTime, timezone).add(bufferEnd, 'm') : undefined;

        return (<div>
            <div className='confirmation-container'>
                <div className='confirmation-banner'>
                    <div>
                        <div className='date-banner-left'>{moment(startTime).tz(timezone).format(CONFIG.dateFormats.user)}</div>
                        <div className='day-banner-left'>Start Time: {moment(startTime).tz(timezone).format(" hh:mm A z")}</div>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4168 6.22258C19.7081 5.92809 20.1829 5.92549 20.4774 6.21678L29.6774 15.3168C29.8193 15.4572 29.8995 15.6483 29.9 15.8479C29.9005 16.0476 29.8215 16.2392 29.6803 16.3803L20.4803 25.5803C20.1874 25.8732 19.7126 25.8732 19.4197 25.5803C19.1268 25.2874 19.1268 24.8126 19.4197 24.5197L27.3393 16.6H3.75C3.33579 16.6 3 16.2642 3 15.85C3 15.4358 3.33579 15.1 3.75 15.1H27.3253L19.4226 7.28322C19.1281 6.99193 19.1255 6.51707 19.4168 6.22258Z" fill="black" />
                        </svg>
                    </div>
                    <div>
                        <div className='date-banner-left'>{moment(endTime).tz(timezone).format(CONFIG.dateFormats.user)}</div>
                        <div className='day-banner-left'>End Time: {moment(endTime).tz(timezone).format(" hh:mm A z")}</div>
                    </div>
                </div>
                <Divider style={{ paddingTop: "1px", color: '#c9c9c9', margin: "0px" }} />
                <div >
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Demo Name:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {demo.title}
                    </div>
                </div>
                <div >
                    {(bufferStart > 0 || bufferEnd > 0) && <>
                        <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>
                            Buffer Time:
                        </div>
                        <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                            {bufferStart > 0 && `Start: ${bufferStartTime?.format(CONFIG.timeFormats.default)} `} {bufferEnd > 0 && `| End: ${bufferEndTime?.format(CONFIG.timeFormats.default)}`}
                        </div>
                    </>}
                </div>
                {demo?.demoType === CONFIG.demoTypes.standardDemo && <div>
                    <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Resource:
                    </div>
                    <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                        {(isMultipleScheduleBooking && isBatchDelete && selectedResource?.length > 0) ?
                            selectedResource?.map((item, index) => { return (<span>{item?.name} {index !== selectedResource?.length - 1 && " | "}</span>) })
                            : selectedResource?.name}
                    </div>
                </div>}
            </div>
            {(isMultipleScheduleBooking && isBatchDelete) && <div className='batch-delete-confirmation-warning' style={{ marginTop: "24px" }}>
                <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex' }}>
                    <span style={{ margin: "0 16px" }}>
                        <Icon
                            icon="warning"
                            size="md"
                            style={{ color: "#AB2C2C" }}
                        />
                    </span>
                    <span>
                        Are you sure you want to delete whole series of batch created schedules?
                    </span>
                </div>
            </div>
            }
            {
                (isMultipleScheduleBooking && isBatchDelete) && <div style={{ marginTop: "24px", fontSize: '14px', fontWeight: "400", lineHeight: "20px", color: "#242424" }}>
                    <Checkbox onChange={() => this.setState(prevState => ({
                        isConfirmed: !prevState.isConfirmed
                    }))}>{<span>Yes, I confirm delete of batch created demos</span>}</Checkbox>
                </div>
            }
            {
                (hasParticipants && !isLoggedInUserCreator) && <div style={{ marginTop: "24px" }}>
                    <Radio.Group groupName="Default Radio Group" defaultValue={this.state.isDeleteForEveryone} onChange={this.onRadioChange}>
                        <Radio value={false}>Delete for me</Radio>
                        <Radio value={true}> Delete for everyone</Radio>
                    </Radio.Group>
                </div>
            }
        </div>
        );
    }

    onRadioChange = async (event) => {
        await this.setState({ isDeleteForEveryone: event.target.value })
    }

    render() {
        const modalTitle = <span className='modal-title'>{this.props.isBatchDelete ? "Delete Batch Created Schedules" : "Delete Schedule"}</span>
        return (
            <>
                <BasicModal open={this.props.isModalVisible}
                    onClose={() => this.props.handleModalClose()}
                    closeButtonLabel={"Cancel"}
                    id="schedule-delete-modal"
                    title={modalTitle}
                    className="neo-modal confirmation-modal-neo"
                    actions={[
                        <NeoButton key="Confirm"
                            disabled={!this.state.isConfirmed}
                            id = 'pop-up-confirm-delete'
                            onClick={() => this.props.handleDelete(this.state.isDeleteForEveryone)}
                        >
                            Delete
                        </NeoButton>]}>
                    {this.renderDetails()}
                </BasicModal>
            </>
        );
    }
}

const mapStateToProps = ({ scheduler, user, newSchedule }) => {
    return {
        timezone: user.profile ? user.profile.timezone : undefined,
        currentSchedule: newSchedule.currentSchedule,
        isMultipleScheduleBooking: user.profile?.isMultipleScheduleBooking || false,
    };
}

export default connect(
    mapStateToProps,
    {

    }
)(ScheduleDeleteConfirmation);