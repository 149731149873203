import { batch } from 'react-redux';
import { aepSchedule } from "../apis";
import {
    FETCH_DEMO_FEEDBACK_QUESTIONS,
    UPDATE_FEEDBACK_QUESTIONS_ROLE_TEMPLATE,
    TOGGLE_IS_FETCHING_FEEDBACK_QUESTIONS,
    CLEAR_FEEDBACK_QUESTIONS_FORM,
    FETCH_FEEDBACK_QUESTION_DETAILS,
    CLEAR_FEEDBACK_QUESTIONS,
    LAST_FEEDBACK_QUESTIONS_FILTER,
    REFRESH_FEEDBACK_QUESTIONS,
    TOGGLE_HAS_FEEDBACK_QUESTIONS_UPDATED,
    UPDATE_FEEDBACK_QUESTIONS_FORM_TAB_DATA
}
    from './types';
import { getUpdatedRoleTemplateAssociations } from "./roleTemplates";
import pushMessage from '../components/common/PushMessage';
import CONFIG from '../config';
import { closeCurrentOpenTab } from './tabsLayout';

export const fetchAllDemoFeedbackQuestions = (filterSort, isActive) => async dispatch => {
    const response = await aepSchedule.get('/scheduleMaintenanceService/questions',
        {
            params: {
                filterSort: {
                    ...filterSort,
                    filter: {
                        ...filterSort?.filter,
                        isActive,
                        questionType: "feedback"
                    }
                }
            }
        })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_DEMO_FEEDBACK_QUESTIONS,
            payload: response?.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch feedback questions');
    dispatch(toggleIsFetchingFeedbackQuestions(false));
    return response.data ? response.data.dataSet : [];
}

export const fetchfeedbackQuestionById = (questionId) => async (dispatch, getState) => {
    const response = await aepSchedule(`/scheduleMaintenanceService/questions/${questionId}`);
    let questionDetails;
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { roleTemplates, user } = getState();
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const feedbackQuestion = response.data;
        questionDetails = {
            ...response.data,
            roleTemplates: isAdmin ? feedbackQuestion.roleTemplates.filter(questionRoleTemplate => allRoleTemplates.some(roleTemplate => questionRoleTemplate.roleTemplate.id === roleTemplate.id)) : feedbackQuestion.roleTemplates,
            assignedRoleTemplates: isAdmin ? feedbackQuestion.roleTemplates.filter(questionRoleTemplate => allRoleTemplates.some(roleTemplate => questionRoleTemplate.roleTemplate.id === roleTemplate.id)) : feedbackQuestion.roleTemplates
        };
        batch(() => {
            dispatch({
                type: FETCH_FEEDBACK_QUESTION_DETAILS,
                payload: questionDetails
            })
            dispatch(updateFeedBackQuestionsFormTabData({ questionId, questionDetails, fetchFromAPI: true }));
            dispatch(toggleIsFetchingFeedbackQuestions(false));
        })
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch Feedback Question");
}

export const updateFeedBackQuestionsFormTabData = ({ questionId, questionDetails, fetchFromAPI = false }) => (dispatch, getState) => {
    var formTabData = {};
    if (fetchFromAPI) {
        formTabData = {
            id: parseInt(questionId),
            data: questionDetails,
            originalData: questionDetails
        }
    }
    else {
        const state = getState();
        const roleTemplates = state.feedbackQuestionsForm?.tabData.find(data => ((parseInt(data?.id) === parseInt(questionId))))?.data?.roleTemplates;
        const assignedRoleTemplates = state.feedbackQuestionsForm?.tabData.find(data => ((parseInt(data?.id) === parseInt(questionId))))?.data?.assignedRoleTemplates;
        let requestData = []
        formTabData = {
            id: parseInt(questionId),
            data: {
                ...requestData,
                ...questionDetails,
                roleTemplates: roleTemplates,
                assignedRoleTemplates: assignedRoleTemplates
            },
            originalData: questionDetails?.originalData
        }
    }
    dispatch({
        type: UPDATE_FEEDBACK_QUESTIONS_FORM_TAB_DATA,
        payload: formTabData
    })
    dispatch(toggleIsFetchingFeedbackQuestions(false));
}

export const postFeedbackQuestion = (updatedQuestion, questionId) => async (dispatch, getState) => {
    const roleTemplates = getState().feedbackQuestionsForm?.tabData.find(data => ((parseInt(data?.id) === parseInt(questionId))))?.data?.roleTemplates;
    const requestData = { ...updatedQuestion, id: questionId !== "-1" ? questionId : undefined, roleTemplates, questionType: "feedback" }
    const contextPath = '/scheduleMaintenanceService/questions'
    const response = await aepSchedule({
        url: questionId === undefined || questionId === "-1" ? contextPath : `${contextPath}/${questionId}`,
        method: questionId === undefined || questionId === "-1" ? 'post' : 'put',
        data: requestData,
    });
    const successMessage = questionId !== "-1" ? "Feedback Question updated successfully" : "Feedback Question created successfully";
    const errorMessage = questionId !== "-1" ? "Unable to update Feedback Question" : "Unable to create Feedback Question";
    if (response.status === CONFIG.HTTP_STATUS.CREATED || response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, successMessage)
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingFeedbackQuestions(false));
    dispatch(closeCurrentOpenTab());
};

export const deleteFeedbackQuestion = (questionId) => async (dispatch) => {
    const response = await aepSchedule.delete("/scheduleMaintenanceService/questions/" + questionId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Demo feedback question deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete Demo feedback question");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
};

export const restoreFeedbackQuestion = (questionId) => async (dispatch) => {
    const response = await aepSchedule.patch("/scheduleMaintenanceService/restore/questions/" + questionId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Demo feedback question restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore Demo feedback question");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const fetchFeedbackQuestionsForRoleTemplate = ({ pageNumber = 1, pageSize = CONFIG.shortPageSize + 1, access, filterSort }) => async (dispatch) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/questions", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            access,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    questionType: "feedback"
                }
            }
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (pageNumber !== 1 && !response.data) {
            dispatch(fetchFeedbackQuestionsForRoleTemplate({ pageNumber: pageNumber - 1 }));
            return;
        }
        else dispatch({ type: FETCH_DEMO_FEEDBACK_QUESTIONS, payload: response.data?.dataSet || [] });
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch demo feedback questions');
    }
    dispatch(toggleIsFetchingFeedbackQuestions(false));
    return response.data || []
}

export const getAllFeedbackQuestions = async (access) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/questions", { params: { access, filterSort: { "filter": { "questionType": "feedback" } } } });
    return response.data ? response.data.dataSet : [];
}

export const toggleIsFetchingFeedbackQuestions = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_FEEDBACK_QUESTIONS,
        payload: flag
    };
};

export const clearFeedbackQuestions = () => {
    return {
        type: CLEAR_FEEDBACK_QUESTIONS
    }
}

export const clearFeedbackQuestionsForm = (id) => {
    return {
        type: CLEAR_FEEDBACK_QUESTIONS_FORM,
        payload: { id }
    }
}

export const updateFeedbackQuestionsRoleTemplate = (access, accessType, roleType, selectedRoleTemplate, questionId) => async (dispatch, getState) => {
    const { feedbackQuestionsForm, roleTemplates } = getState();
    const allRoleTemplates = roleTemplates.all;
    const accessKey = roleType === CONFIG.roleTypes.user ? "allRoleTemplatesUserAccess" : "allRoleTemplatesAdminAccess";
    let feedBackQuestionInfo = feedbackQuestionsForm?.tabData.find(data => parseInt(data?.id) === parseInt(questionId))
    let assignedRoleTemplates = feedBackQuestionInfo?.data?.roleTemplates;
    let updatedRoleTemplates = getUpdatedRoleTemplateAssociations(
        access,
        roleType,
        accessType,
        selectedRoleTemplate,
        assignedRoleTemplates,
        "roleTemplate",
        allRoleTemplates,
        false
    );
    dispatch(
        {
            type: UPDATE_FEEDBACK_QUESTIONS_ROLE_TEMPLATE,
            payload: {
                updatedRoleTemplates,
                selectedRoleTemplate,
                accessType,
                access,
                accessKey,
                questionId
            },
        });
};

export const currentFeedbackQuestionsFilter = (filterSort, active, pageNumber) => {
    return {
        type: LAST_FEEDBACK_QUESTIONS_FILTER,
        payload: [filterSort, active, pageNumber]
    }
}

export const refreshFeedbackQuestions = () => {
    return {
        type: REFRESH_FEEDBACK_QUESTIONS
    };
};

export const toggleHasFeedbackQuestionsUpdated = () => {
    return {
        type: TOGGLE_HAS_FEEDBACK_QUESTIONS_UPDATED
    };
};