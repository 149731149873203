import React from 'react'
import { Table } from 'antd';
import NoData from '../common/NoData';



const NeoTable = ({
    columns,
    dataSource,
    size,
    onChange,
    cn,
    scroll,
    loading,
    rowKey,
    noDataText
}) => {
    return (<Table
        className={`antd-to-neo-table ${cn}`}
        columns={columns}
        dataSource={dataSource || []}
        size={size}
        pagination={false}
        onChange={onChange}
        locale={{ emptyText: <NoData size="sm" text={noDataText} /> }}
        scroll={scroll}
        loading={loading}
        rowKey={rowKey}
    />)
}

export default NeoTable