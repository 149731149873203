import {
    FETCH_SFDC_STATS_DATA,
    CLEAR_SFDC_STATS_DATA,
    LAST_SFDC_STATS_FILTER,
} from '../actions/types';

const sfdcStatsState = {
    data: [],
};
export const sfdcStatsReducer = (state = sfdcStatsState, action) => {
    switch (action.type) {
        case FETCH_SFDC_STATS_DATA:
            return { ...state, data: action.payload || [], isEdit: false, isRedirection: false };
        case CLEAR_SFDC_STATS_DATA:
            return sfdcStatsState;
        case LAST_SFDC_STATS_FILTER:
            return { ...state, filters: action.payload?.[0], status: action.payload?.[1], currentPage: action.payload?.[2], isEdit: true, isRedirection: action.payload?.[3], record: action.payload?.[4] }
        default:
            return state;
    }
};