import { batch } from 'react-redux';

import { aepSchedule, aepLDAP, aepDemo } from '../apis';
import {
    FETCH_DEMO_SCHEDULES,
    CLEAR_DEMO_SCHEDULES_TABLE,
    TOGGLE_IS_FETCHING_DEMO_SCHEDULES,
    TOGGLE_IS_FETCHING_SCHEDULE,
    CLEAR_DEMO_SCHEDULES,
    FETCH_DEMO_SCHEDULE_COMMENTS,
    FETCH_TEAR_DOWN_SCHEDULES,
    UPDATE_MANAGE_SCHEDULES_CURRENT_PAGE,
    FETCH_ALL_USERS_REVIEWS,
    FETCH_USER_REVIEWS,
    TOGGLE_IS_FETCHING_DEMO_REVIEWS,
    FETCH_SCHEDULE_FEEDBACK,
    TOGGLE_IS_FETCHING_SCHEDULE_FEEDBACK,
    FETCH_DEMO_RATING_SPLIT_UP,
    FETCH_SCHEDULE_FEEDBACK_ASSIGNED_QUESTIONS,
    CLEAR_SCHEDULES_RESPONSE,
    LAST_SCHEDULE_FILTER,
    FETCH_MANAGE_SCHEDULE_DETAILS,
    UPDATE_SCHEDULED_DEMO_CURRENT_PAGE,
    CLEAR_MANAGE_DEMO_SCHEDULES,

    CLEAR_AUTO_BUILDS_SCHEDULES,
    UPDATE_AUTOBUILDS_SCHEDULES_CURRENT_PAGE,
    FETCH_AUTO_BUILDS_SCHEDULES,
    FETCH_AUTO_DEMO_SCHEDULE_COMMENTS,
    LAST_AUTO_BUILDS_FILTER,

    FETCH_MANUAL_DEMO_SCHEDULE_COMMENTS,
    FETCH_MANUAL_BUILDS_SCHEDULES,
    UPDATE_MANUALBUILDS_SCHEDULES_CURRENT_PAGE,
    CLEAR_MANUAL_BUILDS_SCHEDULES,
    LAST_MANUAL_BUILDS_FILTER
} from './types';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { closeCurrentOpenTab } from './tabsLayout';


export const getAllDemoSchedules = async (filterSort, active) => {
    const response = await aepSchedule.get('/scheduleMaintenanceService/schedules', { params: { filterSort, active } });
    return response.data?.dataSet || []
}

export const fetchDemoSchedules = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, active, viewType }) => async dispatch => {
    const params = viewType === CONFIG.viewType.CALENDAR ? {
        filterSort,
        active
    } : {
        pageNumber,
        pageSize,
        filterSort,
        active
    };
    const response = await aepSchedule.get('/scheduleMaintenanceService/schedules', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: FETCH_DEMO_SCHEDULES,
                payload: [response.data, response.status] || []
            });
        })
    else {
        dispatch({
            type: CLEAR_SCHEDULES_RESPONSE,
            payload: response.status
        });
        pushMessage(CONFIG.messageType.error, 'Unable to fetch demo schedules');
    }
    dispatch(toggleIsFetchingDemoSchedules(false));
};

export const fetchLiteDemoSchedules = ({ filterSort, active, pageNumber, pageSize, viewType }) => async dispatch => {
    const params = {
        filterSort: {
            ...filterSort,
            filter: {
                ...filterSort?.filter,
                isActive: active === "true" ? true : undefined
            }
        },
        pageNumber: (active === "true" || viewType === CONFIG.viewType.CALENDAR) ? undefined : pageNumber,
        pageSize: (active === "true" || viewType === CONFIG.viewType.CALENDAR) ? undefined : pageSize,
    };
    const response = await aepSchedule.get('/scheduleMaintenanceService/v1/lite/schedules', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: FETCH_DEMO_SCHEDULES,
                payload: response.data || []
            });
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch demo schedules');

    dispatch(toggleIsFetchingDemoSchedules(false));
}

export const fetchScheduleById = async (scheduleId) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/schedules/" + scheduleId);
    // This API will return 200 if the logged in user is either creator or adminCreator or one of the participant.
    let userHasAccessToDemo;
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const demoIdsResponse = await aepDemo.get(`/demoSolutionService/access/demos?demoIds=${response?.data?.demo?.id}`)
        userHasAccessToDemo = demoIdsResponse.data?.includes(response?.data?.demo?.id) ? true : false;
    }
    return { ...response?.data, userHasAccessToDemo };
};


export const fetchScheduleDetailsById = (scheduleId, userSide) => async (dispatch, getState) => {
    const context = userSide ? `/scheduleMaintenanceService/schedules/${scheduleId}` : `/scheduleMaintenanceService/manage/schedules/${scheduleId}`
    const response = await aepSchedule.get(context);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const scheduleDetails = response.data;
        batch(() => {
            dispatch({
                type: FETCH_MANAGE_SCHEDULE_DETAILS,
                payload: scheduleDetails
            });
            dispatch(toggleIsFetchingDemoSchedules(false));
        });
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to fetch Schedule Details");
    }
    return response.status === CONFIG.HTTP_STATUS.OK ? response.data : []
};

export const getUserScheduleById = async (scheduleId, manageSide) => {
    const context = !manageSide ? `/scheduleMaintenanceService/schedules/${scheduleId}` : `/scheduleMaintenanceService/manage/schedules/${scheduleId}`
    const response = await aepSchedule.get(context);
    return response.data || []
}

export const getSchedulesByBatch = async (scheduleId, batchNumber) => {
    const context = `/scheduleMaintenanceService/v1/batch/schedule/${scheduleId}/batchNumber/${batchNumber}`
    const response = await aepSchedule.get(context);
    return response.data || []
}

export const getUserScheduleByIdLite = async (scheduleId, manageSide) => {
    const context = !manageSide ? `/scheduleMaintenanceService/v1/lite/schedules/${scheduleId}` : `/scheduleMaintenanceService/manage/schedules/${scheduleId}`
    const response = await aepSchedule.get(context);
    return response.data || []
}

export const fetchTearDownSchedules = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, buildStatus, isManual }) => async dispatch => {
    const params = {
        pageNumber,
        pageSize,
        filterSort,
        buildStatus,
        isManual
    };
    const response = await aepSchedule.get('/scheduleMaintenanceService/builds', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: FETCH_TEAR_DOWN_SCHEDULES,
                payload: response.data || []
            });
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch demo builds');
    dispatch(toggleIsFetchingDemoSchedules(false));
};

export const fetchBuildsSchedules = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, buildStatus, isManual }) => async dispatch => {
    const params = {
        pageNumber,
        pageSize,
        filterSort,
        buildStatus,
        isManual
    };
    const response = await aepSchedule.get('/scheduleMaintenanceService/builds', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: isManual ? FETCH_MANUAL_BUILDS_SCHEDULES : FETCH_AUTO_BUILDS_SCHEDULES,
                payload: { data: response.data ? response?.data?.dataSet ? response?.data?.dataSet : [] : [] } || []
            });
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch demo builds');
    dispatch(toggleIsFetchingDemoSchedules(false));
};

export const fetchBuildsSchedulesLite = ({ filterSort, buildStatus, isManual }) => async dispatch => {
    const params = {
        filterSort,
        buildStatus,
        isManual
    };
    const response = await aepSchedule.get('/scheduleMaintenanceService/v1/lite/builds', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: isManual ? FETCH_MANUAL_BUILDS_SCHEDULES : FETCH_AUTO_BUILDS_SCHEDULES,
                payload: { data: response.data } || []
            });
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch demo builds');
    dispatch(toggleIsFetchingDemoSchedules(false));
};

export const getBuildsById = (buildId) => async () => {
    const response = await aepSchedule.get(`/scheduleMaintenanceService/v1/builds/${buildId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        return response?.data || []
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch demo build details');
}

// -- Action intended for Export functionality --
export const getAllBuilds = async ({ filterSort, buildStatus, isManual }) => {
    const response = await aepSchedule.get('/scheduleMaintenanceService/builds', {
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter
                }
            },
            buildStatus,
            isManual
        }
    });
    return response.data ? response.data.dataSet : [];
}

export const updateManualDemoBuild = (data, isEmailFollowed) => async (dispatch) => {
    const contextPath = '/scheduleMaintenanceService/build'
    const response = await aepSchedule({
        method: 'put',
        url: `${contextPath}/${data.id}`,
        data: data
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        !isEmailFollowed && pushMessage(CONFIG.messageType.success, "Build updated successfully")
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, "Build Id already exists")
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update build');
    if (response) {
        if (response.status === CONFIG.HTTP_STATUS.OK) {
            return true;
        }
        else return false;
    }
    else return false;
}

export const toggleIsFetchingDemoSchedules = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DEMO_SCHEDULES,
        payload: flag
    };
};

export const toggleIsFetchingSchedule = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_SCHEDULE,
        payload: flag
    };
};

export const clearDemoSchedules = () => {
    return { type: CLEAR_DEMO_SCHEDULES };
}

export const clearDemoSchedulesTable = () => {
    return { type: CLEAR_DEMO_SCHEDULES_TABLE };
}

export const clearAutoBuildsSchedules = () => {
    return { type: CLEAR_AUTO_BUILDS_SCHEDULES };
}

export const clearManualBuildsSchedules = () => {
    return { type: CLEAR_MANUAL_BUILDS_SCHEDULES };
}

export const clearManageDemoSchedules = () => {
    return { type: CLEAR_MANAGE_DEMO_SCHEDULES };
}

export const fetchDemoScheduleComments = (scheduleId) => async dispatch => {
    const response = await aepSchedule.get(`/scheduleMaintenanceService/schedules/${scheduleId}/comments`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: FETCH_DEMO_SCHEDULE_COMMENTS,
                payload: response.data || []
            });
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch comments');
}

export const fetchScheduleComments = (scheduleId, isManual) => async dispatch => {
    const response = await aepSchedule.get(`/scheduleMaintenanceService/schedules/${scheduleId}/comments`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: isManual ? FETCH_MANUAL_DEMO_SCHEDULE_COMMENTS : FETCH_AUTO_DEMO_SCHEDULE_COMMENTS,
                payload: response.data || []
            });
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch comments');
}

export const addScheduleComment = (comment, scheduleId) => async dispatch => {
    const response = await aepSchedule.post(`/scheduleMaintenanceService/schedules/${scheduleId}/comment`, { comment: comment });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        dispatch(fetchDemoScheduleComments(scheduleId))
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to add comment');

}

export const updateScheduleComment = (comment, scheduleId, commentId) => async dispatch => {
    const response = await aepSchedule.put(`/scheduleMaintenanceService/schedule/comment/${commentId}`, { comment: comment });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Comment updated successfully")
        dispatch(fetchDemoScheduleComments(scheduleId))
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update comment');
}

export const deleteScheduleComment = (commentId, scheduleId) => async dispatch => {
    const response = await aepSchedule.delete(`/scheduleMaintenanceService/schedule/comment/${commentId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Comment deleted successfully")
        dispatch(fetchDemoScheduleComments(scheduleId))
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to delete comment');
}

export const updateManageSchedulesCurrentPage = (currentPageNumber, pageSize, demoId, demoIndex) => {
    return {
        type: UPDATE_MANAGE_SCHEDULES_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, demoId, demoIndex]
    }
};

export const updateBuildsCurrentPage = (currentPageNumber, pageSize, demoId, demoIndex, isManual) => {
    return {
        type: isManual ? UPDATE_MANUALBUILDS_SCHEDULES_CURRENT_PAGE : UPDATE_AUTOBUILDS_SCHEDULES_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, demoId, demoIndex]
    }
};

export const updateScheduleEndDate = (endDate, scheduleId) => async dispatch => {
    const requestData = { endTime: endDate };
    const response = await aepSchedule.patch("/scheduleMaintenanceService/scheduleExtension/schedules/" + scheduleId, requestData);
    if (response.status === CONFIG.HTTP_STATUS.OK) pushMessage(CONFIG.messageType.success, "End Date/Time updated successfully")
    else pushMessage(CONFIG.messageType.error, "Unable to update end date/time");
}

export const tearDownSchedule = (scheduleId) => async dispatch => {
    const response = await aepLDAP.post(`/ldapService/schedule/tearDown/${scheduleId}`)
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Demo build torn down successfully")
        return true
    }
    else if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.unauthorized)
        return false;
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to tear down demo build')
    return false
}

export const reinitiateDemoBuild = (scheduleId) => async dispatch => {
    const response = await aepLDAP.post(`ldapService/reInitiate/buildDemo?scheduleId=${scheduleId}`)
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Demo build re-initiated successfully")
        return true
    }
    else if (response.status === CONFIG.HTTP_STATUS.UNAUTHORIZED) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.unauthorized)
        return false;
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to re-initiate demo build')
    return false
}

export const closeDemoSchedule = (scheduleId) => async dispatch => {
    const response = await aepLDAP.post(`/ldapService/schedule/tearDown/${scheduleId}?isClose=true`)
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Demo build closed successfully")
        return true
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to close demo build')
    return false
}


// ======================================Ratings and Reviews Action=======================================

/* CRUD Operations on Feedback
    1. POST Feedback
    2. PUT Feedback
    3. DELETE Feedback
    4. GET Feedback(by ScheduleID) */

export const postScheduleFeedback = (feedbackValues, scheduleId, isEdit) => async dispatch => {
    let values = { ...feedbackValues }
    const overallRating = feedbackValues.overallRating
    const comment = feedbackValues.comment
    delete values['overallRating']
    delete values['comment']

    let questionsValue = []
    for (const [key, value] of Object.entries(values)) {
        questionsValue.push({ question: { id: key }, rating: value })
    }

    const feedback = {
        scheduleComments: [{ comment: comment, rating: overallRating }],
        questionRatings: questionsValue
    }
    const contextPath = `/scheduleMaintenanceService/schedules/${scheduleId}/feedback`
    const successMessage = isEdit ? "updated" : "submitted"
    const failureMessage = isEdit ? "update" : "submit"
    const response = await aepSchedule({
        method: isEdit ? 'put' : 'post',
        url: contextPath,
        data: feedback
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, `Demo feedback ${successMessage} successfully`)
        dispatch(closeCurrentOpenTab())
    }
    else pushMessage(CONFIG.messageType.error, `Failed to ${failureMessage} feedback`);
}

export const postAdminReply = ({ reply, scheduleId, isEdit, demoId, commenterId }) => async (dispatch) => {
    const feedback = {
        comment: reply
    }
    const contextPath = `/scheduleMaintenanceService/schedules/${scheduleId}/feedbackReply/${commenterId}`
    const successMessage = isEdit ? "updated" : "submitted"
    const failureMessage = isEdit ? "update" : "submit"
    const response = await aepSchedule({
        method: isEdit ? 'put' : 'post',
        url: contextPath,
        data: feedback
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        dispatch(toggleIsFetchingDemoReviews(true));
        pushMessage(CONFIG.messageType.success, `Reply ${successMessage} successfully`)
        batch(() => {
            dispatch(getUserReviews(demoId))
            dispatch(getDemoRatingSplitUp(demoId))
        })
    }
    else pushMessage(CONFIG.messageType.error, `Failed to ${failureMessage} reply`);
}

/* 
User can delete his own review and Ratings
Super Admin can delete any user's review and Ratings 
*/
export const deleteUserScheduleFeedback = (scheduleId, demoId, isSuperAdminReply, commenterId, replyForId) => async (dispatch) => {
    const contextApi = isSuperAdminReply ? `/scheduleMaintenanceService/schedules/${scheduleId}/feedbackReply/${replyForId}/replyFor/${commenterId}` : `/scheduleMaintenanceService/schedules/${scheduleId}/feedback/${commenterId}`;
    const response = await aepSchedule.delete(contextApi);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch(toggleIsFetchingDemoReviews(true));
        pushMessage(CONFIG.messageType.success, "Review deleted successfully")
        batch(() => {
            dispatch(getUserReviews(demoId))
            dispatch(getDemoRatingSplitUp(demoId))
        })
    }
    else pushMessage(CONFIG.messageType.error, "Unable to delete Review");
}


export const getScheduleFeedbackByScheduleId = (scheduleId) => async (dispatch) => {
    const response = await aepSchedule.get(`/scheduleMaintenanceService/schedules/${scheduleId}/feedback`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: FETCH_SCHEDULE_FEEDBACK,
                payload: response.data || []
            });
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch schedule feedback');
    dispatch(isFetchingScheduleFeedback(false))
}

export const getScheduleFeedbackAssignedQuestions = () => async (dispatch) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/user/questions", { params: { filterSort: { "filter": { "questionType": "feedback" } } } });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: FETCH_SCHEDULE_FEEDBACK_ASSIGNED_QUESTIONS,
                payload: response.data || []
            });
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch schedule feedback');
}

export const getDemoRatingSplitUp = (demoId) => async (dispatch) => {
    const response = await aepSchedule.get(`/scheduleMaintenanceService/demo/${demoId}/ratingSplit`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: FETCH_DEMO_RATING_SPLIT_UP,
                payload: response.data || []
            });
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Demo Rating Details');
    dispatch(toggleIsFetchingDemoReviews(false))
}

export const getAllUserReviews = ({ pageNumber = 1, pageSize = CONFIG.pageSize, demoId, isManageSection }) => async (dispatch) => {
    const params = {
        isManageSection: isManageSection,
        pageNumber,
        pageSize,
    };
    const response = await aepSchedule.get(`/scheduleMaintenanceService/${demoId}/feedbacks`, { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)

        batch(() => {
            dispatch({
                type: FETCH_ALL_USERS_REVIEWS,
                payload: response.data || []
            });
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch demo reviews');
}

export const getUserReviews = (demoId) => async (dispatch) => {
    const response = await aepSchedule.get(`/scheduleMaintenanceService/${demoId}/myFeedbacks`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)

        batch(() => {
            dispatch({
                type: FETCH_USER_REVIEWS,
                payload: response.data?.dataSet || []
            })
        })
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch my demo reviews');
    dispatch(toggleIsFetchingDemoReviews(false));
}

export const toggleIsFetchingDemoReviews = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DEMO_REVIEWS,
        payload: flag
    };
};

export const isFetchingScheduleFeedback = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_SCHEDULE_FEEDBACK,
        payload: flag
    };
};

export const currentScheduleFilter = (filters, status, pageNumber) => {
    return {
        type: LAST_SCHEDULE_FILTER,
        payload: [filters, status, pageNumber]
    }
}

export const currentBuildsFilter = (filters, status, isManual) => {
    return {
        type: isManual ? LAST_MANUAL_BUILDS_FILTER : LAST_AUTO_BUILDS_FILTER,
        payload: [filters, status]
    }
}

export const updateScheduleDemoCurrentPage = (currentPageNumber, pageSize, scheduledId, scheduleIndex) => {
    return {
        type: UPDATE_SCHEDULED_DEMO_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, scheduledId, scheduleIndex]
    }
};

export const checkIfBuidExists = (buildId) => async () => {
    const response = await aepSchedule.get(`/scheduleMaintenanceService/v1/unique/builds/${buildId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) return true;
    else return false
}