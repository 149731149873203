import {
    FETCH_DYNAMIC_EMAIL_TEMP_VERS,
    CLEAR_DYNAMIC_EMAIL_TEMP_VERS,
    TOGGLE_IS_FETCHING_EMAIL_TEMPLATE_VERSIONS,
    CLEAR_DYNAMIC_EMAIL_TEMPLATE,
    CLEAR_DYNAMIC_EMAIL_PREVIEW,
    UPDATE_EMAIL_PREVIEW_EDITOR_CONTENT,
    TOGGLE_IS_PREVIEW_SUCCESSFUL,
    UPDATE_EDITOR_CONTENT_DYNAMIC_FLOW,
    TOGGLE_CK_EDITOR_CONTENT_CHANGED_FLAG,
    FETCH_DYNAMIC_EMAIL_TEMPLATE
} from '../actions/types';

const dynamicEmailState = {
    versionsData: [],
}

export const dynamicEmailReducer = (state = dynamicEmailState, action) => {
    switch (action.type) {
        case FETCH_DYNAMIC_EMAIL_TEMP_VERS:
            return { ...state, versionsData: action.payload || action.payload };
        case CLEAR_DYNAMIC_EMAIL_TEMP_VERS:
            return dynamicEmailState;
        default:
            return state;
    };
}



const dynamicEmailTemplateState = {
    isFetching: false,
    html: undefined,
    isContentLoaded: false,
    hasCkEditorContentChanged: false
};

export const dynamicEmailTemplateReducer = (state = dynamicEmailTemplateState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_EMAIL_TEMPLATE_VERSIONS:
            return { ...state, isFetching: action.payload };
        case FETCH_DYNAMIC_EMAIL_TEMPLATE:
            return { ...state, emailTemplate: action.payload };
        case UPDATE_EDITOR_CONTENT_DYNAMIC_FLOW:
            return { ...state, isContentLoaded: true, html: action.payload };
        case TOGGLE_CK_EDITOR_CONTENT_CHANGED_FLAG:
            return { ...state, hasCkEditorContentChanged: action.payload };
        case CLEAR_DYNAMIC_EMAIL_TEMPLATE:
            return dynamicEmailTemplateState;
        default:
            return state;
    };
};


const dynamicEmailPreviewState = {
    isFetching: false,
    isPreviewSuccess: false,
    html: undefined,
    isContentLoaded: false
};

export const dynamicEmailPreviewReducer = (state = dynamicEmailPreviewState, action) => {
    switch (action.type) {
        case UPDATE_EMAIL_PREVIEW_EDITOR_CONTENT:
            return { ...state, isContentLoaded: true, html: action.payload };
        case CLEAR_DYNAMIC_EMAIL_PREVIEW:
            return dynamicEmailPreviewState;
        case TOGGLE_IS_PREVIEW_SUCCESSFUL:
            return { ...state, isPreviewSuccess: action.payload };
        default:
            return state;
    };
};