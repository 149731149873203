import { batch } from "react-redux";
import { aepDemo, aepReport, aepSchedule } from "../apis";
import CONFIG from "../config";
import pushMessage from "../components/common/PushMessage";

import {
  ADMIN_DASHBOARD_TODAYS_DEMO_TEST,
  ADMIN_DASHBOARD_UPCOMING_MAINTENANCE,
  ADMIN_DASHBOARD_PENDING_APPROVALS,
  ADMIN_DASHBOARD_COMPLETED_APPROVALS,
  TOGGLE_IS_FETCHING_ADMIN_REQUESTS,
  CLEAR_ADMIN_DASHBOARD,
  CLEAR_APPROVALS_DATA,
  ADMIN_DASHBOARD_REQUEST_STATS,
  CURRENT_APPROVALS_FILTER,
  FETCHING_REQUEST_STATS,
  ADMIN_DASHBOARD_DEMO_STATUS,
  TOGGLE_IS_FETCHING_ADMIN_DEMO_STATUS,
  ADMIN_DASHBOARD_SFDC_STATUS,
  TOGGLE_IS_FETCHING_SFDC_STATUS,
} from "./types";

export const fetchAdminDashboardDailyTestingReport = () => async (dispatch) => {
  const response = await aepReport.get(
    "/reportSearchService/adminDashboard/dailyTestingReport"
  );
  if (
    response.status === CONFIG.HTTP_STATUS.OK ||
    response.status === CONFIG.HTTP_STATUS.NO_CONTENT
  ) {
    batch(() => {
      dispatch({
        type: ADMIN_DASHBOARD_TODAYS_DEMO_TEST,
        payload: response.data || [],
      });
    });
  } else
    pushMessage(
      CONFIG.messageType.error,
      `Unable to fetch today's demo test results.`
    );
};

export const fetchUpcomingMaintenanceForAdmins = () => async (dispatch) => {
  const response = await aepSchedule.get(
    "/scheduleMaintenanceService/adminDashboard/upcomingMaintenance"
  );
  if (
    response.status === CONFIG.HTTP_STATUS.OK ||
    response.status === CONFIG.HTTP_STATUS.NO_CONTENT
  ) {
    batch(() => {
      dispatch({
        type: ADMIN_DASHBOARD_UPCOMING_MAINTENANCE,
        payload: response.data || [],
      });
    });
  } else
    pushMessage(
      CONFIG.messageType.error,
      `Unable to fetch upcoming maintenances.`
    );
};

export const fetchPendingApprovalsForAdmins = () => async (dispatch) => {
  const response = await aepDemo.get(
    "/demoSolutionService/adminDashboard/requests",
    {
      params: {
        filterSort: {
          filter: { type: "All" },
        },
      },
    }
  );
  if (
    response.status === CONFIG.HTTP_STATUS.OK ||
    response.status === CONFIG.HTTP_STATUS.NO_CONTENT
  ) {
    batch(() => {
      dispatch({
        type: ADMIN_DASHBOARD_PENDING_APPROVALS,
        payload: response.data || [],
      });
    });
  } else
    pushMessage(CONFIG.messageType.error, `Unable to fetch pending approvals`);
};

export const changeCurrentFilterForApprovals = (type) => {
  return {
    type: CURRENT_APPROVALS_FILTER,
    payload: type,
  };
};

export const clearAdminRequestData = () => {
  return {
    type: CLEAR_APPROVALS_DATA,
  };
};

export const fetchCompletedApprovalsForAdmins = () => async (dispatch) => {
  const response = await aepDemo.get(
    "demoSolutionService/adminDashboard/completedRequests",
    {
      params: {
        filterSort: {
          filter: { type: "All" },
        },
      },
    }
  );
  if (
    response.status === CONFIG.HTTP_STATUS.OK ||
    response.status === CONFIG.HTTP_STATUS.NO_CONTENT
  ) {
    batch(() => {
      dispatch({
        type: ADMIN_DASHBOARD_COMPLETED_APPROVALS,
        payload: response.data || [],
      });
    });
  } else
    pushMessage(CONFIG.messageType.error, `Unable to fetch completed requests`);
};

export const fetchingDashboardAdminRequests = (flag) => {
  return {
    type: TOGGLE_IS_FETCHING_ADMIN_REQUESTS,
    payload: flag,
  };
};

export const clearAdminDashboard = () => {
  return {
    type: CLEAR_ADMIN_DASHBOARD,
  };
};

export const fetchAdminDashboardRequestStats = () => async (dispatch) => {
  const response = await aepDemo.get(
    "/demoSolutionService/adminDashboard/requestStatistics"
  );
  if (
    response.status === CONFIG.HTTP_STATUS.OK ||
    response.status === CONFIG.HTTP_STATUS.NO_CONTENT
  ) {
    batch(() => {
      dispatch({
        type: ADMIN_DASHBOARD_REQUEST_STATS,
        payload: response.data || [],
      });
    });
  } else
    pushMessage(
      CONFIG.messageType.error,
      `Unable to fetch pending request statistic.`
    );
};

export const isFetchingDashboardRequestStats = (flag) => {
  return {
    type: FETCHING_REQUEST_STATS,
    payload: flag,
  };
};

export const toggleIsFetchingAdminDemoStatus = (flag) => {
  return {
    type: TOGGLE_IS_FETCHING_ADMIN_DEMO_STATUS,
    payload: flag,
  };
};

export const fetchAdminDemoStatus = () => async (dispatch) => {
  const response = await aepDemo.get(
    "/demoSolutionService/v1/adminDashboard/statusMgmt"
  );
  if (
    response.status === CONFIG.HTTP_STATUS.OK ||
    response.status === CONFIG.HTTP_STATUS.NO_CONTENT
  ) {
    batch(() => {
      dispatch({
        type: ADMIN_DASHBOARD_DEMO_STATUS,
        payload: response.data || {},
      });
    });
  } else pushMessage(CONFIG.messageType.error, `Unable to fetch demo status`);
};

export const fetchAdminSFDCData = () => async (dispatch) => {
  const response = await aepSchedule.get(
    "/scheduleMaintenanceService/v1/adminDashboard/opportunityStatistics"
  );
  if (
    response.status === CONFIG.HTTP_STATUS.OK ||
    response.status === CONFIG.HTTP_STATUS.NO_CONTENT
  ) {
    dispatch({
      type: ADMIN_DASHBOARD_SFDC_STATUS,
      payload: response.data || {},
    });
  } else
    pushMessage(
      CONFIG.messageType.error,
      `Unable to fetch opportunity statistics status`
    );
};

export const toggleIsFetchingSFDCStatus = (flag) => {
  return {
    type: TOGGLE_IS_FETCHING_SFDC_STATUS,
    payload: flag,
  };
};

export const resendNotificationToAccountOwner = async (accountOwnerId) => {
  const response = await aepSchedule.post(
    `/scheduleMaintenanceService/v1/sfdcOpportunity/resendEmail/${accountOwnerId}`
  );
  if (response.status === CONFIG.HTTP_STATUS.OK)
    pushMessage(CONFIG.messageType.success, "Email sent successfully.");
  else pushMessage(CONFIG.messageType.error, "Failed to send email.");
};
