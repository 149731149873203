import {
    UPDATE_DATA_CENTER_STATUS_EDITING_KEY,
    TOGGLE_IS_FETCHING_DATA_CENTER_STATUS,
    FETCH_ALL_DATA_CENTER_STATUS,
    TOGGLE_IS_FETCHING_USER_DATA_CENTER_STATUS,
    CLEAR_DEMO_DC_RESPONSE,
    CLEAR_DEMO_DC_STATUS,
    FETCH_USER_DATA_CENTER_STATUS,
    LAST_DEMO_STATUS_FILTER,
    CLEAR_DEMO_DC_STATUS_TABLE_DATA
} from "../actions/types";
const dataCenterStatusState = {
    isFetching: false,
    currentPage: 1,
    editingKey: "",
    isFetchingUser: false,
    demoDataCenterStatus: [],
    count: 0
};
export const dataCenterStatusReducer = (state = dataCenterStatusState, action) => {
    switch (action.type) {
        case FETCH_ALL_DATA_CENTER_STATUS:
            return {
                ...state,
                demoDataCenterStatus: action?.payload || [],
                isEdit: false,
                count: action.payload?.length || 0
            };
        case UPDATE_DATA_CENTER_STATUS_EDITING_KEY:
            return { ...state, editingKey: action.payload };
        case TOGGLE_IS_FETCHING_DATA_CENTER_STATUS:
            return { ...state, isFetching: action.payload };
        case TOGGLE_IS_FETCHING_USER_DATA_CENTER_STATUS:
            return { ...state, isFetchingUser: action.payload };
        case CLEAR_DEMO_DC_RESPONSE:
            return { ...state, response: action.payload };
        case FETCH_USER_DATA_CENTER_STATUS:
            return { ...state, users: action.payload.dataSet || [] }
        case CLEAR_DEMO_DC_STATUS:
            return dataCenterStatusState;
        case CLEAR_DEMO_DC_STATUS_TABLE_DATA:
            return { ...state, demoDataCenterStatus: [], count: 0 };
        case LAST_DEMO_STATUS_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true, currentPage: action.payload[2], pageSize: action.payload[3] }
        default:
            return state;
    }
}