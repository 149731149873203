import React from 'react';
import { connect, batch } from 'react-redux';
import ScheduleMaintenanceTable from './ScheduleMaintenanceTable';
import { toggleBackButton, selectTitle } from '../../actions'

class ScheduleMaintenance extends React.Component {

    componentDidMount() {
        document.getElementById('tab-pane').scrollIntoView()
        batch(() => {
            // this.props.toggleBackButton(false);
            this.props.selectTitle('Schedule Maintenance');
        });
    };

    render() {
        return (
            <React.Fragment>
                <ScheduleMaintenanceTable />
            </React.Fragment>
        );
    }
}

export default connect(
    null,
    { toggleBackButton, selectTitle }
)(ScheduleMaintenance);