import React from 'react';
import { Select, Form, Tooltip, Icon } from 'antd';
import { connect } from 'react-redux';

import { fetchDataCenters } from '../../actions';

const { Option } = Select;

class DataCenters extends React.Component {

    renderDataCenterOptions = () => {
        const { dataCenters } = this.props;
        return dataCenters
            ? dataCenters.map((dataCenter, index) => {
                return <Option key={index} label={dataCenter.title} title={dataCenter.title} value={JSON.stringify({ id: dataCenter.id, title: dataCenter.title, active: dataCenter.active })}>
                    <div>
                        {dataCenter.title}
                        {
                            <span className="float-right">
                                <Tooltip title={dataCenter.active ? "Active" : "Inactive"} key={dataCenter.id}>
                                    {
                                        dataCenter.active
                                            ? <Icon type="check" className="small-text success-text" />
                                            : <Icon type="warning" className="small-text warning-text" />
                                    }
                                </Tooltip>
                            </span>
                        }
                    </div>
                </Option>;
            })
            : [];
    }

    componentDidMount() {
        this.props.fetchDataCenters();
    }

    render() {
        const { form, handleChange, dataCenter, fromDids } = this.props;
        const dataCenterSelect = <Select className="active-inactive-select" id='data-center-select' showSearch={true} placeholder="Select a data center" onChange={handleChange}>{this.renderDataCenterOptions()}</Select>;
        return form
            ? (
                <Form.Item label="Data Center">
                    {form.getFieldDecorator('dataCenter', {
                        rules: [{ required: fromDids ? false : true, message: 'Please select a data center' }],
                        initialValue: dataCenter ? JSON.stringify({ id: dataCenter.id, title: dataCenter.title, active: dataCenter.active }) : undefined
                    })(dataCenterSelect)}
                </Form.Item>
            )
            : dataCenterSelect
    }
}

const mapStateToProps = ({ dataCenters }) => {
    return {
        dataCenters: dataCenters.data
    };
}

export default connect(
    mapStateToProps,
    { fetchDataCenters }
)(DataCenters);