import React from 'react';
import { connect, batch } from 'react-redux';
import { Row, Spin, BackTop } from 'antd';
import { Icon as NeoIcon } from "@avaya/neo-react";
import {
    fetchSolutions,
    selectTitle,
    toggleBackButton,
    updateSelectedDemoSolution,
    fetchSolutionDemos,
    fetchSolutionDemo,
    clearDemoScheduler,
    clearSolutionDemos,
    fetchDataCenters,
    toggleIsFetchingSolutions,
    demoRequestDelete,
    getUpdatedIdString
} from '../../actions';
import DemoCard from './DemoCard';
import CONFIG from '../../config';
import scrollIntoView from 'scroll-into-view';
import { Select } from "antd5"
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

class DemoSolutions extends React.Component {

    constructor(props) {
        super(props);
        this.solutionType = undefined;
        this.searchString = undefined;
        this.pageValue = undefined;
        this.state = {
            isRefresh: false,
        }
    }

    renderSolutionsOptions = () => {
        if (this.props.solutions) {
            return this.props.solutions.map((solution, index) => {
                return solution.demos && solution.demos.length !== 0
                    ? <Option key={index} value={JSON.stringify(solution)}>{solution.title}</Option>
                    : undefined
            });
        }
        return [];
    };

    componentWillUnmount() {
        batch(() => {
            this.props.clearSolutionDemos();
            this.props.clearDemoScheduler();
        })
    }

    componentDidUpdate(prevProps) {
        const { searchString, demoId, activeTab } = this.props.match.params;
        const { universalSearchString, pageValue } = this.props.location;
        const { isRequestDeleted, dataString, isFetching, demos } = this.props;
        if (searchString !== this.searchString) {
            this.props.clearSolutionDemos();
            this.props.toggleIsFetchingSolutions(true);
            this.props.fetchSolutionDemos({ searchString });
            this.searchString = searchString;
        }

        if (prevProps.match.params.demoId !== demoId || prevProps.location.universalSearchString !== universalSearchString || prevProps.match.params.activeTab !== activeTab) {
            batch(() => {
                this.props.clearSolutionDemos();
                this.props.toggleIsFetchingSolutions(true);
                this.props.fetchSolutionDemo(demoId, universalSearchString)
            })
        }

        if (isRequestDeleted) {
            this.props.selectTitle(`Solutions`);
            this.props.toggleIsFetchingSolutions(true);
            if (!isNaN(pageValue)) this.props.fetchSolutions(pageValue, true);
            this.props.demoRequestDelete(false);
        }

        if (dataString !== undefined && dataString !== null && !isFetching && demos?.length > 0 && this.state.isRefresh) {
            scrollIntoView((document.getElementById(dataString)), {
                align: { top: 0.3, left: 0 },
                behavior: "smooth",
                time: 1500,
            },
                () => {
                    this.props.getUpdatedIdString(undefined);
                    this.setState({ isRefresh: false })
                }
            )
        }

    }

    componentDidMount() {
        // const { previousPath } = this.props.location;
        const { demoId, solutionId, solutiontypeId, solutionType } = this.props.match.params;
        const { dataString } = this.props;
        const { universalSearchString } = this.props.location;
        this.pageValue = solutionId;
        const fromDashboard = demoId !== undefined;
        const solutionTypeId = solutiontypeId ? parseInt(solutiontypeId) : parseInt(this.pageValue)
        this.solutionType = solutionType;
        batch(async () => {
            this.props.selectTitle("Solutions");
            this.props.toggleBackButton(false, window.location.pathname);
            if (fromDashboard || solutiontypeId) {
                this.props.toggleIsFetchingSolutions(true);
                demoId ? await this.props.fetchSolutionDemo(demoId, universalSearchString) : await this.props.fetchSolutionDemos({ solutionId, solutionTypeId });
            }
            else {
                this.props.toggleBackButton(false);
                this.props.toggleIsFetchingSolutions(true);
                if (!isNaN(solutionTypeId)) await this.props.fetchSolutions(solutionTypeId, true);
            }
            if (dataString !== undefined && dataString !== null) this.setState({ isRefresh: true });
            this.props.fetchDataCenters();
        });
    }

    handleSelectChange = (value) => {
        const solution = JSON.parse(value);
        const solutionTypeId = parseInt(this.pageValue);
        // this.refs.demoSearch.input.state.value = '';
        this.props.clearSolutionDemos();
        this.props.toggleIsFetchingSolutions(true);
        this.props.updateSelectedDemoSolution(solution, solutionTypeId);
    }

    handleSearch = (searchString) => {
        const { selectedSolution } = this.props
        const solutionTypeId = parseInt(this.pageValue);
        const solutionId = selectedSolution === CONFIG.ALL ? selectedSolution : selectedSolution.id;
        if (selectedSolution || selectedSolution === CONFIG.ALL) {
            this.props.clearSolutionDemos();
            this.props.fetchSolutionDemos({ solutionId, solutionTypeId, searchString })
        }
    }

    handleClear = (e) => {
        const { selectedSolution } = this.props
        const solutionTypeId = parseInt(this.pageValue);
        const solutionId = selectedSolution === CONFIG.ALL ? selectedSolution : selectedSolution.id;
        if (e.target.value === "") this.props.fetchSolutionDemos({ solutionId, solutionTypeId });
    }

    renderDemosList = () => {
        const { pageValue, title, pathname, universalSearchString } = this.props.location;
        return (<DemoCard
            pathValues={{ pathname: pathname, pageValue: pageValue, title: title }}
            user={this.props.user}
            searchString={universalSearchString || this.props.searchString}
            selectedSolution={this.props.selectedSolution}
            ratingRedirectionParams={this.props.location.state}
            params={this.props.match.params}
            propsDemos={this.props.demos}
        />)
    }

    render() {
        const { demos, isFetching } = this.props;
        const empty = <div className='dashboard-no-schedules'><NeoIcon icon="info" size='md' />No Demos</div>;
        return (
            <React.Fragment>
                <div>
                    <BackTop visibilityHeight="200" />
                    <Spin indicator={antIcon} spinning={isFetching}>
                        {demos ? (demos.length === 0 ? empty : this.renderDemosList()) :
                            <Row className="center-align vertical-spacing"></Row>}</Spin>

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ solutions, demoSolutions, user, tabsLayout }) => {
    return {
        solutions: solutions.data,
        selectedSolution: demoSolutions.selectedSolution,
        demos: demoSolutions.demos,
        user: user.profile,
        searchString: demoSolutions.searchString,
        isFetching: solutions.isFetching,
        isRequestDeleted: demoSolutions.isRequestDeleted,
        menu: user.menu,
        panes: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        dataString: demoSolutions.dataString
    };
};

export default connect(
    mapStateToProps,
    {
        fetchSolutions,
        selectTitle,
        toggleBackButton,
        updateSelectedDemoSolution,
        fetchSolutionDemos,
        clearDemoScheduler,
        clearSolutionDemos,
        fetchSolutionDemo,
        fetchDataCenters,
        toggleIsFetchingSolutions,
        demoRequestDelete,
        getUpdatedIdString
    }
)(DemoSolutions);