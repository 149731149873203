import { batch } from 'react-redux';
import moment from 'moment-timezone';

import {
    UPDATE_NOTIFICATION_DETAILS,
    UPDATE_NOTIFICATION_DESCRIPTION,
    CLEAR_NOTIFICATION_DETAILS,
    CLEAR_NOTIFICATION_DESCRIPTION,
    FETCH_NOTIFICATIONS,
    TOGGLE_IS_FETCHING_NOTIFICATIONS,
    UPDATE_PUSH_NOTIFICATION,
    UPDATE_NOTIFICATION_ROLE_TEMPLATE,
    CLEAR_NOTIFICATIONS,
    UPDATE_NOTIFICATIONS_CURRENT_PAGE,
    FETCH_USER_DASHBOARD_NOTIFICATIONS,
    FETCH_ACTIVE_NOTIFICATIONS,
    TOGGLE_IS_NOTIFICATIONS_REORDERED,
    REORDER_NOTIFICATIONS,
    FETCH_INACTIVE_NOTIFICATIONS,
    FETCH_REAL_TIME_PUSH_NOTIFICATION,
    SAVE_REAL_TIME_PUSH_NOTIFICATION,
    TOGGLE_IS_FETCHING_BELL_NOTIFICATIONS,
    FETCH_USER_BELL_NOTIFICATIONS,
    UPDATE_USER_NOTIFICATION_COUNT,
    CLEAR_USER_BELL_NOTIFICATIONS,
    LAST_NOTIFICATION_FILTER,
    CLEAR_NOTIFICATIONS_RESPONSE,
    REFRESH_NOTIFICATIONS,
    TOGGLE_HAS_NOTIFICATIONS_UPDATED,
    UPDATE_NOTIFICATION_TAB_DETAILS,
    CLEAR_NOTIFICATION_CONTENT,
    CLEAR_NOTIFICATION_DATA,
    CLEAR_USER_DASHBOARD_NOTIFICATIONS,
    CLOSE_NOTIFICATION_BANNER,
    FETCH_DRAFT_NOTIFICATIONS
} from './types';
import pushMessage from '../components/common/PushMessage';
import CONFIG from '../config';
import { aepNewsNotificationSupport, aepEmail } from '../apis';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';
import { closeCurrentOpenTab } from './tabsLayout';

export const getAllNotifications = async (access) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/notifications/", { params: { access } });
    return response.data ? response.data.dataSet : [];
}

export const getNotification = async (notificationId) => {
    const response = await aepNewsNotificationSupport.get(`/newsNotificationSupportService/notifications/${notificationId}`);
    return response.data || [];
}

export const fetchUserNotificationAccess = async (notificationId) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/access/notification/" + notificationId);
    return response.data
}

export const fetchNotification = (notificationId, isCloneNotification) => async (dispatch, getState) => {
    const response = await aepNewsNotificationSupport.get(`/newsNotificationSupportService/notifications/${notificationId}`);

    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const { user, roleTemplates } = getState();
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const timeZoneName = user.profile.timezone;
        const notification = response.data;
        const { id, title, validityStartTime, validityEndTime, description, eapOffline, hasDraft, isDraftMode, draftNotification, parentNotification, createdBy } = response.data;
        const notificationDetails = {
            id,
            title,
            eapOffline,
            validityStartTime: !isCloneNotification ? validityStartTime ? moment.tz(validityStartTime, timeZoneName) : undefined : undefined,
            validityEndTime: !isCloneNotification ? validityEndTime ? moment.tz(validityEndTime, timeZoneName) : undefined : undefined,
            roleTemplates: isAdmin ? response.data.roleTemplates.filter(notificationRoleTemplate => allRoleTemplates.some(roleTemplate => notificationRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates,
            assignedRoleTemplates: isAdmin ? response.data.roleTemplates.filter(notificationRoleTemplate => allRoleTemplates.some(roleTemplate => notificationRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates,
            bodyHtml: description,
            hasDraft,
            isDraftMode,
            draftNotification,
            parentNotification,
            createdBy
        };
        batch(() => {
            dispatch({
                type: UPDATE_NOTIFICATION_DETAILS,
                payload: notificationDetails,
                notification
            });
            dispatch(updateNotificationTabDetails({ notificationId, notificationDetails, fetchFromAPI: true, isClone: isCloneNotification }))
            dispatch(toggleIsFetchingNotificationsFlag(false));
        });
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to fetch notifcation");
    }
    return response.data || []
};

//For Draft Mode
export const fetchDraftNotifications = (filterSort) => async (dispatch) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/notifications/draft", {
        params: {
            filterSort
        }
    })
    dispatch(toggleIsFetchingNotificationsFlag(false));
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_DRAFT_NOTIFICATIONS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch draft Notifications');
}

export const updateNotificationTabDetails = ({ notificationId, notificationDetails, fetchFromAPI = false, isClone = false }) => async (dispatch, getState) => {
    var formTabData = {};
    const { user } = getState();
    const timeZoneName = user.profile.timezone;
    if (fetchFromAPI) {
        formTabData = {
            id: parseInt(notificationId),
            details: notificationDetails,
            originalDetails: notificationDetails,
            bodyHtml: notificationDetails.bodyHtml,
            isContentLoaded: true,
            isClone: isClone,
            fetchFromAPI
        }
    }
    else {
        // formTabData = notificationDetails
        let requestData = []
        const state = getState();
        const roleTemplates = state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId)) && (data?.isClone === isClone)))?.details?.roleTemplates;
        const assignedRoleTemplates = state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId)) && (data?.isClone === isClone)))?.details?.assignedRoleTemplates;
        const originalDetails = state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId)) && (data?.isClone === isClone)))?.originalDetails;
        requestData = {
            ...notificationDetails,
            id: notificationId,
            title: notificationDetails?.title,
            eapOffline: notificationDetails.eapOffline,
            validityStartTime: !isClone ? notificationDetails?.publishTime ? moment.tz(notificationDetails?.publishTime, timeZoneName) : undefined : undefined,
            validityEndTime: !isClone ? notificationDetails?.expiryTime ? moment.tz(notificationDetails?.expiryTime, timeZoneName) : undefined : undefined,
            roleTemplates: isClone ? roleTemplates?.map(item => { return { ...item, id: undefined } }) : roleTemplates,
            assignedRoleTemplates: isClone ? assignedRoleTemplates?.map(item => { return { ...item, id: undefined } }) : assignedRoleTemplates,
            isOverride: notificationDetails?.isOverride,
            notifyUsers: notificationDetails?.notifyUsers,
            isAllDay: notificationDetails?.isAllDay,
            hasDraft: originalDetails?.hasDraft,
            isDraftMode: originalDetails?.isDraftMode,
            parentNotification: originalDetails?.parentNotification,
            draftNotification: originalDetails?.draftNotification,
            createdBy: originalDetails?.createdBy,
        }

        formTabData = {
            id: parseInt(notificationId),
            details: requestData,
            originalDetails: notificationDetails?.originalDetails,
            bodyHtml: notificationDetails.bodyHtml,
            isContentLoaded: true,
            isClone: isClone,
            fetchFromAPI
        }
    }

    dispatch({
        type: UPDATE_NOTIFICATION_TAB_DETAILS,
        payload: formTabData
    })
    dispatch(toggleIsFetchingNotificationsFlag(false));
}

export const resetNotificationForm = (id, isClone) => {
    return {
        type: CLEAR_NOTIFICATION_CONTENT,
        payload: { id, isClone }
    }
}

export const clearNotificationForm = () => dispatch => {
    batch(() => {
        dispatch({
            type: CLEAR_NOTIFICATION_DETAILS
        });
        dispatch({
            type: CLEAR_NOTIFICATION_DESCRIPTION
        });
    });
}

export const updateNotificationDescription = (editorType, notificationId, editorContent, isClone) => {
    return {
        type: UPDATE_NOTIFICATION_DESCRIPTION,
        payload: {
            id: notificationId,
            editorContent,
            isClone,
            isContentLoaded: true
        }
    };
};

const checkIfNotificationHasChanged = (notification, oldNotification) => {
    let hasNotificationChanged = false;
    let hasOnlyRoleTemplatesChanged = false;

    hasNotificationChanged = notification?.title?.trim() !== oldNotification?.title?.trim()
        || moment(notification?.validityStartTime).toISOString() !== moment(oldNotification?.validityStartTime).toISOString()
        || moment(notification?.validityEndTime).toISOString() !== moment(oldNotification?.validityEndTime).toISOString()
        || notification?.description?.trim() !== oldNotification?.bodyHtml?.trim()
        || notification?.eapOffline !== oldNotification?.eapOffline
        || notification?.notifyUsers === true

    if (hasNotificationChanged) return { hasNotificationChanged };

    // Role Templates
    let newRTs = notification.roleTemplates.filter(rT => (rT.adminAccess !== 0 || rT.userAccess !== 0));
    hasNotificationChanged = JSON.stringify(newRTs) !== JSON.stringify(oldNotification.roleTemplates);
    if (hasNotificationChanged) {
        hasOnlyRoleTemplatesChanged = true;
        return { hasNotificationChanged, hasOnlyRoleTemplatesChanged }
    }
    return { hasNotificationChanged };
}


export const postNotification = (notification, notificationId, isCloneNotification, isDraftMode) => async (dispatch, getState) => {
    const state = getState();
    const timeZone = getState().user.profile.timezone;
    const currentNotification = state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId)) && (data?.isClone === isCloneNotification)))?.details;
    const description = state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId)) && (data?.isClone === isCloneNotification)))?.bodyHtml;
    const roleTemplates = state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId)) && (data?.isClone === isCloneNotification)))?.details?.roleTemplates;
    if (isCloneNotification || isDraftMode) {
        roleTemplates.forEach(rT => delete rT.id);
    }

    const requestData = {
        id: isDraftMode ? (currentNotification?.parentNotification !== null ? currentNotification?.parentNotification?.id : parseInt(notificationId)) : isCloneNotification ? null : notificationId !== "-1" ? parseInt(notificationId) : undefined,
        validityStartTime: notification.publishTime.tz(timeZone, true).toISOString(),
        validityEndTime: notification.expiryTime.tz(timeZone, true).toISOString(),
        title: notification.title,
        roleTemplates,
        description,
        notifyUsers: notification.notifyUsers,
        isOverride: notification.isOverride,
        eapOffline: notification.eapOffline,
        isDraftMode: false,
        draftNotification: (isDraftMode && currentNotification?.parentNotification !== null) ? { id: parseInt(notificationId), createdBy: currentNotification?.createdBy || null } : null
    };
    const { hasOnlyRoleTemplatesChanged } = notificationId ? checkIfNotificationHasChanged(requestData, state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId)) && (data?.isClone === isCloneNotification)))?.originalDetails) : true;
    const contextPath = "/newsNotificationSupportService/notifications"
    const response = await aepNewsNotificationSupport({
        method: notificationId === undefined || notificationId === "-1" || isCloneNotification ? 'post' : 'put',
        url: notificationId === undefined || notificationId === "-1" || isCloneNotification ? contextPath : `${contextPath}/${parseInt((isDraftMode && currentNotification?.parentNotification) ? currentNotification.parentNotification?.id : notificationId)}`,
        data: requestData,
        params: { onlyRoleTemplateUpdate: hasOnlyRoleTemplatesChanged }
    });

    const successMessage = (isCloneNotification || notificationId === "-1") ? "Notification created successfully" : "Notification updated successfully";
    const errorMessage = (isCloneNotification || notificationId === "-1") ? "Unable to create notification" : "Unable to update notification";
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage);
        dispatch(closeCurrentOpenTab());
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingNotificationsFlag(false));

}

export const postDraftNotification = ({ notificationId, notification, isCloneNotification, isDraftEdit, parentNotificationObj }) => async (dispatch, getState) => {
    const state = getState();
    const isDraftCreatedOutOfParent = (isDraftEdit === false && parentNotificationObj !== null)
    const timeZone = getState().user.profile.timezone;
    const description = state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId))))?.bodyHtml;
    const roleTemplates = state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId))))?.details?.roleTemplates;
    const requestData = {
        id: (isDraftCreatedOutOfParent || notificationId === undefined || notificationId === "-1") ? undefined : parseInt(notificationId),
        validityStartTime: notification.publishTime ? notification.publishTime.tz(timeZone, true).toISOString() : null,
        validityEndTime: notification.expiryTime ? notification.expiryTime.tz(timeZone, true).toISOString() : null,
        title: notification.title ? notification.title : null,
        roleTemplates: isDraftCreatedOutOfParent ? roleTemplates?.map(item => { return { ...item, id: undefined } }) : roleTemplates,
        isDraftMode: true,
        description,
        notifyUsers: notification.notifyUsers ? notification.notifyUsers : null,
        isOverride: notification.isOverride ? notification.isOverride : null,
        eapOffline: notification.eapOffline ? notification.eapOffline : null,
        parentNotification: isCloneNotification ? null : parentNotificationObj
    };
    const { hasNotificationChanged, hasOnlyRoleTemplatesChanged } = notificationId ? checkIfNotificationHasChanged(requestData, state.notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId))))?.originalDetails) : true;
    if (hasNotificationChanged || !notificationId) {
        const contextPath = "/newsNotificationSupportService/notification/draft"
        const response = await aepNewsNotificationSupport({
            method: (isDraftCreatedOutOfParent || notificationId === undefined || notificationId === "-1") ? 'post' : 'put',
            url: (isDraftCreatedOutOfParent || notificationId === undefined || notificationId === "-1") ? contextPath : `${contextPath}/${notificationId}`,
            data: requestData,
            params: { onlyRoleTemplateUpdate: hasOnlyRoleTemplatesChanged }
        });

        if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
            pushMessage(CONFIG.messageType.success, "Notification is saved as Draft")
            dispatch(closeCurrentOpenTab())
        }
        else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
            pushMessage(CONFIG.messageType.warning, "Draft of the same name already exists")
        }
        else {
            pushMessage(CONFIG.messageType.error, "Unable to update Notification Draft");
        }
        dispatch(toggleIsFetchingNotificationsFlag(false))
    }
}

export const fetchNotifications = ({ pageNumber = 1, pageSize = CONFIG.pageSize, access, fetchInactiveNotifications = false, filterSort }) => async (dispatch) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/notifications/", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            access,
            filterSort,
            expired: CONFIG.expired[fetchInactiveNotifications]
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (pageNumber !== 1 && !response.data) {
            dispatch(fetchNotifications({ pageNumber: pageNumber - 1, fetchInactiveNotifications }));
            return;
        }
        else dispatch({ type: fetchInactiveNotifications ? FETCH_INACTIVE_NOTIFICATIONS : FETCH_NOTIFICATIONS, payload: [response.data, response.status] || [] });
    }
    else {
        dispatch({ type: CLEAR_NOTIFICATIONS_RESPONSE, payload: response.status })
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Notifications');
    }
    dispatch(toggleIsFetchingNotificationsFlag(false));
}

export const fetchActiveNotifications = (filterSort) => async (dispatch) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/notifications/active", {
        params: {
            filterSort
        }
    })

    dispatch(toggleIsFetchingNotificationsFlag(false));

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ACTIVE_NOTIFICATIONS, payload: response.data || [] });
    }
    else throw Error("Unable to fetch notifications")
}

export const updateNotificationsCurrentPage = (currentPageNumber, pageSize, notificationsId, notificationsIndex) => {
    return {
        type: UPDATE_NOTIFICATIONS_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, notificationsId, notificationsIndex]
    }
};

export const toggleIsFetchingNotificationsFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_NOTIFICATIONS,
        payload: flag
    }
}

export const putPushNotification = (notificationId, access, key) => async (dispatch) => {
    const requestData = { pushNotification: access };
    const response = await aepNewsNotificationSupport.patch("/newsNotificationSupportService/notifications/" + notificationId, requestData);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Push Notification updated successfully")
        dispatch(updatePushNotification(notificationId, access, key));
    }
    else pushMessage(CONFIG.messageType.error, "Unable to update push notification");
}

export const updatePushNotification = (notificationId, access, key) => {
    return {
        type: UPDATE_PUSH_NOTIFICATION,
        payload: {
            id: notificationId,
            access,
            key
        }
    }
}

export const deleteNotification = (notificationId, hardDelete = false, isDraftMode) => async () => {
    const response = await aepNewsNotificationSupport.delete(`/newsNotificationSupportService/notifications/${notificationId}`, { params: { hardDelete } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Notification ${isDraftMode ? "Draft" : ""} deleted successfully`);
    }
    else pushMessage(CONFIG.messageType.error, `Unable to delete notification  ${isDraftMode ? "Draft" : ""}`);
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const restoreNotification = (notificationId) => async () => {
    const response = await aepNewsNotificationSupport.put(`/newsNotificationSupportService/restore/notifications/${notificationId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, 'Notification restored successfully');
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to restore notification');
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false

}

export const fetchUserNotifications = () => async (dispatch) => {
    const response = await aepNewsNotificationSupport.get("/newsNotificationSupportService/dashboard/notifications");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({ type: FETCH_USER_DASHBOARD_NOTIFICATIONS, payload: response.data || [] });
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Notifications');
    dispatch(toggleIsFetchingNotificationsFlag(false));
}

export const clearDashboardNotifications = () => {
    return { type: CLEAR_USER_DASHBOARD_NOTIFICATIONS }
}

export const closeNotificationBanner = () => {
    return { type: CLOSE_NOTIFICATION_BANNER }
}

export const updateNotificationRoleTemplate = (access, accessType, roleType, selectedRoleTemplate, notificationId, isClone) => (dispatch, getState) => {
    const { notificationFormDetails } = getState();
    const allRoleTemplates = getState().roleTemplates.all;
    const accessKey = roleType === CONFIG.roleTypes.user ? 'allRoleTemplatesUserAccess' : 'allRoleTemplatesAdminAccess';
    let notificationTabData = notificationFormDetails.NotificationDetailsTabData.find(data => ((parseInt(data.id) === parseInt(notificationId)) && (data?.isClone === isClone)));
    let assignedRoleTemplates = notificationTabData?.details?.roleTemplates;
    let updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_NOTIFICATION_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access, accessKey, notificationId, isClone }
    });
}

export const clearNotifications = () => {
    return { type: CLEAR_NOTIFICATIONS }
}

export const reorderNotifications = (reorderedNotifications) => dispatch => {
    batch(() => {
        dispatch({
            type: REORDER_NOTIFICATIONS,
            payload: [...reorderedNotifications]
        });
        dispatch(toggleIsNotificationsReordered(true));
    });
};

export const toggleIsNotificationsReordered = (flag) => {
    return {
        type: TOGGLE_IS_NOTIFICATIONS_REORDERED,
        payload: flag
    };
};

export const putNotificationsOrder = (notifications) => async dispatch => {
    const response = await aepNewsNotificationSupport.put('newsNotificationSupportService/notifications/updateSortOrder', notifications);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Notifications order updated successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to update Notifications order");
    dispatch(toggleIsNotificationsReordered(false));
};


export const getAllRealTimePushNotificationsId = () => dispatch => {
    dispatch({
        type: FETCH_REAL_TIME_PUSH_NOTIFICATION
    })
}

export const saveRealTimePushNotificationsId = (messageId) => async dispatch => {
    dispatch({
        type: SAVE_REAL_TIME_PUSH_NOTIFICATION,
        payload: messageId
    })
}

// BELL NOTIFICATIONS

export const toggleIsFetchingBellNotificationsFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_BELL_NOTIFICATIONS,
        payload: flag
    }
}

export const fetchUserBellNotifications = (pageNumber = 1) => async dispatch => {
    const response = await aepEmail.get("/emailPushNotificationService/messages", {
        params: {
            pageSize: CONFIG.notificationsPageSize,
            pageNumber: pageNumber
        }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_USER_BELL_NOTIFICATIONS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Notifications');
    dispatch(toggleIsFetchingBellNotificationsFlag(false));
}

export const updateUserNotificationCount = (notifications) => async dispatch => {
    const response = await aepEmail.put("/emailPushNotificationService/message/count", { readCount: notifications?.[0]?.id || undefined })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: UPDATE_USER_NOTIFICATION_COUNT });
    }
}

export const clearAllNotifications = async () => {
    const response = await aepEmail.patch("/emailPushNotificationService/pushMessage/reset")
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        return true;
    else return false;
}

export const clearUserBellNotifications = () => {
    return {
        type: CLEAR_USER_BELL_NOTIFICATIONS
    }
}

export const currentNotificationFilter = (activeFilterSort, inactiveFilterSort, draftFilterSort, filters, status) => {
    return {
        type: LAST_NOTIFICATION_FILTER,
        payload: [activeFilterSort, inactiveFilterSort, draftFilterSort, filters, status]
    }
}

export const refreshNotifications = () => {
    return {
        type: REFRESH_NOTIFICATIONS
    }
}

export const toggleHasNotificationsUpdated = flag => {
    return {
        type: TOGGLE_HAS_NOTIFICATIONS_UPDATED,
        payload: flag
    }
}

export const clearNotificationsData = () => {
    return { type: CLEAR_NOTIFICATION_DATA }
}