import {
    ADMIN_DASHBOARD_TODAYS_DEMO_TEST,
    ADMIN_DASHBOARD_UPCOMING_MAINTENANCE,
    ADMIN_DASHBOARD_PENDING_APPROVALS,
    ADMIN_DASHBOARD_COMPLETED_APPROVALS,
    TOGGLE_IS_FETCHING_ADMIN_REQUESTS,
    CLEAR_ADMIN_DASHBOARD,
    ADMIN_DASHBOARD_REQUEST_STATS,
    CURRENT_APPROVALS_FILTER,
    CLEAR_APPROVALS_DATA,
    FETCHING_REQUEST_STATS,
    ADMIN_DASHBOARD_DEMO_STATUS,
    TOGGLE_IS_FETCHING_ADMIN_DEMO_STATUS,
    ADMIN_DASHBOARD_SFDC_STATUS,
    TOGGLE_IS_FETCHING_SFDC_STATUS
} from '../actions/types';

import CONFIG from '../config';

const dashboardState = {
    demoTestResult: {},
    upcomingMaintenances: [],
    pendingApprovals: [],
    fetchingAdminRequests: true,
    requestStats: [],
    currentFilter: CONFIG.typesOfRequests[0],
    demoStatusData: {},
    SFDCStatusData:{}
}

export const adminDashboardReducer = (state = dashboardState, action) => {
    switch (action.type) {
        case ADMIN_DASHBOARD_TODAYS_DEMO_TEST:
            return { ...state, demoTestResult: action.payload };
        case ADMIN_DASHBOARD_UPCOMING_MAINTENANCE:
            return { ...state, upcomingMaintenances: action.payload };
        case ADMIN_DASHBOARD_PENDING_APPROVALS:
            return { ...state, pendingApprovals: action.payload };
        case ADMIN_DASHBOARD_COMPLETED_APPROVALS:
            return { ...state, completedApprovals: state.pendingApprovals?.length > 0 ? action.payload ? action.payload?.slice(0, 5) : [] : action.payload ? action.payload?.slice(0, 3) : [] };
        case TOGGLE_IS_FETCHING_ADMIN_REQUESTS:
            return { ...state, fetchingAdminRequests: action.payload };
        case CLEAR_APPROVALS_DATA:
            return { ...state, pendingApprovals: [], completedApprovals: [] }
        case CURRENT_APPROVALS_FILTER:
            return { ...state, currentFilter: action.payload }
        case CLEAR_ADMIN_DASHBOARD:
            return dashboardState;
        case ADMIN_DASHBOARD_REQUEST_STATS:
            return { ...state, requestStats: action.payload };
        case FETCHING_REQUEST_STATS:
            return { ...state, fetchingRequestStats: action.payload };
        case ADMIN_DASHBOARD_DEMO_STATUS:
            return { ...state, demoStatusData: action.payload }
        case TOGGLE_IS_FETCHING_ADMIN_DEMO_STATUS:
            return { ...state, isFetchingDemoStatus: action.payload };
        case ADMIN_DASHBOARD_SFDC_STATUS:
            return {...state, SFDCStatusData: action.payload};
        case TOGGLE_IS_FETCHING_SFDC_STATUS:
            return {...state,isFetchingSFDCStatus: action.payload}
        default:
            return state;
    }
}