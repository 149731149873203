import React from 'react';
import { connect } from 'react-redux';
import DashboardSchedulesCard from './DashboardSchedulesCard';
import DashboardPreviousSchedulesCard from './DashboardPreviousSchedulesCard';

class DashboardSchedules extends React.Component {
    state = {
        height: `${document.body.clientHeight + 350}px`
    }
    renderSchedules = (section) => {
        return (
            section === "Previous" ?
                <DashboardPreviousSchedulesCard />
                : <DashboardSchedulesCard
                    handleDemoScheduleUpdate={this.props.handleDemoScheduleUpdate}
                    handleDemoScheduleCancel={this.props.handleDemoScheduleCancel}
                    handleMaintenanceCancel={this.props.handleMaintenanceCancel}
                    section={section}

                />
        )
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (e) => {
        this.setState({ height: document.body.clientHeight > 250 ? `${document.body.clientHeight + 300}px` : `250px` });
    }

    render() {
        return (
            <>
                <div
                    id="scrollableDiv"
                    style={{ overflow: 'auto', paddingRight: '10px' }}>
                    {this.renderSchedules(this.props.section)}
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
        user: user.profile,
        isAdmin: user.isAdmin,
    };
}

export default connect(mapStateToProps, {})(DashboardSchedules);