import React from 'react';
import { connect } from 'react-redux'
import RoleTemplatesTable from '../common/RoleTemplatesTable';

import CONFIG from '../../config';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import { updateRoleTemplateCapability, toggleIsFetchingCapability, fetchAllCapability } from '../../actions';

class RoleTemplateCapability extends React.Component {

    state = {
        selectAllDisabled: false
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    columnFilters = {
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "title"),
        sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
        onFilter: (value, record) =>
            record.title
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    }

    handleFilter = (title, confirm) => {
        title = title?.trim();
        if (!title && !this.state.selectAllDisabled) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else confirm()
    }

    handleClear = (clearFilters) => {
        clearFilters();
    }

    handleChange = (_, filters) => {
        const selectAllDisabled = Object.values(filters).filter(value => value.length !== 0)?.length ? true : false;
        this.setState({ selectAllDisabled });
    }

    componentDidMount() {
        this.props.toggleIsFetchingCapability(true);
        this.props.fetchAllCapability({}, true);
    }

    render() {
        const { demoCapabilityData, assignedCapability, allCapabilityAdminAccess, isFetching, updateRoleTemplateCapability } = this.props;
        const { selectAllDisabled } = this.state;
        return (
            <RoleTemplatesTable
                dataKey="capability"
                hideColumns={['user']}
                marginTop={true}
                bordered={true}
                data={demoCapabilityData || []}
                selectAllDisabled={selectAllDisabled}
                columnFilters={this.columnFilters}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1,
                }}
                isFetching={isFetching}
                assignedData={assignedCapability}
                allAdminAccess={allCapabilityAdminAccess}
                updateRoleTemplateData={updateRoleTemplateCapability}
            />
        );

    }

}
const mapStateToProps = ({ demoCapability, roleTemplate }) => {
    return {
        demoCapabilityData: demoCapability.demoCapabilityData,
        assignedCapability: roleTemplate.capabilities,
        allCapabilityAdminAccess: roleTemplate.allCapabilityAdminAccess,
        isFetching: demoCapability.isFetching
    }
}

export default connect(mapStateToProps, { updateRoleTemplateCapability, toggleIsFetchingCapability, fetchAllCapability })(RoleTemplateCapability);