import React from 'react';
import { connect, batch } from 'react-redux';
import { Row, message, Spin, Popover, DatePicker, Dropdown, Tabs } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment-timezone';
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import { Button, Table as NeoTable, Icon as NeoIcon, Menu as NeoMenu, MenuItem, Tooltip as NeoTooltip, Pagination } from 'neo-latest';
import {
    fetchAllExistingDemos,
    fetchDemoSchedules,
    toggleIsFetchingDemoSchedules,
    selectTitle,
    toggleBackButton,
    clearDemoSchedules,
    fetchDemoPurposeTypes,
    exportTable,
    fetchAllResources,
    deleteDemoSchedule,
    updateCurrentDemoSchedule,
    updateSchedulerStartEndTime,
    fetchActiveDemoResources,
    fetchSchedulesOverDuration,
    updateScheduleDemoCurrentPage,
    currentScheduleFilter,
    handleOnClickAction,
    toggleSaveReportVisible,
    clearManageDemoSchedules,
    openLinkInNewTab,
    fetchLiteDemoSchedules,
    clearDemoSchedulesTable
} from '../../actions';
import { getAllDemoSchedules } from '../../actions/demoSchedules';
import { getUserDemoAccess } from '../../actions/scheduler'
import CONFIG from '../../config';
import pushMessage from '../common/PushMessage';
import FilterDrawer from "../common/FilterDrawer"
import openConfirm from '../common/Confirm';
import history from "../../history";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { fetchEmailsBySearchString } from '../../actions/reports';
import { fetchNamesBySearchString } from '../../actions/userRole';
import { getApprovalStatus } from '../../utils/dashboard';
import { exportMenu } from "../../utils/strings";
import { otherFields } from '../../utils/neoTableFields';
import { isTabAlreadyOpen } from "../common/TabLayout";
import SaveReport from "../common/SaveReport";
import throttle from 'lodash/throttle';
import SFDCOppDrawer from '../common/SFDCOppDrawer';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;
export class CustomToolbar extends Toolbar {

    render() {
        return (
            <div className="rbc-toolbar">
                <div className="rbc-btn-group">
                    <button type="button" onClick={() => this.navigate('PREV')}>Prev</button>
                    <button type="button" onClick={() => this.navigate('TODAY')}>Today</button>
                    <button type="button" onClick={() => this.navigate('NEXT')}>Next</button>
                </div>
                <div className="rbc-toolbar-label">{this.props.label}</div>
                <div className="rbc-btn-group">
                    <button type="button" onClick={this.view.bind(null, 'month')}>Month</button>
                    <button type="button" onClick={this.view.bind(null, 'week')}>Week</button>
                    <button type="button" onClick={this.view.bind(null, 'day')}>Day</button>
                </div>
            </div>
        );
    }
}

const localizer = momentLocalizer(moment);

const dataIndexMap = {
    sslHandle: "createdBy.userName",
    email: "createdBy.email",
    adminCreatedBy: "adminCreatedBy.fullName",
    updatedBy: "updatedBy.fullName",
    demo: "demo.title",
    resource: "resource.name",
    demoPurpose: "demoPurpose.purpose",
    startTime: "startTime",
    endTime: "endTime",
    applyToAllCheck: 'includeStartAndEndDate',
    viewDeletedItems: 'viewDeletedItems',
    demoType: 'demo.demoType'
}

const { MonthPicker } = DatePicker;
class ManageScheduledDemo extends React.Component {

    constructor(props) {
        super(props);
        this.handleClear = throttle(this.handleClear, 1000);
        this.selectedMonthFirstDay = moment(moment()).tz(this.props.timeZoneName).startOf('month').startOf('day').toISOString();
        this.selectedMonthLastDay = moment(moment()).tz(this.props.timeZoneName).endOf('month').endOf('day').toISOString();
        this.state = {
            status: "true",
            schedulesTableData: [],
            isReturnedFromEdit: false,
            loading: false,
            isReturnedFromDelete: false,
            viewType: CONFIG.viewType.TABLE,
            calendarViewType: "month",
            calendarDate: moment(moment(moment()).tz(this.props.timeZoneName).format("YYYY-MM-DD HH:mm:ss")).toDate(),
            monthPickerValue: moment(moment()).tz(this.props.timeZoneName),
            matchedUserEmails: [],
            matchedUsersNames: [],
            searchString: [],
            hasEnteredString: false,
            demoAccess: false,
            isFetchingDemoAccessFlag: true,
            currentSchedule: undefined,
            showFilter: false,
            isDeleteEnabled: false,
            oppUpdateModal: false,
            scheduleDetails: null,
            currentPage: 0,
            currentPath: null
        };
    }

    scheduledDemoId = undefined;
    record = undefined;
    filterSort = {};
    filterSave = {};
    pageSize = 10;
    pageNumber = 1;

    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString();
    }

    fetchUserEmails = searchString => {
        fetchEmailsBySearchString({ searchString })
            .then((result) => { result.length !== 0 ? this.setState({ matchedUserEmails: [...new Set(result.map(item => item.email))] }) : this.setState({ matchedUserEmails: [] }) });
    };

    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    clearCurrentSchedules = async () => {
        this.props.clearDemoSchedulesTable();
        this.setState({ currentPage: 0, })
    }

    handleFilter = async (filters, isSave) => {
        let { sslHandle, email, adminCreatedBy, demo, resource, demoPurpose, startEndTime, applyToAllCheck, viewDeletedItems, demoType, updatedBy } = filters;
        const { viewType } = this.state;
        sslHandle = sslHandle?.trim();
        email = email?.trim();
        const isPurposeEmpty = !demoPurpose || (demoPurpose && demoPurpose.length === 0);
        const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isResourceEmpty = !resource || (resource && resource.length === 0);
        const isFilter = isSave ? "save" : "filter";
        const isDemoTypeEmpty = !demoType || (demoType && demoType.length === 0);
        const formEmpty = !sslHandle && !email && !demo && !resource && isDemoTypeEmpty && isPurposeEmpty && isStartEndTimeEmpty && isDemoEmpty && isResourceEmpty && !viewDeletedItems && !adminCreatedBy && !updatedBy;

        if (formEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
            return;
        }
        else if (!formEmpty && isSave && isStartEndTimeEmpty && viewType === CONFIG.viewType.TABLE) {
            pushMessage(CONFIG.messageType.warning, `Please select start and end time to save`)
            return;
        }
        if (isSave) {
            this.filterSave = {
                ...this.filterSave,
                filter: {
                    ...(sslHandle && {
                        [dataIndexMap.sslHandle]: {
                            operator: "=",
                            oprVal: sslHandle || undefined
                        }
                    }),
                    ...(!isDemoTypeEmpty && {
                        [dataIndexMap.demoType]: {
                            operator: "=",
                            oprVal: !isDemoTypeEmpty ? demoType.join(CONFIG.delimiters.saveFilter) : undefined
                        }
                    }),
                    ...(email && {
                        [dataIndexMap.email]: {
                            operator: "=",
                            oprVal: email || undefined
                        }
                    }),
                    ...(adminCreatedBy && {
                        [dataIndexMap.adminCreatedBy]: {
                            operator: "=",
                            oprVal: adminCreatedBy || undefined
                        }
                    }),
                    ...(updatedBy && {
                        [dataIndexMap.updatedBy]: {
                            operator: "=",
                            oprVal: updatedBy || undefined
                        }
                    }),
                    ...(!isDemoEmpty && {
                        [dataIndexMap.demo]: {
                            operator: "=",
                            oprVal: !isDemoEmpty ? demo.join(CONFIG.delimiters.saveFilter) : undefined
                        }
                    }),
                    ...(!isResourceEmpty && {
                        [dataIndexMap.resource]: {
                            operator: "=",
                            oprVal: !isResourceEmpty ? resource.join(CONFIG.delimiters.saveFilter) : undefined
                        }
                    }),
                    ...(!isPurposeEmpty && {
                        [dataIndexMap.demoPurpose]: {
                            operator: "=",
                            oprVal: !isPurposeEmpty ? demoPurpose.join(CONFIG.delimiters.saveFilter) : undefined
                        }
                    }),
                    ...((!isStartEndTimeEmpty || viewType === CONFIG.viewType.CALENDAR) && {
                        [dataIndexMap.startTime]: {
                            operator: ">=",
                            oprVal: viewType === CONFIG.viewType.CALENDAR ? this.selectedMonthFirstDay : !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0].startOf('day')) : undefined
                        }
                    }),
                    ...((!isStartEndTimeEmpty || viewType === CONFIG.viewType.CALENDAR) && {
                        [dataIndexMap.endTime]: {
                            operator: "<",
                            oprVal: viewType === CONFIG.viewType.CALENDAR ? this.selectedMonthLastDay : !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1].endOf('day')) : undefined
                        }
                    }),
                    ...(viewDeletedItems && {
                        isSoftDelete: {
                            operator: "=",
                            oprVal: viewDeletedItems
                        }
                    })
                },
                filterTable: {
                    id: 2,
                    tableName: "Scheduled Demo"
                },
            }
            this.props.toggleSaveReportVisible(true)
            this.setState({ saveReport: true })
        }
        else {
            await this.clearCurrentSchedules()
            this.filterSort = {
                ...this.filterSort,
                filter: {
                    [dataIndexMap.sslHandle]: sslHandle || undefined,
                    [dataIndexMap.email]: email || undefined,
                    [dataIndexMap.adminCreatedBy]: adminCreatedBy || undefined,
                    [dataIndexMap.updatedBy]: updatedBy || undefined,
                    [dataIndexMap.demo]: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.resource]: !isResourceEmpty ? resource.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.demoPurpose]: !isPurposeEmpty ? demoPurpose.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.startTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0]) : undefined,
                    [dataIndexMap.endTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1]) : undefined,
                    [dataIndexMap.applyToAllCheck]: applyToAllCheck || undefined,
                    [dataIndexMap.viewDeletedItems]: viewDeletedItems,
                    [dataIndexMap.demoType]: !isDemoTypeEmpty ? demoType.join(CONFIG.delimiters.selectFilter) : undefined,
                }
            }

            this.setState({ currentPage: 0, })

            batch(() => {
                this.handleFetchSchedules();
            })
        }
    }

    handleClear = async (isRefresh) => {
        await this.clearCurrentSchedules()
        if (!isRefresh) {
            this.filterSort = { ...this.filterSort, filter: undefined };
            this.filterSave = {};
        }
        this.setState({ currentPage: 0, })
        this.handleFetchSchedules();
    }

    handleSave = (filters) => {
        this.handleFilter(filters, true)
    }

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        const { viewType } = this.state;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        let filterSort = {}
        if (viewType === CONFIG.viewType.CALENDAR) {
            filterSort = {
                ...this.filterSort,
                filter: {
                    ...this.filterSort?.filter,
                    [dataIndexMap.startTime]: this.selectedMonthFirstDay,
                    [dataIndexMap.endTime]: this.selectedMonthLastDay
                }
            }
        }
        const schedules = await getAllDemoSchedules(viewType === CONFIG.viewType.CALENDAR ? filterSort : this.filterSort, this.state.status)
        if (schedules?.length !== 0 && schedules !== undefined) {
            exportTable(CONFIG.exportTable.demoSchedules.name, schedules, timeZoneName, CONFIG.exportTable.demoSchedules.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }


    handleFetchSchedules = async () => {
        const { viewType } = this.state;
        if (viewType === CONFIG.viewType.TABLE) {
            this.setState({ loading: true });
            this.props.toggleIsFetchingDemoSchedules(true);
            await this.props.fetchLiteDemoSchedules({ pageSize: this.pageSize, pageNumber: this.pageNumber, filterSort: this.filterSort, active: this.state.status, viewType: CONFIG.viewType.TABLE })
        }
        else if (viewType === CONFIG.viewType.CALENDAR) {
            this.setState({ loading: true });
            const filterSort = {
                ...this.filterSort,
                filter: {
                    ...this.filterSort?.filter,
                    [dataIndexMap.startTime]: this.selectedMonthFirstDay,
                    [dataIndexMap.endTime]: this.selectedMonthLastDay
                }
            }
            this.props.toggleIsFetchingDemoSchedules(true);
            this.props.fetchLiteDemoSchedules({ filterSort: filterSort, active: this.state.status, viewType: CONFIG.viewType.CALENDAR })
                .then(() => this.setState({ loading: false }))
        }
    }

    fetchSchedulesOnMount = async () => {
        this.setState({ loading: true })
        await this.props.fetchLiteDemoSchedules({
            filterSort: this.filterSort,
            active: this.state.status,
            viewType: CONFIG.viewType.TABLE,
            pageSize: this.pageSize, pageNumber: this.pageNumber,
        })
        this.setState({ loading: false })
    }

    async componentDidMount() {
        const { isEdit, currentFilter, pageNumber, manageDemoSchedule } = this.props;
        this.setState({ currentPath: window.location.pathname })

        if (isEdit) {
            this.setState({ currentPage: pageNumber });
            this.pageNumber = pageNumber;
            this.filterSort = currentFilter;
            if (this.filterSort?.filter !== undefined) this.setState({ showFilter: true })
            await this.setState({ status: manageDemoSchedule?.status })
        }
        else this.filterSort = {}

        this.props.toggleIsFetchingDemoSchedules(true);
        await this.fetchSchedulesOnMount();

        batch(() => {
            this.props.selectTitle('Scheduled Demos');
            this.props.toggleBackButton(false);
            this.props.fetchDemoPurposeTypes(false);
            this.props.fetchAllExistingDemos({ getAll: true });
            this.props.fetchAllResources();
        });
    }


    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentScheduleFilter(this.filterSort, this.state.status, this.state.status === "true" ? this.state.currentPage : this.pageNumber)
        }
        else {
            this.props.clearManageDemoSchedules();
        }
    }

    handleSelectChange = async (value) => {
        this.props.toggleIsFetchingDemoSchedules(true);
        await this.clearCurrentSchedules()
        this.setState({ status: value }, () => { this.handleFetchSchedules() })
    }


    handleDeleteConfirmation = async () => {
        const demoType = this.record !== undefined && this.record?.demo?.demoType
        const hardDelete = this.filterSort?.filter?.viewDeletedItems;
        if (this.props.user?.developerMode && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            const response = await this.props.deleteDemoSchedule(this.scheduledDemoId, hardDelete, demoType);
            if (response) {
                this.handleFetchSchedulesOnDelete()
            }
        }
    }

    handleFetchSchedulesOnDelete = async () => {
        this.clearCurrentSchedules()
        const pageSize = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
        const currentPageNumber = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
        await this.props.updateScheduleDemoCurrentPage(currentPageNumber, pageSize, this.scheduledDemoId, this.recordIndex)
        await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: this.recordIndex })
        this.fetchSchedulesOnMount();
    }

    renderHardDeleteConfirmation = (record, index) => {
        this.scheduledDemoId = record.id;
        this.recordIndex = index;
        this.record = record;
        openConfirm('Confirm Permanent Deletion', this.handleDeleteConfirmation, null, CONFIG.warningMessages.hardDelete.replace('<placeHolder>', 'Scheduled Demo'), CONFIG.deleteModalTitle?.replace('<placeholder>', 'scheduled demo'));
    }

    handleViewTypeChange = async (type) => {
        await this.clearCurrentSchedules()
        this.selectedMonthFirstDay = moment(moment()).tz(this.props.timeZoneName).startOf('month').startOf('day').toISOString();
        this.selectedMonthLastDay = moment(moment()).tz(this.props.timeZoneName).endOf('month').endOf('day').toISOString();
        this.setState({ viewType: type }, () => { this.handleFetchSchedules() })
    }

    getCalendarEvents = () => {
        const { schedules } = this.props;
        return schedules?.map(schedule => {
            return {
                id: schedule.id,
                title: schedule.demo?.title,
                start: moment(moment.tz(schedule.startTime, this.props.timeZoneName).format("YYYY-MM-DD HH:mm:ss")).toDate(),
                end: moment(moment.tz(schedule.endTime, this.props.timeZoneName).format("YYYY-MM-DD HH:mm:ss")).toDate(),
                demoType: schedule.demoType,
                email: schedule.createdByEmail,
                handle: schedule.sslHandle,
                resource: schedule.resource?.name
            }
        })
    }

    event = ({ event }) => {
        const content = (
            <div>
                <span className="large-text bold vertical-spacing-2">{moment(event.start).format(CONFIG.dateFormats.calendar)} - {moment(event.end).format(CONFIG.dateFormats.calendar)}</span><br />
                <span className="medium-text vertical-spacing-2"><b>Demo:</b> {event.title}</span><br />
                <span className="medium-text vertical-spacing-2"><b>Demo Type:</b> {event.demoType}</span><br />
                <span className="medium-text vertical-spacing-2"><b>Email:</b> {event.email}</span><br />
                <span className="medium-text vertical-spacing-2"><b>Handle:</b> {event.handle || "-"}</span><br />
                <span className="medium-text vertical-spacing-2"><b>Resource:</b> {event.resource || "N/A"}</span>
            </div>
        )
        return (
            <Popover
                placement="right"
                content={content}
            >
                <span>
                    {event.title}
                </span>
            </Popover>
        )
    }

    handleMonthPickerChange = (date) => {
        this.selectedMonthFirstDay = date?.startOf('month').startOf('day').toISOString();
        this.selectedMonthLastDay = date?.endOf('month').endOf('day').toISOString();
        date && this.setState({ calendarViewType: "month", calendarDate: date?.startOf('month').toDate(), monthPickerValue: date }, () => { this.handleFetchSchedules() })
    }

    fetchDemoAccess = async (record, index) => {
        const demoType = record?.demoType;
        let bufferStartTime;
        const bufferStart = record?.startTimeBuffer || 0;
        bufferStartTime = bufferStart > 0 ? moment.tz(record.startTime, this.props.timeZoneName).subtract(bufferStart, 'm') : moment.tz(record.startTime, this.props.timeZoneName);
        const disableEditButtonAfterApproval = demoType === CONFIG.demoTypes.labPocDemo && (record?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value) || getApprovalStatus(record?.scheduleApproval));
        const isBufferTimeOver = demoType !== CONFIG.demoTypes.standardDemo ? !(moment().tz(this.props.timeZoneName) < bufferStartTime) : false;
        const isDeclinedOrExpired = demoType === CONFIG.demoTypes.labPocDemo
            ? (record?.status === CONFIG.approvalStatusTypes.declinedRequests.value || record?.status === CONFIG.approvalStatusTypes.expiredRequests.value)
            : false
        this.setState({ isFetchingDemoAccessFlag: true, currentSchedule: record, currentIndex: index, disableEditButtonAfterApproval, isBufferTimeOver, isDeclinedOrExpired })

        await getUserDemoAccess(record?.demo?.id, this.props.user, record?.id).then(result => {
            result
                ? this.setState({ demoAccess: result[0], isDeleteEnabled: result[2] })
                : this.setState({ demoAccess: false, isDeleteEnabled: false })
        })
        this.setState({ isFetchingDemoAccessFlag: false })
    }

    handleCalendarRangeChange = (value, viewType) => {
        const { calendarViewType } = this.state

        viewType = viewType || calendarViewType;

        if (viewType === "month") {
            this.setState({ monthPickerValue: moment(value.start).add(10, 'days') })
            this.selectedMonthFirstDay = moment(value.start).startOf('day').toISOString();
            this.selectedMonthLastDay = moment(value.end).endOf('day').toISOString();
        }
        else if (viewType === "week") {
            this.setState({ monthPickerValue: moment(value[0]) })
            this.selectedMonthFirstDay = moment(value[0]).startOf('day').toISOString();
            this.selectedMonthLastDay = moment(value[6]).endOf('day').toISOString();
        }
        else {
            this.setState({ monthPickerValue: moment(value[0]) })
            this.selectedMonthFirstDay = moment(value[0]).startOf('day').toISOString();
            this.selectedMonthLastDay = moment(value[0]).endOf('day').toISOString();
        }
        this.handleFetchSchedules()
    }

    handleUpdate = async (schedule, demoType) => {
        if (demoType !== CONFIG.demoTypes.standardDemo) {
            this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/lab/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${true}`)
            // history.replace({ pathname: `/scheduler/lab/${schedule?.id}/edit`, fromDashboard: true, fromManageSide: true, pathValues: { pathname: '/manage/scheduled-demos' } })
            return
        }

        if (demoType === CONFIG.demoTypes.standardDemo) {
            const { demo } = schedule;
            const { timeZoneName } = this.props;

            const schedulerStartTime = moment(schedule.startTime).tz(timeZoneName).startOf('day').toISOString()
            const schedulerEndTime = moment(schedule.startTime).tz(timeZoneName).endOf('day').toISOString()

            const startTime = moment(schedule.startTime).tz(timeZoneName).startOf('day').toISOString();
            const endTime = moment(schedule.startTime).tz(timeZoneName).endOf('day').toISOString();

            this.props.updateSchedulerStartEndTime(schedulerStartTime, schedulerEndTime);
            this.props.fetchActiveDemoResources(demo?.id)
                .then(() => this.props.fetchSchedulesOverDuration(demo?.id, startTime, endTime))
                .then(() => this.props.updateCurrentDemoSchedule({ ...schedule, id: schedule?.id }))
                .then(() => this.props.openLinkInNewTab(`Schedule Demo - ${schedule?.demo?.title}`, `/scheduler/standard/${schedule?.id}/edit/isReschedule=${false}/fromManageSide=${true}`))
            // .then(() => history.replace({ pathname: `/scheduler/standard/${schedule?.id}/edit`, fromDashboard: true,fromManageSide: true, pathValues: { pathname: '/manage/scheduled-demos' } }))
        }
        else {
            // Not required (Since we dont show any maintenance on the Manage scheduled demos page)
            history.replace({ pathname: "/manage/schedule-maintenance/form", recordId: schedule.id, status: true, record: schedule })
        }
    }

    handleScheduleSoftDelete = async (scheduleId, demoType) => {
        this.demoId = scheduleId;
        this.setState({ loading: true });
        const response = await this.props.deleteDemoSchedule(scheduleId, false, demoType);
        if (response) {
            this.handleFetchSchedulesOnDelete()
        }
        else this.setState({ loading: false });
    }

    renderFilterPanel = () => {
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <div style={{ display: "flex", width: "100%", flexDirection: "row-reverse", gap: "24px" }}>
                    <div >
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <Button className="export-download-button remove-btn-index" icon="chevron-down" variant="secondary">Download</Button>
                        </Dropdown>
                    </div>
                    <div >
                        <NeoIcon size="md" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty(this.filterSort))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                    <div >
                        <NeoIcon size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                </div>
            </div>
        )
    }

    componentDidUpdate() {
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    }

    handleDatesSortForNeoTable = (event) => {
        const targetElement = event.target;
        const sortingDateChild = targetElement.querySelector('#sorting-date');
        const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
            (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
        if (sortingDateChild || arrowChild) {
            setTimeout(() => {
                const myElement = document.querySelector('.neo-dropdown__content');
                if (myElement) {
                    myElement.classList.add('sorting-classes');
                    const parentItem = document.querySelector(".sorting-classes");
                    const children = parentItem.children;
                    children[0].textContent = 'Clear Sort';
                    children[1].textContent = 'Older to Newer';
                    children[2].textContent = 'Newer to Older';
                }
            }, 10)
        }
    }

    handleChangeSortName = () => {
        setTimeout(() => {
            const myElement = document.querySelector('.neo-dropdown__content');
            if (myElement) {
                myElement.classList.add('sorting-classes');
                const parentItem = document.querySelector(".sorting-classes");
                const children = parentItem.children;
                children[0].textContent = 'Clear Sort';
                children[1].textContent = 'Older to Newer';
                children[2].textContent = 'Newer to Older';
            }
        }, 10)
    }

    calculateActionChildren = (record) => {
        const demoType = record?.demoType;
        let bufferStartTime;
        const bufferStart = record?.startTimeBuffer || 0;
        bufferStartTime = bufferStart > 0 ? moment.tz(record.startTime, this.props.timeZoneName).subtract(bufferStart, 'm') : moment.tz(record.startTime, this.props.timeZoneName);
        const disableEditButtonAfterApproval = demoType === CONFIG.demoTypes.labPocDemo && (record?.status?.includes(CONFIG.approvalStatusTypes.approvedRequests.value) || getApprovalStatus(record?.scheduleApproval));
        const isBufferTimeOver = demoType !== CONFIG.demoTypes.standardDemo ? !(moment().tz(this.props.timeZoneName) < bufferStartTime) : false;
        const isDeclinedOrExpired = demoType === CONFIG.demoTypes.labPocDemo
            ? (record?.status === CONFIG.approvalStatusTypes.declinedRequests.value || record?.status === CONFIG.approvalStatusTypes.expiredRequests.value)
            : false;

        const viewDetailsMenu = [
            <MenuItem onClick={() => {
                this.props.handleOnClickAction({
                    component: CONFIG.editComponentRoute.scheduledDemos,
                    tabTitle: record?.demo?.title,
                    recordId: record?.id
                }
                )
            }}>
                <NeoIcon style={{
                    marginRight: "8px"
                }} icon="info" size="md" />
                View Details
            </MenuItem>
        ]

        const customerDemoPurpose = [
            <MenuItem
                onClick={() => this.setState({
                    oppUpdateModal: true,
                    scheduleDetails: record
                })}>
                <NeoIcon style={{
                    marginRight: "8px"
                }} icon="awfos" size="md" />
                Update SFDC Details
            </MenuItem>
        ]

        if (this.state.status === "true") {
            const isEditButtonDisabled = (!(record?.adminAccess === 2) || (disableEditButtonAfterApproval || (isBufferTimeOver && (disableEditButtonAfterApproval || record?.demoType === CONFIG.demoTypes.restfulApiDemo))))
            const activeItems = [<MenuItem
                title={!(record?.adminAccess === 2) ? "You dont have access to this demo" : (isBufferTimeOver && ((disableEditButtonAfterApproval && !isDeclinedOrExpired) || record?.demoType === CONFIG.demoTypes.restfulApiDemo)) ? "Schedule Started" : disableEditButtonAfterApproval ? "Approval process started" : null}
                disabled={isEditButtonDisabled}
                onClick={() => {
                    if (isEditButtonDisabled) return null;
                    else {
                        this.handleUpdate(record, record?.demoType)
                    }
                }}
                id="demo-edit" >
                <NeoIcon style={{
                    marginRight: "8px"
                }} icon="edit" size="md" />
                Edit
            </MenuItem>,
            <MenuItem
                title={!(record?.adminAccess === 2) ? CONFIG.warningMessages.noAccess : undefined}
                disabled={!record?.isDeleteEnabled}
                onClick={() => {
                    if (!record?.isDeleteEnabled) return null;
                    else {
                        const content = record?.hasParticipants
                            ? <>This schedule has one or more participants.<br />
                                On delete, the schedule would be deleted for all participants. <br /><br />
                                Continue? </>
                            : "Confirm Delete?"
                        openConfirm('Yes', () => this.handleScheduleSoftDelete(record?.id, record?.demoType), null, content);
                    }
                }} >
                <NeoIcon style={{
                    marginRight: "8px"
                }} icon="trash" size="md" />
                Delete
            </MenuItem>,
            ]
            return (
                [...viewDetailsMenu, ...activeItems]
            )
        }
        return record?.isCustomerDemoPurpose ? [...viewDetailsMenu, ...customerDemoPurpose] : [...viewDetailsMenu]
    }

    renderTable = () => {
        const { isFetching, schedules, timeZoneName, count } = this.props;
        const neoColumns = [
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center", }}>{a?.cell?.row?.original?.demo?.title}{a?.cell?.row?.original?.hasParticipants && <span style={{ marginLeft: "4px" }}><NeoIcon icon="user-group" size='md' /></span>}</span>
                },
                Header: "Demo title",
                sortType: "string",
                accessor: "demo.title",
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center", }}>{a?.cell?.row?.original?.resource?.name || "-"}</span>
                },
                Header: "Resource",
                sortType: "basic",
                accessor: "resource.name",
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    let bufferStartTime;
                    const bufferStart = record?.startTimeBuffer || 0;
                    bufferStartTime = bufferStart > 0 ? moment.tz(record.startTime, this.props.timeZoneName).subtract(bufferStart, 'm') : moment.tz(record.startTime, this.props.timeZoneName);

                    return <span style={{ textAlign: "center" }}>
                        {(bufferStart > 0) ? <span>
                            {moment(record?.startTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)}{" "}
                            <NeoTooltip
                                position={'auto'}
                                label={<>
                                    <div>Buffer Start Time  - {bufferStartTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                </>}>{<NeoIcon icon="info" style={{ color: '#147ecf' }} />}
                            </NeoTooltip></span> :
                            <span>{moment(record?.startTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)} </span>}
                    </span>
                },
                Header: <span onClick={this.handleChangeSortName} id='sorting-date'>Start Time</span>,
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                },
                accessor: "startTime",
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    let bufferEndTime;
                    const bufferEnd = record?.endTimeBuffer || 0;
                    bufferEndTime = bufferEnd > 0 ? moment.tz(record.endTime, this.props.timeZoneName).add(bufferEnd, 'm') : moment.tz(record.endTime, this.props.timeZoneName);
                    const demoType = record?.demoType;
                    return <span style={{ textAlign: "center" }}>
                        {(bufferEnd > 0 && demoType === CONFIG.demoTypes.standardDemo) ? <span>
                            {moment(record?.endTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)}{" "}
                            <NeoTooltip
                                position={'auto'}
                                label={<>
                                    <div>Buffer End Time  - {bufferEndTime?.format(CONFIG.dateFormats.userDateTime)}</div>
                                </>}>{<NeoIcon icon="info" style={{ color: '#147ecf' }} />}
                            </NeoTooltip></span> :
                            <span>{moment(record?.endTime).tz(this.props.timeZoneName).format(CONFIG.dateFormats.userDateTime)} </span>}
                    </span>
                },
                Header: <span onClick={this.handleChangeSortName} id='sorting-date'>End Time</span>,
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                },
                accessor: "endTime",
            },
            {
                Cell: (a) => {
                    const creator = a?.cell?.row?.original?.createdBy?.fullName;
                    return <span style={{ textAlign: "center" }}>{creator || "-"}</span>
                },
                Header: "Created By",
                accessor: "createdBy.fullName",
                sortType: "basic"
            },
            {
                Header: "  ",
                disableFilters: true,
                disableSortBy: true,
                Cell: (a) => {
                    const record = a.cell.row.original;
                    const children = this.calculateActionChildren(record)
                    return (
                        <NeoMenu className='schedule-demo-menu-actions' positionToToggle="left" menuRootElement={<NeoIcon style={{ cursor: "pointer", color: "#1B77AF" }} icon="typing" size='md' />}>
                            {children}
                        </NeoMenu>
                    )
                }
            }
        ];
        const events = this.getCalendarEvents();
        return (
            isFetching ? null : this.state.viewType === CONFIG.viewType.TABLE ? this.state.status === "true" ? <NeoTable
                columns={neoColumns}
                data={schedules || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                showRowSeparator
                initialStatePageIndex={this.state.currentPage}
                handlePageChange={(newPageIndex, newPageSize) => {
                    this.setState({ currentPage: newPageIndex })
                }}
                {...otherFields}
            /> : <div> <NeoTable
                columns={neoColumns}
                data={schedules || []}
                className="table-actions"
                allowColumnFilter={false}
                showPagination={false}
                showRowSeparator
                initialStatePageIndex={this.pageNumber}
            />
                <Pagination
                    currentPageIndex={this.pageNumber}
                    alwaysShowPagination={true}
                    itemCount={count}
                    itemsPerPage={this.pageSize}
                    // itemsPerPageOptions={[10, 20, 50, 100]}
                    itemDisplayType={"count"}
                    onPageChange={(e, newIndex) => {
                        this.pageNumber = newIndex;
                        this.handleFetchSchedules();
                        e?.preventDefault();
                    }}
                />
            </div> :
                <Spin spinning={this.state.loading} indicator={antIcon} wrapperClassName="spin-overlay">
                    <Row className="right-align bottom-spacing-4" style={{ marginBottom: "10px" }}>
                        <MonthPicker
                            className="left-spacing-3"
                            onChange={this.handleMonthPickerChange}
                            placeholder="Select month"
                            value={this.state.monthPickerValue}
                        />
                    </Row>
                    <Calendar
                        events={events || []}
                        views={['month', 'week', 'day']}
                        defaultView='month'
                        popup
                        showMultiDayTimes
                        // showAllEvents={true}
                        style={{ height: 600 }}
                        localizer={localizer}
                        onView={(view) => this.setState({ calendarViewType: view })}
                        onNavigate={(value) => {
                            this.setState({ calendarDate: value })
                        }}
                        onRangeChange={this.handleCalendarRangeChange}
                        getNow={() => moment(moment(moment()).tz(this.props.timeZoneName).format("YYYY-MM-DD HH:mm:ss")).toDate()}
                        tooltipAccessor={() => null}
                        view={this.state.calendarViewType}
                        date={this.state.calendarDate}
                        components={{ toolbar: CustomToolbar, event: this.event }}
                    />
                </Spin>
        )
    }

    renderTabContents = () => {
        return (
            <React.Fragment>
                <div>
                    {this.renderFilterPanel()}
                </div>
                <div style={{ minHeight: "250px", marginBottom: "40px" }}>
                    {this.renderTable()}
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { purposeTypes, timeZoneName, demos, resources, } = this.props;
        const formFields = [
            {
                label: "Handle",
                type: CONFIG.formFieldTypes.INPUT,
                key: "sslHandle",
                value: this.filterSort?.filter?.[dataIndexMap.sslHandle],
                colSpan: 24
            },
            {
                label: "Email",
                type: CONFIG.formFieldTypes.EMAIL_SEARCH,
                data: this.state.matchedUserEmails,
                key: "email",
                value: this.filterSort?.filter?.[dataIndexMap.email],
                colSpan: 24
            },
            {
                label: "Created By Admin",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                data: this.state.matchedUsersNames,
                key: "adminCreatedBy",
                value: this.filterSort?.filter?.[dataIndexMap.adminCreatedBy],
                colSpan: 24
            },
            {
                label: "Start / End Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "startEndTime",
                colSpan: 24,
                value: [this.filterSort?.filter?.[dataIndexMap.startTime], this.filterSort?.filter?.[dataIndexMap.endTime], this.filterSort?.filter?.[dataIndexMap.applyToAllCheck]],
                disabled: this.state.viewType === CONFIG.viewType.CALENDAR ? true : false
            },
            {
                label: "Demo",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: (demos) ? demos.map(demo => ({ text: demo.title })) : [],
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.demo]
            },
            {
                label: "Resource",
                type: CONFIG.formFieldTypes.SELECT,
                key: "resource",
                mode: "tags",
                valueKey: "text",
                data: (resources) ? resources.map(resource => ({ text: resource.name })) : [],
                value: this.filterSort.filter?.[dataIndexMap.resource],
                colSpan: 24
            },
            {
                label: "Demo Purpose",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demoPurpose",
                data: purposeTypes?.map(type => ({ text: type?.purpose })),
                valueKey: "text",
                value: this.filterSort.filter?.[dataIndexMap.demoPurpose],
                colSpan: 24
            },
            {
                label: "Demo Type",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demoType",
                data: CONFIG.filterDataSet.allDemoTypes,
                value: this.filterSort.filter?.[dataIndexMap.demoType],
                colSpan: 24
            },
            {
                label: "Updated By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                data: this.state.matchedUsersNames,
                key: "updatedBy",
                value: this.filterSort.filter?.[dataIndexMap.updatedBy],
                colSpan: 24
            },
        ]
        const drawerContainer = document.getElementById('#component-content');

        return (
            <>
                <div style={{ display: "flex", marginTop: "28px" }}>
                    <div style={{ display: "flex", flex: "1", color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px" }}>
                        Manage Scheduled Demos
                    </div>
                    <div style={{ display: "flex", flex: "1", justifyContent: "flex-end" }}>
                        <Button icon={"table"} aria-label="Table" className={this.state.viewType === "tableView" ? "neo-button-selected" : ""} variant="secondary" onClick={() => this.handleViewTypeChange(CONFIG.viewType.TABLE)} >Table</Button>
                        <Button icon={"calendar"} aria-label="Calendar" className={this.state.viewType === "calenderView" ? "neo-button-selected left-spacing-3" : "left-spacing-3"} variant="secondary" onClick={() => this.handleViewTypeChange(CONFIG.viewType.CALENDAR)}>Calendar</Button>
                    </div>
                </div>
                <Spin spinning={this.props.isFetching} indicator={antIcon} wrapperClassName="spin-overlay">
                    <div style={{ marginTop: "16px" }}>
                        <Tabs activeKey={this.state.status} className='demo-status-tabs' defaultActiveKey={this.state.status} onChange={this.handleSelectChange}>
                            <TabPane tab="Active" key="true">
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="All" key="all">
                                {this.renderTabContents()}
                            </TabPane>
                        </Tabs>
                    </div>
                    <div>
                        <Row>
                            {this.state.showFilter && (
                                <FilterDrawer
                                    visible={this.state.showFilter}
                                    width='520'
                                    handleCloseFilterDrawer={() => this.setState(prevState => ({
                                        showFilter: false,
                                        record: null
                                    }))}
                                    placement="right"
                                    drawerContainer={drawerContainer}
                                    style={{ zIndex: 9999, }}
                                    maskClosable={false}
                                    panelHeader="Filters and Export"
                                    formFields={formFields}
                                    handleClear={this.handleClear}
                                    handleSave={this.handleSave}
                                    timeZone={timeZoneName}
                                    hideSoftDeleteCheckbox={false}
                                    label='View Deleted Scheduled Demos'
                                    hideSave={false}
                                    viewDeletedItemsValue={this.filterSort?.filter?.viewDeletedItems}
                                    handleFilter={this.handleFilter}
                                    getSearchData={this.fetchUsersName}
                                    getSearchEmailData={this.fetchUserEmails}
                                />)}
                        </Row>
                    </div>
                    {
                        this.state.oppUpdateModal && <SFDCOppDrawer
                            visible={this.state.oppUpdateModal}
                            timezone={this.props.timeZoneName}
                            schedule={this.state.scheduleDetails}
                            handleFetchSchedules={async () => {
                                await this.handleClear(true);
                            }}
                            handleCloseSFDCDrawer={() => { this.setState({ oppUpdateModal: false, scheduleDetails: null }) }}
                        />
                    }
                    {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
                </Spin >
            </>
        );
    }
}
const mapStateToProps = ({ manageDemoSchedule, user, newSchedule, demos, filterPanel, tabsLayout }) => {
    return {
        manageDemoSchedule,
        schedules: manageDemoSchedule.data,
        response: manageDemoSchedule.response,
        count: manageDemoSchedule.count,
        isFetching: manageDemoSchedule.isFetching,
        user: user.profile,
        timeZoneName: user.profile?.timezone,
        purposeTypes: newSchedule.demoPurposeTypes?.dataSet || [],
        demos: filterPanel.allDemos,
        resources: filterPanel.allResources,
        pageSize: manageDemoSchedule.pageSize,
        pageNumber: manageDemoSchedule.pageNumber,
        currentPageNumber: manageDemoSchedule.scheduledDemoCurrentPageNumber,
        scheduleIndex: manageDemoSchedule.scheduledDemoIndex,
        currentScheduleId: manageDemoSchedule.currentScheduleId,
        isEdit: manageDemoSchedule.isEdit,
        currentFilter: manageDemoSchedule.currentFilter,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,

    };
};
export default connect(
    mapStateToProps,
    {
        fetchDemoSchedules,
        toggleIsFetchingDemoSchedules,
        selectTitle,
        toggleBackButton,
        clearDemoSchedules,
        fetchDemoPurposeTypes,
        fetchAllExistingDemos,
        fetchAllResources,
        deleteDemoSchedule,
        updateCurrentDemoSchedule,
        updateSchedulerStartEndTime,
        fetchActiveDemoResources,
        fetchSchedulesOverDuration,
        updateScheduleDemoCurrentPage,
        currentScheduleFilter,
        handleOnClickAction,
        toggleSaveReportVisible,
        clearManageDemoSchedules,
        openLinkInNewTab,
        fetchLiteDemoSchedules,
        clearDemoSchedulesTable
    }
)(ManageScheduledDemo);