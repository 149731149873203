import React from 'react';
import { connect, batch } from 'react-redux';
import { Table, Button, Icon, Divider, Select, Popconfirm, Tooltip, Row, Col, message, Dropdown } from 'antd';
import DragListView from 'react-drag-listview';
import { Icon as NeoIcon } from "@avaya/neo-react";
import scrollIntoView from 'scroll-into-view';
import {
    selectTitle,
    toggleBackButton,
    fetchAllSupportGuides,
    reorderSupportGuides,
    toggleIsFetchingSupportGuides,
    putSupportGuidesOrder,
    deleteSupportGuide,
    clearSupport,
    updateSelectedGuideType,
    restoreSupportGuide,
    exportTable,
    handleOnClickAction,
    currentSupportFilter
} from '../../actions';
import history from '../../history';
import CONFIG from '../../config';
import { toTitleCase } from '../../utils/strings';
import pushMessage from '../common/PushMessage';
import FilterPanel from '../common/FilterPanel';
import openConfirm from '../common/Confirm';
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";

const { Option } = Select;
class ManageSupport extends React.Component {

    constructor(props) {
        super(props);
        this.dragColumn = {
            title: "",
            key: "operate",
            align: 'center',
            render: () => <Icon className="drag-handle" type="drag" />,
            width: '5%'
        };

        this.state = {
            visible: false,
            currentGuide: {},
            editMode: false,
            file: undefined,
            sortedInfo: null,
            filteredData: undefined,
            showFilter: false,
            currentPath: null,
        };
        this.filterSort = {};
        const that = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const newGuides = that.props.guides;
                const item = newGuides.splice(fromIndex, 1)[0];
                newGuides.splice(toIndex, 0, item);
                that.props.reorderSupportGuides(newGuides);
            },
            handleSelector: "i"
        };

        this.isVideoType = false;

        this.tableRef = React.createRef()
    }


    isViewDeletedItemsChecked = false;
    guideType = this.props.guideType || this.props.selectedGuide;

    getSortedGuides = () => {
        const { guides } = this.props;
        const { sortedInfo } = this.state;
        if (this.isTableSorted) guides.sort((a, b) => {
            if (sortedInfo.order === "descend") [a, b] = [b, a]
            return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
        })
        return guides;
    }

    filterRecords = (filters) => {
        let { title, path, viewDeletedItems } = filters;
        const { guides } = this.props;
        title = title?.trim();
        this.formFilters = filters;
        title = title?.trim();
        this.isViewDeletedItemsChecked = viewDeletedItems;
        if (!title && !path && !viewDeletedItems) {
            pushMessage(CONFIG.messageType.warning, "Please select some fields to filter");
            return;
        }

        this.titleSearchString = title;
        this.path = path;
        let filteredData = [];
        filteredData = title
            ? guides.filter(guide => guide.title.toLowerCase().includes(title.toLowerCase()))
            : guides;

        filteredData = path
            ? filteredData.filter(guide => guide.path.toLowerCase().includes(path.toLowerCase())) :
            filteredData;

        this.setState({
            filteredData
        });

        this.filterSort = {
            ...this.filterSort,
            title,
            path,
            viewDeletedItems
        }

        scrollIntoView(document.querySelector(`.ant-table-thead`), {
            align: { top: 10, left: 0 },
            behavior: "smooth"
        })


    }

    handleFilter = (filters) => {
        const { viewDeletedItems } = filters;
        (viewDeletedItems || this.isViewDeletedItemsChecked) ? this.props.fetchAllSupportGuides(this.guideType, viewDeletedItems).then(() => {
            this.isViewDeletedItemsChecked = viewDeletedItems;
            this.filterRecords(filters);
        }) : this.filterRecords(filters);
    }

    handleRestore = (guideId) => {
        const { filteredData } = this.state;
        this.props.restoreSupportGuide(guideId)
            .then(() => {
                if (filteredData) {
                    this.setState({
                        filteredData: filteredData.filter(guide => guide.id !== guideId)
                    })
                }
            })
    }

    handleClear = (isRefresh) => {
        if (!isRefresh) {
            this.titleSearchString = undefined;
            this.path = undefined;
            this.filteredGuideTypes = undefined;
            this.formFilters = undefined;
            this.isViewDeletedItemsChecked && this.props.fetchAllSupportGuides(this.guideType);
            this.isViewDeletedItemsChecked = false
            this.setState({ filteredData: undefined });
            this.filterSort = {}
        } else {
            this.props.toggleIsFetchingSupportGuides(true);
            this.props.fetchAllSupportGuides(this.guideType, this.isViewDeletedItemsChecked);
            const currentFilterSize = this.filterSort?.filter !== undefined ? Object.keys(this.filterSort?.filter)?.length : 0;
            currentFilterSize > 0 && this.filterRecords(this.filterSort)
        }
    }

    handleExport = async (fileType) => {
        const { timeZoneName, guides } = this.props;
        const { filteredData } = this.state;
        const exportData = filteredData || guides
        if (exportData?.length > 0 && exportData !== undefined) {
            pushMessage(CONFIG.messageType.loading, "Exporting", 0);
            exportTable(CONFIG.exportTable.support.name, exportData, timeZoneName, CONFIG.exportTable.support.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    handleChange = (_, __, sorter) => {
        this.setState({
            sortedInfo: sorter
        });
    }

    handleEdit = (record) => history.replace({ pathname: '/manage/support/form', guideId: record.id, guideType: this.guideType, filterSort: this.filterSort })

    handleDeleteConfirmation = () => {
        const hardDelete = this.isViewDeletedItemsChecked;
        const { filteredData } = this.state;
        this.props.deleteSupportGuide(this.guideId, hardDelete)
            .then(() => {
                if (filteredData) {
                    this.setState({
                        filteredData: filteredData.filter(guide => guide.id !== this.guideId)
                    })
                }
            })
    }

    handleDelete = (guideId) => {
        this.guideId = guideId;
        const hardDelete = this.isViewDeletedItemsChecked;
        if (this.props.user?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
        else {
            const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
            const content = hardDelete ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "Support Guide")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "Support Guide")}</h4>
            const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'support guide')
            openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
        }
    }

    renderGuideTypes = () => {
        return Object.values(CONFIG.guideType).map((type) => {
            return <Option value={type.value}>{type.text}</Option>
        });
    }

    handleGuideTypeChange = (guideType) => {
        this.guideType = guideType;
        this.isVideoType = guideType === CONFIG.guideType.videoLink.value;
        this.props.updateSelectedGuideType(this.guideType);
        if (this.isViewDeletedItemsChecked)
            this.handleFilter(this.formFilters)
        else this.props.fetchAllSupportGuides(guideType)
            .then(() => {
                this.formFilters && this.handleFilter(this.formFilters)
            })

    }

    renderUpdateButton = () => {
        const isUpdateDisabled = this.state.filteredData !== undefined || !this.isTableSorted || this.props.guides?.length === 0;
        const deleteView = this.isViewDeletedItemsChecked;
        return !deleteView && (
            <Button className="primary-action-button-bordered"
                disabled={!this.props.isReordered && isUpdateDisabled}
                onClick={() => this.props.putSupportGuidesOrder(this.getSortedGuides())}
            >
                Update order
            </Button>
        );
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            const filterSort = {
                ...this.filterSort,
                sorter: this.state.sortedInfo ?? undefined
            }
            this.props.currentSupportFilter(filterSort, this.guideType);
            this.props.updateSelectedGuideType(this.guideType);
        }
        else {
            this.props.clearSupport();
        }
    }

    async componentDidMount() {
        const { support } = this.props;
        document.getElementById('tab-pane').scrollIntoView();
        this.setState({ currentPath: window.location.pathname })
        if (support?.isEdit) {
            this.filterSort = support.currentFilter;
            const { sorter, ...filterSort } = support.currentFilter;
            const currentFilterSize = filterSort !== undefined ? Object.keys(filterSort)?.length : 0;
            if (currentFilterSize > 0) {
                this.filterRecords(filterSort)
                this.setState({ showFilter: true });
                this.filterSort = filterSort;
            }
            this.guideType = support.guideType;
            this.setState({ sortedInfo: support.currentFilter.sorter })
        }
        await this.props.fetchAllSupportGuides(this.guideType === undefined ? CONFIG.guideType.gettingStarted.value : this.guideType);
        batch(() => {
            this.props.selectTitle('Manage Support');
            this.props.toggleBackButton(false);
            this.guideType = this.props.guideType || this.props.selectedGuide;
            this.isVideoType = this.guideType === CONFIG.guideType.videoLink.value ? this.guideType : false;
            this.forceUpdate();
        });
    }

    render() {
        const { user, guides, viewport } = this.props;
        const guideType = this.guideType;
        let { filteredData, sortedInfo } = this.state;
        const isUserSuperAdmin = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
        sortedInfo = sortedInfo || {};
        this.isTableSorted = Object.keys(sortedInfo)?.length > 2;
        const isDragDisabled = filteredData !== undefined;
        const actionColumn = {
            title: 'Actions',
            key: 'actions',
            width: '15%',
            render: (record) => {
                const { user } = this.props;
                const deleteView = this.isViewDeletedItemsChecked;
                const noAccessWarning = CONFIG.warningMessages.noAccess;
                const isEditDisabled = user.roleTemplate
                    && user.roleTemplate.templateType !== CONFIG.roleTypes.superAdmin
                    && record.adminAccess === CONFIG.roleTemplateAccess.readOnly;
                return (
                    <div>
                        {!deleteView &&
                            <>
                                <Tooltip title={isEditDisabled ? noAccessWarning : undefined} key={record.id}>
                                    <button
                                        onClick={() => {
                                            this.props.handleOnClickAction({
                                                component: CONFIG.editComponentRoute.support,
                                                tabTitle: record?.title,
                                                recordId: record?.id
                                            })
                                        }}
                                        disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}>
                                        <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                    </button>
                                </Tooltip>
                                <Divider type="vertical" />
                            </>
                        }
                        <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                            {
                                <>
                                    <button disabled={isEditDisabled} className={isEditDisabled ? "link-disabled" : "link"} onClick={() => this.handleDelete(record.id)}><span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                    {deleteView && <>
                                        <Divider type="vertical" />
                                        <Popconfirm disabled={isEditDisabled} title="Confirm restore?" onConfirm={() => this.handleRestore(record.id)}>
                                            <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-history" title="Restore" style={{ fontSize: "20px" }}></span></button>
                                        </Popconfirm>
                                    </>
                                    }
                                </>
                            }
                        </Tooltip>
                    </div>
                )
            },
            align: 'center'
        };
        let columns = [
            {
                title: <span className={this.titleSearchString ? "filtered-column" : ''}>Title</span>,
                dataIndex: 'title',
                key: 'title',
                width: '25%',
                align: 'left',
                sorter: (a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
                sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
            },
            {
                title: <span className={this.filteredGuideTypes ? "filtered-column" : ''}>Type</span>,
                dataIndex: 'guideType',
                key: 'guideType',
                width: '15%',
                align: 'center',
                sorter: (a, b) => a.guideType.toLowerCase().localeCompare(b.guideType.toLowerCase()),
                sortOrder: sortedInfo.columnKey === 'guideType' && sortedInfo.order,
                render: (type) => toTitleCase(type)
            }

        ];
        const videoLinkColumn = {

            title: <span className={this.path ? "filtered-column" : ''}>Video Link</span>,
            dataIndex: 'path',
            key: 'path',
            width: '40%',
            align: 'left',
            sorter: (a, b) => a.path.toLowerCase().localeCompare(b.path.toLowerCase()),
            sortOrder: sortedInfo.columnKey === 'path' && sortedInfo.order,
            //eslint-disable-next-line
            render: text => <a href={text} target="_blank" >{text}</a>

        }

        columns = this.isVideoType
            ? [...columns, videoLinkColumn, actionColumn]
            : [...columns, actionColumn];

        const formFields = this.isVideoType
            ? [
                {
                    label: "Title",
                    type: CONFIG.formFieldTypes.INPUT,
                    key: "title",
                    colSpan: 12,
                    value: this.filterSort?.title
                },
                {
                    label: "Video Link",
                    type: CONFIG.formFieldTypes.INPUT,
                    key: "path",
                    value: this.filterSort?.path
                }
            ]
            : [
                {
                    label: "Title",
                    type: CONFIG.formFieldTypes.INPUT,
                    key: "title",
                    colSpan: 12,
                    value: this.filterSort?.title
                }
            ]

        const { showFilter } = this.state
        return (
            <React.Fragment>
                <Row className="vertical-spacing">
                    <Col md={5} xs={24} className={viewport.isMobileView ? "top-spacing" : ""}>
                        <Select showSearch={true} placeholder="Select a type" defaultValue={guideType === undefined ? CONFIG.guideType.gettingStarted.value : guideType} onChange={this.handleGuideTypeChange}>
                            {this.renderGuideTypes()}
                        </Select>
                    </Col>
                    <Col xl={2} sm={6} xs={24} className="left-spacing">
                        <Button className="primary-action-button-filled"
                            onClick={() => {
                                this.props.handleOnClickAction({
                                    component: CONFIG.createComponentRoute.support,
                                    tabTitle: "Create"
                                })
                            }}>New
                            <Icon type="plus-circle" />
                        </Button>

                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoIcon title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Dropdown>
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                </Row>
                <div className="content-container responsive-container">
                    <Row className="bottom-spacing">
                        <Col lg={18} xs={24}>
                            {this.state.showFilter && <FilterPanel
                                formFields={formFields}
                                panelHeader="Filters and Export"
                                label='View Deleted Support Guides'
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                viewDeletedItemsValue={this.filterSort?.viewDeletedItems}
                            />}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={18} xs={24}>
                            <h3> {!this.isViewDeletedItemsChecked ? 'List of all Support Guides' : 'List of Deleted Support Guides'}</h3>
                            <div ref={this.tableRef}>
                                {isUserSuperAdmin && this.renderUpdateButton()}
                                <DragListView {...this.dragProps}>
                                    <Table
                                        size='middle'
                                        className="vertical-spacing responsive-container wrapword"
                                        columns={isUserSuperAdmin && !isDragDisabled ? [this.dragColumn, ...columns] : columns}
                                        dataSource={filteredData || guides}
                                        bordered
                                        pagination={false}
                                        rowKey={(record) => record.id}
                                        loading={this.props.isFetching}
                                        onChange={this.handleChange}

                                    />
                                </DragListView>
                            </div>
                            {isUserSuperAdmin && this.renderUpdateButton()}
                        </Col>
                    </Row>
                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = ({ viewport, support, user, tabsLayout }) => {
    return {
        isReordered: support.isReordered,
        isFetching: support.isFetching,
        guides: support.adminSupportGuides,
        viewport: viewport,
        user: user.profile,
        guideType: support?.currentGuide?.guideType,
        selectedGuide: support.selectedGuide,
        support,
        openedTabs: tabsLayout.panes,
    }
}

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        fetchAllSupportGuides,
        reorderSupportGuides,
        toggleIsFetchingSupportGuides,
        putSupportGuidesOrder,
        deleteSupportGuide,
        clearSupport,
        updateSelectedGuideType,
        restoreSupportGuide,
        exportTable,
        handleOnClickAction,
        currentSupportFilter
    }
)(ManageSupport);