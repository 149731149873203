const ContentRouteConfig = {
    dashboard: '/dashboard',
    userSupport: '/support',
    getStarted: "/support/getting-started",
    userNews: '/news',
    userEZDemos: '/ez-demos',
    userStandardReport: '/standard-reports',
    scheduleApproval: '/schedule-approvals',
    roleTemplates: '/manage/role-templates',
    ssoUsers: '/manage/users/sso',
    nonSsoUsers: '/manage/users/non-sso',
    demos: '/manage/demo',
    scheduleDemos: '/manage/scheduled-demos',
    resources: '/manage/demo-resources',
    solutions: '/manage/demo-solutions',
    solutionTypes: '/manage/solution-types',
    automaticBuilds: '/manage/builds',
    manualBuilds: '/manage/builds/manual',
    purpose: '/manage/purpose',
    demoStatus: '/manage/demo-status',
    feedbackQuestions: '/manage/feedback-questions',
    demoQuestions: '/manage/demo-questions',
    demoRequests: '/manage/demo-requests',
    news: '/manage/news',
    notification: '/manage/notifications',
    support: '/manage/support',
    standardEmail: '/manage/emailTemplates/standard',
    customEmail: '/manage/emailTemplates/custom',
    maintenance: '/manage/schedule-maintenance',
    dataCenter: '/manage/dataCenters',
    dids: '/manage/dids',
    networks: '/manage/networks',
    tips: '/manage/tips',
    customReports: '/manage/custom-reports',
    standardReports: '/manage/standard-reports',
    userCreatedReports: '/manage/user-created-reports',
    products: '/manage/daily-report/products',
    attributes: '/manage/daily-reporting-attributes',
    dailyReport: '/manage/daily-reports',
    demoCapability: '/manage/capability',
    capabilityRequest: '/manage/capability/requests',
    ezDemos: "/manage/ez-demos",
    mySchedules: "/my-schedules",
    myRequests: "/my-requests",
    userReports: "/user-reports",
    sfdcStats: "/manage/sfdc-statistics",
    adminSettings: "/admin/settings"
}

export default ContentRouteConfig;