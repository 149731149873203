import React from 'react';
import { Form, Row, Col, Input, Select } from 'antd';
import { connect } from 'react-redux';
import DataCenters from '../common/DataCenters';
import FormActionButtons from '../common/FormActionButtons';
import { postNewNetwork, clearNetworkData, fetchNetworks, currentNetworksFilter, createNetworkData, } from '../../actions';
import CONFIG from '../../config';
import NetworkRoleTemplates from './NetworkRoleTemplates';
import { isIP } from 'is-ip';
import { isTabAlreadyOpen } from "../common/TabLayout";
const { Option } = Select;

const CreateNetwork = Form.create({ name: 'network_form' })(
    class CreateNetwork extends React.Component {

        state = {
            selectedDataCenter: null,
        }

        handleSubmit = () => {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.postNewNetwork(values)
                        .then(() => this.props.fetchNetworks());
                    this.props.form.resetFields();
                }
            });
        }

        handleReset = () => {
            this.props.form.resetFields();
            this.props.clearNetworkData();
        }

        renderSubnetMaskOptions = () => {
            return (
                CONFIG.subnetMasks.map((subnetMask, index) => {
                    return <Option key={index} value={subnetMask.value}>{`${subnetMask.value} - ${subnetMask.title}`}</Option>;
                }));
        }

        handleNetworkAddress = (rule, value, callback) => {
            if (value && !(isIP(value))) callback("Please input valid network address");
            callback();

        }

        componentWillUnmount() {
            const { form, roleTemplates } = this.props;
            if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
                const values = {
                    networkAddress: form.getFieldValue("networkAddress"),
                    subnetMask: form.getFieldValue("subnetMask"),
                    roleTemplates,
                    gateway: form.getFieldValue("gateway"),
                    description: form.getFieldValue("description"),
                }
                this.props.createNetworkData(this.state.selectedDataCenter, values);
            }
            else {
                this.props.clearNetworkData();
            }
        }

        componentDidMount() {
            // const { currentSelectedDataCenter, isEdit, currentSelectedNetwork } = this.props;
            this.setState({ loading: true })
            this.setState({ currentPath: window.location.pathname })

        }

        render() {
            const { getFieldDecorator } = this.props.form;
            const { isSuperAdmin, data, selectedDC } = this.props;
            return (
                <React.Fragment>
                    <Row gutter={48}>
                        <Col xl={8} md={12}>
                            <Form.Item label="Network Address">
                                {getFieldDecorator('networkAddress', {
                                    rules: [{ required: true, message: 'Please input network address' },
                                    { validator: this.handleNetworkAddress, },],
                                    initialValue: data?.networkAddress
                                })(<Input placeholder="Enter a Network Address" />)}
                            </Form.Item></Col>
                        <Col xl={8} md={12}>
                            <Form.Item label="Subnet Mask">
                                {getFieldDecorator('subnetMask', {
                                    rules: [{ required: true, message: 'Please select a subnet mask' }],
                                    initialValue: data?.subnetMask
                                })(
                                    <Select showSearch={true} placeholder="Select a Subnet Mask">
                                        {this.renderSubnetMaskOptions()}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={48}>
                        <Col xl={8} md={12}>
                            <Form.Item label="Gateway Address">
                                {getFieldDecorator('gateway', {
                                    rules: [{ required: true, message: 'Please input gateway address' },
                                    { validator: this.handleNetworkAddress, },],
                                    initialValue: data?.gateway
                                })(<Input placeholder="Enter a Gateway Address" />)}
                            </Form.Item>
                        </Col>
                        <Col xl={8} md={12}>
                            <Form.Item label="Description">
                                {getFieldDecorator('description', {
                                    rules: [{ required: true, message: 'Please input description' }],
                                    initialValue: data?.description
                                })(<Input placeholder="Enter a Description" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={48}>
                        {isSuperAdmin &&
                            <Col xl={8} md={12} xs={24}>
                                <NetworkRoleTemplates form={this.props.form} />
                            </Col>
                        }
                        <Col xl={6} md={8}>
                            <DataCenters handleChange={(selectedDataCenter) => {
                                this.setState({ selectedDataCenter: JSON.parse(selectedDataCenter) })
                            }}
                                fromDids={true}
                                dataCenter={selectedDC}
                                form={this.props.form} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="right-align ">
                            <FormActionButtons
                                handleSubmit={this.handleSubmit}
                                cancelText={"Clear form"}
                                handleCancel={this.handleReset}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }
    }
)

const mapStateToProps = ({ networks, tabsLayout }) => {
    return {
        data: networks.createData,
        selectedDC: networks.selectedDC,
        openedTabs: tabsLayout.panes,
        isEdit: networks.isEdit,
        roleTemplates: networks?.current?.roleTemplates,
        networks
    }
}


export default connect(
    mapStateToProps,
    {
        postNewNetwork,
        clearNetworkData,
        fetchNetworks,
        currentNetworksFilter,
        createNetworkData,
    }
)(CreateNetwork);

