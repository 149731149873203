import React, { Component } from 'react'
import { Select, Button, Icon, Row, Col, Popconfirm, Table, Form, Tooltip, Switch } from 'antd';
import { connect } from 'react-redux';
import CONFIG from '../../config';

import {
    addDemoTabCapability,
    addNewDemoCapability,
    deleteDemoCapability,
    editDemoCapability
} from "../../actions"

const { Option } = Select;
class DemoCapability extends Component {

    renderCapabilityOptions = () => {
        const { allCapabilities, demoCapability } = this.props;
        const filteredCap = allCapabilities?.length > 0 && allCapabilities?.filter(ar =>
            demoCapability.every(item => item?.capability?.id !== ar?.id));

        return filteredCap ? filteredCap.map((capability, index) => {
            return <Option key={index} value={JSON.stringify(capability)}>{capability.title}</Option>;
        }) : []
    }

    handleAdd = () => {
        const { form } = this.props;
        const demoCapability = form.getFieldsValue().demoCapability;
        demoCapability.forEach(async (capability) => {
            let capabilityToAdd = { capability: JSON.parse(capability), isOneTime: false }
            await this.props.addNewDemoCapability(capabilityToAdd)

        })
        form.setFieldsValue({ demoCapability: undefined });
    }

    renderCapabilitySelect = () => {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Row gutter={24} className="vertical-spacing">
                <Col xl={12} sm={12} xs={24}>
                    <Form id="demoCapabilityForm">
                        <Form.Item>
                            {
                                getFieldDecorator('demoCapability')(
                                    <Select
                                        getPopupContainer={() => document.getElementById('demoCapabilityForm')}
                                        showSearch={true} mode="multiple" placeholder="Select one or more capability" >
                                        {this.renderCapabilityOptions()}
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Col>
                <Col xl={2} sm={2} xs={24}>
                    <Button aria-label="Click" className="primary-action-button-bordered horizontal-spacing antd-button-fix"
                        onClick={this.handleAdd}
                        disabled={(!form.getFieldValue('demoCapability') || form.getFieldValue('demoCapability')?.length === 0)}>
                        Add
                        <Icon type="plus-circle" />
                    </Button>
                </Col>
            </Row>
        )
    }

    handleDelete = (record) => {
        const capabilityId = record?.capability?.id;
        this.props.deleteDemoCapability(capabilityId);
    }

    handleOneTimeChange = (value, record) => {
        this.props.editDemoCapability({
            ...record,
            isOneTime: value
        });
    }

    render() {
        const { demoCapability } = this.props;

        let columns = [
            {
                title: 'Title',
                key: 'capability.title',
                dataIndex: 'capability',
                align: 'left',
                width: 150,
                render: capability => capability ? capability?.title : "",
            },
            {
                title: 'Description',
                key: 'capability.description',
                dataIndex: "capability",
                align: 'left',
                width: 250,
                render: capability => capability ? capability?.description : "",
            },
            {
                title: 'Questions',
                key: 'capability.questions',
                dataIndex: "capability",
                align: 'left',
                width: 250,
                render: capability => {
                    return (
                        <div>
                            {capability?.questions?.length > 0 ?
                                capability?.questions?.map((item, index) => {
                                    return <span>{index + 1}. {item?.question?.title}<br /></span>
                                }) : "-"
                            }
                        </div>
                    )
                },
            },
            {
                title: 'One Time',
                key: 'capability.isOneTime',
                dataIndex: "isOneTime",
                align: 'center',
                width: 100,
                editable: true,
                render: (isOneTime, record) => {
                    const { getFieldDecorator } = this.props.form;
                    return (
                        <Form.Item>
                            {getFieldDecorator(`isOneTime${record?.capability?.id}`, {
                                initialValue: isOneTime ?? false,
                                valuePropName: "checked",
                            })(<Switch
                                style={{ width: '40px' }}
                                size="small"
                                onChange={(rest) => this.handleOneTimeChange(rest, record)}
                            />)}
                        </Form.Item>
                    )
                }
            },
            {
                title: 'Action',
                key: 'action',
                width: 100,
                render: (option, record) => {
                    const { userRoleTemplate, allCapabilities } = this.props;
                    const hasFullAccess = allCapabilities && allCapabilities.find(capability => capability.id === record?.capability?.id) !== undefined;
                    const isDisabled = userRoleTemplate && userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin && !hasFullAccess
                    return (
                        <div>
                            <Popconfirm title="Confirm Delete?"
                                okText="Yes" cancelText="No"
                                onConfirm={() => {
                                    this.handleDelete(record)
                                }}>
                                <Tooltip title={isDisabled ? CONFIG.warningMessages.noAccess : undefined} key={Math.random()}>
                                    <button aria-label="Click" disabled={isDisabled} className={!isDisabled ? "link" : "link-disabled"}> <span class="neo-icon-trash" title="Delete" style={{ fontSize: "20px" }}></span></button>
                                </Tooltip>
                            </Popconfirm>
                        </div >
                    )
                },
                align: 'center'
            }
        ]
        return (
            <React.Fragment>
                {this.renderCapabilitySelect()}
                <Table
                    size='middle'
                    className="responsive-container"
                    rowKey={(record) => record.capability.id}
                    bordered
                    dataSource={demoCapability}
                    columns={columns}
                    pagination={false}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ capabilityDemos, viewport, user, openTabDemoDetails }) => {
    return {
        viewport,
        demoCapability: openTabDemoDetails?.currentTabData?.demoCapability,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        demo: openTabDemoDetails?.currentTabData?.demoBasicDetails?.demo,
        timeZoneName: user.profile?.timezone,
        allCapabilities: capabilityDemos?.allCapabilities
    }
}

const EditableDemoCapabilityTable = Form.create()(DemoCapability);

export default connect(mapStateToProps, {
    addDemoTabCapability,
    addNewDemoCapability,
    deleteDemoCapability,
    editDemoCapability
})(EditableDemoCapabilityTable);