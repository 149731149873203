import React from 'react';
import { connect, batch } from 'react-redux';
import { Tabs, Spin, Row, Dropdown, Form, Tooltip, message } from 'antd';
import moment from 'moment-timezone';
import { Icon as NeoIcon, Button as NeoButton, Table as NeoTable, Chip, BasicModal } from "neo-latest"
import { isTabAlreadyOpen } from "../common/TabLayout";
import {
    fetchDemos,
    fetchBuildsSchedulesLite,
    toggleIsFetchingDemoSchedules,
    selectTitle,
    toggleBackButton,
    clearManualBuildsSchedules,
    fetchScheduleComments,
    addScheduleComment,
    updateScheduleComment,
    deleteScheduleComment,
    updateScheduleEndDate,
    tearDownSchedule,
    reinitiateDemoBuild,
    closeDemoSchedule,
    updateBuildsCurrentPage,
    fetchUsersForDemoDataCenter,
    toggleIsFetchingUserDataCenterStatus,
    updateManualDemoBuild,
    getAllBuilds,
    exportTable,
    currentBuildsFilter,
    checkIfBuidExists,
    toggleHasUnsavedChangesFlag
} from '../../actions';
import CONFIG from '../../config';
import pushMessage from '../common/PushMessage';
import { fetchNamesBySearchString } from '../../actions/userRole';
import FilterDrawer from '../common/FilterDrawer';
import EditDrawer from '../common/EditDrawer';
import { exportMenu } from "../../utils/strings";
import { checkIfFilterSortIsEmpty } from '../../utils/formChanged';
import history from '../../history';
import throttle from 'lodash/throttle';
import { LoadingOutlined } from '@ant-design/icons';
import { otherFields } from '../../utils/neoTableFields';
import ManualDemoBuildsDetails from './ManualDemoBuildsDetails';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { TabPane } = Tabs;
export const ManualDemoBuildsContext = React.createContext();


const dataIndexMap = {
    demo: "demo.title",
    startTime: "startTime",
    endTime: "endTime",
    applyToAllCheck: 'includeStartAndEndDate',
    demoType: 'demo.demoType',
    createdBy: "createdBy.fullName",
    buildStatus: 'buildStatus',
    assignedUser: 'assignedUser.fullName',
    ticketNo: 'ticketNo',
    region: 'region',
    envName: 'envName',
    demoBuildId: 'demoBuildId',
    referenceNo: 'referenceNo',
    customerName: 'purposeAttributesSchedule.customerName'
}

class ManageManualDemoBuilds extends React.Component {

    constructor(props) {
        super(props);
        this.handleClear = throttle(this.handleClear, 1000);
    }

    state = {
        matchedUserNames: [],
        schedulesData: [],
        loading: false,
        isReturnedFromDelete: false,
        submitting: false,
        comment: "",
        endDateTime: undefined,
        selectedSchedule: undefined,
        isEditing: false,
        editingKey: "",
        status: "In-Progress,Available,Expired,Canceled,Assigned,New",
        showFilter: false,
        height: `${document.body.clientHeight - 175}px`,
        drawerHeight: `${document.body.clientHeight - 100}px`,
        showEdit: false,
        isReadOnlyEdit: false,
        record: null,
        formLoading: false,
        statusModal: false,
        isEditDisabled: false,
        currentPage: 0,
    };

    scheduleId = undefined;
    filterSort = {};
    filterSave = {};
    flag = 0;
    tableRef = React.createRef();

    isEditing = record => record.id === this.state.editingKey;


    getUTCTime = (time) => {
        const { timeZoneName } = this.props;
        return time.tz(timeZoneName, true).toISOString();
    }

    clearCurrentSchedules = async () => {
        this.setState({ schedulesData: [], currentPage: 0 })
    }

    handleFilter = async (filters) => {

        let { demo, startEndTime, applyToAllCheck, demoType, createdBy, buildStatus, assignedUser, ticketNo, region, envName, demoBuildId, customerName, referenceNo } = filters;
        const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isDemoTypeEmpty = !demoType || (demoType && demoType.length === 0);
        const isBuildStatusEmpty = !buildStatus || (buildStatus && buildStatus.length === 0);

        createdBy = createdBy?.trim();
        assignedUser = assignedUser?.trim();
        ticketNo = ticketNo?.trim();
        region = region?.trim();
        envName = envName?.trim();
        demoBuildId = demoBuildId?.trim();
        customerName = customerName?.trim();
        referenceNo = referenceNo?.trim();

        const formEmpty = isBuildStatusEmpty && isStartEndTimeEmpty && isDemoEmpty && isDemoTypeEmpty && !createdBy && !assignedUser && !ticketNo && !region && !envName && !demoBuildId && !customerName && !referenceNo;

        if (formEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to filter`)
            return;
        }
        this.setState({ matchedUserNames: [] })
        await this.clearCurrentSchedules()

        this.filterSort = {
            ...this.filterSort,
            filter: {
                [dataIndexMap.demo]: !isDemoEmpty ? demo.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.demoType]: demoType && demoType.length === 1 ? demoType[0] : undefined,
                [dataIndexMap.startTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0]) : undefined,
                [dataIndexMap.endTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1]) : undefined,
                [dataIndexMap.applyToAllCheck]: applyToAllCheck || undefined,
                [dataIndexMap.createdBy]: createdBy || undefined,
                [dataIndexMap.buildStatus]: !isBuildStatusEmpty ? buildStatus.join(CONFIG.delimiters.selectFilter) : undefined,
                [dataIndexMap.assignedUser]: assignedUser || undefined,
                [dataIndexMap.ticketNo]: ticketNo || undefined,
                [dataIndexMap.region]: region || undefined,
                [dataIndexMap.envName]: envName || undefined,
                [dataIndexMap.demoBuildId]: demoBuildId || undefined,
                [dataIndexMap.customerName]: customerName || undefined,
                [dataIndexMap.referenceNo]: referenceNo || undefined
            }
        }

        this.setState({ currentPage: 0 })

        batch(() => {
            this.handleFetchSchedules()

        })

    }

    fetchUsersName = searchString => {
        this.setState({ matchedUserNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUserNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    handleClear = async () => {
        await this.clearCurrentSchedules()
        this.filterSort = { ...this.filterSort, filter: undefined };
        this.setState({ matchedUserNames: [], currentPage: 0 })
        this.handleFetchSchedules();
    }

    handleExport = async (fileType) => {
        const { timeZoneName } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const manualBuilds = await getAllBuilds({ filterSort: this.filterSort, buildStatus: this.state.status, isManual: true });
        if (manualBuilds?.length > 0 && manualBuilds !== undefined) {
            exportTable(CONFIG.exportTable.manualBuilds.name, manualBuilds, timeZoneName, CONFIG.exportTable.manualBuilds.fileName + `.${fileType}`, fileType)
                .then((response) => {
                    message.destroy()
                    const isExport = response.status === CONFIG.HTTP_STATUS.OK
                    pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
                })
                .catch(() => {
                    message.destroy()
                    pushMessage(CONFIG.messageType.error, "Unable to export table")
                })
        }
        else {
            message.destroy()
            pushMessage(CONFIG.messageType.warning, "There is no data to export. Please verify the filters")
        }
    }

    removeDuplicateRecordsIfAny = () => {
        let schedulesData = this.state.schedulesData
        schedulesData = this.props.schedules ? schedulesData.concat(this.props.schedules) : []
        return schedulesData?.filter((value, index) => schedulesData.indexOf(value) === index)
    }

    handleFetchSchedules = async () => {
        this.setState({ loading: true });
        await this.props.fetchBuildsSchedulesLite({ filterSort: this.filterSort, isManual: true, buildStatus: this.state.status })
            .then(() => {
                this.setState(({ schedulesData }) => ({
                    loading: false,
                    schedulesData: this.removeDuplicateRecordsIfAny(),
                }))
            })
    }

    handleSelectChange = async (value) => {
        await this.clearCurrentSchedules()
        this.setState({ status: value }, () => { this.handleFetchSchedules() })
    }

    fetchSchedulesOnMount = async () => {
        this.setState({ loading: true })
        await this.props.fetchBuildsSchedulesLite({
            filterSort: this.filterSort,
            isManual: true,
            buildStatus: this.state.status
        })

        this.setState(({ schedulesData }) => ({
            loading: false,
            schedulesData: this.removeDuplicateRecordsIfAny(),
        }))
    }

    componentDidUpdate = () => {
        const { openedTabs, activeKey } = this.props;
        const formChanged = this.state.editingKey !== "" && this.state.editingKey !== undefined;
        const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);
        else if (hasUnsavedChanges && !formChanged)
            this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, formChanged);

        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );

        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    }

    handleDatesSortForNeoTable = (event) => {
        const targetElement = event.target;
        const sortingDateChild = targetElement.querySelector('#sorting-date');
        const arrowChild = ((targetElement.classList?.contains("neo-icon--small") || (targetElement.classList?.contains("neo-icon-arrow-down")) ||
            (targetElement.classList?.contains("neo-icon-arrow-up"))) && ((targetElement?.offsetParent?.firstChild?.innerHTML?.includes("sorting-date")) || (targetElement?.offsetParent?.lastChild?.innerHTML?.includes("sorting-date"))));
        if (sortingDateChild || arrowChild) {
            setTimeout(() => {
                const myElement = document.querySelector('.neo-dropdown__content');
                if (myElement) {
                    myElement.classList.add('sorting-classes');
                    const parentItem = document.querySelector(".sorting-classes");
                    const children = parentItem.children;
                    children[0].textContent = 'Clear Sort';
                    children[1].textContent = 'Older to Newer';
                    children[2].textContent = 'Newer to Older';
                }
            }, 10)
        }
    }

    cleanup = () => {
        const dropdownLinks = document.querySelectorAll(
            '.neo-btn.neo-btn--default.neo-btn-tertiary.neo-btn-tertiary--default.neo-dropdown__link-header.neo-multiselect'
        );
        if (dropdownLinks?.length > 0) {
            dropdownLinks.forEach((link) => {
                link.addEventListener('click', this.handleDatesSortForNeoTable);
            });
        }
    };

    async componentDidMount() {
        //document.getElementById('container').scrollIntoView();
        const { currentStatus, isEdit, currentFilter, currentPageNumber } = this.props;
        this.setState({ loading: true, currentPath: window.location.pathname })
        if (isEdit) {
            this.filterSort = currentFilter;
            if (this.filterSort?.filter !== undefined) this.setState({ showFilter: true })
            await this.setState({ status: currentStatus, currentPage: currentPageNumber })
        }
        else {
            this.filterSort = {}
        }
        batch(() => {
            this.fetchSchedulesOnMount();
            this.props.toggleIsFetchingDemoSchedules(true);
            this.props.fetchDemos({ getAll: true, demoType: CONFIG.demoTypes.labPocDemo });
        });
    }

    async componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            await this.props.currentBuildsFilter(this.filterSort, this.state.status, true)
            // This will ensure that we do not increment the pageNumber on Tab Switch.
            await this.props.updateBuildsCurrentPage(this.state.currentPage, (this.state.currentPage * 12), 0, 0, true)
        }
        else {
            this.props.clearManualBuildsSchedules();
        }
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
        this.cleanup();
    }

    handleChange = e => {
        this.setState({
            comment: e.target.value,
        });
    };

    handleEndDateSubmit = async () => {
        let { endDateTime, record } = this.state;
        const { timeZoneName } = this.props;
        const { startTime } = record;
        const startValue = moment.tz(startTime, timeZoneName)
        const currentValue = moment.tz(moment(), timeZoneName)

        if (!endDateTime) return;

        if (endDateTime && (endDateTime.valueOf() < startValue.valueOf() || endDateTime.valueOf() < currentValue.valueOf())) {
            pushMessage(CONFIG.messageType.warning, 'End time cannot be less than start time / current time');
            return;
        }

        const endTime = endDateTime.tz(timeZoneName, true).toISOString();
        record = {
            ...record,
            endTime: endTime,
            endTimeBeforeExtend: record.endTimeBeforeExtend !== null ? record.endTimeBeforeExtend : record.endTime
        }

        let schedulesData = this.state.schedulesData.map(item =>
            item.id === record.id
                ? { ...item, endTime }
                : item
        )

        await this.props.updateScheduleEndDate(endTime, record.id)
            .then(() => {
                this.setState({ record, isEditing: false, schedulesData });
                this.handleFetchSchedules();
            });
    };

    handleEndDateChange = value => {
        this.setState({
            endDateTime: value,
        });
    };

    setIsEditing = (flag) => {
        this.setState({ isEditing: flag })
    }


    handleFetchSchedulesOnDelete = async () => {
        this.clearCurrentSchedules()
        const pageSize = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) + CONFIG.lazyLoadPageSize);
        const currentPageNumber = ((Math.ceil(this.recordIndex / CONFIG.lazyLoadPageSize) * CONFIG.lazyLoadPageSize) / CONFIG.lazyLoadPageSize) + 1;
        await this.props.updateBuildsCurrentPage(currentPageNumber, pageSize, this.scheduleId, this.recordIndex, true)
        await this.setState({ isReturnedFromDelete: true, deletedRecordIndex: this.recordIndex })
        this.setState({ currentPage: 0 })
        this.fetchSchedulesOnMount();
    }

    handleEdit = async (record) => {
        this.props.toggleIsFetchingUserDataCenterStatus(true)
        this.setState({ editingKey: record.id, endDateTime: moment.tz(record.endTime, this.props.timeZoneName) })
        await this.props.fetchUsersForDemoDataCenter(record)
    }

    handleCommentEdit = (commentId, initialValue) => {
        this.setState({ editingKey: commentId, comment: initialValue })
    }

    handleCommentDelete = (commentId, scheduleId) => {
        this.props.deleteScheduleComment(commentId, scheduleId)
    }

    handleCommentCancel = () => {
        this.setState({ editingKey: "", comment: "" })
    }


    handleSave = () => {
        const record = this.state.record;
        this.props.form.validateFields(async (error, values) => {
            if (error) {
                pushMessage(CONFIG.messageType.warning, 'Certain form fields require your attention. Please review.');
                return
            }
            else if (values.buildStatus === "Closed" && values.demoBuildId) {
                pushMessage(CONFIG.messageType.warning, 'Record with a build id should be marked as Torn Down.');
                return
            }
            else if (values.buildStatus === "Torn Down" && !values.demoBuildId) {
                pushMessage(CONFIG.messageType.warning, 'Record without a build id should be marked as Closed.');
                return
            }
            else {
                let data = {
                    ...record,
                    assignedUser: JSON.parse(values.assignedUser),
                    demoBuildId: values.demoBuildId,
                    buildStatus: values.buildStatus,
                    ticketNo: values.ticketNo,
                    region: values.region,
                    envName: values.envName
                }
                this.setState({ editingKey: "" })
                if (values?.buildStatus === "Available") {
                    const response = await this.props.checkIfBuidExists(values?.demoBuildId)
                    if (response) history.replace({ pathname: `/manage/${"manual-build"}/email`, data: data, emailType: CONFIG.emailTemplateEventType.manualBuildAvailable })
                    else {
                        pushMessage(CONFIG.messageType.warning, "Build Id already exists")
                        return
                    }
                }
                else {
                    const isUpdated = await this.props.updateManualDemoBuild(data);
                    if (isUpdated) {
                        this.scheduleId = record.id;
                        this.handleFetchSchedulesOnDelete()
                    }
                }
                this.setState({
                    showEdit: false, record: null, visible: false,
                    comment: '',
                    editingKey: "",
                    isEditDisabled: false
                })
            }
        });
    }

    handleRefresh = async () => {
        await this.clearCurrentSchedules()
        this.handleFetchSchedules()
    }

    renderFilterPanel = () => {
        return (
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <div style={{ display: "flex", width: "100%", flex: "1", flexDirection: "row-reverse", gap: "24px" }}>
                    <div >
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoButton className="export-download-button" icon="chevron-down" variant="secondary">Download</NeoButton>
                        </Dropdown>
                    </div>
                    <div >
                        <NeoIcon size="md" className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={(this.state.showFilter || (!checkIfFilterSortIsEmpty(this.filterSort))) ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                    <div >
                        <NeoIcon size="md" className="clickable" title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </div>
                </div>
            </div>
        )
    }

    handeEditRecord = async (record, isEdit, isEditDisabled) => {
        this.setState({
            showEdit: true,
            isReadOnlyEdit: isEdit,
            record,
            loading: true,
            formLoading: true,
            isEditDisabled,
            endDateTime: moment.tz(record.endTime, this.props.timeZoneName)
        })
        if (!isEdit) {
            this.props.toggleIsFetchingUserDataCenterStatus(true)
            this.setState({ editingKey: record.id })
            await this.props.fetchUsersForDemoDataCenter(record);
        }
        this.setState({ loading: false, formLoading: false })
    }

    handleEditClick = async () => {
        const record = this.state.record;
        this.setState({
            showEdit: true,
            isReadOnlyEdit: false,
            loading: true,
            formLoading: true,
            endDateTime: moment.tz(record.endTime, this.props.timeZoneName)
        })
        this.props.toggleIsFetchingUserDataCenterStatus(true)
        this.setState({ editingKey: record.id })
        await this.props.fetchUsersForDemoDataCenter(record)
        this.setState({ loading: false, formLoading: false })
    }

    handleChangeSortName = () => {
        setTimeout(() => {
            const myElement = document.querySelector('.neo-dropdown__content');
            if (myElement) {
                myElement.classList.add('sorting-classes');
                const parentItem = document.querySelector(".sorting-classes");
                const children = parentItem.children;
                children[0].textContent = 'Clear Sort';
                children[1].textContent = 'Older to Newer';
                children[2].textContent = 'Newer to Older';
            }
        }, 10)
    }

    renderTable = () => {
        const { timeZoneName } = this.props;
        const neoColumns = [
            {
                Cell: (a) => {
                    const record = a?.cell?.row?.original;
                    const { user } = this.props;
                    const isDisabled = record.buildStatus === ("Torn Down" || record.buildStatus === "Closed") || (((record?.assignedUser?.id !== user.id) && (user.precedenceTemplateType !== CONFIG.roleTypes.superAdmin)) && record.buildStatus !== "New")
                    return <span onClick={() => this.handeEditRecord(a?.cell?.row?.original, true, isDisabled)} style={{ textAlign: "center", color: "#1B77AF", cursor: "pointer" }}>{a?.cell?.row?.original?.referenceNo}</span>
                },
                Header: "Request No.",
                sortType: "string",
                accessor: "referenceNo",
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.startTime ? moment.tz(a?.cell?.row?.original?.startTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}</span>
                },
                Header: <span onClick={this.handleChangeSortName} id='sorting-date'>Start Date</span>,
                accessor: "startTime",
                sortType: (rowA, rowB) => {
                    const startTimeA = moment.tz(rowA?.original?.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const startTimeB = moment.tz(rowB?.original?.startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (startTimeA.isBefore(startTimeB)) {
                        return -1;
                    }
                    if (startTimeA.isAfter(startTimeB)) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                Cell: (a) => {
                    const record = a?.cell?.row?.original;
                    return <span style={{ textAlign: "center" }}>
                        {record?.endTime ? moment.tz(record?.endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"}
                        {record.endTimeBeforeExtend && <>
                            &nbsp;&nbsp;
                            <Tooltip title={<div>The schedule was extended by {moment.tz(record.endTimeBeforeExtend, timeZoneName).to(moment.tz(record?.endTime, timeZoneName), true)} from {moment.tz(record?.endTimeBeforeExtend, timeZoneName).format(CONFIG.dateFormats.userDateTime)} to {moment.tz(record?.endTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</div>}>
                                <Chip>Extended</Chip>
                            </Tooltip>
                        </>
                        }

                    </span>
                },
                Header: <span onClick={this.handleChangeSortName} id='sorting-date'>End Date</span>,
                accessor: "endTime",
                sortType: (rowA, rowB) => {
                    const endTimeA = moment.tz(rowA?.original?.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    const endTimeB = moment.tz(rowB?.original?.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ', timeZoneName);
                    if (endTimeA.isBefore(endTimeB)) {
                        return -1;
                    }
                    if (endTimeA.isAfter(endTimeB)) {
                        return 1;
                    }
                    return 0;
                }
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.demo?.title || "-"}</span>
                },
                Header: "Demo",
                accessor: "demo.title",
                sortType: "string"
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>
                        <Chip variant={CONFIG.demoBuildStatusChips[a?.cell?.row?.original?.buildStatus]} style={{ textAlign: "center" }}>{a?.cell?.row?.original?.buildStatus}</Chip>
                    </span>
                },
                Header: <span>Status   <NeoIcon size="md" style={{ cursor: "pointer", color: "#1B77AF" }} icon="info"
                    onClick={() => this.setState({ statusModal: true })}
                /> </span>,
                sortType: "basic",
                accessor: "buildStatus",
            },
            {
                Cell: (a) => {
                    const record = a.cell.row.original;
                    const { user } = this.props;
                    const isDisabled = ((record.buildStatus === "Torn Down") || (record.buildStatus === "Closed") || (record?.buildStatus ? record?.buildStatus?.toLowerCase()?.includes("canceled") : false)) || (((record?.assignedUser?.id !== user.id) && (user.precedenceTemplateType !== CONFIG.roleTypes.superAdmin)) && record.buildStatus !== "New")
                    return (
                        <React.Fragment>
                            <div style={{ display: "flex", gap: "24px" }}>
                                {this.state.status === "In-Progress,Available,Expired,Canceled,Assigned,New" && <div>
                                    <Tooltip title="Edit">
                                        <button
                                            onClick={() => this.handeEditRecord(record, false, isDisabled)}
                                            disabled={isDisabled}
                                            className={isDisabled ? "link-disabled" : 'link'}
                                        >
                                            <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </div>}
                                <div>
                                    <Tooltip title="View Details">
                                        <button
                                            onClick={() => this.handeEditRecord(record, true, isDisabled)}
                                            className='link'
                                        >
                                            <span class="neo-icon-list-bullet" title="View Details" style={{ fontSize: "20px" }}></span>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                },
                Header: "  ",
                disableFilters: true,
                disableSortBy: true
            }
        ]
        return (
            this.state.loading ? null : <NeoTable
                columns={neoColumns}
                data={this.props.schedules || []}
                className="table-actions"
                allowColumnFilter={false}
                itemsPerPageOptions={[10, 20, 50, 100]}
                showRowSeparator
                initialStatePageIndex={this.state.currentPage}
                handlePageChange={(newPageIndex, newPageSize) => {
                    if (newPageIndex >= 0) this.setState({ currentPage: newPageIndex })
                }}
                {...otherFields}
            />
        )
    }

    renderTabContents = () => {
        return (
            <React.Fragment>
                <div>
                    {this.renderFilterPanel()}
                </div>
                <div style={{ minHeight: "250px" }}>
                    {this.renderTable()}
                </div>
            </React.Fragment>
        )
    }


    render() {
        const { demos, comments } = this.props;
        const { comment, submitting } = this.state;
        const statusColumns = [
            {
                Cell: (a) => {
                    return <Chip variant={a?.cell?.row?.original?.variant} style={{ textAlign: "center" }}>{a?.cell?.row?.original?.text}</Chip>
                },
                Header: "Information",
                accessor: "text",
                disableFilters: true,
                disableSortBy: true
            },
            {
                Cell: (a) => {
                    return <span style={{ textAlign: "center" }}>{a?.cell?.row?.original?.desc || "-"}</span>
                },
                Header: "Chip Type",
                accessor: "desc",
                disableFilters: true,
                disableSortBy: true
            }
        ]

        const formFields = [
            {
                label: "Demo",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: (demos) ? demos.map(demo => ({ text: demo.title })) : [],
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.demo]
            },
            {
                label: "Start / End Date",
                type: CONFIG.formFieldTypes.RANGE_PICKER,
                key: "startEndTime",
                colSpan: 24,
                value: [this.filterSort?.filter?.[dataIndexMap.startTime], this.filterSort?.filter?.[dataIndexMap.endTime], this.filterSort?.filter?.[dataIndexMap.applyToAllCheck]],
            },
            {
                label: "Created By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "createdBy",
                data: this.state.matchedUserNames,
                colSpan: 24,
                value: this.filterSort?.filter?.[dataIndexMap.createdBy]
            },
            {
                label: "Demo Type",
                type: CONFIG.formFieldTypes.SELECT,
                key: "demoType",
                data: CONFIG.filterDataSet.demoTypes,
                value: this.filterSort.filter?.[dataIndexMap.demoType],
                colSpan: 24,
            },
            {
                label: "Build Status",
                type: CONFIG.formFieldTypes.SELECT,
                key: "buildStatus",
                data: CONFIG.filterDataSet.manualDemoBuildStatus,
                value: this.filterSort.filter?.[dataIndexMap.buildStatus],
                colSpan: 24,
            },
            {
                label: "Assigned User",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "assignedUser",
                data: this.state.matchedUserNames,
                value: this.filterSort?.filter?.assignedUser,
                colSpan: 24,

            },
            {
                label: "Ticket No.",
                type: CONFIG.formFieldTypes.INPUT,
                key: "ticketNo",
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.ticketNo]
            },
            {
                label: "Region",
                type: CONFIG.formFieldTypes.INPUT,
                key: "region",
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.region]
            },
            {
                label: "Env. Name",
                type: CONFIG.formFieldTypes.INPUT,
                key: "envName",
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.envName]
            },
            {
                label: "Request No.",
                type: CONFIG.formFieldTypes.INPUT,
                key: "referenceNo",
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.referenceNo]
            },
            {
                label: "Customer Name",
                type: CONFIG.formFieldTypes.INPUT,
                key: "customerName",
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.customerName]
            },
            {
                label: "Build Id",
                type: CONFIG.formFieldTypes.INPUT,
                key: "demoBuildId",
                colSpan: 24,
                value: this.filterSort.filter?.[dataIndexMap.demoBuildId]
            },
        ]
        const drawerContainer = document.getElementById('#component-content');

        return (
            <React.Fragment>
                <div style={{ color: "#242424", fontSize: "19px", fontWeight: "400", lineHeight: "28px", marginTop: "28px" }}>
                    Manual Builds
                </div>
                <Spin spinning={this.state.loading} indicator={antIcon}>
                    <div style={{ marginTop: "16px" }}>
                        <Tabs activeKey={this.state.status} className='demo-status-tabs' defaultActiveKey={this.state.status} onChange={this.handleSelectChange}>
                            <TabPane tab="Active Builds" key="In-Progress,Available,Expired,Canceled,Assigned,New">
                                {this.renderTabContents()}
                            </TabPane>
                            <TabPane tab="Torn Down and Closed Builds" key="Torn Down,Closed">
                                {this.renderTabContents()}
                            </TabPane>
                        </Tabs>
                    </div>
                </Spin>
                <Row>
                    {this.state.showFilter && (
                        <FilterDrawer
                            visible={this.state.showFilter}
                            width='520'
                            handleCloseFilterDrawer={() => this.setState(prevState => ({
                                showFilter: false,
                                record: null
                            }))}
                            placement="right"
                            drawerContainer={drawerContainer}
                            style={{ zIndex: 9999, }}
                            maskClosable={false}
                            panelHeader="Filters and Export"
                            formFields={formFields}
                            handleClear={this.handleClear}
                            timeZone={this.props.timeZoneName}
                            hideSoftDeleteCheckbox={true}
                            hideSave={true}
                            handleFilter={this.handleFilter}
                            getSearchData={this.fetchUsersName}
                        />)}

                    {
                        this.state.showEdit && <EditDrawer
                            visible={this.state.showEdit}
                            width='520'
                            handleCloseFilterDrawer={() => this.setState({
                                showEdit: false, record: null, visible: false,
                                comment: '',
                                editingKey: "",
                                isEditDisabled: false
                            })}
                            placement="right"
                            drawerContainer={drawerContainer}
                            style={{ zIndex: 9999, }}
                            maskClosable={false}
                            timeZone={this.props.timeZoneName}
                            readOnly={this.state.isReadOnlyEdit}
                            form={this.props.form}
                            handleEditClick={this.handleEditClick}
                            handleSave={this.handleSave}
                            record={this.state.record}
                            isEditDisabled={this.state.isEditDisabled}
                            showEdit={this.state.status === "In-Progress,Available,Expired,Canceled,Assigned,New"}
                            filterSort={this.filterSort}
                            title={`${(!this.state.isReadOnlyEdit) ? "Edit" : ""} Build Details`}
                        >
                            <Spin spinning={this.state.formLoading} indicator={antIcon}>
                                <ManualDemoBuildsDetails
                                    form={this.props.form}
                                    timezone={this.props.timeZoneName}
                                    status={this.state.status}
                                    record={this.state.record}
                                    readOnly={this.state.isReadOnlyEdit}
                                    comments={comments}
                                    comment={comment}
                                    handleChange={this.handleChange}
                                    submitting={submitting}
                                    handleEndDateChange={this.handleEndDateChange}
                                    handleEndDateSubmit={this.handleEndDateSubmit}
                                    endDateTime={this.state.endDateTime}
                                    setIsEditing={this.setIsEditing}
                                    isEditing={this.state.isEditing}
                                    loggedInUser={this.props.user}
                                    handleCommentEdit={this.handleCommentEdit}
                                    handleCommentDelete={this.handleCommentDelete}
                                    editingKey={this.state.editingKey}
                                    handleCommentCancel={this.handleCommentCancel}
                                />
                            </Spin>
                        </EditDrawer>
                    }

                    {
                        this.state.statusModal && <BasicModal
                            open={(this.state.statusModal)}
                            title="Status Info"
                            className="neo-modal confirmation-modal-neo-2 min-height-modal"
                            onClose={() => this.setState({ statusModal: false })}
                        >
                            <NeoTable
                                columns={statusColumns}
                                data={CONFIG.filterDataSet.manualDemoBuildStatus}
                                className="table-actions"
                                allowColumnFilter={false}
                                showPagination={false}
                                showRowSeparator
                                {...otherFields}
                            />
                        </BasicModal>
                    }
                </Row>
            </React.Fragment>
        );
    }
}
const mapStateToProps = ({ demoSchedulesManual, user, demos, dataCenterStatus, tabsLayout }) => {
    return {
        schedules: demoSchedulesManual.tearDownData,
        count: demoSchedulesManual.tearDownCount,
        isFetching: demoSchedulesManual.isFetching,
        user: user.profile,
        users: dataCenterStatus.users,
        timeZoneName: user.profile?.timezone,
        demos: demos.allDemos,
        comments: demoSchedulesManual.comments,
        currentPageNumber: demoSchedulesManual.currentPageNumber,
        pageSize: demoSchedulesManual.pageSize,
        scheduleIndex: demoSchedulesManual.demoIndex,
        currentscheduleId: demoSchedulesManual.currentscheduleId,
        isFetchingUser: dataCenterStatus.isFetchingUser,
        openedTabs: tabsLayout.panes,
        activeKey: tabsLayout.activeKey,
        demoSchedulesManual,
        currentStatus: demoSchedulesManual?.status,
        isEdit: demoSchedulesManual?.isEdit,
        currentFilter: demoSchedulesManual?.currentFilter,
    };
};

const EditableFormManualDemoBuilds = Form.create()(ManageManualDemoBuilds);


export default connect(
    mapStateToProps,
    {
        fetchBuildsSchedulesLite,
        toggleIsFetchingDemoSchedules,
        selectTitle,
        toggleBackButton,
        clearManualBuildsSchedules,
        fetchDemos,
        fetchScheduleComments,
        addScheduleComment,
        deleteScheduleComment,
        updateScheduleComment,
        updateScheduleEndDate,
        tearDownSchedule,
        reinitiateDemoBuild,
        closeDemoSchedule,
        updateBuildsCurrentPage,
        fetchUsersForDemoDataCenter,
        toggleIsFetchingUserDataCenterStatus,
        updateManualDemoBuild,
        checkIfBuidExists,
        getAllBuilds,
        exportTable,
        currentBuildsFilter,
        toggleHasUnsavedChangesFlag
    }
)(EditableFormManualDemoBuilds);
