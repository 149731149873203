import {
    TOGGLE_IS_FETCHING_DAILY_ATTRIBUTES,
    GET_ALL_DAILY_ATTRIBUTES,
    ADD_NEW_DEMO_ATTRIBUTE,
    DELETE_DEMO_ATTRIBUTE,
    CLEAR_ATTRIBUTE_FORM,
    GET_EACH_DAILY_ATTRIBUTE,
    CLEAR_DAILY_ATTRIBUTE,
    GET_ALL_PRODUCTS_FOR_ATTRIBUTES,
    LAST_ATTRIBUTE_FILTERS
} from "./types";

import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { aepReport } from '../apis';

export const toggleIsFetchingDailyAttributes = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DAILY_ATTRIBUTES,
        payload: flag
    }
}


export const getAllDailyAttributes = (viewDeletedAttributes = false) => async (dispatch) => {
    const contextPath = `/reportSearchService/allAttributes?viewDeletedAttributes=${viewDeletedAttributes}`;
    const response = await aepReport.get(contextPath);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: GET_ALL_DAILY_ATTRIBUTES, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch attributes');
    dispatch(toggleIsFetchingDailyAttributes(false));
}

export const addNewDemoAttribute = (demo) => {
    return {
        type: ADD_NEW_DEMO_ATTRIBUTE,
        payload: { demo }
    };
};

export const deleteDemoAttribute = demoId => {
    return {
        type: DELETE_DEMO_ATTRIBUTE,
        payload: demoId
    };
};

export const postDailyReportAttribute = (id , data) => async (dispatch) => {
    const response = await aepReport({
        url: !id  ? `/reportSearchService/attribute` : `/reportSearchService/attribute/${id}`,
        method: !id  ? "POST" : "PUT",
        data
    });
    if (response.status === CONFIG.HTTP_STATUS.CREATED || response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Attribute ${!id  ? "created" : "updated"} successfully`)
        return true;
    } else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, `Attribute name already exist please check existing/deleted attributes!`);
    }
    else {
        pushMessage(CONFIG.messageType.error, `Unable to ${!id  ? "add" : "update"} attribute`);
        return false;
    }
}

export const clearAttributeForm = () => {
    return { type: CLEAR_ATTRIBUTE_FORM }
}


export const getDailyAttributeById = (id) => async (dispatch) => {
    dispatch(toggleIsFetchingDailyAttributes(true));
    const contextPath = `/reportSearchService/attribute/${id}`;
    const response = await aepReport.get(contextPath);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: GET_EACH_DAILY_ATTRIBUTE, payload: response.data || [] });
        const demos = response.data && response.data?.productAttributes;
        if (demos?.length > 0) {
            demos.forEach(eachDemo => {
                dispatch(addNewDemoAttribute(eachDemo?.product))
            })
        }
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch attributes');
    dispatch(toggleIsFetchingDailyAttributes(false));
}

export const deleteDailyReportAttribute = (id, hardDelete = false) => async (dispatch) => {
    dispatch(toggleIsFetchingDailyAttributes(true));
    const contextPath = `/reportSearchService/attribute/${id}`
    const response = await aepReport.delete(contextPath, { params: { hardDelete } });
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Attribute deleted successfully`);
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to delete attribute");
    }
    dispatch(toggleIsFetchingDailyAttributes(false));
}

export const restoreDailyAttribute = (id) => async (dispatch) => {
    dispatch(toggleIsFetchingDailyAttributes(true));
    const contextPath = `/reportSearchService/restore/attribute/${id}`
    const response = await aepReport.put(contextPath);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, `Attribute restored successfully`);
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to restore attribute");
    }
    dispatch(toggleIsFetchingDailyAttributes(false));
}


export const clearAttributes = () => {
    return {
        type: CLEAR_DAILY_ATTRIBUTE,
    }
}

export const getProductsForAttributes = (isActive) => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/allProducts", {
        params: {
            filterSort: {
                filter: {
                    isActive
                }
            }
        }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: GET_ALL_PRODUCTS_FOR_ATTRIBUTES,
            payload: response?.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Products');
}

export const currentAttributeFilters = (filter, sortInfo, isViewDeleted, searchString) => {
    return {
        type: LAST_ATTRIBUTE_FILTERS,
        payload: [filter, sortInfo, isViewDeleted,searchString]
    };
};