import { batch } from 'react-redux';
import { aepDemo } from '../apis';
import {
    FETCH_MY_REQUESTS,
    TOGGLE_IS_FETCHING_MY_REQUESTS,
    CLEAR_MY_REQUESTS,
    LAST_MY_REQUESTS_FILTER
} from './types';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';

export const fetchMyRequests = ({ pageNumber = 1, pageSize = CONFIG.pageSize, filterSort }) => async dispatch => {
    const params = {
        pageNumber,
        pageSize,
        filterSort
    };
    const response = await aepDemo.get('/demoSolutionService/myDemos/myRequests', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        batch(() => {
            dispatch({
                type: FETCH_MY_REQUESTS,
                payload: response.data || []
            });
        })
    else
        pushMessage(CONFIG.messageType.error, 'Error occured while fetching requests');
    dispatch(toggleIsFetchingMyRequests(false));
};

export const toggleIsFetchingMyRequests = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_MY_REQUESTS,
        payload: flag
    };
};


export const clearMyRequests = () => {
    return {
        type: CLEAR_MY_REQUESTS,
    };
};

export const currentMyRequestsFilter = (filterSort, status, pageNumber, pageSize) => {
    return {
        type: LAST_MY_REQUESTS_FILTER,
        payload: [filterSort, status, pageNumber, pageSize]
    }
}

export const fetchMyRequestsForExportPrint = ({ filterSort }) => async dispatch => {
    const params = {
        filterSort
    };
    const response = await aepDemo.get('/demoSolutionService/myDemos/myRequests', { params });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT)
        return response?.data?.dataSet || [];
    else {
        pushMessage(CONFIG.messageType.error, 'Error occurred while fetching requests');
    }
};
