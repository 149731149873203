import React from 'react';
import { connect, batch } from 'react-redux';
import { Form, Input, Col, Row, Switch, Select, Button, Icon, Table, Popconfirm, Modal, Tooltip, Spin } from 'antd';
import DragListView from 'react-drag-listview';

import {
  toggleBackButton,
  updateSelectedSolution,
  toggleIsFetchingSolutions,
  reorderSolutionDemos,
  deleteSolutionDemo,
  addSolutionDemo,
  postSolution,
  putSolution,
  updateSolutionRoleTemplate,
  fetchRoleTemplates,
  fetchAdminRoleTemplates,
  cancelSolutionRoleTemplates,
  saveSolutionRoleTemplates,
  clearSolutionForm,
  updateSelectedSolutionType,
  currentSolutionFilter,
  fetchDemos,
  getAllSolutionTypes,
  toggleHasUnsavedChangesFlag,
  closeCurrentOpenTab
} from '../../actions';
import StatusIcon from '../common/StatusIcon';
import FormActionButtons from '../common/FormActionButtons';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import CONFIG from '../../config';
import SolutionTypes from '../common/SolutionTypes';
import pushMessage from '../common/PushMessage';
import { checkRoleTemplateChanges, checkDemoHasChanged } from "../../utils/status";

const { Option } = Select;

class SolutionForm extends React.Component {
  constructor(props) {
    super(props);
    this.isEditMode = this.props.match.params.solutionId !== undefined
    this.state = {
      visible: false,
    }
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const newDemos = that.props.demos;
        const item = newDemos.splice(fromIndex, 1)[0];
        newDemos.splice(toIndex, 0, item);
        that.props.reorderSolutionDemos(newDemos);
      },
      handleSelector: "i"
    };
  }

  renderAssignButton = () => {
    return <Button className="primary-action-button-bordered right-border-radius" id="solutions-roletemplate-assign" onClick={this.showModal}>Assign</Button>;
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleRoleTemplatesCancel = e => {
    this.props.cancelSolutionRoleTemplates();
    this.setState({
      visible: false
    });
  };

  handleRoleTemplatesAssign = () => {
    this.props.saveSolutionRoleTemplates();
    this.setState({ visible: false });
  }

  handleDelete = (demoId) => {
    this.props.deleteSolutionDemo(demoId);
  }

  renderDemoOptions = () => {
    const { allDemos, demos } = this.props;
    const filteredDemos = allDemos?.length > 0 && allDemos?.filter(oldArr =>
      demos?.every(demo => demo?.id !== oldArr?.id));
    if (filteredDemos) {
      return filteredDemos.map((demo, index) => <Option key={index} value={JSON.stringify({ id: demo?.id, title: demo?.title, isActive: demo?.isActive })} title={demo?.title}>{demo?.title}</Option>);
    }
    else {
      return [];
    }
  }

  handleAddDemo = () => {
    const { form } = this.props;
    const newDemo = form.getFieldsValue().demo;
    newDemo.forEach((demo) => {
      this.props.addSolutionDemo(JSON.parse(demo))
    })
    form.setFieldsValue({ demo: undefined });
  }


  handleCreate = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        pushMessage(CONFIG.messageType.warning, "Please verify the fields");
        return;
      } else {
        this.props.toggleIsFetchingSolutions(true)
        this.isEditMode
          ? this.props.putSolution(this.props.match.params.solutionId, values)
          : this.props.postSolution(values);
      }
    });
  }


  componentDidUpdate() {
    const { userRoleTemplate } = this.props;
    const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    const currentSolution = this.getCurrentData(this.props.form.getFieldsValue());
    this.hasChanged = this.checkSolutionFormChanged(currentSolution, isSuperAdminUser);
    const { openedTabs, activeKey } = this.props;
    const hasUnsavedChanges = openedTabs?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
    if (!hasUnsavedChanges && this.hasChanged)
      this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
    else if (hasUnsavedChanges && !this.hasChanged)
      this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, this.hasChanged);
  }

  componentDidMount() {
    const { userRoleTemplate } = this.props;
    const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
    const { solutionId } = this.props.match.params
    const access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;

    batch(() => {
      const fetchAllRoleTemplates = isAdmin ? this.props.fetchAdminRoleTemplates : this.props.fetchRoleTemplates;
      this.props.fetchDemos({ access, getAll: true })
      this.props.getAllSolutionTypes()
        .then(() => {
          fetchAllRoleTemplates()
            .then(() => {
              if (solutionId) {
                this.props.toggleIsFetchingSolutions(true);
                this.props.updateSelectedSolution(solutionId);
              }
            })
        })
    })
  }

  getAssignedRoleTemplates = (roleTemplates, isSuperAdminUser) => {
    let assignedRoleTemplates = [];
    if (roleTemplates) {
      roleTemplates.forEach(obj => {
        const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
        const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
        if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
          assignedRoleTemplates.push(obj.roleTemplate.title);
        }
      })
    }
    return assignedRoleTemplates;
  }

  componentWillUnmount() {
    this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, false);
    this.props.clearSolutionForm();
  }

  getCurrentData = (solutionDetails) => {
    // const { currentSolution, solutionForm } = this.props;
    return {
      title: solutionDetails.title,
      isActive: solutionDetails.isActive,
      roleTemplates: solutionDetails?.roleTemplates,
      solutionType: solutionDetails?.solutionTypes ? JSON.parse(solutionDetails?.solutionTypes) : null,
    }
  }

  checkSolutionFormChanged = (currentSolution, isSuperAdminUser) => {
    const { solutionForm } = this.props;
    const { solutionId } = this.props.match.params;
    let hasChanged = false;
    if (solutionId) {
      hasChanged = currentSolution?.title?.trim() !== solutionForm?.title?.trim()
        || currentSolution?.isActive !== solutionForm?.isActive
        || currentSolution?.solutionType?.id !== solutionForm?.solutionType?.id
        || checkRoleTemplateChanges(solutionForm?.solutionObj?.roleTemplates, solutionForm?.roleTemplates, isSuperAdminUser)
        || checkDemoHasChanged(solutionForm?.solutionObj?.demos, solutionForm?.demos)
    }
    else {
      hasChanged = currentSolution?.title?.trim() !== ''
        || currentSolution?.isActive !== undefined
        || currentSolution?.solutionType?.id !== undefined
        || checkRoleTemplateChanges(solutionForm?.solutionObj?.roleTemplates, solutionForm?.roleTemplates, isSuperAdminUser)
        || checkDemoHasChanged(solutionForm?.solutionObj?.demos, solutionForm?.demos)
    }
    if (hasChanged) return hasChanged;
    return hasChanged;
  }

  render() {
    const { allRoleTemplates, userRoleTemplate, viewport, form, title, isActive, demos, roleTemplates, allRoleTemplatesUserAccess, allRoleTemplatesAdminAccess, allDemos } = this.props;
    const { getFieldDecorator } = this.props.form;
    const isSuperAdminUser = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    const assignedRoleTemplates = this.getAssignedRoleTemplates(roleTemplates, isSuperAdminUser);
    const adminColumns = [
      {
        title: "",
        key: "operate",
        align: 'center',
        render: () => <Icon className="drag-handle" type="drag" />,
        width: '5%'
      }
    ]
    const columns = [
      {
        title: 'Demo',
        dataIndex: 'title',
        key: 'title',
        width: '20%',
        align: 'left'
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        width: '15%',
        render: (status) => <StatusIcon status={status} />,
        align: 'center'
      },
      {
        title: 'Action',
        key: 'action',
        width: '15%',
        render: (record) => {
          const hasFullAccess = allDemos && allDemos.find(demo => demo.id === record.id) !== undefined;
          const isDisabled = userRoleTemplate && userRoleTemplate.templateType !== CONFIG.roleTypes.superAdmin && !hasFullAccess
          return (
            <div>
              <Popconfirm disabled={isDisabled} title="Confirm Delete?" onConfirm={() => this.handleDelete(record.id)} okText="Yes" cancelText="No">
                <Tooltip title={isDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                  <button disabled={isDisabled} className={!isDisabled ? "link" : "link-disabled"}>Delete</button>
                </Tooltip>
              </Popconfirm>
            </div>
          )
        },
        align: 'center'
      },
    ];

    return (
      <div className="content-container">
        <Spin spinning={this.props.isFetching} wrapperClassName="spin-overlay">
          <Form>
            <Row gutter={48} >
              <Col xl={10} sm={10} xs={24}>
                <Form.Item label="Title">
                  {
                    getFieldDecorator("title", {
                      rules: [
                        {
                          required: true,
                          message: "Please input the title of the solution"
                        }
                      ],
                      initialValue: title ? title : ""
                    })(<Input />)
                  }
                </Form.Item>
              </Col>
              <Col xl={10} sm={10} xs={24}>
                <Form.Item label="Assign Role Template">
                  {getFieldDecorator("roletemplate", {
                    initialValue: assignedRoleTemplates.length !== 0
                      ? assignedRoleTemplates
                      : "None"
                  })(<Input disabled addonAfter={this.renderAssignButton()} />)}
                  {<Modal
                    title="Role Template"
                    visible={this.state.visible}
                    onCancel={this.handleRoleTemplatesCancel}
                    footer={<FormActionButtons okText="Assign" handleCancel={this.handleRoleTemplatesCancel} handleSubmit={this.handleRoleTemplatesAssign} />}
                  >
                    <RoleTemplatesTable
                      hideColumns={isSuperAdminUser ? [] : ['admin']}
                      dataKey="roleTemplate"
                      data={allRoleTemplates}
                      pagination={false}
                      assignedData={roleTemplates}
                      allUserAccess={allRoleTemplatesUserAccess}
                      allAdminAccess={allRoleTemplatesAdminAccess}
                      updateRoleTemplateData={this.props.updateSolutionRoleTemplate}
                    />
                  </Modal>
                  }
                </Form.Item>
              </Col>
              <Col xl={4} sm={4} xs={24}>
                <Form.Item label="Status">
                  {getFieldDecorator("isActive", {
                    valuePropName: "checked",
                    initialValue: isActive
                  })(
                    <Switch
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {(
              <Row gutter={48}>
                <Col xl={10} sm={10} sx={24}>
                  {this.props.solutionTypes && <SolutionTypes isMandatory={true} form={form} solutionTypes={this.props.solutionTypes} solutionType={this.props.solutionType} />}
                </Col>
              </Row>
            )

            }
            <Row gutter={24}>
              <Col xl={10} sm={10} xs={24}>
                <Form.Item>
                  {
                    getFieldDecorator('demo')(
                      <Select
                        showSearch={true}
                        mode="multiple"
                        placeholder="Select a demo">
                        {this.renderDemoOptions()}
                      </Select>
                    )
                  }
                </Form.Item>
              </Col>
              <Col xl={4} sm={4} xs={24}>
                <Button id='add-demos' className="primary-action-button-bordered horizontal-spacing antd-button-fix" onClick={this.handleAddDemo} disabled={(!form.getFieldValue('demo') || form.getFieldValue('demo')?.length === 0)}>
                  Add
                  <Icon type="plus-circle" />
                </Button>
              </Col>
            </Row>
          </Form>
          <DragListView {...this.dragProps}>
            <Table
              size='middle'
              className={!viewport.isMobileView ? "vertical-spacing medium-content responsive-container" : "vertical-spacing-2 medium-content responsive-container"}
              columns={isSuperAdminUser ? [...adminColumns, ...columns] : columns}
              dataSource={demos}
              bordered
              pagination={false}
              rowKey={(record) => record.id}
            />
          </DragListView>
          <Row className="right-align">
            <Button onClick={() => this.props.closeCurrentOpenTab()} className={`cancel-button`} id='solutions-discard-changes'>
              {"Discard & Close"}
            </Button>
            <Button disabled={!this.hasChanged}
              className={`left-spacing primary-action-button-filled`}
              onClick={this.handleCreate} id='solutions-save-changes'>
              {this.isEditMode ? 'Update' : 'Create'}
            </Button>
          </Row>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = ({ solutionForm, demos, viewport, roleTemplates, user, solutionTypeData, tabsLayout }) => {
  return {
    ...solutionForm,
    allDemoData: demos.data,
    allDemos: demos.allDemos,
    viewport: viewport,
    allRoleTemplates: roleTemplates.all,
    userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
    solutionTypes: solutionTypeData.solutionTypes,
    hasSave: tabsLayout.hasSave,
    openedTabs: tabsLayout.panes,
    activeKey: tabsLayout.activeKey,
    solutionForm
  };
}

const SolutionFormRef = Form.create()(SolutionForm);

export default connect(
  mapStateToProps,
  {
    toggleBackButton,
    updateSelectedSolution,
    reorderSolutionDemos,
    toggleIsFetchingSolutions,
    updateSolutionRoleTemplate,
    deleteSolutionDemo,
    addSolutionDemo,
    postSolution,
    putSolution,
    fetchRoleTemplates,
    fetchAdminRoleTemplates,
    cancelSolutionRoleTemplates,
    saveSolutionRoleTemplates,
    clearSolutionForm,
    updateSelectedSolutionType,
    currentSolutionFilter,
    fetchDemos,
    getAllSolutionTypes,
    toggleHasUnsavedChangesFlag,
    closeCurrentOpenTab
  }
)(SolutionFormRef)
