import React from 'react'
import CONFIG from "../../../config";
import moment from 'moment-timezone';

function ScheduleTimeDetails({ demo, timezone, startTime, endTime, showResource }) {
    const bufferStart = demo?.startTimeBuffer;

    return (
        <div>
            <div className='banner'>
                <div className="banner-content">
                    <div className="banner-left">
                        {/* <div className='day-banner-left'>{moment(startTime).tz(timezone).format('dddd')}</div> */}
                        <div className='date-banner-left'>{moment(startTime).tz(timezone).format(CONFIG.dateFormats.schedulerDate)}</div>

                    </div>
                    <div className='banner-middle'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4168 6.47258C19.7081 6.17809 20.1829 6.17549 20.4774 6.46678L29.6774 15.5668C29.8193 15.7072 29.8995 15.8983 29.9 16.0979C29.9005 16.2976 29.8215 16.4892 29.6803 16.6303L20.4803 25.8303C20.1874 26.1232 19.7126 26.1232 19.4197 25.8303C19.1268 25.5374 19.1268 25.0626 19.4197 24.7697L27.3393 16.85H3.75C3.33579 16.85 3 16.5142 3 16.1C3 15.6858 3.33579 15.35 3.75 15.35H27.3253L19.4226 7.53322C19.1281 7.24193 19.1255 6.76707 19.4168 6.47258Z" fill="black" />
                        </svg>
                    </div>
                    <div className='banner-right'>
                        <div className='date-banner-left'>{moment(endTime).tz(timezone).format(CONFIG.dateFormats.schedulerDate)}</div>
                    </div>

                    <div className='banner-seperator-parent'>
                        <div className="banner-separator" />
                    </div>
                    <div className="banner-right-content">
                        <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Demo Name:</div>
                        <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>{demo.title}</div>
                        {(bufferStart > 0) &&
                            <>
                                <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Buffer Time:</div>
                                <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>{`${demo?.startTimeBuffer / 60} Hour(s)`} </div>
                            </>
                        }
                        {showResource && <><div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Demo Duration:</div>
                            <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>{`${demo?.scheduleRange} Day(s)`}</div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleTimeDetails