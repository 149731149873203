import React from 'react';
import { Table, Checkbox } from 'antd';

import CONFIG from '../../config';

const RoleTemplatesTable = (props) => {
    const dataIndex = props.dataIndex ? props.dataIndex : 'title';
    const columns = [
        {
            title: "Title",
            dataIndex,
            key: dataIndex,
            width: '60%',
            align: "left",
            ...(props.columnFilters || {}),
        }
    ];

    const additionalObjProperties = (record) => {
        return props.dataKey === 'news' ?
            { expiryTime: record.expiryTime } : props.dataKey === 'notification'
                ? { validityEndTime: record.validityEndTime } : props.dataKey === "solution" ? {
                    isActive: record?.isActive,
                    solutionType: {
                        id: record?.solutionType?.id,
                        name: record?.solutionType?.name,
                    }
                } : undefined;
    }

    const userColumns = [
        {
            title: "User",
            children: [
                {
                    title: (
                        <div>
                            <br />
                            <Checkbox
                                onChange={(e) => props.recordId
                                    ? props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.user, undefined, props.recordId, props.isClone)
                                    : props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.user)}
                                checked={props.allUserAccess === CONFIG.roleTemplateAccess.readOnly}
                                disabled={(props.data && props.data.length === 0) || props.selectAllDisabled}
                            />
                        </div>
                    ),
                    key: "userAccess",
                    align: "center",
                    width: '20%',
                    render: (access, record) => (
                        <Checkbox
                            onChange={(e) => props.recordId
                                ? props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.user, { id: record.id, [dataIndex]: record[dataIndex], ...(additionalObjProperties(record)) }, props.recordId, props.isClone)
                                : props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.user, { id: record.id, [dataIndex]: record[dataIndex], ...(additionalObjProperties(record)) })}
                            checked={getAccess(record.id, props.assignedData, CONFIG.roleTypes.user, props.dataKey) === CONFIG.roleTemplateAccess.readOnly}
                        />
                    )
                }
            ]
        }
    ];
    const adminColumns = [

        {
            title: <span className="primary-text-color">Admin</span>,
            children: [
                {
                    title: (
                        <div>
                            Read
                            <br />
                            <Checkbox
                                onChange={(e) => props.recordId
                                    ? props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.admin, undefined, props.recordId, props.isClone)
                                    : props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.admin)}
                                checked={props.allAdminAccess === CONFIG.roleTemplateAccess.readOnly}
                                disabled={(props.data && props.data.length === 0) || props.selectAllDisabled} />
                        </div>
                    ),
                    key: "adminReadAccess",
                    align: "center",
                    width: '10%',
                    render: (access, record) => (
                        <Checkbox
                            disabled={record.templateType && record.templateType !== CONFIG.roleTypes.admin}
                            onChange={(e) => props.recordId
                                ? props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.admin, { id: record.id, [dataIndex]: record[dataIndex], ...(additionalObjProperties(record)) }, props.recordId, props.isClone)
                                : props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.readOnly, CONFIG.roleTypes.admin, { id: record.id, [dataIndex]: record[dataIndex], ...(additionalObjProperties(record)) })}
                            checked={getAccess(record.id, props.assignedData, CONFIG.roleTypes.admin, props.dataKey) === CONFIG.roleTemplateAccess.readOnly}
                        />
                    )
                },
                {
                    title: (
                        <div>
                            Write
                            <br />
                            <Checkbox
                                onChange={(e) => props.recordId
                                    ? props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.fullAccess, CONFIG.roleTypes.admin, undefined, props.recordId, props.isClone)
                                    : props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.fullAccess, CONFIG.roleTypes.admin)}
                                checked={props.allAdminAccess === CONFIG.roleTemplateAccess.fullAccess}
                                disabled={(props.data && props.data.length === 0) || props.selectAllDisabled} />
                        </div>
                    ),
                    key: "adminWriteAccess",
                    align: "center",
                    width: '10%',
                    render: (access, record) => (
                        <Checkbox
                            disabled={record.templateType && record.templateType !== CONFIG.roleTypes.admin}
                            onChange={(e) => props.recordId
                                ? props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.fullAccess, CONFIG.roleTypes.admin, { id: record.id, [dataIndex]: record[dataIndex], ...(additionalObjProperties(record)) }, props.recordId, props.isClone)
                                : props.updateRoleTemplateData(e.target.checked, CONFIG.roleTemplateAccess.fullAccess, CONFIG.roleTypes.admin, { id: record.id, [dataIndex]: record[dataIndex], ...(additionalObjProperties(record)) })}
                            checked={getAccess(record.id, props.assignedData, CONFIG.roleTypes.admin, props.dataKey) === CONFIG.roleTemplateAccess.fullAccess}
                        />
                    )
                }
            ]
        }
    ];

    const { extraColumns, pagination, onChange } = props;

    const isUserColumnHidden = props.hideColumns && props.hideColumns.includes('user');
    const isAdminColumnHidden = props.hideColumns && props.hideColumns.includes('admin');
    const allColumns = [
        ...columns,
        ...(extraColumns || []),
        ...(!isUserColumnHidden ? userColumns : []),
        ...(!isAdminColumnHidden ? adminColumns : [])
    ];

    const tableProps = onChange ? { onChange } : {};

    return (
        <Table
            size='middle'
            className={`responsive-container ${props.marginTop && props.marginTop === true ? 'top-spacing' : ''}`}
            bordered={props.bordered ? props.bordered : false}
            columns={allColumns}
            loading={props.isFetching ? props.isFetching : false}
            dataSource={props.data ? props.data : []}
            rowKey={(record) => record.id}
            pagination={pagination}
            {...tableProps}
        />
    )
};

const getAccess = (itemId, assignedData, roleType, dataKey) => {
    const foundItem = assignedData ? assignedData.find(item => item[dataKey]?.id === itemId) : undefined;
    const accessType = roleType === CONFIG.roleTypes.admin ? 'adminAccess' : 'userAccess';
    return foundItem ? foundItem[accessType] : CONFIG.roleTemplateAccess.none;
}

export default RoleTemplatesTable;
