import {
    FETCH_CUSTOM_EMAIL_TEMPLATE,
    UPDATE_EDITOR_CONTENT_CUSTOM_EMAIL_TEMPLATE,
    TOGGLE_CUSTOM_EMAIL_CK_EDITOR_CONTENT_CHANGED_FLAG,
    CLEAR_CUSTOM_EMAIL_TEMPLATE
} from '../actions/types';


const customEmailTemplateVersionsState = {
    isFetching: false,
    html: undefined,
    isContentLoaded: false,
    hasCkEditorContentChanged: false
};

export const customEmailTemplateReducer = (state = customEmailTemplateVersionsState, action) => {
    switch (action.type) {
        case FETCH_CUSTOM_EMAIL_TEMPLATE:
            return { ...state, customEmailTemplate: action.payload };
        case UPDATE_EDITOR_CONTENT_CUSTOM_EMAIL_TEMPLATE:
            return { ...state, isContentLoaded: true, html: action.payload };
        case TOGGLE_CUSTOM_EMAIL_CK_EDITOR_CONTENT_CHANGED_FLAG:
            return { ...state, hasCkEditorContentChanged: action.payload };
        case CLEAR_CUSTOM_EMAIL_TEMPLATE:
            return customEmailTemplateVersionsState;
        default:
            return state;
    };
};
