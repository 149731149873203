import {
    FETCH_ROLE_TEMPLATES,
    FETCH_ROLE_TEMPLATE,
    FETCH_DOMAINS,
    UPDATE_DOMAINS,
    DELETE_ROLE_TEMPLATE,
    UPDATE_ROLE_TEMPLATE_DEMOS,
    CLEAR_ROLE_TEMPLATE,
    TOGGLE_ROLE_TEMPLATES_DEMOS_ALL,
    TOGGLE_ROLE_TEMPLATES_ENVIRONMENT_ALL,
    UPDATE_ROLE_TEMPLATE_NEWS,
    UPDATE_ROLE_TEMPLATE_REPORTS,
    UPDATE_ROLE_TEMPLATE_TIPS,
    UPDATE_ROLE_TEMPLATE_NOTIFICATIONS,
    TOGGLE_IS_FETCHING_ROLE_TEMPLATES,
    CLEAR_ROLE_TEMPLATES,
    UPDATE_ROLE_TEMPLATE_TYPE,
    UPDATE_NETWORK_ADMIN,
    UPDATE_ROLE_TEMPLATE_RESOURCES,
    UPDATE_ROLE_TEMPLATE_SOLUTIONS,
    UPDATE_ROLE_TEMPLATE_ENVIRONMENT,
    UPDATE_ROLE_TEMPLATE_SUPPORT,
    UPDATE_ROLE_TEMPLATE_CAPABILITY,
    UPDATE_ADMIN_ROLE_TEMPLATES,
    UPDATE_ROLE_TEMPLATE_NETWORKS,
    UPDATE_ROLE_TEMPLATE_SOLUTION_TYPES,
    LAST_ROLE_TEMPLATES_FILTER,
    UPDATE_ROLE_TEMPLATE_DEMO_PURPOSE,
    UPDATE_ROLE_TEMPLATE_DEMO_FEEDBACK_QUESTIONS,
    UPDATE_ROLE_TEMPLATE_DEMO_QUESTIONS,
    UPDATE_ROLE_TEMPLATE_EZ_DEMOS
} from '../actions/types';
import CONFIG from '../config';

const roleTemplatesState = {
    isFetching: false
}

export const roleTemplatesReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ROLE_TEMPLATES:
            return { ...state, all: action.payload };
        case LAST_ROLE_TEMPLATES_FILTER:
            return { ...state, currentFilter: action.payload?.filters, isEdit: true, pageNumber: action.payload?.pageNumber }
        case DELETE_ROLE_TEMPLATE:
            return { ...state, all: state.all.filter(roleTemplate => roleTemplate.id !== action.payload) };
        case TOGGLE_IS_FETCHING_ROLE_TEMPLATES:
            return { ...state, isFetching: action.payload };
        case CLEAR_ROLE_TEMPLATES:
            return roleTemplatesState;

        default:
            return state;
    }
};

const roleTemplateState = {
    isFetching: false,
    allDemosUserAccess: undefined,
    allDemosAdminAccess: undefined,
    allNewsUserAccess: 0,
    allNewsAdminAccess: 0,
    allReportsUserAccess: 0,
    allReportsAdminAccess: 0,
    allTipsUserAccess: 0,
    allTipsAdminAccess: 0,
    allNotificationsUserAccess: 0,
    allNotificationsAdminAccess: 0,
    allResourcesUserAccess: 0,
    allResourcesAdminAccess: 0,
    allSolutionsUserAccess: 0,
    allSolutionsAdminAccess: 0,
    allSolutionTypesUserAccess: 0,
    allSolutionTypesAdminAccess: 0,
    allNetworksAdminAccess: 0,
    allSupportAdminAccess: 0,
    allEnvironmentsAccess: 0,
    allAdminRTAccess: 0,
    allDemoPurposeUserAccess: 0,
    allDemoPurposeAdminAccess: 0,
    allFeedbackQuestionUserAccess: 0,
    allFeedbackQuestionAdminAccess: 0,
    allDemoQuestionsAdminAccess: 0,
    allCapabilityAdminAccess: 0,
    allEZDemosAdminAccess: 0,
};

export const roleTemplateReducer = (state = roleTemplateState, action) => {
    switch (action.type) {
        case FETCH_ROLE_TEMPLATE:
            return { ...state, ...action.payload, allEnvironmentsAccess: undefined, data: action.payload };
        case TOGGLE_IS_FETCHING_ROLE_TEMPLATES:
            return { ...state, isFetching: action.payload };
        case FETCH_DOMAINS:
            return { ...state, allDomains: action.payload };
        case UPDATE_DOMAINS:
            return { ...state, allDomains: state.allDomains ? [action.payload, ...state.allDomains] : [action.payload] };
        case UPDATE_ROLE_TEMPLATE_DEMOS:
            return {
                ...state,
                demos: action.payload.updatedDemos,
                [action.payload.accessKey]: action.payload.isAllAccessChanged ? state[action.payload.accessKey] : undefined,
            };
        case UPDATE_ROLE_TEMPLATE_ENVIRONMENT:
            return {
                ...state,
                [action.payload.environmentCategory]: action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                allEnvironmentsAccess: undefined
            };
        case UPDATE_ROLE_TEMPLATE_SUPPORT:
            return {
                ...state,
                supportGuides: action.payload.updatedSupport,
                allSupportAdminAccess: !action.payload.selectedSupport && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none
            }
        case UPDATE_ROLE_TEMPLATE_CAPABILITY:
            return {
                ...state,
                capabilities: action.payload.updatedCapability,
                allCapabilityAdminAccess: !action.payload.selectedCapability && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none
            }
        case UPDATE_ROLE_TEMPLATE_EZ_DEMOS:
            return {
                ...state,
                ezDemos: action.payload.updatedEZDemos,
                [action.payload.allAccessKey]: !action.payload.selectedEZDemo && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none
            }
        case UPDATE_ROLE_TEMPLATE_NETWORKS:
            return {
                ...state,
                networks: action.payload.updatedNetwork,
                allNetworksAdminAccess: !action.payload.selectedNetwork && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none
            }
        case TOGGLE_ROLE_TEMPLATES_ENVIRONMENT_ALL:
            return {
                ...state,
                dataCentersAccess: action.payload.access && action.payload.accessType !== CONFIG.roleTemplateAccess.fullAccess ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                didsAccess: action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                allEnvironmentsAccess: action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none
            };
        case CLEAR_ROLE_TEMPLATE:
            return roleTemplateState;
        case TOGGLE_ROLE_TEMPLATES_DEMOS_ALL:
            return { ...state, [action.payload.accessKey]: action.payload.access ? action.payload.accessType : undefined }
        case UPDATE_ROLE_TEMPLATE_NEWS:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedNews && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                news: action.payload.updatedNews
            };
        case UPDATE_ROLE_TEMPLATE_DEMO_PURPOSE:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedPurpose && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                demoPurposes: action.payload.updatedPurpose
            };
        case UPDATE_ROLE_TEMPLATE_DEMO_FEEDBACK_QUESTIONS:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedQuestion && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                questions: action.payload.updatedQuestions
            };
        case UPDATE_ROLE_TEMPLATE_DEMO_QUESTIONS:
            return {
                ...state,
                allDemoQuestionsAdminAccess: !action.payload.selectedDemoQuestion && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                demoQuestions: action.payload.updatedDemoQuestion
            };
        case UPDATE_ROLE_TEMPLATE_REPORTS:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedReport && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                reports: action.payload.updatedReports
            };
        case UPDATE_ROLE_TEMPLATE_TIPS:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedTip && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                tips: action.payload.updatedTips
            };
        case UPDATE_ROLE_TEMPLATE_RESOURCES:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedResource && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                resources: action.payload.updatedResources
            };
        case UPDATE_ROLE_TEMPLATE_NOTIFICATIONS:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedNotification && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                notifications: action.payload.updatedNotifications
            };
        case UPDATE_ROLE_TEMPLATE_SOLUTIONS:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedSolution && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                solutions: action.payload.updatedSolutions
            };
        case UPDATE_ROLE_TEMPLATE_SOLUTION_TYPES:
            return {
                ...state,
                [action.payload.allAccessKey]: !action.payload.selectedSolutionType && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                solutionTypes: action.payload.updatedSolutionTypes
            };
        case UPDATE_ADMIN_ROLE_TEMPLATES:
            return {
                ...state,
                allAdminRTAccess: !action.payload.selectedRoleTemplate && action.payload.access ? action.payload.accessType : CONFIG.roleTemplateAccess.none,
                roleTemplates: action.payload.updatedRoleTemplates
            };
        case UPDATE_ROLE_TEMPLATE_TYPE:
            return { ...state, templateType: action.payload };
        case UPDATE_NETWORK_ADMIN:
            return { ...state, networksAccess: action.payload }
        default:
            return state;
    }
}
