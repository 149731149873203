import { batch } from 'react-redux';
import { aepReport, aepSchedule, aepUser, aepDemo } from '../apis';
import {
    TOGGLE_IS_FETCHING_REPORTS,
    FETCH_USER_REPORTS,
    FETCH_ENTITY_TYPES,
    TOGGLE_IS_FETCHING_REPORT_FORM,
    COLUMNS_OF_THE_REPORT,
    CLEAR_REPORT_FORM,
    FETCH_REPORTS,
    FETCH_GENERAL_REPORTS,
    CLEAR_REPORT_VALUES,
    UPDATE_REPORTS_CURRENT_PAGE,
    UPDATE_CUSTOM_REPORTS_CURRENT_PAGE,
    UPDATE_REPORT_ROLE_TEMPLATE,
    UPDATE_REPORT_DETAILS,
    RUN_REPORT,
    RUN_GENERAL_REPORT,
    UPDATE_RUN_REPORT_CURRENT_PAGE,
    LAST_REPORT_FILTER,
    LAST_USER_REPORT_FILTER,
    CLEAR_REPORT,
    FETCH_USER_MY_REPORTS,
    FETCH_USER_CREATED_REPORTS,
    CLEAR_REPORT_ON_ENTITY_CHANGE,
    HAS_ACCESS_TO_EAP_OFFLINE,
    TOGGLE_IS_SAVE_REPORT_VISIBLE,
    TOGGLE_IS_FETCHING_USER_REPORTS,
    UPDATE_USER_REPORT_DETAILS,
    COLUMNS_OF_USER_REPORT,
    RUN_USER_REPORT,
    RUN_USER_GENERAL_REPORT,
    CLEAR_USER_REPORT,
    UPDATE_SELECTED_COLUMNS_FOR_REPORT,
    TOGGLE_IS_ENTITY_CHANGED,
    CLEAR_REPORTS_CONTENT
} from './types'
import moment from 'moment-timezone';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';
import { closeCurrentOpenTab, handleOnClickAction } from './tabsLayout';
import { download } from '../utils/file';

const serviceName = {
    report: aepReport,
    schedule: aepSchedule,
    user: aepUser,
    demo: aepDemo
}


export const fetchEmailsBySearchString = async ({ pageNumber = 0, pageSize = CONFIG.fetchCompaniesSize, searchString }) => {
    const contextPath = '/userTemplateService/userslist/emailSearch'
    const response = await aepUser.get(contextPath, {
        params: {
            pageSize,
            pageNumber,
            searchString,
        }
    })
    return response.data ? response.data.dataSet : [];
}


export const getAllItems = async (dataObj, searchString) => {
    const response = await serviceName[dataObj.columnApiService].get(dataObj.columnApi, {
        params: searchString ? {
            pageSize: CONFIG.fetchCompaniesSize,
            searchString,
        } : undefined
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        return response.data ? response.data?.dataSet === undefined ? response.data : response.data.dataSet : [];
    }
    else return []
}

export const getAllReports = async (access) => {
    const response = await aepReport.get("/reportSearchService/manage/reports/all", { params: { access } });
    return response.data ? response.data?.dataSet : [];
}

export const getAllCustomReports = async ({ filterSort, status }) => {

    filterSort = {
        ...filterSort,
        filter: {
            ...filterSort?.filter,
            isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
        }
    }
    const response = await aepReport.get("/reportSearchService/manage/reports/custom", {
        params: {
            filterSort
        }
    });
    return response.data ? response.data.dataSet : [];
}

export const getAllUserCreatedReports = async ({ filterSort, status }) => {

    filterSort = {
        ...filterSort,
        filter: {
            ...filterSort?.filter,
            isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
        }
    }
    const response = await aepReport.get("/reportSearchService/manage/reports/userCreated", {
        params: {
            filterSort
        }
    });
    return response.data ? response.data.dataSet : [];
}

export const getReportExportData = async ({ reportId, userRunReport, filterSort }) => {
    const reportResponse = await aepReport.get("/reportSearchService/reports/" + reportId);
    let runReportResponse;
    if (reportResponse.status === CONFIG.HTTP_STATUS.OK) {
        runReportResponse = await aepReport.post("/reportSearchService/execute/reports/" + reportResponse.data.filterTable?.tableName?.replaceAll(" ", ""), {
            filterSortObject: filterSort ? filterSort : JSON.parse(reportResponse.data.filterSortObject),
            reportId: userRunReport === "fromUser" ? reportId : null,
            isRunByUser: userRunReport === "fromUser" ? true : false

        });
        return runReportResponse.data ? { dataSet: runReportResponse.data?.dataSet, exportViewColumns: JSON.parse(reportResponse.data.viewColumnsJson) } : {}
    }
}

export const runCustomReports = async ({ filterSort, entity }) => {
    const runReportResponse = await aepReport.post("/reportSearchService/execute/reports/" + entity,
        {
            filterSortObject: filterSort,
            reportId: null,
            isRunByUser: false
        })
    return runReportResponse.data ? runReportResponse.data : {}
}

export const toggleIsFetchingReportFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_REPORTS,
        payload: flag
    }
}

export const toggleIsFetchingUserReports = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_USER_REPORTS,
        payload: flag
    }
}

export const toggleIsFetchingReportForm = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_REPORT_FORM,
        payload: flag
    }
}

export const toggleSaveReportVisible = (flag) => {
    return {
        type: TOGGLE_IS_SAVE_REPORT_VISIBLE,
        payload: flag
    }
}

export const clearSaveReports = () => {
    return {
        type: CLEAR_REPORT_VALUES
    }
}

export const fetchUserStandardReports = (filterSort) => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/user/standardReports",
        {
            params: {
                filterSort
            }
        })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        await dispatch({ type: FETCH_USER_REPORTS, payload: response.data || [] })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch reports');
    await dispatch(toggleIsFetchingUserReports(false));
}

export const fetchUserStandardReportsLite = (filterSort) => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/v1/lite/user/standardReports",
        {
            params: {
                filterSort
            }
        })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        await dispatch({ type: FETCH_USER_REPORTS, payload: response.data || [] })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch reports');
    await dispatch(toggleIsFetchingUserReports(false));
}

export const fetchUserMyReports = (filterSort) => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/user/myReports",
        {
            params: {
                filterSort
            }
        })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        await dispatch({ type: FETCH_USER_MY_REPORTS, payload: response.data || [] })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch reports');
    await dispatch(toggleIsFetchingUserReports(false));
}

export const fetchUserMyReportsLite = (filterSort) => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/v1/lite/user/myReports",
        {
            params: {
                filterSort
            }
        })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        await dispatch({ type: FETCH_USER_MY_REPORTS, payload: response.data || [] })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch reports');
    await dispatch(toggleIsFetchingUserReports(false));
}

export const fetchReports = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, access, status, fromRoleTemplateForm }) => async (dispatch) => {
    filterSort = {
        ...filterSort,
        filter: {
            ...filterSort?.filter,
            isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
        }
    }

    const contextPath = fromRoleTemplateForm ? "/reportSearchService/manage/reports/all" : "/reportSearchService/manage/reports/custom"
    const response = await aepReport.get(contextPath, {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            filterSort,
            access
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (pageNumber !== 1 && !response.data) {
            dispatch(fetchReports({ pageNumber: pageNumber - 1, filterSort }));
            return;
        }
        else dispatch({ type: FETCH_REPORTS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch reports');
    dispatch(toggleIsFetchingReportFlag(false));
}

export const fetchGeneralReports = ({ filterSort }) => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/manage/reports/general", {
        params: {
            filterSort,
        }
    })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_GENERAL_REPORTS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Standard Reports');
    dispatch(toggleIsFetchingReportFlag(false));
}

export const fetchUserCreatedReports = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, access, status }) => async (dispatch) => {
    filterSort = {
        ...filterSort,
        filter: {
            ...filterSort?.filter,
            isActive: filterSort?.filter?.viewDeletedItems ? undefined : status
        }
    }

    const response = await aepReport.get("/reportSearchService/manage/reports/userCreated", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            filterSort,
            access
        }
    })

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (pageNumber !== 1 && !response.data) {
            dispatch(fetchUserCreatedReports({ pageNumber: pageNumber - 1, filterSort }));
            return;
        }
        else dispatch({ type: FETCH_USER_CREATED_REPORTS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch User created Reports');
}

export const updateReportRoleTemplate = (access, roleType, accessType, selectedRoleTemplate, isClone, isGeneralReport, recordId) => (dispatch, getState) => {
    const allRoleTemplates = getState().roleTemplates.all;
    const { currentReport } = getState();
    let reportTabData = currentReport.reportDetails.find(data => ((parseInt(data.id) === parseInt(recordId)) && (data?.isClone === isClone) && (data?.isGeneralReport === isGeneralReport)));
    const assignedRoleTemplates = reportTabData?.details?.roleTemplates;
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allRTUserAccess' : 'allRTAdminAccess';
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_REPORT_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access, allAccessKey, isClone, isGeneralReport, recordId }
    });
}

export const updateReportsCurrentPage = ({ pageNumber = 1, filterSort, status, isGeneralReport = false }) => (dispatch) => {
    !isGeneralReport ? batch(() => {
        dispatch({
            type: UPDATE_REPORTS_CURRENT_PAGE,
            payload: pageNumber
        });
        dispatch(toggleIsFetchingReportFlag(true));
        dispatch(fetchReports({ pageNumber, filterSort, status }))
    }) :
        dispatch(fetchGeneralReports({ filterSort }));
};


export const updateCustomReportsCurrentPage = (currentPageNumber, pageSize, reportId, customReportIndex) => {
    return {
        type: UPDATE_CUSTOM_REPORTS_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, reportId, customReportIndex]
    }
};

export const fetchEntityTypes = () => async dispatch => {
    const response = await aepReport.get('/reportSearchService/report/entities');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ENTITY_TYPES,
            payload: response?.data || []
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to Entity Types');
    dispatch(toggleIsFetchingReportForm(false));
    return response?.data || [];
}

export const fetchReportColumns = (entityId, isUserRun, reportId, isClone, isGeneralReport) => async dispatch => {
    const response = await aepReport.get('/reportSearchService/report/entities/' + entityId);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        const payload = isUserRun ? (response.data || []) : reportId ? { reportId, isClone, isGeneralReport, columns: response?.data || [] } : (response.data || [])
        dispatch({
            type: isUserRun ? COLUMNS_OF_USER_REPORT : reportId !== false && COLUMNS_OF_THE_REPORT,
            payload: payload
        });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Entity Types');
    dispatch(toggleIsFetchingReportForm(false));
    return response?.data || []
}

export const clearReportsForm = () => {
    return {
        type: CLEAR_REPORT_FORM
    }
}

export const deleteReport = (reportId, hardDelete = false, isUserCreated = false) => async () => {
    const reportType = isUserCreated ? "userCreated" : null;
    const response = await aepReport.delete("/reportSearchService/reports/" + reportId, { params: { hardDelete, reportType } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Report deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete report");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
};

export const restoreReport = (reportId) => async () => {
    const response = await aepReport.put("/reportSearchService/restore/reports/" + reportId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Report restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore report");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const postSaveAsReport = (reportName, filterSortObject, report, isClone) => async () => {
    const reportData = JSON.stringify({
        name: reportName,
        isActive: report.isActive,
        filterSortObject: JSON.stringify({ filter: filterSortObject.filter }),
        description: report.description,
        viewColumnsJson: report.viewColumnsJson,
        default: report.default,
        filterTable: report.filterTable,
        reportKey: report?.reportKey
    });
    const contextPath = "/reportSearchService/user/reports/" + report.id;
    const response = await aepReport({
        method: 'post',
        url: contextPath,
        data: reportData
    })
    const stringMsg = isClone ? "cloned" : "saved"
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, `Report ${stringMsg} Successfully. You can find the ${stringMsg} Reports under "My Reports"`)
    }
    else {
        pushMessage(CONFIG.messageType.error, `Unable to ${stringMsg} the report`);
    }
}

export const postReport = (id, values, isGeneralReport, reportValues, isClone) => async (dispatch, getState) => {
    const state = getState();
    const reportId = id === "-1" ? undefined : id;
    const actualId = id === "saveReport" ? "-2" : id;
    const currentReport = state?.currentReport?.reportDetails?.find(data => ((parseInt(data.id) === parseInt(actualId)) && (data?.isClone === isClone) && (data?.isGeneralReport === isGeneralReport)))?.details;
    const reportData = reportId === "saveReport" ?
        {
            name: values.name,
            isActive: values.isActive,
            roleTemplates: currentReport?.roleTemplates || [],
            filterTable: reportValues.filterTable,
            filterSortObject: JSON.stringify({ filter: reportValues.filter }),
            description: values.description,
            viewColumnsJson: JSON.stringify(reportValues.reportColumns)
            //need to change for save report;
        }
        :
        {
            ...currentReport,
            id: reportId,
            name: values.name,
            isActive: values.isActive,
            filterTable: JSON.parse(values.entity),
            description: values.description,
            viewColumnsJson: values.columns && values.columns?.length !== 0 ? JSON.stringify(values.columns.map(column => column)) : JSON.stringify(currentReport.reportColumns),
            filterSortObject: JSON.stringify({ filter: values.filter }),
            count: undefined,
            entityTypes: undefined,
            isFetching: undefined,
            isFetchingReportForm: undefined,
            isLoading: undefined,
            reportColumns: undefined,
            reportObj: undefined,
            assignedRoleTemplates: undefined,
            runReportData: undefined
        };

    const contextPath = "/reportSearchService/reports";
    const response = reportId === 'saveReport' ? await aepReport({
        method: 'post',
        url: contextPath,
        data: reportData
    })
        : await aepReport({
            method: reportId === undefined ? 'post' : 'put',
            url: reportId === undefined ? contextPath : `${contextPath}/${reportId}`,
            data: reportData
        });
    const successMessage = reportId && reportId !== 'saveReport' ? 'Report updated successfully' : 'Report created successfully';
    const errorMessage = reportId && reportId !== 'saveReport' ? 'Unable to update Report' : 'Unable to create Report';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage);
        if (isClone || reportId === undefined) {
            await dispatch(resetReportForm(reportId === undefined ? "-1" : reportId, isClone, isGeneralReport));
            await dispatch(closeCurrentOpenTab());
            await dispatch(handleOnClickAction({
                component: CONFIG.editComponentRoute.reports,
                tabTitle: values.name,
                recordId: response?.data,
                recordName: "custom"
            }))
        }
        batch(async () => {
            if (reportId !== "saveReport") {
                await dispatch(resetReportForm(reportId === undefined ? "-1" : reportId, isClone, isGeneralReport));
                const entityTypes = await dispatch(fetchEntityTypes());
                await dispatch(fetchReport(reportId === undefined ? response.data : reportId, false, isGeneralReport, null, isClone, entityTypes))
            }
        })
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    isGeneralReport && dispatch(fetchGeneralReports({ filterSort: {} }));
    if (reportId !== "saveReport")
        if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) return true;
        else return false;
    if (reportId === 'saveReport') {
        await dispatch(resetReportForm("-2", false, false));
        dispatch(toggleSaveReportVisible(false));
    }
}

export const saveReport = (reportValues) => async (dispatch) => {
    let reportObject = {};
    const name = reportValues.name;
    const description = reportValues.description;
    const filterSortObject = reportValues.filterSortObject;
    const viewColumnsJson = reportValues.viewColumnsJson;
    const filterTable = reportValues.filterTable;
    const isActive = reportValues.isActive;
    let isDefault = reportValues.default;
    const reportKey = reportValues?.reportKey;
    const contextPath = "/reportSearchService/user/reports/" + reportValues.id;
    if (reportValues.parentReportId === null) {
        reportObject = {
            name,
            description,
            filterSortObject,
            viewColumnsJson,
            filterTable,
            isActive,
            default: isDefault,
            reportKey
        }
    }
    const response = await aepReport({
        method: reportValues.parentReportId === null ? 'post' : 'put',
        url: contextPath,
        data: reportValues.parentReportId === null ? reportObject : reportValues
    })
    const successMessage = 'Report saved successfully'
    const errorMessage = 'Unable to save Report'
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage)
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
}

export const fetchReport = (reportId, userRun, isGeneralReport, isExport = false, isCloneReport, entityTypes) => async (dispatch, getState) => {
    const response = await aepReport.get("/reportSearchService/reports/" + reportId);
    const isUserRun = userRun === "fromUser";
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
            return;
        }
        const { user, roleTemplates } = getState();
        const timeZone = getState().user.profile.timezone;
        const utcOffset = moment().tz(timeZone).utcOffset()
        const allRoleTemplates = roleTemplates.all;
        const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
        const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
        const report = response.data;
        let cloneRoleTemplates = []
        cloneRoleTemplates = isCloneReport && report?.roleTemplates?.map(item => { delete item?.id; return item })
        const reportData = !isGeneralReport ? {
            ...response.data,
            roleTemplates: isCloneReport ? cloneRoleTemplates : isAdmin ? response.data.roleTemplates.filter(tipRoleTemplate => allRoleTemplates?.some(roleTemplate => tipRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates,
            assignedRoleTemplates: isCloneReport ? cloneRoleTemplates : isAdmin ? response.data.roleTemplates.filter(tipRoleTemplate => allRoleTemplates?.some(roleTemplate => tipRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates,
            filterSortObject: JSON.parse(response.data.filterSortObject),
            viewColumnsJson: JSON.parse(response.data.viewColumnsJson),
            entityTypes
        } : {
            ...response.data,
            roleTemplates: isAdmin ? response.data.roleTemplates.filter(tipRoleTemplate => allRoleTemplates?.some(roleTemplate => tipRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates,
            assignedRoleTemplates: isAdmin ? response.data.roleTemplates.filter(tipRoleTemplate => allRoleTemplates?.some(roleTemplate => tipRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates,
            filterSortObject: JSON.parse(response.data.filterSortObject),
            viewColumnsJson: JSON.parse(response.data.viewColumnsJson),
            entityTypes
        }
        if (isExport) return reportData
        batch(async () => {
            const reportColumns = await dispatch(fetchReportColumns(response?.data?.filterTable.id, isUserRun, false))
            isGeneralReport && await dispatch(runCommonStandardReport({ filterSort: reportData.filterSortObject, reportKey: reportData?.reportKey, reportId: isUserRun ? reportId : null, isRunByUser: userRun, utcOffset }))
            if (!isUserRun) {
                dispatch(updateReportDetails({ reportId, reportData, fetchFromAPI: true, isClone: isCloneReport, reportColumns, isGeneralReport }))
                dispatch(toggleIsFetchingReportFlag(false));
            }
            else {
                dispatch({
                    type: UPDATE_USER_REPORT_DETAILS,
                    payload: { ...reportData, reportColumns: reportColumns },
                    report
                });
                dispatch(toggleIsFetchingUserReports(false));
            }
        })
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Report');
    }
    dispatch(toggleIsFetchingReportForm(false));
}

export const updateReportDetails = ({ reportId, isClone = false, reportData, fetchFromAPI = false, reportColumns, isGeneralReport, entites }) => async (dispatch, getState) => {
    let formTabData = {};
    if (fetchFromAPI) {
        formTabData = {
            id: parseInt(reportId),
            details: { ...reportData, reportColumns, entityChanged: false, },
            originalDetails: reportData,
            isClone,
            isGeneralReport: isGeneralReport,
            reportColumns: reportColumns,
            entityChanged: false,
            fetchFromAPI
        }
    }
    else {
        let requestData = []
        const state = getState();
        const currentReport = state?.currentReport?.reportDetails?.find(data => ((parseInt(data.id) === parseInt(reportId)) && (data?.isClone === isClone) && (data?.isGeneralReport === isGeneralReport)))
        const roleTemplates = currentReport?.details?.roleTemplates;
        const assignedRoleTemplates = currentReport?.details?.assignedRoleTemplates;
        const reportColumns = currentReport?.details?.reportColumns;
        const filterTable = reportData?.entity && JSON.parse(reportData?.entity);
        const entityTypes = currentReport?.details?.entityTypes || entites;
        requestData = {
            ...reportData,
            id: reportId,
            reportColumns: reportColumns,
            filterTable,
            entityTypes,
            entityChanged: false,
            viewColumnsJson: reportData?.columns,
            filterSortObject: reportData?.filterSortObject,
            name: reportData?.name,
            roleTemplates: isClone ? roleTemplates?.map(item => { return { ...item, id: undefined } }) : roleTemplates,
            assignedRoleTemplates: isClone ? assignedRoleTemplates?.map(item => { return { ...item, id: undefined } }) : assignedRoleTemplates,
            fetchFromAPI: false,
            default: reportData?.originalDetails?.default,
            parentReport: reportData?.originalDetails?.parentReport,
            reportKey: reportData?.originalDetails?.reportKey
        }

        formTabData = {
            id: parseInt(reportId),
            details: requestData,
            originalDetails: reportData?.originalDetails,
            isClone: isClone,
            isGeneralReport: isGeneralReport,
            entityChanged: false,
            fetchFromAPI
        }
    }

    dispatch({
        type: UPDATE_REPORT_DETAILS,
        payload: formTabData
    })
    dispatch(toggleIsFetchingReportForm(false));
}

export const runReport = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, reportId, entity, userRunReport }) => async (dispatch) => {
    const response = await aepReport.post("/reportSearchService/execute/reports/" + entity, {
        pageNumber,
        pageSize,
        filterSortObject: filterSort,
        reportId: reportId === undefined ? null : reportId,
        isRunByUser: userRunReport === "fromUser" ? true : false
    });
    batch(() => {
        dispatch({
            type: userRunReport === "fromUser" ? RUN_USER_REPORT : RUN_REPORT,
            payload: [response.data, response.status] || []
        });
        dispatch(toggleIsFetchingReportFlag(false));
        userRunReport === "fromUser" && dispatch(toggleIsFetchingUserReports(false));
    })
}

export const runCommonStandardReport = ({ filterSort, reportId, isExport = false, isRunByUser, reportKey, utcOffset }) => async (dispatch, getState) => {
    const timezone = getState().user.profile.timezone;
    const runByUser = isRunByUser === "fromUser" ? true : false;
    const response = await aepReport.post(`/reportSearchService/v1/runReport/standard/${reportKey}`,
        { filterSortObject: filterSort, reportId: parseInt(reportId), isRunByUser: runByUser },
        { params: { interval: utcOffset, timezone } }
    )
    if (isExport) return response?.data || []
    else if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: runByUser ? RUN_USER_GENERAL_REPORT : RUN_GENERAL_REPORT,
                payload: response?.data || []
            });
            dispatch(toggleIsFetchingReportFlag(false));
            runByUser && dispatch(toggleIsFetchingUserReports(false));
        })
    }
    else {
        batch(() => {
            dispatch({
                type: runByUser ? RUN_USER_GENERAL_REPORT : RUN_GENERAL_REPORT,
                payload: []
            });
            dispatch(toggleIsFetchingReportFlag(false));
            runByUser && dispatch(toggleIsFetchingUserReports(false));
        });
        pushMessage(CONFIG.messageType.error, "Unable to run the report");
    }
}

export const updateRunReportCurrentPage = ({ pageNumber = 1, filterSort, reportId, entity, userRunReport, isGeneralReport, reportName, utcOffset, reportKey }) => async (dispatch, getState) => {
    batch(() => {
        dispatch({
            type: UPDATE_RUN_REPORT_CURRENT_PAGE,
            payload: pageNumber
        });
        dispatch(toggleIsFetchingReportFlag(true));
        isGeneralReport ? dispatch(runCommonStandardReport({ filterSort, reportName, isRunByUser: userRunReport, reportId, utcOffset, reportKey })) : dispatch(runReport({ pageNumber, filterSort, reportId, entity, userRunReport }))
    });
}

export const resetReport = (reportId) => async () => {
    const response = await aepReport.delete("/reportSearchService/user/reports/" + reportId);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Report delete successfully")
    }
    else pushMessage(CONFIG.messageType.error, "Unable to delete the report");
}

export const printReport = (reportId, filterSort, entity, userRunReport) => async (dispatch) => {
    dispatch(toggleIsFetchingReportFlag(true));
    const response = entity !== "Default" ? await aepReport.post("/reportSearchService/execute/reports/" + entity, {
        filterSortObject: filterSort,
        reportId: reportId === undefined ? null : reportId,
        isRunByUser: userRunReport === "fromUser" ? true : false
    }) : undefined
    dispatch(toggleIsFetchingReportFlag(false));
    return response?.data ? response?.data : []
}

export const currentReportFilter = (filters, status, isCustom, pageNumber, pageSize) => {
    return {
        type: isCustom ? LAST_REPORT_FILTER : LAST_USER_REPORT_FILTER,
        payload: [filters, status, pageNumber, pageSize]
    }
}

export const clearReports = () => {
    return {
        type: CLEAR_REPORT
    }
}

export const clearUserReports = () => {
    return {
        type: CLEAR_USER_REPORT
    }
}

export const clearReportOnEntityChange = (id, isClone, isGeneralReport) => {
    return {
        type: CLEAR_REPORT_ON_ENTITY_CHANGE,
        payload: { id, isClone, isGeneralReport }
    }
}

export const fetchAccessForEAPOffline = () => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/eapOffline/access");
    dispatch({
        type: HAS_ACCESS_TO_EAP_OFFLINE,
        payload: response?.status === CONFIG.HTTP_STATUS.OK ? true : false
    });
}

export const updatedSelectedColumns = (columns, id, isClone, isGeneralReport) => dispatch => {
    dispatch({
        type: UPDATE_SELECTED_COLUMNS_FOR_REPORT,
        payload: { columns, id, isClone, isGeneralReport }
    })
}

export const toggleEntityHasChanged = (flag, id, isClone, isGeneralReport) => dispatch => {
    dispatch({
        type: TOGGLE_IS_ENTITY_CHANGED,
        payload: { flag, id, isClone, isGeneralReport }
    })
}

export const resetReportForm = (id, isClone, isGeneralReport) => {
    return {
        type: CLEAR_REPORTS_CONTENT,
        payload: { id, isClone, isGeneralReport }
    }
}

export const getReportById = (reportId) => async (dispatch, getState) => {
    const response = await aepReport.get("/reportSearchService/reports/" + reportId);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
            return {};
        } else return response.data;
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Report');
    }
}

export const exportGeneralReports = async ({ name, filterSort, isRunByUser, reportId, isFirstReport }) => {
    const response = await aepReport({
        method: 'post',
        url: isFirstReport ? `/reportSearchService/v1/export/demoUsagePerQuarterReport` : "/reportSearchService/v1/export/demoUsagePerQuarterReport/demo/solutionType",
        responseType: "blob",
        data: { filterSortObject: filterSort, reportId, isRunByUser }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(name, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}

export const exportCommonStandardReport = ({ name, filterSort, isRunByUser, reportId, reportKey, fileFormat }) => async (dispatch, getState) => {
    const timeZone = getState().user.profile.timezone;
    const utcOffset = moment().tz(timeZone).utcOffset()
    const response = await aepReport({
        method: 'post',
        url: `/reportSearchService/v1/download/${reportKey}`,
        responseType: "blob",
        data: { filterSortObject: filterSort, reportId, isRunByUser },
        params: { interval: utcOffset, timezone: timeZone, fileFormat: fileFormat ?? "xlsx" }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(name, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}