import React from 'react';
import { connect, batch } from 'react-redux';
import { Form, Input, Row, Col, Spin, Popconfirm, Tooltip, Select, Result, Icon, Table, Tag, Upload, Alert, Button, Divider, Modal, Tabs, Descriptions, Popover, Dropdown, Menu, message } from 'antd';
import CONFIG from '../../config';
import TextEditor from '../common/TextEditor';
import { Icon as NeoIcon } from "@avaya/neo-react";
import openConfirm from '../common/Confirm';

import {
    toggleBackButton,
    fetchEmailTemplateVersionsByType,
    updateEditorContentForDynamicFlow,
    updateEmailPreviewEditorContent,
    clearDynamicEmailTemplate,
    clearDynamicEmailPreview,
    clearCustomEmailTemplates,
    fetchPreviewForEmailTemplate,
    postEmailTemplateVersion,
    deleteEmailTemplateVersion,
    updateEmailTemplateDefaultVersion,
    sendEmailToUser,
    toggleIsFetchingEmailTemplateVersions,
    updateManualDemoBuild,
    postDemoRequest,
    toggleCkEditorContentChangedFlag,
    fetchEmailTemplateById,
    closeCurrentOpenTab
} from '../../actions';
import history from '../../history';
import pushMessage from '../common/PushMessage';
import { fetchEmailsBySearchString } from '../../actions/reports';
import { getAllDemosByEventType } from '../../actions/dynamicEmailTemplate';
import { exportEmailTemplateVersion } from "../../actions/emailTemplates";
const { Option } = Select;
const { TabPane } = Tabs;
const { Dragger } = Upload;
const { TextArea } = Input;

class DynamicEmailTemplateFlow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedVersion: undefined,
            recordToClone: undefined,
            recordToEdit: undefined,
            isModalVisible: false,
            defaultActiveKey: "1",
            isTemplateSaved: false,
            hasFormChanged: false,
            hideTemplateTab: false,
            isEdit: false,
            isClone: false,
            file: undefined,
            clonedTitle: undefined,
            clonedDescrption: undefined,
            matchedUserEmails: [],
            disableUpdateButton: false,
            selectedDemo: null,
            demos: [],
            hasCkEditorContentChanged: false
        };
    }

    fetchUserEmails = searchString => {
        fetchEmailsBySearchString({ searchString })
            .then((result) => { result.length !== 0 ? this.setState({ matchedUserEmails: [...new Set(result.map(item => item.email))] }) : this.setState({ matchedUserEmails: [] }) });
    };


    handlePreviewButton = async (versionId) => {
        let statusMgmtObj;
        const { action, data, emailType } = this.props.location;
        if (emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser) {
            const isCommented = action === "declined" ? this.checkIfCommentProvided() : true
            if (!isCommented) {
                pushMessage(CONFIG.messageType.warning, "Please provide a comment before continuing!")
                return;
            }
            else statusMgmtObj = { ...data, notes: this.props.form.getFieldValue("comment") }
        }

        await this.props.fetchEmailTemplateById(versionId, true)
            .then(async () => {
                await this.props.updateEditorContentForDynamicFlow(this.props.currentEmailTemplate?.body)
                this.setState({
                    selectedVersion: this.props.currentEmailTemplate,
                    isModalVisible: true,
                    defaultActiveKey: "3",
                    hideTemplateTab: true
                })
                const subject = this.props.currentEmailTemplate?.subject;
                const updatedRecord = emailType === CONFIG.emailTemplateEventType.manualBuildAvailable ? data : statusMgmtObj
                this.props.fetchPreviewForEmailTemplate(updatedRecord, this.props.currentEmailTemplate, subject, emailType);
            })
    }



    handlePreview = async () => {
        const { action, data, emailType } = this.props.location;
        const { selectedVersion } = this.state
        let statusMgmtObj;
        const subject = this.props?.form?.getFieldsValue(['subject'])?.subject
        if (emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser) {
            const isCommented = action === "declined" ? this.checkIfCommentProvided() : true
            if (!isCommented) {
                pushMessage(CONFIG.messageType.warning, "Please provide a comment before continuing!")
                return;
            }
            else statusMgmtObj = { ...data, notes: this.props.form.getFieldValue("comment") }
        }
        const updatedRecord = emailType === CONFIG.emailTemplateEventType.manualBuildAvailable ? data : statusMgmtObj
        this.setState({
            isModalVisible: true,
            defaultActiveKey: "3",
        })
        this.props.fetchPreviewForEmailTemplate(updatedRecord, selectedVersion, subject, emailType);
    }

    checkIfCommentProvided = () => {
        let comment = this.props.form.getFieldValue("comment");
        comment = comment?.trim();
        const isCommented = comment?.length > 0 ? true : false;
        return isCommented
    }

    handleClone = async (versionId) => {
        const { action } = this.props.location
        const isCommented = action === "declined" ? this.checkIfCommentProvided() : true
        if (!isCommented) {
            pushMessage(CONFIG.messageType.warning, "Please provide a comment before continuing!")
            return;
        }
        this.props.fetchEmailTemplateById(versionId, true)
            .then(async () => {
                await this.props.updateEditorContentForDynamicFlow(this.props.currentEmailTemplate?.body)
                this.setState({
                    isModalVisible: true,
                    selectedVersion: this.props.currentEmailTemplate,
                    isClone: true,
                    disableUpdateButton: true
                })
            })
    }


    handleDeleteVersion = async (record) => {
        const { data, fromManageCustomEmails } = this.props.location
        await this.props.deleteEmailTemplateVersion(record?.id, record?.type, fromManageCustomEmails ? JSON.parse(this.state.selectedDemo)?.id : data?.demo?.id);
    }

    handleEdit = async (versionId) => {
        const { action } = this.props.location
        const isCommented = action === "declined" ? this.checkIfCommentProvided() : true
        if (!isCommented) {
            pushMessage(CONFIG.messageType.warning, "Please provide a comment before continuing!")
            return;
        }
        this.props.fetchEmailTemplateById(versionId, true)
            .then(async () => {
                await this.props.updateEditorContentForDynamicFlow(this.props.currentEmailTemplate?.body)
                this.setState({
                    isModalVisible: true,
                    selectedVersion: this.props.currentEmailTemplate,
                    isEdit: true,
                    disableUpdateButton: true,
                })
            })
    }

    handleSendButton = async () => {
        const okButtonText = "Send"
        const { templateHtml, currentEmailTemplate } = this.props
        this.props.toggleIsFetchingEmailTemplateVersions(true);
        await this.setState({ hasCkEditorContentChanged: currentEmailTemplate?.body === templateHtml ? false : true });
        const { isTemplateSaved, disableUpdateButton, isClone, hasCkEditorContentChanged } = this.state
        if (!this.state.hideTemplateTab && ((isClone && !isTemplateSaved) || !(disableUpdateButton && !hasCkEditorContentChanged))) {
            openConfirm(okButtonText, this.handleSend, () => this.onTabChange("1"), <>There are unsaved changes in the <b>Template</b> section. Do you still wish to send without saving?</>, "Unsaved Changes");
        }
        else this.handleSend()
    }

    handleSend = async () => {


        const isEmailFollowed = true;
        let isRecordUpdated = false;
        const { data, emailType, action } = this.props.location;
        let recepientValues = this.props.form.getFieldsValue(['bcc', 'cc', 'subject'])
        const toRecipient = emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser ? data?.updatedBy?.email : data?.createdBy?.email
        if (emailType === CONFIG.emailTemplateEventType.manualBuildAvailable)
            isRecordUpdated = await this.props.updateManualDemoBuild(data, isEmailFollowed)
        else if (emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser) {
            isRecordUpdated = await this.props.postDemoRequest({ ...data, notes: this.props.form.getFieldValue("comment") }, action, isEmailFollowed)
        }
        const response = isRecordUpdated && await this.props.sendEmailToUser(recepientValues, toRecipient, data?.id, this.state.file, emailType);
        if (response) {
            this.handleCancel()
            if (emailType === CONFIG.emailTemplateEventType.manualBuildAvailable)
                history.push("/manage/builds/manual");
            else if (emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser)
                this.props.closeCurrentOpenTab();
        }
        this.props.toggleIsFetchingEmailTemplateVersions(false);

    }

    handleSaveAs = (isPostVersion) => {
        const { form } = this.props;
        const { data, fromManageCustomEmails } = this.props.location;
        form.validateFields(['title', 'description'], async (err, values) => {
            if (err) {
                return;
            }

            this.setState({ clonedTitle: values?.title, clonedDescrption: values?.description, disableUpdateButton: true })
            this.setState({ hasCkEditorContentChanged: false });
            const response = await this.props.postEmailTemplateVersion(this.state.selectedVersion, values, fromManageCustomEmails ? JSON.parse(this.state.selectedDemo)?.id : data?.demo?.id, isPostVersion)
            if (response !== "FAILED") {
                this.handleEdit(response || this.state.selectedVersion?.id);
            }
            response !== "FAILED" && this.setState({ isTemplateSaved: true })
            this.props.fetchEmailTemplateVersionsByType(fromManageCustomEmails ? JSON.parse(this.state.selectedDemo)?.id : data?.demo?.id, this.state.selectedVersion?.type)
            this.props.toggleIsFetchingEmailTemplateVersions(false)
        })
    }

    handleCancel = () => {
        this.props.clearDynamicEmailTemplate()
        this.props.clearDynamicEmailPreview()
        this.props.clearCustomEmailTemplates()
        this.setState({
            selectedVersion: undefined,
            recordToClone: undefined,
            recordToEdit: undefined,
            isModalVisible: false,
            defaultActiveKey: "1",
            isTemplateSaved: false,
            hasFormChanged: false,
            hideTemplateTab: false,
            isEdit: false,
            isClone: false,
            file: undefined,
            clonedTitle: undefined,
            clonedDescrption: undefined,
            matchedUserEmails: [],
            disableUpdateButton: false
        })
    }

    onTabChange = (value) => {
        this.setState({
            defaultActiveKey: value,
        })
        if (value === "3")
            this.handlePreview()
    }


    handleEmailValidation = (value, field) => {
        if (!value.match(CONFIG.regEx.email)) {
            pushMessage(CONFIG.messageType.warning, "Please input a valid Email ID");
            this.props.form.setFieldsValue({ [field]: this.props.form.getFieldValue(field).slice(0, -1) })
        }
    }

    handleVersionDetailsChange = () => {
        this.setState({ disableUpdateButton: false })
    }


    showErrorPage = () => {
        return (
            <Result
                status="warning"
                title="Sorry, something went wrong!"
            />
        )
    }

    renderWarningMessage = () => {
        return (
            <Alert
                message={<>On click of <b>Save/Update Template</b>, only the changes made to the Template will saved, and not the changes made to the Preview.</>}
                type="warning"
                showIcon
            />
        )
    }



    renderRecipientsDetails = () => {
        const { getFieldDecorator } = this.props.form;
        const { data, emailType, action } = this.props.location;
        const { selectedVersion, matchedUserEmails } = this.state;
        const subjectInitialValue = emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser
            ? selectedVersion?.subject?.replace("[[title]]", data?.demo?.title)?.replace("[[demo_request_status]]", action)
            : selectedVersion?.subject?.replace("[[title]]", data?.demo?.title)
        const toRecipient = emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser ? data?.updatedBy : data?.createdBy
        const uploadProps = {
            multiple: false,
            listType: "picture",
            fileList: this.state.file ? [this.state.file] : [],
            beforeUpload: (file) => {
                const { form } = this.props;
                const isWithinUploadLimit = file.size / 1024 / 1024 < CONFIG.fileSize.dynamicEmailAttachment;
                if (!isWithinUploadLimit) {
                    pushMessage(CONFIG.messageType.warning, `File must be smaller than ${CONFIG.fileSize.dynamicEmailAttachment} MB`)
                    return true;
                }
                this.setState({
                    file
                });
                form.setFields({
                    file: { errors: [] }
                });
                return false;
            },
            onRemove: () => {
                this.setState({ file: undefined })
            }
        };

        const formItemLayout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 14 },
        }
        return (<>
            <Form className='email-recipients-form' layout={formItemLayout}>
                <Form.Item disabled={true} {...formItemLayout} label="To">
                    {getFieldDecorator('to', {
                        rules: [{ required: true, message: 'Recipient cannot be empty!' }],
                        initialValue: `${toRecipient?.fullName} (${toRecipient?.email})`
                    })(<Select disabled={true} mode='tags' showSearch={false} dropdownClassName="select_dropdown" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="CC">
                    {getFieldDecorator('cc')(<Select
                        mode='tags'
                        showSearch
                        placeholder="Optional"
                        onSearch={this.fetchUserEmails}
                        onSelect={(field) => this.handleEmailValidation(field, "cc")}
                        notFoundContent="No Match found!"
                    >
                        {
                            matchedUserEmails?.map((item, index) =>
                                <Option key={index} title={item} value={item}>{item}</Option>
                            )
                        }
                    </Select>)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Bcc">
                    {getFieldDecorator('bcc')(<Select
                        mode='tags'
                        showSearch
                        placeholder="Optional"
                        onSearch={this.fetchUserEmails}
                        onSelect={(field) => this.handleEmailValidation(field, "bcc")}
                        notFoundContent="No Match found!"
                    >
                        {
                            matchedUserEmails?.map((item, index) =>
                                <Option key={index} title={item} value={item}>{item}</Option>
                            )
                        }
                    </Select>)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="Subject">
                    {getFieldDecorator('subject', {
                        rules: [{ required: true, message: 'Subject cannot be empty!', }],
                        initialValue: subjectInitialValue
                    })(<Input />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="File">
                    {
                        getFieldDecorator('file')(
                            <Dragger {...uploadProps}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Maximum upload file size : {CONFIG.fileSize.dynamicEmailAttachment} MB<br />
                                    Allowed Types : ppt, pptx, doc, docx, txt, csv, xlx, xlsx, pdf, epub, mp4, mpg, mpv, avi, gif, jpg, jpeg, png, zip, tar, gz, rar
                                </p>
                            </Dragger>
                        )
                    }
                </Form.Item>
            </Form>
        </>)
    }

    renderEditor = () => {
        const { updateEditorContentForDynamicFlow, updateEmailPreviewEditorContent, form } = this.props;
        const { fromManageCustomEmails } = this.props.location
        const { defaultActiveKey, selectedVersion, hideTemplateTab, file } = this.state;
        const recepientValues = form.getFieldsValue(['to', 'bcc', 'cc', 'subject'])
        return (
            <div className="card-container">
                <Tabs type="card" activeKey={defaultActiveKey} onChange={this.onTabChange}>
                    {!hideTemplateTab && <TabPane tab={<span><Icon type="file-text" />&nbsp;Template</span>} key="1">
                        {selectedVersion === undefined
                            ? this.showErrorPage()
                            :
                            <>
                                <Row className='bottom-spacing-3'>{this.renderWarningMessage()}</Row>
                                <TextEditor
                                    customEmail={true}
                                    updateEditorContent={updateEditorContentForDynamicFlow}
                                    storeKey="dynamicEmailTemplate"
                                    editorKey={this.emailKey}
                                    isEdit={true}
                                    emailType="MANUAL_BUILD_AVAILABLE"
                                    placeholders={selectedVersion?.emailPlaceholders} /></>
                        }
                    </TabPane>}
                    {!fromManageCustomEmails && <TabPane tab={<span><Icon type="team" />&nbsp;Details</span>} disabled={selectedVersion === undefined} key="2" >
                        {this.renderRecipientsDetails()}
                    </TabPane>
                    }
                    {!fromManageCustomEmails && <TabPane tab={<span><Icon type="file-search" />&nbsp;Preview</span>} disabled={selectedVersion === undefined} key="3" >
                        {selectedVersion === undefined
                            ? this.showErrorPage()
                            : <>
                                <Descriptions className="demo-approvers-details" >
                                    {
                                        <>
                                            <Descriptions.Item span={4} label="To">{recepientValues?.to}</Descriptions.Item>
                                            {recepientValues?.cc && <Descriptions.Item span={4} label="Cc">{recepientValues?.cc?.join(', ')}</Descriptions.Item>}
                                            {recepientValues?.bcc && <Descriptions.Item span={4} label="Bcc">{recepientValues?.bcc?.join(', ')}</Descriptions.Item>}
                                            <Descriptions.Item span={4} label="Subject">{recepientValues?.subject}</Descriptions.Item>
                                            {file && <Descriptions.Item span={4} label="Attachments"><Icon type="file-done" />{file?.name}</Descriptions.Item>}
                                        </>
                                    }
                                </Descriptions>
                                <TextEditor
                                    customEmail={true}
                                    updateEditorContent={updateEmailPreviewEditorContent}
                                    storeKey="dynamicEmailPreview"
                                    editorKey={this.emailKeyPreview}
                                    isEdit={true}
                                    emailType="MANUAL_BUILD_AVAILABLE"
                                />
                            </>}
                    </TabPane>
                    }
                </Tabs>
            </div >
        )
    }




    renderModalFooterButtons = () => {
        const { defaultActiveKey, isClone, isTemplateSaved, isEdit, selectedVersion } = this.state
        const { action, fromManageCustomEmails, emailType } = this.props.location

        const sendButtonString = emailType === CONFIG.emailTemplateEventType.demoRequestCompleteUser
            ? action === "approved"
                ? "Approve &"
                : "Decline &"
            : ""
        if (defaultActiveKey === "1") {
            if (isClone || isEdit) {
                return (
                    <>
                        {/* Cancel Button is common for all the actions*/}
                        <Button key="Cancel" onClick={() => this.handleCancel()} className="cancel-button"> Cancel </Button>
                        {(isTemplateSaved || isEdit)
                            ? <Button disabled={selectedVersion === undefined} key="update" onClick={() => this.handleSaveAs()} className="left-spacing primary-action-button-filled" >Update Template</Button>
                            : <Button disabled={selectedVersion === undefined} key="save" onClick={() => { isClone ? this.handleSaveAs(true) : this.handleSaveAs() }} className="left-spacing primary-action-button-filled" >Save Template</Button>}
                        {!fromManageCustomEmails && <Button disabled={selectedVersion === undefined} key="Preview" onClick={() => this.handlePreview()} className="left-spacing primary-action-button-filled" > Preview </Button>}
                    </>)
            }
        }
        else if (defaultActiveKey === "2") {
            return (
                <>
                    {/* Cancel Button is common for all the actions*/}
                    <Button key="Cancel" onClick={() => this.handleCancel()} className="cancel-button"> Cancel </Button>
                    <Button disabled={selectedVersion === undefined} key="Preview" onClick={() => this.handlePreview()} className="left-spacing primary-action-button-filled" > Preview </Button>
                </>)
        }

        else if (defaultActiveKey === "3") {
            return (
                <>
                    {/* Cancel Button is common for all the actions*/}
                    <Button key="Cancel" onClick={() => this.handleCancel()} className="cancel-button"> Cancel </Button>
                    <Button disabled={selectedVersion === undefined || !this.props.isPreviewSuccess || this.props.isFetching} key="send" onClick={() => this.handleSendButton()} className="left-spacing primary-action-button-filled" >{`${sendButtonString} Send`} </Button>
                </>)
        }
    }


    renderEmailTemplateVersions = () => {
        const { demoBuildVersions, user } = this.props;
        const { fromManageCustomEmails, emailType } = this.props.location
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;

        const columns = [
            {
                title: 'Version(s)',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => <span>{record?.title} {(record?.isOriginal || record.demo === null) && <Tag color={CONFIG.colors.success}>Original</Tag>}{record?.isDefault && <Tag color={CONFIG.colors.userDemo}>Recommended</Tag>}</span>,
            },

            {
                title: 'Last Updated By',
                dataIndex: 'lastUpdatedBy',
                align: 'center',
                key: 'lastUpdatedBy',
                render: (text, record) => <span>{record?.lastUpdatedBy !== null ? record?.lastUpdatedBy?.firstName + " " + record?.lastUpdatedBy?.lastName : record?.createdBy !== null ? record?.createdBy?.firstName + " " + record?.createdBy?.lastName : " - "}</span>,
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => {
                    // const isDeleteDisabled = (user?.id !== record?.createdBy?.id || record?.isOriginal)
                    const isDeleteDisabled = (user?.id !== record?.createdBy?.id && !isSuperAdminUser)
                    return (<span className="demo-card-body-details">
                        <Popover content={record?.description} title="Description">
                            <NeoIcon className="link" title="Description" icon="help" style={{ fontSize: "22px" }} />
                        </Popover>
                        <Divider type="vertical" />
                        {!fromManageCustomEmails &&
                            <>
                                <NeoIcon className="link" onClick={() => { this.handlePreviewButton(record?.id) }} title="Preview" icon="email-forwarded" style={{ fontSize: "22px" }} />
                                <Divider type="vertical" />
                            </>
                        }
                        <NeoIcon className="link" onClick={() => { this.handleClone(record?.id) }} title="Clone" icon="copy" style={{ fontSize: "22px" }} />
                        <Divider type="vertical" />
                        <NeoIcon className={record?.isOriginal ? "link-disabled" : "link"} onClick={() => { !record?.isOriginal && this.handleEdit(record?.id) }} disabled={record?.isOriginal} title="Edit" icon="edit" style={{ fontSize: "22px" }} />
                        {<><Divider type="vertical" />
                            <Popconfirm
                                disabled={isDeleteDisabled || record?.isOriginal}
                                placement="right"
                                title="Confirm Delete?"
                                okText="Yes" cancelText="No"
                                onConfirm={() => { this.handleDeleteVersion(record) }}>
                                <Tooltip title={(isDeleteDisabled || record?.isOriginal) ? CONFIG.warningMessages.noAccess : undefined} key={Math.random()}>
                                    <NeoIcon className={(isDeleteDisabled || record?.isOriginal) ? "link-disabled" : "link"} disabled={isDeleteDisabled || record?.isOriginal} icon="trash" style={{ fontSize: "22px" }} />
                                </Tooltip>
                            </Popconfirm></>}
                    </span>)
                }
            }
        ];

        const rowSelection = {

            onChange: (selectedRowKeys, selectedRows) => { this.props.updateEmailTemplateDefaultVersion(selectedRows[0]?.id, emailType, JSON.parse(this.state.selectedDemo)?.id, selectedRows[0]?.isVersion) },

            getCheckboxProps: (record) => ({
                // disabled: record.demo === null,
                checked: record.isDefault
            }),
        };


        return (
            <>
                <Spin spinning={this.props.isFetching}>
                    {!fromManageCustomEmails &&
                        <Row><div style={{ paddingBottom: '20px' }}>
                            <Alert message="You can use an existing template to send email, or clone a template to modify the contents before sending." type="info" showIcon />
                        </div>
                        </Row>
                    }
                    {
                        fromManageCustomEmails && this.renderDemoSelectDropdown()
                    }
                    <Row>
                        {(fromManageCustomEmails) ? <Table
                            rowSelection={isSuperAdminUser ? { type: "radio", ...rowSelection } : undefined}
                            className="build-email-template-table"
                            pagination={false}
                            bordered={false}
                            columns={columns}
                            dataSource={demoBuildVersions}
                            size='small'>
                        </Table>
                            : <Table
                                className="build-email-template-table"
                                pagination={false}
                                bordered={false}
                                columns={columns}
                                dataSource={demoBuildVersions}
                                size='small'>
                            </Table>}
                    </Row>
                </Spin>
            </>
        )
    }

    componentDidMount() {
        const { emailType, fromManageCustomEmails, data } = this.props.location
        const { currentEmailTemplate, templateHtml } = this.props
        this.props.toggleIsFetchingEmailTemplateVersions(true);
        batch(() => {
            this.props.toggleBackButton(false);
            if (fromManageCustomEmails) {
                getAllDemosByEventType(emailType).then((data) => {
                    this.setState({ selectedDemo: JSON.stringify({ id: data?.[0]?.id, title: data?.[0]?.title }), demos: data })
                    this.props.fetchEmailTemplateVersionsByType(data?.[0]?.id, emailType)
                })
            }
            else
                this.props.fetchEmailTemplateVersionsByType(data?.demo?.id, emailType)
            this.emailKey = Math.random().toString();
            this.emailKeyPreview = Math.random().toString();
            this.setState({ hasCkEditorContentChanged: currentEmailTemplate?.body === templateHtml ? false : true });

        });
    }

    componentWillUnmount() {
        this.props.clearDynamicEmailTemplate();
        this.props.clearDynamicEmailPreview();
    }

    handleMenuClick = (e) => {
        const fileType = e.key;
        const demoId = JSON.parse(this.state.selectedDemo)?.id;
        const demoName = JSON.parse(this.state.selectedDemo)?.title;
        const { emailType } = this.props.location;
        const timeZone = this.props.timeZoneName;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportEmailTemplateVersion(CONFIG.exportTable.customEmailTemplate.fileName + `-${demoName}.${fileType}`, fileType, demoId, timeZone, emailType)
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    renderDemoSelectDropdown = () => {
        const { emailType } = this.props.location;
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key={CONFIG.exportFileType.xlsx.ext}>
                    {CONFIG.exportFileType.xlsx.name}
                </Menu.Item>
                <Menu.Item key={CONFIG.exportFileType.csv.ext}>
                    {CONFIG.exportFileType.csv.name}
                </Menu.Item>
            </Menu>
        );
        return <>
            <Row gutter={20}>
                <Col span={10}>
                    <Select
                        onChange={(value) => {
                            this.setState({ selectedDemo: value })
                            this.props.fetchEmailTemplateVersionsByType(JSON.parse(value)?.id, emailType)
                        }
                        }
                        value={this.state.selectedDemo}
                        placeholder="Select a demo"
                    >
                        {this.state.demos?.map((demo, index) => {
                            return <Option key={demo.id} value={JSON.stringify({ id: demo.id, title: demo.title })}>{demo.title}</Option>
                        })
                        }
                    </Select>
                    <br />
                    <br />
                </Col>
                <Col span={2}>
                    <Dropdown overlay={menu}>
                        <Button className="primary-action-button-filled left-spacing-3">
                            Export <Icon type="down" />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </>
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { showComments, action } = this.props.location
        const { isClone, isModalVisible, selectedVersion, clonedTitle, clonedDescrption, hideTemplateTab } = this.state;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }

        return (
            <div className="content-container">
                <Spin spinning={false} wrapperClassName="spin-overlay">
                    {showComments && <div>
                        <Form.Item label="Admin Comment">
                            {
                                getFieldDecorator("comment", {
                                    rules: [
                                        {
                                            required: action === "declined" ? true : false,
                                            message: "Admin comment is mandatory."
                                        }
                                    ]
                                })(<TextArea
                                    allowClear={true}
                                    rows={3} />)
                            }
                        </Form.Item>
                    </div>}
                    <div className="maintenance-steps-content">
                        {this.renderEmailTemplateVersions()}

                        {isModalVisible && <Modal
                            className="scheduler-modal"
                            footer={this.renderModalFooterButtons()}
                            title={
                                <Form layout={formItemLayout} className="email-recipients-form">
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item {...formItemLayout} label="Title">
                                                {getFieldDecorator("title", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please provide a title!"
                                                        }
                                                    ], initialValue: isClone ? clonedTitle : selectedVersion?.title
                                                })(<Input onChange={() => this.handleVersionDetailsChange()} disabled={hideTemplateTab} />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item {...formItemLayout} label="Description">
                                                {getFieldDecorator("description", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please provide a description!"
                                                        }
                                                    ], initialValue: isClone ? clonedDescrption : selectedVersion?.description
                                                })(<Input onChange={() => this.handleVersionDetailsChange()} disabled={hideTemplateTab} />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>}

                            visible={isModalVisible}
                            bodyStyle={{ maxHeight: "78vh" }}
                            onCancel={this.handleCancel}
                            maskClosable={false}
                        >
                            <Spin spinning={this.props.isFetching}>
                                {this.renderEditor()}
                            </Spin>
                        </Modal>}
                    </div>
                </Spin >
            </div >
        );
    }
}

const mapStateToProps = ({ dynamicEmailTemplate, user, dynamicEmail, dynamicEmailPreview }) => {
    return {
        isFetching: dynamicEmailTemplate.isFetching,
        demoBuildVersions: dynamicEmail?.versionsData,
        previewHtml: dynamicEmailPreview?.html,
        templateHtml: dynamicEmailTemplate?.html,
        isPreviewSuccess: dynamicEmailPreview?.isPreviewSuccess,
        user: user.profile,
        currentEmailTemplate: dynamicEmailTemplate.emailTemplate,
        timeZoneName: user.profile.timezone,
    };
}

const LocalUserDynamicEmailTemplateFlowRef = Form.create()(DynamicEmailTemplateFlow);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        fetchEmailTemplateVersionsByType,
        updateEditorContentForDynamicFlow,
        updateEmailPreviewEditorContent,
        clearDynamicEmailTemplate,
        clearDynamicEmailPreview,
        fetchPreviewForEmailTemplate,
        postEmailTemplateVersion,
        deleteEmailTemplateVersion,
        updateEmailTemplateDefaultVersion,
        sendEmailToUser,
        toggleIsFetchingEmailTemplateVersions,
        updateManualDemoBuild,
        postDemoRequest,
        toggleCkEditorContentChangedFlag,
        clearCustomEmailTemplates,
        fetchEmailTemplateById,
        closeCurrentOpenTab
    }
)(LocalUserDynamicEmailTemplateFlowRef);