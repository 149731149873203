import React from 'react';
import { Layout, Tabs, Menu, Dropdown, Modal, Radio, Alert, Divider } from 'antd';
// import { Carousel } from 'antd5'
import { Icon as NeoIcon, Button } from "@avaya/neo-react";
import { connect } from 'react-redux'
import Content from './Content';
import history from '../history';
import CONFIG from '../config.js';
import {
    updateActiveTab,
    deleteTab,
    closeAllTabs,
    closeOtherTabs,
    selectTitle,
    addNewTab,
    openLinkInNewTab,
    restoreTabsOnPageRefresh,
    removeLocalUserTab,
    updateTabsLocalStorage,
    hasSaveChanges,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag,
    resetCreateResource,
    clearHasUnsavedChanges,
    clearSaveReports,
    clearComponentOnTabClose,
    putUserEmailSubscription,
    fetchUserNotifications,
    putUserOptOutCalendarInviteSubscription,
    showPortalTourModal,
    resetReportForm
} from '../actions'
import { isTabAlreadyOpen, shouldShowWarning } from "./common/TabLayout";
import WarningModal from "./common/WarningModal";
import GetStarted from "./GetStarted";
import EZDemoFeedbackModal from './common/EZDemoFeedbackModal';

import PortalTour from './common/PortalTour';

const AntContent = Layout.Content;
const { TabPane } = Tabs;

class TabsLayout extends React.Component {
    carouselRef = React.createRef();

    state = {
        activeKey: null,
        modalOpen: false,
        tabLimitModalOpen: false,
        shouldCloseTab: false,
        unsubscribePreference: undefined,
        showPortalTourGuide: false,
        currentSlide: 0,
    }

    onChange = async newKey => {
        const { activeKey } = this.props;
        const hasTabToBeClosedHasUnsavedChanges = shouldShowWarning(activeKey, true) && this.props.panes?.filter(el => el?.key === activeKey)?.[0]?.hasUnsavedChanges;
        await this.setState({
            activeKey: newKey,
            modalOpen: hasTabToBeClosedHasUnsavedChanges
        })
        if (!hasTabToBeClosedHasUnsavedChanges) {
            await this.props.updateActiveTab(newKey);
            history.replace(newKey)
        }
    };

    onTabClose = async (key) => {
        const { activeKey } = this.props;
        const isClosingTabInView = key === activeKey
        if (key?.includes("/neo/edit/standard") && !isClosingTabInView) {
            const id = key?.split("/").pop();
            await this.props.resetReportForm(id, false, true);
        }
        const hasTabToBeClosedHasUnsavedChanges = shouldShowWarning(activeKey, false) && this.props.panes?.filter(el => el?.key === key)?.[0]?.hasUnsavedChanges
        this.setState({
            activeKey: key,
            modalOpen: hasTabToBeClosedHasUnsavedChanges,
            shouldCloseTab: hasTabToBeClosedHasUnsavedChanges,

        })
        if (!hasTabToBeClosedHasUnsavedChanges) {
            await this.props.deleteTab(key, isClosingTabInView)
            history.replace(this.props.activeKey)
            if (this.props.panes?.length === 0) {
                history.replace('/getStarted');
            }
        }
        await this.props.clearComponentOnTabClose(key);
    }

    handleOk = async () => {
        const { activeKey, shouldCloseTab } = this.state;
        this.props.toggleHasUnsavedChangesFlag(activeKey, false);
        const isCurrentTabClosed = activeKey === this.props.activeKey
        shouldCloseTab && await this.props.deleteTab(activeKey, isCurrentTabClosed)
        history.replace(shouldCloseTab ? this.props.activeKey : activeKey)
        this.props.updateActiveTab(shouldCloseTab ? this.props.activeKey : activeKey);
        this.props.clearHasUnsavedChanges();
        this.setState({
            modalOpen: false,
            shouldCloseTab: false
        });
        if (this.props.panes?.length === 0) {
            history.replace('/getStarted');
            this.props.selectTitle("Get Started");
        }
    }

    handleCancel = () => {
        this.props.hasSaveChanges(false);
        this.setState({ modalOpen: false })
    };

    handleCancelUnsubscribeModal = () => {
        localStorage.removeItem('emailUnsubscribe')
        this.setState({ redirectUnsubscribePage: false })
    }

    handleSubmitUnsubscribe = () => {
        localStorage.removeItem('emailUnsubscribe')
        this.setState({ redirectUnsubscribePage: false })
        switch (this.state.unsubscribePreference) {
            case 1: this.props.putUserEmailSubscription(false);
                break;
            case 2: this.props.putUserOptOutCalendarInviteSubscription(true);
                break;
            case 3: this.props.putUserEmailSubscription(false); this.props.putUserOptOutCalendarInviteSubscription(true)
                break;
            default:
        }
    }

    closeOtherTabs = () => {
        this.props.closeOtherTabs();
    };


    closeAllTabs = () => {
        history.replace('/getStarted');
        this.props.closeAllTabs();
        //Clear the reporting form
        this.props.clearSaveReports();
    };

    handleEmailRedirection = async () => {
        let response;
        const redirectToApprovalPage = localStorage.getItem('redirectToApprovalPage')
        const redirectToFeedbackForm = localStorage.getItem('redirectToFeedbackForm')
        const redirectToEZDemoModal = localStorage.getItem('redirectToEZDemoModal')
        const redirectToCapabilityRequest = localStorage.getItem('redirectToCapabilityRequest')
        const redirectToDemoRequest = localStorage.getItem('redirectToDemoRequest')
        const redirectToNewsSection = localStorage.getItem('news')
        const redirectToSupportSection = localStorage.getItem('support')
        const redirectUnsubscribePage = localStorage.getItem('emailUnsubscribe')

        if (redirectToNewsSection) {
            response = await this.props.openLinkInNewTab('News', `/news`);
            if (response) {
                history.replace(`/news`)
                this.setState({ emailRedirection: true })
            }
        }

        else if (redirectToSupportSection) {
            response = await this.props.openLinkInNewTab('Support', `/support`);
            if (response) {
                history.replace(`/support`)
                this.setState({ emailRedirection: true })
            }
        }

        else if (redirectToApprovalPage) {
            response = await this.props.addNewTab('Schedule Approval', `/emailRedirection/schedule-approvals/details/${localStorage.getItem('scheduleApprovalId')}`);
            if (response) {
                history.replace(`/emailRedirection/schedule-approvals/details/${localStorage.getItem('scheduleApprovalId')}`)
                this.setState({ emailRedirection: true })
            }
        }
        else if (redirectToCapabilityRequest) {
            response = await this.props.addNewTab('Request Details', `/emailRedirection/capability-details/${localStorage.getItem('capabilityRequestId')}`);
            if (response) {
                history.replace(`/emailRedirection/capability-details/${localStorage.getItem('capabilityRequestId')}`);
                this.setState({ emailRedirection: true })
            }
        }

        else if (redirectToDemoRequest) {
            response = await this.props.addNewTab('Request Details', `/emailRedirection/demo-request-details/${localStorage.getItem('demoRequestId')}`);
            if (response) {
                history.replace(`/emailRedirection/demo-request-details/${localStorage.getItem('demoRequestId')}`);
                this.setState({ emailRedirection: true })
            }
        }

        else if (redirectToFeedbackForm) {
            response = await this.props.addNewTab('Feedback', `/emailRedirection/demo-feedback/${localStorage.getItem('scheduleFeedbackId')}`);
            if (response) {
                history.replace(`/demo-feedback/${localStorage.getItem('scheduleFeedbackId')}`);
                this.setState({ emailRedirection: true })
            }
        }

        if (redirectUnsubscribePage) this.setState({ redirectUnsubscribePage: true })
        if (redirectToEZDemoModal) this.setState({ redirectToEZDemoModal: true })

        if (localStorage.getItem('scheduleId') !== null) {
            if (localStorage.getItem('deleteSchedule') || localStorage.getItem('editSchedule') || localStorage.getItem('reSchedule')) {
                if (!isTabAlreadyOpen({ openedTabs: this.props.panes, currentComponentPath: "/dashboard" })) {
                    // We are adding Dashboard panes since the Modal logic for Edit/delete/reschdule is written in Dashboard - index
                    response = await this.props.addNewTab(this.props.isAdmin ? 'Admin Dashboard' : 'User Dashboard', "/dashboard");
                    response && history.replace("/dashboard");
                }
                else this.props.updateActiveTab('/dashboard')
            }
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps?.user?.isLoginComplete !== this.props?.user?.isLoginComplete && this.props?.user?.profile?.backendUser) {
            const openTabsFromLocalStorage = localStorage.getItem('openTabs') !== undefined && JSON.parse(localStorage.getItem('openTabs'));
            const activeKeyFromLocalStorage = localStorage.getItem('openTabsActiveKey') !== undefined && localStorage.getItem('openTabsActiveKey');
            if (this.props.panes?.length === 0 && (openTabsFromLocalStorage?.length === 0 || openTabsFromLocalStorage?.length === undefined)) {
                this.props.addNewTab(this.props.isAdmin ? 'Admin Dashboard' : 'User Dashboard', "/dashboard");
                history.replace("/dashboard");
            }
            else {
                this.props.restoreTabsOnPageRefresh(openTabsFromLocalStorage);
                history.replace(activeKeyFromLocalStorage);
                this.props.updateActiveTab(activeKeyFromLocalStorage);
                localStorage.removeItem('openTabs');
                localStorage.removeItem('openTabsActiveKey');
            }
        }

        // Redirection tabs should not be retained on Refresh. Hence removing them from local storage
        if (this.state.emailRedirection) {
            localStorage.setItem('openTabs', JSON.stringify(this.props.panes?.filter(el => !el?.key.includes("emailRedirection"))));
            localStorage.setItem('openTabsActiveKey', this.props.panes?.[0]?.key);
        }
        else {
            localStorage.setItem('openTabs', JSON.stringify(this.props.panes));
            localStorage.setItem('openTabsActiveKey', this.props.activeKey);
        }
        if (this.props.closeCurrentTab !== undefined) this.onTabClose(this.props.closeCurrentTab);

    }

    async componentDidMount() {
        if (this.props.user?.profile !== undefined) {
            this.props.fetchUserNotifications();
            const openTabsFromLocalStorage = localStorage.getItem('openTabs') !== undefined && JSON.parse(localStorage.getItem('openTabs'));
            const activeKeyFromLocalStorage = localStorage.getItem('openTabsActiveKey') !== undefined && localStorage.getItem('openTabsActiveKey');
            if ((this.props.panes?.length > 0 || (openTabsFromLocalStorage !== undefined && openTabsFromLocalStorage?.length > 0))
                && activeKeyFromLocalStorage !== null) {
                await this.props.restoreTabsOnPageRefresh(openTabsFromLocalStorage);
                await this.props.updateActiveTab(activeKeyFromLocalStorage);
                history.replace(activeKeyFromLocalStorage);
                localStorage.removeItem('openTabs');
                localStorage.removeItem('openTabsActiveKey');
            }
            else {
                if (this.props.isFirstTimeLogin) {
                    await this.props.addNewTab('Welcome', "/my-profile")
                    history.replace("/my-profile");
                }
                else {
                    await this.props.addNewTab(this.props.isAdmin ? 'Admin Dashboard' : 'User Dashboard', "/dashboard")
                    history.replace("/dashboard");
                }
            }
            !this.state.showPortalTourGuide && await this.handleEmailRedirection();
        }
    }

    onRadioButtonSelection = e => {
        this.setState({
            unsubscribePreference: e.target.value,
        });
    };

    handleGetStarted = async () => {
        this.props.showPortalTourModal(false, false);
        this.handleEmailRedirection()
    };

    render() {
        const { activeKey, panes, isFetchingDemo, isPortalTourModalVisible } = this.props;
        const renderTabExtraOptions = (
            <Menu className='renderTabExtraOptions' >
                {panes?.map((item) => {
                    return (
                        <Menu.Item key={item?.key} className='default-cursor font-13px'>
                            <span className='pointer' onClick={() => this.onChange(item?.key)}>{item?.title}</span>
                            <span className='pointer' >
                                <NeoIcon
                                    onClick={() => this.onTabClose(item?.key)}
                                    icon="close"
                                    aria-label="Close"
                                    style={{ color: `${CONFIG.colors.primaryColor}`, float: "right", fontSize: "25px", paddingLeft: "15px" }} />
                            </span>
                        </Menu.Item>
                    )
                })}
                <Divider className="notification-divider grey-divider" />
                <Menu.Item className=' bold font-13px' onClick={this.closeOtherTabs}>Close other tabs</Menu.Item>
                <Menu.Item className=' bold font-13px' onClick={this.closeAllTabs}>Close all tabs</Menu.Item>
            </Menu>
        );
        const { isLoginComplete, isFirstTimeLogin } = this.props.user;
        const isLoginPage = window.location.pathname?.includes("/non-sso-users") || window.location.pathname?.includes("/home")
        const isTabOpened = !isTabAlreadyOpen({ openedTabs: this.props.panes, currentComponentPath: this.props.activeKey });


        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        return (
            <>
                <AntContent>
                    {this.props.panes?.length > 0 && isLoginComplete ? <Tabs
                        tabBarExtraContent={!isFirstTimeLogin && <>

                            <Dropdown overlay={renderTabExtraOptions} trigger={['click']}>
                                <NeoIcon title="Opened Tabs" icon="ellipses-horizontal" style={{ fontSize: "20px", color: "#1473e6" }} />
                            </Dropdown>
                        </>}
                        className='tabbed-layout font'
                        hideAdd
                        size="small"
                        style={{ marginBottom: 5 }}
                        onChange={this.onChange}
                        activeKey={activeKey}
                        type={isFirstTimeLogin ? "card" : "editable-card"}
                        tabBarGutter={-5}
                        onEdit={this.onTabClose}
                    >
                        {this.props.panes?.filter((item, index, self) =>
                            index === self.findIndex((t) => (
                                t.title === item.title && t.key === item.key
                            ))
                        )?.map((pane, index) => {
                            const ellipsisText = pane.key !== activeKey && pane.title?.length > 20 ? pane.title.substring(0, 20) + "..." : pane.title?.length > 40 ? pane.title.substring(0, 40) + "..." : pane.title;
                            return (
                                <TabPane
                                    className='tab-pane-container font'
                                    id="tab-pane"
                                    forceRender={false}
                                    disabled={isFirstTimeLogin || isFetchingDemo}
                                    tab={<span
                                        disabled={isFirstTimeLogin || isFetchingDemo}
                                        style={(isFirstTimeLogin || isFetchingDemo) ? { cursor: "not-allowed" } : { cursor: "pointer" }}
                                        title={pane.title}>
                                        {<span className='tabbed-layout-tab-title'>{ellipsisText}</span>}

                                    </span>}
                                    key={pane.key}>
                                    {pane.key === activeKey &&
                                        !isTabOpened &&
                                        <div className='content'>
                                            <Content
                                                isLoginComplete={isLoginComplete}
                                                activeKey={this.props.activeKey}
                                                isAdmin={this.props.isAdmin}
                                                isFirstTimeLogin={isFirstTimeLogin}
                                            >
                                            </Content>

                                        </div>}
                                </TabPane>
                            )
                        })}
                    </Tabs>
                        : !isLoginPage && <GetStarted />
                    }
                    {!isLoginComplete && <Content isLoginComplete={isLoginComplete} />}
                    <WarningModal
                        handleOk={this.handleOk}
                        handleCancel={this.handleCancel}
                        modalOpen={this.state.modalOpen}
                    />
                    {this.state.redirectToEZDemoModal && <EZDemoFeedbackModal
                        // showFeedbackModal={this.state.redirectToEZDemoModal}
                        feedbackAuditId={localStorage.getItem('ezDemoFeedbackId')}
                        title={decodeURIComponent(localStorage.getItem('ezDemoTitle'))}
                        redirectFromEmail={true}
                    />}
                    <Modal
                        className='unsubscribe-modal'
                        visible={this.state.redirectUnsubscribePage}
                        onCancel={this.handleCancelUnsubscribeModal}
                        title="Unsubscribe Emails"
                        footer={[
                            <Button aria-label="button" key="back" onClick={this.handleCancelUnsubscribeModal}>
                                Cancel
                            </Button>,
                            <Button aria-label="button" disabled={this.state.unsubscribePreference === undefined} key="submit" className="primary-action-button-filled" type="primary" onClick={this.handleSubmitUnsubscribe}>
                                Unsubscribe
                            </Button>,
                        ]}
                    >
                        <p>Your email address is <strong>{this.props.user?.profile?.email}</strong>.</p>
                        <p>You will not receive any more emails from <strong>donotreply@experience.avaya.com</strong></p>
                        <br />
                        <p>You can:</p>
                        <Radio.Group onChange={this.onRadioButtonSelection} value={this.state.unsubscribePreference}>
                            <Radio style={radioStyle} value={1}>
                                Unsubscribe from emails about Demo Updates, News, Notifications and Schedule Maintenances.
                            </Radio>
                            <Radio style={radioStyle} value={2}>
                                Unsubscribe from calendar invites and reminders.
                            </Radio>
                            <Radio style={radioStyle} value={3}>
                                Unsubscribe from all emails from Experience Avaya Portal.
                            </Radio>
                            <br />
                            {this.state.unsubscribePreference !== undefined && <Alert
                                message="Note"
                                description={this.state.unsubscribePreference === 1
                                    ? CONFIG.myProfile.unsubscribePortalEmails
                                    : this.state.unsubscribePreference === 2
                                        ? CONFIG.myProfile.unsubscribeCalendar
                                        : CONFIG.myProfile.unsubscribeAllEmails}
                                type="info"
                                showIcon
                            />}
                        </Radio.Group>
                    </Modal>
                    {isPortalTourModalVisible && <PortalTour fromApp={true} handleGetStarted={this.handleGetStarted} />}
                </AntContent >

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        panes: state.tabsLayout.panes,
        activeKey: state.tabsLayout.activeKey,
        showNewTabWindow: state.tabsLayout.showNewTabWindow,
        isLoginComplete: state.user.isLoginComplete,
        isPortalTourModalVisible: state.user.showPortalTourModal,
        user: state.user,
        isFirstTimeLogin: state.user.isFirstTimeLogin,
        timeZoneName: state.user.profile?.timezone,
        hasSave: state.tabsLayout.hasSave,
        closeCurrentTab: state.tabsLayout.closeCurrentTab,
        isFetchingDemo: state.demos.isFetching,
        isAdmin: state.user.isAdmin,
    };
};

export default connect(mapStateToProps, {
    updateActiveTab,
    deleteTab,
    closeAllTabs,
    closeOtherTabs,
    selectTitle,
    addNewTab,
    fetchUserNotifications,
    openLinkInNewTab,
    restoreTabsOnPageRefresh,
    removeLocalUserTab,
    updateTabsLocalStorage,
    hasSaveChanges,
    closeCurrentOpenTab,
    toggleHasUnsavedChangesFlag,
    resetCreateResource,
    clearHasUnsavedChanges,
    clearSaveReports,
    clearComponentOnTabClose,
    putUserEmailSubscription,
    putUserOptOutCalendarInviteSubscription,
    showPortalTourModal,
    resetReportForm
})(TabsLayout);
