import React from 'react';
import CONFIG from '../config';
import { fetchUserNewsAccess, fetchUserNotificationAccess, fetchUserDemoAccess } from '../actions';
import pushMessage from "../components/common/PushMessage";

export const getNotificationObj = (notification) => {
    const contentObj = notification?.content?.split("||");
    const isMaintenance = CONFIG.notificationTypes.maintenance.includes(contentObj[0])
    const isResourceStatusChange = CONFIG.notificationTypes.dcResourceUpdate[0] === contentObj[0]
    const isDcStatusChange = CONFIG.notificationTypes.dcResourceUpdate[1] === contentObj[0]
    const isRating = CONFIG.notificationTypes.rating.includes(contentObj[0])
    const key1 = (isMaintenance || isResourceStatusChange || isDcStatusChange) ? "startTime" : "id";
    const key2 = (isMaintenance || isResourceStatusChange || isDcStatusChange) ? "endTime" : "extraText";

    const genericNotificationAttributes = {
        ...notification,
        notificationAging: notification.notificationAging,
        timestamp: notification.timestamp,
        messageId: notification.id,
        messageType: contentObj[0].trim() || "",
        content: contentObj,
        type: notification?.type,
    }

    if (notification?.type === CONFIG.pushNotificationTypes.SCHEDULE_PARTICIPANT_ADDED) {
        return {
            ...genericNotificationAttributes,
            title: contentObj[1],
            tag: contentObj[2],
            resourceTitle: contentObj[3],
            startTime: contentObj[4],
            endTime: contentObj[5],
        }
    }

    else if (CONFIG.ezDemoRatingNotificationTypes.includes(notification?.type)) {
        return {
            ...genericNotificationAttributes,
            title: contentObj[1],
            tag: contentObj[0],
            ezDemoId: contentObj[2],
            ezDemoAuditId: contentObj[3],
        }
    }
    else if (notification?.type === CONFIG.pushNotificationTypes.DEMO_MEETING_ON_BEHALF_OF_USER
        || notification?.type === CONFIG.pushNotificationTypes.SCHEDULE_UPDATE_ON_BEHALF_OF_USER
        || notification?.type === CONFIG.pushNotificationTypes.SCHEDULE_DELETE_ON_BEHALF_OF_USER)
        return {
            ...genericNotificationAttributes,
            title: contentObj[1],
            tag: contentObj[2],
            body: contentObj[3] // JSX is decided by the BE
        }
    else if (CONFIG.capabilityNotificationTypes?.includes(notification?.type)) {
        return {
            ...notification,
            timestamp: notification.timestamp,
            notificationAging: notification.notificationAging,
            messageId: notification.id,
            messageType: contentObj[0].trim() || "",
            title: `${contentObj[3]} - ${contentObj[1]}` || "",
            tag: contentObj[2] || "",
            demo: contentObj[1],
            capability: contentObj[3],
            comment: contentObj[5],
        }
    }
    else
        return {
            ...notification,
            timestamp: notification.timestamp,
            notificationAging: notification.notificationAging,
            messageId: notification.id,
            messageType: contentObj[0].trim() || "",
            title: isMaintenance ? contentObj[2] : contentObj[1] || "",
            tag: !isRating ? isMaintenance ? contentObj[3] : contentObj[2] || "" : "",
            description: (isResourceStatusChange || isDcStatusChange) ? contentObj[6] : isMaintenance ? contentObj[4] : contentObj[3] || "",
            additionalDetails: (isResourceStatusChange || isDcStatusChange) ? contentObj[4] : isMaintenance ? contentObj[1] : "",
            [key1]: isMaintenance ? contentObj[5] + "Z" : (isResourceStatusChange || isDcStatusChange) ? contentObj[7] : contentObj[4] || "",
            [key2]: isMaintenance ? contentObj[6] + "Z" : (isResourceStatusChange || isDcStatusChange) ? contentObj[8] : contentObj[5] || "",
            elementId: notification.elementId,
            demoId: (isResourceStatusChange || isDcStatusChange) ? contentObj[7] : isRating ? contentObj[2] : "",
            scheduleId: (isResourceStatusChange || isDcStatusChange) ? contentObj[8] : isRating ? contentObj[3] : "",
            dcResourceTitle: isResourceStatusChange ? contentObj[3] : ""
        }
}

const handleDemoRedirection = (notificationId, pathname, toggleBackButton, fetchSolutionDemo, resetPageNumber, handleDropdownClose, openLinkInNewTab) => {
    resetPageNumber && resetPageNumber()
    toggleBackButton(false);
    handleDropdownClose && handleDropdownClose();
    openLinkInNewTab("Notifications Demo ", `/solutions/demo/${notificationId}`);
}

const handleRatingRedirection = (notification, toggleBackButton, fetchSolutionDemo, resetPageNumber, handleDropdownClose, openLinkInNewTab) => {
    resetPageNumber && resetPageNumber()
    toggleBackButton(false);
    handleDropdownClose && handleDropdownClose();
    const demoId = parseInt(notification.demoId);
    const scheduleId = parseInt(notification.scheduleId);
    notification.messageType === CONFIG.notificationTypes.rating[0]
        ? fetchSolutionDemo(notification.demoId)
            .then(() => {
                // here 2nd last param is isRatingRedirect and last param is isLowRating
                openLinkInNewTab("Demo Ratings - " + notification?.title, `/solutions/demo/${demoId}/${scheduleId}/${true}/${false}`)
            })
        : openLinkInNewTab("Demo Ratings - " + notification?.title, `/demo-ratings/${demoId}/${scheduleId}/${true}/${true}`)
}

const handleNewsRedirection = (notificationId, pathname, toggleBackButton, fetchUserNews, resetPageNumber, handleDropdownClose, openLinkInNewTab) => {
    resetPageNumber && resetPageNumber();
    handleDropdownClose && handleDropdownClose();
    fetchUserNews(true, notificationId)
        .then(() => {
            if (pathname !== "/news") {
                if (pathname !== "/inactive-news") toggleBackButton(false, pathname);
                openLinkInNewTab("News Notification", `/news/${notificationId?.id}`)
            }
        })
}

export const handleViewDetails = (e, notification, pathname, handleModalOpen, toggleBackButton, fetchUserNews, fetchSolutionDemo, resetPageNumber, handleDropdownClose, access, fromRealTime, openLinkInNewTab) => {
    const notificationId = notification.id !== CONFIG.manage ? notification.id : fromRealTime ? notification.id : notification.elementId;
    switch (notification.messageType) {
        case CONFIG.notificationTypes.maintenance[0]:
        case CONFIG.notificationTypes.maintenance[1]:
        case CONFIG.notificationTypes.notification[0]:
        case CONFIG.notificationTypes.notification[1]:
        case CONFIG.notificationTypes.adminSchedule[0]:
        case CONFIG.notificationTypes.adminSchedule[1]:
        case CONFIG.notificationTypes.adminSchedule[2]:
        case CONFIG.notificationTypes.dcResourceUpdate[0]:
        case CONFIG.notificationTypes.dcResourceUpdate[1]:
        case CONFIG.notificationTypes.participantAddedSchedule[0]:
        case CONFIG.notificationTypes.capabilityRequests[0]:
        case CONFIG.notificationTypes.capabilityRequests[1]:
        case CONFIG.notificationTypes.capabilityRequests[2]:
            return handleModalOpen({ closeDropDown: fromRealTime })
        case CONFIG.notificationTypes.ezDemoRating[0]:
        case CONFIG.notificationTypes.ezDemoRating[1]:
            return handleModalOpen({ closeDropDown: true })
        case CONFIG.notificationTypes.demo[0]:
        case CONFIG.notificationTypes.demo[1]:
            if ((notificationId === CONFIG.manage && !access?.userAccess) || (notificationId === CONFIG.manage && fromRealTime)) return handleModalOpen({ closeDropDown: fromRealTime })
            else handleDemoRedirection(notificationId, pathname, toggleBackButton, fetchSolutionDemo, resetPageNumber, handleDropdownClose, openLinkInNewTab)
            return
        case CONFIG.notificationTypes.news[0]:
        case CONFIG.notificationTypes.news[1]:
            if ((notificationId === CONFIG.manage && !access?.userAccess) || (notificationId === CONFIG.manage && fromRealTime)) return handleModalOpen({ closeDropDown: fromRealTime })
            else handleNewsRedirection(notification, pathname, toggleBackButton, fetchUserNews, resetPageNumber, handleDropdownClose, openLinkInNewTab)
            return
        case CONFIG.notificationTypes.rating[0]:
        case CONFIG.notificationTypes.rating[1]:
            handleRatingRedirection(notification, toggleBackButton, fetchSolutionDemo, resetPageNumber, handleDropdownClose, openLinkInNewTab)
            return
        default: return null
    }
}

/**
    * 
    * @param {*} data 
    * handleAccessForNotification() function decides the access for the particular item and
   data is the response from the API call
    */
export const handleAccessForNotification = (data) => {
    if (data?.userAccess > 0 && data?.adminAccess > 0) {
        return {
            userAccess: true,
            adminAccess: true
        };
    }
    else if (data?.userAccess === 0 && data.adminAccess > 0) {
        return {
            userAccess: false,
            adminAccess: true
        };
    }
    else if (data?.userAccess > 0 && data.adminAccess === 0) {
        return {
            userAccess: true,
            adminAccess: false
        };
    }
    else if (data?.length === 0) {
        return {
            userAccess: false,
            adminAccess: false,
        };
    }
    else return {
        userAccess: false,
        adminAccess: false,
    };
}

export const checkNotificationValid = (data) => { //to check the 
    if (data === CONFIG.unableToFetch)
        pushMessage(CONFIG.messageType.error, CONFIG.unableToFetch);

    else {
        return handleAccessForNotification(data);
    }
}
/**
 * 
 * @param {*} notification 
 * Calling the API based on Type of Notification
 */
const handleNotificationType = async (notification, type) => {
    let data;
    const notificationId = notification.id;

    if (type === "News")
        data = await fetchUserNewsAccess(notificationId);

    else if (type === "Demos")
        data = await fetchUserDemoAccess(notificationId);

    else if (type === "Notifications")
        data = await fetchUserNotificationAccess(notificationId)

    return checkNotificationValid(data)

}

export const getAccessList = (contents, type, pathname, toggleBackButton, getUserItem, handleModalOpen, setSelectedNotification, getAdminItem, resetPageNumber, handleDropdownClose, isUserManagementChange) => {
    const messageTypes = {
        Demos: 'DEMO',
        Notifications: 'NOTIFICATION',
        News: 'NEWS'
    }

    const handleOnClick = async (notification) => {
        handleDropdownClose && handleDropdownClose();
        let access = await handleNotificationType(notification, type);
        const alertMessage = `You are no longer authorized to view this ${type.toLowerCase().slice(0, -1)} or this ${type.toLowerCase().slice(0, -1)} has been removed `;

        if (type === CONFIG.accessNotificationTypes.notifications) {
            if (access.adminAccess || access.userAccess) {
                getAdminItem(notification.id)
                    .then((item) => {
                        setSelectedNotification({ ...notification, description: item.description, messageType: messageTypes[type], access: true });
                        handleModalOpen({ closeDropDown: false })
                    })
            }
            else {
                setSelectedNotification({ ...notification, description: alertMessage, messageType: messageTypes[type], access: false });
                handleModalOpen({ closeDropDown: false })
            }
        }
        else {
            if ((access.userAccess && access.adminAccess) || (access.userAccess && !access.adminAccess)) {
                type === "Demos" ? handleDemoRedirection(notification.id, pathname, toggleBackButton, getUserItem, resetPageNumber, handleDropdownClose) :
                    handleNewsRedirection(notification.id, pathname, toggleBackButton, getUserItem, resetPageNumber, handleDropdownClose);
            }
            else if (!access.userAccess && access.adminAccess) {
                getAdminItem(notification.id)
                    .then((item) => {
                        const description = type === "News" ? item.intro : item.description;
                        setSelectedNotification({ ...notification, description: description, messageType: messageTypes[type], access: true });
                        handleModalOpen({ closeDropDown: false })
                    })
            }
            else if (!access.userAccess && !access.adminAccess) {
                setSelectedNotification({ ...notification, description: alertMessage, messageType: messageTypes[type], access: false });
                handleModalOpen({ closeDropDown: false })
            }
        }
    }

    return contents &&
        <>
            <h4>{type}:</h4>
            {
                contents?.map((content, index) => {
                    return <div key={index}>
                        <ul>
                            <li key={index}>
                                <span class={!isUserManagementChange && "link"} onClick={() => !isUserManagementChange && handleOnClick(content)}>{content.title}</span>
                            </li>
                        </ul>
                    </div>
                })
            }
        </>
}

