import {
    UPDATE_DATA_CENTER_STATUS_EDITING_KEY,
    TOGGLE_IS_FETCHING_DATA_CENTER_STATUS,
    FETCH_ALL_DATA_CENTER_STATUS,
    CLEAR_DEMO_DC_RESPONSE,
    CLEAR_DEMO_DC_STATUS,
    FETCH_USER_DATA_CENTER_STATUS,
    TOGGLE_IS_FETCHING_USER_DATA_CENTER_STATUS,
    LAST_DEMO_STATUS_FILTER,
    CLEAR_DEMO_DC_STATUS_TABLE_DATA,
} from "./types"
import { aepDemo } from "../apis";
import pushMessage from '../components/common/PushMessage';
import CONFIG from '../config';

export const updateDataCenterStatusEditingKey = (key = "") => {
    return {
        type: UPDATE_DATA_CENTER_STATUS_EDITING_KEY,
        payload: key
    };
};

export const toggleIsFetchingDataCenterStatus = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DATA_CENTER_STATUS,
        payload: flag
    }
}

export const toggleIsFetchingUserDataCenterStatus = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_USER_DATA_CENTER_STATUS,
        payload: flag
    }
}

export const fetchAllDemoStatus = (status, filterSort) => async dispatch => {
    const contextPath = "/demoSolutionService/v1/lite/statusmgmt";
    const response = await aepDemo.get(contextPath, {
        params: {
            status,
            filterSort
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ALL_DATA_CENTER_STATUS,
            payload: response?.data || []
        })
    }
    else {
        dispatch({
            type: CLEAR_DEMO_DC_RESPONSE,
            payload: response.status
        })
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Demo status');
    }
    dispatch(toggleIsFetchingDataCenterStatus(false));
}

export const getDemoStatusDetails = (statusMgmtId) => async () => {
    const response = await aepDemo.get(`/demoSolutionService/v1/lite/statusMgmt/${statusMgmtId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        return response?.data ? response?.data : []
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch demo status details");
}

export const fetchAllDataCenterStatus = ({ pageNumber, pageSize = 12, status, filterSort }) => async (dispatch) => {
    const contextPath = '/demoSolutionService/statusMgmt';
    const response = await aepDemo.get(contextPath, {
        params: {
            pageSize,
            pageNumber,
            status,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                }
            }
        }
    })

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ALL_DATA_CENTER_STATUS,
            payload: [response.data, response.status] || []
        })
    }
    else {
        dispatch({
            type: CLEAR_DEMO_DC_RESPONSE,
            payload: response.status
        })
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Demo status');
    }
    dispatch(toggleIsFetchingDataCenterStatus(false));
}

export const fetchUsersForDemoDataCenter = (record) => async (dispatch) => {
    const demoId = record?.demo?.id;
    const resourceId = record?.resource?.id;
    const demoType = (record?.type === "Demo DC" || record?.type === "Request") ? true : false;
    const requestURL = `/demoSolutionService/demos/${demoId}/userList/search`
    const response = await aepDemo({
        method: "GET",
        url: requestURL,
        params: { searchString: "", resourceId: demoType ? undefined : resourceId }
    })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_USER_DATA_CENTER_STATUS,
            payload: response.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch user for this demo');
    dispatch(toggleIsFetchingUserDataCenterStatus(false));
}

export const putAssignedUserDataCenter = (data, isSubmit = false) => async (dispatch) => {
    const contextPath = '/demoSolutionService/statusMgmt'
    const response = await aepDemo({
        method: 'put',
        url: isSubmit ? `${contextPath}/${data.id}?isSubmit=true` : `${contextPath}/${data.id}`,
        data: !isSubmit ? data : undefined
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        pushMessage(CONFIG.messageType.success, isSubmit ? "Demo Registration successful" : "Record updated successfully")
    }
    else pushMessage(CONFIG.messageType.error, isSubmit ? "Unable to register demo" : 'Unable to update record');
    if (response) {
        if (response.status === CONFIG.HTTP_STATUS.OK) {
            return true;
        }
        else return false;
    }
    else return false;
}

export const getAllDemosDataCenterStatus = ({ filterSort, status }) => async () => {
    const contextPath = '/demoSolutionService/statusMgmt';
    const response = await aepDemo.get(contextPath, {
        params: {
            status,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                }
            }
        }
    })
    return response.data ? response.data.dataSet : [];
}


export const clearDCData = () => {
    return {
        type: CLEAR_DEMO_DC_STATUS
    }
}


export const postDemoRequest = (data, action, isEmailFollowed) => async (dispatch) => {
    const response = await aepDemo({
        method: 'put',
        url: `/demoSolutionService/statusMgmt/${data?.id}?isSubmit=true`,
        data
    });
    const actionName = action === 'approved' ? "Approve" : "Decline"
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        !isEmailFollowed && pushMessage(CONFIG.messageType.success, `Demo ${actionName} successful`)
    }
    else pushMessage(CONFIG.messageType.error, `Unable to ${actionName} demo`);
    if (response) {
        if (response.status === CONFIG.HTTP_STATUS.OK) {
            return true;
        }
        else return false;
    }
    else return false;
}

export const currentDataCenterFilter = (filters, status, pageNumber, pageSize) => {
    return {
        type: LAST_DEMO_STATUS_FILTER,
        payload: [filters, status, pageNumber, pageSize]
    }
}

export const clearDemoDCStatusTableData = () => {
    return {
        type: CLEAR_DEMO_DC_STATUS_TABLE_DATA
    };
};
