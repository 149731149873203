import React from "react";
import { Input, Form, Select, Switch, Tooltip, Icon } from "antd";
import { ManageDIDsContext } from "./";
const { Option } = Select;

export class DIDTableCell extends React.Component {


    renderDidIdInput = (dataIndex, record, getFieldDecorator) => {
        return (
            <Form.Item>
                {
                    getFieldDecorator('didId', {
                        rules: [
                            {
                                required: true,
                                message: `Please enter DID!`
                            }
                        ],
                        initialValue: record[dataIndex]
                    })(<Input className="top-spacing" />)
                }
            </Form.Item>
        );
    }

    renderNetworkEnvironments = () => {
        const { networkEnvironments } = this.props;
        return networkEnvironments
            ? networkEnvironments.map((env, index) => <Option key={index} value={env}>{env}</Option>)
            : [];
    }

    renderLabInput = (dataIndex, record, getFieldDecorator) => {
        return (
            <Form.Item>
                {
                    getFieldDecorator('lab', {
                        initialValue: record[dataIndex]
                    })(<Select showSearch={true}>
                        <Option value="">None</Option>
                        {this.renderNetworkEnvironments()}
                    </Select>)
                }
            </Form.Item>
        );
    }

    renderProviders = () => {
        const { providers } = this.props;
        return providers
            ? providers.map((provider, index) => <Option key={index} value={JSON.stringify({ id: provider.id, title: provider.title, active: provider.active })}>
                <div>
                    {provider.title}
                    {
                        <span className="float-right">
                            <Tooltip title={provider.active ? "Active" : "Inactive"} key={provider.id}>
                                {
                                    provider.active
                                        ? <Icon type="check" className="small-text success-text" />
                                        : <Icon type="warning" className="small-text warning-text" />
                                }
                            </Tooltip>
                        </span>
                    }
                </div>
            </Option>)
            : [];
    }

    renderProviderInput = (dataIndex, record, getFieldDecorator) => {
        return (
            <Form.Item>
                {
                    getFieldDecorator('provider', {
                        initialValue: record[dataIndex] ? JSON.stringify({ id: record[dataIndex]?.id, title: record[dataIndex]?.title, active: record[dataIndex]?.active }) : undefined
                    })(<Select showSearch={true}>
                        <Option value="">None</Option>
                        {this.renderProviders()}
                    </Select>)
                }
            </Form.Item>
        );
    }

    renderUsageInput = (dataIndex, record, getFieldDecorator) => {
        return (
            <Form.Item>
                {
                    getFieldDecorator('usage', {
                        rules: [
                            {
                                required: true,
                                message: `Please enter description!`
                            }
                        ],
                        initialValue: record[dataIndex]
                    })(<Input className="top-spacing" />)
                }
            </Form.Item>
        );
    }

    renderAssignedInput = (dataIndex, record, getFieldDecorator) => {
        return (
            <Form.Item>
                {
                    getFieldDecorator('assigned', {
                        initialValue: record[dataIndex] ? true : false,
                        valuePropName: 'checked'
                    })(
                        <Switch checkedChildren="Yes" unCheckedChildren="No" />
                    )
                }
            </Form.Item>
        );
    };

    renderCellInput = (dataIndex, record, getFieldDecorator) => {
        if (dataIndex === 'usage') return this.renderUsageInput(dataIndex, record, getFieldDecorator);
        else if (dataIndex === 'lab') return this.renderLabInput(dataIndex, record, getFieldDecorator);
        else if (dataIndex === 'assigned') return this.renderAssignedInput(dataIndex, record, getFieldDecorator);
        else if (dataIndex === 'did') return this.renderDidIdInput(dataIndex, record, getFieldDecorator);
        else if (dataIndex === 'provider') return this.renderProviderInput(dataIndex, record, getFieldDecorator);
    }

    renderCell = ({ getFieldDecorator }) => {
        const { editing, dataIndex, title, inputType, record, children, ...restProps } = this.props;
        return (
            <td {...restProps}>
                {
                    editing
                        ? this.renderCellInput(dataIndex, record, getFieldDecorator)
                        : children
                }
            </td>
        );
    };

    render() {
        return <ManageDIDsContext.Consumer>{this.renderCell}</ManageDIDsContext.Consumer>;
    }
}
