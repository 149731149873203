import {
    UPDATE_DEMO_REQUEST_EDITING_KEY,
    CLEAR_DEMO_REQUEST_DATA,
    TOGGLE_IS_FETCHING_DEMO_REQUEST_USER_STATUS,
    LAST_DEMO_REQUEST_FILTER,
    UPDATE_DEMOS_REQUEST_CURRENT_PAGE,
    FETCH_DEMO_REQUEST,
    FETCH_ALL_DEMO_REQUESTS,
    FETCH_USER_DEMO_REQUEST,
    TOGGLE_IS_FETCHING_DEMO_REQUESTS
} from "./types";

import { aepDemo } from "../apis";
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';


export const fetchDemoRequests = ({ status, filterSort }) => async (dispatch) => {
    const contextPath = '/demoSolutionService/v1/lite/statusmgmt';
    const response = await aepDemo.get(contextPath, {
        params: {
            status,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                }
            }
        }
    })

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ALL_DEMO_REQUESTS,
            payload: response.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Demo Requests');
    dispatch(toggleIsFetchingDemoRequest(false));
}

export const fetchUsersForDemoRequest = (record) => async (dispatch) => {
    const demoId = record?.demo?.id;
    const requestURL = `/demoSolutionService/demos/${demoId}/userList/search`
    const response = await aepDemo({
        method: "GET",
        url: requestURL,
        params: { searchString: "" }
    })
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_USER_DEMO_REQUEST,
            payload: response.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch user for this demo');
    dispatch(toggleIsFetchingDemoRequestUsers(false));
}

export const getAllDemosRequests = ({ filterSort, status }) => async () => {
    const contextPath = '/demoSolutionService/statusMgmt';
    const response = await aepDemo.get(contextPath, {
        params: {
            status,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                }
            }
        }
    })
    return response.data ? response.data.dataSet : [];
}

export const updateDemoRequestEditingKey = (key = "") => {
    return {
        type: UPDATE_DEMO_REQUEST_EDITING_KEY,
        payload: key
    };
};

export const clearDemoRequestData = () => {
    return {
        type: CLEAR_DEMO_REQUEST_DATA
    }
}

export const toggleIsFetchingDemoRequest = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DEMO_REQUESTS,
        payload: flag
    }
}

export const toggleIsFetchingDemoRequestUsers = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DEMO_REQUEST_USER_STATUS,
        payload: flag
    }
}

export const currentDemoRequestFilter = (filterSort, status) => {
    return {
        type: LAST_DEMO_REQUEST_FILTER,
        payload: [filterSort, status]
    }
}

export const updateDemoRequestCurrentPage = (currentPageNumber, pageSize, requestId, requestIndex) => {
    return {
        type: UPDATE_DEMOS_REQUEST_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, requestId, requestIndex]
    }
};

export const getDemoRequestById = (id) => async (dispatch) => {
    const response = await aepDemo.get("/demoSolutionService/statusMgmt/" + id);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        dispatch({
            type: FETCH_DEMO_REQUEST,
            payload: response.data || []
        })
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch Demo Request');
    }
}