import React, { Component } from 'react'
import { Modal, Input } from "antd5";
import { Checkbox, Radio, Form, Icon } from "antd"
import { Button } from "@avaya/neo-react"
import CONFIG from '../../config';
import HTML from './HTML'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { postCapabilityRequest } from "../../actions";
import { connect } from 'react-redux';
const { TextArea } = Input;
class CapabilityRequestModal extends Component {

    state = {
        buttonLoading: false
    }

    componentWillUnmount() {
        this.setState({ buttonLoading: false })
    }

    handleSubmitCapabilityRequest = () => {
        const { form, data, demo, requestId } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            await this.setState({ buttonLoading: true })
            const formValues = Object.entries(values).map(([questionId, answerText]) => ({ question: { id: parseInt(questionId) }, answerText: Array.isArray(answerText) ? answerText?.join("//") : answerText }));
            const userAnswersObj = formValues.map(fv => {
                fv.questionOptions = data?.questions?.filter(dq => dq?.question?.id === parseInt(fv.question?.id))?.[0]?.question?.answers?.map(a => a.title).join('//') || null;
                return fv;
            });

            const dataObject = {
                capability: {
                    id: data?.id,
                    title: data?.title,
                },
                demo: {
                    id: demo?.id,
                    title: demo?.title,
                },
                capabilityAnswers: userAnswersObj,
                id: requestId
            }

            const response = await this.props.postCapabilityRequest(requestId, dataObject);
            this.props.handlePostFeedbackSubmit(response)
            this.setState({ buttonLoading: false })
        })
    }

    renderFooterButtons = () => {
        const { isEdit, handleCancel, rawData } = this.props;
        const { buttonLoading } = this.state;
        const readOnly = rawData?.viewOnly || false;
        return (
            <div style={{ display: "flex" }}>
                <span style={{ width: "20%" }}>
                </span>
                <span style={{ display: "flex", width: "100%", justifyContent: "flex-end", marginBottom: "10px", marginRight: "10px" }}>
                    <Button aria-label="cancel" key="cancel" variant="secondary" onClick={() => handleCancel("modalOpen")} > Cancel </Button>
                    {!readOnly && <Button disabled={buttonLoading} animation={buttonLoading ? "spinner" : "none"} aria-label="submit" key="next" onClick={this.handleSubmitCapabilityRequest} className="left-spacing primary-action-button-filled" >{isEdit ? "Update Request" : "Submit Request"} </Button>}
                </span>
            </div>
        )
    }

    handlePagePrint = (handlePrint) => {
        this.setState({ printPreview: true }, () => { handlePrint() })
    }

    closePrint = () => {
        this.setState({ printPreview: false })
    }

    renderCapabilityData = () => {
        const { data, form, rawData } = this.props;
        const { getFieldDecorator } = form;
        const { questions, instruction, title } = data || [];
        const readOnly = rawData?.viewOnly || false;
        return (
            <div>
                <div style={{ marginBottom: "20px" }}>
                    <p ref={el => (this.componentRef = el)} style={{ fontSize: "12px", background: '#f9f9f9', fontWeight: "400", whiteSpace: "pre-line", marginTop: "10px", }}>
                        <div className="capability-content"><HTML htmlContent={instruction} />
                        </div>
                    </p>
                    <ReactToPrint
                        content={() => this.componentRef}
                        onAfterPrint={this.closePrint}
                        documentTitle={title}
                        pageStyle={CONFIG.dashboard.schedulePrintStyle}>
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <span style={{ fontSize: "12px", fontWeight: "400", }} className="float-right link" onClick={() => { handlePrint() }}>Download Instruction <Icon type="printer" /></span>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                </div>
                {readOnly && <div style={{ color: "red", marginBottom: "20px", fontSize: "12px" }}>* Readonly view </div>}
                {
                    questions?.map((item, key) => {
                        const { title, id, answerType, isMandatory, answers, instruction } = item?.question;
                        const options = answers;
                        const { answerText } = item;
                        const instructionText = instruction ? <p style={{ fontSize: "12px", fontWeight: "400", whiteSpace: "pre-line", background: '#f9f9f9', marginTop: "10px", fontFamily: 'Noto Sans', }}><div className="capability-content"> <HTML htmlContent={instruction} /></div></p> : null
                        return (
                            <div className={readOnly ? "readonly-element" : "margin-1"} style={{ color: "black", margin: "12px" }} >
                                <div>
                                    {key + 1}. {title}
                                    <span>{instructionText}</span>
                                </div>

                                <Form.Item style={readOnly ? { pointerEvents: "none" } : ""} colon={false}>
                                    {
                                        answerType === CONFIG.demoAnswerTypes.Checkbox
                                            ?
                                            (getFieldDecorator(`${id}`, {
                                                rules: [{ required: isMandatory, message: "Atleast one checkbox must be selected" }],
                                                initialValue: answerText?.split('//') || []
                                            })(<Checkbox.Group >{options?.map(opt =>
                                                (<Checkbox style={{ color: "black" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Checkbox>))}
                                            </Checkbox.Group>))
                                            : answerType === CONFIG.demoAnswerTypes.RadioButton
                                                ? (getFieldDecorator(`${id}`, {
                                                    rules: [{ required: isMandatory, message: "Please select an option" }],
                                                    initialValue: answerText || undefined
                                                })(
                                                    <Radio.Group>
                                                        {options?.map(opt =>
                                                            (<Radio style={{ color: "black" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Radio>))}
                                                    </Radio.Group>))
                                                : (getFieldDecorator(`${id}`, {
                                                    rules: [{ required: isMandatory, message: "This field cannot be blank" }],
                                                    initialValue: answerText || undefined
                                                })(<TextArea style={{ color: "black" }} />))
                                    }
                                </Form.Item>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        const { data, visible, handleCancel, rawData } = this.props;
        const readOnly = rawData?.viewOnly || false;
        return (
            <Modal
                closable={false}
                className="capability-request-modal"
                open={visible}
                title={<div style={{ textAlign: "center" }} > {`${readOnly ? "Capability Request Details of" : "Request for"}  ${data?.title}`}</div>}
                onCancel={handleCancel}
                footer={this.renderFooterButtons()}
            >
                <div style={{ maxHeight: "450px", overflowY: "scroll" }} >
                    <Form>
                        {this.renderCapabilityData()}
                    </Form>
                </div>
            </Modal>
        )
    }
}

const CapabilityRequestModalRef = Form.create()(CapabilityRequestModal)

export default connect(null, { postCapabilityRequest })(CapabilityRequestModalRef);