import React from 'react';
import { connect } from 'react-redux'
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import { Icon, Tooltip } from "antd";

import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';
import {
    updateRoleTemplateNetworks,
    toggleIsFetchingNetworks,
    fetchNetworks,
    fetchDataCenters
} from '../../actions';
import { camelToTitleCase } from '../../utils/strings';

class RoleTemplatesNetworks extends React.Component {

    state = {
        selectAllDisabled: false,
        dataCenterFilters: undefined
    }

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, camelToTitleCase(dataIndex)),
        sorter: (a, b) => a[dataIndex].toLowerCase().localeCompare(b[dataIndex].toLowerCase()),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
    })

    handleFilter = (searchString, confirm) => {
        searchString = searchString?.trim();
        if (!searchString && !this.state.selectAllDisabled) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else confirm()
    }

    handleClear = (clearFilters) => {
        clearFilters();
    }

    handleChange = (_, filters) => {
        const selectAllDisabled = Object.values(filters).filter(value => value.length !== 0)?.length ? true : false;
        this.setState({ selectAllDisabled });
    }

    componentDidMount() {
        this.props.toggleIsFetchingNetworks(true);
        this.props.fetchNetworks();
        this.props.fetchDataCenters();
    }

    componentDidUpdate() {
        const { dataCenterFilters } = this.state;
        const { dataCenters } = this.props;
        if (!dataCenterFilters && dataCenters) {
            this.setState({
                dataCenterFilters: dataCenters.map(dataCenter => ({
                    text: dataCenter.title,
                    value: dataCenter.id
                }))
            })
        }
    }

    render() {
        const { networks, assignedNetworks, allNetworksAdminAccess, isFetching, updateRoleTemplateNetworks } = this.props;
        const { selectAllDisabled, dataCenterFilters } = this.state;
        const extraColumn = [
            {
                title: "Description",
                dataIndex: "description",
                key: "description",
                width: '30%',
                ...this.getColumnSearchProps("description")
            },
            {
                title: "Data Center",
                dataIndex: "dataCenter",
                key: "dataCenter",
                width: '50%',
                align: 'center',
                filters: dataCenterFilters || [],
                sorter: (a, b) => a.dataCenter.title.toLowerCase().localeCompare(b.dataCenter.title.toLowerCase()),
                onFilter: (value, record) => record.dataCenter.id === value,
                render: (dataCenter) => {
                    return (
                        <span >
                            {dataCenter.title}
                            &nbsp;&nbsp;&nbsp;
                            {!dataCenter.active &&
                                <Tooltip title="Inactive">
                                    <Icon type="warning" className="small-text warning-text" />
                                </Tooltip>
                            }
                        </span>
                    )
                },
            }
        ];

        return (
            <RoleTemplatesTable
                dataKey="network"
                dataIndex="networkAddress"
                hideColumns={['user']}
                marginTop={true}
                extraColumns={extraColumn}
                bordered={true}
                data={networks || []}
                selectAllDisabled={selectAllDisabled}
                columnFilters={{ ...this.getColumnSearchProps('networkAddress') }}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1
                }}
                isFetching={isFetching}
                assignedData={assignedNetworks}
                allAdminAccess={allNetworksAdminAccess}
                updateRoleTemplateData={updateRoleTemplateNetworks}
            />
        );
    }

}
const mapStateToProps = ({ networks, roleTemplate, dataCenters }) => {
    return {
        networks: networks.data,
        assignedNetworks: roleTemplate.networks,
        allNetworksAdminAccess: roleTemplate.allNetworksAdminAccess,
        isFetching: networks.isFetchingNetworks,
        dataCenters: dataCenters.data
    }
}

export default connect(
    mapStateToProps,
    {
        updateRoleTemplateNetworks,
        toggleIsFetchingNetworks,
        fetchNetworks,
        fetchDataCenters
    }
)(RoleTemplatesNetworks);