import React from 'react';
import { Button } from 'antd';

const FormActionButtons = (props) => {
    return (
        <React.Fragment>
            <Button onClick={props.handleCancel} className={`cancel-button button-width-${props.size}`} id='form-action-cancel'>
                {props.cancelText ? props.cancelText : 'Cancel'}
            </Button>
            <Button disabled={props.isDisabled} className={`left-spacing primary-action-button-filled button-width-${props.size}`} onClick={props.handleSubmit} id='form-action-confirm'>
                {props.okText ? props.okText : 'Create'}
            </Button>
        </React.Fragment>
    );
};

export default FormActionButtons;