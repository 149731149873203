import React from 'react';
import { connect, batch } from 'react-redux';
import { Table, Checkbox } from 'antd';

import {
    toggleIsFetchingReportFlag,
    fetchReports,
    updateRoleTemplateReports
} from '../../actions';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';

const dataIndexMap = {
    title: 'name'
};

class RoleTemplatesReports extends React.Component {

    getReportAccess = (reportId, roleType) => {
        const { assignedReports } = this.props;
        const report = assignedReports ? assignedReports.find(assignedReport => assignedReport.report.id === reportId) : undefined;
        return report && roleType
            ? roleType === CONFIG.roleTypes.user
                ? report.userAccess
                : report.adminAccess
            : undefined;
    }

    renderCheckbox = (report, roleType, allowedAccess) => {
        const reportAccess = this.getReportAccess(report.id, roleType);
        return (
            <Checkbox
                checked={reportAccess === allowedAccess}
                onClick={e => this.props.updateRoleTemplateReports(e.target.checked, roleType, allowedAccess, report)}
            />
        );
    };

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }


    handleSearch = (searchString, confirm) => {
        if (searchString?.trim()) {
            confirm();
        }
        else pushMessage(CONFIG.messageType.warning, 'Please enter search text');
    }

    handleClearSearch = (clearFilters) => {
        clearFilters()
    }

    handleChange = (pagination, filter, sorter) => {

        const filterSort = {
            filter: filter[dataIndexMap.title]?.[0] ? { [dataIndexMap.title]: filter[dataIndexMap.title]?.[0] } : undefined,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }

        this.selectAllDisabled = filterSort.filter !== undefined;
        batch(() => {
            this.props.toggleIsFetchingReportFlag(true);
            this.props.fetchReports({
                pageNumber: pagination.current, pageSize: CONFIG.shortPageSize + 1, filterSort, access: this.access, fromRoleTemplateForm: true
            })
        })

    }

    componentDidMount() {
        const { userRoleTemplate } = this.props;
        this.access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
        this.props.toggleIsFetchingReportFlag(true);
        this.props.fetchReports({ access: this.access, pageSize: (CONFIG.shortPageSize + 1), fromRoleTemplateForm: true });
    }

    render() {
        const { reports, count, isFetching, templateType } = this.props;
        this.adminColumns = [
            {
                title: <span className="primary-text-color">Admin</span>,
                children: [
                    {
                        title: (
                            <div>
                                Read
                                <br />
                                <Checkbox
                                    checked={this.props.allReportssAdminAccess === CONFIG.roleTemplateAccess.readOnly}
                                    onChange={e => this.props.updateRoleTemplateReports(e.target.checked, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.readOnly)}
                                    disabled={(reports && reports.length === 0) || this.selectAllDisabled}
                                />
                            </div>
                        ),
                        key: "adminReadAccess",
                        align: "center",
                        width: '10%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.readOnly)
                    },
                    {
                        title: (
                            <div>
                                Write
                                <br />
                                <Checkbox
                                    checked={this.props.allReportssAdminAccess === CONFIG.roleTemplateAccess.fullAccess}
                                    onChange={e => this.props.updateRoleTemplateReports(e.target.checked, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.fullAccess)}
                                    disabled={(reports && reports.length === 0) || this.selectAllDisabled}
                                />
                            </div>
                        ),
                        key: "adminWriteAccess",
                        align: "center",
                        width: '10%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.admin, CONFIG.roleTemplateAccess.fullAccess)
                    }
                ]
            }
        ]
        this.columns = [
            {
                title: "Title",
                dataIndex: "name",
                key: "title",
                width: '60%',
                align: "left",
                sorter: true,
                filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "title")
            },
            {
                title: "User",
                children: [
                    {
                        title: (
                            <div>
                                Read-only
                                <br />
                                <Checkbox
                                    checked={this.props.allReportsUserAccess === CONFIG.roleTemplateAccess.readOnly}
                                    onChange={e => this.props.updateRoleTemplateReports(e.target.checked, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.readOnly)}
                                    disabled={(reports && reports.length === 0) || this.selectAllDisabled}
                                />
                            </div>
                        ),
                        key: "userReadAccess",
                        align: "center",
                        width: '10%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.readOnly)
                    },
                    {
                        title: (
                            <div>
                                Full-access
                                <br />
                                <Checkbox
                                    checked={this.props.allReportsUserAccess === CONFIG.roleTemplateAccess.fullAccess}
                                    onChange={e => this.props.updateRoleTemplateReports(e.target.checked, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.fullAccess)}
                                    disabled={(reports && reports.length === 0) || this.selectAllDisabled}
                                />
                            </div>
                        ),
                        key: "userFullAccess",
                        align: "center",
                        width: '10%',
                        render: (access, record) =>
                            this.renderCheckbox(record, CONFIG.roleTypes.user, CONFIG.roleTemplateAccess.fullAccess)
                    }
                ]
            }
        ];

        return (
            <Table
                size='middle'
                className="responsive-container top-spacing"
                bordered
                columns={templateType === CONFIG.roleTypes.admin ? [...this.columns, ...this.adminColumns] : this.columns}
                loading={isFetching}
                dataSource={reports}
                rowKey={(record) => record.id}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1,
                    total: count
                }}
            />
        );
    }
}

const mapStateToProps = ({ roleTemplate, user, reports }) => {
    return {
        reports: reports.data,
        count: reports.count,
        isFetching: reports.isFetching,
        assignedReports: roleTemplate.reports,
        allReportsUserAccess: roleTemplate.allReportsUserAccess,
        allReportsAdminAccess: roleTemplate.allReportsAdminAccess,
        templateType: roleTemplate.templateType,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined
    }
}

export default connect(
    mapStateToProps,
    {
        toggleIsFetchingReportFlag,
        fetchReports,
        updateRoleTemplateReports
    }
)(RoleTemplatesReports);