import {
    FETCH_DEMOS,
    FETCH_ALL_DEMOS,
    TOGGLE_IS_FETCHING_DEMOS,
    CLEAR_DEMOS,
    UPDATE_DEMO_SAVE_WARNING,
    SAVE_DEMO_ROLE_TEMPLATES,
    CANCEL_DEMO_ROLE_TEMPLATES,
    CLEAR_CURRENT_DEMO,
    UPDATE_DEMO_REGIONS,
    CURRENT_ACTIVE_DEMO_PANEL,
    FETCH_CURRENT_DEMO_BASIC_DETAILS,
    CLEAR_DEMO_FORM_TAB_DATA,
    ADD_DEMO_TAB_DETAILS,
    UPDATE_DEMO_BASIC_DETAILS,
    UPDATE_DEMO_ROLE_TEMPLATES,
    TOGGLE_ALL_DEMO_ROLE_TEMPLATES,

    FETCH_DEMO_COLLATERAL_TYPES,
    ADD_DEMO_COLLATERALS,
    ADD_NEW_DEMO_COLLATERAL,
    UPDATE_DEMO_COLLATERAL,
    DELETE_DEMO_COLLATERAL,
    REORDER_DEMO_COLLATERALS,
    CLEAR_NEW_DEMO_COLLATERALS,
    TOGGLE_IS_UPLOADING_FILE,
    FETCH_DELETED_COLLATERALS,
    UPDATE_DEMO_DESCRIPTION,
    UPDATE_DEMO_COMPONENT_DETAILS,
    UPDATE_DEMO_ACCESS,
    UPDATE_DEMO_STATUS,
    ADD_NEW_DEMO_RESOURCE,
    DELETE_DEMO_RESOURCE,
    FETCH_USER_GROUPS,
    EDIT_DEMO_RESOURCE,
    DELETE_COLLATERALS,
    LAST_DEMO_FILTER,
    CLEAR_FETCHED_DEMOS,
    CLEAR_DEMO_RESPONSE,
    FETCH_DEMO_REGIONS,
    ADD_NEW_ADDITIONAL_INFO_QUESTION,
    ADD_NEW_DEMO_PRE_REG_QUESTION,
    ADD_DEMO_QUESTIONS,
    DELETE_DEMO_PRE_REG_QUESTION_ASSOCIATION,
    DELETE_DEMO_ADDITIONAL_INFO_QUESTION_ASSOCIATION,

    ADD_NEW_EZ_DEMO,
    DELETE_DEMO_EZ_DEMO,
    FETCH_ALL_MANAGE_EZ_DEMOS,

    ADD_NEW_DEMO_CAPABILITY,
    DELETE_DEMO_CAPABILITY,
    EDIT_DEMO_CAPABILITY
} from '../actions/types';
import CONFIG from '../config';

const demosState = {
    isFetching: false,
    warningMessage: undefined,
    currentPageNumber: undefined,
    currentDemoId: undefined,
    data: [],
    allDemos: [],
    activePanelKey: "1"
};
export const demosReducer = (state = demosState, action) => {
    switch (action.type) {
        case FETCH_DEMOS:
            return { ...state, data: action.payload[0] || [], count: action.payload[0]?.length || 0 };
        case FETCH_ALL_DEMOS:
            return { ...state, allDemos: action.payload[0].dataSet || [] };
        case CLEAR_FETCHED_DEMOS:
            return { ...state, data: [] };
        case TOGGLE_IS_FETCHING_DEMOS:
            return { ...state, isFetching: action.payload };
        case LAST_DEMO_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true, currentPage: action.payload[2] }
        case UPDATE_DEMO_SAVE_WARNING:
            return { ...state, warningMessage: action.payload };
        case CURRENT_ACTIVE_DEMO_PANEL:
            return { ...state, activePanelKey: action.payload }
        case CLEAR_DEMOS:
            return demosState;
        case CLEAR_DEMO_RESPONSE:
            return { ...state, response: action.payload };
        case FETCH_USER_GROUPS:
            return { ...state, userGroups: action.payload || [] }
        default:
            return state;
    }
};

const openTabDemoReducerState = {
    allRoleTemplatesUserAccess: undefined,
    allRoleTemplatesAdminAccess: undefined,
    currentTabData: [],

};

export const openTabDemoDetailsReducer = (state = openTabDemoReducerState, action) => {
    switch (action.type) {

        // ---------------------------------------------------------------------------------------------
        // Demo Basic Details
        // ---------------------------------------------------------------------------------------------
        case FETCH_CURRENT_DEMO_BASIC_DETAILS:
            return { ...state, currentTabData: action.payload || {} };

        // ---------------------------------------------------------------------------------------------
        // Demo CK-Editor Content
        // ---------------------------------------------------------------------------------------------

        case UPDATE_DEMO_DESCRIPTION:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoEditorContent: {
                        ...state.currentTabData.demoEditorContent,
                        descriptionHtml: action.payload?.editorContent
                    }
                }
            }
        case UPDATE_DEMO_ACCESS:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,

                    demoEditorContent: {
                        ...state.currentTabData.demoEditorContent,
                        connectivityDetailsHtml: action.payload?.editorContent
                    }
                }
            };

        case UPDATE_DEMO_COMPONENT_DETAILS:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoEditorContent: {
                        ...state.currentTabData.demoEditorContent,
                        componentDetailsHtml: action.payload?.editorContent
                    }
                }
            };

        // ---------------------------------------------------------------------------------------------
        // Demo Questions
        // ---------------------------------------------------------------------------------------------

        case ADD_NEW_DEMO_PRE_REG_QUESTION:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    assignedPreRegisterQuestions: [...(state.currentTabData.assignedPreRegisterQuestions?.length > 0 ? state.currentTabData.assignedPreRegisterQuestions : []), action.payload?.question]
                }
            };

        case ADD_NEW_ADDITIONAL_INFO_QUESTION:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    assignedAdditionalInfoQuestions: [...(state.currentTabData.assignedAdditionalInfoQuestions?.length > 0 ? state.currentTabData.assignedAdditionalInfoQuestions : []), action.payload?.question]
                }
            };

        case DELETE_DEMO_PRE_REG_QUESTION_ASSOCIATION:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    assignedPreRegisterQuestions: state.currentTabData.assignedPreRegisterQuestions.filter(dQuestion => dQuestion.question.id !== action.payload)
                }
            };

        case DELETE_DEMO_ADDITIONAL_INFO_QUESTION_ASSOCIATION:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    assignedAdditionalInfoQuestions: state.currentTabData.assignedAdditionalInfoQuestions.filter(dQuestion => dQuestion.question.id !== action.payload)
                }
            };


        //----------------------------------------------------------------------------------------------
        //Demo Capability
        //----------------------------------------------------------------------------------------------


        case ADD_NEW_DEMO_CAPABILITY:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoCapability: [...(state.currentTabData.demoCapability?.length > 0 ? state.currentTabData.demoCapability : []), action.payload?.capability]
                }
            }

        case DELETE_DEMO_CAPABILITY:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoCapability: state.currentTabData.demoCapability.filter(item => item.capability.id !== action.payload)
                }
            };

        case EDIT_DEMO_CAPABILITY:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoCapability: state.currentTabData.demoCapability.map(item => item.capability.id !== action.payload.capability.id ? item : action.payload)
                }
            }


        // ---------------------------------------------------------------------------------------------
        // Demo Resources
        case ADD_NEW_DEMO_RESOURCE:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoResources: [...(state.currentTabData.demoResources?.length > 0 ? state.currentTabData.demoResources : []), { resource: action.payload.resource, isActive: true, ldapGroup: action.payload.resourceUserGroup }]
                }
            };
        case DELETE_DEMO_RESOURCE:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoResources: state.currentTabData.demoResources.filter(dResource => dResource.resource.id !== action.payload)
                }
            };

        case EDIT_DEMO_RESOURCE:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoResources: state.currentTabData.demoResources.map(dResource => dResource.resource.id !== action.payload.resource.id ? dResource : action.payload)
                }
            }


        // ---------------------------------------------------------------------------------------------
        // Demo DC Status

        case UPDATE_DEMO_STATUS:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoDCStatuses: state.currentTabData.demoDCStatuses?.map(datacenter => {
                        return datacenter.dataCenter.id === action.payload.dataCenter.id ? action.payload : datacenter
                    })
                }
            }

        // ---------------------------------------------------------------------------------------------
        // Demo Collaterlas
        case ADD_DEMO_COLLATERALS:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoCollaterals: {
                        ...state.currentTabData.demoCollaterals,
                        assignedCollaterals: [...(state.currentTabData.demoCollaterals.newCollaterals?.length > 0 ? state.currentTabData.demoCollaterals.newCollaterals : []), ...(action.payload?.length > 0 ? action.payload : [])]
                    }
                }
            };

        case ADD_NEW_DEMO_COLLATERAL:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoCollaterals: {
                        ...state.currentTabData.demoCollaterals,
                        assignedCollaterals: [...(state.currentTabData.demoCollaterals.assignedCollaterals?.length > 0 ? state.currentTabData.demoCollaterals.assignedCollaterals : []), action.payload],
                        newCollaterals: [...(state.currentTabData.demoCollaterals.newCollaterals?.length > 0 ? state.currentTabData.demoCollaterals.newCollaterals : []), action.payload]
                    }
                }
            }
        case REORDER_DEMO_COLLATERALS:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoCollaterals: {
                        ...state.currentTabData.demoCollaterals,
                        assignedCollaterals: action.payload,
                    },
                    originalDemoObj: state?.currentTabData?.originalDemoObj
                }
            }
        case UPDATE_DEMO_COLLATERAL:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoCollaterals: {
                        ...state.currentTabData.demoCollaterals,
                        assignedCollaterals: state.currentTabData.demoCollaterals?.assignedCollaterals?.map(collateral => collateral.id === action.payload.id ? action.payload : collateral),
                    }
                }
            };
        case DELETE_DEMO_COLLATERAL:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoCollaterals: {
                        ...state.currentTabData.demoCollaterals,
                        assignedCollaterals: state.currentTabData.demoCollaterals?.assignedCollaterals?.filter(collateral => collateral.id !== action.payload),
                    }
                }
            }

        // EZ Demos 

        case ADD_NEW_EZ_DEMO:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoEzDemos: [...(state.currentTabData.demoEzDemos?.length > 0 ? state.currentTabData.demoEzDemos : []), { ...action.payload }]
                }
            }

        case DELETE_DEMO_EZ_DEMO:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoEzDemos: state.currentTabData.demoEzDemos.filter(ezDemo => ezDemo?.ezDemo?.id !== action.payload)
                }
            }

        //Demo Role Templates

        case UPDATE_DEMO_ROLE_TEMPLATES:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoBasicDetails: {
                        ...state.currentTabData?.demoBasicDetails,
                        roleTemplates: action.payload.updatedDemoRoleTemplates,
                        [action.payload.accessKey]: action.payload.isAllAccessChanged ? state[action.payload.accessKey] : undefined
                    }
                }
            };
        case TOGGLE_ALL_DEMO_ROLE_TEMPLATES:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoBasicDetails: {
                        ...state.currentTabData?.demoBasicDetails,
                        [action.payload.accessKey]: action.payload.access ? action.payload.accessType : undefined
                    }
                }
            }
        case SAVE_DEMO_ROLE_TEMPLATES:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoBasicDetails: {
                        ...state.currentTabData?.demoBasicDetails,
                        assignedRoleTemplates: state.currentTabData?.demoBasicDetails?.roleTemplates
                    }
                }
            }
        case CANCEL_DEMO_ROLE_TEMPLATES:
            return {
                ...state,
                currentTabData: {
                    ...state.currentTabData,
                    demoBasicDetails: {
                        ...state.currentTabData?.demoBasicDetails,
                        roleTemplates: state.currentTabData?.demoBasicDetails?.assignedRoleTemplates || [], allRoleTemplatesUserAccess: undefined, allRoleTemplatesAdminAccess: undefined
                    }
                }
            }

        case CLEAR_CURRENT_DEMO:
            return openTabDemoReducerState;
        default:
            return state;
    }
};

const demoFormReducerState = {
    allRoleTemplatesUserAccess: undefined,
    allRoleTemplatesAdminAccess: undefined,
    demoTabData: [],
};

export const demoFormReducer = (state = demoFormReducerState, action) => {
    switch (action.type) {

        case ADD_DEMO_TAB_DETAILS:
            return {
                ...state,
                demoTabData: state.demoTabData.filter(obj =>
                    (obj.demoId !== action.payload.demoId)
                    || (obj.demoId === action.payload.demoId && obj.isDemoClone !== action.payload.isDemoClone)).concat(action.payload)
            };

        case CLEAR_DEMO_FORM_TAB_DATA:
            const updateDemoFormTabData = state.demoTabData?.filter(el =>
            ((parseInt(el.demoId) !== parseInt(action.payload.id))
                || ((parseInt(el.demoId) === parseInt(action.payload.id))
                    && ((el?.isDemoClone !== action.payload.isClone)))
            ));
            return {
                ...state,
                demoTabData: updateDemoFormTabData
            }
        case ADD_DEMO_QUESTIONS:
            return {
                ...state,
                preReqQuestionsData: action.payload?.filter(dq => dq.type === CONFIG.demoQuestionTypes?.[0]?.value),
                preRegQuestionsData: action.payload?.filter(dq => dq.type === CONFIG.demoQuestionTypes?.[1]?.value)
            };
        case CLEAR_CURRENT_DEMO:
            return demoFormReducerState;
        case UPDATE_DEMO_BASIC_DETAILS:
            return { ...state, ...action.payload };

        case FETCH_DEMO_REGIONS:
            return { ...state, allDemoRegions: action.payload }
        case UPDATE_DEMO_REGIONS:
            return { ...state, allDemoRegions: state.allDemoRegions ? [action.payload, ...state.allDemoRegions] : [action.payload] };
        default:
            return state;
    }
}

export const demoCollateralsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_DEMO_COLLATERAL_TYPES:
            return { ...state, collateralTypes: action.payload }
        case CLEAR_CURRENT_DEMO:
            return { ...state, data: undefined, newCollaterals: undefined, deletedCollaterals: undefined }
        case CLEAR_NEW_DEMO_COLLATERALS:
            return { ...state, newCollaterals: undefined }
        case TOGGLE_IS_UPLOADING_FILE:
            return { ...state, isUploading: action.payload };
        case FETCH_DELETED_COLLATERALS:
            return { ...state, deletedCollaterals: action.payload }
        case DELETE_COLLATERALS:
            const deletedCollaterals = state.deletedCollaterals.filter(collateral => collateral.id !== action.payload);
            return { ...state, deletedCollaterals }
        default:
            return state;
    }
}

export const demoEZDemosReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ALL_MANAGE_EZ_DEMOS:
            return { ...state, allEZDemos: action.payload?.dataSet || [] }
        default:
            return state;
    }
}