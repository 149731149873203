import React from 'react';
import { Alert } from 'antd';

import CONFIG from '../config';

const NoSolutions = () => (
    <div className="content-container">
        <Alert
            message={CONFIG.warningMessages.noSolutions}
            type="warning"
            showIcon
        />
    </div>
)

export default NoSolutions;