import React from 'react';
import { connect, batch } from 'react-redux';
import {
    toggleIsFetchingFeedbackQuestions,
    fetchFeedbackQuestionsForRoleTemplate,
    updateRoleTemplateFeedbackQuestions
} from '../../actions';
import RoleTemplatesTable from '../common/RoleTemplatesTable';
import SearchFilter from '../common/SearchFilter';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';

class RoleTemplateFeedbackQuestions extends React.Component {

    getFilterDropDown = (filterProps, dataIndex) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps;
        return (
            <SearchFilter
                dataIndex={dataIndex}
                searchString={selectedKeys[0]}
                setSearchString={setSelectedKeys}
                confirm={confirm}
                handleClear={this.handleClear}
                handleSearch={this.handleFilter}
                clearFilters={clearFilters}
            />
        )
    }

    columnFilters = {
        filterDropdown: (filterProps) => this.getFilterDropDown(filterProps, "feedbackQuestions"),
        sorter: true
    }

    filterSort = {}

    handleFilter = (purpose, confirm) => {
        purpose = purpose?.trim();
        if (!purpose && !this.filterSort.filter) {
            pushMessage(CONFIG.messageType.warning, "Please enter a search string")
            return;
        }
        else confirm()
    }

    handleClear = (clearFilters) => {
        clearFilters();
    }

    handleChange = (pagination, filters, sorter) => {

        this.filterSort = {
            filter: filters.purpose?.[0] ? { purpose: filters.purpose[0] } : undefined,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }

        batch(() => {
            this.props.toggleIsFetchingFeedbackQuestions(true);
            this.props.fetchFeedbackQuestionsForRoleTemplate({
                pageNumber: pagination.current,
                pageSize: (CONFIG.shortPageSize + 1),
                access: this.access,
                filterSort: this.filterSort
            });
        });
    }

    componentDidMount() {
        const { userRoleTemplate } = this.props;
        this.access = userRoleTemplate.templateType === CONFIG.roleTypes.admin ? CONFIG.roleTemplateAccess.fullAccess : undefined;
        this.props.toggleIsFetchingFeedbackQuestions(true);
        this.props.fetchFeedbackQuestionsForRoleTemplate({ access: this.access });
    }

    render() {
        const { feedbackQuestions, count, assignedFeedbackQuestions, isFetching, allFeedbackQuestionAdminAccess, allFeedbackQuestionUserAccess, updateRoleTemplateFeedbackQuestions, templateType } = this.props;
        return (
            <RoleTemplatesTable
                dataKey="question"
                dataIndex="title"
                hideColumns={templateType === CONFIG.roleTypes.admin ? [] : ['admin']}
                marginTop={true}
                bordered={true}
                data={feedbackQuestions}
                selectAllDisabled={this.filterSort.filter !== undefined}
                columnFilters={this.columnFilters}
                onChange={this.handleChange}
                pagination={{
                    pageSize: CONFIG.shortPageSize + 1,
                    total: count
                }}
                isFetching={isFetching}
                assignedData={assignedFeedbackQuestions}
                allAdminAccess={allFeedbackQuestionAdminAccess}
                allUserAccess={allFeedbackQuestionUserAccess}
                updateRoleTemplateData={(updateRoleTemplateFeedbackQuestions)}
            />
        );
    }
}

const mapStateToProps = ({ feedbackQuestions, roleTemplate, user }) => {
    return {
        feedbackQuestions: feedbackQuestions?.questions,
        count: feedbackQuestions.questionCount,
        isFetching: feedbackQuestions.isFetching,
        assignedFeedbackQuestions: roleTemplate.questions,
        allFeedbackQuestionUserAccess: roleTemplate.allFeedbackQuestionUserAccess,
        allFeedbackQuestionAdminAccess: roleTemplate.allFeedbackQuestionAdminAccess,
        templateType: roleTemplate.templateType,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        roleTemplate
    }
}

export default connect(
    mapStateToProps,
    {
        toggleIsFetchingFeedbackQuestions,
        fetchFeedbackQuestionsForRoleTemplate,
        updateRoleTemplateFeedbackQuestions
    }
)(RoleTemplateFeedbackQuestions);