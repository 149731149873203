import { aepReport, aepDemo } from '../apis';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';

import {
    ADD_NEW_EZ_DEMO,
    DELETE_DEMO_EZ_DEMO,
    FETCH_ALL_MANAGE_EZ_DEMOS,
    FETCH_EZ_DEMO_REVIEW_BY_AUDIT_ID
} from "./types";

export const fetchAllManageEZDemos = () => async (dispatch) => {
    const response = await aepDemo.get("/demoSolutionService/ezDemos");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ALL_MANAGE_EZ_DEMOS, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch EZ Demos');
}

export const addNewEZDemo = (ezDemo) => {
    return {
        type: ADD_NEW_EZ_DEMO,
        payload: ezDemo
    }
}

export const deleteEZDemo = ezDemoId => {
    return {
        type: DELETE_DEMO_EZ_DEMO,
        payload: ezDemoId
    };
};

export const ezDemoAudit = (ezDemoId, demoId, solutionTypeId) => async () => {
    const params = {
        params: {
            demoId,
            solutionTypeId
        }
    }
    const response = await aepReport.post(`reportSearchService/ezDemoAudit/${ezDemoId}`, undefined, params)
    return response?.data;
}

export const audit = (ezDemoId, demoId, solutionTypeId,collateralId) => async () => {
    const pathVariable = ezDemoId ? "EZDemo" : "Collateral";
    const payload = {
            ezDemoId,
            demoId,
            solutionTypeId,
            collateralId
        }
    
    const response = await aepReport.post(`/reportSearchService/audit/${pathVariable}`, payload)
    return response?.data;
}

export const restoreEZDemo = (ezDemoId) => async () => {
    const response = await aepDemo.put("/demoSolutionService/restore/ezDemo/" + ezDemoId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "EZ Demo restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore EZ Demo");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const postEZDemoFeedback = (auditId, values) => async () => {
    const contextPath = `/demoSolutionService/ezDemo/${auditId}/feedback`;
    const payload = {
        ezDemoAuditRating: {
            comment: values?.comment,
            rating: values?.overallRating
        }
    }
    const response = await aepDemo({
        method: "POST",
        url: contextPath,
        data: payload
    });
    const successMessage = 'EZ Demo feedback submitted successfully';
    const errorMessage = 'Unable to submit EZ Demo feedback';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage)
        return true;
    }
    else pushMessage(CONFIG.messageType.error, errorMessage)
    return false;
};

export const getEZDemoReviewByAuditId = (auditId) => async (dispatch) => {
    const response = await aepDemo.get(`/demoSolutionService/ezDemo/${auditId}/feedback`);
    // const response = await aepDemo.get(`/demoSolutionService/ezDemo/107/feedback`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_EZ_DEMO_REVIEW_BY_AUDIT_ID, payload: response.data || [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch EZ Demo Review');
}
