import {
    TOGGLE_IS_FETCHING_REPORTS,
    FETCH_USER_REPORTS,
    FETCH_USER_MY_REPORTS,
    FETCH_ENTITY_TYPES,
    TOGGLE_IS_FETCHING_REPORT_FORM,
    COLUMNS_OF_THE_REPORT,
    CLEAR_REPORT_FORM,
    FETCH_REPORTS,
    UPDATE_REPORTS_CURRENT_PAGE,
    UPDATE_REPORT_DETAILS,
    SAVE_REPORTS_ROLE_TEMPLATES,
    CANCEL_REPORTS_ROLE_TEMPLATES,
    UPDATE_REPORT_ROLE_TEMPLATE,
    FETCH_GENERAL_REPORTS,
    RUN_REPORT,
    RUN_GENERAL_REPORT,
    UPDATE_RUN_REPORT_CURRENT_PAGE,
    CLEAR_REPORT_VALUES,
    LAST_REPORT_FILTER,
    LAST_USER_REPORT_FILTER,
    UPDATE_CUSTOM_REPORTS_CURRENT_PAGE,
    CLEAR_REPORT,
    FETCH_USER_CREATED_REPORTS,
    CLEAR_REPORT_ON_ENTITY_CHANGE,
    TOGGLE_IS_SAVE_REPORT_VISIBLE,
    HAS_ACCESS_TO_EAP_OFFLINE,
    TOGGLE_IS_FETCHING_USER_REPORTS,
    UPDATE_USER_REPORT_DETAILS,
    COLUMNS_OF_USER_REPORT,
    RUN_USER_REPORT,
    RUN_USER_GENERAL_REPORT,
    CLEAR_USER_REPORT,
    UPDATE_SELECTED_COLUMNS_FOR_REPORT,
    TOGGLE_IS_ENTITY_CHANGED,
    CLEAR_REPORTS_CONTENT
} from "../actions/types";

import CONFIG from "../config";

const reportsState = {
    isFetching: false,
    currentPageNumber: 1
};

const currentReportState = {
    allRTAdminAccess: undefined,
    allRTUserAccess: undefined,
    isLoading: false,
    hasAccessToEAPOffline: false,
    isSaveReportVisible: false,
    isFetchingReportForm: false,
    reportColumns: [],
    entityTypes: [],
    reportDetails: [],
}

export const reportsReducer = (state = reportsState, action) => {
    switch (action.type) {
        case UPDATE_REPORTS_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };
        case UPDATE_CUSTOM_REPORTS_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, currentCustomReportId: action.payload[2] || undefined, customReportIndex: action.payload[3] || undefined };
        case FETCH_REPORTS:
            return { ...state, data: action.payload?.dataSet || [], count: action.payload.count };
        case LAST_REPORT_FILTER:
            return {
                ...state,
                currentFilter: action.payload[0],
                status: action.payload[1],
                isEditReport: true
            }
        case TOGGLE_IS_FETCHING_REPORTS:
            return { ...state, isFetching: action.payload };
        case HAS_ACCESS_TO_EAP_OFFLINE:
            return { ...state, hasAccessToEAPOffline: action.payload };
        case CLEAR_REPORT:
            return reportsState
        default:
            return state;
    }
};

const generalReportsState = {
    isFetching: false
}

export const generalReportsReducer = (state = generalReportsState, action) => {
    switch (action.type) {
        case FETCH_GENERAL_REPORTS:
            return { ...state, data: action.payload?.dataSet || [], count: action.payload?.count };
        default:
            return state;
    }
};

const userCreatedReportsState = {
    isFetching: false
}

export const userCreatedReportsReducer = (state = userCreatedReportsState, action) => {
    switch (action.type) {
        case FETCH_USER_CREATED_REPORTS:
            return { ...state, data: action.payload?.dataSet || [], count: action.payload?.count };
        case LAST_USER_REPORT_FILTER:
            return {
                ...state,
                currentFilter: action.payload[0],
                status: action.payload[1],
                isEditUser: true
            }
        default:
            return state;
    }
};

export const currentReportReducer = (state = currentReportState, action) => {
    switch (action.type) {
        case FETCH_ENTITY_TYPES:
            return { ...state, entityTypes: action.payload || [] };
        case TOGGLE_IS_FETCHING_REPORT_FORM:
            return { ...state, isFetchingReportForm: action.payload };
        case COLUMNS_OF_THE_REPORT:
            const updateColumnReport = state.reportDetails?.map(el =>
                ((parseInt(el.id) === parseInt(action.payload.reportId))
                    && (el.isClone === action.payload.isClone)
                    && (el.isGeneralReport === action.payload.isGeneralReport))
                    ? {
                        ...el,
                        reportColumns: action.payload.columns,
                        details: {
                            ...el.details,
                            reportColumns: action.payload.columns,
                        }
                    } : el);
            return { ...state, reportDetails: updateColumnReport, runReportData: [], runGeneralReportData: [] };
        case UPDATE_SELECTED_COLUMNS_FOR_REPORT:
            const updateSelectedColumnsReport = state.reportDetails?.map(el =>
                ((parseInt(el.id) === parseInt(action.payload.id))
                    && (el.isClone === action.payload.isClone)
                    && (el.isGeneralReport === action.payload.isGeneralReport))
                    ? {
                        ...el,
                        selectedColumns: action.payload.columns,
                        details: {
                            ...el.details,
                            selectedColumns: action.payload.columns,
                        }
                    } : el);
            return { ...state, reportDetails: updateSelectedColumnsReport };
        case TOGGLE_IS_ENTITY_CHANGED:
            const updateEntityData = state.reportDetails?.map(el =>
                ((parseInt(el.id) === parseInt(action.payload.id))
                    && (el.isClone === action.payload.isClone)
                    && (el.isGeneralReport === action.payload.isGeneralReport))
                    ? {
                        ...el,
                        entityChanged: action.payload.flag,
                        details: {
                            ...el.details,
                            entityChanged: action.payload.flag,
                        }
                    } : el);
            return { ...state, reportDetails: updateEntityData };
        case CLEAR_REPORT_FORM:
            return currentReportState;
        case UPDATE_REPORT_DETAILS:
            return {
                ...state, reportDetails: state?.reportDetails?.filter(obj => ((parseInt(obj.id) !== parseInt(action.payload.id))
                    || (parseInt(obj.id) === parseInt(action.payload.id) && obj.isClone !== action.payload.isClone)
                    || (parseInt(obj.id) === parseInt(action.payload.id) && obj.isGeneralReport !== action.payload.isGeneralReport))).concat(action.payload)
            }
        case UPDATE_REPORT_ROLE_TEMPLATE:
            const updatedData = state?.reportDetails?.map(el => ((parseInt(el.id) === parseInt(action.payload.recordId))
                && (el.isClone === action.payload.isClone)
                && (el.isGeneralReport === action.payload.isGeneralReport))
                ? {
                    ...el,
                    details: {
                        ...el.details,
                        roleTemplates: action.payload.updatedRoleTemplates,
                        [action.payload.allAccessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                            ? action.payload.accessType
                            : CONFIG.roleTemplateAccess.none
                    }
                }
                : el)
            return {
                ...state, reportDetails: updatedData,
            }

        case SAVE_REPORTS_ROLE_TEMPLATES:
            const updatedRTSaveData = state.reportDetails?.map(el => ((parseInt(el.id) === parseInt(action.payload.id))
                && (el.isClone === action.payload.isClone)
                && (el.isGeneralReport === action.payload.isGeneralReport))
                ? {
                    ...el, details: {
                        ...el.details,
                        assignedRoleTemplates: el.details.roleTemplates
                    }
                } : el)
            return { ...state, reportDetails: updatedRTSaveData };
        case CANCEL_REPORTS_ROLE_TEMPLATES:
            const updatedRTCancelData = state.reportDetails?.map(el =>
                ((parseInt(el.id) === parseInt(action.payload.id))
                    && (el.isClone === action.payload.isClone)
                    && (el.isGeneralReport === action.payload.isGeneralReport))
                    ? {
                        ...el, details: {
                            ...el.details,
                            roleTemplates: el.details.assignedRoleTemplates,
                            allRTAdminAccess: undefined,
                            allRTUserAccess: undefined
                        }
                    } : el)
            return { ...state, reportDetails: updatedRTCancelData };
        case CLEAR_REPORT_VALUES:
            return currentReportState;
        case CLEAR_REPORT_ON_ENTITY_CHANGE:
            const updateReportDetails = state.reportDetails?.map(el =>
                ((parseInt(el.id) === parseInt(action.payload.id))
                    && (el.isClone === action.payload.isClone)
                    && (el.isGeneralReport === action.payload.isGeneralReport))
                    ? {
                        ...el, details: {
                            ...el.details,
                            filterSortObject: {},
                            filterTable: {},
                            reportColumns: [],
                            viewColumnsJson: []
                        }
                    } : el)
            return { ...state, count: 0, runReportData: [], runGeneralReportData: [], reportDetails: updateReportDetails }
        case CLEAR_REPORTS_CONTENT:
            const updateReportingData = state.reportDetails?.filter(el =>
            ((parseInt(el.id) !== parseInt(action.payload.id))
                || ((parseInt(el.id) === parseInt(action.payload.id))
                    && ((el?.isClone !== action.payload.isClone) || (el.isGeneralReport !== action.payload.isGeneralReport)))
            ));
            return {
                ...state,
                reportDetails: updateReportingData
            }
        case RUN_REPORT:
            return { ...state, runReportData: action.payload[0].dataSet, count: action.payload[0].count, response: action.payload[1] }
        case RUN_GENERAL_REPORT:
            return { ...state, runGeneralReportData: action.payload }
        case UPDATE_RUN_REPORT_CURRENT_PAGE:
            return { ...state, currentPage: action.payload }
        case TOGGLE_IS_FETCHING_REPORTS:
            return { ...state, isFetching: action.payload };
        case TOGGLE_IS_SAVE_REPORT_VISIBLE:
            return { ...state, isSaveReportVisible: action.payload };
        default:
            return state
    }
};

const userReportState = {
    isFetching: false,
    reportColumns: [],
}

export const userReportsReducer = (state = userReportState, action) => {
    switch (action.type) {
        case FETCH_USER_REPORTS:
            return { ...state, userReports: action.payload?.dataSet || [] };
        case FETCH_USER_MY_REPORTS:
            return { ...state, userMyReports: action.payload?.dataSet || [] };
        case UPDATE_USER_REPORT_DETAILS:
            return { ...state, details: action.payload, reportObj: action.report, userReports: [], userMyReports: [], isUserReport: true };
        case COLUMNS_OF_USER_REPORT:
            return { ...state, reportColumns: action.payload || [], runReportData: [], };
        case TOGGLE_IS_FETCHING_USER_REPORTS:
            return { ...state, isFetching: action.payload };
        case RUN_USER_REPORT:
            return { ...state, runReportData: action.payload[0].dataSet, count: action.payload[0].count, response: action.payload[1] }
        case RUN_USER_GENERAL_REPORT:
            return { ...state, runGeneralReportData: action.payload }
        case CLEAR_USER_REPORT:
            return userReportState;
        default: return state;
    }
}

