import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector, connect } from "react-redux";
import { Form, Row, Col, Button, Spin, Select, Switch, Input, Divider, Icon, Alert } from 'antd';
import {
    fetchCapabilityById,
    toggleIsFetchingCapabilityForm,
    clearCapabilityForm,
    postDemoCapability,
    fetchRoleTemplates,
    saveDemoCapabilityRoleTemplates,
    cancelDemoCapabilityRoleTemplates,
    closeCurrentOpenTab,
    selectTitle,
    toggleHasUnsavedChangesFlag,
    updateOverallInstructionFromForm,
    updateCapabilityQuestionInstructionFromForm,
    deleteCapabilityQuestionInstruction,
    clearCapabilityQuestionsInstructions
} from "../../actions";

import FormActionButtons from "../common/FormActionButtons";
import CONFIG from "../../config";
import TextEditor from '../common/TextEditor';
import { checkRoleTemplateChanges } from "../../utils/status";
// import { isTabAlreadyOpen, isTabRestoredFromLocalStorage } from "../common/TabLayout";
import CapabilityRoleTemplate from "./CapabilityRoleTemplate"
import pushMessage from '../common/PushMessage';
const { Option } = Select;
const { TextArea } = Input;

function DemoCapabilityForm(props) {

    //declarations

    // let generalInstructionKey;
    // let questionInstructionKey;
    const { form: { getFieldDecorator, getFieldValue } } = props;
    const isEditMode = props.match.params.capabilityId !== undefined;
    const { capabilityId } = props.match.params;
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState(0);
    const [ckEditorLoading, setCkEditorLoading] = useState(true);
    const [generalInstructionKey, setGeneralInstructionKey] = useState();
    const [questionInstructionKey, setQuestionInstructionKey] = useState();
    const allRoleTemplates = useSelector(({ roleTemplates }) => roleTemplates?.all || []);
    const capabilityForm = useSelector(({ capabilityForm }) => capabilityForm);
    const capabilityQuestionInstruction = useSelector(({ capabilityQuestionInstruction }) => capabilityQuestionInstruction);
    const capabilityGeneralInstruction = useSelector(({ capabilityGeneralInstruction }) => capabilityGeneralInstruction);
    const user = useSelector(({ user }) => user?.profile);
    const tabsLayout = useSelector(({ tabsLayout }) => tabsLayout);

    const { loading, formData, allRTAdminAccess, capabilityObj, } = capabilityForm;
    const { title, isActive, description, instruction, roleTemplates, questions } = formData || {};

    const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;
    getFieldDecorator('keys', { initialValue: isEditMode ? ([...Array(questions?.length)?.keys()]) : [] });
    const keys = (getFieldValue('keys') || [])

    //LifeCycle's

    useEffect(() => {
        const { capabilityId } = props.match.params;
        batch(async () => {
            dispatch(selectTitle('Manage Capability'));
            await dispatch(fetchRoleTemplates());
            if (capabilityId) {
                dispatch(toggleIsFetchingCapabilityForm(true));
                await dispatch(fetchCapabilityById(capabilityId))
                    .then((data) => {
                        const dataLength = data?.questions?.length;
                        setId(dataLength);
                        setGeneralInstructionKey(Math.random().toString());
                        setQuestionInstructionKey(Math.random().toString());
                        setTimeout(() => setCkEditorLoading(false), 2400)
                    });

                dispatch(toggleIsFetchingCapabilityForm(false));
            }
            setTimeout(() => setCkEditorLoading(false), 2400)
            dispatch(toggleIsFetchingCapabilityForm(false));
        })

        return () => {
            const { form } = props;
            dispatch(toggleHasUnsavedChangesFlag(tabsLayout.activeKey, false))
            setId(0);
            form.resetFields();
            dispatch(clearCapabilityForm())
            dispatch(clearCapabilityQuestionsInstructions())
        }
        //eslint-disable-next-line
    }, [])


    const getCurrentData = (values) => {
        return {
            name: values?.name,
            description: values?.description,
            isActive: values?.isActive,
            roleTemplates: formData?.roleTemplates,
            keys: values?.keys,
            title: values?.title,
            answerType: values?.answerType,
            isMandatory: values?.isMandatory,
            answers: values?.answers || [],
            questionId: values?.questionId || []
        }
    }

    const checkAnswerChanges = (oldData, newData) => {
        let modifiedOldData = oldData?.map(data => data?.title);
        let newModifiedData = newData?.map(data => data?.title);
        return modifiedOldData?.every(item => newModifiedData?.includes(item)) && newModifiedData?.every(item => modifiedOldData?.includes(item))
    }

    const checkHasFormChanged = (currentCapabilityForm, isSuperAdminUser) => {
        const { title, description, isActive, roleTemplates, instruction, questions } = capabilityObj || {};
        let hasChanged = false;
        if (capabilityId) {
            hasChanged = title?.trim() !== currentCapabilityForm?.name?.trim()
                || description?.trim() !== currentCapabilityForm?.description?.trim()
                || isActive !== currentCapabilityForm?.isActive
                || instruction?.trim() !== capabilityGeneralInstruction?.html?.trim()
                || checkRoleTemplateChanges(roleTemplates, currentCapabilityForm?.roleTemplates, isSuperAdminUser);

            if (hasChanged) return hasChanged;

            if (currentCapabilityForm?.keys?.length > 0 || questions?.length > 0) {
                if (currentCapabilityForm?.keys?.length !== questions?.length) hasChanged = true;
                else {
                    let questionsObj = [];
                    let question = {}

                    for (let i = 0; i < currentCapabilityForm?.keys?.length; i++) {
                        question = {
                            title: currentCapabilityForm?.title?.length > 0 ? currentCapabilityForm?.title[i] : undefined,
                            answerType: currentCapabilityForm?.answerType?.length > 0 ? currentCapabilityForm?.answerType[i] : undefined,
                            isMandatory: currentCapabilityForm?.isMandatory?.length > 0 ? currentCapabilityForm?.isMandatory[i] : undefined,
                            isActive: true,
                            instruction: capabilityQuestionInstruction[i]?.html,
                            answers: currentCapabilityForm?.answers?.length > 0 ? currentCapabilityForm?.answers[i] ? currentCapabilityForm?.answers[i]?.map(answer => ({ title: answer })) : null : []
                        }
                        questionsObj.push({ question: question });
                        question = {}
                    }

                    const changed = questionsObj?.map(newData => questions?.some(oldData => (
                        oldData?.question?.title === newData?.question?.title
                        && oldData?.question?.isMandatory === newData?.question?.isMandatory
                        && oldData?.question?.instruction?.trim() === newData?.question?.instruction?.trim()
                        && (oldData?.question?.answerType === ("Textbox")
                            ? true
                            : checkAnswerChanges(oldData?.question?.answers, newData?.question?.answers))
                    )));

                    if (changed?.includes(false)) { hasChanged = true };
                }
            }

        }
        if (hasChanged) return hasChanged;

        return hasChanged;
    }

    //Browser US
    const currentCapabilityForm = getCurrentData(props.form.getFieldsValue())
    const hasChanged = capabilityId ? checkHasFormChanged(currentCapabilityForm, isSuperAdminUser) : true;

    //componentDidUpdate
    useEffect(() => {
        const { panes, activeKey } = tabsLayout;
        const currentCapabilityForm = getCurrentData(props.form.getFieldsValue())
        const hasChanged = capabilityId ? checkHasFormChanged(currentCapabilityForm, isSuperAdminUser) : true;
        const hasUnsavedChanges = panes?.filter(item => item.key === activeKey)?.[0]?.hasUnsavedChanges;
        if (!hasUnsavedChanges && hasChanged)
            dispatch(toggleHasUnsavedChangesFlag(tabsLayout.activeKey, true))
        else if (hasUnsavedChanges && !hasChanged)
            dispatch(toggleHasUnsavedChangesFlag(tabsLayout.activeKey, false))
        //eslint-disable-next-line
    }, [hasChanged])


    const handleCreate = () => {
        const { form } = props;
        const { getFieldValue } = form;
        const { capabilityId } = props.match.params
        form.validateFields(async (err, values) => {
            if (err) {
                pushMessage(CONFIG.messageType.warning, "Please verify the fields");
                return;
            }

            else if (values?.keys?.length < 1) {
                pushMessage(CONFIG.messageType.warning, 'You need to add ateast one question before saving');
            }

            else {
                dispatch(toggleIsFetchingCapabilityForm(true));
                let capabilityObject = {
                    title: values?.name,
                    description: values?.description,
                    isActive: values?.isActive,
                    roleTemplates: formData?.roleTemplates,
                    instruction: capabilityGeneralInstruction?.html

                }
                let questions = [];
                let question = {}
                const keys = (getFieldValue('keys') || [])
                for (let i = 0; i < values?.title?.length; i++) {
                    if (keys.includes(i)) {
                        question = {
                            title: values?.title[i],
                            id: values?.questionId[i] || undefined,
                            answerType: values?.answerType[i],
                            isMandatory: values?.isMandatory[i],
                            isActive: true,
                            answers: values?.answerType[i] === "Textbox" ? [] : values?.answers[i] ? values?.answers[i]?.map(answer => ({ title: answer })) : [],
                            instruction: capabilityQuestionInstruction[i]?.html
                        }
                        questions.push({ id: values?.capabilityQuestionId[i] || undefined, question: question });
                        question = {}
                    }
                }
                capabilityObject = { ...capabilityObject, questions }
                await dispatch(postDemoCapability(capabilityObject, capabilityId))
            }
        })
    }

    //functions
    const renderAssignButton = () => {
        return (
            <Button
                className="primary-action-button-bordered right-border-radius"
                onClick={() => setShowModal(true)}
            >
                Assign
            </Button>
        );
    };

    const add = () => {
        const { getFieldValue, setFieldsValue } = props.form;
        const keys = getFieldValue('keys');
        setId(id => id + 1)
        const nextKeys = keys.concat(id);
        setFieldsValue({
            keys: nextKeys,
        });
    }

    const remove = (k) => {
        const { getFieldValue, setFieldsValue } = props.form;
        const keys = getFieldValue('keys');
        setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
        dispatch(deleteCapabilityQuestionInstruction(k))
    }

    const renderQuestions = (id) => {
        const { form } = props;
        const { capabilityId } = props.match.params;
        const { getFieldDecorator, getFieldValue } = form;
        return (
            <>
                <Row gutter={48}>
                    <Form.Item style={{ display: 'none' }} >
                        {
                            getFieldDecorator(`questionId[${id}]`, {
                                initialValue: (isEditMode && formData?.questions) ? formData?.questions[id]?.question?.id || "" : undefined
                            })
                        }
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} >
                        {
                            getFieldDecorator(`capabilityQuestionId[${id}]`, {
                                initialValue: (isEditMode && formData?.questions) ? formData?.questions[id]?.id || "" : undefined
                            })
                        }
                    </Form.Item>
                    <Col xl={6} lg={12} sm={20} xs={24}>
                        <Form.Item label="Question Title">
                            {getFieldDecorator(`title[${id}]`, {
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input the question",
                                    },
                                ],
                                initialValue: (isEditMode && formData?.questions) ? formData?.questions[id]?.question?.title || "" : undefined,
                            })(<Input />)}
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={12} sm={20} xs={24}>
                        <Form.Item label="Answer Type">
                            {getFieldDecorator(`answerType[${id}]`, {
                                rules: [
                                    {
                                        required: true,
                                        message: "Select the field type",
                                    },
                                ],
                                initialValue: (isEditMode && formData?.questions) ? formData?.questions[id]?.question?.answerType || "" : undefined,
                            })(<Select
                                allowClear={true}
                                showArrow={true}
                                disabled={(capabilityId && formData?.questions[id]?.id) ? true : false}
                                placeholder="Select the field type"
                            >
                                {CONFIG.demoQuestionAnswerTypes?.map((item, index) => (
                                    <Option key={index} value={item.value} >{item.name}</Option>
                                ))}
                            </Select>)}
                        </Form.Item>
                    </Col>
                    <Col xl={6} lg={12} sm={20} xs={24}>
                        <Form.Item style={getFieldValue(`answerType[${id}]`)?.includes("Textbox") ? { display: "none" } : {}} label="Answer Options">
                            {getFieldDecorator(`answers[${id}]`, {
                                rules: [
                                    {
                                        required: getFieldValue(`answerType[${id}]`)?.includes("Textbox") ? false : true,
                                        message: "Select the field type",
                                    },
                                ],
                                initialValue: (isEditMode && formData?.questions) ?
                                    formData?.questions[id]?.question?.answers?.[0]?.title ?
                                        formData?.questions[id]?.question?.answers?.map(answer => answer?.title) :
                                        formData?.questions[id]?.question?.answers?.map(answer => answer)
                                        || undefined : undefined,

                            })(< Select
                                allowClear={true}
                                showArrow={true}
                                placeholder="Select the field type"
                                mode="tags">
                            </Select>)}
                        </Form.Item>
                    </Col>
                    <Col xl={4} sm={4} xs={24}>
                        <Form.Item label="Mandatory">
                            {getFieldDecorator(`isMandatory[${id}]`, {
                                valuePropName: "checked",
                                initialValue: (isEditMode && formData?.questions) ? questions[id]?.question?.isMandatory || false : false,
                            })(
                                <Switch checkedChildren="Yes" unCheckedChildren="No" />
                            )}
                        </Form.Item>
                    </Col>
                    <Col style={{ marginTop: "30px" }} xl={2} lg={2} sm={2} xs={2}>
                        <Icon
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => remove(id)}
                        />
                    </Col>
                </Row >
                <div className="bottom-spacing">
                    <h3>Instruction</h3>
                    {renderWarningMessage()}
                    <br />
                    <TextEditor
                        recordId={id}
                        updateEditorContent={dispatch(updateCapabilityQuestionInstructionFromForm)}
                        componentKey="capabilityQuestionInstruction"
                        storeKey="capabilityQuestionInstruction"
                        editorKey={`${questionInstructionKey}_${id}`}
                        isEdit={isEditMode}
                        contentData={capabilityQuestionInstruction?.[id]?.html}
                    />
                </div>
                <Divider />
            </>
        )
    }

    const getAssignedRoleTemplates = (isSuperAdminUser) => {
        let assignedRoleTemplates = [];
        if (roleTemplates) {
            roleTemplates.forEach(obj => {
                const userAccessCondition = obj.userAccess && obj.userAccess !== CONFIG.roleTemplateAccess.none
                const adminAccessCondition = obj.adminAccess && obj.adminAccess !== CONFIG.roleTemplateAccess.none
                if (isSuperAdminUser ? userAccessCondition || adminAccessCondition : userAccessCondition) {
                    assignedRoleTemplates.push(obj.roleTemplate.title);
                }
            })
        }
        return assignedRoleTemplates;
    }

    const assignedRoleTemplates = getAssignedRoleTemplates(isSuperAdminUser);

    const renderWarningMessage = () => {
        return <Alert message={CONFIG.systemMessage.ckEditorSourceModeWarning} type="warning" showIcon />;
    }
    const handleCancel = async () => {
        const { form } = props;
        form.resetFields();
        dispatch(closeCurrentOpenTab())
    };

    return (
        <div className="content-container">
            <Spin spinning={loading || ckEditorLoading} wrapperClassName="spin-overlay">
                <Form>
                    <Row gutter={48}>
                        <Col xl={10} lg={12} sm={20} xs={24}>
                            <Form.Item label="Capability Title">
                                {getFieldDecorator("name", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input the question",
                                        },
                                    ],
                                    initialValue: title ?? "",
                                })(<Input id="capability-name" />)}
                            </Form.Item>
                        </Col>
                        {isSuperAdminUser && <Col xl={10} lg={12} sm={20} xs={24}>
                            <CapabilityRoleTemplate
                                form={props.form}
                                assignedRoleTemplates={assignedRoleTemplates}
                                renderAssignButton={renderAssignButton}
                                showModal={showModal}
                                setShowModal={setShowModal}
                                allRoleTemplates={allRoleTemplates}
                                formData={formData}
                                allRTAdminAccess={allRTAdminAccess}
                            />
                        </Col>}

                        <Col xl={4} sm={4} xs={24}>
                            <Form.Item label="Status">
                                {getFieldDecorator("isActive", {
                                    valuePropName: "checked",
                                    initialValue: isActive,
                                })(
                                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                                )}
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={48}>
                        <Col xl={22} lg={20} sm={20} xs={24}>
                            <Form.Item label="Description">
                                {getFieldDecorator("description", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input the description",
                                        },
                                    ],
                                    initialValue: description ?? "",
                                })(
                                    <TextArea />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="bottom-spacing">
                        <h3>Instruction</h3>
                        {renderWarningMessage()}
                        <br />
                        <TextEditor
                            // updateEditorContent={updateOverallInstruction}
                            // storeKey="capabilityGeneralInstruction"
                            // componentKey={CONFIG.editorType.capabilityGeneralInstruction}
                            // editorKey={generalInstructionKey}
                            // isEdit={isEditMode}
                            recordId={capabilityId ? capabilityId : "-1 "}
                            updateEditorContent={dispatch(updateOverallInstructionFromForm)}
                            storeKey="capabilityGeneralInstruction"
                            contentData={instruction}
                            editorKey={generalInstructionKey}
                            isEdit={isEditMode}
                        />
                    </div>
                    <Divider />
                    <div>
                        <h3 align="center">Additional Questions</h3>
                        {keys.map((key) => {
                            return renderQuestions(key)
                        })}
                        <br />
                        <div align="center">
                            <Button type="dashed"
                                onClick={add}
                                style={{ width: '25%' }}>
                                <Icon type="plus" /> Add Question
                            </Button>
                        </div>
                    </div>

                    <Row className="right-align">
                        <FormActionButtons
                            handleSubmit={handleCreate}
                            cancelText={"Discard & Close"}
                            isDisabled={!hasChanged}
                            handleCancel={handleCancel}
                            okText={isEditMode ? "Update" : "Create"}
                        />
                    </Row>
                </Form>
            </Spin>
        </div>
    )
}
const DemoCapabilityFormRef = Form.create()(DemoCapabilityForm);

export default connect(null, {
    fetchCapabilityById,
    toggleIsFetchingCapabilityForm,
    clearCapabilityForm,
    postDemoCapability,
    fetchRoleTemplates,
    saveDemoCapabilityRoleTemplates,
    cancelDemoCapabilityRoleTemplates,
    closeCurrentOpenTab,
    selectTitle,
    toggleHasUnsavedChangesFlag,
    clearCapabilityQuestionsInstructions
})(DemoCapabilityFormRef);