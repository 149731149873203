import React, { Component } from 'react'
import { Radio, Row } from 'antd';
import { Line, Column } from '@ant-design/charts';
import CONFIG from '../../config';


export class GeneralReportGraph extends Component {

    state = {
        graphType: CONFIG.graphTypes.lineGraph,
    }

    handleGraphTypeChange = (e) => {
        const graphType = e.target.value;
        this.setState({ graphType: graphType });
    }

    render() {

        const { graphType } = this.state;
        const { graphData, reportObject } = this.props;

        const reportName = (reportObject?.parentReport === null || reportObject?.parentReport === undefined) ? reportObject?.name : reportObject?.parentReport?.name;
        const seriesField = (reportName === CONFIG.generalReportNames.associateTop5 || reportName === CONFIG.generalReportNames.partnerTop5)
            ? "demoTitle"
            : "subject"
        const yField = reportName === CONFIG.generalReportNames.demoHours ? "hoursScheduled" : "scheduleCount"

        var lineGraphConfig = {
            data: graphData?.chart,
            autoFit: false,
            xField: 'month',
            xAxis: {
                label: {
                    autoRotate: false,
                    offset: 10,
                    style: {
                        fontSize: 11,
                    }
                }
            },
            yField: yField,
            seriesField: seriesField,
            color: seriesField === "subject" ? ['#004066', '#F6662A', '#899E8B'] : ['#004066', '#D59006', '#899E8B', '#482902', '#E5350B'],
            smooth: true,
            legend: {
                position: 'bottom'
            }
        };

        var barGraphConfig = {
            data: graphData?.chart,
            autoFit: false,
            xField: 'month',
            columnWidthRatio: 0.4,
            xAxis: {
                label: {
                    autoRotate: false,
                    offset: 10,
                    style: {
                        fontSize: 11,
                    }
                }
            },
            yField: yField,
            color: seriesField === "subject" ? ['#004066', '#F6662A', '#899E8B'] : ['#004066', '#D59006', '#899E8B', '#482902', '#E5350B'],
            seriesField: seriesField,
            legend: {
                position: 'bottom'
            },
            isStack: true,
        };

        return (
            <div>
                <Row className="bottom-spacing-4">
                    <Radio.Group value={this.state.graphType} onChange={this.handleGraphTypeChange}>
                        <Radio.Button value={CONFIG.graphTypes.lineGraph}>Line Graph</Radio.Button>
                        <Radio.Button value={CONFIG.graphTypes.barGraph}>Bar Graph</Radio.Button>
                    </Radio.Group>
                </Row>
                <Row>
                    <div>
                        {graphType === CONFIG.graphTypes.lineGraph
                            ? graphData?.chart !== undefined && <Line {...lineGraphConfig} height={350} />
                            : graphData?.chart !== undefined && <Column {...barGraphConfig} height={350} />}
                    </div>
                </Row>
            </div >
        )
    }
}

export default GeneralReportGraph
