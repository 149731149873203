import { batch } from 'react-redux';
import {
    ADD_DEMO_TAB_DETAILS,
    UPDATE_DEMO_DESCRIPTION,
    UPDATE_DEMO_COMPONENT_DETAILS,
    UPDATE_DEMO_ACCESS,
    UPDATE_DEMO_STATUS,
    RESET_DEMO_STATUS,
    UPDATE_DEMO_ROLE_TEMPLATES,
    TOGGLE_ALL_DEMO_ROLE_TEMPLATES
} from './types';
import CONFIG from "../config";
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';

const getDemoTabStatusObj = (content, data, isDemoClone) => {
    const status = data
        ? data.map(dataCenter => {
            const dataCenterStatus = content?.find((status) => status.dataCenter.id === dataCenter.id)
            if (dataCenterStatus) {
                if (isDemoClone) return { ...dataCenterStatus, id: undefined }
                else return dataCenterStatus;
            }
            else {
                return {
                    status: CONFIG.demoStatus.default,
                    dataCenter: { ...dataCenter, regions: undefined },
                    description: ""
                }
            }
        }) : []
    return status;
}

export const updateDemoCkEditorContents = (editorType, demoId, editorContent, isClone) => {
    const type = editorType === CONFIG.editorType.demoDescription
        ? UPDATE_DEMO_DESCRIPTION
        : editorType === CONFIG.editorType.demoComponent
            ? UPDATE_DEMO_COMPONENT_DETAILS
            : UPDATE_DEMO_ACCESS
    return {
        type,
        payload: {
            id: demoId,
            editorContent,
            isClone,
            isContentLoaded: true
        }
    };
};
export const updateDemoDCStatus = (dcObject) => {
    return {
        type: UPDATE_DEMO_STATUS,
        payload: dcObject
    };
};
export const resetDemoStatus = () => (dispatch, getState) => {
    const { dataCenters: { data } } = getState();
    const status = data
        ? data.map(dataCenter => {
            return {
                status: CONFIG.demoStatus.default,
                dataCenter: { ...dataCenter, regions: undefined },
                description: ""
            }
        })
        : [];
    dispatch({
        type: RESET_DEMO_STATUS,
        payload: status
    });
}

const filterQuestionsOnType = (questions, type) => {
    return questions?.filter(dq => dq.type === type);
}

export const addDemoTabDetailsOnCreate = () => async (dispatch, getState) => {
    const { dataCenters: { data } } = getState();
    const demoDCObj = await getDemoTabStatusObj([], data, false);
    batch(() => {
        dispatch({
            type: ADD_DEMO_TAB_DETAILS,
            payload: {
                demoId: -1,
                demoBasicDetails: {},
                demoEditorContent: {},
                assignedPreRegisterQuestions: [],
                assignedAdditionalInfoQuestions: [],
                demoResources: [],
                demoCapability: [],
                demoDCStatuses: demoDCObj,
                demoCollaterals: [],
                demoEzDemos: [],
                originalDemoObj: undefined,
                isDemoClone: false
            }
        });
    });
}
export const addDemoTabDetails = ({ demoId, basicDetails, demo, fromAPI = false, isDemoClone = false }) => async (dispatch, getState) => {
    const state = getState();
    const currentDemoTab = state.openTabDemoDetails?.currentTabData;
    const { dataCenters: { data } } = getState();
    let demoBasicDetails = {}
    let assignedPreRegisterQuestions = []
    let assignedAdditionalInfoQuestions = []
    let demoResources = []
    let demoDCStatuses = []
    let demoCollaterals = []
    let demoEzDemos = []
    let demoCapability = []
    let demoEditorContent = {}


    demoBasicDetails = {
        ...basicDetails,
        assignedRoleTemplates: basicDetails?.roleTemplates || [],
    }


    const descriptionHtml = currentDemoTab?.demoEditorContent?.descriptionHtml;
    const componentDetailsHtml = currentDemoTab?.demoEditorContent?.componentDetailsHtml;
    const connectivityDetailsHtml = currentDemoTab?.demoEditorContent?.connectivityDetailsHtml;
    demoEditorContent = {
        ...demoEditorContent,
        descriptionHtml: fromAPI ? demo?.description : descriptionHtml,
        componentDetailsHtml: fromAPI ? demo?.componentDetails : componentDetailsHtml,
        connectivityDetailsHtml: fromAPI ? demo?.connectivityDetails : connectivityDetailsHtml
    }
    const currentDemoAdditionalInfoQuestions = currentDemoTab?.assignedAdditionalInfoQuestions;
    const currentDemoPreRegisterQuestions = currentDemoTab?.assignedPreRegisterQuestions;
    assignedAdditionalInfoQuestions = [
        ...assignedAdditionalInfoQuestions,
        ...fromAPI
            ? isDemoClone
                ? []
                : filterQuestionsOnType(demo?.demoQuestions, CONFIG.demoQuestionTypes?.[0]?.value)
            : currentDemoAdditionalInfoQuestions || [],
    ]
    assignedPreRegisterQuestions = [
        ...assignedPreRegisterQuestions,
        ...fromAPI
            ? isDemoClone
                ? []
                : filterQuestionsOnType(demo?.demoQuestions, CONFIG.demoQuestionTypes?.[1]?.value)
            : currentDemoPreRegisterQuestions || [],
    ]
    const currentDemoResources = currentDemoTab?.demoResources;
    demoResources = [
        ...demoResources,
        ...fromAPI ? demo?.demoResources : currentDemoResources || []]
    const currentDemoDCStatus = currentDemoTab?.demoDCStatuses;
    const demoDCObj = fromAPI && await getDemoTabStatusObj(demo?.demoDCStatuses, data, isDemoClone)
    demoDCStatuses = [
        ...demoDCStatuses,
        ...fromAPI ? demoDCObj : currentDemoDCStatus || []
    ]
    const currentDemoCollaterals = currentDemoTab?.demoCollaterals;
    demoCollaterals = fromAPI
        ?
        isDemoClone
            ? []
            :
            {
                ...demoCollaterals,
                assignedCollaterals: fromAPI ? demo?.collaterals : [],
                newCollaterals: [...fromAPI ? [] : []]
            }
        : currentDemoCollaterals;

    const currentEZDemos = currentDemoTab?.demoEzDemos;
    demoEzDemos = [
        ...demoEzDemos,
        ...fromAPI ? isDemoClone ? [] : demo?.demoEzDemos : currentEZDemos || []
    ]
    const currentCapability = currentDemoTab?.demoCapability || [];

    demoCapability = fromAPI ? isDemoClone ? [] : [...(demo?.demoCapabilities?.length > 0 ? demo?.demoCapabilities : []), ...currentCapability] : currentCapability;

    const payload = {
        demoId: parseInt(demoId),
        isDemoClone,
        demoBasicDetails,
        demoEditorContent,
        assignedPreRegisterQuestions,
        assignedAdditionalInfoQuestions,
        demoResources,
        demoDCStatuses,
        demoCollaterals,
        demoEzDemos,
        demoCapability
    }

    batch(() => {
        dispatch({
            type: ADD_DEMO_TAB_DETAILS,
            payload: {
                ...payload,
                originalDemoObj: fromAPI ? JSON.parse(JSON.stringify(payload)) : currentDemoTab?.originalDemoObj,
            }
        });
    });
};
export const updateDemoRoleTemplates = (access, roleType, accessType, selectedRoleTemplate) => (dispatch, getState) => {
    const { openTabDemoDetails, roleTemplates } = getState();
    const allRoleTemplates = roleTemplates.all;
    const assignedRoleTemplates = openTabDemoDetails?.currentTabData?.demoBasicDetails?.roleTemplates ? openTabDemoDetails?.currentTabData?.demoBasicDetails?.roleTemplates : [];
    let updatedDemoRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    const accessKey = roleType === CONFIG.roleTypes.user ? 'allRoleTemplatesUserAccess' : 'allRoleTemplatesAdminAccess';
    batch(() => {
        dispatch({
            type: UPDATE_DEMO_ROLE_TEMPLATES,
            payload: { updatedDemoRoleTemplates, isAllAccessChanged: selectedRoleTemplate === undefined, accessKey }
        });
        if (!selectedRoleTemplate) {
            dispatch({
                type: TOGGLE_ALL_DEMO_ROLE_TEMPLATES,
                payload: { access, accessType, accessKey }
            });
        }
    });
}
