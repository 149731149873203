import { TOGGLE_BACK_BUTTON, SELECT_TITLE, TOGGLE_MENU_BUTTON, TOGGLE_CLEAR_PATH, POST_SEARCH_RESULT } from './types';
import { aepReport } from '../apis';
import CONFIG from '../config';

export const toggleBackButton = (flag, path) => {
    return {
        type: TOGGLE_BACK_BUTTON,
        payload: { flag, path }
    };
};

export const clearPathValue = () => {
    return {
        type: TOGGLE_CLEAR_PATH
    }
}

export const selectTitle = title => {
    return {
        type: SELECT_TITLE,
        payload: title
    };
};

export const toggleMenuButton = flag => {
    return {
        type: TOGGLE_MENU_BUTTON,
        payload: flag
    };
};

export const fetchSearchResults = async ({ searchText, searchFilter, cancelToken }) => {
    const response = await aepReport.get("/reportSearchService/search/", {
        cancelToken: cancelToken.token,
        params: {
            searchText: searchText,
            filterOn: searchFilter
        },
    })
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data || []
    else return []

}

export const postSearchResult = (results) => {
    return {
        type: POST_SEARCH_RESULT,
        payload: results,
    }
}