import React, { Component } from 'react'
import { Modal, Carousel } from 'antd';
import { connect } from 'react-redux';
import { Button } from "@avaya/neo-react";
import welcome1 from "../../assets/images/tour-guide/Welcome1.png";
import welcome2 from "../../assets/images/tour-guide/Welcome2.png";
import welcome3 from "../../assets/images/tour-guide/Welcome3.png";
import welcome4 from "../../assets/images/tour-guide/Welcome4.png";
import welcome5 from "../../assets/images/tour-guide/Welcome5.png"
import { showPortalTourModal, setPortalTourComplete } from "../../actions";
class PortalTour extends Component {

    carouselRef = React.createRef();
    state = {
        currentSlide: 0,
    }

    handleBeforeChange = (from, to) => {
        this.setState({ currentSlide: to });
    };

    handleAfterChange = (current) => {
        this.setState({ currentSlide: current });
    };

    handlePrevClick = async () => {
        const { currentSlide } = this.state;
        if (currentSlide > 0) {
            this.carouselRef.goTo(currentSlide - 1); // Use the 'goTo' method to move to the previous slide
        }
    };

    handleNextClick = async () => {
        const { currentSlide } = this.state;
        if (currentSlide < 4) {
            this.carouselRef.goTo(currentSlide + 1); // Use the 'goTo' method to move to the next slide
        }
    };

    handleGetStarted = async () => {
        const { fromApp } = this.props;
        fromApp && this.props.handleGetStarted();
    };

    handleCloseTour = () => {
        this.props.showPortalTourModal(false, false);
    }

    handleCompleteTour = async () => {
        const { fromApp } = this.props;
        await this.props.setPortalTourComplete();
        if (fromApp) this.props.handleGetStarted();
        else this.props.showPortalTourModal(false, false);
    }
    render() {
        const { currentSlide } = this.state;
        const { isPortalTourCompleted, isWhatsNew } = this.props;
        return (
            <Modal
                visible={this.props.isPortalTourModalVisible}
                closable={false}
                footer={null}
                className='center-vertically font'
                width={800}
                centered
            >
                <><Carousel
                    beforeChange={this.handleBeforeChange}
                    afterChange={this.handleAfterChange}
                    ref={(ref) => (this.carouselRef = ref)}
                >
                    <div>
                        <img className="tour-images" alt="homepage" src={welcome1} />
                    </div>
                    <div>
                        <img className="tour-images" alt="homepage" src={welcome2} />
                    </div>
                    <div>
                        <img className="tour-images" alt="homepage" src={welcome3} />
                    </div>
                    <div>
                        <img className="tour-images" alt="homepage" src={welcome4} />
                    </div>
                    <div>
                        <img className="tour-images" alt="homepage" src={welcome5} />
                    </div>
                </Carousel>
                    <span className='margin-1 portal-guide-btn'>
                        {currentSlide > 0 && <Button aria-label="button" variant="primary" animation="none" size="compact" status="info" onClick={this.handlePrevClick}>Previous</Button>}
                        {(currentSlide < 4) && <Button aria-label="button" variant="secondary" animation="none" size="compact" status="info" onClick={isWhatsNew ? this.handleCloseTour : this.handleGetStarted}>{isWhatsNew ? "Close" : "Remind me later"}</Button>}
                        {currentSlide < 4
                            ? <Button aria-label="button" variant="primary" animation="none" size="compact" status="info" onClick={this.handleNextClick}>Next</Button>
                            : <Button aria-label="button" variant="primary" animation="none" size="compact" status="info" onClick={isPortalTourCompleted ? this.handleGetStarted : this.handleCompleteTour}>{isPortalTourCompleted ? "Close" : "Finish"}</Button>}
                    </span>
                </>
            </Modal>
        )
    }
}

const mapStateToProps = ({ user }) => {
    return {
        isLoginComplete: user.isLoginComplete,
        isPortalTourModalVisible: user.showPortalTourModal,
        isWhatsNew: user.isWhatsNew,
        timeZoneName: user.profile?.timezone,
        isPortalTourCompleted: user.profile.isPortalTourCompleted,
        isAdmin: user.isAdmin,
    };
};

export default connect(mapStateToProps, {
    showPortalTourModal,
    setPortalTourComplete
})(PortalTour);