import { aepDemo } from "../apis";
import {
    FETCH_ACTIVE_CAPABILITY,
    ADD_DEMO_CAPABILITY,
    ADD_NEW_DEMO_CAPABILITY,
    DELETE_DEMO_CAPABILITY,
    EDIT_DEMO_CAPABILITY,
} from './types';

export const fetchActiveCapabilities = () => async dispatch => {
    const response = await aepDemo.get("/demoSolutionService/capabilities",
        { params: { filterSort: { filter: { isActive: true } } } });
    dispatch({
        type: FETCH_ACTIVE_CAPABILITY,
        payload: response?.data ? response?.data?.dataSet : []
    });
};

export const addDemoTabCapability = (capabilities) => {
    return {
        type: ADD_DEMO_CAPABILITY,
        payload: capabilities
    }
}

export const addNewDemoCapability = (capability) => {
    return {
        type: ADD_NEW_DEMO_CAPABILITY,
        payload: { capability }
    };
};

export const deleteDemoCapability = capabilityId => {
    return {
        type: DELETE_DEMO_CAPABILITY,
        payload: capabilityId
    };
};

export const editDemoCapability = capability => {
    return {
        type: EDIT_DEMO_CAPABILITY,
        payload: capability
    }
}
