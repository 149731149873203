import {
    FETCH_ALL_CUSTOM_EMAIL_TEMPLATES,
    FETCH_ALL_STANDARD_EMAIL_TEMPLATES,
    UPDATE_EMAIL_TEMPLATE_EDITOR_CONTENT,
    FETCH_EMAIL_TEMPLATE,
    CLEAR_EMAIL_TEMPLATE_CONTENT,
    TOGGLE_IS_FETCHING_EMAIL_TEMPLATE,
    FETCH_PLACEHOLDERS,
    UPDATE_EMAIL_TEMPLATE_TAB_DETAILS,
    CLEAR_CUSTOM_EMAIL_TEMPLATES,
    CLEAR_STANDARD_EMAIL_TEMPLATES,
    FETCH_ALL_EMAIL_CATEGORIES,
    LAST_CUSTOM_EMAIL_TEMPLATE_FILTER,
    LAST_STANDARD_EMAIL_TEMPLATE_FILTER,
    FETCH_DYNAMIC_EMAIL_TEMPLATE,
    FETCH_CUSTOM_EMAIL_TEMPLATE,
    UPDATE_EDITOR_CONTENT_CUSTOM_EMAIL_TEMPLATE
} from './types'
import { batch } from 'react-redux'
import { aepEmail } from '../apis';
import { closeCurrentOpenTab } from './tabsLayout';
import { toggleIsFetchingEmailTemplateVersions } from './dynamicEmailTemplate';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { download } from '../utils/file';

export const fetchEmailTemplates = ({ pageNumber = 1, pageSize = CONFIG.lazyLoadPageSize, filterSort, type = "CUSTOM" }) => async dispatch => {
    const response = await aepEmail.get(`/emailPushNotificationService/emailTemplates`, {
        params: {
            filterSort: filterSort,
            pageSize: pageSize,
            pageNumber: pageNumber,
            emailTemplateType: type
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: type === "CUSTOM"
                ? FETCH_ALL_CUSTOM_EMAIL_TEMPLATES
                : FETCH_ALL_STANDARD_EMAIL_TEMPLATES,
            payload: response.data
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Email Templates');
    dispatch(toggleIsFetchingEmailTemplateFlag(false));
}

export const fetchEmailTemplatesLite = ({ filterSort, type = "CUSTOM" }) => async dispatch => {
    const response = await aepEmail.get(`/emailPushNotificationService/v1/lite/emailTemplates`, {
        params: {
            filterSort: filterSort,
            emailTemplateType: type
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: type === "CUSTOM"
                ? FETCH_ALL_CUSTOM_EMAIL_TEMPLATES
                : FETCH_ALL_STANDARD_EMAIL_TEMPLATES,
            payload: response.data
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Email Templates');
    dispatch(toggleIsFetchingEmailTemplateFlag(false));
}

export const clearStandardEmailTemplates = () => {
    return { type: CLEAR_STANDARD_EMAIL_TEMPLATES }
}

export const clearCustomEmailTemplates = () => {
    return { type: CLEAR_CUSTOM_EMAIL_TEMPLATES }
}

export const fetchEmailTemplateById = (emailTemplateId, isDynamicEmailTemplateFlow) => async dispatch => {
    const response = await aepEmail.get(`/emailPushNotificationService/emailTemplates/${emailTemplateId}`)
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {


        if (!isDynamicEmailTemplateFlow) {
            batch(() => {
                dispatch({
                    type: FETCH_EMAIL_TEMPLATE,
                    payload: response.data
                })
                dispatch(updateEmailTemplateTabDetails({ emailTemplateId, emailTemplateDetails: response.data, fetchFromAPI: true }))
            })
        }
        else {
            batch(() => {
                dispatch({
                    type: FETCH_DYNAMIC_EMAIL_TEMPLATE,
                    payload: response.data
                })
            })
        }
        return response.data
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Email Templates');
    dispatch(toggleIsFetchingEmailTemplateFlag(false));
}


export const fetchCustomEmailTemplateById = (emailTemplateId) => async dispatch => {
    const response = await aepEmail.get(`/emailPushNotificationService/emailTemplates/${emailTemplateId}`)
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        batch(() => {
            dispatch({
                type: FETCH_CUSTOM_EMAIL_TEMPLATE,
                payload: response.data
            })
        })
        return response.data || {}
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Email Templates');
    dispatch(toggleIsFetchingEmailTemplateFlag(false));
}

export const updateEmailTemplateEditorContent = (componentKey, recordId, newHtmlBody, isClone) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_EMAIL_TEMPLATE_EDITOR_CONTENT,
        payload: { recordId, newHtmlBody }
    })
}

export const updateCustomEmailTemplateEditorContent = (emailTemplate) => async (dispatch) => {
    dispatch({
        type: UPDATE_EDITOR_CONTENT_CUSTOM_EMAIL_TEMPLATE,
        payload: emailTemplate
    })
}

export const updateCustomMaintenanceEmailTemplateEditorContent = (dispatch) => async (emailTemplate) => {
    dispatch({
        type: UPDATE_EDITOR_CONTENT_CUSTOM_EMAIL_TEMPLATE,
        payload: emailTemplate
    })
}

export const updateEmailTemplateTabDetails = ({ emailTemplateId, emailTemplateDetails, fetchFromAPI = false }) => async dispatch => {
    var formTabData = {};


    if (fetchFromAPI) {
        formTabData = {
            id: parseInt(emailTemplateId),
            details: emailTemplateDetails,
            originalDetails: emailTemplateDetails,
            bodyHtml: emailTemplateDetails.body,
            isContentLoaded: true
        }
    }
    else {
        formTabData = emailTemplateDetails
    }

    dispatch({
        type: UPDATE_EMAIL_TEMPLATE_TAB_DETAILS,
        payload: formTabData
    })
    dispatch(toggleIsFetchingEmailTemplateFlag(false));
}

export const saveEmailTemplates = (data, emailTemplateId) => async (dispatch, getState) => {
    const state = getState();
    const currentEmailTemplate = state.emailTemplateForm.emailTemplateDetailsTabData?.find(item => ((parseInt(item?.id) === parseInt(emailTemplateId))));
    let emailTemplate = {
        ...currentEmailTemplate?.originalDetails,
        ...data,
        body: currentEmailTemplate?.bodyHtml,
    }
    const response = await aepEmail.put(`/emailPushNotificationService/emailTemplates/${emailTemplate.id}`, emailTemplate)
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, "Updated Email Template successfully")
        dispatch(closeCurrentOpenTab())
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to update Email Template');
    dispatch(toggleIsFetchingEmailTemplateFlag(false));
}



///onsave call CLEAR_EMAILTEMPATES which will basically clear two things from state taht is one html second iscontent

/*
 CKEditor bug: Does not refresh the content if the same instance is loaded twice with different content
            In Edit mode, the editor instance should not be created until the content is fetched from backend

To Handle this scenario we should set html=undefined and isContentLoaded to false
*/
export const clearEmailTemplateContent = () => dispatch => {
    dispatch({
        type: CLEAR_EMAIL_TEMPLATE_CONTENT,
    })

}

export const toggleIsFetchingEmailTemplateFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_EMAIL_TEMPLATE,
        payload: flag
    };
}

export const fetchPlaceHoldersByEmailTemplateId = (emailTemplateId) => async dispatch => {
    const response = await aepEmail.get(`/emailPushNotificationService/placeholder/${emailTemplateId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_PLACEHOLDERS,
            payload: response.data
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch place holders');
}

export const exportEmailTemplates = async (fileName, fileFormat, filterSort, timeZone, emailTemplateType) => {
    const response = await aepEmail({
        method: "get",
        url: `/emailPushNotificationService/export/emailTemplate/${fileFormat}`,
        responseType: "blob",
        params: {
            filterSort,
            emailTemplateType,
            timeZone
        },
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(fileName, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}

export const exportEmailTemplateVersion = async (fileName, fileFormat, demoId, timeZone, type) => {
    const response = await aepEmail({
        method: "get",
        url: `/emailPushNotificationService/export/emailTemplateVersion/${fileFormat}`,
        responseType: "blob",
        params: {
            type,
            timeZone,
            demoId
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK) download(fileName, response.data)
    else pushMessage(CONFIG.messageType.error, "Unable to export table");
    return response;
}

export const currentEmailtemplateFilter = (type, filters, pageNumber, pageSize) => {
    return {
        type: type === "standard" ? LAST_STANDARD_EMAIL_TEMPLATE_FILTER : LAST_CUSTOM_EMAIL_TEMPLATE_FILTER,
        payload: [filters, pageNumber, pageSize]
    }
}

export const updateCustomEmailTemplateVersion = (versionDetails, values, demoId, isPostVersion) => async (dispatch, getState) => {
    dispatch(toggleIsFetchingEmailTemplateVersions(true))
    const state = getState();
    const postContextPath = "/emailPushNotificationService/emailTemplate/versions";
    const putContextPath = "/emailPushNotificationService/emailTemplates";
    const data = {
        type: versionDetails?.type,
        subject: versionDetails?.subject,
        title: values?.title,
        body: state.customEmailTemplate.html,
        demoId: demoId,
        description: values?.description,
        emailCategory: versionDetails?.emailCategory
    }
    const response = await aepEmail({
        method: isPostVersion ? 'post' : 'put',
        url: isPostVersion ? postContextPath : `${putContextPath}/${versionDetails?.id}`,
        data: data,
        params: { isVersionEdit: isPostVersion ? false : true }
    });


    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, `Template ${isPostVersion ? 'created' : 'updated'} successfully`)
        return isPostVersion && response.data
    }
    else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) {
        pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.duplicate_title.content)
        return "FAILED"
    }
    else {
        pushMessage(CONFIG.messageType.error, `Failed to save template!`);
        return "FAILED"
    }
}

export const fetchEmailTypeCategories = () => async dispatch => {
    const response = await aepEmail.get(`/emailPushNotificationService/emailTemplate/emailCategory`);
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: FETCH_ALL_EMAIL_CATEGORIES,
            payload: response?.data
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Email Templates Types');
    dispatch(toggleIsFetchingEmailTemplateFlag(false));
}