import {
    FETCH_ACTIVE_CAPABILITY,
    FETCH_ALL_USERS_FOR_DEMO
} from "../actions/types";

const capabilityState = {
    loading: false,
}

export const capabilityDemosReducer = (state = capabilityState, action) => {
    switch (action.type) {
        case FETCH_ACTIVE_CAPABILITY:
            return { ...state, allCapabilities: action.payload }
        case FETCH_ALL_USERS_FOR_DEMO:
            return { ...state, allUsers: action.payload }
        default: return state;
    }
}