import React from 'react'
import CONFIG from "../../../config";
import moment from 'moment-timezone';

function ScheduleTimeDetails({ demo, timezone, startTime, endTime, selectedResource, showResource, isMultipleScheduleBooking }) {
    const bufferStart = demo?.startTimeBuffer;
    const bufferEnd = demo?.endTimeBuffer;
    const bufferStartTime = bufferStart > 0 ? moment.tz(startTime, timezone).subtract(bufferStart, 'm') : undefined;
    const bufferEndTime = bufferEnd > 0 ? moment.tz(endTime, timezone).add(bufferEnd, 'm') : undefined;
    return (
        <div style={showResource ? { marginTop: "-14px" } : {}}>
            <div className="banner">
                <div className="banner-content">
                    <div className="banner-left">
                        {/* <div className='day-banner-left'>{moment(startTime).tz(timezone).format('dddd')}</div> */}
                        <div className='date-banner-left'>{moment.tz(startTime, timezone).format(CONFIG.dateFormats.schedulerDate)}</div>
                        <div className='day-banner-left'>Start Time: {moment.tz(startTime, timezone).format(" hh:mm A z")}</div>

                    </div>
                    <div className='banner-middle'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4168 6.47258C19.7081 6.17809 20.1829 6.17549 20.4774 6.46678L29.6774 15.5668C29.8193 15.7072 29.8995 15.8983 29.9 16.0979C29.9005 16.2976 29.8215 16.4892 29.6803 16.6303L20.4803 25.8303C20.1874 26.1232 19.7126 26.1232 19.4197 25.8303C19.1268 25.5374 19.1268 25.0626 19.4197 24.7697L27.3393 16.85H3.75C3.33579 16.85 3 16.5142 3 16.1C3 15.6858 3.33579 15.35 3.75 15.35H27.3253L19.4226 7.53322C19.1281 7.24193 19.1255 6.76707 19.4168 6.47258Z" fill="black" />
                        </svg>
                    </div>
                    <div className='banner-right'>
                        <div className='date-banner-left'>{moment.tz(endTime, timezone).format(CONFIG.dateFormats.schedulerDate)}</div>
                        <div className='day-banner-left'>End Time: {moment.tz(endTime, timezone).format(" hh:mm A z")}</div>

                    </div>
                    <div className='banner-seperator-parent'>
                        <div style={showResource ? { marginTop: "22px" } : {}} className="banner-separator" />
                    </div>

                    <div style={showResource ? { marginTop: "22px" } : {}} className="banner-right-content">
                        <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Demo Name:
                        </div>
                        <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                            {demo.title}
                        </div>
                        {(bufferStart > 0 || bufferEnd > 0) && <>
                            <div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>
                                Buffer Time:
                            </div>
                            <div className='banner-demo-buffer'>
                                {bufferStart > 0 && `Start: ${bufferStartTime?.format(CONFIG.timeFormats.default)} `} {bufferEnd > 0 && `| End: ${bufferEndTime?.format(CONFIG.timeFormats.default)}`}
                            </div>
                        </>}
                        {showResource && <><div style={{ fontSize: '12px', fontWeight: "400", lineHeight: "16px", color: "#242424" }}>Resource:
                        </div>
                            <div style={{ fontSize: '14px', fontWeight: "600", lineHeight: "20px", color: "#242424" }}>
                                {(isMultipleScheduleBooking && selectedResource?.length > 0) ?
                                    selectedResource?.map((item, index) => { return (<span>{item?.name} {index !== selectedResource?.length - 1 && " | "}</span>) })
                                    : selectedResource?.name}
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ScheduleTimeDetails