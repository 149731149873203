import React from 'react';
import { connect } from 'react-redux';
import { Spin } from "antd"

import {
    toggleBackButton,
    fecthDemoPurposeTypesLite,
    fetchVerticalSolutionsLite,
    clearDemoScheduler,
    getUserDemoById,
    getUserScheduleById,
    clearSolutionDemos,
    toggleHasUnsavedChangesFlag,
    updateCurrentDemoSchedule,
    getUserDemoByIdLite,
    getUserScheduleByIdLite,
    clearAllEvents
} from '../../../actions';
import NeoLabDemoScheduleForm from './NeoLabDemoScheduleForm';
import { Prompt } from 'react-router-dom';
import CONFIG from "../../../config";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class NeoScheduleLabDemo extends React.Component {

    state = {
        hasChanged: true,
        hasTimeChanged: true,
        demo: undefined,
        schedule: null,
        loading: true
    }

    async componentDidMount() {
        const { fromManageSide } = this.props.match.params;
        this.props.toggleHasUnsavedChangesFlag(this.props.activeKey, true);
        setTimeout(async () => {
            const isValidView = window.location.pathname.includes("/scheduler/lab")
            if (isValidView) {
                await this.props.fecthDemoPurposeTypesLite();
                await this.props.fetchVerticalSolutionsLite();
                const isCreate = window.location.pathname.includes("new");
                if (isCreate) {
                    await getUserDemoByIdLite(this.props.match.params.demoId)
                        .then((response) => {
                            this.setState({ demo: response, loading: false })
                        })
                }
                else {
                    await getUserScheduleByIdLite(this.props.match.params.scheduleId, fromManageSide === 'true' ? true : false)
                        .then(async (response) => {
                            await this.props.updateCurrentDemoSchedule(response, false)
                            this.setState({ demo: response?.demo, schedule: response, loading: false })
                        })
                }
            }
        }, 100)
    }

    componentWillUnmount() {
        this.props.clearDemoScheduler();
        this.props.clearSolutionDemos();
        this.props.clearAllEvents();
    }

    handleTimeZoneChange = (flag) => {
        this.setState({
            hasChanged: flag
        })
    }

    handleTimeChange = (flag) => {
        setTimeout(() => {
            this.setState({
                hasTimeChanged: flag
            })
        }, 0)
    }

    render() {
        const pathValues = this.props.location.pathValues;
        const { newEvents } = this.props;
        const { isReschedule } = this.props.match.params;
        // const { fromDashboard, } = this.props.location;
        const demo = this.state.demo
        return (
            <div className="font">
                <Spin indicator={antIcon} spinning={!this.state.hasTimeChanged}>
                    <Prompt
                        when={newEvents?.length > 0}
                        message={CONFIG.warningMessages.changesLost}
                    />
                    {this.state.demo?.id ? <div>
                        <NeoLabDemoScheduleForm
                            demo={demo}
                            timeZoneChanged={this.handleTimeZoneChange}
                            handleTimeChange={this.handleTimeChange}
                            pathValues={pathValues}
                            isReschedule={isReschedule === 'true' ? true : false}
                            schedule={this.state.schedule}
                        />
                        <br />
                    </div> : <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}><Spin indicator={antIcon} spinning={this.state.loading} /></div>}
                </Spin>

            </div>
        );
    }
}

const mapStateToProps = ({ newSchedule, tabsLayout }) => {
    return {
        newEvents: newSchedule.newEvents,
        activeKey: tabsLayout.activeKey
    };
};

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        fecthDemoPurposeTypesLite,
        clearDemoScheduler,
        getUserDemoById,
        getUserScheduleById,
        clearSolutionDemos,
        fetchVerticalSolutionsLite,
        toggleHasUnsavedChangesFlag,
        updateCurrentDemoSchedule,
        getUserDemoByIdLite,
        getUserScheduleByIdLite,
        clearAllEvents
    }
)(NeoScheduleLabDemo);