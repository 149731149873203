import React from 'react'
import SockJsClient from "react-stomp";
import { connect } from "react-redux";
import { notification, Tag, Alert } from 'antd';
import {
    getAllRealTimePushNotificationsId,
    saveRealTimePushNotificationsId,
    fetchUserNews,
    toggleBackButton,
    fetchSolutionDemo,
    refreshTrendingDemos,
    refreshDemoRatings,
    fetchUserSchedules,
    openLinkInNewTab,
    fetchUserNotifications,
    clearDashboardNotifications,
    fetchAdminDashboardRequestStats
} from '../actions'
import { getDemo } from '../actions/demos';
import { getNews } from '../actions/news'
import { getNotification } from '../actions/notifications';
import { getNotificationObj, handleViewDetails, getAccessList } from '../utils/notifications';
import CONFIG from '../config';
import { NotificationModal } from './common/NotificationModal';
import HTML from './common/HTML'
import moment from 'moment-timezone';
import history from '../history';
class RealTimePushNotification extends React.Component {

    state = {
        viewDetailsClick: false,
        modalVisible: false
    }

    componentDidMount() {
        this.props.getAllRealTimePushNotificationsId()
    }

    componentWillUnmount() {
        this.clientRef.disconnect();
    }

    handleMessage = async (message, messageIds) => {
        const existingId = messageIds?.find((msgId) => {
            return msgId === message?.id
        })
        const messageold = JSON.stringify(message.content?.split("||")?.[0])
        const isAdminUpdatedSchedule = (messageold === JSON.stringify("Your schedule is updated by Admin. ") || messageold === JSON.stringify("Schedule created by Admin on your behalf ") || messageold === JSON.stringify("Your schedule has been deleted by Admin."))
        if (!existingId) {
            const notification = getNotificationObj(message);
            const isDashboardLocation = history.location?.pathname === "/dashboard";

            if (message.content === "REFRESH") {
                this.props.refreshTrendingDemos(true);
            }
            else if (message.type === "NOTIFICATION_CAROUSEL_UPDATE") {
                await this.props.clearDashboardNotifications();
                await this.props.fetchUserNotifications();
            }
            else if(message.type === "REFRESH_PENDING_REQUESTS"){
                await this.props.fetchAdminDashboardRequestStats();
            }
            else if (isAdminUpdatedSchedule && isDashboardLocation) {
                this.props.fetchUserSchedules(({ isActive: true, viewType: CONFIG.dashboard.schedulesViewType.CARD })) && this.openNotification(notification)
            }
            else if (message.content?.split("||")?.[0] === "Rating Update") {
                this.props.refreshDemoRatings(message.content.split("||"))
            }
            else
                this.openNotification(notification)
        }
    }

    handleViewDetailsClick = () => {
        this.setState({ viewDetailsClick: true })
    }

    openNotification = (notificationDetail) => {
        const { timeZoneName } = this.props;
        const args = {
            key: CONFIG.realTimePushNotificationKey.replace("<messageId>", notificationDetail.messageId),
            message: <div>{notificationDetail.messageType} <br /> <Tag color={CONFIG.colors.userDemo} className="bottom-spacing-3">{notificationDetail.tag + "  " + moment.tz(notificationDetail.timestamp, timeZoneName).format(CONFIG.dateFormats.userDateTime)}</Tag></div>,
            description: this.renderDetails(notificationDetail),
            duration: 7,
            placement: 'topRight',
            style: {
                "maxHeight": "60vh",
                "overflow": "auto"
            }
        };

        this.props.saveRealTimePushNotificationsId(notificationDetail.messageId).then(
            notification.open(args));

    };

    renderDetails = (notificationOj) => {
        const pathname = this.props.location?.pathname;
        const accessDescription = (notificationOj?.messageType === CONFIG.notificationTypes.access) ? JSON.parse(notificationOj.description) : undefined;
        const isUserManagementChange = accessDescription?.refresh;
        return <div>
            <h2>{accessDescription ? CONFIG.listOfAccess : notificationOj.title}</h2>
            {notificationOj?.extraText && <HTML htmlContent={notificationOj?.extraText} />}
            {isUserManagementChange && <Alert message={CONFIG.warningMessages.notifications.userManagement} type="warning" showIcon />}
            <br />
            {accessDescription && getAccessList(accessDescription?.demos, "Demos", pathname, this.props.toggleBackButton, this.props.fetchSolutionDemo, this.handleModalOpen, this.setSelectedNotification, getDemo, null, null, isUserManagementChange)}
            {accessDescription && getAccessList(accessDescription?.news, "News", pathname, this.props.toggleBackButton, this.props.fetchUserNews, this.handleModalOpen, this.setSelectedNotification, getNews, null, null, isUserManagementChange)}
            {accessDescription && getAccessList(accessDescription?.notifications, "Notifications", pathname, this.props.toggleBackButton, null, this.handleModalOpen, this.setSelectedNotification, getNotification, null, null, isUserManagementChange)}
            {!accessDescription && <span
                class="link view-details"
                onClick={(e) => {
                    notification.close(CONFIG.realTimePushNotificationKey.replace("<messageId>", notificationOj.messageId));
                    this.notification = notificationOj;
                    this.handleViewDetailsClick();
                    const fromRealTime = true;
                    handleViewDetails(e, notificationOj, pathname, this.handleModalOpen, this.props.toggleBackButton, this.props.fetchUserNews, this.props.fetchSolutionDemo, null, null, null, fromRealTime, this.props.openLinkInNewTab);
                }}>
                View Details
            </span>
            }
        </div>
    }

    handleModalOpen = () => {
        this.setState({ modalVisible: true })
    }

    handleCancel = () => {
        this.setState({ modalVisible: false, viewDetailsClick: false })
        this.selectedNotification = '';
    }

    setSelectedNotification = (selectedNotification) => {
        this.selectedNotification = selectedNotification
    }

    render() {
        const { isLoginComplete, aepHeader, roleTemplates, messageIds, email, timeZoneName } = this.props;
        const { viewDetailsClick } = this.state;
        const url = process.env.REACT_APP_EMAIL_TEMPLATE_SERVICE_API_URL + "/websocket";
        const topicsEndpoint = roleTemplates ? roleTemplates.map(roleTemplate => `/topic/pushNotification/${roleTemplate.id}`) : [];
        email && topicsEndpoint.push(`/topic/pushNotification/${email}`);
        roleTemplates && roleTemplates.forEach(roleTemplate => topicsEndpoint.push(`/topic/demoTrendUpdate/${roleTemplate.id}`))
        roleTemplates && roleTemplates.forEach(roleTemplate => topicsEndpoint.push(`/topic/demoRatingsUpdate/${roleTemplate.id}`))
        roleTemplates && roleTemplates.forEach(roleTemplate => topicsEndpoint.push(`/topic/notificationCarousel/update/${roleTemplate.id}`))
        roleTemplates && roleTemplates.forEach(roleTemplate => topicsEndpoint.push(`/topic/requestStatus/update/${roleTemplate.id}`))
        return (isLoginComplete && roleTemplates ?
            < div >
                <SockJsClient
                    url={url}
                    topics={topicsEndpoint}
                    headers={{ "aep-header": aepHeader }}
                    onMessage={msg => this.handleMessage(msg, messageIds)}
                    ref={client => {
                        this.clientRef = client;
                    }}
                    //Retry every 2min for the first 5 times, every 5min for the next 15 times and every 20min indefinitely 
                    getRetryInterval={(count) => count <= 5 ? 120000 : count <= 20 ? 300000 : 1200000}
                    autoReconnect={true}
                />
                {this.state.modalVisible && <NotificationModal notification={!viewDetailsClick ? this.selectedNotification : this.notification} visible={this.state.modalVisible} timeZoneName={timeZoneName} onCancel={this.handleCancel} notificationAccess={true} />}
            </div >
            : null
        );
    }
}

const mapStateToProps = ({ user, realTimePushNotification }) => {
    return {
        isLoginComplete: user.isLoginComplete,
        aepHeader: user.aepHeader,
        roleTemplates: user.profile?.roleTemplates,
        messageIds: realTimePushNotification.messageId,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        email: user.profile?.email
    }
}

export default connect(mapStateToProps,
    {
        getAllRealTimePushNotificationsId,
        saveRealTimePushNotificationsId,
        fetchUserNews,
        toggleBackButton,
        fetchSolutionDemo,
        refreshTrendingDemos,
        refreshDemoRatings,
        fetchUserSchedules,
        openLinkInNewTab,
        fetchUserNotifications,
        clearDashboardNotifications,
        fetchAdminDashboardRequestStats
    })(RealTimePushNotification);
