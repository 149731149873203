import { Modal } from 'antd';

const { success } = Modal;

const openSuccess = (title, content, onOk, okText) => {
    success({
        title,
        content,
        onOk,
        okText,
        okButtonProps: { id: 'pop-up-confirm' },
    });
}

export default openSuccess;