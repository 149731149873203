import React from 'react';
import { Form, Modal } from 'antd';
import openInfo from '../common/Info';
import pushMessage from '../common/PushMessage';
import CONFIG from '../../config';
import { checkDataCenterDependencies } from '../../actions/dataCenters';
import { TextInput, Select, SelectOption, Switch, Form as NeoForm } from 'neo-latest';

function DataCenterDetails(props) {
    const { record, readOnly, form, regions } = props;
    const { getFieldDecorator, getFieldValue } = form;

    const renderRegionsList = () => {
        return regions ? regions.map((region, index) => <SelectOption key={index} value={JSON.stringify(region)}>{region.name}</SelectOption>) : [];
    }

    const handleStatusChange = (event) => {
        if (record?.active && !event.target.checked) {
            checkDataCenterDependencies(record?.id, false)
                .then(dependencyList => renderinactiveStatusConfirmation(dependencyList))
                .catch((err) => pushMessage(CONFIG.messageType.error, err.message))
        }
    }

    const renderinactiveStatusConfirmation = (dependencyList) => {
        const { maintenanceCount } = dependencyList;
        const okButtonText = "Dependency Affected";
        const content = maintenanceCount !== 0 ? (
            <React.Fragment>
                <h4>Inactivation of selected data center would affect</h4>
                {maintenanceCount} * Future Scheduled Maintenances <br />
            </React.Fragment>) : null
        maintenanceCount !== 0 && openInfo(okButtonText, content, () => Modal.destroyAll(), "OK", true);
    }

    return (
        <div>
            <NeoForm>
                <Form style={{ display: "flex", gap: "16px", flexDirection: "column" }}>
                    <Form.Item>
                        {
                            getFieldDecorator("title", {
                                rules: [
                                    {
                                        required: true,
                                        message: `Please Input Title!`
                                    }
                                ],
                                initialValue: readOnly ? undefined : record?.title
                            })(<TextInput label="Title" required />)
                        }
                    </Form.Item>
                    <Form.Item>
                        {
                            getFieldDecorator("regions", {
                                rules: [
                                    {
                                        required: true,
                                        message: `Please Input Regions!`
                                    }
                                ],
                                initialValue: readOnly ? undefined : record?.regions?.map((data) => JSON.stringify({ id: data.id, name: data.name }))
                            })(<Select multiple searchable label="Regions" required >
                                {renderRegionsList()}
                            </Select>)
                        }
                    </Form.Item>
                    <Form.Item colon={false} label={<span style={{ fontSize: "12px", color: "#323232" }}>Status</span>}>
                        {
                            getFieldDecorator("active", {
                                initialValue: readOnly ? undefined : record?.active,
                                valuePropName: "checked",
                            })(<Switch onChange={handleStatusChange} className="status-switch-neo" >{getFieldValue("active") ? "Active" : "Inactive"}</Switch>)
                        }
                    </Form.Item>
                </Form>
            </NeoForm>
        </div>
    )
}

export default DataCenterDetails