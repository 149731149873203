import React from 'react';
import { Table, Divider, Tooltip, message } from "antd";
import CONFIG from '../../config';
import StatusIcon from "../common/StatusIcon";
import moment from 'moment-timezone'
import pushMessage from '../common/PushMessage';
import { connect, batch } from 'react-redux';
import {
    toggleIsFetchingReportFlag,
    toggleBackButton,
    selectTitle,
    fetchGeneralReports,
    updateReportsCurrentPage,
    handleOnClickAction,
    exportCommonStandardReport,
} from '../../actions';
class ManageGeneralReports extends React.Component {
    filterSort = {}

    componentDidMount() {
        batch(() => {
            this.props.selectTitle('Manage Standard Reports');
            this.props.toggleBackButton(false);
            this.props.toggleIsFetchingReportFlag(true);
            this.props.fetchGeneralReports(this.filterSort);
        })
    }

    handleChange = (pagination, _, sorter) => {
        this.filterSort = {
            ...this.filterSort,
            sort: sorter.columnKey
                ? {
                    [sorter.columnKey]: CONFIG.sortMap[sorter.order]
                }
                : undefined
        }
        this.props.updateReportsCurrentPage({ filterSort: this.filterSort, isGeneralReport: true })
    }

    handleExport = async (record) => {
        const reportName = record?.name + ".xlsx";
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        this.props.exportCommonStandardReport({ name: reportName, filterSort: JSON.parse(record?.filterSortObject), isRunByUser: false, reportId: record?.id, reportKey: record?.reportKey })
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    getTimeLine = (startTime, endTime) => {
        let timeLine;
        const dateDiff = moment.tz(endTime, this.props.timeZoneName).diff(moment.tz(startTime, this.props.timeZoneName), 'days');
        if (dateDiff <= 7) {
            timeLine = CONFIG.trendingDemosTimeline[0]
        } else if (dateDiff > 7 && dateDiff <= 31) {
            timeLine = CONFIG.trendingDemosTimeline[1]
        } else {
            timeLine = CONFIG.trendingDemosTimeline[2]
        }
        return timeLine;
    }

    render() {
        const { generalReports, reports } = this.props;
        const { timeZoneName } = this.props;

        const columns = [
            {
                title: 'Title',
                dataIndex: 'name',
                key: 'name',
                align: 'left',
                sorter: true,
                width: 250,
            },
            {
                title: 'Created By',
                dataIndex: 'createdBy',
                key: 'createdBy.userName',
                align: 'left',
                sorter: true,
                render: createdBy => createdBy?.firstName + " " + createdBy?.lastName
            },
            {
                title: 'Created Time',
                dataIndex: 'createdTime',
                key: 'createdTime',
                align: 'center',
                sorter: true,
                render: (publishTime) => moment.tz(publishTime, timeZoneName).format(CONFIG.dateFormats.userDateTime)
            },
            {
                title: 'Updated By',
                dataIndex: 'updatedBy',
                key: 'updatedBy.userName',
                align: 'left',
                sorter: true,
                render: updatedBy => updatedBy !== null ? updatedBy?.firstName + " " + updatedBy?.lastName : "-"
            },
            {
                title: 'Updated Time',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                align: 'center',
                sorter: true,
                render: (updatedTime) => updatedTime !== null ? moment.tz(updatedTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"
            },

            {
                title: 'Last Run By',
                dataIndex: 'lastRunBy',
                key: 'lastRunBy.fullName',
                align: 'left',
                sorter: true,
                render: lastRunBy => lastRunBy !== null ? lastRunBy?.firstName + " " + lastRunBy?.lastName : "-"
            },
            {
                title: 'Last Run Time',
                dataIndex: 'lastRunTime',
                key: 'lastRunTime',
                align: 'center',
                sorter: true,
                render: (lastRunTime) => lastRunTime !== null ? moment.tz(lastRunTime, timeZoneName).format(CONFIG.dateFormats.userDateTime) : "-"
            },
            {
                title: 'Status',
                dataIndex: 'isActive',
                key: 'isActive',
                align: 'center',
                sorter: true,
                render: status => <StatusIcon status={status} />
            },
            {
                title: 'Actions',
                key: 'actions',
                align: 'center',
                render: (record) => {
                    const { userProfile } = this.props;
                    const { roleTemplate } = userProfile;
                    const isEditDisabled = roleTemplate && roleTemplate.templateType !== CONFIG.roleTypes.superAdmin && record.adminAccess === CONFIG.roleTemplateAccess.readOnly;
                    return (
                        <React.Fragment>
                            {
                                <>
                                    <Tooltip title={isEditDisabled ? CONFIG.warningMessages.noAccess : undefined} key={record.id}>
                                        <button
                                            onClick={() => {
                                                if (record?.reportKey === CONFIG.standardReportKeys.demoUsage || record?.reportKey === CONFIG.standardReportKeys.demoUsageSolutionDemo || record?.reportKey === CONFIG.standardReportKeys.demoScheduleTrend || record?.reportKey === CONFIG.standardReportKeys.userDistribution) {
                                                    this.props.handleOnClickAction({
                                                        component: CONFIG.editComponentRoute.neoReports,
                                                        tabTitle: record?.name,
                                                        recordId: record?.id,
                                                        recordName: "standard"
                                                    })
                                                }
                                                else {
                                                    this.props.handleOnClickAction({
                                                        component: CONFIG.editComponentRoute.reports,
                                                        tabTitle: record?.name,
                                                        recordId: record?.id,
                                                        recordName: "standard"
                                                    })
                                                }
                                            }}
                                            disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"}><span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span></button>
                                    </Tooltip >

                                    <Divider type="vertical" />
                                    <button disabled={isEditDisabled} className={!isEditDisabled ? "link" : "link-disabled"} onClick={() => this.handleExport(record)}><span class="neo-icon-export-logs" title="Export" style={{ fontSize: "20px" }}></span></button>
                                </>
                            }
                        </React.Fragment>
                    )
                }
            },
        ];


        return (
            <div style={{ padding: "10px 10px 20px 10px" }}>
                <div style={{ color: "#242424" }} class="large-text">List of all Standard Reports</div>
                <br />
                <Table
                    size='middle'
                    className="responsive-container"
                    rowKey={(record) => record.id}
                    bordered
                    dataSource={generalReports.data}
                    scroll={{ x: 1700 }}
                    columns={columns}
                    pagination={false}
                    loading={reports.isFetching}
                    onChange={this.handleChange}
                />
            </div>
        )
    }

}
const mapStateToProps = ({ user, generalReports, reports }) => {

    return {
        userProfile: user.profile,
        generalReports: generalReports,
        timeZoneName: user.profile.timezone,
        reports: reports,
    };
}


export default connect(
    mapStateToProps,
    {
        toggleIsFetchingReportFlag,
        selectTitle,
        toggleBackButton,
        fetchGeneralReports,
        updateReportsCurrentPage,
        handleOnClickAction,
        exportCommonStandardReport
    }
)(ManageGeneralReports)