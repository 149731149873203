import React from 'react'
import { Drawer } from "antd5";
import { Button } from "neo-latest"

function EditDrawer(props) {
    const { visible, showEdit, width, isEditDisabled, style, okButtonText, cancelButtonText, handleEditClick, handleCloseFilterDrawer, placement, drawerContainer, readOnly, title, children, handleSave, record } = props;
    return visible && (
        <Drawer
            className="filter-drawer"
            closeIcon={null}
            open={visible}
            width={width}
            onClose={handleCloseFilterDrawer}
            placement={placement}
            getContainer={drawerContainer}
            style={style}
            title={<span style={{ color: "#242424", fontSize: "19px", fontWeight: "400" }}>{title}</span>}

            extra={
                <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }} className='float-right'>
                    {showEdit && <Button disabled={isEditDisabled} onClick={() => { readOnly ? handleEditClick() : handleCloseFilterDrawer() }} variant="secondary" className="left-spacing-3 avaya-default-button" >
                        {cancelButtonText ? cancelButtonText : readOnly ? "Edit" : "Discard Updates"}
                    </Button>}
                    <Button onClick={() => { readOnly ? handleCloseFilterDrawer() : handleSave(record) }} status='default' className="left-spacing-3 avaya-default-button" >
                        {okButtonText ? okButtonText : readOnly ? "Close" : "Save"}
                    </Button>

                </div>
            }
            maskClosable={false}
        >
            {children}
        </Drawer>
    )
}

export default EditDrawer