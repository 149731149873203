import React from 'react';
import { connect, batch } from 'react-redux';
import { Row, Col, Form, Divider, Popconfirm, Button, Icon, Spin, Modal, Alert, Tooltip, Dropdown } from 'antd';
import StatusIcon from '../common/StatusIcon';
import { Icon as NeoIcon } from "@avaya/neo-react";
import { exportMenu } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";

import {
  toggleBackButton,
  selectTitle,
  fetchDIDs,
  updateSelectedDidsDataCenter,
  updateDidsCurrentPage,
  updateDidEditingKey,
  fetchNetworkEnvironments,
  clearDids,
  exportDIDs,
  updateDIDUploadCriteria,
  importDIDs,
  updateDIDsSelectedRowKeys,
  deleteDIDs,
  restoreDIDs,
  toggleIsFetchingDids,
  toggleIsUploadingOrDownloading,
  clearDidsTableData,
  postNewDid,
  addDID,
  toggleSaveReportVisible,
  removeEmptyDids,
  currentDidsFilter
} from '../../actions';
import DIDTableContent from './DIDTableContent';
import CONFIG from '../../config';
import pushMessage from '../common/PushMessage';
import DataCenters from '../common/DataCenters';
import openConfirm from '../common/Confirm';
import FilterPanel from '../common/FilterPanel';
import BulkUpload from "./BulkUpload";
import Providers from "./Providers"
import SaveReport from "../common/SaveReport";
export const ManageDIDsContext = React.createContext();


const dataIndexMap = {
  did: 'did',
  usage: 'usage',
  lab: 'lab',
  assigned: 'assigned',
  provider: 'provider.title',
  viewDeletedItems: 'viewDeletedItems'
};

class ManageDIDs extends React.Component {

  state = {
    file: undefined,
    didsData: [],
    loading: false,
    objectDidsCount: undefined,
    newDidId: undefined,
    isCreateFormOpen: false,
    flag: 0,
    showFilter: false
  };

  currentPage = 1

  filterSort = {}
  filterSave = {};

  tableRef = React.createRef();


  fetchDidOnProviderChange = () => {
    this.clearCurrentDids();
    this.handleFetchDids();
  }

  clearCurrentDids = async () => {
    this.currentPage = 1
    this.setState({ didsData: [], objectDidsCount: undefined })
  }

  handleUploadClear = () => {
    this.setState({
      file: undefined,
    });
  };

  handleFilter = async (filters, isSave) => {

    let { did, usage, lab, assigned, provider, viewDeletedItems } = filters;
    const isLabEmpty = (!lab || (lab && lab.length === 0));
    const isProviderEmpty = (!provider || (provider && provider.length === 0));
    const isAssignedEmpty = !assigned || (assigned && assigned.length === 0);
    did = did?.trim();
    usage = usage?.trim();
    const isFilter = isSave ? "save" : "filter";

    if (this.state.isCreateFormOpen) {
      pushMessage(CONFIG.messageType.warning, `Please close the 'Create New' form to ${isFilter} the records`)
      return
    }
    if (!did && !usage && isLabEmpty && isAssignedEmpty && isProviderEmpty && !viewDeletedItems) {
      pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
      return;
    }
    if (isSave) {
      this.filterSave = {
        ...this.filterSave,
        filter: {
          ...(did && {
            [dataIndexMap.did]: {
              operator: "=",
              oprVal: did || undefined
            }
          }),
          ...(usage && {
            [dataIndexMap.usage]: {
              operator: "=",
              oprVal: usage || undefined
            }
          }),
          ...(!isLabEmpty && {
            [dataIndexMap.lab]: {
              operator: "=",
              oprVal: !isLabEmpty ? lab.join(CONFIG.delimiters.saveFilter) : undefined
            }
          }),
          ...(assigned && assigned.length === 1 && {
            [dataIndexMap.assigned]: {
              operator: "=",
              oprVal: assigned && assigned.length === 1 ? JSON.parse(assigned[0]) : undefined
            }
          }),
          ...(!isProviderEmpty && {
            [dataIndexMap.provider]: {
              operator: "=",
              oprVal: !isProviderEmpty ? provider.join(CONFIG.delimiters.saveFilter) : undefined
            }
          }),
          ...(viewDeletedItems && {
            isSoftDelete: {
              operator: "=",
              oprVal: viewDeletedItems
            }
          })
        },
        filterTable: {
          id: 6,
          tableName: "DID"
        },
      }
      this.props.toggleSaveReportVisible(true)
      this.setState({ saveReport: true })
    }
    else {
      await this.clearCurrentDids()
      this.filterSort = {
        ...this.filterSort,
        filter: {
          [dataIndexMap.did]: did || undefined,
          [dataIndexMap.usage]: usage || undefined,
          [dataIndexMap.lab]: !isLabEmpty ? lab.join(CONFIG.delimiters.selectFilter) : undefined,
          [dataIndexMap.assigned]: assigned && assigned.length === 1 ? JSON.parse(assigned[0]) : undefined,
          [dataIndexMap.provider]: !isProviderEmpty ? provider.join(CONFIG.delimiters.selectFilter) : undefined,
          [dataIndexMap.viewDeletedItems]: viewDeletedItems
        }
      }
      this.handleFetchDids();
      this.tableRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  handleSaveDID = (filters) => {
    this.handleFilter(filters, true);
  }

  handleClear = async () => {
    if (this.state.isCreateFormOpen) {
      pushMessage(CONFIG.messageType.warning, `Please close the 'Create New' form to clear the filters`)
      return
    }
    await this.clearCurrentDids()
    this.filterSort = { ...this.filterSort, filter: undefined };
    this.filterSave = {};
    this.handleFetchDids();
  }

  handleAddDID = async () => {
    const didId = -1 * Date.now();
    this.setState({ newDidId: didId, isCreateFormOpen: true });
    await this.clearCurrentDids()
    this.props.updateDidEditingKey();
    this.handleFetchDids();
    this.setState({ newDidId: undefined })
  };

  handleConfirmCancel = () => {
    this.setState({ file: undefined });
    Modal.destroyAll();
  }

  handleConfirmOk = () => {
    const { selectedDataCenter } = this.props
    this.props.toggleIsUploadingOrDownloading(true);
    this.props.importDIDs(this.state.file, this.filterSort)
      .then(() => { this.props.updateDidsCurrentPage({ dataCenter: selectedDataCenter, filterSort: this.filterSort }) })
    this.setState({ file: undefined });
  }

  handleImport = () => {
    const { uploadType } = this.props;
    const warningMessage = uploadType === CONFIG.didUploadType.full ? CONFIG.warningMessages.fullImportDIDs : CONFIG.warningMessages.partialImportDIDs;
    openConfirm('Yes', this.handleConfirmOk, this.handleConfirmCancel, warningMessage)
  }

  handleExport = (fileType) => {
    this.props.toggleIsUploadingOrDownloading(true);
    this.props.exportDIDs(fileType, this.filterSort);
  }

  handleSave = (didId, form) => {
    form.validateFields(async (error, values) => {
      if (error) {
        return;
      }
      const did = {
        did: values.didId,
        assigned: values.assigned ? 1 : 0,
        usage: values.usage,
        lab: values.lab,
        provider: values.provider && values.provider !== "" ? JSON.parse(values.provider) : null
      };
      const { selectedDataCenter, currentPage } = this.props;
      const updatedDID = { ...did };

      const response = await this.props.postNewDid(updatedDID, didId, selectedDataCenter, currentPage, this.filterSort)

      if (response === CONFIG.HTTP_STATUS.CREATED) {
        if (didId < 0) {
          await this.clearCurrentDids();
          this.handleFetchDids();
          this.props.removeEmptyDids()
        }
      }
      else if (response === CONFIG.HTTP_STATUS.OK)
        this.modifyDIDRecord(did, didId)
      else {
        await this.clearCurrentDids();
        this.handleFetchDids();
        this.props.removeEmptyDids()
      }
    });
    this.setState({ isCreateFormOpen: false })
  };

  modifyDIDRecord = (didDetails, didId) => {
    let mappedItems = this.state.didsData.map(item => item.id === didId ? { ...didDetails, id: didId } : item);
    this.setState({
      didsData: mappedItems
    })
  }

  handleCancel = (record) => {
    this.props.removeEmptyDids(record);
    this.setState(({ didsData }) => ({
      didsData: didsData.filter((item) => item?.id > 0),
      objectDidsCount: this.props.count - 1,
      isCreateFormOpen: false,
    }))
  };

  handleRadioChange = (event) => this.props.updateDIDUploadCriteria(event.target.value);

  renderWarningMessage = (hasViewAccess) => {
    return (
      <div>
        <Alert
          message={hasViewAccess ? CONFIG.warningMessages.readOnlyEnvironmentAccess : CONFIG.warningMessages.noEnvironmentAccess}
          type="warning"
          showIcon
        />
        <br />
      </div>
    )
  }

  isEditing = record => record.id === this.props.editingKey;

  handleDataCenterChange = async (selectedDataCenter) => {
    const dataCenter = JSON.parse(selectedDataCenter);
    batch(() => {
      this.props.clearDidsTableData();
      this.props.updateDidEditingKey();
      this.props.updateSelectedDidsDataCenter(dataCenter);
      this.props.toggleIsFetchingDids(true);
    })
    await this.clearCurrentDids();
    this.handleFetchDids(dataCenter);
  }

  handleFlagChange = () => {
    this.setState((prevState) => ({
      flag: prevState.flag + 1
    }))
  }

  handleFetchDids = (dataCenter) => {
    const countOfRecordsFetched = this.state.didsData?.length
    const totalDids = this.props.count
    if ((totalDids === undefined && countOfRecordsFetched === undefined) || !(totalDids <= countOfRecordsFetched)) {
      this.setState({ loading: true });
      this.props.fetchDIDs({ pageNumber: this.currentPage++, filterSort: this.filterSort, dataCenter: this.props.selectedDataCenter || dataCenter, didId: this.state.newDidId })
        .then(() => {
          if (this.props.response === CONFIG.HTTP_STATUS.OK || this.props.response === CONFIG.HTTP_STATUS.NO_CONTENT) {
            this.setState(({ didsData }) => ({
              loading: false,
              didsData: this.props.dids ? didsData?.concat(this.props.dids) : [],
              objectDidsCount: this.props.count,
              flag: 0
            }))
          }
        }).then(() => {
          var scrollContainer = document.querySelector('.ant-table-body')
          scrollContainer.scrollTop = scrollContainer?.scrollHeight - scrollContainer?.clientHeight - scrollContainer?.clientHeight;
        })
    }
  }

  componentWillUnmount() {
    if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
      this.props.currentDidsFilter(this.state.status)
    }
    else {
      this.props.clearDids();
    }
  }

  async componentDidMount() {
    const { selectedDataCenter, isEdit, currentPageNumber, currentStatus, currentFilter } = this.props;
    this.setState({ loading: true })
    this.setState({ currentPath: window.location.pathname })

    if (selectedDataCenter) {
      batch(() => {
        this.props.clearDidsTableData();
        this.props.updateDidEditingKey();
        this.props.updateSelectedDidsDataCenter(selectedDataCenter);
        this.props.toggleIsFetchingDids(true);
      })
      await this.clearCurrentDids();
      this.handleFetchDids(selectedDataCenter);
    }

    if (isEdit) {
      this.currentPage = currentPageNumber + 1;
      this.filterSort = currentFilter;
      if (this.filterSort?.filter !== undefined) this.setState({ showFilter: true })
      await this.setState({ status: currentStatus })
    }
    else {
      this.filterSort = {}
    }

    batch(() => {
      this.props.toggleBackButton(false);
      this.props.selectTitle('Manage DIDs');
      this.props.fetchNetworkEnvironments();
    });
  }

  handleSortChange = async (pagination, _, sorter) => {
    await this.clearCurrentDids()
    const { currentPage } = this.props;
    this.filterSort = {
      ...this.filterSort,
      sort: sorter.columnKey
        ? {
          [sorter.columnKey]: CONFIG.sortMap[sorter.order]
        }
        : undefined
    }
    if (currentPage !== pagination.current) this.props.updateDidsCurrentPage({ pageNumber: pagination.current, filterSort: this.filterSort, dataCenter: this.props.selectedDataCenter })
    this.handleFetchDids()
  }

  handleDeleteConfirmation = async () => {
    const hardDelete = this.filterSort?.filter?.viewDeletedItems;
    const response = await this.props.deleteDIDs(hardDelete);
    if (response[0]) {
      this.handleFetchDidsAfterDeleteOrRestore(response)
    }
  }

  handleFetchDidsAfterDeleteOrRestore = async (response) => {
    let didContents = this.state.didsData;
    didContents = didContents.filter((item) => !response[1]?.includes(item.id));
    if (didContents?.length === 0) {
      this.clearCurrentDids();
      this.handleFetchDids();
    }
    else {
      await this.setState(({ objectDidsCount }) => ({
        didsData: didContents,
        objectDidsCount: objectDidsCount - response[1]?.length
      }))
    }
  }

  handleDelete = () => {
    const hardDelete = this.filterSort?.filter?.viewDeletedItems;
    if (this.props.userProfile?.isDeveloper && hardDelete) pushMessage(CONFIG.messageType.warning, CONFIG.warningMessages.forbidden)
    else {
      const okButtonText = hardDelete ? "Confirm Permanent Deletion" : "Confirm Deletion"
      const content = hardDelete ? <h4>{CONFIG.warningMessages.hardDelete.replace("<placeHolder>", "DID(s)")}</h4> : <h4>{CONFIG.warningMessages.softDelete.replace("<placeHolder>", "DID(s)")}</h4>
      const modalTitle = CONFIG.deleteModalTitle?.replace('<placeholder>', 'DID(s)')
      openConfirm(okButtonText, this.handleDeleteConfirmation, null, content, modalTitle);
    }
  }

  handleRestore = async () => {
    const response = await this.props.restoreDIDs();
    if (response[0]) {
      this.handleFetchDidsAfterDeleteOrRestore(response)
    }
  }

  render() {
    const { selectedDataCenter, networkEnvironments, selectedRowKeys, isUploadingOrDownloading, templateType, didsAccess, providers, editingKey } = this.props;
    const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
    const hasViewAccess = isSuperAdmin || didsAccess === CONFIG.roleTemplateAccess.readOnly;
    const hasEditAccess = isSuperAdmin || didsAccess === CONFIG.roleTemplateAccess.fullAccess;
    const deleteView = this.filterSort?.filter?.viewDeletedItems;
    const isDisabled = !hasEditAccess ? true : editingKey !== "";
    const tableColumns = [
      {
        title: 'No.',
        key: 'index',
        align: 'center',
        width: 80,
        render: (text, record, index) => index + 1,
      },
      {
        title: <span className={this.filterSort.filter?.[dataIndexMap.did] ? "filtered-column" : ''}>DID</span>,
        dataIndex: "did",
        width: 200,
        editable: true,
        key: 'did',
        sorter: true,
      },
      {
        title: <span className={this.filterSort.filter?.[dataIndexMap.lab] ? "filtered-column" : ''}>ENV</span>,
        dataIndex: "lab",
        width: 150,
        editable: true,
        key: 'lab',
        sorter: true
      },
      {
        title: <span className={this.filterSort.filter?.[dataIndexMap.usage] ? "filtered-column" : ''}>Description</span>,
        dataIndex: "usage",
        width: 300,
        editable: true,
        key: 'usage',
        sorter: true
      },
      {
        title: <span className={this.filterSort.filter?.[dataIndexMap.provider] ? "filtered-column" : ''}>Provider</span>,
        dataIndex: "provider",
        width: 200,
        editable: true,
        key: 'provider.title',
        sorter: true,
        render: provider => {
          return (
            <span >
              {provider?.title}
              &nbsp;&nbsp;&nbsp;
              {provider !== null && !provider?.active &&
                <Tooltip title="Inactive">
                  <Icon type="warning" className="small-text warning-text" />
                </Tooltip>
              }
            </span>
          )
        },
      },
      {
        title: <span className={this.filterSort.filter?.[dataIndexMap.assigned] !== undefined ? "filtered-column" : ''}>Assigned</span>,
        dataIndex: "assigned",
        width: 120,
        editable: true,
        render: (assigned) => {
          return <StatusIcon status={assigned} />
        },
        key: 'assigned',
        sorter: true,
        align: "center",
      }
    ];

    const actionColumn = {
      title: "Actions",
      align: "center",
      width: 200,
      dataIndex: "operation",
      render: (text, record) => {
        const isSuperAdmin = templateType === CONFIG.roleTypes.superAdmin;
        const hasEditAccess = isSuperAdmin || didsAccess === CONFIG.roleTemplateAccess.fullAccess;
        const editable = this.isEditing(record);
        return editable
          ? (
            <span>
              <ManageDIDsContext.Consumer>
                {form => (
                  <span className="link" onClick={() => this.handleSave(record.id, form)}>
                    <span class="neo-icon-save" title="Save" style={{ fontSize: "20px" }}></span>
                  </span>
                )}
              </ManageDIDsContext.Consumer>
              <Divider type="vertical" />
              <Popconfirm title="Confirm Cancel?" okText="Yes" cancelText="No" onConfirm={() => this.handleCancel()}>
                <span className="link"><span class="neo-icon-close" title="Cancel" style={{ fontSize: "20px" }}></span></span>
              </Popconfirm>
            </span>
          )
          : (
            !deleteView &&
            <>
              <Tooltip
                title={
                  !hasEditAccess ? CONFIG.warningMessages.noAccess : undefined
                }
                key={record.id}
              >
                <button
                  disabled={isDisabled}
                  onClick={() => { this.props.updateDidEditingKey(record.id); this.props.updateDIDsSelectedRowKeys() }}
                  className={isDisabled ? "link-disabled" : "link"}
                >
                  <span class="neo-icon-edit" title="Edit" style={{ fontSize: "20px" }}></span>
                </button>
              </Tooltip>
            </>
          );
      }
    }

    const columns = tableColumns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          networkEnvironments,
          providers
        })
      };
    });

    const uploadProps = {
      accept: ".csv",
      multiple: false,
      fileList: this.state.file ? [this.state.file] : [],
      beforeUpload: (file) => {
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          pushMessage(CONFIG.messageType.warning, 'File must be smaller than 50 MB')
          return true;
        }
        this.setState({
          file
        });
        return false;
      },
      onRemove: () => this.setState({ file: undefined })
    };

    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys) => {
        this.props.updateDIDsSelectedRowKeys(selectedRowKeys);
      },
      getCheckboxProps: () => ({
        disabled: !hasEditAccess
      }),
    };

    const formFields = [
      {
        label: "DID",
        type: CONFIG.formFieldTypes.INPUT,
        key: "did"
      },
      {
        label: "ENV",
        type: CONFIG.formFieldTypes.SELECT,
        key: "lab",
        mode: "tags",
        valueKey: "text",
        data: (networkEnvironments) ? networkEnvironments.map((env) => ({ text: env })) : []
      },
      {
        label: "Description",
        type: CONFIG.formFieldTypes.INPUT,
        key: "usage"
      },
      {
        label: "Assigned",
        type: CONFIG.formFieldTypes.SELECT,
        key: "assigned",
        data: CONFIG.filterDataSet.didAssigned,
      },
      {
        label: "Provider",
        type: CONFIG.formFieldTypes.SELECT,
        key: "provider",
        mode: "tags",
        valueKey: "text",
        data: (providers) ? providers.map((provider) => ({ text: provider?.title })) : []
      },
    ]


    const { showFilter } = this.state;

    return (
      <React.Fragment>
        {(hasEditAccess || hasViewAccess)
          ?
          <>
            <Providers selectedDataCenter={selectedDataCenter} fetchDidOnProviderChange={this.fetchDidOnProviderChange} />
            <div className="content-container">
              <Spin spinning={isUploadingOrDownloading} wrapperClassName="spin-overlay">
                <Row className="vertical-spacing">
                  <Col xl={6} xs={24}>
                    <DataCenters fromDids={true} form={this.props.form} dataCenter={selectedDataCenter} handleChange={this.handleDataCenterChange} />
                  </Col>
                  {
                    selectedDataCenter &&
                    <>
                      <Col style={{ paddingTop: "25px" }} xl={6} xs={24}>
                        {(selectedDataCenter && hasEditAccess) &&
                          <Button style={{ marginLeft: "20px" }} className="primary-action-button-filled"
                            disabled={this.props.editingKey !== "" || deleteView}
                            onClick={this.handleAddDID}>
                            New<Icon type="plus-circle" /></Button>
                        }
                      </Col>
                      {
                        !this.state.file ?
                          <Col style={{ paddingTop: "25px" }} className="float-right" xl={1} sm={2} xs={2} >
                            <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                              <NeoIcon title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                            </Dropdown>
                          </Col>
                          : null
                      }
                      <Col className="float-right" style={{ marginRight: "20px", paddingTop: "25px" }}>
                        <NeoIcon title="Refresh" icon="refresh" onClick={() => this.handleClear(true)} style={{ fontSize: "23px", color: '#0b67bd' }} />
                      </Col>
                      <Col className="float-right" style={{ marginRight: "20px", paddingTop: "25px" }}>
                        <NeoIcon className="clickable" onClick={() => this.setState(prevState => ({
                          showFilter: !prevState.showFilter
                        }))} title="Filter" icon={showFilter ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                      </Col>
                    </>
                  }
                </Row>

                {
                  selectedDataCenter &&
                  <React.Fragment>
                    <Row>
                      <BulkUpload
                        panelHeader="Bulk Upload"
                        handleImport={this.handleImport}
                        uploadProps={uploadProps}
                        isfileUploaded={this.state.file}
                        handleClear={this.handleUploadClear}
                        handleRadioChange={this.handleRadioChange}
                      />
                    </Row>
                    <Row>
                      {this.state.showFilter &&
                        <FilterPanel
                          label='View Deleted DIDs'
                          panelHeader="Filters"
                          formFields={formFields}
                          handleClear={this.handleClear}
                          handleFilter={this.handleFilter}
                          handleSave={this.handleSaveDID}
                        />}
                    </Row>

                    <Row>
                      <Col xxl={18} xl={18} lg={24} s={18} xs={1} className="right-align">
                        {hasEditAccess && (
                          <div className="dids-pop-confirm">
                            <>
                              {deleteView && <>
                                <Divider type="vertical" />
                                <Popconfirm disabled={selectedRowKeys.length === 0} title="Confirm restore?" onConfirm={this.handleRestore}>
                                  <Button disabled={selectedRowKeys.length === 0} className={selectedRowKeys.length !== 0 && "primary-action-button-bordered left-spacing"}>Restore</Button>
                                </Popconfirm>
                              </>
                              }

                            </>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <div ref={this.tableRef}>
                      <ManageDIDsContext.Provider value={this.props.form}>
                        <Row className='vertical-spacing'>
                          <Col xl={6} xs={24}>
                            <h3 className="primary-text-color"> {deleteView ? 'List of Deleted DIDs' : 'List of all DIDs'}</h3>
                          </Col>
                          <Col >
                            <Button className="cancel-button bottom-spacing" style={{ float: 'right' }} disabled={selectedRowKeys.length === 0} onClick={this.handleDelete}>Delete</Button>
                          </Col>
                        </Row>

                        {selectedRowKeys.length > 0 && <div>Selected {selectedRowKeys.length} records</div>}
                        <DIDTableContent
                          dataSource={this.state.didsData}
                          columns={!deleteView ? [...columns, actionColumn] : columns}
                          loading={this.state.loading}
                          handleChange={this.handleSortChange}
                          rowSelection={rowSelection}
                          didCount={this.state.objectDidsCount}
                          flag={this.state.flag}
                          handleFlagChange={this.handleFlagChange}
                          handleFetchDids={this.handleFetchDids} />
                      </ManageDIDsContext.Provider>
                    </div>
                    {this.state.saveReport && <SaveReport closeModal={() => this.setState({ saveReport: false })} reportFilter={this.filterSave} />}
                  </React.Fragment>
                }
              </Spin>
            </div>
          </>
          :
          this.renderWarningMessage()
        }
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ dids, viewport, user, providers, tabsLayout }) => {
  return {
    selectedDataCenter: dids.dataCenter,
    dids: dids.data,
    count: dids.count,
    currentPage: dids.currentPage,
    isFetching: dids.isFetching,
    editingKey: dids.editingKey,
    networkEnvironments: dids.networkEnvironments,
    selectedRowKeys: dids.selectedRowKeys,
    isUploadingOrDownloading: dids.isUploadingOrDownloading,
    uploadType: dids.uploadType,
    templateType: user.profile.roleTemplate.templateType,
    didsAccess: user.profile.didsAccess,
    userProfile: user.profile,
    viewport,
    providers: providers.data,
    response: dids.response,
    openedTabs: tabsLayout.panes
  }
}

const EditableFormTable = Form.create()(ManageDIDs);

export default connect(
  mapStateToProps,
  {
    toggleBackButton,
    selectTitle,
    fetchDIDs,
    updateSelectedDidsDataCenter,
    updateDidsCurrentPage,
    updateDidEditingKey,
    fetchNetworkEnvironments,
    clearDids,
    exportDIDs,
    updateDIDUploadCriteria,
    importDIDs,
    updateDIDsSelectedRowKeys,
    deleteDIDs,
    restoreDIDs,
    toggleIsFetchingDids,
    toggleIsUploadingOrDownloading,
    clearDidsTableData,
    addDID,
    removeEmptyDids,
    postNewDid,
    toggleSaveReportVisible,
    currentDidsFilter
  }
)(EditableFormTable);
