import moment from 'moment-timezone';
import CONFIG from '../config';

export const getUpdatedStatusDescription = (status, desc, timeZone) => {
    if (status !== CONFIG.demoStatus.default) { // If status is not changing to green
        const time = moment.tz(moment(), timeZone).toISOString();		// Get current UTC time
        const addOnDesc = CONFIG.demoStatus.descText.replace("<timestamp>", time);	// Generate "Updated: <Current UTC Time>"
        desc = CONFIG.regEx.getTime.test(desc)	// If description already contains timestamp
            ? desc.replace(CONFIG.regEx.getTime.exec(desc)[1], time) // Replace the time with current time
            : `${addOnDesc}${desc}`; // Else add the timestamp
    }
    else if (status === CONFIG.demoStatus.default) {	// If status is changing to green and contains timestamp
        desc = ""; // Remove the time stamp and description
    }
    return desc;
}

export const getDisplayTime = (desc, timeZone) => {
    if (CONFIG.regEx.getTime.test(desc)) {	// If status description contains timestamp
        const utcTime = CONFIG.regEx.getTime.exec(desc)[1];	// Get the timestamp
        const convertedTime = moment.tz(utcTime, timeZone).format("YYYY-MM-DD");	// Convert it to timezone time
        desc = desc.replace(CONFIG.regEx.getTime.exec(desc)[1], convertedTime);	// Replace timestamp with converted time
    }
    return desc;
}

export const getColorsCount = (statusList) => {
    let r = 0, y = 0, g = 0;
    statusList.forEach(status => {
        if (status === CONFIG.demoStatus.types.indexOf('Red')) r++
        else if (status === CONFIG.demoStatus.types.indexOf('Yellow')) y++
        else g++;
    });
    return { r, y, g };
}

export const checkRoleTemplateChanges = (oldData, newData, isSuperAdminUser) => {
    let hasChanged = false;
    let newRTIds = []
    let newRTs = newData?.length > 0 ? newData?.filter(rT => ((rT.adminAccess && rT.adminAccess !== 0) || (rT.userAccess && rT.userAccess !== 0))) : [];
    if (newRTs?.length > 0) newRTs.forEach(item => newRTIds.push(item.id))
    const oldRTs = oldData?.length > 0 ? oldData?.filter(rT => ((rT.adminAccess && rT.adminAccess !== 0) || (rT.userAccess && rT.userAccess !== 0))) : [];
    // let oldRTsFilter = isSuperAdminUser ? oldRTs : oldRTs?.length > 0 ? oldRTs.filter(item => newRTIds.includes(item.id)) : [];

    hasChanged = JSON.stringify(newRTs) !== JSON.stringify(oldRTs);
    return hasChanged;
}

export const checkDemoHasChanged = (oldDemo, newDemo) => {
    const newDataIds = []
    const oldDataIds = []
    let hasChanged = false;
    if (newDemo?.length > 0) newDemo.forEach(item => newDataIds.push(item?.id));
    if (oldDemo?.length > 0) oldDemo.forEach(item => oldDataIds.push(item?.id));

    if (newDataIds?.length !== oldDataIds?.length) return true;
    //if demo's are reordered
    if (!(JSON.stringify(newDataIds)?.trim() === JSON.stringify(oldDataIds)?.trim())) return true;

    const data = newDataIds?.filter(item => !oldDataIds?.includes(item))
    if (data?.length !== 0) return true;

    // const data = newDataIds?.filter(item => !oldDataIds?.includes(item))
    return hasChanged
}