import {
    UPDATE_DASHBOARD_SCHEDULES_VIEW,
    TOGGLE_IS_FETCHING_USER_SCHEDULES,
    TOGGLE_IS_ACTIVE_SCHEDULES,
    TOGGLE_IS_DASHBOARD_LOADING,
    CLEAR_PREVIOUS_SCHEDULES,
    TOGGLE_IS_RESCHEDULE,
    TOGGLE_IS_RESOURCE_RESCHEDULE,
    TOGGLE_IS_FETCHING_PREVIOUS_SCHEDULES,
    UPDATE_SCHEDULES_CURRENT_PAGE,
    FETCH_SCHEDULES_PRINT_EXPORT,
    CLEAR_DASHBOARD_SCHEDULES,
    FETCH_ACTIVE_USER_SCHEDULES_CARD,
    FETCH_PREVIOUS_USER_SCHEDULES_CARD,
    FETCH_USER_SCHEDULES_CALENDAR,
    FETCH_USER_DEMO_SCHEDULES_TABLE,
    FETCH_USER_MAINTENANCE_SCHEDULES_TABLE,
    UPDATE_USERS_SCHEDULES_PARAMS,
    TOGGLE_IS_TABLE_CLOSE,
    TOGGLE_IS_FETCHING_DASHBOARD_SCHEDULES,
    GET_ALL_ACTIVE_REQUESTS,
    GET_ALL_INACTIVE_REQUESTS,
    DASHBOARD_ACTIVE_SCHEDULES,
    DASHBOARD_PREVIOUS_SCHEDULES,
    DASHBOARD_POPULAR_EZ_DEMOS,
    CLEAR_DASHBOARD_EZ_DEMOS
} from '../actions/types';
import CONFIG from '../config';

const dashboardState = {
    schedulesView: CONFIG.dashboard.cardView,
    isFetchingSchedules: false,
    isFetchingPreviousSchedules: false,
    isActiveSchedules: true,
    fetchingDashboardSchedules: true,
    previousSchedulesCard: [],
    userSchedulesCalendar: undefined,
    schedulesPrintExport: undefined,
    isReschedule: false,
    isResourceReschedule: false,
    currentPage: 1,
    previousSchedulesCardCount: 0,
    maintSchedulesTableCount: 0,
    demoSchedulesTableCount: 0,
    inactiveRequests: [],
    inactiveRequestsCount: 0,
    dashboardSchedules: [],
    dashboardPreviousSchedules: [],
    dashboardActiveScheduleCount: 0,
    isDashboardAPIsFetching: false,
    popularEzDemos: []
}
export const dashboardReducer = (state = dashboardState, action) => {
    switch (action.type) {
        case DASHBOARD_ACTIVE_SCHEDULES:
            return {
                ...state,
                dashboardUpcomingSchedules: action?.payload?.upcomingSchedules || [],
                dashboardTodaySchedules: action?.payload?.todaySchedules || [],
                dashboardActiveScheduleCount: (action?.payload?.todaySchedules?.length || 0) + (action?.payload?.upcomingSchedules?.length || 0)
            }

        case DASHBOARD_PREVIOUS_SCHEDULES:
            return {
                ...state,
                dashboardPreviousSchedules: action?.payload || []
            }
        case UPDATE_DASHBOARD_SCHEDULES_VIEW:
            return { ...state, schedulesView: action.payload };
        case FETCH_ACTIVE_USER_SCHEDULES_CARD:
            return { ...state, activeUserSchedulesCard: action.payload.dataSet, activeScheduleCount: action.payload.count };
        case FETCH_PREVIOUS_USER_SCHEDULES_CARD:
            return { ...state, previousSchedulesCard: [...state.previousSchedulesCard, ...action.payload.dataSet], previousSchedulesCardCount: action.payload.count };
        case FETCH_USER_SCHEDULES_CALENDAR:
            return { ...state, userSchedulesCalendar: action.payload.dataSet };
        case FETCH_USER_DEMO_SCHEDULES_TABLE:
            return { ...state, demoSchedulesTable: action.payload.dataSet, demoSchedulesTableCount: action.payload.count };
        case FETCH_USER_MAINTENANCE_SCHEDULES_TABLE:
            return { ...state, maintSchedulesTable: action.payload.dataSet, maintSchedulesTableCount: action.payload.count };
        case FETCH_SCHEDULES_PRINT_EXPORT:
            return { ...state, schedulesPrintExport: action.payload.dataSet };
        case TOGGLE_IS_FETCHING_USER_SCHEDULES:
            return { ...state, [action.payload.type]: action.payload.flag };
        case TOGGLE_IS_ACTIVE_SCHEDULES:
            return { ...state, isActiveSchedules: action.payload };
        case TOGGLE_IS_DASHBOARD_LOADING:
            return { ...state, isDashboardAPIsFetching: action.payload };
        case TOGGLE_IS_FETCHING_DASHBOARD_SCHEDULES:
            return { ...state, fetchingDashboardSchedules: action.payload };
        case TOGGLE_IS_RESCHEDULE:
            return { ...state, isReschedule: action.payload };
        case TOGGLE_IS_FETCHING_PREVIOUS_SCHEDULES:
            return { ...state, isFetchingPreviousSchedules: action.payload };
        case CLEAR_PREVIOUS_SCHEDULES:
            return { ...state, previousSchedules: [], previousSchedulesCard: [], inactiveRequests: [] };
        case CLEAR_DASHBOARD_SCHEDULES:
            return { ...state, dashboardUpcomingSchedules: [], dashboardTodaySchedules: [] };
        case UPDATE_SCHEDULES_CURRENT_PAGE:
            return { ...state, currentPage: [] };
        case UPDATE_USERS_SCHEDULES_PARAMS:
            return { ...state, fetchUsersSchedulesParams: action.payload };
        case TOGGLE_IS_TABLE_CLOSE:
            return { ...state, userSchedulesCalendar: undefined, schedulesPrintExport: undefined }
        case TOGGLE_IS_RESOURCE_RESCHEDULE:
            return { ...state, isResourceReschedule: action.payload }
        case GET_ALL_ACTIVE_REQUESTS:
            return { ...state, activeRequests: action.payload ? action.payload.dataSet : [] }
        case GET_ALL_INACTIVE_REQUESTS:
            return { ...state, inactiveRequests: action.payload?.dataSet?.length > 0 ? [...state.inactiveRequests, ...action.payload.dataSet] : [...state.inactiveRequests], inactiveRequestsCount: action.payload.count }
        case DASHBOARD_POPULAR_EZ_DEMOS:
            return { ...state, popularEzDemos: action.payload || [] }
        case CLEAR_DASHBOARD_EZ_DEMOS:
            return { ...state, popularEzDemos: [] }
        default:
            return state;
    }
}


