import {
    FETCH_NETWORKS,
    FETCH_IP_ADDRESSES,
    TOGGLE_IS_FETCHING_IP_ADDRESSES,
    TOGGLE_IS_FETCHING_NETWORKS,
    TOGGLE_IS_IP_ADDRESS_FORM_VISIBLE,
    UPDATE_IP_ADDRESS_FORM,
    FETCH_NETWORK_DETAILS,
    UPDATE_NETWORK_DETAILS,
    CLEAR_NETWORKS,
    UPDATE_NETWORK_ROLE_TEMPLATE,
    CLEAR_NETWORK_DATA,
    SAVE_NETWORKS_ROLE_TEMPLATES,
    CANCEL_NETWORKS_ROLE_TEMPLATES,
    FETCH_DELETED_NETWORKS,
    UPDATE_NETWORK_LOGIN_PASSWORD,
    UPDATE_NETWORK_CURRENT_PAGE,
    TOGGLE_IS_IP_ADDRESS_UPDATED,
    FETCH_DELETED_NETWORKS_BY_DC_ID,
    TOGGLE_IS_FETCHING_DELETED_NETWORK,
    TOGGLE_IS_COLLAPSED_STATUS,
    CURRENT_NETWORKS_FILTER,
    UPDATE_SELECTED_NETWORKS_DATACENTER,
    CREATE_NETWORK_DATA
} from '../actions/types';
import CONFIG from '../config';

const networksState = {
    current: undefined,
    isFetchingIPAddresses: false,
    isFetchingDeletedNetworks: false,
    isIpAddressUpdated: false,
    isFetchingNetworks: false,
    allRTAdminAccess: 0,
    currentSelectedDataCenter: undefined,
    currentSelectedNetwork: undefined
};

export const networksReducer = (state = networksState, action) => {
    switch (action.type) {

        case FETCH_NETWORKS:
            return { ...state, data: action.payload, current: undefined, ipAddresses: undefined, selectedNetwork: undefined };
        case FETCH_DELETED_NETWORKS:
            return { ...state, deletedNetworks: action.payload };
        case FETCH_DELETED_NETWORKS_BY_DC_ID:
            return { ...state, deletedNetworksById: action.payload };
        case FETCH_IP_ADDRESSES:
            return { ...state, ipAddresses: action.payload.updatedIPAddresses, ipAddressCount: action.payload.ipAddressCount };
        case UPDATE_NETWORK_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, ipAddressId: action.payload[2] || undefined, ipAddressIndex: action.payload[3] || undefined };
        case TOGGLE_IS_FETCHING_IP_ADDRESSES:
            return { ...state, isFetchingIPAddresses: action.payload, ipAddresses: action.payload ? undefined : state.ipAddresses };
        case TOGGLE_IS_FETCHING_NETWORKS:
            return { ...state, isFetchingNetworks: action.payload }
        case TOGGLE_IS_FETCHING_DELETED_NETWORK:
            return { ...state, isFetchingDeletedNetworks: action.payload }
        case TOGGLE_IS_COLLAPSED_STATUS:
            return {
                ...state,
                isCollapsed: action.payload
            };
        case CURRENT_NETWORKS_FILTER:
            return {
                ...state,
                currentSelectedDataCenter: action.payload?.selectedDataCenter,
                currentSelectedNetwork: action.payload?.selectedNetwork,
                isEdit: true
            };
        case UPDATE_SELECTED_NETWORKS_DATACENTER:
            return { ...state, dataCenter: action.payload };
        case TOGGLE_IS_IP_ADDRESS_UPDATED:
            return { ...state, isIpAddressUpdated: action.payload[0], operation: action.payload[1] }
        case FETCH_NETWORK_DETAILS:
            return { ...state, current: { ...action.payload.networkDetails, assignedRoleTemplates: action.payload.networkDetails?.roleTemplates || [] }, selectedNetwork: action.payload.selectedNetwork };
        case UPDATE_NETWORK_DETAILS:
            return {
                ...state,
                current: { ...state.current, description: action.payload.description, gateway: action.payload.gateway },
                data: state.data.map(network => {
                    return network.id === action.payload.id
                        ? { ...network, description: action.payload.description, gateway: action.payload.gateway }
                        : network
                }),
                selectedNetwork: { ...state.selectedNetwork, description: action.payload.description, gateway: action.payload.gateway }
            }
        case UPDATE_NETWORK_ROLE_TEMPLATE:
            return {
                ...state,
                current: state.current
                    ? {
                        ...state.current,
                        roleTemplates: action.payload.updatedRoleTemplates
                    }
                    : { roleTemplates: action.payload.updatedRoleTemplates }
                ,
                allRTAdminAccess: !action.payload.selectedRoleTemplate && action.payload.access
                    ? action.payload.accessType
                    : CONFIG.roleTemplateAccess.none,
            }
        case SAVE_NETWORKS_ROLE_TEMPLATES:
            return {
                ...state,
                current: state.current
                    ? { ...state.current, assignedRoleTemplates: state.current?.roleTemplates }
                    : { assignedRoleTemplates: state.current?.roleTemplates }
            }
        case CANCEL_NETWORKS_ROLE_TEMPLATES:
            return {
                ...state,
                allRTAdminAccess: 0,
                current: state.current
                    ? { ...state.current, roleTemplates: state?.current?.assignedRoleTemplates || [] }
                    : { roleTemplates: state?.current?.assignedRoleTemplates || [] },
            };
        case CLEAR_NETWORK_DATA:
            return {
                ...networksState,
                isCollapsed: state.isCollapsed,
                data: state.data,
                current: undefined,
                deletedNetworks: state.deletedNetworks,
                selectedDataCenter: undefined,
                currentSelectedDataCenter: undefined,
                currentSelectedNetwork: undefined,
                isEdit: false
            }
        case CREATE_NETWORK_DATA:
            return {
                ...state,
                createData: action.payload.values,
                selectedDC: action.payload.selectedDataCenter,

            }
        case CLEAR_NETWORKS:
            return networksState
        default:
            return state;
    }
};

const ipAddressFormState = {
    visible: false
}

export const ipAddressFormReducer = (state = ipAddressFormState, action) => {
    switch (action.type) {
        case TOGGLE_IS_IP_ADDRESS_FORM_VISIBLE:
            return { ...state, visible: action.payload };
        case UPDATE_IP_ADDRESS_FORM:
            return { ...ipAddressFormState, ...action.payload }
        case UPDATE_NETWORK_LOGIN_PASSWORD:
            return { ...state, networkLoginPassword: action.payload }
        default:
            return state;
    }
}