import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import cookie from 'react-cookies';
import moment from 'moment-timezone';
import history from '../history';
import NavigationMenu from './NavigationMenu';
import Header from './Header';
import TabsLayout from './TabsLayout';
import Loader from './common/Loader';
// import Home from './Home';
import { fetchUserDetails, updateViewPort, login, restoreTabsOnPageRefresh } from '../actions';
import { getUserFromCookies, removeAllCookies } from '../utils/cookies';
import CONFIG from '../config';
import { analytics } from '../firebase';
import "@avaya/neo-react/avaya-neo-react.css";
import "neo-latest/avaya-neo-react.css"
import RealTimePushNotification from '../components/RealTimePushNotification'
import { processEmailRedirectionLink } from './common/EmailRedirection'
import { encrypt } from "../utils/aesDecrypt";
import HomeNeo from './HomeNeo';
import SFDCPage from './SFDCPage';


class App extends React.Component {

    state = {
        shouldRenderApp: false,
        showContentLoader: true,
        loadingPercentage: 0
    }

    constructor(props) {
        super(props);
        window.location.pathname.includes("/redirect") && processEmailRedirectionLink();
        this.isSSOError = cookie.load('SAML_ERR_MSG') !== undefined;
        if (history.location.pathname === '/non-sso-users' || history.location.pathname === '/home/non-sso-users' || localStorage.getItem('header')) {
            history.replace('/home/non-sso-users');
        }
        else if (!this.isSSOError && !localStorage.getItem('header')) {
            this.isSSOValid = cookie.load('__s2jt') !== undefined;
            this.isSSOComplete = cookie.load('ssoComplete') === 'true';
            if (this.isSSOComplete === true) {
                const plainText = moment().utc().add(process.env.REACT_APP_LOGIN_AES_SALT, 'm').format(CONFIG.dateFormats.scheduler);
                const id = encrypt(plainText);
                const encodedUrl = encodeURIComponent(id)
                const url = `${process.env.REACT_APP_SAML_LOGIN}?id=${encodedUrl}`
                window.location.href = url;
                cookie.remove('ssoComplete')
            }
            else if (!this.isSSOValid) {
                history.replace('/home');
            }
            else {
                cookie.save('ssoComplete', true, CONFIG.cookieParams)
                this.user = getUserFromCookies();
            }
        }
        else if (!localStorage.getItem('header')) {
            removeAllCookies();
            window.location.href = process.env.REACT_APP_SAML_LOGOUT;
        }
        // cookie.remove('OptanonAlertBoxClosed');
        setTimeout(() => {
            this.setState({
                showContentLoader: false
            })
        }, 5000)
    }

    setFirebaseUserDetails = (user) => {
        analytics().setUserId(user.email)
        analytics().setUserProperties({ user });
    }


    handleUpdateViewPort = () => {
        this.props.updateViewPort({
            isMobileView: window.innerWidth <= 480,
            isTabletView: window.innerWidth > 480 && window.innerWidth <= 768,
            isDesktopView: window.innerWidth > 768
        });
    }

    logCurrentPage = () => {
        analytics().logEvent({
            app_name: "Experience Avaya Portal",
            app_version: process.env.REACT_APP_VERSION
        })
    };

    componentWillUnmount() {
        const acceptCookiesBtn = document.querySelector('.accept-cookie-container');
        this.removeHistoryListener && this.removeHistoryListener();
        window.removeEventListener('resize', this.handleUpdateViewPort);
        // window.removeEventListener('beforeunload', this.handleBeforeUnload);
        acceptCookiesBtn && acceptCookiesBtn.removeEventListener('click', this.addConsentCookies, false);
    }


    async componentDidMount() {
        window.location.pathname === '/home'
            ? localStorage.setItem('isNonSSOLogin', false)
            : localStorage.setItem('isNonSSOLogin', true)
        const aepHeader = localStorage.getItem('header');
        try {
            if (aepHeader) {
                await this.props.login(aepHeader);
                const { companyName, contactNumber, country, email, firstName, lastName, linkId } = this.props.user
                this.setFirebaseUserDetails({ companyName, contactNumber, country, email, firstName, lastName, linkId });
            }
        } catch (err) {
            localStorage.removeItem('header');
            history.replace('/home/non-sso-users');
        }

        if (this.user) {
            await this.props.fetchUserDetails(this.user)
            this.setFirebaseUserDetails(this.user);
        };
        this.handleUpdateViewPort();
        window.addEventListener('resize', this.handleUpdateViewPort, false);
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.removeHistoryListener = history.listen(() => this.logCurrentPage());
        this.setState({ shouldRenderApp: true })

        setInterval(() => {
            this.state.loadingPercentage < 600 && this.setState((prevState) => ({ loadingPercentage: prevState.loadingPercentage + 50 }));
        }, 100);
    }

    // handleBeforeUnload = () => {
    //     localStorage.removeItem('openTabs');
    //     localStorage.removeItem('openTabsActiveKey');
    // }

    renderHomePage = () => {
        const { showContentLoader } = this.state;
        return (<>
            {
                !showContentLoader ? <>
                    <Header />
                    <HomeNeo history={history} />
                </> : <Loader />
            }
        </>)
    }

    render() {
        const isSFDCRedirection = sessionStorage.getItem("isSFDCRedirection");
        const { isLoginComplete, isFirstTimeLogin } = this.props
        const { showContentLoader } = this.state
        const path = window.location.pathname;
        // let className = `width-${this.state.loadingPercentage}`

        if (isSFDCRedirection) return <SFDCPage />;

        return (
            this.state.shouldRenderApp && (this.props.isLoginComplete || CONFIG.publicRoutes.includes(path)) &&
            <React.Fragment>
                <RealTimePushNotification />
                <Router history={history}>
                    <Layout className={this.props.isTestingMode && 'testing-mode'}>
                        {showContentLoader && isLoginComplete && <Loader />}
                        {(!isLoginComplete) && this.renderHomePage()}
                        {isLoginComplete && <span style={(showContentLoader) ? { display: "none" } : {}} ><Header /></span>}
                        <Layout hidden={showContentLoader} className={!isLoginComplete && 'home-page-layout'}>
                            <Layout>
                                {(isLoginComplete && !isFirstTimeLogin) && <NavigationMenu />}
                                {isLoginComplete && <TabsLayout />}
                            </Layout>
                        </Layout>
                    </Layout>
                </Router>
            </React.Fragment >
        );
    }
}

const mapStateToProps = ({ user, tabsLayout }) => ({
    user: user.profile,
    isTestingMode: user.isTestingMode,
    isLoginComplete: user.isLoginComplete,
    isFirstTimeLogin: user.isFirstTimeLogin,
    panes: tabsLayout.panes,

})

export default connect(
    mapStateToProps,
    { fetchUserDetails, updateViewPort, login, restoreTabsOnPageRefresh }
)(App);
