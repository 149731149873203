import React from 'react';
import { Modal, Table, Button, } from 'antd';


class DeletedCollateralsModal extends React.Component {

    renderFooterButtons = (okButtonText) => {
        return [
            <Button aria-label="Click" key="ok" className="primary-action-button-filled" onClick={this.props.onDeleteModalOk}> {okButtonText} </Button>
        ];
    }
    render() {
        const { visible, okButtonText, columns, deletedCollaterals } = this.props;
        return (
            <Modal
                visible={visible}
                title="List of Deleted  Collaterals"
                className="collateral-modal"
                onCancel={this.props.onDeleteModalOk}
                footer={this.renderFooterButtons(okButtonText)}
            >
                <div className="collateral-delete-view-table-wrapper" >
                    <Table
                        size='middle'
                        className="responsive-container vertical-spacing"
                        columns={columns}
                        dataSource={deletedCollaterals}
                        bordered
                        pagination={false}
                        rowKey={(record) => record.id}
                    />
                </div>
            </Modal>
        )
    }
}


export default DeletedCollateralsModal;