import React from 'react';
import { connect, batch } from 'react-redux';
import { Form, Input, Row, Col, Spin, Popconfirm, Tooltip, Select, Result, Icon, Table, Tag, Alert, Button, Divider, Modal, Tabs, Popover, Dropdown, Menu, message } from 'antd';
import CONFIG from '../../config';
import TextEditor from '../common/TextEditor';
import { Icon as NeoIcon } from "@avaya/neo-react";

import {
    toggleBackButton,
    fetchEmailTemplateVersionsByType,
    updateCustomEmailTemplateEditorContent,
    updateEmailPreviewEditorContent,
    clearDynamicEmailTemplate,
    clearDynamicEmailPreview,
    clearCustomEmailTemplates,
    fetchPreviewForEmailTemplate,
    updateCustomEmailTemplateVersion,
    deleteEmailTemplateVersion,
    updateEmailTemplateDefaultVersion,
    sendEmailToUser,
    toggleIsFetchingEmailTemplateVersions,
    updateManualDemoBuild,
    postDemoRequest,
    toggleCkEditorContentChangedFlag,
    fetchCustomEmailTemplateById,
} from '../../actions';
import pushMessage from '../common/PushMessage';
import { fetchEmailsBySearchString } from '../../actions/reports';
import { getAllDemosByEventType } from '../../actions/dynamicEmailTemplate';
import { exportEmailTemplateVersion } from "../../actions/emailTemplates";
const { Option } = Select;
const { TabPane } = Tabs;

class ManageTemplateVersions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedVersion: undefined,
            recordToClone: undefined,
            recordToEdit: undefined,
            isModalVisible: false,
            defaultActiveKey: "1",
            isTemplateSaved: false,
            hasFormChanged: false,
            hideTemplateTab: false,
            isEdit: false,
            isClone: false,
            file: undefined,
            clonedTitle: undefined,
            clonedDescrption: undefined,
            matchedUserEmails: [],
            disableUpdateButton: false,
            selectedDemo: null,
            demos: [],
            hasCkEditorContentChanged: false
        };
    }

    fetchUserEmails = searchString => {
        fetchEmailsBySearchString({ searchString })
            .then((result) => { result.length !== 0 ? this.setState({ matchedUserEmails: [...new Set(result.map(item => item.email))] }) : this.setState({ matchedUserEmails: [] }) });
    };

    checkIfCommentProvided = () => {
        let comment = this.props.form.getFieldValue("comment");
        comment = comment?.trim();
        const isCommented = comment?.length > 0 ? true : false;
        return isCommented
    }

    handleClone = async (versionId) => {
        this.props.fetchCustomEmailTemplateById(versionId)
            .then(async () => {
                await this.props.updateCustomEmailTemplateEditorContent(this.props.currentEmailTemplate?.body)
                this.setState({
                    isModalVisible: true,
                    selectedVersion: this.props.currentEmailTemplate,
                    isClone: true,
                    disableUpdateButton: true
                })
            })
    }

    handleDeleteVersion = async (record) => {
        await this.props.deleteEmailTemplateVersion(record?.id, record?.type, JSON.parse(this.state.selectedDemo)?.id);
    }

    handleEdit = async (versionId) => {
        const { action } = this.props.location
        const isCommented = action === "declined" ? this.checkIfCommentProvided() : true
        if (!isCommented) {
            pushMessage(CONFIG.messageType.warning, "Please provide a comment before continuing!")
            return;
        }
        this.props.fetchCustomEmailTemplateById(versionId)
            .then(async () => {
                await this.props.updateCustomEmailTemplateEditorContent(this.props.currentEmailTemplate?.body)
                this.setState({
                    isModalVisible: true,
                    selectedVersion: this.props.currentEmailTemplate,
                    isEdit: true,
                    disableUpdateButton: true,
                })
            })
    }

    handleSaveAs = (isPostVersion) => {
        const { form } = this.props;
        form.validateFields(['title', 'description'], async (err, values) => {
            if (err) {
                return;
            }

            this.setState({ clonedTitle: values?.title, clonedDescrption: values?.description, disableUpdateButton: true })
            this.setState({ hasCkEditorContentChanged: false });
            const response = await this.props.updateCustomEmailTemplateVersion(this.state.selectedVersion, values, JSON.parse(this.state.selectedDemo)?.id, isPostVersion)
            if (response !== "FAILED") {
                this.handleEdit(response || this.state.selectedVersion?.id);
            }
            response !== "FAILED" && this.setState({ isTemplateSaved: true })
            this.props.fetchEmailTemplateVersionsByType(JSON.parse(this.state.selectedDemo)?.id, this.state.selectedVersion?.type)
            this.props.toggleIsFetchingEmailTemplateVersions(false)
        })
    }

    handleCancel = () => {
        this.props.clearDynamicEmailTemplate()
        this.props.clearDynamicEmailPreview()
        this.props.clearCustomEmailTemplates()
        this.setState({
            selectedVersion: undefined,
            recordToClone: undefined,
            recordToEdit: undefined,
            isModalVisible: false,
            defaultActiveKey: "1",
            isTemplateSaved: false,
            hasFormChanged: false,
            hideTemplateTab: false,
            isEdit: false,
            isClone: false,
            file: undefined,
            clonedTitle: undefined,
            clonedDescrption: undefined,
            matchedUserEmails: [],
            disableUpdateButton: false
        })
    }

    onTabChange = (value) => {
        this.setState({
            defaultActiveKey: value,
        })
        if (value === "3")
            this.handlePreview()
    }


    handleEmailValidation = (value, field) => {
        if (!value.match(CONFIG.regEx.email)) {
            pushMessage(CONFIG.messageType.warning, "Please input a valid Email ID");
            this.props.form.setFieldsValue({ [field]: this.props.form.getFieldValue(field).slice(0, -1) })
        }
    }

    handleVersionDetailsChange = () => {
        this.setState({ disableUpdateButton: false })
    }


    showErrorPage = () => {
        return (
            <Result
                status="warning"
                title="Sorry, something went wrong!"
            />
        )
    }

    renderWarningMessage = () => {
        return (
            <Alert
                message={<>On click of <b>Save/Update Template</b>, only the changes made to the Template will saved, and not the changes made to the Preview.</>}
                type="warning"
                showIcon
            />
        )
    }


    renderEditor = () => {
        const { updateCustomEmailTemplateEditorContent } = this.props;
        const { defaultActiveKey, selectedVersion, hideTemplateTab } = this.state;
        return (
            <div className="card-container">
                <Tabs type="card" activeKey={defaultActiveKey} onChange={this.onTabChange}>
                    {!hideTemplateTab && <TabPane tab={<span><Icon type="file-text" />&nbsp;Template</span>} key="1">
                        {selectedVersion === undefined
                            ? this.showErrorPage()
                            :
                            <>
                                <Row className='bottom-spacing-3'>{this.renderWarningMessage()}</Row>
                                <TextEditor
                                    customEmail={true}
                                    updateEditorContent={updateCustomEmailTemplateEditorContent}
                                    storeKey="customEmailTemplate"
                                    editorKey={this.emailKey}
                                    isEdit={true}
                                    emailType="MANUAL_BUILD_AVAILABLE"
                                    placeholders={selectedVersion?.emailPlaceholders} /></>
                        }
                    </TabPane>}
                </Tabs>
            </div >
        )
    }




    renderModalFooterButtons = () => {
        const { defaultActiveKey, isClone, isTemplateSaved, isEdit, selectedVersion } = this.state
        if (defaultActiveKey === "1") {
            if (isClone || isEdit) {
                return (
                    <>
                        {/* Cancel Button is common for all the actions*/}
                        <Button key="Cancel" onClick={() => this.handleCancel()} className="cancel-button"> Cancel </Button>
                        {(isTemplateSaved || isEdit)
                            ? <Button disabled={selectedVersion === undefined} key="update" onClick={() => this.handleSaveAs()} className="left-spacing primary-action-button-filled" >Update Template</Button>
                            : <Button disabled={selectedVersion === undefined} key="save" onClick={() => { isClone ? this.handleSaveAs(true) : this.handleSaveAs() }} className="left-spacing primary-action-button-filled" >Save Template</Button>}
                    </>)
            }
        }
        else if (defaultActiveKey === "2") {
            return (
                <>
                    {/* Cancel Button is common for all the actions*/}
                    <Button key="Cancel" onClick={() => this.handleCancel()} className="cancel-button"> Cancel </Button>
                    <Button disabled={selectedVersion === undefined} key="Preview" onClick={() => this.handlePreview()} className="left-spacing primary-action-button-filled" > Preview </Button>
                </>)
        }
    }


    renderEmailTemplateVersions = () => {
        const { demoBuildVersions, user } = this.props;
        const emailType = this.props.match.params.emailTemplateType
        const isSuperAdminUser = user && user.roleTemplate.templateType === CONFIG.roleTypes.superAdmin;

        const columns = [
            {
                title: 'Version(s)',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => <span>{record?.title} {(record?.isOriginal || record.demo === null) && <Tag color={CONFIG.colors.success}>Original</Tag>}{record?.isDefault && <Tag color={CONFIG.colors.userDemo}>Recommended</Tag>}</span>,
            },

            {
                title: 'Last Updated By',
                dataIndex: 'lastUpdatedBy',
                align: 'center',
                key: 'lastUpdatedBy',
                render: (text, record) => <span>{record?.lastUpdatedBy !== null ? record?.lastUpdatedBy?.firstName + " " + record?.lastUpdatedBy?.lastName : record?.createdBy !== null ? record?.createdBy?.firstName + " " + record?.createdBy?.lastName : " - "}</span>,
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                render: (text, record) => {
                    // const isDeleteDisabled = (user?.id !== record?.createdBy?.id || record?.isOriginal)
                    const isDeleteDisabled = (user?.id !== record?.createdBy?.id && !isSuperAdminUser)
                    return (<span className="demo-card-body-details">
                        <Popover content={record?.description} title="Description">
                            <NeoIcon className="link" title="Description" icon="help" style={{ fontSize: "22px" }} />
                        </Popover>
                        <Divider type="vertical" />
                        <NeoIcon className="link" onClick={() => { this.handleClone(record?.id) }} title="Clone" icon="copy" style={{ fontSize: "22px" }} />
                        <Divider type="vertical" />
                        <NeoIcon className={record?.isOriginal ? "link-disabled" : "link"} onClick={() => { !record?.isOriginal && this.handleEdit(record?.id) }} disabled={record?.isOriginal} title="Edit" icon="edit" style={{ fontSize: "22px" }} />
                        {<><Divider type="vertical" />
                            <Popconfirm
                                disabled={isDeleteDisabled || record?.isOriginal}
                                placement="right"
                                title="Confirm Delete?"
                                okText="Yes" cancelText="No"
                                onConfirm={() => { this.handleDeleteVersion(record) }}>
                                <Tooltip title={(isDeleteDisabled || record?.isOriginal) ? CONFIG.warningMessages.noAccess : undefined} key={Math.random()}>
                                    <NeoIcon className={(isDeleteDisabled || record?.isOriginal) ? "link-disabled" : "link"} disabled={isDeleteDisabled || record?.isOriginal} icon="trash" style={{ fontSize: "22px" }} />
                                </Tooltip>
                            </Popconfirm></>}
                    </span>)
                }
            }
        ];

        const rowSelection = {

            onChange: (selectedRowKeys, selectedRows) => { this.props.updateEmailTemplateDefaultVersion(selectedRows[0]?.id, emailType, JSON.parse(this.state.selectedDemo)?.id, selectedRows[0]?.isVersion) },

            getCheckboxProps: (record) => ({
                // disabled: record.demo === null,
                checked: record.isDefault
            }),
        };


        return (
            <>
                <Spin spinning={this.props.isFetching}>
                    {this.renderDemoSelectDropdown()}
                    <Row>
                        <Table
                            rowSelection={isSuperAdminUser ? { type: "radio", ...rowSelection } : undefined}
                            className="build-email-template-table"
                            pagination={false}
                            bordered={false}
                            columns={columns}
                            dataSource={demoBuildVersions}
                            size='small'>
                        </Table>
                    </Row>
                </Spin>
            </>
        )
    }

    componentDidMount() {
        const emailType = this.props.match.params.emailTemplateType
        const { currentEmailTemplate, templateHtml } = this.props
        this.props.toggleIsFetchingEmailTemplateVersions(true);
        batch(() => {
            getAllDemosByEventType(emailType).then((data) => {
                this.setState({ selectedDemo: JSON.stringify({ id: data?.[0]?.id, title: data?.[0]?.title }), demos: data })
                this.props.fetchEmailTemplateVersionsByType(data?.[0]?.id, emailType)
            })
            this.emailKey = Math.random().toString();
            this.emailKeyPreview = Math.random().toString();
            this.setState({ hasCkEditorContentChanged: currentEmailTemplate?.body === templateHtml ? false : true });

        });
    }

    componentWillUnmount() {
        this.props.clearDynamicEmailTemplate();
        this.props.clearDynamicEmailPreview();
    }

    handleMenuClick = (e) => {
        const fileType = e.key;
        const demoId = JSON.parse(this.state.selectedDemo)?.id;
        const demoName = JSON.parse(this.state.selectedDemo)?.title;
        const emailType = this.props.match.params.emailTemplateType
        const timeZone = this.props.timeZoneName;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        exportEmailTemplateVersion(CONFIG.exportTable.customEmailTemplate.fileName + `-${demoName}.${fileType}`, fileType, demoId, timeZone, emailType)
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Table exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })
    }

    renderDemoSelectDropdown = () => {
        const emailType = this.props.match.params.emailTemplateType
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key={CONFIG.exportFileType.xlsx.ext}>
                    {CONFIG.exportFileType.xlsx.name}
                </Menu.Item>
                <Menu.Item key={CONFIG.exportFileType.csv.ext}>
                    {CONFIG.exportFileType.csv.name}
                </Menu.Item>
            </Menu>
        );
        return <>
            <Row gutter={20}>
                <Col span={10}>
                    <Select showSearch={true}
                        onChange={(value) => {
                            this.setState({ selectedDemo: value })
                            this.props.fetchEmailTemplateVersionsByType(JSON.parse(value)?.id, emailType)
                        }
                        }
                        value={this.state.selectedDemo}
                        placeholder="Select a demo"
                    >
                        {this.state.demos?.map((demo, index) => {
                            return <Option key={demo.id} value={JSON.stringify({ id: demo.id, title: demo.title })}>{demo.title}</Option>
                        })
                        }
                    </Select>
                    <br />
                    <br />
                </Col>
                <Col span={2}>
                    <Dropdown overlay={menu}>
                        <Button className="primary-action-button-filled left-spacing-3">
                            Export <Icon type="down" />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </>
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { isClone, isModalVisible, selectedVersion, clonedTitle, clonedDescrption, hideTemplateTab } = this.state;
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }
        return (
            <div className="content-container">
                <Spin spinning={false} wrapperClassName="spin-overlay">
                    <div className="maintenance-steps-content">
                        {this.renderEmailTemplateVersions()}
                        {isModalVisible && <Modal
                            className="scheduler-modal"
                            footer={this.renderModalFooterButtons()}
                            title={
                                <Form layout={formItemLayout} className="email-recipients-form">
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item {...formItemLayout} label="Title">
                                                {getFieldDecorator("title", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please provide a title!"
                                                        }
                                                    ], initialValue: isClone ? clonedTitle : selectedVersion?.title
                                                })(<Input onChange={() => this.handleVersionDetailsChange()} disabled={hideTemplateTab} />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item {...formItemLayout} label="Description">
                                                {getFieldDecorator("description", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please provide a description!"
                                                        }
                                                    ], initialValue: isClone ? clonedDescrption : selectedVersion?.description
                                                })(<Input onChange={() => this.handleVersionDetailsChange()} disabled={hideTemplateTab} />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>}

                            visible={isModalVisible}
                            bodyStyle={{ maxHeight: "78vh" }}
                            onCancel={this.handleCancel}
                            maskClosable={false}
                        >
                            {this.renderEditor()}
                        </Modal>}
                    </div>
                </Spin >
            </div >
        );
    }
}


const mapStateToProps = ({ customEmailTemplate, dynamicEmailTemplate, user, dynamicEmail }) => {
    return {
        isFetching: dynamicEmailTemplate.isFetching,
        demoBuildVersions: dynamicEmail?.versionsData,
        templateHtml: customEmailTemplate?.html,
        user: user.profile,
        currentEmailTemplate: customEmailTemplate.customEmailTemplate,
        timeZoneName: user.profile.timezone,
    };
}

const ManageTemplateVersionsRef = Form.create()(ManageTemplateVersions);

export default connect(
    mapStateToProps,
    {
        toggleBackButton,
        fetchEmailTemplateVersionsByType,
        updateCustomEmailTemplateEditorContent,
        updateEmailPreviewEditorContent,
        clearDynamicEmailTemplate,
        clearDynamicEmailPreview,
        fetchPreviewForEmailTemplate,
        updateCustomEmailTemplateVersion,
        deleteEmailTemplateVersion,
        updateEmailTemplateDefaultVersion,
        sendEmailToUser,
        toggleIsFetchingEmailTemplateVersions,
        updateManualDemoBuild,
        postDemoRequest,
        toggleCkEditorContentChangedFlag,
        clearCustomEmailTemplates,
        fetchCustomEmailTemplateById
    }
)(ManageTemplateVersionsRef);