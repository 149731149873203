import {
    FETCH_ALL_CUSTOM_EMAIL_TEMPLATES,
    FETCH_ALL_STANDARD_EMAIL_TEMPLATES,
    UPDATE_EMAIL_TEMPLATE_EDITOR_CONTENT,
    UPDATE_EMAIL_TEMPLATE_TAB_DETAILS,
    FETCH_EMAIL_TEMPLATE,
    CLEAR_EMAIL_TEMPLATE_CONTENT,
    TOGGLE_IS_FETCHING_EMAIL_TEMPLATE,
    FETCH_PLACEHOLDERS,
    CLEAR_CUSTOM_EMAIL_TEMPLATES,
    FETCH_ALL_EMAIL_CATEGORIES,
    CLEAR_STANDARD_EMAIL_TEMPLATES,
    LAST_CUSTOM_EMAIL_TEMPLATE_FILTER,
    LAST_STANDARD_EMAIL_TEMPLATE_FILTER
} from '../actions/types'

// Note : 

// emailTemplatesReducers is a commom reducer for both Standard and Custom Email Templates for
//     1. toggle isFetching.
//     2. fetching fetchEmailTemplateById
//     3. fetching placeholders


export const emailTemplatesReducers = (state = {}, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_EMAIL_TEMPLATE:
            return { ...state, isFetching: action.payload }
        case FETCH_EMAIL_TEMPLATE:
            return { ...state, emailTemplate: action.payload }
        case FETCH_PLACEHOLDERS:
            return { ...state, placeholders: action.payload };
        default:
            return state
    }
}


const standardEmailTemplatesState = {
    currentPage: 1,
    currentPageNumber: undefined,
    currentEmailTemplateId: undefined,
    data: []
};

export const standardEmailTemplatesReducers = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ALL_STANDARD_EMAIL_TEMPLATES:
            return { ...state, data: action.payload.dataSet || [], count: action.payload.count }
        case FETCH_ALL_EMAIL_CATEGORIES:
            return { ...state, emailCategories: action.payload || [] }
        case TOGGLE_IS_FETCHING_EMAIL_TEMPLATE:
            return { ...state, isFetching: action.payload }
        case CLEAR_STANDARD_EMAIL_TEMPLATES:
            return standardEmailTemplatesState
        case FETCH_PLACEHOLDERS:
            return { ...state, placeholders: action.payload };
        case LAST_STANDARD_EMAIL_TEMPLATE_FILTER:
            return { ...state, currentStandardEmailFilter: action.payload[0], isEdit: true, currentPageNumber: action.payload[1], currentPageSize: action.payload[2] }
        default:
            return state
    }
}

const customEmailTemplatesState = {
    currentPage: 1,
    currentPageNumber: undefined,
    currentEmailTemplateId: undefined,
    data: []
};

export const customEmailTemplatesReducers = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ALL_CUSTOM_EMAIL_TEMPLATES:
            return { ...state, data: action.payload || [], count: action.payload?.length || 0 }
        case TOGGLE_IS_FETCHING_EMAIL_TEMPLATE:
            return { ...state, isFetching: action.payload }
        case CLEAR_CUSTOM_EMAIL_TEMPLATES:
            return customEmailTemplatesState
        case LAST_CUSTOM_EMAIL_TEMPLATE_FILTER:
            return { ...state, currentCustomEmailFilter: action.payload[0], isEdit: true }
        default:
            return state
    }
}

const emailTemplateFormState = {
    emailTemplateDetailsTabData: []
}

export const emailTemplatesFormReducers = (state = emailTemplateFormState, action) => {
    switch (action.type) {
        case UPDATE_EMAIL_TEMPLATE_EDITOR_CONTENT:
            return {
                ...state,
                emailTemplateDetailsTabData: state.emailTemplateDetailsTabData?.map(el => (parseInt(el?.id) === parseInt(action.payload?.recordId)) ? { ...el, bodyHtml: action.payload?.newHtmlBody } : el)
            };
        case UPDATE_EMAIL_TEMPLATE_TAB_DETAILS:
            return {
                ...state,
                emailTemplateDetailsTabData: state.emailTemplateDetailsTabData.filter(obj => obj.id !== action.payload.id).concat(action.payload)
            }
        case CLEAR_EMAIL_TEMPLATE_CONTENT:
            return { ...state, html: undefined, isContentLoaded: false }
        default:
            return state
    }
}