import {
    SHOW_USER_DASHBOARD_TODAY_SCHEDULES_SECTION,
    SHOW_USER_DASHBOARD_UPCOMING_SCHEDULES_SECTION,
    SHOW_USER_DASHBOARD_PREVIOUS_SCHEDULES_SECTION,
    SHOW_USER_DASHBOARD_EZ_DEMO_SECTION,
    SHOW_USER_DASHBOARD_NEWS_SECTION,
    SHOW_USER_DASHBOARD_MOST_SCHEDULED_DEMO_SECTION,
    CLEAR_USER_DASHBOARD_SECTION_VIEWS,
    SHOW_ADMIN_DASHBOARD_UPCOMING_SCHEDULES_SECTION,
    SHOW_ADMIN_DASHBOARD_UPCOMING_MAINTENENCE_SECTION,
    CLEAR_ADMIN_DASHBOARD_SECTION_VIEWS
} from '../actions/types';

const userDashboardSectionViewState = {
    showUserTodaySchedulesSection: true,
    showUserEZDemoSection: true,
    showUserMostScheduledDemosSection: true,
    showUserPreviousSchedulesSection: true,
    showUserUpcomingSchedulesSection: true,
    showUserNewsSection: true
}

export const userDashboardSectionViewsReducer = (state = userDashboardSectionViewState, action) => {
    switch (action.type) {
        case SHOW_USER_DASHBOARD_TODAY_SCHEDULES_SECTION:
            return { ...state, showUserTodaySchedulesSection: action.payload };
        case SHOW_USER_DASHBOARD_UPCOMING_SCHEDULES_SECTION:
            return { ...state, showUserUpcomingSchedulesSection: action.payload };
        case SHOW_USER_DASHBOARD_PREVIOUS_SCHEDULES_SECTION:
            return { ...state, showUserPreviousSchedulesSection: action.payload };
        case SHOW_USER_DASHBOARD_EZ_DEMO_SECTION:
            return { ...state, showUserEZDemoSection: action.payload };
        case SHOW_USER_DASHBOARD_NEWS_SECTION:
            return { ...state, showUserNewsSection: action.payload };
        case SHOW_USER_DASHBOARD_MOST_SCHEDULED_DEMO_SECTION:
            return { ...state, showUserMostScheduledDemosSection: action.payload };
        case CLEAR_USER_DASHBOARD_SECTION_VIEWS:
            return userDashboardSectionViewState;
        default:
            return state;
    }
}


const adminDashboardSectionViewState = {
    showAdminUpcomingSchedulesSection: true,
    showAdminUpcomingMaintenenceSection: true
}

export const adminDashboardSectionViewsReducer = (state = adminDashboardSectionViewState, action) => {
    switch (action.type) {
        case SHOW_ADMIN_DASHBOARD_UPCOMING_SCHEDULES_SECTION:
            return { ...state, showAdminUpcomingSchedulesSection: action.payload };
        case SHOW_ADMIN_DASHBOARD_UPCOMING_MAINTENENCE_SECTION:
            return { ...state, showAdminUpcomingMaintenenceSection: action.payload };
        case CLEAR_ADMIN_DASHBOARD_SECTION_VIEWS:
            return adminDashboardSectionViewState;
        default:
            return state;
    }
}