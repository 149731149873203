import React from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Button, Select, Row, Col, Input, DatePicker, InputNumber, Spin } from 'antd';
import moment from 'moment-timezone';

import CONFIG from '../../config';
import { getAllItems, fetchReportColumns, toggleIsFetchingReportForm } from '../../actions/reports'
const { Option } = Select;
const { RangePicker, MonthPicker } = DatePicker;

class RefineReport extends React.Component {

    state = {
        data: [],
        initialData: [],
        matchedEmails: [],
        searchString: [],
        fetchingEmails: false,
        isLoading: [],
        loading: true
    }

    data = []
    initialData = []
    timeZone = moment.tz.names();
    filterObject = {}

    async componentDidMount() {
        const { userRun, isRunByUser, currentReport } = this.props;
        const currentReportData = !isRunByUser ? this.getCurrentReportData() : currentReport;
        const data = currentReportData?.filterTable?.id && await this.props.fetchReportColumns(currentReportData?.filterTable?.id, isRunByUser, false)
        if (!currentReportData?.default) {
            this.filterObject = {
                ...this.filterObject,
                ...currentReportData?.filterSortObject?.filter
            }
            let columns = []
            columns = data?.length > 0 && data;
            const objectKeysFilterSort = this.filterObject ? Object.keys(this.filterObject) : []
            userRun !== "fromUser" && await this.props.setSelectedColumns(objectKeysFilterSort)
            delete this.filterObject?.startTime;
            delete this.filterObject?.endTime;
            if (currentReportData?.filterTable?.id === 16 || currentReportData?.filterTable?.tableName === "Daily Test Report") {
                delete this.filterObject?.createdTime
            }
            let objectKeys = this.filterObject ? Object.keys(this.filterObject) : []
            this.id = objectKeys.length !== 0 ? objectKeys.length : 0
            const keys = objectKeys.length !== 0 ? [...Array(objectKeys.length).keys()] : []
            keys.forEach(async (k) => {
                const columnObj = columns?.find(column => column.columnKey === objectKeys[k])
                if ((columnObj?.columnType === CONFIG.formFieldTypes.MULTISELECT || columnObj?.columnType === CONFIG.formFieldTypes.SINGLESELECT) && columnObj?.columnApiService) {
                    await this.props.toggleIsFetchingReportForm(true);
                    const response = await getAllItems(columnObj)
                    this.initialData[(k * 3) + 3] = response;
                    await this.setState({ initialData: this.initialData })
                    await this.props.toggleIsFetchingReportForm(false);
                }
                else if (columnObj?.columnType === CONFIG.formFieldTypes.MULTISELECT && columnObj?.columnApiService === null) {
                    const values = columnObj.columnValues.split(",");
                    let valueObj = {};
                    valueObj = values.map((data) => ({ "name": data }))
                    this.initialData[(k * 3) + 3] = valueObj
                    await this.setState({ initialData: this.initialData })
                }
            })
        }
        setTimeout(() => {
            this.setState({ loading: false })
        }, 2500)
    }

    remove = k => {
        const { form, userRun } = this.props;
        const columnObj = form.getFieldValue(`names[${(k * 3) + 1}]`) && JSON.parse(form.getFieldValue(`names[${(k * 3) + 1}]`));
        const keys = form.getFieldValue('keys');
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
        if (userRun !== "fromUser") {
            const selectedColumns = this.props.selectedColumns.filter(column => column !== columnObj?.columnKey)
            this.props.isFilterRemoved();
            this.props.setSelectedColumns(selectedColumns)
        }
    };

    add = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(this.id++);
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    renderColumnValues = (isGeneralReport) => {
        const { selectedColumns, tableName, isRunByUser, currentReport } = this.props;
        const currentReportData = !isRunByUser ? this.getCurrentReportData() : currentReport;
        const dailyReport = (currentReportData?.filterTable?.id === 16 || currentReportData?.filterTable?.tableName === "Daily Test Report" || tableName === "Daily Test Report")
        if (!isGeneralReport) {
            const columns = dailyReport ? currentReportData?.reportColumns?.filter(column => (column.columnKey !== "startTime" && column.columnKey !== "endTime" && column.columnKey !== "createdTime")) : currentReportData?.reportColumns?.filter(column => (column.columnKey !== "startTime" && column.columnKey !== "endTime"))
            return columns?.map((column, index) => <Option key={index} disabled={selectedColumns?.includes(column.columnKey)} value={JSON.stringify(column)} title={column.columnName}>{column.columnName}</Option>)
        }
    }

    renderOperatorValues = (key) => {
        const { form } = this.props;
        const columnObj = form.getFieldValue(key);
        return columnObj ? CONFIG.operators[JSON.parse(columnObj)?.columnType].map((item, index) => <Option key={index} value={item.operator} title={item.operatorText}>{item.operatorText}</Option>) : []
    }

    getData = async (columnObj, key, value) => {
        const columnType = CONFIG.formFieldTypes.MULTISELECT;
        const singleType = CONFIG.formFieldTypes.SINGLESELECT;
        let isLoadingKey = [];
        isLoadingKey[key] = true
        columnObj?.columnType === (columnType || singleType) && this.setState({ isLoading: isLoadingKey }, () => { })
        if (value) {
            if (columnObj.columnApiService) {
                if (value === true || value?.length > 2) {
                    await this.props.toggleIsFetchingReportForm(true);
                    const apiData = await getAllItems(columnObj, value);
                    const data = columnObj?.columnType === "autocomplete" ? this.getUniqueValues(apiData, columnObj?.columnKey) : apiData
                    this.data[key] = data
                    this.setState({ data: this.data }, () => { })
                    await this.props.toggleIsFetchingReportForm(false);
                }
            }
            else if (columnObj?.columnType === CONFIG.formFieldTypes.PACKAGE) {
                this.data[key] = this.timeZone
                this.setState({ data: this.data })
            }
            else {
                this.data[key] = columnObj.columnValues?.split(",")
                this.setState({ data: this.data })
            }
        }
        isLoadingKey[key] = false
        this.data[key] && columnObj?.columnType === (columnType || singleType) && this.setState({ isLoading: isLoadingKey }, () => { })
    }

    getUniqueValues = (apiData, columnKey) => {
        let x;
        let uniqueData = [];
        uniqueData = apiData?.reduce((acc, current) => {
            x = columnKey.includes("company")
                ? acc.find(item => (item?.company === current.company))
                : columnKey.includes("email") ? acc.find(item => (item?.email === current.email))
                    : acc.find(item => (item?.fullName === current.fullName))
            if (!x) return acc.concat([current]);
            else return acc;
        }, []);
        return uniqueData
    }


    getInitialValue = (columnKey, k, operator) => {
        const { form } = this.props;
        const columnObj = JSON.parse(form.getFieldValue(columnKey));
        const objectKeys = this.filterObject ? Object.keys(this.filterObject) : [];
        const value = this.filterObject ? this.filterObject[objectKeys[k]]?.oprVal : undefined
        if (value && columnObj?.columnKey === objectKeys[k]) {
            switch (columnObj?.columnType) {
                case CONFIG.formFieldTypes.MULTISELECT:
                case CONFIG.formFieldTypes.AUTOCOMPLETE:
                case CONFIG.formFieldTypes.PACKAGE:
                    return value?.split("\\\\")
                case CONFIG.formFieldTypes.DATE:
                    if (form.getFieldValue(operator) === "IN") {
                        if (value) {
                            const startTime = value.split('@')[0]
                            const endTime = value.split('@')[1]
                            return [moment.tz(startTime, this.props.timeZoneName), moment.tz(endTime, this.props.timeZoneName)]
                        }
                        else return []
                    }
                    return value && moment.tz(value, this.props.timeZoneName)
                case CONFIG.formFieldTypes.MONTH:
                    return value && moment.tz(value.split('@')[0], this.props.timeZoneName)
                default:
                    return value
            }
        }
        return
    }

    getFormElement = (columnKey, fieldKey, key, k, operator, currentReport) => {
        const { form, isEditMode } = this.props;
        const { getFieldDecorator } = form;
        const columnObj = form.getFieldValue(columnKey) && JSON.parse(form.getFieldValue(columnKey));
        switch (columnObj?.columnType) {
            case CONFIG.formFieldTypes.TEXT:
                return getFieldDecorator(fieldKey, {
                    initialValue: this.getInitialValue(columnKey, k),
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: "Please enter a value",
                        },
                    ],
                })(<Input />)
            case CONFIG.formFieldTypes.MULTISELECT:
            case CONFIG.formFieldTypes.SINGLESELECT:
            case CONFIG.formFieldTypes.PACKAGE:
            case CONFIG.formFieldTypes.BOOLEAN:
                let initialData = this.getInitialValue(columnKey, k)
                return getFieldDecorator(fieldKey, {
                    initialValue: (columnObj?.columnType === CONFIG.formFieldTypes.MULTISELECT)
                        ? initialData?.reduce((acc, item) => {
                            if (this.state.initialData[key]?.find(data => data?.title === item || data?.name === item || data?.purpose === item || data?.fullName === item || data === item)) {
                                acc.push(item)
                            }
                            else {
                                if (this.props.userRun !== "fromUser" && currentReport?.filterSortObject) {
                                    this.props.updateFilterSortObject({ ...(currentReport?.filterSortObject || []), filter: { ...(currentReport?.filterSortObject?.filter || []) } })
                                    // this.props.updateFilterSortObject({ ...(currentReport.filterSortObject || []), filter: { ...(currentReport.filterSortObject?.filter || []), [columnObj.columnKey]: { operator: currentReport.filterSortObject?.filter?.[columnObj.columnKey]?.operator, oprVal: "" } } }) @Kalptiha's code
                                }
                            }
                            return acc
                        }, [])
                        : initialData ? initialData : isEditMode ? false : undefined,
                    rules: [
                        {
                            type: columnObj?.columnType === CONFIG.formFieldTypes.BOOLEAN ? 'boolean' : (columnObj?.columnType === CONFIG.formFieldTypes.PACKAGE || columnObj.columnType === CONFIG.formFieldTypes.MULTISELECT) ? 'array' : 'string',
                            required: true,
                            whitespace: true,
                            message: "Please select a value",
                        },
                    ],
                })(
                    <Select
                        loading={this.state.isLoading[key]}
                        mode={(columnObj?.columnType === CONFIG.formFieldTypes.MULTISELECT || columnObj?.columnType === CONFIG.formFieldTypes.PACKAGE) && "multiple"}
                        placeholder="Select a value"
                        showSearch
                        onDropdownVisibleChange={(...rest) => this.getData(columnObj, key, ...rest)}
                        getPopupContainer={() => document.getElementById('refineReportForm')}
                    >
                        {(columnObj?.columnType === CONFIG.formFieldTypes.MULTISELECT || columnObj?.columnType === CONFIG.formFieldTypes.PACKAGE || columnObj?.columnType === CONFIG.formFieldTypes.SINGLESELECT)
                            ? this.state.data[key]?.map((data, index) =>
                                <Option key={index} title={data.title || data.name || data.fullName || data.purpose || data} value={data.title || data.name || data.fullName || data.purpose || data}>{data.title || data.name || data.fullName || data.purpose || data}</Option>
                            )
                            : columnObj.columnValues?.split(",")?.map((value, index) =>
                                <Option key={index} value={CONFIG.booleanValue[index]}>{value}</Option>
                            )
                        }
                    </Select>
                )
            case CONFIG.formFieldTypes.AUTOCOMPLETE:
                return (
                    getFieldDecorator(fieldKey, {
                        initialValue: this.getInitialValue(columnKey, k),
                        rules: [
                            {
                                type: 'array',
                                required: true,
                                whitespace: true,
                                message: "Please select a value",
                            },
                        ],
                    })(
                        <Select
                            mode="multiple"
                            showSearch={true}
                            placeholder='Type value to search'
                            onSearch={(...rest) => this.getData(columnObj, key, ...rest)}
                            notFoundContent="No Match found!"
                            getPopupContainer={() => document.getElementById('refineReportForm')}

                        >
                            {
                                this.state.data[key]?.map((dataObj, index) =>
                                    <Option key={index} value={dataObj.title || dataObj.name || dataObj.company || dataObj.fullName || dataObj.email}>{dataObj.title || dataObj.name || dataObj.company || dataObj.fullName || dataObj.email}</Option>
                                )
                            }
                        </Select>
                    )
                )
            case CONFIG.formFieldTypes.DATE:
                return (
                    getFieldDecorator(fieldKey, {
                        initialValue: this.getInitialValue(columnKey, k, operator),
                        rules: [
                            {
                                type: form.getFieldValue(operator) === "IN" ? 'array' : 'object',
                                required: true,
                                whitespace: true,
                                message: "Please select a value",
                            },
                        ],
                    })(
                        form.getFieldValue(operator) === "IN"
                            ? (<RangePicker
                                ranges={this.ranges}
                                getPopupContainer={() => document.getElementById('refineReportForm')}
                            />)
                            : (<DatePicker
                                getPopupContainer={() => document.getElementById('refineReportForm')}
                            />)
                    )
                )
            case CONFIG.formFieldTypes.MONTH:
                return (
                    getFieldDecorator(fieldKey, {
                        initialValue: this.getInitialValue(columnKey, k),
                        rules: [
                            {
                                type: 'object',
                                required: true,
                                whitespace: true,
                                message: "Please select a value",
                            },
                        ],
                    })(
                        <MonthPicker
                            getPopupContainer={() => document.getElementById('refineReportForm')}
                        />
                    )
                )
            case CONFIG.formFieldTypes.NUMBER:
                return (
                    getFieldDecorator(fieldKey, {
                        initialValue: this.getInitialValue(columnKey, k) ? parseFloat(this.getInitialValue(columnKey, k)) : undefined,
                        rules: [
                            {
                                type: 'number',
                                required: true,
                                whitespace: true,
                                message: "Please enter a number",
                            },
                        ],
                    })(
                        <InputNumber min={0} />
                    )
                )
            case CONFIG.formFieldTypes.DURATION:
                const initiaValue = this.getInitialValue(columnKey, k) ? parseFloat(this.getInitialValue(columnKey, k)) : 0
                const initialDays = Math.floor(initiaValue / 24 / 60)
                const initialHours = Math.floor(initiaValue / 60 % 24)
                const initialMinutes = Math.floor(initiaValue % 60)
                return (
                    <Input.Group compact>
                        {getFieldDecorator(`${fieldKey}Days`, {
                            initialValue: initialDays
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `${value} day(s)`}
                                parser={value => value.replace(/[^0-9]/g, '')}
                            />
                        )
                        }
                        {getFieldDecorator(`${fieldKey}Hours`, {
                            initialValue: initialHours
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `${value} hour(s)`}
                                parser={value => value.replace(/[^0-9]/g, '')}
                            />
                        )
                        }
                        {getFieldDecorator(`${fieldKey}Minutes`, {
                            initialValue: initialMinutes
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `${value} min(s)`}
                                parser={value => value.replace(/[^0-9]/g, '')}
                            />
                        )
                        }
                    </Input.Group>
                )

            default:
                return getFieldDecorator(fieldKey, {
                    initialValue: this.getInitialValue(columnKey, k),
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: "Please enter a value",
                        },
                    ],
                })(<Input />)
        }
    }

    handleColumnSelectChange = (operatorKey, valueKey, key, selectedColumn) => {
        const { setFieldsValue, getFieldValue } = this.props.form;
        const { selectedColumns } = this.props;
        setFieldsValue({ [operatorKey]: undefined, [valueKey]: undefined })
        let names = getFieldValue('names')
        let updatedSelectedColumns = names[key] ? selectedColumns.filter(col => col !== JSON.parse(names[key]).columnKey) : selectedColumns
        updatedSelectedColumns = [...updatedSelectedColumns, JSON.parse(selectedColumn).columnKey]
        this.setState({ data: [] })
        this.props.setSelectedColumns(updatedSelectedColumns)
    }

    handleOperatorSelectChange = (columnKey, valueKey) => {
        const { setFieldsValue, getFieldValue } = this.props.form;
        const columnObj = JSON.parse(getFieldValue(columnKey));
        columnObj?.columnType === CONFIG.formFieldTypes.DATE && setFieldsValue({ [valueKey]: undefined })
    }

    getCurrentReportData = () => {
        const { currentReport, isCloneReport, isGeneralReport } = this.props;
        const { reportId } = this.props.params || undefined;
        const id = reportId !== undefined ? reportId : "-1";
        return currentReport?.find(item => (
            (parseInt(item?.id) === parseInt(id)) && (item?.isClone === isCloneReport) && (item?.isGeneralReport === isGeneralReport)))?.details;
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { isGeneralReport, timeZoneName, userRun, isRunByUser, currentReport } = this.props;
        const currentReportData = !isRunByUser ? this.getCurrentReportData() : currentReport;
        const objectKeys = this.filterObject ? Object.keys(this.filterObject) : []
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 6 },
            },
        };
        this.ranges = {
            'Last Year': [moment.tz(timeZoneName).startOf('year').subtract(12, 'months'), moment.tz(this.props.timeZoneName).subtract(12, 'months').endOf('year')],
            'Last 6 Months': [moment.tz(timeZoneName).startOf('month').subtract(6, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
            'Last 3 Months': [moment.tz(timeZoneName).startOf('month').subtract(3, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
            'Last Month': [moment.tz(timeZoneName).startOf('month').subtract(1, 'months'), moment.tz(this.props.timeZoneName).subtract(1, 'months').endOf('month')],
            'Last Week': [moment.tz(timeZoneName).startOf('week').subtract(1, 'weeks').add(1, 'days'), moment.tz(this.props.timeZoneName).subtract(1, 'weeks').endOf('week').add(1, 'days')],
            'This Year': [moment.tz(timeZoneName).startOf('year'), moment.tz(this.props.timeZoneName).endOf('year')],
            'This Month': [moment.tz(timeZoneName).startOf('month'), moment.tz(this.props.timeZoneName).endOf('month')],
            'Tomorrow': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(1, 'days')],
            'Next 7 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(7, 'days')],
            'Next 14 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(14, 'days')],
            'Next 30 days': [moment.tz(timeZoneName), moment.tz(timeZoneName).add(30, 'days')],
        }

        getFieldDecorator('keys', { initialValue: objectKeys.length !== 0 ? [...Array(objectKeys.length).keys()] : [] });
        const keys = getFieldValue('keys');
        const formItems = !isGeneralReport ? keys?.map((k, index) => (
            <Row gutter={24}>
                <Col xl={6} xs={24}>
                    <Form.Item
                        required={false}
                        key={k}
                    >
                        {getFieldDecorator(`names[${(k * 3) + 1}]`, {
                            validateTrigger: ['onChange', 'onBlur'],
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "Please select a column",
                                },
                            ],
                            initialValue: JSON.stringify(currentReportData?.reportColumns?.find(column => column.columnKey === objectKeys[k]))
                        })
                            (<Select
                                disabled={userRun === "fromUser"}
                                placeholder="Select a column"
                                onChange={(...rest) => this.handleColumnSelectChange(`names[${(k * 3) + 2}]`, `names[${(k * 3) + 3}]`, (k * 3) + 1, ...rest)}
                                getPopupContainer={() => document.getElementById('refineReportForm')}
                            >
                                {this.renderColumnValues()}
                            </Select>)}
                    </Form.Item>
                </Col>
                <Col xl={6} xs={24}>
                    <Form.Item
                        required={false}
                        key={k}
                    >
                        {getFieldValue(`names[${(k * 3) + 1}]`) && getFieldDecorator(`names[${(k * 3) + 2}]`, {
                            validateTrigger: ['onChange', 'onBlur'],
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "Please select an operator",
                                },
                            ],
                            initialValue: this.filterObject ? this.filterObject[objectKeys[k]]?.operator : undefined
                        })(<Select
                            placeholder="Select an operator"
                            onChange={(...rest) => this.handleOperatorSelectChange(`names[${(k * 3) + 1}]`, `names[${(k * 3) + 3}]`, ...rest)}
                            getPopupContainer={() => document.getElementById('refineReportForm')}
                        >
                            {this.renderOperatorValues(`names[${(k * 3) + 1}]`, (k * 3) + 3)}
                        </Select>)}
                    </Form.Item>
                </Col>
                <Col xl={10} xs={24}>
                    <Form.Item
                        required={false}
                        key={k}
                    >
                        {getFieldValue(`names[${(k * 3) + 2}]`) && this.getFormElement(`names[${(k * 3) + 1}]`, `names[${(k * 3) + 3}]`, (k * 3) + 3, k, `names[${(k * 3) + 2}]`, currentReportData)}
                    </Form.Item>

                </Col>
                <Col xl={1} xs={24} style={{ marginTop: 10 }}>
                    {!isGeneralReport && <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.remove(k)}
                    />}
                </Col>

            </Row>
        )) : <>
            <Row gutter={24}>
                <Col xl={6} xs={24}>
                    <Form.Item>
                        {getFieldDecorator(`names[0]`, {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                },
                            ],
                            initialValue: "Date Range"
                        })
                            (<Input readonly="readonly" />)}
                    </Form.Item>
                </Col>
                <Col xl={10} xs={32}>
                    <Form.Item>
                        {getFieldDecorator('startEndTime', {
                            initialValue: currentReportData?.filterSortObject?.filter?.startTime ? [moment.tz(currentReportData?.filterSortObject?.filter?.startTime.oprVal, this.props.timeZoneName), moment.tz(currentReportData?.filterSortObject?.filter?.endTime.oprVal, this.props.timeZoneName)] : []
                        })(
                            <RangePicker
                                className="full-width"
                                ranges={this.ranges}
                                getPopupContainer={() => document.getElementById('refineReportForm')}
                            />,
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </>

        return (
            <Form id="refineReportForm">
                <Spin spinning={this.state.loading}>
                    {formItems}
                    {!isGeneralReport && <Form.Item {...formItemLayoutWithOutLabel}>
                        {userRun !== "fromUser" && <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                            <Icon type="plus" /> Add Filter
                        </Button>}
                    </Form.Item>}
                </Spin>
            </Form >
        );
    }
}
const mapStateToProps = ({ currentReport, user, userReports }, props) => {
    const acutalReport = props?.userRun === "fromUser" ? userReports?.details : currentReport?.reportDetails;
    return {
        currentReport: acutalReport,
        timeZoneName: user.profile ? user.profile.timezone : undefined,
        isRunByUser: props?.userRun === "fromUser"
    };
}
const RefineReportForm = Form.create({ name: 'dynamic_form_item' })(RefineReport);
export default connect(mapStateToProps, { fetchReportColumns, toggleIsFetchingReportForm })(RefineReportForm)
