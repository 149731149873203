import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Select, Form, Tabs, Button, Icon, Modal } from "antd";
import pushMessage from '../../components/common/PushMessage';

import CONFIG from '../../config';
import { testConnection } from '../../actions/demos'

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

// eslint-disable-next-line
let createParamId = 0, createHeaderId = 0, deleteParamId = 0, deleteHeaderId = 0;

let createParamKey = [], deleteParamKey = [], createHeaderKey = [], deleteHeaderKey = [];

// eslint-disable-next-line
let createParamValue = [], deleteParamValue = [], createHeaderValue = [], deleteHeaderValue = [];

export default function RestfulAPIDetails({ form, requestType, demoBasicDetails, isEditMode, createStatus, deleteStatus, setStatus }) {

    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const { externalApis } = demoBasicDetails || [];
    const [submitting, setSubmitting] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [createErrorText, setCreateErrorText] = useState("");
    const [deleteErrorText, setDeleteErrorText] = useState("");

    const handleCancel = () => { setIsVisible(false) }

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const handleTestBtnClick = async () => {
        const createRequest = requestType === "create"
        const formValues = form.getFieldsValue();
        const requestData = {
            url: createRequest ? formValues.createURL : formValues.deleteURL,
            bodyJson: createRequest ? isJsonString(formValues.createBodyJson) ? JSON.parse(formValues.createBodyJson) : (formValues.createBodyJson === "" || formValues.createBodyJson === null) ? {} : formValues.createBodyJson : isJsonString(formValues.deleteBodyJson) ? JSON.parse(formValues.deleteBodyJson) : (formValues.deleteBodyJson === "" || formValues.deleteBodyJson === null) ? {} : formValues.deleteBodyJson,
            methodType: createRequest ? formValues.createMethodType : formValues.deleteMethodType,
            // eslint-disable-next-line
            headerJson: createRequest ? formValues.createHeaderKey?.length !== 0 ? formValues.createHeaderKey?.reduce((acc, curr, index) => (acc[curr] = formValues.createHeaderValue[index], acc), {}) : undefined : formValues.deleteHeaderKey?.length !== 0 ? formValues.deleteHeaderKey?.reduce((acc, curr, index) => (acc[curr] = formValues.deleteHeaderValue[index], acc), {}) : undefined,
            // eslint-disable-next-line
            paramsJson: createRequest ? formValues.createParamKey?.length !== 0 ? formValues.createParamKey?.reduce((acc, curr, index) => (acc[curr] = formValues.createParamValue[index], acc), {}) : undefined : formValues.deleteParamKey?.length !== 0 ? formValues.deleteParamKey?.reduce((acc, curr, index) => (acc[curr] = formValues.deleteParamValue[index], acc), {}) : undefined,
            authorizatonJson: createRequest ? { userName: formValues.createAuthUserName, password: formValues.createAuthPassword } : { userName: formValues.deleteAuthUserName, password: formValues.deleteAuthPassword }
        }
        setSubmitting(true)
        const type = createRequest ? "createStatus" : "deleteStatus";
        const setErrorText = createRequest ? setCreateErrorText : setDeleteErrorText;
        testConnection(requestData)
            .then(response => {
                setSubmitting(false)
                setStatus(type, response.status === CONFIG.HTTP_STATUS.OK ? true : false)
                if (response.status === CONFIG.HTTP_STATUS.OK) setErrorText("")
                else setErrorText(response.data)
            })
            .catch(() => {
                setSubmitting(false)
                setStatus(type, false)
            })
    }

    const handleChange = () => {
        const type = requestType === "create" ? "createStatus" : "deleteStatus";
        setStatus(type)
    }


    useEffect(() => {
        if (isEditMode) {
            createParamId = createParamKey?.length;
            createHeaderId = deleteParamKey?.length;
            deleteParamId = createHeaderKey?.length;
            deleteHeaderId = deleteHeaderKey?.length;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isEditMode) {
        const createParamsJson = externalApis?.[0]?.paramsJson ? JSON.parse(externalApis?.[0]?.paramsJson) : {};
        const deleteParamsJson = externalApis?.[1]?.paramsJson ? JSON.parse(externalApis?.[1]?.paramsJson) : {};
        const createHeaderJson = externalApis?.[0]?.headerJson ? JSON.parse(externalApis?.[0]?.headerJson) : {};
        const deleteHeaderJson = externalApis?.[1]?.headerJson ? JSON.parse(externalApis?.[1]?.headerJson) : {};

        createParamKey = Object.keys(createParamsJson);
        deleteParamKey = Object.keys(deleteParamsJson);
        createHeaderKey = Object.keys(createHeaderJson);
        deleteHeaderKey = Object.keys(deleteHeaderJson);

        createParamValue = Object.values(createParamsJson);
        deleteParamValue = Object.values(deleteParamsJson);
        createHeaderValue = Object.values(createHeaderJson);
        deleteHeaderValue = Object.values(deleteHeaderJson);
    }

    if (requestType === "create") {
        getFieldDecorator('createParamKeys', { initialValue: createParamKey?.length !== 0 ? [...Array(createParamKey.length).keys()] : [] });
        getFieldDecorator('createHeaderKeys', { initialValue: createHeaderKey?.length !== 0 ? [...Array(createHeaderKey.length).keys()] : [] });
    }
    else {
        getFieldDecorator('deleteParamKeys', { initialValue: deleteParamKey?.length !== 0 ? [...Array(deleteParamKey.length).keys()] : [] });
        getFieldDecorator('deleteHeaderKeys', { initialValue: deleteHeaderKey?.length !== 0 ? [...Array(deleteHeaderKey.length).keys()] : [] });
    }

    const prefixSelector = <div className="methodType">{getFieldDecorator(requestType === "create" ? 'createMethodType' : 'deleteMethodType', {
        initialValue: requestType === "create" ? externalApis?.[0]?.methodType : externalApis?.[1]?.methodType
    })(
        <Select onChange={handleChange}>
            {CONFIG.methodTypes.map(item => <Option value={item}>{item}</Option>)}
        </Select>,
    )}</div>;

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 18, offset: 4 },
        },
    };

    const paramKeys = requestType === "create" ? getFieldValue('createParamKeys') : getFieldValue('deleteParamKeys');
    const headerKeys = requestType === "create" ? getFieldValue('createHeaderKeys') : getFieldValue('deleteHeaderKeys');
    const status = requestType === 'create' ? createStatus : deleteStatus;
    const errorText = requestType === 'create' ? createErrorText : deleteErrorText;

    const remove = (k, type) => {
        const key = requestType === "create" ? `create${type}Keys` : `delete${type}Keys`
        const keys = getFieldValue(key);
        setFieldsValue({
            [key]: keys.filter(key => key !== k),
        });
    };

    const add = (type) => {
        const key = requestType === "create" ? `create${type}Keys` : `delete${type}Keys`
        const keys = getFieldValue(key);
        // eslint-disable-next-line
        const nextKeys = keys.concat(requestType === "create" ? eval(`create${type}Id++`) : eval(`delete${type}Id++`));
        setFieldsValue({
            [key]: nextKeys,
        });
    };

    const handleBeautify = () => {
        const createRequest = requestType === "create"
        const formValues = form.getFieldsValue();
        var ugly = createRequest ? formValues.createBodyJson : formValues.deleteBodyJson
        var isJSON = isJsonString(ugly);
        if (isJSON) {
            var obj = JSON.parse(ugly);
            var pretty = JSON.stringify(obj, undefined, 6);
            form.setFieldsValue(createRequest ? { createBodyJson: pretty } : { deleteBodyJson: pretty })
        }
        else pushMessage(CONFIG.messageType.warning, "Please enter valid JSON to format")
    }

    const renderFormField = (key, type) => {
        return <Row gutter={24} className="bottom-spacing-6">
            <Col xl={6} xs={24}>
                <Form.Item>
                    {
                        getFieldDecorator(requestType === "create" ? `create${type}Key[${key}]` : `delete${type}Key[${key}]`, {
                            // eslint-disable-next-line
                            initialValue: requestType === "create" ? eval(`create${type}Key[${key}]`) : eval(`delete${type}Key[${key}]`),
                            rules: [
                                {
                                    required: true,
                                    message: "Please enter a key",
                                },
                            ],
                        })(<TextArea rows={1} placeholder="Key" onChange={handleChange} />)
                    }
                </Form.Item>
            </Col>
            <Col xl={6} xs={24}>
                <Form.Item>
                    {
                        getFieldDecorator(requestType === "create" ? `create${type}Value[${key}]` : `delete${type}Value[${key}]`, {
                            // eslint-disable-next-line
                            initialValue: requestType === "create" ? eval(`create${type}Value[${key}]`) : eval(`delete${type}Value[${key}]`),
                            rules: [
                                {
                                    required: true,
                                    message: "Please enter a value",
                                },
                            ],
                        })(<TextArea rows={1} placeholder="Value" onChange={handleChange} />)
                    }
                </Form.Item>
            </Col>
            <Col xl={1} xs={24} className="top-spacing-7" >
                <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    onClick={() => remove(key, type)}
                />
            </Col>
        </Row>
    }
    return (
        <>
            <Row>
                <Form.Item label="URL">
                    {getFieldDecorator(requestType === "create" ? 'createURL' : 'deleteURL', {
                        initialValue: requestType === "create" ? externalApis?.[0]?.url : externalApis?.[1]?.url
                    })(<Input addonBefore={prefixSelector} className="fullWidth" onChange={handleChange} />)}
                </Form.Item>
            </Row>
            <Tabs defaultActiveKey={"parameters"} >
                <TabPane tab="Parameters" key="parameters">
                    {paramKeys.map((key) => {
                        return renderFormField(key, 'Param')
                    })}
                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button aria-label="Click" type="dashed" onClick={() => add('Param')} style={{ width: '20%' }}>
                            <Icon type="plus" /> Add Parameter
                        </Button>
                    </Form.Item>
                </TabPane>
                <TabPane tab="Authorization" key="authorization">
                    <Row gutter={24}>
                        <Col xl={6} xs={24}>
                            <Form.Item label="Username">
                                {
                                    getFieldDecorator(requestType === "create" ? 'createAuthUserName' : 'deleteAuthUserName', {
                                        initialValue: requestType === "create" ? externalApis?.[0]?.authorisationJson ? JSON.parse(externalApis?.[0]?.authorisationJson).userName : "" : externalApis?.[1]?.authorisationJson ? JSON.parse(externalApis?.[1]?.authorisationJson).userName : ""
                                    })(<Input onChange={handleChange} />)
                                }
                            </Form.Item>
                        </Col>
                        <Col xl={6} xs={24}>
                            <Form.Item label="Password">
                                {
                                    getFieldDecorator(requestType === "create" ? 'createAuthPassword' : 'deleteAuthPassword', {
                                        initialValue: requestType === "create" ? externalApis?.[0]?.authorisationJson ? JSON.parse(externalApis?.[0]?.authorisationJson).password : "" : externalApis?.[1]?.authorisationJson ? JSON.parse(externalApis?.[1]?.authorisationJson).password : ""
                                    })(<Input.Password onChange={handleChange} />)
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab="Headers" key="headers">
                    {headerKeys.map((key) => {
                        return renderFormField(key, 'Header')
                    })}
                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button aria-label="Click" type="dashed" onClick={() => add('Header')} style={{ width: '20%' }}>
                            <Icon type="plus" /> Add Header
                        </Button>
                    </Form.Item>
                </TabPane>
                <TabPane tab="Body" key="body">
                    <Form.Item>
                        {
                            getFieldDecorator(requestType === "create" ? 'createBodyJson' : 'deleteBodyJson', {
                                initialValue: requestType === "create" ? externalApis?.[0]?.bodyJson : externalApis?.[1]?.bodyJson
                            })(<TextArea id="bodyJSON" rows={8} onChange={handleChange} />)
                        }
                        <span className="beautify" onClick={() => handleBeautify()}> Format JSON</span>
                    </Form.Item>
                </TabPane>
            </Tabs>
            <div className='bottom-spacing-6'>
                <Button aria-label="Click" className='primary-action-button-filled right-spacing-5' loading={submitting} onClick={handleTestBtnClick}>
                    Test Connection
                </Button>
                <span className="bold-text">Status :</span>  {status ? <span className="success-text"> Connection Successful</span> : status === false ? <span className="error-text">Connection Failed</span> : ""} {errorText !== "" && <span className="left-spacing-5 bold-text">View Log &nbsp; <Button aria-label="Click" className="primary-action-button-bordered" onClick={() => setIsVisible(true)} icon="arrows-alt" size="small" /></span>}
            </div>
            <Modal
                title="Error Log"
                visible={isVisible}
                onCancel={handleCancel}
                footer={null}
                width="80%"
            >
                <div className="responsive-container" >
                    {JSON.stringify(errorText)}
                </div>
            </Modal >
        </>
    )
}