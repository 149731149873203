import CONFIG from '../../config.js';
import {
    CLEAR_ALL_PRODUCTS,
    CLEAR_AUTO_BUILDS_SCHEDULES,
    CLEAR_CUSTOM_EMAIL_TEMPLATES,
    CLEAR_DAILY_ATTRIBUTE,
    CLEAR_DAILY_REPORT,
    CLEAR_DATA_CENTERS,
    CLEAR_DEMOS,
    CLEAR_DEMO_APPROVALS,
    CLEAR_DEMO_DC_STATUS,
    CLEAR_DEMO_PURPOSE,
    CLEAR_DEMO_QUESTIONS,
    CLEAR_DEMO_REQUEST_DATA,
    CLEAR_DIDS,
    CLEAR_FEEDBACK_QUESTIONS,
    CLEAR_LOCAL_USERS,
    CLEAR_MAINTENANCE_SCHEDULES,
    CLEAR_MANAGE_DEMO_SCHEDULES,
    CLEAR_MANUAL_BUILDS_SCHEDULES,
    CLEAR_NETWORKS,
    CLEAR_NEWS,
    CLEAR_NEWS_FORM_DATA,
    CLEAR_NOTIFICATIONS,
    CLEAR_REPORT,
    CLEAR_RESOURCES,
    CLEAR_ROLE_TEMPLATES,
    CLEAR_SOLUTIONS,
    CLEAR_SOLUTION_TYPE,
    CLEAR_STANDARD_EMAIL_TEMPLATES,
    CLEAR_SUPPORT,
    CLEAR_TIPS,
    CLEAR_USERS,
    CLEAR_NOTIFICATION_CONTENT,
    CLEAR_DEMO_QUESTIONS_FORM,
    CLEAR_FEEDBACK_QUESTIONS_FORM,
    CLEAR_MY_DEMO_SCHEDULES
}
    from '../../actions/types';
import ROUTE from "../ContentRouteConfig"

export const isTabAlreadyOpen = ({ openedTabs, currentComponentPath }) => {
    return openedTabs?.find(pane => pane.key === currentComponentPath) ? true : false;
};

export const hasMaxTabExceeded = (openedTabs) => {
    return openedTabs?.length >= CONFIG.maximumTabLimit;
};

export const isTabRestoredFromLocalStorage = ({ openedTabs, currentComponentPath }) => {
    return openedTabs?.find(pane => pane.key === currentComponentPath && pane.fromLocalStorage) ? true : false;
};

export const fetchComponentPathFromTabs = ({ openedTabs, currentComponentPath }) => {
    return openedTabs?.find(pane => pane.key === currentComponentPath)?.key;
};


export const shouldShowWarning = (activeKey, isChangeTab) => {
    let flag = true;

    // Mention the URLs of components page for which we retain data on tab change.
    if (isChangeTab) {
        flag = checkChangesForDataRetain(activeKey)
    }
    else {
        flag = checkChangesForInlineData(activeKey);
    }
    return flag;
}

export const findCurrentTabData = (componentTabArray, componentTabId, isDemoClone) => {
    return componentTabArray?.find(item => (parseInt(item?.demoId) === parseInt(componentTabId) && item?.isDemoClone === isDemoClone));
};

export const getClearComponentType = (keyURL) => {
    const pathName = {
        [ROUTE.scheduleApproval]: CLEAR_DEMO_APPROVALS,
        [ROUTE.roleTemplates]: CLEAR_ROLE_TEMPLATES,
        [ROUTE.ssoUsers]: CLEAR_USERS,
        [ROUTE.nonSsoUsers]: CLEAR_LOCAL_USERS,
        [ROUTE.demos]: CLEAR_DEMOS,
        [ROUTE.scheduleDemos]: CLEAR_MANAGE_DEMO_SCHEDULES,
        [ROUTE.resources]: CLEAR_RESOURCES,
        [ROUTE.solutions]: CLEAR_SOLUTIONS,
        [ROUTE.solutionTypes]: CLEAR_SOLUTION_TYPE,
        [ROUTE.automaticBuilds]: CLEAR_AUTO_BUILDS_SCHEDULES,
        [ROUTE.manualBuilds]: CLEAR_MANUAL_BUILDS_SCHEDULES,
        [ROUTE.purpose]: CLEAR_DEMO_PURPOSE,
        [ROUTE.demoStatus]: CLEAR_DEMO_DC_STATUS,
        [ROUTE.feedbackQuestions]: CLEAR_FEEDBACK_QUESTIONS,
        [ROUTE.demoQuestions]: CLEAR_DEMO_QUESTIONS,
        [ROUTE.demoRequests]: CLEAR_DEMO_REQUEST_DATA,
        [ROUTE.news]: CLEAR_NEWS,
        [ROUTE.notification]: CLEAR_NOTIFICATIONS,
        [ROUTE.support]: CLEAR_SUPPORT,
        [ROUTE.standardEmail]: CLEAR_STANDARD_EMAIL_TEMPLATES,
        [ROUTE.customEmail]: CLEAR_CUSTOM_EMAIL_TEMPLATES,
        [ROUTE.maintenance]: CLEAR_MAINTENANCE_SCHEDULES,
        [ROUTE.dataCenter]: CLEAR_DATA_CENTERS,
        [ROUTE.dids]: CLEAR_DIDS,
        [ROUTE.networks]: CLEAR_NETWORKS,
        [ROUTE.tips]: CLEAR_TIPS,
        [ROUTE.customReports]: CLEAR_REPORT,
        [ROUTE.standardReports]: CLEAR_REPORT,
        [ROUTE.products]: CLEAR_ALL_PRODUCTS,
        [ROUTE.attributes]: CLEAR_DAILY_ATTRIBUTE,
        [ROUTE.dailyReport]: CLEAR_DAILY_REPORT,
        [ROUTE.mySchedules]:CLEAR_MY_DEMO_SCHEDULES
    }
    return pathName[keyURL]
};

export const getClearFormType = (component) => {
    const pathName = {
        [CONFIG.createComponentRoute.news.title]: CLEAR_NEWS_FORM_DATA,
        [CONFIG.createComponentRoute.notifications.title]: CLEAR_NOTIFICATION_CONTENT,
        [CONFIG.createComponentRoute.demoQuestions.title]: CLEAR_DEMO_QUESTIONS_FORM,
        [CONFIG.createComponentRoute.feedbackQuestions.title]: CLEAR_FEEDBACK_QUESTIONS_FORM,
    }
    return pathName[component]
};

const checkChangesForDataRetain = (activeKey) => {
    let hasChanged = true;
    if (activeKey?.includes("manage/demo-resources")
        || activeKey?.includes("emailTemplates/standard/")
        || activeKey?.includes("emailTemplates/custom/")
        || activeKey?.includes("/manage/notifications/")
        || activeKey?.includes("/manage/demo-question/")
        || activeKey?.includes("/manage/news/")
        || activeKey?.includes("/manage/feedback-question/")
        || activeKey?.includes("/manage/reports/")
        || activeKey?.includes("/manage/demos")) {
        hasChanged = false;
    }
    return hasChanged;
}

const checkChangesForInlineData = (activeKey) => {
    let hasChanged = true;
    if (!activeKey?.includes("edit")
        && !activeKey?.includes("clone")
        && !activeKey?.includes("create")
        // Mention the URLs of components page which has inline edits
        && !activeKey?.includes("manage/builds/manual")
        && !activeKey?.includes("manage/builds")
        && !activeKey?.includes("manage/demo-status")
        && !activeKey?.includes("manage/demo-requests")
        && !activeKey?.includes("manage/dataCenters")
        && !activeKey?.includes("manage/users/sso")
        && !activeKey?.includes("/manage/daily-report/products")) {
        hasChanged = false;
    }
    return hasChanged;
}

export const checkWarningForRoute = (activeKey) => {
    let hasChanged = true;
    hasChanged = checkChangesForDataRetain(activeKey);
    if (!hasChanged) return hasChanged;
    hasChanged = checkChangesForInlineData(activeKey);
    if (!hasChanged) return hasChanged;
    return hasChanged;
}