import React from "react";
import { Icon, Row, Col, Select, Divider, Form, Modal, Spin, List } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import { toggleMenuButton, postSearchResult, addNewTab, openLinkInNewTab, selectTitle } from "../actions";
import history from '../history';
import CONFIG from '../config';
import ROUTE from '../components/ContentRouteConfig';
import { fetchSearchResults } from '../actions/header';
import HTML from './common/HTML';
import moment from 'moment-timezone';
import LocalUserLoginForm from './LoginForm';
import { LoadingOutlined } from '@ant-design/icons';
import file_search_empty from "../assets/images/file_search_empty.png";
import { Tag } from 'antd5'


const { Option } = Select;
const { CheckableTag } = Tag;


class GetStarted extends React.Component {
    state = {
        isClosed: true,
        modalTitle: "Login",
        searchString: "",
        selectedTags: ["Demo", "Solution"],
        searchData: {},
        updatedSearchData: [],
        selectedSearchResult: undefined,
        loading: false,
        myProfileVisible: false
    }

    getPath = (item, key, searchString) => {
        switch (key) {
            case "Demo":
                return CONFIG.searchRoutes[key].replace("activeTab", item.match) + item.id
            case "Report":
                return item.parentReport !== null ? CONFIG.searchRoutes['MyReports'] + item.id : CONFIG.searchRoutes['StandardReports'] + item.id
            case "Support":
                return CONFIG.searchRoutes[key].replace("guideType", item.guideType) + item.id
            case "Solution":
                return CONFIG.searchRoutes[key].replace("solutionTypeId", item.solutionType?.id) + item.id
            default: return CONFIG.searchRoutes[key] + item.id
        }
    }
    handleSearch = async (searchString) => {

        //Check if there are any previous pending requests
        if (typeof this.cancelToken != typeof undefined) {
            this.cancelToken.cancel("Operation canceled due to new request.");
        }
        //Save the cancel token for the current request
        this.cancelToken = await axios.CancelToken.source();
        this.searchValue = searchString && searchString
        let { selectedTags } = this.state;
        selectedTags = selectedTags.length !== 0 ? selectedTags?.join(",") : undefined;
        this.setState({ loading: true })
        let searchData = searchString?.length > 2 ? await fetchSearchResults({ searchText: searchString, searchFilter: selectedTags, cancelToken: this.cancelToken }) : [];
        searchData = { ...searchData }
        const keys = Object.keys(searchData);
        let updatedSearchData = []
        keys.forEach(key => {
            return key !== "count" && searchData?.[key]?.map(item => (
                updatedSearchData.push({ ...item, type: key, path: this.getPath(item, key, searchString), tag: key, chipColor: CONFIG.searchTagsColor[key] })
            ))
        })
        await this.props.postSearchResult(updatedSearchData);
        this.setState({ updatedSearchData, searchString, searchData, count: searchData.count, loading: false });
    }

    selectedSearchFilter = (value) => {
        this.setState({
            searchFilter: value
        }, () => { this.handleSearch(this.searchValue) })
    }

    handleSelect = async (value) => {
        const { previousPath, form } = this.props;
        const { setFieldsValue } = form;
        const { pathname, pageValue, title } = this.props.location || [];
        const item = JSON.parse(value)
        const path = pageValue ? { pathname, pageValue, title } : pathname;
        const searchString = this.searchValue ? ` - ${this.searchValue}` : "";
        if (item.type === "Notification" || item.type === "Tip") this.setState({ isClosed: false, modalTitle: item.type, selectedSearchResult: item })
        else {
            const response = await this.props.addNewTab(`Search Results${searchString}`, item.path)
            response && history.replace({ pathname: item.path, previousPath: previousPath || path, universalSearchString: this.state.searchString });
        }
        await this.props.postSearchResult([]);
        this.setState({ updatedSearchData: [], searchString: "", searchData: {}, count: 0, });
        this.searchValue = "";
        setFieldsValue({ search: undefined })

    }
    componentDidMount() {
        this.props.selectTitle("Get Started");
    }

    renderModal = () => {
        const { selectedSearchResult, modalTitle, isClosed, searchString } = this.state;
        const searchTextRegEx = CONFIG.regEx.searchText.source.replace('searchText', searchString);
        const intro = selectedSearchResult?.intro && selectedSearchResult?.intro.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`);
        const description = selectedSearchResult?.description && selectedSearchResult?.description.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`)
        const body = selectedSearchResult?.body && selectedSearchResult?.body.replace(new RegExp(searchTextRegEx, "gi"), (match) => `<mark className="highlight-text">${match}</mark>`)

        return (
            <Modal
                title={modalTitle}
                visible={!isClosed}
                onCancel={this.handleCancel}
                footer={null}
                width={selectedSearchResult ? '60%' : this.props.viewport.isMobileView ? '100%' : '30%'}
            >
                {!selectedSearchResult && <LocalUserLoginForm history={this.props.history} loginComplete={this.loginComplete} setModalTitle={this.setModalTitle} />}
                {selectedSearchResult &&
                    <div>
                        <h2>{this.renderTitle(selectedSearchResult?.title)}</h2>
                        <HTML htmlContent={intro || description} />
                        <HTML htmlContent={body} />
                    </div>
                }
            </Modal>

        )
    }

    setModalTitle = (title) => {
        this.setState({
            modalTitle: title
        })
    }


    getTitle = () => {
        var currentHour = new Date(moment.tz(this.props.timeZoneName)).getHours();
        var greet;
        if (currentHour < 12)
            greet = <><span class="neo-icon-clear-day" style={{ fontSize: "25px", color: "orange" }}></span>{`Good Morning,  ${this.props.userprofile?.firstName} ${this.props.userprofile?.lastName}`}</>
        else if (currentHour >= 12 && currentHour < 17)
            greet = <><span class="neo-icon-cloudy-few" style={{ fontSize: "25px", color: "orange" }}></span> {`Good Afternoon,  ${this.props.userprofile?.firstName} ${this.props.userprofile?.lastName}`}</>
        else if (currentHour >= 17 && currentHour <= 24)
            greet = <><span class="neo-icon-clear-night" style={{ fontSize: "25px", color: "#05139a" }}></span>{`Good Evening,  ${this.props.userprofile?.firstName} ${this.props.userprofile?.lastName}`}</>
        return greet;
    };

    handleQuickLinkClick = (item) => {
        this.props.openLinkInNewTab(item.title, item.url)
    }

    handleTagSelect = async (tag, checked) => {
        if (checked) {
            if (tag === "All")
                await this.setState({ selectedTags: ["All", "Demo", "Solution", "News", "Notification", "Support", "EZ Demo", "Report"] })
            else
                await this.setState(prevState => ({ selectedTags: [...prevState.selectedTags, tag] }))
        }
        else {

            if (tag === "All")
                await this.setState({ selectedTags: [] })
            else
                await this.setState(prevState => ({ selectedTags: prevState.selectedTags.filter(eachTag => eachTag !== tag) }));
        }

        this.handleSearch(this.searchValue)
    }

    handleSearchClose = (open) => {
        if (!open) {
            this.setState({
                selectedTags: ["Demo", "Solution"],
            })
        }
    }


    render() {
        const { form, isFirstTimeLogin, isLoginComplete, searchResult, userRoleTemplate } = this.props
        const isFirstTimeSSOLoginPage = isLoginComplete && isFirstTimeLogin
        const { getFieldDecorator } = form;
        const tagsData = ["All", "Demo", "Solution", "News", "Notification", "Support", "EZ Demo", "Report"];
        const empty = <div className='search-no-results font'>
            <img className="search-image" src={file_search_empty} alt="No Results" />
            <div>{this.searchValue?.length > 0 ? <span>We couldn't find any results!<br /><span className="dashboard-card-details">You can try modifying the search/filter criteria to broaden your search</span></span> : "Type something to search"}
            </div>
        </div>
        const isAdmin = userRoleTemplate?.templateType === CONFIG.roleTypes.admin || userRoleTemplate?.templateType === CONFIG.roleTypes.superAdmin;
        const adminQuickLinks = [
            { title: 'Demo List', url: ROUTE.demos },
            { title: 'Resources', url: ROUTE.resources },
            { title: 'News', url: ROUTE.news },
            { title: 'Notifications', url: ROUTE.notification }
        ];
        const userQuickLinks = [
            { title: 'Getting Started', url: ROUTE.getStarted },
            { title: 'News', url: ROUTE.userNews },
            { title: 'Reports', url: ROUTE.userStandardReport },
            { title: 'EZ Demos', url: ROUTE.userEZDemos }
        ];
        return (
            <div className="content-cover">
                {!isFirstTimeSSOLoginPage && <div className="get-started">
                    <div className="get-started-greeting">{this.getTitle()}</div>
                    <Col span={18} offset={3}>
                        <Form>
                            <Row>
                                <Form.Item>
                                    {
                                        !isFirstTimeLogin && this.props.userprofile && getFieldDecorator('search')(
                                            <Select
                                                className='searchForm float-right right-spacing-7 header-search font'
                                                filterOption={false}
                                                placeholder={<span><span className="neo-gray-color">
                                                    <Icon type="search" />
                                                </span> <i style={{ fontSize: "13px" }}>Search</i></span>}
                                                showSearch={true}
                                                defaultActiveFirstOption={false}
                                                onSearch={this.handleSearch}
                                                onSelect={this.handleSelect}
                                                onDropdownVisibleChange={this.handleSearchClose}
                                                optionLabelProp="label"
                                                allowClear={true}
                                                showArrow={false}
                                                autoClearSearchValue={false}
                                                dropdownRender={
                                                    menu => (
                                                        <div className={searchResult?.length > 0 ? "heightSelect" : undefined} onMouseDown={e => e.preventDefault()}>
                                                            <div className="medium-text vertical-spacing bold left-spacing">
                                                                <span className="font" style={{ marginRight: 8, color: "black" }}>
                                                                    Categories:
                                                                </span>
                                                                {tagsData.map((tag) => (
                                                                    <CheckableTag
                                                                        className="font"
                                                                        key={tag}
                                                                        checked={this.state.selectedTags.includes(tag)}
                                                                        onChange={(checked) => this.handleTagSelect(tag, checked)}
                                                                    >
                                                                        {tag}
                                                                    </CheckableTag>
                                                                ))}
                                                                {/* > */}
                                                                <Divider className="no-top-margin-divider" />
                                                            </div>
                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.loading}>
                                                                {searchResult && searchResult?.length > 0 ? menu : empty}
                                                            </Spin>
                                                        </div>
                                                    )
                                                }
                                            >
                                                {
                                                    searchResult?.map((item, index) => (
                                                        <Option className="font" style={index === 0 ? { margin: "10px", color: "black", marginTop: '0px', } : { margin: '10px', color: "black" }} title={item.title || item.name} key={(item.id + item.match + index + Math.random())} value={JSON.stringify(item)} label={item.title || item.name}>
                                                            <Tag color={item?.chipColor}>{item?.tag}</Tag>
                                                            &nbsp;&nbsp;<span className="bold">{item.title || item.name}</span>
                                                            {item.match && <span className=" font-12 text-color-grey">&nbsp;&bull;&nbsp;<i className="font font-12">{CONFIG.demoSections[item?.match]}</i></span>}
                                                        </Option>
                                                    ))
                                                }
                                            </Select>
                                        )
                                    }
                                </Form.Item>
                            </Row>
                        </Form>
                        <div style={{ padding: '16px', marginTop: '30px' }}>
                            <h2 className="get-started-greeting">Quick Links:</h2>
                            <List
                                grid={{
                                    gutter: 8,
                                    xs: 1,
                                    sm: 1,
                                    md: 2,
                                    lg: 4,
                                    xl: 4,
                                    xxl: 4,
                                }}
                                dataSource={isAdmin ? adminQuickLinks : userQuickLinks}
                                renderItem={item => (
                                    <List.Item>
                                        <span className="supportLinks pointer" style={{ "color": "#05139a", "fontSize": "15px" }} onClick={() => this.handleQuickLinkClick(item)}>{isAdmin ? `Manage ` + item?.title : item?.title}</span>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Col>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => ({ header, viewport, user, tabsLayout }) => {
    return {
        showBackButton: header.showBackButton,
        isFirstTimeLogin: user.isFirstTimeLogin,
        previousPath: header.previousPath,
        selectedTitle: header.title,
        viewport: viewport,
        userprofile: user.profile,
        isLoginComplete: user.isLoginComplete,
        searchResult: header.searchResult,
        previousTabs: tabsLayout.previousTabs,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,

    };
};
const GetStartedForm = Form.create()(GetStarted);

export default connect(mapStateToProps, { addNewTab, openLinkInNewTab, toggleMenuButton, postSearchResult, selectTitle })(GetStartedForm);