import {
    TOGGLE_IS_FETCHING_USER_EZ_DEMOS,
    FETCH_USER_EZ_DEMOS,
    CLEAR_USER_EZ_DEMOS,
    FETCH_ALL_USER_EZ_DEMOS,
    CURRENT_USER_EZ_DEMO,
    CLEAR_EZ_DEMOS,
    TOGGLE_IS_FETCHING_EZ_DEMOS,
    FETCH_ALL_EZ_DEMOS,
    FETCH_ACTIVE_EZ_DEMOS,
    DELETE_EZ_DEMOS_DEMO,
    ADD_EZ_DEMOS_DEMO,
    CLEAR_EZ_DEMO_FORM,
    FETCH_ALL_SOLUTION_DEMOS,
    FETCH_EZ_DEMO_BY_ID,
    UPDATE_EZ_DEMOS_ROLE_TEMPLATE,
    SAVE_EZ_DEMOS_ROLE_TEMPLATES,
    CANCEL_EZ_DEMOS_ROLE_TEMPLATES,
    LAST_EZ_DEMO_FILTER,
    UPDATE_EZ_DEMO_CURRENT_PAGE,
    CLEAR_EZ_DEMOS_TABLE_DATA,
    FETCH_ALL_USERS_REVIEWS_EZ_DEMOS,
    TOGGLE_IS_FETCHING_EZ_DEMOS_FEEDBACK,
    FETCH_EZ_DEMO_RATING_SPLIT_UP,
    CLEAR_EZ_DEMO_REVIEWS,
    RE_ORDER_EZ_DEMOS_TABLE,
    TOGGLE_IS_EZ_DEMOS_REORDERED,
    FETCH_EZ_DEMO_REVIEW_BY_AUDIT_ID
} from "../actions/types";
import CONFIG from '../config';

const ezDemoState = {
    loading: false,
}

export const ezDemosReducer = (state = ezDemoState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_USER_EZ_DEMOS:
            return { ...state, loading: action.payload }
        case FETCH_USER_EZ_DEMOS:
            return { ...state, ezDemos: action.payload }
        case FETCH_ALL_USER_EZ_DEMOS:
            return { ...state, allEZDemos: action.payload }
        case CURRENT_USER_EZ_DEMO:
            return { ...state, currentEZDemo: action.payload }
        case CLEAR_USER_EZ_DEMOS:
            return ezDemoState;
        default:
            return state
    }
}

const manageEZDemoState = {
    loading: false,
    data: [],
    activeEZDemos: []
}

// Reducer for EZ Demo Manage Side
export const manageEZDemosReducer = (state = manageEZDemoState, action) => {
    switch (action.type) {
        case TOGGLE_IS_FETCHING_EZ_DEMOS:
            return { ...state, loading: action.payload }
        case FETCH_ALL_EZ_DEMOS:
            return { ...state, data: action.payload?.dataSet || [], count: action.payload?.count || 0, isEdit: false };
        case FETCH_ACTIVE_EZ_DEMOS:
            return { ...state, activeEZDemos: action.payload || [] }
        case RE_ORDER_EZ_DEMOS_TABLE:
            return { ...state, activeEZDemos: action.payload };
        case TOGGLE_IS_EZ_DEMOS_REORDERED:
            return { ...state, isReordered: action.payload };
        case CLEAR_EZ_DEMOS:
            return manageEZDemoState;
        case UPDATE_EZ_DEMO_CURRENT_PAGE:
            return { ...state, currentPageNumber: action.payload[0] || undefined, pageSize: action.payload[1] || undefined, ezDemoIndex: action.payload[2] || undefined, EZDemoIndex: action.payload[3] || undefined };
        case LAST_EZ_DEMO_FILTER:
            return { ...state, currentFilter: action.payload[0] || undefined, status: action.payload[1], isEdit: true };
        case CLEAR_EZ_DEMOS_TABLE_DATA:
            return { ...state, data: [] }
        default: return state
    }
}

const ezDemoFormState = {
    data: {},
    mappedDemos: [],
    solutionDemos: [],
    allRTAdminAccess: undefined,
    allRTUserAccess: undefined,
}

export const ezDemosFormReducer = (state = ezDemoFormState, action) => {
    switch (action.type) {
        case FETCH_EZ_DEMO_BY_ID:
            return { ...state, ...action.payload.ezDemo, ezDemoObj: action.payload.ezDemoObj, assignedRoleTemplates: action.payload?.ezDemo?.roleTemplates || [] }
        case FETCH_ALL_SOLUTION_DEMOS:
            return {
                ...state, solutionDemos: action.payload || []
            }
        case DELETE_EZ_DEMOS_DEMO:
            return {
                ...state,
                mappedDemos: state.mappedDemos.filter((demoObject) => ((demoObject?.id !== action.payload?.demoId) || ((demoObject?.id === action.payload?.demoId) && (demoObject?.isDemo !== action.payload?.isDemo)))),
            };
        case ADD_EZ_DEMOS_DEMO:
            return state.mappedDemos
                ? { ...state, mappedDemos: [...state.mappedDemos, action.payload] }
                : { ...state, mappedDemos: [action.payload] };
        case UPDATE_EZ_DEMOS_ROLE_TEMPLATE:
            return {
                ...state,
                roleTemplates: action.payload.updatedRoleTemplates,
                [action.payload.allAccessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                    ? action.payload.accessType
                    : CONFIG.roleTemplateAccess.none
            }
        case SAVE_EZ_DEMOS_ROLE_TEMPLATES:
            return { ...state, assignedRoleTemplates: state.roleTemplates };
        case CANCEL_EZ_DEMOS_ROLE_TEMPLATES:
            return { ...state, roleTemplates: state.assignedRoleTemplates || [], allRTAdminAccess: undefined, allRTUserAccess: undefined };
        case CLEAR_EZ_DEMO_FORM:
            return ezDemoFormState
        default: return state
    }
}

const ezDemoFeedbackState = {
    loading: false,
    ezDemoByAuditId: []
}

export const ezDemosFeedbackReducer = (state = ezDemoFeedbackState, action) => {
    switch (action.type) {
        case FETCH_ALL_USERS_REVIEWS_EZ_DEMOS:
            return { ...state, allReviews: action.payload || [] }
        case FETCH_EZ_DEMO_REVIEW_BY_AUDIT_ID:
            return { ...state, ezDemoByAuditId: action.payload || [] }
        case TOGGLE_IS_FETCHING_EZ_DEMOS_FEEDBACK:
            return { ...state, loading: action.payload }
        case FETCH_EZ_DEMO_RATING_SPLIT_UP:
            return { ...state, ratingSplitUp: action.payload || [] };
        case CLEAR_EZ_DEMO_REVIEWS:
            return ezDemoFeedbackState;
        default: return state;
    }
}