import {
    FETCH_DEMO_QUESTIONS,
    TOGGLE_IS_FETCHING_DEMO_QUESTIONS,
    SAVE_DEMO_QUESTIONS_ROLE_TEMPLATES,
    UPDATE_DEMO_QUESTIONS_ROLE_TEMPLATE,
    GET_SELECTED_DEMO_QUESTION,
    CLEAR_DEMO_QUESTIONS_FORM,
    CANCEL_DEMO_QUESTIONS_ROLE_TEMPLATES,
    CLEAR_DEMO_QUESTIONS,
    LAST_DEMO_QUESTIONS_FILTER,
    FETCH_DEMO_QUESTION_DETAILS,
    CLEAR_DEMO_QUESTION_RESPONSE,
    LAST_DEMO_QUESTION_FILTER,
    CLEAR_DEMO_QUESTIONS_TABLE_DATA,
    UPDATE_DEMO_QUESTIONS_FORM_TAB_DATA,
    FETCH_ALL_DEMO_QUESTION,
} from "../actions/types";

import CONFIG from "../config";

const demoQuestionsState = {
    isFetching: false,
    questions: [],
    currentQuestionId: undefined,
    currentPageNumber: undefined,
};

const demoQuestionsFormState = {
    allRoleTemplatesUserAccess: undefined,
    allRoleTemplatesAdminAccess: undefined,
    tabData: []
};

export const demoQuestionsReducer = (state = demoQuestionsState, action) => {
    switch (action.type) {
        case FETCH_DEMO_QUESTIONS:
            return { ...state, questions: action.payload || [] };
        case TOGGLE_IS_FETCHING_DEMO_QUESTIONS:
            return { ...state, isFetching: action.payload };
        case LAST_DEMO_QUESTIONS_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true, currentPageNumber: action.payload[2] }
        case LAST_DEMO_QUESTION_FILTER:
            return { ...state, currentFilter: action.payload[0], status: action.payload[1], isEdit: true }
        case CLEAR_DEMO_QUESTION_RESPONSE:
            return { ...state, response: action.payload };
        case CLEAR_DEMO_QUESTIONS:
            return demoQuestionsState;
        case CLEAR_DEMO_QUESTIONS_TABLE_DATA:
            return { ...state, questions: [] };
        case FETCH_ALL_DEMO_QUESTION:
            return { ...state, allQuestions: action.payload || [] };
        default:
            return state;
    }
};

export const demoQuestionsFormReducer = (state = demoQuestionsFormState, action) => {
    switch (action.type) {
        case FETCH_DEMO_QUESTION_DETAILS:
            return {
                ...state,
                ...action.payload,
                assignedRoleTemplates: action.payload.roleTemplates || [],
                demoQuestionObj: action.payload
            };

        case UPDATE_DEMO_QUESTIONS_FORM_TAB_DATA:
            return {
                ...state,
                tabData: state.tabData.filter(obj => ((parseInt(obj?.id) !== parseInt(action.payload?.id)))).concat(action.payload)
            }
        case SAVE_DEMO_QUESTIONS_ROLE_TEMPLATES:
            const updatedRTSaveData = state.tabData?.map(el => ((parseInt(el?.id) === parseInt(action.payload?.id)))
                ? {
                    ...el, data: {
                        ...el.data,
                        assignedRoleTemplates: el.data.roleTemplates
                    }
                } : el)
            return { ...state, tabData: updatedRTSaveData };
        case TOGGLE_IS_FETCHING_DEMO_QUESTIONS:
            return { ...state, isFetching: action.payload };
        case UPDATE_DEMO_QUESTIONS_ROLE_TEMPLATE:
            const updatedData = state.tabData?.map(el => ((parseInt(el?.id) === parseInt(action.payload?.questionId)))
                ? {
                    ...el,
                    data: {
                        ...el.data,
                        roleTemplates: action.payload.updatedRoleTemplates,
                        [action.payload.accessKey]: !action.payload.selectedRoleTemplate && action.payload.access
                            ? action.payload.accessType
                            : CONFIG.roleTemplateAccess.none
                    }
                }
                : el)
            return {
                ...state, tabData: updatedData,
            }
        case GET_SELECTED_DEMO_QUESTION:
            return {
                ...state,
                ...action.payload,
                assignedRoleTemplates: action.payload.roleTemplates || [],
            };
        case CLEAR_DEMO_QUESTIONS_FORM:
            const updatedTabQuestionsData = state.tabData?.filter(el => ((parseInt(el?.id) !== parseInt(action.payload?.id))));
            return { ...state, tabData: updatedTabQuestionsData };
        case CANCEL_DEMO_QUESTIONS_ROLE_TEMPLATES:
            const updatedRTCancelData = state.tabData?.map(el =>
                ((parseInt(el?.id) === parseInt(action.payload?.id)))
                    ? {
                        ...el, data: {
                            ...el.data,
                            roleTemplates: el.data.assignedRoleTemplates,
                            allRoleTemplatesUserAccess: undefined,
                            allRoleTemplatesAdminAccess: undefined
                        }
                    } : el)
            return { ...state, tabData: updatedRTCancelData };
        default:
            return state;
    }
};
