import cookie from 'react-cookies';
import CONFIG from '../config';

export const removeAllCookies = (exceptionCookies) => {
    exceptionCookies = exceptionCookies ? [...exceptionCookies, ...CONFIG.exceptionCookies] : CONFIG.exceptionCookies;
    const allCookies = cookie.loadAll();
    Object.keys(allCookies).forEach(cookieName => {
        if (exceptionCookies && exceptionCookies.includes(cookieName)) return;
        cookie.remove(cookieName, CONFIG.cookieParams.path);
    });
    localStorage.setItem('isNonSSOLogin', false)
}

export const getUserFromCookies = () => {
    return {
        email: cookie.load('email'),
        firstName: cookie.load('firstName'),
        lastName: cookie.load('lastName'),
        companyName: cookie.load('company'),
        contactNumber: cookie.load('phoneNumber'),
        country: cookie.load('country'),
        sslHandle: cookie.load('userId'),
        linkId: cookie.load('linkId'),
        dn: cookie.load('dn')
    };
}