import { batch } from 'react-redux';
import CONFIG from '../config';
import { aepSchedule } from "../apis";
import pushMessage from '../components/common/PushMessage';
import { closeCurrentOpenTab } from './tabsLayout';
import { getUpdatedRoleTemplateAssociations } from './roleTemplates';

import {
    FETCH_ALL_PURPOSE,
    TOGGLE_IS_FETCHING_PURPOSE,
    REORDER_DEMO_PURPOSE,
    TOGGLE_IS_DEMO_PURPOSE_REORDERED,
    LAST_DEMO_PURPOSE_FILTER,
    CLEAR_DEMO_PURPOSE,
    TOGGLE_IS_FETCHING_DEMO_PURPOSE_FORM,
    FETCH_DEMO_PURPOSE_BY_ID,
    UPDATE_DEMO_PURPOSE_ROLE_TEMPLATE,
    CLEAR_DEMO_PURPOSE_FORM,
    REFRESH_DEMO_PURPOSE,
    TOGGLE_HAS_DEMO_PURPOSE_UPDATED
} from './types';

export const fetchAllPurposes = (filterSort, isActive) => async (dispatch) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/demoPurposes",
        {
            params: {
                filterSort: {
                    ...filterSort,
                    filter: {
                        ...filterSort?.filter,
                        isActive
                    }
                }
            }
        });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ALL_PURPOSE, payload: response.data ? response.data : [] });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Purpose');
    dispatch(toggleIsFetchingPurposeFlag(false));
    return response.data ? response.data.dataSet : [];
}

export const fetchDemoPurposeById = (demoPurposeId) => async (dispatch, getState) => {
    const { user, roleTemplates } = getState();
    const allRoleTemplates = roleTemplates.all;
    const userRoleTemplate = user.profile ? user.profile.roleTemplate : undefined
    const isAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.admin;
    const response = await aepSchedule.get("/scheduleMaintenanceService/demoPurposes/" + demoPurposeId);
    const purposeData = {
        ...response.data,
        roleTemplates: isAdmin ? response.data.roleTemplates.filter(purposeRoleTemplate => allRoleTemplates.some(roleTemplate => purposeRoleTemplate.roleTemplate.id === roleTemplate.id)) : response.data.roleTemplates
    }
    const demoPurpose = response.data;
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_DEMO_PURPOSE_BY_ID, payload: purposeData ? purposeData : [], demoPurpose });
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Purpose');
    setTimeout(() => {
        dispatch(toggleIsFetchingDemoPurposeFormFlag(false));
    }, 1000);
}

export const updateDemoPurposeRoleTemplate = (access, accessType, roleType, selectedRoleTemplate) => (dispatch, getState) => {
    const allRoleTemplates = getState().roleTemplates.all;
    const assignedRoleTemplates = getState().demoPurposeForm.roleTemplates;
    const allAccessKey = roleType === CONFIG.roleTypes.user ? 'allRTUserAccess' : 'allRTAdminAccess';
    const updatedRoleTemplates = getUpdatedRoleTemplateAssociations(access, roleType, accessType, selectedRoleTemplate, assignedRoleTemplates, 'roleTemplate', allRoleTemplates, false);
    dispatch({
        type: UPDATE_DEMO_PURPOSE_ROLE_TEMPLATE,
        payload: { updatedRoleTemplates, selectedRoleTemplate, accessType, access, allAccessKey }
    });
}

export const toggleIsFetchingPurposeFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_PURPOSE,
        payload: flag
    }
}

export const toggleIsFetchingDemoPurposeFormFlag = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_DEMO_PURPOSE_FORM,
        payload: flag
    }
}

export const reorderDemoPurposes = (reorderDemoPurposes) => (dispatch) => {
    batch(() => {
        dispatch({
            type: REORDER_DEMO_PURPOSE,
            payload: [...reorderDemoPurposes],
        });
        dispatch(toggleIsDemoPurposesReordered(true));
    });
};


export const toggleIsDemoPurposesReordered = (flag) => {
    return {
        type: TOGGLE_IS_DEMO_PURPOSE_REORDERED,
        payload: flag,
    };
};

export const deletePurpose = (purposeId, hardDelete = false) => async (dispatch) => {
    const response = await aepSchedule.delete("/scheduleMaintenanceService/demoPurposes/" + purposeId, { params: { hardDelete } });
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Demo purpose deleted successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to delete Demo purpose");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false;
};

export const restorePurpose = (purposeId) => async (dispatch) => {
    const response = await aepSchedule.patch("/scheduleMaintenanceService/restore/demoPurposes/" + purposeId);
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(CONFIG.messageType.success, "Demo purpose restored successfully")
        : pushMessage(CONFIG.messageType.error, "Unable to restore demo purpose");
    return response.status === CONFIG.HTTP_STATUS.OK ? true : false
}

export const putDemoPurposesOrder = (solutionTypes) => async (dispatch) => {
    const response = await aepSchedule.put(
        `/scheduleMaintenanceService/demoPurposes/updateSortOrder`,
        solutionTypes
    );
    response.status === CONFIG.HTTP_STATUS.OK
        ? pushMessage(
            CONFIG.messageType.success,
            "Demo Purpose order updated successfully"
        )
        : pushMessage(
            CONFIG.messageType.error,
            "Unable to update Demo Purpose order"
        );
    dispatch(toggleIsDemoPurposesReordered(false));
};

export const currentDemoPurposeFilter = (filterSort, status) => {
    return {
        type: LAST_DEMO_PURPOSE_FILTER,
        payload: [filterSort, status],
    };
}

export const clearDemoPurpose = () => {
    return {
        type: CLEAR_DEMO_PURPOSE
    }
}

export const clearDemoPurposeForm = () => {
    return {
        type: CLEAR_DEMO_PURPOSE_FORM
    }
}

export const postDemoPurpose = (values, demoPurposeId) => async (dispatch, getState) => {
    const state = getState();
    const demoPurpose = state.demoPurposeForm;
    const demoPurposeObject = {
        purpose: values?.purposeName,
        demoPurposeAttributes: values.demoPurposeAttributes?.length > 0 ? values.demoPurposeAttributes : [],
        id: demoPurposeId,
        roleTemplates: demoPurpose.roleTemplates || [],
        isActive: values.isActive || false

    }

    const contextPath = "/scheduleMaintenanceService/demoPurposes";
    const response = await aepSchedule({
        method: demoPurposeId === undefined ? 'post' : 'put',
        url: demoPurposeId === undefined ? contextPath : `${contextPath}/${demoPurposeId}`,
        data: demoPurposeObject,
    });
    const successMessage = demoPurposeId ? 'Demo purpose updated successfully' : 'Demo purpose created successfully';
    const errorMessage = response.status === CONFIG.HTTP_STATUS.CONFLICT ? response.data : demoPurposeId ? 'Unable to update demo purpose' : 'Unable to create demo purpose';
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
        pushMessage(CONFIG.messageType.success, successMessage)
        dispatch(closeCurrentOpenTab())
    }
    else pushMessage(CONFIG.messageType.error, errorMessage);
    dispatch(toggleIsFetchingDemoPurposeFormFlag(false))
}

export const fetchDemoPurposeForRoleTemplate = ({ pageNumber = 1, pageSize = CONFIG.shortPageSize + 1, access, filterSort }) => async (dispatch) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/demoPurposes", {
        params: {
            pageSize: pageSize,
            pageNumber: pageNumber,
            access,
            filterSort
        }
    });
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ALL_PURPOSE, payload: response.data || [] });
    }
    else {
        pushMessage(CONFIG.messageType.error, 'Unable to fetch demo purpose');
    }
    dispatch(toggleIsFetchingPurposeFlag(false));
    return response.data || []
}

export const getAllPurpose = async (access) => {
    const response = await aepSchedule.get("/scheduleMaintenanceService/demoPurposes", { params: { access } });
    return response.data ? response.data.dataSet : [];
}

export const checkDemoPurposeDependencies = async (demoPurposeId, viewDeletedItems = false) => {
    const response = await aepSchedule.get(`/scheduleMaintenanceService/demoPurposes/${demoPurposeId}/dependencies`, { params: { viewDeletedItems } })
    if (response.status === CONFIG.HTTP_STATUS.OK) return response.data
    else throw Error("Unable to get demo dependency list");
};

export const refreshDemoPurpose = () => {
    return {
        type: REFRESH_DEMO_PURPOSE
    };
};

export const toggleHasDemoPurposeUpdated = () => {
    return {
        type: TOGGLE_HAS_DEMO_PURPOSE_UPDATED
    };
};