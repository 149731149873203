import React from "react";
import { connect, batch } from "react-redux";
import { Row, Col, Select, Spin, message, Dropdown, Collapse, Form, Modal, Checkbox, Radio, Input, Divider } from 'antd';
import { Table, } from 'antd5';
import { Icon as NeoIcon, Button, Chip } from '@avaya/neo-react';
import { LoadingOutlined } from '@ant-design/icons';
import { Pagination, Accordion } from "neo-latest"
import {
    selectTitle,
    toggleBackButton,
    fetchDemos,
    fetchDemosApprovals,
    toggleIsFetchingApprovalRequestsFlag,
    clearDemoApprovals,
    exportTable,
    currentDemoApprovalFilter,
    refreshDemoApprovals,
    handleOnClickAction,
    toggleSaveReportVisible,
    approveOrDeclineScheduleRequest
}
    from '../../actions';
import CONFIG from '../../config';
import FilterDrawer from '../common/FilterDrawer';
import moment from 'moment-timezone';
import { fetchNamesBySearchString } from '../../actions/userRole'
import scrollIntoView from 'scroll-into-view';
import pushMessage from '../common/PushMessage';
import { exportMenu, toTitleCase } from "../../utils/strings";
import { isTabAlreadyOpen } from "../common/TabLayout";
// import SaveReport from "../common/SaveReport";
import NoData from "../common/NoData";
import { getUserInformationCard } from "../../utils/getUserInformationCard";
const { Option } = Select;
const { TextArea } = Input;

const dataIndexMap = {
    createdDate: 'createdDate',
    approvedDate: 'approvedDate',
    startTime: 'scheduleId.startTime',
    endTime: 'scheduleId.endTime',
    demo: 'scheduleId.demo.title',
    requestedBy: 'scheduleId.createdBy.fullName',
    approvedBy: 'approvedBy.fullName',
    demoPurpose: 'scheduleId.demoPurpose.purpose',
    customerName: 'scheduleId.purposeAttributesSchedule.customerName',
    tenantName: 'scheduleId.envName',
    incNo: 'scheduleId.ticketNo',
    buildStatus: 'scheduleId.buildStatus',
    region: 'scheduleId.region',
    assignedUser: "scheduleId.assignedUser.fullName",
    demoBuildId: "scheduleId.demoBuildId",
    pocNo: "scheduleId.pocNo",
    requestNo: "scheduleId.referenceNo",
    applyToAllCheck: 'includeStartAndEndDate',
};

class ManageDemoApprovals extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            approvalStatusTypes: props.userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin ? CONFIG.approvalStatusTypes.pendingRequests.value : CONFIG.approvalStatusTypes.myApprovals.value,
            hasEnteredString: false,
            matchedUsersNames: [],
            demoApprovalsData: [],
            isReturnedFromEdit: false,
            showCommentModal: false,
            clickedRecord: undefined,
            showFilter: false,
            collapsedRecord: [],
            height: `${document.body.clientHeight - 175}px`,
            drawerHeight: `${document.body.clientHeight - 100}px`,

        }
    }

    filterSort = {}
    filterSave = {}
    currentPage = 1
    pageSize = CONFIG.cardPageSize
    flag = 0;
    tableRef = React.createRef();

    clearCurrentApprovals = async () => {
        this.currentPage = 1
        await this.setState({ demoApprovalsData: [] })
    }

    fetchUsersName = searchString => {
        this.setState({ matchedUsersNames: [] });
        fetchNamesBySearchString({ searchString })
            .then((result) => {
                if (result?.length !== 0) {
                    delete result.id
                    this.setState({ matchedUsersNames: [...new Set(result.map(item => item.fullName))] })
                }
                this.setState({ hasEnteredString: false })
            });
    };

    getUTCTime = (time) => {
        const { timeZone } = this.props;
        return time.tz(timeZone, true).toISOString();
    }

    handleFilter = async (filters, isSave) => {
        let { demo, demoPurpose, requestedBy, approvedBy, assignee, applyToAllCheck, startEndTime, incNo, region, tenantName, requestNo, customerName, buildID } = filters;
        const { userRoleTemplate, timeZone } = this.props
        const shouldFetchDemos = userRoleTemplate && (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin || userRoleTemplate.templateType === CONFIG.roleTypes.admin)
        const isStartEndTimeEmpty = !startEndTime || (startEndTime && startEndTime.length === 0);
        const isDemoEmpty = !demo || (demo && demo.length === 0);
        const isAssignedEmpty = !assignee || (assignee && assignee.length === 0);
        const isPurposeEmpty = !demoPurpose || (demoPurpose && demoPurpose.length === 0);
        const isRequestedByEmpty = !requestedBy || (requestedBy && requestedBy.length === 0);
        const isApprovedByEmpty = !approvedBy || (approvedBy && approvedBy.length === 0);
        incNo = incNo?.trim();
        region = region?.trim();
        tenantName = tenantName?.trim();
        requestNo = requestNo?.trim();
        customerName = customerName?.trim();
        buildID = buildID?.trim();
        const formEmpty = isPurposeEmpty && isApprovedByEmpty && isDemoEmpty && isRequestedByEmpty && isStartEndTimeEmpty && isAssignedEmpty && !incNo && !region && !tenantName && !requestNo && !customerName && !buildID;
        const isFilter = isSave ? "save" : "filter";
        if (formEmpty) {
            pushMessage(CONFIG.messageType.warning, `Please select some fields to ${isFilter}`)
            return;
        }

        if (isSave) {
            this.filterSave = {
                ...this.filterSave,
                filter: {
                    ...(!isDemoEmpty && {
                        [dataIndexMap.demo]: {
                            operator: "=",
                            oprVal: !isDemoEmpty ? demo?.join(CONFIG.delimiters.saveFilter) : undefined
                        }
                    }),
                    ...(!isPurposeEmpty && {
                        [dataIndexMap.demoPurpose]: {
                            operator: "=",
                            oprVal: !isPurposeEmpty ? demoPurpose?.join(CONFIG.delimiters.saveFilter) : undefined
                        }
                    }),
                    ...(!isRequestedByEmpty && {
                        [dataIndexMap.requestedBy]: {
                            operator: "=",
                            oprVal: !isRequestedByEmpty ? requestedBy : undefined
                        }
                    }),
                    ...(!isApprovedByEmpty && {
                        [dataIndexMap.approvedBy]: {
                            operator: "=",
                            oprVal: !isApprovedByEmpty ? approvedBy : undefined
                        }
                    }),
                    ...(requestNo && {
                        [dataIndexMap.requestNo]: {
                            operator: "like",
                            oprVal: requestNo ? requestNo : undefined
                        }
                    }),
                    ...(region && {
                        [dataIndexMap.region]: {
                            operator: "like",
                            oprval: region ? region : undefined
                        }
                    }),
                    ...(incNo && {
                        [dataIndexMap.incNo]: {
                            operator: "like",
                            oprVal: incNo ? incNo : undefined
                        }
                    }),
                    ...(buildID && {
                        [dataIndexMap.demoBuildId]: {
                            operator: "like",
                            oprval: buildID ? buildID : undefined
                        }
                    }),
                    ...(tenantName && {
                        [dataIndexMap.tenantName]: {
                            operator: "like",
                            oprval: tenantName ? tenantName : undefined
                        }
                    }),
                    ...(!isStartEndTimeEmpty && {
                        [dataIndexMap.startTime]: {
                            operator: "IN",
                            oprVal: !isStartEndTimeEmpty ? startEndTime[0].tz(timeZone, true).startOf('day').toISOString() + '@' + startEndTime[1].endOf('day').tz(timeZone, true).toISOString() : undefined
                        }
                    }),
                    ...(!isStartEndTimeEmpty && {
                        [dataIndexMap.endTime]: {
                            operator: "IN",
                            oprVal: !isStartEndTimeEmpty ? startEndTime[0].tz(timeZone, true).startOf('day').toISOString() + '@' + startEndTime[1].tz(timeZone, true).endOf('day').toISOString() : undefined
                        }
                    }),
                },
                filterTable: {
                    id: 10,
                    tableName: "Scheduled Approval"
                },
            }
            this.props.toggleSaveReportVisible(true)
            this.setState({ saveReport: true })
        }
        else {
            await this.clearCurrentApprovals()
            this.filterSort = {
                ...this.filterSort,
                filter: {
                    [dataIndexMap.demo]: !isDemoEmpty ? (shouldFetchDemos ? demo.join(CONFIG.delimiters.selectFilter) : demo) : undefined,
                    [dataIndexMap.demoPurpose]: !isPurposeEmpty ? demoPurpose.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.requestedBy]: requestedBy || undefined,
                    [dataIndexMap.approvedBy]: approvedBy || undefined,
                    demoFilter: !isDemoEmpty ? (shouldFetchDemos ? demo.join(CONFIG.delimiters.selectFilter) : demo) : undefined,
                    requestedByFilter: requestedBy || undefined,
                    approvedByFilter: approvedBy || undefined,
                    demoPurposeFilter: !isPurposeEmpty ? demoPurpose.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.startTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[0]) : undefined,
                    [dataIndexMap.endTime]: !isStartEndTimeEmpty ? this.getUTCTime(startEndTime[1]) : undefined,
                    [dataIndexMap.assignedUser]: assignee || undefined,
                    assignee: assignee || undefined,
                    [dataIndexMap.applyToAllCheck]: applyToAllCheck || undefined,
                    // [dataIndexMap.buildStatus]: !isBuildStatusEmpty ? buildStatus.join(CONFIG.delimiters.selectFilter) : undefined,
                    // buildStatusFilter: !isBuildStatusEmpty ? buildStatus.join(CONFIG.delimiters.selectFilter) : undefined,
                    [dataIndexMap.incNo]: incNo || undefined,
                    incNo: incNo || undefined,
                    [dataIndexMap.tenantName]: tenantName || undefined,
                    tenantName: tenantName || undefined,
                    [dataIndexMap.region]: region || undefined,
                    region: region || undefined,
                    [dataIndexMap.requestNo]: requestNo?.toUpperCase() || undefined,
                    requestNo: requestNo?.toUpperCase() || undefined,
                    customerName: customerName || undefined,
                    [dataIndexMap.customerName]: customerName || undefined,
                    buildID: buildID || undefined,
                    [dataIndexMap.demoBuildId]: buildID || undefined,
                }
            }
            this.currentPage = 1;
            batch(() => {
                this.handleFetchApprovals()
                this.tableRef.current && this.tableRef.current.scrollIntoView({
                    behavior: 'smooth',
                })
            })
        }
    }


    handleClear = async () => {
        await this.clearCurrentApprovals()
        this.filterSort = { ...this.filterSort, filter: undefined };
        this.handleFetchApprovals();
    }

    handleSave = (filters) => {
        this.handleFilter(filters, true)
    }

    handleExport = async (fileType) => {
        const { timeZone } = this.props;
        pushMessage(CONFIG.messageType.loading, "Exporting", 0);
        const data = await this.props.fetchDemosApprovals({ pageNumber: 1, pageSize: null, filterSort: this.filterSort, status: this.state.approvalStatusTypes })
        exportTable(CONFIG.exportTable.approvals.name, data?.dataSet, timeZone, CONFIG.exportTable.approvals.fileName + `.${fileType}`, fileType)
            .then((response) => {
                message.destroy()
                const isExport = response.status === CONFIG.HTTP_STATUS.OK
                pushMessage(isExport ? CONFIG.messageType.success : CONFIG.messageType.warning, isExport ? "Report exported successfully" : "There is no data to export. Please verify the filters")
            })
            .catch(() => {
                message.destroy()
                pushMessage(CONFIG.messageType.error, "Unable to export table")
            })

    }

    handleSortChange = async (_, pageNumber) => {
        document.getElementById('tab-pane').scrollIntoView();
        this.currentPage = pageNumber
        this.handleFetchApprovals();
    }


    onItemsPerPageChange = async (_, pageSize) => {
        this.pageSize = pageSize
        this.currentPage = 1
        this.handleFetchApprovals();
    }



    handleFetchApprovals = (isRefresh) => {
        this.props.toggleIsFetchingApprovalRequestsFlag(true);
        this.props.fetchDemosApprovals({
            pageSize: this.pageSize,
            pageNumber: this.currentPage,
            filterSort: this.filterSort,
            status: this.state.approvalStatusTypes,
            isRefresh
        })
    }


    handleResize = (e) => {
        this.setState({ height: document.body.clientHeight > 250 ? `${document.body.clientHeight - 175}px` : `250px` });
        this.setState({ drawerHeight: document.body.clientHeight > 250 ? `${document.body.clientHeight - 50}px` : `250px` });
    }

    componentWillUnmount() {
        if (isTabAlreadyOpen({ openedTabs: this.props.openedTabs, currentComponentPath: this.state.currentPath })) {
            this.props.currentDemoApprovalFilter(this.filterSort, this.state.approvalStatusTypes, this.currentPage)
        } else {
            this.props.clearDemoApprovals();
        }
        window.removeEventListener("resize", this.handleResize);

    }

    async componentDidMount() {
        const { isEdit, approvalType, currentPageNumber, currentFilter, userRoleTemplate } = this.props;
        const shouldFetchDemos = userRoleTemplate && (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin || userRoleTemplate.templateType === CONFIG.roleTypes.admin)
        this.setState({ currentPath: window.location.pathname })
        this.setState({ loading: true })
        if (isEdit) {
            this.currentPage = currentPageNumber;
            this.filterSort = currentFilter;
            if (Object.keys(JSON.parse(JSON.stringify(currentFilter)))?.length > 0) {
                const filterObject = currentFilter?.filter ? JSON.parse(JSON.stringify(currentFilter?.filter)) : {};
                (Object.keys(filterObject)?.length > 0 &&
                    (Object.keys(filterObject).every(key => key !== "buildStatusFilter" && key !== "scheduleId.buildStatus" && filterObject[key] !== null && filterObject[key] !== undefined))) && this.setState({ showFilter: true })
            }
            await this.setState({ approvalStatusTypes: approvalType })
        }
        else this.filterSort = {}
        batch(() => {
            this.props.selectTitle("Schedule Approvals");
            this.handleFetchApprovals();
            this.props.toggleIsFetchingApprovalRequestsFlag(true);
            this.props.toggleBackButton(false);
            shouldFetchDemos && this.props.fetchDemos({ getAll: true, demoType: CONFIG.demoTypes.labPocDemo });
        });
        window.addEventListener("resize", this.handleResize);

    }

    componentDidUpdate = () => {
        const requestToScrollIntoView = this.state.isReturnedFromEdit ? this.props.approvalRequestIndex : 0;
        this.state.isReturnedFromEdit
            && this.state.demoApprovalsData?.length > 0
            && requestToScrollIntoView
            && scrollIntoView(document.querySelector(`.scroll-row-${requestToScrollIntoView}`), {
                align: { top: 0, left: 0 },
                behavior: "smooth"
            },
                this.setState({ isReturnedFromEdit: false })
            )
    }

    renderApprovalStatuses = () => {
        return Object.values(CONFIG.approvalStatusTypes).map((type) => {
            return <Option value={type.value}>{type.status}</Option>
        });
    }

    handleApprovalStatusChange = async (approvalType) => {
        await this.clearCurrentApprovals()
        const isApprovedTable = approvalType.includes(CONFIG.approvalStatusTypes.approvedRequests.value)
        this.setFilterSort(isApprovedTable)
        this.setState({ approvalStatusTypes: approvalType }, () => { this.handleFetchApprovals() })
    }

    setFilterSort = (isApprovedTable) => {
        if (isApprovedTable) {
            this.filterSort = {
                ...this.filterSort,
                filter: { ...this.filterSort?.filter, "scheduleId.buildStatus": "New\\Assigned\\In-Progress\\Available\\Expired\\Canceled (In-Progress)\\Canceled (Available)\\Canceled (Failed)\\Failed\\Failed (In-Progress)", "buildStatusFilter": "New\\Assigned\\In-Progress\\Available\\Expired\\Canceled (In-Progress)\\Canceled (Available)\\Canceled (Failed)\\Failed\\Failed (In-Progress)" }
            }
        }
        else {
            this.filterSort = {
                ...this.filterSort,
                filter: {
                    ...this.filterSort?.filter,
                    "scheduleId.buildStatus": undefined,
                    "buildStatusFilter": undefined,
                }
            }
        }
    }

    handleRefresh = async () => {
        const { approvalStatusTypes } = this.state;
        const isApprovedTable = approvalStatusTypes.includes(CONFIG.approvalStatusTypes.approvedRequests.value)
        this.setFilterSort(isApprovedTable)
        this.setState({ loading: true });
        await this.clearCurrentApprovals()
        this.handleFetchApprovals(true);
    }

    handleActionButton = (typeOfApprove, approvalObj) => {
        this.setState({
            showCommentModal: true,
            clickedRecord: approvalObj,
            modalButtonText: typeOfApprove === "DECLINED" ? "Decline" : "Approve",
            typeOfApprove: typeOfApprove
        })
    }
    handleSubmit = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            else {
                let userComment = this.props.form.getFieldValue('comment')
                userComment = userComment?.trim();
                let payload = {
                    ...this.state.clickedRecord,
                    "status": this.state.typeOfApprove,
                    "approversComment": userComment?.length > 0 ? userComment : undefined,
                    "scheduleId": this.state.clickedRecord?.scheduleId?.id
                }
                await this.props.approveOrDeclineScheduleRequest(payload)
                await this.setState({ showCommentModal: false })
                setTimeout(() => this.setState({
                    clickedRecord: undefined,
                    modalButtonText: undefined,
                    typeOfApprove: undefined
                }), 100)
                this.props.form.resetFields();
                this.handleFetchApprovals(true)
            }
        })
    }

    handleCancel = async () => {
        await this.setState({ showCommentModal: false })
        setTimeout(() => this.setState({
            clickedRecord: undefined,
            modalButtonText: undefined,
            typeOfApprove: undefined
        }), 100)
        this.props.form.resetFields();
    }

    renderRowInformation = (title, data) => {

        return (
            <div className="approval-details-column font-14px">
                <span className="bold">{title}:</span><br />{data}
            </div>
        )
    }

    handleCollapseChange = (keys) => {
        this.setState({ collapsedRecord: keys });
    };

    isPanelOpen = (panelKey) => {
        const { collapsedRecord } = this.state;
        return collapsedRecord.includes(panelKey);
    };



    fetchApprovalList = (schedule) => {
        let pastScheduleApprovers = schedule?.scheduleId?.scheduleApproval
        let futureScheduleApprovers;

        // pastScheduleApprovers contains approved as well as current level(Pending). Below logic will move pending object to futureScheduleApprovers
        if (pastScheduleApprovers?.length > 1) {
            futureScheduleApprovers = [...pastScheduleApprovers.slice(-1),
            ...schedule?.scheduleId?.demo?.demoApprovers?.filter(eachLevel => pastScheduleApprovers?.map(obj => obj.level).indexOf(eachLevel.level) === -1)]
            pastScheduleApprovers = pastScheduleApprovers.slice(0, -1)
        }
        else {
            futureScheduleApprovers = schedule?.scheduleId?.demo?.demoApprovers?.filter(eachLevel => pastScheduleApprovers?.map(obj => obj.level).indexOf(eachLevel.level) === -1)

        }

        // combining both pastScheduleApprovers & futureScheduleApprovers for easy looping
        let approversTree = [
            ...pastScheduleApprovers,
            ...futureScheduleApprovers
        ];

        let isRequestApproved = false
        let scheduleApprovers = [];

        approversTree.sort((a, b) => a.level - b.level);

        // Below logic is to create a simple object with User's detail's and status. We do not need details of remaining users, once SA overrides the request.
        // eslint-disable-next-line
        approversTree.some(function (obj) {
            if (!isRequestApproved) {
                const { level, status, approver, approvedDate, approversList, approvedBy } = obj;
                // Once the approval has reached its end before all approvers have acted, no need to show future pending approvers emails.
                if (status === "APPROVED_SA" || status === "DECLINED")
                    isRequestApproved = true;
                scheduleApprovers.push({
                    level,
                    approvedDate,
                    status: toTitleCase(status === undefined ? "PENDING" : status),
                    approver:
                        approversList
                            ? (status === "APPROVED" || status === "DECLINED") ? approvedBy?.email : approversList?.map(item => item.approversEmailId).join(', ')
                            : approver
                });
            }
        });
        return scheduleApprovers;

    }

    renderAdditionalQuestions = (questions) => {
        return (
            questions?.map(item => {
                const { question, answerText } = item;
                const { title, id, answers, answerType } = question
                let userSubmittedAnswers = answerType === CONFIG.demoAnswerTypes.Checkbox ? answerText?.split("//") : answerText;
                return (
                    <React.Fragment key={id}>
                        <Form.Item label={title}>
                            {answerType === CONFIG.demoAnswerTypes.Checkbox
                                ?
                                (<Checkbox.Group value={userSubmittedAnswers} className='font'>
                                    {answers?.map(opt => (<Checkbox style={{ color: "black" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Checkbox>))}
                                </Checkbox.Group>)
                                : answerType === CONFIG.demoAnswerTypes.RadioButton
                                    ? (<Radio.Group value={userSubmittedAnswers} className='font'>{answers?.map(opt => (<Radio style={{ color: "black" }} key={opt?.title} label={opt?.title} value={opt?.title}>{opt?.title}</Radio>))}
                                    </Radio.Group>)
                                    : (<TextArea className='font' value={userSubmittedAnswers} style={{ width: '700px' }} rows={2} />)
                            }
                        </Form.Item>
                    </React.Fragment>
                )

            })
        )
    }


    renderCapabilities = (data, color) => {
        return data?.map(item => { return (<span style={{ marginRight: "5px" }}><Chip variant={color}>{item?.capability?.title}</Chip></span>) })
    }

    renderApprovalsCard = () => {
        const { timeZone, userRoleTemplate, user, demoApprovals } = this.props
        return (demoApprovals && demoApprovals?.length > 0) ?
            demoApprovals?.map((eachCard) => {
                const { isMultipleApprover } = eachCard?.scheduleId?.demo || false;
                const scheduleApprovers = !!isMultipleApprover ? this.fetchApprovalList(eachCard) : []
                const { demoCapabilities } = eachCard?.scheduleId?.demo || [];

                const pendingCapabilities = demoCapabilities && demoCapabilities?.filter(item => item?.status === CONFIG.capabilityDetailsRequestStatus.Pending);
                const approvedCapabilites = demoCapabilities && demoCapabilities?.filter(item => item?.status === CONFIG.capabilityDetailsRequestStatus.Approved);
                const sfdcOpObj = eachCard?.scheduleId?.purposeAttributesSchedule ? eachCard?.scheduleId?.purposeAttributesSchedule.find(obj => obj.demoPurposeAttribute?.type === CONFIG.purposeAttributeTypes.opportunityNumber) : undefined;
                const approverEmails = eachCard?.approvedBy ? eachCard?.approvedBy?.email : (eachCard?.approversList && eachCard?.approversList.length > 0) ? eachCard?.approversList?.map(item => item.approversEmailId).join(', ') : undefined;
                // Compute each Object
                const schedulePrimaryDetailsObj = {
                    "Reference No": eachCard?.scheduleId?.referenceNo,
                    "Customer Name": eachCard?.scheduleId?.customerName || undefined,
                    "Opportunity Number.": sfdcOpObj ? sfdcOpObj?.attributeValue : undefined,
                    "Requested by": getUserInformationCard(eachCard?.scheduleId?.createdBy, eachCard?.scheduleId?.createdBy?.fullName),
                    "Schedule Start Time": moment.tz(eachCard?.scheduleId?.startTime, timeZone).format(CONFIG.dateFormats.user),
                    "Schedule End Time": moment.tz(eachCard?.scheduleId?.endTime, timeZone).format(CONFIG.dateFormats.user),
                    "Requested on": moment.tz(eachCard?.scheduleId?.createdTime, timeZone).format(CONFIG.dateFormats.userDateTime),

                }
                const scheduleSecDetailsObj = {
                    "Country": eachCard?.scheduleId?.country?.name,
                    "Approver(s) Email": approverEmails,
                    "Region": eachCard?.scheduleId?.region,
                    "Selected Capabilities": eachCard?.scheduleId?.parameters ? eachCard?.scheduleId?.parameters?.replaceAll("\\", ", ") : null,
                    "Manager's Email": eachCard?.scheduleId?.managerEmail,
                    "Regional Prime Email": eachCard?.scheduleId?.regionalPrimeEmail,
                    "Assignee": eachCard?.scheduleId?.assignedUser?.fullName,
                    "Build Status": eachCard?.scheduleId?.buildStatus,
                    "INC/IT No": eachCard?.scheduleId?.incNo,
                    "Tenant Name": eachCard?.scheduleId?.tenantName,
                    "Demo Purpose": eachCard?.scheduleId?.demoPurpose?.purpose,
                    "Approver's Comment": eachCard?.approversComment || undefined
                }
                const demoPurposeAttributes = {};
                eachCard?.scheduleId?.purposeAttributesSchedule?.forEach((item) => {
                    if (item?.demoPurposeAttribute?.type !== CONFIG.purposeAttributeTypes.customerName && item?.demoPurposeAttribute?.type !== CONFIG.purposeAttributeTypes.opportunityNumber) {
                        demoPurposeAttributes[item?.demoPurposeAttribute?.attributeLabel] = item?.attributeValue
                    }
                });

                const approvalDetailsObj = { ...schedulePrimaryDetailsObj, ...scheduleSecDetailsObj, ...demoPurposeAttributes }
                const rows = [];
                let currentRow = [];

                let detailObjCount = 0;
                for (const [key, value] of Object.entries(Object.fromEntries(
                    Object.entries(approvalDetailsObj).filter(([key, value]) => value !== undefined && value !== null)
                ))) {
                    currentRow.push({ [key]: value });
                    detailObjCount++;

                    if (detailObjCount % 3 === 0) {
                        rows.push(currentRow);
                        currentRow = [];
                    }
                }

                if (currentRow.length > 0) {
                    rows.push(currentRow);
                }
                const unCollapsedRow = rows?.slice(0, 3);
                const collapsedRow = rows?.slice(3);

                const { collapsedRecord } = this.state;
                const additionalQuestions = eachCard?.scheduleId?.demo?.additionalDemoQuestions;
                const hasQuestions = additionalQuestions?.length > 0;
                const isRequestPending = eachCard?.status === CONFIG.approvalStatusTypes.pendingRequests.value
                const isSuperAdmin = userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin
                const isUserPartOfApprovalList = eachCard?.approversList?.map(eachUser => eachUser.approversEmailId?.toLowerCase()).includes(user?.profile?.email?.toLowerCase());
                const showApproveButton = isRequestPending && isUserPartOfApprovalList
                const showDeclineButton = (isSuperAdmin && isRequestPending) || (isRequestPending && isUserPartOfApprovalList)
                const showOverrideButton = (isSuperAdmin && isRequestPending);

                return (
                    <div className="approval-details-card">
                        <Collapse
                            activeKey={collapsedRecord} onChange={this.handleCollapseChange}
                            className="approvals-collapse"
                            bordered={false}
                        >

                            <div className="approval-div">
                                <span style={{ marginBottom: "18px", marginLeft: "4px", marginRight: "4px" }} className="items-on-either-side">
                                    <div style={{ fontSize: "19px", color: "#242424", fontWeight: "400" }}>{eachCard?.scheduleId?.demo?.title}</div>
                                    <Chip variant={CONFIG.approvalRequestColors[`${this.state.approvalStatusTypes}`]} className={`bottom-spacing-3 ${eachCard?.status === CONFIG.approvalStatusTypes.pendingRequests.value ? "pink-chip" : ""}`}>{CONFIG.approvalRequestStatus[`${this.state.approvalStatusTypes}`]}</Chip>
                                </span>
                                {unCollapsedRow.map((row, rowIndex) => (
                                    <div key={rowIndex} className="approvals-row">
                                        {row.map((col, colIndex) => (
                                            <div key={col.id} className="approval-details-column">
                                                {this.renderRowInformation(Object.keys(col), Object.values(col))}
                                            </div>
                                        ))}

                                    </div>
                                ))}
                                {/* {!this.isPanelOpen(eachCard?.scheduleId?.id.toString()) ? <Row className="approvals-action-button right-align">
                                    {showDeclineButton && <Button onClick={() => this.handleActionButton("DECLINED", eachCard)} status='alert' className="left-spacing-3" variant="secondary"  ><NeoIcon className="action-neo-button" icon="close" /> Decline</Button>}
                                    {showOverrideButton && <Button onClick={() => this.handleActionButton("APPROVED_SA", eachCard)} status='default' className="left-spacing-3 avaya-default-button" variant="secondary" ><NeoIcon className="action-neo-button" icon="message-read" />Override Approve</Button>}
                                    {showApproveButton && <Button onClick={() => this.handleActionButton("APPROVED", eachCard)} status='default' className="left-spacing-3 avaya-default-button" ><NeoIcon className="action-neo-button" icon="check" />Approve</Button>}
                                </Row> : <br />} */}
                            </div>
                            {((collapsedRow && (Object.keys(collapsedRow).length > 0)) || (pendingCapabilities && pendingCapabilities?.length > 0) ||
                                (approvedCapabilites && approvedCapabilites?.length > 0) || hasQuestions || !!isMultipleApprover) &&
                                <div style={{ margin: "16px" }}>
                                    <Accordion
                                        key={eachCard?.scheduleId?.id}
                                        headerId={eachCard?.scheduleId?.id}
                                        header={<div
                                            className="font-14px neo-link no-underline">
                                            {this.isPanelOpen(eachCard?.scheduleId?.id.toString()) ? "Hide information" : "View more information"}
                                        </div>}
                                    >
                                        {collapsedRow.map((row, rowIndex) => (
                                            <>
                                                <div key={rowIndex} className="approvals-row">
                                                    {row.map((col, colIndex) => (
                                                        <div key={col.id} className="approval-details-column">
                                                            {this.renderRowInformation(Object.keys(col), Object.values(col))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </>))
                                        }
                                        <Divider className='approvals-divider' />
                                        {
                                            (pendingCapabilities && pendingCapabilities?.length > 0) ?
                                                <div>
                                                    <span className="bold">Pending Capability Requests</span>
                                                    <div>
                                                        {this.renderCapabilities(pendingCapabilities, "warning")}
                                                    </div>
                                                    <Divider className='approvals-divider' />
                                                </div>
                                                : null
                                        }
                                        {
                                            (approvedCapabilites && approvedCapabilites?.length > 0) ?
                                                <div>
                                                    <span className="bold">Approved Capability Requests:</span>
                                                    <div>
                                                        {this.renderCapabilities(approvedCapabilites, "success")}
                                                    </div>
                                                    <Divider className='approvals-divider' />
                                                </div>
                                                : null
                                        }
                                        {hasQuestions && <div style={{ color: "#242424" }}>
                                            <div><span className="bold">Additional Questions:</span></div>
                                            <div style={{ margin: "12px", cursor: "not-allowed" }}>
                                                {this.renderAdditionalQuestions(additionalQuestions)}
                                            </div>
                                        </div>}
                                        <br />
                                        {!!isMultipleApprover && <div style={{ color: "#242424" }}>
                                            <span className="bold">Approval list:</span>
                                            <br />
                                            <Table
                                                className="approval-details-table"
                                                pagination={false}
                                                size="small"
                                                columns={[
                                                    {
                                                        title: "Level",
                                                        dataIndex: 'level',
                                                        key: "level",
                                                        align: "center",
                                                        width: 50,
                                                    },
                                                    {
                                                        title: "Status",
                                                        dataIndex: 'status',
                                                        key: "status",
                                                        align: "center",
                                                        width: 150,
                                                        render: (status) => <>
                                                            {status === CONFIG.approvalStatusTypes.pendingRequests.status
                                                                ? <NeoIcon icon="away" style={{ color: "#CE94EB", fontSize: "20px" }} />
                                                                : (status === CONFIG.approvalStatusTypes.approvedRequests.status || status === "Approved_sa")
                                                                    ? <NeoIcon icon="available" style={{ color: "#088A08", fontSize: "20px" }} />
                                                                    : <NeoIcon icon="missed" style={{ color: "red", fontSize: "20px" }} />}&nbsp;{status === "Approved_sa" ? "Approved by Admin" : status}</>
                                                    },
                                                    {
                                                        title: "Email ID",
                                                        dataIndex: 'approver',
                                                        key: "approver",
                                                        align: "center",
                                                        width: 50,
                                                    },
                                                    {
                                                        title: "Date",
                                                        dataIndex: 'approvedDate',
                                                        key: "approvedDate",
                                                        align: "center",
                                                        width: 200,
                                                        render: (date) => date && moment.tz(date, timeZone).format(CONFIG.dateFormats.userDateTime)
                                                    }
                                                ]}

                                                dataSource={scheduleApprovers}
                                            />
                                        </div>}
                                    </Accordion>
                                </div>}
                            <div style={{ margin: "8px" }} className="right-align">
                                {showDeclineButton && <Button onClick={() => this.handleActionButton("DECLINED", eachCard)} status='alert' className="left-spacing-3" variant="secondary"  ><NeoIcon className="action-neo-button" icon="close" /> Decline</Button>}
                                {showApproveButton && <Button onClick={() => this.handleActionButton("APPROVED", eachCard)} status='default' className="left-spacing-3 avaya-default-button" variant="secondary"  ><NeoIcon className="action-neo-button" icon="check" />Approve</Button>}
                                {showOverrideButton && <Button onClick={() => this.handleActionButton("APPROVED_SA", eachCard)} status='default' className="left-spacing-3 avaya-default-button" ><NeoIcon className="action-neo-button" icon="message-read" />Override Approve</Button>}
                            </div>
                        </Collapse>
                    </div>
                )
            })
            : null
    }

    handleCloseFilterDrawer = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }))
    }

    render() {
        const { timeZone, demoApprovalsCount, demos, userRoleTemplate } = this.props;
        const shouldFetchDemos = userRoleTemplate && (userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin || userRoleTemplate.templateType === CONFIG.roleTypes.admin)
        const { showFilter } = this.state
        const formFields = [
            {
                label: "Demo",
                type: shouldFetchDemos ? CONFIG.formFieldTypes.SELECT : CONFIG.formFieldTypes.INPUT,
                key: "demo",
                mode: "tags",
                valueKey: "text",
                data: (demos) ? demos.map(demo => ({ text: demo.title })) : [],
                colSpan: 24,
                value: this.filterSort?.filter?.demoFilter
            },
            {
                label: "Customer Name",
                type: CONFIG.formFieldTypes.INPUT,
                key: "customerName",
                colSpan: 24,
                value: this.filterSort?.filter?.customerName
            },

            {
                label: "Requested By",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "requestedBy",
                data: this.state.matchedUsersNames || [],
                colSpan: 24,
                value: this.filterSort?.filter?.requestedByFilter
            },
            {
                label: "Assignee",
                type: CONFIG.formFieldTypes.SEARCH_SELECT,
                key: "assignee",
                data: this.state.matchedUsersNames || [],
                colSpan: 24,
                value: this.filterSort?.filter?.assignee
            },
            {
                label: "Build ID",
                type: CONFIG.formFieldTypes.INPUT,
                key: "buildID",
                colSpan: 12,
                value: this.filterSort?.filter?.buildID
            },
            {
                label: "Request No.",
                type: CONFIG.formFieldTypes.INPUT,
                key: "requestNo",
                colSpan: 12,
                value: this.filterSort?.filter?.requestNo
            },

        ]
        const drawerContainer = document.getElementById('#component-content');
        const filtered = !this.filterSort?.filter ||
            Object.keys(this.filterSort?.filter).every(key => key === "buildStatusFilter" || key === "scheduleId.buildStatus" || this.filterSort?.filter[key] === null || this.filterSort?.filter[key] === undefined);;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Row gutter={24} style={{ marginLeft: "0px", marginRight: "0px" }} >
                    <Col xl={6} sm={6} xs={24}>
                        <div style={{ fontSize: "19px", color: "#242424", fontWeight: "400" }}>
                            {(userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin
                                || userRoleTemplate.templateType === CONFIG.roleTypes.admin) ? "Approvals" : "Pending Approval Requests"}
                        </div>
                    </Col>

                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <Dropdown overlay={exportMenu(this.handleExport)} trigger="click">
                            <NeoIcon title="Export" icon="download" style={{ fontSize: "23px", color: '#0b67bd' }} />
                        </Dropdown>
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon className="clickable" title="Refresh" icon="refresh" onClick={this.handleRefresh} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    <Col xl={1} sm={2} xs={2} className="float-right">
                        <NeoIcon className="clickable" onClick={() => this.setState(prevState => ({
                            showFilter: !prevState.showFilter
                        }))} title="Filter" icon={!filtered ? "filter-filled" : "filter"} style={{ fontSize: "23px", color: '#0b67bd' }} />
                    </Col>
                    <Col xl={6} sm={6} xs={24} className="float-right">
                        {(userRoleTemplate.templateType === CONFIG.roleTypes.superAdmin
                            || userRoleTemplate.templateType === CONFIG.roleTypes.admin) && <Select
                                showSearch={true}
                                placeholder="Select a type"
                                value={this.state.approvalStatusTypes}
                                onChange={this.handleApprovalStatusChange}
                            >
                                {this.renderApprovalStatuses()}
                            </Select>}
                    </Col>
                </Row>
                <div className="bottom-spacing">
                    <div>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.props.isFetching}>
                            <div id="component-content" className="approvals-pagination-row" style={{ maxHeight: this.state.height }}>


                                {this.props.demoApprovals?.length > 0
                                    ? this.renderApprovalsCard()
                                    : <NoData size="lg" />}
                                <Row
                                    className="approval-pagination"

                                >
                                    {demoApprovalsCount > 0 && <Pagination
                                        alwaysShowPagination={true}
                                        itemsPerPageLabel="Items per page: "
                                        currentPageIndex={this.currentPage || 1}
                                        id="templated-pagination"
                                        itemCount={demoApprovalsCount || 0}
                                        itemsPerPage={this.pageSize || 1}
                                        itemsPerPageOptions={[5, 10, 15, 20]}
                                        onItemsPerPageChange={(e, pageIndex) => this.onItemsPerPageChange(e, pageIndex)}
                                        onPageChange={(e, pageIndex) => this.handleSortChange(e, pageIndex)}
                                    />}
                                </Row>
                            </div>
                        </Spin>
                        {showFilter && (
                            <FilterDrawer
                                visible={showFilter}
                                width='520'
                                handleCloseFilterDrawer={this.handleCloseFilterDrawer}
                                placement="right"
                                drawerContainer={drawerContainer}
                                style={{ zIndex: 9999, }}
                                maskClosable={false}
                                panelHeader="Filters and Export"
                                formFields={formFields}
                                handleClear={this.handleClear}
                                handleFilter={this.handleFilter}
                                handleSave={shouldFetchDemos ? this.handleSave : undefined}
                                timeZone={timeZone}
                                hideSoftDeleteCheckbox={true}
                                getSearchData={this.fetchUsersName}
                            />)}
                        <Modal
                            title="Confirmation"
                            visible={this.state.showCommentModal}
                            onCancel={() => this.setState({ showCommentModal: false })}
                            // onOk={this.handleSubmit}
                            footer={[
                                <Button variant="secondary" size="compact" className="left-spacing-3 avaya-default-button" onClick={this.handleCancel}>
                                    Cancel
                                </Button>,
                                <Button status='default' size="compact" className="left-spacing-3 avaya-default-button" onClick={this.handleSubmit} >
                                    {`${this.state.modalButtonText}`}
                                </Button>,
                            ]}
                        >
                            <Form.Item label={`Comment ${this.state.modalButtonText !== "Decline" ? " (Optional)" : ""}`}>
                                {getFieldDecorator("comment", {
                                    rules: [
                                        {
                                            required: this.state.modalButtonText === "Decline",
                                            message: "Comment is mandatory!",
                                        }
                                    ],
                                })(<TextArea />)}
                            </Form.Item>

                        </Modal>
                    </div>
                </div>
            </div>

        );
    }
}

let ManageDemoApprovalsRef = Form.create()(ManageDemoApprovals);

const mapStateToProps = ({ demoApprovals, user, demos, newSchedule, tabsLayout }) => {
    return {
        user,
        timeZone: user.profile.timezone,
        userRoleTemplate: user.profile ? user.profile.roleTemplate : undefined,
        demoApprovals: demoApprovals.data,
        demoApprovalsCount: demoApprovals.count,
        response: demoApprovals.response,
        demos: demos.allDemos,
        isFetching: demoApprovals.isFetching,
        currentPageNumber: demoApprovals.currentPageNumber,
        pageSize: demoApprovals.pageSize,
        currentApprovalScheduleId: demoApprovals.currentApprovalScheduleId,
        approvalRequestIndex: demoApprovals.approvalRequestIndex,
        isEdit: demoApprovals.isEdit,
        approvalType: demoApprovals.approvalType,
        currentFilter: demoApprovals.currentFilter,
        openedTabs: tabsLayout.panes
    };
};

export default connect(
    mapStateToProps,
    {
        selectTitle,
        toggleBackButton,
        fetchDemos,
        fetchDemosApprovals,
        toggleIsFetchingApprovalRequestsFlag,
        clearDemoApprovals,
        currentDemoApprovalFilter,
        refreshDemoApprovals,
        handleOnClickAction,
        toggleSaveReportVisible,
        approveOrDeclineScheduleRequest
    }
)(ManageDemoApprovalsRef);