import { batch } from 'react-redux';
import CONFIG from "../config"
import pushMessage from "../components/common/PushMessage"
import { aepUser } from '../apis';

import {
    CLEAR_LOCAL_USERS,
    FETCH_COUNTRIES,
    FETCH_LOCAL_USER,
    TOGGLE_IS_FETCHING_LOCAL_USERS,
    UPDATE_LOCAL_USERS_CURRENT_PAGE,
    CLEAR_LOCAL_USERS_DETAILS,
    FETCH_ALL_COMPANIES,
    UPDATE_COMPANIES,
    LAST_LOCAL_USERS_FILTER,
    TOGGLE_HAS_LOCAL_USERS_UPDATED,
    REFRESH_LOCAL_USERS,
    UPDATE_LOCAL_USER_FORM_TAB_DATA,
    REMOVE_LOCAL_USER_TAB,
    FETCH_USER_DETAILS
} from './types';

export const fetchLocalUser = (userId) => async (dispatch, getState) => {
    const response = await aepUser.get(`/userTemplateService/local/user/${userId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        const localUserDetails = response.data;
        batch(() => {
            dispatch({
                type: FETCH_LOCAL_USER,
                payload: localUserDetails
            });
            dispatch(updateLocalUserFormTabData({ userId: userId, localUserDetails: localUserDetails, fetchFromAPI: true }))
        });
    }
    else {
        pushMessage(CONFIG.messageType.error, "Unable to fetch Non-SSO User details");
    }
    return response.status === CONFIG.HTTP_STATUS.OK ? response.data : []
};

export const fetchUserById = (userId, dispatchToReducer = true) => async (dispatch, getState) => {
    const response = await aepUser.get(`/userTemplateService/v1/lite/user/${userId}`);
    if (response.status === CONFIG.HTTP_STATUS.OK) {
        if (dispatchToReducer) {
            batch(() => {
                dispatch({
                    type: FETCH_USER_DETAILS,
                    payload: response.data,
                })
            })
        }
        else {
            return response?.data ? response?.data : []
        }
    }
    else pushMessage(CONFIG.messageType.error, "Unable to fetch user details");
}

export const fetchAllCountries = () => async dispatch => {
    const response = await aepUser.get('/userTemplateService/country');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_COUNTRIES, payload: response.data || [] });
        return response.data || []
    }
};


export const fetchAllCompanies = () => async dispatch => {
    const response = await aepUser.get('userTemplateService/companies');
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({ type: FETCH_ALL_COMPANIES, payload: response.data || [] });
    }
};

export const postNewCompany = (company) => async dispatch => {
    const data = {
        company: company
    };
    const response = await aepUser.post('/userTemplateService/companies', data);
    if (response.status === CONFIG.HTTP_STATUS.CREATED) {
        dispatch({
            type: UPDATE_COMPANIES,
            payload: response.data
        });
        pushMessage(CONFIG.messageType.success, 'New Company added successfully');
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to create a Company');
};


export const fetchCompaniesBySearchString = async ({ pageNumber = 0, pageSize = CONFIG.fetchCompaniesSize, searchString }) => {
    const contextPath = '/userTemplateService/companies/search'
    const response = await aepUser.get(contextPath, {
        params: {
            pageSize,
            pageNumber,
            searchString,
        }
    })
    let uniqueData = [];
    uniqueData = response.data?.dataSet?.reduce((acc, current) => {
        const x = acc.find(item => (item?.company === current.company))
        if (!x) return acc.concat([current]);
        else return acc;
    }, []);
    return uniqueData
}


export const refreshLocalUsers = () => {
    return {
        type: REFRESH_LOCAL_USERS
    };
};

export const updateLocalUsersCurrentPage = (currentPageNumber, pageSize, localUserId, localUserIndex) => {
    return {
        type: UPDATE_LOCAL_USERS_CURRENT_PAGE,
        payload: [currentPageNumber, pageSize, localUserId, localUserIndex]
    }
};

const checkIflocalUserDetailsHasChanged = (newlocalUserDetails, oldlocalUserDetails) => {
    let haslocalUserDetailsChanged = false;
    const oldRoleTemplates = oldlocalUserDetails.userRoleTemplates?.map(userRoleTemplate => JSON.stringify({ id: userRoleTemplate?.roleTemplate.id, title: userRoleTemplate?.roleTemplate.title }))

    haslocalUserDetailsChanged = newlocalUserDetails.firstName?.trim() !== oldlocalUserDetails.firstName?.trim()
        || newlocalUserDetails.lastName?.trim() !== oldlocalUserDetails.lastName?.trim()
        || newlocalUserDetails.active !== oldlocalUserDetails.active
        || newlocalUserDetails.sslHandle !== oldlocalUserDetails.sslHandle
        || newlocalUserDetails.timezone !== oldlocalUserDetails.timezone
        || newlocalUserDetails?.company !== oldlocalUserDetails.company?.company
        || newlocalUserDetails.country !== oldlocalUserDetails.country
        || newlocalUserDetails.email !== oldlocalUserDetails.email
        || newlocalUserDetails.contactNumber !== oldlocalUserDetails.contactNumber
        || JSON.stringify(newlocalUserDetails.roleTemplate) !== JSON.stringify(oldRoleTemplates)
    return { haslocalUserDetailsChanged };
}

export const postLocalUser = (localUserDetails, localUserId, activeKey) => async (dispatch, getState) => {
    const { localUsersForm } = getState();
    delete localUserDetails.region;
    const selectedCompany = localUserDetails.company
    const localUser = {
        ...localUserDetails,
        userRoleTemplates: localUserDetails.roleTemplate?.map(roleTemplate => { return { "roleTemplate": JSON.parse(roleTemplate) } }),
        isActive: localUserDetails.active,
        company: (localUserDetails.company?.length !== 0 && localUserDetails.company !== undefined) ? { company: Array.isArray(selectedCompany) ? selectedCompany[0] : selectedCompany } : null
    }
    const { haslocalUserDetailsChanged } = localUserId ? checkIflocalUserDetailsHasChanged(localUserDetails, localUsersForm.localUserDetails) : true;
    if (haslocalUserDetailsChanged || !localUserId) {
        const contextPath = "userTemplateService/local/user"
        const response = await aepUser({
            method: localUserId === undefined ? 'post' : 'put',
            url: localUserId === undefined ? contextPath : `${contextPath}/${localUserId}`,
            data: localUser
        });
        dispatch(toggleHasLocalUsersUpdated(true))
        const successMessage = localUserId ? 'Non-SSO User updated successfully' : 'Non-SSO User created successfully';
        const errorMessage = localUserId ? 'Unable to update Non-SSO User' : 'Unable to create Non-SSO User';
        if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.CREATED) {
            pushMessage(CONFIG.messageType.success, successMessage);
            return {
                localUserId: localUserId === undefined ? response.data : localUserId
            }

        }
        else if (response.status === CONFIG.HTTP_STATUS.CONFLICT) pushMessage(CONFIG.messageType.error, response.data);
        else {
            pushMessage(CONFIG.messageType.error, errorMessage);
        }
    }
    else if (!haslocalUserDetailsChanged) {
        pushMessage(CONFIG.messageType.info, "No changes made")
    };
    dispatch(toggleIsFetchingLocalUsersFlag(false))
};

export const toggleIsFetchingLocalUsersFlag = flag => {
    return {
        type: TOGGLE_IS_FETCHING_LOCAL_USERS,
        payload: flag
    }
}

export const toggleHasLocalUsersUpdated = flag => {
    return {
        type: TOGGLE_HAS_LOCAL_USERS_UPDATED,
        payload: flag
    }
}

export const clearLocalUsers = () => {
    return { type: CLEAR_LOCAL_USERS };
}

export const clearLocalUsersDetails = () => {
    return { type: CLEAR_LOCAL_USERS_DETAILS };
}

export const currentLocalUsersFilter = (filters, status, pageNumber, pageSize) => {
    return {
        type: LAST_LOCAL_USERS_FILTER,
        payload: [filters, status, pageNumber, pageSize]
    }
}

export const updateLocalUserFormTabData = ({ userId, localUserDetails, fetchFromAPI = false }) => {
    var formTabData = {};
    if (fetchFromAPI) {
        formTabData = {
            id: userId,
            data: localUserDetails
        }
    }
    else {
        const selectedCompany = localUserDetails?.company
        const localUser = {
            ...localUserDetails,
            userRoleTemplates: localUserDetails?.roleTemplate?.map(roleTemplate => { return { "roleTemplate": JSON.parse(roleTemplate) } }),
            isActive: localUserDetails?.active,
            region: { name: localUserDetails?.region },
            company: (localUserDetails.company?.length !== 0 && localUserDetails.company !== undefined) ? { company: Array.isArray(selectedCompany) ? selectedCompany[0] : selectedCompany } : null
        }

        formTabData = {
            id: userId,
            data: localUser
        }
    }
    return {
        type: UPDATE_LOCAL_USER_FORM_TAB_DATA,
        payload: formTabData
    }
}

export const removeLocalUserTab = (id) => {
    return {
        type: REMOVE_LOCAL_USER_TAB,
        payload: id
    }
}

