import {
    TOGGLE_IS_FETCHING_PRODUCTS,
    GET_ALL_PRODUCTS,
    GET_ALL_PRODUCT,
    CLEAR_ALL_PRODUCTS,
    UPDATE_PRODUCT_EDITING_KEY,
    ADD_PRODUCT,
    REMOVE_EMPTY_PRODUCT,
    UPDATE_PRODUCT,
    LAST_PRODUCT_FILTERS,
    REFRESH_PRODUCTS
} from "./types";
import { batch } from 'react-redux';
import CONFIG from '../config';
import pushMessage from '../components/common/PushMessage';
import { aepReport } from '../apis';

export const toggleIsProductFetching = (flag) => {
    return {
        type: TOGGLE_IS_FETCHING_PRODUCTS,
        payload: flag,
    }
}

export const getAllReportProducts = ({ pageNumber = 1, pageSize, isActive, filterSort, isExport = false }) => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/allProducts", {
        params: {
            pageNumber,
            pageSize,
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive
                }
            }
        }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (isExport) {
            return response?.data?.dataSet || [];
        }
        else {
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: response?.data || []
            })
        }
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Products');
    dispatch(toggleIsProductFetching(false));
}

export const getAllProductsLite = ({ isActive, filterSort, isExport = false }) => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/v1/lite/allProducts", {
        params: {
            filterSort: {
                ...filterSort,
                filter: {
                    ...filterSort?.filter,
                    isActive
                }
            }
        }
    });

    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        if (isExport) {
            return response?.data || [];
        }
        else {
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: response?.data || []
            })
        }
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Products');
    dispatch(toggleIsProductFetching(false));
}

export const getAllProduct = () => async (dispatch) => {
    const response = await aepReport.get("/reportSearchService/allProducts");
    if (response.status === CONFIG.HTTP_STATUS.OK || response.status === CONFIG.HTTP_STATUS.NO_CONTENT) {
        dispatch({
            type: GET_ALL_PRODUCT,
            payload: response?.data || []
        })
    }
    else pushMessage(CONFIG.messageType.error, 'Unable to fetch Product');
}

export const clearProducts = () => {
    return {
        type: CLEAR_ALL_PRODUCTS
    }
}

export const updateProductEditingKey = (key = "") => {
    return {
        type: UPDATE_PRODUCT_EDITING_KEY,
        payload: key
    };
};

export const addProduct = productId => {
    const product = {
        id: productId,
        title: ""
    };
    return {
        type: ADD_PRODUCT,
        payload: product
    };
};

export const removeEmptyProduct = () => async dispatch => {
    batch(() => {
        dispatch({
            type: REMOVE_EMPTY_PRODUCT
        });
        dispatch(updateProductEditingKey())
    });
};

export const updateProduct = (product) => {
    return {
        type: UPDATE_PRODUCT,
        payload: product
    };
};

export const postProductRecord = (productId, data) => async dispatch => {
    const url = productId < 0 ? "/reportSearchService/product" : `/reportSearchService/product/${productId}`
    const response = await aepReport({
        url,
        method: productId < 0 ? 'post' : 'put',
        data,
    });
    batch(() => {
        dispatch(updateProductEditingKey())
        dispatch(toggleIsProductFetching(false));
    })
    if (response.status === CONFIG.HTTP_STATUS.CREATED || response.status === CONFIG.HTTP_STATUS.OK) {
        pushMessage(CONFIG.messageType.success, productId < 0 ? 'Product created successfully' : "Product updated successfully")
    }
    else pushMessage(CONFIG.messageType.error, productId < 0 ? 'Unable to add product' : "Unable to update product");
}

export const currentProductFilters = (filterSort, status, pageNumber, pageSize) => {
    return {
        type: LAST_PRODUCT_FILTERS,
        payload: [filterSort, status, pageNumber, pageSize]
    };
};

export const refreshProduct = () => {
    return {
        type: REFRESH_PRODUCTS
    }
}